import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, TextField, Box } from '@material-ui/core';
import { IonButton } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import { Dialog, ButtonLoader } from '../../components/material';
import { pushToTraffic } from '../../actions/exports';

const SendToTrafficButton = ({ exportItem, pushToTraffic, ...rest }) => {
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [comments, setComments] = useState('');

    return <>
        <IonButton
            variant="contained"
            color="primary"
            size="small"
            title={t('Activate Campaign and send it to the media owner for scheduling')}
            onClick={() => setOpen(true)}
            {...rest}
        >{t('Activate')}</IonButton>

        <Dialog
            open={open}
            title={t('Activate Campaign')}
        >
            <DialogContent>
                <Box>
                    <LCTypography variant="body1">
                        Submit this campaign to the operator for scheduling.
                        Once submitted, the operator will be notified, will review the campaign
                        and will schedule it.  You will be notified when the campaign is scheduled.
                    </LCTypography>
                </Box>

                <Box mt={3} mb={5}>
                    <TextField
                        placeholder="Comments & Scheduling Instructions for Operator"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments}
                        multiline
                        autoFocus
                        rows={4}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                >
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitting(true);
                        pushToTraffic(exportItem.id, comments).then(() => {
                            setSubmitting(false);
                            setOpen(false);
                        })
                    }}
                    submitting={submitting}
                >Submit to Traffic</ButtonLoader>
            </DialogActions>
        </Dialog>

    </>

}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pushToTraffic: (exportId, comments) => dispatch(pushToTraffic(exportId, comments))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(SendToTrafficButton)
    );
