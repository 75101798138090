import { Box, Grid, IconButton, Popover, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { toDictionary } from '../helpers/array';
import { useTranslation } from 'react-i18next';

export const StylePicker = ({ value,
    onChange,
    renderPreview }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    return <>
        <Box
            style={{ cursor: 'pointer' }}
            onClick={e => setAnchorEl(e.currentTarget)}>
            {renderPreview(StyleOptionsDict[value]?.style ?? {})}
            <IconButton
                style={{ marginLeft: 8 }}>
                <Edit />
            </IconButton>
        </Box>

        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={2} maxWidth={500}>
                <Typography gutterBottom variant='h6'>{t('Style Options:')}</Typography>
                <Grid container spacing={2}>
                    {StyleOptions
                        .map(x => {
                            return <Grid key={x.name}
                                item
                                xs={6}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    onChange(x.name);
                                    setAnchorEl(null);
                                }}>
                                <Typography gutterBottom>{t(x.name)}</Typography>
                                {renderPreview({ width: '100%', ...x.style })}
                            </Grid>
                        })}
                </Grid>
            </Box>
        </Popover>
    </>;
}

const StyleOptions = [
    {
        name: 'Red',
        style: {
            background: '#f7e6e6',
            borderColor: '#efcfcf',
            color: '#a53737',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Orange',
        style: {
            background: '#fbede1',
            borderColor: '#f8dcc3',
            color: '#ba6016',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Yellow',
        style: {
            background: '#fdf3da',
            borderColor: '#e9dbcd',
            color: '#726f56',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Green',
        style: {
            background: '#ddefdd',
            borderColor: '#c6e6c7',
            color: '#326d34',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Blue',
        style: {
            background: '#dee7f8',
            borderColor: '#cfdbe3',
            color: '#464c5c',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Indigo',
        style: {
            background: '#eae6f7',
            borderColor: '#d1c9ee',
            color: '#6e5cb6',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Violet',
        style: {
            background: '#efe8f3',
            borderColor: '#e0d1e7',
            color: '#69427f',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Pink',
        style: {
            background: '#fbeaf8',
            borderColor: '#f6d5ef',
            color: '#da49be',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Grey',
        style: {
            background: '#edeef2',
            borderColor: '#e3e4e8',
            color: '#555',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    },
    {
        name: 'Checkered',
        style: {
            background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBJREFUeNpifP36NQNuwM/Pj0eWiYECMKp5ZGhm/PXrFx7pjx8/jgbYqGZKNAMEGACkAwjWR982ZwAAAABJRU5ErkJggg==)',
            borderColor: '#b2b2b2',
            color: '#555',
            padding: '0 4px',
            borderRadius: '3px',
            border: '1px solid'
        }
    }
]

const StyleOptionsDict = toDictionary(StyleOptions, x => x.name)

export const getStylesByName = name => StyleOptionsDict[name]?.style ?? {};
