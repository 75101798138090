import React from 'react';
import { useSelector } from 'react-redux';
import { InventoryExportCampaignClasses } from '../../helpers/constants';
import AddRowsButton from './AddRowsButton';
import { useTranslation } from 'react-i18next';
import { IconFromSet } from '../../components/IconFromSet';
import { Tv } from '@material-ui/icons';

const AddScreenGroupsButton = ({ screenGroups, onChange }) => {
    const { t } = useTranslation();
    const groupsOptions = useSelector(state => state.lightning.agencyExports.filter(
        x => x.campaign_class == InventoryExportCampaignClasses.screenGroup.class
    ))

    return <AddRowsButton
        options={groupsOptions.map(x => ({
            ...x,
            Icon: <IconFromSet iconKey={x?.options?.ui_options?.layout?.icon}
                FallbackIcon={Tv} />
        }))}
        value={screenGroups}
        onChange={onChange}
        buttonTitle={t('Add Groups')}
        noOptionsText={t('No groups found for your search')}
    />
}

export default AddScreenGroupsButton;
