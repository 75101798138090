/* eslint-disable */
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import { Global } from '../../helpers/constants';

const useStyles = makeStyles(() => {
    return {
        root: {
            '& ol': {
                paddingInlineStart: 30
            },
            '& p': {
                marginBlockStart: '0.5em',
                marginBlockEnd: '0.5em'
            }
        }
    }
});

const EulaText = () => {
    const classes = useStyles();

    return <div id="content" role="main" className={classes.root}>
        <p style={{ textAlign: 'right' }}><em>Updated {moment.utc(Global.eulaModifiedAt).local().format('MM/DD/YYYY')}</em></p>
        <p styles="margin-top: 0.08in; margin-bottom: 0in"><font face="Times New Roman, serif">This
            Lucit Platform End User License Agreement (“<b>EULA</b>”) is a
            binding agreement between you (“<b>End User</b>” or “<b>you</b>”)
            and Lucit LLC (“<b>Lucit</b>”, “<b>we</b>” or “<b>us</b>”).
            This EULA governs your use of the Lucit platform (including all
            related documentation, the “<b>App</b>”). The App is licensed,
            not sold, to you.</font></p>
        <p styles="margin-top: 0.08in; margin-bottom: 0in"><font face="Times New Roman, serif">BY
            CLICKING THE “AGREE” BUTTON OR INSTALLING THE APP, YOU ACCEPT
            THIS EULA AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.  </font>
        </p>
        <p styles="margin-top: 0.08in; margin-bottom: 0in"><font face="Times New Roman, serif"><font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">IF
            YOU ARE ACCEPTING THIS EULA ON BEHALF OF A COMPANY</font></font></span></span></font></font></font>
            <font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">OR
                OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND
                SUCH</font></font></span></span></font></font></font> <font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">ENTITY
                    AND ITS AFFILIATES TO THE TERMS OF THIS EULA, IN WHICH CASE THE TERMS
                    “END USER” OR “YOU” WILL</font></font></span></span></font></font></font>
            <font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">REFER
                TO SUCH ENTITY AND ITS AFFILIATES AS WELL AS YOU PERSONALLY.  </font></font></span></span></font></font></font></font>
        </p>
        <p styles="margin-top: 0.08in; margin-bottom: 0in"><font face="Times New Roman, serif">IF
            YOU DO NOT AGREE TO THESE TERMS, OR IF YOU DO NOT HAVE <font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">THE
                AUTHORITY TO BIND THE</font></font></span></span></font></font></font>
            <font><font face="TimesNewRomanPSMT, Times New Roman, serif"><font size="2"><span styles="font-style: normal"><span styles="font-weight: normal"><font face="Times New Roman, serif"><font size="3">ENTITY
                REFERRED TO ABOVE, </font></font></span></span></font></font></font>DO
            NOT INSTALL OR USE THE APP, AND DELETE IT FROM YOUR DEVICE.</font></p>
        <ol>
            <li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">License
                Grant</span></span></u></font></font></span></font>. Subject to the
                terms of this EULA, Lucit grants you a limited, non-exclusive and
                nontransferable license to:</font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Download
                        and install the App on any mobile device or desktop PC owned or
                        otherwise controlled by you (“<b>Device</b>”) strictly in
                        accordance with the App&apos;s documentation; </font>
                    </p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Use
                        the App on said Device(s) to create and manage digital advertising
                        campaigns for display on digital OOH screens (“<b>Services</b>”),
                        which digital OOH screens are owned and operated by a separate
                        company (“<b>Operator</b>”), with whom you have separately
                        contracted for the display of such screens; and</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Use
                        the App and Services to access and use content provided by Lucit
                        (“<b>Lucit Content</b>”).</font></p>
                    </li></ol>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal">Acknowledgment</span></u></font></font></span></font><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">.
                You acknowledge and agree that:</span></font></font></span></font></font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">Your
                        right to use the App to access the Services, and to use the
                        Services and Lucit Content to </span></font></font></span></font>create
                        and manage digital advertising campaigns for display on digital OOH
                        screens, is subject to your purchase of such rights from the
                        applicable Operator<font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">;</span></font></font></span></font></font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">YOUR
                        ACCESS TO AND USE OF THE APP, LUCIT CONTENT AND SERVICES IS SUBJECT
                        TO PROMPT PAYMENT TO LUCIT BY THE OPERATOR;</span></font></font></span></font></font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">LUCIT
                        HAS THE RIGHT TO SUSPEND OR TERMINATE YOUR ACCESS TO AND USE OF THE
                        APP, LUCIT CONTENT OR SERVICES, AND TO TERMINATE THIS EULA, WITH OR
                        WITHOUT NOTICE, IN THE EVENT OF ANY BREACH BY YOU OF THIS EULA OR
                        BREACH BY THE OPERATOR OF THE APPLICABLE SERVICES AGREEMENT BETWEEN
                        THE OPERATOR AND LUCIT, INCLUDING BUT NOT LIMITED TO THE FAILURE TO
                        PAY ANY AMOUNTS OWED TO LUCIT; </span></font></font></span></font></font>
                    </p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">IN
                        THE EVENT OF ANY DISPUTE OR CLAIM OF ANY NATURE RELATING IN ANY WAY
                        TO THE APP, LUCIT CONTENT OR SERVICES, YOU WILL LOOK SOLELY TO THE
                        OPERATOR AND NOT LUCIT FOR ANY REMEDY OF ANY NATURE TO WHICH YOU
                        BELIEVE YOU MAY BE ENTITLED; and </span></font></font></span></font></font>
                    </p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">Lucit
                        has the right, in its sole discretion, to change this EULA or its
                        Privacy Policy, at any time and without notice, and that it is your
                        responsibility to review the current version of the EULA (</span></font></font></span></font>set
                        forth at www.lucit.cc/eula) and Privacy Policy <font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">(</span></font></font></span></font>set
                        forth at www.lucit.cc/privacy-policy)<font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><span styles="font-weight: normal">
                            from time to time, and to comply with any changes.  </span></font></font></span></font></font>
                    </p>
                    </li></ol>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><u>End
                User Control and Responsibility</u>.  </font>
            </p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">End
                        User has and will retain sole responsibility for: (i)&nbsp;the
                        submission, content and use of all “<b>End User Content</b>”,
                        which means data or content, other than Lucit Content, provided by
                        or for End User, including any Third Party Materials as defined in
                        Section 9 below; (ii) End User’s information technology
                        infrastructure, including computers, software, databases,
                        electronic systems (including database management systems) and
                        networks, whether operated directly by End User or through the use
                        of third-party services (<b>“End User Systems”</b>); (iii) the
                        security and use of End User’s user name, password and any other
                        login information (“<b>Access Credentials</b>”); and (iv) all
                        access to and use of the App, Lucit Content or Services directly or
                        indirectly by or through the End User Systems or End User’s
                        Access Credentials, with or without End User’s knowledge or
                        consent.  </font>
                    </p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Lucit
                        may immediately and without notice remove or disable access to any
                        End User Content that Lucit determines in its sole discretion is in
                        violation of this EULA and, upon notice from Lucit, End User will
                        promptly remove, and cease any further display or other use of, any
                        such End User Content.  End User hereby irrevocably grants to Lucit
                        all rights and permissions in or relating to End User Content as
                        are necessary or useful to enable Lucit to perform the Services,
                        enforce this EULA, and otherwise exercise its rights under this
                        EULA.</font></p>
                    </li></ol>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal">Access
                and Security</span></u></font></font></span></font>. End User will
                employ all physical, administrative, and technical controls,
                screening, and security procedures and other safeguards necessary
                to: (a)&nbsp;securely administer the distribution and use of all
                Access Credentials and protect against any unauthorized access to or
                use of the App or Services; and (b) control the content and use of
                End User Content, including the uploading or other provision of End
                User Content for processing by the Services.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">License
                Restrictions</span></span></u></font></font></span></font>. Except
                as may be expressly permitted in this EULA or in a separate written
                agreement with Lucit, you will not engage in any of the following,
                or permit any other person to do so: </font>
            </p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">use,
                        copy, sell, distribute, post, display, transmit, broadcast or
                        disclose all or any part of the App, Lucit Content or Services;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">modify,
                        translate or adapt the App, Lucit Content or Services, or any part
                        thereof;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">create
                        or attempt to create compilations or derivative works based on the
                        App, Lucit Content or Services, in whole or in part;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">demonstrate,
                        engage in screen scraping, reverse engineer, disassemble,
                        decompile, decode or otherwise attempt to derive or gain access to
                        the source code, object code, system design, underlying structure,
                        ideas or algorithms of the App, Lucit Content or Services, or any
                        part thereof;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">remove,
                        delete, alter or obscure any trademarks or any copyright,
                        trademark, patent or other intellectual property or proprietary
                        rights notices from the App, Lucit Content or Services or any part
                        thereof, including any copy thereof;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">rent,
                        lease, lend, sell, sublicense, assign, distribute, publish,
                        transfer or otherwise make available the App, Lucit Content or
                        Services, or any features or functionality thereof, to any third
                        party for any reason, including by making the App available on a
                        network where it is capable of being accessed by more than one
                        device at any time; using the App or Services for any outsourcing,
                        time-sharing, service bureau, data processing,
                        software-as-a-service, cloud hosting or similar arrangement; or
                        using the App or Services for any other purpose other than your
                        internal benefit; </font>
                    </p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">access
                        or use any part of the App, Lucit Content or Services to create a
                        competing product or service of any nature, to benchmark with a
                        product or service other than Lucit’s, or for any other purpose
                        that is to Lucit’s detriment or commercial disadvantage;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">input,
                        upload, transmit or otherwise provide to or through the App or
                        Services any virus, worm, Trojan horse, disabling device, time bomb
                        or other technology manifesting any contaminating, damaging or
                        disruptive properties;</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">use
                        the App, Lucit Content or Services other than in accordance with
                        this EULA and in compliance with all applicable laws and
                        regulations, including but not limited to any privacy laws, and
                        laws or regulations concerning intellectual property, consumer and
                        child protection, obscenity or defamation; or</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">remove,
                        disable, circumvent or otherwise create or implement any workaround
                        to any copy protection, rights management or security features in
                        or protecting the App, Lucit Content or Services, or any part
                        thereof. </font>
                    </p>
                    </li></ol>
            </li></ol>
        <p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif">The
            parties agree that the foregoing restrictions are to be applied to
            the maximum extent permitted by law.  You further agree that, if you
            believe that reverse engineering or any similar activity is permitted
            under applicable law, you will so advise Lucit at least 30 days
            before engaging in any such activity or permitting any other person
            to do so.  </font>
        </p>
        <ol start="6">
            <li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Reservation
                of Rights</span></span></u></font></font></span></font>. You
                acknowledge and agree that your right to access and use the App,
                Lucit Content and Services is provided under license, and not sold,
                to you. You do not acquire any ownership interest in the App, Lucit
                Content or Services under this EULA, or any other rights thereto
                other than to use the App in accordance with the license granted,
                and subject to all terms, conditions and restrictions, under this
                EULA. Lucit reserves and will retain its entire right, title and
                interest in and to the App, Lucit Content and Services including all
                copyrights, trademarks and other intellectual property rights
                embodied therein or relating thereto.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Collection
                and Use of Your Information</span></span></u></font></font></span></font>.
                You acknowledge that when you download, install or use the App,
                Lucit may use automatic means (including, for example, cookies and
                web beacons) to collect information about your Device and about your
                use of the App. You also may be required to provide certain
                information about yourself as a condition to downloading, installing
                or using the App or certain of its features or functionality.  All
                information we collect through or in connection with this App is
                subject to our Privacy Policy at www.lucit.cc/privacy-policy, as it
                may be amended from time to time in our sole discretion. By
                downloading, installing, using and providing information to or
                through this App, you consent to all actions taken by us with
                respect to your information in compliance with the Privacy Policy.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Updates</span></span></u></font></font></span></font>.
                Lucit may from time to time in its sole discretion develop and
                provide App updates, which may include upgrades, bug fixes, patches,
                other error corrections, or new features (collectively, including
                related documentation, “<b>Updates</b>”). Updates may also
                modify or delete in their entirety certain features and
                functionality. You agree that Lucit has no obligation to provide any
                Updates or to continue to provide or enable any particular features
                or functionality. Based on your Device settings, when your Device is
                connected to the Internet either:</font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">the
                        App will automatically download and install all available Updates;
                        or</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">you
                        may receive notice of or be prompted to download and install
                        available Updates.</font></p>
                    </li></ol>
            </li></ol>
        <p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif">You
            will promptly download and install all Updates and acknowledge and
            agree that the App or portions thereof may not properly operate
            should you fail to do so. You further agree that all Updates will be
            deemed part of the App and be subject to all terms and conditions of
            this EULA.</font></p>
        <ol start="9">
            <li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Third-Party
                Materials</span></span></u></font></font></span></font>. You
                acknowledge and agree that you may use the App or Services to access
                information from third-party service providers (“<b>Third-Party
                    Materials</b>”), in order to populate and use templates to create
                digital billboard displays.  You hereby grant Lucit permission to
                access and use End User Content, including but not limited to
                Third-Party Materials, as needed to provide the Services.  You
                acknowledge and agree that Lucit is not responsible for Third-Party
                Materials, including their accuracy, completeness, timeliness,
                validity, copyright compliance, legality, decency, quality or any
                other aspect thereof. Lucit does not assume and will not have any
                liability or responsibility to you or any other person or entity for
                any Third-Party Materials, and any access to and use of Third-Party
                Materials is entirely at your own risk and subject to such third
                parties&apos; terms and conditions.  ALL THIRD-PARTY MATERIALS ARE
                PROVIDED &quot;AS IS&quot; AND ANY REPRESENTATION OR WARRANTY OF OR
                CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER
                AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY
                MATERIALS.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Term
                and Termination</span></span></u></font></font></span></font>.</font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">The
                        term of this EULA commences when you download or install the App
                        and will continue in effect until terminated by you or Lucit as set
                        forth in this Section 10.</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">You
                        may terminate this EULA by deleting the App and all copies thereof
                        from all Devices on which it has been installed.</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">In
                        addition to Lucit’s right to terminate this EULA as set forth in
                        Section&nbsp;2 above (Acknowledgment), this EULA will terminate at
                        any time without notice if Lucit ceases to support the App, which
                        Lucit may do in its sole discretion.</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Upon
                        termination:</font></p>
                        <ol type="i">
                            <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">all
                                rights granted to you under this EULA will terminate; and</font></p>
                            </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">you
                                must cease all use of the App and delete all copies of the App
                                from all Devices and accounts.</font></p>
                            </li></ol>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Termination
                        will not limit any of Lucit&apos;s rights or remedies at law or in
                        equity.</font></p>
                    </li></ol>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Disclaimer
                of Warranties</span></span></u></font></font></span></font>. </font>
            </p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">THE
                        APP, LUCIT CONTENT AND SERVICES ARE PROVIDED TO END USER “AS IS”
                        AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO
                        THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LUCIT, ON ITS
                        OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
                        RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
                        WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH
                        RESPECT TO THE APP, LUCIT CONTENT OR SERVICES, INCLUDING ALL
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE
                        OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE
                        PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, LUCIT PROVIDES NO
                        WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND,
                        THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED; OR THAT THE
                        APP, LUCIT CONTENT OR SERVICES: (i)&nbsp;WILL NOT BE HACKED;
                        (ii)&nbsp;WILL BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE,
                        OR ERROR FREE; (iii)&nbsp;WILL MEET YOUR REQUIREMENTS; (iv)&nbsp;WILL
                        ACHIEVE ANY INTENDED RESULTS; (v)&nbsp;WILL BE COMPATIBLE OR WORK
                        WITH ANY OTHER SOFTWARE, APPS, SYSTEMS OR SERVICES; (vi)&nbsp;WILL
                        OPERATE WITHOUT INTERRUPTION; OR (vii)&nbsp;WILL MEET ANY
                        PERFORMANCE OR RELIABILITY STANDARDS.</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">SOME
                        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
                        IMPLIED WARRANTIES OR LIMITATIONS ON THE APPLICABLE STATUTORY
                        RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND
                        LIMITATIONS MAY NOT APPLY TO YOU. </font>
                    </p>
                    </li></ol>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Limitation
                of Liability</span></span></u></font></font></span></font>. TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LUCIT
                OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
                SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO
                YOUR USE OF OR INABILITY TO USE THE APP, LUCIT CONTENT OR SERVICES
                FOR:</font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">PERSONAL
                        INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR
                        SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION,
                        COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL,
                        INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES; OR</font></p>
                    </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">DIRECT
                        DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY
                        PAID BY YOU FOR THE SPECIFIC SERVICES FROM WHICH THE CLAIM ARISES. </font>
                    </p>
                    </li></ol>
            </li></ol>
        <p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif">THE
            FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
            BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
            REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR LUCIT WAS
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </font>
        </p>
        <p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif">THE
            PARTIES ACKNOWLEDGE THAT THIS SECTION 12 HAS BEEN INCLUDED AS A
            MATERIAL INDUCEMENT FOR LUCIT TO ENTER INTO THIS EULA AND THAT LUCIT
            WOULD NOT HAVE ENTERED INTO THIS EULA BUT FOR THE LIMITATIONS OF
            LIABILITY AS SET FORTH HEREIN.  </font>
        </p>
        <p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif">SOME
            JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME
            OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. </font>
        </p>
        <ol start="13">
            <li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Indemnification</span></span></u></font></font></span></font>.
                You agree to indemnify, defend and hold harmless Lucit and its
                officers, directors, employees, agents, affiliates, successors and
                assigns from and against any and all losses, damages, liabilities,
                deficiencies, claims, actions, judgments, settlements, interest,
                awards, penalties, fines, costs or expenses of whatever kind,
                including reasonable attorneys&apos; fees, arising from or relating to
                your use or misuse of the App or Services, or your breach of this
                EULA.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Export
                Regulation</span></span></u></font></font></span></font>. The App
                may be subject to U.S. export control laws, including the Export
                Control Reform Act and its associated regulations. You will not,
                directly or indirectly, export, re-export or release the App to, or
                make the App accessible from, any jurisdiction or country to which
                export, re-export or release is prohibited by law, rule or
                regulation. You will comply with all applicable federal laws,
                regulations and rules, and complete all required undertakings
                (including obtaining any necessary export license or other
                governmental approval), prior to exporting, re-exporting, releasing
                or otherwise making the App available outside the U.S.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">U.S.
                Government Rights</span></span></u></font></font></span></font>. The
                App is commercial computer software, as such term is defined in 48
                C.F.R. §2.101. Accordingly, if you are an agency of the U.S.
                Government or any contractor therefor, you receive only those rights
                with respect to the App as are granted to all other end users under
                license, in accordance with (a) 48 C.F.R. §227.7201 through 48
                C.F.R. §227.7204, with respect to the Department of Defense and
                their contractors, or (b) 48 C.F.R. §12.212, with respect to all
                other U.S. Government licensees and their contractors. </font>
            </p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Severability</span></span></u></font></font></span></font>.
                If any provision of this EULA is determined to be invalid or
                unenforceable in any jurisdiction, the other provisions herein will
                remain in full force and effect in such jurisdiction and will be
                liberally construed in order to effectuate the purpose and intent of
                this EULA.  The invalidity or unenforceability of any provision of
                this EULA in any jurisdiction will not affect the validity or
                enforceability of any such provision in any other jurisdiction.  In
                the event that any court of competent jurisdiction determines that
                any provision of this EULA is unreasonable or otherwise
                unenforceable for any reason, then it is the intention of the
                parties that such provision will be enforced to the fullest extent
                that the court deems reasonable, and the EULA will thereby be
                reformed.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Governing
                Law</span></span></u></font></font></span></font>. This EULA will be
                governed by and construed in accordance with the laws of the State
                of North Dakota, excluding its conflict of laws rules.  The
                exclusive forum and venue for any action between the parties will be
                the federal and state courts located in Burleigh County, North
                Dakota.  You hereby consent to accept the jurisdiction of said
                courts, waive any objection to personal jurisdiction or venue, or
                based on forum non conveniens, and agree to accept service of
                process by mail. </font>
            </p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Limitation
                of Time to File Claims</span></span></u></font></font></span></font>.
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
                TO THIS EULA, THE APP, THE LUCIT CONTENT OR THE SERVICES MUST BE
                COMMENCED WITHIN 365 DAYS AFTER THE CAUSE OF ACTION ACCRUES OR
                OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Entire
            </span></span></u></font></font></span></font><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal">Agreement</span></u></font></font></span></font>.
                This EULA, our Privacy Policy and, if you are an Operator or an
                employee, agent or representative thereof, any non-conflicting terms
                in the applicable Services Agreement constitute the entire agreement
                between you and Lucit with respect to the App, Lucit Content and
                Services, and supersede all prior or contemporaneous understandings
                and agreements, whether written or oral, with respect thereto.  You
                acknowledge and agree that, in entering into this EULA, you have not
                relied upon any statement, representation, warranty or agreement,
                whether by Lucit or any third party (including a representative of
                any Operator), except for those expressly contained in this EULA.<font face="Arial, sans-serif">
                </font></font>
            </p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Waiver</span></span></u></font></font></span></font>.
                No amendment, modification, termination or waiver of any provision
                of this EULA will be effective unless the same will be in writing
                and signed by a duly authorized representative of each party.  No
                failure or delay on the part of Lucit in exercising any right, power
                or remedy hereunder will operate as a waiver thereof.  </font>
            </p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><font><span styles="text-decoration: none"><font face="Times New Roman, serif"><font size="3"><u><span styles="font-weight: normal"><span styles="background: #ffffff">Survival</span></span></u></font></font></span></font>.
                Those provisions of this EULA that by their terms, sense or nature
                survive any termination of this EULA will so survive in accordance
                with their terms.</font></p>
            </li><li><p styles="margin-top: 0.17in; margin-bottom: 0.17in"><font face="Times New Roman, serif"><u>Notification
                of Copyright Infringement</u>.</font></p>
                <ol>
                    <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">It
                        is our policy to fully comply with the Digital Millennium Copyright
                        Act (the “DMCA”) and applicable regulations. Although we assume
                        no obligation to police content or screens published, displayed or
                        generated using the App or Services for the presence of materials
                        that may be infringing or are otherwise inappropriate, we reserve
                        the right in our sole discretion to remove or disable access to any
                        content, and we will terminate the account of a repeat infringer in
                        appropriate circumstances. A copyright owner or representative
                        thereof may report alleged copyright infringement by sending a
                        notice that complies with the DMCA to our designated agent to
                        receive notification of claimed infringement (the “Designated
                        Agent”) at the following address:</font></p>
                        <p styles="margin-left: 1.5in; margin-bottom: 0in; background: #ffffff">
                            <font face="Times New Roman, serif">Lucit LLC <br />314 E. Thayer Ave,
                                Suite 220<br />Bismarck, ND 58501<br />Telephone: 701-415-3500<br />Email:
                                support@lucit.cc</font></p>
                    </li></ol>
            </li></ol>

        <ol>
            <ol start="2">
                <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">We
                    reserve the right to change our Designated Agent without prior
                    notice, subject to the applicable requirements of the DMCA and any
                    applicable regulations.</font></p>
                </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">All
                    notices of alleged copyright infringement should include
                    substantially the following:</font></p>
                    <ol type="i">
                        <li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">A
                            description of the copyrighted work claimed to have been
                            infringed, or, if multiple copyrighted works at a single online
                            website are covered by a single notification, a representative
                            list of such works at that site.</font></p>
                        </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">A
                            description of the material that the copyright owner claims to be
                            infringing or to be the subject of infringing activity and that is
                            to be removed or access to which is to be disabled, and
                            information reasonably sufficient to permit us to locate the
                            material.</font></p>
                        </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Information
                            reasonably sufficient to permit us to contact the copyright owner
                            (or representative thereof), such as an address, telephone number
                            and, if available, an electronic mail address.</font></p>
                        </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">A
                            statement that the copyright owner (or representative thereof) has
                            a good faith belief that use of the material in the manner
                            complained of is not authorized by the copyright owner, the
                            owner’s agent, or by law.</font></p>
                        </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">A
                            statement, signed by physical or electronic signature, that the
                            information in the notification is accurate and, under penalty of
                            perjury, that the copyright owner is, or the representative
                            thereof is authorized to act on behalf of, the owner of an
                            exclusive right that is allegedly infringed.</font></p>
                        </li></ol>
                </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">Our
                    Designated Agent may request additional information from the
                    copyright owner (or representative thereof).</font></p>
                </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">PURSUANT
                    TO THE DMCA, 17 U.S.C. § 512(f), ANY PERSON WHO KNOWINGLY MAKES A
                    MATERIAL MISREPRESENTATION THAT MATERIAL IS INFRINGING, OR THAT
                    MATERIAL WAS REMOVED OR DISABLED BY MISTAKE OR MISIDENTIFICATION,
                    MAY BE LIABLE FOR ANY DAMAGES, INCLUDING COSTS AND ATTORNEYS’
                    FEES, INCURRED BY A SERVICE PROVIDER WHO IS INJURED BY SUCH
                    MISREPRESENTATION, OR BY THE COPYRIGHT OWNER OR LICENSEE, AS THE
                    RESULT OF THE SERVICE PROVIDER RELYING UPON SUCH MISREPRESENTATION
                    IN REMOVING OR DISABLING ACCESS TO SUCH MATERIAL, OR IN REPLACING
                    OR CEASING TO DISABLE ACCESS TO THE MATERIAL.</font></p>
                </li><li><p styles="margin-top: 0.08in; margin-bottom: 0.17in"><font face="Times New Roman, serif">For
                    a full description of your rights under the DMCA and the procedures
                    that must be followed, please see the full text of the DMCA, which
                    may be found at www.copyright.gov. Nothing in this EULA is intended
                    to supplant the requirements of the DMCA. In the event of any
                    conflict between any provision of this EULA and the DMCA, the
                    applicable provision of the DMCA will control.</font></p>
                </li></ol>
        </ol>
    </div>;
}

export default EulaText;