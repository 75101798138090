import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, TextField, Box } from '@material-ui/core';
import { IonButton } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import { Dialog, ButtonLoader } from '../../components/material';
import { trafficReject } from '../../actions/exports';

const RejectButton = ({ exportItem, trafficReject, ...rest }) => {
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [comments, setComments] = useState('');

    return <><IonButton
        variant="contained"
        color="secondary"
        size="small"
        {...rest}
        onClick={() => setOpen(true)}
    >{t('Reject')}</IonButton>

        <Dialog
            open={open}
            title={t('Reject')}
        >
            <DialogContent>
                <Box>
                    <LCTypography variant="body1">
                        Reject this campaign with a message back to the submitter.
                    </LCTypography>
                </Box>

                <Box mt={3} mb={5}>
                    <TextField
                        placeholder="Rejection Comments"
                        onChange={(e) => setComments(e.target.value)}
                        value={comments}
                        multiline
                        autoFocus
                        rows={4}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                >
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitting(true);
                        trafficReject(exportItem.id, comments).then(() => {
                            setSubmitting(false);
                            setOpen(false);
                        })
                    }}
                    submitting={submitting}
                >{t('Reject')}</ButtonLoader>
            </DialogActions>
        </Dialog>

    </>

}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trafficReject: (exportId, comments) => dispatch(trafficReject(exportId, comments))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RejectButton)
    );
