import {
    DRIVE_TEMPLATE_ELEMENT_INDEX_FOR_EDIT, DRIVE_TEMPLATE_ELEMENT_ADD, DRIVE_TEMPLATE_ELEMENT_UPDATE,DRIVE_TEMPLATE_ELEMENT_MOVE

} from "../helpers/actionTypes";
import { lucitApi } from "../services/lucitApi"

export function getDriveTemplateElementsForEdit(filters = {}) {

    return dispatch => {
        return lucitApi.driveTemplateElements.indexForEdit(filters)
            .then(driveTemplateElements => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_INDEX_FOR_EDIT,
                    payload: driveTemplateElements.data
                })
            })
    }
}

export function storeBasicShape(params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.storeBasicShape(params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_ADD,
                    payload: driveTemplateElement
                })
            })
    }
}

export function storeBasicShapeImage(params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.storeBasicShapeImage(params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_ADD,
                    payload: driveTemplateElement
                })
            })
    }
}

export function storeBackground(params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.storeBackground(params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_ADD,
                    payload: driveTemplateElement
                })
            })
    }
}

export function storeBackgroundImage(params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.storeBackgroundImage(params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_ADD,
                    payload: driveTemplateElement
                })
            })
    }
}

export function storeText(params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.storeText(params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_ADD,
                    payload: driveTemplateElement
                })
            })
    }
}

export function updateDriveTemplateElement(driveTemplateElementId, params) {

    return dispatch => {
        return lucitApi.driveTemplateElements.update(driveTemplateElementId, params)
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_UPDATE,
                    payload: driveTemplateElement
                })
            })
    }
}

export function moveElementBefore(driveTemplateElementId,targetElementLcuid) {

    return dispatch => {
        return lucitApi.driveTemplateElements.move(driveTemplateElementId,targetElementLcuid,"before")
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_MOVE,
                    payload: {
                        driveTemplateElement : driveTemplateElement,
                    }
                })
            })
    }
}

export function moveElementAfter(driveTemplateElementId,targetElementLcuid) {

    return dispatch => {
        return lucitApi.driveTemplateElements.move(driveTemplateElementId,targetElementLcuid,"after")
            .then(driveTemplateElement => {
                return dispatch({
                    type: DRIVE_TEMPLATE_ELEMENT_MOVE,
                    payload: {
                        driveTemplateElement : driveTemplateElement,
                    }
                })
            })
    }
}
