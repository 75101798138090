/**
* ***
* *** LuCore Constants File *****
* *** Do Not Edit *****
* *** Rebuild with luc config:output-js *****
* ***
*/
/* eslint-disable no-useless-escape */
export class AgencyPolicy {
    static deleteUser = {
        "model": "agency",
        "policy": "deleteUser",
        "fully_qualified_policy": "agency.deleteUser",
        "show_for_third_party_apps": false,
        "explanation": "Can delete users from this operator"
    };
    static editUserRoles = {
        "model": "agency",
        "policy": "editUserRoles",
        "fully_qualified_policy": "agency.editUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can edit user roles for this application"
    };
    static inviteUserRoles = {
        "model": "agency",
        "policy": "inviteUserRoles",
        "fully_qualified_policy": "agency.inviteUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can invite users to this operator with specified roles"
    };
    static canApproveAds = {
        "model": "agency",
        "policy": "canApproveAds",
        "fully_qualified_policy": "agency.canApproveAds",
        "show_for_third_party_apps": true,
        "explanation": "Can approve ads for this operator"
    };
    static viewUserRoles = {
        "model": "agency",
        "policy": "viewUserRoles",
        "fully_qualified_policy": "agency.viewUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can view the roles attached to users of this operator"
    };
    static viewAnalytics = {
        "model": "agency",
        "policy": "viewAnalytics",
        "fully_qualified_policy": "agency.viewAnalytics",
        "show_for_third_party_apps": true,
        "explanation": "Can view stats for this operator and its boards"
    };
    static viewBoards = {
        "model": "agency",
        "policy": "viewBoards",
        "fully_qualified_policy": "agency.viewBoards",
        "show_for_third_party_apps": true,
        "explanation": "Can view the list of boards for this operator"
    };
    static canEditBoards = {
        "model": "agency",
        "policy": "canEditBoards",
        "fully_qualified_policy": "agency.canEditBoards",
        "show_for_third_party_apps": true,
        "explanation": "Can edit boards for this operator"
    };
    static canSetBoardImages = {
        "model": "agency",
        "policy": "canSetBoardImages",
        "fully_qualified_policy": "agency.canSetBoardImages",
        "show_for_third_party_apps": true,
        "explanation": "Can set board images for this operators screens"
    };
    static update = {
        "model": "agency",
        "policy": "update",
        "fully_qualified_policy": "agency.update",
        "show_for_third_party_apps": false,
        "explanation": "Can make changes to the information on this operator"
    };
    static updateDeleteAllAgencyAccounts = {
        "model": "agency",
        "policy": "updateDeleteAllAgencyAccounts",
        "fully_qualified_policy": "agency.updateDeleteAllAgencyAccounts",
        "show_for_third_party_apps": true,
        "explanation": "Access all accounts that are owned by this agency including update,creating content, delete and other operations"
    };
    static canJoinAgencyAccounts = {
        "model": "agency",
        "policy": "canJoinAgencyAccounts",
        "fully_qualified_policy": "agency.canJoinAgencyAccounts",
        "show_for_third_party_apps": false,
        "explanation": "Can automatically join any agency accounts"
    };
    static canEditPayments = {
        "model": "agency",
        "policy": "canEditPayments",
        "fully_qualified_policy": "agency.canEditPayments",
        "show_for_third_party_apps": false,
        "explanation": "Can add and remove payment methods / cards"
    };
    static createAccounts = {
        "model": "agency",
        "policy": "createAccounts",
        "fully_qualified_policy": "agency.createAccounts",
        "show_for_third_party_apps": true,
        "explanation": "Can create accounts attached to this operator"
    };
    static viewCampaigns = {
        "model": "agency",
        "policy": "viewCampaigns",
        "fully_qualified_policy": "agency.viewCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can view campaigns attached to boards for this operator"
    };
    static autoAddToCampaigns = {
        "model": "agency",
        "policy": "autoAddToCampaigns",
        "fully_qualified_policy": "agency.autoAddToCampaigns",
        "show_for_third_party_apps": false,
        "explanation": "Can auto add to campaigns when pulling"
    };
    static viewAccounts = {
        "model": "agency",
        "policy": "viewAccounts",
        "fully_qualified_policy": "agency.viewAccounts",
        "show_for_third_party_apps": true,
        "explanation": "Can view accounts that belong to this operator"
    };
    static createCampaigns = {
        "model": "agency",
        "policy": "createCampaigns",
        "fully_qualified_policy": "agency.createCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can create campaigns attached to boards for this operator"
    };
    static pullCampaigns = {
        "model": "agency",
        "policy": "pullCampaigns",
        "fully_qualified_policy": "agency.pullCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can execute a campaign pull against this operators boards"
    };
    static publishAnalytics = {
        "model": "agency",
        "policy": "publishAnalytics",
        "fully_qualified_policy": "agency.publishAnalytics",
        "show_for_third_party_apps": true,
        "explanation": "Can publish stats for this operator and its boards"
    };
    static viewAdminUsers = {
        "model": "agency",
        "policy": "viewAdminUsers",
        "fully_qualified_policy": "agency.viewAdminUsers",
        "show_for_third_party_apps": false,
        "explanation": "Can view the admins in a list of users on an operator"
    };
    static canAddPayments = {
        "model": "agency",
        "policy": "canAddPayments",
        "fully_qualified_policy": "agency.canAddPayments",
        "show_for_third_party_apps": false,
        "explanation": "If a user can add payment objects manually (Via Lac) - Admin Only"
    };
    static view = {
        "model": "agency",
        "policy": "view",
        "fully_qualified_policy": "agency.view",
        "show_for_third_party_apps": true,
        "explanation": "Can view basic details about this operator"
    };
    static delete = {
        "model": "agency",
        "policy": "delete",
        "fully_qualified_policy": "agency.delete",
        "show_for_third_party_apps": false,
        "explanation": "Can delete this operator"
    };
    static viewPrivateStories = {
        "model": "agency",
        "policy": "viewPrivateStories",
        "fully_qualified_policy": "agency.viewPrivateStories",
        "show_for_third_party_apps": false,
        "explanation": "Can view private stories for this object"
    };
}

export class AccountPolicy {
    static deleteUser = {
        "model": "account",
        "policy": "deleteUser",
        "fully_qualified_policy": "account.deleteUser",
        "show_for_third_party_apps": false,
        "explanation": "Can delete user from this account"
    };
    static editUserRoles = {
        "model": "account",
        "policy": "editUserRoles",
        "fully_qualified_policy": "account.editUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can edit user roles for this application"
    };
    static inviteUsers = {
        "model": "account",
        "policy": "inviteUsers",
        "fully_qualified_policy": "account.inviteUsers",
        "show_for_third_party_apps": false,
        "explanation": "Can invite users to this account"
    };
    static inviteUserRoles = {
        "model": "account",
        "policy": "inviteUserRoles",
        "fully_qualified_policy": "account.inviteUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can invite users to this account with specified roles"
    };
    static update = {
        "model": "account",
        "policy": "update",
        "fully_qualified_policy": "account.update",
        "show_for_third_party_apps": false,
        "explanation": "Can update this account"
    };
    static canEditPayments = {
        "model": "account",
        "policy": "canEditPayments",
        "fully_qualified_policy": "account.canEditPayments",
        "show_for_third_party_apps": false,
        "explanation": "If a user can add and remove payment methods / cards"
    };
    static viewAllUsersStats = {
        "model": "account",
        "policy": "viewAllUsersStats",
        "fully_qualified_policy": "account.viewAllUsersStats",
        "show_for_third_party_apps": false,
        "explanation": "Can this user view all stats for this account? Or just their own"
    };
    static viewAnalytics = {
        "model": "account",
        "policy": "viewAnalytics",
        "fully_qualified_policy": "account.viewAnalytics",
        "show_for_third_party_apps": true,
        "explanation": "Can view analytics for this account"
    };
    static viewCampaigns = {
        "model": "account",
        "policy": "viewCampaigns",
        "fully_qualified_policy": "account.viewCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can view campaigns for this account"
    };
    static createFeeds = {
        "model": "account",
        "policy": "createFeeds",
        "fully_qualified_policy": "account.createFeeds",
        "show_for_third_party_apps": true,
        "explanation": "Can create data sources for this account"
    };
    static viewFeeds = {
        "model": "account",
        "policy": "viewFeeds",
        "fully_qualified_policy": "account.viewFeeds",
        "show_for_third_party_apps": true,
        "explanation": "Can view data sources for this account"
    };
    static viewContent = {
        "model": "account",
        "policy": "viewContent",
        "fully_qualified_policy": "account.viewContent",
        "show_for_third_party_apps": true,
        "explanation": "Can view data for this account"
    };
    static createCampaigns = {
        "model": "account",
        "policy": "createCampaigns",
        "fully_qualified_policy": "account.createCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can create campaigns for this account"
    };
    static editCampaigns = {
        "model": "account",
        "policy": "editCampaigns",
        "fully_qualified_policy": "account.editCampaigns",
        "show_for_third_party_apps": true,
        "explanation": "Can edit campaigns for this account"
    };
    static createContent = {
        "model": "account",
        "policy": "createContent",
        "fully_qualified_policy": "account.createContent",
        "show_for_third_party_apps": true,
        "explanation": "Can add posts / photos, etc. to this account"
    };
    static editCreativeTriggers = {
        "model": "account",
        "policy": "editCreativeTriggers",
        "fully_qualified_policy": "account.editCreativeTriggers",
        "show_for_third_party_apps": false,
        "explanation": "Can edit creative triggers for this account (Dates/Times, Triggers, Screens)"
    };
    static createFullSizeCreatives = {
        "model": "account",
        "policy": "createFullSizeCreatives",
        "fully_qualified_policy": "account.createFullSizeCreatives",
        "show_for_third_party_apps": false,
        "explanation": "Can create full size creatives for this account"
    };
    static createCreativesFromTemplates = {
        "model": "account",
        "policy": "createCreativesFromTemplates",
        "fully_qualified_policy": "account.createCreativesFromTemplates",
        "show_for_third_party_apps": false,
        "explanation": "Can create creatives from templates for this account"
    };
    static viewDriveTemplates = {
        "model": "account",
        "policy": "viewDriveTemplates",
        "fully_qualified_policy": "account.viewDriveTemplates",
        "show_for_third_party_apps": false,
        "explanation": "Can view drive templates on this account"
    };
    static editDriveTemplates = {
        "model": "account",
        "policy": "editDriveTemplates",
        "fully_qualified_policy": "account.editDriveTemplates",
        "show_for_third_party_apps": false,
        "explanation": "Can edit drive templates on this account"
    };
    static view = {
        "model": "account",
        "policy": "view",
        "fully_qualified_policy": "account.view",
        "show_for_third_party_apps": true,
        "explanation": "A user can view this account"
    };
    static viewDefaultCreative = {
        "model": "account",
        "policy": "viewDefaultCreative",
        "fully_qualified_policy": "account.viewDefaultCreative",
        "show_for_third_party_apps": true,
        "explanation": "Can view default creatives for this account"
    };
    static viewAdminUsers = {
        "model": "account",
        "policy": "viewAdminUsers",
        "fully_qualified_policy": "account.viewAdminUsers",
        "show_for_third_party_apps": false,
        "explanation": "Can view the admins in a list of users on an account"
    };
    static canAddPayments = {
        "model": "account",
        "policy": "canAddPayments",
        "fully_qualified_policy": "account.canAddPayments",
        "show_for_third_party_apps": false,
        "explanation": "If a user can add payment objects manually (Via Lac) - Admin Only"
    };
    static viewUserRoles = {
        "model": "account",
        "policy": "viewUserRoles",
        "fully_qualified_policy": "account.viewUserRoles",
        "show_for_third_party_apps": false,
        "explanation": "Can view the roles attached to users of this account"
    };
    static delete = {
        "model": "account",
        "policy": "delete",
        "fully_qualified_policy": "account.delete",
        "show_for_third_party_apps": false,
        "explanation": "Can delete this account"
    };
    static viewPrivateStories = {
        "model": "account",
        "policy": "viewPrivateStories",
        "fully_qualified_policy": "account.viewPrivateStories",
        "show_for_third_party_apps": false,
        "explanation": "Can view private stories for this object"
    };
    static editApps = {
        "model": "account",
        "policy": "editApps",
        "fully_qualified_policy": "account.editApps",
        "show_for_third_party_apps": false,
        "explanation": "Can edit the apps and data sources for this account"
    };
}
