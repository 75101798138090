import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TextOutlineIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 1080 1080">
        <defs>
            <style>{`
                .cls-text-outline {
                    fill: none;
                    stroke: #000;
                    stroke-linejoin: round;
                    stroke-width: 24px;
      }
            `}</style>
        </defs>
        <polygon className="cls-text-outline" points="46.72 1017.5 480.11 62.5 603.04 62.5 1033.28 1017.5 894.6 1017.5 779.55 759.65 303.6 759.65 188.55 1017.5 46.72 1017.5" />
        <polygon className="cls-text-outline" points="352.46 650.51 730.69 650.51 541.58 226.22 352.46 650.51" />
    </SvgIcon>
}
