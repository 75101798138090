export const LOADER_SHOW = "loader/show";
export const LOADER_HIDE = "loader/hide";
export const LOADER_TOOGLE = "loader/toggle";

export const DASHBOARD_ACTIVE_ADS_REQUEST = "dashboard/activeAdsRequest";
export const DASHBOARD_ACTIVE_ADS = "dashboard/activeAds";
export const DASHBOARD_LOCATIONS_REQUEST = "dashboard/locationsRequest";
export const DASHBOARD_LOCATIONS = "dashboard/locations";
export const DASHBOARD_EXPORTS_REQUEST = "dashboard/exportsRequest";

export const SCREENS_SOFTWARE_RESULT = "screens/softwareResult";
export const SCREENS_HARDWARE_RESULT = "screens/hardwareResult";
export const SCREENS_FACING_CARDINALS_RESULT = "screens/facingCardinalsResult";
export const SCREENS_ITEM_RESULT = "screens/itemResult";
export const SCREENS_UPDATE_ITEM = "screens/updateItem";
export const SCREENS_DELETE = "screens/delete";
export const SCREENS_PLAY_STATUSES = "screens/playStatuses";
export const SCREENS_PLAY_STATUS = "screens/playStatus";
export const SCREENS_IMAGES_RESULT = "screens/screenImagesResult";
export const SCREENS_IMAGES_NEW = "screens/screenImagesNew";
export const SCREENS_IMAGES_UPDATE = "screens/screenImagesUpdate";
export const SCREENS_IMAGES_DELETE = "screens/screenImagesDelete";
export const SCREENS_IMAGES_SET_AS_MAIN = "screens/screenImagesSetAsMain";
export const SCREENS_STREET_VIEW_NEW = "screens/streeViewNew";
export const SCREENS_STREET_VIEW_STATUS = "screens/streeViewStatus";
export const SCREENS_GET_STREET_VIEW = "screens/getStreeView";

export const SNACKBAR_SHOW = "snackbar/show";
export const SNACKBAR_HIDE = "snackbar/hide";
export const SNACKBAR_SET_ACTIVE = "snackbar/setActive";
export const SNACKBAR_UPDATE_ACTIVE = "snackbar/updateActive";

export const SYSTEM_NOTIFICATION_SHOW = "systemNotification/show";
export const SYSTEM_NOTIFICATION_HIDE = "systemNotification/hide";
export const SYSTEM_NOTIFICATION_REMOVE = "systemNotification/remove";

export const ENVIRONMENT_LOAD_RESULT = 'environment/loadResult';

export const APPLICATION_BYID = "application/byId";
export const APPLICATION_NEW = "application/new";
export const APPLICATION_UPDATE = "application/update";
export const APPLICATION_REVIEW = "application/review";
export const APPLICATION_DELETE = "application/delete";
export const APPLICATION_SET_IMAGE = "application/setImage";
export const APPLICATION_REMOVE_IMAGE = "application/removeImage";
export const APPLICATION_ADD_CAPABILITY = "application/addCapability";
export const APPLICATION_REMOVE_CAPABILITY = "application/removeCapability";
export const APPLICATION_BOTS = "application/bots";
export const APPLICATION_NEW_BOT = "application/newBot";
export const APPLICATION_DELETE_BOT = "application/deleteBot";

export const USER_LOGIN = "user/login";
export const USER_LOGIN_MFA = "user/loginMfa";
export const USER_LOGIN_MFA_RESET = "user/loginMfaReset";
export const USER_LOGOUT = "user/logout";
export const USER_INFO = "user/info";
export const USER_UPDATE = "user/update";
export const USER_SWITCHING_PROFILE = "user/switchingProfile";
export const USER_SELECT_USER = "user/selectUser";
export const USER_SELECT_ACCOUNT = "user/selectAccount";
export const USER_SELECT_AGENCY = "user/selectAgency";
export const USER_NEW_UPDATE = "user/newUpdate";
export const USER_SET_TIMEZONE = "user/setTimezone";
export const USER_SW_INITIALIZED = "user/swInitialized";
export const USER_CONNECTION_STATUS_CHANGE = "user/connectionStatusChange";
export const USER_PUSH_NOTIFICATIONS_TOKEN = "user/pushNotificationsToken";
export const USER_ACCOUNT_SET_IMAGE = "user/accountSetImage";
export const USER_ACCOUNT_REMOVE_IMAGE = "user/accountRemoveImage";
export const USER_AGENCY_SET_IMAGE = "user/agencySetImage";
export const USER_AGENCY_REMOVE_IMAGE = "user/agencyRemoveImage";
export const USER_SET_IMAGE = "user/setImage";
export const USER_REMOVE_IMAGE = "user/removeImage";
export const USER_UPDATE_TOUR = "user/updateTour";
export const USER_ACCEPT_EULA = "user/acceptEula";
export const USER_DEVELOPER_MODE = "user/developerMode";
export const USER_STORE_PERMISSIONS = "user/storePermissions";

export const PAYMENT_METHODS_CARDS_REQUEST = "paymentMethods/cardsRequest";
export const PAYMENT_METHODS_CARDS_RESULT = "paymentMethods/cardsResult";
export const PAYMENT_METHODS_ADD_CARD = "paymentMethods/addCard";
export const PAYMENT_METHODS_REMOVE_CARD = "paymentMethods/removeCard";
export const PAYMENT_METHODS_SET_PRIMARY_CARD = "paymentMethods/setPrimaryCard";
export const BILLING_INVOICES_RESULT = "billing/invoicesResult";
export const BILLING_PLANS_RESULT = "billing/plansResult";

export const REGISTER_CHANGE_TYPE = "register/changeType";
export const REGISTER_EMAILCODE = "register/emailCode";
export const REGISTER_PHONECODE = "register/phoneCode";
export const REGISTER_EDIT_PHONE = "register/phoneEdit";
export const REGISTER_SET_REGISTRATION = "register/setRegistration";
export const REGISTER_RESET = "register/reset";

export const FORGOTPASSWORD_SET = "forgot/set";
export const FORGOTPASSWORD_RESET = "forgot/reset";

export const INVENTORY_SEARCH_REQUEST = "inventory/searchRequest";
export const INVENTORY_SEARCH_RESULT = "inventory/searchResult";
export const INVENTORY_INVALIDATE = "inventory/invalidate";
export const INVENTORY_ITEM_RESULT = "inventory/itemResult";
export const INVENTORY_ITEM_SELECT = "inventory/itemSelect";
export const INVENTORY_SELECT_ALL = "inventory/selectAll";
export const INVENTORY_UNSELECT_ALL = "inventory/unselectAll";
export const INVENTORY_SAVE_SCROLL_POSITION = "inventory/saveScrollPosition";
export const INVENTORY_NEXT_PAGE_REQUEST = "inventory/nextPageRequest";
export const INVENTORY_NEXT_PAGE_RESULT = "inventory/nextPageResult";
export const INVENTORY_GETITEM_RESULT = "inventory/getItemResult";
export const INVENTORY_SET_PRICE_OVERRIDE = "inventory/setPriceOverride";
export const INVENTORY_CLEAR_PRICE_OVERRIDE = "inventory/clearPriceOverride";
export const INVENTORY_SET_TITLE_OVERRIDE = "inventory/setTitleOverride";
export const INVENTORY_CLEAR_TITLE_OVERRIDE = "inventory/clearTitleOverride";
export const INVENTORY_NEW_ITEM = "inventory/newItem";
export const INVENTORY_ACTIVATE_DRAFT = "inventory/activteDraft";
export const INVENTORY_DELETE_ITEM = "inventory/deleteItem";
export const INVENTORY_NEW_ITEM_PHOTO = "inventory/newItemPhoto";
export const INVENTORY_ITEM_REMOVE_PHOTO = "inventory/itemRemovePhoto";
export const INVENTORY_ITEM_UPDATE = "inventory/itemUpdate";
export const INVENTORY_BOARD_SIZES_RESULT = "inventory/boardSizesResult";

export const INVENTORYSTATUS_ITEM_STATUS_RESULT = "inventoryStatus/itemStatusResult";
export const INVENTORYSTATUS_EXPORT_ITEMS_STATUS_REQUEST = "inventoryStatus/exportItemsStatusRequest";
export const INVENTORYSTATUS_EXPORT_ITEMS_STATUS_RESULT = "inventoryStatus/exportItemsStatusResult";
export const INVENTORYSTATUS_EXPORT_ITEMS_REFRESH_RESULT = "inventoryStatus/exportItemsRefreshResult";
export const INVENTORYSTATUS_EXPORT_ITEMS = "inventoryStatus/exportItems";
export const INVENTORYSTATUS_UNEXPORT_ITEMS = "inventoryStatus/unexportItems";
export const INVENTORYSTATUS_RESET_FILTERS = "inventoryStatus/resetFilters";
export const INVENTORYSTATUS_SET_ITEMS_WEIGHTS = "inventoryStatus/setItemsWeights";

export const EXPORTS_GET_RESULT = "exports/getResult";
export const EXPORTS_GETBYID_RESULT = "exports/getByIdResult";
export const EXPORTS_SEARCH_REQUEST = "exports/searchRequest";
export const EXPORTS_SEARCH_RESULT = "exports/searchResult";
export const EXPORTS_NEXT_PAGE_REQUEST = "exports/nextPageRequest";
export const EXPORTS_NEXT_PAGE_RESULT = "exports/nextPageResult";
export const EXPORTS_SAVE_SCROLL_POSITION = "exports/saveScrollPosition";
export const EXPORTS_RUN = "exports/run";
export const EXPORTS_STATUS_UPDATE = "exports/statusUpdate";
export const EXPORTS_ADD_APX_CONTRACT = "exports/addApxContract";
export const EXPORTS_DELETE_APX_CONTRACT = "exports/deleteApxContract";
export const EXPORTS_ADD_VISTAR_INSERTION_ORDER_MAP = "exports/addVistarInsertionOrderMap";
export const EXPORTS_DELETE_VISTAR_INSERTION_ORDER_MAP = "exports/deleteVistarInsertionOrderMap";
export const EXPORTS_ADD_PX_DEAL_MAP = "exports/addPxDealMap";
export const EXPORTS_DELETE_PX_DEAL_MAP = "exports/deletePxDealMap";
export const EXPORTS_SET_BOARD_IDS = "exports/setBoardIds";
export const EXPORTS_DELETE = "exports/exportDelete";
export const EXPORTS_UPDATE = "exports/exportUpdate";
export const EXPORTS_CAMPAIGN_STATE_CHANGE = "exports/campaignStateChange";

export const FEEDS_SEARCH_REQUEST = "feeds/searchRequest";
export const FEEDS_SEARCH_RESULT = "feeds/searchResult";
export const FEEDS_NEXT_PAGE_REQUEST = "feeds/nextPageRequest";
export const FEEDS_NEXT_PAGE_RESULT = "feeds/nextPageResult";
export const FEEDS_SAVE_SCROLL_POSITION = "feeds/saveScrollPosition";
export const FEEDS_RUN = "feeds/run";
export const FEEDS_GETBYID_RESULT = "feeds/getByIdResult";
export const FEEDS_STATUS_UPDATE = "exports/statusUpdate";
export const FEEDS_SET_DEFAULT_TEMPLATE = "feeds/setDefaultTemplate";
export const FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES = "feeds/setMultipleDriveTemplates";
export const FEEDS_UPDATE_PARAM_VALUES = "feeds/updateParamValues";
export const FEEDS_UPDATE_OVERRIDE_VALUES = "feeds/updateOverrideValues";
export const FEEDS_DELETE = "feeds/delete";
export const FEEDS_UPDATE = "feeds/update";

export const CAMPAIGNS_PENDING_TRAFFIC_COUNT_RESULT = "agency/campaigns/pendingTrafficCount";

export const APPROVALS_COUNT_RESULT = "agency/approvals/count";
export const APPROVALS_SEARCH_REQUEST = "agency/approvals/searchRequest";
export const APPROVALS_SEARCH_RESULT = "agency/approvals/searchResult";
export const APPROVALS_NEXT_PAGE_REQUEST = "agency/approvals/nextPageRequest";
export const APPROVALS_NEXT_PAGE_RESULT = "agency/approvals/nextPageResult";
export const APPROVALS_SAVE_SCROLL_POSITION = "agency/approvals/saveScrollPosition";
export const APPROVALS_REJECTION_CODES_LOADED = "agency/approvals/rejectionCodesLoaded";
export const APPROVALS_TOGGLE_ACTION = "agency/approvals/toggleAction";
export const APPROVALS_STATUS_CHANGED = "agency/approvals/statusChanged";
export const APPROVALS_FORCE_APPROVE_MODERATION_LCUID = 'agency/approvals/forceApproveModeration'

export const ROLES_REQUEST = "roles/request";
export const ROLES_RESULT = "roles/result";

export const ACCOUNT_ADD = "account/add";
export const ACCOUNT_UPDATE = "account/update";
export const ACCOUNT_UPDATE_IS_PARENT = "account/updateIsParent";
export const ACCOUNT_UPDATE_IS_PUBLIC = "account/updateIsPublic";
export const ACCOUNT_DELETE = "account/delete";
export const ACCOUNT_SCREENS = "account/screens";
export const ACCOUNT_JOIN = "account/join";

export const PUSH_NOTIFICATIONS_RECEIVED = "pushNotifications/received"
export const PUSH_NOTIFICATIONS_MARK_READ = "pushNotifications/markAsRead"
export const PUSH_NOTIFICATIONS_MARK_ALL_READ = "pushNotifications/markAllAsRead"
export const PUSH_NOTIFICATIONS_UNREAD_COUNT = "pushNotifications/unreadCount"
export const PUSH_NOTIFICATIONS_GET_RESULT = "pushNotifications/getResult"
export const PUSH_NOTIFICATIONS_SAVE_TYPE_PREFERENCE = "pushNotifications/saveTypePreference"
export const PUSH_NOTIFICATIONS_SAVE_PREFERENCE = "pushNotifications/savePreference"

export const FEATURE_TOGGLE_ENABLE = 'featureToggle/enable';
export const FEATURE_TOGGLE_DISABLE = 'featureToggle/disable';

export const LAYOUT_LOADED = 'layout/loaded';
export const LAYOUT_TOGGLE_ACCOUNT_SWITCHER = 'layout/toggleAccountSwitcher';
export const LAYOUT_TOGGLE_AGENCY_SWITCHER = 'layout/toggleAgencySwitcher';
export const LAYOUT_TOGGLE_PROFILE_SWITCHER = 'layout/toggleProfileSwitcher';
export const LAYOUT_TOGGLE_PROFILE_MENU = 'layout/toggleProfileMenu';
export const LAYOUT_TOGGLE_LEFT_MENU = 'layout/toggleLeftMenu';
export const LAYOUT_TOOGLE_LEFT_MENU_DESKTOP = 'layout/toogleLeftMenuDesktop';
export const LAYOUT_OPEN_SCHEDULE_BUILDER = 'layout/openScheduleBuilder';
export const LAYOUT_CLOSE_SCHEDULE_BUILDER = 'layout/closeScheduleBuilder';
export const LAYOUT_TOOGLE_INVENTORY_TOGGLE_SEARCH = 'layout/toggleInventorySearch';
export const LAYOUT_TOOGLE_SIGNIN_DIALOG = 'layout/toggleSignInDialog';
export const LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG = 'layout/togglePushNotificationsInfoDialog';

export const LOCATION_GETBYID = 'location/getById';
export const LOCATION_UPDATE = 'location/update';
export const LOCATION_NEW_STREET_VIEW = 'location/newStreetView';

export const PHOTO_GETBYID = 'photo/getById';

export const AGENCY_USER_TRUST_CHANGED = 'agencies/trustUserChanged'
export const AGENCY_APPROVAL_SETTINGS_CHANGED = 'agencies/trustUserChanged'
export const AGENCY_UPDATE = 'agencies/agencyUpdate'
export const AGENCY_CAMPAIGNS = 'agencies/campaigns'
export const AGENCY_EXPORTS = 'agencies/exports'
export const AGENCY_DELETE = "agency/delete";
export const AGENCY_ACCOUNTS_REQUEST = 'agencies/accountsRequest'
export const AGENCY_ACCOUNTS = 'agencies/accounts'
export const AGENCY_UNREAD_STORIES = "agencies/unreadStoryCount";

export const CAMPAIGNS_CREATED = 'campaigns/campaignCreated'
export const CAMPAIGNS_CREATED_RESULT = 'campaigns/campaignCreatedResult'

export const STORIES_NEW = "stories/new";

export const ENTITY_SEARCH_REQUEST = "entity/searchRequest";
export const ENTITY_SEARCH_RESULT = "entity/searchResult";
export const ENTITY_SEARCH_ERROR = "entity/searchError";
export const ENTITY_NEXT_PAGE_REQUEST = "entity/nextPageRequest";
export const ENTITY_NEXT_PAGE_RESULT = "entity/nextPageResult";
export const ENTITY_NEXT_PAGE_ERROR = "entity/nextPageError";

export const DESIGNER_LOAD_TEMPLATES_START = "designer/loadTemplatesStart";
export const DESIGNER_LOAD_TEMPLATES = "designer/loadTemplates";
export const DESIGNER_LOAD_TEMPLATE = "designer/loadTemplate";
export const DESIGNER_RESET_STATE = "designer/resetState";
export const DESIGNER_CREATE_TEMPLATE = "designer/createTemplate";
export const DESIGNER_UPDATE_TEMPLATE_REQUEST = "designer/updateTemplateRequest";
export const DESIGNER_UPDATE_TEMPLATE = "designer/updateTemplate";
export const DESIGNER_PUBLISH_TEMPLATE = "designer/publishTemplate";
export const DESIGNER_UNPUBLISH_TEMPLATE = "designer/unpublishTemplate";
export const DESIGNER_REMOVE_TEMPLATE = "designer/removeTemplate";
export const DESIGNER_DUPLICATE_TEMPLATE = "designer/duplicateTemplate";
export const DESIGNER_TEMPLATE_MAKE_PUBLIC = "designer/templateMakePublic";
export const DESIGNER_TEMPLATE_MAKE_PRIVATE = "designer/templateMakePrivate";
export const DESIGNER_LOAD_BOARD_FORMATS = "designer/loadBoardFormats";
export const DESIGNER_ADD_BOARD_FORMAT = "designer/addBoardFormat";
export const DESIGNER_REMOVE_BOARD_FORMAT = "designer/removeBoardFormat";
export const DESIGNER_SET_TEMPLATE_NAME = "designer/setTemplateName";
export const DESIGNER_SET_TEMPLATE_DESCRIPTION = "designer/setTemplateDescription";
export const DESIGNER_SET_TEMPLATE_CROP_RATIO = "designer/setTemplateCropRatio";
export const DESIGNER_ACCOUNT_CHANGE = "designer/accountChange";
export const DESIGNER_BOARD_CHANGE = "designer/boardChange";
export const DESIGNER_ITEM_CLASS_CHANGE = "designer/itemClassChange";
export const DESIGNER_ITEM_CHANGE = "designer/itemChange";
export const DESIGNER_ITEM_USER_CHANGE = "designer/itemUserChange";
export const DESIGNER_EDIT_MODE_GLOBAL_CHANGE = "designer/editModeGlobalChange";
export const DESIGNER_HTML_CHANGE = "designer/htmlChange";
export const DESIGNER_CSS_CHANGE = "designer/cssChange";
export const DESIGNER_JS_CHANGE = "designer/jsChange";
export const DESIGNER_SET_SIZE_VARIANT = "designer/setSizeVariant";
export const DESIGNER_CLEAR_ALL_SIZE_VARIANTS = "designer/clearAllSizeVariants";
export const DESIGNER_SYNC_ALL_SIZE_VARIANTS = "designer/syncAllSizeVariants";
export const DESIGNER_SET_CSS_VARIANTS = "designer/setCSSVariants";
export const DESIGNER_ADD_OBJECT = "designer/addObject";
export const DESIGNER_SELECT_VARIANT = "designer/selectVariant";
export const DESIGNER_ADD_ASSET = "designer/addAsset";
export const DESIGNER_REPLACE_ASSET = "designer/replaceAsset";
export const DESIGNER_DEL_ASSET = "designer/delAsset";
export const DESIGNER_FONTS = "designer/fonts";
export const DESIGNER_ADD_FONT = "designer/addFont";
export const DESIGNER_SET_SELECTED_EDITOR_ID = "designer/setSelectedEditorId";
export const DESIGNER_SET_SELECTED_FRAME_ID = "designer/setSelectedFrameId";
export const DESIGNER_HTML_REPLACE_FOR_ID = "designer/htmlReplaceForId";
export const DESIGNER_HTML_REPLACE_INNER_HTML_FOR_ID = "designer/htmlReplaceInnerHTMLForId";
export const DESIGNER_HTML_APPEND = "designer/htmlAppend";
export const DESIGNER_HTML_MOVE_ELEMENT_LAYER = "designer/moveElementLayer";
export const DESIGNER_HTML_SYNC_LAYERS = "designer/syncLayers";
export const DESIGNER_HTML_DELETE_LAYER = "designer/deleteLayer";
export const DESIGNER_HTML_LAYER_ADD_CLASS = "designer/layerAddClass";
export const DESIGNER_HTML_LAYER_REMOVE_CLASS = "designer/layerRemoveClass";
export const DESIGNER_HTML_LAYER_SET_CSS_FOR_KEY = "designer/layerSetCSSForKey";
export const DESIGNER_HTML_DUPLICATE_LAYER = "designer/duplicateLayer";
export const DESIGNER_HTML_PASTE_TEXT = "designer/pasteText";
export const DESIGNER_CSS_REPLACE_ID = "designer/cssReplaceId";
export const DESIGNER_FORMAT_COPY = "designer/formatCopy";
export const DESIGNER_FORMAT_PASTE = "designer/formatPaste";
export const DESGINER_SET_ITEM_CLASS = "designer/setItemClass";
export const DESIGNER_ADD_ITEM_CLASS = "designer/addItemClass";
export const DESIGNER_REMOVE_ITEM_CLASS = "designer/delItemClass";
export const DESIGNER_SHOW_HIDE_TEMPLATES = "designer/showHideTemplates";
export const DESIGNER_SHOW_HIDE_CODE_EDITOR = "designer/showHideCodeEditor";
export const DESIGNER_SET_THUMB_FROM_SELECTED_FRAME = "designer/setThumbFromSelectedFrame";
export const DESIGNER_LOAD_TEMPLATE_ASSETS = "designer/loadTemplateAssets";
export const DESIGNER_DRAGGING_IS_HAPPENING = "designer/draggingIsHappening";
export const DESIGNER_DRAGGING_IS_DONE = "designer/draggingIsDone";
export const DESIGNER_PREVIEW_SET_ASPECT_RATIO = "designer/previewSetAspectRatio";
export const DESIGNER_SET_GRIDSIZE = "designer/setGridSize";
export const DESIGNER_SET_GRIDSHOW = "designer/setGridShow";
export const DESIGNER_SET_GRIDCOLOR = "designer/setGridColor";
export const DESIGNER_SET_SNAPPING = "designer/setSnapping";
export const DESIGNER_SET_ZOOM = "designer/setZoom";
export const DESIGNER_SET_BORDER_ALL_ELEMENTS_SHOW = "designer/setBorderAllElementsShow";
export const DESIGNER_SET_FIELD_MAP = "designer/setFieldMap";
export const DESIGNER_ADD_CUSTOM_FIELD = "designer/addCustomField";
export const DESIGNER_UPDATE_CUSTOM_FIELD = "designer/updateCustomField";
export const DESIGNER_REMOVE_CUSTOM_FIELD = "designer/removeCustomField";
export const DESIGNER_LOAD_ACCOUNT_KEY_VALUE_STORE = "designer/loadAccountKeyValueStore";
export const DESIGNER_CLEAR_TEMPLATE = "designer/clearTemplate";
export const DESIGNER_TEMPLATE_LOADING = "designer/templateLoading";
export const DESIGNER_KEY_VALUE_DATA_LOADING = "designer/keyValueDataLoading";

export const DESIGNER_PUSH_HISTORY = "designer/pushHistory";
export const DESIGNER_UNDO = "designer/undo";
export const DESIGNER_REDO = "designer/redo";

export const DESISGNER_LOAD_TEMPLATE_ELEMENTS = "designer/loadTemplateElements";

export const DRIVE_TEMPLATE_ELEMENT_INDEX_FOR_EDIT = "driveTemplateElement/indexForEdit";
export const DRIVE_TEMPLATE_ELEMENT_ADD = "driveTemplateElement/add";
export const DRIVE_TEMPLATE_ELEMENT_DELETE = "driveTemplateElement/delete";
export const DRIVE_TEMPLATE_ELEMENT_UPDATE = "driveTemplateElement/update";
export const DRIVE_TEMPLATE_ELEMENT_MOVE = "driveTemplateElement/move";

export const CREATIVEPROOF_ITEM = "creativeProof/item";

export const OBJECT_ITEM = "object/item";
export const OBJECT_COMMENTS = "object/comments";
export const OBJECT_NEW_COMMENT = "object/newComment";
export const OBJECT_UPDATE_COMMENT = "object/updateComment";
export const OBJECT_DELETE_COMMENT = "object/deleteComment";
export const OBJECT_REACTIONS = "object/reactions";
export const OBJECT_NEW_REACTION_REQUEST = "object/newReactionRequest";
export const OBJECT_NEW_REACTION = "object/newReaction";
export const OBJECT_DELETE_REACTION_REQUEST = "object/deleteReactionRequest";
export const OBJECT_DELETE_REACTION = "object/deleteReaction";

export const OBJECT_ATTACHMENTS = "object/attachments";
export const OBJECT_ATTACHMENT_ADD = "object/addAttachment";
export const OBJECT_ATTACHMENT_FILE = "object/attachmentFile";

export const OBJECT_USERS_REQUEST = "object/usersRequest";
export const OBJECT_USERS_RESULT = "object/usersResult";
export const OBJECT_USERS_REMOVE = "object/usersRemove";
export const OBJECT_USERS_UPDATE_ROLES = "object/usersUpdateRoles";
export const OBJECT_USERS_INVITATIONS_REQUEST = "object/usersInvitationsRequest";
export const OBJECT_USERS_INVITATIONS_RESULT = "object/usersInvitationsResult";
export const OBJECT_USERS_INVITE = "object/usersInvite";
export const OBJECT_USERS_UNINVITE = "object/usersUninvite";

export const OBJECT_APPLICATIONS_REQUEST = "object/applicationsRequest";
export const OBJECT_APPLICATIONS_RESULT = "object/applicationsResult";
export const OBJECT_APPLICATIONS_ASSIGN = "object/applicationsAssign";
export const OBJECT_APPLICATIONS_UNASSIGN = "object/applicationsUnassign";

export const OBJECT_APPLICATION_DATA_SOURCES_RESULT = "object/applicationDataSourceResult";
export const OBJECT_APPLICATION_DATA_SOURCES_CLEAR = "object/applicationDataSourceResult";
export const OBJECT_APPLICATION_DATA_SOURCES_ADD = "object/applicationDataSourceAdd";
export const OBJECT_APPLICATION_DATA_SOURCES_UPDATE = "object/applicationDataSourceUpdate";
export const OBJECT_APPLICATION_DATA_SOURCES_REMOVE = "object/applicationDataSourceRemove";
export const OBJECT_APPLICATION_DATA_SOURCES_SET_IMAGE = "object/applicationDataSourceSetImage";
export const OBJECT_APPLICATION_DATA_SOURCES_REMOVE_IMAGE = "object/applicationDataSourceRemoveImage";

export const PROGRAMMATIC_INIT = "programmatic/init";
export const PROGRAMMATIC_FILTERS_INIT = "programmatic/filtersInit";
export const PROGRAMMATIC_FILTERS_CHANGE = "programmatic/filtersChange";
export const PROGRAMMATIC_VIEWPORT_CHANGE = "programmatic/viewportChange";
export const PROGRAMMATIC_STATS_LOADING = "programmatic/statsLoading";
export const PROGRAMMATIC_STATS_LOADED = "programmatic/statsLoaded";
export const PROGRAMMATIC_LOCATIONS_LOADED = "programmatic/locationsLoaded";
export const PROGRAMMATIC_LOCATIONS_LOADING_CHANGE = "programmatic/locationsLoadingChange";
export const PROGRAMMATIC_INITIAL_VIEW_CHANGE = "programmatic/initialViewChange";
export const PROGRAMMATIC_AGENCIES_LOADED = "programmatic/agenciesLoaded";
export const PROGRAMMATIC_VENUE_TYPES_LOADED = "programmatic/venuesLoaded";
export const PROGRAMMATIC_BOARD_FORMATS_LOADED = "programmatic/boardFormatsLoaded";
export const PROGRAMMATIC_AGENCY_COUNTS_LOADED = "programmatic/agencyCountsLoaded";
export const PROGRAMMATIC_BOARD_FORMAT_COUNTS_LOADED = "programmatic/boardFormatCountsLoaded";
export const PROGRAMMATIC_VENUE_TYPE_COUNTS_LOADED = "programmatic/venueTypeCountsLoaded";
export const PROGRAMMATIC_CREATIVE_UPLOADED = "programmatic/creativeUploaded";
export const PROGRAMMATIC_CREATIVE_DELETED = "programmatic/creativeDeleted";
export const PROGRAMMATIC_RESET = "programmatic/reset";

export const PROGRAMMATIC_BUDGET_CHANGE = "programmatic/budgetChange";
export const PROGRAMMATIC_DATES_CHANGE = "programmatic/budgetDatesChange";
export const PROGRAMMATIC_BUDGET_ALLOCATION_CHANGE = "programmatic/budgetAllocationChange";

export const LIGHTNING_DEVICES_LOADED = "lightning/devicesLoaded";
export const LIGHTNING_DEVICE_ADDED = "lightning/deviceAdded";
export const LIGHTNING_DEVICE_UPDATED = "lightning/deviceUpdated";
export const LIGHTNING_DEVICE_DELETED = "lightning/deviceDeleted";
export const LIGHTNING_DEVICE_LAYOUTS_LOADED = "lightning/deviceLayoutsLoaded";
export const LIGHTNING_DEVICE_LAYOUT_ADDED = "lightning/deviceLayoutAdded";
export const LIGHTNING_DEVICE_LAYOUT_UPDATED = "lightning/deviceLayoutUpdated";
export const LIGHTNING_DEVICE_LAYOUT_DELETED = "lightning/deviceLayoutDeleted";
export const LIGHTNING_DEVICE_LAYOUT_MAKE_DEFAULT = "lightning/deviceLayoutMakeDefault";
export const LIGHTNING_DEVICE_DIGITAL_BOARDS_LOADED = "lightning/digitalBoardsLoaded";
export const LIGHTNING_GROUP_CAMPAIGN_ADDED = "lightning/groupCampaignAdded";
export const LIGHTNING_GROUP_CAMPAIGN_UPDATED = "lightning/groupCampaignUpdated";
export const LIGHTNING_GROUP_CAMPAIGN_DELETED = "lightning/groupCampaignDeleted";
export const LIGHTNING_SET_SCREENGROUP_SCREENS = "lightning/setParentCampaigns";
export const LIGHTNING_SET_CHILD_CAMPAIGNS = "lightning/setChildCampaigns";
export const LIGHTNING_LATEST_VERSION_LOADED = "lightning/latestVersionLoaded";

export const LIGHTNING_DEVICE_HEARTBEAT_STATS_LOADED = "lightning/deviceHeartbeatStatsLoaded";
export const LIGHTNING_SCREEN_STATS_LOADED = "lightning/screenStatsLoaded";

export const SHADOW_ACCOUNT_LOADED = "shadowAccount/loaded";

export const EntitySet = {
    publicStory: 'publicStory',
    digitalBoards: 'digitalBoards',
    globalSearch: 'globalSearch',
}
