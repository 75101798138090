import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import StoriesList from '../stories/StoriesList';
import { loadAgencyFeed, loadAgencyFeedNextPage } from '../../actions/stories';
import { InfiniteScroll } from '../../components';
import { ScrollContext } from '../../contexts';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function AgencyFeed(props) {
    const { agency, stories, loadAgencyFeed, loadAgencyFeedNextPage } = props;

    const load = () => loadAgencyFeed(agency.lcuid, 1, props.perPage);
    const loadNext = () => loadAgencyFeedNextPage(agency.lcuid, props.page + 1, props.perPage);
    const scrollContext = useContext(ScrollContext).current;
    const { t } = useTranslation();

    useEffect(() => {
        load();
    }, [])

    if (!props.loading && !stories.length) {
        return <Grid item xs={12} md="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Box mt={10} mb={10}>
                {t("{{name}} hasn't posted any stories yet", { name: agency.name })}
            </Box>
        </Grid>
    }

    return <InfiniteScroll dataLength={stories.length}
        page={props.page}
        perPage={props.perPage}
        lastPage={props.lastPage}
        loading={props.loading}
        load={load}
        loadNext={loadNext}
        scrollableTarget={scrollContext.ref}
    >
        <StoriesList stories={stories} />
    </InfiniteScroll>
}

const mapStateToProps = (state) => {
    return {
        stories: state.stories.public.data,
        loading: state.stories.public.loading,
        page: state.stories.public.currentPage,
        perPage: state.stories.public.perPage,
        lastPage: state.stories.public.lastPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadAgencyFeed: (agencyId, page, perPage) => dispatch(loadAgencyFeed(agencyId, page, perPage)),
        loadAgencyFeedNextPage: (agencyId, page, perPage) => dispatch(loadAgencyFeedNextPage(agencyId, page, perPage)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyFeed);
