import moment from "moment";
import { ExportStatus, CampaignState, InventoryItemClasses, ExportTransformerClasses } from "../helpers/constants";
import { toLocalDateTime } from "../helpers/date";
import { hasAgencies } from './user';

export const filters = state => state.exports.filters;
export const hasNextPage = state => state.exports.currentPage < state.exports.lastPage;
export const nextPage = state => state.exports.currentPage + 1;
export const nextPageLoading = state => state.exports.loadingNextPage;
export const searched = state => state.exports.searched;
export const hasExports = state => state.exports && state.exports.data && state.exports.data.length ? true : false;
export const exports = state => state.exports.data;
export const exportsByIdSelector = state => id => state.exports.data.find(x => x.id === id);
export const exportsTotal = state => state.exports.total;
export const exportsScrollY = state => state.exports.scrollY;
export const hasScrollY = state => !!state.exports.scrollY;
export const isInvalidated = state => state.exports.invalidated;

export const operatorIsAttachedToExport =
    (exportObject, operator) => exportObject.operators && exportObject.operators.find(o => o.id === operator.id)

export const hasOperators = exports => !!exports.find(e => !!(e.operators || []).length);

export const isExportRunningOrWillBe = exportItem => exportItem.status === ExportStatus.running
    || exportItem.status === ExportStatus.runnable
    || exportItem.status === ExportStatus.retry
    || exportItem.status === ExportStatus.queued;

export const hasDigitalBoards = exportItem => exportItem?.settings_array?.transformer?.digital_board_ids?.length > 0;

export const digitalBoardFormatsForAccount = state => {

    const digitalBoards = state.accounts.screens

    if (!digitalBoards) return [];

    const allFormats = digitalBoards.flatMap(board => board.digital_board_format);

    // Creating a set of unique 'digital_board_format' based on 'id'
    const uniqueFormats = Array.from(new Set(allFormats.map(format => format.code)))
        .map(code => allFormats.find(format => format.code === code));

    return uniqueFormats

}

export const hasCampaignWithBoardFormatCode = (state, boardFormatCode) => {
    const digitalBoardFormats = digitalBoardFormatsForAccount(state);

    if (!digitalBoardFormats) return false;

    return digitalBoardFormats.some(format => format.code === boardFormatCode);
}

export const hasCampaignsInRunningOrRunnableState = (state) => {
    const campaigns = state.exports.data;

    if (!campaigns) return false;

    return campaigns.some(campaign => isInRunningOrRunnableCampaignState(campaign));
}

export const hasCampaignsInRunningState = (state) => {
    const campaigns = state.exports.data;

    if (!campaigns) return false;

    return campaigns.some(campaign => campaign.campaign_state === CampaignState.running.id);
}

//Pretty much everything except draft and complete
export const isInRunningOrRunnableCampaignState = (campaign) => {

    return campaign.campaign_state === CampaignState.pulling.id
        || campaign.campaign_state === CampaignState.pullingPaused.id
        || campaign.campaign_state === CampaignState.running.id
        || campaign.campaign_state === CampaignState.paused.id
        || campaign.campaign_state === CampaignState.pendingTraffic.id
        || campaign.campaign_state === CampaignState.pending.id
}

export const isParentCampaign = (exportItem) => {
    return exportItem
        && exportItem.settings_array
        && exportItem.settings_array.transformer
        && exportItem.settings_array.transformer.transformer_class === ExportTransformerClasses.parentCampaign.class
}

export const isCreativeCampaign = (exportItem) => {
    return exportItem
        && exportItem.settings_array
        && exportItem.settings_array.transformer
        && exportItem.settings_array.transformer.transformer_class === ExportTransformerClasses.driveCampaign.class
}

export const hasparentCampaigns = (state) => {
    const campaigns = state.exports.data;

    if (!campaigns) return false;

    return campaigns.some(campaign => isParentCampaign(campaign));
}

export const hasCreativeCampaigns = (state) => {
    const campaigns = state.exports.data;

    if (!campaigns) return false;

    return campaigns.some(campaign => isCreativeCampaign(campaign));
}

export const hasIcon = exportItem => exportItem.options && exportItem.options.primary_image_public_url;
export const isItemsIdFilterAllowed = item => item
    && item.settings_array
    && item.settings_array.filter
    && item.settings_array.filter.user_can_update
    && item.settings_array.filter.user_can_update.item_ids;
export const nextRunLocal = exportItem => moment.utc(exportItem.next_run_date).local()
export const nextRunMilliseconds = exportItem => exportItem.next_run_date
    ? moment.duration(nextRunLocal(exportItem).diff(moment())).asMilliseconds()
    : null;

export const hasMaxTitleLengthWarning =
    (exportItem, title) => title && title.length > exportSettings(exportItem).transformer.max_title_length;

export const shouldShowMaxTitleWarning = (item) => item.item_class !== InventoryItemClasses.creative

export const exportDescription = item => {
    const settings = exportSettings(item);

    if (item.last_run_fallback_mode) {
        return settings.fallback_filter ? settings.fallback_filter.description : "";
    }

    return settings.filter.description;
}

export const exportSettings = item => Object.assign({
    filter: {},
    transformer: {}
}, item.settings_array);

export const exportOptions = item => Object.assign({
    stats_delayed: false,
    stats_unavailable: false
}, item && item.options);

export const isStatsDelayed = item => {
    return exportOptions(item).stats_delayed
}
export const isStatsUnavailable = item => {
    return exportOptions(item).stats_unavailable
}

export const lastRunAt = item => toLocalDateTime(item.last_run_at);

export const hasRunDuration = run => run.export_run_start_at
    && run.completed;
export const runDuration = run => hasRunDuration(run)
    ? moment.duration(moment(run.completed).diff(moment(run.export_run_start_at)))
    : null;

export const runCompletedAt = run => toLocalDateTime(run.completed);
export const runStartedAt = run => toLocalDateTime(run.export_run_start_at);

export const canRunExport = inventoryExport => inventoryExport.status === ExportStatus.ready
    || inventoryExport.status === ExportStatus.error || inventoryExport.status === ExportStatus.empty;

export const showScreens = (inventoryExport, user) => hasAgencies(user) && inventoryExport

export const showIntegrations = (inventoryExport, user) => hasAgencies(user) && inventoryExport
