import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import { showError, showSuccess } from '../../actions/snackbar';
import { ConfirmationDialog } from '../../components';
import { history } from '../../helpers/history';
import { deleteAccount } from '../../actions/account';
import { lucitApi } from "../../services/lucitApi"
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { ACCOUNT_DELETE } from '../../helpers/actionTypes';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const AccountDeleteButton = (props) => {
    const { account, deleteAccount, renderButton, onDelete } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(account.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [account, isConfirmOpen])

    return (
        <>
            {renderButton
                ? renderButton({
                    onClick: () => setIsConfirmOpen(true)
                })
                : <Button
                    color="inherit"
                    variant="contained"
                    className={classes.removeButton}
                    startIcon={<Delete />}
                    onClick={() => setIsConfirmOpen(true)}>
                    {t('Permanently Delete Account')}
                </Button>}

            <ConfirmationDialog
                title={t('Delete Account : ') + account.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteAccount()
                            .then(() => onDelete && onDelete())
                            .finally(() => setIsConfirmOpen(false))
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete this account?</LCTypography>
                        <LCTypography>This action CANNOT be un-done and once you delete this account, you will have to recreate all items,
                            creatives, proofs, campaigns and analytics data associated with it.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Account can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

AccountDeleteButton.propTypes = {
    account: PropTypes.object.isRequired,
    redirectPathname: PropTypes.string
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteAccountFromStore: () => dispatch({
            type: ACCOUNT_DELETE,
            account: props.account
        }),
        deleteAccount: () => {
            return dispatch(deleteAccount(props.account))
                .then(() => {
                    if (props.redirectPathname) {
                        history.push({ pathname: props.redirectPathname });
                    }

                    dispatch(showSuccess(`"${props.account.name}" was successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AccountDeleteButton
);
