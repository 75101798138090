import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../../components';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import LCTypography from '../../components/material/LCTypography';
import { lucitApi } from "../../services/lucitApi";
import { LIGHTNING_DEVICE_DELETED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const DeviceDeleteButton = (props) => {
    const { device, handleDeleted, onClick } = props;
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(device.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [device, isConfirmOpen])

    const deleteDevice = () => {

        setLoading(true);

        lucitApi.objects.delete(device.lcuid)
            .then(() => dispatch({
                type: LIGHTNING_DEVICE_DELETED,
                device
            }))
            .then(() => handleDeleted())
    }

    return (
        <>
            <MenuItem
                disabled={loading}
                onClick={() => {
                    setIsConfirmOpen(true)
                    onClick && onClick();
                }}>
                <ListItemIcon ><Delete /></ListItemIcon>
                <ListItemText primary="Delete" />
            </MenuItem>

            <ConfirmationDialog
                title={t('Delete : ') + device.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                CancelButtonText={loading ? '' : t('Cancel')}
                ConfirmButtonText={loading ? '' : (canBeDeleted ? t('Delete this Device') : t('OK'))}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteDevice()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <div>
                        <CircularProgressCentered /> Deleting Device
                    </div>
                }

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete <b>{device.name}</b>?</LCTypography>
                        <br />
                        <br />
                        <LCTypography>This action CANNOT be un-done and once you delete this object, you will have to recreate all items,
                            data associated with it.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

export default DeviceDeleteButton
