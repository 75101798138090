import { makeStyles, DialogActions, Button } from '@material-ui/core';
import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Dialog } from '../../components/material';
import { RoutePaths } from '../../helpers/constants';
import { getUser, hasAgency } from '../../selectors/user';
import LocationDetails from './LocationDetails';
import LocationDetailsPublic from './LocationDetailsPublic';
import { useTranslation } from 'react-i18next';
import AdvertiseDialog from './AdvertiseDialog';
import { Alert } from '@material-ui/lab';
import CreateProofButton from '../proofs/CreateProofButton';

const useStyles = makeStyles(theme => {
    return {
        billboardMap_infoModal: {
            width: '100%',
            padding: 0,
            paddingTop: 0,
            [theme.breakpoints.up('md')]: {
                width: '600px',
            },
        }
    }
})

const LocationDetailsDialog = props => {
    const {
        isLoggedIn,
        location,
        hasAgency,
        showLastPlay,
        onClose } = props;

    const classes = useStyles();
    const [billboard, setBillboard] = useState(null);
    const [openAdForm, setOpenAdForm] = useState(false);
    const [adRequestSent, setAdRequestSent] = useState(false);

    const { t } = useTranslation();

    return <>
        <Dialog
            maxWidth="md"
            scroll="paper"
            open
            onClose={onClose}
            disableBackdropAndEscapeClose={false}
        >
            <div className={classes.billboardMap_infoModal}>
                {isLoggedIn
                    && <LocationDetails
                        locationId={location.id}
                        showLastPlay={showLastPlay}
                        billboardChange={setBillboard} />}

                {!isLoggedIn
                    && <LocationDetailsPublic
                        locationId={location.id}
                        boards={location.board_ids}

                        billboardChange={setBillboard} />}
            </div>
            <DialogActions>
                {billboard
                    ? <>
                        {!isLoggedIn
                            && <>
                                {!adRequestSent
                                    && <Button variant="outlined"
                                        onClick={() => setOpenAdForm(true)}
                                        color="secondary">{t('Advertise')}</Button>}

                                {adRequestSent
                                    && <Alert variant="outlined" style={{ padding: '0 8px' }}>
                                        {t('Advertise request sent')}
                                    </Alert>}
                            </>}

                        <CreateProofButton billboard={billboard} location={location} />

                        {hasAgency(billboard.agency_id)
                            && isLoggedIn
                            && <Button
                                variant="outlined"
                                color="secondary"
                                component={RouterLink}
                                to={RoutePaths.screen(billboard.id)}>{t('Manage')}</Button>}

                    </>
                    : <>
                        <Skeleton variant='rect' height={36} width={92} />
                        <Skeleton variant='rect' height={36} width={92} />
                    </>}
            </DialogActions>
        </Dialog>

        <AdvertiseDialog
            open={openAdForm}
            onClose={() => setOpenAdForm(false)}
            onRequestSent={() => setAdRequestSent(true)}
            billboardId={billboard && billboard.id}
            location={location}
        />

    </>
}

const mapStateToProps = (state) => {
    return {
        hasAgency: hasAgency(getUser(state)),
        isLoggedIn: state.user.isLoggedIn,
    }
}

export default connect(
    mapStateToProps
)(
    LocationDetailsDialog
);
