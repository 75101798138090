import React from 'react'
import { ApplicationFeatures } from '../../helpers/constants'
import ApplicationFeatureToggle from '../../components/features/ApplicationFeatureToggle'

const AyudaFeatureToggle = ({ children, reverse }) => {

    return <ApplicationFeatureToggle
        appLcuid={ApplicationFeatures.daktronics_player.lcuid}
        reverse={reverse}
    >
        {children}
    </ApplicationFeatureToggle>

}

export default AyudaFeatureToggle;
