import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";
import entities, { initialState as EntitiesInitialState } from './templates/entities';
import {
    EntitySet, ENTITY_NEXT_PAGE_ERROR, ENTITY_NEXT_PAGE_REQUEST, ENTITY_NEXT_PAGE_RESULT, ENTITY_SEARCH_ERROR, ENTITY_SEARCH_REQUEST,
    ENTITY_SEARCH_RESULT, SCREENS_FACING_CARDINALS_RESULT, SCREENS_HARDWARE_RESULT, SCREENS_IMAGES_RESULT, SCREENS_ITEM_RESULT,
    SCREENS_PLAY_STATUS, SCREENS_PLAY_STATUSES, SCREENS_SOFTWARE_RESULT, SCREENS_UPDATE_ITEM, SCREENS_IMAGES_NEW,
    SCREENS_IMAGES_DELETE, SCREENS_IMAGES_UPDATE, SCREENS_IMAGES_SET_AS_MAIN,
    SCREENS_STREET_VIEW_NEW, SCREENS_GET_STREET_VIEW, SCREENS_STREET_VIEW_STATUS, SCREENS_DELETE
} from "../helpers/actionTypes";

export const initialState = {
    ...EntitiesInitialState,
    all: [],
    byId: {},
    status: {},
    images: {},

    hardwareProviders: [],
    softwareProviders: [],
    facingCardinals: []
}

export const billboardsPersistConfig = {
    key: 'billboards',
    whitelist: ['hardwareProviders', 'softwareProviders', 'facingCardinals'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
}

export default function billboard(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case ENTITY_SEARCH_REQUEST:
        case ENTITY_SEARCH_ERROR:
        case ENTITY_NEXT_PAGE_REQUEST:
        case ENTITY_NEXT_PAGE_ERROR: {
            if (action.entitySet === EntitySet.digitalBoards) {
                return {
                    ...state,
                    ...entities(state.public, action)
                };
            }
            return state;
        }
        case ENTITY_NEXT_PAGE_RESULT:
        case ENTITY_SEARCH_RESULT: {
            if (action.entitySet === EntitySet.digitalBoards) {
                const { data, ...rest } = entities(state.public, action);
                return {
                    ...state,
                    ...rest,
                    all: type === ENTITY_SEARCH_RESULT
                        ? data.map(x => x.id)
                        : [...state.all, ...data.map(x => x.id)],
                    byId: type === ENTITY_SEARCH_RESULT
                        ? data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
                        : {
                            ...state.byId,
                            ...data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
                        },
                };
            }
            return state;
        }

        case SCREENS_ITEM_RESULT: {
            if (state.byId[payload.id]) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [payload.id]: {
                            ...state.byId[payload.id],
                            ...payload
                        }
                    }
                }
            }

            return {
                ...state,
                all: [...state.all, payload.id],
                byId: {
                    ...state.byId,
                    [payload.id]: payload
                }
            }
        }

        case SCREENS_UPDATE_ITEM: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [payload.id]: { ...payload }
                }
            }
        }

        case SCREENS_DELETE: {
            return {
                ...state,
                all: state.all.filter(x => x !== payload.id)
            }
        }

        case SCREENS_PLAY_STATUS:
        case SCREENS_PLAY_STATUSES: {
            return {
                ...state,
                status: {
                    ...state.status,
                    ...payload.data.reduce((prev, curr) => ({
                        ...prev,
                        [curr.board_id]: {
                            play_datetime: curr.play_datetime,
                            play_duration: curr.play_duration
                        }
                    }), {})
                }
            }
        }
        case SCREENS_IMAGES_RESULT: {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.id]: payload.data
                }
            }
        }
        case SCREENS_IMAGES_NEW: {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.screenId]: [
                        ...state.images[payload.screenId],
                        payload.data
                    ]
                }
            }
        }

        case SCREENS_IMAGES_UPDATE: {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.screenId]: state.images[payload.screenId].map(image => {
                        if (image.id === payload.data.id) {
                            return {
                                ...image,
                                ...payload.data
                            }
                        }

                        return image;
                    })
                }
            }
        }

        case SCREENS_IMAGES_DELETE: {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.screen.id]: state.images[payload.screen.id].filter(image => image.id !== payload.image.id)
                }
            }
        }

        case SCREENS_IMAGES_SET_AS_MAIN: {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.screen.id]: state.images[payload.screen.id].map(image => {
                        return { ...image, is_main_image: image.id === payload.image.id ? true : false }
                    })
                }
            }
        }

        case SCREENS_STREET_VIEW_NEW: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [payload.id]: {
                        ...state.byId[payload.id],
                        street_view_location_id: payload.streetViewId,
                        street_view_status: payload.status
                    }
                }
            }
        }

        case SCREENS_STREET_VIEW_STATUS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [payload.id]: {
                        ...state.byId[payload.id],
                        street_view_status: payload.status
                    }
                }
            }
        }

        case SCREENS_GET_STREET_VIEW: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [payload.id]: {
                        ...state.byId[payload.id],
                        street_view_location: payload.data
                    }
                }
            }
        }

        case SCREENS_SOFTWARE_RESULT: {
            return {
                ...state,
                softwareProviders: payload.data
            }
        }
        case SCREENS_HARDWARE_RESULT: {
            return {
                ...state,
                hardwareProviders: payload.data
            }
        }
        case SCREENS_FACING_CARDINALS_RESULT: {
            return {
                ...state,
                facingCardinals: payload.data
            }
        }
        default:
            return state
    }
}
