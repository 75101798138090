import React, { useMemo, useState } from 'react';
import {
    Button,
    Popover,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    TextField,
    Divider,
    ListItemIcon,
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { toDictionary } from '../../helpers/array';
import { sortString } from '../../helpers/sorters';
import { searchTermInText } from '../../helpers/string';

const AddRowsButton = ({ options, value, onChange, buttonTitle, noOptionsText }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [showMenuEl, setShowMenuEl] = useState(null);

    const optionsFiltered = options
        .filter(g => !search || searchTermInText(search, [g.name, g.description]))
        .sort((a, b) => sortString(a.name, b.name));
    const valueDict = useMemo(() => toDictionary(value, x => x.lcuid), [value])

    const _onChange = valueDict => onChange(Object.values(valueDict).filter(x => !!x));

    const onAllClick = () => {
        if (optionsFiltered.every(g => Boolean(valueDict[g.lcuid]))) {
            _onChange({ ...valueDict, ...toDictionary(optionsFiltered, x => x.lcuid, () => undefined) })
        } else {
            _onChange({ ...valueDict, ...toDictionary(optionsFiltered, x => x.lcuid) })
        }
    }
    const onClick = option => {
        if (valueDict[option.lcuid]) {
            _onChange({ ...valueDict, [option.lcuid]: undefined })
        } else {
            _onChange({ ...valueDict, [option.lcuid]: option })
        }
    }

    return (
        <>
            <Button
                onClick={(e) => setShowMenuEl(e.currentTarget)}
                variant='outlined'
                endIcon={showMenuEl
                    ? <ExpandLess />
                    : <ExpandMore />}>
                {buttonTitle}
            </Button>

            <Popover
                open={Boolean(showMenuEl)}
                onClose={() => setShowMenuEl(null)}
                anchorEl={showMenuEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List disablePadding style={{ minWidth: 400 }}>
                    <TextField
                        fullWidth
                        autoFocus
                        style={{ padding: 8 }}
                        variant="outlined"
                        size="small"
                        placeholder={t('Search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    {optionsFiltered.length > 0
                        ? <>
                            <ListItem
                                onClick={onAllClick}
                                button
                                disableGutters
                                dense>
                                <Checkbox
                                    size='small'
                                    checked={optionsFiltered.every(g => Boolean(valueDict[g.lcuid]))} />
                                <ListItemText>{t('All')}</ListItemText>
                            </ListItem>
                            <Divider />
                        </>
                        : <ListItem>
                            <LCTypography variant='body2' color="textSecondary">{noOptionsText}</LCTypography>
                        </ListItem>}
                    {optionsFiltered
                        .map(g => (
                            <ListItem key={g.lcuid}
                                onClick={() => onClick(g)}
                                button
                                disableGutters
                                dense>
                                <Checkbox size='small' checked={Boolean(valueDict[g.lcuid])} />
                                {g.Icon
                                    && <ListItemIcon style={{ minWidth: 32 }}>
                                        {g.Icon}
                                    </ListItemIcon>}
                                <ListItemText
                                    primary={g.name}
                                    secondary={g.description}
                                />
                            </ListItem>
                        ))}
                </List>
            </Popover>
        </>
    )

}

export default AddRowsButton;
