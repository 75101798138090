import { IonItem, IonLabel, IonList } from '@ionic/react';
import { Box, Grid, Slider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDesignerPreviewAspectRatio, setDigitalBoard } from '../../actions/designer';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { boardFormats, previewBoard } from '../../selectors/designer';
import { selectedAccount, selectedAgency } from '../../selectors/user';
import { lucitApi } from '../../services/lucitApi';
import { getScreenIcon } from '../../selectors/billboard'
import { getExports } from '../../actions/exports';

const useStyles = makeStyles(() => {
    return {
        screenContainer: {
            height: "calc(100vh - 250px)",
            overflowY: "scroll"
        }
    }
})

const DesignerBoardFormatTool = ({
    frameIdSelected, boardFormats, agencyId, isAccountProfile, getExports, accountScreens,
    setDesignerPreviewAspectRatio, setDigitalBoard, previewBoard
}) => {

    const classes = useStyles()

    const [loading, setLoading] = useState(true)
    const [boardsMatchingFormat, setBoardsMatchingFormat] = useState([])
    const [aspectRatio, setAspectRatio] = useState(1)
    const [agencyBoards, setAgencyBoards] = useState([])

    const boardFormat = boardFormats[frameIdSelected] ?? null

    useEffect(() => {
        if (isAccountProfile) {
            getExports()
        }
    }, [isAccountProfile])

    useEffect(() => {
        if (aspectRatio) {
            setDesignerPreviewAspectRatio(aspectRatio)
        }
    }, [aspectRatio])

    useEffect(() => {

        if (!boardFormat) return;

        const ratio = boardFormat.size.width / boardFormat.size.height;
        setAspectRatio(ratio)

    }, [frameIdSelected, boardFormats])

    useEffect(() => {

        setLoading(true)

        lucitApi.agencies.getDigitalBoards(agencyId).then((boards) => {
            setAgencyBoards(boards)
            setLoading(false)
        })

    }, [agencyId])

    const ratioSliderStep = boardFormat ? (boardFormat.ratios.max - boardFormat.ratios.min) / 25 : 0.1;

    const accountHasScreen = (lcuid) => accountScreens.length > 0 && accountScreens.find((screen) => screen.lcuid === lcuid)

    useEffect(() => {
        if (frameIdSelected) {
            const boardsWithCampaign = [
                ...accountScreens,
                ...agencyBoards.filter(obj2 => !accountScreens.find(obj1 => obj1.id === obj2.id))
            ]

            const boardsMatchingFormat = boardsWithCampaign.filter((board) => board.digital_board_format.code === frameIdSelected)
                .map((board) => {
                    return {
                        ...board,
                        selected: previewBoard?.id === board.id,
                        accountHasScreen: accountHasScreen(board.lcuid)
                    }
                })
                .sort((a, b) => {
                    const ratioDiff = (a.width / a.height) - (b.width / b.height);
                    if (ratioDiff === 0) {
                        return a.width - b.width; // Sort ascending based on width
                    }
                    return ratioDiff;
                }
                )

            setBoardsMatchingFormat(boardsMatchingFormat)

        }
    }, [agencyBoards, frameIdSelected])

    return (
        <Box className={classes.screenContainer} p={2}>

            {loading && <CircularProgressCentered />}

            {!loading && <>

                <Grid container>
                    <Grid item xs={12}>

                        <Alert severity="info">
                            <>{boardsMatchingFormat.length}  </>
                            screens matching <strong>{boardFormat ? boardFormat.name : null}</strong> format size
                        </Alert>

                    </Grid>
                </Grid>

                <IonList>

                    {/**Show the boards that this account has campaigns on first */}
                    {boardsMatchingFormat.filter(b => b.accountHasScreen).map((board) => {
                        return <ScreenRow
                            key={board.id}
                            board={board}
                            setDigitalBoard={setDigitalBoard}
                            setAspectRatio={setAspectRatio}
                        />
                    })}

                    {/**Show the rest of them */}
                    {boardsMatchingFormat.filter(b => !b.accountHasScreen).map((board) => {
                        return <ScreenRow
                            key={board.id}
                            board={board}
                            setDigitalBoard={setDigitalBoard}
                            setAspectRatio={setAspectRatio}
                        />
                    })}

                </IonList>

                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={8} mb={4}>
                            <Slider
                                value={aspectRatio}
                                defaultValue={aspectRatio}
                                min={boardFormat ? boardFormat.ratios.min : aspectRatio}
                                max={boardFormat ? boardFormat.ratios.max : aspectRatio}
                                getAriaValueText={() => aspectRatio}
                                step={ratioSliderStep}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value) => value.toFixed(2)}
                                onChange={(_, newValue) => {
                                    setAspectRatio(newValue)
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </>
            }

        </Box>
    )
}

const ScreenRow = ({ board, setDigitalBoard, setAspectRatio }) => {

    const ratioDescription = '1:' + (board.width / board.height).toFixed(2);

    const screenIcon = getScreenIcon(board.digital_board_format.code, board.accountHasScreen)

    return <IonItem
        button
        style={{ fontWeight: board.selected ? 'bold' : 'normal' }}
        title={
            'Ratio ' + ratioDescription + ' '
            + board.name + ' '
            + board.board_size_description + ' '
            + board.board_identifier
        }
        onClick={() => {
            setAspectRatio(board.width / board.height)
            setDigitalBoard(board)
        }}
    >
        <img
            style={{ width: "24px", marginTop: "-12px" }}
            //accountHasScreen
            src={screenIcon}
        />
        <IonLabel>{board.board_size_description}</IonLabel>
        <IonLabel>{board.name}</IonLabel>
        <IonLabel>{board.board_identifier}</IonLabel>
    </IonItem>

}

const mapStateToProps = state => {

    return {
        frameIdSelected: state.designer.frameIdSelected,
        boardFormats: boardFormats(state),
        agencyId: selectedAgency(state) ? selectedAgency(state).id : (selectedAccount(state) ? selectedAccount(state).agency_id : null),
        previewBoard: previewBoard(state),
        isAccountProfile: selectedAccount(state) ? true : false,
        accountScreens: state.accounts.screens
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setDesignerPreviewAspectRatio: (aspectRatio) => dispatch(setDesignerPreviewAspectRatio(aspectRatio)),
        setDigitalBoard: (digitalBoard) => dispatch(setDigitalBoard(digitalBoard)),
        getExports: () => dispatch(getExports()),
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerBoardFormatTool
    );
