import { Avatar, Box, Link, makeStyles } from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import { Business } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { hasIcon } from '../../selectors/exports';
import { RoutePaths } from '../../helpers/constants';

const useStyles = makeStyles(() => {
    return {
        root: {
            display: "flex",
            alignItems: "center"
        },
        avatar: {
            width: 24,
            height: 24,
            marginRight: 8
        },
        avatarImage: {
            background: 'none'
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },

        iconVerySmall: {
            fontSize: '1rem'
        }
    }
})

const AgenceNamePhoto = ({ agency, isLink = false }) => {
    const classes = useStyles();

    const Component = isLink ? Link : Box;
    const linkProps = isLink && {
        component: RouterLink,
        to: RoutePaths.public.slug(agency.slug),
        color: "secondary",
    }

    return <Component className={classes.root} {...linkProps}>
        {hasIcon(agency)
            ? <Avatar className={`${classes.avatar} ${classes.avatarImage}`} >
                <img
                    src={agency.options.primary_image_public_url}
                    className={classes.image}
                    alt={agency.name}
                    title={agency.name}
                />
            </Avatar>
            : <Avatar className={classes.avatar}>
                <Business className={classes.iconVerySmall} />
            </Avatar>}

        <LCTypography transProps={{ agencyName: agency.name }}>{{ agencyName: agency.name }}</LCTypography>
    </Component>;
}

export default AgenceNamePhoto;
