import { Box, Button, FormHelperText, makeStyles, FormControl } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { lucitApi } from "../../services/lucitApi"; import { GenericDialog } from '../../components/modals/GenericDialog';
import { getUser, selectedProfile } from '../../selectors/user';
import ButtonLoader from '../../components/material/ButtonLoader';
import { getAgencies } from '../../actions/agencies';
import LCTypography from '../../components/material/LCTypography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            x: theme.palette.text.disabled,
            padding: theme.spacing(3)
        },
        cardWrapper: {
            boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            padding: '16px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(222, 222, 222, 0.7)'
        },
        error: {
            marginTop: theme.spacing(1)
        },
        formRows: {
            marginBottom: theme.spacing(2)
        }
    }
});

const NewDataSourceRequest = (props) => {
    const { open, handleClose, profile, user } = props;
    const classes = useStyles();

    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false)

    const [accountType, setAccountType] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhone, setContactPhone] = useState('')

    const [comments, setComments] = useState('')

    const { t } = useTranslation();

    const clearForm = () => {
        setContactName('');
        setContactEmail('');
        setAccountType('');
        setContactPhone('');
        setComments('');
        setError('')
    }

    const handlSubmit = () => {
        if (!contactName) {
            setError(t('Contact name is Required'));
            return;
        }

        if (!contactEmail) {
            setError(t('Contact email is Required'));
            return;
        }

        if (!accountType) {
            setError(t('Account type Required'));
            return;
        }

        setLoading(true)

        const message = `New Data Source Request for ${profile.name} \n\n`
            + `Contact Name: ${contactName} \n`
            + `Contact Email: ${contactEmail} \n`
            + `Contact Phone: ${contactPhone} \n`
            + `Account Type: ${accountType} \n`
            + `Comments: ${comments} \n`

        const request = {
            name: user.name,
            email: user.email,
            phone: user.mobile,
            message: message,
            user_id: user.id,
            user_name: user.name,
            account_id: profile.id,
            account_name: profile.name,

        }

        lucitApi.submitHelpRequest(request).then(() => {
            setLoading(false)
            setRequestSent(true)
            clearForm()
        }).catch(error => {
            setError(error.message)
        });
    }

    return (
        <GenericDialog
            title={t('Request a new app or data source')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>
                {requestSent ?
                    <Box className={classes.cardWrapper} pt={8} pb={8} pl={4} pr={4}>
                        <LCTypography>
                            Your request to setup a new data source has been submitted.
                            We will notify you when it is ready or if we have any questions
                        </LCTypography>
                    </Box>
                    :
                    <Box className={classes.cardWrapper}>
                        <Box mb={3}>
                            <LCTypography variant={"body2"} transProps={{ accountName: profile.name }}>
                                To request a new data source for <strong>{{ accountName: profile.name }}</strong>, please fill
                                out the form below, and we will contact you if we need additional information.
                            </LCTypography>
                        </Box>
                        <div>

                            <FormControl fullWidth className={classes.formRows}>
                                <InputLabel id="demo-simple-select-label">{t('Type of Data Source')}</InputLabel>
                                <Select
                                    labelId="account-type"
                                    id="account-type"
                                    value={accountType}
                                    label={t('Type of Data Source')}
                                    onChange={(e) => setAccountType(e.target.value)}
                                >
                                    <MenuItem value="automotive">{t('Automotive')}</MenuItem>
                                    <MenuItem value="real_estate">{t('Real Estate')}</MenuItem>
                                    <MenuItem value="motorsports">{t('Motorsports')}</MenuItem>
                                    <MenuItem value="ag_heavy_equipment">{t('Ag / Heavy Equipment')}</MenuItem>
                                    <MenuItem value="retail">{t('Retail')}</MenuItem>
                                    <MenuItem value="ecommerce">{t('E-Commerce / Website')}</MenuItem>
                                    <MenuItem value="other">{t('Other / Unknown')}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth className={classes.formRows}>
                                <TextField
                                    onChange={(e) => setContactName(e.target.value)}
                                    value={contactName}
                                    label={t('Authorized Contact Name')}
                                    helperText={
                                        t("Name of person at {{accountName}} that will authorize this data source",
                                            { accountName: profile.name })
                                    }
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formRows}>
                                <TextField
                                    onChange={(e) => setContactEmail(e.target.value)}
                                    value={contactEmail}
                                    label={t('Email Address of Authorized Contact')}
                                    helperText={t("Email address of person at {{accountName}} that will authorize this data source",
                                        { accountName: profile.name })}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formRows}>
                                <TextField
                                    onChange={(e) => setContactPhone(e.target.value)}
                                    value={contactPhone}
                                    label={t('Phone Number of Authorized Contact')}
                                    helperText={t("Phone Number of person at {{accountName}} that will authorize this data source",
                                        { accountName: profile.name })}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formRows}>
                                <TextField
                                    onChange={(e) => setComments(e.target.value)}
                                    value={comments}
                                    multiline
                                    label={t('Comments')}
                                    helperText={t(
                                        'Please describe in your best words, what type of data you are \
                                        trying to connect and how you plan to use it'
                                    )}
                                />
                            </FormControl>
                        </div>
                    </Box>
                }
                {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
            </>}

            ActionsComponent={<>
                {requestSent ?
                    <>
                        <Button onClick={handleClose}
                            color="primary">
                            {t('Ok')}
                        </Button>
                    </>
                    :
                    <>
                        <Button onClick={handleClose}
                            color="primary">
                            {t('Cancel')}
                        </Button>
                        <ButtonLoader
                            disabled={loading}
                            onClick={handlSubmit}
                            submitting={loading}
                            color="primary"
                            variant="contained">
                            {t('Send Request')}
                        </ButtonLoader>
                    </>
                }
            </>}
        />
    )
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        profile: selectedProfile(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAgencies: () => dispatch(getAgencies())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewDataSourceRequest)
