import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getCanInviteRoles, rolesSelector } from '../../selectors/roles';
import { invite } from '../../actions/users';
import { useEffect } from 'react';
import { getRoles } from '../../actions/roles';
import NewInviteDialog from './NewInviteDialog';
import { SubmissionError } from 'redux-form';
import { showError, showSuccess } from '../../actions/snackbar';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

function NewInviteButton(props) {
    const { lcuid, currentUserRoles, getRoles, rolesById, sendInvite } = props;
    const { text = "New Invite" } = props;

    const [isNewInviteOpened, setNewInviteOpened] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getRoles(lcuid);
    }, [lcuid])

    const roles = rolesById(lcuid);

    if (roles == null) {
        return null;
    }

    return (<>
        <Button
            onClick={() => setNewInviteOpened(true)}
            variant="contained"
            color="primary">
            {text}
        </Button>

        <Box mt={2} />
        <NewInviteDialog
            lazy
            roles={getCanInviteRoles(currentUserRoles, roles)}
            open={isNewInviteOpened}
            title={t('New Invite')}
            handleClose={() => setNewInviteOpened(false)}
            onNewInvite={(values) => sendInvite(lcuid, values)
                .then(() => setNewInviteOpened(false))}
        />
    </>);
}

NewInviteButton.propTypes = {
    lcuid: PropTypes.string.isRequired,
    text: PropTypes.string,

    currentUserRoles: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {
        rolesById: lcuid => rolesSelector(state)(lcuid)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: lcuid => dispatch(getRoles(lcuid)),

        sendInvite: (lcuid, values) => {
            // eslint-disable-next-line no-undef
            let promise = dispatch(invite(lcuid,
                values.invites,
                values.roles.map(role => role.id)));

            return promise
                .then(() => dispatch(showSuccess(i18next.t('Invite was successfuly sent to {{user}}',
                    { user: values.invites }))))
                .catch(error => {
                    if (error instanceof SubmissionError) {
                        throw error;
                    }
                    dispatch(showError(i18next.t('Error sending invite, please try again')))
                });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    NewInviteButton
);
