import React, { useState, useRef } from 'react';
import { Typography, makeStyles, GridList, GridListTile, Box, Link } from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import { PhotoLibrary } from '@material-ui/icons';
import clx from 'classnames';

import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePaths } from '../../helpers/constants';
import { ImageGalleryDialog } from '../modals';
import { Avatar } from '../material';
import { hasLucitXRImages } from '../../selectors/inventory'
import { useSize } from '../../helpers/hooks';
import { ImagePreviewDialog } from '../../components';
import { IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { LucitXR } from '../images';

const useStyles = makeStyles(theme => {
    return {
        img: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },
        imgContain: {
            width: '100%',
            // maxWidth: 400,
            objectFit: 'contain',
            objectPosition: 'center'
        },

        morePhotosImg: {
            filter: 'brightness(0.5) blur(2px)'
        },

        morePhotosBox: {
            display: "flex",
            position: "absolute",
            cursor: 'pointer',
            top: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
            color: theme.palette.common.white,

            justifyContent: "center",
            alignItems: "center"
        },
    }
})

const hasPhotos = (story) => {
    return story
        && story.story_data
        && story.story_data.inventory_photos
        && story.story_data.inventory_photos.length > 0
}

const getOperator = (story) => {

    if (story.grouped) {
        return story.stories[0].story.story_data.agency
    }

    return story.story_data.agency
}

const OperatorLink = ({ operator }) => {

    if (!operator)
        return null

    return <Link
        variant='body1'
        component={RouterLink}
        to={RoutePaths.public.slug(operator.slug)}
        color="secondary">
        {operator.name}
    </Link>
}

const ClickableZoomedCreative = ({ creativeToBoardImage, viewportWidth, margin }) => {

    const [isZoomed, setIsZoomed] = useState(false);

    const getZoomSource = () => {
        return creativeToBoardImage
            && creativeToBoardImage.image
            && creativeToBoardImage.image.options
            && creativeToBoardImage.image.options.public_url
    }

    return <>
        <div
            style={{ cursor: "pointer" }}
            onClick={() => setIsZoomed(!isZoomed)}
        >
            <LucitXR
                creative={creativeToBoardImage}
                viewportWidth={viewportWidth}
                margin={margin}
            />
        </div>
        <ImagePreviewDialog
            open={isZoomed}
            handleClose={() => setIsZoomed(false)}
        >
            <IonImg src={getZoomSource()} alt="zoomed" />
        </ImagePreviewDialog>
    </>
}

const PostedCreativeItemStory = (props) => {
    const { story, ...rest } = props;
    const account = story.storyable;

    const containerRef = useRef()
    const { width } = useSize(containerRef)
    const { t } = useTranslation();

    if (!story.grouped && !hasPhotos(story)) {
        return null;
    }

    if (!account)
        return null

    const operator = getOperator(story)

    const getImageSrc = () => {

        if (story.grouped || hasLucitXRImages(story.story_data))
            return null;

        return story.story_data.inventory_photos[0].options.public_url
    }

    const getMedia = () => {
        if (story.grouped)
            return <Media story={story} />

        if (hasLucitXRImages(story.story_data))
            return <div ref={containerRef} style={{ width: "100%" }}>
                <ClickableZoomedCreative
                    creativeToBoardImage={story.story_data.creatives_to_board_images[0]}
                    viewportWidth={width ? width : 600}
                    margin={0.5}
                />
            </div>

        return null
    }

    return <BaseStory
        title={account.name}
        sharableText={`${account.name} posted creatives to ${operator ? operator.name : 'billboard'}`}
        story={story}
        Avatar={<Avatar
            alt={account.name}
            name={account.name}
            src={hasIcon(account) && account.options.primary_image_public_url}
        />}
        Media={getMedia()}
        imageSrc={getImageSrc()}
        StorySubMedia={
            operator
            && !operator.options.model_is_demo
            && <Typography>{t('Posted to ')}<OperatorLink operator={operator} /></Typography>
        }
        {...rest}
    >
    </BaseStory>;
};

const Media = (props) => {
    const { story } = props;
    const classes = useStyles();

    const [galleryOpen, setGalleryOpen] = useState(false);

    if (!story.grouped) {
        return null;
    }

    const containerRef = useRef()
    const { width } = useSize(containerRef)

    const stories = story.stories
        .map(x => x.story)
        .filter(x => x.story_data && x.story_data.inventory_photos && x.story_data.inventory_photos.length);

    const maxPhotos = 4;

    const getBestImage = (story_data, hasMorePhotos) => {

        if (hasLucitXRImages(story_data))
            return <div ref={containerRef} style={{ width: "100%" }}>
                <ClickableZoomedCreative
                    creativeToBoardImage={story_data.creatives_to_board_images[0]}
                    viewportWidth={width ? width : 600}
                    margin={0.5}
                />
            </div>
        else
            return <IonImg
                src={story_data.inventory_photos[0].options.public_url}
                className={clx(classes.imgContain, { [classes.morePhotosImg]: hasMorePhotos })}
                style={{ height: '100%' }}
                alt={story_data.inventory_item.title} />

    }

    return <>
        <GridList cellHeight={120} gap={0.5} className={classes.imageList}>
            {stories
                .slice(0, maxPhotos)
                .map((x, idx) => {
                    const hasMorePhotos = idx == maxPhotos - 1
                        && stories.length > maxPhotos;

                    if (!x.story_data || !x.story_data.inventory_photos[0] || !x.story_data.inventory_photos[0].options)
                        return null

                    return (
                        <GridListTile key={x.id}
                            to={RoutePaths.public.slug(x.story_data.inventory_photos[0].id)}
                            cols={2}>

                            {getBestImage(x.story_data, hasMorePhotos)}

                            {hasMorePhotos
                                && <Box className={classes.morePhotosBox}
                                    onClick={() => setGalleryOpen(true)}>
                                    {/* TODO: think about how make it better looking,
                                              maybe use gaussian blur */}
                                    <LCTypography transProps={{ storiesLengthMax: stories.length - maxPhotos }} variant="h4">
                                        +{{ storiesLengthMax: stories.length - maxPhotos }}
                                    </LCTypography>
                                    <Box ml={1}>
                                        <PhotoLibrary />
                                    </Box>
                                </Box>}
                        </GridListTile>
                    );
                })}
        </GridList>

        <ImageGalleryDialog
            open={galleryOpen}
            handleClose={() => setGalleryOpen(false)}
            photos={stories.map(x => hasPhotos(x) && x.story_data.inventory_photos[0].options
                && x.story_data.inventory_photos[0].options.public_url)}
        />

    </>;
}

export default PostedCreativeItemStory;
