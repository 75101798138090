import React from 'react';
import { Grid } from '@material-ui/core';
import LucitFormField from './LucitFormField';

export const LucitFormFields = ({ fields, feedParamValues, onChange }) => {

    return <Grid container spacing={5}>
        {fields.map((field, index) => {
            return <Grid key={index} item xs={field.grid_size ?? 4}><LucitFormField

                field={field}
                value={feedParamValues[field.key]}
                onChange={(value) => {
                    onChange(field.key, value)
                }}
            /></Grid>
        })}
    </Grid>

}

export default LucitFormFields;
