import { createSelector } from "reselect";

const photos = state => state.photos;

export const photoByIdSelector = createSelector(
    [photos],
    photos => id => photos.byId[id]
)

export const createPlaceholderPhotoImage = (w, h) => {
    var img = document.createElement('img');
    img.setAttribute('style', 'width:' + w + 'px;height:' + h + 'px;border:none;display:block');
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
    return img;
}
