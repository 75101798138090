import React from 'react';
import { InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export const SearchInput = ({ search, setSearch, placeholder = "Filter..." }) => {
    const { t } = useTranslation();

    return (<TextField
        autoFocus
        fullWidth
        size="small"
        variant="outlined"
        placeholder={t(placeholder)}
        inputProps={{
            "aria-autocomplete": "both",
            "aria-haspopup": "false",
            "autoComplete": "off",
            "spellCheck": "false"
        }}
        value={search || ''}
        onChange={(event) => {
            setSearch(event.target.value)
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Tooltip title={t('Filter')}>
                    <Search fontSize="small" />
                </Tooltip>
            </InputAdornment>
        }}
    />)
}
