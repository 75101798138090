import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react';
import { Box, Button, CircularProgress, IconButton, Typography, useTheme, Chip } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteBotUser, getApplicationBots, resetBotUserSecret } from '../../actions/applications';
import { ConfirmationDialog, CopyButton } from '../../components';

import CreateBotButton from './CreateBotButton';
import { Delete, Refresh } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import TokenInfoDialog from './TokenInfoDialog';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { UserTokenTypes } from '../../helpers/constants';
import LCTypography from '../../components/material/LCTypography';
import { searchTermInText } from '../../helpers/string';
import TextField from "@material-ui/core/TextField";

const UserTokenTypeChip = ({ userTokenType }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    if (userTokenType === UserTokenTypes.public) {
        return <Chip
            size="small"
            style={{ background: theme.palette.success.main, color: theme.palette.common.white, marginRight: "15px" }}
            label={t('Public')}
        />;
    }

    if (userTokenType === UserTokenTypes.auth) {
        return <Chip
            size="small"
            style={{ background: theme.palette.warning.main, color: theme.palette.common.white, marginRight: "22px", marginLeft: "3px" }}
            label={t('Auth')}
        />;
    }

    return null;
}

const ApplicationTokens = ({ application, getApplicationBots, resetBotUserSecret, deleteBotUser }) => {
    const [loading, setLoading] = useState(false);
    const [resetingUser, setResetingUser] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [resetting, setResetting] = useState(false);
    const [deletting, setDeleting] = useState(false);
    const [newSecret, setNewSecret] = useState(null);

    const theme = useTheme();
    const { t } = useTranslation();

    const [query, setQuery] = useState('');

    const [showQueryBox, setShowQueryBox] = useState(false);

    const tokenMatchesQuery = (bot) => {
        return searchTermInText(query, [
            bot.name,
            toLocalDateTime(bot.created_at).format("MM/DD/YYYY hh:mm A"),
            bot.api_token
        ]);
    };

    useEffect(() => {
        setQuery('');
    }, [])

    useEffect(() => {

        const TOKEN_COUNT_BEFORE_SHOWING_SEARCH = 12;

        if (application.bots && application.bots.length > TOKEN_COUNT_BEFORE_SHOWING_SEARCH)
            setShowQueryBox(true);
    }, [application.bots])

    useEffect(() => {
        setLoading(true);
        getApplicationBots(application)
            .finally(() => setLoading(false));
    }, [application.lcuid])

    const hasBots = application.bots && application.bots.length > 0;

    const nameForNewToken = hasBots
        ? (application.name + ' Token ') + (application.bots.length + 1)
        : application.name + ' Token';

    const showResetSecretButtonForBot = (botUser) => {
        return botUser.options && botUser.options.user_token_type === UserTokenTypes.auth;
    }

    return <>
        <IonCard style={{ color: 'unset' }}>
            <Box p={2}>
                <CreateBotButton application={application} defaultName={nameForNewToken} />
                <Box mt={2} />
                <Typography variant="h6">Tokens</Typography>
                <IonList>
                    {loading
                        && <CircularProgress size={40} />}
                    {!loading
                        && !hasBots
                        && <LCTypography><i>You don&apos;t have any tokens yet</i>
                            <br /><br />Tokens are required for your app to access the Lucit API or to use any of the Lucit Widgets.
                            <br />
                            <br />To get started, create a new token</LCTypography>}

                    {showQueryBox &&
                        <IonItem>
                            <Box mt={2} mb={2} mr={1} style={{width:"100%"}}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Search')}
                                    value={query}
                                    onChange={e => setQuery(e.target.value)}
                                />
                            </Box>
                        </IonItem>
                    }
                    {!loading
                        && hasBots
                        && application.bots.filter(x=>tokenMatchesQuery(x)).map(x => <>
                            <IonItem key={x.lcuid} style={{ marginBottom: "24px" }}>
                                <IonLabel class="ion-text-wrap" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div>
                                        <h1>{x.name}</h1>
                                        <LCTypography style={{ color: theme.palette.grey[500] }}>
                                            <UserTokenTypeChip userTokenType={x.options && x.options.user_token_type} />
                                            created:&nbsp;<DateTimeRelative date={toLocalDateTime(x.created_at)} />
                                        </LCTypography>
                                        <code style={{ textTransform: "none" }}>{x.api_token}</code>
                                    </div>
                                    <CopyButton
                                        slot="end"
                                        title={t('Copy token')}
                                        size='small'
                                        color="secondary"
                                        variant="outlined"
                                        text={x.api_token}
                                        style={{ marginLeft: 'auto', alignSelf: 'center' }}
                                    />
                                </IonLabel>

                                <Button slot="end"
                                    onClick={() => setResetingUser(x)}
                                    disabled={!showResetSecretButtonForBot(x)}
                                    size='small'
                                    variant="outlined"
                                    startIcon={<Refresh />}
                                    style={{ marginLeft: 8 }}
                                >
                                    {t('Reset Secret')}
                                </Button>
                                <IconButton
                                    onClick={() => setDeletingUser(x)}
                                    size='small'
                                    style={{ marginLeft: 8, color: theme.palette.error.main }}
                                    slot="end">
                                    <Delete />
                                </IconButton>
                            </IonItem>
                        </>)}
                </IonList>
            </Box>
        </IonCard>

        <ConfirmationDialog
            title={`Reset secret for ` + (resetingUser && resetingUser.name)}
            dialogProps={{
                open: Boolean(resetingUser),
                onClose: () => setResetingUser(null),
                keepMounted: false
            }}
            ConfirmButtonText={t('Reset')}
            ConfirmButtonProps={{
                disabled: resetting,
                variant: "outlined",
                style: { color: theme.palette.error.main, borderColor: theme.palette.error.main }
            }}
            handleCancel={() => setResetingUser(null)}
            handleConfirm={() => {
                setResetting(true);
                resetBotUserSecret(application, resetingUser)
                    .then((secret) => {
                        setNewSecret(secret);
                    })
                    .finally(() => setResetting(false))
            }}>
            {t('The current secret will no longer work after resetting.')}<br />
            {t('Are you sure you want to reset this secret?')}
        </ConfirmationDialog>

        <TokenInfoDialog
            open={Boolean(newSecret)}
            handleClose={() => setNewSecret(null)}
            token={resetingUser && resetingUser.api_token}
            secret={newSecret} />

        <ConfirmationDialog
            title={t('Delete {{name}}', { name: deletingUser && deletingUser.name })}
            dialogProps={{
                open: Boolean(deletingUser),
                onClose: () => setDeletingUser(null),
                keepMounted: false
            }}
            ConfirmButtonText={t('Delete')}
            ConfirmButtonProps={{
                disabled: deletting,
                variant: "outlined",
                style: { color: theme.palette.error.main, borderColor: theme.palette.error.main }
            }}
            handleCancel={() => setDeletingUser(null)}
            handleConfirm={() => {
                setDeleting(true);
                deleteBotUser(application, deletingUser)
                    .then(() => setDeletingUser(null))
                    .finally(() => setDeleting(false))
            }}>
            {t('Your current integration may stop working after this action.')} <br />
            {t('Are you sure you want to delete token?')}
        </ConfirmationDialog>
    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApplicationBots: application => dispatch(getApplicationBots(application)),

        resetBotUserSecret: (application, user) => dispatch(resetBotUserSecret(application, user)),
        deleteBotUser: (application, user) => dispatch(deleteBotUser(application, user))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ApplicationTokens
);
