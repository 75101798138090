import { isDevelopment } from "./environment";

(function () {
    var _log = console.log;

    console.log = function () {
        if (isDevelopment()) {
            _log.apply(console, arguments);
        }
    };
})();