import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    DialogContent, DialogActions, Button, makeStyles, useTheme, useMediaQuery,
    TextField,
    Typography,
    Box
} from '@material-ui/core';
import { ButtonLoader, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedAgency } from '../../selectors/user';
import { useDispatch } from 'react-redux';
import {
    loadAgencyLightningDevices
} from '../../actions/lightning';
import { DeviceCodeField } from './NewDeviceDialog';
import { lucitApi } from '../../services/lucitApi';
import { showSuccess } from '../../actions/snackbar';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const LinkDeviceDialog = ({ open, handleClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const agency = useSelector(selectedAgency);
    const devices = useSelector((state) => state.lightning.devices);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [device, setDevice] = useState(null);
    const [deviceRequest, setDeviceRequest] = useState(null);
    const [loading, setLoading] = useState(false);

    const linkDevice = () => {
        setLoading(true);
        lucitApi.lightning.attachToDevice(deviceRequest.lcuid, device.lcuid)
            .then(() => {
                handleClose();
                dispatch(showSuccess(`Your device was successfully linked to ${device.name} (${device.device_number})`))
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!agency)
            return;

        dispatch(loadAgencyLightningDevices(agency.lcuid));
    }, [agency])

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}
            title={t('Link Device')}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <Box mb={2}>
                    <Typography>
                        {t(`Link your physical player to an existing Lightning Device in our system.
                            Note: selecting a device that is already connected to another physical player will disable that player. `)}
                        <br />
                        <br />
                        {t('Please choose a Lightning Device to proceed.')}
                    </Typography>
                </Box>
                <Autocomplete
                    style={{ maxWidth: 500 }}
                    fullWidth
                    value={device}
                    onChange={(_, newValue) => setDevice(newValue)}
                    options={devices}
                    getOptionLabel={option => option.name + ' (' + option.device_number + ')'}
                    getOptionSelected={option => parseInt(device?.id) === parseInt(option.id)}
                    renderInput={(params) => <TextField variant='outlined' label={t('Lightning Device')} {...params} />}
                />

                {device
                    && <Box mt={2} maxWidth={500}>
                        <DeviceCodeField onConfirmed={setDeviceRequest} />
                    </Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    disabled={deviceRequest == null}
                    submitting={loading}
                    onClick={linkDevice}
                    variant='contained'
                    color="primary">
                    {t('Link')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

export default LinkDeviceDialog
