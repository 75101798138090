import { useEffect, useState, useCallback } from "react";

export function useDragNDrop({
    element = document,
    onDrop,
}) {
    const [isDraggingOver, setDraggingOver] = useState(false);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggingOver(true)
    }, []);
    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggingOver(false)
    }, []);

    const handleDrop = useCallback((e) => {
        const { files } = e.dataTransfer;

        e.preventDefault();
        e.stopPropagation();
        setDraggingOver(false);

        if (files && files.length) {
            onDrop(files);
        }
    }, [onDrop]);

    useEffect(() => {
        if (element) {
            element.addEventListener('dragover', handleDragOver);
            element.addEventListener('dragleave', handleDragLeave);
            element.addEventListener('drop', handleDrop);

            return () => {
                element.removeEventListener('dragover', handleDragOver);
                element.removeEventListener('dragleave', handleDragLeave);
                element.removeEventListener('drop', handleDrop);
            };
        }
    }, [handleDragOver, handleDragLeave, handleDrop]);

    return {
        isDraggingOver
    }
}
