import React from 'react';
import { Box, Tooltip, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatNumber } from '../../helpers/string';
import { HelpOutline } from '@material-ui/icons';
import moment from 'moment';

function InvoiceLineItem(props) {
    const { item } = props;

    if (item.invoice_line_item_class == 'App\\LuCore\\Invoices\\InvoiceLineItemClassCredit') {
        return <CreditLineItem {...props} />
    }

    //App\LuCore\Invoices\InvoiceLineItemClassLightningFees
    if (item.invoice_line_item_class == 'App\\LuCore\\Invoices\\InvoiceLineItemClassLightningFees') {
        return <LightningFeesLineItem {...props} />;
    }

    return <DefaultLineItem {...props} />;
}

const CreditLineItem = (props) => {
    const { item, column, ...rest } = props;
    const theme = useTheme();

    if (column == 'title') {
        return <Box display="flex" style={{ color: theme.palette.success.main }}>
            <Typography  {...rest}>
                {item.title}
            </Typography>
            <Tooltip title={"This is credit line"}>
                <HelpOutline fontSize='small' style={{ marginLeft: 8 }} />
            </Tooltip>
        </Box>
    }

    return <DefaultLineItem {...props} style={{ color: theme.palette.success.main }} />;
}

const LightningFeesLineItem = ({ item, column, ...rest }) => {
    if (column == 'title')
        return <Typography {...rest}>{item.title}</Typography>;

    if (column == 'period') {
        const fromDate = moment(item.from_date);
        const toDate = moment(item.to_date);

        return fromDate.isSame(toDate, 'year')
            ? <Typography {...rest}>
                {fromDate.format('MMM DD')} - {toDate.format('MMM DD')}
            </Typography>
            : <Typography {...rest}>
                {fromDate.format('MMM DD, yyyy')} - {toDate.format('MMM DD, yyyy')}
            </Typography>;
    }

    if (column == 'amount')
        return <Typography {...rest}>{formatNumber(item.amount, { style: 'currency', currency: 'USD' })}</Typography>;

    if (column == 'unit_price')
        return <Typography {...rest}>{formatNumber(item.unit_price, { style: 'currency', currency: 'USD' })}</Typography>;

    if (column == 'units')
        return <Typography {...rest}>{Math.floor(item.units)} Devices</Typography>;

    return null;
}

const DefaultLineItem = ({ item, column, ...rest }) => {
    if (column == 'title')
        return <Typography {...rest}>{item.title}</Typography>;

    if (column == 'period') {
        const fromDate = moment(item.from_date);
        const toDate = moment(item.to_date);

        return fromDate.isSame(toDate, 'year')
            ? <Typography {...rest}>
                {fromDate.format('MMM DD')} - {toDate.format('MMM DD')}
            </Typography>
            : <Typography {...rest}>
                {fromDate.format('MMM DD, yyyy')} - {toDate.format('MMM DD, yyyy')}
            </Typography>;
    }

    if (column == 'amount')
        return <Typography {...rest}>{formatNumber(item.amount, { style: 'currency', currency: 'USD' })}</Typography>;

    return null;
}

InvoiceLineItem.propTypes = {
    item: PropTypes.object.isRequired,
    column: PropTypes.string.isRequired
}
export default InvoiceLineItem;
