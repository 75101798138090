import React from 'react';
import { Route } from "react-router";

import LucitRoute from './LucitRoute';
import { useSelector } from 'react-redux';
import { ProfileType } from '../../helpers/constants';
import { HomeLayout } from '../../layouts/HomeLayout';
import { Box, Typography } from '@material-ui/core';

export const RouteAccount = (props) => {
    const { component: Component, sheetOpenDefault, ...rest } = props;
    const profileType = useSelector(state => state.user.profileType);

    return <Route
        {...rest}
        key={Date.now()}
        render={(props) => {
            return (
                profileType.id == ProfileType.account.id
                    ? <LucitRoute component={Component}
                        requireAuth={true}
                        sheetOpenDefault={sheetOpenDefault} {...props} />
                    : <HomeLayout>
                        <Box p={2}
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h5">Please select any Account to view this page</Typography>
                        </Box>
                    </HomeLayout>
            )
        }} />
}

export default RouteAccount;
