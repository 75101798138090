import { apiHttp } from "../helpers/api";
import LucitApiExports from "./lucitApi.exports";

export default class LucitApiFeeds extends LucitApiExports {
    getFeeds(data) {
        return apiHttp.get(`/inventory-feeds`, { params: this.prepareFeedsFilters(data) })
            .then(response => response.data.inventory_feeds);

    }

    getFeedsForAccountAndApplication(accountId, applicationId) {
        return apiHttp.get(`/inventory-feeds`, {
            params: {
                account_id: accountId,
                application_id: applicationId
            }
        })
            .then(response => response.data.inventory_feeds);

    }

    getActiveFeedsForAccount(account_id) {
        return apiHttp.get(`/inventory-feeds`, { params: { account_id: account_id, active: 1 } })
            .then(response => response.data.inventory_feeds);

    }

    getFeed(id) {
        return apiHttp.get(`/inventory-feeds/${id}`)
            .then(response => response.data.inventory_feed);

    }

    getFeedRuns(feedId, data) {
        return apiHttp.get(`/inventory-feeds/${feedId}/runs`, { params: data })
            .then(response => response.data.inventory_feed_runs);

    }

    getFeedRun(runId) {
        return apiHttp.get(`/inventory-feeds/run/${runId}`)
            .then(response => response.data.inventory_feed_run);
    }

    getFeedRunReaderFields(runId) {
        return apiHttp.get(`/inventory-feeds/run/${runId}/reader-fields`)
            .then(response => response.data.reader_fields);
    }

    run(feedId) {
        return apiHttp.put(`/inventory-feeds/${feedId}/make-runnable`, {})
            .then(response => response.data);
    }

    getFeedInventoryItems(feedId, active = 1) {
        return apiHttp.get(`/inventory-feeds/${feedId}/inventory-items`, { params: { active: active } })
            .then(response => response.data);
    }

    getFeedKeyValueStores(feedId, active = 1) {
        return apiHttp.get(`/inventory-feeds/${feedId}/key-value-stores`, { params: { active: active } })
            .then(response => response.data.key_value_stores);
    }

    setDefaultDriveTemplate(feedId, driveTemplateLcuid) {
        return apiHttp.put(`/inventory-feeds/${feedId}/set-default-drive-template`, {
            default_drive_template_lcuid: driveTemplateLcuid
        })
            .then(response => response.data);
    }

    clearDefaultDriveTemplate(feedId) {
        return apiHttp.put(`/inventory-feeds/${feedId}/clear-default-drive-template`)
            .then(response => response.data);
    }

    setMultipleDriveTemplates(feedId, templates) {
        return apiHttp.put(`/inventory-feeds/${feedId}/set-multiple-drive-templates`, {
            templates: JSON.stringify(templates)
        })
            .then(response => response.data);
    }

    prepareFeedsFilters(data) {
        const { search, accountId, status, ...rest } = data;

        return {
            query: search,
            account_id: JSON.stringify(accountId),
            active: 1,
            status: status != null
                ? status
                : undefined,

            ...rest
        };
    }

    /** data supports name,account_id,inventory_feed_provider_id */
    createFeed(data) {
        return apiHttp.post(`/inventory-feeds`, data)
            .then(response => response.data.inventory_feed);
    }

    /**data supports name,schedule */
    updateFeed(feedId, data) {
        return apiHttp.put(`/inventory-feeds/${feedId}`, data)
            .then(response => response.data.inventory_feed);
    }

    deleteInventoryForFeed(feedId) {
        return apiHttp.delete(`/inventory-feeds/${feedId}/delete-all-inventory-items`)
            .then(response => response.data);
    }

    setFeedParamValues(feedId, feedParamValues) {
        return apiHttp.put(`/inventory-feeds/${feedId}/set-feed-param-values`, {
            feed_param_values: JSON.stringify(feedParamValues) ?? '{}'
        })
            .then(response => response.data.inventory_feed);
    }

    setFeedSettingsOverrides(feedId, feedSettingsOverrides) {
        return apiHttp.put(`/inventory-feeds/${feedId}/set-feed-settings-overrides`, {
            feed_settings_overrides: JSON.stringify(feedSettingsOverrides) ?? '{}'
        })
            .then(response => response.data.inventory_feed);
    }

    setActive(feedId, active) {
        return apiHttp.put(`/inventory-feeds/${feedId}/setActive`, {
            active: active
        })
            .then(response => response.data.inventory_feed);
    }

    testRunRetriever(feedId) {
        return apiHttp.get(`/inventory-feeds/${feedId}/runRetriever`, {})
            .then(response => response.data);
    }

    testRunReader(feedId) {
        return apiHttp.get(`/inventory-feeds/${feedId}/runReader`, {})
            .then(response => response.data);
    }

    testRunConverter(feedId) {
        return apiHttp.get(`/inventory-feeds/${feedId}/runConverter`, {})
            .then(response => response.data);
    }

}
