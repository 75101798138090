import { Popover, Button, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React, { useState } from 'react';
import { default as BestColorPicker } from 'react-best-gradient-color-picker'
import PaletteIcon from '@material-ui/icons/Palette';

const LucitFormFieldColor = ({ field, onChange, value }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    return <>
        <FormControl>

            <InputLabel htmlFor={field.key}>{field.name}</InputLabel>

            <ToggleButton
                id={field.key}
                size="small"
                value="showPicker"
                selected={Boolean(anchorEl)}
                onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
                style={{ color: value, marginTop: "44px" }}
            >
                {<PaletteIcon />}
            </ToggleButton>

            <FormHelperText>{field.description}</FormHelperText>

        </FormControl>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <BestColorPicker
                value={value}
                height={150}
                hideColorTypeBtns={true}
                onChange={(color) => onChange(color)}
            />
            <Button
                style={{ marginTop: "10px", float: "right" }}
                onClick={() => onChange("")}>
                Clear
            </Button>
        </Popover>
    </>
}

export default LucitFormFieldColor;
