import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InventoryItemActions from '../containers/inventory/itemDetails/InventoryItemActions';
import { Paper, makeStyles, Box, Link } from '@material-ui/core';

import { withHomeLayout } from '../layouts/HomeLayout';
import { getItem, getItemExportStatus } from '../actions/inventory';
import InventoryItemDetails from '../containers/inventory/InventoryItem';
import { RoutePaths } from '../helpers/constants';
import InventoryItemSkeleton from '../containers/inventory/InventoryItemSkeleton';
import { showError } from '../actions/snackbar';
import { history } from '../helpers/history';
import { itemStatusResult } from '../actions/inventoryStatus';
import { isDeleted, itemByIdSelector } from '../selectors/inventory';
import { getFeed } from '../actions/feeds';
import { selectedAccountInventoryClasses } from '../selectors/user';
import { getRejectionCodes } from '../actions/approvals';
import { PageTitle } from '../components';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        }
    }
})

function InventoryItem(props) {
    const { item, getItem, getFeed, getItemExportStatus, match, accountInventoryClass,
        loadRejectionCodes } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const itemId = match.params.id;
    const feedId = item ? item.feed_id : null;

    useEffect(() => {
        if (props.isDeleted) {
            return null;
        }

        setLoading(true);

        // eslint-disable-next-line no-undef
        Promise.all([
            getItem(itemId, accountInventoryClass),
            getItemExportStatus(itemId)
        ])
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [getItem, getItemExportStatus, itemId])

    useEffect(() => {
        if (feedId) {
            setLoading(true);
            getFeed(feedId)
                .finally(false);
        }
    }, [getFeed, feedId])

    useEffect(() => {
        loadRejectionCodes();
    }, [])

    if (loading || props.isDeleted) {
        return <Paper className={classes.root}>
            <InventoryItemSkeleton />
        </Paper>
    }

    return (
        <Paper className={classes.root}>
            <PageTitle title={item ? item.title : `Inventory Item: ${itemId}`} />
            <Box mb={3}>
                <Link
                    component="button"
                    onClick={() => history.length
                        ? history.goBack()
                        : history.push({ pathname: RoutePaths.inventory })}>
                    <Box className={classes.back}>
                        <ArrowBackIcon className={classes.backIcon} />
                        Back
                    </Box>
                </Link>
            </Box>

            <Box mb={4}>
                <InventoryItemActions item={item} />
            </Box>

            <InventoryItemDetails item={item} />

        </Paper>
    );
}

const mapStateToProps = (state, { match }) => {
    const itemId = match
        ? +match.params.id
        : null;

    return {
        item: itemByIdSelector(state)(itemId),
        isDeleted: isDeleted(state)(itemId),
        accountInventoryClass: selectedAccountInventoryClasses(state)[0]
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getItem: (id, accountInventoryClass) => dispatch(getItem(id, true))  //build creatives if not found
            .catch(error => {
                if (error.isOffline) {
                    ownProps.history.push({ pathname: RoutePaths.noInternet });
                    throw error;
                }

                dispatch(showError(`Inventory item ${id} was not found.`));
                ownProps.history.push({ pathname: accountInventoryClass.route });
                throw error;
            }),

        getItemExportStatus: id => dispatch(getItemExportStatus(id))
            .then(data => dispatch(itemStatusResult(data))),

        getFeed: id => dispatch(getFeed(id)),

        loadRejectionCodes: () => {
            return dispatch(getRejectionCodes());
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(InventoryItem)
    )
);
