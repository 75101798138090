import React from 'react';
import clx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, Fade, Typography, makeStyles } from '@material-ui/core';
import { PROGRAMMATIC_INITIAL_VIEW_CHANGE } from '../../helpers/actionTypes';
import LocationFilter from './LocationFilter';

const useStyles = makeStyles((theme) => {
    return {
        backdrop: {
            zIndex: 2
        },
        filtersBox: {
            position: 'absolute',
            width: '100%',
            maxWidth: 700,
            zIndex: 3,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        skipBox: {
            width: 'fit-content',
            display: 'flex',
            margin: 'auto',
            color: theme.palette.common.white,
            textDecoration: 'underline',
            padding: '4px 8px',
            marginTop: 4,
            borderRadius: 4,
            cursor: 'pointer'
        }
    }
})

const ProgrammaticInitialFilter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialViewActive = useSelector(state => state.programmatic.initial_view_active);
    const disableInitialView = () => {
        dispatch({
            type: PROGRAMMATIC_INITIAL_VIEW_CHANGE,
            initial_view_active: false
        })
    }

    return <>
        <Backdrop open={initialViewActive} transitionDuration={1000} className={classes.backdrop} />
        <Fade in={initialViewActive} transitionDuration={1000}>
            <Box className={clx(classes.filtersBox)}>
                <LocationFilter isBig={true} />
                {initialViewActive
                    && <Box className={classes.skipBox}
                        onClick={() => disableInitialView()}>
                        <Typography variant='body2'>Skip, to browse the map</Typography>
                    </Box>}
            </Box >
        </Fade>
    </>
};

export default ProgrammaticInitialFilter;
