import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { isLoggedIn } from '../../selectors/user';
import { lucitApi } from '../../services/lucitApi';
import CreativeProof from './CreativeProof';

const CreativeProofPage_old = ({ isLoggedIn }) => {
    const { params } = useRouteMatch();
    const [data, setData] = useState(null);

    useEffect(() => {
        lucitApi.objects.get(params.lcuid, isLoggedIn)
            .then(data => lucitApi.inventory.getProofData(data.object.id))
            .then(data => setData(data))
    }, [params.lcuid])

    return <CreativeProof lcuid={params.lcuid} data={data} />
};

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state)
    }
}

export default connect(mapStateToProps)(CreativeProofPage_old);
