import { Box, Button, Chip, Grid, Typography, ButtonGroup, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FeedIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateFeedName } from '../../actions/feeds';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import LCTypography from '../../components/material/LCTypography';
import { hasIcon } from '../../selectors/feeds';
import FeedStatusIcon from './FeedStatusIcon';
import { MoreVert } from '@material-ui/icons';
import { RoutePaths } from '../../helpers/constants';
import FeedDeleteInventoryButton from '../../containers/feeds/FeedDeleteInventoryButton';
import FeedDeleteButton from '../../containers/feeds/FeedDeleteButton';
import FeedProviderAvatar from '../../containers/applications/FeedProviderAvatar';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain',
            maxHeight: 75
        },
        imageIcon: {
            width: '100%',
            height: '100%',
            maxHeight: 150,
            fill: theme.palette.action.active
        },
        inactiveChip: {
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1)
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left',
            display: 'flex',
            alignItems: 'flex-start'
        },
    }
})

const AdvancedSettings = ({ feed }) => {

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <ButtonGroup size='small'>
            <Tooltip title={t('Advanced Settings / Options')}>
                <Button
                    size="small"
                    onClick={handleClick}
                >
                    <MoreVert />
                </Button>
            </Tooltip>
        </ButtonGroup>

        <Menu
            id="advanced-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            <MenuItem>
                <FeedDeleteInventoryButton feed={feed} />
            </MenuItem>
            <MenuItem>
                <FeedDeleteButton feed={feed} redirectPathname={RoutePaths.appsAndData} />
            </MenuItem>
        </Menu>

    </>

}

function FeedDetails(props) {
    const { item, updateFeedName } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={3} lg={2} alignContent="center">
                {hasIcon(item)
                    && <FeedProviderAvatar feedProvider={item.inventoryFeedProvider} style={{ width: 150, height: 150, margin: "auto" }} />
                }
                {!hasIcon(item)
                    && <FeedIcon className={classes.imageIcon} />}
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
                <Box mb={0}>
                    <TextFieldEditable
                        ViewComponent={<Typography variant="h5">
                            {item.name}
                            {!item.active
                                && <Chip
                                    className={classes.inactiveChip}
                                    label={t('Inactive')} />}
                        </Typography>}
                        variant={"outlined"}
                        value={item.name || ""}
                        handleDone={name => {
                            updateFeedName(item.id, name)
                        }}
                        fullWidth={true}
                    />
                </Box>
                <Box mb={2} className={classes.attribute}>
                    <Typography variant="body2" className={classes.attributeValue}>
                        <i>{item.inventoryFeedProvider && item.inventoryFeedProvider.name}</i>
                    </Typography>
                </Box>
                <Box mb={2} className={classes.attribute}>
                    <LCTypography className={classes.attributeTitle}>Status:</LCTypography>
                    <Box className={classes.attributeValue}>
                        <FeedStatusIcon feedId={item.id} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <AdvancedSettings feed={item} />
            </Grid>
        </Grid >
    );
}

FeedDetails.propTypes = {
    item: PropTypes.object.isRequired,
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateFeedName: (id, name) => dispatch(updateFeedName(id, name)),

    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        FeedDetails
    )
);
