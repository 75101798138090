import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { lucitApi } from '../../services/lucitApi';
import { RoutePaths } from '../../helpers/constants';
import ButtonLoader from '../../components/material/ButtonLoader';

const EditDefaultCreativeButton = ({ account }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [gettingDefaultCreative, setGettingDefaultCreative] = useState(false);
    const getAndGoToDefaultCreative = () => {
        setGettingDefaultCreative(true);
        lucitApi.accounts.getDefaultCreative(account.id)
            .then(item => {
                setGettingDefaultCreative(false);
                history.push({ pathname: RoutePaths.inventory + '/' + item.id })
            })
    }

    return <ButtonLoader
        submitting={gettingDefaultCreative}
        onClick={getAndGoToDefaultCreative}
        color="secondary"
        size="small"
        variant="contained"
    >{t('Edit Default')}</ButtonLoader>
}

export default EditDefaultCreativeButton
