import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { reduxForm, Field, change, untouch, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

import TextField from '../components/inputs/TextField';
import OneTimeCodeField from '../components/inputs/OneTimeCodeField';
import { required, email, phoneFormat } from '../helpers/validators';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { login, loginMfaConfirm } from '../actions/user';
import { history } from '../helpers/history';
import { RoutePaths } from '../helpers/constants';
import { clearPhoneNumber, isPhoneNumber } from '../helpers/string';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getUser, selectedProfile } from '../selectors/user';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import { USER_LOGIN_MFA_RESET } from '../helpers/actionTypes';
import { showSuccess } from '../actions/snackbar';
import { QueryString } from '../helpers/constants';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            flex: .5,
        },
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 0)
    },
    submit: {
        height: 40,
        margin: theme.spacing(2, 0, 2),
    },
    signUp: {
        height: 40,
        marginBottom: theme.spacing(2)
    },

    gridCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    gridFlexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const LoginForm = (props) => {
    const { profile, handleSubmit, error, submitting, onNav } = props;
    const classes = useStyles();
    const values = useSelector(getFormValues(FORM_LOGIN));
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const resendCode = () => dispatch(login(values.username, values.password))
        .then(() => dispatch(showSuccess("Code was sent")));

    const isJoiningAnAccount = values ? values.join_lcuid : false;

    const queryString = values ? (isPhoneNumber(values.username)
        ? `?${QueryString.phoneNumber}=${values.username}` : '')
        + (values.join_lcuid ? `&${QueryString.join_lcuid}=${values.join_lcuid}` : '') : '';

    const onSubmit = values => {
        const username = isPhoneNumber(values.username)
            ? clearPhoneNumber(values.username)
            : values.username;
        const onLoggedIn = user => props.onSubmit
            ? props.onSubmit({ ...values, username, user })
            : history.push({ ...history.location, pathname: profile.type.path });

        if (values.code) {
            return dispatch(loginMfaConfirm(values.code))
                .then((user) => {

                    if (isJoiningAnAccount) {
                        return history.push({ ...history.location, pathname: values.join_lcuid });
                    }
                    else {
                        return onLoggedIn(user);
                    }

                });
        }

        return dispatch(login(username, values.password, null, values.join_lcuid))
            .then(data => {
                if (data.requires_mfa) {
                    return;
                }

                if (isJoiningAnAccount) {
                    return history.push({ ...history.location, pathname: values.join_lcuid });
                }
                else {
                    return onLoggedIn(user);
                }

            });
    }

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

            <Box style={{ display: 'none' }}>
                <Field
                    name="join_lcuid"
                    component={TextField}
                    props={{
                        label: "Hidden join_lcuid",
                        required: false,
                        id: "join_lcuid"
                    }}
                />
            </Box>

            {!user.mfa
                && <>
                    <Field
                        name="username"
                        validate={[required]}
                        component={TextField}
                        normalize={value => value.trim()}
                        props={{
                            label: "Email/Phone",
                            variant: "outlined",
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            id: "username",
                            autoComplete: "username"
                        }}
                    />
                    <Field
                        name="password"
                        validate={required}
                        component={TextField}
                        props={{
                            label: "Password",
                            type: "password",
                            variant: "outlined",
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            id: "password",
                            autoComplete: "current-password"
                        }}
                    />
                </>}
            {user.mfa
                && resendCode
                && <Box mt={2}>
                    <OneTimeCodeField
                        resendCode={resendCode}
                    />
                </Box>}
            <Typography color="error">{error}</Typography>
            {submitting
                && <Box className={classes.progress}>
                    <CircularProgressCentered />
                </Box>}
            <Button
                fullWidth
                type={'submit'}
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={() => handleSubmit(onSubmit)}
                className={classes.submit}>Sign In</Button>

            {user.mfa
                ? <Button
                    fullWidth
                    className={classes.signUp}
                    variant="outlined"
                    onClick={() => {
                        dispatch({ type: USER_LOGIN_MFA_RESET });
                        dispatch(change(FORM_LOGIN, 'code', null));
                        dispatch(untouch(FORM_LOGIN, 'code'));
                    }}
                    color="primary">Back</Button>
                : null}

            <Grid container spacing={1} >
                <Grid item xs>
                    <Link
                        variant="body2"
                        component={RouterLink}
                        to={{
                            pathname: RoutePaths.forgotPassword,
                            search: queryString
                        }}
                        onClick={() => onNav && onNav()}
                    >
                        {"Reset Password"}
                    </Link>
                </Grid>
                <Grid item xs className={classes.gridFlexEnd}>
                    <Link variant="body2" component={RouterLink} to={RoutePaths.contact} onClick={() => onNav && onNav()}>
                        {"Contact"}
                    </Link>
                </Grid>
            </Grid>
        </form>
    )
}
LoginForm.propTypes = {
    onSubmit: PropTypes.func
}

const mapStateToProps = state => {
    return {
        profile: selectedProfile(state)
    }
}

export const FORM_LOGIN = "login";
export default reduxForm({
    form: FORM_LOGIN, // a unique name for this form
    validate: (values) => {
        const errors = {};

        errors.username = isPhoneNumber(values.username)
            ? phoneFormat(values.username)
            : email(values.username);

        return errors;
    }
})(
    connect(
        mapStateToProps
    )(
        LoginForm
    )
);
