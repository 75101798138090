import React, { useEffect, useState } from 'react';
import { lucitApi } from '../../services/lucitApi';
import {
    Box, Button, Checkbox, DialogActions, DialogContent, FormControlLabel,
    makeStyles, MenuItem, TextField, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_UPDATE_IS_PARENT, ACCOUNT_UPDATE_IS_PUBLIC } from '../../helpers/actionTypes';
import { ButtonLoader, Dialog } from '../../components';
import LCTypography from '../../components/material/LCTypography';
import { showError, showSuccess } from '../../actions/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { exports } from '../../selectors/exports';
import { InventoryExportCampaignClasses } from '../../helpers/constants';
import { Autocomplete } from '@material-ui/lab';
import { getApplications } from '../../actions/applications';
import { objectSelector } from '../../selectors/objects';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        }
    }
})

const DefaultPublicJoinOptions = {
    role: 'account_poster_limited',
    account_naming_pattern: '{USER_NAME} Account',
    campaign_naming_pattern: '{ACCOUNT_NAME} Campaign',
    new_campaign_mirrors: null,
    auto_add_new_campaign_to_parent_campaign: null,
    welcome_text: null,
    auto_attach_apps: []
};

export function AccountPublicOptionsDialog({ account, open, handleClose }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [publicJoinOptions, setPublicJoinOptions] = useState(account.options.public_can_join
        ? { ...account.options.public_join_options, role: account.options.public_joins_with_role }
        : DefaultPublicJoinOptions);
    const onChange = (value) => setPublicJoinOptions({ ...publicJoinOptions, ...value })
    const campaings = useSelector(state => exports(state)
        .filter(x => x.campaign_class == InventoryExportCampaignClasses.group.class));
    const applications = useSelector(state => objectSelector(state)(account.lcuid).apps ?? []);

    const handleSave = () => {
        setSubmitting(true);

        const promises = [
            lucitApi.accounts.setPublicCanJoin(account, publicJoinOptions)
                .then(() => dispatch({
                    type: ACCOUNT_UPDATE_IS_PUBLIC,
                    lcuid: account.lcuid,
                    public_can_join: true,
                    publicJoinOptions
                }))
        ];

        if (!account.is_parent_account) {
            promises.push(
                lucitApi.accounts.setIsParent(account, true)
                    .then(() => dispatch({
                        type: ACCOUNT_UPDATE_IS_PARENT,
                        lcuid: account.lcuid,
                        is_parent_account: true
                    }))
            )
        }

        return Promise.all(promises)
            .then(() => {
                dispatch(showSuccess(t('Changes were saved')));
                handleClose();
            })
            .catch(() => dispatch(showError(t('Error when saving changes, please try again later'))))
            .finally(() => setSubmitting(false));
    }

    useEffect(() => {
        dispatch(getApplications(account.lcuid))
    }, [account.lcuid])

    return <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        title={t('Community Account Options')}
        onClose={handleClose}
        disableBackdropAndEscapeClose
    >
        <DialogContent className={classes.content}>
            <LCTypography>
                Configure what happens when members of the public join this community.
            </LCTypography>

            <Box display="flex" flexDirection="column" mt={4} mb={2}>
                <TextField
                    style={{ marginBottom: 16 }}
                    label={t('Account naming pattern')}
                    variant="outlined"
                    value={publicJoinOptions.account_naming_pattern}
                    helperText={t('Tip {USER_NAME} will automatically be replaced by the full name of the new user.')}
                    onChange={(e) => onChange({ account_naming_pattern: e.target.value })}
                />
                <TextField
                    style={{ marginBottom: 16 }}
                    label={t('Campaign naming pattern')}
                    variant="outlined"
                    value={publicJoinOptions.campaign_naming_pattern}
                    helperText={t('Tip {ACCOUNT_NAME} will be automatically replaced by the new account name.')}
                    onChange={(e) => onChange({ campaign_naming_pattern: e.target.value })}
                />

                <Box mb={2}>
                    <TextField
                        select
                        label={t('Attached Campaign')}
                        variant="outlined"
                        fullWidth
                        helperText={"Select the campaign that will be attached to the new users account when it is created."}
                        value={publicJoinOptions.new_campaign_mirrors ?? ''}
                        onChange={(e) => onChange({
                            new_campaign_mirrors: e.target.value,
                            auto_add_new_campaign_to_parent_campaign:
                                publicJoinOptions.auto_add_new_campaign_to_parent_campaign == null
                                    ? true
                                    : publicJoinOptions.auto_add_new_campaign_to_parent_campaign
                        })}
                    >
                        {campaings.map(c =>
                            <MenuItem
                                key={c.lcuid}
                                value={c.lcuid}>{c.name}</MenuItem>
                        )}
                        {!campaings.length
                            && <Typography style={{ padding: '8px 16px' }}>{t("Please create at least one group campaign")}</Typography>}
                    </TextField>
                    {publicJoinOptions.new_campaign_mirrors
                        && <FormControlLabel
                            control={<Checkbox
                                checked={publicJoinOptions.auto_add_new_campaign_to_parent_campaign}
                                onChange={(e) => onChange({ auto_add_new_campaign_to_parent_campaign: e.target.checked })}
                                name="gilad" />}
                            label={t('Auto add to mirrored campaign')}
                        />}
                </Box>

                <Autocomplete
                    style={{ marginBottom: 24 }}
                    fullWidth
                    multiple
                    value={publicJoinOptions.auto_attach_apps ?? []}
                    onChange={(_, newValue) => onChange({ auto_attach_apps: newValue })}
                    options={applications.map(a => a.lcuid)}
                    getOptionLabel={option => applications.find(a => a.lcuid === option)?.name}
                    getOptionSelected={(option) => publicJoinOptions.auto_attach_apps.some(lcuid => lcuid === option)}
                    renderInput={(params) => <TextField variant='outlined' label={t('Auto attach applications')}
                        helperText={t('Choose the apps that will automatically be added to the new user\'s account.')} {...params} />}
                />

                <TextField
                    label={t('Welcome Text')}
                    helperText={t('This is the text that will appear at the top of the users account home ' +
                        'screen. It supports standard markdown formatting.')}
                    multiline
                    rows={3}
                    variant={"outlined"}
                    value={publicJoinOptions.welcome_text ?? ''}
                    fullWidth={true}
                    onChange={(e) => onChange({ welcome_text: e.target.value })}
                />
            </Box>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} color="primary">
                {t('Cancel')}
            </Button>
            <ButtonLoader
                submitting={submitting}
                onClick={handleSave}
                color="primary"
                variant="contained">
                {t('Save')}
            </ButtonLoader>
        </DialogActions>
    </Dialog >
}
