import { ACCOUNT_SCREENS } from "../helpers/actionTypes";

export const initialState = {
    screens: []
}

export default function accounts(state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_SCREENS: {
            return Object.assign({}, state, {
                screens: action.screens
            });
        }

        default:
            return state
    }
}
