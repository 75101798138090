// eslint-disable-next-line no-undef
export const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

/**
 * The result of debounce(fn, ms) decorator should be a wrapper that passes the call to fn at maximum once per ms milliseconds.
 * In other words, when we call a "debounced" function, it guarantees that all future calls
 * to the function made less than ms milliseconds after the previous call will be ignored.
 * @export
 * @param {*} fn function to be debounced
 * @param {*} ms
 * @returns debounced fn
 */
export function debounce(fn, ms) {
    let timeoutId = null;

    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => { fn(...args) }, ms);
    }
}

/**
 * Same as debounce but returns promise which could be awaited
 * @export
 * @param {*} fn function to be debounced
 * @param {*} ms
 * @returns debounced fn
 */
export function debouncePromise(fn, ms) {
    let timeoutId = null;

    return (...args) => {
        let resolver = null;

        // eslint-disable-next-line no-undef
        const promise = new Promise((resolve) => {
            resolver = resolve;
        });

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => { resolver(fn(...args)) }, ms);

        return promise;
    }
}

export function throttle(callback, limit) {
    var waiting = false;                      // Initially, we're not waiting
    return function () {                      // We return a throttled function
        if (!waiting) {                       // If we're not waiting
            callback.apply(this, arguments);  // Execute users function
            waiting = true;                   // Prevent future invocations
            setTimeout(function () {          // After a period of time
                waiting = false;              // And allow future invocations
            }, limit);
        }
    }
}
export class Deferred {
    promise;
    resolve;
    reject;

    constructor () {
        // eslint-disable-next-line no-undef
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        })
    }
}
