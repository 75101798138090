import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSignInDialog } from '../../actions/layout';
import { uploadFile } from '../../actions/uploads';
import { useHookWithRefCallback } from '../../helpers/hooks';
import FileUploadInput from '../../components/inputs/FileUploadInput';
import { Typography } from '@material-ui/core';
import { showError, showSuccess } from '../../actions/snackbar';
import { attach } from 'ionicons/icons';
import { ButtonLoader } from '../../components/ionic';

const AttachmentButton = (props) => {
    const { isLoggedIn, addAttachment, openSignInDialog, showError, buttonCanBePressed } = props;

    const [inputRef, setInputRef] = useHookWithRefCallback(null);

    const [loading, setLoading] = useState(false);

    const onClick = useCallback(() => {
        if (!isLoggedIn) {
            return openSignInDialog();
        }

        if (!buttonCanBePressed) {
            alert("You don't have permission to attach files to this proof")
            return;
        }

        inputRef.click();
    }, [inputRef, isLoggedIn]);

    return <>
        <FileUploadInput
            ref={setInputRef}
            id="fileAttachmentUpload"
            extensions={["ai", "csv", "doc", "docx", "gif", "jpg", "jpeg", "pdf", "png", "ppt", "pptx", "psd", "txt", "xls", "xlsx"]}
            maxSize={20 * 1024 * 1024} // 20 MB
            onSuccess={({ file }) => {
                setLoading(true);
                addAttachment(file, file.name, props.attach)
                    .finally(() => setLoading(false))
            }}
            onError={errors => {
                showError(errors.map(e => <Typography key={e.type}>{e.message}</Typography>))
            }}>
            <ButtonLoader
                submitting={loading}
                style={props.style}
                onClick={onClick}
                startIcon={attach}
                variant="contained"
                color={"secondary"}
                size="small"
            >
                Attach
            </ButtonLoader>
        </FileUploadInput>
    </>;
};

AttachmentButton.propTypes = {
    attach: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAttachment: (fileBase64, name, attach) => dispatch(uploadFile(fileBase64, name))
            .then(file => attach(file))
            .then(() => dispatch(showSuccess("File was succesfully uploaded!")))
            .catch(e => {
                if (e.response && e.response.data) {
                    const fileError = e.response.data.file
                        && Array.isArray(e.response.data.file)
                        && e.response.data.file.join('');

                    dispatch(showError(`Error while uploading file. ${fileError || e.response.data.message}`))
                }
            }),

        openSignInDialog: () => dispatch(toggleSignInDialog(true)),
        showError: error => dispatch(showError(error)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentButton);
