import React from 'react';
import { Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

const LCTypography = ({ children, transProps, ...rest }) => {

    return (
        <Typography {...rest}>
            <Trans {...transProps} >
                {children}
            </Trans>
        </Typography>
    );
};

export default LCTypography;