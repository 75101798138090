import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, List, ListItem } from '@material-ui/core';
import { IonCard, IonImg, IonCardContent, IonCardTitle } from '@ionic/react';
import { LucitXR } from '../../components/images';
import { canViewStreetViewForItem } from '../../selectors/inventory';
import { getCityScapeIcon } from '../../selectors/billboard';
import { ImagePreviewDialog } from '../../components';
import { ProofBoardDialog } from './';
import { proofBoardContexts } from '../../selectors/proofs';

const useStyles = makeStyles(theme => ({
    lucitXRImageListContainer: {
        width: '100%',
    },
    lucitXRImageList: {
        width: '100%',
    },
    lucitXRImageCard: {
        height: 'auto !important',
        borderRadius: '10px',
    },
    lucitXRImage: {
        overflow: 'visible !important',
        height: '100%',
        display: 'flex',
        margin: '0 auto',
    },
    previewXRImage: {
        width: '100%',
    },
    previewXRDetailsContainer: {
        display: 'flex',
        width: '100%',
        zIndex: 1,
        flexDirection: 'column',
    },
    previewCreativeContainer: {
        height: '100%',
        maxWidth: 600,
        display: 'flex',
        cursor: 'pointer',
        maxHeight: 300,
        margin: '0 auto',
    },
    imageXRContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        minHeight: 200,
        width: '100%',
        background: '#ffffff',
        overflow: 'hidden',
        '& div': {
            height: '100%',
            width: '100%',
        }
    },
    previewDetailsFlexBox: {
        display: 'flex',
    },
    previewXRDetailsParentContainer: {
        flexWrap: 'inherit',
        display: 'flex',
        alignItems: 'inherit',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    previewDetailsItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        fontSize: '0.8rem',
        padding: 5,
    },
    previewDetailsCreativeInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    previewDetailsCreativeImageLarge: {
        maxWidth: "600px",
        width: "100%",
    },
    previewXRImageContainer: {
        width: '100%',
        background: '#8fd1d9',
        maxHeight: 480,
        maxWidth: 640,
        margin: 0,
        borderRadius: '10px 0px 0px 10px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '10px 10px 0px 0px',
            maxWidth: '100%',
            display: 'flex',
            flex: 1,
        },
    },
    previewCityScape: {
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            height: 'auto',
            display: 'flex',
        },
    },
    previewXRDetailsContainerCard: {
        width: '100%',
        height: '100%',
        margin: 0,
        borderRadius: '0px 10px 10px 0px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0px 0px 10px 10px'
        },
    }
}));

const ProofXRCreativesList = ({ proof, isStillCheckingForNewProofs }) => {
    const classes = useStyles();
    const [selectedPreviewIdx, selectPreviewIdx] = useState(null);

    if (proof == null) {
        return null;
    }

    const canViewStreetView = canViewStreetViewForItem(proof.inventory_item, proof.proof_view_count);
    const previews = useMemo(() => proofBoardContexts(proof), [proof, isStillCheckingForNewProofs]);

    return <>
        <Box className={classes.lucitXRImageListContainer}>
            <Box className={classes.lucitXRImageList}>
                {previews.map((preview, idx) => {
                    const { board, creative, photo } = preview;

                    return (<Box
                        key={board.lcuid}
                        className={classes.lucitXRImageCard}
                    >
                        <Box className={classes.previewXRDetailsParentContainer} alignItems='center'>
                            <IonCard className={classes.previewXRImageContainer}>
                                {creative
                                    ? <XRPreviewCard
                                        onXrClick={() => selectPreviewIdx(idx)}
                                        canViewStreetView={canViewStreetView}
                                        agency={proof.agency}
                                        board={board}
                                        image={creative} />
                                    : <IonImg className={classes.previewCityScape}
                                        src={getCityScapeIcon(board.digital_board_format.code)} />
                                }
                            </IonCard>

                            <Box className={classes.previewXRDetailsContainer}>
                                <IonCard className={classes.previewXRDetailsContainerCard}>
                                    <IonCardContent>
                                        <IonCardTitle>{board.name}</IonCardTitle>
                                        <Box className={classes.previewDetailsFlexBox}>
                                            <List className={classes.previewDetailsCreativeInfo}>
                                                <ListItem className={classes.previewDetailsItem}>
                                                    <strong>Location:&nbsp;</strong>
                                                    {board.location.city}, {board.location.region}
                                                </ListItem>

                                                {Boolean(board.options.facing_cardinal) &&
                                                    <ListItem className={classes.previewDetailsItem}>
                                                        <strong>Facing:&nbsp;</strong>
                                                        {board.options.facing_cardinal}
                                                    </ListItem>
                                                }
                                                <ListItem className={classes.previewDetailsItem}>
                                                    <strong>Format:&nbsp;</strong>
                                                    {board.digital_board_format.name}
                                                </ListItem>

                                            </List>

                                            <List className={classes.previewDetailsCreativeInfo}>
                                                <ListItem className={classes.previewDetailsItem}>
                                                    <strong>Creative ID:&nbsp;</strong>
                                                    {photo.id}
                                                </ListItem>
                                                <ListItem className={classes.previewDetailsItem}>
                                                    <strong>Creative Size:&nbsp;</strong>
                                                    {board.width} x {board.height}
                                                </ListItem>
                                            </List>
                                        </Box>
                                        <Box mt={4} mb={2} style={{ display: "flex" }}>
                                            <CreativeImageContainer photo={photo} />
                                        </Box>
                                    </IonCardContent>
                                </IonCard>
                            </Box>
                        </Box>
                    </Box>)
                })}
            </Box>
        </Box>

        <ProofBoardDialog
            proof={proof}
            indexDefault={selectedPreviewIdx}
            canViewStreetView={canViewStreetView}

            open={selectedPreviewIdx !== null}
            handleClose={() => selectPreviewIdx(null)}
        />
    </>;
}

const CreativeImageContainer = ({ photo }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const classes = useStyles();

    return <Box className={classes.previewCreativeContainer}
        onClick={() => setIsZoomed(!isZoomed)}
    >
        <IonImg style={{ borderRadius: 10 }} src={photo.options.public_url} />

        <ImagePreviewDialog
            open={isZoomed}
            handleClose={() => setIsZoomed(false)}
        >
            <IonImg src={photo.options.public_url} alt="zoomed" className={classes.previewDetailsCreativeImageLarge} />
        </ImagePreviewDialog>
    </Box>
}

const XRPreviewCard = ({ board, image, canViewStreetView, onXrClick }) => {
    const classes = useStyles();

    return (<>
        <Box
            onClick={onXrClick}
            className={classes.imageXRContainer}
            style={{ cursor: "pointer" }}
        >
            <LucitXR
                className={classes.lucitXRImage}
                canViewStreetView={canViewStreetView}
                creative={image}
                ionImageStyle={{
                    margin: '0 auto',
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
                streetView={board.street_view_location}
            />
        </Box>
    </>)
}

ProofXRCreativesList.propTypes = {
    proof: PropTypes.object.isRequired
}

export default connect()(
    ProofXRCreativesList
);
