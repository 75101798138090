import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/react';

import { store } from './reducers';
import { savePushNotificationsToken, setPushNotificationToken } from './actions/user';
import { logger } from './helpers/logger';
import { nativeNotificationReceived } from './actions/pushNotifications';
import { getUser, isLoggedIn } from './selectors/user';
import { handleNotification, handleNotificationTap } from './components/notifications';
import { LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG } from './helpers/actionTypes';

export async function promptWhenIsLoggedIn() {
    if (isLoggedIn(store.getState())) {
        return prompt();
    }

    const unsubscribe = store.subscribe(() => {
        if (isLoggedIn(store.getState())) {
            unsubscribe();
            prompt();
        }
    })

    return Promise.resolve();
}

export async function prompt() {
    const platforms = ["hybrid"];
    const platform = platforms.find(p => isPlatform(p));

    if (platform == null) {
        store.dispatch(setPushNotificationToken("Platform NOT_SUPPORTED"));

        //TODO: Support PWA Firebase Cloud Messaging
        return;
    }

    let permStatus = await PushNotifications.checkPermissions()
        .catch(error => {
            logger.logError("Failed to checkPermissions for push notifications", error);
            return Promise.reject(error);
        });

    if (permStatus.receive === 'prompt') {
        store.dispatch({
            type: LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG,
            pushNotificationsInfoOpen: true
        })
        return;
    }

    return register();
}

export async function register() {
    // Register with Apple / Google to receive push via APNS/FCM
    await PushNotifications.register()
        .catch(error => {
            logger.logError("Failed to register for push notifications", error);
        });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
        const user = getUser(store.getState());

        if (user.options.fcm_token !== token.value) {
            const actualPlatform = isPlatform("android") ? "android" : "ios";

            store.dispatch(savePushNotificationsToken(user.id, token.value, actualPlatform));
        }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', error => {
        logger.logError("Failed to register push notifications", error);

        store.dispatch(setPushNotificationToken(JSON.stringify(error)));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
        store.dispatch(nativeNotificationReceived(notification.data.notification_id))
            .then(x => handleNotification(x, store.dispatch))
            .catch(error => {
                logger.logError("Failed to perform pushNotificationReceived", error);
            });
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', context => {
        store.dispatch(nativeNotificationReceived(context.notification.data.notification_id))
            .then(x => handleNotificationTap(x, store.dispatch))
            .catch(error => {
                logger.logError("Failed to perform pushNotificationActionPerformed", error);
            });
    });
}
