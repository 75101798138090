import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Link, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChartIcon from '@material-ui/icons/ShowChart';
import LCTypography from '../../components/material/LCTypography';
import { exportsStatusSelector } from '../../selectors/inventoryStatus';
import InventoryItemExports from './InventoryItemExports';
import { useState } from 'react';
import { getItem } from '../../actions/inventory';
import { Global, InventoryItemCreativeState, Timeframe } from '../../helpers/constants';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { useEffect } from 'react';
import { useFirstRun } from '../../helpers/hooks/useFirstRun';
import { inventoryItemClass, itemOptions } from '../../selectors/inventory';
import InventoryItemDetails from './itemDetails/InventoryItemDetails';
import PlaysStatsByBillboard from '../analytics/PlaysStatsByBillboard';
import InventoryItemOperators from './InventoryItemOperators';
import { InventoryItemClasses } from '../../helpers/constants';
import DeveloperObjectTools from '../../containers/settings/DeveloperObjectTools';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { currentUserHasPermission } from '../../selectors/user';
import ChangeTemplateButton from '../../containers/inventory/ChangeTemplateButton';
import EditItemTemplateButton from '../../containers/inventory/EditItemTemplateButton';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        },

        strikethrough: {
            textDecoration: 'line-through'
        },

        undoIcon: {
            marginLeft: '10px'
        }
    }
})

function InventoryItem(props) {
    const { item, exportsStatus, getItem } = props;

    const [isCheckingItemUpdates, setCheckingItemUpdates] = useState(false);
    const isFirstRun = useFirstRun();

    const analyticFilters = useMemo(() => ({
        inventory_item_id: item?.id,
    }), [item?.id]);

    const canEditTemplates = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editDriveTemplates))

    const { reset } = useIntervalLimited(() => getItem(item?.id),
        {
            running: isCheckingItemUpdates,
            milliseconds: Global.itemExportsStatusUpdateInterval
        })

    const secondsSinceCreatedAt = () => {
        return (new Date() - new Date(item?.created_at)) / 1000;
    }
    const shouldKeepCheckingUpdates = () => {
        if (item.creative_state === InventoryItemCreativeState.building)
            return true;

        if (secondsSinceCreatedAt() < Global.lucitXRImageCheckInterval)
            return true;

        return false;
    }

    const shouldShowLowerSection = () => {
        return item.item_class !== InventoryItemClasses.defaultCreative.class
    }

    useEffect(() => {
        if (shouldKeepCheckingUpdates()) {
            reset();
            setCheckingItemUpdates(true);
        } else {
            setCheckingItemUpdates(false);
        }

    }, [item?.creative_state, reset]);

    useEffect(() => {
        if (isFirstRun && item?.creative_state !== InventoryItemCreativeState.building) {
            return;
        }

        reset();
        setCheckingItemUpdates(true);
        // eslint-disable-next-line
    }, [
        item?.title,
        // eslint-disable-next-line
        itemOptions(item)?.title_has_override,
        item?.price,
        // eslint-disable-next-line
        itemOptions(item)?.price_has_override,
        reset
    ]);

    if (!item)
        return null;

    return (
        <>
            <Grid container spacing={3}>
                <InventoryItemDetails
                    itemClass={inventoryItemClass(item)}
                    item={item} />
                {
                    shouldShowLowerSection() && <>

                        {item?.feed && item?.feed.id && <Grid item xs={12}>
                            <Box mb={1} display="flex" alignItems="center">
                                <Avatar style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "10px"
                                }}>
                                    <img
                                        style={{ width: "24px" }}
                                        src={item?.feed?.inventory_feed_provider?.options?.primary_image_public_url}
                                    />
                                </Avatar>
                                <LCTypography variant="h6">Data Source</LCTypography>
                            </Box>

                            <Box ml={5} display="flex" alignItems="center">
                                <LCTypography variant="body2">
                                    Creative generated by <> </>
                                    <Link
                                        component={RouterLink}
                                        color="secondary"
                                        style={{ cursor: "pointer" }}
                                        to={`/feeds/${item?.feed?.id}`}
                                    >
                                        {item?.feed?.name}
                                    </Link>
                                </LCTypography>
                            </Box>
                        </Grid>}

                        {item?.drive_template && item?.drive_template.id && <Grid item xs={12}>
                            <Box mb={1} display="flex" alignItems="center">

                                {item?.drive_template?.options?.primary_image_public_url ?

                                    <img
                                        style={{ width: "24px", borderRadius: "5px", marginRight: "10px" }}
                                        src={item?.drive_template?.options?.primary_image_public_url}
                                    />
                                    : <div style={{ marginRight: "10px" }} />
                                }

                                <LCTypography variant="h6">Template</LCTypography>
                            </Box>

                            <Box ml={5} display="flex" alignItems="center">
                                <LCTypography variant="body2">
                                    <>
                                        Built using the<> </>

                                        {canEditTemplates ?
                                            <Link
                                                component={RouterLink}
                                                color="secondary"
                                                style={{ cursor: "pointer" }}
                                                to={`/designer/${item?.drive_template?.lcuid}`}
                                            >
                                                {item?.drive_template?.name}
                                            </Link> :
                                            <strong>{item?.drive_template?.name}</strong>
                                        }<> </>
                                        template
                                    </>
                                </LCTypography>
                                <Box ml={2}>
                                    {<ChangeTemplateButton item={item} />}
                                    <EditItemTemplateButton
                                        item={item}
                                        driveTemplateLcuid={item.drive_template ? item.drive_template.lcuid : null}
                                    />
                                </Box>
                            </Box>
                        </Grid>}

                        <Grid item xs={12} lg={8}>
                            <InventoryItemExports exportsStatus={exportsStatus} />
                        </Grid>

                        <Grid item xs={12} lg={8}>
                            <InventoryItemOperators item={item} />
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={1} display="flex" alignItems="center">
                                <Box component={ChartIcon} mr={1} />
                                <LCTypography variant="h6">Analytics (Last 30 Days)</LCTypography>
                            </Box>
                            <PlaysStatsByBillboard
                                timeframe={Timeframe.last30Days}
                                filters={analyticFilters}
                                hideFilters />
                        </Grid>
                    </>
                }

            </Grid>

            <DeveloperObjectTools object={item} />
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state, props) => {
    return {
        exportsStatus: exportsStatusSelector(state)(props.item?.id)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
