
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function CampaignGroupIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M13.4 6.25V7.75H16.25V6.25H13.4ZM11.975 11.2075C12.659 11.74 13.5496 12.445 14.255 13C14.54 12.6025 14.825 12.1975 15.11 11.8C14.4046 11.245 13.514 10.54 12.83 10C12.545 10.405 12.26 10.81 11.975 11.2075ZM15.11 2.2C14.825 1.8025 14.54 1.3975 14.255 1C13.5496 1.555 12.659 2.26 11.975 2.8C12.26 3.1975 12.545 3.6025 12.83 4C13.514 3.46 14.4046 2.7625 15.11 2.2ZM3.425 4.75C2.64125 4.75 2 5.425 2 6.25V7.75C2 8.575 2.64125 9.25 3.425 9.25H4.1375V12.25H5.5625V9.25H6.275L9.8375 11.5V2.5L6.275 4.75H3.425ZM11.6187 7C11.6187 6.0025 11.2055 5.1025 10.55 4.4875V9.505C11.2055 8.8975 11.6187 7.9975 11.6187 7Z" />
        <path d="M20.4 16.25V17.75H23.25V16.25H20.4ZM18.975 21.2075C19.659 21.74 20.5496 22.445 21.255 23C21.54 22.6025 21.825 22.1975 22.11 21.8C21.4046 21.245 20.514 20.54 19.83 20C19.545 20.405 19.26 20.81 18.975 21.2075ZM22.11 12.2C21.825 11.8025 21.54 11.3975 21.255 11C20.5496 11.555 19.659 12.26 18.975 12.8C19.26 13.1975 19.545 13.6025 19.83 14C20.514 13.46 21.4046 12.7625 22.11 12.2ZM10.425 14.75C9.64125 14.75 9 15.425 9 16.25V17.75C9 18.575 9.64125 19.25 10.425 19.25H11.1375V22.25H12.5625V19.25H13.275L16.8375 21.5V12.5L13.275 14.75H10.425ZM18.6187 17C18.6187 16.0025 18.2055 15.1025 17.55 14.4875V19.505C18.2055 18.8975 18.6187 17.9975 18.6187 17Z" />
    </SvgIcon>
}
