import { PHOTO_GETBYID } from "../helpers/actionTypes";

export const initialState = {
    allIds: [],

    byId: {

    }
}

export default function photos(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case PHOTO_GETBYID:
            return {
                byId: {
                    ...state.byId,

                    [payload.data.id]: payload.data
                },
                allIds: state.allIds.includes(payload.id)
                    ? state.allIds
                    : [
                        ...state.allIds,
                        payload.data.id
                    ]
            }
        default:
            return state
    }
}
