import { LucitXrImageSize } from "./constants";
import { environment } from "./environment";

export const getHashValue = (key, hash = window.location.hash.substring(1)) => {
    const values = new URLSearchParams(hash);

    return values.get(key)
}

export const getQueryString = (history, key) => {
    const values = new URLSearchParams(history.location.search);

    return values.get(key);
}

export const replaceQueryString = (history, key, value) => {
    const values = new URLSearchParams(history.location.search);
    values.set(key, value);

    history.replace({
        ...history.location,

        search: values.toString()
    })
}

export const removeQueryString = (history, key) => {
    const values = new URLSearchParams(history.location.search);
    values.delete(key);

    history.replace({
        ...history.location,

        search: values.toString()
    })
}

export const serializeQueryString = (data) => {
    return Object.keys(data)
        .map(key => `${key}=${data[key]}`)
        .join("&")
}

export const gmStreetViewUrl = (position, pov, size, metaOnly) => {

    const mainUrl = "https://maps.googleapis.com/maps/api/streetview" + (metaOnly ? "/metadata?" : "?")

    return mainUrl + `size=${size || `${LucitXrImageSize.width}x${LucitXrImageSize.height}`}`
        + (position.panoId
            ? `&pano=${position.panoId}`
            : `&location=${position.lat},${position.lng}`)
        //See FOV calculation here:
        //https://ramblings.mcpher.com/ephemeral-exchange/...
        ///...playing-around-with-maps-and-streetview/convert-field-of-view-to-zoom-and-back-again
        + `&fov=${Math.atan(Math.pow(2, 1 - pov.zoom)) * 360 / Math.PI}`
        + `&heading=${pov.heading}`
        + `&pitch=${pov.pitch}`
        + `&key=${environment.GOOGLE_API_KEY}`;

}
