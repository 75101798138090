import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText,
    ListItemAvatar, Grid, useTheme, TextField, InputAdornment, makeStyles, TablePagination
} from '@material-ui/core';
import { Global } from '../../helpers/constants';
import LCTypography from '../../components/material/LCTypography';
import { withRouter } from 'react-router';
import { AgencySelectField } from '../agency';
import { setBoardIds } from '../../actions/exports';
import { showError, showSuccess } from '../../actions/snackbar';
import { lucitApi } from "../../services/lucitApi";
import { toLocalDateTime, minutesAgo } from "../../helpers/date";
import DateTimeRelative from '../../components/DateTimeRelative';
import BarChartIcon from '@material-ui/icons/BarChart';
import { BillboardPullIndicator } from '../billboards';
import { selectedAccount } from "../../selectors/user";
import CampaignBoardsItemSkeleton from './CampaignBoardsItemSkeleton';
import FilterIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../../components/material';
import i18n from '../../i18n';
import OperatorBoardMapFromSelectedScreens from '../../containers/billboards/OperatorBoardMapFromSelectedScreens';

const useStyles = makeStyles(theme => {
    return {
        filters: {
            position: "sticky",
            top: theme.spacing(-3),
            background: theme.palette.common.white,
            padding: theme.spacing(2),
            zIndex: theme.zIndex.appBar
        },

        pagging: {
            position: 'sticky',
            bottom: theme.spacing(-3),
            background: theme.palette.common.white
        }
    }
})

const LastPlayIndicator = ({ play_datetime }) => {
    const theme = useTheme();

    const getTitle = () => {
        return play_datetime ?
            <>
                Last played <DateTimeRelative date={toLocalDateTime(play_datetime)} />
            </> :

            "No plays yet for this screen"
    }

    const getColor = () => {

        if (minutesAgo(play_datetime) <= 60)
            return theme.palette.success.main

        if (minutesAgo(play_datetime) <= 1440)
            return theme.palette.warning.main

        return theme.palette.error.main
    }

    return (<Tooltip title={getTitle()}>
        <BarChartIcon fontSize="small" style={{ color: getColor() }} />
    </Tooltip>
    )
}

const BoardRow = ({ board, selected, onChange, boardData, disabled }) => {
    const handleRowClick = () => {

        if (disabled)
            return;

        onChange({
            target: {
                checked: !selected
            }
        })
    }
    const boardScale = 12;

    return (
        <ListItem dense button onClick={handleRowClick}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    name={`digital_board_` + board.id}
                    checked={selected}
                    disabled={disabled}
                    onChange={onChange}
                />
            </ListItemIcon>
            <ListItemText
                id={board.id}
                primary={board.name}
                secondary={<><strong>{board.board_identifier}</strong> : {board.location?.city}</>}
            />
            <ListItemAvatar>
                <Grid container style={{ width: "150px" }}>
                    <Grid item xs={6}>
                        <div
                            style={{
                                width: (board.width / board.height).toFixed(1) * boardScale + 'px',
                                height: boardScale + 'px',
                                background: '#ABABAB',
                                border: '1px solid black',
                                boxSizing: 'content-box',
                                margin: '0 auto'
                            }}
                        >
                            &nbsp;
                        </div>
                        <div style={{ fontSize: "10px", margin: '0 auto', textAlign: "center" }}>{board.board_size_description}</div>
                        <div style={{ fontSize: "9px", margin: '0 auto', textAlign: "center" }}>{board.digital_board_format.name}</div>

                    </Grid>
                    <Grid item xs={1}><> </></Grid>
                    <Grid item xs={2}>
                        {selected
                            && <BillboardPullIndicator pull_datetime={boardData
                                && boardData.last_pull
                                && boardData.last_pull.last_created_at} />}
                    </Grid>
                    <Grid item xs={1}><> </></Grid>
                    <Grid item xs={2}>
                        {selected ? <LastPlayIndicator play_datetime={boardData
                            && boardData.last_pingback && boardData.last_pingback.play_datetime} /> : <> </>}
                    </Grid>

                </Grid>

            </ListItemAvatar>
        </ListItem>
    )

}

const CampaignBoards = (props) => {
    const { exportItem, billboards, agencies, setBoardIds, selectedAccount, allowEdit } = props;
    const classes = useStyles();
    const [loadingBoards, setLoadingBoards] = useState(false);
    const [digitalBoards, setDigitalBoards] = useState(null);
    const [filteredBoards, setFilteredBoards] = useState(null);
    const [digitalBoardFilter, setDigitalBoardFilter] = useState("");
    const [page, setPage] = useState(1);

    const campaignFirstAgencyId = exportItem && exportItem.operators && exportItem.operators[0].id;

    const bestFirstAgencyId = campaignFirstAgencyId ? campaignFirstAgencyId : selectedAccount?.agency_id;

    const hasSomeAgencies = agencies && agencies.length > 0;

    const [selectedAgency, setSelectedAgency] = useState(
        agencies.find(agency => agency.id === bestFirstAgencyId)
    );

    if (!exportItem)
        return null

    //This dumb parse int is there because some really old exports have the ids as string
    const exportItemDigitalBoardIds = (exportItem?.settings_array?.transformer?.digital_board_ids).map(boardId => parseInt(boardId)) ?? []

    const [selectedBoardIds, setSelectedBoardIds] = useState(exportItemDigitalBoardIds)
    const [boardIdsChanged, setBoardIdsChanged] = useState(false)

    const handleBoardChange = (boardId, checked) => {
        checked ? setSelectedBoardIds([...selectedBoardIds, boardId]) : setSelectedBoardIds(selectedBoardIds.filter(b => b !== boardId))
        setBoardIdsChanged(true)
    }

    const saveBoardIds = () => {
        setBoardIds(selectedBoardIds.filter(boardId => boardId)).then(() => setBoardIdsChanged(false))
        setDigitalBoardFilter("")
        setPage(1)
    }

    const billboardStatusData = (id) => {
        return billboards.find(bd => bd.digital_board.id === id)
    }

    useEffect(() => {
        setSelectedAgency(agencies.find(agency => agency.id === bestFirstAgencyId))
    }, [campaignFirstAgencyId])

    useEffect(() => {
        const newFilteredBoards = digitalBoards ? digitalBoards.filter(board => {
            return board.name.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.board_identifier.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.location?.city.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.location?.region.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.location?.country.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.board_size_description.toUpperCase().includes(digitalBoardFilter.toUpperCase()) ||
                board.digital_board_format?.name.toUpperCase().includes(digitalBoardFilter.toUpperCase())
        }) : null

        const sortedBoards = newFilteredBoards ? newFilteredBoards
            .sort((a, b) => 2 * (a.name > b.name) - 1)
            .sort((a, b) => Number(selectedBoardIds.includes(b.id)) - Number(selectedBoardIds.includes(a.id)))
            : null

        setFilteredBoards(sortedBoards);

    }, [digitalBoards, digitalBoardFilter])

    useEffect(() => {
        setSelectedBoardIds(exportItemDigitalBoardIds)

        if (selectedAgency) {
            setLoadingBoards(true);
            lucitApi.agencies
                .getDigitalBoards(selectedAgency.id).then((result) => {
                    setDigitalBoards(result)
                })
                .finally(() => setLoadingBoards(false))
        }
        else if (bestFirstAgencyId) {
            setLoadingBoards(true);
            lucitApi.agencies
                .getDigitalBoards(bestFirstAgencyId).then((result) => {
                    setDigitalBoards(result)
                })
                .finally(() => setLoadingBoards(false))
        }
        else {
            setDigitalBoards(null)
        }
    }, [exportItem, selectedAgency])

    return (
        <Box>
            {hasSomeAgencies &&
                <Box mb={2} display="flex" alignItems="center">
                    <LCTypography variant="body1" style={{ marginRight: 8 }}>
                        Select screens from
                    </LCTypography>
                    <AgencySelectField
                        disabled={!allowEdit}
                        value={selectedAgency}
                        onChange={agency => setSelectedAgency(agency)}
                    />
                    {selectedAgency
                        && <Button
                            color="secondary"
                            variant="contained"
                            disabled={!boardIdsChanged || !allowEdit}
                            style={{ marginLeft: 8 }}
                            onClick={() => saveBoardIds()}
                        >
                            Save
                        </Button>}
                </Box>
            }

            {digitalBoards !== null && filteredBoards !== null
                && <Box>
                    {loadingBoards
                        &&
                        <List>
                            {Array.from({ length: Global.screenSelectorPageListSize })
                                .map((_, index) => (
                                    <CampaignBoardsItemSkeleton key={index} />
                                ))}
                        </List>}

                    {!loadingBoards
                        && <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>

                                <TextField
                                    type="text"
                                    disabled={!allowEdit}
                                    fullWidth
                                    variant="standard"
                                    placeholder="Filter..."
                                    value={digitalBoardFilter}
                                    onChange={(event) => setDigitalBoardFilter(event.target.value)}
                                    inputProps={{
                                        "aria-autocomplete": "both",
                                        "aria-haspopup": "false",
                                        "autoComplete": "off",
                                        "spellCheck": "false"
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Tooltip title={"Filter"}>
                                                <FilterIcon fontSize="small" />
                                            </Tooltip>
                                        </InputAdornment>
                                    }}
                                />
                                <List style={{
                                    maxHeight: '400px', overflow: 'auto'
                                }} >
                                    {filteredBoards && filteredBoards
                                        .slice((page - 1) * Global.screenSelectorPageListSize, page * Global.screenSelectorPageListSize)
                                        .map(board => (<BoardRow
                                            key={board.item_id}
                                            board={board}
                                            disabled={!allowEdit}
                                            boardData={billboardStatusData(board.id)}
                                            selected={selectedBoardIds.includes(board.id)}
                                            onChange={(e) => handleBoardChange(board.id, e.target.checked)}
                                        />))}
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={8}>

                                <OperatorBoardMapFromSelectedScreens
                                    disabled={!allowEdit}
                                    chosenAgency={selectedAgency}
                                    boards={filteredBoards}
                                    selectedBoardIds={selectedBoardIds}
                                    setSelectedBoardIds={setSelectedBoardIds}
                                />
                            </Grid>
                        </Grid>}

                    {filteredBoards && filteredBoards.length > Global.screenSelectorPageListSize &&
                        <TablePagination
                            className={classes.pagging}
                            component="div"
                            count={filteredBoards.length}
                            page={page - 1}
                            rowsPerPage={Global.screenSelectorPageListSize}
                            rowsPerPageOptions={[Global.screenSelectorPageListSize]}
                            labelRowsPerPage="Per page:"
                            onChangePage={(_, page) => {
                                return setPage(page + 1);
                            }}
                        />}
                </Box>}
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        agencies: state.user.agencies || [],
        selectedAccount: selectedAccount(state)
    }
}

const mapStateToDispatch = (dispatch, ownProps) => {
    return {
        setBoardIds: (boardIds) => {
            return dispatch(setBoardIds(ownProps.exportItem.id, boardIds))
                .then(() => {
                    dispatch(showSuccess(i18n.t("Screens Updated")))
                })
                .catch(() => {
                    dispatch(showError(i18n.t("Error Updating Screens")))
                })
        }
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapStateToDispatch
    )(
        CampaignBoards
    )
);
