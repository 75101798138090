import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { Box, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Avatar as LucitAvatar } from '../../components/material';

import { toggleSignInDialog } from '../../actions/layout';
import { saveComment, updateComment } from '../../actions/objects';
import TextField from '../../components/inputs/TextField';
import { useLayoutEffect } from 'react';
import { useCallback } from 'react';
import { lucitApi } from '../../services/lucitApi';
import { fileToBase64 } from '../../helpers/file';
import { Cancel, Send } from '@material-ui/icons';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { showError, showInfo } from '../../actions/snackbar';
import { Global } from '../../helpers/constants';
import { isPlatform } from '@ionic/core';
import { IonLabel } from '@ionic/react';
import { useKeyDown } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        listItemWithAction: {
            paddingRight: 52,
        },
        listItemAction: {
            transform: 'none',
            top: 0,
            right: 0
        },

        commentInput: {
            // background: theme.palette.grey[200],
            borderRadius: 5,
        },

        previewBox: {
            display: 'flex',
            marginTop: -1,
            paddingTop: 1
        },

        previewImageBox: {
            position: 'relative',
            width: 120,
            height: 80,
            border: `1px solid ${theme.palette.grey[400]}`,
            borderTop: 0,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        previewLoading: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            margin: '0 !important',
            alignItems: 'center'
        },
        previewImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        previewCancel: {
            position: 'absolute',
            top: 4,
            right: 4,
            color: theme.palette.secondary.main,
            background: theme.palette.common.white,
            borderRadius: '50%',
            cursor: 'pointer'
        }
    }
})

const acceptedFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp']

const CommentInput = (props) => {
    const { lcuid, autoFocus } = props;
    const { editComment, onEditOk, onEditCancel } = props;
    const { isLoggedIn, user, uploadImage, showError, showInfo } = props;
    const classes = useStyles();

    const inputRef = useRef();
    const isMobile = isPlatform('android') || isPlatform('ios');
    const isEdit = Boolean(editComment);

    const [commentSaving, setCommentSaving] = useState(false);
    const [commentText, setCommentText] = useState(isEdit ? editComment.comment : '');

    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    const pasteHandler = useCallback(e => {
        if (isEdit || loading || commentSaving) {
            return;
        }

        if (!e.clipboardData || e.clipboardData.length == 0) {
            showInfo(`Sorry, to bother you but there was no image pasted.`);
            return;
        }

        if (images.length >= Global.commentMaxAttachments) {
            showError(`No more than ${Global.commentMaxAttachments} attachments allowed`)
            return;
        }

        for (let i = 0; i < e.clipboardData.items.length; i++) {
            const item = e.clipboardData.items[i];

            if (item.kind != 'file') {
                continue;
            }

            if (!acceptedFiles.includes(item.type)) {
                console.warn(e.clipboardData, item)
                showInfo("Only images can be attached to comment");
                continue;
            }

            const file = item.getAsFile();

            setLoading(true);
            fileToBase64(file)
                .then(fileBase64 => {
                    const image = {
                        loading: true,
                        file,
                        fileBase64
                    };

                    setImages(images => [...images, image]);

                    return uploadImage(file)
                        .then(x => setImages(images => images.map(i => i == image
                            ? { ...image, ...x, loading: false }
                            : i)))
                })
                .catch(error => {
                    console.error("ERROR", error);
                    showError(`Error when uploading image`)
                })
                .finally(() => setLoading(false))
        }
    }, [isEdit, loading, commentSaving, images.length, uploadImage])

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener('paste', pasteHandler)
        }

        return () => inputRef.current && inputRef.current.removeEventListener('paste', pasteHandler)
    }, [pasteHandler])

    useKeyDown(['Esc', 'Escape'], () => isEdit && onEditCancel());

    const sendComment = useCallback(() => {
        setCommentSaving(true);
        props.comment(lcuid, commentText, images)
            .then(() => {
                setCommentText('');
                setImages([]);
            })
            .finally(() => setCommentSaving(false))
    }, [props.comment, lcuid, commentText, images])

    const updateComment = useCallback(() => {
        setCommentSaving(true);
        props.updateComment(lcuid, editComment, commentText)
            .then(() => onEditOk())
            .finally(() => setCommentSaving(false));
    }, [props.updateComment, editComment, lcuid, commentText]);

    if (!isLoggedIn) {
        return null;
    }

    return <>
        <ListItem alignItems="flex-start" disableGutters className={clx({ [classes.listItemWithAction]: isMobile })}>
            <ListItemAvatar style={{ marginTop: 0 }}>
                <LucitAvatar
                    alt={user.name}
                    name={user.name}
                    src={user.options.primary_image_public_url}></LucitAvatar>
            </ListItemAvatar>

            <Box width="100%" >
                <TextField
                    ref={inputRef}
                    autoFocus={autoFocus}  //If comments are open by default, don't auto-focus
                    size="small"
                    variant="outlined"
                    fullWidth
                    rows={2}
                    multiline
                    disabled={commentSaving}
                    input={{
                        value: commentText,
                        onChange: e => setCommentText(e.target.value)
                    }}
                    placeholder="Write a comment..."
                    className={classes.commentInput}
                    helperText={isEdit
                        && <>Press &apos;Esc&apos; to <IonLabel
                            onClick={() => onEditCancel()}
                            color="primary"
                            style={{ cursor: 'pointer' }}>cancel</IonLabel></>}
                    inputProps={{
                        onKeyDown: e => {
                            if (e.shiftKey) {
                                return true;
                            }

                            if (e.key === 'Enter') {
                                e.preventDefault();

                                if (loading) {
                                    return;
                                }

                                if (isMobile)
                                    return;

                                if (Boolean(commentText) || images.length > 0) {
                                    isEdit
                                        ? updateComment()
                                        : sendComment();
                                }

                                return false;
                            }
                        }
                    }}
                />
                {images.length > 0
                    && <Box className={classes.previewBox}>
                        {images.map((x, idx) => <Box
                            key={idx}
                            className={classes.previewImageBox}
                            style={{
                                borderRight: idx === images.length - 1
                                    ? undefined
                                    : 0,

                                borderBottomLeftRadius: idx === 0
                                    ? 8
                                    : 0,
                                borderBottomRightRadius: idx === images.length - 1
                                    ? 8
                                    : 0
                            }}
                        >
                            {x.loading && <CircularProgressCentered className={classes.previewLoading} />}
                            {x.options
                                && x.options.public_url
                                && <img
                                    className={classes.previewImage}
                                    src={x.options.public_url} />}

                            <Cancel className={classes.previewCancel}
                                onClick={() => setImages(images.filter(i => i !== x))}
                            />
                        </Box>)}
                    </Box>}
            </Box>
            {isMobile
                && <ListItemSecondaryAction className={classes.listItemAction}>
                    <IconButton
                        disabled={!commentText && images.length == 0}
                        onClick={() => isEdit
                            ? updateComment()
                            : sendComment()}
                        color="secondary" >
                        <Send />
                    </IconButton>
                </ListItemSecondaryAction>}
        </ListItem>
    </>;
};

CommentInput.propTypes = {
    className: PropTypes.string,
    lcuid: PropTypes.string,

    editComment: PropTypes.object,

    onEditOk: PropTypes.func,
    onEditCancel: PropTypes.func,

    autoFocus: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openSignInDialog: () => dispatch(toggleSignInDialog(true)),
        showInfo: (info) => dispatch(showInfo(info)),
        showError: (error) => dispatch(showError(error)),

        uploadImage: (fileBlob) => lucitApi.uploads.uploadImageFile(fileBlob,
            [
                { "template": "LACCopyPaste" }
            ]),

        comment: (lcuid, comment, images) => {
            return dispatch(saveComment(lcuid, comment, { images }))
        },

        updateComment: (lcuid, comment, newText) => {
            return dispatch(updateComment(lcuid, comment, newText))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentInput);
