import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';

const UserSignedUpStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} joined Lucit!`}
        story={story}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Joined Lucit!</LCTypography>
    </BaseStory >;
};

export default UserSignedUpStory;
