import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, List, ListItem, ListItemAvatar, Typography, useTheme } from '@material-ui/core';
import { lucitApi } from '../../services/lucitApi';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import InvoiceStatusBadge from './InvoiceStatusBadge';
import { formatNumber } from '../../helpers/string';
import { toLocalDateTime } from '../../helpers/date';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../../components';
import LCTypography from '../../components/material/LCTypography';
import { InvoiceStatus, PaymentStatus, RoutePaths } from '../../helpers/constants';
import { GenericDialog } from '../../components/modals/GenericDialog';
import InvoiceLineItem from './InvoiceLineItem';
import moment from 'moment';
import { CardIconsComponentsMap } from './PaymentMethods';
import { AccountBalance } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { history } from '../../helpers/history';
import { Share as ShareIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import ShareInvoiceDialog from './ShareInvoiceDialog';
import { Print } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import { getApplicationLogoUrl } from '../../selectors/layout';

function InvoiceDetails(props) {
    const { lcuid, invoiceAccessToken, publicView } = props;
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    const [payError, setPayError] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const { t } = useTranslation();
    const theme = useTheme()
    const [error, setError] = useState(null);
    const [showShareDialog, setShowShareDialog] = useState(false);

    const parentIsAccount = invoice && invoice.parent_type == 'App\\Account';

    const protectedInvoiceUrl = invoice && (parentIsAccount
        ? RoutePaths.invoice(invoice.lcuid) + '?context_account_id=' + invoice.parent_id
        : RoutePaths.invoice(invoice.lcuid) + '?context_agency_id=' + invoice.parent_id)

    const [isPrinting, setIsPrinting] = useState(false);
    const printComponentRef = useRef();
    const printPromiseResolveRef = useRef(null);

    useEffect(() => {
        if (isPrinting && printPromiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            printPromiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                printPromiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            printPromiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    useEffect(() => {
        setLoading(true);

        if (publicView) {
            lucitApi.billing.getPublicViewInvoice(lcuid, invoiceAccessToken)
                .then(setInvoice)
                .catch(setError)
                .finally(() => setLoading(false))
        }
        else {
            lucitApi.billing.getInvoice(lcuid)
                .then(setInvoice)
                .catch(setError)
                .finally(() => setLoading(false))
        }

    }, [])

    // https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        setColumns([
            {
                title: t("Title"),
                width: "25%",
                render: x => <InvoiceLineItem item={x} column='title' />,
            },
            {
                width: "45%",
                title: t("Period"),
                render: x => <InvoiceLineItem item={x} column='period' />,
            },
            {
                width: "15%",
                title: '',
                align: 'right',
                render: x => <InvoiceLineItem item={x} column='units' />,
            },
            {
                width: "15%",
                title: t("Total Cost"),
                align: 'right',
                render: x => <InvoiceLineItem item={x} column='amount' />,
            },

        ])
    }, [])

    if (error) {
        return <Box>
            <Box display="flex" alignItems="center">
                <Alert severity="error">{t('Invalid invoice or invoice is no longer available')}</Alert>
            </Box>
        </Box>
    }

    if (loading || !invoice) {
        return <CircularProgressCentered size={40} />
    }

    const shouldShowPayButton = !publicView && !isPrinting && (invoice.status == InvoiceStatus.open
        || invoice.status == InvoiceStatus.overdue);

    const shouldShowPayElectronicallyButton = publicView && !isPrinting && (invoice.status == InvoiceStatus.open
        || invoice.status == InvoiceStatus.overdue);

    const shouldShowTransactionsList = !publicView

    const showShowShareButton = !isPrinting && !publicView
    const showPrintButton = !isPrinting

    const mainBoxPaddingLeft = (publicView) ? 12 : (isPrinting ? 6 : 0);
    const mainBoxPaddingTop = (publicView || isPrinting) ? 6 : 2;

    return <div style={{ maxWidth: isPrinting ? "800px" : null }}>
        <Box pl={mainBoxPaddingLeft} pt={mainBoxPaddingTop} pr={mainBoxPaddingLeft} ref={printComponentRef}>

            {isPrinting && <Box mb={2}>
                <div style={{ width: "100%", display: "block", backgroundColor: "black", padding: "10px" }}>
                    <img src={getApplicationLogoUrl()} style={{ width: "100%", maxWidth: "300px", margin: "auto", }} />
                </div>
            </Box>}

            <Box display="flex" alignItems="center">
                <Typography variant="h5" style={{ marginRight: 12 }}>Invoice {invoice.invoice_number}</Typography>
                <InvoiceStatusBadge status={invoice.status} />

                {shouldShowPayElectronicallyButton
                    && <Button
                        variant='contained'
                        color="secondary"
                        size="small"
                        onClick={() => history.push(protectedInvoiceUrl)}
                        style={{ marginLeft: 12, marginRight: 12 }}>
                        {t('Pay Electronically')}
                    </Button>}

                {shouldShowPayButton
                    && <Button
                        variant='contained'
                        color="secondary"
                        size="small"
                        onClick={() => setIsConfirmOpen(true)}
                        style={{ marginLeft: 12, marginRight: 12 }}>
                        {t('Pay')}
                    </Button>}

                {showShowShareButton &&
                    <IconButton
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 12 }}
                        onClick={() => setShowShareDialog(true)}
                    >
                        <ShareIcon fontSize="small" style={{ marginRight: "4px" }} /> Share
                    </IconButton>
                }

                {showPrintButton && <IconButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                >
                    <Print fontSize="small" style={{ marginRight: "4px" }} />
                </IconButton>}

                {showShareDialog && <ShareInvoiceDialog
                    open={showShareDialog}
                    handleClose={() => setShowShareDialog(false)}
                    invoice={invoice}
                />}

                <ConfirmationDialog
                    title={`Pay invoice ${invoice.invoice_number}`}
                    dialogProps={{
                        open: isConfirmOpen,
                        onClose: () => setIsConfirmOpen(false),
                        keepMounted: false
                    }}
                    ConfirmButtonText={t('Pay')}
                    ConfirmButtonProps={{ variant: 'contained', color: 'secondary', disabled: paying }}
                    handleCancel={() => setIsConfirmOpen(false)}
                    handleConfirm={() => {
                        setPaying(true);

                        lucitApi.billing.payInvoice(lcuid)
                            .then(x => {
                                if (x.error)
                                    setPayError(x.error);
                                else
                                    setInvoice(x.invoice)
                            })
                            .finally(() => {
                                setPaying(false);
                                setIsConfirmOpen(false);
                            });
                    }}>
                    <LCTypography>
                        Charge your credit card for the amount of<> </>
                        <b>{formatNumber(invoice.due_amount, { style: 'currency', currency: 'USD' })}</b>
                    </LCTypography>
                </ConfirmationDialog>
            </Box>
            <Box>
                <Typography>{invoice.notes}</Typography>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box>
                            <LCTypography variant='subtitle1' color="textSecondary">Total Amount</LCTypography>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>
                                {formatNumber(invoice.total_amount, { style: 'currency', currency: 'USD' })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8} >
                        <Box>
                            <LCTypography variant='subtitle1' color="textSecondary">Invoice Date</LCTypography>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>
                                {moment(invoice.invoice_date).format('MMM DD, yyyy')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box>
                            <LCTypography variant='subtitle1' color="textSecondary">Amount Due</LCTypography>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>
                                {formatNumber(invoice.due_amount, { style: 'currency', currency: 'USD' })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8} >
                        <Box>
                            <LCTypography variant='subtitle1' color="textSecondary">Due Date</LCTypography>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>
                                {moment(invoice.due_date).format('MMM DD, yyyy')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={1} mb={2}>
                <Divider />
            </Box>
            <Box>
                <MaterialTable
                    columns={columns}
                    data={invoice.invoice_line_items}
                    style={{
                        maxWidth: isPrinting ? "600px" : "800px",
                        width: isPrinting ? "600px" : '100%',
                        boxShadow: 'none'
                    }}
                    options={{
                        tableLayout: 'fixed',
                        headerStyle: { fontSize: '1rem', background: theme.palette.action.hover },
                        emptyRowsWhenPaging: false,
                        paging: false,
                        search: false,
                        showTitle: false,
                        sorting: false,
                        selection: false,
                        toolbar: false,
                        draggable: false
                    }}
                />
            </Box>
            <Box mt={1} mb={2} />

            {shouldShowTransactionsList &&
                <TransactionList invoice={invoice} />
            }

            <Box mt={5} mb={5}></Box>

            <Divider />

            <Box mt={5} mb={5}>
                <div>
                    <LCTypography variant='subtitle1' color="textSecondary">Mailing Address</LCTypography>
                </div>
                <pre>
                    Lucit, LLC<br />
                    314 E. Thayer Ave,<br />
                    Suite 220<br />
                    Bismarck, ND 58501<br />
                </pre>
            </Box>

            <GenericDialog
                title={t(`Payment failed for Invoice ${invoice.invoice_number}`)}
                dialogProps={{
                    open: Boolean(payError),
                    onClose: () => setPayError(null),
                    fullWidth: true,
                    keepMounted: false,
                    maxWidth: 'sm'
                }}
                dialogContentProps={{
                }}
                ContentComponent={<Box>
                    {payError
                        && <>
                            <Typography variant="body1">{payError.message}</Typography>

                            {payError.cannot_process_code === 'parent_does_not_have_stripe' && <>
                                <LCTypography>
                                    Please visit the <strong>Billing page</strong> to add a default payment method to your account
                                </LCTypography>
                            </>}
                        </>}
                </Box>
                }
                ActionsComponent={<>
                    <Button onClick={() => setPayError(null)}
                        color="primary">
                        {t('OK')}
                    </Button>
                </>}
            />
        </Box >
    </div>
}

const TransactionList = ({ invoice }) => {
    if (invoice.payments.length === 0) {
        return null;
    }

    return <>
        <Typography variant="h5">Transactions</Typography>
        <Box>
            <List style={{ maxWidth: 500 }}>
                {invoice.payments.map(p => {
                    const card = p.options &&
                        p.options.meta_data &&
                        p.options.meta_data.charge &&
                        p.options.meta_data.charge.source;

                    if (p.status == PaymentStatus.failed) {
                        return <ListItem key={p.lcuid}>
                            <Typography>
                                Payment failed
                            </Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ marginLeft: 'auto' }}
                            >
                                {toLocalDateTime(p.created_at).format('MMM DD, yyyy, hh:mm A')}
                            </Typography>
                        </ListItem>
                    }

                    if (card != null) {
                        return <ListItem key={p.lcuid}>
                            <ListItemAvatar style={{ display: 'flex', alignItems: 'center', minWidth: 48 }}>
                                {CardIconsComponentsMap[card.brand] || CardIconsComponentsMap['']}
                            </ListItemAvatar>
                            <LCTypography>
                                {formatNumber(p.amount_applied, { style: 'currency', currency: 'USD' })}&nbsp;
                                by card ending <span style={{ letterSpacing: '1px' }}>{card.last4}</span>
                            </LCTypography>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ marginLeft: 'auto' }}
                            >
                                {toLocalDateTime(p.created_at).format('MMM DD, yyyy, hh:mm A')}
                            </Typography>
                        </ListItem>;
                    }

                    return <ListItem key={p.lcuid}>
                        <ListItemAvatar style={{ display: 'flex', alignItems: 'center', minWidth: 48 }}>
                            <AccountBalance />
                        </ListItemAvatar>
                        <Typography>
                            {formatNumber(p.amount_applied, { style: 'currency', currency: 'USD' })}&nbsp;
                            by Check Number {p.payment_source_id}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                            style={{ marginLeft: 'auto' }}
                        >
                            {toLocalDateTime(p.created_at).format('MMM DD, yyyy, hh:mm A')}
                        </Typography>
                    </ListItem>;
                })}
            </List>
        </Box >
    </>
}

InvoiceDetails.propTypes = {
    lcuid: PropTypes.string.isRequired
}
export default InvoiceDetails;
