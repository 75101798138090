import LCTypography from '../../components/material/LCTypography';
import React from 'react';
import { connect } from 'react-redux';
import Inventory from './Inventory';
import { PageTitle } from '../../components';
import { InventoryItemClasses } from '../../helpers/constants';
import { hasExports } from '../../selectors/exports'
import PostButton from '../../containers/inventory/PostButton'

function Creatives(props) {
    const { hasExports } = props;

    return <>
        <PageTitle title={"Creatives"} />
        <Inventory
            title="Creatives"
            noDataLabel={<LCTypography variant="body1">
                You don&apos;t have any creatives yet.
            </LCTypography>}
            inventoryClass={InventoryItemClasses.creative}
            scrollToTopFab={false}
            Actions={hasExports ? <PostButton />
                : null}
            {...props} />
    </>
}

const mapStateToProps = state => {
    return {
        hasExports: hasExports(state),
    }
}

export default connect(
    mapStateToProps
)(Creatives);
