import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NewApplicationDialog from './NewApplicationDialog';

const CreateApplicationButton = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation();

    return <>
        <Button
            variant="contained"
            onClick={() => setDialogOpen(true)}
            color="primary"
        >
            {t('New Application')}
        </Button>

        <NewApplicationDialog
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
}

export default connect(
    mapStateToProps
)(
    CreateApplicationButton
);
