import React from 'react';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import { Box, Drawer, makeStyles, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ButtonLoader from '../../components/material/ButtonLoader';
import { unselectAll, exportSelectedItems } from '../../actions/inventory';
import { selectedCount } from '../../selectors/inventory';
import InventoryExportForm from './InventoryExportForm';
import { showSuccess } from '../../actions/snackbar';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        paper: {
            width: '500px',
            left: 'calc(50% - 250px + 120px)',
            marginTop: theme.spacing(8),

            [theme.breakpoints.down('sm')]: {
                left: 'calc(50% - 200px + 120px)',
                width: '400px'
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        },

        content: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2)
        },

        actions: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 'auto'
        }
    }
})

function InventoryItemSkeleton(props) {
    const { selectedCount, handleClose, open, onSubmit, handleSubmit, submitting } = props;
    const classes = useStyles();

    return (
        <Drawer
            anchor="top"
            open={open}
            transitionDuration={500}
            variant="persistent"
            ModalProps={{
                disableScrollLock: true,
                disableBackdropClick: true,
            }}
            PaperProps={{
                elevation: 4
            }}
            classes={{
                paper: classes.paper
            }}
        >
            <Box className={classes.content}>
                <Box mb={2}>
                    <LCTypography transProp={{ selectedCount: selectedCount }}
                        variant="h6">{{ selectedCount: selectedCount }} item(s) selected</LCTypography>
                </Box>

                <Box mb={2}>
                    <InventoryExportForm
                        form={FORM_INVENTORY_EXPORT}
                        onSubmit={onSubmit} />
                </Box>

                <Box className={classes.actions}>
                    <ButtonLoader
                        submitting={submitting}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >Promote</ButtonLoader>
                    <Box mr={2} />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        startIcon={<CloseIcon />}
                    >Close</Button>
                </Box>
            </Box>
        </Drawer>

    );
}

const mapStateToProps = state => {
    return {
        submitting: isSubmitting(FORM_INVENTORY_EXPORT)(state),
        selectedCount: selectedCount(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: values => {
            const exportIds = values.exports.map(e => e.id);

            // eslint-disable-next-line no-undef
            return dispatch(exportSelectedItems(exportIds))
                .then(() => dispatch(unselectAll()))
                .then(() => dispatch(showSuccess("Items were successfully exported!")))
        },
        handleSubmit: () => dispatch(submit(FORM_INVENTORY_EXPORT)),
        handleClose: () => dispatch(unselectAll()),
    }
}

export const FORM_INVENTORY_EXPORT = "inventoryExport";
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemSkeleton
);
