import { IonBadge } from '@ionic/react';
import React from 'react';
import { ApplicationStatus } from '../../helpers/constants';

const ApplicationStatusBadge = ({ application, hideBadgeIfActive }) => {
    const style = { minWidth: 'fit-content' };

    if(hideBadgeIfActive && application.status === ApplicationStatus.active.id)
        return null;

    return <>
        {application.status === ApplicationStatus.active.id
            && <IonBadge style={style} color="success">{ApplicationStatus.active.title}</IonBadge>}
        {application.status === ApplicationStatus.draft.id
            && <IonBadge style={style} color="light">{ApplicationStatus.draft.title}</IonBadge>}
        {application.status === ApplicationStatus.pending.id
            && <IonBadge style={style} color="primary">{ApplicationStatus.pending.title}</IonBadge>}
        {application.status === ApplicationStatus.rejected.id
            && <IonBadge style={style} color="danger">{ApplicationStatus.rejected.title}</IonBadge>}
        {application.status === ApplicationStatus.inactive.id
            && <IonBadge style={style} color="warning">{ApplicationStatus.inactive.title}</IonBadge>}
        {application.status === ApplicationStatus.deleted.id
            && <IonBadge style={style} color="warning">{ApplicationStatus.deleted.title}</IonBadge>}
    </>
}

export default ApplicationStatusBadge;
