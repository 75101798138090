import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clx from 'classnames';
import {
    Grid, Table, Chip, TableBody, TableCell, TableContainer, TableHead, TableRow, Link,
    List, ListItem, ListItemText, ListItemAvatar, Hidden, Box
} from '@material-ui/core';
import { IonCardContent, IonCardTitle, IonCardSubtitle, IonCard, IonCardHeader, IonButton, IonGrid, IonRow, IonIcon } from '@ionic/react';
import { getUser, selectedAgency } from '../../selectors/user';
import { Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount'
import LCTypography from '../../components/material/LCTypography';
import { business } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { Link as RouterLink } from 'react-router-dom'
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { CampaignState } from '../../helpers/constants';
import { SignBirdPromoCard } from '../../apps/signbird/signBirdPromoCard';
import { isOperator } from '../../selectors/agencies';
import { AgencyAvatar } from '../agency';
import { AccountAvatar } from '../account';
import { sortNumber, sortString } from '../../helpers/sorters';
import { RoutePaths } from '../../helpers/constants';
import { getPermissions } from '../../actions/user';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    summary: {
        cursor: 'default !important'
    },
    content: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    operatorName: {
        marginLeft: '8px',
        marginTop: '4px',
    },
    operatorButtons: {
        [theme.breakpoints.down('xs')]: {
            //width: '100%',
        },
    },
    homeGridContainer: {
        height: '100%',
        margin: 'auto',
        maxWidth: 900,
    },
    homeGridContent: {
        paddingBottom: '500px'
    },
    userHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    userHeaderTitleAvatar: {
        marginRight: '6px'
    },
    accountNameLink: {
        display: 'flex',
        alignItems: 'Center',
        flex: 1,
        textDecoration: 'none',
    },
    accountNameContainer: {
        display: 'flex'
    },
    avatar: {
        backgroundColor: 'transparent',
    },
    avatarNoIcon: {
        backgroundColor: theme.palette.secondary.main,
    },
    accountMobileList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accountMobileItemInline: {
        display: 'inline',
    },
    accountMobileListCard: {
        marginTop: 0,
    },
    clientItemGrid: {
        marginTop: 3,
        marginBottom: 3,
    },
    clientItemGridItem: {
        margin: 'auto',
        padding: 4,
        textAlign: 'center',
    },
    signBirdTipCard: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    signBirdText: {
        color: '#ff6900',
    },
}));

const DashboardOperatorUser = ({ selectedAgency }) => {
    const classes = useStyles();
    const agency = selectedAgency;
    const { t } = useTranslation();
    const [campaignOverviewAccounts, setCampaignOverviewAccounts] = useState([]);
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPermissions());
    }, [])

    useEffect(() => {
        lucitApi.agencies.getAccountCampaignOverview(agency.id)
            .then(accounts => {
                const getCampaignState = account => account.inventory_exports
                    && account.inventory_exports.length
                    && account.inventory_exports[0].campaign_state;
                const hasCampaign = (account, state) => getCampaignState(account) == state;

                const accountsSorted = (accounts || [])
                    // Sort order:
                    // 1. Active campaigns
                    // 2. pendingTraffic campaigns
                    // 3. pending campaigns
                    // 4. draft campaigns
                    // 5. rest campaigns
                    // then sort by name
                    .sort((a, b) => {
                        if (!hasCampaign(a, CampaignState.running.id) && hasCampaign(b, CampaignState.running.id))
                            return 1;
                        if (hasCampaign(a, CampaignState.running.id) && !hasCampaign(b, CampaignState.running.id))
                            return -1;
                        if (!hasCampaign(a, CampaignState.pendingTraffic.id) && hasCampaign(b, CampaignState.pendingTraffic.id))
                            return 1;
                        if (hasCampaign(a, CampaignState.pendingTraffic.id) && !hasCampaign(b, CampaignState.pendingTraffic.id))
                            return -1;
                        if (!hasCampaign(a, CampaignState.pending.id) && hasCampaign(b, CampaignState.pending.id))
                            return 1;
                        if (hasCampaign(a, CampaignState.pending.id) && !hasCampaign(b, CampaignState.pending.id))
                            return -1;
                        if (!hasCampaign(a, CampaignState.draft.id) && hasCampaign(b, CampaignState.draft.id))
                            return 1;
                        if (hasCampaign(a, CampaignState.draft.id) && !hasCampaign(b, CampaignState.draft.id))
                            return -1;
                        if (getCampaignState(a) != getCampaignState(b))
                            return sortNumber(getCampaignState(a), getCampaignState(b))

                        return sortString(a.name, b.name);
                    });

                setCampaignOverviewAccounts(accountsSorted);
                setLoading(false)
            })
    }, [])

    return (
        <>
            <Grid
                container
                className={classes.homeGridContainer}
            >
                <Grid item xs={12} md={12} className={classes.homeGridContent}>

                    <IonCardContent>
                        <IonCardSubtitle>{t('Welcome')}</IonCardSubtitle>
                        <IonCardTitle className={classes.userHeaderTitle}>
                            <AgencyAvatar
                                className={clx(classes.userHeaderTitleAvatar)}
                                agency={agency} />
                            {t(agency.name)}
                        </IonCardTitle>
                    </IonCardContent>

                    <Divider />

                    <OperatorTipsCard />

                    {loading ? <CircularProgressCentered size={40} /> : null}
                    {(campaignOverviewAccounts && campaignOverviewAccounts.length) ? <>
                        <Hidden mdUp>
                            <OperatorClientMobileList campaignOverviewAccounts={campaignOverviewAccounts} />
                        </Hidden>
                        <Hidden smDown>
                            <OperatorActiveAccountSection campaignOverviewAccounts={campaignOverviewAccounts} />
                        </Hidden>
                    </> : null}

                    {isOperator(selectedAgency) && <SignBirdPromoCard />}

                </Grid>
            </Grid>
        </>
    )
}

const OperatorTipsCard = () => {
    const classes = useStyles();
    const [showAddNewAccount, setShowAddNewAccount] = useState(false)
    const { t } = useTranslation();

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle>Tip</IonCardSubtitle>
                    <IonCardTitle>Did you know?</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <LCTypography>
                        You can connect <strong>all of your accounts</strong> in order to provide them
                        real-time statistics, creative control, and automated inventory integration.
                    </LCTypography>
                    <Box mb={2} mt={2}>
                        <LCTypography>
                            Looking for <strong>inspiration</strong>? - Check out the<> </>
                            <Link
                                color="secondary"
                                href="https://lucit.cc/industry-inspo"
                                rel="noopener noreferrer"
                                target="_blank">
                                Lucit Industry Inspo Gallery
                            </Link>
                        </LCTypography>
                    </Box>
                    <IonGrid>
                        <IonRow>
                            <IonButton
                                className={classes.operatorButtons}
                                onClick={() => setShowAddNewAccount(true)}
                            >
                                <IonIcon slot="start" icon={business}></IonIcon>
                                {t('Add Account')}
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>

                <OperatorAddAccount
                    showAgencyAndOperator={false}
                    open={showAddNewAccount}
                    handleClose={() => setShowAddNewAccount(false)}
                />
            </IonCard>
        </>
    )
}

const OperatorActiveAccountSection = ({ campaignOverviewAccounts }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <IonCard className={classes.activeAccountsCard}>
                <OperatorClientTable campaignOverviewAccounts={campaignOverviewAccounts} />
                <Box mt={2} mb={3} pr={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link
                        component={RouterLink}
                        style={{ cursor: "pointer" }}
                        color="secondary"
                        to={RoutePaths.agencyAccounts}
                    >
                        {t('View All Accounts')}
                    </Link>
                </Box>
            </IonCard>
        </>
    )
}

const OperatorClientTable = ({ campaignOverviewAccounts }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <TableContainer className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Account')}</TableCell>
                            <TableCell align="right">{t('Screens')}</TableCell>
                            <TableCell align="right">{t('Status')}</TableCell>
                            <TableCell align="right">{t('Last Play')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            campaignOverviewAccounts.map((account, index) => {
                                return <OperatorClientTableRow key={index} account={account} />
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const OperatorClientTableRow = ({ account }) => {
    const classes = useStyles();

    const getBestCampaignState = () => {
        return (account.inventory_exports && account.inventory_exports.length) ? account.inventory_exports[0].campaign_state : 0
    }

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell className={classes.accountNameContainer}>
                    <Link component={RouterLink}
                        to={{ pathname: `/${account.slug}` }}
                        color="secondary"
                        style={{ textDecoration: 'none' }}
                        className={classes.accountNameLink}>
                        <AccountAvatar account={account}
                            className={clx(classes.userHeaderTitleAvatar)}
                        />
                        {account.name}
                    </Link>
                </TableCell>
                <TableCell align="right">{account.digital_board_ids.length}</TableCell>
                <TableCell align="right">
                    <OperatorAccountStatus
                        campaignState={getBestCampaignState()}
                    />
                </TableCell>
                <TableCell align="right">
                    <AccountLastPlay account={account} />
                </TableCell>
            </TableRow>
        </>
    )
}

const OperatorClientMobileList = ({ campaignOverviewAccounts }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Divider />

            <IonCardContent>
                <IonCardSubtitle>{t('Active')}</IonCardSubtitle>
                <IonCardTitle>{t('Accounts')}</IonCardTitle>
            </IonCardContent>

            <IonCard className={classes.accountMobileListCard}>
                <List className={classes.accountMobileList}>
                    {
                        campaignOverviewAccounts.map((account, index) => {
                            return <OperatorClientMobileListItem key={index} account={account} />
                        })
                    }
                </List>
            </IonCard>
        </>
    )
}

const OperatorClientMobileListItem = ({ account }) => {
    const classes = useStyles();

    const getBestCampaignState = () => {
        return (account.inventory_exports && account.inventory_exports.length) ? account.inventory_exports[0].campaign_state : 0
    }

    return (
        <>
            <ListItem alignItems="flex-start" className={classes.accountMobileItem}>
                <ListItemAvatar>
                    <AccountAvatar account={account}
                        className={clx(classes.userHeaderTitleAvatar)}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Link component={RouterLink}
                            to={{ pathname: `/${account.slug}` }}
                            color="secondary"
                            style={{ textDecoration: 'none' }}
                            className={classes.accountNameLink}>
                            {account.name}
                        </Link>
                    }
                    secondary={
                        <Grid container spacing={3} className={classes.clientItemGrid}>
                            <Grid item xs className={classes.clientItemGridInfo}>
                                <LCTypography variant="subtitle2">Screens</LCTypography>
                                {account.digital_board_ids.length}
                            </Grid>
                            <Grid item xs className={classes.clientItemGridInfo}>
                                <LCTypography variant="subtitle2">Status</LCTypography>
                                <OperatorAccountStatus
                                    campaignState={getBestCampaignState()}
                                />
                            </Grid>
                            <Grid item xs className={classes.clientItemGridInfo}>
                                <LCTypography variant="subtitle2">Last Play</LCTypography>
                                <AccountLastPlay account={account} />
                            </Grid>
                        </Grid>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}

const AccountLastPlay = ({ account }) => {

    const getBestCampaignState = () => {
        return (account.inventory_exports && account.inventory_exports.length) ? account.inventory_exports[0].campaign_state : 0
    }

    const getBestCampaignStateChangedAt = () => {

        if (
            getBestCampaignState() === CampaignState.draft.id ||
            getBestCampaignState() === CampaignState.rejected.id ||
            getBestCampaignState() === CampaignState.pendingTraffic.id ||
            getBestCampaignState() === CampaignState.pending.id ||
            getBestCampaignState() === CampaignState.pulling.id
        ) {
            return null
        }

        return (account.inventory_exports && account.inventory_exports.length) ?
            account.inventory_exports[0].campaign_state_changed_at :
            null
    }

    const lastPlayDateTime = account.last_ping ? (
        account.last_ping.play_datetime ?? account.last_ping.last_play_datetime
    ) : null

    return lastPlayDateTime ?
        <DateTimeRelative date={toLocalDateTime(lastPlayDateTime)} /> :
        (getBestCampaignStateChangedAt() ?
            <DateTimeRelative date={toLocalDateTime(getBestCampaignStateChangedAt())} /> : `--`)

}

const OperatorAccountStatus = ({ campaignState }) => {

    const { t } = useTranslation();

    if (campaignState === CampaignState.running.id) {
        return (
            <Chip variant="outlined" size="small" label={t('Active')} color="secondary" />
        )
    }

    if (campaignState === CampaignState.draft.id) {
        return (
            <Chip variant="outlined" size="small" label={t('Draft')} color="error" />
        )
    }

    if (campaignState === CampaignState.pendingTraffic.id) {
        return (
            <Chip variant="outlined" size="small" label={t('Traffic')} color="secondary" />
        )
    }

    if (campaignState === CampaignState.rejected.id) {
        return (
            <Chip variant="outlined" size="small" label={t('Rejected')} color="error" />
        )
    }

    if (campaignState === CampaignState.pending.id || campaignState === CampaignState.pulling.id) {
        return (
            <Chip variant="outlined" size="small" label={t('Pending')} color="primary" />
        )
    }

    return (
        <Chip variant="outlined" size="small" label={t('In-Active')} color="error" />
    )
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAgency: selectedAgency(state),
    };
}

export default
    connect(
        mapStateToProps
    )(
        DashboardOperatorUser
    );
