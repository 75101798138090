import React from 'react';
import { connect } from 'react-redux';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import UnknownIcon from '@material-ui/icons/Help';

import { getFeedRun } from '../../actions/feeds';
import Tooltip from '../../components/material/Tooltip';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { FeedRunStatus } from '../../helpers/constants';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        imageIcon: {
            width: '100%',
            height: '100%'
        },
        done: {
            fill: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.main
        },
        error: {
            fill: theme.palette.error.main
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
    }
})

function FeedRunStatusIcon(props) {
    const { runId, runStatus, getFeedRun, handleStatusChange } = props;
    const classes = useStyles();

    const { clear, limitExceeded } = useIntervalLimited(() => {
        getFeedRun(runId)
            .then(data => {
                if (data.status !== runStatus) {
                    handleStatusChange(data);
                    clear();
                }
            })
            .catch(error => {
                console.error("FeedRun status updator was stopped due to error", error);
                clear();
            })
    }, { running: runStatus === FeedRunStatus.running });

    return (
        <>
            {runStatus === FeedRunStatus.completed
                && <DoneIcon
                    className={clx(classes.imageIcon, classes.done)} />}
            {runStatus === FeedRunStatus.error
                && <ErrorIcon
                    className={clx(classes.imageIcon, classes.error)} />}
            {runStatus === FeedRunStatus.running
                &&
                <Tooltip
                    hidden={!limitExceeded}
                    containerProps={{ className: classes.imageIcon }}
                    title="Run was stuck...">
                    <CircularProgressCentered
                        className={clx({ [classes.warning]: limitExceeded })}
                        size={40} />
                </Tooltip>}
            {runStatus == null
                && <Tooltip
                    containerProps={{ className: classes.imageIcon }}
                    title="Unknown status...">
                    <UnknownIcon
                        className={clx(classes.imageIcon, classes.warning)} />
                </Tooltip>}
        </>
    );
}

FeedRunStatusIcon.propTypes = {
    runId: PropTypes.number.isRequired,
    runStatus: PropTypes.number.isRequired,
    handleStatusChange: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
    return {
        getFeedRun: id => dispatch(getFeedRun(id))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(
    FeedRunStatusIcon
);
