import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';

const AgencyTeamUserVisitedStory = (props) => {
    const { story, ...rest } = props;

    const primaryStory = story.grouped ? story.stories[0].story : story;

    const user = primaryStory.storyable

    if (!user)
        return null

    const stories = story.grouped
        ? story.stories.map(x => x.story)
        : [primaryStory];

    const storyCount = stories.length;

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} visited Lucit`}
        story={story}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Visited Lucit {storyCount > 1 ? storyCount + ` times` : null}</LCTypography>
    </BaseStory >;
};

export default AgencyTeamUserVisitedStory;
