import React from 'react';
import moment from 'moment';
import { Link } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import RegisterForm from '../containers/registration/RegisterForm';
import { RoutePaths } from '../helpers/constants';
import { connect } from 'react-redux';
import { lucitApi } from '../services/lucitApi';
import { login } from '../actions/user';
import { showSuccess } from '../actions/snackbar';
import { sendPhoneCode } from '../actions/register';
import { getFullPhone } from '../components/inputs/PhoneInput';
import { PageTitle } from '../components';
import { selectedProfile } from '../selectors/user';
import { useTranslation } from 'react-i18next';
import i18next from '../i18n';
import { getApplicationLogoUrl } from '../selectors/layout';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(6),

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    }
}));

const Register = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <PageTitle title={t('Registration')} />
            <div className={classes.paper}>
                <Link component={RouterLink} to={RoutePaths.login} className={classes.avatarContainer}>
                    <img className={classes.avatar} src={getApplicationLogoUrl()} alt="logo" />
                </Link>
                <LCTypography component="h1" variant="h5">
                    Register
                </LCTypography>

                <RegisterForm
                    onSubmit={values => props.handleRegister(values, props.profile)} />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {

    return {
        profile: selectedProfile(state)
    }
}

const mapDisptachToProps = (dispatch, ownProps) => {
    return {
        handleRegister: (values, profile) => {
            const phone = getFullPhone(values.phone);

            if (!values.code) {
                return dispatch(sendPhoneCode(phone));
            }

            const timezone = moment.tz.guess(true);

            return lucitApi.registrations.register(phone, values.code, values.password, timezone)
                .then(() => dispatch(login(phone, values.password)))
                .then(() => dispatch(showSuccess(i18next.t('You have been successfully registered!'))))
                .then(() => ownProps.history.push({ ...ownProps.history.location, pathname: profile.type.path }));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDisptachToProps
)(withRouter(Register));
