import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import StoriesList from '../stories/StoriesList';
import { loadDigitalBoardFeed, loadDigitalBoardFeedNextPage } from '../../actions/stories';
import { InfiniteScroll } from '../../components';
import { ScrollContext } from '../../contexts';

function BillboardFeed(props) {
    const { digitalBoard, stories, loadBillboardFeed, loadBillboardFeedNextPage } = props;

    const load = () => loadBillboardFeed(digitalBoard.lcuid, 1, props.perPage);
    const loadNext = () => loadBillboardFeedNextPage(digitalBoard.lcuid, props.page + 1, props.perPage);
    const scrollContext = useContext(ScrollContext).current;

    useEffect(() => {
        load();
    }, [])

    if (!props.loading && !stories.length) {
        return null;
    }

    return <InfiniteScroll dataLength={stories.length}
        page={props.page}
        perPage={props.perPage}
        lastPage={props.lastPage}
        loading={props.loading}
        load={load}
        loadNext={loadNext}
        scrollableTarget={scrollContext.ref}
    >
        <StoriesList stories={stories} />
    </InfiniteScroll>
}

const mapStateToProps = (state) => {
    return {
        stories: state.stories.public.data,
        loading: state.stories.public.loading,
        page: state.stories.public.currentPage,
        perPage: state.stories.public.perPage,
        lastPage: state.stories.public.lastPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadBillboardFeed: (digitalBoardId, page, perPage) => dispatch(loadDigitalBoardFeed(digitalBoardId, page, perPage)),
        loadBillboardFeedNextPage: (digitalBoardId, page, perPage) => dispatch(loadDigitalBoardFeedNextPage(digitalBoardId, page, perPage)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillboardFeed);