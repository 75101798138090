import { apiHttp } from "../helpers/api";

export default class LucitApiAgencies {

    createAgency(name, website, agencyClass, adminNotes) {
        return apiHttp.post(`/agencies`, {
            name: name,
            website: website,
            agency_class: agencyClass,
            admin_notes: adminNotes
        })
            .then(response => response.data.agency);
    }

    setPrimaryImage(agencyId, image_id) {
        return apiHttp.post(`/agencies/${agencyId}/primaryImage`, { image_id })
            .then(response => response.data.object);
    }

    deletePrimaryImage(agencyId) {
        return apiHttp.delete(`/agencies/${agencyId}/primaryImage`)
            .then(response => response.data);
    }

    update(agencyId, name, description, website, proof_legal_text, support_text) {
        return apiHttp.put(`/agencies/${agencyId}`, {
            name: name,
            description: description,
            website: website,
            proof_legal_text: proof_legal_text,
            support_text: support_text
        })
            .then(response => response.data.agency);
    }

    get(agencyId) {
        return apiHttp.get(`/agencies/` + agencyId)
            .then(response => response.data.agency);
    }

    getAgencies(params = {}) {
        return apiHttp.get(`/agencies`, { params: params })
            .then(response => ({
                agencies: response.data.agencies.data,
                total: response.data.agencies.total
            }));
    }

    getOperatorsWithScreens() {
        return this.getAgencies({ has_digital_boards: 1, per_page: 5000 })
    }

    getDigitalBoards(agencyId, perPage = 5000) {
        return apiHttp.get(`/digital-boards?agency_id=${agencyId}&per_page=${perPage}`)
            .then(response => response.data.digital_boards.data);
    }

    getDigitalBoardFormats(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/digital-board-formats`)
            .then(response => response.data.digital_board_formats);
    }

    getAccounts(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/accounts`)
            .then(response => response.data.accounts);
    }

    getAccountCampaignOverview(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/accounts/campaign-overview`)
            .then(response => response.data.accounts);
    }

    getExports(id) {
        return apiHttp.get(`/agencies/${id}/inventory-exports`)
            .then(response => response.data.inventory_exports);
    }

    getCampaigns(id, filters = {}) {
        return apiHttp.get(`/agencies/${id}/campaigns`, {
            params: {
                campaign_state: filters.campaign_state
                    ? JSON.stringify(filters.campaign_state)
                    : undefined
            }
        })
            .then(response => response.data.campaigns);
    }

    getCampaignsPendingTrafficCount(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/campaigns/pending-traffic-count`)
            .then(response => response.data.pending_traffic_count);
    }

    getApprovalsCount() {
        return apiHttp.get(`/campaigns/approvals/count`)
            .then(response => response.data.pending_approval_counts)
    }

    /**
     *
     * @param {*} agencyId
     * @param {*} approvalSettings { run_state : 1, allow_ai_auto_approve : true, default_ai_min_moderation_score : 0.9 }
     * @returns
     */
    setApprovalSettings(agencyId, approvalSettings) {
        return apiHttp.put(`/agencies/${agencyId}/approval-settings`, approvalSettings)
            .then(response => response);
    }

    getTrustedUsers(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/users/trusted`)
            .then(response => response);
    }

    //Same as getTrustedUSers except we get all users trusted/untrusted
    getOperatorUserTrust(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/users/operator-user-trust`)
            .then(response => response);
    }

    trustUser(agencyId, userId) {
        return apiHttp.put(`/agencies/${agencyId}/users/${userId}/trust`)
            .then(response => response);
    }

    revokeUserTrust(agencyId, userId) {
        return apiHttp.delete(`/agencies/${agencyId}/users/${userId}/trust`)
            .then(response => response);
    }

    getInvoices(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/invoices`)
            .then(response => response.data);
    }
    getBillingPlans(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/billing-plans`)
            .then(response => response.data);
    }

    getCards(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/cards`)
            .then(response => response.data);
    }

    saveCard(agencyId, token) {
        return apiHttp.post(`/agencies/${agencyId}/cards`, { token })
            .then(response => response.data.stripe.card)
    }

    savePrimary(agencyId, id) {
        return apiHttp.put(`/agencies/${agencyId}/cards/${id}/setPrimary`)
            .then(response => response.data)
    }

    deleteCard(agencyId, id) {
        return apiHttp.delete(`/agencies/${agencyId}/cards/${id}`)
            .then(response => response.data)
    }

    getApplications(id) {
        return apiHttp.get(`/agencies/${id}/applications`)
            .then(response => response.data.applications);
    }

    assignApplication(id, application_id) {
        return apiHttp.post(`/agencies/${id}/applications`, { application_id });
    }

    unassignApplication(id, application_id) {
        return apiHttp.delete(`/agencies/${id}/applications/${application_id}`);
    }

    getDriveTemplates(agencyId, filters) {
        return apiHttp.get(`/agencies/${agencyId}/drive-templates`, { params: filters })
            .then(response => response.data.drive_templates);
    }

    getLightningDevices(agencyId, query) {
        return apiHttp.get(`/agencies/${agencyId}/lightning-devices`, { params: { query, per_page: 10000 } }) //dumb high number to get all
            .then(response => response.data.lightning_devices.data);
    }

    getLightningDeviceLayouts(agencyId, query) {
        return apiHttp.get(`/agencies/${agencyId}/lightning-device-layouts`, { params: { query } })
            .then(response => response.data.lightning_device_layouts.data);
    }

    getShadowAccount(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/shadow-account`)
            .then(response => response.data.account);
    }

}
