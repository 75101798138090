import { createSelector } from "reselect";

const billboard = state => state.billboard;

export const billboardSelector = createSelector(
    [billboard],
    billboard => id => {
        const board = billboard.byId[id];

        return board
            ? {
                ...board,
                ...billboard.status[id],
                images: billboard.images[id] || [],
            }
            : null;
    }
)

export const screens = createSelector(
    [billboard, billboardSelector],
    (billboard, selector) => {
        return billboard.all
            .map(selector);
    }
)

/**
 * This accepts an array of boards that contain a location object attached to them
 * And returns an array of locations, with one or more boards attached to them
 * Primarily useful for rendering the map
 */
export const convertBoardArrayToLocationsArray = (boards) => {

    const boardLocations = boards.filter(x => x.location).map(x => ({
        ...x.location,
        targeted: true
    })
    )

    const uniqueBoardLocations = boardLocations.filter((item, index) => boardLocations.findIndex(x => x.id === item.id) === index);

    const refactoredLocations = uniqueBoardLocations.map(x => ({
        ...x,
        targeted: true,
        board_ids: boards.filter(y => y.location.id === x.id),
        board_count: boards.filter(y => y.location.id === x.id).length,
        billboardName: boards.filter(y => y.location.id === x.id).reduce((prev, cur) => prev + cur.name + "\n", "")
    }))

    return refactoredLocations
}

export const billboardOptions = billboard => ({
    //Default
    stats_delayed: false,
    stats_unavailable: false,

    ...billboard.options
})

export const isStatsDelayed = billboard => {
    return billboardOptions(billboard).stats_delayed
}
export const isStatsUnavailable = billboard => {
    return billboardOptions(billboard).stats_unavailable
}

export const getFacingCardinal = (billboard) => {
    return (billboard && billboard.options && billboard.options.facing_cardinal) ? billboard.options.facing_cardinal : ""
}

export const getAvgDailyImpressions = billboard => {
    const number = billboardOptions(billboard).avg_daily_impressions;
    return isNaN(number)
        ? `Unknown`
        : (+number).toLocaleString('en-US')
}

export const getLocationIcon = (location, highlighted) => {
    let format = '';

    const firstBoard = location.board_ids && location.board_ids[0] || location.digital_boards && location.digital_boards[0];

    if (firstBoard) {
        format = firstBoard.digital_board_format ? firstBoard.digital_board_format.code : firstBoard.format_code;
    }

    return getScreenIcon(format, highlighted)
}

export const getCityScapeLocationIcon = (location, highlighted) => {
    let format = '';

    if (location.board_ids) {
        format = location.board_ids[0].format_code;
    }

    if (location.digital_boards) {
        format = location.digital_boards[0].digital_board_format.code;
    }

    return getCityScapeIcon(format, highlighted);
}

export const getScreenIcon = (format, highlighted) => {
    format = format || 'poster';

    return highlighted
        ? `/assets/google-maps/markers/map_icon_screen_${format}_active.png`
        : `/assets/google-maps/markers/map_icon_screen_${format}.png`;
}

export const getCityScapeIcon = (format, highlighted) => {
    format = format || 'poster';

    return highlighted
        ? `/assets/cityScapes/cityscape_screen_${format}_active.png`
        : `/assets/cityScapes/cityscape_${format}.png`;
}

export const getBestFormatForRatio = (formats, ratio) => {
    if (!formats || !ratio)
        return null;

    const formatKeys = Object.keys(formats);
    const formatKey = formatKeys.find((code) => {
        return formats[code].ratios.min <= ratio &&
            formats[code].ratios.max >= ratio;
    });

    return formats[formatKey];
}
