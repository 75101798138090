import React from 'react';
import { Typography, Box } from '@material-ui/core';
import LCTypography from '../../../src/components/material/LCTypography';

export const ScalaLamar = ({ exportItem }) => {

    return (
        <Box p={5}>

            <Box pb={3}>
                <LCTypography variant="h5">Scala - Lamar</LCTypography>
            </Box>

            <LCTypography variant="body2">
                Lamar campaigns are loaded by the traffic team in a special ScalaSript by providing this Lucit campaign ID :
            </LCTypography>

            <Box pt={3} pl={5}>
                <Typography variant="h5">{exportItem.hash_id}</Typography>
            </Box>

        </Box>
    )
}
