import {
    LAYOUT_CLOSE_SCHEDULE_BUILDER, LAYOUT_LOADED, LAYOUT_OPEN_SCHEDULE_BUILDER, LAYOUT_TOGGLE_ACCOUNT_SWITCHER,
    LAYOUT_TOGGLE_AGENCY_SWITCHER, LAYOUT_TOGGLE_LEFT_MENU, LAYOUT_TOGGLE_PROFILE_MENU,
    LAYOUT_TOGGLE_PROFILE_SWITCHER, LAYOUT_TOOGLE_INVENTORY_TOGGLE_SEARCH, LAYOUT_TOOGLE_SIGNIN_DIALOG
} from "../helpers/actionTypes";

export function loadLayoutSettings() {
    return { type: LAYOUT_LOADED }
}

export function toggleAccountSwitcher(open) {
    return {
        type: LAYOUT_TOGGLE_ACCOUNT_SWITCHER,
        open
    }
}

export function toggleAgencySwitcher(open) {
    return {
        type: LAYOUT_TOGGLE_AGENCY_SWITCHER,
        open
    }
}

export function toggleProfileSwitcher(open) {
    return {
        type: LAYOUT_TOGGLE_PROFILE_SWITCHER,
        open
    }
}

export function toggleProfileMenu(open) {
    return {
        type: LAYOUT_TOGGLE_PROFILE_MENU,
        open
    }
}

export function toggleLeftMenu(open) {
    return {
        type: LAYOUT_TOGGLE_LEFT_MENU,
        open
    }
}

export function openScheduleBuilder(item, exportId) {
    return {
        type: LAYOUT_OPEN_SCHEDULE_BUILDER,
        item,
        exportId
    }
}

export function closeScheduleBuilder() {
    return {
        type: LAYOUT_CLOSE_SCHEDULE_BUILDER
    }
}

export function toggleInventorySearch(open) {
    return {
        type: LAYOUT_TOOGLE_INVENTORY_TOGGLE_SEARCH,
        open
    }
}

export function toggleSignInDialog(open) {
    return {
        type: LAYOUT_TOOGLE_SIGNIN_DIALOG,
        open
    }
}
