import React, { useState, useEffect } from 'react';
import { reduxForm, Field, getFormValues, initialize } from 'redux-form';
import { Box, Grid } from '@material-ui/core';

import TextField from '../../../components/inputs/TextField';
import { InventoryItemStatus, QueryString, SortDir, SortFields } from '../../../helpers/constants';
import { connect } from 'react-redux';
import { selectedAccount } from '../../../selectors/user';
import { filters, isSomethingSelected, inventoryTotal } from '../../../selectors/inventory';
import { debounce } from '../../../helpers/async';
import Tooltip from '../../../components/material/Tooltip';
import { useFirstRun } from '../../../helpers/hooks/useFirstRun';
import { useQueryString } from '../../../helpers/hooks/useQueryString';
import SearchPanel from './SearchPanel';

const INVENTORY_FILTERS_FORM = "inventoryFilters";

function InventoryFiltersCreative(props) {
    const { values, selectedAccount, handleSubmit, onSubmit, total } = props;
    const { isSomethingSelected } = props;
    const { initializeFilters, valuesPersisted } = props;

    const submit = handleSubmit(onSubmit);
    const [submitDebounced, setSubmitDebounced] = useState(null);
    const [sortingFields] = useState([
        { title: 'Newest first', field: SortFields.id, direction: SortDir.desc },
        { title: 'Oldest first', field: SortFields.id, direction: SortDir.asc },
        { title: 'Title (A-z)', field: SortFields.title, direction: SortDir.asc },
        { title: 'Title (Z-a)', field: SortFields.title, direction: SortDir.desc },
        { title: 'Status (A-z)', field: SortFields.status, direction: SortDir.asc },
        { title: 'Status (Z-a)', field: SortFields.status, direction: SortDir.desc },
    ]);

    const search = useQueryString(QueryString.search)
    const isFirstRun = useFirstRun();

    useEffect(() => {
        let filters = Object.assign({}, values, valuesPersisted);

        setSubmitDebounced(() => debounce(submit, 1000));

        if (search) {
            filters = Object.assign({}, filters, { search })
        }

        if (filters) {
            initializeFilters(filters);
        }

        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        // As you know useEffect is running not only if dependencies changed, but also on initial render
        // we really don't want to initially submit form here
        if (!isFirstRun) {
            submit();
        }

        // eslint-disable-next-line
    }, [search, selectedAccount])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SearchPanel
                values={values}
                submit={submitDebounced}
                disabled={isSomethingSelected}
                total={total}
                sortingFields={sortingFields}
            >
                <Box mt={3} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Tooltip hidden={!isSomethingSelected}
                                    title={"Please unselect items to change filters"}
                                    placement="bottom-start">
                                    <Field
                                        name="search"
                                        component={TextField}
                                        onChange={submitDebounced}
                                        disabled={isSomethingSelected}
                                        props={{
                                            label: "Search",
                                            variant: "outlined",
                                            fullWidth: true,
                                            clearable: true
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Box mb={2} />
                    </Grid>
                </Grid>
            </SearchPanel>
        </form>
    );
}

const initialValues = {
    category: [],
    exports: [],
    status: [InventoryItemStatus.active, InventoryItemStatus.draft],
    item_class: null,
    mustHavePhotos: true,

    sort: {
        field: SortFields.id,
        direction: SortDir.desc
    }
};

const mapStateToProps = state => {
    return {
        valuesPersisted: filters(state),
        values: getFormValues(INVENTORY_FILTERS_FORM)(state) || initialValues,
        selectedAccount: selectedAccount(state),
        isSomethingSelected: isSomethingSelected(state),

        total: inventoryTotal(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initializeFilters: values => dispatch(initialize(INVENTORY_FILTERS_FORM, values)),
    }
}

export default reduxForm({
    enableReinitialize: true,
    form: INVENTORY_FILTERS_FORM,
    initialValues: initialValues
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InventoryFiltersCreative)
);
