import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Chip, Box, Link, IconButton } from '@material-ui/core';
import { IonCard, IonCardTitle } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { lucitApi } from "../../services/lucitApi";
import { InventoryExportCampaignClasses, ProgrammaticBudgetTypes, QueryString } from "../../helpers/constants";
import moment from 'moment';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePaths } from '../../helpers/constants';
import ExportDetailsStatsSkeleton from '../exports/ExportDetailsStatsSkeleton';
import { CampaignStatusChip } from './CampaignStatusChip';
import { CalendarToday } from '@material-ui/icons';
import { formatNumber } from '../../helpers/string';
import { CampaignBudgetEditDialog } from './CampaignBudgetEditDialog';
import { CampaignDatesEditDialog } from './CampaignDatesEditDialog';
import { CampaignBudgetSpentDialog } from './CampaignBudgetSpentDialog';
import { useTranslation } from 'react-i18next';
import TimedLinearProgress from '../../components/material/TimedLinearProgress';
import { isExportRunningOrWillBe } from '../../selectors/exports';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
    return {
        quickStatsContainer: {
            width: '100%',
        },
        statsGrid: {
            padding: 10,
        },
        quickStatsAlert: {
            borderRadius: '0 0 0 10px',
            color: 'white',
            backgroundColor: theme.palette.primary.dark,
        },
        quickStatsTitleBox: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 10,
        },
        quickStatsTitle: {
            flex: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12pt',
            },
        },
        hover: {
            '& .MuiButtonBase-root': {
                display: 'none'
            },
            "&:hover .MuiButtonBase-root": {
                display: "block"
            }
        }
    }
});

const CampaignOverview = ({ campaign }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [budgetEditOpen, setBudgetEditOpen] = useState(false);
    const [datesEditOpen, setDatesEditOpen] = useState(false);

    const [loadingCosts, setLoadingCosts] = useState(true);
    const [lifetimeCost, setLifetimeCost] = useState(null);
    const [costRollup, setCostRollup] = useState([]);
    const [budgetSpentDialogOpen, setBudgetSpentDialogOpen] = useState(false);

    const { t } = useTranslation();

    const dateFormat = moment(campaign.campaign_start_at).isSame(campaign.campaign_end_at, 'year')
        ? "MMM DD"
        : "MMM DD, YYYY";

    const isRunning = isExportRunningOrWillBe(campaign);

    useEffect(() => {
        if (campaign) {
            setLoading(true);
            setLoadingCosts(true);

            lucitApi.analytics.getOverviewForExport({
                inventory_export_id: campaign.id,
                timeframe: 'all'
            })
                .then(data => setData(data))
                .then(() => setLoading(false))

            lucitApi.exports.getLifetimeCosts(campaign.id)
                .then(data => {
                    setLifetimeCost(data.lifetime_cost)
                    setCostRollup(data.cost_rollup)
                })
                .then(() => setLoadingCosts(false))
        }

    }, [campaign.id])

    return (
        <>
            <IonCard className={classes.quickStatsContainer}>
                <Box className={classes.quickStatsTitleBox}>
                    <IonCardTitle className={classes.quickStatsTitle}>
                        {t('Overview')}
                    </IonCardTitle>
                    <Link
                        component={RouterLink}
                        to={{ pathname: RoutePaths.analytics, search: `?${QueryString.campaignId}=${campaign.id}` }}>
                        <Alert icon={false} className={classes.quickStatsAlert}>
                            {t('Open Analytics')}
                        </Alert>
                    </Link>
                </Box>
                {!loading ?
                    <Grid container className={classes.statsGrid} spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <LCTypography variant='h6' color="primary">Status</LCTypography>
                            <CampaignStatusChip
                                campaign={campaign} />
                        </Grid>
                        {campaign.campaign_class == InventoryExportCampaignClasses.lucitProgrammatic.class
                            && <Grid item xs={6} sm={4} md={3} className={classes.hover}>
                                <LCTypography variant='h6' color="primary"
                                    style={{ display: 'flex', alignItems: 'flex-start' }}>Dates
                                    <IconButton
                                        onClick={() => setDatesEditOpen(true)}
                                        style={{ marginLeft: 8 }}
                                        size="small">
                                        <EditIcon />
                                    </IconButton>
                                </LCTypography>
                                <Box display={"flex"} style={{ gap: 4 }}>
                                    <Chip
                                        label={<>
                                            {moment(campaign.campaign_start_at)
                                                .format(dateFormat)} -&nbsp;
                                            {moment(campaign.campaign_end_at)
                                                .format(dateFormat)}
                                        </>}
                                        icon={<CalendarToday />}
                                    />
                                    <Chip
                                        label={<>
                                            {campaign.budget_type == ProgrammaticBudgetTypes.weekly.code
                                                ? <>
                                                    {moment(campaign.campaign_end_at)
                                                        .diff(moment(campaign.campaign_start_at), 'weeks')} {t('weeks')}
                                                </>
                                                : <>
                                                    {moment(campaign.campaign_end_at)
                                                        .diff(moment(campaign.campaign_start_at), 'days')} {t('days')}
                                                </>}
                                        </>}
                                    />
                                </Box>

                            </Grid>}
                        {campaign.campaign_class == InventoryExportCampaignClasses.lucitProgrammatic.class
                            && <Grid item xs={6} sm={4} md={2} className={classes.hover}>
                                <LCTypography variant='h6' color="primary"
                                    style={{ display: 'flex', alignItems: 'flex-start' }}>Budget
                                    <IconButton
                                        onClick={() => setBudgetEditOpen(true)}
                                        style={{ marginLeft: 8 }}
                                        size="small">
                                        <EditIcon />
                                    </IconButton>
                                </LCTypography>
                                <Box display={"flex"} style={{ gap: 4 }}>
                                    <Chip
                                        label={<>
                                            {formatNumber(campaign.budget_amount,
                                                { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
                                            &nbsp;{ProgrammaticBudgetTypes[campaign.budget_type]?.asSuffix?.toLowerCase()}
                                        </>}
                                    />
                                </Box>
                            </Grid>}

                        {campaign.campaign_class == InventoryExportCampaignClasses.lucitProgrammatic.class
                            && <Grid item xs={6} sm={4} md={2} className={classes.hover}>
                                <LCTypography variant='h6' color="primary"
                                    style={{ display: 'flex', alignItems: 'flex-start' }}>Cost
                                    <IconButton
                                        onClick={() => setBudgetSpentDialogOpen(true)}
                                        style={{ marginLeft: 8 }}
                                        size="small">
                                        <EditIcon />
                                    </IconButton>
                                </LCTypography>
                                <Box display={"flex"} style={{ gap: 4 }}>
                                    <Chip
                                        label={
                                            loadingCosts ?
                                                <><Skeleton /></> : (
                                                    lifetimeCost ?
                                                        <>{formatNumber(
                                                            lifetimeCost,
                                                            { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
                                                        )} spent</>
                                                        : <>--</>)
                                        }
                                    />
                                </Box>
                            </Grid>}

                        {data?.total_plays > 0
                            && <Grid item xs={6} sm={4} md={3}>
                                <LCTypography variant='h6' color="primary">Total Plays</LCTypography>
                                <Chip
                                    className={classes.totalPlays}
                                    label={<>
                                        {data ? data.total_plays.toLocaleString() : `--`}
                                    </>}
                                    icon={<PlayCircleFilledIcon />}
                                />
                            </Grid>}
                        {data?.total_impressions > 0
                            && <Grid item xs={6} sm={4} md={3}>
                                <LCTypography variant='h6' color="primary">Total Impressions</LCTypography>
                                <Chip
                                    className={classes.totalImpressions}
                                    label={<>
                                        {data ? data.total_impressions.toLocaleString() : `--`}
                                    </>}
                                    icon={<VisibilityIcon />}
                                />
                            </Grid>
                        }
                        {Boolean(data?.total_play_duration)
                            && <Grid item xs={6} sm={4} md={3}>
                                <LCTypography variant='h6' color="primary">Time On Screen</LCTypography>
                                <Chip
                                    className={classes.totalDuration}
                                    label={<>
                                        {data ? moment.duration(data.total_play_duration,
                                            'seconds').format("hh:mm:ss", { trim: false }) : `--`}
                                    </>}
                                    icon={<ScheduleIcon />}
                                />
                            </Grid>}

                        {isRunning && <Grid item xs={12}>
                            <TimedLinearProgress
                                durationInSeconds={12}
                                complete={!isRunning}
                                containerStyle={{ width: "100%" }}
                            />
                        </Grid>
                        }
                    </Grid>
                    : <ExportDetailsStatsSkeleton />}
            </IonCard>

            {budgetEditOpen
                && <CampaignBudgetEditDialog
                    open={true}
                    handleClose={() => setBudgetEditOpen(false)}
                    campaign={campaign}
                />}
            {datesEditOpen
                && <CampaignDatesEditDialog
                    open={true}
                    handleClose={() => setDatesEditOpen(false)}
                    campaign={campaign}
                />}

            {budgetSpentDialogOpen
                && <CampaignBudgetSpentDialog
                    open={true}
                    handleClose={() => setBudgetSpentDialogOpen(false)}
                    costRollup={costRollup}
                    lifetimeCost={lifetimeCost}
                />}
        </>
    )
}

export default CampaignOverview;
