import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Link, Box, Typography } from '@material-ui/core';
import { Person, Share as ShareIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Share } from '@capacitor/share';
import LCTypography from '../../components/material/LCTypography';
import { BillboardIcon, RoutePaths } from '../../helpers/constants';
import { connect } from 'react-redux';
import { getItem } from '../../actions/inventory';
import { getExport } from '../../actions/exports';
import { isLoggedIn } from '../../selectors/user';
import ButtonLoader from '../../components/material/ButtonLoader';
import { getFullUrl } from '../../helpers/history';

const useStyles = makeStyles(() => {
    return {
        image: {
            width: '100%',
            flexGrow: 1
        },
    }
})

function InventoryPhoto(props) {
    const { photo, getInventoryItem, getExport, share, user, account, isLoggedIn } = props;
    const classes = useStyles();

    const [item, setItem] = useState(null);
    const [exportItem, setExport] = useState(null);
    const [sharing, setSharing] = useState(false);

    useEffect(() => {
        if (props.item !== undefined) {
            setItem(props.item);
            return;
        }

        if (photo.inventory_item_id) {
            getInventoryItem(photo.inventory_item_id)
                .then(setItem);

        }
    }, [photo.inventory_item_id, getInventoryItem, props.item])

    useEffect(() => {
        if (props.exportItem !== undefined) {
            setExport(props.exportItem);
            return;
        }

        if (photo.options && photo.options.inventory_export_id) {
            getExport(photo.options.inventory_export_id)
                .then(setExport);

        }
    }, [photo.options, getExport, props.exportItem])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <img className={classes.image} src={photo.public_url} alt={photo.inventory_item_id} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {item
                        && <Box mb={3}>
                            {isLoggedIn
                                ? <Link component={RouterLink}
                                    variant="h6"
                                    to={{ pathname: `${RoutePaths.inventory}/${photo.inventory_item_id}` }}
                                    color="secondary">
                                    {item.title}
                                </Link>
                                : <LCTypography transProps={{ title: item.title }} variant="h6">
                                    {{ title: item.title }}
                                </LCTypography>}
                        </Box>}
                    {exportItem
                        && <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                            <Grid item>
                                <BillboardIcon color="secondary" />
                            </Grid>
                            <Grid item xs>
                                <Link component={RouterLink}
                                    to={{ pathname: `${RoutePaths.exports}/${exportItem.id}` }}
                                    color="secondary">
                                    <Typography variant="body1" >{exportItem.name}</Typography>
                                </Link>
                            </Grid>
                        </Grid>}
                    {user
                        && <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                            <Grid item>
                                <LCTypography>Published:</LCTypography>
                            </Grid>
                            <Grid item xs>
                                <Link component={RouterLink}
                                    variant="body1"
                                    to={RoutePaths.public.slug(user.slug)}
                                    color="secondary">
                                    <Box display="flex">
                                        <Person color="secondary" />
                                        {user.name}
                                    </Box>
                                </Link>
                            </Grid>
                        </Grid>}
                    {account
                        && <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                            <Grid item>
                                <LCTypography>Account:</LCTypography>
                            </Grid>
                            <Grid item xs>
                                <Link component={RouterLink}
                                    variant="body1"
                                    to={RoutePaths.public.slug(account.slug)}
                                    color="secondary">
                                    {account.name}
                                </Link>
                            </Grid>
                        </Grid>}
                    <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                        <Grid item>
                            <Box mt={2} />
                            <Box ml={2}>
                                <ButtonLoader
                                    startIcon={<ShareIcon />}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    submitting={sharing}
                                    onClick={() => {
                                        setSharing(true);
                                        share(item, photo)
                                            .finally(() => setSharing(false))
                                    }}
                                >
                                    Share
                                </ButtonLoader>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}

InventoryPhoto.propTypes = {
    photo: PropTypes.object.isRequired,
    item: PropTypes.object,
    exportItem: PropTypes.object,
    user: PropTypes.object,
    account: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getInventoryItem: id => dispatch(getItem(id)),
        getExport: id => dispatch(getExport(id)),

        share: (item, photo) => {
            return Share.share({
                title: item.title,
                text: item.title,
                url: getFullUrl(RoutePaths.public.slug(photo.lcuid)),
                dialogTitle: 'Share photo',
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryPhoto
);
