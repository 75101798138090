import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";

import {
    EXPORTS_GETBYID_RESULT, INVENTORYSTATUS_EXPORT_ITEMS, INVENTORYSTATUS_UNEXPORT_ITEMS, EXPORTS_SEARCH_REQUEST, EXPORTS_SEARCH_RESULT,
    EXPORTS_RUN, EXPORTS_NEXT_PAGE_REQUEST, EXPORTS_NEXT_PAGE_RESULT, EXPORTS_SAVE_SCROLL_POSITION, USER_SELECT_ACCOUNT,
    EXPORTS_STATUS_UPDATE, CAMPAIGNS_CREATED_RESULT, EXPORTS_SET_BOARD_IDS, EXPORTS_DELETE, EXPORTS_UPDATE, EXPORTS_CAMPAIGN_STATE_CHANGE
} from "../helpers/actionTypes";
import { ExportStatus } from "../helpers/constants";

export const initialState = {
    filters: { active: 1 },
    searched: false,
    data: [],

    //designed as backup data for local filtering
    dataOriginal: [],

    loadingNextPage: false,

    currentPage: 1,
    perPage: 100,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,

    scrollY: null,

    invalidated: true
}

export const persistConfig = {
    key: 'exports',
    storage: localStorage,
    blacklist: ["loadingNextPage"],
    stateReconciler: autoMergeLevel2,
}

export default function exports(state = initialState, { type, ...payload }) {
    switch (type) {
        case INVENTORYSTATUS_EXPORT_ITEMS:
        case INVENTORYSTATUS_UNEXPORT_ITEMS:
            {
                return Object.assign({},
                    state,
                    {
                        data: state.data.map(x => {
                            if (x.id === payload.exportId) {
                                return {
                                    ...x,
                                    status: ExportStatus.running
                                };
                            }

                            return x;
                        })

                    });
            }

        case EXPORTS_SET_BOARD_IDS:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.exportId) {
                        return {
                            ...x,
                            settings_array: {
                                ...x.settings_array,
                                transformer: {
                                    ...x.settings_array.transformer,
                                    digital_board_ids: payload.data.digital_boards.map((board) => board.id),
                                }
                            },
                            operators: [
                                {
                                    id: payload.data.digital_boards[0] && payload.data.digital_boards[0].agency_id
                                }
                            ]
                        }
                    }

                    return x;
                }
                )
            });

        case USER_SELECT_ACCOUNT:
            return Object.assign({},
                state,
                {
                    filters: null,
                    data: [],
                    invalidated: true
                });
        case CAMPAIGNS_CREATED_RESULT:
            return Object.assign({},
                state,
                {
                    filters: null,
                    data: [],
                    invalidated: true
                });
        case EXPORTS_SEARCH_REQUEST:
            return Object.assign({},
                state,
                {
                    searched: false,
                    data: [],
                    filters: null,
                },
                payload);
        case EXPORTS_SEARCH_RESULT:
            return Object.assign({},
                state,
                payload,
                {
                    dataOriginal: payload.isOffline
                        ? state.dataOriginal
                        : payload.data,
                    searched: true,
                    scrollY: null,
                    invalidated: false
                });
        case EXPORTS_NEXT_PAGE_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingNextPage: true
                });
        case EXPORTS_NEXT_PAGE_RESULT: {
            const { data = [], ...rest } = payload;
            const dataNext = state.data.concat(data);

            return Object.assign({},
                state,
                rest,
                {
                    loadingNextPage: false,
                    data: dataNext,
                    dataOriginal: dataNext
                });
        }
        case EXPORTS_SAVE_SCROLL_POSITION:
            return Object.assign({}, state, {
                scrollY: payload.scrollY
            })
        case EXPORTS_GETBYID_RESULT: {
            const exportItem = state.data.find(x => x.id === payload.id);

            return Object.assign({}, state, {
                data: exportItem
                    ? state.data.map(x => x.id === payload.id
                        ? Object.assign({}, x, payload)
                        : x)
                    : [
                        ...state.data,
                        payload
                    ]
            })
        }

        case EXPORTS_STATUS_UPDATE:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.id) {
                        return {
                            ...x,
                            status: payload.status
                        }
                    }

                    return x;
                })
            })

        case EXPORTS_CAMPAIGN_STATE_CHANGE:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.exportId) {
                        return {
                            ...x,
                            campaign_state: payload.data.campaign_state
                        }
                    }

                    return x;
                })
            })

        case EXPORTS_RUN:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id == payload.id) {
                        return {
                            ...x,
                            status: ExportStatus.running
                        }
                    }

                    return x;
                })
            })
        case EXPORTS_DELETE:
            {
                const newState = {
                    data: state.data.filter((x => x.id !== payload.exportItem.id))
                };

                return Object.assign({}, state, newState);
            }
        default:
            return state

        case EXPORTS_UPDATE:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.exportId) {
                        return {
                            ...x,
                            name: payload.fields.name
                        }
                    }

                    return x;
                })
            })
    }
}
