import { sortBoolean, sortNumber } from "../helpers/sorters";
import { boardImagesHaveAnyPhotos, getFirstPhotoForBoard, isLucitXRStreetView } from "./inventory";
import { StreetViewStatus, InventoryItemCreativeState } from "../helpers/constants";

export const proofBoardContexts = proof => {
    if (!proof || !proof.digital_boards) {
        return [];
    }

    return proof.digital_boards
        .map((board) => proofBoardContext(proof, board))
        .filter(x => Boolean(x))
}

export const proofBoardContext = (proof, board) => {
    // Get first inventory photo that matches board sizing;
    const photo = getFirstPhotoForBoard(board, proof.inventory_item);

    if (!photo)
        return null;    //For some reason, no creative exists in this size

    const boardCreatives = proof.creatives_to_board_images
        // Find creatives for given inventory photo and board
        .filter(x => x.inventory_photo_id == photo.id &&
            x.digital_board_to_image.digital_board_id == board.id)

        // Sort creatives that either StreetView or Main BoardImage or non-main
        // Streetview creatives always last
        .sort((a, b) =>
            sortNumber(b.digital_board_to_image.is_main_image, a.digital_board_to_image.is_main_image) ||
            sortBoolean(isLucitXRStreetView(a), isLucitXRStreetView(b)));

    // Filter out non-streetview creatives if board has any streetview creatives
    const creatives = boardImagesHaveAnyPhotos(boardCreatives)
        ? boardCreatives.filter(x => !isLucitXRStreetView(x))
        : boardCreatives

    // Take first creative for main preview image
    const creative = creatives.find(() => true)

    return {
        board,
        creatives,
        creative,
        photo
    }
}

export const keepCheckingBackgroundDataForProof = (data) => {

    if (!data || !data.inventory_item)
        return true;

    const secondsSinceCreated = (new Date() - new Date(data.inventory_item.created_at)) / 1000;

    if (secondsSinceCreated > 60)
        return false;

    return true;

}

export const keepCheckingForNewProofData = (data) => {

    if (!data || !data.inventory_item)
        return true;

    const secondsSinceCreated = (new Date() - new Date(data.inventory_item.created_at)) / 1000;

    if (secondsSinceCreated > 60)
        return false;

    if (data.inventory_item.creative_state == InventoryItemCreativeState.building)
        return true;

    if (!data.creatives_to_board_images || !data.digital_boards)
        return true;

    if (data.creatives_to_board_images.length == 0 || data.digital_boards.length == 0)
        return true;

    const boardCountWithStreetView = data.digital_boards.filter(x => x.street_view_status === StreetViewStatus.active).length;

    //https://medium.com/code-85/how-to-find-unique-values-of-a-specific-key-in-an-array-of-objects-276e28b9770d
    const uniqueBoardsInImages = [...
        new Set(data.creatives_to_board_images.map(obj => { return obj.digital_board_to_image.digital_board_id }))
    ]

    const uniqueBoardsInImagesCount = uniqueBoardsInImages.length

    if (uniqueBoardsInImagesCount < boardCountWithStreetView)
        return true;

    return false;

}

export const enableCeateProofButton = (billboard) => {
    if (!billboard)
        return false

    if (billboard.street_view_status === StreetViewStatus.active)
        return true;

    if (!billboard.digital_board_to_images)
        return false

    if (!billboard.digital_board_to_images.length)
        return false

    return true
}
