import { EntitySet, ENTITY_NEXT_PAGE_ERROR, ENTITY_NEXT_PAGE_REQUEST, ENTITY_NEXT_PAGE_RESULT, ENTITY_SEARCH_ERROR, ENTITY_SEARCH_REQUEST, ENTITY_SEARCH_RESULT } from "../helpers/actionTypes";
import entities, { initialState as entitiesInitialState } from "./templates/entities";

export const initialState = {
    ...entitiesInitialState,

}

export default function globalSearch(state = initialState, action) {
    switch (action.type) {
        case ENTITY_SEARCH_REQUEST:
        case ENTITY_SEARCH_ERROR:
        case ENTITY_SEARCH_RESULT:
        case ENTITY_NEXT_PAGE_REQUEST:
        case ENTITY_NEXT_PAGE_ERROR:
        case ENTITY_NEXT_PAGE_RESULT: {
            if (action.entitySet === EntitySet.globalSearch) {
                return {
                    ...state,
                    ...entities(state, action)
                };
            }
            return state;
        }
        default:
            return state
    }
}
