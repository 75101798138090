import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Box, Link } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { RoutePaths } from '../helpers/constants';
import RegisterUserInfoForm from '../containers/registration/RegisterUserInfoForm';
import { createUser } from '../actions/register';
import { login } from '../actions/user';
import { showSuccess } from '../actions/snackbar';
import { useHistory } from 'react-router';
import { PageTitle } from '../components';
import { selectedProfile } from '../selectors/user';
import { useTranslation } from 'react-i18next';
import i18next from '../i18n';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(6),

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    }
}));

const RegistrationUserInfo = (props) => {
    const { registrationId, phone, profile } = props;
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const logo = require('../assets/images/LucitLogoBlackOnTransparent.png').default;

    useEffect(() => {
        if (!registrationId) {
            history.push({
                ...history.location,
                pathname: RoutePaths.login
            });
        }
    }, [registrationId])

    return (
        <Container component="main" maxWidth="xs">
            <PageTitle title={t('Registration')} />
            <div className={classes.paper}>
                <Link component={RouterLink} to={RoutePaths.login} className={classes.avatarContainer}>
                    <img className={classes.avatar} src={logo} alt="logo" />
                </Link>
                <LCTypography component="h1" variant="h5">
                    Personal Information
                </LCTypography>
                <Box mt={1} />

                <RegisterUserInfoForm
                    onSubmit={values => props.handleRegister({
                        ...values,
                        registrationId,
                        phone
                    }, profile)}
                />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {

    return {
        profile: selectedProfile(state),
        phone: state.register.phone,
        registrationId: state.register.registrationId,
    }
}

const mapDisptachToProps = (dispatch, ownProps) => {
    return {
        handleRegister: (values, profile) => {
            const timezone = moment.tz.guess(true);

            return dispatch(createUser(values.registrationId, values.fullName, values.email, values.password, timezone))
                .then(() => dispatch(showSuccess(i18next.t('Registation Succesful'))))
                .then(() => dispatch(login(values.phone, values.password, values.registrationId)))
                .then(() => ownProps.history.push({ ...ownProps.history.location, pathname: profile.type.path }));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDisptachToProps
)(withRouter(RegistrationUserInfo));
