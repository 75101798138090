import { Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CreativesUploadDialog } from './CreativesUploadDialog';

const CreativesSection = () => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const images = useSelector(state => state.programmatic.creative_images)

    if (images.length == 0) {
        return null;
    }

    return <>
        <Typography variant="subtitle1" gutterBottom><b>{t('Creatives')}</b></Typography>
        <Link
            onClick={e => {
                e.preventDefault();
                setDialogOpen(true);
            }}
            variant='body2'
            color="secondary"
            style={{ cursor: 'pointer' }}>
            {images.length} creative(s) uploaded
        </Link>

        {dialogOpen
            && <CreativesUploadDialog
                title="Your Creatives"
                open={true}
                showApply={false}
                handleClose={() => setDialogOpen(false)}
            />}
    </>
};

export default CreativesSection;
