import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ModerationScoreIcon from './ModerationScoreIcon';
import UserTrustRow from './UserTrustRow'
import { forceApproveModeration } from '../../actions/approvals';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import {
    defaultApprovalMinModerationScore, defaultApprovalMinModerationScoreToShowDetails,
    compositionObjectClasses, compositionObjectClassTypes
} from '../../helpers/constants'
import { ImagePreviewDialog } from '../../components';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const scoreToPct = (score) => {
    return (score * 100).toFixed(0)
}

const getUniqueModerationData = (approvals) => {

    const uniqueItems = [];
    const uniqueKeys = []

    approvals.map(approval => {
        approval.object_moderation_data && approval.object_moderation_data.map(mod => {
            if (mod.moderation && uniqueKeys.indexOf(mod.moderation.id) === -1) {
                uniqueKeys.push(mod.moderation.id)
                uniqueItems.push(mod)
            }
        })
    })

    return uniqueItems
}

const getUniqueModerationProvenanceUsers = (approvals) => {

    const uniqueItems = [];
    const uniqueKeys = []

    approvals.map(approval => {
        approval.object_moderation_ownership && approval.object_moderation_ownership.map(user => {
            if (uniqueKeys.indexOf(user.id) === -1) {
                uniqueKeys.push(user.id)
                uniqueItems.push(user)
            }
        })
    })

    return uniqueItems
}

const getFormattedText = (textData) => {

    /**
     * To understand why this is here, please review T3059 which
     * discusses the reasoning behind this dumb thing
     * Why is it dumb? - It is dumb because of strings, images,
     * imagemagick, prices, making shell calls via PHP and other
     * unkown forces of which I will never understand
     */
    return textData.replace("\\\$", "$"); //eslint-disable-line

}

const ModerationScoreObjectPreview = ({ modObject }) => {

    if (compositionObjectClasses[modObject.class].type === compositionObjectClassTypes.text)
        return <ModerationScoreObjectPreviewText modObject={modObject} />

    if (compositionObjectClasses[modObject.class].type === compositionObjectClassTypes.image)
        return <ModerationScoreObjectPreviewImage modObject={modObject} />
}

const ModerationScoreObjectPreviewText = ({ modObject }) => {
    return getFormattedText(modObject.data.text)
}

const ModerationScoreObjectPreviewImage = ({ modObject }) => {

    const [previewPhotoUrl, setPreviewPhotoUrl] = useState(null)

    const { t } = useTranslation()

    return (
        <>
            <img src={modObject.data.src}
                style={{ width: "50px", cursor: "pointer" }}
                onClick={() => setPreviewPhotoUrl(modObject.data.src)}>
            </img>
            <ImagePreviewDialog
                title={t('Creative preview')}
                open={Boolean(previewPhotoUrl)}
                handleClose={() => setPreviewPhotoUrl(null)}
            >
                {previewPhotoUrl && <img src={previewPhotoUrl} alt="Creative preview" />}
            </ImagePreviewDialog>
        </>
    )
}

const ModerationObjectProbBox = ({ name, score }) => {

    const convertProbToPct = () => {
        return (score * 100).toFixed(0)
    }

    if (score <= 0.01) {
        return (
            <></>
        )
    }

    return (
        <Box><Chip label={name + ` Probability ` + convertProbToPct() + `%`} /></Box>
    )
}

const ModerationScoreMoreDetailsShowOffensive = ({ offensive }) => {
    const { t } = useTranslation();

    if (!offensive.boxes)
        return <></>

    const convertProbToPct = (score) => {
        return (score * 100).toFixed(0)
    }

    return (
        <Box>
            <b>{t('Offensive Image : ')}</b>
            {offensive.boxes.map((offense, idx) => {
                return (
                    <Box key={idx}><Chip label={offense.label + ` ` + convertProbToPct(offense.prob) + `%`} /></Box>
                )
            })}
        </Box>
    )
}

const ModerationScoreMoreDetailsShowProfanity = ({ profanity }) => {
    const { t } = useTranslation();

    return (
        <Box>
            {profanity.map(prof => {
                return (
                    <><b>{t('Profanity : ')}</b> <Chip label={prof.match} /></>
                )
            })}
        </Box>
    )
}

const ModerationScoreMoreDetailsRowSightEngine = ({ sightEngine }) => {
    const { t } = useTranslation();

    return (
        <Box>
            {sightEngine.status === "disabled" &&
                <Box><b>{t('SightEngine Data Not Available')}</b></Box>
            }

            {sightEngine.gore && sightEngine.gore.prob &&
                <ModerationObjectProbBox name="Gore" score={sightEngine.gore.prob} />
            }

            {sightEngine.drugs && sightEngine.drugs &&
                <ModerationObjectProbBox name="Drugs" score={sightEngine.drugs} />
            }

            {sightEngine.weapon && sightEngine.weapon &&
                <ModerationObjectProbBox name="Weapons" score={sightEngine.weapon} />
            }

            {sightEngine.alcohol && sightEngine.alcohol &&
                <ModerationObjectProbBox name="Alcohol" score={sightEngine.alcohol} />
            }

            {sightEngine.offensive && sightEngine.offensive.prob &&
                <ModerationScoreMoreDetailsShowOffensive offensive={sightEngine.offensive} />
            }

            {sightEngine.nudity && sightEngine.nudity.raw &&
                <ModerationObjectProbBox name="Raw Nudity" score={sightEngine.nudity.raw} />
            }

            {sightEngine.nudity && sightEngine.nudity.partial &&
                <ModerationObjectProbBox name="Partial Nudity" score={sightEngine.nudity.partial} />
            }

            {sightEngine.text && sightEngine.text.profanity &&
                <ModerationScoreMoreDetailsShowProfanity profanity={sightEngine.text.profanity} />
            }

            {sightEngine.profanity && sightEngine.profanity.matches && sightEngine.profanity.matches.length &&
                <ModerationScoreMoreDetailsShowProfanity profanity={sightEngine.profanity.matches} />
            }

        </Box>
    )

}

const ModerationScoreMoreDetailsRow = ({ modObject, forceApproveModeration }) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={8}>

                    {modObject && modObject.moderation_data && modObject.moderation_data.sightengine &&

                        <ModerationScoreMoreDetailsRowSightEngine sightEngine={modObject.moderation_data.sightengine} />

                    }

                    {modObject && modObject.moderation_data && modObject.moderation_data.non_alpha &&
                        <span>{t('Non-Alpha data (numbers, characters only)')}</span>
                    }

                </Grid>
                <Grid xs={4}>

                    {modObject.moderation_score < defaultApprovalMinModerationScore &&

                        <Button variant="outlined" onClick={() => forceApproveModeration(modObject.lcuid)}>{t('Approve')}</Button>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}

const ModerationScoreDetailRow = ({ modObject, forceApproveModeration }) => {

    const [showDetails, setShowDetails] = useState(false)
    const score = modObject.moderation.moderation_score
    const { t } = useTranslation();

    const toggleShowDetails = () => {
        setShowDetails(!showDetails)
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid xs={1}>
                    <Box maxWidth={30}>
                        <ModerationScoreIcon score={score} />
                    </Box>
                </Grid>
                <Grid xs={2}>
                    {scoreToPct(score)}%
                </Grid>
                <Grid xs={6}>
                    <ModerationScoreObjectPreview modObject={modObject} />
                </Grid>

                <Grid xs={3}>
                    {score <= defaultApprovalMinModerationScoreToShowDetails && <Button onClick={toggleShowDetails}>{t('Details')}</Button>}
                </Grid>

                {showDetails &&
                    <ModerationScoreMoreDetailsRow modObject={modObject.moderation} forceApproveModeration={forceApproveModeration} />
                }

            </Grid>

        </>

    )

}

const ModerationScoreDetails = ({ approvals, forceApproveModeration }) => {
    const { t } = useTranslation();

    if (!approvals)
        return (
            <Box p={3}>{t('No Moderation Data Available')}</Box>
        )

    const uniqueApprovals = getUniqueModerationData(approvals)

    if (!uniqueApprovals.length)
        return (
            <Box p={3}>{t('No Moderation Data Available')}</Box>
        )

    const uniqueProvenanceUsers = getUniqueModerationProvenanceUsers(approvals)

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper>
                        <Box pl={1} pb={4}>
                            <Typography variant="h6">{t('Users')}</Typography>
                        </Box>
                        <Box pl={3} pb={4}>
                            {uniqueProvenanceUsers.map((user) => (
                                <UserTrustRow key={user.id} user={user} />
                            ))}
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper>
                        <Box pl={1} pb={4}>
                            <Typography variant="h6">{t('Scores')}</Typography>
                        </Box>
                        <Box pl={3} pb={4}>
                            {uniqueApprovals.map((mod) => (
                                <ModerationScoreDetailRow
                                    key={mod.hash}
                                    modObject={mod}
                                    forceApproveModeration={forceApproveModeration}
                                />
                            ))}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </Box >
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        forceApproveModeration: lcuid => dispatch(forceApproveModeration(lcuid))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ModerationScoreDetails
    )
);
