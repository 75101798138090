import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    useTheme, useMediaQuery
} from '@material-ui/core';
import { Dialog, CircularProgressCentered } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import { selectedAgency } from '../../selectors/user';
import { useSelector } from 'react-redux';
import { LIGHTNING_DEVICE_LAYOUT_ADDED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';
import DeviceLayoutEditor from './DeviceLayoutEditor';
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const NewDeviceLayoutDialog = ({ open, handleClose }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const agency = useSelector(selectedAgency);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [deviceLayout, setDeviceLayout] = useState({})
    const [error, setError] = useState(null)

    const canCreateNewLayout = deviceLayout
        && deviceLayout.name
        && deviceLayout.data
        && deviceLayout.data.screens
        && deviceLayout.data.screens.length > 0;

    const createDeviceLayout = () => {

        setLoading(true)

        const deviceLayoutPayload = {
            ...deviceLayout,
            agency_id: agency.id
        }

        lucitApi.lightning.createDeviceLayout(deviceLayoutPayload)
            .then((response) => {
                dispatch({
                    type: LIGHTNING_DEVICE_LAYOUT_ADDED,
                    deviceLayout: response.lightning_device_layout
                })
                handleClose()
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}
            title={t('New Device Layout')}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>

                {t('Create a new device layout')}

                <DeviceLayoutEditor
                    onDeviceLayoutChange={setDeviceLayout}
                />

            </DialogContent>

            <DialogActions>
                {error
                    &&
                    <Alert severity="error">{
                        error.response?.data?.message || error.message}
                    </Alert>

                }
                {loading && <CircularProgressCentered size={24} />}
                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                {
                    <Button
                        disabled={!canCreateNewLayout || loading}
                        onClick={() => createDeviceLayout()}
                        color="primary"
                        variant="contained">
                        {t('Done')}
                    </Button>
                }
            </DialogActions>
        </Dialog >
    )

}

export default NewDeviceLayoutDialog;
