import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
    List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, makeStyles, Box
} from '@material-ui/core';
import { connect } from 'react-redux';
import { RoutePaths } from '../../helpers/constants';
import ExportsIcon from '../../components/icons/ExportIcon';
import { Fragment } from 'react';
import { exports } from '../../selectors/dashboard';
import { StatsDelayedChip, StatsUnavailableChip } from '../../components';
import CampaignStatusText from '../campaigns/CampaignStatusText';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
        }
    },
    nestedDivider: {
        marginLeft: theme.spacing(11),

        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(9),
        }
    },

    exportAvatar: {
        background: 'none'
    },
    exportAvatarIcon: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main
    },
    exportDelayed: {
        marginLeft: theme.spacing(1)
    },

    listItem: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5)
        }
    },

    listItemIcon: {
        justifyContent: 'flex-end'
    },
}));

const DashboardCampaigns = (props) => {
    const { exports, EmptyDataLabel } = props;

    const classes = useStyles();

    if (!exports.length) {
        return EmptyDataLabel || null;
    }

    const campaignHasIcon = (e) => {
        return campaignIconUrl(e)
    }

    const campaignIconUrl = (e) => {

        if (e.options && e.options.primary_image_public_url) {
            return e.options.primary_image_public_url
        }

        return null
    }

    return (
        <Box mb={2}>
            <List
                component='div'
                disablePadding
            >
                {exports.map(e => (
                    <Fragment key={e.id}>
                        <ListItem
                            disableGutters={true}
                            className={classes.nested}
                            button
                            component={RouterLink}
                            to={`${RoutePaths.exports}/${e.id}`}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    className={classes.exportAvatar}
                                    src={campaignIconUrl(e)}>
                                    {!campaignHasIcon(e)
                                        && <ExportsIcon
                                            className={classes.exportAvatarIcon}
                                        />}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography variant="body1">{e.name}</Typography>
                                <Typography variant="body2" color="textSecondary">

                                    <CampaignStatusText campaign={e} />
                                    <StatsDelayedChip
                                        exportObject={e}
                                        chipProps={{
                                            className: classes.exportDelayed
                                        }} />
                                    <StatsUnavailableChip
                                        exportObject={e}
                                        chipProps={{
                                            className: classes.exportDelayed
                                        }} />

                                </Typography>
                            </ListItemText>
                        </ListItem>

                    </Fragment>
                ))}
            </List >
        </Box>
    )
}

DashboardCampaigns.propTypes = {
    exports: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        exports: exports(state),
    };
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DashboardCampaigns
);
