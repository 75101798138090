import {
    SCREENS_ITEM_RESULT, DASHBOARD_LOCATIONS, DASHBOARD_LOCATIONS_REQUEST,
    SCREENS_SOFTWARE_RESULT, SCREENS_HARDWARE_RESULT, SCREENS_FACING_CARDINALS_RESULT, SCREENS_UPDATE_ITEM, SCREENS_PLAY_STATUSES,
    SCREENS_PLAY_STATUS, EntitySet, SCREENS_IMAGES_RESULT, SCREENS_IMAGES_NEW, SCREENS_IMAGES_DELETE, SCREENS_IMAGES_UPDATE,
    SCREENS_IMAGES_SET_AS_MAIN, SCREENS_STREET_VIEW_NEW, SCREENS_GET_STREET_VIEW, SCREENS_STREET_VIEW_STATUS, SCREENS_DELETE
} from "../helpers/actionTypes";
import { StreetViewStatus } from "../helpers/constants";
import { lucitApi } from "../services/lucitApi"
import { entityNextPage, entitySearch } from "./entities";
import { createStreetViewLocation, createStreetViewSnapshotLocation, getLocationById } from "./locations";

export function getScreens(filters, isNextPage) {
    return (dispatch) => {
        const search = () => lucitApi.billboards.get(filters);

        return isNextPage
            ? entityNextPage(EntitySet.digitalBoards, search, filters)(dispatch)
            : entitySearch(EntitySet.digitalBoards, search, filters)(dispatch);
    }
}

export function getScreen(id) {
    return (dispatch) => {
        return lucitApi.billboards.getById(id)
            .then(data => {
                dispatch({
                    type: SCREENS_ITEM_RESULT,
                    ...data
                })

                return data;
            });
    }
}

export function createScreen(screen) {
    return (dispatch, getState) => {
        const state = getState();

        return lucitApi.billboards.create({
            board_type: 'billboard_outdoor',
            network: 0,
            ...screen,
            agency_id: state.user.selectedAgencyId
        })
            .then((data) => {
                const screen = {
                    ...data.digital_board,
                    location: data.location,
                    digital_board_to_images: []
                };

                dispatch({
                    type: SCREENS_ITEM_RESULT,
                    ...screen
                })

                return screen;
            });
    }
}

export function updateScreen(screen) {
    return (dispatch) => {
        return lucitApi.billboards.update({
            avg_daily_impressions: 0,
            impressions_calc_method: 0,
            ...screen,
            ...screen.options
        })
            .then(screen => dispatch({
                type: SCREENS_UPDATE_ITEM,
                ...screen
            }));
    }
}

export function deleteScreen(screen) {
    return (dispatch) => {
        return lucitApi.objects.delete(screen.lcuid)
            .then(() => dispatch({
                type: SCREENS_DELETE,
                ...screen
            }));
    }
}

export function createImage(screen, file, fileName = "digital-board-image.png", coordinates) {
    return (dispatch) => {
        return lucitApi.uploads.uploadImage(file, fileName, [], {
            model_uri_code: "digital-board",
            model_id: screen.id
        })
            .then(image => {
                return lucitApi.billboards.createImage(screen.id, image.id, fileName, coordinates, 'normal')
                    .then(data => ({ ...data, image }))
            })
            .then(data => dispatch({
                type: SCREENS_IMAGES_NEW,
                screenId: screen.id,
                data
            }));
    }
}

export function updateImage(screen, screenImage, coordinates, style) {
    return (dispatch) => {
        return lucitApi.billboards.updateImage(screen.id, screenImage, coordinates, style)
            .then(data => dispatch({
                type: SCREENS_IMAGES_UPDATE,
                screenId: screen.id,
                data
            }));
    }
}

export function deleteImage(screen, image) {
    return (dispatch) => {
        return lucitApi.billboards.deleteImage(screen.id, image.id)
            .then(() => dispatch({
                type: SCREENS_IMAGES_DELETE,
                screen,
                image
            }));
    }
}

export function setAsMainImage(screen, image) {
    return (dispatch) => {
        return lucitApi.billboards.setAsMainImage(screen.id, image.id)
            .then(() => dispatch({
                type: SCREENS_IMAGES_SET_AS_MAIN,
                screen,
                image
            }));
    }
}

export function getStreetView(boardId, streetViewId) {
    return (dispatch) => {
        return dispatch(getLocationById(streetViewId))
            .then(data => dispatch({
                type: SCREENS_GET_STREET_VIEW,
                id: boardId,
                data
            }));
    }
}

export function setStreetViewStatus(boardId, status) {
    return (dispatch) => {
        return lucitApi.billboards.setStreetViewStatus(boardId, status)
            .then(data => {
                dispatch({
                    type: SCREENS_STREET_VIEW_STATUS,
                    id: boardId,
                    status
                })

                return data;
            });
    }
}

export function createStreetView(location, boardId, position, pov, coordinates, backupImageId) {
    return (dispatch) => {
        return dispatch(createStreetViewLocation(location, position, pov.heading, pov.pitch, pov.zoom, boardId, backupImageId))
            .then(location => lucitApi.billboards.setStreetViewLocation(boardId, location.id)
                .then(() => location))
            .then(location => lucitApi.billboards.createImage(boardId, null, 'Street View', coordinates, 'street_view_overlay')
                .then(() => location))
            .then(location => {
                dispatch({
                    type: SCREENS_STREET_VIEW_NEW,
                    id: boardId,
                    streetViewId: location.id
                })
            });
    }
}

export function createStreetViewSnapshot(location, boardId, position, pov) {
    return (dispatch) => {
        return dispatch(createStreetViewSnapshotLocation(location, position, pov.heading, pov.pitch, pov.zoom, boardId))
    }
}

export function updateStreetView(location, boardId, position, pov, screenImage, coordinates, backupImageId) {
    return (dispatch) => {
        return dispatch(createStreetViewLocation(location, position, pov.heading, pov.pitch, pov.zoom, boardId, backupImageId))
            .then(location => lucitApi.billboards.setStreetViewLocation(boardId, location.id)
                .then(() => location))
            .then(location => {
                dispatch({
                    type: SCREENS_STREET_VIEW_NEW,
                    id: boardId,
                    streetViewId: location.id,
                    status: StreetViewStatus.active
                })
            })
            .then(location => lucitApi.billboards.updateImage(boardId, screenImage, coordinates, 'street_view_overlay')
                .then(data => {
                    dispatch({
                        type: SCREENS_IMAGES_UPDATE,
                        screenId: boardId,
                        data
                    })
                    return location;
                }))
            ;
    }
}

export function getImages(id) {
    return (dispatch) => {
        return lucitApi.billboards.getImages(id)
            .then(data => dispatch({
                type: SCREENS_IMAGES_RESULT,
                id,
                data
            }));
    }
}

export function getPlayStatuses(agencyId) {
    return (dispatch) => {
        return lucitApi.billboards.getPlayStatuses(agencyId)
            .then(data => dispatch({
                type: SCREENS_PLAY_STATUSES,
                data
            }));
    }
}

export function getPlayStatus(id) {
    return (dispatch) => {
        return lucitApi.billboards.getPlayStatus(id)
            .then(data => dispatch({
                type: SCREENS_PLAY_STATUS,
                data
            }));
    }
}

export function getMap(filters = {}) {
    return (dispatch) => {
        dispatch({ type: DASHBOARD_LOCATIONS_REQUEST })

        return lucitApi.billboards.getMap({
            accountIds: filters.accountIds,
            agencyIds: filters.agencyIds,
            street_view_only: filters.streetViewOnly,
        })
            .then(data => {
                dispatch({
                    type: DASHBOARD_LOCATIONS,
                    data
                });

                return data.locations;
            })
    }
}

export function getScreensSoftware() {
    return (dispatch) => {
        return lucitApi.billboards.getSofwareProviders()
            .then(data => dispatch({
                type: SCREENS_SOFTWARE_RESULT,
                data
            }));
    }
}

export function getScreensHardware() {
    return (dispatch) => {
        return lucitApi.billboards.getHardwareProviders()
            .then(data => dispatch({
                type: SCREENS_HARDWARE_RESULT,
                data
            }));
    }
}

export function getFacingCardinals() {
    return (dispatch) => {
        return lucitApi.billboards.getFacingCardinals()
            .then(data => dispatch({
                type: SCREENS_FACING_CARDINALS_RESULT,
                data
            }));
    }
}
