import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, List } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { exports, searched, hasNextPage, nextPageLoading, nextPage, exportsScrollY, hasScrollY } from '../../selectors/exports';
import { loadPage, exportsSaveScroll } from '../../actions/exports';
import { useInfiniteScroll } from '../../helpers/hooks/useInfiniteScroll';
import ExportListItem from './ExportListItem';
import { Global } from '../../helpers/constants';
import { useContext } from 'react';
import { ScrollContext } from '../../contexts';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

function ExportsList(props) {
    const { nextPage, isNextPageLoading, hasNextPage, loadNextPage, searched, items } = props;
    const { hasScrollY, scrollY, saveScroll } = props;
    const scrollContext = useContext(ScrollContext).current;

    useInfiniteScroll({
        isLoading: isNextPageLoading,
        hasMoreItems: hasNextPage,
        loadMoreItems: () => {
            loadNextPage(nextPage);
        },

        threshold: Global.nextPageLoadThresold,
        getRef: () => scrollContext.ref
    })

    useEffect(() => {
        const scrollRef = scrollContext.ref;

        if (hasScrollY) {
            scrollRef.scrollTo({
                top: scrollY,
                // behavior: 'smooth'
            })
        }

        return () => saveScroll(scrollRef.scrollTop);
    }, [scrollContext, hasScrollY, scrollY, saveScroll])

    return (
        <>
            <List
                disablePadding>
                {items.map((item) => <ExportListItem key={item.id} item={item} />)}
            </List>

            {isNextPageLoading
                && <Box m={1} mt={2} display="flex" justifyContent="center">
                    <CircularProgressCentered size={40} />
                </Box>}

            {!items.length
                && searched
                && <Box mt={2}>
                    <LCTypography variant="subtitle1">No Campaigns</LCTypography>
                </Box>}
        </>
    );
}

const mapStateToProps = state => {
    return {
        items: exports(state),
        nextPage: nextPage(state),
        hasNextPage: hasNextPage(state),
        isNextPageLoading: nextPageLoading(state),
        searched: searched(state),

        scrollY: exportsScrollY(state),
        hasScrollY: hasScrollY(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadNextPage: (page) => dispatch(loadPage(page)),
        saveScroll: (scrollY) => dispatch(exportsSaveScroll(scrollY))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportsList
);
