import React from 'react';
import ReactInfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import LCTypography from '../../components/material/LCTypography';
import { Box } from '@material-ui/core';
import CircularProgressCentered from '../material/CircularProgressCentered';

function InfiniteScroll(props) {
    const { page, lastPage, perPage, loading, dataLength, scrollableTarget, ...rest } = props;

    const load = () => props.load(page, perPage);
    const loadNext = () => props.loadNext(page + 1, perPage);

    if (loading) {
        return <CircularProgressCentered size={40} />;
    }

    if (scrollableTarget == null) {
        return null;
    }

    return <>
        <ReactInfiniteScroll
            dataLength={dataLength}
            next={loadNext}
            hasMore={page < lastPage}
            scrollableTarget={scrollableTarget}

            loader={<LCTypography>Loading...</LCTypography>}
            endMessage={<LCTypography align='center'>
                <b>Yay! You have seen it all</b>
            </LCTypography>}

            refreshFunction={load}
            pullDownToRefresh={false}
            pullDownToRefreshThreshold={75}
            pullDownToRefreshContent={
                <Box mb={2}>
                    <LCTypography variant='h6' align='center'>
                        &#8595; Pull down to refresh
                    </LCTypography>
                </Box>
            }
            releaseToRefreshContent={
                <LCTypography variant='h6' align='center'>
                    &#8593; Release to refresh
                </LCTypography>
            }

            {...rest}
        >
            {props.children}
        </ReactInfiniteScroll>
    </>
}

InfiniteScroll.propTypes = {
    loading: PropTypes.bool,
    page: PropTypes.number,
    lastPage: PropTypes.number,
    perPage: PropTypes.number,
    dataLength: PropTypes.number,

    load: PropTypes.func,
    loadNext: PropTypes.func,
    scrollableTarget: PropTypes.any,
}

export default InfiniteScroll;