import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { IonButton } from '@ionic/react';
import { deleteItem } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { ConfirmationDialog } from '../../../components';
import { history } from '../../../helpers/history';
import { inventoryItemClass } from '../../../selectors/inventory';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const DeleteButton = (props) => {
    const { item, deleteItem } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    if (!inventoryItemClass(item).allowUserDelete)
        return null

    return (
        <>
            <IonButton
                color="inherit"
                size="small"
                variant="contained"
                className={classes.removeButton}
                startIcon={<Delete />}
                onClick={() => setIsConfirmOpen(true)}>
                Delete
            </IonButton>
            <ConfirmationDialog
                title={item.title}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    deleteItem()
                        .finally(() => setIsConfirmOpen(false))
                }}>
                Are you sure you want to remove this item?
            </ConfirmationDialog>
        </>
    )
}

DeleteButton.propTypes = {
    item: PropTypes.object.isRequired,
    redirectPathname: PropTypes.string
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteItem: () => {
            return dispatch(deleteItem(props.item))
                .then(() => {
                    if (props.redirectPathname) {
                        history.push({ pathname: props.redirectPathname });
                    }
                    dispatch(showSuccess(`"${props.item.title}" was successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DeleteButton
);
