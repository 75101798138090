import React, { useCallback, useState } from 'react';
import { Button, DialogActions, DialogContent, Divider, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ButtonLoader, Dialog } from '../../components';
import { lucitApi } from '../../services/lucitApi';
import { minOrEqual, number } from '../../helpers/validators';
import { Build } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { LIGHTNING_DEVICE_UPDATED } from '../../helpers/actionTypes';
import { showSuccess } from '../../actions/snackbar';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 3),
        }
    }
})

export const DeviceSettingsDialog = ({ device, open, handleClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [values, setValues] = useState(device.options?.device_settings ?? {});
    const [errors, setErrors] = useState({});
    const setValue = (key, value) => setValues(x => ({ ...x, [key]: value }));

    const updateSettings = useCallback(() => {
        const promises = [];

        const hasChanges = Object.keys(values)
            .some(key => device.options[key] != values[key]);

        if (hasChanges) {
            promises.push(
                lucitApi.lightning.setSettings(device.lcuid, values)
                    .then(device => dispatch({ type: LIGHTNING_DEVICE_UPDATED, device }))
            )
        }

        setSubmitting(true);
        return Promise.all(promises)
            .then(() => {
                dispatch(showSuccess(t('Device Settigs were successfully updated')));
                handleClose();
            })
            .finally(() => setSubmitting(false));
    }, [device, values])

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            title={t('Settings: ') + device.name}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>

                <Grid container spacing={2}>
                    {deviceSettings
                        .filter(d => !d.isAdvanced)
                        .map(f => <Field key={f.key}
                            field={f}
                            value={values[f.key]}
                            setValue={x => setValue(f.key, x)}

                            error={errors[f.key]}
                            setError={e => setErrors(x => ({ ...x, [f.key]: e }))}
                        />)}

                    <Grid item xs={12}>
                        <Button
                            startIcon={<Build />}
                            style={{ marginTop: 8 }}
                            onClick={() => setShowAdvanced(!showAdvanced)}
                            variant='outlined'
                            color="primary">
                            {showAdvanced
                                ? t('Hide Advanced')
                                : t('Show Advanced')}
                        </Button>
                    </Grid>
                    {showAdvanced
                        && <>
                            <Grid item xs={12}>
                                <Divider />
                                <Typography style={{ marginTop: 16 }} variant="h6">{t('Advanced Settings')}</Typography>
                            </Grid>
                            {deviceSettings
                                .filter(d => d.isAdvanced)
                                .map(f => <Field key={f.key}
                                    field={f}
                                    value={values[f.key]}
                                    setValue={x => setValue(f.key, x)}
                                    error={errors[f.key]}
                                    setError={e => setErrors(x => ({ ...x, [f.key]: e }))}
                                />)}
                        </>}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Close')}
                </Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={updateSettings}
                    color="primary"
                    variant="contained">
                    {t('Save')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const Field = ({ field, value, setValue, error, setError }) => {
    const { t } = useTranslation();

    return <Grid item xs={12} md={6}>
        <Typography><b>{t(field.title)}</b></Typography>
        <Typography gutterBottom variant="body2">{t(field.description)}</Typography>
        <TextField
            variant='outlined'
            size="small"
            defaultValue={value ?? field.defaultValue}
            value={value}
            onChange={e => {
                const newValue = e.target.value;

                if (field.validators) {
                    setError(field.validators.map(v => v(newValue)).find(e => !!e));
                }

                setValue(newValue);
            }}

            error={error}
            helperText={error}
        />
    </Grid>;
}

const deviceSettings = [
    {
        key: 'FetchPlaylistEveryMs',
        title: 'Fetch Playlist Interval (milliseconds)',
        description: 'How often should the device retrieve a new playlist',
        validators: [number, minOrEqual(10 * 1000)],  // At least 10 seconds
        defaultValue: 4 * 60 * 1000         // 4 minutes
    },
    {
        key: 'FetchPlaylistDurationSeconds',
        title: 'Fetch Playlist Duration (seconds)',
        description: 'How long of a playlist should this device generate on every request',
        validators: [number, minOrEqual(10)],         // At least 10 seconds
        defaultValue: 5 * 60                // 5 minutes
    },
    {
        key: 'SlotLengthMs',
        title: 'Slot Length (milliseconds)',
        description: 'How many milliseconds should each ad display on the screen before moving to the next one',
        validators: [number, minOrEqual(5 * 1000)],   // At least 5 seconds
        defaultValue: 10 * 1000             // 10 seconds
    },
    {
        key: 'ShowTimeoutErrorIfProcessTooLongMs',
        title: 'Show Timeout Error If Process Too Long (milliseconds)',
        description: 'Time limit before showing a timeout error for a long process',
        validators: [number, minOrEqual(0)],
        defaultValue: 5 * 60 * 1000,            // 5 minutes
        isAdvanced: true
    },
    {
        key: 'CheckForDeviceRegistrationEveryMs',
        title: 'Check For Device Registration Interval (milliseconds)',
        description: 'Interval at which the device checks for registration status',
        validators: [number, minOrEqual(1 * 1000)],       // At least 1 second
        defaultValue: 5 * 1000,                 // 5 seconds
        isAdvanced: true
    },
    {
        key: 'SendHeartbeatEveryMs',
        title: 'Send Heartbeat Interval (milliseconds)',
        description: 'Interval at which the device sends heartbeat signals',
        validators: [number, minOrEqual(5 * 1000)],      // At least 5 seconds
        defaultValue: 30 * 1000,                // 30 seconds
        isAdvanced: true
    },
    {
        key: 'FetchPlaylistEveryMs',
        title: 'Fetch Playlist Interval (milliseconds)',
        description: 'Interval at which the device fetches the playlist',
        validators: [number, minOrEqual(10 * 1000)],      // At least 10 seconds
        defaultValue: 4 * 60 * 1000,                // 4 minutes
        isAdvanced: true
    },
    {
        key: 'FetchPlaylistWhenEmptyOrError',
        title: 'Fetch Playlist When Empty Or Error Interval (milliseconds)',
        description: 'Interval at which the device fetches the playlist when it is empty or an error occurs',
        validators: [number, minOrEqual(10 * 1000)],      // At least 10 seconds
        defaultValue: 20 * 1000,                // 20 seconds
        isAdvanced: true
    },
    {
        key: 'ProcessPlayReportsEveryMs',
        title: 'Process Play Reports Interval (milliseconds)',
        description: 'Interval at which the device processes play reports',
        validators: [number, minOrEqual(100)],            // At least 100 milliseconds
        defaultValue: 500,                      // 500 milliseconds
        isAdvanced: true
    },
    {
        key: 'RunGarbageCollectorEveryMs',
        title: 'Run Garbage Collector Interval (milliseconds)',
        description: 'Interval at which the device runs the garbage collector',
        validators: [number, minOrEqual(1 * 60 * 1000)],  // At least 1 minute
        defaultValue: 60 * 1000,                // 1 minute
        isAdvanced: true
    },
    {
        key: 'UpdateIonDeviceInfoEveryMs',
        title: 'Update Ion Device Info Interval (milliseconds)',
        description: 'Interval at which the device updates Ion device information',
        validators: [number, minOrEqual(1 * 60 * 1000)],  // At least 1 minute
        defaultValue: 5 * 60 * 1000,            // 5 minutes
        isAdvanced: true
    },
    {
        key: 'CacheImagesForMs',
        title: 'Cache Images Duration (milliseconds)',
        description: 'Duration for which images are cached',
        validators: [number, minOrEqual(0)],
        defaultValue: 12 * 60 * 60 * 1000,           // 12 hours
        isAdvanced: true
    },
    {
        key: 'MaxImagesInCache',
        title: 'Maximum Images In Cache',
        description: 'Maximum number of images that can be cached',
        validators: [number, minOrEqual(0)],                  // At least 1 image
        defaultValue: 100,                          // 100 images
        isAdvanced: true
    },
    {
        key: 'NumberOfFailedPlayReportsBeforeTestingApi',
        title: 'Number of Failed Play Reports Before Testing API',
        description: 'Number of failed play reports before the device tests the API',
        validators: [number, minOrEqual(1)],                 // At least 1 failure
        defaultValue: 5,                           // 5 failures
        isAdvanced: true
    },
    {
        key: 'PersistStateEveryMs',
        title: 'Persist State Interval (milliseconds)',
        description: 'Interval at which the device persists its state',
        validators: [number, minOrEqual(1 * 60 * 1000)],     // At least 1 minute
        defaultValue: 1 * 60 * 1000,               // 1 minute
        isAdvanced: true
    }
];
