import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { inventoryItemClass } from '../../selectors/inventory';
import TemplatePhotoStreamDialog from './TemplatePhotoStreamDialog';

function EditTemplateItemValuesButton({ item }) {

    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const { t } = useTranslation();

    if (!inventoryItemClass(item).canAssignTempalates)
        return null

    if (!item.drive_template)
        return null

    return <>

        <IonButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setIsNewDialogOpen(true)}
        >
            {t('Edit Values')}
        </IonButton>

        {isNewDialogOpen
            && <TemplatePhotoStreamDialog
                open={isNewDialogOpen}
                selectedTemplateLcuid={item.drive_template.lcuid}
                item={item}
                handleClose={() => setIsNewDialogOpen(false)}
            />}
    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    EditTemplateItemValuesButton
);
