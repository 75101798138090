import { Box, Link } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { EditorJS, ParseStatusBar, invalidJSCodeError } from '../../containers/designer/CodeEditor';
import { getDesignerFunctions } from '../../selectors/designer'

const TabContainer = ({ children }) => {

    return (
        <Box p={3}>
            {children}
        </Box>
    );
}

const CustomFieldCodeEditor = ({
    customFieldCode, setCustomFieldCode, customFieldFields, setCustomFieldFields, setError, showHelp = true
}) => {

    const [codeError, setCodeError] = useState(null);

    const { t } = useTranslation();

    const fieldList = useSelector(state => state.designer.fields);

    const customDesignerFunctions = useSelector(
        state => getDesignerFunctions(state)
    );

    const isApprovedDesignerFunction = (inputString) => {
        return customDesignerFunctions.some(designerFunction => inputString.startsWith(designerFunction.name + "("));
    }

    const endsInCloseParenOrSemiColon = (inputString) => {
        return inputString.trim().endsWith(")") || inputString.trim().endsWith(");");
    }

    const invalidCustomFieldCodeError = () => {

        if (!isApprovedDesignerFunction(customFieldCode)) {
            return t("Syntax Error : Invalid function. Please use only approved functions");
        }

        if (!endsInCloseParenOrSemiColon(customFieldCode)) {
            return t("Syntax Error : Missing closing parenthesis");
        }

        const countOfDoubleLeftCurlyBraces = (customFieldCode.match(/{{/g) || []).length;
        const countOfDoubleRightCurlyBraces = (customFieldCode.match(/}}/g) || []).length;

        if (countOfDoubleLeftCurlyBraces !== countOfDoubleRightCurlyBraces) {
            return t("Syntax Error : Mismatched curly braces.  Please ensure each pair of {{ has a matching }}");
        }

        //Remove the curly braces
        const prepareCodeForTest = customFieldCode && customFieldCode.replace(/{{/g, "").replace(/}}/g, "");

        return invalidJSCodeError(prepareCodeForTest);
    }

    const [selectedTab, setSelectedTab] = useState("js");

    useEffect(() => {
        setCodeError(invalidCustomFieldCodeError());
    }, [customFieldCode]);

    useEffect(() => {
        setError && setError(codeError);
    }, [codeError])

    const [customFieldFieldsString, setCustomFieldFieldsString] = useState(JSON.stringify(customFieldFields, null, 2));

    const [customFieldFieldsCodeError, setCustomFieldFieldsCodeError] = useState(null);

    useEffect(() => {
        try {
            JSON.parse(customFieldFieldsString);
            setCustomFieldFieldsCodeError(null);
            setCustomFieldFields(JSON.parse(customFieldFieldsString));
        } catch (e) {
            setCustomFieldFieldsCodeError(t("Syntax Error : Invalid JSON"));
        }
    }, [customFieldFieldsString]);

    return (
        <Box style={{ minHeight: "400px" }}>

            <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    label={t("Field Code")}
                    value={"js"}
                />
                <Tab
                    label={t("Field Map")}
                    value={"fieldmap"}
                />
                {showHelp && <Tab
                    label={t("Help")}
                    value={"help"}
                />
                }
            </Tabs>

            {selectedTab === "js" &&
                <TabContainer>
                    <EditorJS
                        js={customFieldCode}
                        styles={{ height: 200, border: '1px solid #ccc', borderRadius: "4px" }}
                        onChange={(js) => {
                            setCustomFieldCode(js);
                        }}
                    />

                    {customFieldCode &&
                        <div>
                            <ParseStatusBar
                                message={codeError}
                            />
                        </div>
                    }

                </TabContainer>
            }

            {selectedTab === "fieldmap" && <TabContainer>

                <EditorJS
                    js={customFieldFieldsString}
                    styles={{ height: 200, border: '1px solid #ccc', borderRadius: "4px" }}
                    onChange={(js) => {
                        setCustomFieldFieldsString(js);
                    }}
                />

                {customFieldFieldsString && customFieldFields?.length > 0 &&
                    <div>
                        <ParseStatusBar
                            message={customFieldFieldsCodeError}
                        />
                    </div>
                }

            </TabContainer>
            }

            {selectedTab === "help" && <TabContainer>
                <div style={{ paddingLeft: "5px", maxHeight: 400, overflowY: 'auto', marginTop: "20px" }}>

                    <Box mb={4}>

                        <LCTypography variant="body1"><strong>Function List</strong></LCTypography>
                        <LCTypography variant="body2">
                            These functions are available for use in the code editor.
                            Learn more at <Link
                                href="https://www.lucit.cc/post/templates-custom-field-function-reference"
                                color="secondary"
                                target="_blank"
                            >Custom Field Function Reference
                            </Link>

                        </LCTypography>

                        <Box mt={2} mb={2}>
                            {customDesignerFunctions.map((designerFunction, index) => {
                                return (
                                    <div
                                        key={index}
                                        title={designerFunction.description}
                                        style={{ display: "block", padding: "1px 15px 5px 0px", fontSize: "0.8em" }}
                                    >
                                        <code>{designerFunction.name}{`()`}</code>
                                    </div>
                                )
                            })}
                        </Box>
                    </Box>

                    <Box mb={4}>
                        <LCTypography variant="body1"><strong>Available Macro List</strong></LCTypography>
                        <LCTypography variant="body2">
                            These macros are available for use in the code editor
                            and mirror the list of data elements in the template designer
                            Learn more at <Link
                                href="https://www.lucit.cc/post/templates-custom-field-macros-for-dynamic-fields"
                                color="secondary"
                                target="_blank"
                            >Macros for Dynamic Fields</Link>
                        </LCTypography>

                        <Box mt={2} mb={2}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    {fieldList.filter(f => f.object !== "custom_field").map((field, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: "12%" }}>
                                                    <span style={{ fontSize: "0.8em" }}>
                                                        {field.name}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div style={{
                                                        display: "block", padding: "0px 15px 0px 0px", fontSize: "0.8em"
                                                    }}>
                                                        <code>{`{`}{field.macroCode}{`}`}</code>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </div>
            </TabContainer>
            }
        </Box>
    )
}

export default CustomFieldCodeEditor;
