import { apiHttp } from "../helpers/api";
import { LocationClasses, LuCoreModelClasses } from "../helpers/constants";

export default class LucitApiLocations {

    getAll(data = {}) {
        return apiHttp.get(`/locations`, {
            params: {
                special: data.special
            }
        })
            .then(response => response.data.locations);

    }

    getById(id, isAuthenticated) {
        return apiHttp.get(isAuthenticated ? `/locations/${id}` : `/public/locations/${id}`)
            .then(response => response.data);
    }

    createStreetViewSnapshot(location, position, heading, pitch, zoom, boardId) {
        return this.createStreetView(location, position, heading, pitch, zoom, boardId, {
            location_class: LocationClasses.streetViewSnapshot.class,
        });
    }

    createStreetView(location, position, heading, pitch, zoom, boardId, options) {
        return this.createLocation(location, position, {
            parent_id: boardId,
            parent_type: LuCoreModelClasses.digitalBoard.class,
            location_class: LocationClasses.streetView.class,
            heading,
            pitch,
            zoom,

            ...options
        });
    }

    createLocation(location, position, options) {
        const postData = {
            name: location.name,
            city: location.city,
            region: location.region,
            country: location.country,
            latitude: position.lat,
            longitude: position.lng,
            google_street_view_pano_id: position.panoId,

            ...options,
        }
        return apiHttp.post(`/locations`, postData)
            .then(response => response.data.location);
    }

    setCoordinates(location) {
        return apiHttp.put(`/locations/${location.lcuid}/set-coords`, {
            latitude: location.latitude,
            longitude: location.longitude
        })
            .then(response => response.data.location);
    }
}
