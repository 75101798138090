import { Button, FormHelperText, Grid } from '@material-ui/core';
import React from 'react';

const SampleCustomJsonDropDown = ({ field, onChange, value }) => {

    return <>
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <FormHelperText>{field.description}</FormHelperText>
                <span>Current Value is <code>{JSON.stringify(value)}</code></span>
            </Grid>
            <Grid xs={6} item>
                <Button
                    onClick={
                        () => {
                            onChange({
                                "key1": "value1",
                                "key2": "value2",
                                "key_extra": {
                                    "key_extra1": "value_extra1",
                                    "key_extra2": "value_extra2",
                                },
                            })
                        }
                    }
                >
                    CLick this to make value some json object
                </Button>
            </Grid>
            <Grid xs={6} item>
                <Button
                    onClick={
                        () => {
                            onChange({
                                "key1": "Newvalue1",
                                "key2": "Newvalue2",
                                "key_extra": {
                                    "key_extra1": "Newvalue_extra1",
                                    "key_extra2": "Newvalue_extra2",
                                },
                            })
                        }
                    }
                >
                    CLick this to make value some other json object
                </Button>

            </Grid>

        </Grid>
    </>
}

export default SampleCustomJsonDropDown;
