import { IonCard, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateApplication } from '../../actions/applications';
import { Tooltip } from '../../components/material';
import { ApplicationStatus } from '../../helpers/constants';
import { sortString } from '../../helpers/sorters';
import { lucitApi } from '../../services/lucitApi';

const ApplicationPermissions = ({ application, setPermission }) => {
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        setLoading(true);
        lucitApi.applications.getPermissions()
            .then(data => setPermissions(data))
            .finally(() => setLoading(false));
    }, []);

    const onUpdate = useCallback((e, permission) => {
        setUpdating(true);
        setPermission(application, permission, e.target.checked)
            .finally(() => setUpdating(false))
    }, [application]);

    return <>
        <IonCard style={{ color: 'unset' }}>
            <Box p={2}>
                <Typography variant="h6">Account Permissions</Typography>

                {loading
                    && <CircularProgress style={{ padding: 8 }} size={40} />}
                {!loading
                    && <PermissionsList
                        application={application}
                        disabled={updating}
                        onUpdate={onUpdate}
                        permissions={permissions.filter(x => x.model === 'account')} />}

                <Box p={2} />
                <Typography variant="h6">Operator Permissions</Typography>
                {loading
                    && <CircularProgress style={{ padding: 8 }} size={40} />}
                {!loading
                    && <PermissionsList
                        application={application}
                        disabled={updating}
                        onUpdate={onUpdate}
                        permissions={permissions.filter(x => x.model === 'agency')} />}
            </Box>
        </IonCard>
    </>
}

const PermissionsList = ({ application, permissions, disabled, onUpdate }) => {
    const { t } = useTranslation();

    return <Grid container>
        {permissions
            .sort((a, b) => sortString(a.policy, b.policy))
            .map(c =>
                <Grid key={c.fully_qualified_policy} item xs={12} md={6}>
                    <Tooltip
                        key={c.fully_qualified_policy}
                        hidden={application.status === ApplicationStatus.draft.id}
                        title={t("Permissions can be changed only when Application is in draft status.")}>
                        <IonItem>
                            <IonLabel class="ion-text-wrap">
                                <h2>{c.policy}</h2>
                                <p>{c.explanation}</p>
                            </IonLabel>
                            <IonToggle
                                disabled={disabled || application.status !== ApplicationStatus.draft.id}
                                checked={application.permissions.allowed
                                    && application.permissions.allowed.includes(c.fully_qualified_policy)}
                                onClick={e => onUpdate(e, c)}
                                slot="end"></IonToggle>
                        </IonItem>
                    </Tooltip>
                </Grid>
            )}
    </Grid>;
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPermission: (application, permission, enable) => {
            if (enable) {
                return dispatch(updateApplication({
                    ...application,
                    permissions: [...application.permissions.allowed || [], permission.fully_qualified_policy].join(',')
                }));
            }

            return dispatch(updateApplication({
                ...application,
                permissions: application.permissions.allowed.filter(p => p !== permission.fully_qualified_policy).join(',')
            }));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ApplicationPermissions
);
