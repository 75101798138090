import React, { useState } from 'react';
import clx from 'classnames';
import { List, ListItem, ListItemAvatar, ListItemText, useTheme, Collapse, makeStyles, Tooltip, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { isAccountParentForAnother, selectedProfile } from '../../selectors/user';
import { connect } from 'react-redux';
import { Global, ProfileType } from '../../helpers/constants';
import AccountAvatar from './AccountAvatar';

const useStyles = makeStyles(() => ({
    selected: {
        '& .MuiTypography-root': {
            fontWeight: 'bold',
        }
    },
    expandAction: {
        cursor: 'pointer',
        display: 'flex',
        padding: 0,
        right: 0
    },
}));

const AccountListItems = (props) => {
    const { accounts, ...rest } = props;
    return accounts
        .map(account => (<AccountListItem
            key={account.id}
            account={account}
            {...rest}
        />))
}

const AccountListItem = (props) => {
    const { account, ...rest } = props;
    const { selectedProfile, handleChange,
        level = 0, maxLevel = 10, autoFocusSelected,
        Buttons, ButtonProps, arrowPosition = 'right', ListItemProps } = rest;

    const classes = useStyles();
    const theme = useTheme();

    const selectedAccount = selectedProfile.type === ProfileType.account
        ? selectedProfile
        : null;

    const isSelected = selectedAccount && selectedAccount.id === account.id;

    const defaultOpen = account.children.length < Global.accountMaxLevelHierarchy
        || isAccountParentForAnother(account, selectedAccount);

    const [open, setOpen] = useState(defaultOpen);
    const toggleOpen = () => setOpen(!open);

    const hasChildAccounts = account.children.length > 0 && level < maxLevel;

    return (<>
        <ListItem
            selected={isSelected}
            autoFocus={autoFocusSelected && isSelected}
            className={clx({ [classes.selected]: isSelected })}
            style={{ paddingLeft: theme.spacing((level + 1) * 2) }}
            button
            {...ListItemProps}
            onClick={() => handleChange(account, toggleOpen)}>
            {arrowPosition == 'left'
                ? <>
                    {hasChildAccounts
                        && <Box
                            display="flex"
                            ml={-1}
                            mr={1}
                            onClick={e => { e.stopPropagation(); setOpen(!open); }}>
                            {open
                                ? <KeyboardArrowDown />
                                : <KeyboardArrowRight />}
                        </Box>}
                    <ListItemAvatar style={{ marginLeft: hasChildAccounts ? 0 : 24 }}>
                        <AccountAvatar account={account} />
                    </ListItemAvatar>
                </>
                : <ListItemAvatar >
                    <AccountAvatar account={account} />
                </ListItemAvatar>}

            <Tooltip title={account.name}>
                <ListItemText primary={account.name} primaryTypographyProps={{ noWrap: true }} />
            </Tooltip>

            {Buttons
                && <Buttons account={account} {...ButtonProps} />}

            {hasChildAccounts
                && arrowPosition == 'right'
                && <span
                    onClick={e => { e.stopPropagation(); setOpen(!open); }}
                    className={classes.expandAction}>
                    {open
                        ? <ExpandLess />
                        : <ExpandMore />}
                </span>}
        </ListItem>

        {account.children.length > 0
            && level < maxLevel
            && <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <AccountListItems
                        {...rest}
                        accounts={account.children}
                        level={level + 1}
                    />
                </List>
            </Collapse>}
    </>);
}

const mapStateToProps = state => {
    return {
        selectedProfile: selectedProfile(state),
    };
}

export default connect(
    mapStateToProps
)(AccountListItems);
