import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { PageTitle } from '../components';
import { useEffect } from 'react';
import { withConditionalLayout } from '../layouts/ConditionalLayout';
import ApplicationList from '../containers/applications/ApplicationList';
import { getAllApplications } from '../actions/applications';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';

function PublicAppsPage({ getAllApplications }) {

    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getAllApplications({
            params: {
                per_page: 1000
            }
        })
            .then((applications) => setApps(applications))
            .finally(() => setLoading(false));
    }, []);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Paper component={Box} p={0} pt={3}>
            <PageTitle title={`Lucit Applications`} />

            <Box>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            Lucit Applications
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <LCTypography variant="body2" gutterBottom>
                            The Lucit Applications Library is a list of applications, integrations and data sources built
                            on the Lucit platform that you can attach to your
                            account. These applications are built by Lucit and our third-party partners
                        </LCTypography>

                        {!isXs && <>
                            <LCTypography variant="body2" gutterBottom>
                                Use Lucit Apps to connect product data, inventory data, eCommerce data and more
                                to your Digital OOH Billboard Creatives.
                                <br />
                                <br />Connect your Marketing Analytics software to Lucit
                                to analytics performance data from your campaigns,
                                and build player applications for digital signage that can fetch
                                dynamic content creatives from Lucit
                            </LCTypography>
                        </>}

                        {!isXs && <>

                            <Box mt={3} mb={4}>
                                <LCTypography variant="body2" gutterBottom>
                                    Learn more about developing applications for the Lucit Platform at <Link
                                        href="https://www.lucit.cc/developers"
                                        color="secondary">
                                        https://www.lucit.cc/developers
                                    </Link>
                                </LCTypography>
                            </Box>
                        </>}
                    </IonCardContent>
                </IonCard>
            </Box>

            {loading && <CircularProgressCentered />}

            <ApplicationList
                apps={apps}
                selectApp={(app) => history.push(`/${app.slug}`)}
            />

        </Paper>
    );
}

const mapStateToProps = () => {

    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllApplications: (filters) => dispatch(getAllApplications(filters)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withConditionalLayout(
        PublicAppsPage, { publicOptions: { disablePaper: true } }
    )
);
