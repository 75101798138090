import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function DropShadowIcon(props) {
        return <SvgIcon {...props} viewBox="0 0 1080 1080">
                <rect x="144" y="157" width="696" height="696" />
                <rect x="191" y="888.75" width="241" height="91.87" />
                <rect x="477.69" y="888.75" width="180.79" height="91.87" />
                <rect x="703" y="888.75" width="127.47" height="91.87" />
                <rect x="875.67" y="889" width="91.33" height="91.12" />
                <rect x="800.76" y="287.61" width="241" height="91.87" transform="translate(1254.81 -587.7) rotate(90)" />
                <rect x="830.86" y="544.2" width="180.79" height="91.87" transform="translate(1511.39 -331.12) rotate(90)" />
                <rect x="857.52" y="742.85" width="127.47" height="91.87" transform="translate(1710.04 -132.47) rotate(90)" />
        </SvgIcon>
}
