import React from 'react';
import clx from 'classnames';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => {
    return {
        rootOneLine: {
            display: 'flex',
            alignItems: 'center'
        },
        board: {
            background: '#ABABAB',
            border: '1px solid black',
            boxSizing: 'content-box',
            margin: '0 auto'
        },

        boardSize: {
            color: theme.palette.common.black,
            fontSize: "10px",
            margin: '0 auto',
            textAlign: "center"
        },
        boardSizeName: {
            color: theme.palette.common.black,
            fontSize: "9px",
            margin: '0 auto',
            textAlign: "center"
        },
        boardSizeOneLine: {
            marginLeft: 8
        }
    }
})

function BillboardThumbnail({ board,
    boardScale = 12,
    fontSize = undefined,
    showPixelsSuffix = false,
    showAsOneLine = false,
    classes: classesCustom = {} }) {
    const classes = useStyles();

    return (<Box className={clx({
        [classes.rootOneLine]: showAsOneLine,
        [classesCustom.root]: Boolean(classesCustom.root)
    })}>
        <Box
            className={clx({
                [classes.board]: true,
                [classes.boardOneLine]: showAsOneLine,
                [classesCustom.board]: Boolean(classesCustom.board)
            })}
            style={{
                width: (board.width / board.height).toFixed(1) * boardScale + 'px',
                height: boardScale + 'px'
            }}
        />

        <Box
            style={{
                fontSize: isNaN(fontSize)
                    ? undefined
                    : fontSize
            }}
            className={clx({
                [classes.boardSize]: true,
                [classes.boardSizeOneLine]: showAsOneLine,
                [classesCustom.boardSize]: Boolean(classesCustom.boardSize)
            })}>
            {board.board_size_description} {showPixelsSuffix && "pixels"}
        </Box>

        <Box
            style={{
                fontSize: isNaN(fontSize)
                    ? undefined
                    : fontSize
            }}
            className={clx({
                [classes.boardSize]: true,
                [classes.boardSizeOneLine]: showAsOneLine,
                [classesCustom.boardSize]: Boolean(classesCustom.boardSize)
            })}>
            {board.digital_board_format && board.digital_board_format.name}
        </Box>
    </Box >);
}

export default BillboardThumbnail;
