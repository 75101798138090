import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { gmStreetViewUrl } from '../../helpers/url';
import { getPosition, getPov } from "../../selectors/locations";
import { connect } from 'react-redux';
import { getLocationById } from '../../actions/locations';

const StreetViewOverlay = ({ creative, streetView, getLocationById, alwaysUseBackupImage }) => {
    const [location, setLocation] = useState(streetView);
    const [svMetaData, setSvMetaData] = useState(null);

    const streetViewId = creative.digital_board_to_image
        && creative.digital_board_to_image.options.street_view_location_id;

    // Load street view location if it empty
    useEffect(() => {
        if (!location && streetViewId)
            getLocationById(streetViewId)
                .then(data => setLocation(data))

    }, [streetViewId])

    // Update location when new streetView passed
    useEffect(() => setLocation(streetView), [streetView])

    useEffect(() => {

        if (location) {

            const metaUrl = gmStreetViewUrl(position, pov, null, true);

            if (metaUrl) {
                fetch(metaUrl)
                    .then((response) => response.json())
                    .then((data) => setSvMetaData(data))
            }

        }

    }, [location])

    const position = getPosition(location);
    const pov = getPov(location);

    const streetViewBackupImageUrl = location
        && location.options.street_view_backup_image_public_url
        ? location.options.street_view_backup_image_public_url
        : null;

    const streetViewImageUrl = useMemo(() => {

        if (//If we are getting no image from the pano and we have backup image use it
            svMetaData
            && svMetaData.status === "ZERO_RESULTS"
            && location
            && location.options.street_view_backup_image_public_url
        )
            return location.options.street_view_backup_image_public_url

        return position && pov
            ? gmStreetViewUrl(position, pov)
            : null
    }, [position, pov, svMetaData]);

    if (location == null) {
        return null;
    }

    return <div style={{ position: "relative" }}>
        <img
            style={{
                position: "relative",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            }}
            src={streetViewImageUrl}
        />

        {/* Overlay our backup image over the top of the street view image.  We still pay for the api call.
        but we use the image we know is an eact match for the overlay. */}
        {alwaysUseBackupImage && streetViewBackupImageUrl &&
            <img
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
                src={streetViewBackupImageUrl}
            />
        }
        <img
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            }}
            src={creative.image.options.public_url}
        />
    </div>
}

StreetViewOverlay.propTypes = {
    creative: PropTypes.object.isRequired,
    streetView: PropTypes.object
}

const mapDispatchToProps = dispatch => {
    return {
        getLocationById: id => dispatch(getLocationById(id))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(StreetViewOverlay);
