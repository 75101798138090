import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme, DialogContent } from '@material-ui/core';

import { Dialog } from '../material';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginForm from '../../containers/LoginForm';
import { showSuccess } from '../../actions/snackbar';
import { toggleSignInDialog } from '../../actions/layout';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        height: '100%'
    },

    dialogContentRoot: {
    },
}));

const SignInDialog = ({ open, handleClose, handleSignIn, showSuccessLogin }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="xs"
            scroll="paper"
            open={open}
            aria-labelledby="signup-dialog-title"
            onClose={handleClose}
            title={t('Sign In')}
            TitleProps={{
                id: "signup-dialog-title"
            }}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <LoginForm
                    onSubmit={({ user }) => {
                        if (handleSignIn) {
                            handleSignIn();
                        } else {
                            showSuccessLogin(user)
                            handleClose();
                        }
                    }}
                    onNav={() => handleClose()}

                />
            </DialogContent>
        </Dialog>
    )
}

SignInDialog.propTypes = {
    handleSignIn: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        open: state.layout.signInOpen,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleClose: () => dispatch(toggleSignInDialog(false)),
        showSuccessLogin: (user) => dispatch(showSuccess(i18next.t('Welcome, ') + user.name + ("!")))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SignInDialog)
);
