import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import BillboardsIcon from '@material-ui/icons/DesktopWindows';
import { lucitApi } from '../../services/lucitApi';
import { Skeleton } from '@material-ui/lab';

export const ClearChannel = ({ exportItem, apiToken }) => {

    const [loadingBoards, setLoadingBoards] = useState(true);
    const [digitalBoards, setDigitalBoards] = useState([]);

    useEffect(() => {
        exportItem && lucitApi.exports.getExportBillboards(exportItem.id).then((digital_boards) => {
            setDigitalBoards(digital_boards);
            setLoadingBoards(false);
        })
    }, [exportItem?.id])

    return (
        <Box p={5}>

            <Box pb={3}>
                <LCTypography variant="h5">Clear Channel Player</LCTypography>
            </Box>

            <LCTypography variant="body2">
                Contact your Clear Channel Scheduling team for assistance.
            </LCTypography>
            <LCTypography variant="body2">
                Each digital screen will request it&apos;s creatives using the vendor supplied location ID&apos;s.
            </LCTypography>

            {loadingBoards && <Skeleton animation="wave" height={300} width={'90%'} />}

            {digitalBoards && digitalBoards.map((board, idx) => {
                return (
                    <Box p={2} key={idx}>
                        <h5><BillboardsIcon /><> </>{board.board_identifier} : {board.name}</h5>
                        <code>
                            <b>SIGN ID</b> : {board.board_identifier}<br />
                            <b>CAMPAIGN ID</b> : {exportItem.hash_id}<br />
                            <b>SIZE</b> : {board.width}x{board.height} <br />
                            <b>AD NAME</b> : Lucit : {exportItem.account && exportItem.account.name} :
                            {board.board_identifier}<br />
                            <br /><br />

                            <b>API URL JSON</b>: <br />https://pull.lucit.app/api/v1/campaigns/cco/{exportItem.hash_id}
                            /pull?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                            <br />

                            <b>API URL XML</b>: <br />https://pull.lucit.app/api/v1/campaigns/cco/{exportItem.hash_id}
                            /pull/xml?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                            <br />

                            <b>API URL IMAGE</b>: <br />https://pull.lucit.app/api/v1/campaigns/cco/{exportItem.hash_id}
                            /pull/img?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                            <br />

                            <b>API URL VAST</b>: <br />https://pull.lucit.app/api/v1/campaigns/cco/{exportItem.hash_id}
                            /pull/vast?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                            <br />

                            <b>API URL HTML</b>: <br />https://pull.lucit.app/api/v1/campaigns/cco/{exportItem.hash_id}
                            /pull/html?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                            <br />

                        </code>
                    </Box>
                )
            })}

        </Box>
    )
}
