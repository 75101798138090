import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, useTheme, useMediaQuery } from '@material-ui/core';
import { Dialog } from '../material';

const GenericDialog = (props) => {
    const { title,
        dialogProps,
        dialogTitleProps,
        dialogContentProps,
        dialogActionsProps,
        ContentComponent,
        ActionsComponent } = props;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            title={title}
            TitleProps={dialogTitleProps}
            fullScreen={isXs}
            {...dialogProps}
        >
            <DialogContent {...dialogContentProps}>
                {ContentComponent}
            </DialogContent>
            <DialogActions {...dialogActionsProps}>
                {ActionsComponent}
            </DialogActions>
        </Dialog>
    )
}

GenericDialog.propTypes = {
    dialogProps: PropTypes.object,
    dialogTitleProps: PropTypes.object,
    dialogContentProps: PropTypes.object,
    dialogActionsProps: PropTypes.object,
    ContentComponent: PropTypes.any,
    ActionsComponent: PropTypes.any,
}

export { GenericDialog }
