import { Box, Paper } from '@material-ui/core';
import React from 'react';
import AccountProfile from '../../containers/account/AccountProfile';
import { OgMetatags, PageTitle } from '../../components';

import { hasIcon } from '../../selectors/account';
import AccountFeed from '../../containers/account/AccountFeed';
import { ButtonScrollToTopFab } from '../../components/material';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';

function Profile(props) {
    const { object } = props;

    return <>
        <Box component={Paper} p={3} style={{ maxWidth: '600px', margin: 'auto' }}>
            <PageTitle title={object.name} />
            <OgMetatags
                title={object.name}
                image={hasIcon(object)
                    ? object.options.primary_image_public_url
                    : null} />

            <AccountProfile account={object} />
            <ButtonScrollToTopFab />
        </Box>

        <Box mt={2}>
            <AccountFeed account={object} />
        </Box>
    </>
}
export default withConditionalLayout(
    Profile, { publicOptions: { disablePaper: true } }
)