import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { lucitApi } from '../../services/lucitApi';
import { OpenOOHVenueTypeMaxSupportedLevel, RoutePaths } from '../../helpers/constants';
import { ListItemAvatar, List, ListItem, ListItemText } from '@material-ui/core';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { AgencyAvatar } from '../agency';
import { getScreenIcon } from '../../selectors/billboard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
        body: {
            height: '100%',
            minHeight: "600px",
            display: "block"
        },
        mapBox: {
            position: 'relative',
            marginTop: 0,
            alignItems: 'center',
            height: '100%',
            minHeight: "600px",
        }
    }
});

export const CampaignBoardsProgrammatic = ({ campaign }) => {
    const classes = useStyles();
    const [venueTypes, setVenueTypes] = useState([]);
    const [boardFormats, setBoardFormats] = useState([]);
    const [showAgencies, setShowAgencies] = useState(false);
    const [showVenueTypes, setShowVenueTypes] = useState(false);
    const [showScreenSizes, setShowScreenSizes] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        lucitApi.billboards.getDigitalBoardFormats()
            .then(data => setBoardFormats(Object.keys(data).map(code => ({ ...data[code], code }))))
    }, []);

    useEffect(() => {
        lucitApi.billboards.getVenueTypes()
            .then(data => setVenueTypes(data.filter(vt => vt.level <= OpenOOHVenueTypeMaxSupportedLevel)))
    }, []);

    const screenCount = campaign.settings_array?.transformer?.digital_board_ids?.length

    const campaignBoardFormats = campaign.digital_board_filters?.board_formats?.include?.length > 0
        ? campaign.digital_board_filters?.board_formats?.include.map(i => boardFormats.find(b => b.code == i))
            .filter(x => !!x)
        : boardFormats;
    const campaignVenueTypes = campaign.digital_board_filters?.open_ooh_venue_type?.include?.length > 0
        ? campaign.digital_board_filters?.open_ooh_venue_type?.include
            .map(i => venueTypes.find(b => b.enumeration_id == i))
        : venueTypes;

    return (
        <Box className={classes.body}>

            <Box display={"Flex"} mt={1} mb={1} style={{ gap: 8 }}>
                <Button variant='outlined' color='secondary' onClick={() => setShowAgencies(true)}>
                    {campaign.operators.length} {t('Operators')}
                </Button>
                <Button variant='outlined' color='secondary' onClick={() => setShowScreenSizes(true)}>
                    {campaignBoardFormats.length} {t('Screen Sizes')}
                </Button>
                <Button variant='outlined' color='secondary' onClick={() => setShowVenueTypes(true)}>
                    {campaignVenueTypes.length} {t('Venue Types')}
                </Button>
            </Box>

            <Box mt={3}>
                <Button
                    component={RouterLink}
                    color="secondary"
                    variant="contained"
                    to={RoutePaths.public.programmatic(campaign.lcuid)}>{t('Edit ' + screenCount + ' Screens')}</Button>
            </Box>

            {/**
             * TEMPORARILY REMOVED
             * The map is not renderiong the screens
             * I am not sure why
             * will fix later
            <Box className={classes.mapBox}>
                <ProgrammaticMap
                    geo={campaign.digital_board_filters.geo.include}
                    locations={locations}
                    agencies={agencies}
                    venueTypes={venueTypes}
                    viewOnly />
            </Box>
            **/}

            {showAgencies
                && <GenericDialog
                    title={"Operators"}
                    dialogProps={{
                        open: true,
                        onClose: () => setShowAgencies(false),
                        fullWidth: true,
                        maxWidth: 'sm'
                    }}
                    ContentComponent={<>
                        <List dense disablePadding>
                            {campaign.operators.map(o => (<ListItem key={o.lcuid} disableGutters>
                                <ListItemAvatar>
                                    <AgencyAvatar agency={o} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={o.name}
                                />
                            </ListItem>))}
                        </List>
                    </>}
                    ActionsComponent={<>
                        <Button
                            onClick={() => setShowAgencies(false)}
                            variant='contained'
                            color="secondary">
                            {t('OK')}
                        </Button>
                    </>}
                />}
            {showVenueTypes
                && <GenericDialog
                    title={"Venue Types"}
                    dialogProps={{
                        open: true,
                        onClose: () => setShowVenueTypes(false),
                        fullWidth: true,
                        maxWidth: 'sm'
                    }}
                    ContentComponent={<>
                        <List dense disablePadding>
                            {campaignVenueTypes.map(o => (<ListItem key={o.lcuid}
                                style={{ paddingLeft: o.parent_id ? 16 : 0 }}
                                disableGutters>
                                <ListItemText
                                    primary={o.name}
                                />
                            </ListItem>))}
                        </List>
                    </>}
                    ActionsComponent={<>
                        <Button
                            onClick={() => setShowVenueTypes(false)}
                            variant='contained'
                            color="secondary">
                            {t('OK')}
                        </Button>
                    </>}
                />}
            {showScreenSizes
                && <GenericDialog
                    title={"Screen Sizes"}
                    dialogProps={{
                        open: true,
                        onClose: () => setShowScreenSizes(false),
                        fullWidth: true,
                        maxWidth: 'sm'
                    }}
                    ContentComponent={<>
                        <List disablePadding dense>
                            {campaignBoardFormats.map(o => (<ListItem
                                key={o.code}
                                disableGutters>
                                <img
                                    style={{ width: "40px", marginRight: 8 }}
                                    src={getScreenIcon(o.code)}
                                />
                                <ListItemText
                                    primary={o.name}
                                    secondary={`${o.size.width}x${o.size.height}`}
                                />
                            </ListItem>))}
                        </List>
                    </>}
                    ActionsComponent={<>
                        <Button
                            onClick={() => setShowScreenSizes(false)}
                            variant='contained'
                            color="secondary">
                            {t('OK')}
                        </Button>
                    </>}
                />}
        </Box>
    )
}
