import {
    LIGHTNING_DEVICES_LOADED, LIGHTNING_DEVICE_LAYOUTS_LOADED,
    LIGHTNING_DEVICE_DIGITAL_BOARDS_LOADED, SHADOW_ACCOUNT_LOADED,
    LIGHTNING_DEVICE_HEARTBEAT_STATS_LOADED, LIGHTNING_SCREEN_STATS_LOADED, LIGHTNING_LATEST_VERSION_LOADED
} from "../helpers/actionTypes";
import { lucitApi } from "../services/lucitApi"
import { ApplicationFeatures } from "../helpers/constants";
import { sortBoolean } from "../helpers/sorters";

export function loadAgencyShadowAccount(agencyId) {
    return dispatch => {
        lucitApi.agencies.getShadowAccount(agencyId).then(shadowAccount => {

            dispatch({
                type: SHADOW_ACCOUNT_LOADED,
                shadowAccount: shadowAccount
            });

            return shadowAccount;

        })
    }
}

export function loadAgencyDigitalBoards(agencyId) {
    return dispatch => {
        return lucitApi.agencies.getDigitalBoards(agencyId)
            .then(digitalBoards => {
                dispatch({
                    type: LIGHTNING_DEVICE_DIGITAL_BOARDS_LOADED,
                    digitalBoards: digitalBoards
                });

                return digitalBoards;
            })
    }
}

export function loadAgencyDeviceLayouts(agencyId) {
    return dispatch => {
        lucitApi.agencies.getLightningDeviceLayouts(agencyId)
            .then(deviceLayouts => {
                dispatch({
                    type: LIGHTNING_DEVICE_LAYOUTS_LOADED,
                    deviceLayouts: deviceLayouts
                        .sort((a, b) => sortBoolean(a.is_default, b.is_default))
                });

                return deviceLayouts;
            })
    }
}

export function loadAgencyLightningDevices(agencyId) {
    return dispatch => {
        lucitApi.agencies.getLightningDevices(agencyId).then(devices => {

            dispatch({
                type: LIGHTNING_DEVICES_LOADED,
                devices: devices
            });

            return devices;

        })
    }
}

export function loadLightningDeviceHeartbeatStats(stats) {
    return dispatch => {
        dispatch({
            type: LIGHTNING_DEVICE_HEARTBEAT_STATS_LOADED,
            stats: stats
        });
    }
}

export function loadLightningScreenStats(stats) {
    return dispatch => {
        dispatch({
            type: LIGHTNING_SCREEN_STATS_LOADED,
            stats: stats
        });
    }
}

export function loadLightningLatestVersion() {
    return dispatch => {
        lucitApi.applications.getKeyValueStores(
            ApplicationFeatures.lightning.lcuid,
            ["version"],
            ApplicationFeatures.lightning.lcuid
        ).then(key_value_stores => {

            if (!key_value_stores || !key_value_stores[0]) {
                return null;
            }

            dispatch({
                type: LIGHTNING_LATEST_VERSION_LOADED,
                version: key_value_stores[0].value
            });
        })
    }
}
