import { apiHttp } from "../helpers/api";

export default class LucitApiBillboard {
    get(filters) {
        const { page, perPage, agencyId } = filters;

        return apiHttp.get(`/digital-boards`, {
            params: {
                agency_id: agencyId,
                page,
                per_page: perPage
            }
        })
            .then(response => response.data.digital_boards)
    }

    create(screen) {
        return apiHttp.post(`/digital-boards`, screen)
            .then(response => response.data)
    }

    update(screen) {
        return apiHttp.put(`/digital-boards/${screen.id}`, screen)
            .then(response => response.data.digital_board)
    }

    delete(screen) {
        return apiHttp.delete(`/digital-boards/${screen.id}`)
            .then(response => response.data)
    }

    createImage(screenId, imageId, name, coordinates, style) {
        return apiHttp.post(`/digital-boards/${screenId}/board-images`, {
            image_id: imageId,
            name,
            overlay_data: JSON.stringify({ overlay_perspective_coordinates: coordinates.map(c => [c.x, c.y]) }),
            overlay_type: "perspective",
            style: style || "normal"
        })
            .then(response => response.data.digital_board_to_image)
    }

    updateImage(screenId, screenImage, coordinates, style) {
        return apiHttp.put(`/digital-boards/${screenId}/board-images/${screenImage.id}`, {
            image_id: screenImage.image && screenImage.image.id,
            is_main_image: screenImage.is_main_image,
            name: screenImage.name,
            overlay_data: JSON.stringify({ overlay_perspective_coordinates: coordinates.map(c => [c.x, c.y]) }),
            overlay_type: "perspective",
            style: style || "normal"
        })
            .then(response => response.data.digital_board_to_image)
    }

    deleteImage(screenId, imageId) {
        return apiHttp.delete(`/digital-boards/${screenId}/board-images/${imageId}`);
    }

    setAsMainImage(screenId, imageId) {
        return apiHttp.put(`/digital-boards/${screenId}/board-images/${imageId}/set-main-image`, {})
            .then(response => response.data)
    }

    getById(id) {
        return apiHttp.get(`/digital-boards/${id}`, { params: { board_show_data_type_v2: true } })
            .then(response => response.data.digital_board)
    }

    getPlaylist(screenId, params) {
        return apiHttp.get(`/digital-boards/${screenId}/fetch-playlist`, { params })
            .then(response => response.data.playlist?.playlist?.items || []);
    }
    getPlayStatuses(agencyId) {
        return apiHttp.get(`/digital-boards/play-status`, {
            params: { agency_id: agencyId }
        })
            .then(response => response.data.digital_board_play_status || []);
    }

    getPlayStatus(id) {
        return apiHttp.get(`/digital-boards/${id}/play-status`)
            .then(response => response.data.digital_board_play_status || []);
    }

    getMap(data, isLoggedIn) {
        const { accountIds, agencyIds, street_view_only } = data;

        const url = isLoggedIn ? '/digital-boards/map' : '/digital-boards/map/noauth';

        return apiHttp.get(url, {
            params: {
                account_id: accountIds
                    ? JSON.stringify(accountIds)
                    : null,
                agency_id: agencyIds
                    ? JSON.stringify(agencyIds)
                    : null,

                bounds_sw_lat: -90,
                bounds_sw_long: -180,
                bounds_ne_lat: 90,
                bounds_ne_long: 180,
                street_view_only: street_view_only ? 1 : 0
            }
        })
            .then(response => response.data)
    }

    getMapV2({ bounds, digital_board_filters }, options) {
        return apiHttp.post('/digital-boards/map/v2', {
            bounds,
            digital_board_filters,
            limit: 1000,
            use_clustering: true
        }, options)
            .then(response => response.data)
    }

    getImages(id) {
        return apiHttp.get(`/digital-boards/${id}/board-images`, { params: { per_page: 10_000 } })
            .then(response => response.data.digital_board_to_images.data);
    }

    getPublicImages(id) {
        return apiHttp.get(`/public/digital-boards/${id}/board-images`, { params: { per_page: 10_000 } })
            .then(response => response.data.digital_board_to_images.data);
    }

    getHardwareProviders() {
        return apiHttp.get('/digital-boards/hardware-providers')
            .then(response => response.data.hardware_providers);
    }

    getSofwareProviders() {
        return apiHttp.get('/digital-boards/software-providers')
            .then(response => response.data.software_providers);
    }

    getFacingCardinals() {
        return apiHttp.get('/digital-boards/facing-cardinals')
            .then(response => response.data.facing_cardinals);
    }

    getDigitalBoardFormats() {
        return apiHttp.get('/digital-boards/digital-board-formats')
            .then(response => response.data.digital_board_formats);
    }

    getVenueTypes() {
        return apiHttp.get('/digital-boards/venue-taxonomy-ids')
            .then(response => response.data.venue_taxonomy_ids);
    }

    setStreetViewLocation(boardId, locationId) {
        return apiHttp.put(`/digital-boards/${boardId}/street-view-location/${locationId}/set`)
            .then(response => response.data.digital_board);
    }

    clearStreetViewLocation(boardId) {
        return apiHttp.put(`/digital-boards/${boardId}/street-view-location/clear`)
            .then(response => response.data.digital_board);
    }

    setStreetViewStatus(boardId, street_view_status) {
        return apiHttp.put(`/digital-boards/${boardId}/street-view-location/set-status`, {
            street_view_status: street_view_status
        })
            .then(response => response.data.digital_board);
    }
}
