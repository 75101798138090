import { useCallback, useState } from "react"

export function useHookWithRefCallback() {
    const [node, setNode] = useState(null)

    const ref = useCallback(node => {
        setNode(node);
    }, [])

    return [node, ref]
}