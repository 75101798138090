import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CreateProofDialog from '../../containers/location/CreateProofDialog';
import { enableCeateProofButton } from '../../selectors/proofs';
import { selectedProfileIsAccount } from '../../selectors/user';
import QuickAddProof from './QuickAddProof';
import { hasAgencies, getUser } from '../../selectors/user';

const CreateProofButton = ({ buttonProps, billboard, location, profileIsAccount, isLoggedIn, hasAgencies }) => {

    const [createProofForm, setCreateProofForm] = useState(false);
    const [showQuickAddProof, setShowQuickAddProof] = React.useState(false);

    const { t } = useTranslation();

    return <>
        <Button
            variant="contained"
            disabled={!isLoggedIn ? false : !enableCeateProofButton(billboard)}
            onClick={() => profileIsAccount ? setShowQuickAddProof(true) : setCreateProofForm(true)}
            color="primary"
            {...buttonProps}
        >
            {t('Create Proof')}
        </Button>

        <CreateProofDialog
            open={createProofForm}
            onClose={() => setCreateProofForm(false)}
            billboard={billboard}
            location={location}
        />

        {profileIsAccount && <QuickAddProof
            open={showQuickAddProof}
            billboard={billboard}
            skipAccountSelection={hasAgencies ? false : true}
            handleClose={() => setShowQuickAddProof(false)}
        />
        }
    </>
}

CreateProofButton.propTypes = {
    billboard: PropTypes.object.isRequired,
    location: PropTypes.object.location,
}

const mapStateToProps = (state) => {
    return {
        profileIsAccount: selectedProfileIsAccount(state),
        isLoggedIn: state.user.isLoggedIn,
        hasAgencies: hasAgencies(getUser(state)),
    }
}

export default connect(
    mapStateToProps
)(
    CreateProofButton
);
