import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AddDeviceButton from './AddDeviceButton';
import DeviceLayouts from './DeviceLayouts';
import Screens from './Screens';
import ScreenGroupsPage from './ScreenGroups';
import { selectedAgency } from '../../selectors/user';
import { useSelector, useDispatch } from 'react-redux';
import {
    loadAgencyShadowAccount, loadAgencyDeviceLayouts, loadAgencyDigitalBoards, loadAgencyLightningDevices, loadLightningLatestVersion
} from '../../actions/lightning';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import CampaignGroups from './CampaignGroups';
import { lucitApi } from '../../services/lucitApi';
import { AGENCY_EXPORTS } from '../../helpers/actionTypes';
import LinkDeviceButton from './LinkDeviceButton';
import BillboardsMap from '../../containers/dashboard/BillboardsMap';
import { convertBoardArrayToLocationsArray } from '../../selectors/billboard';

const MapTab = () => {

    const [locations, setLocations] = useState([]);

    const agency = useSelector(selectedAgency);
    const digitalBoards = useSelector(state => state.lightning.digitalBoards);

    useEffect(() => {

        const boardLocations = convertBoardArrayToLocationsArray(digitalBoards);
        setLocations(boardLocations)
    }, [digitalBoards])

    return <div style={{ height: '75vh', width: '100%' }}>
        <BillboardsMap
            agencyId={agency?.id}
            dontAutoCenterToUser={true}
            locations={locations}
            showTargetedOnly={false}
            highlightTargeted={true}
            showLastPlay={false}
            promptGeoPermissions={false}
            centeringType="locations"
            enableClustering={false}
        />

    </div>
}

const LightningPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const agency = useSelector(selectedAgency);
    const shadowAccount = useSelector(state => state.lightning.shadowAccount);
    const { t } = useTranslation();

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (!agency?.id)
            return;

        lucitApi.agencies.getExports(agency.lcuid)
            .then(exports => dispatch({ type: AGENCY_EXPORTS, exports }));

        dispatch(loadLightningLatestVersion())
        dispatch(loadAgencyShadowAccount(agency.id))
        dispatch(loadAgencyDigitalBoards(agency.id))
        dispatch(loadAgencyDeviceLayouts(agency.id))
        dispatch(loadAgencyLightningDevices(agency.id))
    }, [agency?.id])

    const canShowAddDeviceButton = shadowAccount && agency;

    return <>
        <Box mb={2} display='flex' justifyContent="space-between">
            <LCTypography variant="h5">Lighting Player</LCTypography>
            {canShowAddDeviceButton &&
                <Box display="flex" style={{ gap: 8 }}>
                    <LinkDeviceButton />
                    <AddDeviceButton />
                </Box>}
        </Box>
        <Box mb={2} style={{ borderBottom: '1px solid', borderColor: 'rgba(0, 0, 0, 0.12)' }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label={t('Screens')} />
                <Tab label={t('Screen Groups')} />
                <Tab label={t('Campaign Groups')} />
                <Tab label={t('Map')} />
                <Tab label={t('Device Layouts')} />
            </Tabs>
        </Box>

        {selectedTab === 0 && (
            <Screens />
        )}

        {selectedTab === 1 && (
            <ScreenGroupsPage />
        )}

        {selectedTab === 2 && (
            <CampaignGroups />
        )}

        {selectedTab === 3 && (
            <MapTab />
        )}

        {selectedTab === 4 && (
            <DeviceLayouts />
        )}
    </>
}

export default LightningPage
