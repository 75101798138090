import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from "react-i18next";
import { lucitApi } from "../../services/lucitApi";
import { Checkbox, InputAdornment, List, ListItem, ListItemIcon, Tooltip, Grid } from "@material-ui/core";
import FilterIcon from '@material-ui/icons/FilterList';
import CircularProgressCentered from "../../components/material/CircularProgressCentered";
import BillboardListItem from "./BillboardListItem";
import OperatorBoardMapFromSelectedScreens from '../../containers/billboards/OperatorBoardMapFromSelectedScreens';

const BillboardSelector = (props) => {
    const { agency, exportBoards, sizes, values, onChange, setSelectedBoardIds, disabledAllChecked = false, showFilter = true } = props;

    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [digitalBoards, setDigitalBoards] = useState([])
    const [initialValues] = useState(values)
    const { t } = useTranslation();

    useEffect(() => {
        if (agency) {
            setDigitalBoards([]);
            setLoading(true);

            lucitApi.agencies.getDigitalBoards(agency.id)
                .then(x => setDigitalBoards(x))
                .finally(() => setLoading(false));
        }
    }, [agency])

    useEffect(() => {
        if (exportBoards) {
            setDigitalBoards(exportBoards);
        }
    }, [exportBoards])

    if (loading) {
        return <CircularProgressCentered />
    }

    let boards = digitalBoards
        .filter(x =>
            (sizes == null || sizes.some(size => x.width / x.height >= size.data.ratios.min && x.width / x.height <= size.data.ratios.max))
            && (search == ''
                || x.name.toUpperCase().includes(search.toUpperCase())
                || x.board_identifier.toUpperCase().includes(search.toUpperCase())
                || x.location.city.toUpperCase().includes(search.toUpperCase())
                || x.location.region.toUpperCase().includes(search.toUpperCase())
                || x.location.country.toUpperCase().includes(search.toUpperCase())
                || x.board_size_description.toUpperCase().includes(search.toUpperCase())
                || x.digital_board_format.name.toUpperCase().includes(search.toUpperCase())
            ))
        .sort((a, b) => 2 * (a.name > b.name) - 1)

    if (initialValues) {
        boards = boards.sort((a) => 2 * (initialValues.includes(a.id) ? 0 : 1) - 1)
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>

                {showFilter && <TextField
                    type="text"
                    fullWidth
                    variant="standard"
                    placeholder={t("Filter...")}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    inputProps={{
                        "aria-autocomplete": "both",
                        "aria-haspopup": "false",
                        "autoComplete": "off",
                        "spellCheck": "false"
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <Tooltip title={t("Filter...")}>
                                <FilterIcon fontSize="small" />
                            </Tooltip>
                        </InputAdornment>
                    }}
                />
                }

                <List style={{
                    maxHeight: '400px', overflow: 'auto'
                }} >

                    <ListItem disableGutters >
                        <Tooltip title={t("Select All")}>
                            <ListItemIcon>
                                <Checkbox
                                    name="digital_board_select_all"
                                    edge="start"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            onChange(boards.map(x => x.id))
                                        } else {
                                            onChange([])
                                        }
                                    }}
                                />
                            </ListItemIcon>
                        </Tooltip>
                    </ListItem>

                    {
                        boards
                            .map(x => <BillboardListItem
                                key={x.id}
                                board={x}
                                showIndicators={false}
                                StartIcon={<ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        disabled={disabledAllChecked}
                                        name={`digital_board_` + x.id}
                                        checked={disabledAllChecked || values.includes(x.id)}
                                        onChange={(e) => e.target.checked
                                            ? onChange([...values, x.id])
                                            : onChange(values.filter(v => v !== x.id))}
                                    />
                                </ListItemIcon>}
                            />)
                    }
                </List>
            </Grid>
            <Grid item xs={12} sm={8}>
                <OperatorBoardMapFromSelectedScreens
                    chosenAgency={agency}
                    boards={boards}
                    selectedBoardIds={values}
                    setSelectedBoardIds={setSelectedBoardIds}
                />
            </Grid>
        </Grid>
    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BillboardSelector)
)
