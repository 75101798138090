import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";
import {
    EntitySet, ENTITY_NEXT_PAGE_ERROR, ENTITY_NEXT_PAGE_REQUEST,
    ENTITY_NEXT_PAGE_RESULT, ENTITY_SEARCH_ERROR, ENTITY_SEARCH_REQUEST, ENTITY_SEARCH_RESULT,
    STORIES_NEW, OBJECT_REACTIONS, OBJECT_NEW_COMMENT, OBJECT_NEW_REACTION,
    OBJECT_DELETE_REACTION, OBJECT_COMMENTS, OBJECT_UPDATE_COMMENT, OBJECT_DELETE_COMMENT
} from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import { idOrLcuidEquals } from "../selectors/objects";
import entities, { initialState as entityInitialState } from "./templates/entities";
import reactions from "./templates/reactions";

export const initialState = {
    public: {
        ...entityInitialState,
        perPage: 20
    },
}

export const persistConfig = {
    key: 'stories',
    whitelist: ['public'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
}

export default function stories(state = initialState, action) {

    switch (action.type) {
        case STORIES_NEW: {
            return {
                ...state,
                public: {
                    ...state.public,
                    data: state.public.data.some(x => x.id === action.story.id)
                        ? state.public.data.map(x => x.id === action.story.id
                            ? ({ ...x, ...action.story })
                            : x)
                        : [action.story, ...state.public.data]
                }
            };
        }

        case ENTITY_SEARCH_REQUEST:
        case ENTITY_SEARCH_ERROR:
        case ENTITY_SEARCH_RESULT:
        case ENTITY_NEXT_PAGE_REQUEST:
        case ENTITY_NEXT_PAGE_ERROR:
        case ENTITY_NEXT_PAGE_RESULT: {
            if (action.entitySet === EntitySet.publicStory) {
                return {
                    ...state,
                    public: entities(state.public, action)
                };
            }
            return state;
        }

        case OBJECT_COMMENTS:
        case OBJECT_NEW_COMMENT:
        case OBJECT_UPDATE_COMMENT:
        case OBJECT_DELETE_COMMENT:
        case OBJECT_REACTIONS:
        case OBJECT_NEW_REACTION:
        case OBJECT_DELETE_REACTION: {
            if (action.objectClass === LuCoreModelClasses.story) {
                return updateStories(state, action.lcuid,
                    (prevStory) => ({
                        ...prevStory,
                        ...reactions(prevStory, action)
                    }))
            }

            return state;
        }

        default:
            return state
    }
}

const updateStory = (story, storyId, update) => {
    let result = story;

    if (story.story_data.is_about_another_story &&
        idOrLcuidEquals(story.story_data.about_story, storyId)) {
        result = {
            ...result,
            story_data: {
                ...result.story_data,
                about_story: update(result.story_data.about_story)
            }
        }
    }

    if (idOrLcuidEquals(story, storyId)) {
        result = update(result);
    }

    return result;
}

const updateStories = (state, storyId, update) => ({
    ...state,
    public: {
        ...state.public,
        data: state.public.data.map(x => {
            if (x.grouped) {
                return {
                    ...x,
                    stories: x.stories.map(y => {

                        return idOrLcuidEquals(y.story, storyId) ||
                            y.story.story_data.is_about_another_story &&
                            idOrLcuidEquals(y.story.story_data.about_story, storyId)
                            ? { ...y, story: updateStory(y.story, storyId, update) }
                            : y
                    })
                }
            }

            return updateStory(x, storyId, update);
        })
    }
})
