import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    TextField, Box,
    Typography,
    Chip
} from '@material-ui/core';
import { Dialog, ButtonLoader } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import { selectedAgency } from '../../selectors/user';
import { useSelector } from 'react-redux';
import { LIGHTNING_GROUP_CAMPAIGN_ADDED, LIGHTNING_GROUP_CAMPAIGN_UPDATED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';
import { Alert } from "@material-ui/lab";
import LCTypography from '../../components/material/LCTypography';
import { IconPicker } from '../../components/IconPicker';
import { Tv } from '@material-ui/icons';
import { StylePicker } from '../../components/StylePicker';
import { IconFromSet } from '../../components/IconFromSet';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const ScreenGroupDialog = ({ group, open, handleClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const agency = useSelector(selectedAgency);
    const dispatch = useDispatch();
    const isEdit = group != null;

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(group?.name ?? t('New Group'));
    const [icon, setIcon] = useState(group?.options?.ui_options?.layout?.icon);
    const [styleName, setStyleName] = useState(group?.options?.ui_options?.layout?.styleName);
    const [error, setError] = useState()

    const handleSave = () => {
        const promises = [];
        const data = { name, ui_options: { icon, styleName } };

        if (isEdit) {
            promises.push(
                lucitApi.exports.update(group.id, data)
                    .then((response) => dispatch({
                        type: LIGHTNING_GROUP_CAMPAIGN_UPDATED,
                        groupCampaign: response.inventory_export
                    }))
            );
        } else {
            promises.push(
                lucitApi.campaigns.generateScreenGroupCampaign(agency.lcuid, data)
                    .then((response) => {
                        dispatch({
                            type: LIGHTNING_GROUP_CAMPAIGN_ADDED,
                            groupCampaign: response.inventory_export
                        })
                    })
            );
        }

        setLoading(true)
        return Promise.all(promises)
            .then(handleClose)
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth

            title={<>
                <IconPicker value={icon}
                    onChange={setIcon}
                    style={{ marginRight: 8 }}
                    FallbackIcon={Tv}
                />
                {isEdit
                    ? group.name
                    : t('New Screen Group')}
            </>}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <LCTypography>
                    Create a new screen group that you can use to organize your screens.
                </LCTypography>

                <Box mt={2} mb={2}>
                    <TextField
                        label={t('Name')}
                        variant="outlined"
                        autoFocus
                        fullWidth
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Box mt={2}>
                        <Typography gutterBottom>{t('Preview')}:</Typography>
                        <StylePicker
                            value={styleName}
                            onChange={setStyleName}
                            renderPreview={style => <Chip
                                style={style}
                                icon={<IconFromSet
                                    className="MuiChip-icon"
                                    style={{ color: style?.color }}
                                    iconKey={icon}
                                    FallbackIcon={Tv} />}
                                label={name}
                            />}
                        />
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                {error
                    &&
                    <Alert severity="error">{
                        error.response?.data?.message || error.message}
                    </Alert>

                }

                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={loading}
                    disabled={!name}
                    onClick={handleSave}
                    color="primary"
                    variant="contained">
                    {isEdit
                        ? t('Save')
                        : t('Create')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )

}

export default ScreenGroupDialog;
