import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import SecurityIcon from '@material-ui/icons/Security';
import { getUser } from '../../selectors/user';
import { resetPassword } from '../../actions/user';
import { showSuccess, showInfo, showError } from '../../actions/snackbar';
import ResetPasswordForm from '../ResetPasswordForm';
import ButtonLoader from '../../components/material/ButtonLoader';
import { sendPhoneCode } from '../../actions/register';

const useStyles = makeStyles((theme) => {
    return {
        resetFormTitle: {
            marginBottom: theme.spacing(2)
        },
    }
});

function UserInfo(props) {
    const { user, onChangingPassword } = props;
    const classes = useStyles();

    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const sendConfirmationCode = useCallback(() => {
        setIsSendingCode(true);
        return props
            .handlePasswordChangeRequest(user.phone)
            .then(() => {
                setIsChangingPassword(true);

                onChangingPassword && onChangingPassword(true);
            })
            .finally(() => {
                setIsSendingCode(false);
            })
    }, [props.handlePasswordChangeRequest, user.phone, onChangingPassword])

    return (
        <>
            {!isChangingPassword
                && <Grid item xs={12}>
                    <ButtonLoader
                        submitting={isSendingCode}
                        variant="contained"
                        color="secondary"
                        onClick={sendConfirmationCode}
                        startIcon={<SecurityIcon />}
                    >
                        Change Password
                    </ButtonLoader>
                </Grid>}
            {isChangingPassword
                &&
                <Grid item xs={12}>
                    <LCTypography variant="subtitle1" className={classes.resetFormTitle}>Please create a new password</LCTypography>

                    <ResetPasswordForm
                        resendCode={sendConfirmationCode}
                        onSubmit={values => props
                            .handlePasswordChange(values)
                            .then(() => {
                                setIsChangingPassword(false);

                                onChangingPassword && onChangingPassword(false);
                            })}
                    />
                </Grid>}
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handlePasswordChangeRequest: phone => {
            return dispatch(sendPhoneCode(phone))
                .then(() => {
                    dispatch(showInfo('SMS code was sent to your mobile device'))
                })
                .catch(error => {
                    if (error.isOffline) {
                        throw error;
                    }

                    dispatch(showError('Something went wrong, please try again later'));
                    throw error;
                });
        },

        handlePasswordChange: values => {
            return dispatch(resetPassword(values.code, values.password))
                .then(() => dispatch(showSuccess('Your password was successfuly changed')));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo);
