import React from 'react';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { connect } from 'react-redux';
import { PageTitle } from '../../components';
import { ButtonScrollToTopFab } from '../../components/material';
import { selectedAgency } from '../../selectors/user';
import { useTranslation } from 'react-i18next';
import { default as AgencyFeedComponent } from '../../containers/agency/AgencyFeed';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

const AgencyFeed = ({ selectedAgency }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.root}>
            <PageTitle title={t('Activity Feed')} />

            <Box mt={2} />

            <Box mt={2}>
                <AgencyFeedComponent agency={selectedAgency} />
            </Box>

            <ButtonScrollToTopFab />
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        selectedAgency: selectedAgency(state)
    }
}

export default connect(
    mapStateToProps
)(
    withHomeLayout(
        AgencyFeed
    )
);
