import { Button, Grid, TextField, Box } from '@material-ui/core';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import LCTypography from '../../../components/material/LCTypography';
import { Delete as DeleteIcon, Save as SaveIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const filterFieldsAndFunctions = [
    {
        id: "title",
        label: "Title",
        type: "field",
        dataType: "string"
    },
    {
        id: "description",
        label: "Description",
        type: "field",
        dataType: "string"
    },
    {
        id: "price",
        label: "Price",
        type: "field",
        dataType: "float"
    },
    {
        id: "price_original",
        label: "Original Price",
        type: "field",
        dataType: "float"
    },
    {
        id: "unqique_id",
        label: "Unique ID",
        type: "field",
        dataType: "string"
    },
    {
        id: "dealer_stock_number",
        label: "Stock Number",
        type: "field",
        dataType: "string"
    },
    {
        id: "year",
        label: "Year",
        type: "field",
        dataType: "integer"
    },
    {
        id: "make",
        label: "Make",
        type: "field",
        dataType: "string"
    },
    {
        id: "model",
        label: "Model",
        type: "field",
        dataType: "string"
    },
    {
        id: "sub_model",
        label: "Sub Model",
        type: "field",
        dataType: "string"
    },
    {
        id: "new_used",
        label: "New",
        type: "field",
        dataType: "boolean"
    },
    {
        id: "basePhotoCount",
        label: "Photo Count",
        type: "function",
        dataType: "integer"
    },
    {
        id: "primaryPhotoAspectRatio",
        label: "Photo Aspect Ratio",
        type: "function",
        dataType: "float"
    },
    {
        id: "mileage",
        label: "Mileage",
        type: "function",
        dataType: "integer"
    }
]

const filterOperators = [
    //like, like_one_of,equals,does_not_equals,is_one_of,>,<,>=,<=
    {
        id: "like",
        operator: "like",
        label: "Contains",
        help: "Any text, not case sensitive",
        showValueField: true
    },
    {
        id: "not_like",
        operator: "not_like",
        label: "Does not Contain",
        help: "Any text",
        showValueField: true
    },
    {
        id: "like_one_of",
        operator: "like_one_of",
        label: "Contains One Of",
        help: "Multiple items separated by commas",
        showValueField: true
    },
    {
        id: "not_like_any_of",
        operator: "not_like_any_of",
        label: "Contains None Of",
        help: "Multiple items separated by commas",
        showValueField: true
    },
    {
        id: "equals",
        operator: "equals",
        label: "Equals",
        help: "Any value, case sensitive, matches exactly",
        showValueField: true
    },
    {
        id: "does_not_equals",
        operator: "does_not_equals",
        label: "Does Not Equal",
        help: "Any value",
        showValueField: true
    },
    {
        id: "is_one_of",
        operator: "is_one_of",
        label: "Is One Of",
        help: "Multiple items separated by commas",
        showValueField: true
    },
    {
        id: "not_any_of",
        operator: "not_any_of",
        label: "Is Not One Of",
        help: "Multiple items separated by commas",
        showValueField: true
    },
    {
        id: "greater_than",
        operator: ">",
        label: "Greater Than",
        help: "Must be a number",
        showValueField: true
    },
    {
        id: "less_than",
        operator: "<",
        label: "Less Than",
        help: "Must be a number",
        showValueField: true
    },
    {
        id: "greater_than_or_equal_to",
        operator: ">=",
        label: "Greater Than Or Equal To",
        help: "Must be a number",
        showValueField: true
    },
    {
        id: "less_than_or_equal_to",
        operator: "<=",
        label: "Less Than Or Equal To",
        help: "Must be a number",
        showValueField: true
    },
    {
        id: "is_between",
        operator: "is_between",
        label: "Is Between",
        help: "Two numbers separated by a comma (min,max)",
    },
    {
        id: "is_true",
        operator: "is_true",
        label: "Is True",
        help: "Must be true or true'ish value",
        showValueField: false
    },
    {
        id: "is_false",
        operator: "is_false",
        label: "Is False",
        help: "Must be false or falsy value",
        showValueField: false
    },
    {
        id: "is_empty",
        operator: "is_empty",
        label: "Is Empty",
        help: "Must be empty, blank, null or false value",
        showValueField: false
    },
    {
        id: "is_not_empty",
        operator: "is_not_empty",
        label: "Is Not Empty",
        help: "Must be a value",
        showValueField: false
    },
    //datetime_is_future, datetime_is_past, datetime_is_today
    {
        id: "datetime_is_future",
        operator: "datetime_is_future",
        label: "Is In Future",
        help: "Must be a future date or time",
        showValueField: false
    },
    {
        id: "datetime_is_past",
        operator: "datetime_is_past",
        label: "Is In Past",
        help: "Must be a past date or time",
        showValueField: false
    },
    {
        id: "datetime_is_today",
        operator: "datetime_is_today",
        label: "Is Today",
        help: "Must be today's date or time",
        showValueField: false
    },
    {
        id: "matches_regexp",
        operator: "matches_regexp",
        label: "Matches Regular Expression",
        help: "Must match a regular expression in PCRE format /{pattern}/{modifiers}",
        showValueField: true
    },
    {
        id: "does_not_match_regexp",
        operator: "does_not_match_regexp",
        label: "Does Not Match Regular Expression",
        help: "Must not match a regular expression in PCRE format /{pattern}/{modifiers}",
        showValueField: true
    }
]

export const ItemFilterEditor = ({
    filterFields, filterFunctions, onChange, additionalFieldsAndFunctions = [], includeDefaultFieldsAndFunctions = true
}) => {

    const [showAddNewFilter, setShowAddNewFilter] = useState(false);

    //Effective behavior of includeDefaultFieldsAndFunctions is as an override of all existing
    //filterFieldsAndFunctions (defined above as a constant)
    const fieldsAndFunctions = includeDefaultFieldsAndFunctions
        ? filterFieldsAndFunctions.concat(additionalFieldsAndFunctions)
        : additionalFieldsAndFunctions;

    const addNewFilter = (newFilter) => {

        if (newFilter.field_name) {
            filterFields.push(newFilter)
        }
        else if (newFilter.function_name) {
            filterFunctions.push(newFilter)
        }

        onChange({
            filter_fields: filterFields,
            filter_functions: filterFunctions
        })
    }

    const updateExistingFilterField = (index, newFilter) => {

        //It is possible that the new filter is a field or function
        //we need to remove it from it's current array and add it to the new one

        if (newFilter.field_name) {
            filterFields[index] = newFilter  //didn't change its type
        }
        else if (newFilter.function_name) {
            filterFields.splice(index, 1)       //type changed,must be removed
            filterFunctions.push(newFilter)
        }

        onChange({
            filter_fields: filterFields,
            filter_functions: filterFunctions
        })

    }

    const updateExistingFilterFunction = (index, newFilter) => {

        //It is possible that the new filter is a field or function
        //we need to remove it from it's current array and add it to the new one

        if (newFilter.function_name) {
            filterFunctions[index] = newFilter  //didn't change its type
        }
        else if (newFilter.field_name) {
            filterFunctions.splice(index, 1)       //type changed,must be removed
            filterFields.push(newFilter)
        }

        onChange({
            filter_fields: filterFields,
            filter_functions: filterFunctions
        })

    }

    const sortedFieldsAndFunctions = fieldsAndFunctions.sort((a, b) => {
        // Check if "group" exists on both objects
        const groupA = a.group || '';
        const groupB = b.group || '';

        // If the groups are different, sort by group
        if (groupA !== groupB) {
            return groupA.localeCompare(groupB);
        }

        // If the groups are the same (or both empty), sort by label
        return a.label.localeCompare(b.label);
    });

    return <Grid container spacing={3}>

        {filterFields.map((filterField, index) => {

            return <ItemFilterEditorRow
                key={index}
                filter={filterField}
                filterType={"field"}
                fieldsAndFunctions={sortedFieldsAndFunctions}
                onChange={(filter) => updateExistingFilterField(index, filter)}
                onDelete={() => {
                    filterFields.splice(index, 1)
                    onChange({
                        filter_fields: filterFields,
                        filter_functions: filterFunctions
                    })
                }}
            />

        })}

        {filterFunctions.map((filterFunction, index) => {

            return <ItemFilterEditorRow
                key={index}
                filter={filterFunction}
                filterType={"function"}
                fieldsAndFunctions={sortedFieldsAndFunctions}
                onChange={(filter) => updateExistingFilterFunction(index, filter)}
                onDelete={() => {
                    filterFunctions.splice(index, 1)
                    onChange({
                        filter_fields: filterFields,
                        filter_functions: filterFunctions
                    })
                }}
            />

        })}

        {!showAddNewFilter && <Box mt={2} ml={1}><Button
            variant="outlined"
            color="primary"
            onClick={() => {
                setShowAddNewFilter(true)
            }}
        >+ Condition</Button></Box>}

        {showAddNewFilter
            && <ItemFilterEditorRow
                filter={null}
                fieldsAndFunctions={sortedFieldsAndFunctions}
                onChange={(filter) => {
                    addNewFilter(filter)
                    setShowAddNewFilter(false)
                }
                }
            />
        }

    </Grid>

}

export const ItemFilterEditorRow = ({ filter, onChange, fieldsAndFunctions, onDelete }) => {

    const [filterField, setFilterField] = useState(filter ? filter.field_name : null);
    const [filterFunction, setFilterFunction] = useState(filter ? filter.function_name : null);
    const [filterOperator, setFilterOperator] = useState(filter ? filter.operator : null);
    const [filterValue, setFilterValue] = useState(filter ? filter.value : null);

    const [dirty, setDirty] = useState(false);

    const matchingOperator = filterOperator ? filterOperators.find((o) => o.id === filterOperator) : null;

    const operatorHelp = matchingOperator ? matchingOperator.help : null;
    const showValueField = matchingOperator ? matchingOperator.showValueField : true;

    const filterFieldDefaultValues = fieldsAndFunctions.filter((f) => f.id === filterField || f.id === filterFunction)
    const filterDefaultValue = filterFieldDefaultValues.length > 0 ? filterFieldDefaultValues[0] : null

    const filterOpeartorDefaultValues = filterOperators.filter((o) => o.operator === filterOperator)
    const filterOperatorDefaultValue = filterOpeartorDefaultValues.length > 0 ? filterOpeartorDefaultValues[0] : null

    const { t } = useTranslation();

    useEffect(() => {
        if (!showValueField) {
            setFilterValue("");//empty string is fine
        }
    }, [showValueField])

    return <>
        <Grid item xs={3}>

            <div style={{ display: "flow", alignItems: "left", width: "100%" }}>

                <MaterialAutocomplete
                    required
                    defaultValue={filterDefaultValue}
                    id="item-filter-field"
                    options={fieldsAndFunctions}
                    groupBy={(option) => option.group || 'Standard'} // Group by "group" field, or 'Standard' if it doesn't exist
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="When" />}
                    onChange={(_, newValue) => {

                        if (newValue && newValue.type === "field") {
                            setFilterField(newValue.id)
                            setFilterFunction(null)
                        }
                        else if (newValue && newValue.type === "function") {
                            setFilterField(null)
                            setFilterFunction(newValue.id)
                        }
                        else {
                            setFilterField(null)
                            setFilterFunction(null)
                        }

                        setDirty(true)
                    }}
                />
            </div>
        </Grid>
        <Grid item xs={3}>
            <div style={{ display: "flow", alignItems: "left", width: "100%" }}>

                <MaterialAutocomplete
                    required
                    defaultValue={filterOperatorDefaultValue}
                    id="item-operator-field"
                    options={filterOperators}
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label=" " />}
                    onChange={(_, newValue) => {
                        setFilterOperator(newValue && newValue.operator)
                        setDirty(true)
                    }}
                />
            </div>
        </Grid>
        <Grid item xs={3}>

            <div style={{ display: "flow", alignItems: "left", width: "100%" }}>

                {showValueField ? <div><TextField
                    required
                    label=" "
                    defaultValue={filterValue}
                    onChange={(e) => {
                        setFilterValue(e.target.value)
                        setDirty(true)
                    }}
                /></div>
                    : <></>}

            </div>

            <LCTypography variant="caption">{operatorHelp}</LCTypography>
        </Grid>
        <Grid item xs={3}>
            <div style={{ display: "flow", alignItems: "left", width: "100%", marginTop: "25px" }}>

                {filter ? <SaveIcon
                    variant="contained"
                    color="primary"
                    title={t('Save Filter')}
                    style={{ color: !dirty ? "grey" : "black", cursor: 'pointer' }}
                    disabled={!dirty}
                    onClick={() => {
                        onChange({
                            type: filterField ? "filter_field" : "filter_function",
                            field_name: filterField,
                            function_name: filterFunction,
                            operator: filterOperator,
                            value: filterValue
                        })
                        setDirty(false)
                    }}
                /> : <Button
                    variant="contained"
                    color="primary"
                    title={t('Add Filter')}
                    disabled={!dirty}
                    onClick={() => {
                        onChange({
                            type: filterField ? "filter_field" : "filter_function",
                            field_name: filterField,
                            function_name: filterFunction,
                            operator: filterOperator,
                            value: filterValue
                        })
                        setDirty(false)
                    }}
                >{t('Add')}</Button>
                }

                {filter && <DeleteIcon
                    title={t('Remove Filter')}
                    color="primary"
                    style={{ marginLeft: "10px", cursor: 'pointer' }}
                    onClick={() => {
                        onDelete();
                    }}
                />}

            </div>
        </Grid>
    </>
}

export default ItemFilterEditor;
