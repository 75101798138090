import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { PageTitle } from '../components';
import { withConditionalLayout } from '../layouts/ConditionalLayout';
import { useTranslation } from 'react-i18next';
import {
    LAYOUT_TOOGLE_LEFT_MENU_DESKTOP,
    PROGRAMMATIC_LOCATIONS_LOADED,
    PROGRAMMATIC_LOCATIONS_LOADING_CHANGE,
    PROGRAMMATIC_AGENCIES_LOADED, PROGRAMMATIC_BOARD_FORMATS_LOADED, PROGRAMMATIC_VENUE_TYPES_LOADED,
    PROGRAMMATIC_BOARD_FORMAT_COUNTS_LOADED, PROGRAMMATIC_VENUE_TYPE_COUNTS_LOADED,
    PROGRAMMATIC_AGENCY_COUNTS_LOADED, PROGRAMMATIC_INIT, PROGRAMMATIC_RESET,
} from '../helpers/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { ProgrammaticMapConnected } from '../containers/programmatic/ProgrammaticMap';
import ProgrammaticFilters from '../containers/programmatic/ProgrammaticFilters';
import { Box } from '@material-ui/core';
import { lucitApi } from '../services/lucitApi';
import {
    getDigitalBoardFilters, getHasGeos, getHasValidViewport, getBoardFormatOptionsLoaded, getVenueTypeOptionsLoaded, getAgencyOptionsLoaded
} from '../selectors/programmatic';
import { sortString } from '../helpers/sorters';
import { OpenOOHVenueTypeMaxSupportedLevel } from '../helpers/constants';
import ProgrammaticInitialFilter from '../containers/programmatic/ProgrammaticInitialFilter';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles(() => {
    return {
        main: {
            padding: 0,
            height: '100%',
            maxWidth: '100%',
        },
        body: {
            position: 'relative',
            display: 'flex',
            height: '100%',
        },
        mapBox: {
            position: 'relative',
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
        }
    }
});

const ProgrammaticPage = () => {
    const { params } = useRouteMatch();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const viewport = useSelector(state => state.programmatic.viewport);
    const zoom = useSelector(state => state.programmatic.zoom);
    const digitalBoardFilters = useSelector(getDigitalBoardFilters);
    const leftMenuDesktopOpen = useSelector(state => state.layout.leftMenuDesktopOpen);
    const hasGeos = useSelector(getHasGeos);
    const hasValidViewport = useSelector(getHasValidViewport);
    const boardFormatOptionsLoaded = useSelector(getBoardFormatOptionsLoaded);
    const venueTypeOptionsLoaded = useSelector(getVenueTypeOptionsLoaded);
    const agencyOptionsLoaded = useSelector(getAgencyOptionsLoaded);

    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        if (params.lcuid) {
            setLoaded(false);
            lucitApi.getExport(params.lcuid)
                .then(data => dispatch({
                    type: PROGRAMMATIC_INIT,
                    ...data
                }))
                .finally(() => setLoaded(true))

            return () => dispatch({ type: PROGRAMMATIC_RESET })
        }
    }, [params.lcuid])

    useEffect(() => {
        dispatch({ type: LAYOUT_TOOGLE_LEFT_MENU_DESKTOP, leftMenuDesktopOpen: false });
        return () => dispatch({ type: LAYOUT_TOOGLE_LEFT_MENU_DESKTOP, leftMenuDesktopOpen });
    }, [])

    useEffect(() => {
        lucitApi.billboards.getVenueTypes()
            .then(data => {
                dispatch({
                    type: PROGRAMMATIC_VENUE_TYPES_LOADED,
                    venue_types_options: data.filter(vt => vt.level <= OpenOOHVenueTypeMaxSupportedLevel)
                })
            }
            )
    }, []);

    useEffect(() => {
        lucitApi.agencies.getAgencies({ has_digital_boards: 1, per_page: 5000 }) //get them all
            .then(({ agencies, total }) => {
                dispatch({
                    type: PROGRAMMATIC_AGENCIES_LOADED,
                    agency_options: agencies.sort((a, b) => sortString(a.name, b.name)),
                    total_agencies: total
                })
            })
    }, []);

    useEffect(() => {
        lucitApi.billboards.getDigitalBoardFormats()
            .then(data => {
                dispatch({
                    type: PROGRAMMATIC_BOARD_FORMATS_LOADED,
                    board_format_options: Object.keys(data).map(code => ({ ...data[code], code }))
                })
            })
    }, []);

    const loadBillboardLocations = (controller) => {
        const filters = {
            bounds: viewport,
            digital_board_filters: digitalBoardFilters
        };

        dispatch({ type: PROGRAMMATIC_LOCATIONS_LOADING_CHANGE, loading: true });

        lucitApi.billboards.getMapV2(filters, { signal: controller.signal })
            .then(data => dispatch({
                type: PROGRAMMATIC_LOCATIONS_LOADED,
                locations: data.digital_boards,
                filter_match_count_in_bounds: data.filter_match_count_in_bounds
            }))
            .catch(() => { })

    }

    //Reload locations when filters change
    useEffect(() => {
        if (!hasValidViewport)
            return;

        const controller = new AbortController();
        loadBillboardLocations(controller);

        return () => controller.abort();
    }, [digitalBoardFilters.geo, digitalBoardFilters.board_formats, digitalBoardFilters.agencies, digitalBoardFilters.open_ooh_venue_type])

    //Reload locations when viewport changes if we do not have any geos selected
    useEffect(() => {
        if (!hasValidViewport)
            return;

        //Viewport won't change result, if we have geos on the map
        if (hasGeos)
            return;

        const controller = new AbortController();
        loadBillboardLocations(controller);

        return () => controller.abort();
    }, [viewport])

    //Reload locations when viewport changes if we have geos
    useEffect(() => {
        if (!hasValidViewport)
            return;

        const controller = new AbortController();
        loadBillboardLocations(controller);

        return () => controller.abort();
    }, [zoom])

    useEffect(() => {
        if (!boardFormatOptionsLoaded)
            return;

        const controller = new AbortController();

        lucitApi.campaigns.getGroupedBoardCountsDigitalBoardFormats(
            digitalBoardFilters,
            { signal: controller.signal }
        )
            .then(digital_board_formats => {
                dispatch({
                    type: PROGRAMMATIC_BOARD_FORMAT_COUNTS_LOADED,
                    board_format_counts: digital_board_formats
                })
            })

        return () => controller.abort();
    }, [boardFormatOptionsLoaded, digitalBoardFilters.geo, digitalBoardFilters.agencies, digitalBoardFilters.open_ooh_venue_type]);

    useEffect(() => {
        if (!venueTypeOptionsLoaded)
            return;

        const controller = new AbortController();

        lucitApi.campaigns.getGroupedBoardCountsVenueType(
            digitalBoardFilters,
            { signal: controller.signal }
        )
            .then(venue_types => {
                dispatch({
                    type: PROGRAMMATIC_VENUE_TYPE_COUNTS_LOADED,
                    venue_type_counts: venue_types
                })
            })

        return () => controller.abort();
    }, [venueTypeOptionsLoaded, digitalBoardFilters.geo, digitalBoardFilters.agencies, digitalBoardFilters.board_formats]);

    //getGroupedBoardCountsAgencies
    useEffect(() => {
        if (!agencyOptionsLoaded)
            return;

        const controller = new AbortController();

        lucitApi.campaigns.getGroupedBoardCountsAgencies(
            digitalBoardFilters,
            { signal: controller.signal }
        )
            .then(agencies => {
                dispatch({
                    type: PROGRAMMATIC_AGENCY_COUNTS_LOADED,
                    agency_counts: agencies
                })
            })

        return () => controller.abort();
    }, [agencyOptionsLoaded, digitalBoardFilters.geo, digitalBoardFilters.board_formats, digitalBoardFilters.open_ooh_venue_type]);

    return (
        <Container component="main" className={classes.main}>
            <PageTitle title={t("Programmatic")} />
            <Box className={classes.body}>
                {loaded && <ProgrammaticInitialFilter />}
                <ProgrammaticFilters />

                <Box className={classes.mapBox}>
                    <ProgrammaticMapConnected />
                </Box>
            </Box>
        </Container>
    )
}

export { ProgrammaticPage as ProgrammaticPageWithoutLayout }
export default withConditionalLayout(ProgrammaticPage, {
    publicOptions: {
        disablePaper: true, disablePermanentDrawer: true,
        showHomeButton: false, hideSearchToolbar: true
    }
});
