import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IonCard, IonCardContent } from '@ionic/react';
import { isLoggedIn } from '../../selectors/user';
import { Box, Grid, makeStyles } from '@material-ui/core';
import BillboardsMap from '../dashboard/BillboardsMap';
import LCTypography from '../../components/material/LCTypography';
import { formatNumber } from '../../helpers/string';
import { convertBoardArrayToLocationsArray } from '../../selectors/billboard';
import { useState } from 'react';
import ProofLocationDialog from './ProofLocationDialog';

const useStyles = makeStyles(theme => ({
    proofMapContainer: {
        width: '100%',
        height: '300px',
    },
    proofMap: {
        borderRadius: '10px',
        height: '100%',
        boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '10px 10px 0px 0px',
        },
    },
    proofPhotoGridContainer: {
        marginBottom: '20px',
        position: 'relative',
    },
    proofMapDetails: {
        position: 'absolute',
        marginInline: '10px',
        [theme.breakpoints.down('sm')]: {
            marginInline: '0px',
            position: 'relative',
            width: '100%',
        },
    },
    proofMapDetailsCard: {
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
            width: '100%',
            borderRadius: '0px 0px 10px 10px',
        },
    },
    proofMapDetailsContent: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    }
}));

function ProofBillboardMap(props) {
    const { proof } = props;
    const classes = useStyles();

    const [location, selectLocation] = useState(null);

    const locations = useMemo(() => proof && proof.digital_boards
        ? convertBoardArrayToLocationsArray(proof.digital_boards)
        : null, [proof]);

    //If the number of boards with impressions is equal to the total number of boards, show the impressions
    const showImpressions = proof
        .digital_boards
        .filter(board => board.options.avg_daily_impressions > 0)
        .length === proof.digital_boards.length;

    const dailyImpressions = formatNumber(proof.digital_boards.reduce((prev, curr) => prev + curr.options.avg_daily_impressions, 0))

    return <>
        <Grid container className={classes.proofPhotoGridContainer}>
            <Grid item className={classes.proofMapContainer}>
                <BillboardsMap
                    classes={{ map: classes.proofMap }}
                    dontAutoCenterToUser={true}
                    locations={locations}
                    showTargetedOnly={false}
                    highlightTargeted={true}
                    showLastPlay={false}
                    promptGeoPermissions={false}
                    onLocationSelected={location => selectLocation(location)}
                />
            </Grid>
            <Box className={classes.proofMapDetails}>
                <IonCard className={classes.proofMapDetailsCard}>
                    <IonCardContent className={classes.proofMapDetailsContent}>
                        <Box style={{ flex: '1' }}>
                            <LCTypography variant="h6"><strong>Screens:</strong> {formatNumber(proof.digital_boards.length)}</LCTypography>
                        </Box>
                        {showImpressions ?
                            <Box style={{ flex: '1' }}>
                                <LCTypography variant="h6"><strong>Daily Impressions:</strong> &nbsp;
                                    {dailyImpressions}
                                </LCTypography>
                            </Box>
                            : <></>
                        }
                    </IonCardContent>
                </IonCard>
            </Box>
        </Grid>

        <ProofLocationDialog
            proof={proof}
            location={location}

            open={Boolean(location)}
            handleClose={() => selectLocation(null)}
        />
    </>
}

ProofBillboardMap.propTypes = {
    proof: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    }
}

export default connect(
    mapStateToProps
)(
    ProofBillboardMap
);
