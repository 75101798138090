import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import NewStreetViewDialog from './NewStreetViewDialog';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

function AddStreetViewButton({ board, location, open, onClose }) {
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(open);
    const { t } = useTranslation();

    const close = useCallback(() => {
        setIsNewDialogOpen(false);
        if (onClose) {
            onClose();
        }
    }, [onClose])

    useEffect(() => {
        setIsNewDialogOpen(open);
    }, [open]);

    return <>
        {board.street_view_location_id
            && <>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() => setIsNewDialogOpen(true)}>
                    {t('Edit')}
                </Button>
            </>}

        {!board.street_view_location_id
            && <>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setIsNewDialogOpen(true)}>
                    {t('Add Street View')}
                </Button>
            </>}

        {isNewDialogOpen
            && <NewStreetViewDialog
                screen={board}
                location={board.street_view_location || location}
                title={t('Street View for {{name}}', { name: board.name })}
                open={isNewDialogOpen}
                handleClose={close} />}
    </>
}

AddStreetViewButton.propTypes = {
    board: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

export default AddStreetViewButton;
