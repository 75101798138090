import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar, Box, CircularProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction,
    ListItemText, makeStyles, Tooltip, Button, Switch
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Delete, Edit } from '@material-ui/icons';
import { IconButtonLoader } from '../../components/material';
import { getUser, getUserRoles, userIsUser } from '../../selectors/user';
import { canRemoveUsers, getCanInviteRoles, rolesSelector } from '../../selectors/roles';
import { ConfirmationDialog } from '../../components';
import RolesChip from './RolesChip';
import { useTranslation } from 'react-i18next';
import { deleteUser, getUsers } from '../../actions/users';
import { useEffect } from 'react';
import { objectSelector } from '../../selectors/objects';
import { getRoles } from '../../actions/roles';
import { showError } from '../../actions/snackbar';
import i18next from '../../i18n';
import { GenericDialog } from '../../components/modals/GenericDialog';
import LCTypography from '../../components/material/LCTypography';
import AccountAvatar from '../../containers/account/AccountAvatar';
import EditRolesDialog from './EditRolesDialog';

const useStyles = makeStyles((theme) => {
    return {
        secondaryAction: {
            paddingRight: theme.spacing(7.5)
        },
        avatarContainerInlineSmall: {
            width: "12px",
            height: "12px",
            display: "inline-flex",
            marginLeft: "4px",
            marginRight: "4px",
        },
        list: {
            width: '100%',
            maxWidth: 500
        },
        userAccountsList: {
            marginTop: "12px",
            width: "100%",
            overflowX: "clip",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: theme.palette.text.secondary,
            cursor: "pointer",
            '&:hover': {
                textDecoration: "underline"
            }
        },
        removeButton: {
            color: theme.palette.error.main,
            '&:hover': {
                color: theme.palette.error.dark,
            }
        }
    }
}
)

function UsersList(props) {
    const { lcuid, getUsers, getRoles, objectById, rolesById, ...rest } = props;
    const classes = useStyles();

    const object = objectById(lcuid);
    const roles = rolesById(lcuid);

    useEffect(() => {
        Promise.all([
            getUsers(object.lcuid),
            getRoles(object.lcuid)])
    }, [object.lcuid])

    if (object.loadingUsers | object.loadingRoles) {
        return <CircularProgress size={40} />;
    }

    return (<>
        <List className={classes.list}>
            {object.users
                .filter(x => userIsUser(x))
                .map(x => <UserListItem
                    key={x.id}
                    lcuid={lcuid}
                    roles={roles}
                    user={x}
                    users={object.users}
                    getUsers={getUsers}
                    {...rest} />)}
        </List>
    </>);
}

const UserListItem = props => {
    const { lcuid, currentUser, showAccountList, currentUserRoles, user, users, roles, deleteUser, enableRemove = true } = props;

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [removeAccountsWhenDeletingUser, setRemoveAccountsWhenDeletingUser] = useState(true);
    const [removingUser, setRemovingUser] = useState(false);
    const [isEditRoles, setIsEditRoles] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    const userRoles = getUserRoles(user);
    const userHasAccounts = user.accounts && user.accounts.length > 0;

    return (<ListItem classes={{ secondaryAction: classes.secondaryAction }} disableGutters>
        <ListItemIcon>
            <Avatar alt={user.name}
                className={classes.avatarContainer}
                src={user.options.primary_image_public_url} />
        </ListItemIcon>
        <Box maxWidth={400}>
            <ListItemText>
                {user.name}
            </ListItemText>

            <RolesChip
                roles={userRoles}
                rolesDictionary={roles}
            />

            {showAccountList && <UserAccountsList
                user={user}
                deleteUser={(accountLcuid, userId) => {
                    return deleteUser(accountLcuid, userId)
                }}
            />}
        </Box>
        <ListItemSecondaryAction>
            {enableRemove
                && (user.id === currentUser.id || canRemoveUsers(currentUserRoles, roles))
                && users.length !== 1
                && <>
                    <Tooltip title={t('Edit Roles')}>
                        <IconButtonLoader
                            size={24}
                            onClick={() => setIsEditRoles(true)}
                            aria-label={t('edit')}>
                            <Edit />
                        </IconButtonLoader>
                    </Tooltip>
                    <Tooltip title={t('Remove User')}>
                        <IconButtonLoader
                            className={classes.removeButton}
                            size={24}
                            onClick={() => {
                                setConfirmOpen(true);
                            }}
                            aria-label={t('delete')}>
                            <Delete />
                        </IconButtonLoader>
                    </Tooltip>
                </>}

            <EditRolesDialog
                lazy
                lcuid={lcuid}
                user={user}
                roles={userRoles.map(role => ({ id: role, ...roles[role] }))}
                rolesDictionary={getCanInviteRoles(currentUserRoles, roles)}
                open={isEditRoles}
                title={t('Edit {{name}} Roles', { name: user.name })}
                handleClose={() => setIsEditRoles(false)}
            />

            <ConfirmationDialog
                title={t('Remove User')}
                ConfirmButtonText={t('Remove {{name}}?', { name: user.name })}
                dialogProps={{
                    open: confirmOpen,
                    onClose: () => setConfirmOpen(false),
                    keepMounted: false,
                    lazy: true
                }}
                handleCancel={() => setConfirmOpen(false)}
                handleConfirm={() => {
                    setRemovingUser(true);

                    if (removeAccountsWhenDeletingUser && userHasAccounts) {
                        return Promise.all(user.accounts.map(x => deleteUser(lcuid, x.id)))
                            .then(() => deleteUser(lcuid, user.id))
                            .finally(() => {
                                setRemovingUser(false)
                                setConfirmOpen(false)
                            })

                    }
                    else {
                        return deleteUser(lcuid, user.id)
                            .finally(() => {
                                setRemovingUser(false)
                                setConfirmOpen(false)
                            })
                    }
                }}>
                {t('Are you sure you want to remove {{name}}?', { name: user.name })}

                {removingUser && <CircularProgress size={40} />}
                {userHasAccounts && <Box mt={2} ml={2} mb={2}>
                    <Box mt={2} mb={1}>
                        <LCTypography variant="caption" color="textSecondary">
                            This user currently has access to the following accounts:
                        </LCTypography>
                    </Box>
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {user.accounts.map(x => <Box key={x.id} display="flex" alignItems="center" mt={1}>
                            <AccountAvatar account={x} />
                            <Box ml={1}>
                                {x.name}
                                {removeAccountsWhenDeletingUser && <div
                                    style={{ color: "red", fontStyle: "italic", fontSize: "0.6em", opacity: 0.4 }}> (remove)
                                </div>}
                            </Box>
                        </Box>)}
                    </div>
                    <Box mt={2} mb={1}>
                        <div><Switch
                            checked={removeAccountsWhenDeletingUser}
                            onChange={(e) => setRemoveAccountsWhenDeletingUser(e.target.checked)}
                        /> Also remove this user from the accounts above
                        </div>
                    </Box>
                </Box>}
            </ConfirmationDialog>
        </ListItemSecondaryAction>
    </ListItem>)
}

const UserAccountsList = ({ user, deleteUser }) => {

    const [accounts, setAccounts] = useState(user.accounts);

    const classes = useStyles();

    const [showAccountListDiaLog, setShowAccountListDiaLog] = useState(false);

    return <>
        <div
            className={classes.userAccountsList}
            onClick={() => setShowAccountListDiaLog(true)}
        >
            {accounts && accounts.map(x => <span key={x.id}>
                {x.name}
                {accounts.indexOf(x) !== accounts.length - 1 && ", "}
            </span>)}

        </div>

        {showAccountListDiaLog && <UserDialog
            user={user}
            deleteUser={deleteUser}
            open={showAccountListDiaLog}
            onClose={() => setShowAccountListDiaLog(false)}
            onRemoveAccount={(accountLcuid) => {
                setAccounts(accounts.filter(x => x.lcuid !== accountLcuid))
            }}
        />
        }
    </>
}

const UserDialog = props => {
    const { user, deleteUser, open, onClose, onRemoveAccount } = props;

    const [accounts, setAccounts] = useState(user.accounts);

    const classes = useStyles();

    const { t } = useTranslation();

    const removeAccount = (accountLcuid) => {
        return deleteUser(accountLcuid, user.id)
            .then(() => {
                setAccounts(accounts.filter(x => x.lcuid !== accountLcuid))
                onRemoveAccount(accountLcuid)
            })
    }

    return <GenericDialog
        title={t('Account List : ') + user.name}
        dialogProps={{
            open: open,
            onClose: () => onClose(),
            fullWidth: true,
            maxWidth: 'xs'
        }}
        dialogContentProps={{
            className: classes.dialogContent
        }}

        ContentComponent={<Box>
            <LCTypography>
                A list of accounts that {user.name} has access to.
            </LCTypography>

            <Box>
                <List className={classes.list}>
                    {accounts && accounts
                        .map(x => <UserAccountListItem
                            key={x.id}
                            user={user}
                            account={x}
                            deleteUser={(accountLcuid, userId) => {
                                return deleteUser(accountLcuid, userId)
                                    .then(() => {
                                        removeAccount(accountLcuid)
                                    })
                            }}
                        />)}
                </List>
            </Box>
        </Box>}

        ActionsComponent={<>
            <Button onClick={() => onClose()}
                color="primary">
                {t('Close')}
            </Button>
        </>}
    />
}

const UserAccountListItem = props => {
    const { user, account, deleteUser, enableRemove = true } = props;

    const [confirmOpen, setConfirmOpen] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    return (<ListItem classes={{ secondaryAction: classes.secondaryAction }}>
        <ListItemIcon>
            <AccountAvatar
                account={account}
                className={classes.avatarContainer} />
        </ListItemIcon>
        <Box maxWidth={400}>
            <ListItemText>
                {account.name}
            </ListItemText>
        </Box>
        {enableRemove
            && <ListItemSecondaryAction>
                <Tooltip title={t('Remove Account')}>
                    <IconButtonLoader
                        size={24}
                        onClick={() => {
                            setConfirmOpen(true);
                        }}
                        aria-label={t('delete')}>
                        <Delete />
                    </IconButtonLoader>
                </Tooltip>

                <ConfirmationDialog
                    title={t('Remove {{user}} from this Account', { user: user.name })}
                    dialogProps={{
                        open: confirmOpen,
                        onClose: () => setConfirmOpen(false),
                        keepMounted: false,
                        lazy: true
                    }}
                    handleCancel={() => setConfirmOpen(false)}
                    handleConfirm={() => deleteUser(account.lcuid, user.id)
                        .finally(() => setConfirmOpen(false))}>
                    {t('Are you sure you want to remove {{user}} from {{name}}?', {
                        name: account.name,
                        user: user.name
                    })}
                </ConfirmationDialog>
            </ListItemSecondaryAction>}
    </ListItem>)
}

UsersList.propTypes = {
    lcuid: PropTypes.string.isRequired,
    enableRemove: PropTypes.bool,

    currentUserRoles: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {
        objectById: lcuid => objectSelector(state)(lcuid),
        rolesById: lcuid => rolesSelector(state)(lcuid),

        currentUser: getUser(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: lcuid => dispatch(getUsers(lcuid)),
        getRoles: lcuid => dispatch(getRoles(lcuid)),

        deleteUser: (lcuid, userId) => dispatch(deleteUser(userId, lcuid))
            .catch(() => {
                dispatch(showError(i18next.t('Error removing user, please try again')))
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    UsersList
);
