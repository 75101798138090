/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, Box,
    useTheme, useMediaQuery, IconButton, lighten, Stepper, Step,
    StepLabel, StepContent, Grid
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { Dialog, ButtonLoader } from '../../components/material';
import { LassoCanvasImage } from '../../components/lasso-canvas';
import ImageUploadInput from '../../components/inputs/ImageUploadInput';
import { createImage, updateImage } from '../../actions/billboards';
import { pathToString } from '../../helpers/string';
import { transformCoordinates } from '../../helpers/image';
import { showError, showSuccess } from '../../actions/snackbar';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const useStyles = makeStyles(theme => {
    return {
        content: {
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },

        stepper: {
            padding: 0,
            marginTop: 8,
            marginLeft: -8
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        uploadArea: {
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px dashed gray'
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
        },
        previewImage: {
            maxHeight: '100%',
            maxWidth: '100%',
            imageOrientation: 'from-image'
        },
        previewBox: {
            '& .react-transform-component, .react-transform-element': {
                height: '100%'
            }
        }
    }
})

const NewImageDialog = (props) => {
    const { open, screen, screenImage, create, update, title, handleClose, dialogProps } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState({});
    const setValue = useCallback((field, value) => setFields(fields => ({ ...fields, [field]: value })), []);

    const [activeStep, setActiveStep] = React.useState(0);

    const stepProps = {
        fields, setValue,
        next: () => setActiveStep(activeStep + 1)
    };

    const steps = [
        { title: 'Upload Image', content: <UploadImage {...stepProps} /> },
        { title: 'Select Screen', content: <SelectBorder {...stepProps} /> },
        { title: 'Preview', content: <Preview {...stepProps} /> }
    ].filter(step => !props.disabledSteps || !props.disabledSteps.includes(step.title));

    useEffect(() => {
        if (screenImage) {
            setFields({
                image: screenImage.image.options.public_url,
                imageName: screenImage.name,
                coordinates: screenImage.options.overlay_perspective_coordinates.map(cords => ({ x: cords[0], y: cords[1] }))
            })
        }

        if (props.step) {
            const step = steps.indexOf(steps.find(step => step.title === props.step));

            setActiveStep(step);
        }
    }, [screenImage, props.step])

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent className={classes.content}>
                <Stepper activeStep={activeStep} className={classes.stepper} orientation={isXs ? "vertical" : "horizontal"}>
                    {steps
                        .map((step) => (
                            <Step key={step.title}>
                                <StepLabel>{step.title}</StepLabel>

                                {isXs
                                    && <StepContent>
                                        {step.content}
                                    </StepContent>}
                            </Step>
                        ))}
                </Stepper>

                {!isXs
                    && <Box mt={4} mb={2} height="calc(100% - 80px)" overflow="auto">
                        {steps[activeStep].content}
                    </Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                    className={classes.button}
                >
                    Back
                </Button>
                {activeStep < steps.length - 1
                    && <Button
                        disabled={activeStep === 1 && !fields.coordinates}
                        variant="contained"
                        color="primary"
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        className={classes.button}
                    >
                        Next
                    </Button>}
                {activeStep === steps.length - 1
                    && <ButtonLoader
                        color="secondary"
                        submitting={loading}
                        onClick={() => {
                            setLoading(true);

                            if (screenImage) {
                                return update(screen, screenImage, fields.coordinates)
                                    .then(handleClose)
                                    .finally(() => setLoading(false))
                            }

                            return create(screen, fields.image, fields.imageName, fields.coordinates)
                                .then(handleClose)
                                .finally(() => setLoading(false))
                        }}
                        variant="contained">
                        {screenImage
                            ? 'Update'
                            : 'Create'}
                    </ButtonLoader>}
            </DialogActions>
        </Dialog >
    )
}

const UploadImage = (props) => {
    const { fields, setValue, next } = props;
    const inputRef = useRef();
    const classes = useStyles();

    const image = fields.image;

    useEffect(() => {
        if (inputRef.current && !image) {
            inputRef.current.click();
        }
    }, []);

    return <>
        {image
            && <Box mb={1} height="calc(100% - 50px)" display="flex" justifyContent="center">
                <img src={image} alt="Cropped" className={classes.previewImage} />
            </Box>}
        {!image
            && <Box className={classes.uploadArea}
                onClick={() => { inputRef.current.click() }}>
                <LCTypography>Click here to upload</LCTypography>
            </Box>}

        <ImageUploadInput
            ref={inputRef}
            useCamera={false}
            id="imageUpload"
            onSuccess={({ file, fileBase64 }) => {
                setValue('image', fileBase64);
                setValue('imageName', file.name);
                setValue('path', null);

                next();
            }}
            onError={errors => {
                // showError(errors.map(e => <Typography key={e.type}>{e.message}</Typography>))
            }}
            {...props.UploadInputProps}>
            {image
                && <Button
                    component="label"
                    variant="outlined"
                    color="secondary"
                    onClick={() => { inputRef.current.click() }}
                >
                    Change
                </Button>}
        </ImageUploadInput>
    </>
}

const SelectBorder = (props) => {
    const { fields, setValue } = props;
    const classes = useStyles();

    const image = fields.image;

    const onPathChange = useCallback(path => setValue('coordinates', path), [setValue])

    return <>
        <Box height="100%" overflow="hidden">
            <Grid container style={{ height: '100%' }}>
                <Grid item md={12} className={classes.center} style={{ height: '100%' }}>
                    <LassoCanvasImage
                        src={image}
                        path={fields.coordinates}
                        onPathChange={onPathChange}
                        classes={{ previewImage: classes.previewImage }}
                    />
                </Grid>
            </Grid>
        </Box>
    </>
}

const Preview = (props) => {
    const { fields } = props;
    const imageRef = useRef();
    const classes = useStyles();
    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageNaturalSize, setImageNaturalSize] = useState({ width: 0, height: 0 });
    const image = fields.image;

    const path = transformCoordinates(fields.coordinates, imageNaturalSize, imageSize);

    const onLoad = useCallback(() => {
        setImageSize({
            width: imageRef.current.width,
            height: imageRef.current.height
        })
        setImageNaturalSize({
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight
        })

        setLoaded(true);
    }, [])

    return <Box mb={1}
        className={classes.previewBox}
        height="calc(100% - 8px)"
        display="flex"
        justifyContent="center">
        <TransformWrapper>
            <TransformComponent >
                <Box display="flex" position="relative" height="100%">
                    <img src={image}
                        ref={imageRef}
                        onLoad={onLoad}
                        className={classes.previewImage}
                    />

                    {loaded
                        && <svg className={classes.previewImage}
                            style={{
                                position: 'absolute',
                                width: imageSize.width,
                                height: imageSize.height
                            }}
                        >
                            <polygon
                                fill='#aaFFaa'
                                stroke={theme.palette.primary.main}
                                points={pathToString(path)} />
                        </svg>}
                </Box>
            </TransformComponent>
        </TransformWrapper>
    </Box>
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapStateToDispatch = (dispatch) => {
    return {
        create: (screen, file, fileName, coordinates) => dispatch(createImage(screen, file, fileName, coordinates))
            .then(() => dispatch(showSuccess("Digital Screen image was successfully uploaded")))
            .catch(error => {
                dispatch(showError("Error during uploading Digital Screen image"))
                throw error;
            }),

        update: (screen, screenImage, coordinates) => dispatch(updateImage(screen, screenImage, coordinates))
            .then(() => dispatch(showSuccess("Digital Screen image was successfully updated")))
            .catch(error => {
                dispatch(showError("Error during updating Digital Screen image"))
                throw error;
            }),
    }
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    NewImageDialog
)
