import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import clx from 'classnames';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandMore';
import { Link, Box, Grid, makeStyles, MenuItem, Typography, useTheme, IconButton } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { getPlaysByItem } from '../../actions/analytics';
import { Global, RoutePaths } from '../../helpers/constants';
import { sortNumber } from '../../helpers/sorters';
import TextField from '../../components/inputs/TextField';
import Tooltip from '../../components/material/Tooltip';
import ItemRow from './itemsTable/ItemRow';
import ItemDetailsCollapsable from './itemsTable/ItemDetailsCollapsable';
import ItemCell from './itemsTable/ItemCell';
import { isItemSold, showSoldBannerOnItem } from '../../selectors/inventory';
import ImageSold from '../../components/images/ImageSold';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { DateRangeInput } from '../../components/inputs/DateRangeInput'

const useStyles = makeStyles(theme => {
    return {
        image: {
            height: '50px',
        },

        itemTitle: {
            color: theme.palette.secondary.main,
            fontWeight: 'bold'
        },

        itemTitleSold: {
            color: theme.palette.primary.main,
            fontWeight: 'normal',
        },

        soldText: {
            ...theme.typography.caption
        }
    }
})

function PlaysStatsByItem(props) {
    const { timeframe, setTimeframe, timeframes, getPlaysByItem } = props;
    const theme = useTheme();
    const classes = useStyles();
    const tableRef = useRef();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const { t } = useTranslation();

    // https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
    const [columns, setColumns] = useState([]);

    const [dateRange, setDateRange] = useState({
        "startDate": new Date(new Date().setDate(new Date().getDate() - 30)),    //30 days ago
        "endDate": new Date()
    })

    const getTimeframeOpts = () => {

        return {
            "start_date": dateRange.startDate,
            "end_date": dateRange.endDate
        }
    }

    const showDateSelector = () => timeframe === "custom"

    const handleExpandToggle = useCallback(rowData => {
        setData(data.map(x => {
            if (x.tableData.id === rowData.tableData.id) {
                x.tableData.isExpanded = !x.tableData.isExpanded
            }
            else {
                x.tableData.isExpanded = false;
            }

            return x;
        }));
    }, [data, setData])

    useEffect(() => {
        setColumns([
            {
                filtering: false,
                sorting: false,
                width: 24,
                cellStyle: () => {
                    return {
                        padding: theme.spacing(2, 1)
                    };
                },
                // eslint-disable-next-line react/display-name
                render: rowData => {
                    return <>
                        {!rowData.tableData.isExpanded
                            && <IconButton size="small" onClick={() => handleExpandToggle(rowData)}>
                                <ExpandMoreIcon />
                            </IconButton>}
                        {rowData.tableData.isExpanded
                            && <IconButton size="small" onClick={() => handleExpandToggle(rowData)}>
                                <ExpandLessIcon />
                            </IconButton>}
                    </>
                }
            },
            {
                title: t('Item'),
                filtering: false,
                sorting: false,
                // width: 200,
                // eslint-disable-next-line react/display-name
                render: x =>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>
                            <Tooltip hidden={!isItemSold(x)}>
                                <Link
                                    component={RouterLink}
                                    to={RoutePaths.inventory + `/${x.object_id}`}>
                                    <ImageSold
                                        isSold={isItemSold(x) && showSoldBannerOnItem(x)}
                                        classes={{
                                            root: classes.image,
                                            image: classes.avatarImage,
                                            soldText: classes.soldText
                                        }}
                                        imageProps={{
                                            alt: x.object_name,
                                            src: x.object_creatives[0].object_image,
                                            title: x.object_name,
                                        }}

                                        barWidth={20}
                                        barLength={75}
                                    />
                                </Link>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={"auto"}>
                            <Tooltip hidden={!isItemSold(x)}>
                                <Link component={RouterLink} to={RoutePaths.inventory + `/${x.object_id}`}>
                                    <Typography variant="body2" className={clx(classes.itemTitle, {
                                        [classes.itemTitleSold]: isItemSold(x)
                                    })}>
                                        {x.object_name}
                                    </Typography>
                                </Link>
                            </Tooltip>
                        </Grid>
                    </Grid>,
            },
            {
                title: t('Statistics'),
                filtering: false,
                customSort: (a, b) => sortNumber(a.total_plays, b.total_plays),
                // eslint-disable-next-line react/display-name
                render: x => <>
                    {
                        x.total_impressions > 0 ?
                            <>
                                <LCTypography transProps={{ xTotalImpressions: x.total_impressions.toLocaleString() }} variant="body2">
                                    {{ xTotalImpressions: x.total_impressions.toLocaleString() }} Impressions
                                </LCTypography>
                                <Typography component="p" variant="caption" color="textSecondary">
                                    {t('with')} {moment.duration(x.total_play_duration, 'seconds').format("hh:mm:ss", { trim: false })} {t('Time On Screen')}
                                </Typography>
                                <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }} component="p" variant="caption" color="textSecondary">
                                    via {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                                </LCTypography>
                            </>
                            : <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }} variant="body2">
                                {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                            </LCTypography>

                    }
                </>
            },
        ])

    }, [classes, theme, handleExpandToggle])

    useEffect(() => {

        const controller = new AbortController();

        if (timeframe) {
            setLoading(true);

            getPlaysByItem(timeframe, getTimeframeOpts(), { signal: controller.signal })
                .then(data => setData(data))
                .finally(() => setLoading(false))
        }

        return () => controller.abort();
    }, [timeframe, getPlaysByItem, dateRange])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t('Timeframes')}
                        input={{
                            onChange: (e) => setTimeframe(e.target.value),
                            value: timeframe
                        }}
                        select={true}
                        fullWidth={true}
                        variant={"outlined"}
                    >
                        {timeframes.map(t => (
                            <MenuItem key={t.timeframe} value={t.timeframe}>
                                {t.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    {showDateSelector() &&
                        <DateRangeInput

                            meta={{}}
                            input={{
                                value: dateRange,
                                onChange: (value) => setDateRange(value)
                            }}
                            fullWidth
                            mobilePickerProps={{
                                disablePast: true,
                                autoOk: true,
                                emptyLabel: 'No End Date',
                                // eslint-disable-next-line react/display-name
                            }}
                            pickerProps={{
                                showMonthAndYearPickers: false,
                                showDateDisplay: false,
                                months: 2,
                                direction: "horizontal",
                            }}
                            format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                                ? "MMM DD"
                                : "MMM DD, YYYY"}
                        />
                    }

                </Grid>
            </Grid>

            <Box mt={3} />

            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                isLoading={loading}
                data={data}
                components={{
                    OverlayLoading: props => (
                        <div>
                            <CircularProgressCentered {...props} />
                        </div>
                    ),
                    // eslint-disable-next-line react/display-name
                    Row: ItemRow,
                    Cell: ItemCell,
                    DetailPanel: ItemDetailsCollapsable
                }}
                options={{
                    emptyRowsWhenPaging: true,
                    pageSize: Global.exportItemListSize,
                    pageSizeOptions: [5, Global.exportItemListSize, 50],
                    debounceInterval: 100,
                    headerStyle: { backgroundColor: theme.palette.grey[50] },
                    paging: data.length > 100,
                    search: false,
                    showTitle: false,
                    sorting: true,
                    selection: false,
                    toolbar: false,
                    draggable: false,
                    tableLayout: 'auto',

                    rowStyle: item => {
                        return {
                            background: isItemSold(item)
                                ? theme.palette.action.selected
                                : null
                        };
                    }
                }}
            />
        </Box>
    );
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPlaysByItem: (timeframe, timeframe_opts) => dispatch(getPlaysByItem(timeframe, timeframe_opts))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PlaysStatsByItem)
    );
