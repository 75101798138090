import { Avatar, Grid, Typography, makeStyles, Box, Link as MaterialLink, Divider, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getInitials } from '../../helpers/string';
import { hasIcon } from '../../selectors/account';
import ApplicationStatusBadge from './ApplicationStatusBadge';
import ReactMarkdown from 'react-markdown'
import YouTubeVideo from '../../components/video/YouTubeVideo';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import LCTypography from '../../components/material/LCTypography';
import { isLoggedIn } from "../../selectors/user";
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card, CardActionArea, CardContent, CardMedia,
    GridList, GridListTile, Dialog, DialogContent
} from '@material-ui/core';
import FlareIcon from '@material-ui/icons/Flare';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },
        shareBtn: {
            // height: 36
        },
        cardWrapper: {
            height: '100%',
            width: '100%'
        },
        cardSelected: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        creativeTile: {
            padding: theme.spacing(1.5),
            paddingLeft: 0,
            display: 'flex',
            justifyContent: 'center'
        }
    }
})

const ApplicationTemplatesListCard = ({ template }) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <><Card className={classes.cardWrapper}>
        <CardActionArea
            onClick={handleClickOpen}
        >
            <CardMedia
                component="img"
                height={70}
                style={{
                    objectFit: "contain",
                }}
                image={
                    template.options.primary_image_public_url
                        ? template.options.primary_image_public_url
                        : "/assets/designer/empty-drive-template.png"
                }
                title={template.name}
            />
            <CardContent>
                <LCTypography gutterBottom variant="subtitle2" component="p" noWrap>
                    {template.name}
                </LCTypography>
            </CardContent>
        </CardActionArea>
    </Card>

        <Dialog open={open} onClose={handleClose} width="md" maxWidth={true}>
            <DialogContent className={classes.dialogContent}>
                <img
                    src={
                        template.options.primary_image_public_url
                            ? template.options.primary_image_public_url
                            : '/assets/designer/empty-drive-template.png'
                    }
                    alt={template.name}
                    className={classes.dialogImage}
                    style={{ width: '100%', height: 'auto' }}
                />
            </DialogContent>
        </Dialog>

    </>

}

const ApplicationTemplatesList = ({ application }) => {

    const [driveTemplates, setDriveTemplates] = useState([]);
    const classes = useStyles();

    const isAuthenticated = useSelector(isLoggedIn);

    useEffect(() => {
        lucitApi.applications.driveTemplates(application.lcuid, isAuthenticated).then((driveTemplates) => {
            setDriveTemplates(driveTemplates);
        })
    }, [application.lcuid]);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    if (!driveTemplates.length) return null;

    return <Box mt={2}>

        <LCTypography variant="h5">Sample Templates</LCTypography>
        <Box mt={1}>
            <LCTypography variant="body2">
                The <strong>{application.name}</strong> comes with the following
                free and <i>fully customizable</i> sample digital billboard template{driveTemplates.length > 1 ? 's' : ''}
            </LCTypography>
        </Box>

        <Box mt={2}>

            <GridList
                cols={isXs ? 1 : 3}
                cellHeight={'auto'}>

                {driveTemplates.map((template) => {
                    return <GridListTile
                        key={template.lcuid}
                        classes={{ tile: classes.creativeTile }}>
                        return <ApplicationTemplatesListCard template={template} />
                    </GridListTile>
                })}
            </GridList>
        </Box>

        <Box mt={2}>
            <LCTypography variant="body2"><strong>Build your Own Creatives</strong></LCTypography>
            <LCTypography variant="body2">
                Or, you can build your own from scratch using the Lucit Dynamic template Tools!  Learn
                more at <MaterialLink
                    color="secondary"
                    href="https://www.lucit.cc/dynamic-creative-tools"
                > Dynamic OOH Creative Tools
                </MaterialLink> or get started designing now
                at <MaterialLink
                    component={RouterLink}
                    color="secondary"
                    to="/designer">
                    Lucit Template Designer
                </MaterialLink>
            </LCTypography>
        </Box>

    </Box>

}

function ApplicationProfile(props) {
    const { application, helpMode } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    if (!application) return null;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return <Grid container spacing={2}>

        {!helpMode && <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {hasIcon(application)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={application.options.primary_image_public_url}
                        className={classes.image}
                        alt={application.name}
                        title={application.name}
                    />
                </Avatar>
                : <Avatar className={classes.avatar}>
                    {getInitials(application.name)}
                </Avatar>}
        </Grid>
        }

        <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column' }}>

            {!helpMode && <Typography variant="h5">{application.name}</Typography>}

            {!helpMode && <Box>
                <ApplicationStatusBadge application={application} hideBadgeIfActive />
            </Box>
            }

            {application.organization_name && <Box mt={1}>
                <Typography variant="subtitle2">{application.organization_name}</Typography>
            </Box>}

            {application.website && <Box mt={1}>
                <Typography variant="body2">
                    <MaterialLink
                        href={application.website}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {application.website}
                    </MaterialLink>
                </Typography>

            </Box>}

        </Grid>
        <Grid item xs={12}>

            <Box p={0}>

                {application.description
                    && <Box mt={3}>
                        <ReactMarkdown>{application.description}</ReactMarkdown>
                    </Box>}

                {Boolean(application.premium) && <Box>
                    <Box display="flex" alignItems="center" mb={1} mt={2}>
                        <FlareIcon style={{ color: "#4caf50" }} />
                        <Box ml={1} style={{ fontWeight: "bold", color: "#4caf50" }}>Premium App</Box>

                    </Box>

                    <Box ml={1} mt={2}>
                        <ReactMarkdown>
                            {application.premium_fees_description}
                        </ReactMarkdown>
                    </Box>

                </Box>

                }

                {application.help_url && <Box mt={1}>
                    <MaterialLink
                        href={application.help_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <strong>{t('Help & Support')}</strong> : {application.help_url}
                    </MaterialLink>
                </Box>}

                {application.video_url && <Box mt={6} mb={3}>

                    <YouTubeVideo
                        url={application.video_url}
                        youTubeProps={{
                            style: {
                                maxWidth: '100%',
                            },
                            opts: {
                                width: isXs ? '100%' : '580',
                                height: isXs ? '200' : '400',
                            }
                        }}
                    />

                </Box>}

                <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

                <ApplicationTemplatesList application={application} />

                <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

                {!helpMode && <Box mt={2}>
                    <Typography variant="caption">
                        To add this app to your account, visit your <MaterialLink
                            component={RouterLink}
                            to="/settings"
                            color="secondary"
                            style={{ cursor: "pointer" }}
                        >
                            account settings
                        </MaterialLink>
                        <strong> Apps &amp; Data Sources</strong>
                    </Typography>
                </Box>}

            </Box>
        </Grid>

        {!helpMode &&
            <Grid item xs={12}>
                <Box mt={4} mb={4}>
                    <MaterialLink component={RouterLink} to="/apps"
                        style={{ cursor: "pointer" }} color="secondary">&lt; View all Apps</MaterialLink>
                </Box>
            </Grid>
        }
    </Grid>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        user: state.user,
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    ApplicationProfile
);
