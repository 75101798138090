import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Divider, AccordionSummary, AccordionDetails, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import LCTypography from '../../components/material/LCTypography';
import { withRouter } from 'react-router';
import { getEnvironment } from '../../actions/environment';
import ButtonLoader from '../../components/material/ButtonLoader';
import { showError } from '../../actions/snackbar';
import { systemInfo, versionFieldId } from '../../selectors/environment';
import { useEffect } from 'react';
import { getUser } from '../../selectors/user';
import { showSystemNotification } from '../../actions/systemNotification';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },

        value: {
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        headerIcon: {
            marginBottom: -3
        }
    }
});

function SystemInfo(props) {
    const { user, handleCheckUpdate, systemInfo, getEnvironment } = props;
    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkingUpdates, setCheckingUpdates] = useState(false);

    useEffect(() => {
        setLoading(true);

        getEnvironment()
            .finally(() => setLoading(false))
    }, [getEnvironment])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="SystemInfo-content"
                id="SystemInfo-header"
            >
                <LCTypography variant="h5" ><SettingsIcon className={classes.headerIcon} /> System</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                {loading && <CircularProgressCentered size={40} />}

                {!loading
                    && <Grid container spacing={2}>
                        {systemInfo
                            .map((info, index) => <Grid key={index} container item xs={12}>
                                <Grid item xs={6} md={2}>
                                    <LCTypography transProps={{ infoLabel: info.label }}>{{ infoLabel: info.label }}</LCTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LCTypography transProps={{ infoValue: info.value }}
                                        className={classes.value}>
                                        {{ infoValue: info.value }}
                                    </LCTypography>

                                    {info.id === versionFieldId
                                        && user.handleCheckUpdate
                                        && !user.hasUpdate
                                        && <ButtonLoader
                                            submitting={checkingUpdates}
                                            onClick={() => {
                                                setCheckingUpdates(true);
                                                handleCheckUpdate(user)
                                                    .finally(() => setCheckingUpdates(false));
                                            }}
                                            size="small"
                                            variant="contained"
                                            color="secondary">Check Updates</ButtonLoader>}
                                    {info.id === versionFieldId
                                        && user.hasUpdate
                                        && <Button
                                            onClick={user.handleUpdate}
                                            size="small"
                                            variant="contained"
                                            color="secondary">Update</Button>}
                                </Grid>
                            </Grid>)}

                        <Grid container item xs={12}>
                            <Grid item xs={6} md={2}>
                                <LCTypography>FCM Token</LCTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.value}>
                                    {user.tokenPushNotifications}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>}
            </AccordionDetails>
        </AccordionScrollable >
    );
}

const mapStateToProps = (state) => {
    return {
        systemInfo: systemInfo(state),
        user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEnvironment: () => {
            return dispatch(getEnvironment())
                .catch(error => {
                    if (error.isOffline) {
                        throw error;
                    }

                    dispatch(showError("Error during loading system info..."));
                    throw error;
                });
        },

        handleCheckUpdate: user => user.handleCheckUpdate()
            .then((hasUpdate) => {
                if (!hasUpdate) {
                    dispatch(showSystemNotification('checkUpdate', 'You are using latest version', { duration: 5 * 1000 }));
                }
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                dispatch(showError('Something went wrong...', { duration: 5 * 1000 }));
            })
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(SystemInfo)
    );
