import { default as UserSignedUpStory } from './UserSignedUpStory';
import { default as PostedCreativeItemStory } from './PostedCreativeItemStory';
import { default as CommentCreatedStory } from './CommentCreatedStory';
import { default as ReactionCreatedStory } from './ReactionCreatedStory';
import { default as AgencyTeamUserSignedUpStory } from './AgencyTeamUserSignedUpStory';
import { default as AgencyTeamAccountCreatedStory } from './AgencyTeamAccountCreatedStory';
import { default as AgencyTeamUserInvitedSomeoneStory } from './AgencyTeamUserInvitedSomeoneStory';
import { default as AgencyTeamUserVisitedStory } from './AgencyTeamUserVisitedStory';
import { default as AgencyTeamCreativeProofCreatedStory } from './AgencyTeamCreativeProofCreatedStory';
import { default as AgencyTeamDriveTemplateCreatedStory } from './AgencyTeamDriveTemplateCreatedStory';
import { default as AgencyTeamApplicationAttachedStory } from './AgencyTeamApplicationAttachedStory';

export const StoryClassMap = {
    "App\\LuCore\\FeedStories\\Layout\\UserSignedUpStoryClass": UserSignedUpStory,
    "App\\LuCore\\FeedStories\\Layout\\PostedCreativeItemStoryClass": PostedCreativeItemStory,
    "App\\LuCore\\FeedStories\\Layout\\CommentCreatedStoryClass": CommentCreatedStory,
    "App\\LuCore\\FeedStories\\Layout\\ReactionCreatedStoryClass": ReactionCreatedStory,

    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamUserSignedUpStoryClass": AgencyTeamUserSignedUpStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamAccountCreatedStoryClass": AgencyTeamAccountCreatedStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamUserInvitedSomeoneStoryClass": AgencyTeamUserInvitedSomeoneStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamUserVisitedStoryClass": AgencyTeamUserVisitedStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamCreativeProofCreatedStoryClass": AgencyTeamCreativeProofCreatedStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamDriveTemplateCreatedStoryClass": AgencyTeamDriveTemplateCreatedStory,
    "App\\LuCore\\FeedStories\\AgencyTeam\\AgencyTeamApplicationAttachedStoryClass": AgencyTeamApplicationAttachedStory,

}
