import { lucitApi } from "../services/lucitApi";
import { isAllAgencySelected, selectedAgency, agencies, getUser } from "../selectors/user";
import {
    AGENCY_USER_TRUST_CHANGED, AGENCY_APPROVAL_SETTINGS_CHANGED, APPROVALS_COUNT_RESULT,
    AGENCY_UPDATE, USER_AGENCY_REMOVE_IMAGE, USER_AGENCY_SET_IMAGE, AGENCY_DELETE, AGENCY_CAMPAIGNS,
    CAMPAIGNS_PENDING_TRAFFIC_COUNT_RESULT,
    AGENCY_ACCOUNTS,
    AGENCY_ACCOUNTS_REQUEST,
    AGENCY_UNREAD_STORIES
} from "../helpers/actionTypes";
import { uploadImage } from "./uploads"
import { selectedAgencyIds } from "../selectors/user"

export function getAgencyAccounts(agencyId) {
    return dispatch => {
        dispatch({ type: AGENCY_ACCOUNTS_REQUEST })

        return lucitApi.agencies.getAccounts(agencyId)
            .then(accounts => dispatch({ type: AGENCY_ACCOUNTS, accounts }))
    }
}

export function getCampaigns(id, filters) {
    return (dispatch) => {
        return lucitApi.agencies.getCampaigns(id, filters)
            .then(campaigns => {
                dispatch({
                    type: AGENCY_CAMPAIGNS,
                    id,
                    campaigns
                })
                return campaigns;
            });
    }
}

export function getAgencies() {
    return () => {
        return lucitApi.agencies.getAgencies()
            .then(x => x.agencies);
    }
}

export function setPrimaryImage(fileBase64) {
    return (dispatch, getState) => {
        return dispatch(uploadImage(fileBase64))
            .then(image => {
                const agencyIds = selectedAgencyIds(getState());
                const agencyId = agencyIds[0];

                return lucitApi.agencies.setPrimaryImage(agencyId, image.id)
                    .then(agency => dispatch({
                        type: USER_AGENCY_SET_IMAGE,
                        agencyId,
                        options: agency.options
                    }))
            })
    }
}

export function deletePrimaryImage() {
    return (dispatch, getState) => {

        const agencyIds = selectedAgencyIds(getState());
        const agencyId = agencyIds[0];

        return lucitApi.agencies.deletePrimaryImage(agencyId)
            .then(() => dispatch({
                type: USER_AGENCY_REMOVE_IMAGE,
                agencyId
            }))
    }
}

export function changeAgencyDetails(name, description, website, proof_legal_text, support_text) {
    return (dispatch, getState) => {

        const agencyIds = selectedAgencyIds(getState());
        const agencyId = agencyIds[0];

        return lucitApi.agencies.update(agencyId, name, description, website, proof_legal_text, support_text)
            .then((agency) => dispatch({
                type: AGENCY_UPDATE,
                agency: agency
            }))
    }
}

export function getAgencyDigitalBoardFormats(agencyId) {
    return lucitApi.agencies.getDigitalBoardFormats(agencyId);
}

export function loadApprovalsCount() {
    return (dispatch) => {

        return lucitApi.agencies.getApprovalsCount()
            .then(data => dispatch({
                type: APPROVALS_COUNT_RESULT,
                data
            }));
    }
}

export function loadPendingTrafficCount(agencyId) {
    return (dispatch) => {
        return lucitApi.agencies.getCampaignsPendingTrafficCount(agencyId)
            .then(campaigns_pending_traffic_count => dispatch({
                type: CAMPAIGNS_PENDING_TRAFFIC_COUNT_RESULT,
                campaigns_pending_traffic_count
            }));
    }
}

export function trustUser(userId) {
    return (dispatch, getState) => {

        const user = getUser(getState());

        if (isAllAgencySelected(user)) {
            agencies(user).forEach(agency => {
                return lucitApi.agencies.trustUser(agency.id, userId)
            })
        }
        else {
            lucitApi.agencies.trustUser(selectedAgency(getState()).id, userId)
        }

        return dispatch({
            type: AGENCY_USER_TRUST_CHANGED,
            userId: userId,
            trusted: true
        })
    }
}

export function revokeUserTrust(userId) {
    return (dispatch, getState) => {

        const user = getUser(getState());

        if (isAllAgencySelected(user)) {
            agencies(user).forEach(agency => {
                return lucitApi.agencies.revokeUserTrust(agency.id, userId)
            })
        }
        else {
            lucitApi.agencies.revokeUserTrust(selectedAgency(getState()).id, userId)
        }

        return dispatch({
            type: AGENCY_USER_TRUST_CHANGED,
            userId: userId,
            trusted: false
        })
    }
}

export function setApprovalSettings(settings) {
    return (dispatch, getState) => {

        const user = getUser(getState());

        if (isAllAgencySelected(user)) {
            return Promise.resolve();  //don't do anything
        }

        //Legacy settintgs, see T6428
        settings.auto_reject_moderation_score = settings.ai_auto_reject_score;
        settings.auto_reject_ads_from_untrusted_users = settings.ai_auto_reject_untrusted;

        return lucitApi.agencies.setApprovalSettings(selectedAgency(getState()).id, settings)
            .then(() => {
                dispatch({
                    type: AGENCY_APPROVAL_SETTINGS_CHANGED,
                    agencyId: selectedAgency(getState()).id,
                    settings: settings
                })
            })
    }
}

export function deleteAgency(agency) {
    return dispatch => {
        return lucitApi.objects.delete(agency.lcuid)
            .then(() => dispatch({
                type: AGENCY_DELETE,
                agency
            }))
    }
}

export function loadAgencyStoriesCount(agencyId) {
    return (dispatch) => {
        return lucitApi.stories
            .getAgencyStoryCount(agencyId)
            .then(count => dispatch({ type: AGENCY_UNREAD_STORIES, count }))
    }
}
