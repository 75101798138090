import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { RoutePaths, BillboardIcon } from '../../helpers/constants';
import { getItem } from '../../actions/inventory';
import { getExport } from '../../actions/exports';
import { getLocationById } from '../../actions/locations';
import { Map } from '../../components/google-maps';
import BillboardThumbnail from './BillboardThumbnail';
import { AgenceNamePhoto } from '../../components/agency';
import { getUser, hasAgency } from '../../selectors/user';
import BillboardStreetView from './BillboardStreetView';
import CreateProofButton from '../../containers/proofs/CreateProofButton';

const useStyles = makeStyles(() => {
    return {
        imageGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        image: {
            width: '100%'
        },

        map: {
            width: '100%',
            height: '300px',
        },
        svPlaceholder: {
            height: 'auto'
        }
    }
})

function BillboardProfile(props) {
    const { agency, board, getLocationById, hasAgency } = props;
    const classes = useStyles();
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (props.location) {
            setLocation(props.location)
            return;
        }

        if (board && board.location_id) {
            getLocationById(board.location_id)
                .then(setLocation)
        }
    }, [getLocationById, board, props.location])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} className={classes.imageGrid}>
                <BillboardStreetView
                    interact={true}
                    classes={{ root: classes.map, rootPlaceholder: classes.svPlaceholder }}
                    board={board}
                    location={location} />
                {location
                    && <Map
                        classes={{
                            map: classes.map
                        }}
                        locations={[{
                            ...location,
                            targeted: true
                        }]}
                        showCenterButton={false}
                        showTargetedButton={false}
                    />}
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2} display="flex" alignItems="center">
                    <Box component={BillboardIcon} mr={2} />
                    <LCTypography transProps={{ boardName: board.name }} variant="h6">{{ boardName: board.name }}</LCTypography>
                </Box>
                {[
                    {
                        hidden: agency == null,
                        title: <LCTypography variant="body1">Operator:</LCTypography>,
                        value: <AgenceNamePhoto agency={agency} isLink={true} />
                    },
                    {
                        title: <LCTypography variant="body1">Identifier:</LCTypography>,
                        value: <LCTypography
                            transProps={{ boardIdentifier: board.board_identifier }}>
                            {{ boardIdentifier: board.board_identifier }}</LCTypography>
                    },
                    {
                        title: <LCTypography variant="body1">Size:</LCTypography>,
                        value: <Box width="fit-content">
                            <BillboardThumbnail
                                showAsOneLine={true}
                                showPixelsSuffix={true}
                                board={board}
                                boardScale={20}
                                fontSize={14}
                            />
                        </Box>
                    },
                    {
                        title: <LCTypography variant="body1">Location:</LCTypography>,
                        value: location && <LCTypography
                            transProps={{ locationName: location.name }}>
                            {{ locationName: location.name }}</LCTypography>
                    },
                    {
                        title: <LCTypography variant="body1"></LCTypography>,
                        value: <>
                            <CreateProofButton billboard={board} location={board.location} />
                            {hasAgency(agency.id)
                                && <Button
                                    variant="contained"
                                    color="secondary"
                                    component={RouterLink}
                                    to={RoutePaths.screen(board.id)} >Manage</Button>
                            }</>
                    }
                ]
                    .filter(x => !x.hidden)
                    .map(({ title, value, titleGridProps, valueGridProps }, index) => (
                        <Grid key={index} container spacing={3} alignItems="flex-start" wrap="nowrap">
                            <Grid item style={{ width: 100 }} {...titleGridProps}>
                                {title}
                            </Grid>
                            <Grid item xs {...valueGridProps}>
                                {value}
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    )
}

BillboardProfile.propTypes = {
    board: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        hasAgency: hasAgency(getUser(state)),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getInventoryItem: id => dispatch(getItem(id)),
        getExport: id => dispatch(getExport(id)),
        getLocationById: id => dispatch(getLocationById(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BillboardProfile
);
