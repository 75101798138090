import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { AppBar, Tabs, Tab, Paper, Box } from '@material-ui/core';

import PlaysStatsByPeriod from './PlaysStatsByPeriod';
import PlaysStatsByCampaign from './PlaysStatsByCampaign';
import PlaysStatsByBillboard from './PlaysStatsByBillboard';
import { QueryString } from '../../helpers/constants';
import { useQueryString } from '../../helpers/hooks/useQueryString';
import { replaceQueryString } from '../../helpers/url';
import PlaysStatsByItem from './PlaysStatsByItem';

import { Periods, Timeframe } from "../../helpers/constants";

const tab = {
    period: "period",
    ad: "ad",
    creatives: "creatives",
    campaign: "campaign",
    billboard: "billboard",
}

export { tab as AnalyticsTab }

const tabs = [
    {
        title: "Period",
        value: tab.period,
        Component: PlaysStatsByPeriod
    },
    // {
    //     title: "Ad",
    //     value: tab.ad,
    //     Component: PlaysStatsByAd
    // },
    {
        title: "Creatives",
        value: tab.creatives,
        Component: PlaysStatsByItem
    },
    {
        title: "Campaigns",
        value: tab.campaign,
        Component: PlaysStatsByCampaign
    },
    {
        title: "Screens",
        value: tab.billboard,
        Component: PlaysStatsByBillboard
    }
];

function PlaysStats(props) {
    const { timeframes, periods } = props;
    const history = useHistory();
    const initialTab = useQueryString(QueryString.tab, tab.period);
    const [activeTab, setActiveTab] = useState(initialTab);

    const [allowedPeriods, setAllowedPeriods] = useState(periods);
    const [period, setPeriod] = useState(Periods.daily);
    const [timeframe, _setTimeframe] = useState(Timeframe.thisWeek);

    const setTimeframe = useCallback((newValue) => {
        const timeframe = timeframes.find(z => z.timeframe === newValue);
        const allowedPeriods = periods.filter(p => timeframe.allowable_periods.indexOf(p.period) > -1);

        setAllowedPeriods(allowedPeriods);
        _setTimeframe(newValue);

        if (allowedPeriods.every(p => p.period !== period)) {
            setPeriod(allowedPeriods[0].period);
        }
    }, [timeframes, periods, period])


    return (
        <Paper>
            <AppBar position="static" color="default">
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => {
                        setActiveTab(value);
                        replaceQueryString(history, QueryString.tab, value);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    {tabs.map(tab => <Tab key={tab.title} label={tab.title} value={tab.value} />)}
                </Tabs>
            </AppBar>
            <Box p={2} mt={2} mb={2}>
                {tabs.map(({ title, value, Component }) => (
                    activeTab === value
                    && <Component
                        key={title}
                        {...{
                            timeframe,
                            setTimeframe,
                            period,
                            setPeriod
                        }}
                        {...props}
                        periods={allowedPeriods} />
                ))}
            </Box>
        </Paper>
    );
}

export default PlaysStats;
