import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import { InventoryItemClasses } from '../../../helpers/constants';
import InventoryItemDetailsArticle from './InventoryItemDetailsArticle';
import InventoryItemDetailsAutomotive from './InventoryItemDetailsAutomotive';
import InventoryItemDetailsChildCampaign from './InventoryItemDetailsChildCampaign';
import InventoryItemDetailsCreative from './InventoryItemDetailsCreative';
import InventoryItemDetailsDefaultFallback from './InventoryItemDetailsDefaultFallback';
import InventoryItemDetailsGeneric from './InventoryItemDetailsGeneric';
import InventoryItemDetailsPhotoStream from './InventoryItemDetailsPhotoStream';
import InventoryItemDetailsRealEstate from './InventoryItemDetailsRealEstate';
import InventoryItemDetailsRealEstateAgent from './InventoryItemDetailsRealEstateAgent';
import InventoryItemDetailsReview from './InventoryItemDetailsReview';
import InventoryItemDetailsJobPost from './InventoryItemDetailsJobPost';
import InventoryItemDetailsSocialPost from './InventoryItemDetailsSocialPost';

function InventoryItemDetails(props) {
    const { itemClass, ...rest } = props;

    if (itemClass === InventoryItemClasses.defaultCreative) {

        return <InventoryItemDetailsDefaultFallback {...rest} />
    }

    if (itemClass === InventoryItemClasses.automotive) {
        return <InventoryItemDetailsAutomotive {...rest} />
    }

    if (itemClass === InventoryItemClasses.real_estate) {
        return <InventoryItemDetailsRealEstate {...rest} />
    }

    if (itemClass === InventoryItemClasses.photoStream) {
        return <InventoryItemDetailsPhotoStream {...rest} />
    }

    if (itemClass === InventoryItemClasses.childCampaign) {
        return <InventoryItemDetailsChildCampaign {...rest} />
    }

    if (itemClass === InventoryItemClasses.realEstateAgent) {
        return <InventoryItemDetailsRealEstateAgent {...rest} />
    }

    if (itemClass === InventoryItemClasses.article) {
        return <InventoryItemDetailsArticle {...rest} />
    }

    if (itemClass === InventoryItemClasses.review) {
        return <InventoryItemDetailsReview {...rest} />
    }

    if (itemClass === InventoryItemClasses.job_post) {
        return <InventoryItemDetailsJobPost {...rest} />
    }

    if (itemClass === InventoryItemClasses.social_post) {
        return <InventoryItemDetailsSocialPost {...rest} />
    }

    if (itemClass === InventoryItemClasses.creative) {
        return <InventoryItemDetailsCreative {...rest} />
    }

    return <InventoryItemDetailsGeneric {...rest} />;
}

InventoryItemDetails.propTypes = {
    itemClass: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {

    }
}

export default connect(
    mapStateToProps
)(
    InventoryItemDetails
);
