import React, { useState } from "react";
import cronstrue from 'cronstrue';
import { TextField, Box } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { FeedCron } from "../../helpers/constants";
import DoneIcon from '@material-ui/icons/Done';
import DeclineIcon from '@material-ui/icons/Clear';
import { makeStyles } from "@material-ui/core";
import { IconButton, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { cronScheduleIdIsAllowed } from "../../selectors/feeds";

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '400px'
        },
        disabled: {
            cursor: 'not-allowed'
        },
        buttonContiner: {
            display: 'flex',
        },
        button: {
            marginLeft: theme.spacing(1)
        },
        buttonError: {
            marginBottom: '20px'
        }
    }
})

const FeedScheduleEdit = ({ feed, onScheduleChange }) => {

    const [edit, setEdit] = useState(false);
    const [schedule, setSchedule] = useState(feed.schedule);

    const classes = useStyles();
    const { t } = useTranslation();

    if (edit) {
        return <><TextField
            value={schedule ?? ''}
            select={true}
            fullWidth
            helperText={t('Select a schedule for this feed to run')}
            onChange={(e) => {
                setSchedule(e.target.value);
            }}
        >
            {FeedCron.schedules.filter(option => cronScheduleIdIsAllowed(feed, option.id)).map((option) => {
                return <MenuItem key={option.value} value={option.value}>
                    {option.name}
                </MenuItem >
            })}

        </TextField>
            <Box className={classes.buttonContiner}>
                <IconButton
                    size={'small'}
                    className={classes.button}
                    onClick={() => {
                        onScheduleChange(schedule)
                        setEdit(false)
                    }}
                >
                    <DoneIcon />
                </IconButton>
                <IconButton
                    size={'small'}
                    className={classes.button}
                    onClick={() => setEdit(false)}
                >
                    <DeclineIcon />
                </IconButton>
            </Box>
        </>
    }

    return <>
        {cronstrue.toString(feed.schedule, { throwExceptionOnParseError: false })}
        <IconButton
            size={'small'}
            className={classes.button}
            onClick={() => setEdit(true)}
        >
            <EditIcon />
        </IconButton>
    </>

}

export default FeedScheduleEdit;
