import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';

import loader from './loader';
import layout, { layoutPersistConfig } from './layout';
import user, { userPersistConfig } from './user';
import accounts from './accounts';
import agencies from './agencies';
import register from './register';
import snackbar from './snackbar';
import systemNotification from './systemNotification';
import forgotPassword from './forgotPassword';
import globalSearch from './globalSearch';
import environment from './environment';
import dashboard from './dashboard';
import designer from './designer';
import analytics from './analytics';
import pushNotifications from './pushNotifications';
import billboard, { billboardsPersistConfig } from './billboard';
import photos from './photos';
import locations from './locations';
import stories from './stories';
import creativeProofs from './creativeProofs';
import featureToggle, { persistConfig as featureTogglePersistConfig } from './featureToggle';
import inventory, { persistConfig as inventoryPersistConfig } from './inventory';
import inventoryStatus from './inventoryStatus';
import objects from './objects';
import exports, { persistConfig as exportsPersistConfig } from './exports';
import feeds, { persistConfig as feedsPersistConfig } from './feeds';
import approvals, { persistConfig as approvalsPersistConfig } from './approvals';
import roles, { persistConfig as rolesPersistConfig } from './roles';
import applications from './applications';
import programmatic, { programmaticPersistConfig } from './programmatic';
import lightning from './lightning';
import driveTemplateElements from './driveTemplateElements';

export const rootReducer = combineReducers({
    lightning,
    driveTemplateElements,
    programmatic: persistReducer(programmaticPersistConfig, programmatic),
    applications,
    accounts,
    agencies,
    user: persistReducer(userPersistConfig, user),
    roles: persistReducer(rolesPersistConfig, roles),
    loader,
    layout: persistReducer(layoutPersistConfig, layout),
    register,
    snackbar,
    systemNotification,
    forgotPassword,
    globalSearch,
    environment,
    dashboard,
    designer,
    pushNotifications,
    analytics,
    locations,
    photos,
    inventory: persistReducer(inventoryPersistConfig, inventory),
    inventoryStatus,
    featureToggle: persistReducer(featureTogglePersistConfig, featureToggle),
    exports: persistReducer(exportsPersistConfig, exports),
    feeds: persistReducer(feedsPersistConfig, feeds),
    billboard: persistReducer(billboardsPersistConfig, billboard),
    stories,
    creativeProofs,
    objects,

    approvals: persistReducer(approvalsPersistConfig, approvals),

    form: formReducer
})

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger());
}

export const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares));
export const persistor = persistStore(store)
