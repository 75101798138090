import React from 'react';
import { Box } from '@material-ui/core';
import LCTypography from '../../../src/components/material/LCTypography';

export const Ayuda = ({ exportItem }) => {

    return (
        <Box p={5}>
            <Box pb={3}>
                <LCTypography variant="h5">Ayuda</LCTypography>
            </Box>

            <LCTypography transProps={{ exportItemHash: exportItem.hash_id }} variant="body2">
                Ask your Lucit Account Rep for the &quot;HTML Package&quot;
                for campaign ID <strong>{{ exportItemHash: exportItem.hash_id }}</strong>
            </LCTypography>
        </Box>
    )
}
