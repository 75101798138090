import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Box, List, Paper, TextField } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { InfiniteScroll, PageTitle } from '../components';
import { useQueryString } from '../helpers/hooks';
import { Global, QueryString } from '../helpers/constants';
import { globalSearch } from '../actions/globalSearch';
import { useEffect } from 'react';
import { getSearch } from '../selectors/globalSearch';
import { SearchResultListItem } from '../containers/layout';
import { useState } from 'react';
import { debouncePromise } from '../helpers/async';
import { replaceQueryString } from '../helpers/url';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ScrollContext } from '../contexts';
import { withConditionalLayout } from '../layouts/ConditionalLayout';

function SearchResults(props) {
    const { results, search } = props;
    const scrollContext = useContext(ScrollContext).current;
    const history = useHistory();

    const [inputValue, setInputValue] = useState(useQueryString(QueryString.search, ''));
    const [query] = useState(useQueryString(QueryString.search, ''));

    const load = () => search(query, 1, Global.globalSearchMaxResultsCount);
    const loadNext = () => search(query, props.page + 1, props.perPage, true);

    const searchDebounce = useCallback(debouncePromise((query) => {
        search(query);
        replaceQueryString(history, QueryString.search, query);
    }, 1000), [search]);

    useEffect(() => {
        if (query) {
            load();
        }
    }, [])

    return (
        <Paper component={Box} p={2} pt={3}>
            <PageTitle title={`${query} - Search`} />

            <Box>
                <TextField
                    variant="outlined"
                    fullWidth
                    label='Search'
                    value={inputValue}
                    onChange={e => {
                        setInputValue(e.target.value);
                        searchDebounce(e.target.value);
                    }}
                />
            </Box>

            <Box mt={2}>
                <LCTypography transProps={{ ptotal: props.total }}>Found {{ ptotal: props.total }} results</LCTypography>
            </Box>

            <InfiniteScroll dataLength={results.length}
                page={props.page}
                perPage={props.perPage}
                lastPage={props.lastPage}
                loading={props.loading}
                load={load}
                loadNext={loadNext}
                scrollableTarget={scrollContext.ref}

                endMessage={null}
            >
                <List>
                    {results
                        .map((x, idx) => (<SearchResultListItem
                            key={x.lcuid + idx}
                            item={x}
                            listItemProps={{
                                disableGutters: false
                            }} />))}
                </List>
            </InfiniteScroll>
        </Paper>
    );
}

const mapStateToProps = state => {
    const search = getSearch(state);

    return {
        results: search.data,

        page: search.currentPage,
        lastPage: search.lastPage,
        perPage: search.perPage,
        total: search.total,

        loading: search.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        search: (query, page, perPage, isNext) => dispatch(globalSearch(query, { page, perPage }, isNext))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withConditionalLayout(
        SearchResults, { publicOptions: { disablePaper: true } }
    )
);
