import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    Paper, makeStyles, Box, Link, Button, Grid, Card, CardContent,
    FormControl, Select, MenuItem, Radio, FormControlLabel, RadioGroup
} from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { withHomeLayout } from '../layouts/HomeLayout';
import { RoutePaths, Global, ProfileType } from '../helpers/constants';
import { showError } from '../actions/snackbar';
import { getFeed, getFeedRuns, runFeed, setFeedStatus } from '../actions/feeds';
import FeedDetailsContainer from '../containers/feeds/FeedDetails';
import FeedDetailsSkeleton from '../containers/feeds/FeedDetailsSkeleton';
import FeedRuns from '../containers/feeds/FeedRuns';
import FeedRunsSkeleton from '../containers/feeds/FeedRunsSkeleton';
import {
    feedByIdSelector, feeds, lastRunAt, isFeedRunningOrWillBe,
    feedIsInventoryItemFeed, feedIsKeyValueData, feedWritesStatsToKeyValues, canHaveTemplatesAssigned
} from '../selectors/feeds';
import { PageTitle } from '../components';
import FeedSettings from '../containers/feeds/FeedSettings';
import FeedTemplates from '../containers/feeds/FeedTemplates';
import DeveloperObjectTools from '../containers/settings/DeveloperObjectTools';
import { Tabs, Tab } from '@material-ui/core';
import FeedDataKeyValueStore from '../containers/feeds/FeedDataKeyValueStore';
import FeedDataInventoryItems from '../containers/feeds/FeedDataInventoryItems';
import { FeedStatus } from '../helpers/constants';
import { useTranslation } from 'react-i18next';
import DateTimeRelative from '../components/DateTimeRelative';
import { Alert, AlertTitle } from '@material-ui/lab';
import TimedLinearProgress from '../components/material/TimedLinearProgress';
import ApplicationProfile from '../containers/applications/ApplicationProfile'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        },

        runs: {
            marginTop: theme.spacing(2)
        }
    }
})

const FeedDetailsTabContainer = ({ children }) => {

    return <Box mt={3} ml={6}>
        {children}
    </Box>
}

const FeedDetailsSettings = ({ feed, switchToTab }) => {

    return <FeedDetailsTabContainer>

        {feed && <FeedSettings feed={feed} switchToTab={switchToTab} />}

        <Box mt={24}>
            <hr />
        </Box>

        {feed && <DeveloperObjectTools object={feed} />}

    </FeedDetailsTabContainer>

}

const FeedDetailsTemplates = ({ feed }) => {

    return <FeedDetailsTabContainer>

        {canHaveTemplatesAssigned(feed) ? <>
            <FeedTemplates feed={feed} />
        </>
            : <Box style={{ maxWidth: "500px", width: "100%" }}>
                <LCTypography variant="h5">
                    Templates for this data source
                </LCTypography>
                <Box mt={2} mb={12}>
                    <LCTypography variant="body2">
                        To create templates for this data source:
                        <ol>
                            <li>
                                <Link
                                    component={RouterLink}
                                    to={RoutePaths.designerTemplates}
                                    color="secondary"
                                >
                                    Visit the template designer
                                </Link>
                            </li>
                            <li>
                                Create a new template
                            </li>
                            <li>
                                Under <b>Data Source Elements</b>, select the fields you want to use in your template
                            </li>
                        </ol>
                    </LCTypography>
                    <br /><br />
                    <LCTypography variant="body2">
                        This data source creates data that is available to
                        all templates you may design.
                    </LCTypography>
                </Box>
            </Box>
        }

    </FeedDetailsTabContainer>
}

const ElapsedTimeForModule = ({ feed, moduleName }) => {

    const getValueForKey = (objectArray, keyName) => {
        return objectArray ? objectArray.find(item => item.name == keyName)?.value : null;
    }

    const elapsedTime = getValueForKey(feed.last_run_stats.Elapsed_Time, moduleName)

    if (!elapsedTime) return <></>;

    return <Box mb={1}><LCTypography variant="body2">
        <i>{elapsedTime.toFixed(2) + ` secs`}</i>
    </LCTypography>
    </Box>

}

const EstimatedTimeLinearProgress = ({ feed, complete }) => {

    const [durationInSeconds, setDurationInSeconds] = useState(0)

    const getValueForKey = (objectArray, keyName) => {
        return objectArray ? objectArray.find(item => item.name == keyName)?.value : 0;
    }

    useEffect(() => {

        const totalSeconds = feed?.last_run_stats ? getValueForKey(feed.last_run_stats.Elapsed_Time, "Retriever")
            + getValueForKey(feed.last_run_stats.Elapsed_Time, "Reader")
            + getValueForKey(feed.last_run_stats.Elapsed_Time, "Converter")
            + getValueForKey(feed.last_run_stats.Elapsed_Time, "Processor")
            : 12

        setDurationInSeconds(totalSeconds)

    }, [feed?.id])

    return <>{durationInSeconds > 0 ? <TimedLinearProgress durationInSeconds={durationInSeconds ?? 12} complete={complete} /> : null}</>
}

const FeedDetailsLastRunStats = ({ feed }) => {

    const [expanded, setExpanded] = useState(false);

    const [feedIsRunning, setFeedIsRunning] = useState(false);
    const [feedIsRunningDelayed, setFeedIsRunningDelayed] = useState(false);

    const getValueForKey = (objectArray, keyName) => {
        return objectArray ? objectArray.find(item => item.name == keyName)?.value : null;
    }

    const bytesReceived = feed.last_run_stats ? getValueForKey(feed.last_run_stats.Retriever, "file_size") ?? 0 : 0;

    const generalError = feed.last_run_stats ? (getValueForKey(feed.last_run_stats.General, "exception_occurred") ?? false) : false;

    useEffect(() => {

        if (!isFeedRunningOrWillBe(feed) && feedIsRunning) {
            setTimeout(() => {
                setFeedIsRunningDelayed(false)
            }, 1000)
        }

        if (isFeedRunningOrWillBe(feed) && !feedIsRunningDelayed) {
            setFeedIsRunningDelayed(true)
        }

        setFeedIsRunning(isFeedRunningOrWillBe(feed))

    }, [feed?.status])

    return <>

        <Box ml={2} display="inline-block">
            {feedIsRunningDelayed ?
                <EstimatedTimeLinearProgress feed={feed} complete={!feedIsRunning} />
                : <Link
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    color="secondary"
                    onClick={() => setExpanded(!expanded)}
                >

                    <>Processed {feed.last_run_converted} Items {feed.last_run_at &&
                        <> <DateTimeRelative date={lastRunAt(feed)} /></>}
                    </>
                </Link>
            }
        </Box>

        {generalError && <Box mt={2}>

            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {getValueForKey(feed.last_run_stats.General, "exception_lucore_code") && <>
                    <b>{getValueForKey(feed.last_run_stats.General, "exception_lucore_code")}</b> :</>
                }
                {getValueForKey(feed.last_run_stats.General, "exception_message")}
            </Alert>

        </Box>}

        {Boolean(feed.last_run_stats && Object.keys(feed.last_run_stats).length && expanded) && <Box mt={3}>

            <Grid container spacing={2}>
                <Grid item xs={3}>

                    <Card variant="outlined">
                        <CardContent>
                            <LCTypography variant="h6" component="div">
                                Data Retriever
                            </LCTypography>
                            <ElapsedTimeForModule feed={feed} moduleName="Retriever" />
                            <LCTypography variant="body2">
                                {bytesReceived.toLocaleString() + ` bytes retrieved from source`}<br />
                            </LCTypography>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={3}>

                    <Card variant="outlined">
                        <CardContent>
                            <LCTypography variant="h6" component="div">
                                Data Reader
                            </LCTypography>

                            <ElapsedTimeForModule feed={feed} moduleName="Reader" />

                            <LCTypography variant="body2">
                                {getValueForKey(feed.last_run_stats.Reader, "rows_added") +
                                    ` rows added from source data`}<br />

                                {getValueForKey(feed.last_run_stats.Reader, "rows_skipped") && <>
                                    {getValueForKey(feed.last_run_stats.Reader, "rows_skipped") + ` rows were skipped`}<br />
                                </>}

                                {getValueForKey(feed.last_run_stats.Reader, "rows_with_exceptions") && <>
                                    {getValueForKey(feed.last_run_stats.Reader, "rows_with_exceptions") +
                                        ` rows had errors when trying to read them`}<br />
                                </>}

                            </LCTypography>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={3}>

                    <Card variant="outlined">
                        <CardContent>
                            <LCTypography variant="h6" component="div">
                                Data Converter
                            </LCTypography>

                            <ElapsedTimeForModule feed={feed} moduleName="Converter" />

                            <LCTypography variant="body2">
                                {getValueForKey(feed.last_run_stats.Converter, "rows_converted") +
                                    ` rows were converted to Lucit Data`}<br />

                                {getValueForKey(feed.last_run_stats.Converter, "rows_skipped") && <>
                                    {getValueForKey(feed.last_run_stats.Converter, "rows_skipped") +
                                        ` rows were skipped`}<br />
                                </>}

                                {getValueForKey(feed.last_run_stats.Converter, "rows_with_exceptions") && <>
                                    {getValueForKey(feed.last_run_stats.Converter, "rows_with_exceptions") +
                                        ` rows had errors when trying to convert them`}<br />
                                </>}

                            </LCTypography>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={3}>

                    <Card variant="outlined">
                        <CardContent>
                            <LCTypography variant="h6" component="div">
                                Data Processor
                            </LCTypography>

                            <ElapsedTimeForModule feed={feed} moduleName="Processor" />

                            <LCTypography variant="body2">

                                {feedIsKeyValueData(feed) ? <>
                                    {getValueForKey(feed.last_run_stats.Processor, "key_value_rows_added") +
                                        ` items were added `}<br />
                                    {getValueForKey(feed.last_run_stats.Processor, "key_value_rows_updated") +
                                        ` items were updated / changed `}<br />
                                    {getValueForKey(feed.last_run_stats.Processor, "key_value_rows_deleted") +
                                        ` items were deleted `}<br />

                                </>
                                    :
                                    <>

                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_items_added") +
                                            ` items were added `}<br />
                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_items_updated") +
                                            ` items were updated / changed `}<br />
                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_items_deleted") +
                                            ` items were deleted `}<br />

                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_items_skipped") && <>
                                            {getValueForKey(feed.last_run_stats.Processor, "inventory_items_skipped") +
                                                ` items were skipped`}<br />
                                        </>}

                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_items_exceptions") && <>
                                            {getValueForKey(feed.last_run_stats.Processor, "inventory_items_exceptions") +
                                                ` items had errors when trying to process them`}<br />
                                        </>}

                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_photos_added") +
                                            ` photos were added `}<br />
                                        {getValueForKey(feed.last_run_stats.Processor, "inventory_photos_deleted") +
                                            ` photos were deleted `}<br />

                                    </>
                                }

                            </LCTypography>
                        </CardContent>
                    </Card>

                </Grid>

            </Grid>

        </Box>
        }

    </>

}

const FeedDetailsData = ({ feed, runFeed, setFeedStatus }) => {

    const [refreshing, setRefreshing] = useState(false);
    const [active, setActive] = useState(1);

    const { t } = useTranslation();

    return <Box>

        <Box ml={6} mt={2} mb={1}>

            <FormControl style={{ float: "right" }}>
                <RadioGroup
                    value={active}
                    defaultValue={active}
                    onChange={e => setActive(parseInt(e.target.value))}
                    row
                >
                    <FormControlLabel
                        color="secondary"
                        size="small"
                        value={1}
                        control={<Radio color="secondary" size="small" />}
                        label="Active"
                    />
                    <FormControlLabel
                        color="secondary"
                        size="small"
                        value={0}
                        control={<Radio color="secondary" size="small" />}
                        label="Deleted"
                    />
                </RadioGroup>
            </FormControl>

            <Button
                size="small"
                inline="true"
                variant="outlined"
                color="secondary"
                disabled={isFeedRunningOrWillBe(feed) || refreshing}
                onClick={() => {
                    setRefreshing(true);
                    runFeed(feed.id)
                        .then(() => setFeedStatus(feed.id, FeedStatus.running))
                        .then(() => getFeed(feed.id))
                        .finally(() => setRefreshing(false))
                }}>
                {t('Refresh Data')}
            </Button>

            {
                feed ? <FeedDetailsLastRunStats feed={feed} />
                    : <></>
            }

        </Box>

        <Box ml={7} mt={2} mb={1}>

        </Box>

        {feed && <FeedDetailsTabContainer>

            {feedIsInventoryItemFeed(feed) ?
                <FeedDataInventoryItems feed={feed} active={active} />
                : null}

            {feedIsKeyValueData(feed) || feedWritesStatsToKeyValues(feed) ?
                <FeedDataKeyValueStore feed={feed} active={active} />
                : null
            }

        </FeedDetailsTabContainer>
        }
    </Box >
}

const FeedDetailsHistory = ({ feed, getFeedRuns }) => {

    const [feedRuns, setFeedRuns] = useState([]);
    const [loadingRuns, setLoadingRuns] = useState(true);
    const [changedOnly, setChangedOnly] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        getFeedRuns(feed.id, changedOnly)
            .then(data => setFeedRuns(data))
            .finally(() => setLoadingRuns(false));
    }, [getFeedRuns, feed.id, changedOnly]);

    const setRunStatus = (id, status) => setFeedRuns(
        feedRuns.map(e => {
            if (e.feed_run_id === id) {
                e.feed_run_status = status;
            }

            return e;
        }))

    return <FeedDetailsTabContainer>

        <Box>
            <Box pl={2} mt={2} fontStyle="italic">
                <LCTypography
                    transProps={{ globalCount: Global.countOfRuns }} variant="body1">
                    Showing only last {{ globalCount: Global.countOfRuns }} updates.
                </LCTypography>

                <FormControl style={{ width: "400px" }}>
                    <Select
                        labelId="changed-only"
                        id="changed-only"
                        value={changedOnly}
                        label={t('Filter List')}
                        onChange={(e) => setChangedOnly(e.target.value)}
                    >
                        <MenuItem value="0">{t('All data source runs')}</MenuItem>
                        <MenuItem value="1">{t('Only data source runs with changes')}</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {loadingRuns && <FeedRunsSkeleton count={Global.countOfRuns} />}
            {!loadingRuns && <FeedRuns item={{ ...feed, runs: feedRuns }} setRunStatus={setRunStatus} />}

        </Box>

    </FeedDetailsTabContainer>
}

const FeedDetailsHelp = ({ feed }) => {

    return <FeedDetailsTabContainer>

        <Box style={{ maxWidth: "1000px" }}>
            <ApplicationProfile
                application={feed?.inventoryFeedProvider?.application}
                helpMode={true}
            />
        </Box>

        {!feed?.inventoryFeedProvider?.application && <Box>
            <LCTypography variant="body1">
                There is no help available for this data source.
            </LCTypography>
        </Box>
        }

    </FeedDetailsTabContainer>

}

const FeedWarningCard = ({ severity, content, condition }) => {

    if (!condition) {
        return <></>
    }

    return <Grid item xs={3}><Card variant="outlined" style={{ height: "100%" }}>
        <CardContent>
            <Box mt={2}>
                <Alert severity={severity}>
                    <b>Action Required</b>
                    <br />
                    {content}
                </Alert>
            </Box>
        </CardContent>
    </Card></Grid>

}

const FeedWarnings = ({ feed }) => {

    const lastItemsProcessedCount = feed ? feed.last_run_converted : 0;

    const feedRequiresTemplates = feed && !feedIsKeyValueData(feed);

    const feedHasTemplates = feed?.drive_templates?.default_template?.lcuid;

    return <Box>

        <Grid container spacing={4}>

            <FeedWarningCard
                condition={feed.status === FeedStatus.ready && !lastItemsProcessedCount}
                severity="info"
                content="No data was found, or there was a problem.  Check your settings or try refreshing the data on the data tab"
            />

            <FeedWarningCard
                condition={feed.status === FeedStatus.setup}
                severity="info"
                content="Finish the settings for this data source and test it"
            />

            <FeedWarningCard
                condition={feed.status === FeedStatus.error}
                severity="warning"
                content="There was an error processing the data.  Check your settings or try refreshing the data on the data tab"
            />

            <FeedWarningCard
                condition={feedRequiresTemplates && !feedHasTemplates}
                severity="info"
                content="Add a template on the templates tab.  Without one, no creatives will be generated."
            />

        </Grid>

    </Box>

}

const TabTitleWarningIcon = () => <span style={{ display: "contents", color: "#fd4949" }}>(!)</span>

function FeedDetails(props) {
    const { feed, getFeed, getFeedRuns, match, runFeed, setFeedStatus } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFeed(match.params.id)
            .finally(() => setLoading(false));
    }, [getFeed, match.params.id]);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const lastItemsProcessedCount = feed ? feed.last_run_converted : 0;

    const feedRequiresTemplates = feed && !feedIsKeyValueData(feed);

    const feedHasTemplates = feed?.drive_templates?.default_template?.lcuid;

    const templatesLabel = feedRequiresTemplates && !feedHasTemplates ? <>Templates <TabTitleWarningIcon /></> : "Templates";

    const dataLabel = <>Data {lastItemsProcessedCount ? ` (` + lastItemsProcessedCount + `)` : <TabTitleWarningIcon />}</>;

    return (
        <>
            <PageTitle title={feed && feed.name} />
            <Paper className={classes.root}>

                {loading && <FeedDetailsSkeleton />}
                {!loading && <FeedDetailsContainer item={feed} />}

                {feed && <FeedWarnings feed={feed} />}

            </Paper>

            {feed && <Box mb={3}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Settings" />
                    <Tab label={dataLabel} />
                    <Tab label={templatesLabel} />
                    <Tab label="History" />
                    <Tab label="Help" />

                </Tabs>

                {selectedTab === 0 && (
                    <FeedDetailsSettings feed={feed} switchToTab={(newTab) => setSelectedTab(newTab)} />
                )
                }

                {selectedTab === 1 && (
                    <FeedDetailsData feed={feed} runFeed={runFeed} setFeedStatus={setFeedStatus} />
                )
                }

                {selectedTab === 2 && (
                    <FeedDetailsTemplates feed={feed} />
                )
                }

                {selectedTab === 3 && (
                    <FeedDetailsHistory feed={feed} getFeedRuns={getFeedRuns} />
                )
                }

                {selectedTab === 4 && (
                    <FeedDetailsHelp feed={feed} />
                )
                }

            </Box>
            }

        </>
    );
}

const mapStateToProps = (state, { match }) => {
    const feedId = match
        ? +match.params.id
        : null;
    return {
        feed: feedByIdSelector(state)(feedId) || {},
        feeds: feeds(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFeed: id => dispatch(getFeed(id))
            .catch(error => {
                if (error.isOffline) {
                    ownProps.history.push({ pathname: RoutePaths.noInternet });
                    throw error;
                }

                dispatch(showError(`Feed with id '${id}' was not found.`));
                ownProps.history.push({ pathname: ProfileType.account.path });
                throw error;
            }),
        getFeedRuns: (id, changed_only) => dispatch(getFeedRuns(id, { per_page: Global.countOfRuns }, changed_only)),
        runFeed: id => dispatch(runFeed(id)),
        setFeedStatus: (id, status) => dispatch(setFeedStatus(id, status)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            FeedDetails
        )
    )
);
