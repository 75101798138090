import { apiHttp } from "../helpers/api";

export default class LucitApiAccounts {

    get(id) {
        return apiHttp.get(`/accounts/${id}`)
            .then(response => response.data.account);
    }

    setPrimaryImage(accountId, image_id) {
        return apiHttp.post(`/accounts/${accountId}/primaryImage`, { image_id })
            .then(response => response.data.object);
    }

    deletePrimaryImage(accountId) {
        return apiHttp.delete(`/accounts/${accountId}/primaryImage`)
            .then(response => response.data);
    }

    join(accountId, force_join_child) {
        return apiHttp.post(`/accounts/${accountId}/join`, { force_join_child })
            .then(response => response.data.account);
    }

    getRoles() {
        return apiHttp.get(`/accounts/roles`)
            .then(response => response.data.roles)
    }

    create(data) {
        return apiHttp.post("/accounts", data)
            .then(response => response.data.account);
    }

    update(accountId, name, description, website, newCreativesAndPostsAlwaysInDraftState, removeBackgroundsFromAllPrimaryImages) {
        return apiHttp.put(`/accounts/${accountId}`, {
            name: name,
            description: description,
            website: website,
            new_creatives_and_posts_always_in_draft_state: newCreativesAndPostsAlwaysInDraftState ? true : false,
            remove_backgrounds_from_all_primary_images: removeBackgroundsFromAllPrimaryImages ? true : false

        })
            .then(response => response.data.account);
    }

    setIsParent(account, is_parent_account) {
        return is_parent_account
            ? apiHttp.put(`/accounts/${account.lcuid}/set-is-parent-account`)
            : apiHttp.put(`/accounts/${account.lcuid}/clear-is-parent-account`)
    }

    setPublicCanJoin(account, data) {
        return data
            ? apiHttp.put(`/accounts/${account.lcuid}/set-public-can-join`, data)
            : apiHttp.put(`/accounts/${account.lcuid}/clear-public-can-join`)
    }

    getChildren(account) {
        return apiHttp.get(`/accounts/${account.lcuid}/child-accounts`)
            .then(response => response.data.child_accounts);
    }

    getUserClone(accountId) {
        return apiHttp.get(`/accounts/${accountId}/get-user-clone`)
            .then(response => response.data.user);
    }

    hasDefaultCreative(accountId) {
        return apiHttp.get(`/accounts/${accountId}/has-default-creative`)
            .then(response => response.data.has_default_creative);
    }

    getDefaultCreative(accountId) {
        return apiHttp.get(`/accounts/${accountId}/get-default-creative`)
            .then(response => response.data.inventory_item);
    }

    resetDefaultCreative(accountId) {
        return apiHttp.get(`/accounts/${accountId}/reset-default-creative`)
            .then(response => response.data.inventory_item);
    }

    getDriveTemplates(accountId, filters) {
        return apiHttp.get(`/accounts/${accountId}/drive-templates`, { params: filters })
            .then(response => response.data.drive_templates);
    }

    getInvoices(accountId) {
        return apiHttp.get(`/accounts/${accountId}/invoices`)
            .then(response => response.data);
    }
    getBillingPlans(accountId) {
        return apiHttp.get(`/accounts/${accountId}/billing-plans`)
            .then(response => response.data);
    }

    getCards(accountId) {
        return apiHttp.get(`/accounts/${accountId}/cards`)
            .then(response => response.data);
    }

    saveCard(accountId, token) {
        return apiHttp.post(`/accounts/${accountId}/cards`, { token })
            .then(response => response.data.stripe.card)
    }

    savePrimary(accountId, id) {
        return apiHttp.put(`/accounts/${accountId}/cards/${id}/setPrimary`)
            .then(response => response.data)
    }

    deleteCard(accountId, id) {
        return apiHttp.delete(`/accounts/${accountId}/cards/${id}`)
            .then(response => response.data)
    }

    getApplications(id) {
        return apiHttp.get(`/accounts/${id}/applications`)
            .then(response => response.data.applications);
    }

    assignApplication(id, application_id) {
        return apiHttp.post(`/accounts/${id}/applications`, { application_id });
    }

    unassignApplication(id, application_id) {
        return apiHttp.delete(`/accounts/${id}/applications/${application_id}`);
    }

    getExports(id) {
        return apiHttp.get(`/accounts/${id}/inventory-exports`)
            .then(response => response.data.inventory_exports);
    }

    getScreens(id) {
        return apiHttp.get(`/accounts/${id}/campaigns/boards`)
            .then(response => Array.isArray(response.data.digital_boards)
                ? response.data.digital_boards
                : []);
    }

    getKeyValueStores(accountId, keys) {
        return apiHttp.get(`/accounts/${accountId}/key-value-stores`, { params: { keys: keys } })
            .then(response => response.data.key_value_stores);
    }

    getUniqueKeyValueStores(accountId, objectType) {
        return apiHttp.get(`/accounts/${accountId}/unique-key-value-store-keys`, { params: { object_type: objectType } })
            .then(response => response.data.key_value_store_keys);
    }

    addInventoryItemClass(accountId, itemClass) {
        return apiHttp.post(`/accounts/${accountId}/inventory-item-class`, { inventory_item_class: itemClass })
            .then(response => response.data.account);
    }

    removeInventoryItemClass(accountId, itemClass) {
        return apiHttp.put(`/accounts/${accountId}/inventory-item-class`, { inventory_item_class: itemClass })
            .then(response => response.data.account);
    }
}
