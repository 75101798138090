import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submit, isSubmitting, getFormValues } from 'redux-form';
import { Paper, makeStyles, Box } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { withHomeLayout } from '../layouts/HomeLayout';
import { searched, isInvalidated, feeds } from '../selectors/feeds';
import { search } from '../actions/feeds';
import { store } from '../reducers';
import ButtonScrollToTopFab from '../components/material/ButtonScrollToTopFab';
import FeedsFilters, { FEEDS_FILTERS_FORM } from '../containers/feeds/FeedsFilters';
import FeedsList from '../containers/feeds/FeedsList';
import { RoutePaths } from '../helpers/constants';
import { PageTitle } from '../components';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import PolicyFeatureToggle from '../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../helpers/lucoreConstants';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Feeds(props) {
    const { feeds, submitting, submitSearch, handleSearch, invalidated, history } = props;
    const classes = useStyles();

    useEffect(() => {
        // We really don't want to have initial load, if user comes from 'ExportItem' page, or any other page
        // We don't want to have inital load if user scrolled at least something and didn't changed filters 
        if (invalidated) {
            submitSearch();
        }

        if (feeds.length === 1) {
            history.push({ pathname: `${RoutePaths.feeds}/${feeds[0].id}` });
        }

        // eslint-disable-next-line
    }, [feeds])

    return (
        <Paper className={classes.root}>
            <PolicyFeatureToggle policy={AccountPolicy.editApps}>
                <PageTitle title={"Feeds"} />
                <Box mb={2}>
                    <LCTypography variant="h5">Data Sources</LCTypography>
                </Box>

                <FeedsFilters onSubmit={handleSearch} />

                {submitting &&
                    <Box mt={2} mb={2}>
                        <CircularProgressCentered size={40} />
                    </Box>}

                <Box mt={2} />
                <FeedsList />

                <ButtonScrollToTopFab />
            </PolicyFeatureToggle>
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        feeds: feeds(state),
        submitting: isSubmitting(FEEDS_FILTERS_FORM)(state),
        searched: searched(state),
        invalidated: isInvalidated(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitSearch: () => dispatch(submit(FEEDS_FILTERS_FORM)),
        handleSearch: () => {
            const values = getFormValues(FEEDS_FILTERS_FORM)(store.getState());

            return dispatch(search(values));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            Feeds
        )
    )
);
