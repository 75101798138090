import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { activateItem } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { InventoryItemStatus } from '../../../helpers/constants';
import { ButtonLoader } from '../../../components/ionic';
import { checkmarkCircleOutline } from 'ionicons/icons';

export const useStyles = makeStyles(() => {
    return {
    }
})

const ActivateButton = (props) => {
    const { item, activateItem } = props;
    const [activating, setActivating] = useState(false);

    return (
        <>
            {item.status === InventoryItemStatus.draft
                && <ButtonLoader
                    submitting={activating}
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={checkmarkCircleOutline}
                    onClick={() => {
                        setActivating(true);
                        activateItem()
                            .finally(() => setActivating(false))
                    }}>
                    Activate
                </ButtonLoader>}
        </>
    )
}

ActivateButton.propTypes = {
    item: PropTypes.object.isRequired,
    redirectPathname: PropTypes.string
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        activateItem: () => {
            // Should not update store until user will leave this page
            return dispatch(activateItem(props.item))
                .then(() =>
                    dispatch(showSuccess(`"${props.item.title}" was successfully activated`)))
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ActivateButton
);
