import { useEffect, useState } from "react";

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => {
      if (e instanceof MouseEvent) {
        setPosition({ x: e.clientX, y: e.clientY });
      } else {
        const tEvent = e.touches[0];
        setPosition({ x: tEvent.clientX, y: tEvent.clientY });
      }
    }

    window.addEventListener("mousemove", setFromEvent);
    window.addEventListener("touchmove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
      window.removeEventListener("touchmove", setFromEvent);
    };
  }, []);

  return position;
};