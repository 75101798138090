import React, { useState } from 'react';
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TypographyShowMore = ({ children, maxLength = 120, maxLines = 5, ...rest }) => {
    const textLength = (children || '').length;
    const lines = (children || '').split('\n');

    const [showMore, setShowMore] = useState(false)
    const { t } = useTranslation();

    if (!children) {
        return null;
    }

    if (children.__proto__ !== String.prototype) {
        return children;
    }

    let shortText = '';

    if (lines.length > maxLines && !showMore) {
        shortText = lines.slice(0, maxLines).join('\n');
    }

    if (textLength > maxLength && !showMore) {
        shortText = children.slice(0, maxLength);
    }

    if (shortText) {
        return <Typography {...rest}>
            {shortText}... &nbsp;
            <Link
                component="span"
                variant='body2'
                color="textSecondary"
                style={{ cursor: "pointer" }}
                onClick={() => setShowMore(true)}>
                <b>{t('See More')}</b>
            </Link>
        </Typography>
    }

    return (
        <Typography {...rest}>
            {children}
        </Typography>
    );
};

export default TypographyShowMore;
