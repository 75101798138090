import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, useMediaQuery, useTheme, DialogContent } from '@material-ui/core';

import { Dialog } from '../material';
import { withRouter } from 'react-router';
import { ProfileType } from '../../helpers/constants';
import { getUserInfo, selectAgency } from '../../actions/user';
import { getUser } from '../../selectors/user';
import { connect } from 'react-redux';
import { AgencyListItems } from '../../containers/agency';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    expandAction: {
        cursor: 'pointer',
        display: 'flex',
        padding: theme.spacing(2),
        right: 0
    },

    dialogPaper: {
        height: '100%'
    },

    dialogContentRoot: {
        padding: 0
    },

    listSubheader: {
        background: 'white'
    },
}));

const AgencySelectorDialog = ({ user, open, handleClose, handleAgencySelect, onChange, getUserInfo }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const handleChange = (agency) => {
        handleAgencySelect(agency);
        handleClose();
        if (onChange)
            onChange();
    };

    useEffect(() => {
        if (open) {
            getUserInfo()
        }
    }, [open]);

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="xs"
            scroll="paper"
            open={open}
            aria-labelledby="agency-selector-dialog-title"
            onClose={handleClose}
            title={t('Select Operator')}
            TitleProps={{
                id: "agency-selector-dialog-title"
            }}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <List
                    disablePadding
                >
                    <AgencyListItems
                        agencies={user.agencies}
                        handleChange={handleChange} />
                </List>
            </DialogContent>
        </Dialog>
    )
}

AgencySelectorDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAgencySelect: agency => {
            dispatch(selectAgency(agency, {
                animate: true,
                onSwitched: () =>
                    ownProps.history.push({ pathname: ProfileType.agency.path })
            }));
        },
        getUserInfo: () => dispatch(getUserInfo())
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AgencySelectorDialog)
);
