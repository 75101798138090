import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { selectedAccount, selectedAccountRoles } from '../../selectors/user';
import { PageTitle } from '../../components';
import InvitedListAccordion from '../../containers/users/InvitedListAccordion';
import UsersListAccordion from '../../containers/users/UsersListAccordion';
import { useTranslation } from 'react-i18next';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';

function Users(props) {
    const { selectedAccount, selectedAccountRoles } = props;
    const { t } = useTranslation();

    return <>
        <PageTitle title={t('Users & Invites')} />

        <PolicyFeatureToggle policy={AccountPolicy.inviteUsers}>

            <UsersListAccordion
                lcuid={selectedAccount.lcuid}
                currentUserRoles={selectedAccountRoles}

                title={t('{{name}} Users', { name: selectedAccount.name })}
                description={t(
                    'These users have access to this account, and can perform various actions such as creating ads and viewing analytics'
                )}
                buttonText={t('Invite someone to this account')}
            />

            <InvitedListAccordion
                lcuid={selectedAccount.lcuid}
            />

            <ButtonScrollToTopFab />

        </PolicyFeatureToggle>
    </>
}

const mapStateToProps = state => {
    return {
        selectedAccount: selectedAccount(state),
        selectedAccountRoles: selectedAccountRoles(state),
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

const UsersWithoutLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);

export { UsersWithoutLayout }
export default withRouter(withHomeLayout(UsersWithoutLayout));
