import { SubmissionError } from "redux-form";
import { apiHttp } from "../helpers/api";
import { ItemCondition, SortDir } from "../helpers/constants";
import { isExportActive } from "../selectors/inventory";
import i18next from "i18next";

export const InventoryStats = {
    make: 'make',
    category: 'category',
    model: 'model',
    yearRange: 'year_range',
    priceRange: 'price_range'
}

export default class LucitApiInventory {

    getInventoryItemClasses() {
        return apiHttp.get(`/inventory-items/inventory-item-classes`)
            .then(response => response.data.inventory_item_classes)
    }

    create(data) {
        return apiHttp.post(`/inventory-items`, data)
            .then(response => response.data.inventory_item)
            .catch(error => {
                if (error.isOffline) {
                    throw new SubmissionError({
                        _error: i18next.t('You are offline...')
                    })
                }

                const response = error.response;

                throw new SubmissionError({
                    _error: response.data.message || i18next.t('Something went wrong')
                })
            });

    }

    createDraft(data) {
        return apiHttp.post(`/inventory-items/draft`, data)
            .then(response => response.data.inventory_item)
            .catch(error => {
                if (error.isOffline) {
                    throw new SubmissionError({
                        _error: i18next.t('You are offline...')
                    })
                }

                const response = error.response;

                throw new SubmissionError({
                    _error: response.data.message || i18next.t('Something went wrong')
                })
            });
    }

    updateItem(itemId, data) {
        return apiHttp.put(`/inventory-items/${itemId}`, data)
            .then(response => response.data.inventory_item);
    }

    activateDraft(item) {
        return apiHttp.put(`/inventory-items/${item.id}/activate-draft-item`)
            .then(response => response.data.inventory_item)
            .catch(error => {
                if (error.isOffline) {
                    throw new SubmissionError({
                        _error: i18next.t('You are offline...')
                    })
                }

                const response = error.response;

                throw new SubmissionError({
                    _error: response.data.message || i18next.t('Something went wrong')
                })
            });
    }

    delete(item) {
        return apiHttp.delete(`/inventory-items/${item.id}`)
            .then(response => response.data);
    }

    setTitle(item, title) {
        return apiHttp.put(`/inventory-items/${item.id}`, { title })
            .then(response => response.data.inventory_item);
    }

    deletePhoto(photo) {
        return apiHttp.delete(`/inventory-photos/${photo.id}`)
    }

    getProof(id) {
        return apiHttp.get(`/inventory-items/${id}/get-creative-proof`)
            .then(response => response.data);
    }

    getProofData(id) {
        return apiHttp.get(`/inventory-items/${id}/proof-data/noauth`)
            .then(response => response.data);
    }

    getPhoto(id) {
        return apiHttp.get(`/inventory-photos/${id}`)
            .then(response => response.data);
    }

    getStats(stat, data) {
        return apiHttp.get(`/inventory-item-stats`, {
            params: {
                stat,
                ...this.prepareInventoryFilters(data)
            }
        })
            .then(data => {
                return data.data.inventory_item_stat;
            });
    }

    getInventoryItems(data) {
        return apiHttp.post(`/inventory-items/search`,
            {
                ...this.prepareInventoryFilters(data)
            })
            .then(response => {
                return response.data.inventory_items;
            })
    }

    getInventoryItem(id, buildCreatives) {

        const params = {
            special: "export_status,creative_approvals,lucit_xr,drive_template,location,feed"
        }

        if (buildCreatives)
            params.special += ",build_creatives_if_none"

        return apiHttp.get(`/inventory-items/${id}`, {
            params: params
        })
            .then(response => {
                const item = response.data.inventory_item;

                item.exports = ((response.data.export_status || [])
                    //show active first
                    .sort((a, b) => (isExportActive(a) === isExportActive(b))
                        ? 0 :
                        isExportActive(a)
                            ? -1
                            : 1)
                    .map(x => x.export)) || [];

                item.approvals = (response.data.creative_approvals || [])

                item.creatives_to_board_images = (response.data.creatives_to_board_images || [])

                item.digital_boards = (response.data.digital_boards || [])

                item.feed = (response.data.feed || [])

                return item;
            })
    }

    getInventoryItemExportStatus(id) {
        return apiHttp.get(`/inventory-items/${id}/export-status`)
            .then(response => response.data.result)
    }

    export(exportId, itemIds) {

        const body = {
            "inventory_item_ids": JSON.stringify(itemIds)
        };

        return apiHttp.put(`/inventory-exports/${exportId}/filter/inventory-item-ids/add`, body)
    }

    unexport(exportId, itemIds) {

        const body = {
            "inventory_item_ids": JSON.stringify(itemIds)
        };

        return apiHttp.put(`/inventory-exports/${exportId}/filter/inventory-item-ids/remove`, body)

    }

    setExportRunSchedule(itemId, exportId, schedule) {
        return apiHttp.put(`/inventory-items/${itemId}/set-export-filter`, {
            export_id: exportId,
            filter: JSON.stringify(schedule)
        })
            .then(response => response.data.inventory_item)
    }

    setExportDigitalBoardFilter(itemId, exportId, digitalBoardFilter) {
        return apiHttp.put(`/inventory-items/${itemId}/set-board-filter`, {
            export_id: exportId,
            digital_board_filter: JSON.stringify(digitalBoardFilter)
        })
            .then(response => response.data.inventory_item)
    }

    setExportBoardIds(itemId, exportId, boardIds) {
        return apiHttp.put(`/inventory-items/${itemId}/set-export-boards`, {
            export_id: exportId,
            board_ids: JSON.stringify(boardIds)
        })
            .then(response => response.data.inventory_item)
    }

    setTitleOverride(itemId, title) {
        return apiHttp.put(`/inventory-items/${itemId}/setTitleOverride`, { title })
            .then(response => response.data.inventory_item);
    }

    setPriceOverride(itemId, price) {
        return apiHttp.put(`/inventory-items/${itemId}/setPriceOverride`, { price })
            .then(response => response.data.inventory_item);
    }

    clearTitleOverride(itemId) {
        return apiHttp.put(`/inventory-items/${itemId}/clearTitleOverride`)
            .then(response => response.data.inventory_item);
    }

    clearPriceOverride(itemId) {
        return apiHttp.put(`/inventory-items/${itemId}/clearPriceOverride`)
            .then(response => response.data.inventory_item);
    }

    setDriveTemplate(itemId, driveTemplateLcuid) {
        return apiHttp.put(`/inventory-items/${itemId}/drive-template`, {
            drive_template_lcuid: driveTemplateLcuid
        })
            .then(response => response.data.inventory_item)
    }

    prepareInventoryFilters(data) {
        const { search, accountId, category = [], exports = [], make = [], model = [], status = [], item_class,
            yearFrom, yearTo, condition = [], sort, ...rest } = data;

        const stringifiedItemClass = Array.isArray(item_class) && item_class.length ? JSON.stringify(item_class) : (
            item_class ? JSON.stringify([item_class]) : undefined
        );

        return {
            query: search,
            status: status.length
                ? JSON.stringify(status)
                : null,
            per_page: 20,

            sort: sort
                ? `${sort.field},${sort.direction || SortDir.asc}`
                : null,

            account_id: JSON.stringify(accountId),
            export_id: exports.length
                ? JSON.stringify(exports)
                : undefined,
            category_id: category.length
                ? JSON.stringify(category)
                : undefined,
            new_used: condition.length === 1 && condition[0] === ItemCondition.new
                ? 1
                : condition.length === 1 && condition[0] === ItemCondition.used
                    ? 0
                    : undefined,
            make: make.length
                ? JSON.stringify(make)
                : undefined,
            item_class: stringifiedItemClass,
            model: model.length
                ? JSON.stringify(model)
                : undefined,
            year: !yearFrom && !yearTo
                ? undefined
                : JSON.stringify({
                    "from": yearFrom
                        ? yearFrom
                        : undefined,
                    "to": yearTo
                        ? yearTo
                        : undefined
                }),

            ...rest
        };
    }
}
