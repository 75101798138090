import React, { useState } from 'react';
import { connect } from 'react-redux';
import clx from 'classnames';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { formValueSelector, reduxForm, change } from 'redux-form';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';

import ButtonLoader from '../../components/material/ButtonLoader';
import { showError, showSuccess } from '../../actions/snackbar';
import { Dialog } from '../../components/material';
import { PhotosGallery } from '../../components';
import { requiredBuilder } from '../../helpers/validators';
import ImageUploadField from '../../components/inputs/ImageUploadField';
import { DialogCloseReason, Global, InventoryItemClasses, RoutePaths } from '../../helpers/constants';
import { createItem, loadBoardSizes } from '../../actions/inventory';
import { getProof } from '../../actions/creativeProofs';
import { selectedAccountIds } from '../../selectors/user';
import { history } from '../../helpers/history';

const useStyles = makeStyles(theme => {
    return {
        warning: {
            color: theme.palette.warning.main
        },

        imgContainer: {
            position: 'relative',
            width: '100%',
            minHeight: 255,
            maxWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        img: {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        },
    }
})

function NewCreativeDialog(props) {
    const { open, handleClose, submitting, handleSubmit, loadBoardSizes, selectedAccountIds, selectedExportId } = props;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        loadBoardSizes(selectedAccountIds, selectedExportId)
    }, [selectedAccountIds, selectedExportId])

    return (
        <Dialog
            fullWidth
            fullScreen={isXs}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            lazy={true}

            aria-labelledby="export-dialog-title">
            <DialogTitle id="export-dialog-title">Add New Creative</DialogTitle>
            <DialogContent>
                <NewCreativeForm {...props} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    startIcon={<CloseIcon />}
                >Close</Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >Create Draft</ButtonLoader>
            </DialogActions>
        </Dialog>
    );
}

const deriveTitleFromFileName = (fileName) => {

    if (!fileName)
        return "";

    let title = fileName;

    //Remove extension
    title = title.replace(/\.[^/.]+$/, "")

    //Remove the first occurrence of something that looks like a ratio
    title = title.replace(/\d+x\d+/, "")

    const maxLen = Global.newCreativeNameMaxLength;

    title = title.length > maxLen ? title.substring(0, maxLen) : title

    return title.trim()

}

const NewCreativeForm = props => {
    const { photo, handleSubmit, onSubmit, handleClose, boardSizes, change, handleSkip, skipInsteadOfCancel = false } = props;
    const classes = useStyles();

    const [photoValidators] = useState([requiredBuilder('Photo is required')])

    const aspect = boardSizes.length
        ? boardSizes[0].aspect_ratio
        : Global.imageAspectDefault;

    const minWidth = boardSizes.length
        ? +boardSizes[0].min_width
        : 0;
    const minHeight = +(minWidth / aspect).toFixed(0);

    const boardSizeCount = boardSizes.length;

    const setTitle = (title) => {
        change("title", deriveTitleFromFileName(title))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <ImageUploadField
                    name="photo"
                    validate={photoValidators}

                    props={{
                        id: "photo",
                        open: true,
                        hidePreview: true,
                        hideButton: true,
                        setTitle: setTitle,
                        handleCancel: (reason) => {

                            if (reason == DialogCloseReason.skip) {
                                return handleSkip();
                            }

                            handleClose(reason);

                        },
                        handleDialogClose: (reason) => {

                            if (reason == DialogCloseReason.skip) {
                                return handleSkip();
                            }

                            if (reason !== DialogCloseReason.confirm) {
                                return handleClose(reason);
                            }

                            return handleSubmit(onSubmit)()
                                .then(result => {
                                    // https://redux-form.com/8.2.2/docs/api/props.md/#-code-handlesubmit-eventorsubmit-function-code-
                                    // handleSubmit wrapper function will always resolve promise with error when it is
                                    if (result && result._error) {
                                        throw result;
                                    }
                                })
                        },

                        ImageUploadDialogProps: {
                            title: "Upload Creative",
                            skipInsteadOfCancel: skipInsteadOfCancel,
                            src: photo,
                            saveButtonText: "Create",
                            showPreviewButton: false,
                            aspect,

                            UploadInputProps: {
                                minWidth,
                                minHeight,
                                boardSizeCount: boardSizeCount,
                            }
                        }
                    }}
                />

                <PhotosGallery
                    photos={[{
                        Component: <>
                            <Box className={clx(classes.imgContainer)}>
                                <img
                                    className={classes.img}
                                    src={photo}
                                    alt='New Creative'
                                    title='New Creative'
                                />
                            </Box>
                        </>
                    }]}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    const photo = formValueSelector(FORM_NEW_INVENTORY)(state, 'photo');

    return {
        selectedAccountIds: selectedAccountIds(state),
        photo: photo && photo.image,
        boardSizes: state.inventory.boardSizes
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        change,
        loadBoardSizes: (accountIds, selectedExportId) => dispatch(loadBoardSizes(accountIds, selectedExportId)),

        handleSkip: () => {
            history.push({ pathname: ownProps.selectedExportId ? RoutePaths.campaign(ownProps.selectedExportId) : RoutePaths.home })
        },

        onSubmit: values => dispatch(
            createItem({
                ...values,
                isDraft: true,
                auto_activate: ownProps.autoActivateCreative,
                inventoryExportId: ownProps.selectedExportId,
                itemClass: InventoryItemClasses.creative.class
            }))
            .then(item => {
                if (ownProps.autoCreateProof) {
                    return dispatch(getProof(item.id))
                        .then(proof => {
                            dispatch(showSuccess(`${item.title} proof successfully created`))
                            ownProps.handleClose();
                            history.push({ pathname: RoutePaths.public.slug(proof.lcuid) })
                        })
                }

                dispatch(showSuccess(`${item.title} was successfully created`))
                ownProps.handleClose();
                history.push({ pathname: `${RoutePaths.inventory}/${item.id}` })
            })
            .catch(result => {
                dispatch(showError(`${result.errors._error}`));

                throw result;
            })
    }
}

export const FORM_NEW_INVENTORY = "inventoryNew";

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: FORM_NEW_INVENTORY,
            initialValues: {
                photo: null,
                title: '',
                itemClass: InventoryItemClasses.creative.class
            }
        })(
            NewCreativeDialog
        )
    );
