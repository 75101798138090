import { SNACKBAR_SHOW, SNACKBAR_HIDE, SNACKBAR_SET_ACTIVE, SNACKBAR_UPDATE_ACTIVE } from "../helpers/actionTypes";

export const defaultOptions = {
    isOpen: false,
    severity: 'info',
    message: '',
    duration: 6000,

    snackbarProps: {},
    alertProps: {},

    onClose: () => { }
}

export const initialState = {
    activeMessage: null,
    messages: []
}

export default function snackbar(state = initialState, { type, ...payload }) {
    switch (type) {
        case SNACKBAR_SET_ACTIVE:
            return Object.assign({},
                state,
                {
                    activeMessage: payload.message
                });
        case SNACKBAR_UPDATE_ACTIVE:
            return Object.assign({},
                state,
                {
                    activeMessage: Object.assign({}, state.activeMessage, payload)
                });
        case SNACKBAR_SHOW:
            {
                const message = Object.assign({}, defaultOptions, {
                    isOpen: true,
                    ...payload
                });

                return Object.assign({},
                    state,
                    {
                        messages: [
                            message,
                            ...state.messages
                        ]
                    });
            }
        case SNACKBAR_HIDE:
            return Object.assign({},
                state,
                {
                    messages: payload.message
                        ? state.messages.filter(m => m !== payload.message)
                        : []
                });
        default:
            return state
    }
}
