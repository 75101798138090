import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
    duplicateTemplateForItem, loadAllBoardFormats, loadTemplate, loadTemplateAssets,
    setPreviewInventoryItem, setPreviewInventoryItemUser, setAccount, getFieldMap
} from '../../actions/designer';
import { createItem, updateItem } from '../../actions/inventory';
import DebounceField from '../../components/inputs/DebounceField';
import ImageUploadField from '../../components/inputs/ImageUploadField';
import TextField from '../../components/inputs/TextField';
import { Dialog } from '../../components/material';
import ButtonLoader from '../../components/material/ButtonLoader';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { DesignerPreview } from '../../containers/designer/DesignerPreview';
import { Global, InventoryItemClasses, RoutePaths } from '../../helpers/constants';
import { history } from '../../helpers/history';
import { requiredBuilder, required } from '../../helpers/validators';
import {
    boardFormats, designerReady, getPreview, getSizeVariantCSSForBoardFormat, templateAssets, driveTemplateLcuid, getDataSourceFields
} from '../../selectors/designer';
import { exportSettings } from '../../selectors/exports';
import { lucitApi } from '../../services/lucitApi';
import { selectedAccount } from '../../selectors/user';

export const FORM_TEMPLATE_EDIT_VALUES = "inventoryEditTemplateValues";

function TemplatePhotoStreamDialog(props) {
    const { open, handleClose, submitting, handleSubmit, item, user, selectedAccount, setPreviewInventoryItemUser, setAccount } = props;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    const [dialogReady, setDialogReady] = useState(false);

    const submitButtonName = () => item ? 'Save' : 'Create';
    const { t } = useTranslation();

    const selectedAccountMightHaveUserClone = () => {

        return (selectedAccount
            && selectedAccount.options.external_id
            && selectedAccount.options.external_id_source) ? true : false
    }

    useEffect(() => {

        if (selectedAccountMightHaveUserClone() && !item?.id) {

            setDialogReady(false);
            lucitApi.accounts.getUserClone(selectedAccount.id).then((userClone) => {
                if (userClone) {
                    setPreviewInventoryItemUser(userClone);
                }
                setDialogReady(true);
            })
        }
        else {
            if (!item?.id)
                setPreviewInventoryItemUser(user);
            else
                setPreviewInventoryItemUser(item.user);

            setDialogReady(true);
        }

        setAccount(selectedAccount)

    }, [item?.id, user, selectedAccount])

    if (!dialogReady)
        return <CircularProgressCentered />

    return (
        <Dialog
            fullWidth
            fullScreen={isXs}
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <NewPhotoStreamForm {...props} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    startIcon={<CloseIcon />}
                >{t('Cancel')}</Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >{submitButtonName()}</ButtonLoader>
            </DialogActions>
        </Dialog>
    );
}

const NewPhotoStreamForm = props => {
    const { handleSubmit, onSubmit, submitting, item, designerReady, change,
        aspect, selectedTemplateLcuid, setPreviewInventoryItem, driveTemplateLcuid, fromDesigner, getFieldMap,
        loadTemplateAssets, templateAssets, boardFormats, loadTemplate, getPreview, getSizeVariantCSSForBoardFormat, loadAllBoardFormats

    } = props;

    const [photoValidators] = useState([requiredBuilder('Photo is required')])

    const [loading, setLoading] = useState(true);

    const [driveTemplate, setDriveTemplate] = useState(null);

    const { t } = useTranslation();
    const macroFields = useSelector(getDataSourceFields);

    const selectedLcuidMatchesLoadedLcuid = selectedTemplateLcuid === driveTemplateLcuid;

    const templateLayers = driveTemplate
        && driveTemplate.templates
        && driveTemplate.templates.base
        && driveTemplate.templates.base.layers

    const bestAspect = () => driveTemplate
        && driveTemplate.templates
        && driveTemplate.templates.base
        && driveTemplate.templates.base.photo_crop_aspect_ratio
        ? driveTemplate.templates.base.photo_crop_aspect_ratio
        : aspect;

    const wantsMacro = (macro) => {
        return driveTemplate
            && driveTemplate.templates
            && driveTemplate.templates.base
            && driveTemplate.templates.base.used_macros.includes(macro);

    }

    const getBestFieldNameForId = (id, defaultValue) => {

        if (!templateLayers)
            return defaultValue;

        const layer = templateLayers.find(x => x.id === id || x.objectCode === id);

        if (layer) {
            return layer.fieldName;
        }

        return defaultValue;
    }

    const getBextPlaceholderForId = (id, defaultValue) => {
        if (!templateLayers)
            return defaultValue;

        const layer = templateLayers.find(x => x.id === id || x.objectCode === id);

        if (layer) {
            return layer.placeholder;
        }

        return defaultValue;
    }

    const wantsPhoto = () => wantsMacro('item.options.primary_image_public_url');
    const wantsPhotoBGRemoved = () => wantsMacro('item.options.primary_image_background_removed_public_url');

    useEffect(() => {
        if (selectedTemplateLcuid) {
            setLoading(true);

            getFieldMap(selectedTemplateLcuid);

            lucitApi.driveTemplates.get(selectedTemplateLcuid)
                .then(driveTemplate => {
                    setDriveTemplate(driveTemplate);
                    change('drive_template_name', driveTemplate.name)
                    setLoading(false);
                })
        }
        else {
            setLoading(false);
        }
    }, [selectedTemplateLcuid])

    useEffect(() => {
        loadTemplateAssets();
        loadAllBoardFormats();

        //We don't want to reload the template data
        //if it is already loaded
        //because we are calling postad directly from
        //the designer page
        if (!fromDesigner) {
            loadTemplate(selectedTemplateLcuid);
        }

    }, [])

    useEffect(() => {

        if (item?.id)
            setPreviewInventoryItem(item);
        else
            setPreviewInventoryItem(null);
    }, [item?.id])

    const boardFormatCode = driveTemplate
        && driveTemplate.templates
        && driveTemplate.templates.base
        && driveTemplate.templates.base.template_board_formats
        && driveTemplate.templates.base.template_board_formats[0]

    const boardFormat = boardFormats[boardFormatCode]

    const ratio = boardFormat ? boardFormat.size.width / boardFormat.size.height : 1

    const preview = getPreview();

    if (loading)
        return <CircularProgressCentered />

    return (
        <>
            <Box mb={5}>{designerReady && selectedLcuidMatchesLoadedLcuid && boardFormat && <DesignerPreview
                boardFormat={boardFormat}
                boardFormatCode={boardFormatCode}
                lcuid={selectedTemplateLcuid}
                templateAssets={templateAssets}
                aspectRatio={ratio}
                maxHeightTall="300px"
                html={preview.html}
                css={preview.css}
                js={preview.js}
                customCSS={getSizeVariantCSSForBoardFormat(boardFormatCode)}
                onCustomCSSChange={() => { }}
                disabled={true}
                postMode={true}
            />
            }
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>

                    {(wantsPhoto() || wantsPhotoBGRemoved()) && <Grid item xs={12} sm={6}>

                        <Box p={8} style={{ border: "1px dotted #808080" }}><ImageUploadField
                            name="photo"
                            uploadPromptMessage={getBestFieldNameForId("item_options_primary_image_public_url", t('Upload an Image'))}
                            validate={item ? null : photoValidators}
                            existingImageUrl={item ? item.options.primary_image_public_url : null}
                            removeBackground={wantsPhotoBGRemoved()}
                            props={{
                                id: "photo",
                                defaultOpen: false,
                                hideButton: true,

                                ImageUploadDialogProps: {
                                    title: "Post",
                                    aspect: bestAspect(),
                                    askForCameraOnAndroid: true
                                }
                            }}
                        />

                            <Box mt={3} />

                        </Box>

                    </Grid>
                    }

                    <Grid item xs={12} sm={6}>
                        {macroFields.map((field, index) => {
                            if (field.type === "text" && field.object === "item" && wantsMacro(field.macro)) {
                                return <Box key={index}><Box mt={3} />
                                    <DebounceField
                                        name={field.property}
                                        component={TextField}
                                        validate={[required]}
                                        placeholder={getBextPlaceholderForId(field.id, field.placeholder)}
                                        props={{
                                            label: getBestFieldNameForId(field.id, field.name),
                                            variant: "outlined",
                                            disabled: submitting,
                                            required: true,
                                            fullWidth: true,
                                            id: field.property,
                                        }}
                                    />
                                </Box>
                            }
                        })}
                    </Grid>

                </Grid>

            </form>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const exportSetting = state.exports.data
        .map(exportSettings)
        .map(x => x.transformer)
        .find(x => x.photo_crop_aspect_ratio) || {};

    const initialFormValues = {
        primary_image_public_url:
            ownProps.item ? ownProps.item.options.primary_image_public_url : null,
        primary_image_background_removed_public_url:
            ownProps.item ? ownProps.item.options.primary_image_background_removed_public_url : null,
        title: ownProps.item ? ownProps.item.title : null,
        description: ownProps.item ? ownProps.item.description : null,
        make: ownProps.item ? ownProps.item.make : null,
        model: ownProps.item ? ownProps.item.model : null,
        year: ownProps.item ? ownProps.item.year : null,
        sub_model: ownProps.item ? ownProps.item.sub_model : null,
        dealer_stock_number: ownProps.item ? ownProps.item.dealer_stock_number : null,
        price: ownProps.item ? ownProps.item.price : null,
        price_original: ownProps.item ? ownProps.item.price_original : null,
        "location.address": ownProps.item && ownProps.item.location ? ownProps.item.location.address : null,
        "location.city": ownProps.item && ownProps.item.location ? ownProps.item.location.city : null,
        "location.region": ownProps.item && ownProps.item.location ? ownProps.item.location.region : null,
        "location.postal_code": ownProps.item && ownProps.item.location ? ownProps.item.location.postal_code : null,
        "location.country": ownProps.item && ownProps.item.location ? ownProps.item.location.country : null,
    }

    if (ownProps.item) {
        initialFormValues.inventory_attributes = ownProps.item.inventory_attributes ?? [];
    }

    return {
        aspect: exportSetting.photo_crop_aspect_ratio
            ? +exportSetting.photo_crop_aspect_ratio
            : Global.imageAspectForPhotoStream,

        templateAssets: templateAssets(state),

        boardFormats: boardFormats(state),

        selectedAccount: selectedAccount(state),
        user: state.user,
        getPreview: () => getPreview(state),
        getSizeVariantCSSForBoardFormat: (boardFormat) => getSizeVariantCSSForBoardFormat(state, boardFormat),
        designerReady: designerReady(state),
        driveTemplateLcuid: driveTemplateLcuid(state),

        initialValues: initialFormValues

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: values => dispatch(ownProps.item ? updateItem(ownProps.item.id, {
            ...values,
            selectedTemplateLcuid: ownProps.selectedTemplateLcuid
        }) : createItem({
            ...values,
            inventoryExportId: ownProps.selectedExportId,
            selectedTemplateLcuid: ownProps.selectedTemplateLcuid,
            itemClass: InventoryItemClasses.photoStream.class
        }))
            .then(item => {
                if (values.editTemplate) {
                    dispatch(duplicateTemplateForItem(item.lcuid, ownProps.selectedTemplateLcuid)).then((driveTemplate) => {
                        history.push(`/designer/${driveTemplate.lcuid}`);
                    })
                    ownProps.handleClose();
                }
                else {
                    ownProps.handleClose();
                    history.push({ pathname: `${RoutePaths.inventory}/${item.id}` })
                }

            }),
        loadTemplateAssets: () => dispatch(loadTemplateAssets()),
        loadAllBoardFormats: () => dispatch(loadAllBoardFormats()),
        loadTemplate: (lcuid) => dispatch(loadTemplate(lcuid)),
        getFieldMap: (lcuid) => dispatch(getFieldMap(lcuid)),
        setPreviewInventoryItem: (item) => dispatch(setPreviewInventoryItem(item)),
        setPreviewInventoryItemUser: (user) => dispatch(setPreviewInventoryItemUser(user)),
        setAccount: (account) => dispatch(setAccount(account)),
    }
}

const InventoryEditTemplateValuesForm = reduxForm({
    form: FORM_TEMPLATE_EDIT_VALUES,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onChange: (values, dispatch) => {

        const photoImage = values.photo ? values.photo.image : values.primary_image_public_url;
        const bgRemovedImage = values.photo
            && values.photo.bgRemovedImage
            && values.photo.bgRemovedImage.options
            && values.photo.bgRemovedImage.options.public_url
            ? values.photo.bgRemovedImage.options.public_url : values.primary_image_background_removed_public_url;

        const itemValues = {
            options: {
                primary_image_public_url: photoImage,
                primary_image_background_removed_public_url: bgRemovedImage,
            },
            title: values.title ? values.title : null,
            description: values.description ? values.description : null,
            price: values.price ? values.price : null,
            price_original: values.price_original ? values.price_original : null,
            make: values.make ? values.make : null,
            model: values.model ? values.model : null,
            year: values.year ? values.year : null,
            sub_model: values.sub_model ? values.sub_model : null,
            dealer_stock_number: values.dealer_stock_number ? values.dealer_stock_number : null,
            location: {
                address: values.location && values.location.address ? values.location.address : null,
                city: values.location && values.location.city ? values.location.city : null,
                region: values.location && values.location.region ? values.location.region : null,
                postal_code: values.location && values.location.postal_code ? values.location.postal_code : null,
                country: values.location && values.location.country ? values.location.country : null
            }
        }

        if (values.inventory_attributes) {
            itemValues.inventory_attributes = values.inventory_attributes;
        }

        dispatch(setPreviewInventoryItem(itemValues))
    }
})(TemplatePhotoStreamDialog)

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        (
            InventoryEditTemplateValuesForm
        )
    );
