import { logger } from './logger';
import { store } from '../reducers';
import { environment } from '../selectors/environment';
import { selectedAccountIds, getUser } from '../selectors/user';
import { blacklistedErrors } from './constants';

window.addEventListener("error", ({ error, message }) => {
    if (blacklistedErrors.find(regex => regex.test(message)))
        return;

    const state = store.getState();

    logger.logError(message, {
        // Source parameter could be usefull if we want to filter errors in future
        source: 'GlobalErrorHandling',
        error,
        stackTrace: error ? error.stack : [],
        environment: {
            ...environment(state),

            deviceScreen: {
                width: window.outerWidth,
                height: window.outerHeight
            },
            viewport: window.visualViewport
        },

        user: {
            id: getUser(state).id,
            selectedAccountIds: selectedAccountIds(state),
        }
    });
})
