import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import ModerationScoreIcon from './ModerationScoreIcon';
import ModerationScoreDetails from './ModerationScoreDetails';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';

const ModerationScore = ({ approvals }) => {

    const [showModerationDetails, setShowModerationDetails] = useState(false)

    const { t } = useTranslation();

    const getCompositeScore = () => {

        if (!approvals)
            return 0

        return approvals.reduce((prev, cur) => {

            return cur.object_moderation_composite_score < prev ? cur.object_moderation_composite_score : prev
        }, 1)
    }

    const getCompositeScorePct = () => {
        return (getCompositeScore() * 100).toFixed(2)
    }

    return (
        <Box>
            <Box
                title={`Approval Score : ` + getCompositeScorePct() + `%`}
                onClick={() => setShowModerationDetails(true)}
                style={{ cursor: "pointer" }}
            >
                <ModerationScoreIcon
                    score={getCompositeScore()}
                />
            </Box>

            <GenericDialog
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "xs",
                    open: showModerationDetails,
                    onClose: () => setShowModerationDetails(false)
                }}
                title={t('Approval Details')}
                ContentComponent={<>
                    <ModerationScoreDetails approvals={approvals} />
                </>}
            />
        </Box>
    )
}

export default ModerationScore
