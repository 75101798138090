import { Box, Button, Grid, makeStyles, Radio, RadioGroup, } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import MenuItem from '@material-ui/core/MenuItem'
import { duplicateTemplate } from '../../actions/designer';
import { userAccounts, selectedAccount } from '../../selectors/user';
import { FormControl, InputLabel, Select, FormControlLabel } from '@material-ui/core';
import { RoutePaths } from '../../helpers/constants';
import { useHistory } from 'react-router';
import { selectAccount } from '../../actions/user';
import ButtonLoader from '../../components/material/ButtonLoader';
import { getApplications } from '../../actions/applications';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const DesignerCopyTemplateDialog = (props) => {
    const {
        driveTemplateLcuid, open, handleClose, duplicateTemplate,
        userAccounts, selectedAccount, handleSwitchAccount
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)

    const [accountLcuid, setAccountLcuid] = useState()
    const [copyToANewAccount, setCopyToANewAccount] = useState(false)

    const getButtonName = () => {

        if (copyToANewAccount)
            return t('Copy To Account')

        return t('Duplicate')

    }

    const handleDuplicateTemplate = () => {

        const accountFromLcuid = userAccounts.find(acc => acc.lcuid === accountLcuid)

        setLoading(true)

        duplicateTemplate(driveTemplateLcuid, accountLcuid).then((driveTemplate) => {

            if (copyToANewAccount)
                handleSwitchAccount(accountFromLcuid, driveTemplate)
            else
                history.push({ pathname: RoutePaths.designerTemplates + `/${driveTemplate.lcuid}` });

            setLoading(false)

            handleClose()
        })

    }

    useEffect(() => {

        if (selectedAccount?.lcuid) {
            setAccountLcuid(selectedAccount.lcuid)
        }

    }, [selectedAccount])

    return (<>
        <GenericDialog
            title={t('Duplicate Template')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LCTypography variant="body2">
                            Duplicate this template and all of its layers.
                            <br /><br />
                            You can copy this template to your current account, or, select one
                            of your other accounts to copy it to.
                        </LCTypography>
                    </Grid>

                    <Grid item xs={8}>
                        <Box p={2} mb={2}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="account-action"
                                    value={copyToANewAccount ? "new" : "duplicate"}
                                    onChange={(e) => setCopyToANewAccount(e.target.value === "new")}
                                >
                                    <FormControlLabel
                                        value="duplicate"
                                        control={<Radio />}
                                        label={t('Duplicate in this Account')}
                                    />
                                    <FormControlLabel
                                        value="new"
                                        control={<Radio />}
                                        label={t('Copy to a New Account')}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {copyToANewAccount && (
                                <FormControl fullWidth className={classes.formRows}>
                                    <InputLabel id="demo-simple-select-label">{t('Choose Account : ')}</InputLabel>
                                    <Select
                                        value={accountLcuid}
                                        label={t('Choose Account')}
                                        onChange={(e) => setAccountLcuid(e.target.value)}
                                    >
                                        {userAccounts.map((acc, index) => (
                                            <MenuItem key={index} value={acc.lcuid} style={{ display: 'flex', alignItems: 'center' }}>
                                                {acc.options.primary_image_public_url && (
                                                    <img
                                                        style={{ width: "25px", maxHeight: "25px", marginRight: "5px" }}
                                                        src={acc.options.primary_image_public_url}
                                                    />
                                                )}
                                                {acc.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    </Grid>

                </Grid>

            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader onClick={handleDuplicateTemplate}
                    color="secondary"
                    variant="contained"
                    submitting={loading}
                >
                    {getButtonName()}
                </ButtonLoader>
            </>}
        />
    </>)
}

const mapStateToProps = (state) => {
    return {
        selectedAccount: selectedAccount(state),
        userAccounts: userAccounts(state),
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        getApplications: (lcuid) => dispatch(getApplications(lcuid)),
        handleSwitchAccount: (account, newDriveTemplate) => {

            dispatch(selectAccount(account, {
                animate: true,
                onSwitched: () =>
                    props.history.push({ pathname: RoutePaths.designerTemplates + `/${newDriveTemplate.lcuid}` })
            }))

        },

        duplicateTemplate: (driveTemplateLcuid, accountLcuid, feedLcuid) =>
            dispatch(duplicateTemplate(driveTemplateLcuid, accountLcuid, feedLcuid)),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DesignerCopyTemplateDialog)
)
