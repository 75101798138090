import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getItem } from '../../../actions/inventory';
import InventoryItemPhotoGallery from './InventoryItemPhotoGallery';
import PriceOverridable from './PriceOverridable';
import TitleOverridable from './TitleOverridable';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        }
    }
})

function InventoryItem(props) {
    const { item } = props;
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <InventoryItemPhotoGallery item={item} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <TitleOverridable item={item} />
                </Box>
                <Box className={classes.attribute}>
                    <Box component={Typography} className={classes.attributeTitle}>Stock:</Box>
                    <Box component={Typography} className={classes.attributeValue}>{item.dealer_stock_number}</Box>
                </Box>
                <Box className={classes.attribute}>
                    <Box alignSelf="flex-start" component={Typography} className={classes.attributeTitle}>Price:</Box>
                    <Box className={classes.attributeValue}>
                        <PriceOverridable item={item} />
                    </Box>
                </Box>
            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
