import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemAvatar, Avatar, ListItemText, ListItem, Grid, Typography } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { runStartedAt, runCompletedAt } from '../../selectors/feeds';
import { FeedRunStatus } from '../../helpers/constants';
import FeedRunStatusIcon from './FeedRunStatusIcon';
import DateTimeRelative from '../../components/DateTimeRelative';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        imageIcon: {
            width: '100%',
            height: '100%'
        },
        done: {
            fill: theme.palette.success.main
        },
        error: {
            fill: theme.palette.error.main
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
    }
})

function FeedRun(props) {
    const { run, setRunStatus } = props;
    const classes = useStyles();

    return <ListItem>
        <ListItemAvatar>
            <Avatar className={classes.avatar}>
                <FeedRunStatusIcon
                    runId={run.feed_run_id}
                    runStatus={run.feed_run_status}
                    handleStatusChange={data => setRunStatus(data.feed_run_id, data.feed_run_status)} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText>
            <LCTypography transProps={{ runFeed: run.feed_run_message }}
                variant="body1">{{ runFeed: run.feed_run_message }}</LCTypography>
            <Box mt={1}>
                <Grid container spacing={1}>
                    {run.added > 0
                        && <LCTypography transProps={{ radd: run.added }}
                            variant="body2"> {{ radd: run.added }} added, </LCTypography>
                    }
                    {run.updated > 0
                        && <LCTypography transProps={{ uprun: run.updated }}
                            variant="body2">&nbsp;{{ uprun: run.updated }} changed, </LCTypography>
                    }
                    {run.deleted > 0
                        && <LCTypography transProps={{ delrun: run.deleted }}
                            variant="body2">&nbsp;{{ delrun: run.deleted }} deleted, </LCTypography>
                    }
                    {run.photos > 0
                        && <LCTypography transProps={{ phoRun: run.photos }}
                            variant="body2">&nbsp;{{ phoRun: run.photos }} new photos </LCTypography>
                    }
                    {run.feed_run_status === FeedRunStatus.completed
                        && run.completed
                        && <Grid item xs={12} className={classes.gridItem} >
                            <Typography variant="body2" color="textSecondary">
                                <DateTimeRelative date={runCompletedAt(run)} />
                            </Typography>
                        </Grid>}
                    {run.feed_run_status !== FeedRunStatus.completed
                        && run.feed_run_start_at
                        && <Grid item xs={12} className={classes.gridItem} >
                            <Typography variant="body2" color="textSecondary">
                                Started&nbsp;
                                <DateTimeRelative date={runStartedAt(run)} />
                            </Typography>
                        </Grid>}
                </Grid>
            </Box>

        </ListItemText>
    </ListItem>;
}

FeedRun.propTypes = {
    run: PropTypes.object.isRequired
}

export default FeedRun;
