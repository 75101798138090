import {
    OBJECT_COMMENTS, OBJECT_REACTIONS, OBJECT_NEW_REACTION,
    OBJECT_DELETE_REACTION, OBJECT_NEW_COMMENT, OBJECT_NEW_REACTION_REQUEST,
    OBJECT_DELETE_REACTION_REQUEST, OBJECT_ATTACHMENTS, OBJECT_ATTACHMENT_ADD,
    OBJECT_ATTACHMENT_FILE, OBJECT_DELETE_COMMENT, OBJECT_UPDATE_COMMENT,
    OBJECT_USERS_INVITATIONS_REQUEST, OBJECT_USERS_INVITATIONS_RESULT, OBJECT_USERS_INVITE,
    OBJECT_USERS_UNINVITE, OBJECT_USERS_REQUEST, OBJECT_USERS_RESULT, OBJECT_USERS_REMOVE, OBJECT_APPLICATIONS_REQUEST,
    OBJECT_APPLICATIONS_RESULT, OBJECT_APPLICATIONS_ASSIGN, OBJECT_APPLICATIONS_UNASSIGN, PAYMENT_METHODS_CARDS_REQUEST,
    PAYMENT_METHODS_CARDS_RESULT, PAYMENT_METHODS_ADD_CARD, PAYMENT_METHODS_REMOVE_CARD, PAYMENT_METHODS_SET_PRIMARY_CARD,
    BILLING_INVOICES_RESULT, BILLING_PLANS_RESULT, OBJECT_USERS_UPDATE_ROLES
} from "../helpers/actionTypes";
import applications, { initialState as appsInitialState } from "./templates/applications";
import attachments from "./templates/attachments";
import reactions from "./templates/reactions";
import users, { initialState as usersInitialState } from "./templates/users";
import billing, { initialState as billingInitialState } from "./templates/billing";

export const initialState = {
    byId: {}
}

export default function objects(state = initialState, action) {
    switch (action.type) {
        case OBJECT_COMMENTS:
        case OBJECT_REACTIONS:
        case OBJECT_NEW_REACTION_REQUEST:
        case OBJECT_NEW_REACTION:
        case OBJECT_NEW_COMMENT:
        case OBJECT_UPDATE_COMMENT:
        case OBJECT_DELETE_COMMENT:
        case OBJECT_DELETE_REACTION:
        case OBJECT_DELETE_REACTION_REQUEST: {
            return apply(state, action, (state) => reactions(state, action))
        }

        case OBJECT_ATTACHMENTS:
        case OBJECT_ATTACHMENT_FILE:
        case OBJECT_ATTACHMENT_ADD: {
            return apply(state, action, (state) => attachments(state, action))
        }

        case OBJECT_USERS_INVITATIONS_REQUEST:
        case OBJECT_USERS_INVITATIONS_RESULT:
        case OBJECT_USERS_INVITE:
        case OBJECT_USERS_UNINVITE:
        case OBJECT_USERS_REQUEST:
        case OBJECT_USERS_RESULT:
        case OBJECT_USERS_UPDATE_ROLES:
        case OBJECT_USERS_REMOVE: {
            return apply(state, action, (state) => users(state, action))
        }

        case OBJECT_APPLICATIONS_REQUEST:
        case OBJECT_APPLICATIONS_ASSIGN:
        case OBJECT_APPLICATIONS_UNASSIGN:
        case OBJECT_APPLICATIONS_RESULT: {
            return apply(state, action, (state) => applications(state, action))
        }

        case PAYMENT_METHODS_CARDS_REQUEST:
        case PAYMENT_METHODS_CARDS_RESULT:
        case PAYMENT_METHODS_ADD_CARD:
        case PAYMENT_METHODS_REMOVE_CARD:
        case PAYMENT_METHODS_SET_PRIMARY_CARD:
        case BILLING_INVOICES_RESULT:
        case BILLING_PLANS_RESULT:
            return apply(state, action, (state) => billing(state, action))

        default:
            return state
    }
}

const apply = (state, action, applyReducer) => ({
    ...state,
    byId: {
        ...state.byId,
        [action.lcuid]: {
            lcuid: action.lcuid,
            ...usersInitialState,
            ...appsInitialState,
            ...billingInitialState,
            ...state.byId[action.lcuid],
            ...applyReducer(state.byId[action.lcuid])
        }
    }
})
