import { createSelector } from "reselect"

export const getBoardFormatOptionsLoaded = createSelector([state => state.programmatic.board_format_options], (boardFormatOptions) => {
    return boardFormatOptions.length > 0
})

export const getVenueTypeOptionsLoaded = createSelector([state => state.programmatic.venue_type_options], (venueTypeOptions) => {
    return venueTypeOptions.length > 0
})

export const getAgencyOptionsLoaded = createSelector([state => state.programmatic.agency_options], (agencyOptions) => {
    return agencyOptions.length > 0
})

export const getHasGeos = createSelector([state => state.programmatic.geo], (geo) => {
    return (geo.length > 0)
})

export const getHasValidViewport = createSelector([state => state.programmatic.viewport], (viewport) => {
    return viewport
        && Object.hasOwn(viewport, 'north')
        && Object.hasOwn(viewport, 'south')
        && Object.hasOwn(viewport, 'east')
        && Object.hasOwn(viewport, 'west')
})

export const getDigitalBoardFilters = createSelector(
    [state => state.programmatic.geo,
    state => state.programmatic.boardFormats,
    state => state.programmatic.venueTypes,
    state => state.programmatic.agencies],
    (geo, boardFormats, venueTypes, agencies) => ({
        geo: { include: geo },
        board_formats: boardFormats.length > 0
            ? { include: boardFormats.map(x => x.code) }
            : undefined,
        open_ooh_venue_type: venueTypes.length > 0
            ? { include: venueTypes.map(x => x.enumeration_id) }
            : undefined,
        agencies: agencies.length > 0
            ? { include: agencies.map(x => x.lcuid) }
            : undefined,
    }));

export const getBudgetAllocation = createSelector(
    [state => state.programmatic.venueTypes,
    state => state.programmatic.venue_type_options,
    state => state.programmatic.budget_allocation],
    (venueTypes, venueTypeOptions, budgetAllocation) => {
        const values = venueTypes.length > 0
            ? venueTypes
            : venueTypeOptions;

        return {
            open_ooh_venue_types: values.map(x => ({
                ...x,
                venue_type_id: x.enumeration_id,
                allocation: 0,
                ...budgetAllocation.open_ooh_venue_types?.find(y => y.venue_type_id == x.enumeration_id)
            }))
        }
    });

export const venueTypesTreeSearch = (venueTypes, search) => {
    const results = venueTypes
        .filter(a => !search || (a.name && a.name.toLowerCase().indexOf(search.toLowerCase()) > -1))
        ;

    return venueTypesTree(results)
}

export const getVenueTypePath = (venueType) => {
    const path = [];
    const recurse = x => {
        if (x == null) return;
        path.push(x.name)
        recurse(x.parent);
    };

    recurse(venueType);
    return path.reverse().join(' / ');
}

export const venueTypesTree = (venueTypes) => {
    // For optimization purpose, Dictionary has O(1) complexity for getting key
    const venueTypesMap = venueTypes.reduce((prev, curr) => ({ ...prev, [curr.enumeration_id]: curr }), {});

    // Find venueTypes which doesn't have parent at all, or venueType which parent are not in list
    const rootVenueTypes = venueTypes
        .filter(venueType => venueType.parent_id == null
            || !venueTypesMap[venueType.parent_id]);

    // {rootVenueTypes} - array of venueTypes for which we need assign children
    // {level}          - level of recursive depth
    const recursivelyAssignChildren = (rootVenueTypes, level = 0) => {
        for (const venueType of rootVenueTypes) {
            if (venueType.children == null)
                venueType.children = venueTypes.filter(a => a.parent_id === venueType.enumeration_id);
            venueType.children.forEach(x => x.parent = venueType);

            recursivelyAssignChildren(venueType.children, level + 1);
        }
        return rootVenueTypes;
    }

    return recursivelyAssignChildren(rootVenueTypes);
};

// Do some action to all nodes down in the tree
export const recursivelyDesc = (option, fn) => {
    fn(option);
    return option.children.length > 0
        && option.children.forEach(x => recursivelyDesc(x, fn));
}

// Do some action to all parent nodes up in the tree
export const recursivelyAsc = (option, fn) => {
    if (option.parent != null) {
        fn(option.parent);
        recursivelyAsc(option.parent, fn)
    }
}

// Check if at least one node down in the tree return true for <someFn>
export const recursivelySomeChildren = (option, someFn) => {
    return option.children.length > 0
        && option.children.some(x => someFn(x) || recursivelySomeChildren(x, someFn));
}

/** AI-generated
 * Calculates a number in a specified range based on an input number between 0 and 1.
 * @param {number} inputNumber - The input number between 0 and 1.
 * @param {number} minValue - The minimum value of the desired range.
 * @param {number} maxValue - The maximum value of the desired range.
 * @returns {number} - The calculated number within the specified range.
 */
export const calculateNumberInRange = (inputNumber, minValue, maxValue) => {
    // Ensure inputNumber is between 0 and 1
    inputNumber = Math.min(1, Math.max(0, inputNumber));

    // Calculate the number in the specified range
    return inputNumber * (maxValue - minValue) + minValue;
}

/** AI-Generated
 * Formats a number to a string with "K" (thousands) or "M" (millions) based on its magnitude.
 * @param {number} number - The input number to be formatted.
 * @returns {string} - The formatted string.
 */
export const formatNumberToShortVariant = (number) => {
    if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + 'K';
    } else {
        return number.toString();
    }
}
