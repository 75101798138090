import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles } from '@material-ui/core';
import LCTypography from '../../../components/material/LCTypography';
import { hasPrice } from '../../../selectors/inventory';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        text: {
            textAlign: 'left',
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1)
            }
        }
    }
})

function InventoryItemCardContentAutomovie(props) {
    const { item } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return <Box p={2}>
        {hasPrice(item)
            && <Typography className={classes.text} noWrap>
                ${Number(item.price).toFixed(0)}
            </Typography>}
        {!hasPrice(item)
            && <LCTypography className={classes.text} noWrap>-</LCTypography>}

        <LCTypography transProps={{ itemDealerStockNumber: item.dealer_stock_number }} className={classes.text} noWrap>
            {{ itemDealerStockNumber: item.dealer_stock_number }}
        </LCTypography>
        <Typography className={classes.text} color="textSecondary" noWrap>
            {item.new_used ? t('New') : t('Used')}
        </Typography>
    </Box>;
}

InventoryItemCardContentAutomovie.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
)(
    InventoryItemCardContentAutomovie
);
