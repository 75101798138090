import {
    Box, Button, Grid, Typography, makeStyles
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { duplicateTemplate, resetState, showHideTemplates } from '../../actions/designer';
import { PageTitle } from '../../components';
import DesignerNewTemplateDialog from '../../containers/designer/DesignerNewTemplateDialog';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { DesignerTemplateList } from '../../containers/designer/DesignerTemplateList';
import { GenericDialog } from '../../components/modals/GenericDialog';
import TemplatePhotoStreamDialog from '../../containers/inventory/TemplatePhotoStreamDialog';
import { useHistory } from 'react-router';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import { selectedProfile } from '../../selectors/user';
import { ProfileType } from '../../helpers/constants';
import { lucitApi } from '../../services/lucitApi';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { useWidth } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            padding: theme.spacing(3)
        }
    }
});

const DesignerTemplates = ({ selectedProfile }) => {
    const width = useWidth();
    const { t } = useTranslation();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [newTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false)

    return (
        <PolicyFeatureToggle policy={AccountPolicy.editDriveTemplates}>
            <Box p={6} pt={2} height="calc(100% - 6px)">
                <PageTitle title={t('Template Designer')} />

                <Hidden smUp implementation="js">

                    <Box justifyContent="center" alignItems="center" mt={20}>
                        <Typography variant="h6" color="textSecondary">
                            {t('Template Designer is not available on mobile devices.')}
                        </Typography>
                        <hr />
                        <Typography variant="body2" color="textSecondary">
                            {t('Please use a desktop computer or a tablet in landscape mode.')}
                        </Typography>
                    </Box>

                </Hidden >

                <Hidden xsDown implementation="js">
                    <Box mb={2}>
                        <Button
                            onClick={() => {
                                resetState()
                                setNewTemplateDialogOpen(true)
                            }}
                            color="secondary"
                            variant="contained">
                            {t('Create a new template')}
                        </Button>
                        {newTemplateDialogOpen && <DesignerNewTemplateDialog
                            open={newTemplateDialogOpen}
                            handleClose={() => {
                                setNewTemplateDialogOpen(false)
                                showHideTemplates(false)
                            }}
                        />
                        }
                    </Box>

                    <DesignerTemplateList
                        showUserName
                        width={width}
                        onClick={setSelectedTemplate}
                        onEmptyClick={() => {
                            resetState()
                            setNewTemplateDialogOpen(true)
                        }}
                    />

                    {selectedTemplate
                        && <DesignerTemplateAction
                            open={true}
                            handleClose={() => setSelectedTemplate(null)}
                            template={selectedTemplate}
                            isPublic={selectedTemplate && selectedTemplate.parent_id !== selectedProfile.id}
                        />}
                </Hidden>
            </Box >
        </PolicyFeatureToggle>
    );
}

const DesignerTemplateAction = ({ open, template, isPublic, handleClose }) => {
    const classes = useStyles()
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showPostDialog, setShowPostDalog] = useState(false)
    const selectedAccountProfile = useSelector(selectedProfile)
    const [accountFeeds, setAccountFeeds] = useState([])
    const [showFeedDropDown, setShowSelectFeedDropDown] = useState(false)
    const [selectedFeedLcuid, setSelectedFeedLcuid] = useState(null)
    const [loading, setLoading] = useState(false)

    const templateHasDataSourceScopedMacros = template.options?.data_source_macro_scope_cache?.has_data_source_scoped_macros

    useEffect(() => {
        if (selectedAccountProfile && selectedAccountProfile.type === ProfileType.account) {
            setLoading(true)
            lucitApi.feeds.getActiveFeedsForAccount(selectedAccountProfile.id)
                .then(result => {
                    setAccountFeeds(result.data)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [selectedAccountProfile?.id])

    const accountFeedsMatchingFeedProviderForDataSourceScopedMacros = () => {
        if (!templateHasDataSourceScopedMacros)
            return [];

        const feedProviderLcuids = template.options.data_source_macro_scope_cache.linked_data_source_scoped_macro_feed_providers;

        return accountFeeds.filter(feed => feedProviderLcuids.includes(feed.inventoryFeedProvider.lcuid));
    }

    const startDuplicateTemplate = () => {

        if (accountFeedsMatchingFeedProviderForDataSourceScopedMacros().length == 0) {
            dispatch(
                duplicateTemplate(template.lcuid)
            ).then(driveTemplate => history.push({ pathname: `/designer/${driveTemplate.lcuid}` }))
        }
        else if (accountFeedsMatchingFeedProviderForDataSourceScopedMacros().length == 1) {

            const firstFeed = accountFeedsMatchingFeedProviderForDataSourceScopedMacros()[0];

            dispatch(
                duplicateTemplate(template.lcuid, null, firstFeed.lcuid)
            ).then(driveTemplate => history.push({ pathname: `/designer/${driveTemplate.lcuid}` }))
        }
        else {
            setShowSelectFeedDropDown(true)
        }

    }

    return <>
        <GenericDialog
            title={t('Template')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}
            ContentComponent={<>
                <>
                    <Grid container spacing={2} justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Box textAlign="center">
                                <Box style={{ maxWidth: "500px", margin: "0 auto" }}>
                                    <img style={{ width: "100%" }}
                                        src={template.options.primary_image_public_url
                                            ? template.options.primary_image_public_url
                                            : "/assets/designer/empty-drive-template.png"
                                        }
                                    />
                                </Box>
                                <Typography variant="h6" gutterBottom>{template.name}</Typography>
                                <Box>
                                    {t('What would you like to do?')}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box textAlign="center">
                                {!isPublic
                                    && <>
                                        <Button
                                            onClick={() => {
                                                history.push({ pathname: `/designer/${template.lcuid}` })
                                            }}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            {t('Edit Template')}
                                        </Button>
                                    </>}
                            </Box>
                        </Grid>
                        {!showFeedDropDown &&
                            <Grid item xs={4}>
                                <Box textAlign="center">
                                    {isPublic && <Button
                                        disabled={loading}
                                        onClick={() => {
                                            startDuplicateTemplate()
                                        }}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {t('Duplicate & Edit')}
                                    </Button>}
                                </Box>
                            </Grid>
                        }

                        {!showFeedDropDown && !templateHasDataSourceScopedMacros &&
                            <Grid item xs={4}>
                                <Box textAlign="center">
                                    <Button
                                        onClick={() => {
                                            setShowPostDalog(true)
                                        }}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {t('Post Ad')}
                                    </Button>
                                </Box>
                            </Grid>
                        }

                        {showFeedDropDown && <Grid item xs={12}>

                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs={8}>

                                    <FormControl fullWidth>
                                        <InputLabel id="feed-select-label">{t('Select a feed to attach to this template')}</InputLabel>
                                        <Select
                                            labelId="feed-select-label"
                                            id="feed-select"
                                            value={selectedFeedLcuid}
                                            onChange={(e) => {
                                                setSelectedFeedLcuid(e.target.value)
                                            }}
                                        >
                                            {accountFeedsMatchingFeedProviderForDataSourceScopedMacros().map(feed => (
                                                <MenuItem key={feed.lcuid} value={feed.lcuid}>{feed.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign="center">
                                        <Button
                                            disabled={loading}
                                            onClick={() => {
                                                setLoading(true)
                                                dispatch(
                                                    duplicateTemplate(template.lcuid, null, selectedFeedLcuid)
                                                ).then(driveTemplate => history.push({ pathname: `/designer/${driveTemplate.lcuid}` }))
                                            }
                                            }
                                            color="primary"
                                            variant="contained"
                                        >
                                            {t('Duplicate & Edit')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </>
            </>}
            ActionsComponent={<></>}
        />
        {showPostDialog && <TemplatePhotoStreamDialog
            open={showPostDialog}
            selectedTemplateLcuid={template.lcuid}
            handleClose={() => {
                setShowPostDalog(false)
            }}
        />}
    </>
}

const mapStateToProps = (state) => {
    return {
        selectedProfile: selectedProfile(state)
    };
}

const mapDispatchToProps = () => {
    return {
    };
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withHomeLayout(DesignerTemplates)
    );
