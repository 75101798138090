import moment from "moment";

export const sortString = (a, b) => a > b ? 1 : a < b ? -1 : 0;
export const sortNumber = (a, b) => a - b;
export const sortBoolean = (a, b) => (a === b) ? 0 : a ? -1 : 1;
export const sortNullLast = (a, b) => a == null ? 1 : b == null ? -1 : 0;

export const sortDate = (a, b) =>
    moment(a).isAfter(b)
        ? 1 :
        moment(a).isSame(b)
            ? 0
            : -1;