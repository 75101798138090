import React from 'react';
import clx from 'classnames';
import { makeStyles, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@material-ui/core';

import { withRouter } from 'react-router';
import { selectedProfile } from '../../selectors/user';
import { connect } from 'react-redux';
import { ProfileType } from '../../helpers/constants';
import AgencyAvatar from './AgencyAvatar';

const useStyles = makeStyles(() => ({
    selected: {
        // color: theme.palette.secondary.main

        '& .MuiTypography-root': {
            fontWeight: 'bold',
        }
    },
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },
}));

const AgencyListItems = ({ agencies, approvalsCounts, selectedProfile, handleChange, autoFocusSelected }) => {
    return agencies
        .map(agency => (<AgencyListItemForwarded
            key={agency.id}
            agency={agency}
            approvalsCounts={approvalsCounts}
            selectedProfile={selectedProfile}
            handleChange={handleChange}
            autoFocusSelected={autoFocusSelected}
        />));
}

const AgencyListItem = ({ forwardedRef, agency, approvalsCounts, selectedProfile, handleChange, autoFocusSelected }) => {
    const classes = useStyles();

    const isSelected = selectedProfile.type === ProfileType.agency
        && selectedProfile.id === agency.id;

    return (<>
        <ListItem
            innerRef={forwardedRef}
            selected={isSelected}
            autoFocus={autoFocusSelected && isSelected}
            className={clx({ [classes.selected]: isSelected })}
            button
            onClick={() => handleChange(agency)}>
            <ListItemAvatar>
                <AgencyAvatar agency={agency} />
            </ListItemAvatar>
            <Tooltip title={agency.name}>
                <ListItemText
                    primary={agency.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={Boolean(approvalsCounts[agency.id]) && `${approvalsCounts[agency.id]} creative(s) waiting approval`}
                    secondaryTypographyProps={{ noWrap: true }}
                />
            </Tooltip>
        </ListItem>
    </>);
}

const mapStateToProps = state => {
    return {
        selectedProfile: selectedProfile(state),
        approvalsCounts: state.agencies.approvalsCounts
    };
}

const AgencyListItemConnected = connect(mapStateToProps)(AgencyListItem);
// eslint-disable-next-line
const AgencyListItemForwarded = React.forwardRef((props, ref) => <AgencyListItemConnected {...props} forwardedRef={ref} />)

export { AgencyListItemForwarded as AgencyListItem }
export default withRouter(
    connect(
        mapStateToProps
    )(AgencyListItems)
);
