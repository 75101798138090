import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { selectedAgency, selectedAgencyRoles } from '../../selectors/user';
import { PageTitle } from '../../components';
import UsersListAccordion from '../../containers/users/UsersListAccordion';
import InvitedListAccordion from '../../containers/users/InvitedListAccordion';
import { useTranslation } from 'react-i18next';

function Users(props) {
    const { selectedAgency, selectedAgencyRoles } = props;
    const { t } = useTranslation();

    return <>
        <PageTitle title={t('Operator Team')} />

        <UsersListAccordion
            lcuid={selectedAgency.lcuid}
            currentUserRoles={selectedAgencyRoles}
            showAccountList={true}
            title={t('{{name}} Team Members', { name: selectedAgency.name })}
            description={t('These users work for your company.  They can create new accounts, view analytics, etc.')}
            buttonText={t('Invite a team member')}
        />

        <InvitedListAccordion
            lcuid={selectedAgency.lcuid}
        />

        <ButtonScrollToTopFab />
    </>
}

const mapStateToProps = state => {
    return {
        selectedAgency: selectedAgency(state),
        selectedAgencyRoles: selectedAgencyRoles(state),
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

const UsersWithoutLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);

export { UsersWithoutLayout }
export default withRouter(withHomeLayout(UsersWithoutLayout));
