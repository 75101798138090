import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEulaAccepted } from '../../selectors/user';

const EulaGate = (props) => {
    const { isEulaAccepted, children } = props;

    return isEulaAccepted
        ? children
        : null;
}

const mapStateToProps = state => {
    return {
        isEulaAccepted: isEulaAccepted(state)
    }
}

export default withRouter(
    connect(
        mapStateToProps
    )(
        EulaGate
    )
);