import { connect } from 'react-redux';
import { useEffect } from 'react';
import { setContext } from '../actions/user';
import { useQueryString } from '../helpers/hooks';
import { QueryString } from '../helpers/constants';
// import { useHistory } from 'react-router';
// import { removeQueryString } from '../helpers/url';

const ContextInitializer = (props) => {
    const { setContext } = props;
    // const history = useHistory();
    const contextAccountId = useQueryString(QueryString.contextAccountId);
    const contextAgencyId = useQueryString(QueryString.contextAgencyId);

    useEffect(() => {
        if (contextAccountId) {
            setContext({
                accountId: contextAccountId
            });
        }

        if (contextAgencyId) {
            setContext({
                agencyId: contextAgencyId
            });
        }

        // TODO: probably we don't need to clear queryString, because:
        // 1. If it will be cleared, then if user will copy current url it will be not valid (it will not contain context_account_id)
        // 2. We had concern that leaving "context_account_id" in url will cause problems
        //    when user will change account and then refresh page, it could switch account back
        //    but this will not happed, since when account is being changed queryString will be cleared automatically

        // removeQueryString(history, QueryString.contextAccountId);

    }, [contextAccountId, contextAgencyId])

    return null;
};

const mapDispatchToProps = dispatch => {
    return {
        setContext: data => {
            dispatch(setContext(data));
        }
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ContextInitializer);
