import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IonCard, IonCardTitle } from '@ionic/react';
import { Skeleton } from '@material-ui/lab';
import ExportDetailsStatsSkeleton from './ExportDetailsStatsSkeleton';

const useStyles = makeStyles(theme => {
    return {
        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginBottom: theme.spacing(2),
            fontWeight: 'bold'
        },
        titleSkeleton: {
            width: '250px',
            padding: 10,
        },
        attributeValue: {
            textAlign: 'left'
        },
        chip: {
            borderRadius: 16
        },
        quickStatsContainerSkeleton: {
            width: '100%',
            padding: 10,
        },
        statsGridSkeleton: {
            padding: 10,
        },
        quickStatsTitleBoxSkeleton: {
            display: 'flex',
            alignItems: 'baseline',
            paddingLeft: 10,
            flexDirection: 'column',
        },
        quickStatsTitleSkeleton: {
            width: 200,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12pt',
            },
        },
    }
})

function ExportDetailsSkeleton() {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.titleSkeleton}>
                <Skeleton variant="rect" height={24} />
            </Box>
            <IonCard className={classes.quickStatsContainerSkeleton}>
                <Box className={classes.quickStatsTitleBoxSkeleton}>
                    <IonCardTitle className={classes.quickStatsTitleSkeleton}>
                        <Skeleton variant="rect" height={24} />
                    </IonCardTitle>
                    <ExportDetailsStatsSkeleton />
                </Box>
            </IonCard>
        </>
    );
}

export default ExportDetailsSkeleton;
