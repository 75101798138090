import React from 'react';
import { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { selectedAccountIds } from '../../selectors/user';
import DashboardAccountNew from './DashboardAccountNew';
import { getAds, getExports } from '../../actions/dashboard';
import { getPermissions } from '../../actions/user';
import { getUser } from '../../selectors/user';
import { invalidate, searchItems } from '../../actions/inventory';

const useStyles = makeStyles(theme => ({
    accountDashboard: {
        marginTop: theme.spacing(0),
    }
}));

const Dashboards = (props) => {
    const { showInventory, getAds, accountIds, getInventory, getExports, getPermissions } = props;
    const classes = useStyles();

    useEffect(() => {
        if (!accountIds.length) {
            return;
        }

        getPermissions();
        getAds();
        getExports();

        if (showInventory) {
            getInventory();
        }
        // eslint-disable-next-line
    }, [JSON.stringify(accountIds)])

    return <>
        {accountIds.length > 0
            && <DashboardAccountNew className={classes.accountDashboard} />}
    </>
}

const mapStateToProps = state => {
    return {
        accountIds: selectedAccountIds(state),
        user: getUser(state),

        showInventory: false, //hasRealEstateInventoryClass(state) || hasPhotoStreamInventoryClass(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAds: (accountIds) => dispatch(getAds(accountIds)),
        getExports: () => dispatch(getExports()),
        getPermissions: () => dispatch(getPermissions()),
        getInventory: () => {
            dispatch(invalidate());

            return dispatch(searchItems({
                per_page: 20
            }))
        }
    };
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Dashboards);
