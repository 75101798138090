import React from 'react';
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    Typography,
    Box,
    IconButton,
    ListItemIcon,
    Tooltip
} from '@material-ui/core';
import { DateRange, Delete } from '@material-ui/icons';
import { Tv } from '@material-ui/icons';

const useStyles = makeStyles(() => {
    return {
        accordion: {
        },
        accordionSummaryContent: {
            margin: 0
        }
    }
})

const RowsAccordions = ({ rows, hideSchedule, hasSchedule, hideScreenSelector, hasBoardIds, onDelete, onBoardClick, onScheduleClick }) => {

    return (
        <Box>
            {rows.map(row => (<RowAccordion key={row.lcuid}
                row={row}
                onDelete={onDelete}
                onScheduleClick={onScheduleClick}
                hideSchedule={hideSchedule}
                hasSchedule={hasSchedule}
                hideScreenSelector={hideScreenSelector}
                hasBoardIds={hasBoardIds}
                onBoardFilterClick={(g) => onBoardClick(g)}
            />))}
        </Box>
    );
}

const RowAccordion = ({
    row, hideSchedule, hasSchedule, onDelete, onScheduleClick, onBoardFilterClick, hideScreenSelector, hasBoardIds
}) => {
    const classes = useStyles();

    return <Accordion expanded={false} className={classes.accordion}>
        <AccordionSummary classes={{ content: classes.accordionSummaryContent }}>
            {row.Icon
                && <ListItemIcon style={{ display: 'flex', alignItems: 'center', minWidth: '36px' }}>
                    {row.Icon}
                </ListItemIcon>}
            <Typography style={{ alignSelf: 'center' }}>
                {row.name}
                {row.description
                    && <Typography component="span" variant='body2' color='textSecondary' style={{ marginLeft: 8 }}>
                        {row.description}
                    </Typography>}
            </Typography>
            <Box ml="auto">
                {!hideSchedule
                    && <IconButton
                        onClick={() => onScheduleClick?.(row)}
                        color={hasSchedule?.(row)
                            ? "secondary"
                            : "default"}
                    >
                        <DateRange />
                    </IconButton>}

                {!hideScreenSelector && <Tooltip title={'Select Screens'}>
                    <IconButton
                        color={hasBoardIds?.(row)
                            ? "secondary"
                            : "default"}
                        onClick={() => onBoardFilterClick?.(row)}>
                        <Tv />
                    </IconButton>
                </Tooltip>
                }
                <IconButton onClick={() => onDelete(row)}>
                    <Delete />
                </IconButton>
            </Box>
        </AccordionSummary>
    </Accordion>
}

export default RowsAccordions;
