import { createSelector } from "reselect";
import { RoutePaths } from "../helpers/constants";
import { sortNumber } from "../helpers/sorters";
import {
    getUser, selectedAgencyIds,
    selectedAgency, currentUserHasPermission,
    selectedAccount
} from "./user";
import { getAppLogoUrl } from '../helpers/environment';
import { hasApp } from "./objects";

export const getLayout = state => state.layout;
export const getApprovalsCounts = state => state.agencies.approvalsCounts;
export const getCampaignsPendingTrafficCount = state => state.agencies.campaignsPendingTrafficCount;
export const getUnreadStoriesCount = state => state.agencies.unreadStoriesCount;
export const getFeatures = state => state.featureToggle.features;

export const getMenuLinks = createSelector(
    [getLayout, getApprovalsCounts, getCampaignsPendingTrafficCount, getUnreadStoriesCount,
        selectedAgencyIds, getFeatures, getUser, selectedAgency, currentUserHasPermission,
        selectedAccount,
        state => state],
    (layout, approvalsCounts, campaignsPendingTrafficCount, unreadStoriesCount, selectedAgencyIds, features, user,
        selectedAgency, currentUserHasPermission, selectedAccount,
        state) => {

        const selectedAgencyId = selectedAgencyIds[0];

        const links = layout.links
            .map(l => {
                if (l.route === RoutePaths.dev.applications) {
                    return { ...l, hidden: !user.isDeveloperMode };
                }

                if (l.route === RoutePaths.dev.designerElements) {
                    return { ...l, hidden: !user.isDeveloperMode };
                }

                if (l.route === RoutePaths.accountChildren) {
                    return { ...l, hidden: !selectedAccount?.is_parent_account }
                }

                return l;
            })
            .filter(l => !l.hidden)
            .filter(l => !l.feature || (features[l.feature]))
            .filter(l => l.permission
                ? currentUserHasPermission(l.permission)
                : true)
            .filter(l => l.agencyClasses && selectedAgency
                ? l.agencyClasses.includes(selectedAgency.agency_class)
                : true)
            // Routes that require application
            .filter(
                l => l.appLcuid
                    ? hasApp(state, selectedAgency?.lcuid, l.appLcuid)
                    : true
            )
            .map(link => {
                if (link.route === RoutePaths.approvals && approvalsCounts[selectedAgencyId]) {
                    return {
                        ...link,

                        title: `${link.title} (${approvalsCounts[selectedAgencyId]})`
                    }
                }
                if (link.route === RoutePaths.agencyCampaigns && campaignsPendingTrafficCount) {
                    return {
                        ...link,

                        title: `${link.title} (${campaignsPendingTrafficCount})`
                    }
                }
                if (link.route === RoutePaths.agencyFeed && unreadStoriesCount) {
                    return {
                        ...link,

                        title: `${link.title} (${unreadStoriesCount})`
                    }
                }

                return link;
            })

        return links.sort((a, b) => sortNumber(a.order, b.order))
    }
)

//White Labeling Stuff

export const getApplicationLogoUrl = () => {
    return getAppLogoUrl();
}
