import React from 'react';
import { makeStyles, DialogActions, Button, useMediaQuery, useTheme, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../components/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
    }
})

const CostRow = ({ row, lifetimeCost }) => {

    const pctOfSpend = (row.total_cost / lifetimeCost) * 100;

    return <TableRow key={row.venue_type_id}>
        <TableCell component="th" scope="row">
            {row.agency?.name}
        </TableCell>
        <TableCell>
            {row.open_ooh_venue_taxonomy?.fully_qualified_name}
        </TableCell>
        <TableCell align="right">
            {row.total_spots}
        </TableCell>
        <TableCell align="right" >
            {row.total_impressions}
        </TableCell>
        <TableCell align="right">${row.total_cost?.toFixed(2)}</TableCell>
        <TableCell align="right">{pctOfSpend.toFixed(2)}%</TableCell>
    </TableRow>
}

export const CampaignBudgetSpentDialog = ({ open, handleClose,
    lifetimeCost, costRollup }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="md"
            scroll="paper"
            open={open}
            onClose={handleClose}
            title={t('Budget Spent')}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <h1> {t('Campaign Budget Spent')}</h1>
                <h2> {t('Lifetime Cost')} : ${lifetimeCost}</h2>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Media Owner')}</TableCell>
                                <TableCell>{t('Venue')}</TableCell>
                                <TableCell align="right">Spots</TableCell>
                                <TableCell align="right">Impressions</TableCell>
                                <TableCell align="right">Cost</TableCell>
                                <TableCell align="right">Pct of Spend</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {costRollup && costRollup.sort((a, b) => b.total_cost - a.total_cost)
                                .map((row, index) => {
                                    return <CostRow
                                        key={index}
                                        row={row}
                                        lifetimeCost={lifetimeCost}
                                    />
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained">
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
