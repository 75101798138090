import { FormControl, FormHelperText, FormLabel, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

const LucitFormFieldMultipleSelect = ({ field, onChange, value }) => {

    const [localValue, setLocalValue] = React.useState(null);

    useEffect(() => {
        if (localValue === null && value) {
            setLocalValue(value);
        }

    }, [value])

    return <>
        <FormControl style={{ width: "100%" }}>
            <FormLabel component="legend">{field.name}</FormLabel>

            <FormHelperText>{field.description}</FormHelperText>

            <Autocomplete
                fullWidth
                value={localValue ?? []}
                onChange={(_, value) => {
                    setLocalValue(value)
                    onChange(value)
                }}
                name="formMultelect"
                multiple
                limitTags={2}
                options={field.enum_options}
                disableCloseOnSelect
                getOptionSelected={(option) => localValue ? localValue.some(e => e.value === option.value) : false}
                getOptionLabel={(option) => option.label}
                renderInput={renderProps => <TextField
                    {...renderProps}
                    variant="outlined"
                    fullWidth={true}
                />}
            />
        </FormControl>
    </>
}

export default LucitFormFieldMultipleSelect;
