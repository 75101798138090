import {
    Accordion, AccordionDetails, AccordionSummary, Box, Grid, List,
    ListItem, ListSubheader, Typography, makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { Designer } from '../../helpers/constants';
import {
    getLastColorUsed, getLastFontUsed, html, previewAccount, previewInventoryItem, previewBoard, getFieldMap
} from '../../selectors/designer';
import { idExistsInHTMLString } from './DesignerHTML';
import { replaceCSSForId, setHTML, setSelectedEditor, syncLayers } from '../../actions/designer';
import { getCSSSizingStringForNewElements } from './DesignerElements';
import { macroHasBeenUsed } from '../../selectors/designerMacro';
import { getDataSourceObject, getDataSourceMacroValueFromObject } from '../../helpers/designer/macroses';
import AddCustomFieldDialog from './AddCustomFieldDialog';
import { Edit as EditIcon, Add as AddIcon } from '@material-ui/icons';
import EditCustomFieldDialog from './EditCustomFieldDialog';
import { getUniqueId } from './DesignerElements';

const useStyles = makeStyles(() => {
    return {
        accordionRoot: {
            width: '100%',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&.Mui-expanded': {
                margin: 'auto',
            },
        },
        accordionSummaryRoot: {
            padding: "0 8px",
            minHeight: 32,
            '&.Mui-expanded': {
                minHeight: 32,
            }
        },
        accordionSummaryContent: {
            width: 'calc(100% - 30px)',
            margin: 'auto',
            '&.Mui-expanded': {
                margin: 'auto',
            },
        },
        accordionDetailsRoot: {
            flexDirection: "column",
            padding: '0',
            paddingLeft: 4
        },

        macroRow: {
            marginBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
        },
        dataSourceRowBox: {
            position: 'relative',
            padding: "4px",
            border: "1px solid transparent",
            borderRadius: "5px",
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
                border: "1px solid #0000001f",
                boxShadow: "0px 0px 5px 0px #0000001f",
                '& .MuiBox-root': {
                    visibility: 'visible'
                }
            }
        },
        dataConnectedElementIcon: {
            width: "10px",
            height: "10px",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: 4,
            backgroundColor: "#02c9c9",
            border: "1px solid #ffffff",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        },
        dataConnectedElementImage: {
            width: "15px",
            height: "15px",
            marginRight: 4,
            display: "inline-block",
            border: "1px solid #dddddd",
            borderRadius: "10%",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        },
        editCustomFieldIcon: {
            width: "18px",
            marginRight: "4px",
            '&:hover': {
                color: '#808080 !important',
            }
        },
        addCustomFieldIcon: {
            width: "20px",
            marginLeft: "auto",
            marginRight: "4px",
            '&:hover': {
                color: '#808080 !important',
            }
        }
    }
})

export const DesignerMacros = ({ query }) => {

    const fieldMap = useSelector(state => getFieldMap(state, query))

    if (fieldMap == null) {
        return null;
    }

    const groups = Object.values(fieldMap)
        // workaround to sort groups
        .sort((a, b) => {
            if (a.object != 'item' && b.object == 'item')
                return 1;
            if (a.object == 'item' && b.object != 'item')
                return -1;
            if (a.object != 'account' && b.object == 'account')
                return 1;
            if (a.object == 'account' && b.object != 'account')
                return -1;

            return 0;
        });

    return <>
        <List style={{ width: '100%' }} disablePadding>
            {groups
                .filter(g => Object.values(g.field_map).some(subGroup =>
                    subGroup.class === Designer.CustomFieldTemplate.class || subGroup.fields.length > 0))
                .map(g => <MacroGroup
                    key={g.object}
                    group={g}
                    expanded={true}
                    handleExpand={() => { }}
                />)}
        </List>
    </>
}

const isCustomFieldGroup = (group) => {
    return group.object == Designer.CustomFieldTemplate.object
}

const isCustomField = (field) => {
    return field.object == Designer.CustomFieldTemplate.object
}

const MacroGroup = ({ group, expanded, handleExpand }) => {
    const classes = useStyles();

    const [showAddCustomFieldDialog, setShowAddCustomFieldDialog] = useState(false);

    return <Accordion
        classes={{ root: classes.accordionRoot }}
        expanded={expanded}
        onChange={handleExpand}>
        <AccordionSummary
            classes={{
                root: classes.accordionSummaryRoot,
                content: classes.accordionSummaryContent,
                expandIcon: "MuiIconButton-sizeSmall"
            }}
            expandIcon={null}
            title={group.description}
        >
            <LCTypography
                variant="body2"
                style={{ fontWeight: 500 }}>
                {group.name}
            </LCTypography>

            {isCustomFieldGroup(group) && <><AddIcon
                title={"Add Custom Field"}
                size="small"
                className={classes.addCustomFieldIcon}
                onClick={(event) => {
                    setShowAddCustomFieldDialog(true)
                    event.stopPropagation()
                    return false
                }}
            />

                {showAddCustomFieldDialog && <AddCustomFieldDialog
                    open={showAddCustomFieldDialog}
                    handleClose={() => setShowAddCustomFieldDialog(false)}
                />}

            </>

            }

        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <List disablePadding style={{ paddingBottom: 8 }}>
                {Object.values(group.field_map)
                    .filter(subgroup => subgroup.class === Designer.CustomFieldTemplate.class || subgroup.fields.length > 0)
                    .map((subgroup, idx) => <MacroSubGroup
                        key={idx}
                        group={group}
                        subgroup={subgroup}
                        isFirst={idx === 0} />)}
            </List>
        </AccordionDetails>
    </Accordion>
}

const MacroSubGroup = ({ group, subgroup, isFirst }) => {

    const fields = subgroup.fields

    return <>
        {subgroup.name != 'Common'
            && <ListSubheader
                style={{ padding: '0 0 0 8px', lineHeight: '16px', marginTop: isFirst ? 0 : 8 }}>
                <Typography
                    variant="caption" style={{ fontWeight: 500 }}>
                    {subgroup.name}
                </Typography>
            </ListSubheader>}

        {fields.map(field => <MacroField group={group} key={field.id} field={field} />)}
    </>
}

const MacroField = ({ group, field }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const inventoryItem = useSelector(previewInventoryItem);
    const account = useSelector(previewAccount);
    const digitalBoard = useSelector(previewBoard);
    const user = inventoryItem ? inventoryItem.user : null
    const hasBeenUsed = useSelector(state => macroHasBeenUsed(state, field.macro))

    const object = getDataSourceObject(group, inventoryItem, account, user, digitalBoard)
    const value = getDataSourceMacroValueFromObject(field, object, false)

    const id = getIdForDataSourceElement(field)
    const macroHtml = getMacroHtml(field, id)

    const designerHtml = useSelector(html);
    const lastFontUsed = useSelector(getLastFontUsed);
    const lastColorUsed = useSelector(getLastColorUsed);

    const fontName = lastFontUsed ? lastFontUsed.name : Designer.DefaultFontName
    const lastColorShouldBeIgnored = Designer.IgnoreLastColorIfOneOf.includes(lastColorUsed)
    const color = (lastColorUsed && !lastColorShouldBeIgnored) ? lastColorUsed : Designer.DefaultTextColor

    const fontsCss = field.type === Designer.ObjectTypes.Text ? "font-family:'" + fontName + "';" : ""
    const colorCss = field.type === Designer.ObjectTypes.Text ? "color:" + color + ";" : ""
    const css = field.defaultCss + getCSSSizingStringForNewElements() + fontsCss + colorCss

    const [showEditCustomField, setShowEditCustomField] = useState(null);

    const onAddToHtml = () => {
        if (!idExistsInHTMLString(id, designerHtml)) {
            //First set the initial CSS
            if (css)
                dispatch(replaceCSSForId(id, css));

            //Then, add the element to
            const newHtml = designerHtml + "\n\n" + macroHtml
            dispatch(setHTML(newHtml))
                .then(() => {
                    dispatch(syncLayers())
                    dispatch(setSelectedEditor(id))
                })
        }
    }

    const displayText = () => {
        if (!value)
            return ""

        if (typeof value != "string")
            return value

        if (value.length < 24)
            return value

        return value.substring(0, 24) + "..."
    }

    return <ListItem style={{ padding: '0 8px 0 8px' }}>
        <Grid item xs={12} className={classes.macroRow}
            style={{ color: hasBeenUsed ? "#00000042" : "inherit", }}
            onClick={onAddToHtml}
            title={field.macroCode + ` ` + (field.type === Designer.ObjectTypes.Text ? displayText() : '')}
        >
            <Box className={classes.dataSourceRowBox}>
                <Grid container>
                    <Grid item xs={11} style={{ fontWeight: "bold" }}>

                        <span className={classes.dataConnectedElementIcon} />

                        {
                            field.type === Designer.ObjectTypes.Image &&
                            <img className={classes.dataConnectedElementImage} src={value} />
                        }

                        {field.name}
                    </Grid>
                    <Grid item xs={1}>
                        {isCustomField(field) &&
                            <EditIcon
                                title={"Edit Custom Field"}
                                size="small"
                                className={classes.editCustomFieldIcon}
                                onClick={(event) => {
                                    setShowEditCustomField(field.id)
                                    event.stopPropagation()
                                    return false
                                }}
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
        </Grid>

        {showEditCustomField &&
            <EditCustomFieldDialog
                open={showEditCustomField}
                customFieldId={showEditCustomField}
                handleClose={() => setShowEditCustomField(null)}
            />
        }
    </ListItem>
}

export const getIdForDataSourceElement = (element) => {
    return "data_source_" + element.type + '_' + getUniqueId();
}

export const getMacroHtml = (element, id) => {

    //If the macroCode contains a function with a double quote, it will break the HTML
    //We need to escape it
    const macroCode = element.macroCode ? element.macroCode.replace(/"/g, '&quot;') : element.macroCode
    const macroPlaceholder = element.placeholder ? element.placeholder.replace(/"/g, '&quot;') : element.placeholder

    switch (element.type) {
        case Designer.ObjectTypes.Text:
            return ' <div x-objectcode="' + element.id + '" id="' + id + '"'
                + ' class="' + element.defaultClasses + '"'
                + ' x-placeholder="' + macroPlaceholder + '"'
                + ' data-value="' + macroCode + '">' + macroCode + '</div>'
        case Designer.ObjectTypes.Image:
            return '<div x-objectcode="' + element.id + '" id="' + id + '"'
                + ' class="' + element.defaultClasses + '"'
                + ' x-placeholder="' + macroPlaceholder + '"'
                + ' style="background-image : url(' + macroCode
                + ')" data-value="' + macroCode + '"></div>'
    }

    return null
}
