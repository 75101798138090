import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import Sorting from './Sorting';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => {
    return {
        filtersBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(-6)

        },

        iconButtonOutlined: {
            padding: '5px 8px',
            minWidth: 'auto'
        }
    }
})

function SearchPanel({ values, submit, disabled, sortingFields, children }) {
    const classes = useStyles();

    return <>
        <Box className={classes.filtersBox}>
            <Hidden smDown>
                <Sorting
                    rootProps={{ ml: 'auto' }}
                    values={values}
                    submit={submit}
                    disabled={disabled}

                    fields={sortingFields}
                />
            </Hidden>
            <Hidden mdUp>
                <Sorting
                    rootProps={{ ml: 1 }}
                    buttonProps={{ className: classes.iconButtonOutlined, startIcon: null }}
                    renderSelected={() => <Sort />}
                    values={values}
                    submit={submit}
                    disabled={disabled}

                    fields={sortingFields}
                />
            </Hidden>
        </Box>

        {children}

    </>;
}

SearchPanel.propTypes = {
    values: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    sortingFields: PropTypes.array.isRequired,

    submit: PropTypes.func,
    disabled: PropTypes.bool
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPanel);
