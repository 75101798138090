import React, { useEffect } from 'react';
import { Box } from "@material-ui/core";
import { GenericDialog } from "../../components/modals/GenericDialog";
import CircularProgressCentered from "../../components/material/CircularProgressCentered";
import LCTypography from "../../components/material/LCTypography";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile } from '../../actions/user';
import ProfileAvatar from './ProfileAvatar';

const SwitchingAccountDialog = () => {
    const dispatch = useDispatch();
    const { profileSwitch,
        profileSwitched,
        profileSwitchAnimateDelay } = useSelector(state => state.layout)

    useEffect(() => {
        if (profileSwitch) {
            const timeoutId = setTimeout(() => {
                /* It's important to select new profile before switching to new page,
                 * so then when landing on new page the requests will be always made on behalf of new profile.
                 * The challange is that we can't atomicly change profile and redirect to new page.
                 */
                dispatch(selectProfile(profileSwitch));

                if (profileSwitched) {
                    try { profileSwitched(); }
                    catch (error) {
                        console.error(error);
                    }
                }
            }, profileSwitchAnimateDelay)

            return () => clearTimeout(timeoutId);
        }
    }, [profileSwitch])

    return <GenericDialog
        dialogProps={{
            open: Boolean(profileSwitch),
            fullWidth: true,
            hideCloseButton: true,
            maxWidth: 'xs'
        }}
        ContentComponent={<Box>
            {profileSwitch
                && <>
                    <CircularProgressCentered rootStyle={{ margin: 16, marginBottom: 24 }} />
                    <LCTypography variant="body1" align='center'>Switching to:</LCTypography>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <ProfileAvatar profile={profileSwitch} />
                        <LCTypography
                            variant="body1"
                            color='secondary'
                            style={{ marginLeft: 8 }}
                        >
                            <b>{profileSwitch.name}</b>
                        </LCTypography>
                    </Box>
                </>}
        </Box >}
    />
}

export default SwitchingAccountDialog;
