import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography, makeStyles } from '@material-ui/core';
import { AccountListItems } from '../account';
import { accountsTree, getUserRoles, selectedAccount, selectedAgency } from '../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { debouncePromise } from '../../helpers/async';
import { useTranslation } from 'react-i18next';
import { getAgencyAccounts } from '../../actions/agencies';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { Delete, People, Settings } from '@material-ui/icons';
import { ProfileType, RoutePaths } from '../../helpers/constants';
import { joinAccount } from '../../actions/account';
import AccountDeleteButton from '../account/AccountDeleteButton';
import AccountUsersDialog from '../account/AccountUsersDialog';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount';
import { ACCOUNT_ADD } from '../../helpers/actionTypes';
import { ProfileChangeButton } from '../layout/ProfileChangeButton';
import { searchTermInText } from '../../helpers/string';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            color: theme.palette.error.main,
            '&:hover': {
                color: theme.palette.error.dark,
            }
        }
    }
})

const AgencyAccounts = ({ agency }) => {
    // const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [showAddNewAccount, setShowAddNewAccount] = useState(false);
    const [query, setQuery] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAgencyAccounts(agency.lcuid));
    }, [])

    const loading = useSelector(state => state.agencies.accountsLoading);
    const searchDebounce = useCallback(debouncePromise((query) => setQuery(query), 800), []);

    const accounts = useSelector(state => state.agencies.accounts);

    const accountsFiltered = useMemo(() => {

        return accountsTree(
            accounts.filter(account => searchTermInText(query, [account.name]))
        );
    }, [query, accounts]);

    return <>
        <Box>
            <Button
                onClick={() => setShowAddNewAccount(true)}
                variant="contained"
                color="primary"
            >Add Account</Button>
            <OperatorAddAccount
                showAgencyAndOperator={false}
                showAgency={false}
                open={showAddNewAccount}
                handleClose={() => setShowAddNewAccount(false)}
                onAdded={account => dispatch({ type: ACCOUNT_ADD, account })}
            />
        </Box>
        <Box mt={2} mb={2}>
            <TextField
                variant="outlined"
                fullWidth={true}
                label={t('Search')}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                    searchDebounce(e.target.value);
                }}
            />
        </Box>

        {loading
            && <CircularProgressCentered />}

        {accountsFiltered.length === 0
            && query
            && <Typography>
                No accounts found for your search query
            </Typography>}

        <AccountListItems
            accounts={accountsFiltered}
            handleChange={(_, toggleOpen) => toggleOpen()}
            level={0}

            ListItemProps={{ selected: false }}
            arrowPosition="left"
            Buttons={AgencyAccountButtons}
        />
    </>
}

export const AgencyAccountButtons = ({ account, onDelete }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [joining, setJoining] = useState(false);
    const [usersOpen, setUsersOpen] = useState(false);

    const user = useSelector(state => state.user);
    const agency = useSelector(selectedAgency);
    const _account = useSelector(selectedAccount);
    const isAgencyAdmin = agency != null && getUserRoles(agency).some(role => role == 'agency_admin');
    const isAccountAdmin = _account != null && getUserRoles(_account).some(role => role == 'account_admin');

    const userAccounts = useSelector(state => state.user.accounts);
    const hasJoined = userAccounts.some(a => a.lcuid === account.lcuid);

    return <Box position='absolute' right={0}>
        {!hasJoined
            && (isAgencyAdmin || isAccountAdmin)
            && <Button
                disabled={joining}
                onClick={() => {
                    setJoining(true);
                    dispatch(joinAccount(account.lcuid))
                        .finally(() => setJoining(false))
                }}
                variant='outlined'
                size="small">Join</Button>}
        {hasJoined
            && <ProfileChangeButton
                pathname={RoutePaths.home}
                targetProfile={{ ...account, type: ProfileType.account }}
                size="small"
                startIcon={<Settings />}
                variant="outlined"
                style={{ marginLeft: 8 }}
            >Manage</ProfileChangeButton>}
        {(isAgencyAdmin || isAccountAdmin)
            && <Button
                onClick={() => setUsersOpen(true)}
                size="small"
                startIcon={<People />}
                variant="outlined"
                style={{ marginLeft: 8 }}
            >Users</Button>}

        {(isAgencyAdmin || isAccountAdmin || account.created_by_user_id == user.id)
            && <AccountDeleteButton
                account={account}
                onDelete={() => onDelete && onDelete(account)}
                renderButton={props => <IconButton
                    {...props}
                    className={classes.removeButton}
                    style={{ marginLeft: 8 }}>
                    <Delete />
                </IconButton>}
            />}

        {usersOpen
            && <AccountUsersDialog
                account={account}
                agency={agency}
                open={true}
                handleClose={() => setUsersOpen(false)}
            />}
    </Box>;
}

export default AgencyAccounts;
