import React from 'react';
import { Redirect, Route } from "react-router";

import { RoutePaths } from "../../helpers/constants";
import { store } from '../../reducers';
import LucitRoute from './LucitRoute';

export const RouteProtected = (props) => {
    const { component: Component, sheetOpenDefault, path, ...rest } = props;

    //If we are NOT logged in then, check to see if the path is the home page (private)
    //And if so, send to the public home page
    //If not send to the login page

    return <Route
        {...rest}
        path={path}
        key={Date.now()}
        render={(props) => (
            store.getState().user.isLoggedIn
                ? <LucitRoute
                    component={Component}
                    sheetOpenDefault={sheetOpenDefault}
                    {...rest}
                    {...props} />
                : (
                    path === RoutePaths.home
                        ? <Redirect to={RoutePaths.public.home} />
                        : <Redirect to={RoutePaths.login} />
                )
        )} />
}

export default RouteProtected;
