import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { IonImg } from '@ionic/react';
import { Grid } from '@material-ui/core';

const AgencyTeamApplicationAttachedStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    const storyData = story.story_data || {};

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} added an app`}
        story={story}
        Media={<Grid container>
            <Grid item xs={6}>
                {storyData.image
                    ? <IonImg src={storyData.image} style={{ maxWidth: "150px", margin: "auto" }} />
                    : null
                }
            </Grid>
            <Grid item xs={6}>
                {storyData.parent_image
                    ? <IonImg src={storyData.parent_image} style={{ maxWidth: "150px", margin: "auto" }} />
                    : null
                }
            </Grid>
        </Grid>}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Added the <> </>
            {storyData.name} application to
            <Link
                component={RouterLink}
                color="secondary"
                to={`/` + storyData.parent_slug}> {storyData.parent_name}</Link>
        </LCTypography>
    </BaseStory >;
};

export default AgencyTeamApplicationAttachedStory;
