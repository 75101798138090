import { getFormValues } from "redux-form";

import { lucitApi } from "../services/lucitApi"
import { selectedAccountId } from "../selectors/user";
import { photoByIdSelector } from "../selectors/photos";
import {
    INVENTORY_SEARCH_REQUEST, INVENTORY_SEARCH_RESULT, INVENTORY_ITEM_SELECT, INVENTORY_NEW_ITEM,
    INVENTORY_NEW_ITEM_PHOTO, INVENTORY_SELECT_ALL, INVENTORY_UNSELECT_ALL, INVENTORY_NEXT_PAGE_RESULT,
    INVENTORY_NEXT_PAGE_REQUEST, INVENTORY_SAVE_SCROLL_POSITION, INVENTORY_SET_PRICE_OVERRIDE,
    INVENTORY_CLEAR_PRICE_OVERRIDE, INVENTORY_GETITEM_RESULT, INVENTORY_INVALIDATE, INVENTORY_ITEM_UPDATE,
    INVENTORY_ITEM_REMOVE_PHOTO, INVENTORY_SET_TITLE_OVERRIDE, INVENTORY_CLEAR_TITLE_OVERRIDE,
    PHOTO_GETBYID, INVENTORY_DELETE_ITEM, INVENTORY_BOARD_SIZES_RESULT, INVENTORY_ACTIVATE_DRAFT
} from "../helpers/actionTypes";
import { filters, itemsSelected, inventoryItems, hasNextPage } from "../selectors/inventory";
import { INVENTORY_FILTERS_FORM } from "../containers/inventory/filters/InventoryFilters";
import { InventoryPhotoSet } from "../helpers/constants"
import { clearTimezoneISO } from "../helpers/date";
import { createPlaceholderPhotoImage } from "../selectors/photos"

export function loadBoardSizes(accountIds, inventoryExportId) {
    return (dispatch) => {
        return lucitApi.campaigns.getBoardSizes({ accountIds, inventoryExportId })
            .then(data => {
                dispatch({
                    type: INVENTORY_BOARD_SIZES_RESULT,
                    data: data.board_sizes
                })
            })
    }
}

export function createItem(data) {
    const {
        itemClass, photo, isDraft, inventoryExportId, selectedTemplateLcuid, auto_activate, title, drive_template_name, ...rest
    } = data;

    return (dispatch, getState) => {
        const state = getState();
        const accountId = selectedAccountId(state);

        // eslint-disable-next-line no-undef
        let promise = Promise.resolve();

        if (isDraft) {
            promise = lucitApi.inventory.createDraft({
                //always take first account
                ...rest,
                title: title ? title : drive_template_name,
                account_id: accountId,
                inventory_item_class: itemClass,
                auto_activate: auto_activate,
                inventory_export_id: inventoryExportId,
                drive_template_lcuid: selectedTemplateLcuid
            });
        } else {
            promise = lucitApi.inventory.create({
                ...rest,
                title: title ? title : drive_template_name,
                inventory_item_class: itemClass,
                //always take first account
                account_id: accountId,
                inventory_export_id: inventoryExportId,
                drive_template_lcuid: selectedTemplateLcuid
            });
        }

        return promise.then(item => {
            dispatch({
                type: INVENTORY_NEW_ITEM,
                item
            });

            let uploadImage = null;

            if (photo)
                uploadImage = photo.image;
            else
                uploadImage = createPlaceholderPhotoImage(100, 100).src  //hack See T5335

            return lucitApi.uploads.uploadInventoryPhoto(uploadImage, item.id, InventoryPhotoSet.base)
                .then(data => {
                    dispatch({
                        type: INVENTORY_NEW_ITEM_PHOTO,
                        itemId: item.id,
                        photo: data
                    });

                    return item;
                });
        });
    }
}

export function updateItem(itemId, data) {

    const { photo, ...rest } = data;

    return (dispatch) => {
        return lucitApi.inventory.updateItem(itemId, { ...rest })
            .then((item) => {
                dispatch({
                    type: INVENTORY_ITEM_UPDATE,
                    id: itemId,
                    item: {
                        ...item
                    }
                })

                if (photo) {

                    return lucitApi.uploads.uploadInventoryPhoto(photo.image, itemId, InventoryPhotoSet.primary_override, "image.png")
                        .then(data => {
                            dispatch({
                                type: INVENTORY_NEW_ITEM_PHOTO,
                                itemId: itemId,
                                photo: data
                            })
                            return item;
                        }
                        )
                }
                else {
                    return item;
                }
            }
            )
    }
}

export function activateItem(item) {
    return (dispatch) => {
        return lucitApi.inventory.activateDraft(item)
            .then(() => dispatch({
                type: INVENTORY_ACTIVATE_DRAFT,
                itemId: item.id
            }))
    }
}

export function deleteItem(item) {
    return dispatch => {
        return lucitApi.inventory.delete(item)
            .then(() => dispatch({
                type: INVENTORY_DELETE_ITEM,
                item
            }))
    }
}

export function setExportRunSchedule(itemId, exportId, schedule) {
    return dispatch => {
        schedule = schedule.map(x => ({
            type: x.type,

            //When x.type==="date_range"
            start_datetime: x.type === "date_range" ? clearTimezoneISO(x.startDate) : null,
            end_datetime: x.type === "date_range" && x.endDate != null
                ? clearTimezoneISO(x.endDate)
                : null,

            //When x.type==="time_range"
            start_time: x.type === "time_range" ? x.startTime : null,
            end_time: x.type === "time_range" && x.endTime != null
                ? x.endTime
                : null,

            //When x.type==="days_of_the_week"
            days: x.type === "days_of_the_week" ? x.days : null,

            //When x.type==="item_filter"
            filter_fields: x.type === "item_filter" ? x.filter_fields : null,
            filter_functions: x.type === "item_filter" ? x.filter_functions : null
        }))

        return lucitApi.inventory.setExportRunSchedule(itemId, exportId, schedule)
            .then(item => dispatch({
                type: INVENTORY_GETITEM_RESULT,
                ...item
            }));
    }
}

export function setExportDigitalBoardFilter(itemId, exportId, digitalBoardFilter) {
    return dispatch => {
        return lucitApi.inventory.setExportDigitalBoardFilter(itemId, exportId, digitalBoardFilter)
            .then(item => dispatch({
                type: INVENTORY_GETITEM_RESULT,
                ...item
            }));
    }
}

export function setExportBoardIds(itemId, exportId, boardIds) {
    return dispatch => {
        return lucitApi.inventory.setExportBoardIds(itemId, exportId, boardIds)
            .then(item => dispatch({
                type: INVENTORY_GETITEM_RESULT,
                ...item
            }));
    }
}

export function setItemTitle(item, title) {
    return dispatch => {
        return lucitApi.inventory.setTitle(item, title)
            .then(data => dispatch({
                type: INVENTORY_ITEM_UPDATE,
                id: item.id,
                item: {
                    ...data,
                    title
                }
            }))
    }
}

export function setDriveTemplate(item, driveTemplateLcuid) {
    return dispatch => {
        return lucitApi.inventory.setDriveTemplate(item, driveTemplateLcuid)
            .then(data => dispatch({
                type: INVENTORY_ITEM_UPDATE,
                id: item.id,
                item: {
                    ...data
                }
            }))
    }
}

export function setItemPhoto(item, file, replacesPhoto) {

    const replacesPhotoId = replacesPhoto ? replacesPhoto.id : null

    return dispatch => {
        return lucitApi.uploads.uploadInventoryPhoto(file, item.id, InventoryPhotoSet.primary_override, "image.png", replacesPhotoId)
            .then(data => dispatch({
                type: INVENTORY_NEW_ITEM_PHOTO,
                itemId: item.id,
                photo: data
            }))
    }
}

export function removeItemPhoto(item, photo) {
    return dispatch => {
        return lucitApi.inventory.deletePhoto(photo)
            .then(() => dispatch({
                type: INVENTORY_ITEM_REMOVE_PHOTO,
                itemId: item.id,
                photoId: photo.id
            }))
    }
}

export function inventorySearchRequest(filters) {
    return {
        type: INVENTORY_SEARCH_REQUEST,
        filters
    }
}

export function inventorySearchResult(data) {
    const { current_page, last_page, per_page, ...rest } = data;

    return {
        type: INVENTORY_SEARCH_RESULT,
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
        ...rest
    }
}

export function inventoryNextPageRequest() {
    return {
        type: INVENTORY_NEXT_PAGE_REQUEST,

    }
}

export function invalidate(inventoryClass) {
    return {
        type: INVENTORY_INVALIDATE,
        inventoryClass
    }
}

export function inventoryNextPageResult(data) {
    return {
        ...inventorySearchResult(data),

        type: INVENTORY_NEXT_PAGE_RESULT
    }
}

export function inventorySaveScroll(scrollY) {
    return {
        type: INVENTORY_SAVE_SCROLL_POSITION,
        scrollY
    }
}

export function toggleSelect(itemId) {
    return {
        type: INVENTORY_ITEM_SELECT,
        itemId
    }
}

export function unselectAll() {
    return {
        type: INVENTORY_UNSELECT_ALL
    }
}

export function selectAll() {
    return {
        type: INVENTORY_SELECT_ALL
    }
}

export function setPriceOverrideResult(item) {
    return {
        type: INVENTORY_SET_PRICE_OVERRIDE,
        ...item
    }
}

export function clearPriceOverrideResult(item) {
    return {
        type: INVENTORY_CLEAR_PRICE_OVERRIDE,
        ...item
    }
}

export function getItemResult(item) {
    return {
        type: INVENTORY_GETITEM_RESULT,
        ...item
    }
}

export function getPhoto(id) {
    return (dispatch, getState) => {
        const state = getState();
        const photo = photoByIdSelector(state)(id);

        if (photo) {
            // eslint-disable-next-line no-undef
            return Promise.resolve(photo);
        }

        return lucitApi.inventory.getPhoto(id)
            .then(data => {
                dispatch({
                    type: PHOTO_GETBYID,
                    data
                });

                return data;
            });
    }
}

export function getStats(stat, data) {
    return (_, getState) => {
        return lucitApi.inventory.getStats(stat,
            {
                accountId: selectedAccountId(getState()),
                ...data
            });
    }
}

export function searchItems(filters = {}) {
    return (dispatch, getState) => {
        dispatch(inventorySearchRequest(filters));

        const body = {
            accountId: selectedAccountId(getState()),

            ...prepareSearchFilters(filters)
        };

        return lucitApi.inventory.getInventoryItems(body)
            .then(data => dispatch(inventorySearchResult(data)))
    }
}

export function loadPage(page) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(inventoryNextPageRequest());

        const body = {
            accountId: selectedAccountId(state),
            page: page,

            ...prepareSearchFilters(filters(state))
        };

        return lucitApi.inventory.getInventoryItems(body)
            .then(data => dispatch(inventoryNextPageResult(data)))
    }
}

export function getItem(id, buildCreatives) {
    return dispatch => {
        return lucitApi.inventory.getInventoryItem(id, buildCreatives)
            .then(data => dispatch(getItemResult(data)));
    }
}

export function setTitleOverride(id, title) {
    return dispatch => {
        return lucitApi.inventory.setTitleOverride(id, title)
            .then(data => dispatch({
                type: INVENTORY_SET_TITLE_OVERRIDE,
                ...data
            }));
    }
}

export function clearTitleOverride(id, title) {
    return dispatch => {
        return lucitApi.inventory.clearTitleOverride(id, title)
            .then(data => dispatch({
                type: INVENTORY_CLEAR_TITLE_OVERRIDE,
                ...data
            }));
    }
}

export function setPriceOverride(id, price) {
    return dispatch => {
        return lucitApi.inventory.setPriceOverride(id, price)
            .then(data => dispatch(setPriceOverrideResult(data)));
    }
}

export function clearPriceOverride(id) {
    return dispatch => {
        return lucitApi.inventory.clearPriceOverride(id)
            .then(data => dispatch(clearPriceOverrideResult(data)));
    }
}

export function getItemExportStatus(id) {
    return () => {
        return lucitApi.inventory.getInventoryItemExportStatus(id);
    }
}

export function exportSelectedItems(exportIds) {
    return (_, getState) => {
        const state = getState();
        const itemIds = itemsSelected(state).map(i => i.id);

        const promises = exportIds
            .map(id => lucitApi.inventory.export(id, itemIds));

        // eslint-disable-next-line no-undef
        return Promise.all(promises)
    }
}

export function exportAllItems(exportIds) {
    return (_, getState) => {
        const state = getState();

        // eslint-disable-next-line no-undef
        let promise = Promise.resolve(inventoryItems(state));

        // Load all other items if current search result is not full
        if (hasNextPage(state)) {
            const values = getFormValues(INVENTORY_FILTERS_FORM)(state);
            const filters = {
                ...prepareSearchFilters(values),

                accountId: selectedAccountId(state),
                per_page: 10000
            };

            promise = lucitApi.inventory.getInventoryItems(filters)
                .then(data => data.data);
        }

        return promise.then(items => {
            const itemIds = items.map(i => i.id);

            const promises = exportIds
                .map(id => lucitApi.inventory.export(id, itemIds));

            // eslint-disable-next-line no-undef
            return Promise.all(promises)
        })
    }
}

function prepareSearchFilters(filters) {
    const { exports, category, ...rest } = filters;

    return {
        ...rest,

        category: category
            ? category.map(e => e.id)
            : [],
        exports: exports
            ? exports.map(e => e.id)
            : []
    };
}
