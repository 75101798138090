import { createMuiTheme } from '@material-ui/core/styles';
import { isPlatform } from '@ionic/react';
import { getAppPalette } from '../helpers/environment';

const paletteColors = getAppPalette()

// see https://material.io/resources/color
export const defaultTheme = createMuiTheme({
    palette: paletteColors,
    overrides: {
        MuiTextField: {
            root: {
                '& input[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                    },
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: isPlatform('desktop') ? '12px' : '4px',
                    height: isPlatform('desktop') ? '12px' : '4px'
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': isPlatform('desktop') ? 'inset 0 0 12px rgba(0,0,0,0.00)' : 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.15)',
                    // outline: '1px solid slategrey'
                }
            }
        },

        MuiChip: {
            root: {
                borderRadius: 4
            },
            outlinedPrimary: {
                borderWidth: '1.6px'
            },
            outlinedSecondary: {
                borderWidth: '1.6px'
            }
        }
    }
}); 
