import { Designer } from "../constants";

export const parseLayersFromHtml = html => {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, "text/html");

    const layers = [...document.body.childNodes]
        .filter(x => x.nodeType == Node.ELEMENT_NODE)
        .map(element => {

            return {
                id: element.id,
                type: 'html',
                name: element.id,
                tag: element.tagName,
                title: element.title,
                classes: [...element.classList],
                style: element.style.cssText,
                backgroundImageUrl: element.style.backgroundImage,
                objectCode: element.getAttribute('x-objectcode') || '',
                objectType: getLayerType(element),
                objectClass: getLayerClass(element),
                placeholder: element.getAttribute('x-placeholder') || '',
                fieldName: element.getAttribute('x-fieldName') || '',
                elementSettings: element.getAttribute('x-element-settings') || '{}',
                elementSettingValues: element.getAttribute('x-element-setting-values') || '{}',
                macroCode: element.getAttribute('data-value') || '',

                html: element.innerHTML
            }
        })

    return layers;
}

export const serializeLayersToHtml = layers => {
    return layers
        .map(layer => {
            const element = document.createElement(layer.tag);
            element.id = layer.id;
            element.title = layer.title;
            element.innerHTML = layer.html;

            layer.classes.forEach(x => element.classList.add(x));
            layer.objectCode && element.setAttribute('x-objectcode', layer.objectCode);
            layer.placeholder && element.setAttribute('x-placeholder', layer.placeholder);
            layer.fieldName && element.setAttribute('x-fieldName', layer.fieldName);
            layer.macroCode && element.setAttribute('data-value', layer.macroCode);

            layer.elementSettings && element.setAttribute('x-element-settings', layer.elementSettings);
            layer.elementSettingValues && element.setAttribute('x-element-setting-values', layer.elementSettingValues);

            if (layer.backgroundImageUrl && layer.objectType == Designer.ObjectTypes.Image) {

                //Replace apostrophes with quotes

                const bgImageCode = layer.backgroundImageUrl.replaceAll("\"", "'");
                const bgImageStyle = "background-image: " + bgImageCode + ";";

                element.setAttribute('style', bgImageStyle);

            }

            if (layer.macroCode && layer.objectType == Designer.ObjectTypes.Image) {
                //const bgImageCode = "url('" + layer.macroCode + "')";
                //element.style.backgroundImage = bgImageCode;
                //Note, we have to do it this weird way
                //See the bug discussed in T6830

                const bgImageStyle = "background-image: url('" + layer.macroCode + "');";

                element.setAttribute('style', bgImageStyle);
            }

            return element.outerHTML;
        })
        .join('\n\n')
}

const getLayerClass = (element) => {
    if (!element)
        return null;

    if (element.classList.contains("lc_dt_data"))
        return Designer.ObjectClasses.DataSource

    if (element.classList.contains("lc_dt_image"))
        return Designer.ObjectClasses.Asset

    if (element.classList.contains("lc_dt_text"))
        return Designer.ObjectClasses.Object

    if (element.classList.contains("lc_dt_object"))
        return Designer.ObjectClasses.Object
}

const getLayerType = (element) => {
    if (!element)
        return null;

    if (element.classList.contains("lc_dt_image"))
        return Designer.ObjectTypes.Image

    if (element.classList.contains("lc_dt_text"))
        return Designer.ObjectTypes.Text

    if (element.classList.contains("lc_dt_object_svg"))
        return Designer.ObjectTypes.Svg

    if (element.classList.contains("lc_dt_object"))
        return Designer.ObjectTypes.Object
}
