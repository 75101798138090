import React from 'react';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { connect } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { PageTitle } from '../../components';
import { ButtonScrollToTopFab } from '../../components/material';
import { selectedAgency } from '../../selectors/user';
import AgencyCampaigns from '../../containers/agency/AgencyCampaigns';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Campaigns() {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <PageTitle title={"Operator Campaigns"} />

            <LCTypography variant="h5">Campaigns</LCTypography>

            <Box mt={1} />
            <AgencyCampaigns />

            <ButtonScrollToTopFab />
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        selectedAgency: selectedAgency(state)
    }
}

export default connect(
    mapStateToProps
)(
    withHomeLayout(
        Campaigns
    )
);
