import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Hidden, makeStyles, Box, Link } from '@material-ui/core';
import ExportDetailsContainerGroup from '../containers/exports/ExportDetailsContainerGroup';
import ExportDetailsSkeleton from '../containers/exports/ExportDetailsSkeleton';
import { RoutePaths } from '../helpers/constants';
import { showError } from '../actions/snackbar';
import { getExport } from '../actions/exports';
import { history } from '../helpers/history';
import ExportItemList from '../containers/exports/itemsList/ExportItemList';
import { exportsByIdSelector, isItemsIdFilterAllowed } from '../selectors/exports';
import { PageTitle, WeightsEditorDialog } from '../components';
import { hasRequestedItems } from '../selectors/inventoryStatus';
import Tooltip from '../components/material/Tooltip';
import PostButton from '../containers/inventory/PostButton'
import { getUser } from '../selectors/user';
import { useTranslation } from 'react-i18next';
import {
    IonCard, IonCardHeader, IonCardTitle, IonButton, IonIcon,
    IonCardContent
} from '@ionic/react';
import { options } from 'ionicons/icons';
import DeveloperObjectTools from '../containers/settings/DeveloperObjectTools';
import i18next from 'i18next';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        },

        content: {
            marginTop: theme.spacing(2),
        },

        itemsExpansion: {
            padding: 0
        },

        itemsSummaryPanel: {
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        creativeCardHeader: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            },
        },
        creativeTitleBox: {
            flex: 1,
        },
        creativeCTABox: {
            display: 'flex',
        },
        campaignPageContainer: {
            margin: 'auto',
            paddingLeft: '20px',
            paddingRight: '20px',
            maxWidth: '1400px',
            marginBottom: "300px",
            [theme.breakpoints.down('sm')]: {
                padding: '10px !important',
                maxWidth: '100% !important',
            },
        },
    }
})

function ExportDetailsDigitalBoardCampaign(props) {
    const { exportId, exportItem, getExport, hasRequestedItems } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const [isManageWeightsOpen, setIsManageWeightsOpen] = useState(false);
    const { t } = useTranslation();

    const refreshExport = () => {
        setLoading(true);
        getExport(exportId)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        refreshExport();
    }, [getExport, exportId]);

    return (
        <div className={classes.campaignPageContainer}>
            <PageTitle title={exportItem && exportItem.name} />

            <Hidden mdUp>
                <Box mb={3}>
                    <Link
                        component="button"
                        onClick={() => history.length
                            ? history.goBack()
                            : history.push({ pathname: RoutePaths.exports })}>
                        <Box className={classes.back}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {t('Back')}
                        </Box>
                    </Link>
                </Box>
            </Hidden>

            {loading && <ExportDetailsSkeleton />}
            {!loading
                && exportItem
                && <ExportDetailsContainerGroup item={exportItem} />}

            <div>
                <IonCard>
                    <IonCardHeader className={classes.creativeCardHeader}>
                        <Box className={classes.creativeTitleBox}>
                            <IonCardTitle>
                                Screen Groups
                            </IonCardTitle>
                        </Box>
                        <Box className={classes.creativeCTABox}>
                            {isItemsIdFilterAllowed(exportItem)
                                && <Tooltip
                                    title={!hasRequestedItems
                                        ? t('You don\'t have any items in campaign')
                                        : t('Adjust Frequency')}
                                >
                                    <IonButton
                                        disabled={!hasRequestedItems}
                                        onClick={e => {
                                            e.stopPropagation();
                                            setIsManageWeightsOpen(true);
                                        }}
                                        color="secondary"
                                        size="small">
                                        <IonIcon slot="start" icon={options} />
                                        {t('Frequency')}
                                    </IonButton>
                                </Tooltip>}

                            <PostButton
                                defaultSelectedExportId={exportItem.id}
                                buttonProps={{
                                    className: classes.addPhotoButton,
                                    size: 'small',
                                }} />
                        </Box>

                    </IonCardHeader>

                    <IonCardContent className={classes.itemsExpansion} >
                        <ExportItemList exportId={exportId} showScreenSelector={false} />
                    </IonCardContent>
                </IonCard>

                <WeightsEditorDialog
                    title={t('Adjust Frequency')}
                    open={isManageWeightsOpen}
                    handleClose={() => setIsManageWeightsOpen(false)}
                    exportId={exportId}
                />
            </div>

            <DeveloperObjectTools object={exportItem} additionalFields={[
                {
                    label: 'hash_id',
                    value: exportItem.hash_id,
                }
            ]} />
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { exportId } = ownProps;

    return {
        exportId,
        exportItem: exportsByIdSelector(state)(exportId) || {},

        hasRequestedItems: hasRequestedItems(state)(exportId),

        user: getUser(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getExport: id => dispatch(getExport(id))
            .catch(error => {
                if (error.isOffline) {
                    ownProps.history.push({ pathname: RoutePaths.noInternet });
                    throw error;
                }

                //dispatch(showError(`Export with id '${id}' was not found.`));
                dispatch(showError(i18next.t(`Export with id {{eID}} was not found.`, { eID: id })));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportDetailsDigitalBoardCampaign
);
