import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router";
import { RoutePaths } from '../../helpers/constants';

export const LucitRoute = (props) => {
    const { requireAuth, component: Component, sheetOpenDefault, ...rest } = props;

    const isLoggedIn = useSelector(x => x.user.isLoggedIn);

    return <Route
        {...rest}
        key={Date.now()}
        render={(props) =>
            requireAuth && !isLoggedIn
                ? <Redirect to={RoutePaths.public.home} />        //Change this to RoutePaths.public.feed when ready
                : <Component sheetOpenDefault={sheetOpenDefault} {...rest} {...props} />
        } />
}

LucitRoute.propTypes = {
    requireAuth: PropTypes.bool
}

export default LucitRoute;
