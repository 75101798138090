import React from 'react';
import { Box } from '@material-ui/core';
import LCTypography from '../../../src/components/material/LCTypography';

export const Daktronics = ({ exportItem, apiToken }) => {

    return (
        <Box p={5}>

            <Box pb={3}>
                <LCTypography variant="h5">Daktronics</LCTypography>
            </Box>

            <LCTypography variant="body2">
                The step by step process for loading Watchfire campaigns is documented in
                <a target="_blank" rel="noreferrer" href="https://lucit.cc/post/how-to-schedule-lucit-in-the-daktronics-player/">
                    How to Schedule Lucit in the Daktronics Player</a>
            </LCTypography>

            <LCTypography variant="body2">
                To load a Daktronics campaign, you schedule a special <code>.dpf</code> file that contains the following text
            </LCTypography>

            <Box p={2}>
                <code>
                    src=https://pull.lucit.app/api/v1/campaigns/daktronics/{exportItem.hash_id}
                    /pull/html?api_token={apiToken}&amp;player_id=$(player_id)<br />
                    producer=vast;
                </code>
            </Box>

            <LCTypography variant="body2">
                At this time, it is recommended that you work with the Lucit Support Team on setting up this
                DPF file to schedule in the Daktronics Player
            </LCTypography>
        </Box>
    )
}
