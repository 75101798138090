import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Add, Delete } from '@material-ui/icons';
import moment from 'moment';
import { max } from '../../helpers/array';
import { useTranslation } from 'react-i18next';
import { TimeRangeInput } from '../../components/inputs/TimeRangeInput';

const useStyles = makeStyles((theme) => {
    return {
        rangeRoot: {
            [theme.breakpoints.down(520)]: {
                marginTop: 12
            }
        },

    }
});

function TimeRanges({ schedules, setSchedules }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const timeRangeSchedules = schedules.filter(s => s.type === "time_range");

    const onChange = useCallback((id, value) => {
        setSchedules(schedules.map(s => {
            return s.id === id
                ? { ...s, ...value }
                : s;
        }))
    }, [schedules])

    const onNew = useCallback(() =>
        setSchedules([
            ...schedules,
            {
                id: max(schedules, x => x.id) + 1,
                type: "time_range",
                startTime: moment().set('h', 6).set('m', 0).set('s', 0).format(),
                endTime: moment().set('h', 18).set('m', 0).set('s', 0).format(),
            }
        ]),
        [schedules])

    return (
        <>
            {timeRangeSchedules.map((range, idx) => (<Box
                key={range.id}
                className={idx !== 0 ? classes.rangeRoot : ''}
                display="flex"
                alignItems="center">
                <TimeRangeInput
                    defaultStartTime={range.startTime}
                    defaultEndTime={range.endTime}
                    onTimeRangeUpdate={(value) => onChange(range.id, value)}
                />
                <Box mr={1} />
                <IconButton
                    size="small"
                    style={{ marginLeft: 'auto', padding: 8 }}
                    onClick={() => setSchedules(schedules.filter(x => x.id !== range.id))}
                >
                    <Delete />
                </IconButton>
            </Box>))}

            <Button
                style={{ marginTop: 16 }}
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<Add />}
                onClick={onNew}>
                {t('Add Range')}
            </Button>
        </>
    );
}

export default TimeRanges;
