import { Avatar, Grid, Typography, makeStyles, Box, Button, Divider, Link } from '@material-ui/core';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { getInitials } from '../../helpers/string';
import { hasIcon } from '../../selectors/account';
import { ProfileType, QueryString, QueryStringActions, RoutePaths } from '../../helpers/constants';
import { ConfirmationDialog } from '../../components';
import { Share } from '@capacitor/share';
import { Share as ShareIcon } from '@material-ui/icons';
import { Language as LanguageIcon } from '@material-ui/icons';
import { getFullUrl } from '../../helpers/history';
import { hasAccount } from '../../selectors/user';
import { getQueryString } from '../../helpers/url';
import { ProfileChangeButton } from '../layout/ProfileChangeButton';
import ReactMarkdown from 'react-markdown'
import { selectProfile } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { joinAccount } from '../../actions/account';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },
        shareBtn: {
            marginLeft: theme.spacing(2)
            // height: 36
        }
    }
})


const ManageAccountButton = ({ user, account, isLoggedIn }) => {
    if (!isLoggedIn)
        return null;

    if (!hasAccount(user)(account.lcuid))
        return null;

    return <ProfileChangeButton
        targetProfile={{ ...account, type: ProfileType.account }}
        pathname={RoutePaths.home}
        color="secondary"
        variant="contained"
    >
        Manage Account
    </ProfileChangeButton>
}

const JoinAccountButton = ({ user, account, isLoggedIn }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    let history = useHistory();

    const inviteLcuid = getQueryString(history, QueryString.inviteLcuid);

    const dispatch = useDispatch();

    if (!account.options.public_can_join && !inviteLcuid)
        return null;

    return (
        <>
            {isLoggedIn
                && <>
                    {hasAccount(user)(account.lcuid)
                        ? <Button
                            color="secondary"
                            variant="contained"
                            component={RouterLink}
                            style={{ marginLeft: '10px' }}
                            to={{
                                pathname: RoutePaths.home,
                                search: `?${QueryString.contextAccountId}=${account.id}` +
                                    `&${QueryString.action}=${QueryStringActions.post}`
                            }}
                        >
                            Post
                        </Button>
                        : <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: '10px' }}
                            onClick={() => setIsConfirmOpen(true)}>
                            JOIN
                        </Button>}

                    <ConfirmationDialog
                        title={`Join ` + account.name}
                        dialogProps={{
                            open: isConfirmOpen,
                            onClose: () => setIsConfirmOpen(false),
                            keepMounted: false
                        }}
                        ConfirmButtonText="Yes - Join"
                        ConfirmButtonProps={{
                            color: "secondary",
                            variant: "contained"
                        }}
                        handleCancel={() => setIsConfirmOpen(false)}
                        handleConfirm={() => {
                            dispatch(joinAccount(account.lcuid, true))  // Dispatch the action and return its Promise
                                .then((newAccount) => {
                                    // Handle the new account after the action resolves
                                    dispatch(selectProfile(newAccount, {
                                        animate: true,
                                        onSwitched: () => history.push({
                                            pathname: RoutePaths.home
                                        })
                                    }));
                                    return newAccount;
                                })
                                .finally(() => {
                                    // Close the confirmation dialog
                                    setIsConfirmOpen(false);
                                })
                                .catch(error => {
                                    // Handle any errors during the process
                                    console.error('Error joining account:', error);
                                });
                        }}>
                        Are you sure you want to Join {account.name}?
                    </ConfirmationDialog>
                </>}

            {!isLoggedIn
                &&
                <Button
                    color="secondary"
                    variant="contained"
                    component={RouterLink}
                    style={{ marginLeft: '10px' }}
                    to={{
                        pathname: RoutePaths.registrationNew,
                        search: `?lcuid=${account.lcuid}`
                    }}
                >
                    JOIN
                </Button>
            }
        </>
    )
}

function AccountProfile(props) {
    const { user, account, isLoggedIn, share } = props;

    const classes = useStyles();

    return <Grid container spacing={2}>
        <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {hasIcon(account)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={account.options.primary_image_public_url}
                        className={classes.image}
                        alt={account.name}
                        title={account.name}
                    />
                </Avatar>
                : <Avatar className={classes.avatar}>
                    {getInitials(account.name)}
                </Avatar>}
        </Grid>

        <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5">{account.name}</Typography>
            {account.parent_account && <Box>
                <Link
                    component={RouterLink}
                    to={RoutePaths.public.slug(account.parent_account.slug)}
                    color="secondary"
                >
                    {account.parent_account.name}
                </Link>
            </Box>}

            {account.description
                && <Box mt={2}>
                    <ReactMarkdown>{account.description}</ReactMarkdown>
                </Box>}

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

            <div style={{ display: 'flex' }}>

                <ManageAccountButton
                    user={user}
                    account={account}
                    isLoggedIn={isLoggedIn}
                />

                <JoinAccountButton
                    user={user}
                    account={account}
                    isLoggedIn={isLoggedIn} />

                <Button
                    className={classes.shareBtn}
                    onClick={() => share(account, account.name, account.description)}
                    startIcon={<ShareIcon />}
                    variant="outlined"
                    color="primary">
                    Share
                </Button>

                {account.website
                    &&
                    <Button
                        style={{ marginLeft: '10px' }}
                        className={classes.btn}
                        href={account.website}
                        startIcon={<LanguageIcon />}
                        variant="outlined"
                        color="primary">
                        Website
                    </Button>}
            </div>

            {account.options.public_joins_with_code
                && !isLoggedIn
                && <Box mt={1}>
                    <Typography variant="subtitle1">
                        Or use promo code <b><code>{account.options.public_joins_with_code}</code></b> during Sign Up
                    </Typography>
                </Box>}

        </Grid>
    </Grid>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        user: state.user,
    }
}

const mapDispatchToProps = () => {
    return {
        share: (account, title, text) => {
            return Share.share({
                title: title,
                text: text,
                url: getFullUrl(RoutePaths.public.slug(account.slug)),
                dialogTitle: 'Share',
            });
        },

    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    AccountProfile
);
