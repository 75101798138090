import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../../components';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import LCTypography from '../../components/material/LCTypography';
import { lucitApi } from "../../services/lucitApi";
import { LIGHTNING_DEVICE_LAYOUT_DELETED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const DeviceLayoutDeleteButton = (props) => {
    const { deviceLayout, handleDeleted } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(deviceLayout.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [deviceLayout, isConfirmOpen])

    const deleteDeviceLayout = () => {

        lucitApi.objects.delete(deviceLayout.lcuid)
            .then(() => dispatch({
                type: LIGHTNING_DEVICE_LAYOUT_DELETED,
                deviceLayout
            }))
            .then(() => handleDeleted())
    }

    return (
        <>
            <Button
                color="inherit"
                variant="contained"
                size="small"
                className={classes.removeButton}
                startIcon={<Delete />}
                onClick={() => setIsConfirmOpen(true)}>
                {t('Delete This Device Layout')}
            </Button>

            <ConfirmationDialog
                title={t('Delete : ') + deviceLayout.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteDeviceLayout()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete <b>{deviceLayout.name}</b>?</LCTypography>
                        <LCTypography>This action CANNOT be un-done and once you delete this object, you will have to recreate all items,
                            data associated with it.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

export default DeviceLayoutDeleteButton
