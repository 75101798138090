import React, { useCallback, useState } from 'react';
import { DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';
import { Dialog } from '../material';

const ConfirmationDialog = (props) => {
    const { title,
        handleConfirm,
        handleCancel,
        dialogProps,
        DialogContentTextProps,
        CancelButtonProps,
        CancelButtonText = "Cancel",
        ConfirmButtonProps,
        ConfirmButtonText = "OK",
        Actions,
        children } = props;

    const [loading, setLoading] = useState(false);

    const confirm = useCallback(() => {
        setLoading(true);

        // eslint-disable-next-line no-undef
        const promise = handleConfirm() || Promise.resolve()

        promise
            .finally(() => setLoading(false));
    }, [handleConfirm])

    return (
        <Dialog
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            title={title}
            {...dialogProps}
            allowDragAndMove={false}
        >
            <DialogContent>
                <DialogContentText
                    component="div"
                    id="alert-dialog-slide-description"
                    {...DialogContentTextProps}>
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {Actions}
                <Button
                    onClick={handleCancel}
                    disabled={loading}
                    color="primary"
                    {...CancelButtonProps}>
                    {CancelButtonText}
                </Button>
                <Button
                    onClick={confirm}
                    disabled={loading}
                    color="primary"
                    {...ConfirmButtonProps}>
                    {ConfirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { ConfirmationDialog }
