import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import LoginForm from '../containers/LoginForm';
import { useQueryString } from '../helpers/hooks/useQueryString'
import { QueryString } from '../helpers/constants';
import { Alert } from '@material-ui/lab';
import { Avatar, Box, Typography } from '@material-ui/core';
import { PageTitle } from '../components';
import { withPublicLayout } from '../layouts/PublicLayout';
import LCTypography from '../components/material/LCTypography';
import { getAppName } from '../helpers/environment'
import { useSelector } from 'react-redux';
import { getUser, isLoggedIn, selectedProfile } from '../selectors/user';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';

const loginIcon = require('../assets/images/iconLogin.png').default;

const useStyles = makeStyles(theme => ({
    paper: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%'
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    },
    badge: {
        width: 240,
        height: 'auto'
    },
    loginContainer: {
        height: '100%',
        maxHeight: '800px',
        // maxWidth: '1200px',
        padding: '16px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    coverContainer: {
        [theme.breakpoints.down('xs')]: {
            flex: 'inherit',
            marginRight: '0px',
        },
        flex: 2,
        position: 'relative',
        marginRight: '40px',
    },
    loginFormContainer: {
        flex: 1,
        padding: 20,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
        },
    },
    media: {
        height: '100%',
        width: '100%',
    },
    coverTextContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: 20,
        color: 'white',
        textShadow: '0px 6px 5px #000000',
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    coverText: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    loginFormCoverText: {
        flex: 1,
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        borderRadius: 10
    },
    gradientBackground: {
        background: 'linear-gradient(72deg,#34bfc2,#efcbe1,#b3b6cf,#91d1d8)',
        backgroundSize: '240% 240%',
        animation: '$gradientAnimation 25s ease infinite',
        width: '100%',
        height: '100%',
    },
    "@keyframes gradientAnimation": {
        "0%": {
            backgroundPosition: '0% 50%'
        },
        "50%": {
            backgroundPosition: '100% 50%'
        },
        "100%": {
            backgroundPosition: '0% 50%'
        }
    },
}));

const Login = () => {
    const classes = useStyles();
    const usernameHint = useQueryString(QueryString.username);
    const isNew = Boolean(useQueryString(QueryString.isNew));
    const join_lcuid = useQueryString(QueryString.join_lcuid);
    const isAuthenticated = useSelector(isLoggedIn);
    const profile = useSelector(selectedProfile);
    const user = useSelector(getUser);
    const { t } = useTranslation();

    if (isAuthenticated && profile) {
        return <Redirect to={profile.type.path} />
    }

    return (
        <Container component="main" maxWidth="sm" className={classes.loginContainer}>
            <PageTitle title={"Login"} />
            <div className={classes.paper}>
                <Card className={classes.loginFormContainer}>
                    <Box className={classes.loginFormCoverText}>
                        <Avatar src={loginIcon} className={classes.large} variant="rounded" />
                        <LCTypography variant="h4">{getAppName()}</LCTypography>
                        {user.mfa
                            ? <Typography>{t('A code was sent to your mobile number ending in')}
                                &nbsp;***{user.mfa_last_4_digits}</Typography>
                            : <Typography>{t('Welcome to connected digital out-of-home')}</Typography>}
                    </Box>

                    {isNew
                        && <>
                            <Box m={1} />
                            <Alert severity={"success"}>
                                <Box>
                                    <LCTypography>
                                        Your account was successfuly registered
                                    </LCTypography>
                                </Box>
                            </Alert>
                        </>}

                    <LoginForm
                        initialValues={{ username: usernameHint, join_lcuid: join_lcuid }} />
                </Card>
            </div>
        </Container>
    )
}

export default withPublicLayout(Login, {
    disablePaper: true,
    disablePermanentDrawer: true,
    showHomeButton: false,
});
