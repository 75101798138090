import React from 'react';
import { Box, Typography } from "@material-ui/core"

export const SettingsSectionRow = ({ title, description, children, inline = true }) => {
    return <>
        <Box mb={2}>
            <Box display="flex" alignItems="start">
                {inline
                    ? <>
                        <Box mr={2}>
                            <Typography style={{ fontWeight: 'bold', color: '#404040' }}>{title}</Typography>
                            {description &&
                                <Typography style={{ marginTop: 4 }}>{description}</Typography>}
                        </Box>
                        <Box ml="auto">
                            {children}
                        </Box>
                    </>
                    : <>
                        <Box>
                            <Typography style={{ fontWeight: 'bold', color: '#404040' }}>{title}</Typography>
                            {description &&
                                <Typography style={{ marginTop: 4 }}>{description}</Typography>}
                            {children}
                        </Box>
                    </>}
            </Box>
        </Box>
    </>
}
