import React from 'react';
import { store } from "../../reducers";
import { showError, hideSnackbar } from "../../actions/snackbar";
import { Button } from "@material-ui/core";

/**
 * Interceptor to catch 502, 503, 504 transient errors and show 'Reload' snackbar
 * @export
 * @param {*} instance
 */
export function useTransientErrorInterceptor(instance) {
    instance.interceptors.response.use(_ => _, onError);
}

const transientErrorCodes = [502, 503, 504];
const isTransientError = code => !!transientErrorCodes.find(x => x === code);

export function onError(error) {
    if (error.isOffline) {
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
    }

    if (isTransientError(error.response.status)) {
        const reload = () => {
            store.dispatch(hideSnackbar());
            window.location.reload();
        };

        store.dispatch(showError('Something went wrong...', {
            duration: 5_000,
            action: <Button color="primary" onClick={reload}>Reload</Button>,
        }))

        // eslint-disable-next-line no-undef
        return Promise.reject(error);
    }

    // eslint-disable-next-line no-undef
    return Promise.reject(error);
}
