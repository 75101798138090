import React from 'react';
import PropTypes from 'prop-types';

export const Condition = (props) => {
    const { if: condition = true, component: Component, ...rest } = props;

    return condition
        ? <Component {...rest} />
        : props.children;
}

Condition.propTypes = {
    if: PropTypes.bool.isRequired,
    component: PropTypes.object.isRequired
}

export default Condition;