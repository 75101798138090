import { useTheme, useMediaQuery } from '@material-ui/core';

export function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse(); // Breakpoints in descending order (xl, lg, md, sm, xs)

    return keys.reduce((output, key) => {
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
    }, null);
}
