import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { minLength } from '../../helpers/validators';
import AutocompleteField from '../../components/inputs/AutocompleteField';

const RolesField = props => {
    const { roles, value } = props;

    const [roleValidators] = useState([minLength(1, "This field is required")])
    const customRenderOption = (option, { selected }) => (
        <>
            <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
            />
            <Box>
                <strong>{option.title}</strong>
                <Typography
                    variant="body2">{option.description}
                </Typography>
            </Box>
        </>
    )

    return <AutocompleteField
        name="roles"
        validate={roleValidators}
        props={{
            label: "Roles",
            limitTags: 2,
            options: roles,
            getOptionSelected: option => value.some(e => e.id === option.id),
            getOptionLabel: option => option.title,
            customRenderOption: customRenderOption,
            textFieldProps: {
                variant: "outlined",
                fullWidth: true
            }
        }}
    />
}

RolesField.propTypes = {
    roles: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
}

export default RolesField;
