import React, { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { IonButton, IonButtons, IonDatetime, IonDatetimeButton, IonModal, IonNote, isPlatform } from '@ionic/react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',

            [theme.breakpoints.down(520)]: {
                justifyContent: 'space-between'
            }
        },

        button: {
            width: 200,

            [theme.breakpoints.down(520)]: {
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',

                '&::part(native)': {
                    marginTop: 4
                }
            }
        },

        note: {
            cursor: 'pointer',
            width: 110
        }
    }
});

const DateTimeRangeInput = ({ id = "date", input }) => {
    const classes = useStyles();

    const [isInfinite, setInfinite] = useState(!input.value.endDate)

    const dateFromRef = useRef();
    const dateToRef = useRef();
    const dateToButtonRef = useRef();
    const { t } = useTranslation();

    const initEndDate = useCallback(() => {
        const endDate = moment(input.value.startDate).set('h', 23).set('m', 59).set('s', 59).format();

        input.onChange({ ...input.value, endDate })
        dateToRef.current.reset(endDate)

        setInfinite(false);
        dateToButtonRef.current.handleDateClick();
    }, [input, dateToRef, dateToButtonRef]);

    const clearEndDate = useCallback(() => {
        setInfinite(true);
        input.onChange({ ...input.value, endDate: null })
        dateToRef.current.cancel(true)
    }, [input, dateToRef])

    // Read more: https://lch.lcdevops.com/T3874
    const iosDatetimeFix = isPlatform('ios') && {
        min: undefined,
        max: undefined
    };

    return <Box className={classes.root}>
        <IonDatetimeButton
            className={classes.button}
            datetime={`${id}-from`}>
            <span slot="date-target">{moment(input.value.startDate).format('MMM DD, YYYY')}</span>
            <span slot="time-target">{moment(input.value.startDate).format('hh:mm A')}</span>
        </IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
            <IonDatetime
                ref={dateFromRef}
                id={`${id}-from`}
                locale='en-US'
                min={moment().format()}
                max={moment().add(2, 'y').format()}
                value={input.value.startDate}
                showDefaultButtons={true}
                onIonChange={(e) => {
                    if (input.value.endDate && moment(e.target.value).isAfter(moment(input.value.endDate))) {
                        clearEndDate();
                    }

                    input.onChange({ ...input.value, startDate: e.target.value });
                }}

                {...iosDatetimeFix}
            >
                <IonButtons slot="buttons">
                    <IonButton color="medium" onClick={() => dateFromRef.current.cancel(true)}>{t('Cancel')}</IonButton>
                    <IonButton color="primary" onClick={() => dateFromRef.current.confirm(true)}>{t('Done')}</IonButton>
                </IonButtons>
            </IonDatetime>
        </IonModal>

        <Box ml={1} mr={1}>&mdash;</Box>

        {isInfinite
            && <IonNote
                className={classes.note}
                onClick={initEndDate}>
                No end date
            </IonNote>}
        <div style={{ display: isInfinite ? 'none' : 'block' }}>
            <IonDatetimeButton
                className={classes.button}
                ref={dateToButtonRef}
                datetime={`${id}-to`}>
                {input.value.endDate
                    && <>
                        <span slot="date-target">{moment(input.value.endDate).format('MMM DD, YYYY')}</span>
                        <span slot="time-target">{moment(input.value.endDate).format('hh:mm A')}</span>
                    </>}
            </IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
                <IonDatetime
                    ref={dateToRef}
                    id={`${id}-to`}
                    locale='en-US'
                    min={input.value.startDate}
                    max={moment.utc(input.value.startDate).add(2, 'y').format()}
                    value={input.value.endDate}
                    showDefaultButtons={true}
                    onIonChange={(e) => input.onChange({ ...input.value, endDate: e.target.value })}

                    {...iosDatetimeFix}>
                    <IonButtons slot="buttons">
                        <IonButton color="danger"
                            style={{ marginRight: 'auto' }}
                            onClick={clearEndDate}>{t('Clear')}</IonButton>
                        <IonButton color="medium" onClick={() => dateToRef.current.cancel(true)}>{t('Cancel')}</IonButton>
                        <IonButton color="primary" onClick={() => dateToRef.current.confirm(true)}>{t('Done')}</IonButton>
                    </IonButtons>
                </IonDatetime>
            </IonModal>
        </div>

    </Box>
};

export { DateTimeRangeInput }
