import React from 'react';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';

const ButtonLoader = ({ startIcon, disabled, submitting, children, ...rest }) => (
    <IonButton
        {...rest}
        disabled={submitting || disabled}
    >
        {submitting && <IonSpinner style={{ width: 20, height: 20, marginRight: 8 }} color="secondary" slot="start" ></IonSpinner>}
        {!submitting && <IonIcon icon={startIcon} slot="start" />}
        {children}
    </IonButton>
);

export default ButtonLoader;
