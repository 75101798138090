import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from './FilterButton';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, Divider, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { PROGRAMMATIC_FILTERS_CHANGE } from '../../helpers/actionTypes';
import { CircularProgressCentered } from '../../components';
import { getScreenIcon } from '../../selectors/billboard';
import { CreativesUploadDialog } from './CreativesUploadDialog';

const useStyles = makeStyles((theme) => {
    return {
        filterButtonSelected: {
            color: theme.palette.primary.main,
        },
        list: {
            maxHeight: 300,
            overflow: 'auto',
            margin: '0 -16px',
            padding: '0 8px;'
        },
        subheader: {
            lineHeight: '48px',
            background: 'white',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        indeterminate: { color: theme.palette.secondary.main }
    }
})

const BoardFormatsFilter = () => {
    const [opened, setOpened] = useState(false);
    const [uploadOpened, setUploadOpened] = useState(false);
    const [boardFormats, setBoardFormats] = useState({});
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const programmatic = useSelector(state => state.programmatic);

    const options = useSelector(state => state.programmatic.board_format_options) ?? [];

    const loading = useMemo(() => !options, [options]);

    useEffect(() => {
        const boardFormatsDictionary = programmatic.boardFormats
            .reduce((prev, curr) => ({
                ...prev,
                [curr.code]: curr
            }), {})
        setBoardFormats(boardFormatsDictionary);
    }, [programmatic.boardFormats, opened])

    const onChange = useCallback((option) => {
        if (boardFormats[option.code]) {
            delete boardFormats[option.code]
        } else {
            boardFormats[option.code] = option;
        }

        setBoardFormats({ ...boardFormats })
    }, [boardFormats]);

    const boardFormatsCount = useMemo(() => Object.keys(boardFormats).length, [boardFormats])

    const onChangeAll = useCallback(() => {
        if (options.length == boardFormatsCount) {
            setBoardFormats({})
        } else {
            setBoardFormats(options.reduce((prev, curr) =>
                ({ ...prev, [curr.code]: curr }),
                {}
            ));
        }
    }, [boardFormatsCount, options])

    return <>
        <FilterButton
            open={opened}
            className={programmatic.boardFormats.length > 0
                ? classes.filterButtonSelected
                : null}
            title={programmatic.boardFormats.length > 0
                ? `${programmatic.boardFormats.length} ` + t('Screen Sizes')
                : t('Screen Sizes')}
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            onApply={() => {
                dispatch({
                    type: PROGRAMMATIC_FILTERS_CHANGE,
                    boardFormats: Object.values(boardFormats)
                })
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={classes.subheader}>
                    {t('Screen Sizes')}
                </Typography>

                <Typography color='secondary'
                    onClick={() => setUploadOpened(true)}
                    style={{ cursor: 'pointer' }}>
                    Need help?
                </Typography>
            </Box>
            <List disablePadding className={classes.list}>
                {loading
                    && <CircularProgressCentered />}
                <ListItem
                    style={{ padding: 0, cursor: 'pointer' }}
                    onClick={() => onChangeAll()}
                    disableGutters>
                    <Checkbox
                        size='small'
                        classes={{ indeterminate: classes.indeterminate }}
                        indeterminate={boardFormatsCount > 0 && options.length != boardFormatsCount}
                        checked={options.length == boardFormatsCount}
                    />
                    <ListItemText>{t('Select All')}</ListItemText>
                </ListItem>
                <Divider />
                {options.sort((a, b) => {
                    //If count is greater than 0, then it should be on top
                    if (a.count > 0 && b.count > 0) {
                        return 0;
                    } else if (a.count > 0) {
                        return -1;
                    } else if (b.count > 0) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).map(o => (<ListItem
                    key={o.code}
                    style={{
                        padding: 0,
                        cursor: 'pointer',
                        opacity: o.count ? 1 : 0.5
                    }}
                    onClick={() => onChange(o)}
                    disableGutters>
                    <Checkbox
                        size='small'
                        checked={Boolean(boardFormats[o.code])}
                    />
                    <ListItemText
                        primary={o.name + ` (` + o.count + `)`}
                        secondary={`${o.size.width}x${o.size.height}`}
                    />
                    <img
                        style={{ width: "40px" }}
                        src={getScreenIcon(o.code)}
                    />
                </ListItem>))}
            </List>
        </FilterButton>
        {uploadOpened
            && <CreativesUploadDialog
                open={true}
                handleClose={() => setUploadOpened(false)}
                handleApply={(formats) => {
                    formats.filter(f => boardFormats[f.code] == null)
                        .forEach(f => boardFormats[f.code] = f);
                    setBoardFormats({ ...boardFormats });
                    dispatch({
                        type: PROGRAMMATIC_FILTERS_CHANGE,
                        boardFormats: Object.values(boardFormats)
                    })
                    setOpened(false);
                }}
            />}
    </>
};

export default BoardFormatsFilter;
