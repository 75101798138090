import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextField from '../../components/inputs/TextField';
import { getScreens } from '../../actions/billboards';
import { getAccountScreens } from '../../actions/account';
import { screens } from '../../selectors/billboard';
import { useState } from 'react';
import { Autocomplete } from '../../components/inputs/AutocompleteField';
import { ProfileType } from '../../helpers/constants';
import { selectedProfile } from '../../selectors/user';

function ScreenFilter(props) {
    const { screens, profile, loadScreens, value, onChange } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState();

    useEffect(() => {
        loadScreens(profile)
            .finally(() => setLoading(false))
    }, [])

    return (
        <Autocomplete
            multiple={false}
            openOnFocus={true}
            disableCloseOnSelect={false}
            input={{
                value,
                onChange
            }}
            loading={loading}
            options={screens}
            getOptionSelected={option => value.id === option.id}
            getOptionLabel={(option) => option.board_identifier + ' : ' + (option.name || option.title)}
            renderInput={(params) => <TextField {...params}
                variant="outlined"
                label={t('Screen')} />}
        />
    );
}

const mapStateToProps = (state) => {
    const profile = selectedProfile(state);

    return {
        profile,
        screens: profile.type === ProfileType.account
            ? state.accounts.screens
            : screens(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadScreens: (profile) => profile.type === ProfileType.agency
            ? dispatch(getScreens({ agencyId: profile.id, perPage: 1_000 }))
            : dispatch(getAccountScreens(profile)),
    };
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ScreenFilter);
