import { buildMacroCodeFromAsset } from "../containers/designer/DesignerElements";
import { getDataSourceObject, getDataSourceMacroValueFromObject } from '../helpers/designer/macroses';
import { getDataSourceFields } from '../selectors/designer';

export const macroHasBeenUsed = (state, macro) => {
    return state.designer.usedMacros.includes(macro)
}

export const getListOfUsedMacros = (state, contentString, assets) => {
    const fields = state.designer.fields;

    const usedMacros = []

    fields.forEach((element) => {
        if (contentString.includes(element.macroCode))
            usedMacros.push(element.macro)
    })

    //Now, parse contentString for any inventory_attributes that look like this:
    // {{item.inventory_attributes.SOMEFIELDNAME}}
    const inventoryAttributes = contentString.match(/{{item.inventory_attributes.[a-zA-Z0-9_]+}}/g)
    if (inventoryAttributes) {
        inventoryAttributes.forEach((attr) => {
            const macro = attr.replace("{{", "").replace("}}", "")

            if (!usedMacros.includes(macro))
                usedMacros.push(macro)
        })
    }

    assets.forEach((img) => {
        if (img.image && contentString.includes("{" + img.image.lcuid + ".options.public_url}"))
            usedMacros.push(img.image.lcuid + ".options.public_url")
    })

    return usedMacros;
}

export const replaceMacros = (state, contentString, item, account, user, digitalBoard, assets) => {

    const fields = getDataSourceFields(state);

    let newString = contentString;

    const accountKeyValueStore = state.designer.accountKeyValueStore;

    fields.forEach((element) => {

        if (macroHasBeenUsed(state, element.macro)) {

            const object = getDataSourceObject(element, item, account, user, digitalBoard, accountKeyValueStore)

            const newValue = getDataSourceMacroValueFromObject(element, object, false)

            //If the value contains double quotes, we need to escape them, otherwise the string will be broken
            //Here we will replace them with &quot; which is the HTML entity for double quotes
            const newValueHtmlReady = typeof newValue === 'string' ? newValue.replace(/"/g, '&quot;') : newValue;

            if (newValue) {

                const regex = new RegExp(element.macroCode, "g");
                //This is kind of a hack but it works

                //If we find a macro that is directly surrounded by extra (double) curly braces, then we are assuming (here) that
                //this macros is being used as a string parameter in a function. So we need to escape the single quotes
                //This is done without affecting any other macros that are not being used as function parameters
                //We also protect against the case where newValue is not a string, in which case we don't need to escape anything

                const regexForFunctions = new RegExp("{" + element.macroCode + "}", "g"); //Look for {{macroCode}} in functions
                const escapedValue = typeof newValueHtmlReady === 'string' ? newValueHtmlReady.replace("'", "\\'") : newValue;
                newString = newString.replace(regexForFunctions, escapedValue);

                //The escaped version has been done, so now, find the normal macros and replace them with the original value
                //The macros that are left are like {macroCode} and not {{macroCode}}
                newString = newString.replace(regex, newValueHtmlReady);
            }
        }
    })

    if (assets) {
        assets.forEach((asset) => {
            if (asset.image)
                newString = newString.replace(
                    new RegExp(buildMacroCodeFromAsset(asset), 'g'),
                    asset.image.options.public_url
                )
        })
    }

    return newString
}
