import React from 'react';
import { Box } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { connect } from 'react-redux';
import { PageTitle, StripeProvider } from '../../components';
import AgencyInfo from '../../containers/agency/AgencyInfo';
import { RoutePaths } from '../../helpers/constants';
import { getUser, selectedAgency } from '../../selectors/user';
import AgencyDeleteButton from '../../containers/agency/AgencyDeleteButton';
import AppsSettings from '../../containers/settings/AppsSettings';
import i18next from 'i18next';
import DeveloperObjectTools from '../../containers/settings/DeveloperObjectTools';

function Settings({ selectedAgency }) {

    return (
        <StripeProvider>
            <Box>
                <PageTitle title={i18next.t('Settings')} />
                <AgencyInfo />

                <Box mt={2} />
                <AppsSettings lcuid={selectedAgency.lcuid} />

                <Box mt={8} ml={6} mb={8}>
                    <AgencyDeleteButton agency={selectedAgency} redirectPathname={RoutePaths.home} />
                </Box>

                <DeveloperObjectTools object={selectedAgency} />

            </Box>
        </StripeProvider>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAgency: selectedAgency(state)
    }
}

export default connect(
    mapStateToProps
)(
    withHomeLayout(
        Settings
    )
);
