import {
    DRIVE_TEMPLATE_ELEMENT_INDEX_FOR_EDIT, DRIVE_TEMPLATE_ELEMENT_ADD, DRIVE_TEMPLATE_ELEMENT_DELETE,
    DRIVE_TEMPLATE_ELEMENT_UPDATE,DRIVE_TEMPLATE_ELEMENT_MOVE
} from "../helpers/actionTypes";

export const initialState = {
    data: [],
}

export default function driveTemplateElements(state = initialState, action) {
    switch (action.type) {
        case DRIVE_TEMPLATE_ELEMENT_INDEX_FOR_EDIT:
            return {
                ...state,
                data: action.payload
            }

        case DRIVE_TEMPLATE_ELEMENT_ADD:
            return {
                ...state,
                //Put it at the beginning of the data array
                data: [action.payload, ...state.data]
            }

        case DRIVE_TEMPLATE_ELEMENT_UPDATE:
            return {
                ...state,
                data: state.data.map((element) => {
                    if (element.id === action.payload.id) {
                        return action.payload
                    }
                    return element
                })
            }

        case DRIVE_TEMPLATE_ELEMENT_DELETE:
            return {
                ...state,
                data: state.data.filter((element) => element.id !== action.payload)
            }

        case DRIVE_TEMPLATE_ELEMENT_MOVE:
            return {
                ...state,
                data: state.data.map((element) => {
                    if (element.id === action.payload.driveTemplateElement.id) {
                        return action.payload.driveTemplateElement
                    }
                    return element
                })
            }

        default:
            return state
    }
}
