import { Marker } from '@react-google-maps/api';

import React, { useCallback, useMemo } from 'react';
import { getLocationIcon } from '../../selectors/billboard';

const ScreenMapMarker = props => {
    const { location, highlight, clusterer, onLoad, onClick, onUnmount, onDragEnd, title } = props;

    const position = useMemo(
        () => new window.google.maps.LatLng(+location.latitude, +location.longitude),
        [location.latitude, location.longitude]
    );

    const zIndex = useMemo(
        () => highlight
            ? 20
            : 10,
        [highlight]
    );

    const icon = useMemo(() => ({
        url: getLocationIcon(location, highlight),
        scaledSize: new window.google.maps.Size(40, 40),
    }), [highlight, location])

    const handleLoad = useCallback(marker => onLoad(marker, location), [onLoad, location]);
    const handleClick = useCallback(event => onClick(event, location), [onClick, location]);
    const handleUnmount = useCallback(event => onUnmount(event, location), [onUnmount, location]);
    const handleDragEnd = useCallback(event => onDragEnd(event, location), [onDragEnd, location]);

    return <Marker
        title={title}
        key={location.id}
        clusterer={clusterer}
        animation={window.google.maps.Animation.DROP}
        zIndex={zIndex}

        position={position}
        icon={icon}

        draggable={location.draggable}

        onUnmount={handleUnmount}
        onLoad={handleLoad}
        onClick={handleClick}
        onDragEnd={handleDragEnd}
    />
}

export default ScreenMapMarker
