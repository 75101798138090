import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { inventoryStatus, exportItemsShouldUpdateSelector } from '../../selectors/inventoryStatus';
import { getExportItemsByIds, getExport } from '../../actions/exports';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { Global } from '../../helpers/constants';
import { useEffect } from 'react';
import { exportsByIdSelector, isExportRunningOrWillBe } from '../../selectors/exports';

function ExportItemsStatusUpdator(props) {
    const { exportStatusForUpdate, getItemsByIds, getExport } = props;

    const exportItem = props.exportItem || props.exportItemFromState;

    const isRunning = isExportRunningOrWillBe(exportItem);

    const { reset } = useIntervalLimited(() => {
        getItemsByIds(exportStatusForUpdate.map(x => x.item_id))
        getExport(exportItem.id);
    }, {
        running: exportStatusForUpdate.length || isRunning,

        milliseconds: Global.exportItemStatusUpdateInterval
    });

    useEffect(() => {
        reset();

        // eslint-disable-next-line
    }, [isRunning, JSON.stringify(exportStatusForUpdate.map(x => x.state))])

    return null;
}

ExportItemsStatusUpdator.propTypes = {
    exportId: PropTypes.number.isRequired
}

const mapStateToProps = (state, props) => {
    return {
        ...inventoryStatus(state),
        exportStatusForUpdate: exportItemsShouldUpdateSelector(state)(props.exportId),
        exportItemFromState: exportsByIdSelector(state)(props.exportId) || {}
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        getItemsByIds: (ids) => dispatch(getExportItemsByIds(props.exportId, ids)),
        getExport: id => dispatch(getExport(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportItemsStatusUpdator
);
