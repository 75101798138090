import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleStreetview from 'react-google-streetview';
import { GoogleMapsContext } from '../../contexts';
import { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import CircularProgressCentered from '../material/CircularProgressCentered';
import { logger } from '../../helpers/logger';
import { useTranslation } from 'react-i18next';

export const StreetView = (props) => {
    const { lat, lng, pov = { heading: 100, pitch: 0, }, zoom = 1,
        onChangePosition, onChangePov } = props;

    const { t } = useTranslation()

    const panoramaOptions = useMemo(() => ({
        position: { lat, lng },
        pov,
        zoom,
        addressControl: false,
        showRoadLabels: false,
        zoomControl: false,
        clickToGo: true,
        motionTracking: false,
        motionTrackingControl: false,
        panControl: true
    }), [lat, lng, pov, zoom])

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(gmContext.error)
            logger.logError(`Street View failed to load: ${gmContext.error}`, { Error: gmContext.error });
        }
    }, [gmContext.error])

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>{t('Stree View is currently unavailable')}</Typography>
        </Box>
    }

    return (
        <GoogleStreetview
            apiKey={gmContext.key}
            streetViewPanoramaOptions={panoramaOptions}
            onPositionChanged={(position) => {
                if (onChangePosition) {
                    onChangePosition(position.toJSON());
                }
            }}
            onPovChanged={(pov) => {
                if (onChangePov) {
                    onChangePov(pov);
                }
            }}
        />
    )
};

StreetView.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    pov: PropTypes.shape({
        heading: PropTypes.number,
        pitch: PropTypes.number,
    }),
    zoom: PropTypes.number,

    onChangePosition: PropTypes.func,
    onChangePov: PropTypes.func
}

export default StreetView;
