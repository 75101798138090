import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { deleteAllInventoryForFeed } from '../../actions/feeds';
import { showError, showSuccess } from '../../actions/snackbar';
import { ConfirmationDialog } from '../../components';
import LCTypography from '../../components/material/LCTypography';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const FeedDeleteInventoryButton = (props) => {
    const { feed, deleteAllInventoryForFeed } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <>
            <Button
                color="inherit"
                variant="contained"
                size="small"
                className={classes.removeButton}
                startIcon={<Delete />}
                onClick={() => setIsConfirmOpen(true)}>
                {t('Delete all items created from this feed')}
            </Button>

            <ConfirmationDialog
                title={t('Delete all inventory from : ') + feed.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={t('Delete')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    deleteAllInventoryForFeed()
                    setIsConfirmOpen(false)
                }}>

                <LCTypography>Are you sure you want to delete ALL of the inventory items that
                    were created by <b>{feed.name}</b>?</LCTypography>
                <LCTypography>After you delete all of the inventory items, you will have to re-run the
                    feed in order to recreate them.
                </LCTypography>

            </ConfirmationDialog>
        </>
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteAllInventoryForFeed: () => {
            return dispatch(deleteAllInventoryForFeed(props.feed))
                .then(() => {
                    dispatch(showSuccess(`"All inventory attached this feed were successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FeedDeleteInventoryButton
);
