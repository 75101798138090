import {
    OBJECT_ATTACHMENTS, OBJECT_ATTACHMENT_ADD, OBJECT_ATTACHMENT_FILE
} from "../../helpers/actionTypes";

export const initialState = {
    attachments: [],
}

export default function attachments(state = initialState, { type, ...payload }) {
    switch (type) {

        case OBJECT_ATTACHMENTS:
            return Object.assign({ ...initialState },
                state,
                {
                    attachments: payload.attachments
                });

        case OBJECT_ATTACHMENT_ADD:
            return Object.assign({ ...initialState },
                state,
                {
                    attachments: [...state.attachments, payload.attachment],
                });

        case OBJECT_ATTACHMENT_FILE:
            return Object.assign({ ...initialState },
                state,
                {
                    attachments: state.attachments.map(a => {
                        if (a.lcuid == payload.attachment_lcuid) {
                            return {
                                ...a,
                                file: payload.file
                            }
                        }

                        return a;
                    })
                });
        default:
            return state
    }
}
