import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutocompleteField from '../../components/inputs/AutocompleteField';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectedAccount } from '../../selectors/user';
import { getExports } from '../../actions/exports';
import { getFormValues, reduxForm, propTypes } from 'redux-form';
import { requiredAtLeastOne } from '../../helpers/validators';
import { isItemsIdFilterAllowed } from '../../selectors/exports';

function InventoryExportForm(props) {
    const { name, values, selectedAccount, getExports, submitting, handleSubmit, onSubmit } = props;

    const [loadingExports, setLoadingExports] = useState(false);
    const [exports, setExports] = useState([]);

    useEffect(() => {
        setLoadingExports(true);

        getExports()
            .then(data => setExports(data))
            .finally(() => setLoadingExports(false))
    }, [getExports, selectedAccount])

    return (
        <form onSubmit={handleSubmit(onSubmit)} name={name} >
            <AutocompleteField
                name="exports"
                validate={requiredAtLeastOne}
                props={{
                    label: "Choose Campaign",
                    loading: loadingExports,
                    options: exports,
                    disabled: submitting,
                    limitTags: 4,
                    getOptionSelected: option => values.exports.some(e => e.id === option.id),
                    getOptionLabel: option => option.name,

                    textFieldProps: {
                        variant: "outlined",
                        fullWidth: true
                    }
                }}
            />
        </form>
    );
}

InventoryExportForm.propTypes = {
    ...propTypes,

    onSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const { form } = ownProps;

    return {
        values: getFormValues(form)(state),
        selectedAccount: selectedAccount(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getExports: () => dispatch(getExports())
            .then(data => data.filter(x => isItemsIdFilterAllowed(x)))
    }
}

export default reduxForm({
    initialValues: {
        exports: []
    }
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        InventoryExportForm
    )
);
