import React from 'react';
import { replaceCSSForSelectedId, replaceHTMLForSelectedId } from "../../actions/designer";
import { editorSelectedId, getCSSForSelectedId, getHtmlForSelectedId } from "../../selectors/designer";
import { connect } from 'react-redux';
import { getLayerDataForId } from '../../selectors/designerLayers';

const ToolbarControl = ({ Component, children, objectId, html, css, onChangeHtml, onChangeCss, layer, colors, ...rest }) => {
    return <Component {...{ objectId, html, css, onChangeHtml, onChangeCss, layer, colors, ...rest }}>
        {children}
    </Component>
}

const mapStateToProps = state => {
    return {
        objectId: editorSelectedId(state),
        layer: getLayerDataForId(state, editorSelectedId(state)),
        html: getHtmlForSelectedId(state),
        css: getCSSForSelectedId(state),
        colors: state.designer.colors
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeHtml: (html) => dispatch(replaceHTMLForSelectedId(html)),
        onChangeCss: (css) => dispatch(replaceCSSForSelectedId(css))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ToolbarControl
);
