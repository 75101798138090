import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, makeStyles, Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import { ImageUploadDialog } from '../modals';
import { useCallback } from 'react';
import { DialogCloseReason } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../material/CircularProgressCentered';
import { lucitApi } from '../../services/lucitApi';

const useStyles = makeStyles(() => {
    return {
        uploadArea: {
            cursor: 'pointer',
            textAlign: 'center'
        },
        uploadImage: {
            maxWidth: '250px',
            width: "100%",
            imageOrientation: 'from-image'
        }
    }
});

const ImageUploadField = (props) => {
    return <Field
        component={ImageUpload}
        {...props}
    />
}

const ImageUpload = (props) => {
    const { input, meta: { touched, error },
        open,
        defaultOpen,
        hideButton,
        hidePreview,
        handleDialogClose,
        handleCancel,
        removeBackground,
        setTitle,
        existingImageUrl,
        uploadPromptMessage,
        ImageUploadDialogProps } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const [isUploadOpened, setIsUploadOpened] = useState(Boolean(defaultOpen));

    const [bgRemovedImage, setBgRemovedImage] = useState(null)
    const [removingBackground, setRemovingBackground] = useState(false)

    const closeDialog = useCallback((reason) => {
        // eslint-disable-next-line no-undef
        let promise = Promise.resolve();

        if (handleDialogClose) {
            // eslint-disable-next-line no-undef
            promise = handleDialogClose(reason) || Promise.resolve();
        }

        return promise.then(() => setIsUploadOpened(false));
    }, [handleDialogClose])

    const cancel = useCallback((reason) => {
        if (handleCancel)
            handleCancel(reason)
        else
            setIsUploadOpened(false)

    }, [handleCancel])

    useEffect(() => {
        if (open !== undefined) {
            setIsUploadOpened(open);
        }
    }, [open])

    const gridSizeForPhotoPreview = removeBackground ? 6 : 12

    return (
        <>
            {!hidePreview
                && <Grid container spacing={3}>

                    <Grid item xs={gridSizeForPhotoPreview}><Box mb={1}
                        onClick={() => setIsUploadOpened(true)}
                        className={classes.uploadArea}>
                        {input.value
                            ? <img className={classes.uploadImage} src={input.value.image} alt={t('Upload preview')} />
                            : (
                                existingImageUrl ?
                                    <img className={classes.uploadImage} src={existingImageUrl} alt={t('Upload preview')} />
                                    : (uploadPromptMessage ? uploadPromptMessage : t('Upload Image')))
                        }
                    </Box>
                    </Grid>

                    {removeBackground && <Grid item xs={6}>
                        <Box mb={1}>
                            {removingBackground && <CircularProgressCentered title={"Removing Background"} />}

                            {bgRemovedImage &&
                                <img className={classes.uploadImage} src={bgRemovedImage.options.public_url} alt={t('BG Removed')}
                                />}

                        </Box>
                    </Grid>
                    }

                </Grid>}
            {!hideButton
                && <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsUploadOpened(true)}
                >
                    {input.value
                        ? 'Change'
                        : 'Upload photo'}
                </Button>}

            {touched && !!error
                && <Box mt={1}>
                    <FormHelperText error>{error}</FormHelperText>
                </Box>}

            <ImageUploadDialog
                title={t('Upload Image')}
                uploadPromptMessage={uploadPromptMessage}
                disableDelete={true}
                saveButtonText={t('Add')}
                {...ImageUploadDialogProps}

                open={isUploadOpened}
                handleSave={(image, { fileBase64, file, fileName }) => {

                    setTitle && setTitle(fileName)

                    input.onChange({
                        image,
                        file,
                        fileBase64
                    });

                    if (removeBackground) {
                        setRemovingBackground(true)
                        lucitApi.uploads.getBackgroundRemovedImage(image).then((bgRemovedImage) => {
                            setRemovingBackground(false)
                            setBgRemovedImage(bgRemovedImage)

                            input.onChange({
                                image,
                                file,
                                fileBase64,
                                bgRemovedImage
                            });

                        })

                    }

                    return closeDialog(DialogCloseReason.confirm);
                }}
                handleClose={(reason) => {
                    // if no photo was not uploaded yet, assume that we need cancel whole operation
                    if (!input.value) {
                        cancel(reason);
                    } else {
                        closeDialog(reason);
                    }
                }}
            />
        </>
    )
};

export default ImageUploadField;
