import { removePositioningFromCss } from "../containers/designer/DesignerCSS"
import { getAssetElementForObjectType, getObjectElementForObjectCode } from "../containers/designer/DesignerElements"
import { getLayerTypeFromElement, parseHtmlForLayers } from "../containers/designer/DesignerHTML"
import { Designer } from "../helpers/constants"

export const getLayerDataForId = (state, id) => {
    return state.designer.layers.find((layer) => layer.id === id)
}

export const getHtmlLayerForId = (state, id) => {
    const layers = parseHtmlForLayers(state.designer.html)
    const matchingLayers = layers.filter((layer) => layer.id === id)

    return matchingLayers.length ? matchingLayers[0] : null
}

export const getLayerDataFromHtml = (state, html) => {
    const layersNodes = parseHtmlForLayers(html)
    const layers = [];

    layersNodes.forEach((layer) => {
        layers.push(deriveSettingsFromLayer(state, layer));
    })

    return layers;
}

export const deriveSettingsFromLayer = (state, layer) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(layer.html, 'text/html');
    const node = doc.body.firstChild;

    return {
        id: layer.id,
        name: layer.id,
        classes: node.classList,
        placeholder: node.getAttribute("x-placeholder") ?? getBestDefaultPlaceholderForLayerId(state, node.getAttribute("x-objectcode")),
        fieldName: node.getAttribute("x-fieldName") ?? getBestDefaultFieldNameForLayerId(state, node.getAttribute("x-objectcode")),
        objectCode: node.getAttribute("x-objectcode"),
        macroCode: node.getAttribute("data-value"),
        objectClass: getLayerClassFromElement(state, node),
        objectType: getLayerTypeFromElement(node),
        elementSettings: node.getAttribute("x-element-settings") ?? '{}',
        elementSettingValues: node.getAttribute("x-element-setting-values") ?? '{}',
    };
}

export const getIdsForObjectCode = (state, objectCode, html) => {
    const layers = getLayerDataFromHtml(state, html)
    const ids = []

    layers.forEach((layer) => {
        if (layer.objectCode === objectCode)
            ids.push(layer.id)
    });

    return ids
}

export const getBestDefaultPlaceholderForLayerId = (state, id) => {
    const fields = state.designer.fields;
    const dataSourceElement = fields.find(f => f.id == id);

    return dataSourceElement?.placeholder ?? id
}

export const getBestDefaultFieldNameForLayerId = (state, id) => {
    const fields = state.designer.fields;
    const dataSourceElement = fields.find(f => f.id == id);

    return dataSourceElement?.name ?? id
}

export const getLayerClassFromElement = (state, element) => {
    if (!element)
        return null;

    const fields = state.designer.fields;
    const id = element.getAttribute("x-objectcode")
    const field = fields.find(f => f.id == id);

    if (field)
        return Designer.ObjectClasses.DataSource

    if (element.classList.contains("lc_dt_image"))
        return Designer.ObjectClasses.Asset

    if (element.classList.contains("lc_dt_text"))
        return Designer.ObjectClasses.Object

    if (element.classList.contains("lc_dt_object"))
        return Designer.ObjectClasses.Object

    return null
}

export const getDefaultCssForLayer = (state, layer, resetPosition = false) => {
    const fields = state.designer.fields;
    let defaultCss = "top:10%;left:10%;width:10%;height:10%;";

    if (layer) {
        if (layer.objectClass === Designer.ObjectClasses.DataSource) {
            const dataSourceElement = fields.find(f => f.id == layer.objectCode);
            defaultCss = dataSourceElement?.defaultCss ?? defaultCss
        }

        if (layer.objectClass === Designer.ObjectClasses.Asset) {
            const assetElement = getAssetElementForObjectType(layer.objectType)
            defaultCss = assetElement?.defaultCss ?? defaultCss
        }

        if (layer.objectClass === Designer.ObjectClasses.Object) {
            const objectElement = getObjectElementForObjectCode(layer.objectCode)
            defaultCss = objectElement?.defaultCss ?? defaultCss
        }

    }

    if (!resetPosition) {
        defaultCss = removePositioningFromCss(defaultCss);
    }

    return defaultCss;
}
