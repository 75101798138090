import { apiHttp } from "../helpers/api";
import LucitApiFeeds from "./lucitApi.feeds";
export default class LucitApiPushNotifications extends LucitApiFeeds {

    getUnreadCount() {
        return apiHttp.get(`/users/current/notifications/unread/count`)
            .then(response => response.data);
    }

    getNotification(id) {
        return apiHttp.get(`/users/current/notifications/${id}`)
            .then(response => response.data);
    }

    getNotifications() {
        return apiHttp.get(`/users/current/notifications`)
            .then(response => response.data);
    }

    getChannels() {
        return apiHttp.get(`/users/notifications/channels`)
            .then(response => response.data);
    }

    getNotificationTypes() {
        return apiHttp.get(`/users/notifications/classes`)
            .then(response => response.data);
    }

    updateChannel(channel, enabled) {
        return apiHttp.put(`/users/current/notifications/preference/channels`, {
            channel,
            enabled
        }).then(response => response.data);
    }

    updateChannelForType(notificationType, channel, enabled) {
        return apiHttp.put(`/users/current/notifications/preference`, {
            notification_class: notificationType,
            channel,
            enabled
        }).then(response => response.data);
    }

    markAllAsRead() {
        return apiHttp.post(`/users/current/notifications/unread/markAllRead`);
    }

    markAsRead(notificationId) {
        return apiHttp.post(`/users/current/notifications/${notificationId}/markAsRead`);
    }
}