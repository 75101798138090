import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { Link, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoices } from '../../actions/billing';
import { objectSelector } from '../../selectors/objects';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { formatNumber } from '../../helpers/string';
import InvoiceStatusBadge from './InvoiceStatusBadge';
import moment from 'moment';
import { RoutePaths } from '../../helpers/constants';

function InvoicesTable(props) {
    const { lcuid } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const billing = useSelector(state => objectSelector(state)(lcuid));
    const invoices = useMemo(() => billing.invoices, [billing])

    useEffect(() => {
        setLoading(true);
        dispatch(getInvoices(lcuid))
            .finally(() => setLoading(false));
    }, [])

    // https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setColumns([
            {
                title: t("Amount"),
                align: 'right',
                filtering: false,
                sorting: false,
                headerStyle: { width: 120 },
                cellStyle: { width: 120 },
                // eslint-disable-next-line react/display-name
                render: x => <Typography>
                    {formatNumber(x.total_amount, { style: 'currency', currency: 'USD' })}
                </Typography>,
            },
            {
                filtering: false,
                sorting: false,
                headerStyle: { width: 60 },
                cellStyle: { width: 60 },
                // eslint-disable-next-line react/display-name
                render: x => <InvoiceStatusBadge status={x.status} paymentStatus={0} />,
            },
            {
                title: t("Invoice"),
                filtering: false,
                sorting: false,
                // eslint-disable-next-line react/display-name
                render: x => <Link
                    component={RouterLink}
                    color="secondary"
                    style={{ letterSpacing: '0.08333em', cursor: 'pointer', fontWeight: 500 }}
                    to={RoutePaths.invoice(x.lcuid)}
                >
                    {x.invoice_number}
                </Link>
            },
            {
                title: t("Due"),
                filtering: false,
                sorting: false,
                headerStyle: { width: 140 },
                cellStyle: { width: 140 },
                // eslint-disable-next-line react/display-name
                render: x => <Typography>{moment(x.due_date).isSame(moment(), 'year')
                    ? moment(x.due_date).format('MMM DD')
                    : moment(x.due_date).format('MMM DD, yyyy')}</Typography>,
            }
        ])

    }, [])

    return (
        <>
            {Boolean(!loading && !invoices.length) && <Box p={4}>
                <Typography>{t('Your invoices will appear here when you have an active billing plan')}</Typography>
            </Box>
            }

            {Boolean(!loading && invoices.length) &&
                <MaterialTable
                    columns={columns}
                    isLoading={loading}
                    data={invoices}
                    components={{
                        OverlayLoading: props => (<CircularProgressCentered {...props} />)
                    }}
                    style={{ width: '100%', boxShadow: 'none', maxWidth: "700px" }}
                    options={{
                        headerStyle: { fontSize: '1rem' },
                        emptyRowsWhenPaging: true,
                        pageSize: 20,
                        pageSizeOptions: [5, 20, 50],
                        debounceInterval: 100,
                        paging: invoices.length > 20,
                        search: false,
                        showTitle: false,
                        sorting: true,
                        selection: false,
                        toolbar: false,
                        draggable: false
                    }}
                />}
        </>
    );
}
InvoicesTable.propTypes = {
    lcuid: PropTypes.string.isRequired
}
export default InvoicesTable;
