import { FORGOTPASSWORD_SET, FORGOTPASSWORD_RESET } from "../helpers/actionTypes";
import { showSuccess } from "./snackbar";
import { lucitApi } from "../services/lucitApi";
import { sendPhoneCode } from "./register";
import i18next from "i18next";

function set(data) {
    return {
        type: FORGOTPASSWORD_SET,
        ...data
    }
}

export function reset() {
    return {
        type: FORGOTPASSWORD_RESET
    }
}

export function editPhone() {
    return {
        type: FORGOTPASSWORD_SET,
        isCodeSent: false
    }
}

export function requestCode(phone) {
    return (dispatch) => {
        dispatch(set({ isCodeSending: true, phone: phone }));

        return dispatch(sendPhoneCode(phone))
            .then(data => {
                dispatch(set({ isCodeSent: true, userId: data.user_id }));
            })
            .finally(() => dispatch(set({ isCodeSending: false })));
    }
}

export function resetPassword(phone, code, password) {
    return dispatch => {
        return lucitApi.resetPassword(phone, code, password)
            .then(() => dispatch(showSuccess(i18next.t('Your password was successfuly changed'))));
    }
}
