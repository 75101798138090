import {
    USER_SELECT_ACCOUNT, USER_SELECT_AGENCY, LIGHTNING_DEVICES_LOADED, LIGHTNING_DEVICE_LAYOUTS_LOADED,
    LIGHTNING_DEVICE_LAYOUT_ADDED, LIGHTNING_DEVICE_ADDED, LIGHTNING_DEVICE_LAYOUT_UPDATED, LIGHTNING_DEVICE_LAYOUT_DELETED,
    LIGHTNING_DEVICE_UPDATED, LIGHTNING_DEVICE_DIGITAL_BOARDS_LOADED, LIGHTNING_DEVICE_DELETED, SHADOW_ACCOUNT_LOADED,
    LIGHTNING_GROUP_CAMPAIGN_ADDED, LIGHTNING_GROUP_CAMPAIGN_UPDATED, LIGHTNING_GROUP_CAMPAIGN_DELETED,
    LIGHTNING_DEVICE_HEARTBEAT_STATS_LOADED, LIGHTNING_SCREEN_STATS_LOADED,
    LIGHTNING_SET_SCREENGROUP_SCREENS,
    LIGHTNING_SET_CHILD_CAMPAIGNS,
    AGENCY_EXPORTS, LIGHTNING_LATEST_VERSION_LOADED,
    LIGHTNING_DEVICE_LAYOUT_MAKE_DEFAULT
} from "../helpers/actionTypes";
import { toDictionary } from "../helpers/array";

export const initialState = {
    dataStatus: {
        devices: false,
        deviceLayouts: false,
        digitalBoards: false,
        shadowAccount: false,
        agencyExports: false,
    },
    devices: [],
    deviceLayouts: [],
    digitalBoards: [],
    shadowAccount: null,
    agencyExports: [],
    version: null,  //Latest player version
}

export default function lightning(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case USER_SELECT_ACCOUNT:
        case USER_SELECT_AGENCY:
            return Object.assign({},
                state,
                initialState);

        case SHADOW_ACCOUNT_LOADED:
            return Object.assign({}, state, {
                shadowAccount: payload.shadowAccount,
                dataStatus: {
                    ...state.dataStatus,
                    shadowAccount: true
                }
            });

        case AGENCY_EXPORTS:
            return Object.assign({}, state, {
                agencyExports: payload.exports,
                dataStatus: {
                    ...state.dataStatus,
                    agencyExports: true
                }
            });

        case LIGHTNING_GROUP_CAMPAIGN_ADDED:
            return Object.assign({}, state, {
                agencyExports: state.agencyExports.concat([{ ...payload.groupCampaign, parent_exports: [], child_exports: [] }]),
            });

        case LIGHTNING_GROUP_CAMPAIGN_UPDATED:
            return Object.assign({}, state, {
                agencyExports: state.agencyExports.map((exportItem) => {
                    if (exportItem.id === payload.groupCampaign.id) {
                        return {
                            ...exportItem,
                            ...payload.groupCampaign,
                        }
                    }
                    return exportItem;
                }),
            });

        case LIGHTNING_GROUP_CAMPAIGN_DELETED:
            return Object.assign({}, state, {
                agencyExports: state.agencyExports
                    .filter((exportItem) => exportItem.id !== payload.groupCampaign.id)
                    .map(c => {
                        if (c.parent_exports.some(p => p.lcuid == payload.groupCampaign.lcuid)) {
                            return { ...c, parent_exports: c.parent_exports.filter(x => x.lcuid !== action.groupCampaign.lcuid) }
                        }

                        if (c.child_exports.some(p => p.lcuid == payload.groupCampaign.lcuid)) {
                            return { ...c, child_exports: c.child_exports.filter(x => x.lcuid !== action.groupCampaign.lcuid) }
                        }

                        return c;
                    }),
            });

        case LIGHTNING_DEVICE_DIGITAL_BOARDS_LOADED:

            return Object.assign({}, state, {
                digitalBoards: payload.digitalBoards,
                dataStatus: {
                    ...state.dataStatus,
                    digitalBoards: true
                }
            });

        case LIGHTNING_DEVICES_LOADED:

            return Object.assign({}, state, {
                devices: payload.devices,
                dataStatus: {
                    ...state.dataStatus,
                    devices: true
                }
            });

        case LIGHTNING_DEVICE_ADDED:

            return Object.assign({}, state, {
                devices: state.devices.concat(payload.device),
            });

        case LIGHTNING_DEVICE_UPDATED:

            return Object.assign({}, state, {
                devices: state.devices.map((device) => {
                    if (device.lcuid === payload.device.lcuid) {
                        return payload.device;
                    }
                    return device;
                }),
            });

        case LIGHTNING_DEVICE_DELETED:

            return Object.assign({}, state, {
                devices: state.devices.filter((device) => {
                    return device.lcuid !== payload.device.lcuid;
                }),
            });

        case LIGHTNING_DEVICE_LAYOUTS_LOADED:

            return Object.assign({}, state, {
                deviceLayouts: payload.deviceLayouts,
                dataStatus: {
                    ...state.dataStatus,
                    deviceLayouts: true
                }
            });

        case LIGHTNING_DEVICE_LAYOUT_ADDED:

            return Object.assign({}, state, {
                deviceLayouts: state.deviceLayouts.concat(payload.deviceLayout),
            });

        case LIGHTNING_DEVICE_LAYOUT_UPDATED:

            return Object.assign({}, state, {
                deviceLayouts: state.deviceLayouts.map((deviceLayout) => {
                    if (deviceLayout.lcuid === payload.deviceLayout.lcuid) {
                        return payload.deviceLayout;
                    }
                    return deviceLayout;
                }),
            });

        case LIGHTNING_DEVICE_LAYOUT_DELETED:

            return Object.assign({}, state, {
                deviceLayouts: state.deviceLayouts.filter((deviceLayout) => {
                    return deviceLayout.lcuid !== payload.deviceLayout.lcuid;
                }),
            });
        case LIGHTNING_DEVICE_LAYOUT_MAKE_DEFAULT:
            return Object.assign({}, state, {
                deviceLayouts: state.deviceLayouts.map(x => {
                    return { ...x, is_default: x.lcuid == payload.deviceLayout.lcuid };
                }),
            });

        case LIGHTNING_DEVICE_HEARTBEAT_STATS_LOADED: {

            const deviceStats = payload.stats;

            //each stats item has a lightning_device_id, a lightning_version, a device_info and a last_hearbeat_at
            //Find the matchging device in the devices array and update the summary_lightning_last_heartbeat

            return Object.assign({}, state, {
                devices: state.devices.map((device) => {
                    const matchingLastHeartbeat = deviceStats.find((stats) => {
                        return stats.lightning_device_id === device.id;
                    });

                    if (matchingLastHeartbeat) {
                        return {
                            ...device,
                            summary_lightning_last_heartbeat: matchingLastHeartbeat,
                        }
                    }
                    return device;
                }
                )
            });

        }

        //LIGHTNING_SCREEN_STATS_LOADED
        //Each stats item has a digital_board_id
        //Find the matching digital board in the digitalBoards array and update the summary_digital_board_last_play

        case LIGHTNING_SCREEN_STATS_LOADED: {

            const screenStats = payload.stats;

            return Object.assign({}, state, {
                digitalBoards: state.digitalBoards.map((digitalBoard) => {

                    const matchingLastPlay = screenStats.find((stats) => {
                        return stats.digital_board_id === digitalBoard.id;
                    });

                    if (matchingLastPlay) {
                        return {
                            ...digitalBoard,
                            summary_digital_board_last_play: matchingLastPlay,
                        }
                    }
                    return digitalBoard;
                }
                )
            })

        }

        case LIGHTNING_SET_SCREENGROUP_SCREENS: {
            const dict = toDictionary(state.agencyExports, x => x.lcuid, x => x);
            const screensExports = action.screens.map(s => dict[s.options.connected_export_lcuid]);
            const group = { ...action.group, ...dict[action.group.lcuid] };

            return Object.assign({}, state, {
                agencyExports: state.agencyExports.map(c => {
                    if (c.lcuid === group.lcuid) {
                        return { ...c, parent_exports: screensExports };
                    }

                    let child_exports = c.child_exports;

                    // Detach
                    if (group.parent_exports.some(p => p.lcuid == c.lcuid)) {
                        child_exports = child_exports.filter(e => e.lcuid !== group.lcuid);
                    }

                    // Attach
                    if (screensExports.some(s => s.lcuid == c.lcuid)) {
                        child_exports = [...child_exports, group];
                    }

                    return child_exports === c.child_exports
                        ? c
                        : { ...c, child_exports };
                }),
            });
        }
        case LIGHTNING_SET_CHILD_CAMPAIGNS: {
            return Object.assign({}, state, {
                agencyExports: state.agencyExports.map(c => {
                    if (c.lcuid === action.item.lcuid) {
                        return { ...c, child_exports: action.child_exports };
                    }

                    let parent_exports = c.parent_exports ?? [];

                    // Detach
                    if (action.item.child_exports?.some(e => e.lcuid === c.lcuid)) {
                        parent_exports = c.parent_exports?.filter(e => e.lcuid !== action.item.lcuid)
                    }

                    // Attach
                    if (action.child_exports.some(g => g.lcuid === c.lcuid)) {
                        parent_exports = [...parent_exports, action.item];
                    }

                    return parent_exports == c.parent_exports
                        ? c
                        : { ...c, parent_exports };
                }),
            });
        }

        case LIGHTNING_LATEST_VERSION_LOADED: {
            return Object.assign({}, state, {
                version: payload.version
            });
        }

        default:
            return state
    }
}
