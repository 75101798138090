import React, { lazy, Suspense } from 'react';
import { ApplicationCapabilities } from '../../../helpers/constants';
import { hasCapability } from '../../../selectors/applications';
import { Box } from '@material-ui/core';

export const AssignedAppCapabilitiesActions = ({ application, autoInitDefaultAction }) => {
    return <>
        {Object.keys(ApplicationCapabilities)
            .map((capabilityCode, index) => {
                const c = ApplicationCapabilities[capabilityCode];

                if (c.hasAssignedAppCapabilityActionsComponent && hasCapability(application, c)) {
                    const LazyComponent = lazy(() => import('./' + capabilityCode + '/AssignedAppCapabilityActions.jsx'));

                    return <Suspense key={index}
                        fallback={<div>Loading...</div>}>
                        <Box ml={1}>
                            <LazyComponent application={application}
                                autoInitDefaultAction={autoInitDefaultAction} />
                        </Box>
                    </Suspense>
                }
            })}
    </>
}

export default AssignedAppCapabilitiesActions;
