import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
    return {
        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginBottom: theme.spacing(2),
            fontWeight: 'bold'
        },
        titleSkeleton: {
            width: '250px',
            padding: 10,
        },
        attributeValue: {
            textAlign: 'left'
        },
        chip: {
            borderRadius: 16
        },
        quickStatsContainerSkeleton: {
            width: '100%',
            padding: 10,
        },
        statsGridSkeleton: {
            padding: 10,
        },
        quickStatsTitleBoxSkeleton: {
            display: 'flex',
            alignItems: 'baseline',
            paddingLeft: 10,
            flexDirection: 'column',
        },
        quickStatsTitleSkeleton: {
            width: 200,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12pt',
            },
        },
    }
})

function ExportDetailsStatsSkeleton() {
    const classes = useStyles();

    return (
        <Grid container className={classes.statsGridSkeleton} spacing={2}>
            <Grid item xs={6} sm={6} md={3}>
                <Box className={classes.attributeTitle}>
                    <Skeleton variant="rect" height={24} />
                </Box>
                <Skeleton variant="rect" height={32} width={85} className={classes.chip} />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <Box className={classes.attributeTitle}>
                    <Skeleton variant="rect" height={24} />
                </Box>
                <Skeleton variant="rect" height={32} width={85} className={classes.chip} />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <Box className={classes.attributeTitle}>
                    <Skeleton variant="rect" height={24} />
                </Box>
                <Skeleton variant="rect" height={32} width={85} className={classes.chip} />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
                <Box className={classes.attributeTitle}>
                    <Skeleton variant="rect" height={24} />
                </Box>
                <Skeleton variant="rect" height={32} width={85} className={classes.chip} />
            </Grid>
        </Grid>
    );
}

export default ExportDetailsStatsSkeleton;
