import { useDispatch, useSelector } from 'react-redux';
import { updateTemplate } from '../../actions/designer';
import { useDebounce, useNonInitialEffect } from '../../helpers/hooks';

const DesignerAutosave = () => {
    const dispatch = useDispatch();
    const historyLength = useSelector(state => state.designer.history.length);
    const boardFormatLength = useSelector(state => state.designer.templateBoardFormats.length);
    const templateName = useSelector(state => state.designer.templateName);
    const templateDescription = useSelector(state => state.designer.templateDescription);
    const inventory_item_class_length = useSelector(state => state.designer.inventory_item_class.length);
    const photo_crop_aspect_ratio = useSelector(state => state.designer.photo_crop_aspect_ratio);

    const updateTemplateDebounced = useDebounce(() => dispatch(updateTemplate()), 1500, []);

    // Autosave when history length changes
    // We do not want to autosave when you change css/html, because it could be REDO/UNDO or etc.
    useNonInitialEffect(() => {
        updateTemplateDebounced();
    }, [historyLength, boardFormatLength, inventory_item_class_length, templateName,
        templateDescription, templateDescription, photo_crop_aspect_ratio])

    return null;
}

export default DesignerAutosave
