import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ButtonLoader from '../../components/material/ButtonLoader';
import { Tabs, Tab, Grid } from '@material-ui/core';
import { Designer } from '../../helpers/constants';
import { EditorCSS, EditorHTML, EditorJS } from '../../containers/designer/CodeEditor';
import {
    storeBasicShape, storeBasicShapeImage, storeBackground, storeBackgroundImage, storeText, updateDriveTemplateElement
} from '../../actions/driveTemplateElements';
import { UploadFileAndGetImage } from '../../containers/designer/DesignerImages';
import { fileToBase64 } from '../../helpers/file';
import { lucitApi } from "../../services/lucitApi";
import DeleteElementButton from './DeleteElementButton';
import { CSSFontSelector } from '../../containers/designer/DesignerCSS';
import { injectFont } from '../../containers/designer/DesignerFonts';
import { ParseStatusBar, invalidJSCodeError } from '../../containers/designer/CodeEditor';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            x: theme.palette.text.disabled,
            padding: theme.spacing(3)
        },

        cardWrapper: {
            boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            padding: '16px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(222, 222, 222, 0.7)'
        },
        selectedTemplate: {
            border: '1px solid #3f51b5'
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const TabContainer = ({ children }) => {

    return (
        <Box pt={2} mb={6} style={{ minHeight: "400px" }}>
            {children}
        </Box>
    )
}

const TabDetails = ({ name, setName, description, setDescription, image, setImage, setIsDirty, showImageUploader }) => {

    //name, classList, code, type, elementClass, description, image

    const { t } = useTranslation();

    return (
        <TabContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mb={2}>

                        {showImageUploader && <UploadFileAndGetImage
                            showMessage={null}
                            setDraggingIsHappening={() => { }}
                            setDraggingIsDone={() => { }}
                            onUpload={(files) => {
                                const file = files[0];

                                fileToBase64(file).then((fileBase64) => {
                                    lucitApi.uploads.uploadImage(fileBase64, file.name).then((response) => {
                                        setImage(response)
                                        setIsDirty(true)
                                    })
                                })
                            }}
                        >
                            {image ? <img src={image.options.public_url} style={{ maxHeight: 250 }} /> : `Upload an image`}
                        </UploadFileAndGetImage>
                        }

                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box mb={2}>
                                <TextField
                                    required
                                    helperText={t('Name this element')}
                                    id="element_name"
                                    label={t('Element Name')}
                                    value={name}
                                    fullWidth
                                    onChange={
                                        (e) => {
                                            setName(e.target.value)
                                            setIsDirty(true)
                                        }
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={2}>
                                <TextField
                                    required
                                    helperText={t('Description & Tags to aid in filtering and searching')}
                                    id="element_description"
                                    label={t('Desscription')}
                                    value={description}
                                    multiline
                                    fullWidth
                                    onChange={
                                        (e) => {
                                            setDescription(e.target.value)
                                            setIsDirty(true)
                                        }
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </TabContainer>
    )
}

const TabCSS = ({ defaultCss, setDefaultCss, setIsDirty }) => {

    return (
        <TabContainer>
            <EditorCSS
                css={defaultCss}
                onChange={(css) => {
                    setDefaultCss(css)
                    setIsDirty(true)
                }}
                editorBoxStyles={{ border: '1px solid #efefef' }}
            />

        </TabContainer>
    )
}

const TabSVG = ({ innerHtml, setInnerHtml, setIsDirty }) => {

    return (
        <TabContainer>
            <EditorHTML
                html={innerHtml}
                onChange={(html) => {
                    setInnerHtml(html)
                    setIsDirty(true)
                }}
                editorBoxStyles={{ border: '1px solid #efefef' }}
            />
        </TabContainer>
    )
}

const TabHTML = ({ innerHtml, setInnerHtml, setIsDirty }) => {
    return (
        <TabContainer>
            <EditorHTML
                html={innerHtml}
                onChange={(html) => {
                    setInnerHtml(html)
                    setIsDirty(true)
                }}
                editorBoxStyles={{ border: '1px solid #efefef' }}
            />
        </TabContainer>
    )

}

const TabJS = ({ js, setJs, setIsDirty }) => {

    const [codeError, setCodeError] = useState(null);

    const endsInCloseParenOrSemiColon = (inputString) => {
        return inputString.trim().endsWith(")") || inputString.trim().endsWith(");");
    }

    const invalidCodeError = () => {

        if (!endsInCloseParenOrSemiColon(js)) {
            return "Syntax Error : Missing closing parenthesis";
        }

        const countOfDoubleLeftCurlyBraces = (js.match(/{{/g) || []).length;
        const countOfDoubleRightCurlyBraces = (js.match(/}}/g) || []).length;

        if (countOfDoubleLeftCurlyBraces !== countOfDoubleRightCurlyBraces) {
            return "Syntax Error : Mismatched curly braces.  Please ensure each pair of {{ has a matching }}";
        }

        //Remove the curly braces
        const prepareCodeForTest = js && js.replace(/{{/g, "").replace(/}}/g, "");

        return invalidJSCodeError(prepareCodeForTest);
    }

    useEffect(() => {

        if (!js) {
            setCodeError(null);
            return;
        }

        setCodeError(invalidCodeError());
    }, [js]);

    return (
        <TabContainer>
            <EditorJS
                js={js}
                onChange={(js) => {
                    setJs(js)
                    setIsDirty(true)
                }}
                editorBoxStyles={{ border: '1px solid #efefef' }}
            />

            <div>
                <ParseStatusBar
                    message={codeError}
                />
            </div>

        </TabContainer>
    )

}

const TabFonts = ({ defaultCss, setDefaultCss, setFonts, setIsDirty }) => {

    return (
        <TabContainer>
            <CSSFontSelector
                css={defaultCss}
                cssKey="font-family"
                onChangeCss={(css) => setDefaultCss(css)}
                showAddNew={false}
                onSelectFont={(font) => {

                    console.log(font)

                    setFonts([font])
                    injectFont(font)
                    setIsDirty(true)
                }}
            />
        </TabContainer>
    )
}

const TabElementSettings = ({ elementSettings, setElementSettings, setIsDirty }) => {

    const [originalElementSettings] = useState(elementSettings);
    const [js, setJs] = useState('[]');
    const [jsObject, setJsObject] = useState([]);
    const [codeError, setCodeError] = useState(null);

    useEffect(() => {
        if (originalElementSettings) {
            setJs(JSON.stringify(originalElementSettings, null, 2))
        }
    }, [originalElementSettings])

    useEffect(() => {
        try {
            const newSettings = JSON.parse(js)
            setJsObject(newSettings)
        }
        catch (e) {
            console.log(e)
        }
    }, [js])

    useEffect(() => {
        if (elementSettings !== jsObject) {
            setElementSettings(jsObject)
            setIsDirty(true)
        }
    })

    useEffect(() => {

        if (!js) {
            setCodeError(null);
            return;
        }

        try {
            JSON.parse(js);
            setCodeError(null);
        } catch (e) {
            setCodeError("Syntax Error : Invalid JSON");
        }
    }, [js]);

    return (
        <TabContainer>
            <EditorJS
                js={js}
                onChange={(js) => {
                    setJs(js)
                    setIsDirty(true)
                }}
                editorBoxStyles={{ border: '1px solid #efefef' }}
            />

            <div>
                <ParseStatusBar
                    message={codeError}
                />
            </div>

        </TabContainer>
    )

}

const SelectTypeAndElementClass = ({ setType, setElementClass }) => {

    const options = [];

    options.push({
        name: 'HTML Shape',
        type: Designer.ObjectTypes.Object,
        class: Designer.ObjectElementClasses.BasicShape.class
    })

    options.push({
        name: 'SVG Shape',
        type: Designer.ObjectTypes.Svg,
        class: Designer.ObjectElementClasses.BasicShape.class
    })

    options.push({
        name: 'Image Shape',
        type: Designer.ObjectTypes.Image,
        class: Designer.ObjectElementClasses.BasicShape.class
    })

    options.push({
        name: 'Text',
        type: Designer.ObjectTypes.Text,
        class: Designer.ObjectElementClasses.Text.class
    })

    options.push({
        name: 'Background (CSS)',
        type: Designer.ObjectTypes.Object,
        class: Designer.ObjectElementClasses.Background.class
    })

    options.push({
        name: 'Background (SVG)',
        type: Designer.ObjectTypes.Svg,
        class: Designer.ObjectElementClasses.Background.class
    })

    options.push({
        name: 'Background Image',
        type: Designer.ObjectTypes.Image,
        class: Designer.ObjectElementClasses.Background.class
    })

    return <Grid container spacing={2}>
        {options.map((option, index) => {
            return (
                <Grid item xs={4} key={index}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setType(option.type)
                            setElementClass(option.class)
                        }}
                    >
                        {option.name}
                    </Button>
                </Grid>
            )
        })
        }
    </Grid>
}

const DriveTemplateElementEditor = ({ open, handleClose, driveTemplateElement, selectedApplication }) => {

    const [name, setName] = useState(driveTemplateElement?.name ?? 'My Element');
    const [defaultCss, setDefaultCss] = useState(driveTemplateElement?.default_css ?? '');
    const [description, setDescription] = useState(driveTemplateElement?.description);
    const [elementClass, setElementClass] = useState(driveTemplateElement?.element_class);
    const [type, setType] = useState(driveTemplateElement?.type);
    const [fonts, setFonts] = useState(driveTemplateElement?.fonts);
    const [image, setImage] = useState(driveTemplateElement?.image);
    const [innerHtml, setInnerHtml] = useState(driveTemplateElement?.inner_html ?? '');
    const [js, setJs] = useState(driveTemplateElement?.js ?? '');
    const [elementSettings, setElementSettings] = useState(driveTemplateElement?.element_settings);

    const code = driveTemplateElement?.code;

    const [isDirty, setIsDirty] = useState(false);

    const isModeNew = !driveTemplateElement?.id;
    const isModeEdit = !isModeNew;

    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false)

    const hasTypeAndElementClass = type && elementClass;

    const dispatch = useDispatch();

    const defaultCssContainsFill = defaultCss && defaultCss.includes('fill');

    const getInlinePreviewCss = () => {

        const commonCss = 'font-size:2em;text-align:middle;vertical-align:center;';

        var previewCss = commonCss;

        if (!defaultCssContainsFill && type === Designer.ObjectTypes.Svg) {
            previewCss += 'fill: rgb(51, 51, 51);'
        }

        if (elementClass === Designer.ObjectElementClasses.Background.class) {
            previewCss += 'height: 100%; width: 100%;background-size:cover;background-repeat:no-repeat;background-position:center;';
        }
        else if (elementClass === Designer.ObjectElementClasses.BasicShape.class && type === Designer.ObjectTypes.Image) {
            previewCss += 'width:100%;height:100%;background-size:contain;background-repeat:no-repeat;background-position:center;';
        }
        else {
            previewCss += 'width:100%;height:100%;';
        }

        if (defaultCss)
            previewCss += defaultCss;

        return previewCss;

    }

    const getPreviewInnerHtml = () => {
        if (!innerHtml && type === Designer.ObjectTypes.Text && elementClass === Designer.ObjectElementClasses.Text.class) {
            return name;
        }

        return innerHtml ?? '';
    }

    const getPreviewHtml = () => {

        //This function should mirror autoGeneratePreviewHtml() in DriveTemplateElement.php

        let style = getInlinePreviewCss();

        if (type === Designer.ObjectTypes.Image && image) {
            style += `background-image: url('${image.options.public_url}');`
        }

        const previewInnerHtml = getPreviewInnerHtml();

        const html = `<div
                x-objectcode="${code}"
                id="{id}"
                title="${name}"
                style="${style}"
            >
                ${previewInnerHtml}
            </div>
        `;

        return html;

    }

    const tabList = {
        details: {
            //name, classList, code, type, elementClass, description, image
            code: "details",
            name: "Details",
            showForAll: true
        },
        svg: {
            code: "svg",
            name: "SVG",
            showForTypes: [
                Designer.ObjectTypes.Svg
            ],
            showForClasses: [
                Designer.ObjectElementClasses.BasicShape.class,
                Designer.ObjectElementClasses.Background.class
            ]
        },
        html: {
            code: "html",
            name: "HTML",
            showForTypes: [
                Designer.ObjectTypes.Object,
                Designer.ObjectTypes.Text
            ],
            showForClasses: [
                Designer.ObjectElementClasses.BasicShape.class,
                Designer.ObjectElementClasses.Text.class,
            ]
        },
        css: {
            code: "css",
            name: "CSS",
            showForAll: true
        },
        js: {
            code: "js",
            name: "JS",
            showForAll: true
        },
        fonts: {
            code: "fonts",
            name: "Fonts",
            showForTypes: [
                Designer.ObjectTypes.Text
            ],
            showForClasses: [
                Designer.ObjectElementClasses.Text.class
            ]
        },
        elementSettings: {
            code: "elementSettings",
            name: "Settings",
            showForTypes: [
                Designer.ObjectTypes.Text,
                Designer.ObjectTypes.Object,
                Designer.ObjectTypes.Svg,
                Designer.ObjectTypes.Image
            ],
            showForClasses: [
                Designer.ObjectElementClasses.Text.class,
                Designer.ObjectElementClasses.BasicShape.class,
                Designer.ObjectElementClasses.Background.class
            ]
        }
    }

    const [selectedTab, setSelectedTab] = useState("details");

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const canShowTab = (tab) => {
        if (tab.showForAll) {
            return true;
        }

        const typesMatch = tab.showForTypes && tab.showForTypes.includes(type);
        const classesMatch = tab.showForClasses && tab.showForClasses.includes(elementClass);

        return typesMatch && classesMatch;
    }

    const { t } = useTranslation();

    const addNewElement = () => {

        if (type === Designer.ObjectTypes.Svg && elementClass === Designer.ObjectElementClasses.BasicShape.class) {
            return dispatch(storeBasicShape({
                name,
                type: Designer.ObjectTypes.Svg,
                description,
                inner_html: innerHtml,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Object && elementClass === Designer.ObjectElementClasses.BasicShape.class) {
            return dispatch(storeBasicShape({
                name,
                type: Designer.ObjectTypes.Object,
                description,
                inner_html: innerHtml,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Image && elementClass === Designer.ObjectElementClasses.BasicShape.class) {
            return dispatch(storeBasicShapeImage({
                name,
                description,
                image_lcuid: image.lcuid,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Object && elementClass === Designer.ObjectElementClasses.Background.class) {
            return dispatch(storeBackground({
                name,
                type: Designer.ObjectTypes.Object,
                description,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Svg && elementClass === Designer.ObjectElementClasses.Background.class) {
            return dispatch(storeBackground({
                name,
                type: Designer.ObjectTypes.Svg,
                inner_html: innerHtml,
                description,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Image && elementClass === Designer.ObjectElementClasses.Background.class) {
            return dispatch(storeBackgroundImage({
                name,
                description,
                image_lcuid: image.lcuid,
                default_css: defaultCss,
                js: js,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        if (type === Designer.ObjectTypes.Text && elementClass === Designer.ObjectElementClasses.Text.class) {
            return dispatch(storeText({
                name,
                description,
                inner_html: innerHtml,
                default_css: defaultCss,
                js: js,
                default_html: innerHtml,
                fonts: fonts,
                element_settings: elementSettings,
                ...(selectedApplication && selectedApplication !== "global" && { application_lcuid: selectedApplication.lcuid })
            }))
        }

        //No matching type and class
        throw new Error('Invalid type and class combination');
    }

    const updateElement = () => {

        return dispatch(updateDriveTemplateElement(driveTemplateElement.lcuid, {
            name,
            description,
            default_css: defaultCss,
            default_html: innerHtml,
            js: js,
            fonts: fonts,
            element_settings: elementSettings,
        }))
    }

    const saveElement = () => {

        if (isModeNew) {
            return addNewElement()
        }
        else {
            return updateElement()
        }
    }

    const elementRequiresHtml = () => {
        return elementClass === Designer.ObjectElementClasses.BasicShape.class && (
            type === Designer.ObjectTypes.Svg || type === Designer.ObjectTypes.Object
        )

    }

    const disableSave = () => {

        if (elementRequiresHtml() && !innerHtml) {
            return true;
        }

        return !isDirty || isSubmitting || !description || !name
    }

    return (
        <GenericDialog
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>

                {!hasTypeAndElementClass && <SelectTypeAndElementClass
                    setType={setType}
                    setElementClass={setElementClass}
                />}

                {hasTypeAndElementClass && <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            {Object.keys(tabList).filter(
                                (tabKey) => canShowTab(tabList[tabKey])
                            ).map((tabKey) => {
                                return (
                                    <Tab
                                        key={tabKey}
                                        label={t(tabList[tabKey].name)}
                                        value={tabList[tabKey].code}
                                    />
                                )
                            })}
                        </Tabs>
                    </Grid>

                    <Grid item xs={8}>

                        {selectedTab === "details" && <TabDetails
                            name={name}
                            setName={setName}
                            description={description}
                            setDescription={setDescription}
                            image={image}
                            setImage={setImage}
                            setIsDirty={setIsDirty}
                            showImageUploader={isModeNew && type === Designer.ObjectTypes.Image}
                        />}

                        {selectedTab === "css" && <TabCSS
                            defaultCss={defaultCss}
                            setDefaultCss={setDefaultCss}
                            setIsDirty={setIsDirty}
                        />}

                        {selectedTab === "svg" && <TabSVG
                            innerHtml={innerHtml}
                            setInnerHtml={setInnerHtml}
                            setIsDirty={setIsDirty}
                        />}

                        {selectedTab === "html" && <TabHTML
                            innerHtml={innerHtml}
                            setInnerHtml={setInnerHtml}
                            setIsDirty={setIsDirty}
                        />}

                        {selectedTab === "js" && <TabJS
                            js={js}
                            setJs={setJs}
                            setIsDirty={setIsDirty}
                        />}

                        {selectedTab === "fonts" && <TabFonts
                            fonts={fonts}
                            setFonts={setFonts}
                            defaultCss={defaultCss}
                            setDefaultCss={setDefaultCss}
                            setIsDirty={setIsDirty}
                        />}

                        {selectedTab === "elementSettings" && <TabElementSettings
                            elementSettings={elementSettings}
                            setElementSettings={setElementSettings}
                            setIsDirty={setIsDirty}
                        />}

                    </Grid>
                    <Grid item xs={4} justifyContent="center" alignItems="center">
                        <div
                            style={{
                                margin: "0 auto",
                                paddingTop: "40px",
                                width: "200px",
                                height: "200px",
                            }}
                            dangerouslySetInnerHTML={{ __html: getPreviewHtml() }}
                        ></div>
                    </Grid>
                </Grid>
                }
            </>}

            ActionsComponent={<>
                {isModeEdit && <DeleteElementButton
                    driveTemplateElement={driveTemplateElement}
                    handleClose={handleClose}
                />}

                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    color="secondary"
                    variant="contained"
                    submitting={isSubmitting}
                    disabled={disableSave()}
                    onClick={() => {
                        setIsSubmitting(true)
                        saveElement().then(() => {
                            setIsSubmitting(false)
                            handleClose()
                        })
                    }}
                >
                    {isModeNew ? t('Create') : t('Save')}
                </ButtonLoader>
            </>}
        />
    )

}

export default DriveTemplateElementEditor;
