import { Box, Button, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import ApplicationAvatar from './ApplicationAvatar';
import { ButtonLoader } from '../../components';
import { lucitApi } from '../../services/lucitApi';
import FlareIcon from '@material-ui/icons/Flare';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            // height: 'calc(100vh - 180px)',
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const NewAppAuthorization = (props) => {
    const { app, open, handleClose, handleAuthorized } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [permissions, setPermissions] = useState({});
    const [loadingPermissions, setLoadingPermissions] = useState(false);

    useEffect(() => {
        setLoadingPermissions(true);
        lucitApi.applications.getPermissions()
            .then(data => {
                const map = data.reduce((prev, curr) => ({ ...prev, [curr.fully_qualified_policy]: curr }), {});
                setPermissions(map)
            })
            .finally(() => setLoadingPermissions(false));
    }, []);

    return (
        <GenericDialog
            title={t('Authorize Application')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                {app
                    && <>
                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                            <ApplicationAvatar app={app} style={{ width: 100, height: 100, marginRight: 0 }} />

                            <Typography variant="h6">{app.name}</Typography>

                            {app.premium &&
                                <Box display="flex" alignItems="center" mb={1} mt={2}>
                                    <FlareIcon style={{ color: "#4caf50" }} />
                                    <Box ml={1} style={{ fontWeight: "bold", color: "#4caf50" }}>Premium App</Box>
                                    <Box ml={1} style={{ color: "#4caf50" }}>({t('May contain additional charges')})</Box>
                                </Box>}

                        </Box>
                        <Typography>
                            {t('{{name}} requires the following permissions to be allowed:', { name: app.name })}
                        </Typography>

                        {!loadingPermissions
                            && <Box>
                                <List style={{ listStyle: 'disc', listStylePosition: "inside" }}>
                                    {app.permissions.allowed.map(p => <ListItem key={p}
                                        style={{ paddingTop: 0, paddingBottom: 0, display: 'list-item' }}>
                                        <ListItemText
                                            style={{ display: 'inline-block' }}
                                            primary={permissions[p] && permissions[p].explanation}
                                        />
                                    </ListItem>)}
                                </List>
                            </Box>
                        }
                    </>}
            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader
                    submitting={loading}
                    onClick={() => {
                        setLoading(true);
                        handleAuthorized()
                            .then(handleClose)
                            .finally(() => setLoading(false));
                    }}
                    color="primary"
                    variant="contained">
                    {t('Allow')}
                </ButtonLoader>
            </>}
        />
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAppAuthorization)
