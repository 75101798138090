import React from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const ButtonLoader = ({ disabled, submitting, children, ...rest }) => (
    <Button
        {...rest}
        disabled={submitting || disabled}
        startIcon={submitting ? null : rest.startIcon}
        endIcon={submitting ? null : rest.endIcon}
    >
        {submitting && <CircularProgress size={14} style={{ marginRight: 8 }} />}
        {children}
    </Button>
);

export default ButtonLoader;
