import {
    REGISTER_CHANGE_TYPE, REGISTER_PHONECODE, REGISTER_EDIT_PHONE,
    REGISTER_RESET, REGISTER_SET_REGISTRATION
} from '../helpers/actionTypes';

export const RegisterTypes = {
    EMAIL: 'email',
    PHONE: 'phone'
}

export const initialState = {
    registerType: RegisterTypes.PHONE,
    registrationId: null,
    userName: null,
    email: null,
    phone: null,
    fullName: null,

    codeSentTime: null,
}

export default function register(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case REGISTER_CHANGE_TYPE:
            return Object.assign({}, state, {
                registerType: payload.registerType
            })
        case REGISTER_PHONECODE:
            return Object.assign({}, state, {
                userName: payload.userName,
                email: payload.email,
                codeSentTime: payload.codeSentTime
            });
        case REGISTER_EDIT_PHONE:
            return Object.assign({}, state, {
                codeSentTime: null,
                registrationId: null
            });
        case REGISTER_SET_REGISTRATION:
            return Object.assign({}, state, {
                registrationId: payload.id,
                phone: payload.phone,
            });
        case REGISTER_RESET:
            return Object.assign({}, state, initialState)
        default:
            return state
    }
}
