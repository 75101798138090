import React, { useEffect, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, makeStyles, Grid, Slider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { lucitApi } from '../../services/lucitApi';
import { Dialog } from '../../components';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles(theme => {
    return {
        content: {
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 3),
        },
        img: {
            height: 300,
            width: '100%',
            objectFit: 'contain'
        }
    }
})

export const ScreenPlaylistPreviewDialog = ({ screen, open, handleClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [playlist, setPlaylist] = useState([]);
    const [duration, setDuration] = useState(300);
    const [durationValue, setDurationValue] = useState(duration);

    useEffect(() => {
        setLoading(true);
        lucitApi.billboards.getPlaylist(screen.lcuid, { duration, track_analytics: 0 })
            .then(setPlaylist)
            .finally(() => setLoading(false));
    }, [duration]);

    const ratio = playlist.length > 0
        ? playlist[0].width / playlist[0].height
        : 1;

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            title={t('Playlist for ') + screen.name}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <Box position="sticky" top={0} zIndex={1} style={{ background: 'white' }}>
                    <Typography gutterBottom>
                        {t('Playlist Duration')}: <b>{moment.duration(duration, 'seconds').humanize()}</b>
                    </Typography>
                    <Slider
                        min={60}
                        max={3600}
                        step={10}
                        defaultValue={durationValue}
                        value={durationValue}
                        onChange={(_, value) => setDurationValue(value)}
                        onChangeCommitted={(_, value) => setDuration(value)}
                    />
                </Box>

                <Typography gutterBottom variant='h6'>
                    {t('Creatives')}
                </Typography>
                {loading
                    && <>
                        <Box display="flex" style={{ gap: 8 }}>
                            <Skeleton variant='rect' width="50%" height={300} />
                            <Skeleton variant='rect' width="50%" height={300} />
                        </Box>
                        <Box mt={1} display="flex" style={{ gap: 8 }}>
                            <Skeleton variant='rect' width="50%" height={300} />
                            <Skeleton variant='rect' width="50%" height={300} />
                        </Box>
                    </>}

                <Grid container spacing={1}>
                    {playlist.map(p => {
                        const md = ratio > 1.25
                            ? 6
                            : 4;

                        return <Grid key={p.lcuid} item xs={12} md={md}>
                            <img src={p.src} className={classes.img} />
                        </Grid>
                    })}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}
