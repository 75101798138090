import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Tooltip, useTheme } from '@material-ui/core';
import { BillingPlanStatus } from '../../helpers/constants';

function BillingPlanStatusBadge(props) {
    const { status } = props;
    const theme = useTheme();

    if (status === BillingPlanStatus.new) {
        return <Chip
            size="small"
            style={{ background: theme.palette.secondary.main, color: theme.palette.common.white }}
            label="New"
        />;
    }

    if (status === BillingPlanStatus.pendingStartDate) {
        return <Tooltip title="Waiting for start date">
            <Chip
                size="small"
                style={{ background: theme.palette.grey[400] }}
                label="Pending"
            />
        </Tooltip>;
    }

    if (status === BillingPlanStatus.active) {
        return <Chip
            size="small"
            style={{ background: theme.palette.success.main, color: theme.palette.common.white }}
            label="Active"
        />;
    }

    if (status === BillingPlanStatus.inactive) {
        return <Chip
            size="small"
            style={{ background: theme.palette.warning.light, color: theme.palette.common.white }}
            label="Not Active"
        />;
    }

    return null;
}

BillingPlanStatusBadge.propTypes = {
    status: PropTypes.number.isRequired
}
export default BillingPlanStatusBadge;
