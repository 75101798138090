import React, { useState } from 'react';
import { Grid, Divider, AccordionSummary, AccordionDetails } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { Bug } from 'react-ionicons';
import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import { setDeveloperMode } from '../../actions/user';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
        content: {
            padding: "8px 0"
        },
        headerIcon: {
            marginBottom: -3,
            width: '1em',
            height: '1em'
        }
    }
});

function DeveloperSettings({ isDeveloperMode, setDeveloperMode }) {
    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);
    const { t } = useTranslation();

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="DeveloperSettings-content"
                id="DeveloperSettings-header"
            >
                <LCTypography variant="h5" >
                    <Bug style={{
                        width: '1em',
                        height: '1em',
                        marginBottom: -3,
                        marginRight: 5
                    }} />
                    Developers</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>{t('Developer Mode')}</h2>
                                <p>{t('Enables access to developers features for '
                                    + 'integration purposes such as Applications, API tokens and etc.')}</p>
                            </IonLabel>
                            <IonToggle
                                checked={isDeveloperMode}
                                onIonChange={e => setDeveloperMode(e.target.checked)}
                                slot="start"></IonToggle>
                        </IonItem>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </AccordionScrollable>
    );
}

const mapStateToProps = state => {
    return {
        isDeveloperMode: state.user.isDeveloperMode
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDeveloperMode: enable => dispatch(setDeveloperMode(enable))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    DeveloperSettings
);
