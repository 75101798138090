import { IonCard } from '@ionic/react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import LCTypography from '../../../components/material/LCTypography';
import { getCapabilityConfigForClassName } from '../../../selectors/applications';

export const ApplicationCapabilitySettings = ({ application, capabilityClass }) => {

    const capabilityConfig = getCapabilityConfigForClassName(capabilityClass)

    return <IonCard style={{ color: 'unset' }}>
        <Box p={2}>
            <LCTypography variant="h6">{capabilityConfig.name} Settings</LCTypography>
            <Box p={2}>
                {
                    capabilityConfig.components.developerAppSettings ?
                        <CustomCapabilityComponent application={application} capabilityId={capabilityConfig.id} />
                        : <Alert severity="warning">No settings are configured for this capability.</Alert>
                }

            </Box>
        </Box>
    </IonCard>

}

const CustomCapabilityComponent = ({ application, capabilityId }) => {

    const LazyComponent = lazy(() => import('./' + capabilityId + '/AppCapabilitySettings.jsx'));

    return <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent application={application} />
    </Suspense>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ApplicationCapabilitySettings);
