import {
    OBJECT_APPLICATIONS_ASSIGN,
    OBJECT_APPLICATIONS_REQUEST,
    OBJECT_APPLICATIONS_RESULT,
    OBJECT_APPLICATIONS_UNASSIGN
} from "../../helpers/actionTypes";

export const initialState = {
    loading: false,

    apps: [],
    appsById: {}
}

export default function applications(state = initialState, action) {
    const initializedState = { ...initialState, ...state };

    switch (action.type) {
        case OBJECT_APPLICATIONS_REQUEST:
            return Object.assign(initializedState,
                state,
                {
                    loading: true
                });

        case OBJECT_APPLICATIONS_RESULT:
            return Object.assign(initializedState,
                state,
                {
                    loading: false,
                    apps: action.data,
                    appsById: action.data.reduce((prev, curr) => ({ ...prev, [curr.lcuid]: curr }), {})
                });
        case OBJECT_APPLICATIONS_ASSIGN:
            return Object.assign(initializedState,
                state,
                {
                    apps: [action.app, ...state.apps],
                    appsById: {
                        ...state.appsById,
                        [action.app.lcuid]: action.app
                    }
                });

        case OBJECT_APPLICATIONS_UNASSIGN:
            return Object.assign(initializedState,
                state,
                {
                    apps: state.apps.filter(x => x != action.app),
                });
        default:
            return state
    }
}
