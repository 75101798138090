import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControlLabel, Switch } from '@material-ui/core';
import { toggle } from '../../actions/featureToggle';

const FeatureSwitch = (props) => {
    const { features, featureName, handleChange } = props;

    return <FormControlLabel
        control={<Switch
            checked={features[featureName] || false}
            onChange={e => handleChange(featureName, e.target.checked)}
            name={featureName} />}
        label={featureName}
    />;
}

FeatureSwitch.propTypes = {
    featureName: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        features: state.featureToggle.features
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleChange: (featureName, enable) => dispatch(toggle(featureName, enable))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FeatureSwitch
)
