import React from 'react';
import { showInfo, updateActiveMessage } from '../../actions/snackbar';
import { RoutePaths } from '../../helpers/constants';
import { history } from '../../helpers/history';
import { TextNotification } from './TextNotification';

const CampaignStartedNotification = props => (
    <TextNotification {...props} />
);

const handleNotification = (notification, dispatch, handleClick) => {
    dispatch(
        showInfo(<CampaignStartedNotification notification={notification} />, {
            snackbarProps: {
                onClick: () => {
                    handleClick();
                    dispatch(updateActiveMessage({ isOpen: false }));
                }
            }
        }))
};

const handleClick = (notification) =>
    history.push({ pathname: RoutePaths.exports + `/${notification.data.inventory_export_id}` });

export { CampaignStartedNotification, handleNotification, handleClick }
