import React from 'react';
import { connect } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import Inventory from './Inventory';
import { InventoryItemClasses } from '../../helpers/constants';
import PostButton from '../../containers/inventory/PostButton'
import { hasExports } from '../../selectors/exports'

function PhotoStream(props) {
    const { inventorySearchOpen, hasExports } = props;

    return <>
        <Inventory
            title="Posts"
            noDataLabel={!inventorySearchOpen
                && <LCTypography variant="body1">
                    You don&apos;t have any posts yet.
                </LCTypography>}
            inventoryClass={InventoryItemClasses.photoStream}
            scrollToTopFab={false}
            Actions={hasExports ? <PostButton />
                : null}
            {...props} />

    </>
}

const mapStateToProps = state => {
    return {
        inventorySearchOpen: state.layout.inventorySearch.open,
        hasExports: hasExports(state),
    }
}

export default connect(
    mapStateToProps
)(PhotoStream);
