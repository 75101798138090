import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm, } from 'redux-form';
import { required } from '../helpers/validators';
import { Typography, Box, Button, Grid } from '@material-ui/core';
import PasswordField from '../components/inputs/PasswordField';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import OneTimeCodeField from '../components/inputs/OneTimeCodeField';

const useStyles = makeStyles(theme => {
    return {
        form: {
            width: '100%',
            maxWidth: '400px'
        },
        error: {
            marginBottom: theme.spacing(1)
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(1)
        }
    }
});

function ResetPassword(props) {
    const { handleSubmit, onSubmit, submitting, error, gridProps } = props;
    const { resendCode } = props;
    const classes = useStyles();

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} {...gridProps}>
                <Grid item xs={12}>
                    <OneTimeCodeField
                        resendCode={resendCode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="password"
                        validate={required}
                        component={PasswordField}
                        props={{
                            label: "Password",
                            type: "password",
                            variant: "outlined",
                            required: true,
                            fullWidth: true,
                            id: "password",
                            autoComplete: "new-password"
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="passwordConfirm"
                        validate={required}
                        component={PasswordField}
                        props={{
                            label: "Confirm password",
                            type: "password",
                            variant: "outlined",
                            required: true,
                            fullWidth: true,
                            id: "passwordConfirm",
                            autoComplete: "off",
                            hideStrengthMeter: true
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {error && <Typography className={classes.error} color="error">{error}</Typography>}
                    {submitting
                        && <Box className={classes.progress}>
                            <CircularProgressCentered />
                        </Box>}
                    <Button
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                        onClick={handleSubmit(onSubmit)}>Submit</Button>
                </Grid>
            </Grid>
        </form>
    );
}

ResetPassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    resendCode: PropTypes.func
}

export const FORM_RESET_PASSWORD = "resetPassword";
export default reduxForm({
    form: FORM_RESET_PASSWORD, // a unique name for this form
    validate: values => {
        const errors = {};

        if (values.password !== values.passwordConfirm) {
            errors.passwordConfirm = "Password confirmation doesn't match Password";
        }

        return errors;
    }
})(ResetPassword);
