import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import TextField from "@material-ui/core/TextField";
import { updateCustomField, removeCustomField, syncCustomFieldToExistingLayers } from '../../actions/designer';
import CustomFieldCodeEditor from './CustomFieldCodeEditor';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

const EditCustomFieldDialog = ({ open, handleClose, customFieldId }) => {

    const [error, setError] = useState(null);

    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const customField = useSelector(state => state.designer.customFieldMap.find(cf => cf.id === customFieldId));

    const [customFieldName, setCustomFieldName] = useState(customField ? customField.name : '');
    const [customFieldPlaceholder, setCustomFieldPlaceholder] = useState('');
    const [customFieldCode, setCustomFieldCode] = useState(customField ? customField.property : '');
    const [customFieldFields, setCustomFieldFields] = useState(customField?.fields ? customField.fields : []);

    const validateValues = () => {
        if (customFieldName === '') {
            alert(t('Please enter a custom field name'));
            return false;
        }

        if (customFieldCode === '') {
            alert(t('Please enter a custom field code'));
            return false;
        }

        return true;
    }

    const updateNewCustomField = () => {

        if (!validateValues())
            return;

        const updatedCustomField = {
            id: customFieldId,
            property: customFieldCode,
            macro: customFieldCode,
            macroCode: "{" + customFieldCode + "}",
            name: customFieldName,
            placeholder: customFieldPlaceholder,
            fields: customFieldFields
        }

        dispatch(updateCustomField(updatedCustomField));

        dispatch(syncCustomFieldToExistingLayers(updatedCustomField))

        handleClose();
    }

    const deleteField = () => {
        if (window.confirm(t('Are you sure you want to delete this custom field?'))) {
            dispatch(removeCustomField(customFieldId));
            handleClose();
        }
    }

    return (
        <>
            <GenericDialog
                title={t('Edit Custom Field')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: true,
                    maxWidth: 'md'
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<Grid container spacing={5}>
                    <Grid item xs={12}>
                        <LCTypography>
                            This is an advanced feature with little to no documentation. Please use with caution.
                        </LCTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Custom Field Name')}
                                    value={customFieldName}
                                    onChange={e => setCustomFieldName(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={2}></Grid>

                            <Grid item xs={5}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Placeholder')}
                                    value={customFieldPlaceholder}
                                    onChange={e => setCustomFieldPlaceholder(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFieldCodeEditor
                                    customFieldCode={customFieldCode}
                                    setCustomFieldCode={setCustomFieldCode}
                                    customFieldFields={customFieldFields}
                                    setCustomFieldFields={setCustomFieldFields}
                                    setError={setError}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                ActionsComponent={<>
                    <Button onClick={() => {
                        deleteField();
                    }}
                        variant="outlined"
                        color="secondary">
                        {t('Delete')}
                    </Button>

                    <Button onClick={() => {
                        handleClose();
                    }}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={error}
                        onClick={() => {
                            updateNewCustomField()
                        }}
                        color="primary">
                        {t('Save')}
                    </Button>
                </>}
            />
        </>
    )
}

export default EditCustomFieldDialog;
