/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function FileImage(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <path fillRule="evenodd" clipRule="evenodd" d="M21 6.40457V21.9472C21 22.9775 20.1661 23.8125 19.1379 23.8125H4.86208C3.83351 23.8125 3 22.9775 3 21.9472V2.05277C3 1.0225 3.83351 0.1875 4.86208 0.1875H14.7929L21 6.40457Z" fill="#DFE2E6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21 6.15128V6.9375H15.8178C14.7448 6.9375 14.25 6.06726 14.25 4.99409V0.1875H15.0348L21 6.15128Z" fill="#414549" />
        <path d="M10.8236 11.7963C10.6237 11.5251 10.2183 11.5251 10.0185 11.7963L6.58697 16.4534C6.34371 16.7835 6.57942 17.25 6.98949 17.25H13.8526C14.2627 17.25 14.4984 16.7835 14.2551 16.4534L10.8236 11.7963Z" fill="#414549" />
        <path d="M15.233 13.7802C15.0328 13.5292 14.6513 13.5292 14.4512 13.7802L12.3315 16.4382C12.0704 16.7657 12.3036 17.25 12.7224 17.25H16.9617C17.3806 17.25 17.6138 16.7657 17.3527 16.4382L15.233 13.7802Z" fill="#5F6469" />
        <path d="M17.0526 13.05C17.5758 13.05 18 12.6471 18 12.15C18 11.6529 17.5758 11.25 17.0526 11.25C16.5294 11.25 16.1052 11.6529 16.1052 12.15C16.1052 12.6471 16.5294 13.05 17.0526 13.05Z" fill="#5F6469" />
    </SvgIcon>
}
