import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Backdrop, ClickAwayListener } from '@material-ui/core';

import { useKeyPress } from '../../helpers/hooks';
import SearchAutocomplete from './SearchAutocomplete';

const useStyles = makeStyles(() => ({
    autocompleteRoot: {
        position: 'absolute',
        top: '20%',

        padding: '0 24px',
    },
    inputRoot: {
        height: 48,
    },
}));

function SearchBar(props) {
    const { open, onClose } = props;
    const classes = useStyles();

    useKeyPress('Escape', onClose);

    if (!open) {
        return null;
    }

    return <>
        <Backdrop open>
            <ClickAwayListener onClickAway={onClose}>
                <SearchAutocomplete
                    classes={{
                        autocompleteRoot: classes.autocompleteRoot,
                        inputRoot: classes.inputRoot
                    }} />
            </ClickAwayListener>
        </Backdrop>
    </>;
}

export default withRouter(SearchBar);
