import { Box } from '@material-ui/core';
import React from 'react';
import { PageTitle, StripeProvider } from '../../components';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { useSelector } from 'react-redux';
import { selectedAgency } from '../../selectors/user';
import BillingSettings from '../../containers/settings/BillingSettings';

function Billing() {
    const agency = useSelector(selectedAgency);

    return (
        <StripeProvider>
            <Box>
                <PageTitle title={"Billing"} />
                <BillingSettings
                    lcuid={agency.lcuid}
                />
            </Box>
        </StripeProvider>
    );
}

const BillingWithoutLayout = Billing;

export { BillingWithoutLayout };
export default withHomeLayout(BillingWithoutLayout);
