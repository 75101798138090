import React from 'react';
import PropTypes from 'prop-types';
import { FileExcel, FileImage, FilePdf, FileText, FileWord } from '../icons';
import { fileExtension, formatBytes } from '../../helpers/file';
import { Avatar, Box, CardActionArea, makeStyles, Typography } from '@material-ui/core';
import { FileExtension } from '../../helpers/constants';
import { CloudDownloadOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            '&:hover': {
                background: theme.palette.action.hover
            }
        },

        download: {
            marginLeft: 'auto'
        }
    }
})

const FileThumbnail = (props) => {
    const { className, fileName, fileSize = 0, user, src } = props;

    const classes = useStyles();

    return <CardActionArea className={classes.root}
        component="a"
        href={src}
        target="_blank">
        <Box p={1} display="flex" alignItems="center" className={className}>
            <Avatar variant='square' style={{ background: 'transparent', marginRight: 8 }}>
                <FileIcon fileName={fileName} style={{ width: 40, height: 40 }} />
            </Avatar>

            <Box style={{ minWidth: 0 }}>
                <Typography noWrap>{fileName}</Typography>
                <Typography variant='caption'>
                    {formatBytes(fileSize)}, {`${user && user.name}`}
                </Typography>
            </Box>

            <Box className={classes.download} >
                <CloudDownloadOutlined />
            </Box>
        </Box>
    </CardActionArea>
}

const FileIcon = ({ fileName, ...rest }) => {
    const extension = fileExtension(fileName);

    if (extension == FileExtension.pdf) {
        return <FilePdf {...rest} />
    }

    if (extension == FileExtension.doc ||
        extension == FileExtension.docx) {
        return <FileWord {...rest} />
    }

    if (extension == FileExtension.csv ||
        extension == FileExtension.xls ||
        extension == FileExtension.xlsx) {
        return <FileExcel {...rest} />
    }

    if (extension == FileExtension.png ||
        extension == FileExtension.jpg ||
        extension == FileExtension.jpeg ||
        extension == FileExtension.gif) {
        return <FileImage {...rest} />
    }

    return <FileText {...rest} />
}

FileThumbnail.propTypes = {
    src: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    fileSize: PropTypes.number.isRequired,

    className: PropTypes.string,
    user: PropTypes.object
}

export default FileThumbnail;
