import React from 'react';
import { Box } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import UserInfo from '../../containers/settings/UserInfo';
import SecurityInfo from '../../containers/settings/SecurityInfo';
import { connect } from 'react-redux';
import SystemInfo from '../../containers/settings/SystemInfo';
import { useState } from 'react';
import FeaturesToggle from '../../containers/settings/FeaturesToggle';
import { isAdminUser } from '../../selectors/user';
import NotificationsSettings from '../../containers/settings/NotificationsSettings';
import { PageTitle, StripeProvider } from '../../components';
import { DeveloperSettings } from '../../containers/settings';

function Settings(props) {
    const { isAdmin } = props;
    const [isEditUserInfo, setIsEditUserInfo] = useState(false);

    return (
        <StripeProvider>
            <Box mb={25}>
                <PageTitle title={"Settings"} />
                <UserInfo handleIsEdit={setIsEditUserInfo} />

                <NotificationsSettings />

                {!isEditUserInfo
                    && <SecurityInfo />}

                <DeveloperSettings />

                {isAdmin
                    && <FeaturesToggle />}

                <SystemInfo />
            </Box>
        </StripeProvider>
    );
}

const mapStateToProps = state => {
    return {
        isAdmin: isAdminUser(state)
    }
}

export default connect(
    mapStateToProps
)(
    withHomeLayout(
        Settings
    )
);
