import React from 'react';
import { Snackbar as SnackbarMaterial, makeStyles, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { hideSnackbar, setActiveMessage, updateActiveMessage } from '../../actions/snackbar';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
    alert: {
        cursor: 'pointer'
    },
    icon: {
        alignSelf: 'center'
    }
}));

const Snackbar = (props) => {
    const { messages, removeMessage, activeMessage, setActive, updateActive, ...other } = props;
    const classes = useStyles();

    useEffect(() => {
        if (messages.length && !activeMessage) {
            // Set a new snack when we don't have an active one
            const nextMessage = messages[0];

            setActive(nextMessage);
            removeMessage(nextMessage);
        } else if (messages.length && activeMessage && activeMessage.isOpen) {
            // Close an active snack when a new one is added
            updateActive({ isOpen: false });
        }
    }, [messages, activeMessage, removeMessage, setActive, updateActive]);

    if (!activeMessage) {
        return null;
    }

    const { isOpen, duration, message, severity, action, onClose, snackbarProps = {}, alertProps = {} } = activeMessage;

    const handleClose = (reason) => {
        if (reason !== 'clickaway') {
            updateActive({ isOpen: false });
            onClose && onClose(reason);
        }
    };

    return <>
        <SnackbarMaterial
            key={message}
            open={isOpen}
            autoHideDuration={duration}
            onClose={(_, reason) => handleClose(reason)}
            onExited={() => setActive(null)}
            {...other}
            {...snackbarProps}
        >
            <Alert
                elevation={6}
                variant="filled"
                severity={severity}
                className={classes.alert}
                classes={{
                    icon: classes.icon
                }}
                onClick={() => handleClose('click')}
                {...alertProps}>
                <Box>
                    {message}
                    {action
                        && <Box ml={1} display="inline-block">
                            {action}
                        </Box>}
                </Box>
            </Alert>
        </SnackbarMaterial>
    </>
};

const mapStateToProps = state => {
    return {
        ...state.snackbar,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActive: message => dispatch(setActiveMessage(message)),
        updateActive: data => dispatch(updateActiveMessage(data)),
        removeMessage: (message) => {
            dispatch(hideSnackbar(message));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Snackbar);