import { apiHttp } from "../helpers/api";

export default class LucitApiStories {

    getPublicStories(isLoggedIn, filters) {
        return apiHttp.get(
            isLoggedIn
                ? `/stories/public`
                : `/stories/public/noauth`,
            {
                params: {
                    perPage: filters.perPage,
                    page: filters.page
                }
            })
            .then(response => response.data.feed)
            .then(response => ({ ...response, data: response.data.map(x => x.story) }))
    }

    getUserStories(isLoggedIn, userId, filters) {
        return apiHttp.get(isLoggedIn
            ? `users/${userId}/stories`
            : `users/${userId}/stories/noauth`, {
            params: {
                perPage: filters.perPage,
                page: filters.page
            }
        })
            .then(response => response.data.feed)
            .then(response => ({ ...response, data: response.data.map(x => x.story) }))
    }

    getAccountStories(isLoggedIn, accountId, filters) {
        return apiHttp.get(isLoggedIn
            ? `accounts/${accountId}/stories`
            : `accounts/${accountId}/stories/noauth`, {
            params: {
                perPage: filters.perPage,
                page: filters.page
            }
        })
            .then(response => response.data.feed)
            .then(response => ({ ...response, data: response.data.map(x => x.story) }))
    }

    getAgencyStories(isLoggedIn, agencyId, filters) {
        return apiHttp.get(isLoggedIn
            ? `agencies/${agencyId}/stories`
            : `agencies/${agencyId}/stories/noauth`, {
            params: {
                perPage: filters.perPage,
                page: filters.page
            }
        })
            .then(response => response.data.feed)
            .then(response => ({ ...response, data: response.data.map(x => x.story) }))
    }

    getBillboardStories(isLoggedIn, digitalBoardId, filters) {
        return apiHttp.get(isLoggedIn
            ? `digital-boards/${digitalBoardId}/stories`
            : `digital-boards/${digitalBoardId}/stories/noauth`, {
            params: {
                perPage: filters.perPage,
                page: filters.pagee
            }
        })
            .then(response => response.data.feed)
            .then(response => ({ ...response, data: response.data.map(x => x.story) }))
    }

    getAgencyStoryCount(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/stories/new/count`)
            .then(response => response.data.story_count_new)
    }
}
