import { apiHttp } from "../helpers/api";

export default class LucitApiLightning {
    confirmCode(code) {
        return apiHttp.post(`/lightning/device-requests/confirm-code`, { code })
            .then(response => response.data.device_request);
    }

    generateDevice(lcuid, params) {
        return apiHttp.post(`/lightning/device-requests/${lcuid}/generate-device`, params)
            .then(response => response.data.lightning_device);
    }

    attachToDevice(lcuid, deviceLcuid) {
        return apiHttp.post(`/lightning/device-requests/${lcuid}/attach-to-device`, { device_lcuid: deviceLcuid })
            .then(response => response.data);
    }

    getDevice(lcuid) {
        return apiHttp.get(`/lightning/devices/${lcuid}`)
            .then(response => response.data);
    }

    setSettings(lcuid, settings) {
        const { slot_length, ...rest } = settings;

        return apiHttp.put(`/lightning/devices/${lcuid}/set-device-settings`, {
            device_settings: rest,
            slot_length
        })
            .then(response => response.data.lightning_device);
    }

    updateDevice(lcuid, params) {
        return apiHttp.put(`/lightning/devices/${lcuid}`, params)
            .then(response => response.data);
    }

    moveDevice(lcuid, params) {
        return apiHttp.put(`/lightning/devices/${lcuid}/move`, params)
            .then(response => response.data);
    }

    changeDeviceLayout(lcuid, params) {
        return apiHttp.put(`/lightning/devices/${lcuid}/change-device-layout`, params)
            .then(response => response.data);
    }

    makeInactive(lcuid) {
        return apiHttp.put(`/lightning/devices/${lcuid}/make-inactive`)
            .then(response => response.data);
    }

    makeActive(lcuid) {
        return apiHttp.put(`/lightning/devices/${lcuid}/make-active`)
            .then(response => response.data);
    }

    createDeviceLayout(params) {
        return apiHttp.post(`/lightning-device-layouts`, params)
            .then(response => response.data);
    }

    updateDeviceLayout(lcuid, params) {
        return apiHttp.put(`/lightning-device-layouts/${lcuid}`, params)
            .then(response => response.data);
    }

    makeDefaultLayoutDefault(lcuid) {
        return apiHttp.put(`/lightning-device-layouts/${lcuid}/make-default`)
            .then(response => response.data);
    }

    addInstruction(lcuid, params) {
        return apiHttp.post(`/lightning/devices/${lcuid}/instructions`, params)
            .then(response => response.data.lightning_device_remote_instruction);
    }

    removeInstruction(lcuid, instructionLcuid) {
        return apiHttp.delete(`/lightning/devices/${lcuid}/instructions/${instructionLcuid}`)
            .then(response => response.data);
    }

    getInstructions(lcuid) {
        return apiHttp.get(`/lightning/devices/${lcuid}/instructions`)
            .then(response => response.data.lightning_device_remote_instructions);
    }

    getLogs(lcuid) {
        return apiHttp.get(`/lightning/devices/${lcuid}/logs`)
            .then(response => response.data.logs)
    }

}
