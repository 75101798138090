import React from 'react';
import {
    Button,
    Box
} from '@material-ui/core';
import WeightsEditor from '../../components/weights/WeightsEditor';
import { Global } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';

const WeightsEditorBox = ({ weights, setWeights }) => {

    const { t } = useTranslation();

    const handleReset = () => setWeights(
        weights.map(x => ({ ...x, weight: Global.defaultItemWeight }))
    )

    return <>
        <Box mb={2} display="flex" justifyContent="space-between">
            <LCTypography variant='h6'>Adjust Frequency</LCTypography>
            <Button
                onClick={handleReset}
                color="primary"
                variant="outlined">{t('Reset')}</Button>
        </Box>
        <Box>
            <WeightsEditor
                items={weights}
                onChange={setWeights} />
        </Box>
    </>;
}

export default WeightsEditorBox;
