import React from 'react';
import { Box, ListItemText, ListItem, Grid, Button, useTheme } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { lastRunAt } from '../../selectors/feeds';
import { FeedStatus } from '../../helpers/constants';
import { RoutePaths } from '../../helpers/constants';
import DateTimeRelative from '../../components/DateTimeRelative';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none',
            width: '20px',
            height: '20px',
        },
        image: {
            width: '100%',
            objectFit: 'contain'
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },

        imageIcon: {
            width: '100%',
            height: '100%',
            fill: theme.palette.action.active
        },
        active: {
            background: theme.palette.success.main,
            color: theme.palette.common.white
        },
    }
})

function FeedListItem(props) {
    const { item } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    const theme = useTheme();

    return (
        <ListItem key={item.id}
            disableGutters
            component={RouterLink}
            to={`${RoutePaths.feeds}/${item.id}`}
            className="MuiLink-root"
            style={{ color: theme.palette.secondary.main }}
        >
            <ListItemText>
                <LCTypography transProps={{ iname: item.name }} variant="body1">{{ iname: item.name }}</LCTypography>
                <Box>
                    <Grid container spacing={1}>
                        {item.last_completed_run_converted
                            && <Grid item className={classes.gridItem} >
                                <LCTypography transProps={{ lastItem: item.last_completed_run_converted }}
                                    variant="body2" color="textSecondary">
                                    {{ lastItem: item.last_completed_run_converted }} processed,&nbsp;
                                    <DateTimeRelative date={lastRunAt(item)} />
                                </LCTypography>
                            </Grid>}

                        {item.status === FeedStatus.setup
                            && <Grid item className={classes.gridItem}>
                                <LCTypography
                                    variant="body2" color="textSecondary">
                                    Feed has not completed setup
                                </LCTypography>
                            </Grid>}

                        {item.status === FeedStatus.error
                            && <Grid item className={classes.gridItem}>
                                <LCTypography
                                    variant="body2" color="error">
                                    An error has occurred running this feed
                                </LCTypography>
                            </Grid>}

                    </Grid>
                </Box>
            </ListItemText>
            <Box>
                <Button variant='outlined' color="secondary">
                    {t('Settings')}
                </Button>
            </Box>
        </ListItem>
    );
}

export default withRouter(
    FeedListItem
);
