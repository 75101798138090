import moment from "moment";
import { createSelector } from "reselect";
import { AppTourStatus, Global, InventoryItemClasses, ProfileType, Roles } from "../helpers/constants";
import { idOrLcuidEquals } from "./objects";
import { isOperator } from "../selectors/agencies";

export const isLoggedIn = state => state.user.isLoggedIn;
export const getUser = state => state.user;
export const isAdminUser = state => state.user && state.user.roles.some(r => r === Roles.admin);

export const userIsBotUser = (user) => user && user.roles.split(":").some(r => r === Roles.bot);
export const userIsUser = (user) => user && user.roles.split(":").some(r => r === Roles.user);

export const isEulaAccepted = createSelector(
    [getUser],
    user => {
        const options = userOptions(user);
        const eualAccept = options.eula_accept && options.eula_accept[user.applicationId];

        return eualAccept
            && moment.utc(eualAccept.datetime).isValid()
            && moment.utc(eualAccept.datetime).isAfter(moment.utc(Global.eulaModifiedAt))
    }
)

export const isAccountSelected = user => user.selectedAccountId != null;
export const isAllAccountSelected = () => false;
export const hasAccounts = user => user.accounts.length > 0;

export const accountIsDemo = (account) => {
    if (!account)
        return true; //doesn't exist

    if (!account.options)
        return true; //Something is wrong

    return account.options.model_is_demo
}

//Return true if at least ONE of this users accounts is NOT a demo account
export const hasRealAccounts = user => {
    const totalRealAccounts = user.accounts.reduce((realAccountCounter, account) => {
        return !accountIsDemo(account) ? realAccountCounter + 1 : realAccountCounter
    }, 0)

    return totalRealAccounts > 0
}

export const hasAccount = user => id => user.accounts.some(a => a.id === parseInt(id) || a.lcuid === id);
export const isSingleAccount = user => user.accounts.length === 1;
export const isMultiAccount = user => user.accounts.length > 1;

export const isAgencySelected = user => user.selectedAgencyId != null;
export const isAllAgencySelected = () => false;

export const selectedAgency = createSelector(
    [getUser],
    user => user.agencies.find(x => x.id === user.selectedAgencyId)
)

export const agencies = user => user.agencies;
export const hasAgencies = user => user.agencies.length > 0;
export const hasAgency = user => id => user.agencies.some(a => a.id === id || a.lcuid === id);
export const isSingleAgency = user => user.agencies.length === 1;
export const isMultiAgency = user => user.agencies.length > 1;

export const accountsTreeSearch = (state, search, agencyId) => {
    const accounts = state.user.accounts
        .filter(a => !search || (a.name && a.name.toLowerCase().indexOf(search.toLowerCase()) > -1))
        .filter(a => !agencyId || a.agency_id === agencyId)
        ;

    return accountsTree(accounts)
}

export const accountsTree = (accounts) => {
    // For optimization purpose, Dictionary has O(1) complexity for getting key
    const accountsMap = accounts.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});

    // Find accounts which doesn't have parent at all, or account which parent are not in list
    const rootAccounts = accounts
        .filter(account => account.parent_account_id == null
            || !accountsMap[account.parent_account_id]);

    // {accounts} - array of accounts for which we need assign children
    // {level}    - level of recursive depth
    const recursivelyAssignChildren = (rootAccounts, level = 0) => {
        if (level > Global.accountMaxLevelHierarchy) {
            return rootAccounts;
        }

        for (const account of rootAccounts) {
            account.children = accounts.filter(a => a.parent_account_id === account.id);

            recursivelyAssignChildren(account.children, level + 1);
        }

        return rootAccounts;
    }

    return recursivelyAssignChildren(rootAccounts);
};

export const profiles = (state) => {
    return [
        ...[{ ...state.user, type: ProfileType.user, order: 0 }],
        ...state.user.accounts.map(x => ({ ...x, type: ProfileType.account, order: 1 })),
        ...state.user.agencies.map(x => ({ ...x, type: ProfileType.agency, order: 2 }))
    ];
}

export const isAccountParentForAnother = (account, anotherAccount) => {
    if (!anotherAccount
        || !account
        || !account.children
        || !account.children.length) {
        return false;
    }

    if (account.id === anotherAccount.parent_account_id) {
        return true;
    }

    return account.children.some(child => isAccountParentForAnother(child, anotherAccount))
}

export const channelGloballyEnabled = (user, channel) => user.options.notification_channels
    ? user.options.notification_channels.includes(channel)
    : false;

export const userOptions = user => Object.assign({
    tours: {},
    notification_channels: [],
    eula_accept: {}
}, user.options)

export const userTourSelector = createSelector(
    [getUser],
    user => tourId => Object.assign({
        id: tourId,
        status: AppTourStatus.pending,
        data: {}
    }, userOptions(user).tours[tourId])
)
export const userTimezone = user => user.options.timezone;
export const canRemoveCards = user => user.billing.cards.length > 1
    || (user.billing.cards.length === 1
        && user.billing.options.can_remove_last_card);

export const selectedAccount = createSelector(
    [getUser],
    user => user.accounts.find(x => x.id === user.selectedAccountId)
)

export const selectedAccountId = createSelector(
    [getUser],
    user => user.selectedAccountId
)

export const accountSelector = createSelector(
    [getUser],
    user => id => user.accounts.find(a => a.id === parseInt(id) || a.lcuid === id)
)

export const allAccountIds = createSelector(
    [getUser],
    user => user.accounts.map(a => a.id)
)

export const selectedAccounts = createSelector(
    [getUser],
    user => isAccountSelected(user)
        ? user.accounts.filter(a => a.id === user.selectedAccountId)
        : user.accounts
)

export const selectedAccountIds = createSelector(
    [selectedAccounts],
    accounts => accounts.map(a => a.id)
)

export const selectedAgencies = createSelector(
    [getUser],
    user => isAgencySelected(user)
        ? user.agencies.filter(a => a.id === user.selectedAgencyId)
        : user.agencies
)

export const selectedAgencyIds = createSelector(
    [selectedAgencies],
    agencies => agencies.map(a => a.id)
)

export const agencySelector = createSelector(
    [getUser],
    user => id => user.agencies.find(a => a.id === parseInt(id) || a.lcuid === id)
)

export const accountInventoryItemClasses = account => {
    const allClasses = Object.values(InventoryItemClasses);

    let classes = Array.isArray(account.inventory_item_class)
        ? account.inventory_item_class
        : [account.inventory_item_class];

    classes = classes.map(c => allClasses.find(value => value.class === c))
        .filter(c => !!c);

    return classes.length
        ? classes
        : [InventoryItemClasses.generic]
}

export const selectedAccountInventoryClasses = createSelector(
    [getUser, selectedAccount],
    (user, selectedAccount) => {
        const inventoryClasses = isAccountSelected(user) && selectedAccount
            ? [...accountInventoryItemClasses(selectedAccount)]
            : user.accounts.reduce((accum, a) => [...accum, ...accountInventoryItemClasses(a)], [])

        // eslint-disable-next-line no-undef
        return inventoryClasses.length
            ? inventoryClasses.filter((x, i, array) => array.indexOf(x) === i)
            : [InventoryItemClasses.generic]
    }
)

export const hasRealEstateInventoryClass = createSelector(
    [selectedAccountInventoryClasses],
    inventoryClasses => inventoryClasses.find(c => c === InventoryItemClasses.real_estate)
)

export const hasPhotoStreamInventoryClass = createSelector(
    [selectedAccountInventoryClasses],
    inventoryClasses => inventoryClasses.find(c => c === InventoryItemClasses.photoStream)
)

export const hasCreativeInventoryClass = createSelector(
    [selectedAccountInventoryClasses],
    inventoryClasses => inventoryClasses.find(c => c === InventoryItemClasses.creative)
)

export const hasChildCampaignInventoryClass = createSelector(
    [selectedAccountInventoryClasses],
    inventoryClasses => inventoryClasses.find(c => c === InventoryItemClasses.childCampaign)
)

export const getUserRoles = user => user.pivot
    ? user.pivot.roles
        .split(":")
        .filter(x => Boolean(x))
    : [];

export const getUserAgencyPivot = (user, agencyId) => {

    const agency = user.agencies && user.agencies.find(x => idOrLcuidEquals(x, agencyId));

    return agency
        ? agency.pivot
        : null;
}

export const getUserAccountPivot = (user, accountId) => {
    const account = user.accounts && user.accounts.find(x => idOrLcuidEquals(x, accountId));

    return account
        ? account.pivot
        : null;
}

export const getUserApplicationPivot = (user, appId) => {
    const app = user.applications && user.applications.find(x => idOrLcuidEquals(x, appId));

    return app
        ? app.pivot
        : null;
}

export const userHasApplication = (user, appId) => {
    const app = user.applications && user.applications.find(x => idOrLcuidEquals(x, appId));

    return app ? true : false;
}

export const userAccounts = state => state.user.accounts;

export const selectedAgencyRoles = createSelector(
    [selectedAgencies],
    agencies => agencies.map(agency => getUserRoles(agency))
        .flat()
        .filter((x, idx, array) => array.indexOf(x) === idx)
);

export const selectedAccountRoles = createSelector(
    [selectedAccounts],
    accounts => accounts.map(account => getUserRoles(account))
        .flat()
        .filter((x, idx, array) => array.indexOf(x) === idx)
);

export const permissions = createSelector(
    [getUser],
    user => user?.permissions
        ? user.permissions
        : {}
)

export const selectedProfile = createSelector(
    [getUser, selectedAgency, selectedAccount],
    (user, selectedAgency, selectedAccount) => {
        switch (user.profileType.id) {
            case ProfileType.agency.id:
                return { ...selectedAgency, type: ProfileType.agency };
            case ProfileType.account.id:
                return { ...selectedAccount, type: ProfileType.account };
            default:
                return { ...user, type: ProfileType.user };
        }
    }
);

export const currentUserHasPermission = createSelector(
    [getUser, selectedProfile],
    (user, selectedProfile) => (policy) => {
        return user.permissions[selectedProfile.lcuid]?.some(p => p.fully_qualified_policy === policy.fully_qualified_policy)
    }
);

export const selectedProfileIsAgency = createSelector(
    [selectedProfile],
    (profile) => profile.type === ProfileType.agency
);

export const selectedProfileIsOperator = createSelector(
    [selectedProfile],
    (profile) => profile.type === ProfileType.agency && isOperator(profile)
);

export const selectedProfileIsAdAgency = createSelector(
    [selectedProfile],
    (profile) => profile.type === ProfileType.agency && !isOperator(profile)
);

export const selectedProfileIsAccount = createSelector(
    [selectedProfile],
    (profile) => profile.type === ProfileType.account
);

export const selectedProfileIsUser = createSelector(
    [selectedProfile],
    (profile) => profile.type === ProfileType.user
);
