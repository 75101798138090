
/**
 * Interceptor is responsible to identify if request was failed due to network error
 * This interceptor should always be first in chain, so other interceptors will have `isOffline` prop
 * @export
 * @param {*} instance
 */
export function useOfflineErrorInterceptor(instance) {
    instance.interceptors.response.use(_ => _, onError);
}

export function onError(error) {
    error.isOffline = !error.response;

    // eslint-disable-next-line no-undef
    return Promise.reject(error);
}