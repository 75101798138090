import { REGISTER_CHANGE_TYPE, REGISTER_PHONECODE, REGISTER_EDIT_PHONE, REGISTER_RESET, REGISTER_SET_REGISTRATION } from "../helpers/actionTypes";
import { lucitApi } from "../services/lucitApi";

export function sendPhoneCode(phoneNumber) {
    return dispatch => {
        return lucitApi.registrations.getSmsCode(phoneNumber)
            .then(data => {
                dispatch(registerPhoneCode(data.user, data.codeSentTime));

                return data;
            })
    }
}

export function createRegistration(phone, join_lcuid, join_code) {
    return (dispatch) => {
        return lucitApi.registrations.create(phone, join_lcuid, join_code)
            .then(data => {
                dispatch(setRegistration(data.id, phone));
                dispatch(registerPhoneCode(null, data.codeSentTime))
            })
    }
}

export function createUser(registrationId, name, email, password, timezone) {
    return () => {
        return lucitApi.registrations.createUser({
            registrationId,
            name,
            email,
            password,
            timezone
        });
    }
}

export function setRegistration(id, phone) {
    return {
        type: REGISTER_SET_REGISTRATION,
        id,
        phone
    }
}

export function registerTypeChange(registerType) {
    return {
        type: REGISTER_CHANGE_TYPE,
        registerType
    }
}

export function registerPhoneCode(user, codeSentTime) {
    return {
        type: REGISTER_PHONECODE,
        // get-sms-code endpoint doesn't return user for now
        // userName: user.name,
        // email: user.email,
        codeSentTime
    }
}

export function registerEditPhone() {
    return {
        type: REGISTER_EDIT_PHONE
    }
}

export function registerReset() {
    return {
        type: REGISTER_RESET
    }
}
