import { makeStyles, Typography, Box, Tabs, Tab, AppBar, Link, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { getScreen } from '../../actions/billboards';
import { getPhoto } from '../../actions/inventory';
import { getLocationById } from '../../actions/locations';
import DateTimeRelative from '../../components/DateTimeRelative';
import BillboardThumbnail from '../billboards/BillboardThumbnail';
import { RoutePaths } from '../../helpers/constants';
import { toLocalDateTime } from '../../helpers/date';
import { sortNullLast } from '../../helpers/sorters';
import { billboardSelector, getFacingCardinal } from '../../selectors/billboard';
import { lastPingSelector } from '../../selectors/dashboard';
import { Avatar } from '../../components/material';
import { hasIcon } from '../../selectors/account';
import { locationByIdSelector } from '../../selectors/locations';
import { lucitApi } from '../../services/lucitApi';
import { getUser, hasAgency } from '../../selectors/user';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { locationOutline, timerOutline } from 'ionicons/icons';
import { IonCardContent, IonList, IonLabel, IonItem, IonIcon } from '@ionic/react';
import BestBillboardMainImage from '../../containers/location/BestBillboardMainImage';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        infoWindowImg: {
            width: '100%',
            height: '100%',
            objectFit: "contain",
            objectPosition: 'center',
            maxHeight: 300,
        },
        infoWindowGridLeft: {

        },
        billboardContainer: {
            minWidth: '100%'
        },
        infoWindowGridRight: {
            marginBottom: 'auto',
        },
        mapModalImageContainer: {
            padding: 0,
            height: 'auto',
            [theme.breakpoints.down('xs')]: {
                maxHeight: 200,
            },
        },
        billboardMap_infoModal: {
            width: '100%',
            padding: 0,
            paddingTop: 0,
            [theme.breakpoints.up('md')]: {
                width: '600px',
            },
        },
        sizeBoard: {
            margin: 0,
            marginLeft: "10px"
        },
        infoModalText: {
            display: 'flex !important',
            alignItems: 'center',
        },
        infoIconStyle: {
            marginRight: 8,
        },
        boardTabsBar: {
            marginBottom: 10,
        },
        userMapTitleAvatar: {
            marginTop: 12,
            marginBottom: 12,
            marginRight: 15,
            width: 25,
            height: 25,
            marginInlineEnd: 32,
        },
        bilboardMap_cardHeader: {
            paddingBottom: 0,
        }
    }
})

const LocationDetails = (props) => {
    const {
        locationId,
        showLastPlay = true,
        lastPingSelector,
        getLocation,
        getBillboards,
        getPhoto } = props;

    const classes = useStyles();

    const [location, setLocation] = useState(null);
    const [billboards, setBillboards] = useState([]);
    const [agency, setAgency] = useState(null);

    const [activeBillboard, setActiveBillboard] = useState(null);

    useEffect(() => {
        getLocation(locationId)
            .then(location => {
                setLocation(location);
                return location && location.digital_boards ? getBillboards(location.digital_boards.map(x => x.id)) : []
            })
            .then(boards => {
                const billboards = boards.sort((a, b) => sortNullLast(lastPingSelector(a.id), lastPingSelector(b.id)));

                setActiveBillboard(billboards[0]);
                setBillboards(billboards);
            })
    }, [locationId, getLocation]);

    useEffect(() => {
        // Load agency if board selected, agency not loaded yet or board has another agency as previous
        if (activeBillboard && (!agency || agency.id !== activeBillboard.agency_id)) {
            setAgency(null)
            lucitApi.objects.get(`LY${activeBillboard.agency_id}`)
                .then(data => setAgency(data.object))
        }

        if (props.billboardChange) {
            props.billboardChange(activeBillboard)
        }
    }, [activeBillboard, agency])

    if (!location) {
        return <CircularProgressCentered size={40} />
    }

    return <div>
        {billboards.map(billboard =>
            <BoardTab
                key={billboard.id}
                {...{
                    isActive: billboard === activeBillboard,
                    showLastPlay,
                    location,
                    billboard,
                    lastPing: lastPingSelector(billboard.id),
                    getPhoto,
                }}
            />
        )}
        <Box className={classes.billboardContainer}>
            {billboards.length > 1
                && <AppBar position="static" color="transparent" className={classes.boardTabsBar}>
                    <Tabs
                        value={activeBillboard.id}
                        onChange={(_, value) => {
                            setActiveBillboard(billboards.find(b => b.id === value));
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {billboards.map((billboard, idx) => {
                            const faceName = getFacingCardinal(billboard)
                                ? getFacingCardinal(billboard) + ' Face'
                                : 'Face ' + (idx + 1)

                            return <Tab key={billboard.id} label={faceName} value={billboard.id} />
                        })}
                    </Tabs>
                </AppBar>}
        </Box>

        <IonCardContent>
            <IonList>
                {agency ?
                    <IonItem>
                        <Tooltip title="Operator">
                            <Avatar
                                src={hasIcon(agency) && agency.options.primary_image_public_url}
                                name={agency.name}
                                className={clx(classes.userMapTitleAvatar, { [classes.avatarNoIcon]: !hasIcon(agency) })}
                                slot="start" />
                        </Tooltip>
                        <IonLabel>
                            <Link component={RouterLink}
                                to={RoutePaths.public.slug(agency.slug || agency.lcuid)}
                                color="secondary">
                                {agency.name}
                            </Link>
                            <span> : </span>
                            {Boolean(activeBillboard)
                                && <Link component={RouterLink}
                                    to={RoutePaths.public.slug(activeBillboard.slug || activeBillboard.lcuid)}
                                    color="primary">
                                    <LCTypography variant="h7" color="secondary">
                                        {activeBillboard.name}
                                    </LCTypography>
                                </Link>}
                        </IonLabel>
                    </IonItem>
                    :
                    <IonItem>
                        <Typography>
                            <Skeleton variant="text" width="50%" />
                        </Typography>
                    </IonItem>
                }

                {location ?
                    <IonItem>
                        <Tooltip title="Location">
                            <IonIcon icon={locationOutline} slot="start" />
                        </Tooltip>
                        <IonLabel className={classes.infoModalText}>
                            {location.address} {location.city} {location.region} {location.postal_code}
                            <span> : </span>{Boolean(activeBillboard) && <BillboardThumbnail
                                board={activeBillboard}
                                boardScale={20}
                                fontSize={16}
                                classes={{ board: classes.sizeBoard }}
                                showAsOneLine={true} />}
                        </IonLabel>
                    </IonItem>
                    :
                    <IonItem>
                        <Typography>
                            <Skeleton variant="text" width="50%" />
                        </Typography>
                    </IonItem>
                }

                {activeBillboard
                    && lastPingSelector(activeBillboard.id)
                    && showLastPlay
                    && <IonItem>
                        {activeBillboard ? <>
                            <Tooltip title="Last Play">
                                <IonIcon icon={timerOutline} slot="start" />
                            </Tooltip>
                            <IonLabel className={classes.infoModalText}>
                                <DateTimeRelative date={toLocalDateTime(lastPingSelector(activeBillboard.id).play_datetime)} />
                            </IonLabel>
                        </>
                            :

                            <Typography>
                                <Skeleton variant="text" width="50%" />
                            </Typography>

                        }
                    </IonItem>}
            </IonList>
        </IonCardContent>
    </div>
}

const BoardTab = props => {
    const { location, showLastPlay, lastPing, billboard, isActive, getPhoto } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        if (lastPing && showLastPlay) {
            setLoading(true);
            getPhoto(lastPing.photo_id)
                .then(photo => setPhoto(photo))
                .finally(() => setLoading(false));
        }
    }, [getPhoto, lastPing, showLastPlay])

    if (!isActive) {
        return null;
    }

    return <Box p={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.mapModalImageContainer}>

        {loading
            && <Skeleton variant="rect" animation="wave" width={440} height={200} />}
        {!loading
            && <>
                {!photo
                    && <>
                        <BestBillboardMainImage billboard={billboard} />
                    </>}
                {!photo
                    && billboard
                    && billboard.options
                    && billboard.options.public_url
                    && <img src={billboard.options.public_url}
                        alt={location.name}
                        className={classes.infoWindowImg} />}
            </>}
        {photo
            && <Link component={RouterLink}
                to={RoutePaths.inventoryPhoto + `/${photo.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.infoWindowImg}>
                <img src={photo.public_url}
                    alt={location.name}
                    className={classes.infoWindowImg} />
            </Link>}
    </Box>;
}

LocationDetails.propTypes = {
    locationId: PropTypes.number.isRequired,

    showLastPlay: PropTypes.bool,

    billboardChange: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        locationByIdSelector: locationByIdSelector(state),
        lastPingSelector: lastPingSelector(state),

        billboardSelector: billboardSelector(state),

        hasAgency: hasAgency(getUser(state)),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLocation: id => dispatch(getLocationById(id)),
        getPhoto: id => dispatch(getPhoto(id)),
        getBillboards: ids => {
            // eslint-disable-next-line no-undef
            return Promise.all(ids.map(id => dispatch(getScreen(id))))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    LocationDetails
);
