import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Divider, AccordionSummary, AccordionDetails, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { getUser } from '../../selectors/user';
import { getApplications } from '../../actions/applications';
import { useTranslation } from 'react-i18next';
import { Apps } from '@material-ui/icons';
import { objectSelector } from '../../selectors/objects';
import { AssignApplicationButton, AssignedAppsList } from '../../containers/applications';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column'
        },
        headerIcon: {
            marginBottom: -3
        },
    }
});

function AppsSettings(props) {
    const { lcuid, getApplications, selectAssignedApps, objectById } = props;
    const classes = useStyles();

    const [isOpened, setOpened] = useState(true);
    const [newApp, setNewApp] = useState(null);

    const object = objectById(lcuid);
    const { t } = useTranslation();
    const apps = selectAssignedApps(lcuid);

    useEffect(() => {
        getApplications(lcuid);
    }, [lcuid])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Apps-content"
                id="Apps-header"
            >
                <Typography variant="h5" > <Apps className={classes.headerIcon} />{t(' Apps & Data Sources')}</Typography>
                <Box ml={2} onClick={e => e.stopPropagation()}>
                    <AssignApplicationButton
                        lcuid={lcuid}
                        onNewAppAssigned={setNewApp}
                    />
                </Box>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Box style={{ maxWidth: 1200 }} ml={6}>
                    <Box>
                        {apps.length > 0
                            ? <AssignedAppsList lcuid={lcuid} applications={apps} newApp={newApp} />
                            : (object.loading
                                ? <CircularProgressCentered />
                                : <Box mt={8} mb={8}><Typography>
                                    {t(`Add applications to your Lucit account such as Data Sources & Data Feeds,
                                    Reporting Applications and Other 3rd Party Integrations`)}
                                </Typography></Box>
                            )}
                    </Box>
                </Box>
            </AccordionDetails>
        </AccordionScrollable>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        objectById: lcuid => objectSelector(state)(lcuid),
        selectAssignedApps: lcuid => objectSelector(state)(lcuid).apps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApplications: (lcuid) => dispatch(getApplications(lcuid))
    };
}

AppsSettings.propTypes = {
    lcuid: PropTypes.string.isRequired
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppsSettings);
