import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectedProfile } from '../../selectors/user'
import { hasApp } from '../../selectors/objects'

const ApplicationFeatureToggle = (props) => {

    const { appLcuid, reverse, children, hasApp } = props;

    const profileHasApp = hasApp(appLcuid)

    // Reverse actually means to hide content if feature is enabled
    if (reverse) {
        return profileHasApp
            ? null
            : children;
    }

    return !profileHasApp
        ? null
        : children;
}

ApplicationFeatureToggle.propTypes = {
    appLcuid: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        features: state.featureToggle.features,
        hasApp: (appLcuid) => hasApp(state, selectedProfile(state).lcuid, appLcuid),
    }
}

export default connect(
    mapStateToProps
)(
    ApplicationFeatureToggle
)
