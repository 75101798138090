import React, { useState, useEffect } from 'react';
import { LinearProgress, Box } from '@material-ui/core';

const TimedLinearProgress = ({ durationInSeconds, complete, linearProgressProps = {}, containerStyle }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer;
        const interval = 100; // Update progress every 100 milliseconds
        const steps = (durationInSeconds * 1000) / interval; // Calculate number of steps to complete

        const incrementProgress = () => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100 || complete) {
                    clearInterval(timer);
                    return 100;
                }
                // Use a logarithmic scale for progress
                const logProgress = Math.log(prevProgress + 1) / Math.log(100);
                const nextProgress = Math.min(prevProgress + (1 - logProgress) * (100 / steps), 100); // Progress should never exceed 100
                return nextProgress;
            });
        };

        timer = setInterval(incrementProgress, interval);

        return () => {
            clearInterval(timer);
        };
    }, [durationInSeconds, complete]);

    const style = containerStyle ?? { width: "600px", maxWidth: "90%" };

    return (
        <Box style={style}>
            <LinearProgress
                variant="determinate"
                value={progress}
                {...linearProgressProps}
            />
        </Box>
    );
};

export default TimedLinearProgress;
