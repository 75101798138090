import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { InventoryItemStatus } from '../../../helpers/constants';
import { useTranslation } from 'react-i18next';

function DraftChip(props) {
    const { item, ...rest } = props;
    const { t } = useTranslation();

    if (item.status !== InventoryItemStatus.draft) {
        return null;
    }

    return <Chip
        component="span"
        size="small"
        label={t('Draft')}
        {...rest}
    />;
}

DraftChip.propTypes = {
    item: PropTypes.object.isRequired
}

export default DraftChip;