import moment from 'moment';
import {
    PUSH_NOTIFICATIONS_RECEIVED, PUSH_NOTIFICATIONS_MARK_ALL_READ,
    PUSH_NOTIFICATIONS_UNREAD_COUNT, PUSH_NOTIFICATIONS_GET_RESULT, PUSH_NOTIFICATIONS_MARK_READ,
    PUSH_NOTIFICATIONS_SAVE_TYPE_PREFERENCE, PUSH_NOTIFICATIONS_SAVE_PREFERENCE
} from "../helpers/actionTypes"
import { lucitApi } from "../services/lucitApi"

export function getChannels() {
    return () => {
        return lucitApi.notifications.getChannels()
            .then(x => x.channels)
    }
}

export function getNotificationTypes() {
    return () => {
        return lucitApi.notifications.getNotificationTypes()
            .then(x => x.notification_classes)
    }
}

export function updateChannelForType(type, channel, enabled) {
    return (dispatch) => {
        return lucitApi.notifications.updateChannelForType(type, channel, enabled)
            .then(() => dispatch({
                type: PUSH_NOTIFICATIONS_SAVE_TYPE_PREFERENCE,
                class: type,
                channel,
                enabled
            }))
    }
}

export function updateChannel(channel, enabled) {
    return (dispatch) => {
        return lucitApi.notifications.updateChannel(channel, enabled)
            .then(() => dispatch({
                type: PUSH_NOTIFICATIONS_SAVE_PREFERENCE,
                channel,
                enabled
            }))
    }
}

export function saveMarkAsRead(notificationId) {
    return (dispatch) => {
        return lucitApi.notifications.markAsRead(notificationId)
            .then(() => dispatch(markAsRead(notificationId)));
    }
}

export function saveMarkAllAsRead() {
    return (dispatch) => {
        return lucitApi.notifications.markAllAsRead()
            .then(() => dispatch(markAllAsRead()));
    }
}

export function getUnreadCount() {
    return (dispatch) => {
        return lucitApi.notifications.getUnreadCount()
            .then(data => dispatch(unreadCount(data.unread_notification_count)));
    }
}

export function getNotifications() {
    return (dispatch) => {
        return lucitApi.notifications.getNotifications()
            .then(data => dispatch(results(data.notifications.data)));
    }
}

export function nativeNotificationReceived(id) {
    return dispatch => {
        return lucitApi.notifications.getNotification(id)
            .then(data => {
                dispatch(notificationReceived(data.notification[0]));

                return data.notification[0];
            });
    }
}

function markAsRead(notificationId) {
    return {
        type: PUSH_NOTIFICATIONS_MARK_READ,
        readAt: moment().utc(),
        notificationId
    }
}

function markAllAsRead() {
    return {
        type: PUSH_NOTIFICATIONS_MARK_ALL_READ,
        readAt: moment().utc()
    }
}

function unreadCount(count) {
    return {
        type: PUSH_NOTIFICATIONS_UNREAD_COUNT,
        count
    }
}

function results(data) {
    return {
        type: PUSH_NOTIFICATIONS_GET_RESULT,
        data
    }
}

function notificationReceived(notification) {
    return {
        type: PUSH_NOTIFICATIONS_RECEIVED,
        notification
    }
}
