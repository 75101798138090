import {
    APPLICATION_ADD_CAPABILITY,
    APPLICATION_BOTS,
    APPLICATION_BYID,
    APPLICATION_DELETE,
    APPLICATION_DELETE_BOT,
    APPLICATION_NEW, APPLICATION_NEW_BOT, APPLICATION_REMOVE_CAPABILITY, APPLICATION_REMOVE_IMAGE, APPLICATION_REVIEW,
    APPLICATION_SET_IMAGE, APPLICATION_UPDATE,
    OBJECT_APPLICATIONS_ASSIGN, OBJECT_APPLICATIONS_REQUEST, OBJECT_APPLICATIONS_RESULT, OBJECT_APPLICATIONS_UNASSIGN,
    OBJECT_APPLICATION_DATA_SOURCES_RESULT, OBJECT_APPLICATION_DATA_SOURCES_CLEAR,
    OBJECT_APPLICATION_DATA_SOURCES_ADD, OBJECT_APPLICATION_DATA_SOURCES_UPDATE, OBJECT_APPLICATION_DATA_SOURCES_REMOVE,
    OBJECT_APPLICATION_DATA_SOURCES_SET_IMAGE, OBJECT_APPLICATION_DATA_SOURCES_REMOVE_IMAGE
} from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import { parseClass } from "../helpers/lcuid";
import { lucitApi } from "../services/lucitApi"
import { uploadImage } from "./uploads";
import { isLoggedIn } from "../selectors/user";

export function getAllApplications(filters = {}) {

    return (_, getState) => {

        const isAuthenticated = isLoggedIn(getState());

        return lucitApi.applications.list(isAuthenticated, filters)
            .then(applications => {

                return applications.data;
            })
    }
}

export function getApplications(lcuid) {
    return dispatch => {
        const objectClass = parseClass(lcuid);

        dispatch({
            type: OBJECT_APPLICATIONS_REQUEST,
            objectClass,
            lcuid
        })

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.agencies.getApplications(lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.accounts.getApplications(lcuid)

        return promise.then(data => {
            dispatch({
                type: OBJECT_APPLICATIONS_RESULT,
                data,
                objectClass,
                lcuid
            })

            return data;
        })
    }
}

export function getApplication(lcuid) {
    return dispatch => {
        return lucitApi.applications.get(lcuid)
            .then(application => {
                dispatch({
                    type: APPLICATION_BYID,
                    application
                });
                return application;
            })
    }
}

export function getApplicationBots(application) {
    return dispatch => {
        return lucitApi.applications.getBots(application.lcuid)
            .then(data => {
                dispatch({
                    type: APPLICATION_BOTS,
                    application,
                    data
                });
                return application;
            })
    }
}

export function getApplicationDataSourceCapabiltySettings(lcuid) {
    return dispatch => {
        return lucitApi.applications.getFeedProviders(lcuid).then(inventory_feed_providers => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_RESULT,
                application_lcuid: lcuid,
                inventory_feed_providers: inventory_feed_providers.data
            });
            return inventory_feed_providers;
        })
    }
}

export function clearApplicationDataSourceCapabilitySettings() {
    return dispatch => {
        return dispatch({
            type: OBJECT_APPLICATION_DATA_SOURCES_CLEAR
        });
    }
}

export function addApplicationDataSourceCapabilityFeedProvider(application_lcuid, name, description, inventory_item_class) {
    return dispatch => {
        return lucitApi.feedProviders.createFeedProvider({
            application_id: application_lcuid,
            name,
            description,
            inventory_item_class: inventory_item_class
        }).then((inventory_feed_provider) => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_ADD,
                inventory_feed_provider: inventory_feed_provider
            });
            return inventory_feed_provider;
        })
    }
}

export function updateApplicationDataSourceCapabilityFeedProvider(feed_provider_id, name, description, inventory_item_class) {
    return dispatch => {
        return lucitApi.feedProviders.updateFeedProvider(feed_provider_id, {
            name,
            description,
            inventory_item_class
        }).then((inventory_feed_provider) => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_UPDATE,
                inventory_feed_provider: inventory_feed_provider
            });
            return inventory_feed_provider;
        })
    }
}

export function deleteApplicationDataSourceCapabilityFeedProvider(feed_provider_lcuid) {
    return (dispatch) => {
        return lucitApi.objects.delete(feed_provider_lcuid)
            .then(() => {
                dispatch({
                    type: OBJECT_APPLICATION_DATA_SOURCES_REMOVE,
                    feed_provider_lcuid
                });
                return feed_provider_lcuid;
            });
    }
}

export function updateFeedProviderSettings(feed_provider_id, feedSettings) {
    return dispatch => {
        return lucitApi.feedProviders.setFeedSettings(feed_provider_id, feedSettings).then((inventory_feed_provider) => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_UPDATE,
                inventory_feed_provider: inventory_feed_provider
            });
            return inventory_feed_provider;
        })
    }
}

export function updateFeedProviderParams(feed_provider_id, feedParams) {
    return dispatch => {
        return lucitApi.feedProviders.setFeedParams(feed_provider_id, feedParams).then((inventory_feed_provider) => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_UPDATE,
                inventory_feed_provider: inventory_feed_provider
            });
            return inventory_feed_provider;
        })
    }
}

export function setFeedProviderActive(feed_provider_id, active) {
    return dispatch => {
        return lucitApi.feedProviders.setActive(feed_provider_id, active).then((inventory_feed_provider) => {
            dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_UPDATE,
                inventory_feed_provider: inventory_feed_provider
            });
            return inventory_feed_provider;
        })
    }
}

export function setFeedProviderPrimaryImage(feedProvider, fileBase64) {
    return (dispatch) => {
        return dispatch(uploadImage(fileBase64))
            .then(image => {
                return lucitApi.feedProviders.setPrimaryImage(feedProvider.lcuid, image.id)
                    .then(feedProvider => dispatch({
                        type: OBJECT_APPLICATION_DATA_SOURCES_SET_IMAGE,
                        feedProvider,
                        options: feedProvider.options
                    }))
            })
    }
}

export function deleteFeedProviderPrimaryImage(feedProvider) {
    return (dispatch) => {
        return lucitApi.feedProviders.deletePrimaryImage(feedProvider.lcuid)
            .then(() => dispatch({
                type: OBJECT_APPLICATION_DATA_SOURCES_REMOVE_IMAGE,
                feedProvider
            }))
    }
}

export function createApplication(data) {
    return (dispatch) => {
        return lucitApi.applications.createApplication(data)
            .then(application => {
                dispatch({
                    type: APPLICATION_NEW,
                    application
                });
                return application;
            });
    }
}

export function updateApplication(application) {
    return (dispatch) => {
        return lucitApi.applications.updateApplication(application)
            .then(application => {
                dispatch({
                    type: APPLICATION_UPDATE,
                    application
                });
                return application;
            });
    }
}

export function deleteApplication(application) {
    return (dispatch) => {
        return lucitApi.objects.delete(application.lcuid)
            .then(() => {
                dispatch({
                    type: APPLICATION_DELETE,
                    application
                });
                return application;
            });
    }
}

export function setPrimaryImage(application, fileBase64) {
    return (dispatch) => {
        return dispatch(uploadImage(fileBase64))
            .then(image => {
                return lucitApi.applications.setPrimaryImage(application.lcuid, image.id)
                    .then(application => dispatch({
                        type: APPLICATION_SET_IMAGE,
                        application,
                        options: application.options
                    }))
            })
    }
}

export function deletePrimaryImage(application) {
    return (dispatch) => {
        return lucitApi.applications.deletePrimaryImage(application.lcuid)
            .then(() => dispatch({
                type: APPLICATION_REMOVE_IMAGE,
                application
            }))
    }
}

export function submitApplicationReview(application) {
    return (dispatch) => {
        return lucitApi.applications.submitReview(application.lcuid)
            .then(() => dispatch({
                type: APPLICATION_REVIEW,
                application
            }));
    }
}

export function setCapability(application, capability, enable) {
    return (dispatch) => {
        if (enable) {
            return lucitApi.applications.addCapability(application, capability)
                .then(capability => dispatch({
                    type: APPLICATION_ADD_CAPABILITY,
                    application,
                    capability
                }));
        }

        return lucitApi.applications.deleteCapability(application, capability)
            .then(() => dispatch({
                type: APPLICATION_REMOVE_CAPABILITY,
                application,
                capability
            }));
    }
}

export function createBotUser(application, name, userTokenType) {
    return (dispatch) => {
        return lucitApi.applications.createBotUser(application.lcuid, name, userTokenType)
            .then(user => {
                dispatch({
                    type: APPLICATION_NEW_BOT,
                    application,
                    user: { ...user, secret: null }
                });

                return user;
            });
    }
}

export function resetBotUserSecret(application, user) {
    return () => {
        return lucitApi.applications.resetBotUserSecret(application.lcuid, user.lcuid);
    }
}

export function deleteBotUser(application, user) {
    return (dispatch) => {
        return lucitApi.applications.deleteBotUser(application.lcuid, user.lcuid)
            .then(() => dispatch({
                type: APPLICATION_DELETE_BOT,
                application,
                user
            }));
    }
}

export function assignApplication(lcuid, app) {
    return dispatch => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.agencies.assignApplication(lcuid, app.lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.accounts.assignApplication(lcuid, app.lcuid)

        return promise.then(data => {
            dispatch({
                type: OBJECT_APPLICATIONS_ASSIGN,
                objectClass,
                lcuid,
                app
            })

            return data;
        })
    }
}

export function unassignApplication(lcuid, app) {
    return dispatch => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.agencies.unassignApplication(lcuid, app.lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.accounts.unassignApplication(lcuid, app.lcuid)

        return promise.then(data => {
            dispatch({
                type: OBJECT_APPLICATIONS_UNASSIGN,
                objectClass,
                lcuid,
                app
            })

            return data;
        })
    }
}
