import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { debounce } from '../../helpers/async';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getReactions, saveReaction, saveReactionRequest } from '../../actions/objects';
import { toggleSignInDialog } from '../../actions/layout';
import { isLiked } from '../../selectors/objects';

const LikeButton = (props) => {
    const { lcuid, isPublic, className } = props;
    const { isLoggedIn, openSignInDialog, getReactions, saveReaction, saveReactionRequest } = props;

    const [liked, setLiked] = useState(false);

    const submitReaction = useCallback(debounce(liked => {
        saveReaction(lcuid, liked);
    }, 1000), [lcuid]);

    const onClick = useCallback(() => {
        if (!isPublic && !isLoggedIn) {
            return openSignInDialog();
        }

        const likedNew = !liked;

        setLiked(likedNew);
        submitReaction(likedNew);
        saveReactionRequest(lcuid, likedNew);

        if (props.onClick)
            props.onClick(likedNew);
    }, [lcuid, liked, isPublic, isLoggedIn, props.onClick]);

    useEffect(() => {
        if (isLoggedIn && props.likeValue == null)
            getReactions(lcuid)
    }, [isLoggedIn, props.likeValue, lcuid]);

    const likeValue = props.likeValue || props._isLiked(lcuid);

    useEffect(() => setLiked(likeValue), [likeValue])

    return <Button
        className={className}
        onClick={onClick}
        startIcon={<ThumbUp />}
        color={liked ? "secondary" : "primary"}>
        {liked
            ? `Liked`
            : `Like`}
    </Button>;
};

LikeButton.propTypes = {
    isPublic: PropTypes.bool,
    lcuid: PropTypes.string.isRequired,

    likeValue: PropTypes.bool,

    onSubmit: PropTypes.func
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,

        _isLiked: lcuid => isLiked(state)(lcuid)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReactions: (lcuid) => dispatch(getReactions(lcuid)),
        saveReactionRequest: (lcuid, liked) => dispatch(saveReactionRequest(lcuid, liked)),
        saveReaction: (lcuid, liked) => {
            return dispatch(saveReaction(lcuid, liked))
        },

        openSignInDialog: () => dispatch(toggleSignInDialog(true))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikeButton);
