import { orientationFixer } from "../helpers/image";

export const environment = state => state.environment;

export const versionFieldId = 'version';
export const systemInfo = state => {
    const env = environment(state);

    return [
        {
            label: 'Application Source',
            value: env.platform
        },
        {
            label: 'Browser',
            value: env.browserName
        },
        {
            label: 'User Agent',
            value: env.userAgent
        },
        {
            label: 'Canvas Limits',
            value: `${JSON.stringify(env.canvasSizeLimits, null, 2)} (this is constant)`
        },
        {
            label: 'Image Orientation default',
            value: orientationFixer.browserDefaultImageOrientation()
        },
        {
            label: 'Environment:',
            value: env.nodeEnv
        },
        {
            id: versionFieldId,
            label: 'Version:',
            value: env.version
        },
        {
            label: 'API Url:',
            value: env.apiUrl
        },
        {
            label: 'LuCore Version:',
            value: env.luCoreVersion
        },
        {
            label: 'LuCore Host:',
            value: env.luCoreHost
        },
        {
            label: 'LuCore Server Ip:',
            value: env.luCoreServerIp
        },
        {
            label: 'PHP Version:',
            value: env.phpVersion
        },
        {
            label: 'LCUtils Version:',
            value: env.lcUtilsVersion
        }
    ]
}