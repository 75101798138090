import React from 'react';
import { SubmissionError } from "redux-form";
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

import { apiHttp } from "../helpers/api";
import { ErrorCodes, RoutePaths } from "../helpers/constants";

export default class LucitApiInvitations {

    getAccountInvites(accountId) {
        return apiHttp.get(`/accounts/${accountId}/users/invited`)
            .then(response => response.data.user_invitations);
    }

    getAgencyInvites(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/users/invited`)
            .then(response => response.data.user_invitations);
    }

    getApplicationInvites(appId) {
        return apiHttp.get(`/applications/${appId}/users/invited`)
            .then(response => response.data.user_invitations);
    }

    accountInvite(accountId, invites, roles) {
        return apiHttp.post(`/accounts/${accountId}/invite`, {
            roles: roles.join(':'),
            invites
        })
            .then(response => response.data.user_invitations)
            .catch(error => this.handleInviteError(error));
    }

    agencyInvite(agencyId, invites, roles) {
        return apiHttp.post(`/agencies/${agencyId}/invite`, {
            roles: roles.join(':'),
            invites
        })
            .then(response => response.data.user_invitations)
            .catch(error => this.handleInviteError(error));
    }

    applicationInvite(appId, invites, roles) {
        return apiHttp.post(`/applications/${appId}/invite`, {
            roles: roles.join(':'),
            invites
        })
            .then(response => response.data.user_invitations)
            .catch(error => this.handleInviteError(error));
    }

    delete(lcuid) {
        return apiHttp.delete(`/users/invitations/${lcuid}`)
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                if (error.response.status === 403) {
                    throw new SubmissionError(error.response.data.message)
                }
            });
    }

    handleInviteError(error) {
        if (error.isOffline) {
            throw error;
        }

        const { data } = error.response;

        if (data.code === ErrorCodes.invalidParameters
            && data.data.mobile_phone_number) {
            throw new SubmissionError({
                username: data.data.mobile_phone_number[0]
            })
        } else if (data.code === ErrorCodes.phoneInvalidFormat) {
            throw new SubmissionError({
                username: data.message
            })
        }

        throw new SubmissionError({
            _error: <span>Something went wrong. <Link
                color="secondary"
                component={RouterLink}
                to={RoutePaths.help}>Contact us</Link></span>
        })
    }
}
