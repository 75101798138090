import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { IonButton } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import { Dialog, ButtonLoader } from '../../components/material';
import { trafficComplete } from '../../actions/exports';

const MarkAsTrafficCompleteButton = ({ exportItem, trafficComplete, ...rest }) => {
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    return <>
        <IonButton
            variant="contained"
            color="primary"
            size="small"
            {...rest}
            onClick={() => setOpen(true)}
        >{t('Traffic Complete')}</IonButton>
        <Dialog
            open={open}
            title={t('Traffic')}
        >
            <DialogContent>
                <Box>
                    <LCTypography variant="body1">
                        This campaign has been scheduled in our player system.
                    </LCTypography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                >
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitting(true);
                        trafficComplete(exportItem.id).then(() => {
                            setSubmitting(false);
                            setOpen(false);
                        })
                    }}
                    submitting={submitting}
                >Traffic Complete</ButtonLoader>
            </DialogActions>
        </Dialog>

    </>

}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        trafficComplete: (exportId) => dispatch(trafficComplete(exportId))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(MarkAsTrafficCompleteButton)
    );
