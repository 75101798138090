import React from 'react';
import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './InventoryItem';
import { Skeleton } from '@material-ui/lab';

function InventoryItemSkeleton() {
    const classes = useStyles();

    return (
        <>
            <Box mb={3}>
                <Skeleton variant="rect" height={24} width={125} />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Skeleton variant="rect" height={300} />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Box mb={2}>
                        <Skeleton variant="rect" height={24} width={'50%'} />
                    </Box>
                    <Box className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" width={'80%'} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" width={150} />
                        </Box>
                    </Box>
                    <Box className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" width={'80%'} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" width={120} />
                        </Box>
                    </Box>
                    <Box className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" width={'80%'} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" width={140} />
                        </Box>
                    </Box>
                    <Box className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" width={'80%'} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" width={90} />
                        </Box>
                    </Box>

                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box >
                        <Skeleton variant="rect" height={300} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps
)(
    InventoryItemSkeleton
);