import React, { useMemo, useRef, useState } from 'react';
import clx from 'classnames';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, } from '@material-ui/core/styles';
import {
    Box, Button, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme, Link as MaterialLink
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ErrorBoundary } from '../components/errors/ErrorBoundary';
import { Close, Home, Menu, DirectionsCar, HomeWork, StoreMallDirectory, Tv, Apps } from '@material-ui/icons';
import { Global, RoutePaths } from '../helpers/constants';
import { useSwipeable } from 'react-swipeable';
import { SignInDialog } from '../components';
import { ScrollContext } from '../contexts';
import { Swipeable } from '../components/swipeable/Swipeable';
import { useTranslation } from 'react-i18next';
import { LucitXrIcon } from '../../src/components';
import { getApplicationLogoUrl } from '../selectors/layout';
import CreativeIcon from '@material-ui/icons/Collections';
import CodeIcon from '@material-ui/icons/Code';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    appBar: {
        height: '100%',
        zIndex: theme.zIndex.drawer + 1,
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`
    },
    title: {
        display: 'flex',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        '& img': {
            height: 32,
        },
        [theme.breakpoints.up('md')]: {
            position: 'static',
            transform: 'none',

            '& img': {
                height: 48,
            },
        },
    },
    grow: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        overflow: 'auto',

        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        height: `calc(100vh - 56px - env(safe-area-inset-top))`,

        "@media (min-width: 0px) and (orientation: landscape)": {
            height: `calc(100vh - 48px - env(safe-area-inset-top))`,
        },

        [theme.breakpoints.down('md')]: {
            padding: 0
        },

        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - 64px - env(safe-area-inset-top))`,
            marginLeft: drawerWidth,
        }
    },
    contentWithSearchToolbar: {
        padding: 0,
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 112px - env(safe-area-inset-top))`,

            "@media (min-width: 0px) and (orientation: landscape)": {
                height: `calc(100vh - 96px - env(safe-area-inset-top))`,
            },
        }
    },
    contentNoPadding: {
        padding: 0
    },

    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`
    },
    paper: {
        padding: theme.spacing(3, 2, 3, 2),
        minHeight: '100%'
    },
    leftMenuDisabled: {
        marginLeft: 0,
    }
}));

function PublicLayout(props) {
    const {
        disablePaper, disablePermanentDrawer, disableContentPadding,
        showHomeButton,
        customClasses = {} } = props;
    const classes = useStyles();
    const theme = useTheme();

    const scrollingContext = useRef({ ref: null });
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => setDrawerOpen(!isDrawerOpen);
    const closeDrawer = () => setDrawerOpen(false);

    const onSwipedRight = e => {
        const [initialX] = e.initial;

        if (initialX < Global.leftSwipeOpenMenuBoundsWidth
            && !isDrawerOpen) {
            handleDrawerToggle();
        }
    };

    const closeMenuSwipeHandler = useSwipeable({
        onSwipedLeft: () => {
            if (isDrawerOpen) {
                handleDrawerToggle();
            }
        }
    })

    const ContentPaper = useMemo(() => disablePaper
        ? (props) => <>{props.children}</>
        : (props) => <Paper className={classes.paper}>{props.children}</Paper>,
        [disablePaper])

    const { t } = useTranslation();

    return (

        <ScrollContext.Provider value={scrollingContext}>
            <div className={classes.root}>
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar>
                        {showHomeButton
                            && <Hidden smDown>
                                <Button
                                    color="secondary"
                                    component={Link}
                                    startIcon={<Home />}
                                    to={RoutePaths.public.home}
                                >Home</Button>
                            </Hidden>}

                        <Hidden mdUp>
                            <IconButton
                                color="inherit"
                                aria-label={t('Open drawer')}
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <Menu />
                            </IconButton>
                        </Hidden>
                        <Box className={classes.title} >
                            <MaterialLink
                                component={Link}
                                to={RoutePaths.public.home}>
                                <img height={45} src={getApplicationLogoUrl()} alt="Main logo" style={{ cursor: "pointer" }} />
                            </MaterialLink>
                        </Box>

                        <Box className={classes.grow} />

                    </Toolbar>

                </AppBar>

                <Box component="nav" className={classes.drawer}>
                    <Hidden mdUp implementation="js" >
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={isDrawerOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            PaperProps={{
                                ...closeMenuSwipeHandler
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                <Close />
                            </IconButton>

                            <LeftSideMenu closeDrawer={closeDrawer}  {...props} />
                        </Drawer>
                    </Hidden>
                    {!disablePermanentDrawer
                        && <Hidden smDown implementation="js">
                            <Drawer
                                className={classes.drawer}
                                variant="permanent"
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <div className={classes.toolbar} />
                                <LeftSideMenu {...props} />
                            </Drawer>
                        </Hidden>}
                </Box>

                <Box component={Swipeable}
                    innerRef={e => scrollingContext.current.ref = e}
                    className={clx(classes.content,
                        customClasses.content,
                        {
                            [classes.contentNoPadding]: disableContentPadding,
                            [classes.leftMenuDisabled]: disablePermanentDrawer
                        })}
                    onSwipedRight={onSwipedRight}>
                    <ContentPaper>
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </ContentPaper>
                </Box>

                <SignInDialog />
            </div>
        </ScrollContext.Provider>
    );
}

const LeftSideMenu = ({ closeDrawer }) => {

    const { t } = useTranslation();

    return <List>

        <ListItem
            button
            onClick={closeDrawer}
            component={Link}
            to={RoutePaths.public.home}>
            <ListItemIcon>
                <Home />
            </ListItemIcon>
            <ListItemText>{t('Home')}</ListItemText>
        </ListItem>

        <ListItem
            button
            onClick={closeDrawer}
            component={Link}
            to={RoutePaths.public.apps}>
            <ListItemIcon>
                <Apps />
            </ListItemIcon>
            <ListItemText>{t('OOH Apps')}</ListItemText>
        </ListItem>

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucit.cc/dynamic-creative-overview">
            <ListItemIcon>
                <CreativeIcon />
            </ListItemIcon>
            <ListItemText>{t('Dynamic Creatives')}</ListItemText>
        </ListItem>

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucit.cc/developers">
            <ListItemIcon>
                <CodeIcon />
            </ListItemIcon>
            <ListItemText>{t('Developers')}</ListItemText>
        </ListItem>

        <Divider />

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucit.cc/automotive">
            <ListItemIcon>
                <DirectionsCar />
            </ListItemIcon>
            <ListItemText>{t('Automotive')}</ListItemText>
        </ListItem>

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.lucit.cc/real-estate">
            <ListItemIcon>
                <HomeWork />
            </ListItemIcon>
            <ListItemText>{t('Real Estate')}</ListItemText>
        </ListItem>

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucit.cc/retail">
            <ListItemIcon>
                <StoreMallDirectory />
            </ListItemIcon>
            <ListItemText>{t('Retail')}</ListItemText>
        </ListItem>

        <Divider />

        <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://lucit.cc/operators">
            <ListItemIcon>
                <Tv />
            </ListItemIcon>
            <ListItemText>{t('Screen Owners')}</ListItemText>
        </ListItem>

        <Divider />

        <ListItem
            button
            rel="noopener noreferrer"
            component={Link}
            to={RoutePaths.public.xr}>
            <ListItemIcon>
                <LucitXrIcon />
            </ListItemIcon>
            <ListItemText><strong>{t('Try Lucit XR')}</strong></ListItemText>
        </ListItem>

        <Hidden mdUp>
            <Box mt={2} pl={2} pr={2}>
                <Button
                    variant="outlined"
                    fullWidth
                    component={Link}
                    to={RoutePaths.login}>
                    {t('Sign in')}
                </Button>
            </Box>

            <Box mt={2} pl={2} pr={2}>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    component={Link}
                    to={RoutePaths.registrationNew}>
                    {t('Sign Up')}
                </Button>
            </Box>
        </Hidden>

        <Divider />

    </List>;
}

const mapStateToProps = () => {
    return {
    };
}

const PublicLayoutConnected = withRouter(
    connect(
        mapStateToProps
    )(PublicLayout)
);

export default PublicLayoutConnected;

export function withPublicLayout(WrappedComponent,
    options = {
        disablePaper: false,
        disablePermanentDrawer: false,
        disableContentPadding: false,
        showHomeButton: false,
    }) {
    class WithPublicLayout extends React.Component {
        render() {
            return <PublicLayoutConnected
                disablePaper={options.disablePaper}
                showHomeButton={options.showHomeButton}
                disablePermanentDrawer={options.disablePermanentDrawer}
                disableContentPadding={options.disableContentPadding}>
                <WrappedComponent {...this.props} />
            </PublicLayoutConnected>
        }
    }

    WithPublicLayout.displayName = `WithPublicLayout(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return WithPublicLayout;
}
