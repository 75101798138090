import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { lucitApi } from '../../services/lucitApi';
import InventoryPhoto from '../../containers/inventoryPhoto/InventoryPhoto';
import { OgMetatags, PageTitle } from '../../components';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';

function CreativeProfile(props) {
    const { object } = props;

    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [item, setItem] = useState(null);

    useEffect(() => {
        const promises = [];

        setLoaded(false)

        promises.push(
            lucitApi.objects.get(`LU${object.user_id}`)
                .then(data => setUser(data.object))
        );

        promises.push(
            lucitApi.objects.get(`LI${object.inventory_item_id}`)
                .then(data => {
                    setItem(data.object);
                    return lucitApi.objects.get(`LA${data.object.account_id}`);
                })
                .then(data => setAccount(data.object))
        );

        // eslint-disable-next-line no-undef
        Promise.all(promises)
            .finally(() => setLoaded(true))
    }, [object])

    if (!loaded) {
        return null;
    }

    return (<>
        <PageTitle title={item.title} />
        <OgMetatags
            title={`Creative: ${item.title}`}
            description={`${item.title} is managed by ${account.name}`}
            image={object.public_url}
        />

        <InventoryPhoto
            photo={object}
            item={item}
            exportItem={null}
            account={account}
            user={user}
        />

        <Box mt={5} />
        <Box>
            <LCTypography variant="body1">
                Story Feed is comming soon...
            </LCTypography>
        </Box>
    </>);
}

export default withConditionalLayout(
    CreativeProfile, { publicOptions: { disablePaper: true } }
)
