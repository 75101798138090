import { Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown'

const LucitFormFieldHeader = ({ field }) => {

    return <>
        <Typography variant="h6" gutterBottom>{field.name}</Typography>
        <ReactMarkdown>{field.description}</ReactMarkdown>
    </>
}

export default LucitFormFieldHeader;
