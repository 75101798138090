import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
const url = require('../../assets/images/LucitXR_Icon_teal.png').default;

export default function LucitXrIcon(props) {
    const { t } = useTranslation();

    return <Tooltip title={t("Lucit XR available")}>
        <img
            src={url}
            width={32}
            height={32}
            {...props} />
    </Tooltip>
}
