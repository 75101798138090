import React from 'react';
import { Chip } from '@material-ui/core';
import { isStatsUnavailable } from '../../selectors/exports';
import i18next from '../../i18n';

const StatsUnavailableChip = ({ exportObject, chipProps }) => {
    if (!isStatsUnavailable(exportObject))
        return null;

    return (
        <Chip component="span"
            size="small"
            label={i18next.t('Stats Unavailable')}
            {...chipProps} />
    )
}

export default StatsUnavailableChip
