import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, MenuItem } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { search } from '../../actions/approvals';
import { filters } from '../../selectors/approvals';
import { ApprovalStatus, Global } from '../../helpers/constants';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import TextField from '../../components/inputs/TextField';
import { store } from '../../reducers';
import { debounce } from '../../helpers/async';

function CreativeFilters(props) {
    const { values, handleSubmit, onSubmit, totalUniqueInventoryItems } = props;
    const { initializeFilters, valuesPersisted } = props;

    const submit = handleSubmit(onSubmit);
    const [submitDebounced, setSubmitDebounced] = useState()

    useEffect(() => {
        const filters = Object.assign({}, values, valuesPersisted);

        if (filters) {
            initializeFilters(filters);
        }

        setSubmitDebounced(() => debounce(submit, 200));
        // eslint-disable-next-line
    }, [])

    return (
        <Box component="form"
            display="flex"
            flexDirection="column">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} xl={4}>
                    <Field
                        name="status"
                        component={TextField}
                        onChange={submitDebounced}
                        props={{
                            label: "Status",
                            variant: "outlined",
                            fullWidth: true,
                            select: true,
                        }}
                    >
                        {[
                            ApprovalStatus.pending,
                            ApprovalStatus.ok,
                            ApprovalStatus.ai_approved,
                            ApprovalStatus.ai_rejected,
                            ApprovalStatus.rejected]
                            .map(x => (
                                <MenuItem key={x.id}
                                    value={x.id}>
                                    {x.title}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <Box ml="auto" mr={1.5} mb={1} display="flex" alignItems="center" flexDirection="row">
                        {totalUniqueInventoryItems
                            && <Box>
                                {/* <Typography variant="body2">{from || 0}-{to || 0} of {total}</Typography> */}
                                <LCTypography transProps={{ totalUniqueInventoryItems: totalUniqueInventoryItems }} variant="body2">
                                    {{ totalUniqueInventoryItems: totalUniqueInventoryItems }} items found
                                </LCTypography>
                            </Box>}

                        {/* <Box ml={2} display="flex" alignItems="center">
                            <Typography variant="body2">Creatives per page: </Typography>
                            <Box ml={1} />

                            <Field
                                name="perPage"
                                component={TextField}
                                onChange={submitDebounced}
                                props={{
                                    variant: "outlined",
                                    size: "small",
                                    select: true,
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Field>
                        </Box> */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

CreativeFilters.propTypes = {
}

export const APPROVALS_FILTER_FORM = "approvalsFilters";

const initialValues = {
    status: ApprovalStatus.pending.id,
    perPage: Global.approvalsPerPage,
};

const mapStateToProps = state => {
    return {
        valuesPersisted: filters(state),
        values: getFormValues(APPROVALS_FILTER_FORM)(state) || initialValues,

        from: state.approvals.from,
        to: state.approvals.to,
        total: state.approvals.total,
        totalUniqueInventoryItems: state.approvals.totalUniqueInventoryItems,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initializeFilters: values => dispatch(initialize(APPROVALS_FILTER_FORM, values)),

        onSubmit: () => {
            const data = getFormValues(APPROVALS_FILTER_FORM)(store.getState());

            return dispatch(search(data));
        },
    }
}

export default reduxForm({
    enableReinitialize: true,
    form: APPROVALS_FILTER_FORM,
    initialValues: initialValues
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        CreativeFilters
    )
);
