
export const storySelector = stories => id => {
    return stories.reduce((prev, curr) => {
        if (curr.grouped) {
            return [...prev, ...curr.stories.map(x => x.story)]
        }

        return [...prev, curr]
    }, [])
        .find(x => x.id === id);
};