import React from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, makeStyles, Paper } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
import Replay from '@material-ui/icons/Replay';
import HomePageIcon from '@material-ui/icons/Home';
import LCTypography from '../../components/material/LCTypography';
import { RoutePaths } from '../../helpers/constants';
import { history } from '../../helpers/history';
import { showError } from '../../actions/snackbar';
import { connect } from 'react-redux';
import { getUser, selectedProfile } from '../../selectors/user';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            padding: theme.spacing(3)
        },

        fullHeight: {
            height: `calc(100vh)`
        }
    }
})

const ErrorPage = (props) => {
    const { user, profile, handleReload, isFatal, fullSize } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return <Paper className={clx(classes.root, { [classes.fullHeight]: fullSize })}>
        <Box display="flex" justifyContent="center" alignItems="center">
            <ErrorIcon color="error" fontSize="large" />
            <Box mr={1} />
            <LCTypography variant="h5">Unexpected Error</LCTypography>
        </Box>

        <Box mt={2} />
        <LCTypography variant="body1">Oops, something has gone wrong.  Please contact us if you continue to see this message.</LCTypography>

        <Box mt={2} />
        {isFatal
            &&
            <>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleReload(user, profile)}
                    startIcon={<Replay />}>
                    {t('Reload')}
                </Button>
                <Box mt={2} />
                <Link href="https://lucit.cc/contact/" target="_blank" rel="noopener noreferrer">
                    <LCTypography variant="body1">Contact us</LCTypography>
                </Link>
            </>}
        {!isFatal
            && (<>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push({ pathname: profile.type.path })}
                    startIcon={<HomePageIcon />}>
                    {t('Home')}
                </Button>
                <Box mt={2} />

                <Link component={RouterLink}
                    to={RoutePaths.help}>
                    <LCTypography variant="body1">Contact us</LCTypography>
                </Link>
            </>)}
    </Paper>
}

ErrorPage.propTypes = {
    isFatal: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
        profile: selectedProfile(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleReload: (user, profile) => {
            if (user.handleCheckUpdate) {
                user.handleCheckUpdate()
                    .then(() => {
                        if (user.handleUpdate) {
                            return user.handleUpdate();
                        }
                    })
                    .then(() => {
                        history.push({ pathname: profile.type.path });
                        window.location.reload();
                    })
                    .catch(error => {
                        if (error.isOffline) {
                            throw error;
                        }

                        dispatch(showError(i18next.t('Something went wrong...'), { duration: 5 * 1000 }));
                    })
            } else {
                history.push({ pathname: profile.type.path });
                window.location.reload();
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ErrorPage
)
