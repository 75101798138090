import React, { useState } from 'react';
import clx from 'classnames';
import { Snackbar, Button, IconButton, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { removeSystemNotification } from '../actions/systemNotification';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: 'pointer'
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));

const SystemNotification = (props) => {
    const { notifications, removeNotification, ...other } = props;
    const classes = useStyles();

    const [activeNotification, setActiveNotification] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (notifications.length && !activeNotification) {
            // Set a new snack when we don't have an active one
            const nextNotification = notifications[0];

            setActiveNotification(nextNotification);
            removeNotification(nextNotification);
        } else if (notifications.length && activeNotification && activeNotification.isOpen) {
            // Close an active snack when a new one is added
            setActiveNotification({ ...activeNotification, isOpen: false });
        }
    }, [notifications, activeNotification, removeNotification]);

    if (!activeNotification) {
        return null;
    }

    const { key, isOpen, duration, message, action, onClick: onGlobalClick } = activeNotification;
    const { hideClose, title, onClick } = action;

    const handleClose = (reason) => {
        if (reason !== 'clickaway') {
            setActiveNotification({ ...activeNotification, isOpen: false });
        }
    };

    return <>
        <Snackbar
            key={key}
            className={clx({ [classes.clickable]: onGlobalClick })}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClick={onGlobalClick}
            action={
                <>
                    {title
                        && <Button color="secondary" size="small" onClick={onClick}>
                            {title}
                        </Button>}
                    {!hideClose
                        && <IconButton
                            aria-label={t('close')}
                            color="inherit"
                            className={classes.close}
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>}
                </>
            }
            open={isOpen}
            autoHideDuration={duration}
            onClose={(_, reason) => handleClose(reason)}
            onExited={() => setActiveNotification(null)}

            message={message}
            {...other}
        />
    </>
};

const mapStateToProps = state => {
    return {
        ...state.systemNotification,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeNotification: notification => dispatch(removeSystemNotification(notification.key))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemNotification);
