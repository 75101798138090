import { SYSTEM_NOTIFICATION_SHOW, SYSTEM_NOTIFICATION_HIDE, SYSTEM_NOTIFICATION_REMOVE } from "../helpers/actionTypes";

export function showSystemNotification(key, message, options) {
    return {
        type: SYSTEM_NOTIFICATION_SHOW,
        key,
        message,
        ...options
    }
}

export function hideSystemNotification(key) {
    return {
        type: SYSTEM_NOTIFICATION_HIDE,
        key
    }
}

export function removeSystemNotification(key) {
    return {
        type: SYSTEM_NOTIFICATION_REMOVE,
        key
    }
}
