import { makeStyles, Button, Box } from '@material-ui/core';
import clx from 'classnames';
import LCTypography from '../../components/material/LCTypography';
import { Geolocation } from '@capacitor/geolocation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from '../../components/google-maps';
import { isPlatform } from '@ionic/react';
import { LocationDetailsDialog } from '../location';
import { getLocationGps, guessLocation } from '../../actions/user';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { getMap } from '../../actions/billboards';
import { selectedProfile } from '../../selectors/user';
import { ProfileType } from '../../helpers/constants';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { isOperator } from '../../selectors/agencies';

const useStyles = makeStyles(theme => {
    return {
        root: {
            width: '100%',
            height: '100%',
            position: 'relative'
        },

        map: {
            width: '100%',
            height: '100%',

            [theme.breakpoints.up('sm')]: {
                height: '100%'
            },

            [theme.breakpoints.up('lg')]: {
                height: '100%'
            }
        },

        mapLoader: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            zIndex: 3,
        },
        mapLoaderBackdrop: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: theme.palette.background.default,
            opacity: 0.6
        }
    }
})

const BillboardsMap = (props) => {
    const { loading, guessLocation, showLastPlay, agencyId,
        getLocations, getLocationGps, promptGeoPermissions = true, showCenterButton = true,
        selectedProfile, isLoggedIn, placeId, streetViewOnly, dontAutoCenterToUser, centeringType, center, ...rest } = props;
    const classes = useStyles();
    const customClasses = rest.classes || {};

    const [openGeolocationInfo, setOpenGeolocationInfo] = useState(false);
    const [locationSelected, setLocationSelected] = useState(null);
    const [locations, setLocations] = useState(props.locations || []);

    const showTargetedOnly = props.showTargetedOnly === undefined
        ? selectedProfile.type === ProfileType.account
        : Boolean(props.showTargetedOnly);

    const highlightTargeted = props.highlightTargeted === undefined ? showTargetedOnly : props.highlightTargeted

    const shouldShowGeoLocationInfoPop = () => {
        if (isPlatform('capacitor')) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (promptGeoPermissions) {
            Geolocation.checkPermissions()
                .then(data => {
                    if (data.location === 'prompt') {

                        if (shouldShowGeoLocationInfoPop())
                            setOpenGeolocationInfo(true);
                        else
                            getLocationGps();
                    }
                    if (data.location === 'granted') {
                        getLocationGps()
                    }
                })
        }
    }, [promptGeoPermissions]);

    useEffect(() => {
        if (isLoggedIn)
            guessLocation();
    }, [isLoggedIn]);

    useEffect(() => {
        // Do not load locations if they are provided in props;
        if (props.locations) {
            return;
        }

        if (selectedProfile.type === ProfileType.account) {
            getLocations({ accountIds: [selectedProfile.id], streetViewOnly }, isLoggedIn)
                .then(setLocations);
        }
        else if (selectedProfile.type === ProfileType.agency && isOperator(selectedProfile)) {
            getLocations({ agencyIds: [selectedProfile.id], streetViewOnly }, isLoggedIn)
                .then(setLocations);
        } else {
            getLocations({ agencyIds: agencyId && [agencyId], streetViewOnly }, isLoggedIn)
                .then(setLocations);
        }
    }, [props.locations, agencyId]);

    const getBestCenteringType = () => {

        if (centeringType)
            return centeringType;

        if (isLoggedIn && !dontAutoCenterToUser && selectedProfile.type === ProfileType.user)
            return 'userGeoposition';

        if (isLoggedIn && !dontAutoCenterToUser && selectedProfile.type === ProfileType.agency && !isOperator(selectedProfile))
            return 'custom';

        if (isLoggedIn)
            return 'locations';

        if (center)
            return 'custom';

        return 'locations';

    }

    return <Box className={classes.root}>
        {loading
            && <>
                <Box className={classes.mapLoaderBackdrop} />
                <Box className={classes.mapLoader}>
                    <CircularProgressCentered size={40} />
                    <LCTypography>
                        Loading Billboards
                    </LCTypography>
                </Box>
            </>}

        <Map
            classes={{
                map: clx(classes.map, customClasses.map)
            }}
            locations={locations}

            centeringType={getBestCenteringType()}
            center={center}
            showTargetedButton={showTargetedOnly}
            showTargetedOnly={showTargetedOnly}
            highlightTargeted={highlightTargeted}
            showCenterButton={showCenterButton}

            {...rest}

            locationSelected={locationSelected}
            placeId={placeId}
            onLocationChange={value => {
                let proceed = true;

                if (props.onLocationSelected) {
                    proceed = props.onLocationSelected(value);
                }

                if (proceed) {
                    setLocationSelected(value);
                }
            }}
        />

        {Boolean(locationSelected) && (
            <LocationDetailsDialog {...{
                location: locationSelected,
                showLastPlay,

                onClose: () => setLocationSelected(null)
            }} />
        )}

        {openGeolocationInfo
            && <GenericDialog
                title="Geolocation Permissions"
                dialogProps={{
                    open: openGeolocationInfo,
                    onClose: () => {
                        setOpenGeolocationInfo(false);
                        Geolocation.requestPermissions()
                            .then(x => x.location == 'granted' && getLocationGps());
                    },
                    fullWidth: true,
                    maxWidth: 'xs'
                }}
                ContentComponent={<>
                    This app uses location data to show you nearby digital billboards.

                    You will be prompted to grant your location permissions after closing this message.
                </>}

                ActionsComponent={<>
                    <Button onClick={() => {
                        setOpenGeolocationInfo(false);
                        Geolocation.requestPermissions()
                            .then(x => x.location == 'granted' && getLocationGps());
                    }}
                        color="primary">
                        OK
                    </Button>
                </>}
            />}
    </Box>;
}

const mapStateToProps = (state) => {
    return {
        loading: state.dashboard.loadingLocations,
        isLoggedIn: state.user.isLoggedIn,

        selectedProfile: selectedProfile(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        guessLocation: () => dispatch(guessLocation()),
        getLocations: (data, isLoggedIn) => dispatch(getMap(data, isLoggedIn)),
        getLocationGps: () => dispatch(getLocationGps())
    }
}

BillboardsMap.propTypes = {
    agencyId: PropTypes.number,
    locations: PropTypes.array,

    promptGeoPermissions: PropTypes.bool,
    showTargetedOnly: PropTypes.bool,
    showCenterButton: PropTypes.bool,
    showLastPlay: PropTypes.bool,
    streetViewOnly: PropTypes.bool,
    onLocationSelected: PropTypes.func,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BillboardsMap
);
