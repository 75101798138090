import { apiHttp } from "../helpers/api";

export default class LucitApiObjects {
    get(idOrSlug, isAuthenticated) {
        return apiHttp.get(
            isAuthenticated
                ? `/objects/${idOrSlug}`
                : `/objects/${idOrSlug}/noauth`)
            .then(response => response.data)
    }

    canBeDeleted(lcuid) {
        return apiHttp.get(`/objects/${lcuid}/can-be-deleted`)
            .then(response => response.data)
    }

    delete(lcuid) {
        return apiHttp.delete(`/objects/${lcuid}`)
            .then(response => response.data)
    }

    getReactions(lcuid, isAuth) {
        return apiHttp.get(isAuth
            ? `/objects/${lcuid}/reactions`
            : `/public/objects/${lcuid}/reactions`)
            .then(response => response.data)
    }

    addReaction(lcuid, reaction_class) {
        return apiHttp.post(`/objects/${lcuid}/reactions`, { reaction_class })
            .then(response => response.data)
    }

    deleteReaction(lcuid) {
        return apiHttp.delete(`/objects/${lcuid}/reactions`)
            .then(response => response.data)
    }

    getComments(lcuid) {
        return apiHttp.get(`/public/objects/${lcuid}/comments`)
            .then(response => response.data)
    }

    addComment(lcuid, comment, data) {
        const { images } = data;

        return apiHttp.post(`/objects/${lcuid}/comments`, { comment, attachments: JSON.stringify(images.map(i => i.lcuid)) })
            .then(response => response.data)
    }

    updateComment(lcuid, comment) {
        return apiHttp.put(`/comments/${lcuid}`, { comment })
            .then(response => response.data)
    }

    addAttachment(lcuid, attachable_lcuid) {
        return apiHttp.post(`/objects/${lcuid}/attachments`, { attachable_lcuid })
            .then(response => response.data.attachment);
    }

    getAttachments(lcuid) {
        return apiHttp.get(`/public/objects/${lcuid}/attachments`)
            .then(response => response.data.attachments)
    }

    getPermissions(lcuid) {
        return apiHttp.get(`/objects/${lcuid}/permissions`)
            .then(response => response.data.permissions)
    }

}
