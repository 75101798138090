import { apiHttp } from "../helpers/api";

export default class LucitApiImages {

    getLuHiHtmlCreative(lcuid) {
        return apiHttp.get(`/images/lu-hi-html-creative/${lcuid}`)
            .then(response => response.data);
    }

    get(id) {
        return apiHttp.get(`/images/${id}`)
            .then(response => response.data);
    }
}