import { Periods, Timeframe } from "../helpers/constants";
import { isLessThan } from "../helpers/moment";

export const shouldConvertToLocalTime = period => {
    return period === Periods.perMinute || period === Periods.hourly
}

export const guessFilters = overview => {
    if (!overview || !overview.first_play_datetime) {
        return {
            timeframe: Timeframe.today,
            period: Periods.hourly
        }
    }

    if (isLessThan(overview.first_play_datetime, 1, 'day')) {
        return {
            timeframe: Timeframe.today,
            period: Periods.hourly
        }
    }

    if (isLessThan(overview.first_play_datetime, 7, 'days')) {
        return {
            timeframe: Timeframe.thisWeek,
            period: Periods.daily
        }
    }

    if (isLessThan(overview.first_play_datetime, 30, 'days')) {
        return {
            timeframe: Timeframe.last7Days,
            period: Periods.daily
        }
    }

    if (isLessThan(overview.last_play_datetime, 30, 'days')) {
        return {
            timeframe: Timeframe.last7Days,
            period: Periods.daily
        }
    }

    if (isLessThan(overview.last_play_datetime, 6, 'months')) {
        return {
            timeframe: Timeframe.all,
            period: Periods.weekly
        }
    }

    return {
        timeframe: Timeframe.all,
        period: Periods.monthly
    }
}