import React, { useState } from 'react';
import { makeStyles, DialogActions, Button, useMediaQuery, useTheme, DialogContent, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../components/material';
import { useSelector, useDispatch } from 'react-redux';
import { PROGRAMMATIC_BUDGET_ALLOCATION_CHANGE, PROGRAMMATIC_RESET } from '../../helpers/actionTypes';
import { lucitApi } from '../../services/lucitApi';
import { getDigitalBoardFilters, getBudgetAllocation } from '../../selectors/programmatic';
import { useHistory } from 'react-router';
import ButtonLoader from '../../components/material/ButtonLoader';
import TimedLinearProgress from '../../components/material/TimedLinearProgress';
import { AccountSelectOptions } from '../account/AccountSelectOptions';
import { selectAccount } from '../../actions/user';
import { createAccount } from '../../actions/account';
import { CampaignBudgetAllocationTable } from '../campaigns/CampaignBudgetAllocationTable';

const useStyles = makeStyles(() => {
    return {
        dialogPaper: {
            height: '100%'
        },

        dialogContentRoot: {
            padding: 0
        },

        listSubheader: {
            background: 'white'
        },
    }
})

const Steps = {
    BudgetAllocation: {
        title: "Budget Allocation"
    },
    ChooseAccount: {
        title: "Account"
    }
}

export const CreateCampaignDialog = ({ open, handleClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const history = useHistory();

    const digitalBoardFilters = useSelector(getDigitalBoardFilters);
    const creative_images = useSelector(state => state.programmatic.creative_images);
    const budget_allocation = useSelector(getBudgetAllocation);
    const budget_amount = useSelector(state => state.programmatic.budget.amount);
    const budget_type = useSelector(state => state.programmatic.budget.budget_type);
    const start_date = useSelector(state => state.programmatic.dates.startDate);
    const end_date = useSelector(state => state.programmatic.dates.endDate);
    const locationCount = useSelector(state => state.programmatic.locations.length)

    const [account, setAccount] = useState(null);
    const [creatingCampaign, setCreatingCampaign] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [step, setStep] = useState(Steps.BudgetAllocation);

    const FAKE_SECONDS_PER_LOCATION = 0.05;
    const durationInSeconds = locationCount * FAKE_SECONDS_PER_LOCATION;

    const handleGenerateCampaign = async () => {
        setCreatingCampaign(true)
        setShowProgressBar(true)

        let choosenAccount = account;
        if (choosenAccount.new) {
            choosenAccount = await dispatch(createAccount(choosenAccount));
        }

        await lucitApi.campaigns.generateProgrammaticCampaign({
            account_lcuid: choosenAccount.lcuid,
            digital_board_filters: digitalBoardFilters,
            budget_allocation: {
                open_ooh_venue_types: budget_allocation.open_ooh_venue_types.map(b => ({
                    venue_type_id: b.venue_type_id,
                    allocation_type: b.allocation_type,
                    allocation: b.allocation,
                }))
            },
            budget_amount: budget_amount,
            budget_type: budget_type,
            start_date: start_date,
            end_date: end_date,
            creative_image_ids: creative_images.map(i => i.id)
        }).then(async response => {
            const newExport = response.inventory_export;

            dispatch(selectAccount(choosenAccount, {
                animate: true,
                onSwitched: () => {
                    history.push(`/campaign/${newExport.id}`);
                    dispatch({ type: PROGRAMMATIC_RESET });
                }
            }));
            setCreatingCampaign(false);
        })
    }

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="md"
            scroll="paper"
            open={open}
            onClose={handleClose}
            title={t(step.title)}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <Box p={2}>
                    {step == Steps.BudgetAllocation
                        && <CampaignBudgetAllocationTable
                            budgetAllocation={budget_allocation}
                            budget={budget_amount}
                            budgetType={budget_type}
                            onChange={value => dispatch({
                                type: PROGRAMMATIC_BUDGET_ALLOCATION_CHANGE,
                                budget_allocation: value
                            })}
                        />}
                    {step == Steps.ChooseAccount
                        && <AccountSelectOptions onAccountSelected={setAccount} />}
                </Box>
            </DialogContent>
            <DialogActions>
                {showProgressBar ?
                    <TimedLinearProgress
                        durationInSeconds={durationInSeconds ?? 12}
                        complete={!creatingCampaign}
                        linearProgressProps={{ color: "secondary" }}
                    />
                    : null}
                <Button onClick={() => step == Steps.ChooseAccount
                    ? setStep(Steps.BudgetAllocation)
                    : handleClose()}>
                    {t('Back')}
                </Button>
                {step == Steps.BudgetAllocation
                    ? <Button onClick={() => setStep(Steps.ChooseAccount)}
                        color="secondary"
                        variant="contained">
                        {t('Continue')}
                    </Button>
                    : <ButtonLoader
                        onClick={handleGenerateCampaign}
                        color="secondary"
                        variant="contained"
                        submitting={creatingCampaign}
                    >
                        {t('Generate Campaign')}
                    </ButtonLoader>}
            </DialogActions>
        </Dialog>
    );
};
