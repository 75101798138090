import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid, Card, Typography, Box, Collapse, CardMedia, CardHeader,
    Link, CardContent, Checkbox, TextField, MenuItem,
    FormControlLabel, Avatar
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { ZoomIn } from '@material-ui/icons';
import { withRouter } from 'react-router';
import { ApprovalRejectCode, RoutePaths } from '../../helpers/constants';
import { ImagePreviewDialog, PhotosGallery } from '../../components';
import { userOptions } from '../../selectors/user';
import { ButtonLoader, Tooltip } from '../../components/material';
import { lastComment, rejectionCodes } from '../../selectors/approvals';
import { ApprovalStatus } from '../../helpers/constants';
import { approve, refresh, reject, saveApprovalsStatus } from '../../actions/approvals';
import { showError, showSuccess } from '../../actions/snackbar';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import ModerationScore from './ModerationScore'
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n'

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '325px',

            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
        },
        headerRoot: {
            // display: 'block'
        },
        headerContent: {
            width: 'calc(100% - 48px)'
        },
        subheader: {
            fontSize: '0.75rem',
            fontWeight: 400
        },

        avatar: {
            alignSelf: 'flex-start'
        },

        image: {
            width: '100%',
            objectFit: 'contain'
        },

        approvedText: {
            color: theme.palette.success.main
        },

        itemZoomIn: {
            cursor: 'pointer',

            '&:hover img': {
                filter: 'opacity(0.3)'
            },
            '&:hover svg': {
                display: 'block'
            }
        },
        imgPreviewZoom: {
            display: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },

        content: {
            paddingBottom: `${theme.spacing(2)}px !important`,
        },
        actions: {
            justifyContent: "flex-end"
        }
    }
})

function CreativeCard(props) {
    const {
        approve, reject, rejectionCodes,
        account, user, created_by_user, inventoryItem, approvalGroup } = props;
    const classes = useStyles();
    const approvals = approvalGroup.approvals;
    const approval = approvals[0];
    const approvalStatus = approval.approval_status;

    const [previewPhotoUrl, setPreviewPhotoUrl] = useState(null);
    const [approved, setApproved] = useState(true);
    const [rejectCode, setRejectCode] = useState(approval.rejection_code || ApprovalRejectCode.inappropriateContent);
    const [rejectNotes, setRejectNotes] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const { t } = useTranslation();

    const submit = useCallback(() => {
        setSubmitting(true);
        props.submit(approvalGroup, rejectCode, rejectNotes)
            .finally(() => setSubmitting(false));
    }, [approvals, rejectCode, rejectNotes]);

    const getBestAccountName = (account) => {

        if (!account)
            return t('Invalid Account')

        return account.parent_account ? account.parent_account.name : account.name
    }

    const getBestAccountNameTitle = (account) => {

        if (!account)
            return t('Invalid Account')

        return account.parent_account ? account.name + t(' for ') + account.parent_account.name : account.name
    }

    const getUserDisplayNameTitleText = (user, created_by_user) => {
        if (user.id === created_by_user.id)
            return user.name

        return created_by_user.name + t(' on behalf of ') + user.name

    }

    useEffect(() => {
        if (approved) {
            approve(inventoryItem);
            return;
        }

        reject(inventoryItem);
    }, [approved, JSON.stringify(approvals.map(a => a.action))])

    return (
        <Card classes={{ root: classes.root }} elevation={3}>
            <CardHeader
                classes={{
                    root: classes.headerRoot,
                    content: classes.headerContent,
                    avatar: classes.avatar
                }}
                avatar={
                    <Avatar
                        alt={created_by_user.name}
                        title={created_by_user.name}
                        src={userOptions(created_by_user).primary_image_public_url}
                    />}
                title={
                    <Grid
                        justify="space-between" // Add it here :)
                        container
                    >
                        <Grid item>
                            <Tooltip title={getUserDisplayNameTitleText(user, created_by_user)} placement="top-start">
                                <LCTypography transProps={{ createdUserName: created_by_user.name }} display="block" noWrap>
                                    {{ createdUserName: created_by_user.name }}
                                </LCTypography>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <ModerationScore approvals={approvals} />
                        </Grid>
                    </Grid>
                }
                subheader={<>
                    <Tooltip title={getBestAccountNameTitle(account)} placement="top-start">
                        <LCTypography
                            transProps={{ getBestAccount: getBestAccountName(account) }}
                            display="block"
                            variant="caption"
                            noWrap>
                            {{ getBestAccount: getBestAccountName(account) }}
                        </LCTypography>
                    </Tooltip>

                    <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                        noWrap>
                        <DateTimeRelative date={toLocalDateTime(approval.updated_at)} />
                    </Typography>
                </>}
            />

            <PhotosGallery
                photos={approvals.map(approval => ({
                    Component: <Box
                        className={classes.itemZoomIn}
                        onClick={() => setPreviewPhotoUrl(approval.inventory_photo.public_url)}>

                        <CardMedia
                            component="img"
                            height={160}
                            className={classes.image}
                            title={approval.inventory_item.title}
                            image={approval.inventory_photo.public_url}
                        />

                        <ZoomIn className={classes.imgPreviewZoom} />
                    </Box>
                }))}
            />

            {approvals.length === 1 &&
                <Box mt={6} />
            }

            <ImagePreviewDialog
                title={t('Creative preview')}
                open={Boolean(previewPhotoUrl)}
                handleClose={() => setPreviewPhotoUrl(null)}
            >
                {previewPhotoUrl && <img src={previewPhotoUrl} alt="Creative preview" />}
            </ImagePreviewDialog>

            <CardContent className={classes.content}>
                <Link
                    component={RouterLink}
                    to={RoutePaths.inventory + `/${inventoryItem.id}`}
                >
                    <LCTypography transProps={{ inventoryItemTitle: inventoryItem.title }} noWrap variant="body2">
                        {{ inventoryItemTitle: inventoryItem.title }}
                    </LCTypography>
                </Link>

                <Box mt={1} />

                {approvalStatus === ApprovalStatus.pending.id
                    && <FormControlLabel
                        disabled={submitting}
                        control={
                            <Checkbox
                                size="small"
                                checked={approved}
                                onChange={() => setApproved(!approved)}
                                color="secondary"
                            />
                        }
                        label={t('Approve')}
                    />}

                {approvalStatus === ApprovalStatus.rejected.id
                    && <Box mb={1}>
                        <Typography color="error" noWrap>
                            {t('Rejected By ')}
                            {approval.status_updated_by_user && approval.status_updated_by_user.name} :
                            {lastComment(approval) ? `(${lastComment(approval)})` : ``}
                        </Typography>
                    </Box>}

                {approvalStatus === ApprovalStatus.ok.id
                    && <Box mb={1}>
                        <LCTypography
                            transProps={{
                                approvalStatusUpdated: approval.status_updated_by_user,
                                approvalStatusUpdatedName: approval.status_updated_by_user.name
                            }}
                            className={classes.approvedText} noWrap>
                            Approved By&nbsp;
                            {{ approvalStatusUpdated: approval.status_updated_by_user } &&
                                { approvalStatusUpdatedName: approval.status_updated_by_user.name }}
                        </LCTypography>
                    </Box>}

                {approvalStatus === ApprovalStatus.ai_approved.id
                    && <Box mb={1}>
                        <LCTypography
                            transProps={{
                                approvalStatusUpdated:
                                    approval.status_updated_by_user, approvalStatusUpdatedName:
                                    approval.status_updated_by_user.name
                            }}
                            className={classes.approvedText} noWrap>
                            Approved by AI :&nbsp;
                            {{ approvalStatusUpdated: approval.status_updated_by_user } &&
                                { approvalStatusUpdatedName: approval.status_updated_by_user.name }}
                        </LCTypography>
                    </Box>}

                {approvalStatus === ApprovalStatus.ok.id || approvalStatus === ApprovalStatus.ai_approved.id
                    && <FormControlLabel
                        disabled={submitting}
                        control={
                            <Checkbox
                                size="small"
                                checked={!approved}
                                onChange={() => setApproved(!approved)}
                                color="secondary"
                            />
                        }
                        label={t('Reject')}
                    />}

                <Collapse in={!approved
                    || approvalStatus === ApprovalStatus.rejected.id}
                    timeout="auto"
                    unmountOnExit>
                    <Box mt={1} />
                    <TextField
                        label={t('Reason')}
                        size="small"
                        value={rejectCode}
                        disabled={submitting || approvalStatus === ApprovalStatus.rejected.id}
                        onChange={(event) => setRejectCode(event.target.value)}
                        select
                        fullWidth
                        variant="outlined"
                    >
                        {rejectionCodes.map(code => (
                            <MenuItem key={code.id} value={+code.id}>
                                {code.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box mt={2} />
                    <TextField
                        label={t('Comments')}
                        size="small"
                        value={rejectNotes}
                        disabled={submitting}
                        onChange={(event) => setRejectNotes(event.target.value)}
                        variant="outlined"
                        placeholder="Optional"
                        fullWidth
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                submit()
                            }

                            if (e.key === 'Escape') {
                                setApproved(true)
                            }
                        }}
                    />

                    <Box mt={2} display="flex" justifyContent="flex-end">

                        {(approvalStatus === ApprovalStatus.pending.id
                            || approvalStatus === ApprovalStatus.ok.id
                            || approvalStatus === ApprovalStatus.ai_approved.id)
                            && <ButtonLoader
                                submitting={submitting}
                                onClick={() => submit()}
                                variant="contained"
                                color="primary">
                                Reject
                            </ButtonLoader>}

                        {approvalStatus === ApprovalStatus.rejected.id
                            && <ButtonLoader
                                submitting={submitting}
                                onClick={() => submit()}
                                variant="contained"
                                color="primary">
                                Approve
                            </ButtonLoader>}

                    </Box>

                </Collapse>

            </CardContent>
        </Card>
    );
}

CreativeCard.propTypes = {
    agency: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    inventoryItem: PropTypes.object.isRequired,
    approvalGroup: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        rejectionCodes: rejectionCodes(state)
    }
}

const mapDispatchToProps = (dispatch, props) => {

    return {
        approve: (inventoryItem) => dispatch(approve(inventoryItem)),
        reject: (inventoryItem) => dispatch(reject(inventoryItem)),

        submit: (approvalGroup, rejectionCode, notes) =>
            dispatch(saveApprovalsStatus([approvalGroup], rejectionCode, notes))
                .then(() => dispatch(showSuccess(approvalGroup.approvals[0].action === ApprovalStatus.rejected.id
                    ? <>
                        <i>{props.inventoryItem.title}</i> {i18next.t('creatives rejected')}
                    </>
                    : <>
                        <i>{props.inventoryItem.title}</i> {i18next.t('creatives approved')}
                    </>)))
                .catch(error => {
                    dispatch(showError(i18next.t('Unexpected error. Creatives were not rejected')));
                    throw error;
                })
                .then(() => dispatch(refresh())),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        CreativeCard
    )
);
