import {
    ApprovalRunState, defaultApprovalMinModerationScore, AgencyClasses, ApprovalTypes, DefaultApprovalType
} from '../helpers/constants'

export const runState = agency => agency && agency.options && agency.options.approvals ? agency.options.approvals.run_state : ApprovalRunState.allButRejected.id;

export const allowAiAutoApprove = agency => agency && agency.options && agency.options.approvals ? agency.options.approvals.allow_ai_auto_approve : false;

export const autoRejectModerationScore = agency => agency
    && agency.options
    && agency.options.approvals
    ? (agency.options.approvals.ai_auto_reject_score || agency.options.approvals.auto_reject_moderation_score)
    : false;

//Note the above Legacy settings in autoRejectModerationScore, see T6428

export const autoRejectFromUntrustedUsers = agency => agency
    && agency.options
    && agency.options.approvals
    ? (agency.options.approvals.ai_auto_reject_untrusted || agency.options.approvals.auto_reject_ads_from_untrusted_users)
    : false;

//Note the above Legacy settings in autoRejectFromUntrustedUsers, see T6428

export const minModerationScore = agency => agency
    && agency.options
    && agency.options.approvals
    ? agency.options.approvals.default_ai_min_moderation_score
    : defaultApprovalMinModerationScore

export const hasPlayerSoftware = (agency, software) => agency.software_provider && agency.software_provider.split(",").includes(software)

export const isOperator = (agency) => agency && agency.agency_class === AgencyClasses.operator.class

export const approvalSettingIsTeamApproved = (agency) => {
    return runState(agency) === ApprovalRunState.approvedOnly.id && !allowAiAutoApprove(agency)
}

export const approvalSettingIsAllApproved = (agency) => {

    return runState(agency) === ApprovalRunState.approvedOnly.id
        && allowAiAutoApprove(agency)
        && !autoRejectFromUntrustedUsers(agency)
        && !autoRejectModerationScore(agency)
}

export const approvalSettingIsAutoApproverReject = (agency) => {
    return runState(agency) === ApprovalRunState.approvedOnly.id
        && allowAiAutoApprove(agency)
        && (autoRejectFromUntrustedUsers(agency) || autoRejectModerationScore(agency))
}

export const approvalSettingIsAllButRejected = (agency) => {
    return runState(agency) === ApprovalRunState.allButRejected.id
        && allowAiAutoApprove(agency)
        && !autoRejectFromUntrustedUsers(agency)
        && !autoRejectModerationScore(agency)
}

export const approvalSetting = (agency) => {
    if (!agency || !agency.options || !agency.options.approvals) {
        return DefaultApprovalType
    }

    if (approvalSettingIsTeamApproved(agency)) {
        return ApprovalTypes.TeamApproved
    }
    if (approvalSettingIsAllApproved(agency)) {
        return ApprovalTypes.AllApproved
    }

    if (approvalSettingIsAutoApproverReject(agency)) {
        return ApprovalTypes.AutoApproverReject
    }

    if (approvalSettingIsAllButRejected(agency)) {
        return ApprovalTypes.AllNotRejected
    }

    return DefaultApprovalType

}
