import { Box, makeStyles } from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import React from 'react';
import clx from 'classnames';

const useStyles = makeStyles((theme) => {
    const getSoldBarStyles = props => {
        const { barWidth = 30, barLength = 120 } = props || {};

        const translateX = barLength / (2 * Math.sqrt(2)) - barWidth / Math.sqrt(2) - 1;
        const translateY = barLength / 2 - barLength / 2 / Math.sqrt(2) + barWidth / Math.sqrt(2) + 1;

        return {
            width: barWidth,
            height: barLength,
            transform: `translateY(${-translateY}px) translateX(${translateX}px) rotate(45deg)`,
        };
    }

    return {
        root: {
            position: 'relative',
            overflow: 'hidden'
        },

        image: {
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'left'
        },

        soldBar: props => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            transformOrigin: `center left`,
            background: theme.palette.error.main,

            ...getSoldBarStyles(props)
        }),

        soldTextWrapper: {
            transform: 'rotate(-90deg)',
        },
        soldText: {
            textTransform: 'uppercase',
            color: 'white',
            fontSize: theme.typography.body1.fontSize,
        }
    };
}, { name: 'ImageSold' });

const ImageSold = props => {
    const { classes: customClasses = {}, imageProps = {}, Text, isSold, textProps } = props;
    const classes = useStyles(props);

    const TextComponent = Text || SoldText;

    return (
        <Box className={clx(classes.root, customClasses.root)}>
            <img
                className={clx(classes.image, customClasses.image, imageProps.className)}
                alt="Item Sold"
                {...imageProps}
            />
            {isSold
                && <Box className={classes.soldBar}>
                    <Box className={classes.soldTextWrapper}>
                        <TextComponent classes={classes} {...textProps} />
                    </Box>
                </Box>}
        </Box>
    )
};

const SoldText = props => {
    const { classes: customClasses, className, ...rest } = props;
    const classes = useStyles(props);

    return <LCTypography
        className={clx(classes.soldText, className, customClasses.soldText)}
        {...rest}>
        Sold</LCTypography>
}

export default ImageSold;