import {
    Box, Button, Chip, Divider, IconButton,
    Link, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography, useTheme, makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getBoardConnectedExportForBoard } from "../../selectors/lightning";
import BillboardThumbnailV2 from "../../containers/billboards/BillboardThumbnailV2";
import { Description, Dialpad, Edit, MoreVert, PlaylistAddCheck, Settings, Tv } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { BillboardIcon, LightningDeviceStatus, LightningDeviceStatusDictionary, RoutePaths } from "../../helpers/constants";
import { BillboardPlayIndicator, BillboardPullIndicator } from "../../containers/billboards";
import EditSceenGroupsDialog from "./EditSceenGroupsDialog";
import { sortNumber, sortString } from '../../helpers/sorters';
import { searchTermInText } from "../../helpers/string";
import { LogsIcon } from "../../components";
import { DeviceLogsDialog } from "./DeviceLogsDialog";
import { DeviceInstructionsDialog } from "./DeviceInstructionsDialog";
import { DeviceEditDialog } from "./DeviceEditDialog";
import DeviceDeleteButton from "./DeviceDeleteButton";
import { useDispatch } from "react-redux";
import {
    loadAgencyDigitalBoards, loadAgencyLightningDevices
} from '../../actions/lightning';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { ScreenPlaylistPreviewDialog } from "./ScreenPlaylistPreviewDialog";
import { DeviceSettingsDialog } from "./DeviceSettingsDialog";
import { IconFromSet } from "../../components/IconFromSet";
import { getStylesByName } from "../../components/StylePicker";

// Define styles
const useStyles = makeStyles(() => ({
    smallImage: {
        height: 20,
        borderRadius: 3,
        transition: 'transform 0.3s ease-in-out',
        cursor: 'pointer',
    },
    largeImage: {
        height: 250,
        width: 'auto',
        borderRadius: 1,
    },
    tooltip: {
        maxWidth: 'none', // Ensures Popper does not limit the width of the tooltip
    },
    tooltipContent: {
        display: 'inline-block', // Ensures the tooltip width adjusts based on the image
        alignContent: 'center',
        textAlignLast: 'center',
    }
}));

export const useColumns = () => {
    const { t } = useTranslation();
    const getExport = useSelector(state => screen => getBoardConnectedExportForBoard(state)(screen));
    const theme = useTheme();

    const customFilterAndSearch = (term, x) => searchTermInText(term, [
        x.lid,
        x.board_identifier,
        x.digital_board_format?.name,
        x.board_size_description,
        x.location?.city,
        x.location?.region,

        ...(getExport(x)?.child_exports?.map(e => e.name) ?? [])
    ]);

    const columnStyle = { padding: '4px 12px' }
    return [
        {
            title: t('ID'),
            filtering: true,
            sorting: true,
            cellStyle: columnStyle,
            customSort: (a, b) => sortNumber(a.id, b.id),
            customFilterAndSearch,
            render: x => {
                const device = x.isGroupingRow
                    ? x
                    : x.lightning_device;

                const Id = <Tooltip title={<>
                    Version: {device?.summary_lightning_last_heartbeat?.lightning_version} <br />
                    Name: {device?.summary_lightning_last_heartbeat?.device_info?.name} <br />
                    OS: {device?.summary_lightning_last_heartbeat?.device_info?.operatingSystem}&nbsp;
                    {device?.summary_lightning_last_heartbeat?.device_info?.osVersion}
                </>}>
                    <Typography variant='body2'>{device?.device_number}</Typography>
                </Tooltip>;

                if (x.isGroupingRow) {
                    return <>
                        {Id}
                        <Typography variant='body2' style={{ color: theme.palette.text.hint }}>{x.screens.length} screens</Typography>
                    </>
                }

                if (x.isChildRow) {
                    return <Box ml={2}>
                        <Typography variant='body2' style={{ color: theme.palette.text.hint }}>{x.board_identifier}</Typography>
                    </Box>
                }

                return <>
                    {Id}
                    <Typography variant='body2' style={{ color: theme.palette.text.hint }}>{x.board_identifier}</Typography>
                </>
            }
        },
        {
            title: t('Name'),
            filtering: true,
            sorting: true,
            field: 'name',
            cellStyle: columnStyle,
            render: x => {
                if (x.isGroupingRow) {
                    return <>
                        <Typography variant="body2" style={{ display: 'flex', marginLeft: 'auto' }}>
                            {x.name.length > 35 ? x.name.slice(0, 33) + '...' : x.name}
                            {x.description
                                && <Tooltip title={x.description}>
                                    <Description color="secondary" fontSize="small" />
                                </Tooltip>}
                        </Typography>

                    </>
                }

                return <>
                    <Link
                        color="secondary"
                        style={{ cursor: 'pointer', display: 'flex', marginLeft: 'auto' }}
                        component={RouterLink}
                        to={RoutePaths.screen(x.id)}
                    >
                        {x.name.length > 35 ? x.name.slice(0, 33) + '...' : x.name}
                        {!x.isChildRow && x.lightning_device?.description
                            && <Tooltip title={x.lightning_device?.description}>
                                <Description color="secondary" fontSize="small" />
                            </Tooltip>}
                    </Link>
                </>
            },
        },
        {
            title: t('Groups'),
            filtering: true,
            sorting: true,
            field: 'groups',
            customSort: (a, b) => sortString(
                getExport(a)?.child_exports?.map(e => e.name).find(() => true),
                getExport(b)?.child_exports?.map(e => e.name).find(() => true),
            ),
            customFilterAndSearch,
            cellStyle: columnStyle,
            render: x => {
                if (x.isGroupingRow) {
                    return null;
                }

                return <GroupsColumn value={x} />;
            }
        },
        {
            title: t('Format (size)'),
            filtering: true,
            sorting: true,
            cellStyle: columnStyle,
            customSort: (a, b) => sortNumber(a.width / a.height, b.width / b.height),
            customFilterAndSearch,
            render: x => {
                if (x.isGroupingRow) {
                    return null;
                }

                return <FormatColumn value={x} />;
            }
        },
        {
            title: t('Location'),
            filtering: true,
            sorting: true,
            field: 'location.city',
            cellStyle: columnStyle,
            customFilterAndSearch,
            render: x => {
                if (x.isChildRow) {
                    return null;
                }

                return x.location.city + ', ' + x.location.region
            }
        },
        {
            title: t('Status'),
            filtering: true,
            sorting: true,
            field: 'status',
            cellStyle: columnStyle,
            render: x => <CommunicationsColumn item={x} />
        },
        {
            title: t('Actions'),
            filtering: false,
            sorting: false,
            cellStyle: { ...columnStyle },
            render: x => <ActionsColumn value={x} />
        }
    ];
}

const GroupsColumn = ({ value }) => {
    const [showMore, setShowMore] = useState(false);
    const matchingExport = useSelector(state => getBoardConnectedExportForBoard(state)(value));
    const groups = matchingExport?.child_exports;

    if (groups == null || groups.length == 0) {
        return null;
    }


    return <Box display="flex">
        <Box display="flex" style={{ gap: 4, flexWrap: 'wrap' }}>
            {groups
                .slice(0, showMore ? groups.length : 1)
                .map(g => (<Chip
                    icon={<IconFromSet
                        iconKey={g.options?.ui_options?.layout?.icon}
                        style={{
                            color: getStylesByName(g.options?.ui_options?.layout?.styleName)?.color
                        }}
                        FallbackIcon={Tv} />}
                    key={g.lcuid}
                    label={g.name}
                    style={getStylesByName(g.options?.ui_options?.layout?.styleName)}
                />))}
        </Box>

        {groups.length > 1
            && !showMore
            && <Button
                onClick={() => setShowMore(true)}
                style={{ width: 70, marginLeft: 8, textTransform: 'none' }}
                color="secondary">
                <Typography variant="body2">+{groups.length - 1} more</Typography>
            </Button>}
    </Box>
}

const FormatColumn = ({ value }) => {
    return <BillboardThumbnailV2 board={value} />
}

const BillboardPlayLastImage = ({ item }) => {

    const classes = useStyles()

    if (!item.summary_digital_board_last_play?.inventory_photo)
        return null;

    const itemTitle = item?.summary_digital_board_last_play?.inventory_item?.title

    return <Box
        ml={2}
        style={{
            display: "inline-block"
        }}
    >
        <Tooltip
            title={
                <span className={classes.tooltipContent}>
                    <Typography variant="h5">{itemTitle}</Typography>
                    <div>
                        {item.summary_digital_board_last_play?.last_play_datetime
                            && <>Last played : <DateTimeRelative
                                date={toLocalDateTime(item.summary_digital_board_last_play?.last_play_datetime)} />
                            </>
                        }
                    </div>
                    <Link
                        component={RouterLink}
                        to={RoutePaths.inventory + '/' + item.summary_digital_board_last_play?.inventory_item_id}
                    >
                        <img
                            src={item.summary_digital_board_last_play?.inventory_photo.options.public_url}
                            className={classes.largeImage}
                            style={{ cursor: 'pointer' }}
                        />
                    </Link>
                </span>
            }
            classes={{ tooltip: classes.tooltip }}
            interactive
            PopperProps={{
                modifiers: {
                    offset: {
                        enabled: true,
                        offset: '-250, 0' // Adjust these values as needed
                    },
                },
            }}
        >
            <img
                src={item.summary_digital_board_last_play?.inventory_photo.options.public_url}
                className={classes.smallImage}
            />
        </Tooltip>
    </Box>
}

const CommunicationsColumn = ({ item }) => {
    const theme = useTheme();

    const colors = {
        [LightningDeviceStatus.active.id]: theme.palette.success.main,
        [LightningDeviceStatus.offline.id]: theme.palette.error.main,
    }

    const device = item.isGroupingRow
        ? item
        : item.lightning_device;


    if (item.isChildRow) {
        return <>
            {item.summary_digital_board_last_play?.last_play_datetime
                && <BillboardPlayIndicator play_datetime={item.summary_digital_board_last_play?.last_play_datetime} />}

            {item.summary_digital_board_last_play?.last_play_datetime
                && <BillboardPlayLastImage item={item} />}
        </>;
    }

    return <Box display="flex" style={{ gap: 8 }} alignItems="center">
        <Chip
            variant='outlined'
            size="small"
            label={LightningDeviceStatusDictionary[device?.status]?.title}
            style={{
                color: colors[device?.status],
                borderColor: colors[device?.status],
            }}
        />

        {device?.summary_lightning_last_heartbeat?.last_hearbeat_at
            && <BillboardPullIndicator
                pull_datetime={device?.summary_lightning_last_heartbeat?.last_hearbeat_at} />}

        {item.summary_digital_board_last_play?.last_play_datetime
            && !item.isGroupingRow
            && <BillboardPlayIndicator
                play_datetime={item.summary_digital_board_last_play?.last_play_datetime} />}

        {item.summary_digital_board_last_play?.last_play_datetime
            && !item.isGroupingRow
            && <BillboardPlayLastImage item={item} />}
    </Box>
}

const ActionsColumn = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isEditGroupsOpen, setIsEditGroupsOpen] = useState(false);
    const [isLogsOpen, setIsLogsOpen] = useState(false);
    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    const [isPlaylistPreviewOpen, setIsPlaylistPreviewOpen] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const device = value.isGroupingRow ? value : value.lightning_device;

    return <>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{ disablePadding: true }}
        >
            {!value.isChildRow
                && <MenuItem onClick={() => {
                    setAnchorEl(null);
                    setIsEditOpen(true);
                }}>
                    <ListItemIcon ><Edit /></ListItemIcon>
                    <ListItemText primary={t("Edit")} />
                </MenuItem>}
            {!value.isChildRow
                && <MenuItem onClick={() => {
                    setAnchorEl(null);
                    setIsSettingsOpen(true);
                }}>
                    <ListItemIcon ><Settings /></ListItemIcon>
                    <ListItemText primary={t("Settings")} />
                </MenuItem>}
            {!value.isGroupingRow
                && <MenuItem onClick={() => {
                    setAnchorEl(null)
                    setIsPlaylistPreviewOpen(true);
                }}>
                    <ListItemIcon ><PlaylistAddCheck /></ListItemIcon>
                    <ListItemText primary={t("Preview Playlist")} />
                </MenuItem>}
            {!value.isGroupingRow
                && <MenuItem onClick={() => {
                    setIsEditGroupsOpen(true)
                    setAnchorEl(null)
                }}>
                    <ListItemIcon ><BillboardIcon /></ListItemIcon>
                    <ListItemText primary={t("Groups")} />
                </MenuItem>}
            {/* <MenuItem onClick={() => { setAnchorEl(null) }}>
                <ListItemIcon ><RemoveRedEye /></ListItemIcon>
                <ListItemText primary="Preview Playlist" />
            </MenuItem> */}
            {!value.isChildRow
                && <MenuItem onClick={() => { setAnchorEl(null); setIsInstructionsOpen(true); }}>
                    <ListItemIcon ><Dialpad /></ListItemIcon>
                    <ListItemText primary="Instructions" />
                </MenuItem>}
            {!value.isChildRow
                && <MenuItem
                    onClick={() => { setAnchorEl(null); setIsLogsOpen(true); }}>
                    <ListItemIcon ><LogsIcon /></ListItemIcon>
                    <ListItemText primary="Logs" />
                </MenuItem>}

            {!value.isChildRow
                && <Divider />}
            {device && !value.isChildRow
                && <DeviceDeleteButton
                    device={device}
                    handleDeleted={() => {
                        //Reload data
                        dispatch(loadAgencyDigitalBoards(device.agency_id))
                        dispatch(loadAgencyLightningDevices(device.agency_id))
                    }}
                    onClick={() => setAnchorEl(null)} />}
        </Menu>

        {isEditOpen
            && <DeviceEditDialog
                device={device}
                open={isEditOpen}
                handleClose={() => setIsEditOpen(false)}
            />}
        {isEditGroupsOpen
            && <EditSceenGroupsDialog
                screen={value}
                open={isEditGroupsOpen}
                handleClose={() => setIsEditGroupsOpen(false)}
            />}
        {isLogsOpen
            && <DeviceLogsDialog
                device={device}
                open={isLogsOpen}
                handleClose={() => setIsLogsOpen(false)}
            />}
        {isInstructionsOpen
            && <DeviceInstructionsDialog
                device={device}
                open={isInstructionsOpen}
                handleClose={() => setIsInstructionsOpen(false)}
            />}
        {isPlaylistPreviewOpen
            && <ScreenPlaylistPreviewDialog
                screen={value}
                open={isPlaylistPreviewOpen}
                handleClose={() => setIsPlaylistPreviewOpen(false)}
            />}
        {isSettingsOpen
            && <DeviceSettingsDialog
                device={device}
                open={isSettingsOpen}
                handleClose={() => setIsSettingsOpen(false)}
            />}
    </>
}
