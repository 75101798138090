import React, { useState } from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ApprovalSettings from './ApprovalSettings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectedAgency } from '../../selectors/user';
import { setApprovalSettings } from '../../actions/agencies';
import { ButtonLoader } from '../../components';
import { Tabs, Tab } from '@material-ui/core';
import TrustedUsers from './TrustedUsers';

const ApprovalSettingsDialog = ({ open, handleClose }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState(null);

    const agency = useSelector(selectedAgency);
    const dispatch = useDispatch();

    const save = () => {
        if (settings == null) {
            return handleClose();
        }

        setLoading(true);
        dispatch(setApprovalSettings(settings))
            .then(() => {
                handleClose();
                setSettings(null);
            })
            .finally(() => setLoading(false));
    }

    return <>
        <GenericDialog
            title={t('Approval Settings for {{agency}}', { agency: agency.name })}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'lg'
            }}
            dialogContentProps={{
            }}

            ContentComponent={<>
                <ApprovalSettingsContainer
                    agency={agency}
                    handleApprovalSettingsChange={setSettings} />
            </>}
            ActionsComponent={<>
                <ButtonLoader
                    submitting={loading}
                    onClick={save}
                    variant='contained'
                    color="secondary">
                    {t('Save')}
                </ButtonLoader>
            </>}
        />
    </>

}

const ApprovalSettingsContainer = ({ agency, handleApprovalSettingsChange }) => {

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    return <div>
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
        >
            <Tab label="Approval Settings" />
            <Tab label="Trusted Users" />
        </Tabs>
        <div>
            {selectedTab === 0 && (
                <ApprovalSettings
                    agency={agency}
                    handleApprovalSettingsChange={handleApprovalSettingsChange}
                />
            )}
            {selectedTab === 1 && <TrustedUsers
                agency={agency}
            />}
        </div>
    </div>
}

export default ApprovalSettingsDialog
