import { Box, Button, FormHelperText, makeStyles } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { lucitApi } from "../../services/lucitApi";
import { getUserInfo } from '../../actions/user';
import { GenericDialog } from '../../components/modals/GenericDialog';
import {
    agencySelector, getUser, selectedAccount, selectedAgency,
    selectedProfileIsAccount, selectedProfileIsOperator, selectedProfileIsAgency
} from '../../selectors/user';
import ButtonLoader from '../../components/material/ButtonLoader';
import { selectAccount } from '../../actions/user';
import { useHistory, withRouter } from 'react-router';
import { userAccounts } from '../../selectors/user';
import AllOperatorsSelectField from '../agency/AllOperatorsSelectField';
import AgencySelectField from '../agency/AgencySelectField';
import NewCreativeDialog from '../inventory/NewCreativeDialog';
import { useTranslation } from 'react-i18next';
import { CampaignState, InventoryExportCampaignClasses, RoutePaths } from '../../helpers/constants';
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import { AccountSelectField } from '../account';
import { BillboardSelector } from '../billboards';
import { useCallback } from 'react';
import { Select, MenuItem } from "@material-ui/core";
import { history } from '../../helpers/history';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        },
        accountSelectRadio: {
            maxWidth: "400px"
        },
        campaignSelectRadio: {
            maxWidth: "400px"
        },
        map: {
            width: '100%',
            height: '400px',
            [theme.breakpoints.up('md')]: {
                height: '400px',
            }
        },
    }
});

const AccountOptions = {
    current: 'current',
    another: 'another',
    new: 'new'
};
const Steps = {
    ChooseAccount: 'chooseAccount',
    ChooseScreens: 'chooseScreens'
}

const QuickAddProof = (props) => {
    const {
        open, handleClose, billboard,
        agencySelector, selectedAgency, selectedAccount, handleAccountSelect, dialogTitle, skipAccountSelection,
        newCampaignOnly, newCampaignClass
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [step, setStep] = useState(skipAccountSelection ? Steps.ChooseScreens : Steps.ChooseAccount);
    const [option, setOption] = useState(AccountOptions.current);
    const [chosenAccount, chooseAccount] = useState(selectedAccount);
    const [chosenAgency, chooseAgency] = useState(selectedAgency
        || (billboard && { id: billboard.agency_id })
        || agencySelector(selectedAccount && selectedAccount.agency_id)
        || (selectedAccount ? { id: selectedAccount.agency_id } : null));
    const [exports, setExports] = useState([]);
    const [selectedExportId, setSelectedExportId] = useState(null);
    const [selectedBoardIds, setSelectedBoardIds] = useState(billboard ? [billboard.id] : [])
    const [name, setName] = useState(t('New Group Campaign'));

    const [error, setError] = useState('');
    const [accountError, setAccountError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingExports, setLoadingExports] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false)

    useEffect(() => {

        if (open && billboard && billboard.agency_id) {
            lucitApi.agencies.get(billboard.agency_id).then((agency) => {
                chooseAgency(agency)
            });
        }
    }, [billboard, open])

    useEffect(() => {
        if (open && chosenAccount && chosenAccount.id) {
            setLoadingExports(true)
            lucitApi.exports.getActiveExports({ accountId: chosenAccount.id })
                .then((exports) => {
                    setExports(exports.data)
                    setLoadingExports(false)
                })
        }
        else {
            setExports([])
        }
    }, [chosenAccount, open])

    const goBack = useCallback(() => setStep(Steps.ChooseAccount), [setStep])
    const goNext = useCallback(() => {
        if (chosenAccount.new_account && !chosenAccount.name) {
            return setAccountError(t('The Account name field is required.'));
        }

        // If chosenAccount doesn't have exports, go to next step
        if (step === Steps.ChooseAccount && chosenAccount) {
            return setStep(Steps.ChooseScreens)
        }

        const promises = [];

        if (newCampaignClass === InventoryExportCampaignClasses.group.class) {
            promises.push(
                lucitApi.campaigns.generateGroupCampaign({
                    name,
                    account_lcuid: chosenAccount.lcuid,
                    selected_board_ids: JSON.stringify(selectedBoardIds),
                }).then(r => ({ export: r.inventory_export }))
            );
        } else {
            promises.push(
                // submit proof
                lucitApi.submitQuickProofRequest({
                    account_id: chosenAccount.id,
                    account_name: chosenAccount.name,
                    ad_agency_id: selectedAgency ? selectedAgency.id : null,
                    agency_id: chosenAgency.id,
                    new_account: chosenAccount.new_account,
                    inventory_export_id: selectedExportId,
                    selectedBoardIds: selectedBoardIds
                })
            )
        }

        setLoading(true)
        return Promise.all(promises)
            .then(([data]) => {
                if (skipAccountSelection) {
                    history.push({ pathname: RoutePaths.campaign(data.export.id) })
                } else {
                    handleAccountSelect(data.account || chosenAccount)
                }
                handleClose()
                setSelectedExportId(data.export.id)
                setIsNewDialogOpen(true)
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
    }, [chosenAccount, chosenAgency, loadingExports, exports, selectedBoardIds, selectedExportId])

    const shouldAutoActivateCreative = (account, exports) =>
        account.new_account ||
        exports.length === 0 ||
        exports[0].campaign_state === CampaignState.draft.id ||
        exports[0].campaign_state === CampaignState.rejected.id

    return (
        <>
            <GenericDialog
                title={dialogTitle ? dialogTitle : t('Add Proof')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: step === Steps.ChooseScreens ? true : false,
                    maxWidth: 'lg'
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<>
                    {step === Steps.ChooseAccount
                        && <ChooseAccountStep {...{
                            ...props,
                            option, setOption, accountError, setAccountError, chosenAgency,
                            chosenAccount, chooseAccount
                        }} />}

                    {step === Steps.ChooseScreens
                        && <ChooseScreensStep {...{
                            ...props,
                            name, setName,
                            newCampaignOnly: newCampaignOnly || billboard ? true : false,
                            selectedBoardIds, setSelectedBoardIds,
                            chosenAgency, chooseAgency,
                            exports: exports,
                            selectedExportId: selectedExportId,
                            setSelectedExportId: (value) => {
                                setSelectedExportId(value)
                            }
                        }} />}

                    {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
                </>}

                ActionsComponent={<>
                    {step == Steps.ChooseAccount
                        && <Button onClick={handleClose}
                            color="primary">
                            {t('Cancel')}
                        </Button>}
                    {step == Steps.ChooseScreens
                        && <Button onClick={goBack}
                            color="primary">
                            {t('Back')}
                        </Button>}
                    <ButtonLoader
                        disabled={
                            loading ||
                            loadingExports ||
                            !chosenAccount ||
                            ((!selectedExportId && !selectedBoardIds.length) && step === Steps.ChooseScreens)
                        }
                        onClick={goNext}
                        submitting={loading}
                        color="primary"
                        variant="contained">
                        {t('Next')}
                    </ButtonLoader>
                </>}
            />

            {isNewDialogOpen
                && <NewCreativeDialog
                    skipInsteadOfCancel={true}
                    open={isNewDialogOpen}
                    selectedExportId={selectedExportId}
                    autoActivateCreative={shouldAutoActivateCreative(chosenAccount, exports)}
                    autoCreateProof={true}
                    handleClose={() => setIsNewDialogOpen(false)} />}

        </>
    )
}

const ChooseAccountStep = props => {
    const { option, setOption, accountError, setAccountError, accounts, chosenAgency,
        accountsByAgencyId, selectedAccount, chosenAccount, chooseAccount, isAgencySelectedProfile } = props;

    const { t } = useTranslation();
    const agencyAccounts = useMemo(() => accountsByAgencyId(chosenAgency.id), [chosenAgency, accountsByAgencyId]);

    const classes = useStyles();

    // Set default option for Operators
    useEffect(() => {
        setTimeout(() => {
            if (isAgencySelectedProfile) {
                agencyAccounts.length > 0
                    ? setOption(AccountOptions.another)
                    : setOption(AccountOptions.new);
            }
            else {
                setOption(AccountOptions.current)
            }
        }, 0)
    }, [isAgencySelectedProfile, agencyAccounts.length])

    useEffect(() => {
        if (option === AccountOptions.current || option === AccountOptions.another) {
            isAgencySelectedProfile
                ? agencyAccounts.length > 0 && chooseAccount(agencyAccounts[0])
                : chooseAccount(selectedAccount)
        }

        if (option === AccountOptions.new) {
            chooseAccount({ new_account: true })
        }
    }, [option, selectedAccount, isAgencySelectedProfile, agencyAccounts])

    return <Box className={classes.accountSelectRadio}>
        <IonList>
            <IonRadioGroup
                value={option}
                onIonChange={e => setOption(e.target.value)}
            >
                {isAgencySelectedProfile
                    ? <><IonItem disabled={agencyAccounts.length === 0}>
                        <IonLabel class="ion-text-wrap">
                            <h2>{t('Choose Account')}</h2>
                            <p>{t('The proof will be created in chosen account')}</p>
                        </IonLabel>
                        <IonRadio
                            slot="end"
                            value={AccountOptions.another}></IonRadio>
                    </IonItem>
                        {option == AccountOptions.another
                            && <IonItem><AccountSelectField
                                agencyId={isAgencySelectedProfile
                                    ? chosenAgency.id
                                    : null}
                                value={chosenAccount}
                                onChange={a => chooseAccount(a)}
                            />
                            </IonItem>}
                    </>
                    : <>
                        <IonItem>
                            <IonLabel class="ion-text-wrap">
                                <h2>{selectedAccount.name}</h2>
                                <p>{t('Add to : ')}<b>{selectedAccount.name}</b></p>
                            </IonLabel>
                            <IonRadio slot="end"
                                value={AccountOptions.current}></IonRadio>
                        </IonItem>

                        <IonItem disabled={accounts.length === 1}>
                            <IonLabel class="ion-text-wrap">
                                <h2>{t('A Different Account')}</h2>
                                <p>{t('Select from your accounts')}</p>
                            </IonLabel>
                            <IonRadio slot="end"
                                value={AccountOptions.another}></IonRadio>
                        </IonItem>

                        {option == AccountOptions.another
                            && <IonItem><AccountSelectField
                                agencyId={isAgencySelectedProfile
                                    ? chosenAgency.id
                                    : null}
                                value={chosenAccount}
                                onChange={a => chooseAccount(a)}
                            />
                            </IonItem>}

                    </>}

                <IonItem >
                    <IonLabel class="ion-text-wrap">
                        <h2>{t('Create new Account')}</h2>
                        <p>{t('Generate a new account')}</p>
                    </IonLabel>
                    <IonRadio slot="end"
                        value={AccountOptions.new}></IonRadio>
                </IonItem>
            </IonRadioGroup>
        </IonList>
        <Box mt={2}>
            {option == AccountOptions.new
                && <TextField
                    variant="outlined"
                    fullWidth={true}
                    required={true}
                    error={Boolean(accountError)}
                    helperText={accountError}
                    label={t('Account name')}
                    value={chosenAccount && chosenAccount.name || ''}
                    onChange={e => {
                        chooseAccount({ ...chosenAccount, name: e.target.value });
                        setAccountError('')
                    }}
                />}
        </Box>
    </Box>
}

const ChooseScreensStep = props => {
    const { chosenAgency, chooseAgency, newCampaignOnly, newCampaignClass,
        selectedBoardIds, setSelectedBoardIds, isOperatorSelectedProfile,
        exports, setSelectedExportId, selectedExportId,
        name, setName
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [screenTypeOption, setScreenTypeOption] = useState(newCampaignOnly || exports.length == 0 ? "newCampaign" : "existingCampaign");

    return <>
        {newCampaignClass === InventoryExportCampaignClasses.group.class
            && <TextField
                style={{ maxWidth: 500, marginTop: 16 }}
                label={t('Campaign name')}
                variant="outlined"
                value={name}
                fullWidth
                autoFocus
                onChange={e => setName(e.target.value)}
            />}

        {exports.length > 0 && <Box mt={2} className={classes.campaignSelectRadio}>
            {!newCampaignOnly &&
                <IonList>
                    <IonRadioGroup value={screenTypeOption} onIonChange={e => setScreenTypeOption(e.target.value)}>
                        <IonItem disabled={!exports.length}>
                            <IonLabel class="ion-text-wrap">
                                <h2>{t('An Existing Campaign')}</h2>
                                <p>{t('Select from your campaigns')}</p>
                            </IonLabel>
                            <IonRadio slot="end"
                                value="existingCampaign"></IonRadio>
                        </IonItem>

                        {screenTypeOption == "existingCampaign"
                            && <IonItem>
                                {!selectedExportId && <FormHelperText>{t('Select a Campaign')}</FormHelperText>}
                                <Select
                                    fullWidth={true}
                                    value={selectedExportId}
                                    onChange={e => setSelectedExportId(e.target.value)}
                                >
                                    {exports.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                </Select>

                            </IonItem>}

                        <IonItem>
                            <IonLabel class="ion-text-wrap">
                                <h2>{t('New')}</h2>
                                <p><b>{t('Create a new Campaign')}</b></p>
                            </IonLabel>
                            <IonRadio slot="end"
                                value="newCampaign"></IonRadio>
                        </IonItem>

                    </IonRadioGroup>
                </IonList>
            }
        </Box >
        }

        {
            screenTypeOption == "newCampaign" && <>

                {isOperatorSelectedProfile
                    ? <IonLabel class="ion-text-wrap">
                        <h2>{t('Choose Screens')}</h2>
                        <p>{t('Select your screens by checking them or clicking on the map, you can change this later.')}</p>
                    </IonLabel>

                    : <IonLabel class="ion-text-wrap">
                        <h2>{t('Choose Operator and Screens')}</h2>
                        <p>{t('Select your screens by checking them or clicking on the map, you can change this later.')}</p>
                    </IonLabel>
                }

                <Box mt={2}>
                    {isOperatorSelectedProfile ?
                        <AgencySelectField
                            value={chosenAgency}
                            onChange={a => chooseAgency(a)}
                        />
                        :
                        <AllOperatorsSelectField
                            value={chosenAgency}
                            onChange={a => chooseAgency(a)}
                        />}

                    {chosenAgency
                        && <>
                            <Box mt={2} />
                            <BillboardSelector
                                agency={chosenAgency}
                                values={selectedBoardIds}
                                onChange={setSelectedBoardIds}
                                setSelectedBoardIds={setSelectedBoardIds}
                            />
                        </>}
                </Box>
            </>
        }
    </>
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        accounts: userAccounts(state),
        agencies: state.user.agencies,
        accountsByAgencyId: agencyId => state.user.accounts.filter(a => a.agency_id === agencyId),
        agencySelector: id => agencySelector(state)(id),
        isOperatorSelectedProfile: selectedProfileIsOperator(state),
        isAgencySelectedProfile: selectedProfileIsAgency(state),
        selectedAgency: selectedAgency(state),
        selectedAccount: selectedAccount(state),
        selectedProfileIsAccount: selectedProfileIsAccount(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAccountSelect: account => {
            dispatch(getUserInfo()).then(() => {
                dispatch(selectAccount(account, {
                    onSwitched: () => history.push({ pathname: RoutePaths.home })
                }))
            });
        },
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(QuickAddProof)
)
