/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, useMediaQuery, useTheme, InputAdornment, Box, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ButtonLoader, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Map } from '../../components/google-maps';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
        },
        map: {
            width: '100%',
            height: '300px',
        }
    }
})

const SetBillboardCoordinates = (props) => {
    const { open,
        title,
        handleSave,
        handleClose } = props;

    const { t } = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [submitting, setSubmitting] = useState(false);
    const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });

    const locations = useMemo(() => ([{
        latitude: props.latitude,
        longitude: props.longitude,
        draggable: true,
        targeted: true
    }]), [props.latitude, props.longitude])

    useEffect(() => {
        const newCoords = {
            latitude: props.latitude || coords.latitude,
            longitude: props.longitude || coords.longitude
        }

        setCoords(newCoords)
    }, [props.latitude, props.longitude])

    return (
        <Dialog
            open={open}
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
        >
            <DialogContent className={classes.content}>
                <Typography>Drag the screen icon to actual location on map</Typography>
                <Box mb={2} />
                <Map
                    classes={{
                        map: classes.map
                    }}
                    locations={locations}
                    showCenterButton={false}
                    showTargetedButton={false}
                    onDragEnd={e => {
                        setCoords({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={() => {
                        setSubmitting(true);
                        (handleSave(coords) || Promise.resolve())
                            .then(handleClose)
                            .finally(() => setSubmitting(false))
                    }}
                    className={classes.button}
                >
                    Save
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapStateToDispatch = (dispatch) => {
    return {

    }
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    SetBillboardCoordinates
)
