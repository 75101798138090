import React from 'react';
import { connect } from 'react-redux';

import InventoryFiltersGeneric from './InventoryFiltersGeneric';
import InventoryFiltersCreative from './InventoryFiltersCreative';
import { getUser } from '../../../selectors/user';
import { InventoryItemClasses } from '../../../helpers/constants';

export const INVENTORY_FILTERS_FORM = "inventoryFilters";

function InventoryFilters({ inventoryClass, onSubmit }) {

    if (inventoryClass === InventoryItemClasses.creative) {
        return <InventoryFiltersCreative onSubmit={onSubmit} />;
    }

    if (inventoryClass === InventoryItemClasses.photoStream) {
        return <InventoryFiltersCreative onSubmit={onSubmit} />;
    }

    return <InventoryFiltersGeneric onSubmit={onSubmit} />;
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryFilters);
