import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import CircularProgressCentered from '../components/material/CircularProgressCentered';

export const Loader = (props) => {
    const { isOpen } = props;

    return (
        <>
            <div className={classNames("loader", { "loader--is-open": isOpen })} >
                <CircularProgressCentered />
            </div>
        </>
    )
};

const mapStateToProps = state => {
    return {
        isOpen: state.loader.isOpen,
    }
}

export default connect(
    mapStateToProps
)(Loader);
