import React from 'react';
import { Popper as MaterialPopper, Paper, Collapse, Backdrop, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Condition from '../../components/utils/Condition';

const useStyles = makeStyles(() => {
    return {
        backdrop: {
            zIndex: 1
        },
        collapseHidden: {
            width: 0
        },
    }
})

const Popper = (props) => {
    const { anchorEl, open, onClose, forwardedRef } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Condition if={isMd}
            component={Backdrop}
            open={open}
            onClick={onClose}
            className={classes.backdrop}>
            <MaterialPopper
                popperRef={forwardedRef}
                placement="bottom-end"
                disablePortal
                transition
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
            >
                {({ TransitionProps }) => (
                    <Collapse {...TransitionProps} classes={{ hidden: classes.collapseHidden }}>

                        <Paper className={classes.papper}>
                            {props.children}
                        </Paper>
                    </Collapse>
                )}
            </MaterialPopper>
        </Condition>
    );
};

export default React.forwardRef((props, ref) => <Popper {...props} forwardRef={ref} />);
