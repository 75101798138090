import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Link, List, ListItem,
    ListItemIcon, ListItemText, makeStyles, Typography, ListItemSecondaryAction, Tooltip, CircularProgress
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { Mail, Phone, Delete } from '@material-ui/icons';
import { toLocalDateTime } from '../../helpers/date';
import DateTimeRelative from '../../components/DateTimeRelative';
import { IconButtonLoader } from '../../components/material';
import { InvitationStatus } from '../../helpers/constants';
import { showError, showSuccess } from '../../actions/snackbar';
import { getInvites, uninvite } from '../../actions/users';
import { ConfirmationDialog } from '../../components';
import { SubmissionError } from 'redux-form';
import RolesChip from './RolesChip';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import { objectSelector } from '../../selectors/objects';
import { useEffect } from 'react';
import { rolesSelector } from '../../selectors/roles';
import { getRoles } from '../../actions/roles';

const useStyles = makeStyles(theme => {
    return {
        invitedDate: {
            marginLeft: theme.spacing(1)
        },
        inviteItemContainer: {
            overflowWrap: 'break-word'
        },
        list: {
            width: '100%',
            maxWidth: 500
        },
        secondaryAction: {
            paddingRight: theme.spacing(7.5)
        }
    }
})

function InvitedList(props) {
    const { lcuid, getInvites, getRoles, objectById, rolesById, deleteInvite } = props;
    const classes = useStyles();
    const [removingLcuid, setRemovingLcuid] = useState(null);
    const { t } = useTranslation();

    const object = objectById(lcuid);

    useEffect(() => {
        Promise.all([
            getInvites(object.lcuid),
            getRoles(object.lcuid)
        ])

    }, [object.lcuid])

    if (object.loadingInvites || object.loadingRoles)
        return <CircularProgress size={40} />;

    const roles = rolesById(lcuid);

    return (<>
        {object.invites.length
            ? <LCTypography variant="body2">
                The following invitations are pending, once these users signup, they will be added
            </LCTypography>
            : <>{t('There are no invitations pending')}</>}
        <List className={classes.list}>
            {object.invites.map(x => {
                return (<ListItem key={x.id} classes={{ secondaryAction: classes.secondaryAction }}>
                    <ListItemIcon>
                        {x.email
                            ? <Mail />
                            : <Phone />}
                    </ListItemIcon>
                    <Box maxWidth={400} width={'100%'}>
                        <ListItemText className={classes.inviteItemContainer}>
                            {x.email
                                ? <Link href={`mailto:${x.email}`}>
                                    {x.email}
                                </Link>
                                : <Link href={`tel:${x.mobile}`}>
                                    {x.mobile}
                                </Link>}
                            <Typography className={classes.invitedDate} variant="caption">
                                (<DateTimeRelative date={toLocalDateTime(x.created_at)} format="DD MMM hh:mm A" />)
                            </Typography>
                        </ListItemText>

                        <RolesChip
                            roles={x.options.roles}
                            rolesDictionary={roles}
                        />
                    </Box>

                    {x.status === InvitationStatus.pending.id
                        && <ListItemSecondaryAction>
                            <Tooltip title={t('Delete user invitation')}>
                                <IconButtonLoader
                                    size={24}
                                    submitting={false}
                                    onClick={() => setRemovingLcuid(x.lcuid)}
                                    aria-label={t('delete')}>
                                    <Delete />
                                </IconButtonLoader>
                            </Tooltip>

                            <ConfirmationDialog
                                title={t('Delete Invite')}
                                dialogProps={{
                                    open: x.lcuid === removingLcuid,
                                    onClose: () => setRemovingLcuid(null),
                                    keepMounted: false,
                                    lazy: true
                                }}
                                handleCancel={() => setRemovingLcuid(null)}
                                handleConfirm={() => {
                                    return deleteInvite(lcuid, removingLcuid)
                                        .finally(() => setRemovingLcuid(null))
                                }}>
                                {t('Are you sure you want to delete invite for {{user}}?',
                                    { user: x.email || x.mobile })}
                            </ConfirmationDialog>
                        </ListItemSecondaryAction>}
                </ListItem>)
            })}
        </List>
    </>);
}

InvitedList.propTypes = {
    lcuid: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
    return {
        objectById: lcuid => objectSelector(state)(lcuid),
        rolesById: lcuid => rolesSelector(state)(lcuid)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: (lcuid) => dispatch(getRoles(lcuid)),
        getInvites: (lcuid) => dispatch(getInvites(lcuid)),

        deleteInvite: (lcuid, invitationLcuid) => dispatch(uninvite(lcuid, invitationLcuid))
            .then(() => dispatch(showSuccess(i18next.t('Invite was successfuly deleted'))))
            .catch(error => {
                if (error instanceof SubmissionError) {
                    return dispatch(showError(error.errors))
                }

                dispatch(showError(i18next.t('Error deleting invite, please try again')));
            })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InvitedList
);
