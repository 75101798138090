import React from 'react';
import {
    DESIGNER_LOAD_TEMPLATE, DESIGNER_RESET_STATE, DESIGNER_LOAD_BOARD_FORMATS,
    DESIGNER_SET_TEMPLATE_NAME, DESIGNER_SET_TEMPLATE_DESCRIPTION,
    DESIGNER_ACCOUNT_CHANGE, DESIGNER_BOARD_CHANGE, DESIGNER_ITEM_CLASS_CHANGE,
    DESIGNER_ITEM_CHANGE, DESIGNER_EDIT_MODE_GLOBAL_CHANGE,
    DESIGNER_HTML_CHANGE, DESIGNER_CSS_CHANGE, DESIGNER_CSS_REPLACE_ID, DESIGNER_JS_CHANGE,
    DESIGNER_SET_SIZE_VARIANT, DESIGNER_SET_CSS_VARIANTS, DESIGNER_SELECT_VARIANT,
    DESIGNER_DEL_ASSET, DESIGNER_ADD_FONT, DESIGNER_SET_SELECTED_EDITOR_ID, DESIGNER_SET_SELECTED_FRAME_ID,
    DESIGNER_CREATE_TEMPLATE, DESIGNER_UPDATE_TEMPLATE, DESIGNER_PUBLISH_TEMPLATE,
    DESIGNER_UNPUBLISH_TEMPLATE, DESIGNER_HTML_REPLACE_FOR_ID, DESIGNER_HTML_APPEND,
    DESIGNER_HTML_MOVE_ELEMENT_LAYER, DESIGNER_SET_TEMPLATE_CROP_RATIO,
    DESIGNER_ADD_ITEM_CLASS, DESIGNER_REMOVE_ITEM_CLASS,
    DESIGNER_ADD_BOARD_FORMAT, DESIGNER_REMOVE_BOARD_FORMAT, DESIGNER_HTML_DELETE_LAYER,
    DESIGNER_CLEAR_ALL_SIZE_VARIANTS, DESIGNER_SHOW_HIDE_TEMPLATES, DESIGNER_SHOW_HIDE_CODE_EDITOR,
    DESIGNER_SET_THUMB_FROM_SELECTED_FRAME, DESIGNER_LOAD_TEMPLATE_ASSETS, DESIGNER_HTML_REPLACE_INNER_HTML_FOR_ID,
    DESIGNER_TEMPLATE_MAKE_PUBLIC, DESIGNER_TEMPLATE_MAKE_PRIVATE, DESIGNER_REMOVE_TEMPLATE, DESIGNER_DUPLICATE_TEMPLATE,
    DESIGNER_DRAGGING_IS_HAPPENING, DESIGNER_DRAGGING_IS_DONE, DESIGNER_HTML_SYNC_LAYERS,
    DESIGNER_HTML_LAYER_ADD_CLASS, DESIGNER_HTML_LAYER_REMOVE_CLASS, DESIGNER_HTML_LAYER_SET_CSS_FOR_KEY, DESIGNER_PUSH_HISTORY,
    DESIGNER_HTML_DUPLICATE_LAYER, DESIGNER_HTML_PASTE_TEXT, DESIGNER_UPDATE_TEMPLATE_REQUEST, DESIGNER_ITEM_USER_CHANGE,
    DESIGNER_PREVIEW_SET_ASPECT_RATIO, DESIGNER_SYNC_ALL_SIZE_VARIANTS, DESIGNER_SET_FIELD_MAP,
    DESIGNER_ADD_CUSTOM_FIELD, DESIGNER_REMOVE_CUSTOM_FIELD, DESIGNER_UPDATE_CUSTOM_FIELD, DESIGNER_LOAD_ACCOUNT_KEY_VALUE_STORE,
    DESIGNER_LOAD_TEMPLATES, DESIGNER_LOAD_TEMPLATES_START, DESIGNER_CLEAR_TEMPLATE,
    DESIGNER_TEMPLATE_LOADING, DESIGNER_KEY_VALUE_DATA_LOADING, DESISGNER_LOAD_TEMPLATE_ELEMENTS, DESIGNER_ADD_OBJECT
} from "../helpers/actionTypes";

import { lucitApi } from "../services/lucitApi";
import {
    getUpdateParamsForDesigner, driveTemplateLcuid, getPreview, deriveDropAspectRatioForIds, getDataSourceFieldById
} from '../selectors/designer';
import { selectedProfile } from '../selectors/user';
import { getInjectableHtml, getHtmlForId } from '../containers/designer/DesignerHTML';
import { Designer, ProfileType } from '../helpers/constants'
import { getLayerDataFromHtml, getIdsForObjectCode, getLayerDataForId } from "../selectors/designerLayers";

export function loadTemplates(filters = {}) {
    return (dispatch, getState) => {
        const profile = selectedProfile(getState());

        let promise = Promise.resolve([]);
        dispatch({ type: DESIGNER_LOAD_TEMPLATES_START });

        switch (profile.type) {
            case ProfileType.agency:
                promise = lucitApi.agencies.getDriveTemplates(profile.id, filters)
                break;
            case ProfileType.account:
                promise = lucitApi.accounts.getDriveTemplates(profile.id,
                    Object.assign({
                        include_parent_account_templates: 1
                    }, filters))
                break;
            case ProfileType.user:
                promise = lucitApi.users.getDriveTemplates(profile.id, filters)
                break;
        }

        return promise.then(templates => {
            dispatch({ type: DESIGNER_LOAD_TEMPLATES, templates })
            return templates;
        })
    }
}

export function loadElements(filters) {
    return (dispatch) => {
        return lucitApi.driveTemplateElements.index(filters)
            .then(driveTemplateElements => {

                //Loop through  driveTemplateElements.data and create a new array
                //
                const elements = driveTemplateElements.data.map((element) => {
                    return {
                        name: element.name,
                        description: element.description,
                        element_class: element.element_class,
                        code: element.code,
                        type: element.type,
                        preview: <div
                            style={{ width: "100", height: "100%" }}
                            dangerouslySetInnerHTML={{
                                __html: element.preview_html
                            }}></div >,
                        html: element.html,
                        defaultCss: element.default_css,
                        js: element.js,
                        element_settings: element.element_settings ?? {}
                    }
                })

                return dispatch({
                    type: DESISGNER_LOAD_TEMPLATE_ELEMENTS,
                    elements: elements
                })
            })
    }
}

export function showHideTemplates(templatesVisible) {
    return (dispatch) => {

        dispatch({
            type: DESIGNER_SHOW_HIDE_TEMPLATES,
            templatesVisible
        })
    }
}

export function showHideCodeEditor(codeEditorVisible) {
    return (dispatch) => {

        dispatch({
            type: DESIGNER_SHOW_HIDE_CODE_EDITOR,
            codeEditorVisible
        })
    }
}

export function createTemplate() {
    return (dispatch, getState) => {

        const params = getUpdateParamsForDesigner(getState())

        return lucitApi.driveTemplates.create(params).then((drive_template) => {
            dispatch({
                type: DESIGNER_CREATE_TEMPLATE,
                drive_template
            })

            return drive_template;

        })
    }
}

export function duplicateTemplate(templateLcuid, parent_lcuid, attachToFeedLcuid) {
    return (dispatch, getState) => {

        const params = {
            parent_lcuid: parent_lcuid ? parent_lcuid : selectedProfile(getState()).lcuid,
            update_scoped_feed_lcuid: attachToFeedLcuid
        }

        return lucitApi.driveTemplates.duplicate(templateLcuid, params).then((drive_template) => {
            dispatch({
                type: DESIGNER_DUPLICATE_TEMPLATE,
                drive_template
            })
            return drive_template
        })
    }
}

export function duplicateTemplateForItem(itemLcuid, templateLcuid) {
    return (dispatch) => {

        const params = {
            parent_lcuid: itemLcuid
        }

        return lucitApi.driveTemplates.duplicate(templateLcuid, params).then((drive_template) => {
            dispatch({
                type: DESIGNER_DUPLICATE_TEMPLATE,
                drive_template
            })

            return drive_template
        })
    }
}

export function updateTemplate() {
    return (dispatch, getState) => {

        const params = getUpdateParamsForDesigner(getState())

        const lcuid = driveTemplateLcuid(getState())

        dispatch({ type: DESIGNER_UPDATE_TEMPLATE_REQUEST });

        return lucitApi.driveTemplates.update(lcuid, params)
            .then((drive_template) => dispatch({
                type: DESIGNER_UPDATE_TEMPLATE,
                drive_template
            }))
    }
}

export function makePublic() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        lucitApi.driveTemplates.makePublic(lcuid).then((drive_template) => dispatch({
            type: DESIGNER_TEMPLATE_MAKE_PUBLIC,
            drive_template
        }))
    }
}

export function makePrivate() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        lucitApi.driveTemplates.makePrivate(lcuid).then((drive_template) => dispatch({
            type: DESIGNER_TEMPLATE_MAKE_PRIVATE,
            drive_template
        }))
    }
}

export function publishTemplate() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        lucitApi.driveTemplates.publish(lcuid).then((drive_template) => dispatch({
            type: DESIGNER_PUBLISH_TEMPLATE,
            drive_template
        }))
    }
}

export function unPublishTemplate() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        lucitApi.driveTemplates.unPublish(lcuid).then((drive_template) => dispatch({
            type: DESIGNER_UNPUBLISH_TEMPLATE,
            drive_template
        }))
    }
}

export function removeTemplate() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        lucitApi.driveTemplates.remove(lcuid).then((drive_template) => dispatch({
            type: DESIGNER_REMOVE_TEMPLATE,
            drive_template
        }))
    }
}

export function loadTemplate(lcuid) {
    return (dispatch) => {

        dispatch({ type: DESIGNER_TEMPLATE_LOADING })
        dispatch({ type: DESIGNER_CLEAR_TEMPLATE })

        return lucitApi.driveTemplates.get(lcuid)
            .then((drive_template) => dispatch({
                type: DESIGNER_LOAD_TEMPLATE,
                drive_template
            }))
    }
}

export function reloadCurrentTemplate() {
    return (dispatch, getState) => {

        const lcuid = driveTemplateLcuid(getState())

        dispatch({ type: DESIGNER_TEMPLATE_LOADING })

        return lucitApi.driveTemplates.get(lcuid)
            .then((drive_template) => dispatch({
                type: DESIGNER_LOAD_TEMPLATE,
                drive_template
            }))
    }
}

export function resetState() {
    return (dispatch) => {

        return dispatch({
            type: DESIGNER_RESET_STATE
        })
    }
}

export function setThumbFromSelectedFrame(setMacros = true, width = null, height = null) {
    return (dispatch, getState) => {

        const preview = getPreview(getState(), setMacros);

        const designer = getState().designer

        const lcuid = designer.lcuid
        const html = preview.html
        const css = preview.css
        const js = preview.js

        const templateAssets = designer.templateAssets

        const useFrameId = designer.frameIdSelected ? designer.frameIdSelected : designer.templateBoardFormats[0]

        const imageWidth = width ?? designer.boardFormats[useFrameId].size.width
        const imageHeight = height ?? designer.boardFormats[useFrameId].size.height

        const initialHtml = getInjectableHtml(html, css, js, templateAssets);

        lucitApi.driveTemplates.generatePrimaryImageFromHtml(lcuid, {
            html: initialHtml,
            width: imageWidth,
            height: imageHeight,
        }).then((lhhc) => dispatch({
            type: DESIGNER_SET_THUMB_FROM_SELECTED_FRAME,
            lhhc
        }))

    }
}

export function derivePhotoCropAspectRatio() {
    return (dispatch, getState) => {

        const averageRatio = deriveDropAspectRatioForIds(
            getState(),
            "item_options_primary_image_public_url",
            "item_options_primary_image_background_removed_public_url"
        )

        dispatch({
            type: DESIGNER_SET_TEMPLATE_CROP_RATIO,
            photo_crop_aspect_ratio: averageRatio ? averageRatio : 1
        })
    }
}

export function loadTemplateAssets() {

    return (dispatch) => {

        return lucitApi.driveTemplates.templateAssets()
            .then((template_assets) => dispatch({
                type: DESIGNER_LOAD_TEMPLATE_ASSETS,
                templateAssets: {
                    ...template_assets,
                    loaded: true
                }
            }))

    }
}

export function loadBoardFormats(agencyId) {
    return (dispatch) => {
        return lucitApi.agencies.getDigitalBoardFormats(agencyId)
            .then((boardFormats) => {
                dispatch({
                    type: DESIGNER_LOAD_BOARD_FORMATS,
                    boardFormats
                })
                return boardFormats;
            })
    }
}

export function loadAllBoardFormats() {

    return (dispatch) => {

        return lucitApi.billboards.getDigitalBoardFormats()
            .then((boardFormats) => dispatch({
                type: DESIGNER_LOAD_BOARD_FORMATS,
                boardFormats
            }))
    }
}

export function loadAccountKeyValueStore(accountId, keys) {

    return (dispatch) => {

        dispatch({ type: DESIGNER_KEY_VALUE_DATA_LOADING })

        return lucitApi.accounts.getKeyValueStores(accountId, keys)
            .then((keyValueStore) => dispatch({
                type: DESIGNER_LOAD_ACCOUNT_KEY_VALUE_STORE,
                keyValueStore: keyValueStore
            }))
    }
}

export function setTemplateName(name) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_TEMPLATE_NAME,
            templateName: name
        })
    }
}

export function setTemplateDescription(description) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_TEMPLATE_DESCRIPTION,
            templateDescription: description
        })
    }
}

export function setTemplateCropRatio(cropRatio) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_TEMPLATE_CROP_RATIO,
            photo_crop_aspect_ratio: cropRatio
        })
    }
}

export function setHTML(html) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: DESIGNER_HTML_CHANGE,
                    html: html
                })
                dispatch({ type: DESIGNER_PUSH_HISTORY });

                resolve();
            } catch (error) {
                reject(error);
            }
        });

    }
}

export function replaceInnerHtmlForSelectedId(html) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_REPLACE_INNER_HTML_FOR_ID,
            html: html
        })
    }
}

export function replaceHTMLForSelectedId(html) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_REPLACE_FOR_ID,
            html: html
        })
    }
}

export function replaceHtmlForId(id, html) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_REPLACE_FOR_ID,
            id: id,
            html: html
        })
    }
}

export function deleteLayer(id) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_DELETE_LAYER,
            id: id
        })
        dispatch({
            type: DESIGNER_PUSH_HISTORY
        })
    }
}

export function layerAddClass(id, className) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_LAYER_ADD_CLASS,
            className: className,
            id: id
        })

        dispatch({ type: DESIGNER_PUSH_HISTORY })
    }
}

export function layerRemoveClass(id, className) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_LAYER_REMOVE_CLASS,
            className: className,
            id: id
        })

        dispatch({ type: DESIGNER_PUSH_HISTORY })
    }
}

export function layerSetCssForKey(id, key, value) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_LAYER_SET_CSS_FOR_KEY,
            key: key,
            value: value,
            id: id
        })

        dispatch({ type: DESIGNER_PUSH_HISTORY });
    }
}

export function syncLayers() {
    return (dispatch, getState) => {
        const designer = getState().designer

        dispatch({
            type: DESIGNER_HTML_SYNC_LAYERS,
            layers: getLayerDataFromHtml(getState(), designer.html)
        })
    }
}

export function syncLayerSettingsToHtml(id, layerSettings) {
    return (dispatch, getState) => {

        const isDataSourceElement = layerSettings.objectClass === Designer.ObjectClasses.DataSource;
        const isImage = layerSettings.objectType === Designer.ObjectTypes.Image;

        const designer = getState().designer

        const html = designer.html

        const htmlForId = getHtmlForId(id, html)

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlForId, 'text/html');
        const node = doc.body.firstChild;

        node.setAttribute("x-placeholder", layerSettings.placeholder)
        node.setAttribute("x-fieldName", layerSettings.fieldName)

        if (isDataSourceElement) {
            //We have a new macro
            const dataSourceElement = getDataSourceFieldById(getState(), layerSettings.objectCode)

            node.setAttribute("x-objectcode", dataSourceElement.id)
            node.setAttribute("data-value", dataSourceElement.macroCode)

            if (isImage) {
                node.style = `background-image : url('` + dataSourceElement.macroCode + `')`
            }
            else {
                node.innerHTML = dataSourceElement.macroCode
            }
        }

        if (layerSettings.elementSettingValues) {
            node.setAttribute("x-element-setting-values", layerSettings.elementSettingValues)
        }

        dispatch({
            type: DESIGNER_HTML_REPLACE_FOR_ID,
            id: id,
            html: node.outerHTML,
            layerSettings: layerSettings
        })
    }

}

export function appendHTML(html) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_APPEND,
            html: html
        })
    }
}

export function moveElementLayerInHtml(id, direction) {

    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_MOVE_ELEMENT_LAYER,
            id,
            direction
        })

        dispatch({ type: DESIGNER_PUSH_HISTORY })
    }
}

export function moveElementLayerToAnotherIdInHtml(id, toId, direction) {

    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_MOVE_ELEMENT_LAYER,
            id,
            direction,
            toId
        })
    }
}

export function duplicateLayer(id) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_DUPLICATE_LAYER,
            id
        })
    }
}

export function pasteText(content) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_HTML_PASTE_TEXT,
            content
        })
    }
}

export function setCSS(css) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_CSS_CHANGE,
            css: css
        })
    }
}

export function replaceCSSForSelectedId(css) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_CSS_REPLACE_ID,
            css: css
        })
    }
}

export function replaceCSSForId(id, css) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_CSS_REPLACE_ID,
            id: id,
            css: css
        })
    }
}

export function setJS(js) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_JS_CHANGE,
            js: js
        })
    }
}

export function setSizeVariant(boardFormat, css) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_SIZE_VARIANT,
            boardFormat: boardFormat,
            css: css
        })
    }
}

export function clearAllSizeVariants() {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_CLEAR_ALL_SIZE_VARIANTS,
        })
    }
}

export function syncAllSizeVariants() {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SYNC_ALL_SIZE_VARIANTS,
        })
    }
}

export function setVariants(variants) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_CSS_VARIANTS,
            variants: variants,
        })
    }
}

export function selectVariant(variantId) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SELECT_VARIANT,
            variantId: variantId,
        })
    }
}

export function addObject(params) {
    return (dispatch) => {
        return dispatch({
            type: DESIGNER_ADD_OBJECT,
            id: params.id,
            html: params.html,
            css: params.css,
            showOnlyForThisBoardFormat: params.showOnlyForThisBoardFormat,
            isBackground: params.isBackground,
            elementSettings: params.elementSettings,
            js: params.js,
        })
    }
}

export function deleteAsset(assetId) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_DEL_ASSET,
            assetId: assetId,
        })
    }
}

export function addBoardFormat(boardFormat) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_ADD_BOARD_FORMAT,
            boardFormat: boardFormat,
        })
    }
}

export function removeBoardFormat(boardFormat) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_REMOVE_BOARD_FORMAT,
            boardFormat: boardFormat,
        })
    }
}

export function addInventoryItemClass(driveTemplateLcuid, inventoryItemClass) {
    return (dispatch) => {
        driveTemplateLcuid ? lucitApi.driveTemplates.addInventoryItemClass(driveTemplateLcuid, inventoryItemClass).then(() => dispatch({
            type: DESIGNER_ADD_ITEM_CLASS,
            inventory_item_class: inventoryItemClass,
        })) : dispatch({
            type: DESIGNER_ADD_ITEM_CLASS,
            inventory_item_class: inventoryItemClass,
        })
    }
}

export function removeInventoryItemClass(driveTemplateLcuid, inventoryItemClass) {
    return (dispatch) => {
        driveTemplateLcuid ? lucitApi.driveTemplates.removeInventoryItemClass(driveTemplateLcuid, inventoryItemClass).then(() => dispatch({
            type: DESIGNER_REMOVE_ITEM_CLASS,
            inventory_item_class: inventoryItemClass,
        })) : dispatch({
            type: DESIGNER_REMOVE_ITEM_CLASS,
            inventory_item_class: inventoryItemClass,
        })
    }
}

export function addFont(font) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_ADD_FONT,
            font: font,
        })
    }
}

export function setSelectedEditor(id, source) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_SELECTED_EDITOR_ID,
            id,
            source
        })
    }
}

export function setSelectedFrame(id) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_SET_SELECTED_FRAME_ID,
            id: id,
        })
    }
}

export function setAccount(account) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_ACCOUNT_CHANGE,
            account: account
        })
    }
}

export function setDigitalBoard(digitalBoard) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_BOARD_CHANGE,
            digital_board: digitalBoard
        })
    }
}

export function setItemClass(itemClass) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_ITEM_CLASS_CHANGE,
            itemClass: itemClass
        })
    }
}

export function setPreviewInventoryItemUser(user) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_ITEM_USER_CHANGE,
            user: user
        })
    }
}

export function setPreviewInventoryItem(inventoryItem) {

    return (dispatch, getState) => {

        const item = (inventoryItem && inventoryItem.user) ? inventoryItem : {
            ...inventoryItem,
            user: getState().designer.previewInventoryItemUser
        }

        dispatch({
            type: DESIGNER_ITEM_CHANGE,
            inventoryItem: item
        })
    }
}

export function setDesignerPreviewAspectRatio(aspectRatio) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_PREVIEW_SET_ASPECT_RATIO,
            aspectRatio: aspectRatio
        })
    }
}

export function setEditModeGlobal(editModeGlobal) {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_EDIT_MODE_GLOBAL_CHANGE,
            editModeGlobal: editModeGlobal
        })
    }
}

export function setDraggingIsHappening() {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_DRAGGING_IS_HAPPENING
        })
    }
}

export function setDraggingIsDone() {
    return (dispatch) => {
        dispatch({
            type: DESIGNER_DRAGGING_IS_DONE
        })
    }
}

export function getFieldMap(lcuid) {
    return (dispatch) => {
        return lucitApi.driveTemplates.getFieldMap(lcuid)
            .then(fieldMap => dispatch({
                type: DESIGNER_SET_FIELD_MAP,
                fieldMap
            }))
    }
}

export function addCustomField(customField) {
    return (dispatch) => {
        return dispatch({
            type: DESIGNER_ADD_CUSTOM_FIELD,
            customField: customField,
        })
    }
}

export function updateCustomField(customField) {
    return (dispatch) => {
        return dispatch({
            type: DESIGNER_UPDATE_CUSTOM_FIELD,
            customField: customField,
        })
    }
}

export function syncCustomFieldToExistingLayers(customField) {
    return (dispatch, getState) => {

        const html = getState().designer.html;

        const ids = getIdsForObjectCode(
            getState(),
            customField.id, //The customField id is the objectCode for any layers that use it
            html
        )

        if (ids.length > 0) {
            ids.forEach(id => {

                const layerSettings = getLayerDataForId(getState(), id)

                const htmlForId = getHtmlForId(id, html)

                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlForId, 'text/html');
                const node = doc.body.firstChild;

                layerSettings.placeholder = customField.placeholder
                layerSettings.fieldName = customField.name
                layerSettings.macroCode = customField.macroCode

                node.setAttribute("x-placeholder", customField.placeholder)
                node.setAttribute("x-fieldName", customField.name)
                node.setAttribute("data-value", customField.macroCode)

                node.innerHTML = customField.macroCode

                dispatch({
                    type: DESIGNER_HTML_REPLACE_FOR_ID,
                    id: id,
                    html: node.outerHTML,
                    layerSettings: layerSettings
                })

            })
        }
    }
}

export function removeCustomField(customFieldId) {
    return (dispatch) => {
        return dispatch({
            type: DESIGNER_REMOVE_CUSTOM_FIELD,
            customFieldId: customFieldId,
        })
    }
}
