import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton, LinearProgress, Box, makeStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { passwordStrengthMeter } from '../../helpers/passwordStrength';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        strengthBar: {
            height: 8,
            borderRadius: 4,
            backgroundColor: '#b1b1b14f',
        },
        weak: {
            backgroundColor: theme.palette.error.main,
        },
        fair: {
            backgroundColor: theme.palette.warning.main,
        },
        good: {
            backgroundColor: theme.palette.info.main,
        },
        strong: {
            backgroundColor: theme.palette.success.main,
        }
    }
})

const PasswordField = ({ input, label, meta: { touched, error }, hideStrengthMeter, ...custom }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const passwordScore = passwordStrengthMeter.calculate(input.value);
    const { t } = useTranslation();

    return (
        <>
            <TextField
                label={label}
                error={touched && !!error}
                helperText={touched && error}

                InputProps={{
                    type: showPassword ? 'text' : 'password',
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label={t('toggle password visibility')}
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }}
                {...input}
                {...custom}
            />

            {input.value
                && !hideStrengthMeter
                && <Box mt={2}>
                    <LinearProgress
                        classes={{
                            root: classes[passwordScore.text],
                            bar: classes[passwordScore.text.toLowerCase()]
                        }}
                        className={classes.strengthBar}
                        variant="determinate"
                        value={passwordScore.value} />

                    <Box mt={1} mb={1}>
                        <Box fontWeight={'bold'} component="span">{t('Password strength: ')}</Box>
                        <Box color={passwordScore.color} component="span">{passwordScore.text}</Box>
                    </Box>
                </Box>}
        </>
    );
};

export default PasswordField;