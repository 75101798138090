import React from 'react';
import { connect } from 'react-redux';
import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import DriveTemplateElements from '../../containers/driveTemplateElements/DriveTemplateElements';

function DesignerElements() {

    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t("Designer Elements")} />
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{t('My Designer Elements')}</IonCardTitle>
                    <IonCardContent><LCTypography>
                        Build reusable elements for dynamic creative templates :<> </>
                        <Link
                            color="secondary"
                            href="https://www.lucit.cc/developers"
                            target="_blank"
                        >
                            Learn More : View the Developer Documentation
                        </Link></LCTypography>
                    </IonCardContent>
                </IonCardHeader>

                <IonCardContent>

                    <DriveTemplateElements />

                </IonCardContent>
            </IonCard>

            <ButtonScrollToTopFab />
        </>
    );
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps
)(
    withHomeLayout(DesignerElements)
);
