import {
    OBJECT_COMMENTS, OBJECT_REACTIONS, OBJECT_NEW_REACTION,
    OBJECT_DELETE_REACTION, OBJECT_NEW_COMMENT, OBJECT_NEW_REACTION_REQUEST,
    OBJECT_DELETE_REACTION_REQUEST, OBJECT_DELETE_COMMENT, OBJECT_UPDATE_COMMENT
} from "../../helpers/actionTypes";
import { ReactionClass } from "../../helpers/constants";

export const initialState = {
    // Current user reaction
    reactionCurrent: null,

    reactions: [],
    comments: []
}

export default function reactions(state = initialState, { type, ...payload }) {
    const initializedState = { ...initialState, ...state };

    switch (type) {

        case OBJECT_COMMENTS:
            return Object.assign({ ...initialState },
                state,
                {
                    comments: payload.comments
                });

        case OBJECT_REACTIONS:
            return Object.assign({ ...initialState },
                state,
                {
                    reactions: payload.reactions,
                    reactionCurrent: payload.current_user_reaction
                });

        case OBJECT_NEW_REACTION_REQUEST: {
            const reaction = {
                lcuid: null,
                reaction_class: ReactionClass.like
            }

            return Object.assign({ ...initialState },
                state,
                payload,
                {
                    reactionCurrent: reaction,
                    reactions: [...initializedState.reactions, reaction]
                });

        }
        case OBJECT_NEW_REACTION:
            return Object.assign({ ...initialState },
                state,
                payload,
                {
                    reactionCurrent: payload.reaction,
                    reactions: [...initializedState.reactions.filter(x => Boolean(x.lcuid)), payload.reaction]
                });

        case OBJECT_NEW_COMMENT:
            return Object.assign({ ...initialState },
                state,
                payload,
                {
                    comments: [...initializedState.comments, payload.comment]
                });

        case OBJECT_UPDATE_COMMENT:
            return Object.assign({ ...initialState },
                state,
                payload,
                {
                    comments: initializedState.comments.map(x => {
                        if (x.lcuid === payload.comment.lcuid) {
                            return { ...x, comment: payload.comment.comment };
                        }

                        return x;
                    })
                });

        case OBJECT_DELETE_COMMENT:
            return Object.assign({ ...initialState },
                state,
                payload,
                {
                    comments: initializedState.comments.filter(x => x.lcuid !== payload.comment.lcuid)
                });

        case OBJECT_DELETE_REACTION_REQUEST:
        case OBJECT_DELETE_REACTION:
            if (state.reactionCurrent == null) {
                return state;
            }

            // Delete current reaction
            return Object.assign({ ...initialState },
                state,
                {
                    reactionCurrent: null,
                    reactions: initializedState.reactions.filter(r => r.lcuid != state.reactionCurrent.lcuid)
                });
        default:
            return state
    }
}
