import {
    INVENTORYSTATUS_EXPORT_ITEMS_REFRESH_RESULT, INVENTORYSTATUS_ITEM_STATUS_RESULT,
    INVENTORYSTATUS_EXPORT_ITEMS, INVENTORYSTATUS_UNEXPORT_ITEMS, INVENTORYSTATUS_EXPORT_ITEMS_STATUS_RESULT,
    INVENTORYSTATUS_EXPORT_ITEMS_STATUS_REQUEST, INVENTORYSTATUS_RESET_FILTERS,
    INVENTORYSTATUS_SET_ITEMS_WEIGHTS, INVENTORY_GETITEM_RESULT, EXPORTS_GETBYID_RESULT
} from "../helpers/actionTypes"
import { Global, ItemExportStatus } from '../helpers/constants';
import { itemOptions } from '../selectors/inventory';

export const initialState = {
    filters: {
        search: '',
    },

    currentPage: 1,
    perPage: 50,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,

    exports: {
    },

    items: {
    },

    exportsItems: [
    ],

    itemsWeights: {
        byId: {
        },

        all: []
    }
}

export default function inventoryStatus(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case INVENTORYSTATUS_RESET_FILTERS:
            return Object.assign(state,
                {
                    filters: initialState.filters,
                    currentPage: initialState.currentPage,
                    total: initialState.total
                });
        case INVENTORYSTATUS_EXPORT_ITEMS_STATUS_REQUEST:
            return Object.assign({},
                state,
                payload);

        case EXPORTS_GETBYID_RESULT:
            return Object.assign({},
                state,
                {
                    exports: {
                        ...state.exports,
                        [payload.id]: Object.assign({}, state.exports[payload.id], payload)
                    }
                });

        case INVENTORY_GETITEM_RESULT:
            if (!payload.exports) {
                return state;
            }

            return Object.assign({},
                state,
                {
                    exports: {
                        ...state.exports,

                        ...payload.exports.reduce((acc, e) => {
                            return { ...acc, [e.id]: Object.assign({}, state.exports[e.id], e) }
                        }, {})
                    }
                });

        case INVENTORYSTATUS_ITEM_STATUS_RESULT:
            {
                payload.data.forEach(x => {
                    const itemStatus = x.item_status[0];
                    const exportId = x.details.inventory_export_id;

                    state.exports[exportId] = Object.assign({}, state.exports[exportId], x.details);
                    state.items[itemStatus.item_id] = Object.assign({}, state.items[itemStatus.item_id], itemStatus.item);
                });

                return Object.assign({},
                    state,
                    {
                        exports: state.exports,
                        items: state.items,

                        exportsItems: [
                            ...state.exportsItems.filter(exportItem =>
                                payload.data.every(x => x.details.inventory_export_id !== exportItem.exportId
                                    && x.item_status[0].item_id !== exportItem.itemId)
                            ),

                            ...payload.data.map(x => {
                                const { item_id, ...exportItem } = x.item_status[0];
                                return {
                                    ...exportItem,

                                    exportId: x.details.inventory_export_id,
                                    itemId: item_id,
                                }
                            })
                        ],

                    });
            }

        case INVENTORYSTATUS_EXPORT_ITEMS_REFRESH_RESULT: {
            const { data, ...rest } = payload.item_status;
            const exportId = payload.details.inventory_export_id;

            return Object.assign({},
                state,
                {
                    ...rest,

                    exportsItems: state.exportsItems.map(exportItem => {
                        if (exportId === exportItem.exportId) {
                            const exportItemNew = data.find(x => x.item_id === exportItem.itemId);

                            if (exportItemNew) {
                                return {
                                    ...exportItem,
                                    ...exportItemNew
                                };
                            }
                        }

                        return exportItem;
                    })
                });
        }
        case INVENTORYSTATUS_EXPORT_ITEMS_STATUS_RESULT: {
            const { data, ...rest } = payload.item_status;
            const exportId = payload.details.inventory_export_id;

            state.exports[exportId] = Object.assign({}, state.exports[exportId], payload.details);

            data.forEach(x => {
                const item = x.item || {
                    weight: Global.defaultItemWeight,
                    run_schedule: [],
                    digital_board_filter: [],
                    board_ids: [],

                };

                state.items[x.item_id] = Object.assign({},
                    state.items[x.item_id],
                    x.item,
                    {
                        options: {
                            ...itemOptions(state.items[x.item_id]),
                            ...itemOptions(item),
                            exports: {
                                [exportId]: {
                                    run_schedule: item.schedule,
                                    digital_board_filter: item.digital_board_filter,
                                    board_ids: item.board_ids,
                                }
                            }
                        }
                    });

                state.itemsWeights.byId[x.item_id] = item.weight;
            });

            mutateItemsWeights(state);

            return Object.assign({},
                state,
                {
                    ...rest,

                    exports: state.exports,
                    items: state.items,

                    exportsItems: [
                        ...state.exportsItems.filter(exportItem =>
                            data.every(x => exportId !== exportItem.exportId
                                && x.item_id !== exportItem.itemId)
                        ),

                        ...data.map(x => {
                            return {
                                ...x,

                                exportId,
                                itemId: x.item_id,
                            }
                        })
                    ]
                });
        }
        case INVENTORYSTATUS_EXPORT_ITEMS:
        case INVENTORYSTATUS_UNEXPORT_ITEMS:
            {
                const isExporting = type === INVENTORYSTATUS_EXPORT_ITEMS;

                return Object.assign({},
                    state,
                    {
                        exportsItems: state.exportsItems
                            .map(x => {
                                if (x.exportId === payload.exportId
                                    && payload.itemIds.some(id => id === x.itemId)) {
                                    return {
                                        ...x,
                                        requested: isExporting,
                                        state: isExporting
                                            ? ItemExportStatus.exportPending
                                            : ItemExportStatus.unexportPending,
                                        details: ""
                                    }
                                }

                                return x;
                            }).filter(x => x.requested
                                || (x.state !== ItemExportStatus.itemInactive
                                    && x.state !== ItemExportStatus.doesNotBelongToAccount)
                            )
                    });
            }
        case INVENTORYSTATUS_SET_ITEMS_WEIGHTS: {
            payload.itemsWeights.forEach(x => {
                state.itemsWeights.byId[x.id] = x.weight;
            })

            mutateItemsWeights(state);

            return Object.assign({},
                state,
                {
                    itemsWeights: state.itemsWeights
                });
        }
        default:
            return state
    }
}

function mutateItemsWeights(state) {
    state.itemsWeights.all = Object.keys(state.itemsWeights.byId)
        .map(id => ({
            id: +id,
            weight: state.itemsWeights.byId[id]
        }))
        .sort((a, b) => b.weight - a.weight)
        .map(x => x.id);
}
