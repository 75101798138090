import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { trustUser, revokeUserTrust } from '../../actions/agencies';
import { Avatar, Grid, Box } from '@material-ui/core';
import { userOptions } from '../../selectors/user';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    hiddenOnXs: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

const UserTrustRow = ({ user, trustUser, revokeUserTrust }) => {

    const classes = useStyles();

    const [isTrusted, setIsTrusted] = useState(user && user.is_trusted)

    const setUserTrusted = () => {
        setIsTrusted(true)
        trustUser(user.id);
    }

    const setUserNotTrusted = () => {
        setIsTrusted(false)
        revokeUserTrust(user.id);
    }

    if (!user)
        return <></>

    return (
        <Grid container spacing={1}>
            <Grid item xs={3} sm={3}>
                <Box style={{ maxWidth: '20px' }}>
                    <Avatar
                        alt={user.name}
                        title={user.name}
                        src={userOptions(user).primary_image_public_url}
                    />
                </Box>
            </Grid>
            <Grid item xs={5} sm={3}>
                {user.name}
            </Grid>
            <Grid item xs={4} sm={6}>
                {
                    isTrusted ?
                        <Button variant="outlined" onClick={setUserNotTrusted}>
                            <VerifiedUserIcon color="secondary" />
                            <span className={classes.hiddenOnXs}> Trusted</span>
                        </Button>
                        :
                        <Button variant="outlined" onClick={setUserTrusted}>
                            <VerifiedUserIcon color="error" />
                            <span className={classes.hiddenOnXs}> Not Trusted</span>
                        </Button>
                }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        trustUser: id => dispatch(trustUser(id)),
        revokeUserTrust: id => dispatch(revokeUserTrust(id))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        UserTrustRow
    )
);
