import {
    Box, Button, makeStyles, TextField, InputAdornment, FormControl,
    Select, MenuItem
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { ProgrammaticBudgetTypes } from '../../helpers/constants';
import { lucitApi } from '../../services/lucitApi';
import { useDispatch } from 'react-redux';
import { EXPORTS_GETBYID_RESULT } from '../../helpers/actionTypes';
import { ButtonLoader } from '../../components';
import { CampaignImpressionStats } from './CampaignImpressionStats';
import { CampaignBudgetAllocationDialog } from './CampaignBudgetAllocationDialog';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

export const CampaignBudgetEditDialog = ({ open, handleClose, campaign }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const [stats, setStats] = useState(null);
    const [budgetInput, setBudgetInput] = useState(Number(campaign.budget_amount));
    const [budgetType, setBudgetType] = useState(campaign.budget_type);
    const [budgetAllocation, setBudgetAllocation] = useState(campaign.budget_allocation)
    const [budgetAllocationOpen, setBudgetAllocationOpen] = useState(false);
    const [venueTypeOptions, setVenueTypeOptions] = useState({});
    const [venueTypeCounts, setVenueTypeCounts] = useState({});

    const budgetAllocationWithStats = useMemo(() => ({
        open_ooh_venue_types: budgetAllocation.open_ooh_venue_types.map(x => ({
            ...x,
            count: venueTypeCounts[x.venue_type_id] ?? 0, //TODO: it appears that backend return counts for just child venueTypes, should we calculate count for parents here?
            ...venueTypeOptions[x.venue_type_id]
        }))
    }), [budgetAllocation, venueTypeCounts, venueTypeOptions]);

    const budgetParams = ProgrammaticBudgetTypes[budgetType].budgetParams;

    useEffect(() => {
        if (budgetInput < budgetParams.min) {
            setBudgetInput(budgetParams.min);
        } else if (budgetInput > budgetParams.max) {
            setBudgetInput(budgetParams.max);
        }
    }, [budgetType])

    // Load stats
    useEffect(() => {
        const controller = new AbortController();
        const filters = {
            digital_board_filters: campaign.digital_board_filters,
            budget_type: budgetType,
            budget_amount_dollars: budgetInput,
            start_date: campaign.campaign_start_at,
            end_date: campaign.campaign_end_at
        };

        setLoadingStats(true);
        lucitApi.campaigns
            .getEstimateStats(filters, { singal: controller.signal })
            .then(setStats)
            .finally(() => setLoadingStats(false));
        return () => controller.abort();
    }, [campaign.digital_board_filters, budgetInput, budgetType]);

    // Load venue_types_options and counts
    useEffect(() => {
        lucitApi.billboards.getVenueTypes()
            .then(x => setVenueTypeOptions(
                x.reduce((prev, curr) => ({
                    ...prev,
                    [curr.enumeration_id]: curr
                }), {})
            ));

        lucitApi.campaigns.getGroupedBoardCountsVenueType(campaign.digital_board_filters)
            .then(setVenueTypeCounts)
    }, [campaign])

    const onSave = () => {
        setSaving(true);
        return lucitApi.exports.setProgrammaticSettings(campaign.lcuid, {
            digital_board_filters: campaign.digital_board_filters,
            budget_allocation: budgetAllocation,
            budget_amount: budgetInput,
            budget_type: budgetType,
            start_date: campaign.campaign_start_at,
            end_date: campaign.campaign_end_at
        })
            .then(data => dispatch({
                type: EXPORTS_GETBYID_RESULT,
                ...data.inventory_export
            }))
            .finally(() => setSaving(false));
    };

    return (<>
        <GenericDialog
            title={t('Campaign Budget')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                <Box mt={1} display="flex">
                    <TextField
                        variant="outlined"
                        size='small'
                        title='Budget'
                        fullWidth
                        style={{ maxWidth: 100 }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: {
                                min: budgetParams.min,
                                max: budgetParams.max,
                            },
                        }}
                        value={budgetInput}
                        onChange={e => {
                            setBudgetInput(e.target.value)
                        }}
                    />
                    <FormControl size="small" style={{ marginLeft: 8 }} fullWidth>
                        <Select
                            variant='outlined'
                            size="small"
                            value={budgetType}
                            onChange={e => setBudgetType(e.target.value)}
                        >
                            {Object.keys(ProgrammaticBudgetTypes)
                                .map(key => {
                                    const type = ProgrammaticBudgetTypes[key];
                                    return <MenuItem key={type.code} value={type.code}>
                                        {type.altName}
                                    </MenuItem>
                                })}
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <CampaignImpressionStats
                        loading={loadingStats}
                        stats={stats}
                        budgetType={budgetType}
                        dates={{
                            startDate: campaign.campaign_start_at,
                            endDate: campaign.campaign_end_at
                        }}
                    />
                </Box>
            </Box>}

            ActionsComponent={<>
                <Button
                    style={{ marginRight: 'auto', marginLeft: 16 }}
                    onClick={() => setBudgetAllocationOpen(true)}
                    size="small"
                    variant="outlined"
                    color='secondary'
                >{t('Budget Allocation')}</Button>
                <Button onClick={handleClose}>
                    {t('Close')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    onClick={async () => {
                        await onSave()
                        handleClose();
                    }}
                    variant="contained"
                    color="primary">
                    {t('Save')}
                </ButtonLoader>
            </>}
        />

        {budgetAllocationOpen
            && <CampaignBudgetAllocationDialog
                open={budgetAllocationOpen}
                handleClose={() => setBudgetAllocationOpen(false)}

                budgetAllocation={budgetAllocationWithStats}
                budgetType={budgetType}
                budget={budgetInput}
                onChange={value => setBudgetAllocation(value)}
            />}
    </>)
}
