/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function FileWord(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M23.9905 6.41274H5.54053V1.93149C5.54053 1.33149 6.02803 0.843994 6.62803 0.843994H22.8843C23.4843 0.843994 23.9718 1.33149 23.9718 1.93149V6.41274H23.9905Z" fill="#41A5ED" />
        <path d="M23.9905 6.41321H5.54053V12.0007H23.9905V6.41321Z" fill="#2C7BD5" />
        <path d="M23.9905 12.0002H5.54053V17.5877H23.9905V12.0002Z" fill="#195ABE" />
        <path d="M22.9032 23.156H6.64695C6.04695 23.156 5.55945 22.6685 5.55945 22.0685V17.5873H23.9907V22.0685C23.9907 22.6685 23.5032 23.156 22.9032 23.156Z" fill="#103E91" />
        <path d="M11.1468 18.094H1.20928C0.553027 18.094 0.00927734 17.5502 0.00927734 16.894V7.10649C0.00927734 6.45024 0.553027 5.90649 1.20928 5.90649H11.1468C11.803 5.90649 12.3468 6.45024 12.3468 7.10649V16.894C12.3468 17.5502 11.8218 18.094 11.1468 18.094Z" fill="#2166C3" />
        <path d="M3.60894 15.4502L1.94019 8.71899H3.27144L3.92769 11.7752C4.09644 12.6002 4.26519 13.444 4.37769 14.119H4.39644C4.50894 13.4065 4.69644 12.619 4.88394 11.7565L5.61519 8.71899H6.92769L7.60268 11.8127C7.77143 12.619 7.92144 13.3502 8.01519 14.0815H8.03394C8.14644 13.3315 8.31519 12.5815 8.50269 11.7565L9.21519 8.71899H10.4902L8.65269 15.4502H7.30269L6.59019 12.2815C6.42144 11.5315 6.29018 10.894 6.19643 10.1627H6.17769C6.06519 10.8752 5.93394 11.5315 5.72769 12.3002L4.94019 15.469H3.60894V15.4502Z" fill="white" />
        <path opacity="0.2" d="M12.3469 6.99353C12.3469 7.03103 12.3469 7.06853 12.3469 7.10603V16.8935C12.3469 17.5498 11.8032 18.0935 11.1469 18.0935H5.55945V19.1623H12.1594C12.8157 19.1623 13.3594 18.6185 13.3594 17.9623V8.17478C13.3594 7.57478 12.9282 7.06853 12.3469 6.99353Z" fill="#414549" />
    </SvgIcon>
}
