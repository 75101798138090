import React from 'react';
import clx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Map } from '../../components/google-maps';

const useStyles = makeStyles(theme => {
    return {
        map: {
            width: '100%',
            height: '300px',
            [theme.breakpoints.up('md')]: {
                height: '400px',
            }
        },
    }
})

function BillboardOnMap({ location, classes: customClasses = {} }) {
    const classes = useStyles();

    return (<Map
        classes={{
            map: clx(classes.map, customClasses.map)
        }}
        locations={[{
            ...location,
            targeted: true
        }]}
        showCenterButton={false}
        showTargetedButton={false}
    />)
}

export default BillboardOnMap;
