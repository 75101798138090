import { createSelector } from "reselect";

const locations = state => state.locations;

export const locationByIdSelector = createSelector(
    [locations],
    locations => id => locations.byId[id]
)

export const isLocationLoadedSelector = createSelector(
    [locations],
    locations => id => Boolean(locations.byId[id])
)

export const getPosition = location => (location == null
    ? null
    : {
        lat: +location.latitude,
        lng: +location.longitude,
        panoId: location.options
            ? location.options.google_street_view_pano_id
            : null
    })

export const getPov = location => (location == null
    ? null
    : {
        heading: +location.heading,
        pitch: +location.pitch,
        zoom: +location.zoom
    })
