import React, { useState, useEffect } from 'react';
import { Box, Link } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import ItemFilterEditor from '../inventory/itemFilter/ItemFilterEditor';
import { lucitApi } from '../../services/lucitApi';
import { Skeleton } from '@material-ui/lab';
import { RoutePaths } from '../../helpers/constants';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';

function ItemCombinedFilter({ schedules, setSchedules, digitalBoardFilter, setDigitalBoardFilter, accountId }) {

    const [loadingAccountKeys, setLoadingAccountKeys] = useState(true);
    const [loadingBoardKeys, setLoadingBoardKeys] = useState(true);
    const [keysParsed, setKeysParsed] = useState(false);
    const [accountKeys, setAccountKeys] = useState([]);
    const [boardKeys, setBoardKeys] = useState([]);

    const [additionalFieldsAndFunctions, setAdditionalFieldsAndFunctions] = useState([]);

    const itemFilter = schedules.find(s => s.type === "item_filter") ?? {};

    const combinedFilterFields = (digitalBoardFilter.filter_fields || []).concat(itemFilter.filter_fields || []);
    const combinedFilterFunctions = (digitalBoardFilter.filter_functions || []).concat(itemFilter.filter_functions || []);

    const hasFieldsOrFunctions = additionalFieldsAndFunctions.length > 0;

    const staticBoardFields = [
        {
            id: 'digital_board.name',
            label: 'Screen Name',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.agency.name',
            label: 'Media Owner Name',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.venue_taxonomy_id',
            label: 'Venue Taxonomy ID',
            type: 'field',
            dataType: 'integer',
            group: 'Screen'
        },
        {
            id: 'digital_board.digital_board_format',
            label: 'Digital Board Format',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.location.city',
            label: 'Screen City',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.location.region',
            label: 'Screen State/Province',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.location.country',
            label: 'Screen Country',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        },
        {
            id: 'digital_board.location.datetime_current',
            label: 'Screen Current Date/Time',
            type: 'field',
            dataType: 'string',
            group: 'Screen'
        }
    ];

    const onChangeFilter = (newValues) => {
        //setDigitalBoardFilter(newValues);

        //Here we need to break apart the keys and determine if they belong to the schedule or the digital board

        const digitalBoardFilter = {
            filter_fields: [],
            filter_functions: [], //not currently supported
        };

        const itemFilter = {
            filter_fields: [],
            filter_functions: [], //not currently supported
        };

        newValues.filter_fields.forEach((field) => {
            if (accountKeys.some(k => k.macro === field.field_name)) {
                itemFilter.filter_fields.push(field);
            } else {
                digitalBoardFilter.filter_fields.push(field);
            }
        });

        //If there is an existing item filter, update it
        //Otherwise, add a new item filter
        setSchedules(
            schedules
                .map(s => {
                    if (s.type === "item_filter") {
                        // Check if filter_fields and filter_functions are empty
                        if (itemFilter.filter_fields.length === 0 && itemFilter.filter_functions.length === 0) {
                            return null; // Indicate that this object should be removed
                        } else {
                            return { ...s, ...itemFilter };
                        }
                    }
                    return s;
                })
                .filter(Boolean) // Remove null values from the array
                .concat(
                    schedules.some(s => s.type === "item_filter") ||
                        (itemFilter.filter_fields.length === 0 && itemFilter.filter_functions.length === 0)
                        ? []
                        : [{ type: "item_filter", ...itemFilter }]
                )
        );

        setDigitalBoardFilter(digitalBoardFilter);

    }

    useEffect(() => {
        setLoadingAccountKeys(true);
        lucitApi.accounts.getUniqueKeyValueStores(accountId, 'App\\Account').then((keys) => {
            setAccountKeys(keys);
        }).finally(() => {
            setLoadingAccountKeys(false);
        })
    }, [accountId]);

    useEffect(() => {
        setLoadingBoardKeys(true);
        lucitApi.accounts.getUniqueKeyValueStores(accountId, 'App\\DigitalBoard').then((keys) => {
            setBoardKeys(keys);
        }).finally(() => {
            setLoadingBoardKeys(false);
        })
    }, [accountId]);

    useEffect(() => {
        if (loadingAccountKeys || loadingBoardKeys) return;

        const accountFields = accountKeys.map((field) => {
            return {
                id: field.macro,
                label: field.key_name,
                type: 'field',
                dataType: 'string',
                group: field.inventory_feed?.name ?? 'Account'
            }
        });

        const boardFields = staticBoardFields.concat(boardKeys.map((field) => {
            return {
                id: field.macro,
                label: field.key_name,
                type: 'field',
                dataType: 'string',
                group: field.inventory_feed?.name ?? 'Screen'
            }
        }));

        const fields = accountFields.concat(boardFields);

        setAdditionalFieldsAndFunctions(fields);
        setKeysParsed(true);
    }, [loadingAccountKeys, loadingBoardKeys]);

    return <>

        <Box mt={4} pl={2}>
            <Box>
                {hasFieldsOrFunctions && <LCTypography>Make this ad active only when the following conditions are true :</LCTypography>}
                <Box mt={2} mb={6} style={{ display: 'flex', justifyContent: 'center' }}>

                    {!keysParsed && <Skeleton animation="wave" height={40} width="90%" style={{ marginBottom: 6 }} />}

                    {keysParsed && !hasFieldsOrFunctions && <>
                        <Alert severity="warning" style={{ maxWidth: '400px', width: '100%' }}>
                            <LCTypography>
                                You have no app data for custom filters
                                <br />
                                <br />
                                Try adding  <Link
                                    component={RouterLink}
                                    color="secondary"
                                    style={{ cursor: "pointer" }}
                                    to={RoutePaths.appsAndData}
                                >
                                    some data apps to your account (such as weather or sports data)
                                </Link>
                            </LCTypography>
                        </Alert>
                    </>}

                    {keysParsed && hasFieldsOrFunctions && <ItemFilterEditor
                        filterFields={combinedFilterFields || []}
                        filterFunctions={combinedFilterFunctions || []}
                        onChange={(newValues) => onChangeFilter(newValues)}
                        additionalFieldsAndFunctions={additionalFieldsAndFunctions}
                        includeDefaultFieldsAndFunctions={false}
                    />
                    }

                </Box>

            </Box>
        </Box>

    </>;
}

export default ItemCombinedFilter;
