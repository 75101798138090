import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';

import { BillboardImages, BillboardProfile } from '../../containers/billboards';
import BillboardFeed from '../../containers/billboards/BillboardFeed';
import { lucitApi } from '../../services/lucitApi';
import { OgMetatags, PageTitle } from '../../components';
import { ButtonScrollToTopFab } from '../../components/material';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';
import { ImageStyle } from '../../helpers/constants';

function Profile(props) {
    const { object } = props;

    const [board, setBoard] = useState({ ...object, images: [] });

    const [loaded, setLoaded] = useState(false);
    const [agency, setAgency] = useState(null);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        const promises = [];

        setLoaded(false)

        promises.push(
            lucitApi.objects.get(`LY${object.agency_id}`)
                .then(data => setAgency(data.object))
        );

        promises.push(
            lucitApi.objects.get(`LL${object.location_id}`)
                .then(data => setLocation(data.object))
        );

        if (object.street_view_location_id) {
            promises.push(
                lucitApi.objects.get(`LL${object.street_view_location_id}`)
                    .then(data => setBoard(object => ({
                        ...object,
                        street_view_location: data.object
                    })))
            );
        }

        promises.push(
            lucitApi.billboards.getPublicImages(object.id)
                .then(data => setBoard(object => ({
                    ...object,
                    images: data
                })))
        )

        // eslint-disable-next-line no-undef
        Promise.all(promises)
            .finally(() => setLoaded(true))
    }, [object])

    if (!loaded) {
        return null;
    }

    return (<>
        <Box component={Paper} p={3}>
            <PageTitle title={board.name} />
            <OgMetatags
                title={board.name}
                description={`${board.name} (size ${board.width}px ${board.height}px) is managed by ${agency.name}`}
            />

            <BillboardProfile
                agency={agency}
                location={location}
                board={board} />
        </Box>

        {Boolean(board.images
            .filter(i => i.style === ImageStyle.normal)
            .length)
            && <Box mt={1}>
                <Typography variant="h6" style={{ marginLeft: 12 }}>Board Images</Typography>
                <BillboardImages board={board} enableEdit={false} />
            </Box>}

        <Box mt={2}>
            <BillboardFeed digitalBoard={board} />
        </Box>

        <ButtonScrollToTopFab />
    </>)
}

export default withConditionalLayout(
    Profile, { publicOptions: { disablePaper: true } }
)
