import React, { useState } from 'react';
import { Divider, AccordionSummary, AccordionDetails, Box, Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LCTypography from '../../components/material/LCTypography';
import { AppTour, AppTourStatus, Features } from '../../helpers/constants';
import FeatureSwitch from '../../components/features/FeatureSwitch';
import { connect } from 'react-redux';
import { getUser, userTourSelector } from '../../selectors/user';
import { notifications } from '../../selectors/pushNotifications';
import { handleNotification } from '../../components/notifications';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { Autocomplete } from '../../components/inputs/AutocompleteField';
import { setTour } from '../../actions/user';
import { lucitApi } from '../../services/lucitApi';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2),
            flexDirection: 'column'
        },
        headerIcon: {
            marginBottom: -3
        }
    }
});

function FeaturesToggle(props) {
    const { user, notifications, showNotification } = props;
    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);
    const [notification, setNotification] = useState(null);

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="FeaturesToggle-content"
                id="FeaturesToggle-header"
            >
                <LCTypography variant="h5" >
                    <NewReleasesIcon className={classes.headerIcon} />
                    &nbsp;New Features
                </LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                {Object.keys(Features)
                    .map(key => <FeatureSwitch key={key} featureName={Features[key]} />)}

                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <LCTypography style={{ fontWeight: 'bold' }}>Push Notifications Token:</LCTypography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography>
                                {user.tokenPushNotifications}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <LCTypography style={{ fontWeight: 'bold' }}>Notifications:</LCTypography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Autocomplete
                                onChange={(_, value) => setNotification(value)}
                                multiple={false}
                                value={notification}
                                label={"Notification"}
                                options={notifications}
                                textFieldProps={{
                                    variant: "outlined",
                                }}
                                getOptionLabel={option => option.data.title}
                                getOptionSelected={option => notification.id === option.id}
                                renderOption={(option) => (
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1">
                                            {option.data.title}
                                        </Typography>
                                        <Typography variant="body2">
                                            {option.data.body}
                                        </Typography>
                                        <Box mt={1} />
                                        <Typography variant='caption'>
                                            {JSON.stringify(option.data)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                            <Box mt={2} />
                            <Button
                                disabled={!notification}
                                onClick={() => showNotification(notification)}
                                variant="contained"
                                color="secondary">Show</Button>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <Button
                                onClick={() => props.tourReady(props.profileSwitcherTour)}
                                variant="contained"
                                color="secondary">Start Profile Switcher Tour</Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            lucitApi.spyStatus().then(x => alert(`RESPONSE: ${JSON.stringify(x)}`));
                        }}>
                        Test encrypted endpoint
                    </Button>
                </Box>
            </AccordionDetails>
        </AccordionScrollable>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        notifications: notifications(state),

        profileSwitcherTour: userTourSelector(state)(AppTour.profileSwitcher.id),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showNotification: notification => handleNotification(notification, dispatch),
        tourReady: (tour) => dispatch(setTour({
            ...tour,
            status: AppTourStatus.ready
        })),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FeaturesToggle
);
