import { fontCSS } from '../containers/designer/DesignerFonts'
import { getHtmlForId } from '../containers/designer/DesignerHTML'
import { getCSSStyleForId, getNewStyleStringForString, getStyleValueForCSSKey } from '../containers/designer/DesignerCSS'
import { selectedProfile } from '../selectors/user'
import { LuCoreModelClasses, InventoryItemClasses, Designer } from '../helpers/constants'
import { getIdsForObjectCode } from './designerLayers'
import { replaceMacros } from './designerMacro'
import { searchTermInText } from '../helpers/string';

export const designer = state => state.designer;

export const itemClass = state => state.designer.itemClass
export const previewInventoryItem = state => state.designer.previewInventoryItem
export const previewBoard = state => state.designer.previewBoard
export const boardFormats = state => state.designer.boardFormats    //all of the board formats available
export const templateBoardFormats = state => state.designer.templateBoardFormats //The formats selected for this template
export const driveTemplateLcuid = state => state.designer.lcuid
export const templatePhotoCropAspectRatio = state => state.designer.photo_crop_aspect_ratio
export const variants = state => state.designer.variants
export const selectedVariant = state => state.designer.selectedVariant
export const assets = state => state.designer.assets
export const previewAccount = state => state.designer.previewAccount
export const dirty = state => state.designer.dirty
export const status = state => state.designer.status
export const isPublic = state => state.designer.public
export const primary_image_public_url = state => state.designer.primaryImagePublicUrl
export const templateName = state => state.designer.templateName
export const templateDescription = state => state.designer.templateDescription
export const html = state => state.designer.html
export const css = state => state.designer.css
export const js = state => state.designer.js
export const editorSelectedId = state => state.designer.editorSelectedId
export const editorSelectedIdSource = state => state.designer.editorSelectedIdSource
export const frameIdSelected = state => state.designer.frameIdSelected
export const editModeGlobal = state => state.designer.editModeGlobal
export const sizeVariantsCSS = state => state.designer.sizeVariantsCSS
export const templatesVisible = state => state.designer.templatesVisible
export const codeEditorVisible = state => state.designer.codeEditorVisible
export const templateAssets = state => state.designer.templateAssets
export const templateAssetsLoaded = state => state.designer.templateAssets.loaded
export const draggingIsHappening = state => state.designer.draggingIsHappening

export const selectedFrameIdName = state =>
    state.designer.boardFormats[state.designer.frameIdSelected]
        ? state.designer.boardFormats[state.designer.frameIdSelected].name
        : ""

export const selectedFrameAspectRatio = state =>
    state.designer.boardFormats[state.designer.frameIdSelected]
        ? state.designer.boardFormats[state.designer.frameIdSelected].size.width /
        state.designer.boardFormats[state.designer.frameIdSelected].size.height
        : 1

export const templateCanBeCreated = state => state.designer.inventory_item_class.length
    && state.designer.templateBoardFormats.length
    && state.designer.templateName

export const driveTemplateIsLoaded = state => state.designer.lcuid

export const templateParentIsInventoryItem = state => state.designer.parent_type === LuCoreModelClasses.inventoryItem.class;

//Note on this, we used to prevent editing of public templates
//We now allow it because public templates have to be duplicated
//in order to be used, so we don't have to worry about them being
//messed up by editing
export const allowEdit = () => true

export const idIsSelected = (state, id) => id === state.designer.editorSelectedId

export const hasInventoryItemClass = (state, itemClass) => {
    return state.designer.inventory_item_class.includes(itemClass)
}

export const inventoryItemClasses = (state) => {
    return state.designer.inventory_item_class
}

export const templateIsFullSizeCreative = (state) => {
    return inventoryItemClasses(state)
        && inventoryItemClasses(state).length === 1
        && inventoryItemClasses(state).includes(InventoryItemClasses.creative.class)
}

export const boardFormatsLoaded = (state) => Object.keys(state.designer.boardFormats).length > 0

export const hasBoardFormat = (state, boardFormat) => {
    return state.designer.templateBoardFormats.includes(boardFormat)
}

export const designerReady = (state) => templateAssetsLoaded(state)
    && boardFormatsLoaded(state)
    && driveTemplateIsLoaded(state)

export const getNewStyleStringForId = (state, id, styles) => {

    let css = state.designer.css;

    if (!state.designer.editModeGlobal) {

        //We are in individual edit mode
        const sizeVariant = state.designer.sizeVariantsCSS[state.designer.frameIdSelected] ?? []

        css = sizeVariant.css ?? ""
    }
    const currentStyleString = getCSSStyleForId(id, css)

    return getNewStyleStringForString(currentStyleString, styles)

}

export const getHtmlForSelectedId = state => {
    return getHtmlForId(state.designer.editorSelectedId, state.designer.html)
}

export const getCSSForSelectedId = state => {
    return state.designer.editModeGlobal ?
        getCSSStyleForId(state.designer.editorSelectedId, state.designer.css) :
        getCSSStyleForId(state.designer.editorSelectedId, getSizeVariantCSSForBoardFormat(state, state.designer.frameIdSelected))
}

export const getCSSForId = (state, id) => {
    return state.designer.editModeGlobal ?
        getCSSStyleForId(id, state.designer.css) :
        getCSSStyleForId(id, getSizeVariantCSSForBoardFormat(state, state.designer.frameIdSelected))
}

export const getSizeVariantForBoardFormat = (state, boardFormat) => {
    return state.designer.sizeVariantsCSS[boardFormat] ?? null
}

export const getSizeVariantCSSForBoardFormat = (state, boardFormat) => {

    const variant = getSizeVariantForBoardFormat(state, boardFormat)

    return (variant && variant.css) ? variant.css : ""
}

export const getSizeVariantCSSForSelectedFrame = (state) => {

    const variant = getSizeVariantForBoardFormat(state, state.designer.frameIdSelected)

    return (variant && variant.css) ? variant.css : ""
}

export const coreDesignerFunctions = (state) => {
    return state.designer.templateAssets?.data?.help?.designerFunctions ?? [
        {
            "name": "fnValue",
            "description": "Accepts and Returns the value of the data item",
            "parameters": [
                {
                    "name": "value",
                    "type": "string",
                    "description": "The value to set the data item to"
                }
            ]
        },
        {
            "name": "fnConcat",
            "description": "Accepts an array of strings and returns the concatenated string",
            "parameters": [
                {
                    "name": "stringList",
                    "type": "array",
                    "description": "The array of strings to concatenate"
                }
            ]
        },
        {
            "name": "fnJson",
            "description": "Accepts a JSON object and returns a string",
            "parameters": [
                {
                    "name": "json",
                    "type": "object",
                    "description": "The JSON object to convert to a string"
                }
            ]
        }
    ]
}

/**
 * get a list of all custom functions (in this templates javascript) that were added to the designer using
 * registerDesignerFunction
 * //Thanks ChatGPT
 */
export const extractCustomRegisteredDesignerFunctionsFromJs = (state) => {

    const customJs = state.designer.js;

    if (!customJs) return [];

    const pattern = /registerDesignerFunction\s*\(\s*["'](.*?)["']\s*,/g;
    const matches = customJs.match(pattern) || [];

    // Extract function names from the matches
    const functionNames = matches.map(match => {
        const namePattern = /["'](.*?)["']/;
        const nameMatch = match.match(namePattern);
        return nameMatch ? nameMatch[1] : null;
    }).filter(Boolean);

    return functionNames;

}

//registerTextFormattingFunction
export const extractCustomRegisteredTextFormattingFunctionsFromJs = (state) => {

    const customJs = state.designer.js;

    const pattern = /registerTextFormattingFunction\s*\(\s*["'](.*?)["']\s*,\s*["'](.*?)["']/gs;
    const matches = customJs.matchAll(pattern);

    // Extract function names and friendly function names from the matches
    const functionDetails = Array.from(matches).map(match => {
        return {
            functionName: match[1],
            friendlyFunctionName: match[2]
        };
    });

    return functionDetails;

}

export const getDesignerFunctions = (state) => {

    const coreFunctions = coreDesignerFunctions(state);

    const customFunctions = extractCustomRegisteredDesignerFunctionsFromJs(state);

    const customFunctionsHelp = customFunctions.map(x => {
        return {
            name: x,
            description: "Custom function added to this template in the code editor",
            parameters: [],
        }
    })

    return coreFunctions.concat(customFunctionsHelp);

}

export const getDesignerTextFormattingFunctions = (state) => {

    const customFunctions = extractCustomRegisteredTextFormattingFunctionsFromJs(state);

    if (!customFunctions || customFunctions.length === 0) return [];

    const textClassOptions = customFunctions.map(x => {
        return {
            class: 'lc_format_tff_' + x.functionName,
            label: x.friendlyFunctionName
        }
    })

    return textClassOptions;

}

export const getFieldMap = (state, query = "") => {

    if (!state.designer.fieldMap) return null;

    const fieldMap = state.designer.fieldMap;
    //Now add in the custom fields?
    fieldMap.custom = {
        description: Designer.CustomFieldGroupDescription,
        name: Designer.CustomFieldGroupName,
        object: Designer.CustomFieldTemplate.object,
        sort_order: 10,
        field_map: {
            common: {
                class: Designer.CustomFieldTemplate.class,
                name: "Common",
                fields: state.designer.customFieldMap ?? []
            }
        }
    }

    if (!query || query === "") return fieldMap;

    // Create a deep copy of the object to avoid mutating the original
    const filteredFieldMap = JSON.parse(JSON.stringify(fieldMap));

    // Iterate over the keys in the object
    for (const key in filteredFieldMap) {
        if (Object.hasOwn(filteredFieldMap, key)) {
            const fieldMap = filteredFieldMap[key].field_map;

            // Iterate over the field_map entries
            for (const mapKey in fieldMap) {

                if (Object.hasOwn(fieldMap, mapKey)) {
                    const fields = fieldMap[mapKey].fields;

                    // Filter the fields array based on the name property
                    fieldMap[mapKey].fields = fields.filter(field =>
                        searchTermInText(query, [
                            field.id,
                            field.name,
                            field.class,
                            field.description,
                            field.group_name,
                            field.name,
                            field.object_name,
                            field.placeholder, field.macro
                        ])
                    );
                }
            }
        }
    }

    return filteredFieldMap;

}

export const getUsedMacros = state => {
    return state.designer.usedMacros
}

/**
 * A macro looks like `digital_board.store.Weather1_forecast_daily_will_it_rain`
 * Its key is `Weather1_forecast_daily_will_it_rain`
 */
export const getKeyFromKeyValueMacro = (macro) => {
    const parts = macro.split('.store.');

    if (parts.length === 2)
        return parts[1];
    else
        return null;
}

/**
 *
 * Based on the usedMacros, return a list of keys from the key value store that are used in the template
 */
export const getKeysUsedInMacros = state => {
    const usedMacros = getUsedMacros(state);
    const fields = getDataSourceFields(state);

    const macroFields = fields.filter(
        x => x.object === "key_value_store"
            && getKeyFromKeyValueMacro(x.macro)
            && usedMacros.includes(x.macro)
    ).map(x => (getKeyFromKeyValueMacro(x.macro)))

    return macroFields

}

export const getDataSourceFields = state => {

    const fields = state.designer.fields

    const macroFields = fields.filter(x => x.class !== Designer.CustomFieldTemplate.class)

    //Now, check the custom fields for any fields that they are using
    //These fields will become inventory_attributes on the item
    const customFields = fields.filter(x => x.class === Designer.CustomFieldTemplate.class)

    customFields.forEach((field) => {
        if (field.fields) {
            field.fields.forEach((customField) => {

                const attributeField = {
                    ...Designer.CustomFieldItemAttributeTemplate,
                    ...{
                        id: "custom_field_" + customField.key,
                        name: customField.name,
                        property: "inventory_attributes." + customField.key,
                        macro: "item.inventory_attributes." + customField.key,
                        macroCode: "{item.inventory_attributes." + customField.key + "}",
                        placeholder: customField.placeholder,
                    }
                }

                macroFields.push(attributeField)
            })
        }
    })

    return macroFields

};
export const getDataSourceFieldById = (state, id) => state.designer.fields.find(x => x.id == id);

export const getAccountKeyValueForObject = (state, objectId, objectType, key) => {

    return state.designer.accountKeyValues.find(
        x => x.object_id == objectId && x.object_type == objectType && x.key == key)?.value ?? null;
}

export const getLastFontUsed = (state) => {
    return state.designer.fonts.length ? state.designer.fonts[state.designer.fonts.length - 1] : null
}

export const getLastColorUsed = (state) => {
    return state.designer.colors.length ? state.designer.colors[state.designer.colors.length - 1] : null
}

export const deriveDropAspectRatioForIds = ((state, objectCode, bgRemovedObjectCode) => {
    const aspectRatios = [];

    state.designer.templateBoardFormats.forEach((boardFormatId) => {
        const boardFormat = state.designer.boardFormats[boardFormatId]

        if (!boardFormat) return

        const css = state.designer.css + getSizeVariantCSSForBoardFormat(state, boardFormatId)

        const ids = getIdsForObjectCode(state, objectCode, state.designer.html)
            .concat(getIdsForObjectCode(state, bgRemovedObjectCode, state.designer.html))

        ids.forEach((id) => {
            const cssForId = getCSSStyleForId(id, css)

            const width = getStyleValueForCSSKey(cssForId, "width").replace("%", "") / 100
            const height = getStyleValueForCSSKey(cssForId, "height").replace("%", "") / 100

            if (!width || !height) return

            const aspectRatio = (boardFormat.size.width * width) / (boardFormat.size.height * height)

            aspectRatios.push(aspectRatio)
        })
    })

    const averageRatio = aspectRatios.length > 0 ? aspectRatios.reduce((a, b) => a + b) / aspectRatios.length : 1

    return averageRatio

})

export const getUpdateParamsForDesigner = (state) => {
    const designer = state.designer
    const getTemplateFields = () => {
        return {
            "photo": {
                "type": "image",
                "photo_select": "primary",
            },
            "title": {
                "type": "text",
            }
        }
    }

    const getTemplateFilters = () => {
        return {
            "filter_fields": [
                {
                    "field_name": "item_class",
                    "operator": "equals",
                    "value": designer.itemClass
                }
            ]
        }
    }

    const getStringifiedTemplates = () => {
        return JSON.stringify({
            "base": {
                "html": designer.html,
                "css": designer.css,
                "template_board_formats": designer.templateBoardFormats,
                "css_size_variants": designer.sizeVariantsCSS,
                "fonts": designer.fonts,
                "colors": designer.colors,
                "js": designer.js,
                "fields": getTemplateFields(),
                "filters": getTemplateFilters(),
                "layers": designer.layers,
                "photo_crop_aspect_ratio": parseFloat(designer.photo_crop_aspect_ratio),
                "drive_image_engine": "\\App\\LuCore\\Images\\DriveImageEngine\\HTMLDriveImageEngine",
                "used_macros": designer.usedMacros,
                "custom_field_map": designer.customFieldMap,
                "edit_mode_global": designer.editModeGlobal
            }
        })
    }

    const getStringifiedVariants = () => {
        return JSON.stringify(designer.variants)
    }

    const getStringifiedAssets = () => {
        return JSON.stringify(designer.assets)
    }

    const getStringifiedItemClasses = () => {
        return designer.inventory_item_class.length > 0 ? JSON.stringify(designer.inventory_item_class) : ""
    }

    return {
        name: designer.templateName,
        description: designer.templateDescription,
        templates: getStringifiedTemplates(),
        variants: getStringifiedVariants(),
        assets: getStringifiedAssets(),
        inventory_item_class: getStringifiedItemClasses(),
        parent_lcuid: selectedProfile(state).lcuid
    }
}

export const getPreview = (state, setMacros = true) => {
    const designer = state.designer

    const getFontCSS = () => {
        return designer.fonts.map((font) => {
            return fontCSS(font)
        }).join("\n")
    }

    const getDefaultVariantCSSHeaderForBoardFormat = (boardFormatCode) => {

        const boardFormat = designer.boardFormats[boardFormatCode]

        if (!boardFormat)
            return ""

        const minRatioDecimal = Math.ceil(boardFormat.ratios.min * 1000)
        const maxRatioDecimal = Math.floor(boardFormat.ratios.max * 1000)

        const defaultRatioMediaQueryCSS =
            "@media (min-aspect-ratio: " + minRatioDecimal + "/1000) and (max-aspect-ratio: " + maxRatioDecimal + "/1000) {\n\n"

        return defaultRatioMediaQueryCSS

    }

    const getPreviewCSS = () => {

        let newCss = designer.css

        newCss = getFontCSS() + "\n\n" + newCss

        const sizeVariantCSSString = Object.keys(designer.sizeVariantsCSS).map((sv) => {

            const headerCode = getDefaultVariantCSSHeaderForBoardFormat(sv);

            return (sv && headerCode) ? headerCode + designer.sizeVariantsCSS[sv].css + "\n}\n" : ""

        }).join("\n")

        newCss = newCss + "\n\n/**SIZE VARIANTS**/\n\n" + sizeVariantCSSString

        if (designer.variants && designer.variants[designer.selectedVariant])
            newCss = newCss + "\n\n/**STYLE VARIANTS**/\n\n" + designer.variants[designer.selectedVariant].css

        newCss = setMacros ? replaceMacros(
            state,
            newCss,
            designer.previewInventoryItem,
            designer.previewAccount,
            designer.previewInventoryItem ? designer.previewInventoryItem.user : null,
            designer.previewBoard,
            designer.assets
        ) : replaceMacros(
            state,
            newCss,
            null,
            null,
            null,
            null,
            designer.assets
        )

        return newCss

    }

    const getPreviewHtml = () => {

        let newHtml = designer.html

        newHtml = setMacros ? replaceMacros(
            state,
            newHtml,
            designer.previewInventoryItem,
            designer.previewAccount,
            designer.previewInventoryItem ? designer.previewInventoryItem.user : null,
            designer.previewBoard,
            designer.assets
        ) : replaceMacros(
            state,
            newHtml,
            null,
            null,
            null,
            null,
            designer.assets
        )

        return newHtml
    }

    const getPreviewJS = () => {

        let newJS = "\n/**CUSTOM JS**/\n\n" + designer.js

        newJS = setMacros ? replaceMacros(
            state,
            newJS,
            designer.previewInventoryItem,
            designer.previewAccount,
            designer.previewInventoryItem ? designer.previewInventoryItem.user : null,
            designer.previewBoard,
            designer.assets
        ) : replaceMacros(
            state,
            newJS,
            null,
            null,
            null,
            null,
            designer.assets
        )

        return newJS
    }

    return {
        "html": getPreviewHtml(),
        "css": getPreviewCSS(),
        "js": getPreviewJS()
    }
}
