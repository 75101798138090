import { Box, Button, FormHelperText, makeStyles, FormControl } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { lucitApi } from "../../services/lucitApi";
import { getUserInfo } from '../../actions/user';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { getUser, selectedAgency } from '../../selectors/user';
import ButtonLoader from '../../components/material/ButtonLoader';
import { selectAccount, selectAgency } from '../../actions/user';
import { ProfileType } from '../../helpers/constants';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import AgencySelectField from '../../containers/agency/AgencySelectField';
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import { AgencyClasses } from '../../helpers/constants';
import { createAccount } from '../../actions/account';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            x: theme.palette.text.disabled,
            padding: theme.spacing(3)
        },

        cardWrapper: {
            // boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            // padding: '16px 8px',
            // borderRadius: '4px',
            // border: '1px solid rgba(222, 222, 222, 0.7)'
        },

        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const OperatorAddAccount = (props) => {
    const {
        open, handleClose, handleAccountSelect, handleAgencySelect, selectedAgency,
        agencies, defaultAccountTypeOption = 'newAccount',
        showAgencyAndOperator = false, showAgency = true, parentAccount = null,
        onAdded
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [accountTypeOption, setAccountTypeOption] = useState('newAccount');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [agency, setAgency] = useState(selectedAgency);
    const [accountName, setAccountName] = useState('')
    const [accountWebSite, setAccountWebSite] = useState('')
    const [accountType, setAccountType] = useState('')
    const [accountCityState, setAccountCityState] = useState('')
    const [comments, setComments] = useState('')
    const { t } = useTranslation();

    const clearForm = () => {
        setAccountName('')
        setAccountWebSite('')
        setAccountType('')
        setAccountCityState('')
        setComments('')
    }

    useEffect(() => {
        setAccountTypeOption(defaultAccountTypeOption)
    }, [defaultAccountTypeOption])

    const addingAccount = () => accountTypeOption === 'newAccount';
    const addingAdAgency = () => accountTypeOption === 'newAdAgency';
    const addingOperator = () => accountTypeOption === 'newOperator';

    const handlSubmit = () => {
        if (!accountName) {
            setError(t('Name is Required'));
            return;
        }

        if (parentAccount) {
            setLoading(true)
            return dispatch(createAccount({
                name: accountName,
                parent_account_id: parentAccount.id,
                agency_id: parentAccount.agency_id,
                account_class: parentAccount.account_class,
                inventory_item_class: parentAccount.inventory_item_class[0],
            }))
                .then(account => {
                    onAdded && onAdded(account);
                    clearForm()
                    handleClose()
                })
                .catch(() => setLoading(false))
        }

        if (addingAccount())
            handlSubmitAccount();

        if (addingAdAgency())
            handleSubmitAgency();

        if (addingOperator())
            handleSubmitAgency();
    }

    const handleSubmitAgency = () => {
        if (!accountCityState) {
            setError(t('City state is Required'));
            return;
        }

        setLoading(true)

        const agencyClass = addingAdAgency() ? AgencyClasses.adagency.class : AgencyClasses.operator.class

        lucitApi.agencies.createAgency(accountName, accountWebSite, agencyClass, accountCityState).then((agency) => {
            setLoading(false)
            clearForm()
            handleClose()
            handleAgencySelect(agency)
        }).catch(error => {
            setError(error.message)
        });
    }

    const handlSubmitAccount = () => {
        if (!accountType) {
            setError(t('Account type is Required'));
            return;
        }

        if (!accountCityState) {
            setError(t('Account city state is Required'));
            return;
        }

        setLoading(true)
        const getBestAgencyId = () => {
            if (agency)
                return agency.id;
            if (agencies.length > 0)
                return agencies[0].id

            return null;
        }

        const request = {
            accountWebSite: accountWebSite,
            accountName: accountName,
            accountCityState: accountCityState,
            accountType: accountType,
            comments: comments,
            agency_id: getBestAgencyId()
        }

        lucitApi.submitOperatorNewAccountRequest(request).then((data) => {
            setLoading(false)
            clearForm()
            handleClose()

            if (!data.queued) {
                onAdded
                    ? onAdded(data.account)
                    : handleAccountSelect(data.account)
            }
        }).catch(error => {
            setError(error.message)
        });
    }

    return (
        <GenericDialog
            title={t('Add New')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>
                <Box className={classes.cardWrapper}>
                    {showAgencyAndOperator
                        && <>
                            <IonList>
                                <IonRadioGroup value={accountTypeOption} onIonChange={e => setAccountTypeOption(e.target.value)}>
                                    <IonItem>
                                        <IonLabel class="ion-text-wrap">
                                            <h2>{t('Account')}</h2>
                                            <p>{t('Create campaigns and creatives')}</p>
                                        </IonLabel>
                                        <IonRadio slot="end"
                                            value="newAccount"></IonRadio>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel class="ion-text-wrap">
                                            <h2>{t('Ad Agency')}</h2>
                                            <p>{t('Manage Multiple Accounts under one roof')}</p>
                                        </IonLabel>
                                        <IonRadio slot="end"
                                            value="newAdAgency"></IonRadio>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel class="ion-text-wrap">
                                            <h2>{t('Operator / Screen Owner')}</h2>
                                            <p>{t('Media owners, setup billboards / OOH screens that your company owns')}</p>
                                        </IonLabel>
                                        <IonRadio slot="end"
                                            value="newOperator"></IonRadio>
                                    </IonItem>
                                </IonRadioGroup>
                            </IonList>
                        </>}
                    <div>
                        {showAgency
                            && addingAccount()
                            && agencies.length > 1
                            && <FormControl margin='dense' fullWidth>
                                <AgencySelectField
                                    value={agency}
                                    onChange={agency => setAgency(agency)}
                                />
                            </FormControl>}

                        <FormControl margin='dense' fullWidth>
                            <TextField
                                onChange={(e) => setAccountName(e.target.value)}
                                value={accountName}
                                label={t(' Name')}
                                autoFocus
                            />
                        </FormControl>

                        <FormControl margin='dense' fullWidth>
                            <TextField
                                onChange={(e) => setAccountWebSite(e.target.value)}
                                value={accountWebSite}
                                helperText="https://www.companywebsite.com"
                                label={t('Website Link')}
                            />
                        </FormControl>

                        <FormControl margin='dense' fullWidth>
                            <TextField
                                onChange={(e) => setAccountCityState(e.target.value)}
                                value={accountCityState}
                                label={t('City / State')}
                            />
                        </FormControl>

                        {addingAccount() && <FormControl margin='dense' fullWidth>
                            <InputLabel id="demo-simple-select-label">{t('Type')}</InputLabel>
                            <Select
                                labelId="account-type"
                                id="account-type"
                                value={accountType}
                                label={t('Type')}
                                onChange={(e) => setAccountType(e.target.value)}
                            >
                                <MenuItem value="automotive">{t('Automotive')}</MenuItem>
                                <MenuItem value="real_estate">{t('Real Estate')}</MenuItem>
                                <MenuItem value="motorsports">{t('Motorsports')}</MenuItem>
                                <MenuItem value="ag_heavy_equipment">{t('Ag / Heavy Equipment')}</MenuItem>
                                <MenuItem value="retail">{t('Retail')}</MenuItem>
                                <MenuItem value="professional_service">{t('Professional Service')}</MenuItem>
                                <MenuItem value="government_political">{t('Government / Political')}</MenuItem>
                                <MenuItem value="news_media">{t('Media / News / Content')}</MenuItem>
                                <MenuItem value="other">{t('Other')}</MenuItem>
                            </Select>
                        </FormControl>
                        }
                    </div>
                </Box>
                {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    disabled={loading}
                    onClick={handlSubmit}
                    submitting={loading}
                    color="primary"
                    variant="contained">
                    {t('Add')}
                </ButtonLoader>
            </>}
        />
    )
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        agencies: state.user.agencies,
        selectedAgency: selectedAgency(state)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleAccountSelect: account => {
            dispatch(getUserInfo()).then(() => {
                dispatch(selectAccount(account,
                    {
                        animate: true,
                        onSwitched: () => props.history.push({ pathname: ProfileType.account.path })
                    }))
            });
        },
        handleAgencySelect: agency => {
            dispatch(getUserInfo()).then(() => {
                dispatch(selectAgency(agency,
                    {
                        animate: true,
                        onSwitched: () => props.history.push({ pathname: ProfileType.agency.path })
                    }))
            });
        },
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OperatorAddAccount)
)
