import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { PageTitle } from '../../components';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { useHistory, useRouteMatch } from 'react-router';
import InvoiceDetails from '../../containers/billing/InvoiceDetails';
import { ArrowBack } from '@material-ui/icons';

function Invoice() {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <Box>
            <PageTitle title={"Invoice"} />
            <Box p={2}>
                {history.length
                    && <Box mb={1}>
                        <Link
                            component="button"
                            onClick={() => history.goBack()}>
                            <Box display="flex" alignItems="center">
                                <ArrowBack />
                                <Typography style={{ marginLeft: 8 }}>Back</Typography>
                            </Box>
                        </Link>
                    </Box>}
                <InvoiceDetails lcuid={match.params.lcuid} />
            </Box>
        </Box>
    );
}

const InvoiceWithoutLayout = Invoice;

export { InvoiceWithoutLayout };
export default withHomeLayout(InvoiceWithoutLayout);
