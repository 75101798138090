import React from 'react';
import { Box, Divider, Typography } from "@material-ui/core"

export const SettingsSection = ({ title, children, last }) => {
    return <>
        <Box mb={last ? 0 : 2}>
            <Typography variant="h6" style={{ marginBottom: 8 }}>{title}</Typography>
            <Box mt={1} />
            <Box ml={6} maxWidth={600}>
                {children}
            </Box>

            <Box mt={2} />
            {!last
                && <Divider />}
        </Box>
    </>
}
