
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ApplicationIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <rect x="1.5" y="1.5" width="9" height="9" rx="1.5" stroke="#364152" />
        <rect x="1.5" y="13.5" width="9" height="9" rx="1.5" stroke="#364152" />
        <rect x="13.5" y="13.5" width="9" height="9" rx="4.5" stroke="#364152" />
        <rect x="13.5" y="1.5" width="9" height="9" rx="1.5" stroke="#364152" />
    </SvgIcon>
}
