import { apiHttp } from "../helpers/api";
import LucitApiExports from "./lucitApi.exports";

export default class LucitApiFeedProviders extends LucitApiExports {

    /**
     *
     * @params json params {
     *   query : string,
     *   application_id : int/lcuid
     *   inventory_item_class : string
     * }
     */
    getFeedProviders(isLoggedIn, params) {

        const url = isLoggedIn ? `/inventory-feed-providers` : `/public/inventory-feed-providers`;

        return apiHttp.get(url, { params: params })
            .then(response => response.data.inventory_feed_providers);
    }

    /** data supports name,description, application_id and inventory_item_class */
    createFeedProvider(data) {
        return apiHttp.post(`/inventory-feed-providers`, data)
            .then(response => response.data.inventory_feed_provider);
    }

    /** data supports, name and desccription */
    updateFeedProvider(feedProviderId, data) {
        return apiHttp.put(`/inventory-feed-providers/${feedProviderId}`, data)
            .then(response => response.data.inventory_feed_provider);
    }

    setActive(feedProviderId, active) {
        return apiHttp.put(`/inventory-feed-providers/${feedProviderId}/setActive`, {
            active: active
        })
            .then(response => response.data.inventory_feed_provider);
    }

    setFeedSettings(feedProviderId, feedSettings) {
        return apiHttp.put(`/inventory-feed-providers/${feedProviderId}/set-feed-settings`, {
            feed_settings: JSON.stringify(feedSettings)
        })
            .then(response => response.data.inventory_feed_provider);
    }

    setFeedParams(feedProviderId, feedParams) {
        return apiHttp.put(`/inventory-feed-providers/${feedProviderId}/set-feed-params`, {
            feed_params: JSON.stringify(feedParams)
        })
            .then(response => response.data.inventory_feed_provider);
    }

    setPrimaryImage(feedProviderId, image_id) {
        return apiHttp.post(`/inventory-feed-providers/${feedProviderId}/primaryImage`, { image_id })
            .then(response => response.data.object);
    }

    deletePrimaryImage(feedProviderId) {
        return apiHttp.delete(`/inventory-feed-providers/${feedProviderId}/primaryImage`)
            .then(response => response.data);
    }

    getDriveTemplates(feedProviderId, filters) {
        return apiHttp.get(`/inventory-feed-providers/${feedProviderId}/drive-templates`, { params: filters })
            .then(response => response.data.drive_templates);
    }

}
