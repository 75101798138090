import moment from "moment";

/**
 * Generated array of sequenced years starting from @startYear up to current year *
 * @export
 * @param {*} startYear
 * @returns
 */
export function getYearsUpToNow(startYear) {
    const yearNow = new Date().getFullYear();

    if (startYear > yearNow) {
        return [startYear];
    }

    return getRangeLength(startYear, yearNow)
}

/**
 * Generates number sequence array of values starting with @start value, with @length
 * @export
 * @param {*} start
 * @param {*} length
 * @returns array
 */
export function getRange(start, end) {
    if (isNaN(start) || isNaN(end)
        || start == null || end == null) {
        return [];
    }

    return getRangeLength(start, Math.abs(end - start + 1))
}

/**
 * Generates number sequence array of values starting with @start value, with @length
 * @export
 * @param {*} start
 * @param {*} length
 * @returns array
 */
export function getRangeLength(start, length) {
    return Array.from({ length: length }, (x, i) => start + i)
}

export const minutesAgo = (date) => {
    return moment().diff(moment(date), 'minutes');
}

export function toLocalDateTime(date) {
    return moment.utc(date).local();
}

export function clearTimezone(date) {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss");

    return moment.utc(dateWithoutZone);
}

export function clearTimezoneISO(date) {
    return clearTimezone(date).format();
}
