import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../components';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { AccountChildren } from '../../containers/account/AccountChildren';
import { useSelector } from 'react-redux';
import { accountsTree, selectedAccount } from '../../selectors/user';
import { useTranslation } from 'react-i18next';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount';
import { lucitApi } from '../../services/lucitApi';

function ChildAccounts() {
    const account = useSelector(selectedAccount);
    const [showAddNewAccount, setShowAddNewAccount] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        lucitApi.accounts.getChildren(account)
            .then(x => setAccounts(accountsTree(x)))
            .finally(() => setLoading(false));
    }, [])

    return (
        <Box p={2}>
            <PageTitle title={t('Child Accounts')} />
            <Box display={"flex"} justifyContent="space-between">
                <Typography variant="h5">{t('Child Accounts')}</Typography>

                <Button
                    onClick={() => setShowAddNewAccount(true)}
                    variant="contained"
                    color="primary"
                >{t('Add Account')}</Button>
                <OperatorAddAccount
                    parentAccount={account}
                    showAgencyAndOperator={false}
                    showAgency={false}
                    open={showAddNewAccount}
                    handleClose={() => setShowAddNewAccount(false)}
                    onAdded={account => setAccounts([...accounts, account])}
                />
            </Box>

            <AccountChildren
                loading={loading}
                accounts={accounts}
                onDelete={account => setAccounts(accounts.filter(x => x != account))} />
        </Box>
    );
}

export default withHomeLayout(ChildAccounts);
