import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, FormControlLabel, Popover, TextField, Typography } from '@material-ui/core';
import {
    DESIGNER_SET_GRIDCOLOR, DESIGNER_SET_GRIDSHOW, DESIGNER_SET_GRIDSIZE, DESIGNER_SET_SNAPPING,
    DESIGNER_SET_BORDER_ALL_ELEMENTS_SHOW
} from '../../helpers/actionTypes';
import { useTranslation } from 'react-i18next';
import { default as BestColorPicker } from 'react-best-gradient-color-picker'
import { FormatColorFill } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DesignerEditMode from './DesignerEditMode';
import { ColorPicker } from './DesignerCSS';
import LCTypography from '../../components/material/LCTypography';
import ToolbarControl from './ToolbarControl';
import { showHideCodeEditor } from '../../actions/designer';

const DesignerBackgroundEditor = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const gridShow = useSelector(state => state.designer.gridShow);
    const gridSize = useSelector(state => state.designer.gridSize);
    const gridColor = useSelector(state => state.designer.gridColor);
    const enableSnapping = useSelector(state => state.designer.enableSnapping);
    const borderAllElementsShow = useSelector(state => state.designer.borderAllElementsShow);
    const dispatch = useDispatch();

    const codeEditorVisible = useSelector(state => state.designer.codeEditorVisible);

    const [inputValue, setInputValue] = React.useState(gridSize);

    useEffect(() => setInputValue(gridSize), [gridSize])

    return <Box p={2} style={{ height: "calc(100vh - 250px)", overflowY: "scroll" }}>
        <Box mb={2}>
            <Typography variant='subtitle2'>{t('Appearance')}</Typography>
            <ToggleButtonGroup style={{ marginTop: 8 }}>
                <ToolbarControl
                    Component={ColorPicker}
                    className="MuiToggleButtonGroup-grouped"
                    title={t('Background Color')}
                    allowGradient={true}
                    Icon={<FormatColorFill />}
                    cssKey="background"
                />
            </ToggleButtonGroup >
        </Box >

        <Typography variant='subtitle2'>{t('View')}</Typography>
        <Box display="flex">
            <FormControlLabel
                control={<Checkbox checked={gridShow} size="small"
                    onChange={() => {
                        dispatch({ type: DESIGNER_SET_GRIDSHOW, gridShow: !gridShow });
                        dispatch({ type: DESIGNER_SET_GRIDSIZE, gridSize: !gridShow ? 15 : 1 })
                    }} />}

                label={<LCTypography variant="body2">Grid</LCTypography>}
            />
            {gridShow
                && <Box display="flex" alignItems="center">
                    <Button
                        size="small"
                        style={{ padding: 0, minWidth: 28 }}
                        variant="outlined"
                        onClick={() => dispatch({ type: DESIGNER_SET_GRIDSIZE, gridSize: gridSize - 1 })}
                    >-</Button>
                    <TextField
                        style={{ marginLeft: 8, marginRight: 8, width: 32 }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={inputValue}
                        size='small'
                        type="tel"
                        autoComplete='off'
                        onChange={e => setInputValue(e.target.value)}
                        onBlur={() => dispatch({ type: DESIGNER_SET_GRIDSIZE, gridSize: +inputValue })}
                    />
                    <Button
                        size="small"
                        style={{ padding: 0, minWidth: 28 }}
                        variant="outlined"
                        onClick={() => dispatch({ type: DESIGNER_SET_GRIDSIZE, gridSize: gridSize + 1 })}
                    >+</Button>

                    <ToggleButton
                        style={{ marginLeft: 8, padding: 4 }}
                        size="small"
                        onChange={(e) => {
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <FormatColorFill style={{ fontSize: '1rem' }} />
                    </ToggleButton>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <BestColorPicker
                            value={gridColor}
                            height={150}
                            onChange={color => dispatch({ type: DESIGNER_SET_GRIDCOLOR, gridColor: color })}
                        />
                    </Popover>
                </Box>}

        </Box>
        <Box display="flex">
            <FormControlLabel
                control={<Checkbox checked={enableSnapping} size="small"
                    onChange={() => {
                        dispatch({ type: DESIGNER_SET_SNAPPING, enableSnapping: !enableSnapping });
                    }} />}

                label={<LCTypography variant="body2">Enable Snapping</LCTypography>}
            />
        </Box>

        <Box display="flex">
            <FormControlLabel
                control={<Checkbox checked={borderAllElementsShow} size="small"
                    onChange={() => {
                        dispatch({
                            type: DESIGNER_SET_BORDER_ALL_ELEMENTS_SHOW,
                            borderAllElementsShow: !borderAllElementsShow
                        });
                    }} />}

                label={<LCTypography variant="body2">Show Border on All Elements</LCTypography>}
            />
        </Box>

        <Box mt={2} mb={2}>
            <DesignerEditMode />
        </Box>
        <Box mt={2}>
            <Button
                color="primary"
                variant={codeEditorVisible ? "contained" : "outlined"}
                size="small"
                onClick={() => dispatch(showHideCodeEditor(!codeEditorVisible))}>
                {t('Code Editor')}
            </Button>
        </Box>
    </Box >
}

export default DesignerBackgroundEditor
