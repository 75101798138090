import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { RoutePaths } from '../../helpers/constants';

const AgencyTeamAccountCreatedStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    const storyData = story.story_data || {};

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} joined Lucit!`}
        story={story}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Created <> </>
            <Link
                component={RouterLink}
                color="secondary"
                to={RoutePaths.public.slug(storyData.slug)}>{storyData.name}</Link>
        </LCTypography>
    </BaseStory >;
};

export default AgencyTeamAccountCreatedStory;
