import { createSelector } from "reselect";
import { Global, ItemExportStatus } from "../helpers/constants";
import { exportSettings } from "./exports";
import { itemByIdSelector } from "./inventory";

export const inventoryStatus = state => state.inventoryStatus;

export const itemAspectRatioSelector = createSelector(
    [inventoryStatus],

    (state) => id => {
        const exportTransformerSettings = state.exportsItems
            .filter(x => x.itemId === id && isExportedOrPending(x))
            .map(x => exportSettings(state.exports[x.exportId]).transformer)
            .find(() => true) || {};

        return !isNaN(exportTransformerSettings.photo_crop_aspect_ratio)
            ? +exportTransformerSettings.photo_crop_aspect_ratio
            : Global.imageAspectDefault;
    }
)

export const exportItemsSelector = createSelector(
    [itemByIdSelector, inventoryStatus],

    // {
    //     export: {},
    //     items: [ { itemId: 1, exportId: 2 }]
    // }
    (itemByIdSelector, inventoryStatus) => id => ({
        export: inventoryStatus.exports[id] || {},
        items: inventoryStatus.exportsItems.filter(x => x.exportId === id)
            .map(x => {
                x.item = { ...inventoryStatus.items[x.itemId], ...itemByIdSelector(x.itemId) };
                return x;
            })
    })
)

export const exportItemsShouldUpdateSelector = createSelector(
    [inventoryStatus],

    state => exportId => {
        return state.exportsItems
            .filter(x => x.exportId === exportId)
            .filter(x => x.state === ItemExportStatus.exportPending || x.state === ItemExportStatus.unexportPending);
    }
)

export const exportsStatusSelector = createSelector(
    [itemByIdSelector, inventoryStatus],

    (itemByIdSelector, inventoryStatus) => id => inventoryStatus.exportsItems.filter(x => x.itemId === id)
        .map(x => {
            x.export = inventoryStatus.exports[x.exportId] || {};
            x.item = { ...x.item, ...itemByIdSelector(x.itemId) };
            return x;
        })
)

export const exportItemsWeightsSelector = createSelector(
    [inventoryStatus],

    state => exportId => state.itemsWeights.all
        .filter(id => {
            const exportItem = state.exportsItems.find(x => x.exportId === exportId && x.itemId === id);

            return exportItem && isExportedOrPending(exportItem);
        })
        .map(id => ({
            ...state.items[id],
            id,
            weight: state.itemsWeights.byId[id]
        }))
)

export const hasRequestedItems = createSelector(
    [exportItemsWeightsSelector],

    exportItemsWeightsSelector => id => {
        return exportItemsWeightsSelector(id).length > 0;
    }
)

export const isExportedOrPending = exportItem => exportItem.exported
    || (exportItem.requested && exportItem.state === ItemExportStatus.exportPending);

export const getItemWeightPercentage = (weights, weight) => {
    const totalWeights = weights.reduce((prev, curr) => prev + curr, 0);

    return Number((100 * weight / totalWeights).toFixed(1))
}
