import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { DESIGNER_FORMAT_PASTE, DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

export const PasteFormatDialog = ({ open, handleClose }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [pasteFormatAllSizes, setPasteFormatAllSizes] = useState(false);

    return (
        <>
            <GenericDialog
                title={t('Paste Format')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: true,
                    maxWidth: 'sm'
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<Grid container spacing={5}>
                    <Grid item xs={12}>
                        <LCTypography>
                            While in individual edit mode, you can paste a format either only on this screen format size,
                            or across all screen format sizes.
                        </LCTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <IonList>
                            <IonRadioGroup
                                value={pasteFormatAllSizes} onIonChange={e => setPasteFormatAllSizes(e.detail.value)}
                            >
                                <IonItem onclick={() => setPasteFormatAllSizes(false)}>
                                    <IonLabel class="ion-text-wrap">
                                        <p>This element on this screen format size only</p>
                                    </IonLabel>
                                    <IonRadio
                                        value={false}
                                        slot="end"
                                    ></IonRadio>
                                </IonItem>
                                <IonItem onclick={() => setPasteFormatAllSizes(true)}>
                                    <IonLabel class="ion-text-wrap">
                                        <p>Paste on this element across all screen format sizes</p>
                                    </IonLabel>
                                    <IonRadio
                                        value={true}
                                        slot="end"
                                    ></IonRadio>
                                </IonItem>
                            </IonRadioGroup>

                        </IonList>
                    </Grid>

                </Grid>}

                ActionsComponent={<>
                    <Button onClick={() => {

                        if (pasteFormatAllSizes) {
                            dispatch({ type: DESIGNER_FORMAT_PASTE, allSizes: true })
                            dispatch({ type: DESIGNER_PUSH_HISTORY });
                        }
                        else {
                            dispatch({ type: DESIGNER_FORMAT_PASTE })
                            dispatch({ type: DESIGNER_PUSH_HISTORY });
                        }

                        handleClose();
                    }}
                        color="primary">
                        {t('Apply')}
                    </Button>
                </>}
            />
        </>
    )
}
