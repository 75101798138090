import React from 'react';
import { Box, Grid, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import clx from 'classnames';
import BillboardThumbnail from './BillboardThumbnail';
import BillboardPullIndicator from './BillboardPullIndicator';
import { LucitXrIcon, StreetViewIcon } from '../../components';
import { ImageStyle, StreetViewStatus } from '../../helpers/constants';
import { BillboardPlayIndicator } from '.';

const useStyles = makeStyles(theme => {
    return {
        rootWithActions: {
            [theme.breakpoints.up('sm')]: {
                paddingRight: 72
            }
        },
        indicators: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: 'auto',
                width: 60
            }
        },
        screenListItem: {
            flexWrap: 'nowrap',
        }
    }
})

function BillboardListItem({ board, last_pull_datetime, last_play_datetime, StartIcon, Actions, showIndicators = true }) {
    const classes = useStyles();

    return <ListItem disableGutters className={clx({ [classes.rootWithActions]: Boolean(Actions) })}>
        {StartIcon}
        <Grid container className={classes.screenListItem} alignItems='center'>
            <Grid item xs={12} sm="auto" style={{ flex: 1 }}>
                <ListItemText
                    id={board.id}
                    primary={<>
                        {board.name}
                    </>}
                    primaryTypographyProps={{ style: { display: 'flex' } }}
                    secondary={<><strong>{board.board_identifier}</strong> : {board.location.city}</>}
                />
            </Grid>
            {board.digital_board_to_images && board.digital_board_to_images.some(i => i.style === ImageStyle.normal)
                && <Grid item style={{ marginLeft: 12 }}>
                    <Box ml={1}> <LucitXrIcon width={24} height={24} /></Box>
                </Grid>}

            {board.street_view_status === StreetViewStatus.active
                && <Grid item style={{ marginLeft: 12 }}>
                    <Box ml={1}> <StreetViewIcon width={24} height={24} /></Box>
                </Grid>}

            <Grid item className={classes.indicators} style={{ marginLeft: 12 }}>
                <BillboardThumbnail board={board} />
            </Grid>

            {showIndicators
                && <>
                    <Grid item style={{ marginLeft: 12 }}>
                        {last_pull_datetime
                            ? <BillboardPullIndicator pull_datetime={last_pull_datetime} />
                            : <Box width={20} height={20} />}
                    </Grid>
                    <Grid item style={{ marginLeft: 12 }}>
                        {last_play_datetime
                            ? <BillboardPlayIndicator play_datetime={last_play_datetime} />
                            : <Box width={20} height={20} />}
                    </Grid>
                </>}
        </Grid>

        {Actions
            && <ListItemSecondaryAction>
                {Actions}
            </ListItemSecondaryAction>}
    </ListItem>;
}

const mapStateToDispatch = () => {
    return {
    }
}

export default connect(
    null,
    mapStateToDispatch
)(
    BillboardListItem
)
