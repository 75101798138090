import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { GridList, GridListTile, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LCTypography from '../../components/material/LCTypography';
import { searched } from '../../selectors/approvals';
import { saveApprovalsStatus, refresh, loadPage } from '../../actions/approvals';
import { useInfiniteScroll } from '../../helpers/hooks/useInfiniteScroll';
import CreativeCard from './CreativeCard';
import ButtonLoader from '../../components/material/ButtonLoader';
import { ApprovalStatus, Global } from '../../helpers/constants';
import { ScrollContext } from '../../contexts';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useWidth } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        creativeTile: {
            padding: theme.spacing(1.5),
            display: 'flex',
            justifyContent: 'center'
        }
    }
})

const colsMap = {
    "xs": 1,
    "sm": 1,
    "md": 2,
    "lg": 4,
    "xl": 5
}

function CreativeList(props) {
    const { filters, approvalGroups, loading, submitApproved,
        loadingNextPage, nextPage, hasNextPage, loadPage } = props;
    const classes = useStyles();
    const scrollContext = useContext(ScrollContext).current;
    const width = useWidth();
    const cols = colsMap[width];
    const [submitting, setSubmitting] = useState(false);

    const infiniteScrollEnabled = filters.status !== ApprovalStatus.pending.id;

    useInfiniteScroll({
        isLoading: loadingNextPage,
        hasMoreItems: infiniteScrollEnabled
            && hasNextPage,
        loadMoreItems: () => loadPage(nextPage),
        threshold: Global.nextPageLoadThresold,
        getRef: () => scrollContext.ref
    })

    return (
        <Box display="flex" flexDirection="column">
            {loading
                && <Box m={1} mt={2} display="flex" justifyContent="center">
                    <CircularProgressCentered size={40} />
                </Box>}

            <GridList
                cols={cols}
                cellHeight={'auto'}>
                {approvalGroups.map(group => <GridListTile
                    key={JSON.stringify(group.approvals.map(a => a.inventory_photo_id))}
                    classes={{ tile: classes.creativeTile }}>

                    <CreativeCard
                        approvalGroup={group}
                        agency={group.agency}
                        account={group.account}
                        user={group.user}
                        created_by_user={group.created_by_user}
                        inventoryItem={group.inventoryItem}
                    />

                </GridListTile>)}
            </GridList>

            {!approvalGroups.length
                && searched
                && !loading
                && <Box>
                    <LCTypography variant="h5">No creatives</LCTypography>
                </Box>}

            {loadingNextPage
                && <Box m={1} mt={2} display="flex" justifyContent="center">
                    <CircularProgressCentered size={40} />
                </Box>}

            {approvalGroups.length > 0
                && filters.status === ApprovalStatus.pending.id
                && <Box mt={2} display="flex" justifyContent="center" alignItems="center" position="relative">
                    <ButtonLoader
                        submitting={submitting}
                        onClick={() => {
                            setSubmitting(true);
                            submitApproved(approvalGroups)
                                .then(() => {
                                    const scrollRef = scrollContext.ref;

                                    scrollRef.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    })
                                })
                                .finally(() => setSubmitting(false));
                        }}
                        variant="contained"
                        color="primary">
                        Approve
                    </ButtonLoader>
                </Box>}
        </Box>
    );
}

CreativeList.propTypes = {
}

const mapStateToProps = state => {
    return {
        approvalGroups: state.approvals.data,

        loading: state.approvals.loading,
        loadingNextPage: state.approvals.loadingNextPage,

        filters: state.approvals.filters,

        hasNextPage: state.approvals.currentPage < state.approvals.lastPage,
        nextPage: state.approvals.currentPage + 1
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadPage: (page) => dispatch(loadPage(page)),
        submitApproved: (approvalGroups) => {
            const approvedGroups = approvalGroups.filter(group =>
                group.approvals.every(approval => approval.action === ApprovalStatus.ok.id));

            return dispatch(saveApprovalsStatus(approvedGroups, null, ""))
                .then(() => dispatch(refresh()))
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    CreativeList
);
