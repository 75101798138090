/* eslint-disable */
import { getPlatforms, isPlatform } from "@ionic/react";
import { v4 as uuidv4 } from 'uuid';
import { DEVICE_ID_LCUID_PREFIX } from "./constants";

const packageFile = require('../../package.json');
const prefix = "REACT_APP_";

export function isDevelopment() {
    return process.env.NODE_ENV === 'development';
}

export function apiIsProduction() {
    return process.env.REACT_APP_API_BASE_URL === 'https://api.lucit.app/api/v2';
}


export function getFacebookAppId() {
    return process.env.REACT_APP_FB_APP_ID ?? "";
}

export function getAppName() {
    return process.env.REACT_APP_WHITE_LABEL_APP_NAME ?? "Lucit";
}

export function whiteLabelCode() {
    return process.env.REACT_APP_WHITE_LABEL_CODE;
}

export function getAppLogoUrl() {

    return process.env.REACT_APP_WHITE_LABEL_LOGO_URL ?? '/assets/images/LucitLogoWhiteOnTransparent.png';
}

export function getAppPalette() {
    return process.env.REACT_APP_WHITE_LABEL_PALETTE ? JSON.parse(process.env.REACT_APP_WHITE_LABEL_PALETTE) : {
        primary: { main: '#000000', light: '#5c5c5c', dark: '#0c0c0c' },
        secondary: { main: '#02c9c9', light: '#63fdfc', dark: '#009898' },
        error: { main: '#f07c75', light: '#ffada4', dark: '#ba4d49' },
        header: { background: '#000000', text: '#02c9c9' }
    };
}

/**
 * Turns the string "true" or "false" into a boolean value
 *
 * Later we can build support for other types
 */
const convertEnvValsTyped = (value) => {
    if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    } else {
        return value;
    }
}

/**
 * When you run npm start, it is equal to `development`,
 * when you run npm test it is equal to `test`,
 * and when you run npm run build it is equal to `production`.
 * @export
 */
export const environment = {
    ...Object.keys(process.env)
        .filter(key => key.startsWith(prefix))
        .reduce((prev, next) => {
            const value = process.env[next];
            prev[next.replace(prefix, '')] = convertEnvValsTyped(value)
            return prev;

        }, {}),

    GOOGLE_API_KEY: isPlatform('android')
        ? process.env.REACT_APP_GOOGLE_API_KEY_ANDROID
        : isPlatform('ios')
            ? process.env.REACT_APP_GOOGLE_API_KEY_IOS
            : process.env.REACT_APP_GOOGLE_API_KEY_BROWSER,
    VERSION: packageFile.version,
    LC_DEVICE_ID: getOrSetDeviceId()
};

export function getCurrentPlatform() {
    return getPlatforms()
        .filter(platform => isPlatform(platform))
        .join(', ');
}

export function getOrSetDeviceId() {

    if (localStorage.getItem('lc_device_id')) {

        const currentDeviceId = localStorage.getItem('lc_device_id')

        if (currentDeviceId.slice(0, 6) === 'LCUID-') {
            return currentDeviceId;
        }
        //We are going to recycle the old ids for new ones with the right prefix
    }

    const deviceId = 'LCUID-' + DEVICE_ID_LCUID_PREFIX + '-' + uuidv4()

    localStorage.setItem('lc_device_id', deviceId)

    return deviceId;
}

export function getBrowserInfo() {
    var userAgent = navigator.userAgent;
    var browserName = navigator.appName;

    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset = userAgent.indexOf("OPR/")) != -1) {
        browserName = "Opera";
        fullVersion = userAgent.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = userAgent.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = userAgent.substring(verOffset + 6);
        if ((verOffset = userAgent.indexOf("Version")) != -1)
            fullVersion = userAgent.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = userAgent.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = userAgent.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = userAgent.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = userAgent.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = userAgent.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = userAgent.substring(verOffset + 7);
        if ((verOffset = userAgent.indexOf("Version")) != -1)
            fullVersion = userAgent.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = userAgent.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = userAgent.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = userAgent.lastIndexOf(' ') + 1) <
        (verOffset = userAgent.lastIndexOf('/'))) {
        browserName = userAgent.substring(nameOffset, verOffset);
        fullVersion = userAgent.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
        browserName,
        fullVersion,
        majorVersion,
        appName: navigator.appName,
        userAgent: navigator.userAgent
    };
}
