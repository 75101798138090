import { DASHBOARD_ACTIVE_ADS, DASHBOARD_ACTIVE_ADS_REQUEST, DASHBOARD_EXPORTS_REQUEST } from "../helpers/actionTypes";
import { selectedAccountId } from "../selectors/user";
import { lucitApi } from "../services/lucitApi";
import { exportsGetResult, exportsSearchResult } from "./exports";
import { feedsSearchResult } from "./feeds";

export function getAds() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({ type: DASHBOARD_ACTIVE_ADS_REQUEST })

        const accountId = selectedAccountId(state)

        return lucitApi.campaigns.getAds({ accountId })
            .then((data) => dispatch({
                type: DASHBOARD_ACTIVE_ADS,
                data
            }));
    }
}

export function getExports() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({ type: DASHBOARD_EXPORTS_REQUEST })

        return lucitApi.exports.getActiveExports(
            {
                accountId: selectedAccountId(state),
                per_page: 1000
            }
        ).then(result => {
            dispatch(exportsSearchResult(result));
            dispatch(exportsGetResult(result.data));

            return result.data;
        });
    }
}

export function getFeeds() {
    return (dispatch, getState) => {
        const state = getState();

        const filters = {
            active: 1,
            accountId: selectedAccountId(state),
            per_page: 1000
        }

        return lucitApi.getFeeds(filters)
            .then(result => {
                dispatch(feedsSearchResult(result));

                return result.data;
            });
    }
}
