import {
    Button, makeStyles, Box, Grid,
    Link, FormControlLabel, Checkbox
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
    createTemplate, duplicateTemplate,
    loadAllBoardFormats, loadBoardFormats, resetState
} from '../../actions/designer';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { ButtonLoader, CustomCard } from '../../components';
import { selectedAccount, selectedAgency, selectedProfile } from '../../selectors/user';
import { history } from '../../helpers/history';
import {
    addBoardFormat, removeBoardFormat
} from '../../actions/designer';
import LCTypography from '../../components/material/LCTypography';
import * as DesignerSelectors from '../../selectors/designer';
import * as CampaignSelectors from '../../selectors/exports';
import BoardFormatToggleButton from '../../containers/billboards/BoardFormatToggleButton'
import { InventoryItemClasses, ProfileType, RoutePaths } from '../../helpers/constants';
import { DESGINER_SET_ITEM_CLASS } from '../../helpers/actionTypes';
import InventoryItemClassIcon from '../inventory/InventoryItemClassIcon';
import { lucitApi } from '../../services/lucitApi';
import { groupBy } from '../../helpers/array';
import { DesignerTemplateList } from './DesignerTemplateList';
import { getAccountScreens } from '../../actions/account';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        },
        cardSmaller: {
            flexDirection: 'row'
        }
    }
});

const DesignerNewTemplateDialog = ({ open, handleClose }) => {
    const [type, setType] = useState(null);
    const [variant, setVariant] = useState('New');
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stepNumber, setStepNumber] = useState(0);
    const loadingTemplates = useSelector(state => state.designer.templatesLoading);
    const hasTemplates = useSelector(state => state.designer.templates.length > 0);
    const boardFormats = useSelector(DesignerSelectors.templateBoardFormats);
    const inventoryItemClasses = useSelector(state => state.designer.inventory_item_class)
    const { t } = useTranslation();

    const steps = useMemo(() => ([
        {
            profileType: ProfileType.agency,
            title: t('Which Inventory type are you going to post using this template?'),
            isFinish: !hasTemplates,
            disabled: (boardFormats && boardFormats.length == 0)
                || loadingTemplates
                || inventoryItemClasses.length < 2
        },
        {
            profileType: ProfileType.account,
            title: t('How are you going to use this template?'),
            //If user has no other templates, we don't need to show the next step
            isFinish: type == 'FullSize' || !hasTemplates,
            disabled: (boardFormats && boardFormats.length == 0)
                || type == null
                || loadingTemplates
        },
        {
            title: t('How do you want to start?'),
            isFinish: variant == 'New'
        },
        {
            title: t('Choose a template to duplicate'),
            isFinish: true,
            disabled: selectedTemplateId == null
        }
    ]), [type, variant, boardFormats, loadingTemplates, hasTemplates, selectedTemplateId,
        inventoryItemClasses.length]);

    const profile = useSelector(selectedProfile);
    const account = useSelector(selectedAccount);
    const classes = useStyles();
    const dispatch = useDispatch();
    const step = steps.filter(x => x.profileType == null
        || x.profileType == profile.type)[stepNumber];

    useEffect(() => {
        dispatch(resetState())
        setStepNumber(0);
        setType(null);
        setVariant('New')
    }, [open])

    useEffect(() => {
        if (variant == 'New')
            setSelectedTemplateId(null)
    }, [variant])

    useEffect(() => {
        if (account)
            dispatch(getAccountScreens(account))
    }, [account])

    return (<>
        <GenericDialog
            title={t(step.title)}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}
            ContentComponent={<Box pt={2}>
                {stepNumber == 0
                    && <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TemplateTypeSelector type={type} setType={setType} />
                        </Grid>
                        <Grid item xs={12}>
                            <BoardSelector />
                        </Grid>
                    </Grid>}

                {stepNumber == 1
                    && <BlankOrExistingSelector variant={variant} setVariant={setVariant} />}

                {stepNumber == 2
                    && <TemplateSelector
                        selectedTemplateId={selectedTemplateId}
                        setSelectedTemplateId={setSelectedTemplateId}
                    />}
            </Box>}

            ActionsComponent={<>
                {stepNumber == 0
                    ? <Button onClick={handleClose}
                        color="primary">
                        {t('Cancel')}
                    </Button>
                    : <Button onClick={() => setStepNumber(x => x - 1)}
                        color="primary">
                        {t('Back')}
                    </Button>}

                {step.isFinish
                    ? <ButtonLoader
                        disabled={step.disabled}
                        submitting={loading}
                        onClick={() => {
                            setLoading(true);
                            selectedTemplateId
                                ? dispatch(duplicateTemplate(selectedTemplateId))
                                    .then(x => history.push({ pathname: `/designer/${x.lcuid}` }))
                                    .finally(() => setLoading(false))
                                : dispatch(createTemplate())
                                    .then(x => history.push({ pathname: `/designer/${x.lcuid}` }))
                                    .finally(() => setLoading(false))
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        {t('Create')}
                        <br />
                    </ButtonLoader>
                    : <Button disabled={step.disabled}
                        color="secondary"
                        variant="contained"
                        onClick={() => setStepNumber(x => x + 1)}>
                        {t('Next')}
                    </Button>}
            </>}
        />
    </>)
}

const TemplateTypeSelector = ({ type, setType }) => {
    const profile = useSelector(selectedProfile);
    const { t } = useTranslation();
    const [classesMap, setClassesMap] = useState({});
    const [allowManualPosting, setAllowManualPosting] = useState(true);
    const canAttachDataSource = itemClass => classesMap[itemClass] && classesMap[itemClass].can_be_created_by_data_sources;

    useEffect(() => {
        lucitApi.inventory.getInventoryItemClasses()
            .then(classes => {
                const classesMap = classes.reduce((prev, curr) => ({ ...prev, [curr.class]: curr }), {});
                setClassesMap(classesMap)
            })
    }, [])

    let inventoryTypes = [];

    if (profile.type == ProfileType.account) {
        inventoryTypes = profile.inventory_item_class.filter(canAttachDataSource)
            .map(itemClass => ({
                type: itemClass,
                class: itemClass,
                classes: [itemClass, InventoryItemClasses.photoStream.class],
                title: <>{classesMap[itemClass].class_description}</>,
            }));
    }

    if (profile.type == ProfileType.agency) {
        const classes = Object.values(classesMap).filter(x => x.can_be_created_by_data_sources);
        const groups = Object.values(groupBy(classes, x => x.group_class));

        inventoryTypes = groups.map(g => ({
            type: g[0].group_class,
            classes: allowManualPosting
                ? [...g.map(x => x.class), InventoryItemClasses.photoStream.class]
                : g.map(x => x.class),
            title: <>{g[0].group_class_description}</>,
        }))
    }

    const templateTypes = useMemo(() => ([
        {
            classes: [InventoryItemClasses.photoStream.class],
            title: t('Post Template'),
            imageUrl: '/assets/images/studio/generic_template_icon.png',
            type: 'Generic',
            description: t('Design a generic template that usually includes a dynamic photo and caption that '
                + 'you can use to create multiple ads.')
        },
        {
            classes: [InventoryItemClasses.creative.class],
            title: t('Complete Creative'),
            imageUrl: '/assets/images/studio/static_template_icon.png',
            type: 'FullSize',
            description: t('Design an ad without dynamic elements that you will usually post once but can be posted multiple times.')
        },
        {
            classes: inventoryTypes.length > 0
                ? [inventoryTypes[0].class, InventoryItemClasses.photoStream.class]
                : [],
            title: t('Data Connected Template'),
            imageUrl: '/assets/images/studio/data_connected_template_icon.png',
            type: 'DataConnected',
            description: t('Design a template with dynamic elements that a data source will use to automatically create ads.'),
        }
    ]), [inventoryTypes]);

    return <>
        <Box>
            <Grid container spacing={1}>
                {profile.type == ProfileType.account
                    && templateTypes.map(x => <Grid key={x.type} item xs={12} sm={4}>
                        <ItemCard
                            key={x.type}
                            item={x}
                            isSelected={x.type == type}
                            select={item => setType(item.type)}
                        />
                    </Grid>)}

                {(type == 'DataConnected' || profile.type == ProfileType.agency)
                    && <InventoryTypeSelector inventoryTypes={inventoryTypes} profile={profile} />}

                {profile.type == ProfileType.agency
                    && <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={allowManualPosting} onChange={(_, x) => setAllowManualPosting(x)} />}
                            label="Allow manual posting?"
                        />
                    </Grid>}
            </Grid>
        </Box>
    </>
}

const InventoryTypeSelector = ({ inventoryTypes, profile }) => {
    const designer = useSelector(state => state.designer);
    const classes = useStyles();

    if (inventoryTypes.length === 1) {
        return <Box></Box>;
    }

    return <>
        {profile.type == ProfileType.account
            && <Grid item xs={12}>
                <LCTypography variant="h6">Data Connected Template</LCTypography>
            </Grid>}
        {inventoryTypes.map(x => <Grid key={x.type} item xs={12} sm={3}>
            <ItemCard
                key={x.type}
                item={x}
                isSelected={x.classes[0] == designer.inventory_item_class[0]}
                select={() => { }}
                className={classes.cardSmaller}
            />
        </Grid>)}
        {inventoryTypes.length == 0
            && <Grid item xs={12}>
                <Box ml={3} mt={2}>
                    <LCTypography variant="body2">
                        If you do not yet have your data source setup, you may edit your <strong>
                            Account Inventory Types
                        </strong> in your account settings to select a type of data that this account is expected to have
                    </LCTypography>
                </Box>
                <Box ml={3} mt={2}>
                    <LCTypography color="secondary" variant="body2">
                        <Link color="inherit" component={RouterLink} to={RoutePaths.accountSettings}>
                            Click here to add data source apps or edit your account inventory types
                        </Link>
                    </LCTypography>
                </Box>
            </Grid>}
    </>
}

const BoardSelector = () => {
    const agency = useSelector(selectedAgency);
    const account = useSelector(selectedAccount);
    const dispatch = useDispatch();

    const boardFormats = useSelector(DesignerSelectors.boardFormats);
    const hasBoardFormat = useSelector(state => code => DesignerSelectors.hasBoardFormat(state, code))
    const hasCampaignWithBoardFormatCode = useSelector(state => code => CampaignSelectors.hasCampaignWithBoardFormatCode(state, code))

    /**
       * List of board formats that belong to this operator
       */
    useEffect(() => {
        if (agency)
            dispatch(loadBoardFormats(agency.id))
        else if (account)
            dispatch(loadBoardFormats(account.agency_id))
        else
            dispatch(loadAllBoardFormats())
    }, [agency, account])

    useEffect(() => {
        Object.keys(boardFormats)
            .filter(hasCampaignWithBoardFormatCode)
            .forEach(code => dispatch(addBoardFormat(code)));
    }, [Object.keys(boardFormats).length])

    return <>
        <Box mt={1} mb={1}>
            <LCTypography variant="h6">Board Formats</LCTypography>
            <LCTypography variant="body2">Choose the size(s) for this template</LCTypography>
        </Box>
        <Box>
            {Object.keys(boardFormats).map((code, index) => {
                return (
                    <BoardFormatToggleButton
                        key={index}
                        boardFormatCode={code}
                        boardFormat={boardFormats[code]}
                        isSelected={hasBoardFormat(code)}
                        hasCampaignWithBoardFormatCode={hasCampaignWithBoardFormatCode(code)}
                        onClick={() => {
                            if (hasBoardFormat(code)) {
                                dispatch(removeBoardFormat(code))
                            } else {
                                dispatch(addBoardFormat(code))
                            }
                        }}
                    />
                )
            })}
        </Box>
    </>
}

const BlankOrExistingSelector = ({ variant, setVariant }) => {
    const { t } = useTranslation();
    const variants = useMemo(() => ([{
        title: t('New'),
        description: t('Create a new template from scratch on a blank canvas')
    }, {
        title: t('Start with an Existing Template'),
        description: t('Create a template by starting with an existing template and modifying it')
    }]), [])

    return <Box mb={4}>
        <Grid container spacing={1}>
            {variants.map(x => (
                <Grid key={x.title} item xs={6}>
                    <CustomCard
                        title={x.title}
                        description={x.description}
                        isSelected={variant == x.title}
                        select={() => setVariant(x.title)}
                    />
                </Grid>
            ))}
        </Grid>
    </Box >
}

const TemplateSelector = ({ selectedTemplateId, setSelectedTemplateId }) => {
    return <Box>
        <Box mb={4}>
            <LCTypography variant="body2">
                Select from one of the following to create a new design based on an existing template
            </LCTypography>
        </Box>
        <DesignerTemplateList
            width="lg"
            compact={true}
            onClick={template => setSelectedTemplateId(template.lcuid)}
            isSelected={template => template.lcuid == selectedTemplateId}
        />
    </Box>
}

const ItemCard = ({ item, isSelected, select, className }) => {
    const dispatch = useDispatch();

    return <CustomCard
        title={item.title}
        description={item.description}
        imageUrl={item.imageUrl}
        Icon={item.imageUrl == null
            && <InventoryItemClassIcon itemClass={item.classes[0]} style={{ width: '100%', height: '100%' }} />}
        isSelected={isSelected}
        select={() => {
            select(item);
            dispatch({
                type: DESGINER_SET_ITEM_CLASS,
                inventory_item_class: item.classes
            });
        }}
        className={className}
    />
}

export default withRouter(DesignerNewTemplateDialog)
