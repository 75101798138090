import { LOCATION_GETBYID, LOCATION_NEW_STREET_VIEW, LOCATION_UPDATE } from "../helpers/actionTypes";
import { isLocationLoadedSelector, locationByIdSelector } from "../selectors/locations";
import { lucitApi } from "../services/lucitApi"
import { isLoggedIn } from "../selectors/user";

export function getLocationById(id) {
    return (dispatch, getState) => {
        const state = getState();

        const isAuthenticated = isLoggedIn(state);

        if (isLocationLoadedSelector(state)(id)) {
            // eslint-disable-next-line no-undef
            return Promise.resolve(locationByIdSelector(state)(id));
        }

        return lucitApi.locations.getById(id, isAuthenticated)
            .then(data => {
                dispatch({
                    type: LOCATION_GETBYID,
                    data
                });

                return data;
            });
    }
}

export const createStreetViewLocation = (location, position, heading, pitch, zoom, boardId, backupImageId) => {
    return (dispatch) => {
        const prefix = "Street View for ";

        location.name = location.name.startsWith(prefix)
            ? location.name
            : prefix + location.name

        return lucitApi.locations.createStreetView(location, position, heading, pitch, zoom, boardId, { backup_image_id: backupImageId })
            .then(data => {
                dispatch({
                    type: LOCATION_NEW_STREET_VIEW,
                    data
                });

                return data;
            });
    }
}

export const createStreetViewSnapshotLocation = (location, position, heading, pitch, zoom, boardId) => {
    return () => {
        const prefix = "Street View Snapshot for ";

        location.name = location.name.startsWith(prefix)
            ? location.name
            : prefix + location.name

        return lucitApi.locations.createStreetViewSnapshot(location, position, heading, pitch, zoom, boardId);
    }
}

export const setLocationCoords = (location) => {
    return dispatch => {
        return lucitApi.locations.setCoordinates(location)
            .then(location => {
                dispatch({
                    type: LOCATION_UPDATE,
                    location
                })
            })
    }
}
