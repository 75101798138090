import React, { useState } from 'react';
import { withRouter } from 'react-router';
import clx from 'classnames';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { InputBase, IconButton, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import { getUser } from '../../selectors/user';
import { debouncePromise } from '../../helpers/async';
import { getSearch, getSearchResults } from '../../selectors/globalSearch';
import { Global, QueryString, RoutePaths } from '../../helpers/constants';
import { globalSearch } from '../../actions/globalSearch';
import { useHistory } from 'react-router';
import SearchResultListItem from './SearchResultListItem';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';

const autocompleteMaxWidth = 900;
const useStyles = makeStyles(theme => ({
    autocompleteRoot: {
        width: '100%',
        maxWidth: autocompleteMaxWidth
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearIcon: {
        fill: theme.palette.common.white
    },
    endAdorment: {
        width: theme.spacing(5),
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        height: 38,
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(1, 5, 1, 7),
    },

    popperRoot: {
        zIndex: theme.zIndex.drawer + 2,
    },

    listBoxRoot: {
        maxHeight: '40vh',

        [theme.breakpoints.up('md')]: {
            maxHeight: '60vh',
        },
    },
    listBoxSubHeader: {
        backgroundColor: theme.palette.common.white,
        top: theme.spacing(-1)
    },
    disabled: {
        cursor: "not-allowed"
    }
}))

const SearchAutocomplete = (props) => {
    const { user, handleSearch, searchResults, loading } = props;
    const [inputValue, setInputValue] = useState('');
    const classes = useStyles();
    const propsClasses = props.classes || {};
    const history = useHistory();

    const canMakeGlobalSearch = inputValue && inputValue.length >= Global.globalSearchMinCharacters;
    const { t } = useTranslation();

    return (
        <Autocomplete
            id="search"
            className={clx(classes.autocompleteRoot, propsClasses.autocompleteRoot)}
            options={canMakeGlobalSearch
                ? searchResults
                : []}
            noOptionsText={canMakeGlobalSearch
                ? `Nothing was found for ${inputValue}...`
                : `Type more characters to search...`}
            openOnFocus={true}
            getOptionLabel={option => option.title}
            filterOptions={options => options}
            disabled={!user.isOnline}
            classes={{ listbox: classes.listBoxRoot }}
            PopperComponent={(rest) => <Popper {...rest}
                className={clx(classes.popperRoot, propsClasses.popperRoot)}
                placement="bottom"
                {...props.PopperProps} />}
            loading={loading}
            inputValue={inputValue}
            onChange={(e, option) => {
                if (option) {
                    setInputValue('');

                    // https://lch.lcdevops.com/T3818
                    // Handle case when user select option by pressing "Enter"
                    // We should manually redirect to item
                    if (e.type === "keydown") {
                        history.push({
                            ...location,
                            pathname: option.route
                                || RoutePaths.public.slug(option.target_slug || option.target_lcuid)
                        })
                    }
                }
            }}
            onInputChange={(e) => {
                if (!e) return;

                const newValue = e.target.value;

                if (newValue === inputValue) return;

                setInputValue(e.target.value);

                // Search only if value length is more than {Global.globalSearchMinCharacters} character, to reduce amount of found items
                if (newValue && newValue.length >= Global.globalSearchMinCharacters) {
                    handleSearch(e.target.value)
                }
            }}
            renderOption={option => <SearchResultListItem item={option} />}
            renderInput={params => {
                return <div className={clx(classes.search, propsClasses.search)}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        ref={params.InputProps.ref}
                        placeholder={t('Search...')}
                        classes={{
                            root: clx(classes.inputRoot, propsClasses.inputRoot),
                            input: clx(classes.inputInput, propsClasses.inputInput, { [classes.disabled]: !user.isOnline }),
                        }}
                        inputProps={{ 'aria-label': 'search', ...params.inputProps }}

                        onKeyPress={e => {
                            if (e.key === "Enter" && inputValue) {
                                history.push(RoutePaths.searchResults + `?${QueryString.search}=${inputValue}`)
                            }
                        }}
                    />
                    <div className={classes.endAdorment} >
                        {loading && <CircularProgressCentered size={21} />}
                        {
                            !loading
                            && inputValue
                            && <IconButton
                                size="small"
                                onClick={() => setInputValue("")}>
                                <ClearIcon className={classes.clearIcon} fontSize="small" />
                            </IconButton>
                        }
                    </div>
                </div>;
            }}
        />
    );
}

const mapStateToProps = state => {
    const search = getSearch(state);
    const searchResults = getSearchResults(state);

    return {
        loading: search.loading,
        searchResults,
        user: getUser(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleSearch: debouncePromise(inputValue => dispatch(globalSearch(inputValue)), 300)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchAutocomplete)
);
