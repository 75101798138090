import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { Route, Switch, useRouteMatch } from 'react-router';
import {
    ApplicationGeneral, ApplicationCapabilities,
    ApplicationPermissions, ApplicationTokens, ApplicationUsers
} from '../../containers/applications';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';
import { Box } from '@material-ui/core';
import { NotFound } from '../common/NotFoundPage';
import { getApplication } from '../../actions/applications';

const Tabs = {
    General: {
        id: "General",
        path: url => `${url}`,
        exact: true,
        Component: ApplicationGeneral
    },
    Users: {
        id: "Users",
        path: url => `${url}/users`,
        Component: ApplicationUsers
    },
    Capabilities: {
        id: "Capabilities",
        path: url => `${url}/capabilities`,
        Component: ApplicationCapabilities
    },
    Permissions: {
        id: "Permissions",
        path: url => `${url}/permissions`,
        Component: ApplicationPermissions
    },
    Tokens: {
        id: "Tokens",
        path: url => `${url}/tokens`,
        Component: ApplicationTokens
    }
}

function Application({ getApplication, applicationById }) {
    const { path, url, params } = useRouteMatch();
    const { pathname } = useLocation();
    const lcuid = params.lcuid;
    const application = applicationById(lcuid);

    const defaultTab = Object.values(Tabs).find(t => t.path(url) == pathname);

    const [value, setValue] = useState((defaultTab || Tabs.General).id);
    const history = useHistory();

    useEffect(() => {
        getApplication(lcuid);
    }, [lcuid])

    if (application == null) {
        return <NotFound />
    }

    return (
        <>
            <PageTitle title={application.name} />
            <IonSegment value={value} onIonChange={e => setValue(e.target.value)}>
                {Object.values(Tabs).map(t => (
                    <IonSegmentButton key={t.id} value={t.id}
                        onClick={() => history.push({ pathname: t.path(url) })}
                        style={{ minWidth: 'unset' }}>
                        <IonLabel>{t.id}</IonLabel>
                    </IonSegmentButton>))}
            </IonSegment>

            <Box >
                <Switch>
                    {Object.values(Tabs).map(t => (
                        <Route key={t.id} path={t.path(path)} exact={t.exact}>
                            <t.Component application={application} />
                        </Route>))}
                </Switch>
            </Box>

            <ButtonScrollToTopFab />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        applicationById: lcuid => state.applications.data.find(a => a.lcuid === lcuid)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApplication: lcuid => dispatch(getApplication(lcuid))
    }
}

const ApplicationWithoutLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Application);

export { ApplicationWithoutLayout }
export default withHomeLayout(ApplicationWithoutLayout);
