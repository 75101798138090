import {
    OBJECT_ATTACHMENTS,
    OBJECT_COMMENTS, OBJECT_DELETE_COMMENT, OBJECT_DELETE_REACTION, OBJECT_DELETE_REACTION_REQUEST, OBJECT_ITEM,
    OBJECT_NEW_COMMENT, OBJECT_NEW_REACTION, OBJECT_NEW_REACTION_REQUEST, OBJECT_REACTIONS, OBJECT_UPDATE_COMMENT
} from "../helpers/actionTypes";
import { ReactionClass } from "../helpers/constants";
import { getClass, parseClass } from "../helpers/lcuid";
import { isLoggedIn } from "../selectors/user";
import { lucitApi } from "../services/lucitApi";

export function getObject(lcuid) {
    return (dispatch, getState) => {
        const isAuth = isLoggedIn(getState());

        return lucitApi.objects.get(lcuid, isAuth)
            .then(data => {
                dispatch({
                    type: OBJECT_ITEM,
                    lcuid,
                    object: data.object,
                    object_class: getClass(data.object_class)
                });

                return data;
            })
    }
}

export function getAttachments(lcuid) {
    return (dispatch) => {
        return lucitApi.objects.getAttachments(lcuid)
            .then(attachments => dispatch({
                type: OBJECT_ATTACHMENTS,
                lcuid,
                objectClass: parseClass(lcuid),
                attachments
            }));
    }
}

export function getComments(lcuid) {
    return (dispatch) => {
        return lucitApi.objects.getComments(lcuid)
            .then(data => dispatch({
                type: OBJECT_COMMENTS,
                lcuid,
                objectClass: parseClass(lcuid),
                ...data
            }));
    }
}

export function getReactions(lcuid) {
    return (dispatch, getState) => {
        const isAuth = isLoggedIn(getState());

        return lucitApi.objects.getReactions(lcuid, isAuth)
            .then(data => dispatch({
                type: OBJECT_REACTIONS,
                lcuid,
                objectClass: parseClass(lcuid),
                ...data
            }));
    }
}

export function saveReactionRequest(lcuid, liked) {
    return dispatch => {
        return liked
            ? dispatch({
                type: OBJECT_NEW_REACTION_REQUEST,
                lcuid,
                objectClass: parseClass(lcuid)
            })
            : dispatch({
                type: OBJECT_DELETE_REACTION_REQUEST,
                lcuid,
                objectClass: parseClass(lcuid)
            });
    }
}

export function saveReaction(lcuid, liked) {
    return (dispatch) => {

        if (liked) {
            return lucitApi.objects.addReaction(lcuid, ReactionClass.like)
                .then(data => dispatch({
                    type: OBJECT_NEW_REACTION,
                    lcuid,
                    objectClass: parseClass(lcuid),
                    ...data
                }));
        }

        return lucitApi.objects.deleteReaction(lcuid)
            .then(() => dispatch({
                type: OBJECT_DELETE_REACTION,
                lcuid,
                objectClass: parseClass(lcuid),
            }));
    }
}

export function saveComment(lcuid, comment, data) {
    return (dispatch) => {

        return lucitApi.objects.addComment(lcuid, comment, data)
            .then(data => dispatch({
                type: OBJECT_NEW_COMMENT,
                lcuid,
                objectClass: parseClass(lcuid),
                ...data
            }));
    }
}

export function updateComment(lcuid, comment, newText) {
    return (dispatch) => {
        return lucitApi.objects.updateComment(comment.lcuid, newText)
            .then(data => dispatch({
                type: OBJECT_UPDATE_COMMENT,
                lcuid,
                objectClass: parseClass(lcuid),
                ...data
            }));
    }
}

export function deleteComment(lcuid, commentLcuid) {
    return (dispatch) => {
        return lucitApi.objects.delete(commentLcuid)
            .then(() => {
                dispatch({
                    type: OBJECT_DELETE_COMMENT,
                    lcuid,
                    objectClass: parseClass(lcuid),
                    comment: { lcuid: commentLcuid },
                });
            })
    }
}
