import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

import FeedRun from './FeedRun';

function FeedRuns(props) {
    const { item, setRunStatus } = props;

    return (
        <List>
            {item.runs.map(r => <FeedRun key={r.feed_run_id} run={r} setRunStatus={setRunStatus} />)}
        </List>
    );
}

FeedRuns.propTypes = {
    item: PropTypes.object.isRequired,
    setRunStatus: PropTypes.func.isRequired
}

export default FeedRuns;
