import React from 'react';
import moment from 'moment';
import { Typography, Box, makeStyles } from '@material-ui/core';

import { RoutePaths } from '../../helpers/constants';
import { showInfo, updateActiveMessage } from '../../actions/snackbar';
import { history } from '../../helpers/history';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer'
        },

        icon: {
            marginRight: theme.spacing(1),
            height: theme.spacing(4),
            objectFit: 'contain'
        }
    }
});

const MassEmailNotification = ({ notification }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {notification.data
                && <>
                    <Typography variant="subtitle1">{notification.data.title}</Typography>
                    <Typography variant="body2" gutterBottom>{notification.data.body}</Typography>
                    <Typography variant="caption" align="right">{moment.utc(notification.data.created_at).local().format("hh:mm A MMMM DD, YYYY")}</Typography>
                </>}
        </Box>
    );
};

const handleNotification = (notification, dispatch, handleClick) => {
    dispatch(
        showInfo(
            <MassEmailNotification notification={notification} />,
            {
                snackbarProps: {
                    onClick: () => {
                        handleClick();
                        dispatch(updateActiveMessage({ isOpen: false }));
                    }
                }
            }
        )
    )
};

const handleClick = (notification) =>
    history.push({ pathname: RoutePaths.massEmail + `/${notification.data.mass_email_id}` });

export { MassEmailNotification, handleNotification, handleClick }