/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    DialogContent, DialogActions, Box, useTheme, useMediaQuery, FormControlLabel, Radio, RadioGroup, Link
} from '@material-ui/core';

import { connect } from 'react-redux';
import ButtonLoader from '../material/ButtonLoader';
import { Dialog } from '../material';
import { setExportBoardIds } from '../../actions/inventory';
import { showError, showSuccess } from '../../actions/snackbar';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import BillboardSelector from '../../containers/billboards/BillboardSelector';
import LCTypography from '../material/LCTypography';
import { Alert } from '@material-ui/lab';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { RoutePaths } from '../../helpers/constants';
import i18next from 'i18next';
import { Link as RouterLink } from 'react-router-dom';

const ItemScreenSelectDialog = (props) => {
    const { open, setBoardIds, exportId, itemBoardIds, handleClose, dialogProps, onChange, onSave } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [saving, setSaving] = useState(false);
    const [selectedBoardIds, setSelectedBoardIds] = useState([]);
    const [exportBoards, setExportBoards] = useState([]);
    const [allBoards, setAllBoards] = useState(true);
    const [loading, setLoading] = useState(true);

    const handleOnSave = () => {
        onSave(allBoards ? [] : selectedBoardIds)
        setSaving(false);
        handleClose();
    }

    useEffect(() => {
        setSelectedBoardIds(itemBoardIds);
        if (itemBoardIds.length) {
            setAllBoards(false);
            onChange && onChange(true);
        }
        else {
            onChange && onChange(false);
        }
    }, [itemBoardIds])

    useEffect(() => {

        open && lucitApi.exports.getExportBillboards(exportId).then((digital_boards) => {
            setExportBoards(digital_boards);
            setLoading(false);
        })

    }, [exportId, open])

    const selectedScreenCount = selectedBoardIds.length;

    return (
        <Dialog
            open={open}
            lazy
            disableEnforceFocus={true}
            fullWidth
            maxWidth='lg'
            fullScreen={isXs}
            title={t("Item Screen Select")}
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent>

                <Box mb={2}>
                    <RadioGroup
                        name="run-all-boards"
                        value={allBoards ? "all" : "selected"}
                        onChange={(e) => setAllBoards(e.target.value === "all")}
                    >
                        <FormControlLabel
                            value={"all"}
                            control={<Radio />}
                            label={
                                <><strong>{t("All Screens")}</strong>
                                    <LCTypography variant="body2">
                                        This item will run on all screens that are attached to this campaign
                                    </LCTypography>
                                </>
                            } />

                        <FormControlLabel
                            value={"selected"}
                            control={<Radio />}
                            style={{ marginTop: 12, marginBottom: 12 }}
                            label={<><strong>{t("Choose Screens")}</strong>
                                <LCTypography variant="body2">
                                    {selectedScreenCount ?
                                        <>Only run on the <strong>{selectedScreenCount.toString()}</strong> screens selected below
                                        </>
                                        :
                                        <>Select the screens you want this item to run on</>}
                                </LCTypography>
                                {
                                    !allBoards && selectedScreenCount === 0 &&
                                    <Alert severity="warning">
                                        {t('Select at least 1 screen')}
                                    </Alert>
                                }

                            </>} />

                    </RadioGroup>


                    {loading && <CircularProgressCentered />}

                    <Box mt={2}>

                        <BillboardSelector
                            agency={null}
                            exportBoards={exportBoards}
                            disabledAllChecked={allBoards}
                            sizes={null}
                            values={selectedBoardIds}
                            showFilter={true}
                            setSelectedBoardIds={setSelectedBoardIds}
                            onChange={(boardIds) => {
                                setSelectedBoardIds(boardIds);
                            }}
                        />
                    </Box>

                </Box>

                <Alert severity="info">
                    <LCTypography variant="body2">
                        When setting screen limitations, we strongly recommend
                        adding a Default Creative in your <Link
                            component={RouterLink}
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            to={RoutePaths.accountSettings}
                        >
                            Account Settings
                        </Link>
                    </LCTypography>
                </Alert>

            </DialogContent>
            <DialogActions>
                <ButtonLoader
                    submitting={saving}
                    onClick={() => {
                        setSaving(true);

                        //If an onSave method was sent, use that instead of the
                        onSave ? (
                            handleOnSave()
                        ) :
                            setBoardIds(allBoards ? [] : selectedBoardIds)
                                .then(() => {
                                    handleClose();
                                })
                                .finally(() => setSaving(false))
                    }}
                    color="secondary"
                    variant="contained">
                    {onSave ? 'Ok' : 'Save'}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const mapStateToProps = () => {

    return {
    }
}

const mapStateToDispatch = (dispatch, ownProps) => {
    return {
        setBoardIds: (boardIds) => dispatch(setExportBoardIds(ownProps.itemId, ownProps.exportId, boardIds))
            .then(() => {
                dispatch(showSuccess(i18next.t('Item board ids saved')))
            })
            .catch(() => {
                dispatch(showError(i18next.t('Error saving Item Board Ids')))
            })
    }
}

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    ItemScreenSelectDialog
)
