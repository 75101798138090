import React, { useEffect, useState } from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import { Box, Popover } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { useTheme } from '@material-ui/styles';
import { isPlatform } from '@ionic/react';
import { DatePicker } from '@material-ui/pickers';

const DateRangeInput = (props) => {

    if (isPlatform('android')
        || isPlatform('ios')) {
        return <DateRangeInputMobile
            {...props}
        />;
    }

    return <DateRangeInputDesktop
        {...props}
    />;
}

const DateRangeInputMobile = ({ input, meta: { touched, error } = {}, mobilePickerProps, ...custom }) => {
    const { format } = custom;

    const dateFormat = format || 'MM/DD/YYYY';

    return <Box display="flex" alignItems="center">
        <DatePicker
            inputVariant="outlined"
            format={dateFormat}
            value={input.value.startDate}
            onChange={(value) => {
                if (moment(value).isAfter(input.value.endDate)) {
                    input.onChange({
                        startDate: input.value.endDate,
                        endDate: value
                    });
                } else {
                    input.onChange({ ...input.value, startDate: value });
                }
            }}

            error={touched && !!error}
            helperText={touched && error}
            {...mobilePickerProps}
        />

        <Box ml={1} mr={1}>
            to
        </Box>

        <DatePicker
            inputVariant="outlined"
            format={dateFormat}
            value={input.value.endDate}
            initialFocusedDate={input.value.startDate}
            onChange={(value) => {
                if (moment(value).isBefore(input.value.startDate)) {
                    input.onChange({
                        startDate: value,
                        endDate: input.value.startDate
                    });
                } else {
                    input.onChange({ ...input.value, endDate: value })
                }
            }}
            {...mobilePickerProps}
        />
    </Box>
}

const DateRangeInputDesktop = ({ input, label, meta: { touched, error } = {}, pickerProps, format, size }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeCount, setChangeCount] = useState(0);
    const theme = useTheme();

    const dateFormat = format || 'MM/DD/YYYY';

    const inputProps = Object.assign({},
        input,
        {
            readOnly: true,
            value: input.value && input.value.startDate
                ? `${moment(input.value.startDate).format(dateFormat)} - ${moment(input.value.endDate).isValid()
                    ? moment(input.value.endDate).format(dateFormat)
                    : 'No End Date'}`
                : ``,

            onClick: e => setAnchorEl(e.currentTarget)
        });

    useEffect(() => {
        if (anchorEl === null) {
            setChangeCount(0);
        }
    }, [anchorEl])

    return <>
        <MaterialTextField
            label={label}
            error={touched && !!error}
            variant="outlined"
            helperText={touched && error}
            size={size}
            inputProps={inputProps}
            InputProps={{ endAdornment: <DateRangeIcon /> }}
            fullWidth
        />

        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onBackdropClick={() => setAnchorEl(null)}
        >
            <DateRange
                ranges={[{
                    startDate: input.value.startDate,
                    endDate: input.value.endDate,
                    key: 'selection',
                }]}
                rangeColors={[theme.palette.secondary.main]}

                color={theme.palette.secondary.main}
                months={1}
                fixedHeight={true}
                onChange={e => {
                    setChangeCount(changeCount + 1);
                    input.onChange(e.selection);

                    // we assume that date is selected when startDate and endDate are different dates
                    // if (!moment(e.selection.startDate).isSame(moment(e.selection.endDate))) {
                    if (changeCount + 1 == 2) {
                        setAnchorEl(null);
                    }
                }}
                {...pickerProps}
            />
        </Popover>

    </>
};

export { DateRangeInput }
