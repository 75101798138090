import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { trash } from 'ionicons/icons';
import { showError, showSuccess } from '../../actions/snackbar';
import { ConfirmationDialog } from '../../components';
import { history } from '../../helpers/history';
import { deleteExport } from '../../actions/exports';
import { lucitApi } from "../../services/lucitApi"
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { EXPORTS_DELETE } from '../../helpers/actionTypes';
import { IonIcon } from '@ionic/react';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const ExportDeleteButton = (props) => {
    const { campaign, deleteCampaign, onClick, disabled } = props;
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(campaign.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [campaign, isConfirmOpen])

    return (
        <>
            <MenuItem
                disabled={disabled}
                onClick={() => {
                    setIsConfirmOpen(true);
                    if (onClick) {
                        onClick()
                    }
                }}
            >
                <ListItemIcon>
                    <IonIcon icon={trash} slot="start" />
                </ListItemIcon>
                <ListItemText primary="Delete Campaign" />
            </MenuItem>

            <ConfirmationDialog
                title={t('Delete Campaign : ') + campaign.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteCampaign()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete this campaign?</LCTypography>
                        <LCTypography>This action CANNOT be un-done and once you delete this campaign.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Campaign can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

ExportDeleteButton.propTypes = {
    campaign: PropTypes.object.isRequired,
    redirectPathname: PropTypes.string
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteExportFromStore: () => dispatch({
            type: EXPORTS_DELETE,
            campaign: props.campaign
        }),
        deleteCampaign: () => {
            return dispatch(deleteExport(props.campaign))
                .then(() => {
                    if (props.redirectPathname) {
                        history.push({ pathname: props.redirectPathname });
                    }

                    if (props.onAfterDelete)
                        props.onAfterDelete();

                    dispatch(showSuccess(`"${props.campaign.name}" was successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportDeleteButton
);
