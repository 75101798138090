import { MTableBodyRow } from 'material-table';
import React from 'react';

const ItemRow = props => {
    const { components, data } = props;

    const DetailPanel = components.DetailPanel;

    return <>
        <MTableBodyRow {...props} />

        <DetailPanel {...props} open={data.tableData.isExpanded} />
    </>
};

export default ItemRow;