import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Chip, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { StatsDelayedChip, StatsUnavailableChip } from '../../components';
import { canRunExport } from '../../selectors/exports';
import { getUser } from '../../selectors/user';
import { runExport, setName, getExport } from '../../actions/exports';
import { Global, RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { IonCardTitle, IonButton, IonIcon } from '@ionic/react';
import { link, refresh, ellipsisVerticalCircle } from 'ionicons/icons';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { maxLength, required } from '../../helpers/validators';
import ExportDeleteButton from '../../containers/exports/ExportDeleteButton';
import { CampaignPlayerIntegrationsDialog } from '../campaigns';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain',
            maxHeight: 50
        },
        imageIcon: {
            width: '100%',
            height: '100%',
            maxHeight: 50
        },
        inactiveChip: {
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1)
        },
        delayedChip: {
            marginLeft: theme.spacing(1)
        },

        attribute: {
            display: 'flex',
            margin: 0,
            padding: 10,
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
        },
        campaignStatsSection: {
            display: 'flex',
        },
        campaignTitleSection: {
            display: 'flex',
            alignItems: 'center',
            padding: 10,
            wordBreak: 'break-word',
        },
        campaignTitle: {
            flex: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12pt',
            },
        },
        campaignButtonsGroup: {
            display: 'flex',
        },
    }
})

function ExportDetailsContainerGroup(props) {
    const { item, runExport, setName, getExport } = props;
    const classes = useStyles();
    const [refreshing, setRefreshing] = useState(false);
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const updateCampaignName = (exportId, exportName) => {
        setName(exportId, exportName);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const refreshExport = () => {
        setRefreshing(true);
        getExport(item.id).finally(() => setRefreshing(false));
    }

    return (
        <div>

            <Box className={classes.campaignTitleSection}>

                <IonCardTitle
                    className={classes.campaignTitle}
                    color={item.active
                        ? "textPrimary"
                        : "textSecondary"}
                >

                    <TextFieldEditable
                        classes={{
                            root: classes.inputRoot
                        }}
                        fullWidth={true}
                        circularProgressProps={{ size: 40 }}
                        size="small"
                        ViewComponent={
                            item.name}
                        label={t('Campaign Name')}
                        variant={"outlined"}
                        value={item.name || ""}
                        validators={[required, maxLength(Global.titleMaxLength)]}
                        handleDone={name => updateCampaignName(item.id, name)}
                    />

                    {!item.active
                        && <Chip
                            className={classes.inactiveChip}
                            label={t('Inactive')} />}
                </IonCardTitle>

                <Box className={classes.campaignButtonsGroup}>

                    <IonButton
                        color="secondary"
                        fill="clear"
                        onClick={(event) => {
                            handleClick(event)
                        }
                        }
                    >
                        <IonIcon slot="icon-only" icon={ellipsisVerticalCircle} />
                    </IonButton>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem
                            disabled={refreshing || !canRunExport(item)}
                            onClick={() => {
                                setRefreshing(true);
                                runExport(item.id)
                                    .finally(() => setRefreshing(false))
                                handleCloseMenu()
                            }}
                        >
                            <ListItemIcon>
                                <IonIcon icon={refresh} slot="start" />
                            </ListItemIcon>
                            <ListItemText primary={t('Refresh Data')} />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                refreshExport()
                                handleClickOpen()
                                handleCloseMenu()
                            }}
                        >
                            <ListItemIcon>
                                <IonIcon icon={link} slot="start" />
                            </ListItemIcon>
                            <ListItemText primary={t('Link Player')} />
                        </MenuItem>
                        <ExportDeleteButton campaign={item} redirectPathname={RoutePaths.home} />
                    </Menu>
                </Box>
            </Box>

            <StatsDelayedChip
                exportObject={item}
                chipProps={{
                    className: classes.delayedChip
                }}
            />
            <StatsUnavailableChip
                exportObject={item}
                chipProps={{
                    className: classes.delayedChip
                }} />

            {open && <CampaignPlayerIntegrationsDialog
                open={open}
                handleClose={handleClose}
                campaign={item}
            />}
        </div>
    );
}

ExportDetailsContainerGroup.propTypes = {
    item: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runExport: id => dispatch(runExport(id)),
        setName: (id, name) => dispatch(setName(id, name)),
        getExport: id => dispatch(getExport(id)),
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ExportDetailsContainerGroup
    )
);
