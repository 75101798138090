import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, Collapse, Divider, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRAMMATIC_FILTERS_CHANGE } from '../../helpers/actionTypes';
import {
    recursivelyDesc, recursivelyAsc, venueTypesTreeSearch,
    recursivelySomeChildren, getVenueTypePath
} from '../../selectors/programmatic';
import FilterButton from './FilterButton';
import { CircularProgressCentered, SearchInput } from '../../components';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles((theme) => {
    return {
        filterButtonSelected: {
            color: theme.palette.primary.main,
        },
        list: {
            maxHeight: 300,
            overflow: 'auto',
            margin: '0 -16px',
            padding: '0 8px;'
        },
        subheader: {
            lineHeight: '48px',
            background: 'white',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        indeterminate: { color: theme.palette.secondary.main }
    }
})

const VenueTypesFilter = () => {
    const [opened, setOpened] = useState(false);
    const [venueTypes, setVenueTypes] = useState({});
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const programmatic = useSelector(state => state.programmatic);

    const options = useSelector(state => state.programmatic.venue_type_options) ?? [];
    const loading = useMemo(() => !options, [options]);

    useEffect(() => {
        const venueTypesDictionary = programmatic.venueTypes
            .reduce((prev, curr) => ({
                ...prev,
                [curr.enumeration_id]: curr
            }), {})
        setVenueTypes(venueTypesDictionary);
    }, [programmatic.venueTypes, opened])

    const onChange = useCallback((option) => {
        const isSelected = x => venueTypes[x.enumeration_id];
        const childrenSelectedCount = option.children.filter(isSelected).length;
        const childrenCount = option.children.length;
        const indeterminate = childrenSelectedCount > 0 && childrenSelectedCount < childrenCount;

        if (venueTypes[option.enumeration_id] && !indeterminate) {
            recursivelyDesc(option, option => { delete venueTypes[option.enumeration_id] });

            // if all children are unselected, make sure the parent is also unselected
            recursivelyAsc(option, parent => {
                if (parent.children.filter(isSelected).length == 0) {
                    delete venueTypes[parent.enumeration_id];
                }
            })
        } else {
            recursivelyDesc(option, option => venueTypes[option.enumeration_id] = option);

            // if all children are selected, make sure the parent is also selected
            recursivelyAsc(option, parent => {
                if (parent.children.length ==
                    parent.children.filter(isSelected).length) {
                    venueTypes[parent.enumeration_id] = option
                }
            })
        }
        setVenueTypes({ ...venueTypes })
    }, [venueTypes])

    const venueTypesCount = useMemo(() => Object.keys(venueTypes).length, [venueTypes])
    const onChangeAll = useCallback(() => {
        if (options.length == venueTypesCount) {
            setVenueTypes({})
        } else {
            setVenueTypes(options.reduce((prev, curr) =>
                ({ ...prev, [curr.enumeration_id]: curr }),
                {}
            ));
        }
    }, [venueTypesCount, options])

    const optionsTree = useMemo(() => venueTypesTreeSearch(options), [options]);

    const optionsFiltered = useMemo(() => {
        // Check if the search string is empty
        if (!search) return options ?? [];

        // Split the search string into individual terms
        const searchTerms = search.toLowerCase().split(' ');

        // Filter options based on whether any of the query terms match the option name
        return options.filter(option =>
            searchTerms.every(term => option.name && option.name.toLowerCase().includes(term))
        );
    }, [options, search]);

    return <FilterButton
        className={programmatic.venueTypes.length > 0
            ? classes.filterButtonSelected
            : null}
        title={programmatic.venueTypes.length > 0
            ? `${programmatic.venueTypes.length} ` + t('Venue Types')
            : t('Venue Types')}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        onApply={() => {
            dispatch({
                type: PROGRAMMATIC_FILTERS_CHANGE,
                venueTypes: Object.values(venueTypes)
            })
        }}
    >
        <LCTypography className={classes.subheader}>
            Venue Types
        </LCTypography>
        <Box mb={1}>
            <SearchInput search={search} setSearch={setSearch} />
        </Box>
        <List disablePadding className={classes.list}>
            <ListItem
                style={{ padding: 0, cursor: 'pointer' }}
                onClick={() => onChangeAll()}
                disableGutters>
                <Checkbox
                    size='small'
                    classes={{ indeterminate: classes.indeterminate }}
                    indeterminate={venueTypesCount > 0 && options.length != venueTypesCount}
                    checked={options.length == venueTypesCount}
                />
                <ListItemText>{t('Select All')}</ListItemText>
            </ListItem>
            <Divider />
            {loading
                && <CircularProgressCentered size={32} />}

            {!loading
                && (search ? optionsFiltered : optionsTree).map(o => (<VenueTypeLineItem
                    key={o.enumeration_id}
                    venueType={o}
                    checked={x => Boolean(venueTypes[x.enumeration_id])}
                    onClick={onChange}
                    hideChildren={Boolean(search)}
                />))}
        </List>
    </FilterButton>
}

const VenueTypeLineItem = ({ venueType, checked, onClick, hideChildren, level = 0 }) => {
    const classes = useStyles();
    const childrenCount = venueType.children.length;
    const childrenSelectedCount = venueType.children.filter(checked).length;
    const indeterminate = recursivelySomeChildren(venueType, checked) && childrenSelectedCount < childrenCount

    return <>
        <ListItem
            style={{
                padding: 0,
                paddingLeft: level * 28,
                cursor: 'pointer',
                opacity: venueType.count ? 1 : 0.5
            }}
            onClick={() => onClick(venueType)}
            disableGutters>
            <Checkbox
                size='small'
                checked={checked(venueType)}
                indeterminate={indeterminate}
                classes={{ indeterminate: classes.indeterminate }}
            />
            <ListItemText
                primary={venueType.name + ` (` + venueType.count + `)`}
                secondary={hideChildren && getVenueTypePath(venueType)}
            />
        </ListItem>
        {venueType.children.length > 0
            && !hideChildren
            && <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {venueType.children
                        .map(x => (<VenueTypeLineItem
                            key={x.enumeration_id}
                            venueType={x}
                            checked={checked}
                            onClick={onClick}
                            level={level + 1}
                        />))}
                </List>
            </Collapse>}
    </>
}

export default VenueTypesFilter;
