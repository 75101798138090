import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

const LucitFormFieldSecret = ({ field, value, onChange }) => {

    const [showPassword, setShowPassword] = useState(false);

    const isError = () => {
        return false
    }

    return <TextField
        id={field.key}
        name={field.key}
        autoComplete="new-password"
        label={field.name}
        fullWidth
        value={value ?? ''}
        error={isError()}
        required
        type={showPassword ? 'text' : 'password'}
        helperText={field.description}
        onChange={(e) => {
            onChange(e.target.value)
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle secret visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            )
        }}
    />
}

export default LucitFormFieldSecret;
