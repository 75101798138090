import { apiHttp } from "../helpers/api";

export default class LucitApiApplications {
    list(isAuthenticated, filters) {

        const url = isAuthenticated ? `/applications` : `/public/applications`;
        return apiHttp.get(url, filters)
            .then(response => response.data.applications);
    }

    get(lcuid) {
        return apiHttp.get(`/applications/${lcuid}`)
            .then(response => response.data.application);
    }

    updateApplication(data) {
        return apiHttp.put(`/applications/${data.lcuid}`, {
            name: data.name,
            organization_name: data.organization_name,
            website: data.website,
            help_url: data.help_url,
            video_url: data.video_url,
            premium: data.premium,
            premium_fees_description: data.premium_fees_description,
            description: data.description,
            permissions: data.permissions
                ? data.permissions
                : undefined
        })
            .then(response => response.data.application);
    }

    createApplication(data) {
        return apiHttp.post(`/applications`, {
            description: 'Draft Application',
            ...data
        })
            .then(response => response.data.application);
    }

    setPrimaryImage(applicationId, image_id) {
        return apiHttp.post(`/applications/${applicationId}/primaryImage`, { image_id })
            .then(response => response.data.object);
    }

    deletePrimaryImage(applicationId) {
        return apiHttp.delete(`/applications/${applicationId}/primaryImage`)
            .then(response => response.data);
    }

    submitReview(lcuid) {
        return apiHttp.post(`/applications/${lcuid}/submit-for-review`)
            .then(response => response.data);
    }

    invite(applicationId, email, phone, roles) {
        return apiHttp.post(`/applications/${applicationId}/invite`, {
            roles: roles.join(':'),
            email,
            mobile_phone_number: phone
        })
            .then(response => response.data.user_invitation)
            .catch(error => this.handleInviteError(error));
    }

    getBots(applicationId) {
        return apiHttp.get(`/applications/${applicationId}/bot-users`)
            .then(response => response.data.users);
    }

    createBotUser(applicationId, userName, userTokenType) {
        return apiHttp.post(`/applications/${applicationId}/users/create-bot-user`, {
            name: userName,
            user_token_type: userTokenType
        })
            .then(response => ({
                ...response.data.user,
                secret: response.data.secret
            }));
    }

    resetBotUserSecret(applicationId, userId) {
        return apiHttp.post(`/applications/${applicationId}/users/${userId}/reset-bot-user-secret`)
            .then(response => response.data.secret)
    }

    deleteBotUser(applicationId, userId) {
        return apiHttp.delete(`/users/${userId}/applications/${applicationId}`);
    }

    getClasses() {
        return apiHttp.get(`/applications/application-classes`)
            .then(response => response.data.application_classes);
    }

    getCapabilities() {
        return apiHttp.get(`/applications/application-capabilities`)
            .then(response => response.data.application_capabilities);
    }

    getPermissions() {
        return apiHttp.get(`/applications/application-permissions`)
            .then(response => response.data.application_permissions);
    }

    addCapability(application, capability) {
        return apiHttp.post(`/applications/${application.lcuid}/capabilities`, {
            capability_class: capability.capability_class
        })
            .then(response => response.data.application_capability);
    }

    deleteCapability(application, capability) {
        return apiHttp.delete(`/applications/${application.lcuid}/capabilities/${capability.id}`)
            .then(response => response.data);
    }

    getFeedProviders(applicationId) {
        return apiHttp.get(`/inventory-feed-providers`, {
            params: {
                application_id: applicationId
            }
        })
            .then(response => response.data.inventory_feed_providers);
    }

    driveTemplates(applicationId, isAuthenticated) {

        const url = isAuthenticated ? `/applications` : `/public/applications`;

        return apiHttp.get(url + `/${applicationId}/drive-templates`)
            .then(response => response.data.drive_templates);
    }

    getKeyValueStores(applicationId, keys, objectLcuid) {
        return apiHttp.get(`/applications/${applicationId}/key-value-stores`, {
            params: {
                keys: JSON.stringify(keys),
                object_lcuid: objectLcuid,
            }
        })
            .then(response => response.data.key_value_stores);
    }
}
