import { LuCoreModelClasses } from "./constants";

const classes = Object.values(LuCoreModelClasses);

export function parseClass(lcuid) {
    if (!lcuid) return;

    const parts = lcuid.split('-');

    if (parts.length < 3) {
        return null;
    }

    return classes.find(x => x.lid === parts[1]);
}

export function getClass(className) {
    return classes.find(x => x.class === className);
}
