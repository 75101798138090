import { lucitApi } from "../services/lucitApi";
import { SearchType, Global } from "../helpers/constants";
import { getMenuLinks } from "../selectors/layout";
import { EntitySet } from "../helpers/actionTypes";
import { entityNextPage, entitySearch } from "./entities";
import { isLoggedIn } from "../selectors/user";

export function globalSearch(query, filters, isNext) {

    return (dispatch, getState) => {
        filters = Object.assign({
            query,
            page: 1,
            perPage: Global.globalSearchMaxResultsCount
        }, filters)

        const isAuthenticated = isLoggedIn(getState());

        const search = () => lucitApi.globalSearch(isAuthenticated, filters)
            .then((result) => {
                if (isNext) {
                    return result;
                }

                const links = isAuthenticated
                    ? siteMapGlobalSearch(getState(), query)
                    : [];

                return {
                    ...result,

                    data: [
                        ...links,
                        ...result.data
                    ],
                    total: result.total + links.length
                };
            });

        return isNext
            ? entityNextPage(EntitySet.globalSearch, search, filters)(dispatch)
            : entitySearch(EntitySet.globalSearch, search, filters)(dispatch);
    }
}

function siteMapGlobalSearch(state, query) {
    return getMenuLinks(state)
        .filter(l => l.section === state.user.profileType.id)
        .filter(l => l.title.toLowerCase().indexOf(query.toLowerCase()) > -1)
        .map(x => ({
            ...x,
            lcuid: `${SearchType.link.type}-${x.route}`,
            searchable_type: SearchType.link.type,
        }))
}
