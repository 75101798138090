import React, { useMemo } from 'react';
import { ProgrammaticBudgetTypes } from '../../helpers/constants';
import { formatNumberToShortVariant } from '../../selectors/programmatic';
import { Skeleton } from '@material-ui/lab';
import LCTypography from '../../components/material/LCTypography';
import moment from 'moment';

export const CampaignImpressionStats = ({ loading, dates, budgetType, stats }) => {
    const duration = useMemo(() => moment(dates.endDate).diff(dates.startDate, 'days'), [dates])
    const format = number => formatNumberToShortVariant(Math.round(number));

    return <>
        {loading
            && <>
                <Skeleton animation="wave" height={40} width="90%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={16} width="90%" style={{ marginBottom: 6 }} />
            </>}
        {!loading
            && budgetType != ProgrammaticBudgetTypes.lifetime.code
            && stats
            && <>
                <LCTypography gutterBottom style={{ fontWeight: 'bold' }}>
                    {format(stats.estimated_impressions.lifetime.min)}
                    &nbsp;to {format(stats.estimated_impressions.lifetime.max)}
                    &nbsp;impressions
                </LCTypography>
                <LCTypography gutterBottom variant="body2">
                    Your ads will appear {format(stats.estimated_plays.lifetime.min)}
                    &nbsp;to {format(stats.estimated_plays.lifetime.max)} times
                    &nbsp;on up to {format(stats.digital_board_count_eligible)} screens
                    over {duration} days
                </LCTypography>
            </>}
        {!loading
            && budgetType == ProgrammaticBudgetTypes.lifetime.code
            && Boolean(stats)
            && <>
                <LCTypography gutterBottom style={{ fontWeight: 'bold' }}>
                    {format(stats.estimated_impressions.per_day.min)}
                    &nbsp;to {format(stats.estimated_impressions.per_day.max)}
                    &nbsp;daily impressions
                </LCTypography>
                <LCTypography gutterBottom variant="body2">
                    Your ads will appear {format(stats.estimated_plays.per_day.min)}
                    &nbsp;to {format(stats.estimated_plays.per_day.max)} times
                    on up to {format(stats.digital_board_count_eligible)} screens
                </LCTypography>
            </>}
    </>
};
