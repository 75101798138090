import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';
import { Divider, AccordionSummary, AccordionDetails, Box, Link, Button } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Business } from '@material-ui/icons'
import { useHistory } from 'react-router';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { getUser, selectedAgency } from '../../selectors/user';
import { required } from '../../helpers/validators';
import { changeAgencyDetails, setPrimaryImage, deletePrimaryImage } from '../../actions/agencies';
import { showSuccess, showError } from '../../actions/snackbar';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import ImageUploadDialog from '../../components/modals/ImageUploadDialog';
import { Global } from '../../helpers/constants';
import { RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { SettingsSection } from '../settings';
import { AvatarEditable, ButtonLoader } from '../../components';
import { hasIcon } from '../../selectors/account';
import { selectUser } from '../../actions/user';

const useStyles = makeStyles(() => {
    return {
        summaryContent: {
            justifyContent: 'center',
            flexDirection: 'column'
        },
        content: {
            flexDirection: 'column'
        },
        formField: {
            width: '100%',
            maxWidth: 600
        },
        headerIcon: {
            marginBottom: -3
        },
        avatarContainer: {
            width: 100,
            height: 100,
        }
    }
});

function AgencyInfo(props) {
    const { selectedAgency, changeAgencyDetails } = props;
    const classes = useStyles();
    const [isOpened, setOpened] = useState(true);
    const [isDeletingImage, setIsDeletingImage] = useState(false);
    const [isUploadOpened, setIsUploadOpened] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="userinfo-content"
                id="userinfo-header"
                classes={{ content: classes.summaryContent }}
            >
                <LCTypography variant="h5" ><AccountBoxIcon className={{ classesHeader: classes.headerIcon }} /> Settings</LCTypography>
            </AccordionSummary>

            <Divider />

            <AccordionDetails className={classes.content}>
                <SettingsSection title={t("Personal Settings")}>
                    <Box mt={2}>
                        <LCTypography variant="body1">
                            To edit Notifications, Personal Information and Security,
                            <> </>
                            <Link
                                variant='body1'
                                color="secondary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(selectUser({
                                    animate: true,
                                    onSwitched: () => history.push({ pathname: RoutePaths.settings })
                                }))}
                            >
                                edit your personal profile settings
                            </Link>
                        </LCTypography>
                    </Box>
                </SettingsSection>

                <SettingsSection title={t("Profile Photo")}>
                    <Box className={classes.formField} display="flex" alignItems="center">
                        <AvatarEditable
                            classes={{ root: classes.avatarContainer }}
                            fallbackIcon={<Business />}
                            src={selectedAgency.options.primary_image_public_url}
                            onClick={() => setIsUploadOpened(true)}
                        />
                        <Box ml="auto" mr={7}>
                            {hasIcon(selectedAgency)
                                && <ButtonLoader
                                    submitting={isDeletingImage}
                                    onClick={() => {
                                        setIsDeletingImage(true);
                                        props.handleImageDelete()
                                            .then(() => setIsDeletingImage(false));
                                    }}
                                    variant='text'
                                    color="primary"
                                    style={{ marginRight: 12 }}>
                                    Remove Photo
                                </ButtonLoader>}
                            <Button
                                onClick={() => setIsUploadOpened(true)}
                                variant='contained'
                                color="primary">
                                Change Photo
                            </Button>
                        </Box>
                    </Box>
                    {isUploadOpened
                        && <ImageUploadDialog
                            title={t('Edit Profile Image')}
                            src={selectedAgency.options.primary_image_public_url}
                            open={isUploadOpened}
                            autoUpload
                            aspect={Global.imageAspectAccountLogo}
                            askForCameraOnAndroid={true}
                            handleDelete={() => props.handleImageDelete()
                                .then(() => setIsUploadOpened(false))}
                            handleSave={image => props.handleImageUpload(image)
                                .then(() => setIsUploadOpened(false))}
                            handleClose={() => setIsUploadOpened(false)}
                        />}
                </SettingsSection>

                <SettingsSection title={t("Company Name")}>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        variant={"outlined"}
                        value={selectedAgency.name}
                        validators={[required]}
                        fullWidth={true}
                        id={"operatorname"}
                        handleDone={(value) => changeAgencyDetails(
                            value,
                            selectedAgency.description,
                            selectedAgency.website,
                            selectedAgency.options.proof_legal_text,
                            selectedAgency.options.support_text
                        )}
                    />
                </SettingsSection>
                <SettingsSection
                    title={t("Public Description")}>
                    <LCTypography style={{ marginBottom: 8 }}>
                        This description will appear on your public page
                    </LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        multiline
                        variant={"outlined"}
                        value={selectedAgency.description}
                        validators={[required]}
                        fullWidth={true}
                        id={"description"}
                        inputProps={{ multiline: true, rows: 2 }}
                        handleDone={(value) => changeAgencyDetails(
                            selectedAgency.name,
                            value,
                            selectedAgency.website,
                            selectedAgency.options.proof_legal_text,
                            selectedAgency.options.support_text
                        )}
                    />
                    <Box mt={1}>
                        <Link
                            variant='body1'
                            component={RouterLink}
                            color="secondary"
                            to={RoutePaths.public.slug(selectedAgency.slug)}>
                            {t('This is the link to your Public Page')}
                        </Link>
                    </Box>
                </SettingsSection>

                <SettingsSection
                    title={t("Website")}>
                    <LCTypography style={{ marginBottom: 8 }}>Your website link will be added to your public page</LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        variant={"outlined"}
                        value={selectedAgency.website}
                        fullWidth={true}
                        id={"website"}
                        handleDone={(value) => changeAgencyDetails(
                            selectedAgency.name,
                            selectedAgency.description,
                            value,
                            selectedAgency.options.proof_legal_text,
                            selectedAgency.options.support_text
                        )}
                    />
                </SettingsSection>
                <SettingsSection title={t("Creative Proof Legal Disclaimer Text")}>
                    <LCTypography style={{ marginBottom: 8 }}>
                        This text appears on any creative proofs that your team generates for clients
                    </LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        multiline
                        variant={"outlined"}
                        value={selectedAgency.options.proof_legal_text}
                        validators={[required]}
                        fullWidth={true}
                        id={"proof_legal_text"}
                        inputProps={{ multiline: true, rows: 2 }}
                        handleDone={(value) => changeAgencyDetails(
                            selectedAgency.name,
                            selectedAgency.description,
                            selectedAgency.website,
                            value,
                            selectedAgency.options.support_text
                        )}
                    />
                </SettingsSection>
                <SettingsSection title={t("Help and Support Text")}>
                    <LCTypography style={{ marginBottom: 8 }}>
                        Show your support contact email, phone, hours or any other information here
                    </LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        multiline
                        variant={"outlined"}
                        value={selectedAgency.options.support_text}
                        validators={[required]}
                        fullWidth={true}
                        id={"support_text"}
                        inputProps={{ multiline: true, rows: 2 }}
                        handleDone={(value) => changeAgencyDetails(
                            selectedAgency.name,
                            selectedAgency.description,
                            selectedAgency.website,
                            selectedAgency.options.proof_legal_text,
                            value
                        )}
                    />
                </SettingsSection>

            </AccordionDetails>
        </AccordionScrollable >
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAgency: selectedAgency(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {

        changeAgencyDetails: (name, description, website, proof_legal_text, support_text) => {
            return dispatch(changeAgencyDetails(name, description, website, proof_legal_text, support_text))
                .then(() => {
                    dispatch(showSuccess('Details Updated'))
                });
        },

        handleImageUpload: (image) => {
            return dispatch(setPrimaryImage(image))
                .then(() => dispatch(showSuccess('Profile photo was updated successfuly')))
                .catch(error => {
                    dispatch(showError('Failed to save profile photo'));

                    throw error;
                });
        },

        handleImageDelete: () => {
            return dispatch(deletePrimaryImage())
                .then(() => dispatch(showSuccess('Profile photo was removed')))
                .catch(error => {
                    dispatch(showError('Failed to remove profile photo'));

                    throw error;
                });
        },

        showError: (error) => dispatch(showError(error))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyInfo);
