import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GridList, GridListTile, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    inventoryItems, searched, isSomethingSelected, hasNextPage,
    nextPageLoading, nextPage, inventoryScrollY, hasScrollY
} from '../../selectors/inventory';
import InventoryItemCard from './InventoryItemCard';
import ActionsDrawer from './ActionsDrawer';
import ActionsHeader from './ActionsHeader';
import { loadPage, inventorySaveScroll } from '../../actions/inventory';
import { useInfiniteScroll } from '../../helpers/hooks/useInfiniteScroll';
import { Global, InventoryItemStatus } from '../../helpers/constants';
import { useContext } from 'react';
import { hasAllowedExports } from '../../selectors/inventory';
import { ScrollContext } from '../../contexts';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import { useWidth } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        inventoryItemTile: {
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center'
        }
    }
})

const colsMap = {
    "xs": 1,
    "sm": 1,
    "md": 2,
    "lg": 4,
    "xl": 5
}

function InventoryList(props) {
    const { nextPage, isNextPageLoading, hasNextPage, loadNextPage,
        searched, isSomeSelected, items, hasAllowedExports,
        hideNoDataLabel,
        noDataLabel } = props;
    const { hasScrollY, scrollY, saveScroll } = props;
    const classes = useStyles();
    const scrollContext = useContext(ScrollContext).current;
    const { t } = useTranslation();

    useInfiniteScroll({
        isLoading: isNextPageLoading,
        hasMoreItems: hasNextPage,
        loadMoreItems: () => {
            loadNextPage(nextPage);
        },

        threshold: Global.nextPageLoadThresold,
        getRef: () => scrollContext.ref
    })

    useEffect(() => {
        const scrollRef = scrollContext.ref;

        if (hasScrollY) {
            scrollRef.scrollTo({
                top: scrollY,
                // behavior: 'smooth'
            })
        }
    }, [scrollContext, hasScrollY, scrollY])

    useEffect(() => {
        const scrollRef = scrollContext.ref;

        return () => {
            saveScroll(scrollRef.scrollTop);
        }
    }, [scrollContext, saveScroll])

    const width = useWidth();
    const cols = colsMap[width];

    const draftItems = items.filter(i => i.status == InventoryItemStatus.draft);
    const [status, setStatus] = React.useState(InventoryItemStatus.active);

    return (
        <Box>
            {draftItems.length > 0
                && <IonSegment value={status}
                    onIonChange={e => setStatus(+e.detail.value)}>
                    <IonSegmentButton value={InventoryItemStatus.active}>
                        {t("Active")}
                    </IonSegmentButton>
                    <IonSegmentButton value={InventoryItemStatus.draft}>
                        {t("Draft")} ({draftItems.length})
                    </IonSegmentButton>
                </IonSegment>}

            <GridList
                cols={cols}
                cellHeight={'auto'}
                spacing={8}>
                {items
                    .filter(x => x.status === status)
                    .map(item => <GridListTile key={item.id} id={item.id} classes={{ tile: classes.inventoryItemTile }}>
                        <InventoryItemCard item={item} />
                    </GridListTile>)}
            </GridList>

            {isNextPageLoading
                && <Box m={1} mt={2} display="flex" justifyContent="center">
                    <CircularProgressCentered size={40} />
                </Box>}

            {!items.length
                && searched
                && !hideNoDataLabel
                && <Box ml={1} mt={4} mb={2}>
                    {noDataLabel ? noDataLabel : <></>}
                </Box>}

            {hasAllowedExports
                && isSomeSelected
                && <>
                    <Hidden xsDown implementation="js">
                        <ActionsDrawer
                            open={isSomeSelected}
                        />
                    </Hidden>

                    <Hidden smUp implementation="js">
                        <ActionsHeader
                            open={isSomeSelected}
                        />
                    </Hidden>
                </>}

        </Box>
    );
}

InventoryList.propTypes = {
    hideNoDataLabel: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        items: inventoryItems(state),
        nextPage: nextPage(state),
        hasNextPage: hasNextPage(state),
        isNextPageLoading: nextPageLoading(state),
        searched: searched(state),
        isSomeSelected: isSomethingSelected(state),

        scrollY: inventoryScrollY(state),
        hasScrollY: hasScrollY(state),
        hasAllowedExports: hasAllowedExports(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadNextPage: (page) => dispatch(loadPage(page)),
        saveScroll: (scrollY) => dispatch(inventorySaveScroll(scrollY))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryList
);
