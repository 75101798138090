import { useEffect } from "react";

// TODO: take a look
// import InfiniteScroll from 'react-infinite-scroll-component';

export function useInfiniteScroll({
    isLoading,
    hasMoreItems,
    loadMoreItems,
    threshold = 150,

    getRef = null,
    useWindowScrolling = false
}) {
    if (!useWindowScrolling && !getRef) {
        console.error("You should pass getRef function when not using window scrolling");
    }

    const shouldLoadMoreItems = () => {
        if (isLoading || !hasMoreItems) {
            return false;
        }

        if (useWindowScrolling) {
            return Math.round(document.documentElement.offsetHeight - window.scrollY - window.innerHeight) <= threshold;
        }

        const ref = getRef();

        if (!ref) {
            return false;
        }

        return ref.scrollHeight - ref.scrollTop - window.innerHeight <= threshold;
    };

    const onWindowEvent = () => shouldLoadMoreItems() && loadMoreItems();

    useEffect(() => {
        const container = useWindowScrolling
            ? window
            : getRef();

        if (!container) {
            console.warn(`${useInfiniteScroll.name} doesn't received valid ref for scroll container`)
            return;
        }

        container.addEventListener("scroll", onWindowEvent);
        container.addEventListener("resize", onWindowEvent);

        return () => {
            container.removeEventListener("scroll", onWindowEvent);
            container.removeEventListener("resize", onWindowEvent);
        };
    });
}
