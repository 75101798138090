import React from 'react';
import clx from 'classnames';
import { ListItem, ListItemAvatar, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../../components/material';
import { selectedProfile } from '../../selectors/user';
import { ProfileType } from '../../helpers/constants';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    selected: {
        // color: theme.palette.secondary.main

        '& .MuiTypography-root': {
            fontWeight: 'bold',
        }
    },
    avatar: {
        background: theme.palette.secondary.main
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },
}));

const UserListItems = ({ users, selectedProfile, handleChange }) => {
    return users
        .map(user => (<UserListItem
            key={user.id}
            user={user}
            selectedProfile={selectedProfile}
            handleChange={() => handleChange(user)}
        />))
}

const UserListItem = ({ user, selectedProfile, handleChange, autoFocusSelected }) => {
    const classes = useStyles();
    const isSelected = selectedProfile
        && selectedProfile.type === ProfileType.user
        && selectedProfile.id === user.id;

    return (<>
        <ListItem
            selected={isSelected}
            autoFocus={autoFocusSelected && isSelected}
            className={clx({ [classes.selected]: isSelected })}
            button
            onClick={handleChange}>
            <ListItemAvatar>
                <Avatar
                    src={hasIcon(user) && user.options.primary_image_public_url}
                    name={user.name}
                    className={classes.avatar} />
            </ListItemAvatar>
            <Tooltip title={user.name}>
                <ListItemText
                    primary={user.name}
                    primaryTypographyProps={{ noWrap: true }}
                />
            </Tooltip>
        </ListItem>
    </>);
}

const mapStateToProps = state => {
    return {
        selectedProfile: selectedProfile(state)
    };
}

export default connect(
    mapStateToProps
)(UserListItems);
