import {
    ENTITY_NEXT_PAGE_RESULT, ENTITY_SEARCH_RESULT, ENTITY_NEXT_PAGE_ERROR,
    ENTITY_NEXT_PAGE_REQUEST, ENTITY_SEARCH_ERROR, ENTITY_SEARCH_REQUEST
} from "../helpers/actionTypes";

export function entitySearch(entitySet, search, filters) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_SEARCH_REQUEST,
            entitySet,
            filters
        });

        return search()
            .then(data => dispatch(entitySearchResult(entitySet, data)))
            .catch(() => dispatch({
                type: ENTITY_SEARCH_ERROR,
                entitySet
            }))
    }
}

export function entityNextPage(entitySet, search, filters) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_NEXT_PAGE_REQUEST,
            entitySet,
            filters
        });

        return search()
            .then(data => dispatch(entityNextPageResult(entitySet, data)))
            .catch(() => dispatch({
                type: ENTITY_NEXT_PAGE_ERROR,
                entitySet
            }))
    }
}

function entitySearchResult(entitySet, data) {
    const { current_page, last_page, per_page, ...rest } = data;

    return {
        type: ENTITY_SEARCH_RESULT,
        entitySet,
        currentPage: +current_page,
        lastPage: +last_page,
        perPage: +per_page,
        ...rest
    }
}

function entityNextPageResult(entitySet, data) {
    return {
        ...entitySearchResult(entitySet, data),

        type: ENTITY_NEXT_PAGE_RESULT,
    }
}
