const tourDataAttribute = 'data-tour';
const createTourStepId = (tourId, step) => `${tourId}_${step}`;

export const createTourSelector = (tourId, step) => `[${tourDataAttribute}~="${createTourStepId(tourId, step)}"]`;

// Example:
// attachTours([
//     { id: AppTour.accountSwitcher.id, step: 0 },
//     { id: AppTour.someTour.id, step: 2 }
// ])
export const attachTours = (tours) => {
    const tourStepIds = tours
        .map(t => createTourStepId(t.id, t.step))
        .join(' ');

    return { [tourDataAttribute]: tourStepIds };
};
