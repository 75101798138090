import { lucitApi } from "../services/lucitApi";
import { LogLevel } from "./constants";
import { isDevelopment } from "./environment";

export const LogLevels = Object.values(LogLevel)
    .reduce((prev, curr, idx) => ({ ...prev, [curr]: idx }), {});

export class LoggerOptions {
    disabled;
    debug;
    minLevel;

    constructor(minLevel = LogLevel.info, debug = false, disabled = false) {
        this.minLevel = minLevel;
        this.debug = debug;
        this.disabled = disabled;
    }
}

export class Logger {
    options;

    constructor(options) {
        this.options = options
    }

    log(level, message, data) {
        return this.send(level,
            message,
            data);
    }

    logDebug(message, data) {
        return this.send(LogLevel.debug,
            message,
            data);
    }

    logError(message, data) {
        return this.send(LogLevel.error,
            message,
            data);
    }

    logInformation(message, data) {
        return this.send(LogLevel.info,
            message,
            data);
    }

    send(level, message, data) {
        const logEntry = {
            level,
            message,
            data
        };

        if (this.options.disabled) {
            // eslint-disable-next-line no-undef
            return Promise.resolve();
        }

        if (this.options.minLevel && LogLevels[this.options.minLevel] > LogLevels[level]) {
            this.options.debug
                && console.info(`Skipping logEntry, because minLevel is ${this.options.minLevel}`, logEntry, this.options)

            // eslint-disable-next-line no-undef
            return Promise.resolve();
        }

        this.options.debug
            && console.log(`Sending log message.`, logEntry);

        return lucitApi.log(logEntry)
            .then(() => {
                this.options.debug
                    && console.log(`Successfuly sent log message.`, logEntry);
            })
            .catch(error => {
                this.options.debug
                    && console.error(`Failed to send log message.`, logEntry, error);
            });
    }
}

export const loggerOptions = new LoggerOptions(LogLevel.info, false, isDevelopment());
export const logger = new Logger(loggerOptions);
