import { ProfileType } from "../helpers/constants";
import { selectedAccountIds, selectedProfileIsAgency, selectedProfile } from "../selectors/user";
import { lucitApi } from "../services/lucitApi";

export function getOverview() {
    return (_, getState) => {

        if (selectedProfileIsAgency(getState())) {
            return lucitApi.analytics.getOverviewForOperator({ agency_id: selectedProfile(getState()).id });
        }
        else {
            const accountIds = selectedAccountIds(getState());

            return lucitApi.analytics.getOverview({ accountIds });
        }

    }
}

export function getDatePeriods() {
    return () => {
        return lucitApi.analytics.getDatePeriods();
    }
}

export function getDateTimeframes() {
    return () => {
        return lucitApi.analytics.getDateTimeframes();
    }
}

export function getPlaysByPeriod(period, timeframe, timeframe_opts, screenId, campaignId, options) {
    return (_, getState) => {
        const profile = selectedProfile(getState());

        return lucitApi.analytics.getPlaysByPeriod({
            agency_id: profile.type === ProfileType.agency
                ? profile.id
                : null,
            account_id: profile.type === ProfileType.account
                ? selectedAccountIds(getState())
                : null,
            period,
            timeframe,
            timeframe_opts,
            inventory_export_id: campaignId,
            digital_board_id: screenId
        }, options)
    }
}

export function getPlaysByAd(timeframe, timeframe_opts, options) {
    return (_, getState) => {

        if (selectedProfileIsAgency(getState())) {
            return lucitApi.analytics.getPlaysByAdForOperator(selectedProfile(getState()).id, timeframe, timeframe_opts, options);
        }
        else {
            const accountId = selectedAccountIds(getState());

            return lucitApi.analytics.getPlaysByAd(accountId, timeframe, timeframe_opts, options)
        }

    }
}

export function getPlaysByItem(timeframe, timeframe_opts, options) {
    return (_, getState) => {

        if (selectedProfileIsAgency(getState())) {
            return lucitApi.analytics.getPlaysByItemForOperator(selectedProfile(getState()).id, timeframe, timeframe_opts, options);
        }
        else {
            const accountId = selectedAccountIds(getState());

            return lucitApi.analytics.getPlaysByItem(accountId, timeframe, timeframe_opts, options)
        }

    }
}

export function getPlaysByCampaign(timeframe, timeframe_opts, options) {
    return (_, getState) => {

        if (selectedProfileIsAgency(getState())) {
            return lucitApi.analytics.getPlaysByCampaignForOperator(selectedProfile(getState()).id, timeframe, timeframe_opts, options);
        }
        else {
            const accountId = selectedAccountIds(getState());

            return lucitApi.analytics.getPlaysByCampaign(accountId, timeframe, timeframe_opts, options)
        }

    }
}

export function getPlaysForCampaign(exportId, timeframe, timeframe_opts, options) {
    return () => {
        return lucitApi.analytics.getPlaysForCampaign(exportId, timeframe, timeframe_opts, options)
    }
}

export function getPlaysByBillboard(timeframe, data, timeframe_opts, options) {
    return (_, getState) => {

        if (selectedProfileIsAgency(getState())) {
            return lucitApi.analytics.getPlaysByBillboardForOperator(
                selectedProfile(getState()).id,
                timeframe,
                data,
                timeframe_opts,
                options
            );
        }
        else {
            const accountId = selectedAccountIds(getState());

            return lucitApi.analytics.getPlaysByBillboard(accountId, timeframe, data, timeframe_opts, options)
        }
    }
}
