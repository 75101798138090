import React, { useCallback, useEffect } from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import { FeedStatus } from '../../helpers/constants';
import { connect } from 'react-redux';
import Tooltip from '../../components/material/Tooltip';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { getFeed, setFeedStatus } from '../../actions/feeds';
import useTimeout from '../../helpers/hooks/useTimeout';
import { feedByIdSelector, isFeedRunningOrWillBe, nextRunMilliseconds } from '../../selectors/feeds';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        imageIcon: {
            width: '100%',
            height: '100%'
        },
        done: {
            fill: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.main
        },
        error: {
            fill: theme.palette.error.main
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
    }
})

function FeedStatusIcon(props) {
    const { getFeed, setFeedStatus } = props;
    const feed = props.feed || props.feedFromState;

    const classes = useStyles();
    const [pooling, setPooling] = useState(false);
    const { t } = useTranslation();

    const refreshFeed = useCallback(() => getFeed(feed.id)
        .then(data => setPooling(isFeedRunningOrWillBe(data)))
        .catch(error => console.error("Feed Status updator error", error)),
        [getFeed, feed.id])

    const { limitExceeded, reset } = useIntervalLimited(
        refreshFeed,
        {
            running: isFeedRunningOrWillBe(feed) || pooling
        }
    );

    useEffect(() => {
        if (limitExceeded) {
            setPooling(false);
        }
    }, [limitExceeded])

    useTimeout(() => {
        reset();
        setPooling(true);
        setFeedStatus(feed.id, FeedStatus.running);
    }, nextRunMilliseconds(feed))

    return (
        <>
            {feed.status === FeedStatus.setup
                && <Tooltip title={t('Feed is currently being setting up')}>
                    <><i>in setup</i></>
                </Tooltip>}
            {feed.status === FeedStatus.ready
                && <DoneIcon
                    fontSize="small"
                    className={clx(classes.statusIcon, classes.done)} />}
            {feed.status === FeedStatus.error
                && <Tooltip title={t('An error occurred running this data source')}><ErrorIcon
                    fontSize="small"
                    className={clx(classes.statusIcon, classes.error)} />
                </Tooltip>
            }
            {isFeedRunningOrWillBe(feed)
                && <Tooltip
                    title={t('Refreshing')}>
                    <CircularProgress
                        fontSize="small"
                        style={{ width: "20px", height: "20px" }}
                        className={clx(classes.statusIcon, classes.done)}
                    />
                </Tooltip>}
        </>
    );
}

FeedStatusIcon.propTypes = {
    feedId: PropTypes.number,
    feed: PropTypes.object,
}

const mapStateToProps = (state, props) => {
    return {
        feedFromState: feedByIdSelector(state)(props.feedId) || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFeed: id => dispatch(getFeed(id)),
        setFeedStatus: (id, status) => dispatch(setFeedStatus(id, status))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FeedStatusIcon
);
