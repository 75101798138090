import {
    USER_ACCOUNT_SET_IMAGE, ACCOUNT_UPDATE, USER_ACCOUNT_REMOVE_IMAGE,
    ACCOUNT_DELETE, ACCOUNT_SCREENS, ACCOUNT_JOIN, ACCOUNT_ADD
} from "../helpers/actionTypes"
import { selectedAccountId } from "../selectors/user"
import { lucitApi } from "../services/lucitApi"
import { uploadImage } from "./uploads"
import { ProfileType } from "../helpers/constants"

export function createAccount(account) {
    return async dispatch => {
        const accountCreated = await lucitApi.accounts.create(account);

        dispatch({
            type: ACCOUNT_ADD,
            account: accountCreated
        })

        return accountCreated;
    }
}

export function setPrimaryImage(fileBase64) {
    return (dispatch, getState) => {
        return dispatch(uploadImage(fileBase64))
            .then(image => {

                const accountId = selectedAccountId(getState());

                return lucitApi.accounts.setPrimaryImage(accountId, image.id)
                    .then(account => dispatch({
                        type: USER_ACCOUNT_SET_IMAGE,
                        accountId,
                        options: account.options
                    }))
            })
    }
}

export function deletePrimaryImage() {
    return (dispatch, getState) => {

        const accountId = selectedAccountId(getState());

        return lucitApi.accounts.deletePrimaryImage(accountId)
            .then(() => dispatch({
                type: USER_ACCOUNT_REMOVE_IMAGE,
                accountId
            }))
    }
}

export function changeAccountDetails(name, description, website, newCreativesAndPostsAlwaysInDraftState, removeBackgroundsFromAllPrimaryImages) {
    return (dispatch, getState) => {

        const accountId = selectedAccountId(getState());

        return lucitApi.accounts.update(accountId, name, description, website, newCreativesAndPostsAlwaysInDraftState, removeBackgroundsFromAllPrimaryImages)
            .then((account) => dispatch({
                type: ACCOUNT_UPDATE,
                account: account
            }))
    }
}

export function addInventoryItemClass(itemClass) {
    return (dispatch, getState) => {

        const accountId = selectedAccountId(getState());

        return lucitApi.accounts.addInventoryItemClass(accountId, itemClass)
            .then((account) => dispatch({
                type: ACCOUNT_UPDATE,
                account: account
            }))
    }
}

export function removeInventoryItemClass(itemClass) {
    return (dispatch, getState) => {

        const accountId = selectedAccountId(getState());

        return lucitApi.accounts.removeInventoryItemClass(accountId, itemClass)
            .then((account) => dispatch({
                type: ACCOUNT_UPDATE,
                account: account
            }))
    }
}

export function joinAccount(lcuid, force_join_child) {
    return dispatch => {
        return lucitApi.accounts.join(lcuid, force_join_child)
            .then(account => {

                const newAccount = {
                    ...account,
                    type: ProfileType.account
                }

                dispatch({ type: ACCOUNT_JOIN, account: newAccount })
                return newAccount;
            })
    }
}

export function deleteAccount(account) {
    return dispatch => {
        return lucitApi.objects.delete(account.lcuid)
            .then(() => dispatch({
                type: ACCOUNT_DELETE,
                account
            }))
    }
}

export function getAccountScreens(account) {
    return dispatch => {
        return lucitApi.accounts.getScreens(account.lcuid)
            .then(screens => dispatch({
                type: ACCOUNT_SCREENS,
                account,
                screens
            }))
    }
}
