import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import LCTypography from '../../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { lucitApi } from '../../../services/lucitApi'
import { RoutePaths } from '../../../helpers/constants';
import { getItem } from '../../../actions/inventory';
import InventoryItemPhotoGallery from './InventoryItemPhotoGallery';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { userAccounts } from '../../../selectors/user';
import { selectAccount } from '../../../actions/user';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        }
    }
})

const InventoryItem = (props) => {
    const { item, userAccounts } = props;
    const [exportObject, setExportObject] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        //Since this is a ChildCampaign, this InventoryItem actually represents an InventoryExport in a Child Account of the current account
        const exportId = item.unique_id;

        lucitApi.exports.getExport(exportId).then((data) => {
            setExportObject(data)
        })
    }, [item])

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const accountFromId = exportObject && userAccounts.find(acc => acc.id === exportObject.account_id)

    const accountIsShadowAccount = exportObject
        && exportObject.account
        && exportObject.account.account_class == 'App\\LuCore\\Accounts\\OperatorShadowAccountClass'

    const switchToAccountCampaign = () => {

        if (accountIsShadowAccount) {
            const url = RoutePaths.exports + `/${exportObject.id}`

            history.push(url)
            return
        }

        if (!accountFromId) {
            alert(
                "You do not have access to the account for "
                + item.title
                + " You may need top request that the owner of this account invite you to it"
            )
            return
        }

        dispatch(selectAccount(accountFromId,
            {
                animate: true,
                onSwitched: () => history.push({ pathname: RoutePaths.exports + `/${exportObject.id}` })
            }))
    }

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <InventoryItemPhotoGallery item={item} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <Typography variant="h5">{item.title}</Typography>

                    <Box className={classes.attribute}>
                        <Box component={LCTypography} className={classes.attributeValue}>
                            <br />
                            <LCTypography variant="body2">
                                This is a sub campaign that contains a group of ads that you can edit
                            </LCTypography>
                            <br />

                            {exportObject &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={switchToAccountCampaign}
                                >{t('Edit Campaign')}</Button>
                            }

                        </Box>
                    </Box>

                </Box>
            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        userAccounts: userAccounts(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
