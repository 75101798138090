import React, { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, Grid, TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { lucitApi } from '../../services/lucitApi';
import { ButtonLoader, Dialog } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { AGENCY_EXPORTS, LIGHTNING_DEVICE_UPDATED } from '../../helpers/actionTypes';
import { Alert } from '@material-ui/lab';
import VenueTypeDropDown from '../../components/billboards/VenueTypeDropDown';
import { LightningDeviceLayoutSelector } from './LightningDeviceLayoutSelector';
import { loadAgencyDigitalBoards } from '../../actions/lightning';
import { GenericDialog } from '../../components/modals/GenericDialog';
import LCTypography from '../../components/material/LCTypography';
import { StepSetDeviceLocation } from './NewDeviceDialog';

const useStyles = makeStyles(theme => {
    return {
        content: {
            minHeight: 180,
            padding: theme.spacing(1, 2.5),
        }
    }
})

export const DeviceEditDialog = ({ device, open, handleClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const deviceLayouts = useSelector((state) => state.lightning.deviceLayouts);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(device.name);
    const [description, setDescription] = useState(device.description);
    const [venueTypeId, setVenueTypeId] = useState(device.venue_taxonomy_id);
    const [deviceLayoutLcuid, setDeviceLayoutLcuid] = useState(device.lightning_device_layout?.lcuid)
    const [deviceLayoutsOpen, setDeviceLayoutsOpen] = useState(false)
    const [deviceLocation, setDeviceLocation] = useState(device)
    const [error, setError] = useState(null)

    const updateDevice = () => {
        const promises = [];

        if (device.lightning_device_layout?.lcuid != deviceLayoutLcuid) {
            promises.push(
                lucitApi.lightning.changeDeviceLayout(device.lcuid, {
                    width: device.width,
                    height: device.height,
                    lightning_device_layout_lcuid: deviceLayoutLcuid
                }).then((response) => {
                    dispatch({
                        type: LIGHTNING_DEVICE_UPDATED,
                        device: response.lightning_device
                    })

                    return Promise.all([
                        dispatch(loadAgencyDigitalBoards(device.agency_id)),
                        lucitApi.agencies.getExports(device.agency_id)
                            .then(exports => dispatch({ type: AGENCY_EXPORTS, exports }))
                    ]);
                })
            )
        }

        if (device.name != name ||
            device.venue_taxonomy_id != venueTypeId ||
            device.description != description) {
            promises.push(
                lucitApi.lightning
                    .updateDevice(device.id,
                        {
                            name,
                            description,
                            venue_taxonomy_id: venueTypeId,
                            latitude: deviceLocation.latitude,
                            longitude: deviceLocation.longitude,
                        })
                    .then((response) => dispatch({
                        type: LIGHTNING_DEVICE_UPDATED,
                        device: response.lightning_device
                    }))
            );
        }

        if (device.latitude != deviceLocation.latitude ||
            device.longitude != deviceLocation.longitude) {
            promises.push(
                lucitApi.lightning
                    .moveDevice(device.lcuid,
                        {
                            latitude: deviceLocation.latitude,
                            longitude: deviceLocation.longitude,
                        })
                    .then((response) => dispatch({
                        type: LIGHTNING_DEVICE_UPDATED,
                        device: response.lightning_device
                    }))
            );
        }

        setLoading(true)
        return Promise.all(promises)
            .then(handleClose)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            title={t("Edit")}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <StepSetDeviceLocation
                            device={deviceLocation}
                            onDone={setDeviceLocation} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box maxWidth={600}>
                            <TextField
                                label={t('Device Name')}
                                variant="outlined"
                                autoFocus
                                fullWidth
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <TextField
                                style={{ marginTop: 16 }}
                                label={t('Notes')}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Box>

                        <Box mt={2}>
                            <VenueTypeDropDown
                                setVenueId={(venueTypeId) => setVenueTypeId(venueTypeId)}
                                initialVenueId={device.venue_taxonomy_id} />
                        </Box>

                        <Box mt={2} display="flex" style={{ gap: 8 }}>
                            <TextField
                                label={t('Width')}
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                defaultValue={device.width}
                            />
                            <TextField
                                label={t('Height')}
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                defaultValue={device.height}
                            />
                        </Box>

                        <Box mt={2} mb={1} display="flex" alignItems="center">
                            <LCTypography>
                                <b>Device Layout:&nbsp;</b>
                                {deviceLayouts.find(l => l.lcuid == deviceLayoutLcuid)?.name ?? '(not-set)'}
                            </LCTypography>
                            <Button
                                onClick={() => setDeviceLayoutsOpen(true)}
                                size="small"
                                variant='contained'
                                style={{ marginLeft: 8 }}>{t('Change')}</Button>

                            <GenericDialog
                                title={t('Device Layouts')}
                                dialogProps={{
                                    open: deviceLayoutsOpen,
                                    handleClose: () => setDeviceLayoutsOpen(false),
                                    fullWidth: true,
                                    maxWidth: 'sm'
                                }}
                                ContentComponent={<>
                                    <LightningDeviceLayoutSelector device={device}
                                        value={deviceLayoutLcuid}
                                        setValue={lcuid => {
                                            setDeviceLayoutLcuid(lcuid);
                                            setDeviceLayoutsOpen(false);
                                        }} />
                                </>}
                                ActionsComponent={<>
                                    <Button onClick={() => setDeviceLayoutsOpen(false)} color="primary" variant='outlined'>
                                        {t('Close')}
                                    </Button>
                                </>}
                            />
                        </Box>

                        {deviceLayoutLcuid != device.lightning_device_layout?.lcuid
                            && <Alert severity='warning'>
                                {t(`Changing the layout of this device, will remove and re-create all the screens.
                        Are you sure you want to do this?`)
                                }
                            </Alert>}
                        {error &&
                            <Alert severity="error">{
                                error.response?.data?.message || error.message}
                            </Alert>}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='outlined'>
                    {t('Close')}
                </Button>
                <ButtonLoader
                    submitting={loading}
                    onClick={updateDevice}
                    color="primary"
                    variant="contained">
                    {t('Save')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}
