import { Box, TextField, Grid, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    addBoardFormat, addInventoryItemClass, removeBoardFormat, removeInventoryItemClass,
    setTemplateCropRatio, setTemplateDescription, setTemplateName
} from '../../actions/designer';
import LCTypography from '../../components/material/LCTypography';
import {
    boardFormats, driveTemplateLcuid, hasBoardFormat, hasInventoryItemClass, primary_image_public_url,
    templateDescription, templateName, templatePhotoCropAspectRatio
} from '../../selectors/designer';
import { lucitApi } from '../../services/lucitApi';
import { useTranslation } from 'react-i18next';
import BoardFormatToggleButton from '../../containers/billboards/BoardFormatToggleButton'
import { hasCampaignWithBoardFormatCode } from '../../selectors/exports';
import { ChipList } from '../../components';

const DesignerSettings = ({ driveTemplateLcuid, primary_image_public_url,
    templateDescription, setTemplateDescription,
    photoCropAspectRatio, setTemplateCropRatio,
    addInventoryItemClass, removeInventoryItemClass, hasInventoryItemClass,
    boardFormats, addBoardFormat, removeBoardFormat, hasBoardFormat, hasCampaignWithBoardFormatCode }) => {

    const [itemClasses, setItemClasses] = useState([]);
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        lucitApi.inventory.getInventoryItemClasses().then((itemClasses) => {
            setItemClasses(itemClasses
                .filter((itemClass) => itemClass.can_have_assigned_drive_template)
                .map(i => ({ ...i, id: i.class, label: i.class_description })));
        })
    }, [])

    return (
        <Box>
            <Box mb={2}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="General" />
                    <Tab label="Advanced" />
                </Tabs>
            </Box>

            {selectedTab === 0 && (
                <>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box style={{ textAlign: "center" }}>
                                    {primary_image_public_url &&
                                        <img style={{ width: "100%", borderRadius: "4px", marginRight: "5px" }}
                                            src={primary_image_public_url} />
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box mt={1} mb={2}>
                                    <TextField
                                        label={t('Keywords & Hashtags')}
                                        id="template-description"
                                        fullWidth
                                        variant="outlined"
                                        autoComplete='off'
                                        multiline
                                        rows={2}
                                        value={templateDescription ?? ""}
                                        placeholder={t('A description and keywords will help make this template easy to find')}
                                        onChange={(e) => setTemplateDescription(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box mt={2}>
                                    <LCTypography variant="subtitle1"><b>Board Formats</b></LCTypography>
                                    <LCTypography variant="caption">Choose the sizes you want to build this template for.
                                        Green icons represent sizes that match screens in your current campaign(s)</LCTypography>
                                    <br />
                                    {Object.keys(boardFormats).map((boardFormatCode, index) => {
                                        return (
                                            <BoardFormatToggleButton
                                                key={index}
                                                boardFormatCode={boardFormatCode}
                                                boardFormat={boardFormats[boardFormatCode]}
                                                isSelected={hasBoardFormat(boardFormatCode)}
                                                hasCampaignWithBoardFormatCode={hasCampaignWithBoardFormatCode(boardFormatCode)}
                                                onClick={() => {
                                                    if (hasBoardFormat(boardFormatCode)) {
                                                        removeBoardFormat(boardFormatCode)
                                                    } else {
                                                        addBoardFormat(boardFormatCode)
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            {selectedTab === 1
                && <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box mb={2}>
                                <LCTypography variant="subtitle1"><b>Inventory Types</b></LCTypography>
                                <LCTypography variant="caption">Choose POST to have this template appear during the post ad process,
                                    select any other classes to make this eligible for data feed connected templates</LCTypography>
                                <br />
                                <ChipList
                                    items={itemClasses.filter(i => hasInventoryItemClass(i.class))}
                                    itemsToAdd={itemClasses.filter(i => !hasInventoryItemClass(i.class))}

                                    onAdd={(item) => addInventoryItemClass(driveTemplateLcuid, item.class)}
                                    onDelete={(item) => removeInventoryItemClass(driveTemplateLcuid, item.class)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <LCTypography variant="subtitle1"><b>Other Settings</b></LCTypography>
                            <Box mt={2}>
                                <TextField
                                    label={t('Photo Crop Ratio')}
                                    autoComplete='off'
                                    variant="outlined"
                                    id="template-photo-crop-ratio"
                                    value={photoCropAspectRatio ? photoCropAspectRatio : ""}
                                    placeholder={"1"}
                                    onChange={(e) => setTemplateCropRatio(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </>}

        </Box >
    )
}

const mapStateToProps = state => {
    return {
        driveTemplateLcuid: driveTemplateLcuid(state),
        primary_image_public_url: primary_image_public_url(state),
        templateName: templateName(state),
        templateDescription: templateDescription(state),
        photoCropAspectRatio: templatePhotoCropAspectRatio(state),
        boardFormats: boardFormats(state),
        hasBoardFormat: (boardFormatCode) => hasBoardFormat(state, boardFormatCode),
        hasInventoryItemClass: (itemClass) => hasInventoryItemClass(state, itemClass),
        hasCampaignWithBoardFormatCode: (boardFormatCode) => hasCampaignWithBoardFormatCode(state, boardFormatCode),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setTemplateName: (name) => dispatch(setTemplateName(name)),
        setTemplateDescription: (description) => dispatch(setTemplateDescription(description)),
        setTemplateCropRatio: (cropRatio) => dispatch(setTemplateCropRatio(cropRatio)),
        addInventoryItemClass: (driveTemplateLcuid, itemClass) => dispatch(addInventoryItemClass(driveTemplateLcuid, itemClass)),
        removeInventoryItemClass: (driveTemplateLcuid, itemClass) => dispatch(removeInventoryItemClass(driveTemplateLcuid, itemClass)),
        addBoardFormat: (boardFormatCode) => dispatch(addBoardFormat(boardFormatCode)),
        removeBoardFormat: (boardFormatCode) => dispatch(removeBoardFormat(boardFormatCode)),
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerSettings
    );
