import React from 'react';
import ReactDOM from 'react-dom';
import './helpers/console.overrides';
import './helpers/globalErrorHandler';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import App from './App';
import './assets/styles/index.scss';
import * as updateWorker from './updateWorker';
import reportWebVitals from './reportWebVitals';

import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));

// Update worker (checking for new versions)
updateWorker.register({
    platforms: ["android", "ios"],
    // 1 hour
    intervalInMs: 60 * 60 * 1000
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
