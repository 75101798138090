import { apiHttp } from "../helpers/api";

export default class LucitApiFacebook {

    getLongTermAccessToken(fb_exchange_token) {
        return apiHttp.get(`/facebook/long-term-access-token`, {
            params: {
                fb_exchange_token: fb_exchange_token
            }
        })
            .then(response => response.data.facebook)
    }

    getAccounts(user_id, access_token) {
        return apiHttp.get(`/facebook/accounts`, {
            params: {
                user_id: user_id,
                access_token: access_token
            }
        })
            .then(response => response.data.facebook)
    }
}
