import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';
import { Container, makeStyles, Box, Grid, Avatar, Hidden, Link, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clx from 'classnames';
import { IonCard, IonModal, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonContent } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import BillboardsMap from '../../containers/dashboard/BillboardsMap';
import { useTranslation } from 'react-i18next';
import { lucitApi } from "../../services/lucitApi";
import { RoutePaths } from '../../helpers/constants';
import Alert from '@material-ui/lab/Alert';
import { useRef } from 'react';
import { useCallback } from 'react';
import { debounce } from '../../helpers/async';
import { getAppName } from '../../helpers/environment'

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: '100%',
        flex: 1,
        display: 'flex',
    },
    homeGridContainer: {
        height: '100%',
        display: 'flex',
    },
    homeMapCard: {
        flex: 1,
    },
    card: {
        maxWidth: 600,
        width: '100%',
        marginBottom: "15px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    appWelcomeMessageHeader: {
        backgroundColor: '#000000',
        color: 'white'
    },
    infiniteScrollContainer: {
        height: 'calc(100vh - 64px)',
        minWidth: '300px',
        overflow: 'scroll',
    },
    content: {
        padding: 0,
        marginTop: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    rightSideContainer: {
        minWidth: '300px',
    },
    itemMap: {
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0
        },
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    feedProviderItem: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    feedProvidedGrid: {
        marginTop: 10
    },
    modalWindow: {
        marginTop: '10%',
        zIndex: '1300 !important',
        '&::part(handle)': {
            width: 150,
            height: 6,
            background: '#02c9c9',

            '&::before': {
                width: 150,
                paddingTop: 16,
                paddingBottom: 24
            }
        }
    },
    homeGridItem: {
        maxWidth: '100%'
    },
    appMapAlert: {
        position: 'absolute',
        zIndex: 1,
        margin: 10,
        backgroundColor: 'black',
        color: 'white',
    },
    appMobileMapAlert: {
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '10px 10px 0px 0px',
    }
}));

const DataFeedProviders = () => {

    const classes = useStyles();

    const [providers, setProviders] = useState([]);

    useEffect(() => {
        lucitApi.feedProviders.getFeedProviders(false,{per_page:1000}).then((response) => {
            response && setProviders(response.data);
        })
    }, [])

    return <Grid container spacing={2} className={classes.feedProvidedGrid}>
        {providers.filter(a=>a.application?.slug != 'LucitLegacyDataSources').sort((a,b)=>{
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return
        }).map((provider, index) => {
            return <Grid key={index} item xs={4} className={classes.feedProviderItem}>
                <Link
                    component={RouterLink}
                    to={`/${provider.application?.slug}`}
                    color="secondary">

                    <Avatar src={provider.options.primary_image_public_url} className={classes.large} />
                    <IonCardSubtitle>{provider.name}</IonCardSubtitle>
                </Link>
            </Grid>
        })}
    </Grid>

}

function StoryFeedPublic() {
    const { t } = useTranslation();
    const modalRef = useRef();

    const classes = useStyles();

    const swipeDownDebounced = useCallback(debounce((scrollTop) =>
        scrollTop == 0 && modalRef.current.setCurrentBreakpoint(0.1), 200),
        [modalRef.current])

    return <>

        <Hidden mdUp>
            <PublicHomeMap />

            <IonModal
                ref={modalRef}
                isOpen={true}
                initialBreakpoint={0.45}
                breakpoints={[0.1, 0.45, 1]}
                backdropDismiss={false}
                backdropBreakpoint={0.1}
                keepContentsMounted
                handleBehavior="cycle"
                className={classes.modalWindow}
            >
                <IonContent
                    forceOverscroll={true}
                    scrollEvents={true}
                    onIonScroll={e => {
                        swipeDownDebounced(e.detail.scrollTop);
                    }}
                >
                    <Alert severity="info" className={classes.appMobileMapAlert}>
                        {t('Luxit XR is now available! Click any billboard to get started!')}
                    </Alert>
                    <PublicHomeContentSideBar />
                </IonContent>
            </IonModal>
        </Hidden>

        <Hidden smDown>
            <Grid
                container
                alignItems="stretch"
                className={classes.homeGridContainer}
            >
                <IonCard className={classes.homeMapCard}>
                    <Alert severity="info" className={classes.appMapAlert}>
                        {t('Luxit XR is now available! Click any billboard to get started!')}
                    </Alert>
                    <PublicHomeMap />
                </IonCard>

                <Grid item xs={12} md={4} className={classes.homeGridItem}>
                    <Box className={classes.infiniteScrollContainer} >
                        <PublicHomeContentSideBar />
                    </Box>
                </Grid>
            </Grid>
        </Hidden>

    </>;
}

const PublicHomeMap = () => {
    const classes = useStyles();

    const center = useMemo(() => ({
        lat: 38.116386,
        lng: -101.299591,
        zoom: 4.25
    }), [])

    return <Container className={clx(classes.content, classes.itemMap)}>
        <BillboardsMap
            promptGeoPermissions={false}
            centeringType='custom'
            showCenterButton={false}
            center={center}
            streetViewOnly={true}
        />
    </Container>
}

const PublicHomeContentSideBar = () => {
    const { t } = useTranslation();

    return <>
        <IonCard>
            <IonImg src="/assets/images/coverPhoto.jpg" />
            <IonCardHeader>
                <IonCardTitle>{t('Welcome to ' + getAppName())}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <LCTypography variant="body1">The leading platform for digital billboard data integrations</LCTypography>
                <ul>
                    <li>
                        {t('Easily see how your ad will look on any screen using LucitXR!')}
                        <Link
                            href={RoutePaths.public.xr}
                            rel="noopener noreferrer"
                            color="secondary">
                            {t(' Create a proof today')}
                        </Link>
                    </li>
                    <li>
                        {t('Connect digital billboard screens to data feeds from auto dealerships')}
                        {t(', real estate brokerages, eCommerce and news feeds')}
                        <Link
                            href="https://lucit.cc/advertisers"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary">
                            {t(' Learn more')}
                        </Link>
                    </li>
                    <li>
                        {t('Control all aspects of your advertising campaigns, across digital billboard companies, ')}
                        <Link
                            href="https://lucit.cc/"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary">
                            {t('from a single interface')}
                        </Link>
                    </li>
                    <li>
                        {t('Screen owners, connect your players, screens, and your sales teams with Lucit')}
                        <Link
                            href="https://lucit.cc/operators"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary">
                            {t(' Get started')}
                        </Link>
                    </li>
                </ul>

                <Box mt={2} pl={2} pr={2}>
                    <Button
                        variant="outlined"
                        fullWidth
                        component={RouterLink}
                        to={RoutePaths.login}>
                        {t('Sign in')}
                    </Button>
                </Box>

                <Box mt={2} pl={2} pr={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        component={RouterLink}
                        to={RoutePaths.registrationNew}>
                        {t('Sign Up')}
                    </Button>
                </Box>

            </IonCardContent>
        </IonCard>

        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{t('Data Partners')}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <LCTypography variant="h5">
                    Connect Digital Billboards to data from multiple partner sources and data providers
                </LCTypography>

                <Box>

                    <DataFeedProviders />

                </Box>

                <Box mt={1}>
                    View all Lucit Applications at <Link
                        component={RouterLink}
                        to="/apps"
                        color="secondary">
                        Lucit Apps List
                    </Link>
                </Box>

            </IonCardContent>
        </IonCard>

        <IonCard>
            <IonCardHeader>
                <IonCardTitle>{t('Learn More')}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {t('For more information, or for help and support, ')}
                <Link
                    variant="body2"
                    color="secondary"
                    component={RouterLink}
                    to={RoutePaths.contact}>
                    {t('Contact us ')}
                </Link>
                or <Link
                    href="https://lucit.cc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary">
                    {t('visit our website at lucit.cc')}
                </Link>

            </IonCardContent>
        </IonCard>

    </>
}

const StoryFeedPublicConnected = connect(
)(StoryFeedPublic);

export default withConditionalLayout(StoryFeedPublicConnected, {
    publicOptions: { disablePaper: true }
})
