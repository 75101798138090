import React from 'react';
import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { reduxForm, Field, reset } from 'redux-form';
import { connect } from 'react-redux';

import TextField from '../../components/inputs/TextField';
import { required, email } from '../../helpers/validators';
import { submitHelpRequest } from '../../actions/help';
import { getUser } from '../../selectors/user';
import { showSuccess } from '../../actions/snackbar';
import PhoneInput, { getFullPhone } from '../../components/inputs/PhoneInput';
import { DefaultCountry } from '../../helpers/countries';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 0)
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));

const ContactForm = (props) => {
    const { handleSubmit, error, submitting, user } = props;
    const classes = useStyles();

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(props.submitHelpRequest)}>
            {!user.isLoggedIn
                && <>
                    <Field
                        name="name"
                        validate={[required]}
                        component={TextField}
                        props={{
                            label: "Your name",
                            variant: "outlined",
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            id: "name",
                            autoComplete: "name"
                        }}
                    />
                    <Field
                        name="email"
                        validate={[required, email]}
                        component={TextField}
                        props={{
                            label: "Email Address",
                            variant: "outlined",
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            id: "email",
                            autoComplete: "email"
                        }}
                    />
                    <Field
                        name="phone"
                        validate={[required]}
                        component={PhoneInput}
                        props={{
                            id: "phone",
                            required: true
                        }}
                    />
                </>}
            <Field
                name="message"
                validate={required}
                component={TextField}
                props={{
                    label: "Message",
                    multiline: true,
                    rows: "4",
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "message"
                }}
            />
            <Typography color="error">
                {error}
            </Typography>
            {submitting
                && <Box className={classes.progress}>
                    <CircularProgressCentered />
                </Box>}

            <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={handleSubmit(props.submitHelpRequest)}
                className={classes.submit}>Send</Button>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        initialValues: getUser(state),
        user: getUser(state),
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitHelpRequest: values => {
            const data = {
                ...values,
                phone: getFullPhone(values.phone)
            }

            return dispatch(submitHelpRequest(data))
                .then(data => {
                    dispatch(reset(FORM_CONTACT));

                    if (ownProps.onSubmitted) {
                        return ownProps.onSubmitted(data);
                    }

                    dispatch(showSuccess(`Your message was successfully sent. Ticket with number #${data.ticket_number} was created.`));
                });
        }
    };
}

export const FORM_CONTACT = "login";
export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            enableReinitialize: true,
            form: FORM_CONTACT, // a unique name for this form
            initialValues: {
                phone: { countryCode: DefaultCountry.code, phoneCode: DefaultCountry.phone, phoneNumber: '' }
            }
        })(ContactForm)
    );
