import { apiHttp } from "../helpers/api";

export default class LucitApiDriveTemplates {

    index(filters) {
        return apiHttp.get('/drive-templates', {
            params: filters
        })
            .then(response => response.data.drive_templates)
    }

    publicTemplates(filters) {
        return apiHttp.get('/drive-templates/public', {
            params: filters
        })
            .then(response => response.data.drive_templates)
    }

    templateAssets() {
        return apiHttp.get('/drive-templates/template-assets',)
            .then(response => response.data.template_assets)
    }

    get(driveTemplateId) {
        return apiHttp.get('/drive-templates/' + driveTemplateId)
            .then(response => response.data.drive_template)
    }

    create(params) {
        return apiHttp.post('/drive-templates', params)
            .then(response => response.data.drive_template);
    }

    generatePrimaryImageFromHtml(driveTemplateId, params) {
        return apiHttp.post('/drive-templates/' + driveTemplateId + '/generate-primary-image-from-html', params)
            .then(response => response.data.lhhc);
    }

    update(driveTemplateId, params) {
        return apiHttp.put('/drive-templates/' + driveTemplateId, params)
            .then(response => response.data.drive_template);
    }

    publish(driveTemplateId) {
        return apiHttp.put('/drive-templates/' + driveTemplateId + '/publish')
            .then(response => response.data.drive_template);
    }

    unPublish(driveTemplateId) {
        return apiHttp.put('/drive-templates/' + driveTemplateId + '/un-publish')
            .then(response => response.data.drive_template);
    }

    remove(driveTemplateId) {
        return apiHttp.put('/drive-templates/' + driveTemplateId + '/remove')
            .then(response => response.data.drive_template);
    }

    makePublic(driveTemplateId) {
        return apiHttp.put('/drive-templates/' + driveTemplateId + '/make-public')
            .then(response => response.data.drive_template);
    }

    makePrivate(driveTemplateId) {
        return apiHttp.put('/drive-templates/' + driveTemplateId + '/make-private')
            .then(response => response.data.drive_template);
    }

    duplicate(driveTemplateId, params) {
        return apiHttp.post('/drive-templates/' + driveTemplateId + '/duplicate', params)
            .then(response => response.data.drive_template);
    }

    delete(driveTemplateId) {
        return apiHttp.post('/drive-templates/' + driveTemplateId)
            .then(response => response.data.success);
    }

    addInventoryItemClass(driveTemplateId, itemClass) {
        return apiHttp.post(`/drive-templates/${driveTemplateId}/inventory-item-class`, {
            inventory_item_class: itemClass
        })
            .then(response => response.data.drive_template)
    }

    removeInventoryItemClass(driveTemplateId, itemClass) {
        return apiHttp.put(`/drive-templates/${driveTemplateId}/inventory-item-class`, {
            inventory_item_class: itemClass
        })
            .then(response => response.data.drive_template)
    }

    getFieldMap(lcuid) {
        return apiHttp.get(`/drive-templates/${lcuid}/field-map`)
            .then(response => response.data.field_map)
    }
}
