import React, { useState } from 'react';
import clx from 'classnames';
import { Box, Link, makeStyles } from '@material-ui/core';
import { required } from '../../helpers/validators';
import TextField from './TextField';
import { Field } from 'redux-form';
import useInterval from '../../helpers/hooks/useInterval';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        resendCode: {
            marginTop: 8
        },
        resendCodeDisabled: {
            color: theme.palette.grey[600],
            cursor: 'not-allowed'
        }
    }
});

const OneTimeCodeField = ({ style, resendCode }) => {
    const classes = useStyles();

    const [isSendingCode, setIsSendingCode] = useState(false);
    const [resendWaitTime, setResendWaitTime] = useState(30);
    const { t } = useTranslation();

    // null - means disable
    const resendWaitTimeInterval = resendWaitTime > 0
        ? 1000
        : null;

    const canResend = resendWaitTime === 0 && !isSendingCode;

    // eslint-disable-next-line
    useInterval(() => {
        setResendWaitTime(resendWaitTime - 1);
    }, resendWaitTimeInterval);

    return <Box>
        <Field
            style={style}
            name="code"
            validate={[required]}
            component={TextField}
            props={Object.assign({
                label: t("Confirmation Code"),
                variant: "outlined",
                required: true,
                fullWidth: true,
                id: "code",
                autoComplete: "one-time-code",
                inputProps: {
                    maxLength: 5
                }
            })}
        />
        {resendCode && <Link
            component="button"
            type='button'
            color="secondary"
            className={clx(classes.resendCode, { [classes.resendCodeDisabled]: !canResend })}
            disabled={!canResend}
            onClick={() => {
                setIsSendingCode(true);
                resendCode()
                    .finally(() => {
                        setIsSendingCode(false);
                        setResendWaitTime(60)
                    })
            }}
        >{resendWaitTime > 0 && `(${resendWaitTime})`} {t('Resend Code.')}</Link>}
    </Box>
}

export default React.forwardRef((props, ref) => <OneTimeCodeField {...props} forwardedRef={ref} />);
