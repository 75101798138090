import {
    PROGRAMMATIC_FILTERS_CHANGE, PROGRAMMATIC_VIEWPORT_CHANGE, PROGRAMMATIC_LOCATIONS_LOADED, PROGRAMMATIC_LOCATIONS_LOADING_CHANGE,
    PROGRAMMATIC_INITIAL_VIEW_CHANGE,
    PROGRAMMATIC_AGENCIES_LOADED, PROGRAMMATIC_BOARD_FORMATS_LOADED, PROGRAMMATIC_VENUE_TYPES_LOADED,
    PROGRAMMATIC_AGENCY_COUNTS_LOADED, PROGRAMMATIC_BOARD_FORMAT_COUNTS_LOADED, PROGRAMMATIC_VENUE_TYPE_COUNTS_LOADED,
    PROGRAMMATIC_BUDGET_CHANGE, PROGRAMMATIC_BUDGET_ALLOCATION_CHANGE, PROGRAMMATIC_DATES_CHANGE, PROGRAMMATIC_FILTERS_INIT,
    PROGRAMMATIC_STATS_LOADING,
    PROGRAMMATIC_STATS_LOADED,
    PROGRAMMATIC_CREATIVE_UPLOADED,
    PROGRAMMATIC_RESET,
    PROGRAMMATIC_INIT,
    PROGRAMMATIC_CREATIVE_DELETED
} from "../helpers/actionTypes";
import sessionStorage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createTransform } from 'redux-persist';
import JSOG from 'jsog'

import { ProgrammaticBudgetTypes, InventoryExportCampaignClasses, ProgrammaticMapSettings } from "../helpers/constants";
import moment from "moment";

export const initialState = {
    campaignLcuid: null,
    editMode: false,
    locations: [],
    locationsLoading: false,
    stats: null,
    statsLoading: false,
    initial_view_active: true,
    placeId: null,
    viewport: {},
    zoom: ProgrammaticMapSettings.defaultZoom,
    geo: [],

    // Uploaded images
    creative_images: [],

    //These are the selected filters
    venueTypes: [],
    boardFormats: [],
    agencies: [],

    //These are all of the options for the filters
    board_format_options: [],
    venue_type_options: [],
    agency_options: [],

    budget: {
        amount: 100,
        budget_type: ProgrammaticBudgetTypes.daily.code,
    },

    budget_allocation: [],

    dates: {
        touched: false,
        startDate: new Date().toISOString(),
        endDate: moment()
            .add(ProgrammaticBudgetTypes.daily.budgetParams.defaultDuration, 'days')
            .toDate()
            .toISOString()
    }
}

const filtersFields = [
    'initial_view_active', 'viewport',
    'geo', 'venueTypes',
    'boardFormats', 'agencies',
    'budget', 'budget_allocation',
    'creative_images', 'dates'];

const JSOGTransform = createTransform(
    (inboundState) => JSOG.encode(inboundState),
    (outboundState) => JSOG.decode(outboundState),
)

export const programmaticPersistConfig = {
    key: 'programmatic',
    whitelist: filtersFields,
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    transforms: [JSOGTransform]
}

export default function programmatic(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case PROGRAMMATIC_RESET: {
            return Object.assign({},
                state,
                filtersFields.reduce((prev, field) => ({
                    ...prev,
                    [field]: initialState[field],
                    placeId: null
                }), {})
            )
        }
        case PROGRAMMATIC_STATS_LOADING:
            return Object.assign({}, state, {
                statsLoading: payload.loading,
            });
        case PROGRAMMATIC_STATS_LOADED:
            return Object.assign({}, state, {
                stats: payload.stats,
            });

        case PROGRAMMATIC_LOCATIONS_LOADED:
            return Object.assign({}, state, {
                locations: payload.locations,
                locationsLoading: false
            });

        case PROGRAMMATIC_VIEWPORT_CHANGE: {
            return Object.assign({}, state, {
                viewport: payload.viewport ?? state.viewport,
                zoom: payload.zoom ?? state.zoom
            });
        }

        case PROGRAMMATIC_CREATIVE_UPLOADED:
            return Object.assign({}, state, {
                creative_images: [...state.creative_images, payload.image]
            });
        case PROGRAMMATIC_CREATIVE_DELETED:
            return Object.assign({}, state, {
                creative_images: state.creative_images.filter(i => i.lcuid !== payload.lcuid)
            });

        case PROGRAMMATIC_INIT: {
            const exportItem = payload;

            if (exportItem.campaign_class !== InventoryExportCampaignClasses.lucitProgrammatic.class)
                return state;

            const geo = exportItem.digital_board_filters?.geo?.include ?? [];
            const venueTypeIds = exportItem.digital_board_filters?.open_ooh_venue_type?.include ?? [];
            const venueTypes = venueTypeIds.map(venueTypeId => {
                return {
                    enumeration_id: venueTypeId
                }
            })

            const boardFormatIds = exportItem.digital_board_filters?.board_formats?.include ?? [];
            const boardFormats = boardFormatIds.map(boardFormatId => {
                return {
                    code: boardFormatId
                }
            })

            const agencyIds = exportItem.digital_board_filters?.agencies?.include ?? [];
            const agencies = agencyIds.map(agencyLcuid => {
                return {
                    lcuid: agencyLcuid
                }
            })

            return Object.assign({}, state, {
                campaignLcuid: exportItem.lcuid,
                editMode: true,
                initial_view_active: false,
                geo: geo,
                venueTypes: venueTypes,
                boardFormats: boardFormats,
                agencies: agencies,
                budget: {
                    amount: exportItem.budget_amount,
                    budget_type: exportItem.budget_type
                },
                budget_allocation: exportItem.budget_allocation,
                dates: {
                    startDate: exportItem.campaign_start_at,
                    endDate: exportItem.campaign_end_at
                }
            });

        }

        case PROGRAMMATIC_FILTERS_INIT: {
            return Object.assign({}, state, {
                campaignLcuid: null,
                editMode: false,
                geo: payload.geo,
                venueTypes: payload.venueTypes,
                boardFormats: payload.boardFormats,
                agencies: payload.agencies
            });
        }

        case PROGRAMMATIC_FILTERS_CHANGE: {
            const { geo, ...rest } = payload;
            const newState = rest;

            if (geo) {
                if (geo.remove) {
                    newState.geo = state.geo.filter(x => x.id !== geo.id)
                } else {
                    newState.geo = state.geo.some(x => x.id == geo.id)
                        ? state.geo.map(x => x.id == geo.id ? geo : x)
                        : [...state.geo, geo]
                }
            }

            return Object.assign({}, state, newState);
        }

        case PROGRAMMATIC_LOCATIONS_LOADING_CHANGE: {
            return Object.assign({}, state, {
                locationsLoading: payload.loading
            });
        }

        case PROGRAMMATIC_INITIAL_VIEW_CHANGE: {
            return Object.assign({}, state, {
                initial_view_active: payload.initial_view_active
            });
        }

        case PROGRAMMATIC_AGENCIES_LOADED: {

            const alreadyLoaded = state.agency_options.length === payload.agency_options.length;

            if (alreadyLoaded)
                return state;

            return Object.assign({}, state, {
                agency_options: payload.agency_options
            });
        }

        case PROGRAMMATIC_BOARD_FORMATS_LOADED: {

            const alreadyLoaded = state.board_format_options.length === payload.board_format_options.length;

            if (alreadyLoaded)
                return state;

            return Object.assign({}, state, {
                board_format_options: payload.board_format_options
            });
        }

        case PROGRAMMATIC_VENUE_TYPES_LOADED: {

            const alreadyLoaded = state.venue_type_options.length === payload.venue_types_options.length;

            if (alreadyLoaded)
                return state;

            return Object.assign({}, state, {
                venue_type_options: payload.venue_types_options
            });
        }

        case PROGRAMMATIC_BOARD_FORMAT_COUNTS_LOADED: {

            const boardFormatCountsByFormat = payload.board_format_counts;

            return Object.assign({}, state, {
                board_format_options: state.board_format_options.map(x => {
                    return {
                        ...x,
                        count: boardFormatCountsByFormat ? boardFormatCountsByFormat[x.code] ?? 0 : 0
                    }
                })
            });
        }

        case PROGRAMMATIC_VENUE_TYPE_COUNTS_LOADED: {

            const venueTypeCountsByType = payload.venue_type_counts;

            //This crazy function will get the count for a venue type and all of its children and grand children
            const getCountForTypeAndChildrenAndGrandChildren = (venueType) => {

                const venueTypeCount = venueTypeCountsByType[venueType.enumeration_id] ?? 0;
                const childrenCount = venueType.children ? venueType.children.reduce((acc, child) => {

                    const childVenuTypeCount = venueTypeCountsByType[child.enumeration_id] ?? 0;
                    const grandChildrenCount = child.children ? child.children.reduce((acc, grandChild) => {
                        return acc + (venueTypeCountsByType[grandChild.enumeration_id] ?? 0);
                    }, 0) : 0;

                    return acc + childVenuTypeCount + grandChildrenCount;

                }, 0) : 0;

                return venueTypeCount + childrenCount;

            }

            return Object.assign({}, state, {
                venue_type_options: state.venue_type_options.map(x => {
                    return {
                        ...x,
                        count: getCountForTypeAndChildrenAndGrandChildren(x),
                        children: x.children.map(y => {
                            return {
                                ...y,
                                count: getCountForTypeAndChildrenAndGrandChildren(y),
                                children: y.children.map(z => {
                                    return {
                                        ...z,
                                        count: getCountForTypeAndChildrenAndGrandChildren(z)
                                    }
                                })
                            }
                        })
                    }
                })
            });
        }

        case PROGRAMMATIC_AGENCY_COUNTS_LOADED: {

            const agencyCountsByAgency = payload.agency_counts;

            return Object.assign({}, state, {
                agency_options: state.agency_options.map(x => {
                    return {
                        ...x,
                        count: agencyCountsByAgency ? agencyCountsByAgency[x.id] ?? 0 : 0
                    }
                }
                )
            });
        }

        case PROGRAMMATIC_BUDGET_CHANGE: {
            return Object.assign({}, state, {
                budget: {
                    amount: payload.budget,
                    budget_type: payload.budget_type
                }
            });
        }

        case PROGRAMMATIC_BUDGET_ALLOCATION_CHANGE: {
            return Object.assign({}, state, {
                budget_allocation: payload.budget_allocation
            });

        }

        case PROGRAMMATIC_DATES_CHANGE: {
            return Object.assign({}, state, {
                dates: {
                    touched: Boolean(payload.touched),
                    startDate: payload.startDate,
                    endDate: payload.endDate
                }
            });
        }

        default:
            return state
    }
}
