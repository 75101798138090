import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, TextField, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useInterval from "../../helpers/hooks/useInterval";
import MaterialTable from "material-table";
import { lucitApi } from '../../services/lucitApi';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { Dialog, Tooltip } from '../../components';
import { LightningDeviceRemoteInstructionStatusDictionary } from '../../helpers/constants';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { toDictionary } from '../../helpers/array';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

export const DeviceInstructionsDialog = ({ device, open, handleClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [instructions, setInstructions] = useState([])
    const [loadedFirstTime, setLoadedFirstTime] = useState(false)

    const loadInstructions = () => {
        lucitApi.lightning.getInstructions(device.lcuid)
            .then(setInstructions)
            .finally(() => setLoadedFirstTime(true))
    }

    useEffect(() => loadInstructions(), [device]);

    useInterval(() => {
        //reload the instruction list, if any of the instructions do not have a status of 2
        if (instructions.some(i => i.status !== 2)) {
            loadInstructions()
        }
    }, 5_000)

    const columns = [
        {
            title: t('Datetime'),
            filtering: true,
            sorting: true,
            field: 'created_at',
            render: x => {
                return <Tooltip title={`${x.created_at} UTC`}>
                    <DateTimeRelative date={toLocalDateTime(x.created_at)} />
                </Tooltip>
            }
        },
        {
            title: t('Last signal at'),
            filtering: true,
            sorting: true,
            field: 'updated_at',
            render: x => {
                if (x.updated_at == x.created_at) {
                    return null;
                }
                return <Tooltip title={`${x.updated_at}`}>
                    <DateTimeRelative date={toLocalDateTime(x.updated_at)} />
                </Tooltip>
            }
        },
        {
            title: t('Instruction'),
            filtering: true,
            sorting: true,
            field: 'instruction',
            render: x => {
                return <>
                    <Typography>{x.instruction}</Typography>
                    {x.parameters && Object.keys(x.parameters).length > 0
                        && <Typography variant='body2' color="textSecondary">
                            {JSON.stringify(x.parameters, null, '\t')}
                        </Typography>}
                </>
            }
        },
        {
            title: t('Status'),
            filtering: true,
            sorting: true,
            field: 'status',
            render: x => {
                return <>
                    <Typography>{LightningDeviceRemoteInstructionStatusDictionary[x.status]?.title}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {x.comments?.map(c => c.comment)
                            ?.filter(x => !!x)
                            ?.join('. ')}
                    </Typography>
                </>
            }
        },
    ];

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            title={t('Instructions : ') + device.name}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <Box>
                    <InstructionsPanel device={device}
                        onInstructionSent={x => {
                            // trick to overcome bug in material-table lib
                            setInstructions([])
                            setInstructions([x, ...instructions])

                        }}
                    />

                    {!loadedFirstTime
                        && <>
                            <Skeleton variant='rect' width="100%" height={25} />
                            <hr />
                            <Skeleton variant='rect' width="100%" height={25} />
                            <hr />
                            <Skeleton variant='rect' width="100%" height={25} />
                            <hr />
                            <Skeleton variant='rect' width="100%" height={25} />
                            <hr />
                            <Skeleton variant='rect' width="100%" height={25} />
                            <hr />
                            <Skeleton variant='rect' width="100%" height={25} />

                            <hr />

                        </>}

                    {loadedFirstTime && <>
                        <MaterialTable
                            title={t('History')}
                            loading={!loadedFirstTime}
                            columns={columns}
                            data={instructions}
                            options={{
                                emptyRowsWhenPaging: true,
                                pageSize: 100,
                                pageSizeOptions: [100, 250],
                                debounceInterval: 100,
                                headerStyle: {},
                                paging: instructions?.length > 100,
                                search: true,
                                showTitle: true,
                                sorting: true,
                                selection: false,
                                toolbar: true,
                                draggable: false,
                                tableLayout: 'auto'
                            }}
                        />
                    </>}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

const InstructionsPanel = ({ device, onInstructionSent }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedInstruction, setSelectedInstruction] = useState(null);

    const lightningVersion = useSelector(state => state.lightning.version);

    const [parameters, setParameters] = useState({});

    const instructions = useMemo(() => [
        {
            title: t('Reload Device'),
            description: t(`This action will restart the device, causing it to temporarily go offline. `
                + `Any ongoing processes will be interrupted.`),
            name: 'reload',
            parameters: {}
        },
        {
            title: t('Clear Cache and Restart'),
            description: t(`This action will clear all cached data on the device and restart it.  This will cause it to reload the playlist
            and any other data from the server. - Generally a safe operation, but may cause the device to go offline temporarily.`),
            name: 'clear_cache_and_restart',
            parameters: {}
        },
        {
            title: t('Refresh Playlist'),
            description: t(`This action will refresh the list of creatives that are scheduled to play.
            The device will fetch the latest playlist from the server.`),
            name: 'refresh_playlist',
            parameters: {}
        },
        {
            title: t('Reload Settings'),
            description: t(`This action will reload the device settings from the server. `
                + `Any changes made on the server will be applied to the device.`),
            name: 'refresh_device_settings',
            parameters: {}
        },
        {
            title: t('Dump State'),
            description: t(`This action will output the current state of the device, including system status and configurations, `
                + `to a log file for diagnostic purposes.`),
            name: 'dump_state',
            parameters: {}
        },
        {
            title: t('Dump Logs'),
            description: t(`This action will collect and output the device logs, `
                + `which can be used for troubleshooting and identifying issues.`),
            name: 'dump_logs',
            parameters: {}
        },
        {

            title: t('Update Lightning Version'),
            description: t(`This action will update the Lightning software version on the device to the specified version.
            The update usually takes up to one minute.   Latest version is : {{version}}`, { version: lightningVersion }),
            name: 'pull_capacitor_update',
            parameters: {
                version: {
                    title: t("Version"),
                    description: t(`The specific version of the Lightning software to update to. `
                        + `This must be provided and should be a valid version string.`),
                    type: 'string',
                    placeholder: lightningVersion,
                    required: true
                }
            }
        }
    ], [])

    const addInstruction = (instruction, parameters, run_at) => {
        setLoading(true)
        return lucitApi.lightning.addInstruction(device.lcuid,
            {
                instruction,
                parameters,
                run_at
            })
            .then(onInstructionSent)
            .finally(() => setLoading(false));
    }

    return <Box mb={2} display="flex" flexWrap="wrap" style={{ gap: 8 }}>
        {instructions.map(i => (
            <Button
                key={i.name}
                onClick={() => {
                    setSelectedInstruction(i);
                    setParameters({ ...i.parameters });
                }}
                variant="contained"
                color="primary"
            >
                {t(i.title)}
            </Button>
        ))}

        {selectedInstruction && <>
            <GenericDialog
                title={t(selectedInstruction.title)}
                dialogProps={{
                    open: true,
                    onClose: () => setSelectedInstruction(null),
                    fullWidth: true,
                    maxWidth: 'xs'
                }}
                ContentComponent={<>
                    <Typography>{selectedInstruction.description}</Typography>

                    <Box display="flex" mt={1} mb={1} style={{ gap: 8 }}>
                        {Object.keys(parameters)
                            .map(paramName => <TextField
                                key={paramName}
                                placeholder={parameters[paramName].placeholder}
                                helperText={parameters[paramName].description}
                                fullWidth
                                required={parameters[paramName].required}
                                label={parameters[paramName].title}
                                value={parameters[paramName].value || ''}
                                onChange={(e) => setParameters({
                                    ...parameters,
                                    [paramName]: {
                                        ...parameters[paramName],
                                        value: e.target.value
                                    }
                                })}
                            />)}
                    </Box>
                </>}

                ActionsComponent={<>
                    <Button
                        disabled={loading || Object.values(parameters).some(p => p.required && !p.value)}
                        onClick={() => {
                            addInstruction(
                                selectedInstruction.name,
                                toDictionary(Object.keys(parameters), x => x, x => parameters[x].value)
                            )
                                .then(() => setSelectedInstruction(null));
                        }}
                        variant="contained"
                        color="primary">
                        {t('Send Instruction')}
                    </Button>
                </>}
            />

        </>}
    </Box>
}
