import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import StoriesList from '../../containers/stories/StoriesList';
import { loadPublicStory, loadPublicStoryNextPage } from '../../actions/stories';
import { InfiniteScroll } from '../../components';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ScrollContext } from '../../contexts';

const useStyles = makeStyles(() => {
    return {
        card: {
            maxWidth: 600,
            width: '100%',
            marginBottom: "15px",
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        appWelcomeMessageHeader: {
            backgroundColor: '#333333',
            color: 'white'
        },
        infiniteScrollContainer: {
            '& .infinite-scroll-component__outerdiv': {
                width: '100%',
            }
        }
    }
})

function StoryFeedPrivate(props) {
    const { stories, loadPublicStory, loadPublicStoryNextPage } = props;

    const load = () => loadPublicStory(1, props.perPage);
    const loadNext = () => loadPublicStoryNextPage(props.page + 1, props.perPage);
    const scrollContext = useContext(ScrollContext).current;

    const classes = useStyles();

    useEffect(() => {
        load();
    }, [])

    return <Box display="flex" justifyContent="center" className={classes.infiniteScrollContainer}>
        <InfiniteScroll dataLength={stories.length}
            page={props.page}
            perPage={props.perPage}
            lastPage={props.lastPage}
            loading={props.loading}
            load={load}
            loadNext={loadNext}
            scrollableTarget={scrollContext.ref}
        >
            <StoriesList stories={stories} />
        </InfiniteScroll>
    </Box>
}

const mapStateToProps = (state) => {
    return {
        stories: state.stories.public.data,
        loading: state.stories.public.loading,
        page: state.stories.public.currentPage,
        perPage: state.stories.public.perPage,
        lastPage: state.stories.public.lastPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadPublicStory: (page, perPage) => dispatch(loadPublicStory(page, perPage)),
        loadPublicStoryNextPage: (page, perPage) => dispatch(loadPublicStoryNextPage(page, perPage)),
    }
}

const StoryFeedPrivateConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(StoryFeedPrivate);

export default withHomeLayout(StoryFeedPrivateConnected);
