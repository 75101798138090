import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { hasCapability } from '../../../selectors/applications';
import { ApplicationCapabilities } from '../../../helpers/constants';
import { Box } from '@material-ui/core';

export const AssignedAppCapabilitiesPreview = ({ application }) => {

    return <>
        {Object.keys(ApplicationCapabilities).map((capabilityCode, index) => {
            const c = ApplicationCapabilities[capabilityCode];

            if (c.hasAssignedAppCapabilityPreviewComponent && hasCapability(application, c)) {
                const LazyComponent = lazy(() => import('./' + capabilityCode + '/AssignedAppCapabilityPreview.jsx'));

                return <Suspense key={index} fallback={<div>Loading...</div>}>
                    <Box pl={7} width="100%">
                        <LazyComponent application={application} />
                    </Box>
                </Suspense>
            }
        })}
    </>

}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AssignedAppCapabilitiesPreview
);
