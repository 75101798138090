import React, { useEffect, useState } from 'react';
import { gmStreetViewUrl } from '../../helpers/url';
import { IonImg } from '@ionic/react';
import { getCityScapeIcon } from '../../selectors/billboard';
import { ImageStyle } from '../../helpers/constants';
import { lucitApi } from '../../services/lucitApi';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const BestBillboardMainImage = ({ billboard }) => {

    const [primaryBoardImage, setPrimaryBoardImage] = useState(null);
    const [loadingBoardImage, setLoadingBoardImage] = useState(true);
    const [streetViewOnly, setIsStreetViewOnly] = useState(false);
    const [streetViewLocationId, setStreetViewLocationId] = useState(null);
    const [streetViewLocation, setStreetViewLocation] = useState(null);

    const getBestPreviewImage = () => {

        if (streetViewOnly && streetViewOnly && streetViewLocation) {
            return gmStreetViewUrl({
                panoId: streetViewLocation.options.google_street_view_pano_id
            }, {
                heading: streetViewLocation.heading,
                pitch: streetViewLocation.pitch,
                zoom: streetViewLocation.zoom
            })
        }

        if (primaryBoardImage && primaryBoardImage.image) {
            return primaryBoardImage.image.options.public_url
        }

        return getCityScapeIcon(billboard.digital_board_format.code)
    }

    useEffect(() => {

        setLoadingBoardImage(true)

        lucitApi.billboards.getPublicImages(billboard.id).then(data => {
            if (!data || !data.length) {
                setPrimaryBoardImage(null)
                setLoadingBoardImage(false)
            }
            else {

                const xrImages = data.filter(image => image.style === ImageStyle.normal)

                if (xrImages.length) {
                    setPrimaryBoardImage(xrImages[0])
                    setLoadingBoardImage(false)
                }
                else {
                    setIsStreetViewOnly(true)

                    setPrimaryBoardImage(data[0])
                    setStreetViewLocationId(data[0].options.street_view_location_id)
                }

            }
        })

    }, [billboard])

    useEffect(() => {

        if (streetViewLocationId) {

            lucitApi.objects.get(`LL${streetViewLocationId}`)
                .then(data => {
                    setStreetViewLocation(data.object)
                    setLoadingBoardImage(false)
                })
        }
    }, [streetViewLocationId])

    if (!billboard)
        return <CircularProgressCentered size={40} />

    if (loadingBoardImage) {
        return <CircularProgressCentered size={40} />
    }

    return <IonImg src={getBestPreviewImage()} />

}

export default BestBillboardMainImage;
