import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Dialog, DialogContent, Button, Box, Typography, ListItemAvatar, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { PageTitle } from '../components';
import { withConditionalLayout } from '../layouts/ConditionalLayout';
import { useTranslation } from 'react-i18next';
import BillboardsMap from '../containers/dashboard/BillboardsMap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { GoogleMapsContext } from '../contexts';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import { useRouteMatch } from 'react-router';
import { lucitApi } from '../services/lucitApi';
import { LuCoreModelClasses } from '../helpers/constants';
import { hasIcon } from '../selectors/exports';
import { Business } from '@material-ui/icons';

const tutorialSteps = [
    {
        label: 'Proof Any Ad on Real Screens',
        imgPath:
            '../assets/images/xr_cover1.png',
    },
    {
        label: 'Choose a Screen',
        imgPath:
            '../assets/images/xr_cover2.png',
    },
    {
        label: 'Upload Your Ad',
        imgPath:
            '../assets/images/xr_cover3.png',
    },
    {
        label: 'See Your Proof',
        imgPath:
            '../assets/images/xr_cover4.png',
    },
];

const useStyles = makeStyles(() => {
    return {
        mapBox: {
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },
        main: {
            padding: 0,
            height: '100%',
            maxWidth: '100%',
        },
        coverSection: {
            backgroundImage: 'url(/assets/images/lucitXR_pubCover.png)',
            backgroundSize: 'cover',
        },
        heroTextBox: {
            maxWidth: 300,
        },
        body: {
            height: '100%',
            position: 'relative',
        },
        floatingLeftDiv: {
            position: 'absolute',
            zIndex: 1,
            margin: 10,
            width: 'auto',
        },
        floatingRightDiv: {
            position: 'absolute',
            zIndex: 1,
            margin: 10,
            right: 0,
            top: 150,
        },
        floatingLogo: {
            position: 'absolute',
            zIndex: 1,
            marginBottom: 20,
            bottom: 0,
            width: 200,
        },
        floatingLogoImg: {
            width: '100%',
        },
        root: {
            //maxWidth: 400,
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'left',
            padding: 0,
            marginBottom: 20,
        },
        img: {
            height: 'auto',
            width: '100%',
            display: 'block',
            maxWidth: 700,
            maxHeight: 500,
            overflow: 'hidden',
            margin: 'auto',
        },
        searchBox: {
            position: 'relative',
            display: 'flex',
            background: 'black',
            padding: 10,
            borderRadius: 10,
            width: 'auto',
            '&:hover > div': {
                //width: '240px',
            },
            '&:active': {
                //width: '240px',
            }
        },
        searchCTA: {
            minWidth: '40px',
            minHeight: '40px',
            height: '40px',
            width: '40px',
        },
        floatingRightDivButton: {
            minWidth: '40px',
            minHeight: '40px',
            height: '40px',
            width: '40px',
        },
        floatingAgency: {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
            height: '60px',
            margin: '10px',
            padding: '10px 16px',
            borderRadius: '10px',
            background: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tourContent: {
            padding: 0,
            paddingTop: '0px !important',
        },
        avatar: {
            background: 'none'
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
        },
    }
});

const SwipeableTextMobileStepper = ({ closeDialog }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>
                ))}
            </SwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={activeStep === maxSteps - 1
                    ? <Button size="small" onClick={closeDialog}>
                        Finish
                    </Button>
                    :
                    <Button size="small" onClick={handleNext}>
                        Next
                    </Button>
                }
                backButton={activeStep === 0
                    ?
                    < Button size="small" onClick={closeDialog}>
                        Skip
                    </Button>
                    :
                    <Button size="small" onClick={handleBack}>
                        Back
                    </Button>
                }
            />
        </div>
    );
}

const PublicXRPage = () => {
    const [open, setOpen] = React.useState(false);
    const [agency, setAgency] = React.useState(null);
    const classes = useStyles();

    const { params } = useRouteMatch();
    const slug = params.slug

    useEffect(() => {
        if (slug) {
            lucitApi.objects.get(slug)
                .then(x => x.object_class === LuCoreModelClasses.agencies.class
                    && setAgency(x.object))
        }
    }, [slug])

    useEffect(() => {
        if (!localStorage.getItem("USER_SAW_PUBLIC_XR_PAGE"))
            setOpen(true);
    }, [])

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem("USER_SAW_PUBLIC_XR_PAGE", true);
    };

    const { t } = useTranslation();

    const center = useMemo(() => ({
        lat: 38.116386,
        lng: -101.299591,
        zoom: 5.25
    }), [])

    const [locationValue, setLocationValue] = useState(null)
    const [placeId, setPlaceId] = useState(null)

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(`Places API failed to load: ${gmContext.error}`, { Error: gmContext.error });
        }
    }, [gmContext.error])

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>{t('Places API is currently unavailable')}</Typography>
        </Box>
    }
    useEffect(() => {

        locationValue && setPlaceId(locationValue.value.place_id)

    }, [locationValue])

    return (
        <Container component="main" className={classes.main}>
            <PageTitle title={t("LucitXR - Bringing Proofs to Life")} />
            <div className={classes.body}>
                <div className={classes.floatingLeftDiv}>
                    <div className={classes.searchBox}>
                        <GooglePlacesAutocomplete
                            apiKey={gmContext.key}
                            selectProps={{
                                locationValue,
                                onChange: setLocationValue,
                                placeholder: 'Search for a location',
                                styles: {
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        width: '200px',
                                    }),
                                },
                            }}
                        />
                        <Button variant="contained" className={classes.searchCTA} color="primary">
                            <SearchIcon />
                        </Button>
                    </div>
                </div>
                <div className={classes.floatingRightDiv}>
                    <div className={classes.floatingRightDivContent}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.floatingRightDivButton}
                            href="https://www.lucit.cc/post/how-to-try-lucitxr">
                            <HelpIcon />
                        </Button>
                    </div>
                </div>
                <div className={classes.floatingLogo}>
                    <img className={classes.floatingLogoImg} src="/assets/images/lucitXR_logo.png" alt="LucitXR Logo" />
                </div>
                {agency
                    && <div className={classes.floatingAgency}>
                        <ListItemAvatar>
                            {agency && hasIcon(agency)
                                ? <Avatar className={classes.avatar} >
                                    <img
                                        src={agency.options.primary_image_public_url}
                                        className={classes.image}
                                        alt={agency.name}
                                        title={agency.name}
                                    />
                                </Avatar>
                                : <Avatar>
                                    <Business />
                                </Avatar>}
                        </ListItemAvatar>
                        <Typography
                            color="secondary"
                            variant="button"
                            style={{ fontWeight: 'bold' }}
                        >{agency.name}</Typography>
                    </div>}
                <div className={classes.mapBox}>
                    {slug && !agency
                        ? null // Don't show map until agency is loaded
                        : <BillboardsMap
                            agencyId={agency && agency.id}
                            promptGeoPermissions={false}
                            showCenterButton={false}
                            placeId={placeId}
                            showTargetedButton={false}
                            showTargetedOnly={true}
                            streetViewOnly={true}
                            // Dont set center if agency is present
                            // That will auto-center across agency locations
                            center={agency
                                ? null
                                : center}
                        />}
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent className={classes.tourContent}>
                    <SwipeableTextMobileStepper closeDialog={handleClose} />
                </DialogContent>
            </Dialog>
        </Container>
    )
}

export default
    withConditionalLayout(
        PublicXRPage, {
        publicOptions: {
            disablePaper: true, disablePermanentDrawer: true,
            showHomeButton: false, hideSearchToolbar: true
        }
    }
    );
