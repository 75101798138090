import React from 'react';
import ReactMaskedInput from "react-text-mask";

const MaskedInput = (props) => {
    const { inputRef, ...other } = props;

    return <ReactMaskedInput
        {...other}
        ref={ref => {
            inputRef && inputRef(ref ? ref.inputElement : null);
        }}
    />
};

export default MaskedInput;