import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { OgMetatags, PageTitle } from '../../components';

import { hasIcon } from '../../selectors/account';
import { ButtonScrollToTopFab } from '../../components/material';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';
import { ApplicationProfile } from '../../containers/applications';

function Profile(props) {
    const { object } = props;

    return <>
        <Box component={Paper} p={3} style={{ maxWidth: '800px', margin: 'auto' }}>
            <PageTitle title={object.name} />
            <OgMetatags
                title={object.name}
                image={hasIcon(object)
                    ? object.options.primary_image_public_url
                    : null} />

            <ApplicationProfile application={object} />
            <ButtonScrollToTopFab />
        </Box>
    </>
}

export default withConditionalLayout(
    Profile, { publicOptions: { disablePaper: true } }
)
export { Profile as ApplicationProfileWithoutLayout }
