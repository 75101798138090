import { Box, Button, FormHelperText, makeStyles } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createApplication } from '../../actions/applications';
import { RoutePaths } from '../../helpers/constants';
import { IonList, IonRadio, IonRadioGroup, IonItem, IonLabel } from '@ionic/react';
import { useEffect } from 'react';
import { lucitApi } from '../../services/lucitApi';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const NewApplicationDialog = (props) => {
    const { open, handleClose, createApplication } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const [applicationClasses, setApplicationClasses] = useState([]);
    const [applicationClass, setApplicationClass] = useState('App\\LuCore\\Applications\\ThirdPartyApplicationClass');

    useEffect(() => {
        lucitApi.applications.getClasses().then((appClasses) => {
            setApplicationClasses(appClasses);
        })
    }, []);

    return (
        <GenericDialog
            title={t('New Application')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box mt={2}>
                <TextField
                    variant="outlined"
                    fullWidth={true}
                    required={true}
                    label={t('Name')}
                    value={name}
                    autoFocus
                    inputRef={input => input && input.focus()}
                    onChange={e => setName(e.target.value)}
                />

                {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}

                <Box mt={2}>
                    <IonLabel><LCTypography>
                        Choose the best class for your new application.  This <b>cannot</b> be changed later.
                    </LCTypography>
                    </IonLabel>
                    <IonList>
                        <IonRadioGroup

                            value={applicationClass}
                            onIonChange={e => setApplicationClass(e.target.value)}
                        >
                            {applicationClasses.map((appClass, index) => {
                                return <IonItem key={index}>
                                    <IonLabel class="ion-text-wrap">
                                        <h2>{appClass.class_name}</h2>
                                        <p>{appClass.class_description}</p>
                                    </IonLabel>
                                    <IonRadio
                                        slot="end"
                                        value={appClass.class}></IonRadio>
                                </IonItem>
                            })
                            }

                        </IonRadioGroup>

                    </IonList>
                </Box>

            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader
                    disabled={!name}
                    submitting={loading}
                    onClick={() => {
                        setLoading(true);
                        createApplication({ name, application_class: applicationClass })
                            .then(app => {
                                setName('');
                                handleClose();
                                history.push({ pathname: RoutePaths.dev.application(app.lcuid) })
                            })
                            .catch(() => setError('Unexpected error happened, please try again later'))
                            .finally(() => setLoading(false))
                    }}
                    color="primary"
                    variant="contained">
                    {t('Create')}
                </ButtonLoader>
            </>}
        />
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createApplication: data => dispatch(createApplication(data))
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewApplicationDialog)
)
