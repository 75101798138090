/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    DialogContent, DialogActions, useTheme, useMediaQuery,
    Button,
} from '@material-ui/core';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';
import ItemFilterBuilder from './ItemFilterBuilder';
import { Dialog } from '../../components';

const ItemFilterDialog = ({ open, handleClose, title, buttonTitle, dialogProps, buttonProps,
    schedule = [], setFilter, digitalBoardFilter = [], accountId }) => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [saving, setSaving] = useState(false);
    const [_schedules, _setSchedules] = useState(schedule);
    const [_digitalBoardFilter, _setDigitalBoardFilter] = useState(digitalBoardFilter);
    const { t } = useTranslation();

    useEffect(() => {
        _setSchedules(schedule);
    }, [JSON.stringify(schedule)])

    useEffect(() => {
        _setDigitalBoardFilter(digitalBoardFilter);
    }, [JSON.stringify(digitalBoardFilter)])

    return (
        <Dialog
            open={open}
            lazy
            disableEnforceFocus={true}
            fullWidth
            maxWidth="md"
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent style={{ minHeight: 200 }}>
                {open && <ItemFilterBuilder
                    accountId={accountId}
                    schedules={_schedules}
                    setSchedules={_setSchedules}
                    digitalBoardFilter={_digitalBoardFilter}
                    setDigitalBoardFilter={_setDigitalBoardFilter}
                />}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    onClick={() => {

                        setSaving(true);

                        (setFilter(_schedules, _digitalBoardFilter) ?? Promise.resolve())
                            .then(handleClose)
                            .finally(() => setSaving(false))
                    }}
                    color="secondary"
                    variant="contained"
                    {...buttonProps}>
                    {buttonTitle
                        ? buttonTitle
                        : t('Save')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

export default ItemFilterDialog
