import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';

const SeeMoreListItem = ({ more, onChange }) => {

    return (
        <ListItem
            button
            onClick={() => onChange(!more)}>
            <ListItemIcon>
                {more
                    ? <ExpandLess />
                    : <ExpandMore />}
            </ListItemIcon>
            <ListItemText
                primary={more
                    ? "See less"
                    : "See more"}
            />
        </ListItem>
    );
};

export default SeeMoreListItem;