import React, { useEffect, useState } from 'react';
import { Global } from '../../helpers/constants';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { logger } from '../../helpers/logger';
import { lucitApi } from '../../services/lucitApi';
import CreativeProof from './CreativeProof';
import { keepCheckingForNewProofData, keepCheckingBackgroundDataForProof } from "../../selectors/proofs";

const CreativeProofPage = (props) => {
    const { object } = props;

    const [data, setData] = useState(null);

    useEffect(() => {
        lucitApi.creativeProofs.getPublic(object.lcuid)
            .then(data => setData(data))
    }, [])

    const { clear } = useIntervalLimited(() => {
        lucitApi.creativeProofs.getPublic(object.lcuid)
            .then(data => setData(data))
            .catch(error => {
                logger.logError("CreativeProof status updator was stopped due to error", { error, object });
                clear();
            })
    }, {
        milliseconds: Global.creativeProofRefreshInternal,
        running: keepCheckingBackgroundDataForProof(data)
    });

    return <CreativeProof lcuid={object.lcuid} data={data} isStillCheckingForNewProofs={keepCheckingForNewProofData(data)} />
};

export default CreativeProofPage;
