import React, { useState, useEffect } from 'react';
import {
    Box, List, ListItem, ListItemIcon, Avatar, ListItemSecondaryAction, makeStyles, ListItemText,
    InputAdornment, TextField, Tooltip
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { trustUser, revokeUserTrust } from '../../actions/agencies';
import { useDispatch } from 'react-redux';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { toLocalDateTime } from '../../helpers/date';
import ModerationScoreIcon from './ModerationScoreIcon';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles((theme) => {
    return {
        secondaryAction: {
            paddingRight: theme.spacing(7.5)
        },

        list: {
            width: '100%',
            maxWidth: 700
        },
        isBotUserContainer: {
            fontStyle: "italic",
            color: theme.palette.text.disabled
        },
        isSystemUser: {
            color: theme.palette.text.secondary
        },
        isBotUser: {
            color: theme.palette.text.disabled,
            fontSize: "0.8em",
            fontStyle: "italic"
        },
        userAccountList: {
            color: theme.palette.text.secondary,
            fontSize: "0.8em",
            overflowX: "clip",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }
    }
})

const TrustedUsers = ({ agency, ...rest }) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const [query, setQuery] = useState('')

    if (!agency || !agency.id) {
        return (
            <Box m={10}>
                {t('In order to change settings for an Operator, please choose one')}
            </Box>
        )
    }

    const filteredUsers = query ? users.filter(user => {
        return searchTermInText(query, [user.name]);
    }) : users;

    const doesNotHaveUsers = () => {
        return (!users || users.length === 0)
    }

    const hasUsers = () => {
        return !doesNotHaveUsers()
    }

    useEffect(() => {
        setLoading(true);
        lucitApi.agencies.getOperatorUserTrust(agency.id)
            .then(response => {
                setUsers(response.data.trusted_users);
            })
            .finally(() => setLoading(false))
    }, [agency.id])

    return (
        <Box ml={2} mt={4}>

            <Box mb={2}>
                {!doesNotHaveUsers() &&
                    <LCTypography variant="body1">
                        The following users have been trusted, or specifically un-trusted by the approver.
                        <br />
                        New users are added to this
                        via the Approval Score icon on the main approvals screen
                        <ModerationScoreIcon score={1} /> <ModerationScoreIcon score={0.8} /> <ModerationScoreIcon score={0} />

                    </LCTypography>
                }
            </Box>
            <Box>
                <Box style={{ maxWidth: "400px" }}>
                    {!loading && hasUsers() &&
                        <TextField
                            type="text"
                            fullWidth
                            variant="standard"
                            placeholder="Filter..."
                            value={query || ''}

                            onChange={(event) => setQuery(event.target.value)}
                            inputProps={{
                                "aria-autocomplete": "both",
                                "aria-haspopup": "false",
                                "autoComplete": "off",
                                "spellCheck": "false"
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Tooltip title={t('Filter')}>
                                        <FilterIcon fontSize="small" />
                                    </Tooltip>
                                </InputAdornment>
                            }}
                        />
                    }
                </Box>
                <Box mt={8} mb={8} pl={6}>
                    <LCTypography variant="body1">
                        Only content from trusted users is eligible to be approved by the Auto Approver.
                    </LCTypography>
                </Box>

                <Box>
                    {loading && <CircularProgressCentered />}
                </Box>
                {!loading && doesNotHaveUsers() &&
                    <Box mt={8} mb={8} pl={6}>
                        <LCTypography variant="body1">
                            No users have been added to your trusted list.   This means that you have not specifically
                            reviewed and approved the users on a creative yet.  Visit the main approvals screen and
                            use one of the approval score icons to add users.
                            <ModerationScoreIcon score={1} /> <ModerationScoreIcon score={0.8} /> <ModerationScoreIcon score={0} />

                        </LCTypography>
                    </Box>
                }
                <Box style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <List className={classes.list}>
                        {filteredUsers && filteredUsers
                            .map(x => <TrustedUserListItem
                                key={x.id}
                                trust={x}
                                user={x.user}
                                {...rest}
                            />)}
                    </List>
                </Box>
            </Box>
        </Box >
    )
}

const TrustedUserListItem = ({ user, trust }) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [isTrusted, setIsTrusted] = useState(trust && trust.is_trusted)

    const dispatch = useDispatch();

    const setUserTrusted = () => {
        setIsTrusted(true)
        dispatch(trustUser(user.id))
    }

    const setUserNotTrusted = () => {
        setIsTrusted(false)
        dispatch(revokeUserTrust(user.id))
    }

    const updatedByUserName = trust.updated_by_user ? trust.updated_by_user.name : 'unknown'

    const userIsBot = user && user.roles.includes(':bot:')
    const userIsSystemUser = user && user.roles.includes(':system:')

    const userIsSystemOrBotUser = userIsBot || userIsSystemUser

    return <ListItem classes={{ secondaryAction: classes.secondaryAction }}>
        <ListItemIcon>
            <Avatar alt={user.name}
                className={classes.avatarContainer}
                src={user.options.primary_image_public_url} />
        </ListItemIcon>
        <Box maxWidth={400}>
            <Tooltip title={
                userIsSystemOrBotUser ?
                    t('This system user is part of the data automation and \
                    creative processing system in Lucit and is normally safe to trust')
                    : ''
            }>
                <ListItemText className={userIsSystemOrBotUser ? classes.isBotUserContainer : null}>
                    {user.name}
                    {userIsSystemOrBotUser && <><br />

                        <div className={classes.isBotUser}>(system process user)</div>
                    </>}
                    {!userIsSystemOrBotUser && user.accounts && user.accounts.length > 0 &&
                        <div className={classes.userAccountList}>
                            {user.accounts.map(x => x.name).join(', ')}
                        </div>
                    }
                </ListItemText>
            </Tooltip>
        </Box>
        <ListItemSecondaryAction>
            <Tooltip
                title={
                    t('Last updated by ')
                    + updatedByUserName
                    + ' on '
                    + (new Date(toLocalDateTime(trust.updated_at))).toLocaleDateString()
                }>
                {
                    isTrusted ?
                        <Button variant="outlined" onClick={setUserNotTrusted}>
                            <VerifiedUserIcon color="secondary" />
                            <span className={classes.hiddenOnXs}> Trusted</span>
                        </Button>
                        :
                        <Button variant="outlined" onClick={setUserTrusted}>
                            <VerifiedUserIcon color="error" />
                            <span className={classes.hiddenOnXs}> Not Trusted</span>
                        </Button>
                }
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>
}

export default TrustedUsers
