import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Box, Slider, Typography } from '@material-ui/core';
import LCTypography from '../material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        sliderValueLabel: {
            top: -28,
            '&>span': {
                width: 80,
                height: 'auto',
                transform: 'none',
                borderRadius: 0,
                padding: theme.spacing(0.5, 1),
                '&>span': {
                    transform: 'none',
                }
            }
        },
    }
})

const WeightsEditor = ({ items, onChange }) => {
    const [totalWeight, setTotalWeight] = useState(0);

    const setItemWeight = useCallback((id, weight) => {
        onChange(items.map(x => {
            if (x.id === id) {
                return {
                    ...x,
                    weight
                }
            }

            return x;
        }));
    }, [items]);

    useEffect(() => {
        setTotalWeight(items?.reduce((prev, cur) => prev + cur.weight, 0) ?? 0)
    }, [items])

    return items.map(item => (
        <WeightSlider key={item.id} {...{ items, setItemWeight, item, totalWeight }} />
    ));
}

const WeightSlider = (props) => {
    const { item, setItemWeight, totalWeight } = props;
    const classes = useStyles();

    const [newWeightPct, setNewWeightPct] = useState((item.weight / totalWeight) * 100);

    useEffect(() => {
        setNewWeightPct((item.weight / totalWeight) * 100)
    }, [item.weight, totalWeight])

    const getNewWeightValue = () => {
        return Math.max(
            Math.round(((newWeightPct / 100) * (totalWeight - item.weight)) / (1 - (newWeightPct / 100))),
            1
        )
    }

    return <Box key={item.id} display="flex" alignItems="center" mb={2}>
        <Typography style={{ width: '40%' }}>
            {item.title}
        </Typography>
        <Box display="flex" position="relative" width="60%" ml={2}>
            <Slider
                step={1}
                min={1}
                max={99}
                value={newWeightPct}
                classes={{
                    valueLabel: classes.sliderValueLabel
                }}

                onChange={(_, itemWeightPct) => setNewWeightPct(itemWeightPct)}
                onChangeCommitted={() => setItemWeight(item.id, getNewWeightValue())}
            />
            <LCTypography
                transProps={{ newWeightFixed: newWeightPct.toFixed(0) }}
                variant="body2"
                style={{ marginLeft: '8px', alignSelf: 'center' }}>{{ newWeightFixed: newWeightPct.toFixed(0) }}%
            </LCTypography>
        </Box>
    </Box>
}

export default WeightsEditor
