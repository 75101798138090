import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Box, makeStyles, TablePagination } from '@material-ui/core';

import { exportItemsSelector, inventoryStatus } from '../../../selectors/inventoryStatus';
import ExportItem from './ExportItem';
import TitleFilter from './TitleFilter';
import { Global } from '../../../helpers/constants';
import { getExportItems } from '../../../actions/exports';
import { debounce } from '../../../helpers/async';
import { useCallback } from 'react';
import ExportItemSkeleton from './ExportItemSkeleton';
import { inventoryStatusReset } from '../../../actions/inventoryStatus';
import ExportItemsStatusUpdator from '../ExportItemsStatusUpdator';
import { searchTermInText } from '../../../helpers/string';

const useStyles = makeStyles(theme => {
    return {
        filters: {
            position: "sticky",
            top: theme.spacing(-3),
            background: theme.palette.common.white,
            padding: theme.spacing(2),
            zIndex: theme.zIndex.appBar
        },

        pagging: {
            position: 'sticky',
            bottom: theme.spacing(-3),
            background: theme.palette.common.white
        }
    }
})

function ExportItemsList(props) {
    const { exportId, reset, exportStatus, getItems, filters, currentPage, showScreenSelector = true } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState(filters.search);
    const [search, setSearch] = useState(filters.search);
    const [page, setPage] = useState(currentPage);

    const setSearchDebounced = useCallback(debounce((value) => {
        setSearch(value);
        setPage(1);
    }, 1000), []);

    const getItemsDebounced = useCallback(
        debounce(() => {
            setLoading(true);
            return getItems()
                .finally(() => setLoading(false))
        }, 1000),
        [getItems],
    );

    useEffect(() => {
        getItemsDebounced();
    }, [getItemsDebounced])

    useEffect(() => reset, [reset])

    const items = exportStatus.items.filter(item => {
        return searchTermInText(search, [item?.item?.title, item?.item?.dealer_stock_number])
    });

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box className={classes.filters}>
                <TitleFilter
                    value={searchInput}
                    onChange={value => {
                        setSearchInput(value);
                        setSearchDebounced(value);
                    }} />
            </Box>

            {!loading
                && (
                    <List>
                        {items
                            .slice((page - 1) * Global.exportItemListSize, page * Global.exportItemListSize)
                            .map(item => (<ExportItem
                                key={item.item_id}
                                itemStatus={item}
                                details={"test"}
                                showScreenSelector={showScreenSelector}
                                settings={exportStatus.export} />))}
                    </List>
                )}
            {loading
                && (
                    <List>
                        {Array.from({ length: Global.exportItemListSize })
                            .map((_, index) => (
                                <ExportItemSkeleton key={index} />
                            ))}
                    </List>
                )}

            {/*
            <Pagination
                page={page}
                count={Math.ceil(total / Global.exportItemListSize)}
                onChange={(_, page) => setPage(page)}
            /> */}

            {/* Paging here is having zero-based index */}
            <TablePagination
                className={classes.pagging}
                component="div"
                count={items.length}
                page={page - 1}
                rowsPerPage={Global.exportItemListSize}
                rowsPerPageOptions={[Global.exportItemListSize]}
                labelRowsPerPage="Per page:"
                onChangePage={(_, page) => {
                    return setPage(page + 1);
                }}
            />
            <ExportItemsStatusUpdator exportId={exportId} />
        </Box>
    );
}

ExportItemsList.propTypes = {
    exportId: PropTypes.number.isRequired,
}

const mapStateToProps = (state, props) => {
    return {
        ...inventoryStatus(state),
        exportStatus: exportItemsSelector(state)(props.exportId)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        getItems: () => dispatch(getExportItems(props.exportId, null, 1, 100_000)),
        // getItems: (search, page) => dispatch(getExportItems(props.exportId, search, page, Global.exportItemListSize)),

        reset: () => dispatch(inventoryStatusReset())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportItemsList
);
