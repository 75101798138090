import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import StoriesList from '../stories/StoriesList';
import { loadAccountFeed, loadAccountFeedNextPage } from '../../actions/stories';
import { InfiniteScroll } from '../../components';
import { useContext } from 'react';
import { ScrollContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

function AccountFeed(props) {
    const { account, stories, loadAccountFeed, loadAccountFeedNextPage } = props;

    const load = () => loadAccountFeed(account.lcuid, 1, props.perPage);
    const loadNext = () => loadAccountFeedNextPage(account.lcuid, props.page + 1, props.perPage);
    const scrollContext = useContext(ScrollContext).current;
    const { t } = useTranslation();

    useEffect(() => {
        load();
    }, [])

    if (!props.loading && !stories.length) {
        return <Grid item xs={12} md="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Box mt={10} mb={10}>
                {account.options?.public_can_join ?
                    t("Join now to be the first to post to {{name}}!", { name: account.name })
                    : t("{{name}} hasn't posted any stories yet", { name: account.name })}
            </Box>
        </Grid>
    }

    return <InfiniteScroll dataLength={stories.length}
        page={props.page}
        perPage={props.perPage}
        lastPage={props.lastPage}
        loading={props.loading}
        load={load}
        loadNext={loadNext}
        scrollableTarget={scrollContext.ref}
    >
        <StoriesList stories={stories} />
    </InfiniteScroll>
}

const mapStateToProps = (state) => {
    return {
        stories: state.stories.public.data,
        loading: state.stories.public.loading,
        page: state.stories.public.currentPage,
        perPage: state.stories.public.perPage,
        lastPage: state.stories.public.lastPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadAccountFeed: (accountId, page, perPage) => dispatch(loadAccountFeed(accountId, page, perPage)),
        loadAccountFeedNextPage: (accountId, page, perPage) => dispatch(loadAccountFeedNextPage(accountId, page, perPage)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountFeed);
