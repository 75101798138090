import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles, Box, Hidden } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { selectedProfile } from '../../selectors/user';
import ProfileMenuPopper from './ProfileMenuPopper';
import { getUserInfo } from '../../actions/user';
import { AppTour } from '../../helpers/constants';
import { attachTours } from '../../helpers/tour';
import { toggleProfileMenu } from '../../actions/layout';
import ProfileAvatar from './ProfileAvatar';

const useStyles = makeStyles(theme => ({
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },
    avatar: {
        backgroundColor: 'transparent'
    },
    avatarNoIcon: {
        backgroundColor: theme.palette.secondary.main
    },

    selectedProfileNameBox: {
        marginLeft: theme.spacing(1),
        maxWidth: 250,

        [theme.breakpoints.down('sm')]: {
            maxWidth: 120
        }
    },
    selectedProfileName: {
        color: theme.palette.header.text
    }
}));

const ProfileMenu = ({ selectedProfile, getUserInfo, profileMenuOpen, toggleProfileMenu }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (profileMenuOpen) {
            getUserInfo()
        }
    }, [profileMenuOpen]);

    // Close Profile Menu when unmounting component
    // This could be case, when moving to another page
    useEffect(() => {
        return () => toggleProfileMenu(false)
    }, [])

    return (
        <>
            <Box
                ref={e => setAnchorEl(e)}
                display="flex"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleProfileMenu(true)}

                {...attachTours([
                    { id: AppTour.profileSwitcher.id, step: 0 }
                ])}
            >
                <ProfileAvatar profile={selectedProfile} />
                {/* <Avatar
                    src={hasIcon(selectedProfile) && selectedProfile.options.primary_image_public_url}
                    name={selectedProfile.name}
                    className={clx(classes.avatar, { [classes.avatarNoIcon]: !hasIcon(selectedProfile) })} /> */}
                <Hidden smDown>
                    <Box className={classes.selectedProfileNameBox}>
                        <LCTypography
                            className={classes.selectedProfileName}
                            transProps={{ selectedProfileName: selectedProfile.name }}
                            variant="body1" color='secondary' noWrap
                        >
                            <b>{{ selectedProfileName: selectedProfile.name }}</b>
                        </LCTypography>
                    </Box>
                </Hidden>
            </Box>

            <ProfileMenuPopper
                anchorEl={anchorEl}
                open={profileMenuOpen}
                onClose={() => toggleProfileMenu(false)} />
        </>

    );

}

const mapStateToProps = state => {
    return {
        selectedProfile: selectedProfile(state),
        profileMenuOpen: state.layout.profileMenuOpen
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(getUserInfo()),
        toggleProfileMenu: (open) => dispatch(toggleProfileMenu(open))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProfileMenu)
);
