import React from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { connect, useDispatch } from 'react-redux';
import { Grid, ListItemSecondaryAction, Box, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '../../../components/material/Avatar';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { hasPhoto, getBestCreativePhoto, hasPrice } from '../../../selectors/inventory';
import { useState } from 'react';
import IconButtonLoaderNew from '../../../components/material/IconButtonLoaderNew';
import { showError, showSuccess } from '../../../actions/snackbar';
import { exportItems, unexportItems, assumeExportIsRunning } from '../../../actions/exports';
import { ItemExportStatusDescription, RoutePaths, InventoryItemPlaceholder, InventoryItemClasses, ItemExportStatus } from '../../../helpers/constants';
import { Link } from 'react-router-dom';
import { isExportedOrPending } from '../../../selectors/inventoryStatus';
import { hasMaxTitleLengthWarning, shouldShowMaxTitleWarning } from '../../../selectors/exports';
import Tooltip from '../../../components/material/Tooltip';
import { Messages } from '../../../helpers/messages';
import { getItemRunSchedule, getItemBoardIds, getItemDigitalBoardFilter, hasItemFilterOrBoardFilter } from '../../../selectors/inventory';
import { useTranslation } from 'react-i18next';
import { IonCardSubtitle, IonIcon } from '@ionic/react';
import LCTypography from '../../../components/material/LCTypography';
import { alertCircleOutline } from 'ionicons/icons';
import ItemScreenSelectDialog from '../../../components/modals/ItemScreenSelectDialog';
import { Tv } from '@material-ui/icons';
import { ScheduleBuilderDialog } from '../../itemSchedule';
import { setExportRunSchedule, setExportDigitalBoardFilter } from '../../../actions/inventory';
import { FolderSpecial } from '@material-ui/icons';
import { ItemFilterDialog } from '../../itemSchedule';
import { exportsByIdSelector } from '../../../selectors/exports';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../../../helpers/lucoreConstants';
import { currentUserHasPermission } from '../../../selectors/user';

const useStyles = makeStyles(theme => ({
    inline: {
        display: 'inline',
    },
    itemZoomIn: {
        cursor: 'pointer',

        '&:hover img': {
            filter: 'opacity(0.3)'
        },
        '&:hover svg': {
            display: 'block'
        }
    },
    itemDetails: {
        marginTop: theme.spacing(1)
    },
    itemSecondaryAction: {
        paddingRight: 160,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 16,
        },
    },

    itemTitleExported: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold'
    },

    itemHasSettings: {
        color: theme.palette.secondary.main
    },

    warning: {
        color: theme.palette.warning.main
    },
    exportAction: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            top: '20%'
        },
    },
    listAvatarIcon: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    exportPriceContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5
    },
    itemSkipMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.warning.main,
        '& span': {
            marginLeft: 5,
            lineHeight: 'auto'
        },
    },
}));

const ExportItemAvatar = ({ src, isChildCampaign }) => {

    //If we have a  child campaign, then use a circle avatar,
    //otherwise fully show the image which is probably a creative
    return isChildCampaign ? <Avatar alt="item preview"
        src={src}
    /> : <img
        src={src}
        alt="item preview"
        style={{ width: 100, height: 56, objectFit: 'contain' }} />
}

function ExportItem(props) {
    const { itemStatus, settings, exportItem, unexportItem, selectedExport, showScreenSelector = true } = props;
    const { item_id, item, exportId, requested, state, details } = itemStatus;
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [submitting, setSubmitting] = useState(false);
    const [isSettingsOpened, setSettingsOpened] = useState(false);
    const [isScheduleOpened, setScheduleOpened] = useState(false);
    const [isItemFilterOpened, setItemFilterOpened] = useState(false);

    const stateDescription = ItemExportStatusDescription[state] || {};
    const exportedOrPending = isExportedOrPending(itemStatus);
    const { t } = useTranslation();

    const [itemHasSchedule, setItemHasSchedule] = useState(getItemRunSchedule(item, exportId).length > 0);
    const [itemHasFilter, setItemHasFilter] = useState(hasItemFilterOrBoardFilter(item, exportId));
    const [itemHasBoardIds, setItemHasBoardIds] = useState(getItemBoardIds(item, exportId).length > 0);

    const useCanEditCreativeTriggers = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editCreativeTriggers));

    if (stateDescription.hideInList)
        return <></>

    const skipDetails = state === ItemExportStatus.itemInactive ? "Item is in Draft" : (details ? details.skipDetails : null);

    const tooltipTitle = requested ? t('Selected') +
        (exportedOrPending ? t(' And Active') : t(' but not active because of schedule or some other constraint')) : '';

    return (
        <>
            <ListItem
                key={item_id}
                divider
                selected={!exportedOrPending}
                alignItems="flex-start"
                className={clx({ [classes.itemSecondaryAction]: true })}
            >
                <Tooltip
                    title={tooltipTitle}
                >
                    <ListItemAvatar style={{ marginRight: 12 }} className={classes.listAvatarIcon}>
                        <Link to={{ pathname: RoutePaths.inventory + `/${item_id}` }}>
                            <ExportItemAvatar
                                isChildCampaign={item.item_class === InventoryItemClasses.childCampaign.class}
                                src={item && hasPhoto(item)
                                    ? getBestCreativePhoto(item)
                                    : InventoryItemPlaceholder[item && item.item_class]
                                    || InventoryItemPlaceholder[InventoryItemClasses.generic.class]} />
                        </Link>
                    </ListItemAvatar>
                </Tooltip>
                <ListItemText>
                    <Tooltip
                        title={tooltipTitle}
                    >
                        <Link to={{ pathname: RoutePaths.inventory + `/${item_id}` }}
                            style={{ textDecoration: 'none' }}>
                            <IonCardSubtitle
                                className={clx({ [classes.itemTitleExported]: exportedOrPending })}
                            >{item
                                ? item.title
                                : item_id}
                            </IonCardSubtitle>
                        </Link>
                    </Tooltip>

                    <Grid container spacing={1} className={classes.exportPriceContainer}>
                        {item
                            && !isNaN(+item.price)
                            && hasPrice(item)
                            &&
                            <Typography variant="body2" color="textSecondary">${(+item.price).toFixed(0)}</Typography>
                        }
                        {item
                            &&
                            <Typography variant="body2" color="textSecondary"
                                style={{ fontStyle: 'italic' }}>{item.dealer_stock_number}</Typography>
                        }
                    </Grid>

                    {item
                        &&
                        <Grid className={classes.itemDetails}
                            container
                            spacing={1}
                            alignItems="center">

                            {skipDetails && <>

                                <Box className={classes.itemSkipMessage}>

                                    <IonIcon icon={alertCircleOutline} />
                                    <LCTypography variant="overline">
                                        {skipDetails ? skipDetails : stateDescription.long}
                                    </LCTypography>
                                </Box>

                            </>}

                            {shouldShowMaxTitleWarning(item) && hasMaxTitleLengthWarning(settings, item.title)
                                && isExportedOrPending(itemStatus)
                                && <Box display="flex"
                                    component={Grid}
                                    className={classes.warning}>
                                    <Box component={Typography}
                                        mr={1}
                                        alignSelf="flex-start" />

                                    <Box
                                        alignSelf="flex-end"
                                        component={Typography}
                                        variant="body2">
                                        {Messages.itemTitleCouldBeTooLong}
                                    </Box>
                                </Box>}
                        </Grid>
                    }
                </ListItemText>

                <ListItemSecondaryAction className={classes.exportAction}>
                    {item
                        && <>
                            <Tooltip title={t('Adjust Date Range')}>
                                <IconButton
                                    disabled={!useCanEditCreativeTriggers}
                                    className={clx({
                                        [classes.itemHasSettings]: itemHasSchedule
                                    })}
                                    size={isSmDown
                                        ? "small"
                                        : "medium"}
                                    onClick={() => setScheduleOpened(!isScheduleOpened)}
                                >
                                    <DateRangeIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={t('Set custom filters and triggers')}>
                                <IconButton
                                    disabled={!useCanEditCreativeTriggers}
                                    className={clx({
                                        [classes.itemHasSettings]: itemHasFilter
                                    })}
                                    size={isSmDown
                                        ? "small"
                                        : "medium"}
                                    onClick={() => setItemFilterOpened(!isItemFilterOpened)}
                                >
                                    <FolderSpecial />
                                </IconButton>
                            </Tooltip>

                            {showScreenSelector && <Tooltip title={t('Select Screens')}>
                                <IconButton
                                    disabled={!useCanEditCreativeTriggers}
                                    className={clx({
                                        [classes.itemHasSettings]: itemHasBoardIds
                                    })}
                                    onClick={() => setSettingsOpened(!isSettingsOpened)}>
                                    <Tv />
                                </IconButton>
                            </Tooltip>
                            }

                            <ScheduleBuilderDialog
                                open={isScheduleOpened}
                                handleClose={() => setScheduleOpened(false)}
                                title={t(`Schedule Editor`)}

                                schedule={getItemRunSchedule(item, exportId)}
                                setSchedule={newSchedule => {

                                    if (newSchedule.some(x => x.type === "date_range"
                                        || x.type === "time_range"
                                        || x.type === "days_of_the_week"
                                    )) {
                                        setItemHasSchedule(true); //purely for the icon color state
                                    } else {
                                        setItemHasSchedule(false); //purely for the icon color state
                                    }

                                    dispatch(setExportRunSchedule(item_id, exportId, newSchedule))
                                        .then(() => dispatch(assumeExportIsRunning(exportId)))
                                        .then(() => dispatch(showSuccess(t('Item schedule saved'))))
                                        .catch(() => dispatch(showError(t('Error saving schedule'))))
                                }}
                            />

                            <ItemFilterDialog
                                open={isItemFilterOpened}
                                handleClose={() => setItemFilterOpened(false)}
                                title={`Custom Filters / Triggers Editor`}
                                accountId={selectedExport.account_id}
                                schedule={getItemRunSchedule(item, exportId)}
                                digitalBoardFilter={getItemDigitalBoardFilter(item, exportId)}
                                setFilter={(newSchedule, newDigitalBoardFilter) => {

                                    if (
                                        newSchedule.some(x => x.type === "item_filter")
                                        || newDigitalBoardFilter.filter_fields?.length > 0
                                    ) {
                                        setItemHasFilter(true); //purely for the icon color state
                                    } else {
                                        setItemHasFilter(false); //purely for the icon color state
                                    }
                                    dispatch(setExportRunSchedule(item_id, exportId, newSchedule)).finally(() => {
                                        dispatch(setExportDigitalBoardFilter(item_id, exportId, newDigitalBoardFilter))
                                            .then(() => dispatch(assumeExportIsRunning(exportId)))
                                            .then(() => dispatch(showSuccess(t('Item filter saved'))))
                                            .catch(() => dispatch(showError(t('Error saving filter'))))
                                    })

                                }}
                            />

                            <ItemScreenSelectDialog
                                open={isSettingsOpened}
                                handleClose={() => setSettingsOpened(false)}
                                itemId={item_id}
                                exportId={exportId}
                                itemBoardIds={getItemBoardIds(item, exportId)}
                                lazy
                                onChange={(hasBoardIds) => {
                                    if (hasBoardIds) {
                                        setItemHasBoardIds(true); //purely for the icon color state
                                    } else {
                                        setItemHasBoardIds(false); //purely for the icon color state
                                    }
                                }}
                            />
                        </>}
                    {requested
                        &&
                        <Tooltip
                            title={tooltipTitle}
                        >
                            <IconButtonLoaderNew
                                submitting={submitting}
                                size={24}
                                color="secondary"
                                style={{ opacity: exportedOrPending ? 1 : 0.5 }}
                                buttonSize={isSmDown
                                    ? "small"
                                    : "medium"}
                                onClick={() => {
                                    setSubmitting(true);
                                    unexportItem(settings.inventory_export_id, item_id)
                                        .finally(() => setSubmitting(false))
                                }}>
                                <CheckCircleIcon />
                            </IconButtonLoaderNew>
                        </Tooltip>
                    }
                    {!requested
                        && item
                        &&
                        <Tooltip title={t('Add Item')}>
                            <IconButtonLoaderNew
                                submitting={submitting}
                                size={24}
                                buttonSize={isSmDown
                                    ? "small"
                                    : "medium"}
                                onClick={() => {
                                    setSubmitting(true);
                                    exportItem(settings.inventory_export_id, item_id)
                                        .finally(() => setSubmitting(false))
                                }}>
                                <AddCircleOutlineIcon />
                            </IconButtonLoaderNew>
                        </Tooltip>
                    }
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
}

ExportItem.propTypes = {
    itemStatus: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedExport: exportsByIdSelector(state)(ownProps.itemStatus.exportId) || {},
    }
}

const mapDispatchToProps = dispatch => {
    return {
        exportItem: (exportId, itemId) => dispatch(exportItems(exportId, [itemId]))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                dispatch(showError('Unexpected error during adding...'));
                throw error;
            }),

        unexportItem: (exportId, itemId) => dispatch(unexportItems(exportId, [itemId]))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                dispatch(showError('Unexpected error during adding...'));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportItem
);
