import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTour } from '../../actions/user';
import { AppTour, AppTourStatus } from '../../helpers/constants';
import { isLoggedIn, userTourSelector } from '../../selectors/user';
import { getLayout } from '../../selectors/layout';
import { TourCloseReason } from '../../components/reactour/ReactTour';
import ProfileSwitcherTour from './ProfileSwitcherTour';

const Tours = (props) => {
    const { toursReady, tourStarted, layout,
        startTour, skipTour, completeTour, isLoggedIn } = props;
    const [starting, setStarting] = useState(false);

    const shouldStartTour = !tourStarted
        && toursReady.length
        && !starting;

    useEffect(() => {
        if (shouldStartTour) {
            setStarting(true);
            startTour(toursReady[0])
                .finally(() => setStarting(false));
        }
    }, [shouldStartTour, toursReady, startTour])

    const onClose = useCallback((reason, step) => {
        // eslint-disable-next-line no-undef
        let promise = Promise.resolve();

        if (reason === TourCloseReason.completed) {
            promise = completeTour(tourStarted, step)
        } else {
            promise = skipTour(tourStarted, step)
        }

        return promise;
    }, [tourStarted, skipTour, completeTour])

    const isTourOpen = id => layout.loaded
        && Boolean(tourStarted && tourStarted.id === id);

    return <>
        {isLoggedIn
            && <ProfileSwitcherTour
                open={isTourOpen(AppTour.profileSwitcher.id)}
                onClose={onClose}
            />}
    </>;
};

const tours = [
    AppTour.profileSwitcher
]

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
        layout: getLayout(state),

        toursReady: tours
            .map(tour => userTourSelector(state)(tour.id))
            .filter(t => t.status === AppTourStatus.ready),

        tourStarted: tours
            .map(tour => userTourSelector(state)(tour.id))
            .find(t => t.status === AppTourStatus.started)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        startTour: tour => dispatch(setTour({
            ...tour,
            status: AppTourStatus.started,
            date_time_started: moment.utc().toISOString()
        })),

        skipTour: (tour, step) => dispatch(setTour({
            ...tour,
            status: AppTourStatus.skipped,
            date_time_complete: moment.utc().toISOString(),
            last_step: step
        })),

        completeTour: (tour, step) => dispatch(setTour({
            ...tour,
            status: AppTourStatus.completed,
            date_time_complete: moment.utc().toISOString(),
            last_step: step
        }))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tours);
