import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProgrammaticBudgetTypes } from '../../helpers/constants';
import { DateRangeInput } from '../../components/inputs/DateRangeInput';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PROGRAMMATIC_BUDGET_CHANGE, PROGRAMMATIC_DATES_CHANGE } from '../../helpers/actionTypes';

const BudgetFilter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const budget = useSelector(state => state.programmatic.budget.amount);
    const budgetType = useSelector(state => state.programmatic.budget.budget_type);
    const setBudget = (budget, budget_type) => dispatch({
        type: PROGRAMMATIC_BUDGET_CHANGE,
        budget,
        budget_type
    });
    const [budgetInput, setBudgetInput] = useState(budget);

    const dateRange = useSelector(state => state.programmatic.dates);
    const setDateRange = (dateRange, touched) => dispatch({
        type: PROGRAMMATIC_DATES_CHANGE,
        touched,
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString()
    });

    const budgetParams = ProgrammaticBudgetTypes[budgetType].budgetParams;

    useEffect(() => {
        if (budget < budgetParams.min) {
            setBudget(budgetParams.min, budgetType);
            setBudgetInput(budgetParams.min);
        } else if (budget > budgetParams.max) {
            setBudget(budgetParams.max, budgetType);
            setBudgetInput(budgetParams.max);
        }

        // Set default range only when date was not changed by user
        if (budgetParams.defaultDuration && !dateRange.touched) {
            setDateRange({
                startDate: moment().toDate(),
                endDate: moment().add(budgetParams.defaultDuration,
                    budgetType == ProgrammaticBudgetTypes.daily.code
                        ? 'days'
                        : 'weeks').toDate()
            })
        }
    }, [budgetType])

    useEffect(() => {
        dispatch({
            type: PROGRAMMATIC_BUDGET_CHANGE,
            budget: budget,
            budget_type: budgetType,
        });
        setBudgetInput(budget)
    }, [budget, budgetType])

    return <>
        <Typography variant="subtitle1" gutterBottom><b>{t('Budget')}</b></Typography>
        <Box display="flex">
            <TextField
                variant="outlined"
                size='small'
                title='Budget'
                fullWidth
                style={{ maxWidth: 100 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: {
                        min: budgetParams.min,
                        max: budgetParams.max,
                    },
                }}
                value={budgetInput}
                onChange={e => {
                    setBudgetInput(e.target.value)

                    if (isNaN(e.target.value) == false) {
                        setBudget(+e.target.value, budgetType);
                    }
                }}
            />
            <FormControl size="small" style={{ marginLeft: 8 }} fullWidth>
                <Select
                    variant='outlined'
                    size="small"
                    value={budgetType}
                    onChange={e => setBudget(budget, e.target.value)}
                >
                    {Object.keys(ProgrammaticBudgetTypes)
                        .map(key => {
                            const type = ProgrammaticBudgetTypes[key];
                            return <MenuItem key={type.code} value={type.code}>
                                {type.altName}
                            </MenuItem>
                        })}
                </Select>
            </FormControl>
        </Box>

        <Box mt={1}>
            <DateRangeInput
                size="small"
                meta={{}}
                input={{
                    value: {
                        startDate: new Date(dateRange.startDate),
                        endDate: new Date(dateRange.endDate)
                    },
                    onChange: (value) => setDateRange(value, true)
                }}
                fullWidth
                mobilePickerProps={{
                    disablePast: true,
                    autoOk: true,
                    emptyLabel: 'No End Date',
                    // eslint-disable-next-line react/display-name
                }}
                pickerProps={{
                    showMonthAndYearPickers: false,
                    showDateDisplay: false,
                    months: 2,
                    direction: "horizontal",
                }}
                format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                    ? "MMM DD"
                    : "MMM DD, YYYY"}
            />
        </Box>
    </>
};

export default BudgetFilter;
