import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { showError, showSuccess } from '../../actions/snackbar';
import { ConfirmationDialog } from '../../components';
import { lucitApi } from "../../services/lucitApi"
import LCTypography from '../../components/material/LCTypography';
import i18next from 'i18next';
import { deleteScreen } from '../../actions/billboards';
import { useTranslation } from 'react-i18next';

const DeleteBillboardButton = ({ screen, showAsListItem, onClick }) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const handleDelete = () => dispatch(deleteScreen(screen))
        .then(() => dispatch(showSuccess(i18next.t('Screen was deleted'))))
        .catch(error => {
            dispatch(showError(i18next.t('Failed to delete Screen')));

            throw error;
        })

    return < >
        {showAsListItem
            ? <MenuItem
                disabled={loading}
                onClick={() => {
                    if (loaded) {
                        onClick && onClick();
                        setIsConfirmOpen(true)
                        return;
                    }

                    setLoading(true);
                    lucitApi.objects.canBeDeleted(screen.lcuid)
                        .then((x) => {
                            setCanBeDeleted(x.can_be_deleted);
                            setReasons(x.reasons);
                            setIsConfirmOpen(true)
                            setLoaded(true);
                            onClick && onClick();
                        })
                        .finally(() => setLoading(false))
                }}>
                <ListItemIcon ><Delete /></ListItemIcon>
                <ListItemText primary="Delete" />
            </MenuItem>
            : <IconButton
                disabled={loading}
                onClick={() => {
                    if (loaded) {
                        setIsConfirmOpen(true)
                        return;
                    }

                    setLoading(true);
                    lucitApi.objects.canBeDeleted(screen.lcuid)
                        .then((x) => {
                            setCanBeDeleted(x.can_be_deleted);
                            setReasons(x.reasons);
                            setIsConfirmOpen(true)
                            setLoaded(true);
                        })
                        .finally(() => setLoading(false))
                }}>

                {loading
                    ? <CircularProgress size={24} />
                    : <Delete />}

            </IconButton>}

        <ConfirmationDialog
            lazy
            title={screen && screen.name}
            dialogProps={{
                open: isConfirmOpen,
                onClose: () => setIsConfirmOpen(false),
                keepMounted: false
            }}
            ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
            handleCancel={() => setIsConfirmOpen(false)}
            handleConfirm={() => {
                if (!canBeDeleted) {
                    return setIsConfirmOpen(false);
                }

                return handleDelete(screen)
                    .finally(() => setIsConfirmOpen(false))
            }}>
            {canBeDeleted
                && <>
                    <LCTypography>Are you sure you want to remove this screen?</LCTypography>
                </>
            }
            {!canBeDeleted
                && <>
                    <LCTypography>Screen can not be deleted because:</LCTypography>

                    <ul>
                        {Object.keys(reasons).map((reason_code, index) => {
                            return <li key={index}>{reasons[reason_code]}</li>
                        })}
                    </ul>
                </>}
        </ConfirmationDialog>
    </>
}

export default DeleteBillboardButton;
