import React from 'react';
import clx from 'classnames';
import { TextField, Checkbox, InputAdornment, makeStyles } from '@material-ui/core';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { Field } from 'redux-form';
import CircularProgressCentered from '../material/CircularProgressCentered';

const AutocompleteField = (props) => {
    return <Field
        component={Autocomplete}
        //https://github.com/redux-form/redux-form/issues/2768#issuecomment-411434841
        onBlur={e => { e.preventDefault(); }}
        {...props}
    />
}

const useStyles = makeStyles(() => {
    return {
        fullWidth: {
            width: '100%'
        }
    }
});

const Autocomplete = (props) => {
    const { input, label, className, fullWidth, meta: { touched, error } = {}, textFieldProps, options, ...custom } = props;
    const { getOptionLabel, loading, customRenderOption, multiple = true, ...other } = custom;
    const classes = useStyles();

    const defaultRenderOption = (option, { selected }) => (
        <>
            {multiple && <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
            />}
            {getOptionLabel(option)}
        </>
    )

    const textFieldPropsCombined = Object.assign({},
        textFieldProps,
        loading && {
            InputProps: {
                endAdornment: <InputAdornment><CircularProgressCentered size={25} /></InputAdornment>
            }
        })
    return (
        <MaterialAutocomplete
            {...input}
            className={clx(className, { [classes.fullWidth]: fullWidth })}
            disableCloseOnSelect
            multiple={multiple}
            openOnFocus={true}
            loading={loading}
            //TODO: upgrade material ui lib
            // fullWidth={fullWidth}
            limitTags={5}

            onChange={(_, values) => input.onChange(values)}
            options={options}
            getOptionSelected={option => input.value.some(v => v.value === option.value)}
            getOptionLabel={getOptionLabel}

            renderOption={customRenderOption ? customRenderOption : defaultRenderOption}
            renderInput={renderProps => <TextField
                {...renderProps}
                {...textFieldPropsCombined}

                label={label}
                error={touched && !!error}
                helperText={touched && error}

            />}

            {...other}

        />
    )
};

export default AutocompleteField;
export { Autocomplete }
