import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

const YouTubeVideo = ({ url, youTubeProps = {} }) => {
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|watch\?vi=|&vi=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const videoId = getYouTubeVideoId(url);

    if (!videoId) {
        return <p>Invalid YouTube URL</p>;
    }

    return (
        <YouTube videoId={videoId} {...youTubeProps} />
    );
};

YouTubeVideo.propTypes = {
    url: PropTypes.string.isRequired,
};

export default YouTubeVideo;
