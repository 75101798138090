import {
    BILLING_INVOICES_RESULT, BILLING_PLANS_RESULT, PAYMENT_METHODS_ADD_CARD, PAYMENT_METHODS_CARDS_REQUEST,
    PAYMENT_METHODS_CARDS_RESULT, PAYMENT_METHODS_REMOVE_CARD, PAYMENT_METHODS_SET_PRIMARY_CARD
} from "../../helpers/actionTypes";

export const initialState = {
    options: {},
    cards: [],
    invoices: [],
    plans: [],
}

export default function billing(state = initialState, { type, ...payload }) {
    switch (type) {
        case PAYMENT_METHODS_CARDS_REQUEST: {
            return state;
        }

        case PAYMENT_METHODS_CARDS_RESULT:
            if (!payload.has_stripe) {
                return state;
            }

            return Object.assign({}, state, {
                options: {
                    ...state.options,
                    can_remove_last_card: payload.can_remove_last_card,
                },
                cards: payload.stripe.cards.map(card => {
                    card.default = card.id === payload.stripe.default_source;

                    return card;
                })
            });

        case PAYMENT_METHODS_ADD_CARD:
            return Object.assign({}, state, {
                cards: [
                    ...state.cards,
                    payload.card
                ]
            });
        case PAYMENT_METHODS_REMOVE_CARD:
            return Object.assign({}, state, {
                cards: state.cards
                    .filter(c => c.id !== payload.id)
                    .map((c, idx, arr) => {
                        // if default card was removed, set first card as default
                        if (payload.default && idx === arr.length - 1) {
                            return Object.assign({}, c, { default: true });
                        }

                        return c;
                    })
            });
        case PAYMENT_METHODS_SET_PRIMARY_CARD:
            return Object.assign({}, state, {
                cards: state.cards.map(c => Object.assign({}, c, { default: c.id === payload.id }))
            });

        case BILLING_INVOICES_RESULT:
            return Object.assign({}, state, {
                invoices: payload.invoices.data
            });
        case BILLING_PLANS_RESULT:
            return Object.assign({}, state, {
                plans: payload.billing_plans.data
            });
        default:
            return state
    }
}
