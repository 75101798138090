import React from 'react';
import { showInfo, updateActiveMessage } from '../../actions/snackbar';
import { history } from '../../helpers/history';
import { Typography, Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { ProfileType } from '../../helpers/constants';
import { getUserInfo } from '../../actions/user';
import { store } from '../../reducers';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer'
        },

        icon: {
            marginRight: theme.spacing(1),
            height: theme.spacing(4),
            objectFit: 'contain'
        },
        gutterBottomText: {
            wordBreak: 'break-word'
        }
    }
});

/**
 * Note that this notification is registered for both
 * AccountInformationNotifications AND AgencyInformationNotifications
 * See constants.js and index.js
 */

const AccountInformationNotification = ({ notification }) => {

    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {notification.data
                && <>
                    <Typography variant="subtitle1">{notification.data.title}</Typography>
                    <Typography className={classes.gutterBottomText} variant="body2" gutterBottom>{notification.data.body}</Typography>
                    <Typography
                        variant="caption" align="right">{moment.utc(notification.data.created_at).local().format("hh:mm A MMMM DD, YYYY")}
                    </Typography>
                </>}
        </Box>
    );
}

const handleNotification = (notification, dispatch, handleClick) => {

    dispatch(
        showInfo(<AccountInformationNotification notification={notification} />, {
            snackbarProps: {
                onClick: () => {
                    handleClick();
                    dispatch(updateActiveMessage({ isOpen: false }));
                }
            }
        }))
};

const handleClick = () => {
    store.dispatch(getUserInfo()).then(() => history.push({ pathname: ProfileType.account.path }));
}

export { AccountInformationNotification, handleNotification, handleClick }
