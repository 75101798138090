import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";

const libraries = ["places", "drawing", "marker", "geometry"];

export function useGoogleMapScripts(key) {
    const [gmAuthError, setGmAuthError] = useState(null);
    const { isLoaded: isGmLoaded, loadError: gmLoadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key,
        libraries,
        version: "beta"
    })

    const gmContext = useMemo(() => ({
        key,
        loaded: isGmLoaded,
        error: key
            ? gmLoadError || gmAuthError
            : `Map cannot be displayed because the REACT_APP_GOOGLE_API_KEY params are empty`
    }), [key, isGmLoaded, gmLoadError, gmAuthError])

    useEffect(() => {
        // See https://developers.google.com/maps/documentation/javascript/events#auth-errors
        window.gm_authFailure = () => {
            setGmAuthError("Google Maps API key is invalid")
        }
    }, []);

    return gmContext
}
