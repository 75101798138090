import React, { useEffect, useState } from 'react';
import LightningDeviceLayout from './LightningDeviceLayout';
import {
    Box, Grid, TextField, Divider, MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LightningTransitions } from '../../helpers/constants';

const DeviceLayoutEditor = ({ deviceLayout, onDeviceLayoutChange }) => {

    const { t } = useTranslation();

    const [localDeviceLayout, setLocalDeviceLayout] = useState(deviceLayout ?? {
        name: "New Device Layout", data: {
            globalStyles: "",
            globqlHTML: "",
            screens: [{
                code: "top_screen",
                name: "Top Screen",
                type: "fixed",
                position: { left: "0%", top: "0%" },
                size: { width: "100%", height: "100%" },
                styles: "{}",
                data: { animation: null }
            }]
        }
    })

    const [name, setName] = useState(localDeviceLayout.name)
    const [screenCount, setScreenCount] = useState(localDeviceLayout.data.screens.length)
    const [globalStyles, setGlobalStyles] = useState(localDeviceLayout.data.globalStyles)
    const [globalHTML, setGlobalHTML] = useState(localDeviceLayout.data.globalHTML)

    useEffect(() => {

        const newDeviceLayout = {
            name: name,
            data: {
                globalStyles: globalStyles,
                globalHTML: globalHTML,
                screens: []
            }
        }

        for (let i = 0; i < screenCount; i++) {
            let widthPercentage, leftPercentage, heightPercentage, topPercentage;

            switch (screenCount) {
                case 1:
                    widthPercentage = 100;
                    heightPercentage = 100;
                    leftPercentage = 0;
                    topPercentage = 0;
                    break;
                case 2:
                    widthPercentage = 100;
                    heightPercentage = 50;
                    topPercentage = i === 0 ? 0 : 50;
                    leftPercentage = 0;
                    break;
                case 3:
                    widthPercentage = 100 / screenCount;
                    leftPercentage = widthPercentage * i;
                    topPercentage = 0;
                    heightPercentage = 100;
                    break;
                case 4:
                    widthPercentage = 50;
                    heightPercentage = 50
                    leftPercentage = i % 2 === 0 ? 0 : 50;
                    topPercentage = i < 2 ? 0 : 50;
                    break;
                default:
                    widthPercentage = 100 / screenCount;
                    leftPercentage = widthPercentage * i;
                    break;
            }

            newDeviceLayout.data.screens.push({
                code: `screen_${i}`,
                name: `Screen ${i + 1}`,
                type: "fixed",
                no_content_behavior: "show_message",
                position: { left: leftPercentage + "%", top: topPercentage + "%" },
                size: { width: widthPercentage + "%", height: heightPercentage + "%" },
                data: { animation: null }
            })

            setLocalDeviceLayout(newDeviceLayout)
        }
    }, [screenCount])

    useEffect(() => {

        const newDeviceLayout = {
            ...localDeviceLayout
        }

        newDeviceLayout.name = name;
        newDeviceLayout.data.globalStyles = globalStyles;
        newDeviceLayout.data.globalHTML = globalHTML;

        setLocalDeviceLayout(newDeviceLayout)

    }, [name, globalStyles, globalHTML])

    useEffect(() => {

        if (!onDeviceLayoutChange)
            return;

        onDeviceLayoutChange(localDeviceLayout)

    }, [localDeviceLayout])

    return (

        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={t('Name')}
                    value={localDeviceLayout ? localDeviceLayout.name : ''}
                    onChange={e => setName(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={t('Number of Screens')}
                    value={screenCount}
                    onChange={e => setScreenCount(parseInt(e.target.value))}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={10}
                    label={t('Custom Global CSS')}
                    value={globalStyles}
                    onChange={e => setGlobalStyles(e.target.value)}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={10}
                    label={t('Custom Global HTML')}
                    value={globalHTML}
                    onChange={e => setGlobalHTML(e.target.value)}
                />
            </Grid>

            <Grid item xs={8}>
                <Box>
                    {localDeviceLayout && <LightningDeviceLayout deviceLayout={localDeviceLayout} width={500} />}
                </Box>
            </Grid>
            <Grid item xs={4}>

                {localDeviceLayout && localDeviceLayout.data.screens.map((screen, index) => {
                    return <Box key={index} mb={3}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Screen Name')}
                            value={screen.name}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].name = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />
                        <TextField
                            fullWidth
                            select
                            style={{ marginBottom: 16 }}
                            variant='outlined'
                            size="small"
                            label={t('Animation')}
                            value={screen.animation}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].animation = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        >
                            <MenuItem value={null}>{t('No animation')}</MenuItem>
                            {Object.keys(LightningTransitions)
                                .map(key =>
                                    <MenuItem key={key} value={key}>{t(LightningTransitions[key].title)}</MenuItem>
                                )}
                        </TextField>
                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Left Position')}
                            value={screen.position.left}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].position.left = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />
                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Top Position')}
                            value={screen.position.top}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].position.top = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />
                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Width')}
                            value={screen.size.width}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].size.width = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />
                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Height')}
                            value={screen.size.height}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].size.height = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />

                        <TextField
                            select
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Empty Playlist Behavior')}
                            value={screen.no_content_behavior ? screen.no_content_behavior : "show_message"}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].no_content_behavior = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        >
                            <MenuItem value="show_message">Show Message</MenuItem>
                            <MenuItem value="show_nothing">Show Nothing</MenuItem>
                        </TextField>

                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Custom CSS class names')}
                            helperText={t('space separated')}
                            value={screen.classNames ? screen.classNames : ""}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].classNames = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />

                        <TextField
                            fullWidth
                            variant='outlined'
                            size="small"
                            style={{ marginBottom: 16 }}
                            label={t('Styles')}
                            helperText={t('react style object, json with quoted keys and values')}
                            value={screen.styles ? screen.styles : "{}"}
                            onChange={e => {
                                const newDeviceLayout = { ...localDeviceLayout }
                                newDeviceLayout.data.screens[index].styles = e.target.value;
                                setLocalDeviceLayout(newDeviceLayout)
                            }}
                        />
                        <Divider />
                    </Box>
                })}

            </Grid>

        </Grid>

    )

}

export default DeviceLayoutEditor;
