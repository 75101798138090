import { Box, makeStyles, Link } from '@material-ui/core';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { assignApplication, getAllApplications } from '../../actions/applications';
import { showSuccess } from '../../actions/snackbar';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { requiresAuthorization } from '../../selectors/applications';
import NewAppAuthorization from './NewAppAuthorization';
import { selectedProfileIsOperator, selectedProfileIsAdAgency, selectedProfileIsAccount } from '../../selectors/user';
import { getCapabilityConfigForClassName } from '../../selectors/applications'
import LCTypography from '../../components/material/LCTypography';
import NewDataSourceRequest from '../../components/modals/NewDataSourceRequest';
import ApplicationList from './ApplicationList';
import { getUserInfo } from '../../actions/user';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            height: 'calc(100vh - 180px)',
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const NewApplicationAssignment = (props) => {
    const { lcuid, open, handleClose, getAllApplications, assignApplication, getUserInfo,
        selectedProfileIsOperator, selectedProfileIsAdAgency, selectedProfileIsAccount } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [apps, setApplications] = useState(null);
    const [app, setApp] = useState(null);

    const [showNewDataSourceRequest, setShowNewDataSourceRequest] = useState(false);

    const showAppForCurrentProfile = (app) => {

        return app.capabilities?.some(c => {

            const capabilityConfig = getCapabilityConfigForClassName(c.destination.capability_class);

            const shouldSee = (capabilityConfig
                && ((capabilityConfig.showForAccount && selectedProfileIsAccount)
                    || (capabilityConfig.showForOperator && selectedProfileIsOperator)
                    || (capabilityConfig.showForAdAgency && selectedProfileIsAdAgency))) ? true : false

            return shouldSee ?? false
        })
    }

    const selectApp = (app) => {

        if (requiresAuthorization(app.application_class)) {
            setApp(app);
        }
        else {
            assignApplication(lcuid, app).then(() => {
                handleClose(app)
            })
        }
    }

    useEffect(() => {
        if (open) {
            getAllApplications({
                params: {
                    per_page: 1000
                }
            })
                .then((applications) => setApplications(applications.filter((app) => showAppForCurrentProfile(app))));
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            setApp(null);
        }
    }, [open])

    useEffect(() => {
        if (open) {
            getUserInfo()
        }
    }, [open])

    return (
        <GenericDialog
            title={t('Add Application or Data Source')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'lg'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box mt={2}>

                <LCTypography variant="body2" gutterBottom style={{ float: "right" }}>
                    Don&apos;t see what you are looking for?<> </>
                    <Link
                        style={{ cursor: 'pointer' }}
                        color="secondary"
                        onClick={() => setShowNewDataSourceRequest(true)}
                    >
                        {t('Click here to request a new data source or application')}
                    </Link>
                </LCTypography>

                <ApplicationList apps={apps} selectApp={selectApp} />

                <NewAppAuthorization
                    app={app}
                    open={Boolean(app)}
                    handleClose={() => setApp(null)}
                    handleAuthorized={() => {
                        return assignApplication(lcuid, app)
                            .then(() => handleClose(app))
                    }}
                />

                <NewDataSourceRequest
                    open={showNewDataSourceRequest}
                    handleClose={() => setShowNewDataSourceRequest(false)}
                />
            </Box>}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        selectedProfileIsOperator: selectedProfileIsOperator(state),
        selectedProfileIsAdAgency: selectedProfileIsAdAgency(state),
        selectedProfileIsAccount: selectedProfileIsAccount(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllApplications: (filters) => dispatch(getAllApplications(filters)),

        getUserInfo: () => dispatch(getUserInfo()), //This is here to refresh the user account apps list

        assignApplication: (lcuid, app) => dispatch(assignApplication(lcuid, app))
            .then(() => dispatch(showSuccess(i18next.t('{{name}} was successfully connected', { name: app.name }))))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewApplicationAssignment)
