import { apiHttp } from "../helpers/api";

export default class LucitApiRoles {

    getAgencyRoles() {
        return apiHttp.get(`/agencies/roles`)
            .then(response => response.data.roles)
    }

    getAccountRoles() {
        return apiHttp.get(`/accounts/roles`)
            .then(response => response.data.roles)
    }

    getApplicationRoles() {
        return apiHttp.get(`/applications/roles`)
            .then(response => response.data.roles);
    }
}
