import React from 'react'
import { ApplicationFeatures } from '../../helpers/constants'
import ApplicationFeatureToggle from '../../components/features/ApplicationFeatureToggle'

const ApparatixFeatureToggle = ({ children, reverse }) => {

    return <ApplicationFeatureToggle
        appLcuid={ApplicationFeatures.apparatix_player.lcuid}
        reverse={reverse}
    >
        {children}
    </ApplicationFeatureToggle>

}

export default ApparatixFeatureToggle;
