import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';

const LucitFormFieldGeneric = ({ field, value, onChange }) => {

    const isInteger = () => {
        if (typeof value === 'number' && Number.isInteger(value)) {
            return true; // It's an integer
        }

        if (typeof value === 'string' && /^\d+$/.test(value)) {
            return true; // It's a string representing an integer
        }

        return false
    }

    const isFloat = () => {
        if (typeof value === 'number' && !Number.isInteger(value)) {
            return true; // It's a float, so it's valid
        }

        if (typeof value === 'string' && /^-?\d+(\.\d+)?$/.test(value)) {
            return true; // It's a string representing a float, so it's valid
        }

        return false; // Not a float
    };

    const isValidLink = () => {
        const linkRegex = /^[\w-]+(\.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;

        return typeof value === 'string' && linkRegex.test(value);
    };

    const isError = () => {

        if (!value) {
            return false;
        }

        if (field.type === 'int' && !isInteger()) {
            return true;
        }

        if (field.type === 'float' && !isFloat()) {
            return true;
        }

        if (field.type === 'link' && !isValidLink()) {
            return true;
        }

        if (field.max && value > field.max) {
            return true;
        }

        if (field.min && value < field.min) {
            return true;
        }

        if (field.max_length && value.length > field.max_length) {
            return true;
        }

        return false;
    }

    return <TextField
        id={field.key}
        name={field.key}
        autoComplete="off"
        value={value ?? ''}
        label={field.name}
        select={field.enum}
        multiline={field.multiline}
        rows={field.multiline ? (field.rows ?? 4) : undefined}
        fullWidth
        error={isError()}
        helperText={field.description}
        required={field.required}
        placeholder={field.placeholder}
        onChange={(e) => {
            onChange(e.target.value)
        }}
    >
        {field.enum && field.enum_options.map((option) => {
            return <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem >
        })}

    </TextField>

}

export default LucitFormFieldGeneric;
