import { defaultTheme } from '../theme/defaultTheme';

class PasswordStrength {

    criterias = [];

    constructor () {
        this.criterias = Object.keys(PasswordCriteria)
            .map(key => {
                return {
                    ruleName: key,
                    ruleRegex: PasswordCriteria[key]
                }
            })
    }

    calculate(value) {
        const unit = 100 / this.criterias.length;
        const score = this.criterias.filter(c => c.ruleRegex.test(value)).length * unit;

        const nearest = Object.keys(passwordScoreMap)
            .find(x => +x >= score);

        return {
            ...passwordScoreMap[nearest],
            value: score
        };
    }
}

class PasswordCriteria {
    static at_least_eight_chars = RegExp(/^.{8,63}$/);
    static at_least_one_lower_case_char = RegExp(/^(?=.*?[a-z])/);
    static at_least_one_upper_case_char = RegExp(/^(?=.*?[A-Z])/);
    static at_least_one_digit_char = RegExp(/^(?=.*?[0-9])/);
    static at_least_one_special_char = RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/);
}

export const passwordStrengthMeter = new PasswordStrength();

export const passwordScoreMap = {
    0: {
        text: 'Weak',
        color: defaultTheme.palette.error.main,
    },
    20: {
        text: 'Fair',
        color: defaultTheme.palette.warning.main,
    },
    40: {
        text: 'Fair',
        color: defaultTheme.palette.warning.main,
    },
    60: {
        text: 'Good',
        color: defaultTheme.palette.info.main,
    },
    80: {
        text: 'Strong',
        color: defaultTheme.palette.success.main,
    },
    100: {
        text: 'Strong',
        color: defaultTheme.palette.success.main,
    },
}
