import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
import { OgMetatags, PageTitle } from '../../components';
import UserFeed from '../../containers/user/UserFeed';
import UserProfile from '../../containers/user/UserProfile';

import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { hasIcon } from '../../selectors/account';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';

function Profile(props) {
    const { object } = props;

    return <>
        <Box component={Paper} p={3} style={{ maxWidth: '600px', margin: 'auto' }}>
            <PageTitle title={object.name} />
            <OgMetatags
                title={object.name}
                image={hasIcon(object)
                    ? object.options.primary_image_public_url
                    : null} />

            <UserProfile user={object} />
        </Box>

        <Box mt={2}>
            <UserFeed user={object} />
        </Box>
        <ButtonScrollToTopFab />
    </>
}

export default withRouter(
    withConditionalLayout(
        Profile, { publicOptions: { disablePaper: true } }
    )
);