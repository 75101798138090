import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Typography, Divider, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { getUser } from '../../selectors/user';
import { showError, showSuccess } from '../../actions/snackbar';
import { PaymentMethods, InvoicesTable, BillingPlansTable } from '../../containers/billing';
import { useTranslation } from 'react-i18next';
import { AccountBalance, Description } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },
        headerIcon: {
            marginBottom: -3
        },
    }
});

function BillingSettings(props) {
    const { lcuid } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Box p={2}>
                <Typography variant="h5"> <CreditCardIcon className={classes.headerIcon} />{t(' Payment Methods')}</Typography>
                <Box mt={2} />
                <PaymentMethods
                    lcuid={lcuid}
                />
            </Box>
            <Divider />
            <Box p={2}>
                <Typography variant="h5" ><AccountBalance className={classes.headerIcon} />{t(' Plans')}</Typography>
                <Box mt={2} />
                <BillingPlansTable lcuid={lcuid} />
            </Box >
            <Divider />
            <Box p={2}>
                <Typography variant="h5" ><Description className={classes.headerIcon} />{t(' Invoices')}</Typography>
                <Box mt={2} />
                <InvoicesTable lcuid={lcuid} />
            </Box >
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showSuccess: (msg) => dispatch(showSuccess(msg)),
        showError: (msg) => dispatch(showError(msg))
    };
}

BillingSettings.propTypes = {
    lcuid: PropTypes.string.isRequired,
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(BillingSettings)
    );
