import { TextField } from '@material-ui/core';
import React from 'react';

const LucitFormFieldDateTime = ({ field, value, onChange }) => {

    const isError = () => {
        return false
    }

    return <TextField
        id={field.key}
        name={field.key}
        label={field.name}
        fullWidth
        value={value ?? ''}
        error={isError()}
        required
        type={'datetime-local'}
        helperText={field.description}
        onChange={(e) => {
            onChange(e.target.value)
        }}
        InputLabelProps={{
            shrink: true,
        }}
    />
}

export default LucitFormFieldDateTime;
