import { Box, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import { selectedAgency } from '../../selectors/user';
import AgencyAccounts from '../../containers/agency/AgencyAccounts';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Accounts() {
    const classes = useStyles();
    const { t } = useTranslation();

    const agency = useSelector(selectedAgency)

    return <Paper className={classes.root}>
        <PageTitle title={t('My Accounts')} />

        <Box mb={2} display="flex">
            <LCTypography
                transProps={{ name: agency.name }}
                variant="h5">{{ name: agency.name }} Accounts</LCTypography>
        </Box>

        <Box mt={2} />
        <AgencyAccounts agency={agency} />

        <ButtonScrollToTopFab />
    </Paper>
}

export { Accounts as AccountsWithoutLayout }
export default withHomeLayout(Accounts);
