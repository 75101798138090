import {
    Box, Button, CircularProgress, Divider, Grid, Popover, Switch, Tooltip, Typography, useTheme, TextField, Checkbox, FormControlLabel
} from '@material-ui/core';
import { FormatClear, FormatColorFill, PhotoSizeSelectLarge, Visibility, VisibilityOff } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import LCTypography from '../../components/material/LCTypography';
import { debounce } from '../../helpers/async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useDropzone } from 'react-dropzone';
import { lucitApi } from '../../services/lucitApi';
import { addFont } from '../../actions/designer';
import { Designer } from '../../helpers/constants'
import { default as BestColorPicker } from 'react-best-gradient-color-picker'
import { hasClass, htmlStringToElement } from './DesignerHTML'
import { parse } from 'css';
import EnhancedSlider from '../../components/material/EnhancedSlider';
import { Autocomplete } from '../../components/inputs/AutocompleteField';
import { DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';
import { TextDecrease, TextIncrease } from '../../components';
import { capitalize } from '../../helpers/string';
import { getDefaultCssForLayer } from '../../selectors/designerLayers';

// adding tests

export const idExistsInCSSString = (id, css) => css.includes("#" + id);

export const ofssetTopLeftPositionInCss = (css, offsetPercentage = 2) => {
    const cssRegex = /(top|left):\s*([\d.]+)%/g;

    const offsetCssString = css.replace(cssRegex, (_, property, percentage) => {
        const originalValue = parseFloat(percentage);
        const newValue = originalValue + offsetPercentage;
        return `${property}: ${newValue}%`;
    });

    return offsetCssString;
}

export const removePositioningFromCss = (css) => {
    const cleanedCSS = css.replace(/([a-z-]+)\s*:\s*([^;]+);/gi, (match, p1) => {
        const propertyName = p1.trim().toLowerCase();
        if (propertyName === 'height' || propertyName === 'width' || propertyName === 'top' || propertyName === 'left') {
            return '';
        }
        return match;
    });

    return cleanedCSS
}

export const getPositioningFromCss = (css) => {
    let extractedProperties = '';

    // Extract values of height, width, top, and left properties
    css.replace(/([a-z-]+)\s*:\s*([^;]+);/gi, (match, p1, p2) => {
        const propertyName = p1.trim().toLowerCase();
        if (propertyName === 'height' || propertyName === 'width' || propertyName === 'top' || propertyName === 'left') {
            extractedProperties += `${propertyName}: ${p2.trim()}; `;
        }
        return match;
    });

    return extractedProperties.trim();
}

export const getColorsFromCSS = (cssString) => {

    if (!cssString)
        return [];

    let ast = null

    try {
        ast = parse(cssString);
    }
    catch (e) {
        return [];
    }

    const colors = [];

    const traverseRules = (node) => {
        if (node.type === 'rule') {
            for (const declaration of node.declarations) {
                if (declaration.type === 'declaration') {
                    if (declaration.property === 'color' || declaration.property === 'background-color') {
                        colors.push(...extractColors(declaration.value));
                    }
                    if (declaration.property === 'box-shadow' || declaration.property === 'text-shadow') {
                        colors.push(...extractShadowColors(declaration.value));
                    }
                    if (declaration.property === 'background') {
                        colors.push(...extractBackgroundColors(declaration.value));
                    }
                }
            }
        }

        if (node.rules) {
            for (const nestedRule of node.rules) {
                traverseRules(nestedRule);
            }
        }
    }

    const extractColors = (value) => {
        const colorRegex = /#[a-fA-F0-9]{3,6}|(rgba?|hsla?)\([^)]+\)/g;
        return value.match(colorRegex) || [];
    }

    const extractShadowColors = (value) => {
        const shadowRegex = /(\d+(?:\.\d+)?px\s*){2,4}(#[a-fA-F0-9]{3,6}|(rgba?|hsla?)\([^)]+\))/g;
        const matches = value.match(shadowRegex) || [];
        return matches.map((match) => match.match(/(#[a-fA-F0-9]{3,6}|(rgba?|hsla?)\([^)]+\))/)[1]);
    }

    const extractBackgroundColors = (value) => {
        const backgroundRegex = /#[a-fA-F0-9]{3,6}|(rgba?|hsla?)\([^)]+\)/g;
        const matches = value.match(backgroundRegex) || [];
        return matches;
    }

    traverseRules(ast.stylesheet);

    // Filter for unique colors
    const uniqueColors = [...new Set(colors)];

    return uniqueColors;
}

export const getBestColorIconColor = (color) => {
    const bestIconColor =
        isColorLighterThanFafafa(color)
            ? "#efefef"
            : color;

    return bestIconColor;
}

export const isColorLighterThanFafafa = (color) => {
    // Convert color values to RGB
    function convertToRgb(color) {
        const tempElem = document.createElement('div');
        tempElem.style.color = color;
        document.body.appendChild(tempElem);
        const rgbColor = getComputedStyle(tempElem).color;
        document.body.removeChild(tempElem);
        return rgbColor;
    }

    // Calculate brightness of RGB color
    function calculateBrightness(rgb) {
        // Use formula: (R * 299 + G * 587 + B * 114) / 1000
        const values = rgb.match(/\d+/g).map(Number);
        return (values[0] * 299 + values[1] * 587 + values[2] * 114) / 1000;
    }

    const referenceColor = convertToRgb('#fafafa');
    const targetColor = convertToRgb(color);

    const referenceBrightness = calculateBrightness(referenceColor);
    const targetBrightness = calculateBrightness(targetColor);

    return targetBrightness > referenceBrightness;
}

export const getRatioForId = (id, css) => {
    if (!idExistsInCSSString(id, css))
        return null;

    const cssForId = getCSSStyleForId(id, css)

    if (!cssForId)
        return null

    const width = getStyleValueForCSSKey(cssForId, "width")
    const height = getStyleValueForCSSKey(cssForId, "height")

    if (!width || !height)
        return null

    return width.replace("%", "") / height.replace("%", "")
}

export const getCSSStyleForId = (id, css) => {
    if (!idExistsInCSSString(id, css))
        return "";

    const idAt = css.indexOf("#" + id)
    const openBraceAt = css.indexOf("{", idAt)

    if (!openBraceAt)
        return ""

    const closeBraceAt = css.indexOf("}", openBraceAt)

    if (!closeBraceAt)
        return ""

    return css.substring(openBraceAt + 1, closeBraceAt).trim()
}

export const replaceCSSStyleForId = (id, newStyle, css) => {
    if (!idExistsInCSSString(id, css)) {
        return css + "\n\n" + "#" + id + " {\n" + newStyle + "\n}\n"
    }

    const idAt = css.indexOf("#" + id)
    const openBraceAt = css.indexOf("{", idAt)
    const closeBraceAt = css.indexOf("}", openBraceAt)

    if (!closeBraceAt)
        return css + "\n\n" + "#" + id + " " + newStyle

    return css.substring(0, openBraceAt + 1) + "\n" + newStyle + css.substring(closeBraceAt).trim()
}

export const deleteCSSId = (id, css) => {
    if (!idExistsInCSSString(id, css)) {
        return css;
    }

    const idAt = css.indexOf("#" + id)
    const openBraceAt = css.indexOf("{", idAt)
    const closeBraceAt = css.indexOf("}", openBraceAt)

    if (!closeBraceAt)
        return css;

    return css.substring(0, idAt) + "\n" + css.substring(closeBraceAt + 1).trim()
}

export const getNewStyleStringForString = (css, styles) => {
    const currentStyleRows = css.trim().split(";").filter((r) => r)
    const currentStyleObjects = currentStyleRows.map((s) => s.trim().split(":"))

    const newStyleObjects = currentStyleObjects.map((s) => {
        const styleName = s[0]
        const matchStyleObject = styles.filter((ns) => ns[0] === styleName)
        const styleValue = matchStyleObject.length ? matchStyleObject[0][1] : s[1]

        return [styleName, styleValue]
    })

    styles.forEach((s) => {
        const styleName = s[0]
        const matchStyleObject = newStyleObjects.filter((ns) => ns[0] === styleName)

        // Add new styles
        if (!matchStyleObject.length) {
            newStyleObjects.push(s)
        }
    });

    // Filtler out styles without value
    const newStyleString = newStyleObjects.filter(o => o[1] != '')
        .map((s) => s.join(":")).join(";\n");

    return newStyleString + ";\n"
}

export const getStyleValueForCSSKey = (css, cssKey, defaultStyle = "") => {
    const currentStyleRows = css.trim().split(";").filter((r) => r)
    const currentStyleObjects = currentStyleRows.map((s) => s.trim().split(":"))
    const matchStyleObject = currentStyleObjects.filter((s) => s[0] === cssKey)

    return (matchStyleObject && matchStyleObject.length) ? matchStyleObject[0][1] : defaultStyle
}

export const IconButtonExclusive = ({ iconClasses, css, cssKey, onChangeCss, group = true }) => {
    const value = getStyleValueForCSSKey(css, cssKey);

    const dispatch = useDispatch();
    const setValue = (e) => {

        const clickedCurrentSelectedButton = value === null;

        const useValue = clickedCurrentSelectedButton ? "" : e;

        onChangeCss(getNewStyleStringForString(css, [
            [cssKey, useValue]
        ]));

        if (value != useValue) {
            dispatch({ type: DESIGNER_PUSH_HISTORY });
        }
    }

    const { t } = useTranslation();

    const Component = group
        ? ToggleButtonGroup
        : Fragment;

    return (
        <Component
            value={value}
            size="small"
            exclusive
            onChange={(_, newValue) => setValue(newValue)}
        >
            {
                iconClasses.map((c, index) => {
                    return <Tooltip key={index} title={t(c.label)}>
                        <ToggleButton size="small" key={c.class}
                            className={"MuiToggleButtonGroup-grouped MuiToggleButtonGroup-groupedHorizontal"}
                            selected={value === c.class}
                            value={c.class}
                            aria-label={t(c.label)}>
                            {c.icon}
                        </ToggleButton>
                    </Tooltip>
                })
            }
        </Component >
    );
}

export const IconOnOff = ({ Icon, IconOff = null, css, cssKey, cssValueOn, cssValueOff,
    onChangeCss, disabled, title, className }) => {

    const dispatch = useDispatch();
    const isOn = getStyleValueForCSSKey(css, cssKey) == cssValueOn;
    const setOn = value => {
        onChangeCss(getNewStyleStringForString(css, [
            [cssKey, value ? cssValueOn : cssValueOff]
        ]));
        dispatch({ type: DESIGNER_PUSH_HISTORY });
    }

    return <Tooltip title={title}>
        <ToggleButton
            size="small"
            disabled={disabled}
            selected={isOn}
            value={cssKey}
            onChange={() => setOn(!isOn)}
            className={className}
        >
            {isOn ? Icon : IconOff || Icon}
        </ToggleButton>
    </Tooltip >
}

export const VisibilityOnOff = ({ css, onChangeCss, disabled, title, className }) => {

    const cssKey = "visibility";

    const dispatch = useDispatch();

    const currentValue = getStyleValueForCSSKey(css, cssKey)
    const isOn = currentValue == "visible" || !currentValue;

    const setOn = value => {
        onChangeCss(getNewStyleStringForString(css, [
            [cssKey, value ? "visible" : "hidden"]
        ]));
        dispatch({ type: DESIGNER_PUSH_HISTORY });
    }

    return <Tooltip title={title}>
        <ToggleButton
            size="small"
            disabled={disabled}
            selected={!isOn}
            value={cssKey}
            onChange={() => setOn(!isOn)}
            className={className}
        >
            {isOn ? <Visibility /> : <VisibilityOff />}
        </ToggleButton>
    </Tooltip >
}

export const ColorPicker = ({ Icon, css, cssKey, onChangeCss, title, className, allowGradient, colors }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [initialColor, setInitialColor] = React.useState(null);

    const dispatch = useDispatch();
    const color = getStyleValueForCSSKey(css, cssKey);
    const setColor = value => onChangeCss(getNewStyleStringForString(css, [
        [cssKey, value]
    ]))

    const numberOfColorsToShow = 18

    const colorPresets = colors ? [
        ...colors,
        ...Designer.ColorPresets
    ] : Designer.ColorPresets;

    return <>
        <Tooltip title={title}>
            <ToggleButton
                size="small"
                value="showPicker"
                selected={Boolean(anchorEl)}
                onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                    setInitialColor(color);
                }}
                style={{ color: getBestColorIconColor(color) }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                // Autosave color upon closign the modal (and if color was changed)
                if (initialColor != color) {
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <BestColorPicker
                value={color}
                height={150}
                presets={colorPresets.slice(0, numberOfColorsToShow)}
                hideColorTypeBtns={allowGradient ? false : true}
                onChange={(color) => {
                    setColor(color);
                }}
            />
            <Button
                style={{ marginTop: "10px", float: "right" }}
                onClick={() => setColor("")}>
                Clear
            </Button>
        </Popover>
    </>
}

export const BorderEditor = ({ Icon, css, onChangeCss, className }) => {
    const [showPicker, setShowPicker] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const borderEnabled = getStyleValueForCSSKey(css, "border-style") == "solid";
    const borderColor = getStyleValueForCSSKey(css, "border-color");
    const borderWidth = parseInt(getStyleValueForCSSKey(css, "border-width", "0").replace("px", ""));
    const borderRadius = parseInt(getStyleValueForCSSKey(css, "border-radius", "0").replace("px", ""));

    const [initialBorder, setInitialBorder] = useState(null);
    const borderValue = { borderEnabled, borderColor, borderWidth, borderRadius };

    const dispatch = useDispatch();
    const setBorder = (borderEnabled, borderColor, borderWidth, borderRadius) => {

        const borderShouldBeEnabled = borderWidth > 0 || borderRadius > 0;

        onChangeCss(getNewStyleStringForString(css,
            [
                ["border-style", borderShouldBeEnabled ? "solid" : "none"],
                ["border-color", borderColor],
                ["border-width", borderWidth + "px"],
                ["border-radius", borderRadius + "px"],
            ]))
    }

    return <>
        <Tooltip title={t('Edit Border style, color, width and radius')}>
            <ToggleButton
                size="small"
                value="showBorderEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setInitialBorder(JSON.stringify(borderValue))
                    setAnchorEl(e.currentTarget)
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                if (initialBorder != JSON.stringify(borderValue))
                    dispatch({ type: DESIGNER_PUSH_HISTORY });
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: { width: '400px' },
            }}
        >
            <Box p={4}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <LCTypography variant="body2" gutterBottom>Width</LCTypography>
                    </Grid>
                    <Grid item xs={10}>

                        <EnhancedSlider
                            size="small"
                            defaultValue={borderWidth}
                            valueLabelDisplay="Width"
                            step={1}
                            min={0}
                            max={20}
                            value={borderWidth}
                            onChange={(_, newValue) => setBorder(borderEnabled, borderColor, newValue, borderRadius)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <LCTypography variant="body2" gutterBottom>Corners</LCTypography>
                    </Grid>
                    <Grid item xs={10}>
                        <EnhancedSlider
                            size="small"
                            defaultValue={borderRadius}
                            valueLabelDisplay="Width"
                            step={1}
                            min={0}
                            max={50}
                            value={borderRadius}
                            onChange={(_, newValue) => setBorder(borderEnabled, borderColor, borderWidth, newValue)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <LCTypography variant="body2" gutterBottom>Color</LCTypography>
                    </Grid>
                    <Grid item xs={10}>

                        <ToggleButton
                            size="small"
                            value="showPicker"
                            selected={showPicker}
                            style={{ color: getBestColorIconColor(borderColor) }}
                            onChange={() => setShowPicker(!showPicker)}
                        >
                            <FormatColorFill />
                        </ToggleButton>
                        {showPicker
                            && <BestColorPicker
                                value={borderColor}
                                height={150}
                                onChange={(color) => setBorder(borderEnabled, color, borderWidth, borderRadius)}
                            />}
                    </Grid>

                </Grid>
            </Box>
        </Popover>
    </>
}

export const SVGStrokeEditor = ({ Icon, css, onChangeCss, className }) => {
    const [showPicker, setShowPicker] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const borderEnabled = getStyleValueForCSSKey(css, "stroke") !== "";
    const borderColor = getStyleValueForCSSKey(css, "stroke", "#ffffff");
    const borderWidth = parseInt(getStyleValueForCSSKey(css, "stroke-width", "0"));

    const [initialBorder, setInitialBorder] = useState(null);
    const borderValue = { borderEnabled, borderColor, borderWidth };

    const dispatch = useDispatch();
    const setBorder = (borderEnabled, borderColor, borderWidth) =>
        onChangeCss(getNewStyleStringForString(css,
            [
                ["stroke", borderEnabled ? borderColor : ""],
                ["stroke-width", borderEnabled ? borderWidth : ""],
            ]))

    return <>
        <Tooltip title={t('Edit Shape Border style, color, width and radius')}>
            <ToggleButton
                size="small"
                value="showBorderEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setInitialBorder(JSON.stringify(borderValue))
                    setAnchorEl(e.currentTarget)
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                if (initialBorder != JSON.stringify(borderValue))
                    dispatch({ type: DESIGNER_PUSH_HISTORY });
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: { width: '400px' },
            }}
        >
            <Box p={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <Tooltip title={t('Enable Border')}>
                            <Switch
                                checked={borderEnabled}
                                onChange={() => setBorder(!borderEnabled, borderColor, borderWidth)}
                            >
                            </Switch></Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Width</LCTypography>
                        <EnhancedSlider
                            disabled={!borderEnabled}
                            size="small"
                            defaultValue={borderWidth}
                            valueLabelDisplay="Width"
                            step={1}
                            min={0}
                            max={20}
                            value={borderWidth}
                            onChange={(_, newValue) => setBorder(borderEnabled, borderColor, newValue)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Color</LCTypography>
                        <ToggleButton
                            size="small"
                            disabled={!borderEnabled}
                            value="showPicker"
                            selected={showPicker}
                            style={{ color: getBestColorIconColor(borderColor) }}
                            onChange={() => setShowPicker(!showPicker)}
                        >
                            <FormatColorFill />
                        </ToggleButton>
                        {showPicker
                            && <BestColorPicker
                                value={borderColor}
                                height={150}
                                onChange={(color) => setBorder(borderEnabled, color, borderWidth)}
                            />}
                    </Grid>

                </Grid>
            </Box>
        </Popover>
    </>
}

export const FontStrokeEditor = ({ Icon, css, onChangeCss, className }) => {
    const [showPicker, setShowPicker] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const textStrokeString = getStyleValueForCSSKey(css, "-webkit-text-stroke");

    const borderEnabled = textStrokeString && textStrokeString != "none";

    const textStrokeStringSplitMatch = textStrokeString.match(/^(\S+)\s(.*)/)
    const textStrokeStringSplit = textStrokeStringSplitMatch ? textStrokeStringSplitMatch.slice(1) : []
    const borderColor = textStrokeStringSplit[1] ?? Designer.DefaultBorderColor;
    const borderWidthString = textStrokeStringSplit[0] ?? "0px";
    const borderWidth = parseInt((borderWidthString).replace("px", ""));

    const [initialStroke, setInitialStroke] = useState(null);

    const dispatch = useDispatch();
    const setBorder = (borderEnabled, borderColor, borderWidth) =>
        onChangeCss(getNewStyleStringForString(css,
            [
                ["-webkit-text-stroke", borderEnabled ? (borderWidth + "px " + borderColor) : "none"],
            ]))

    return <>
        <Tooltip title={t('Edit Font stroke width and radius')}>
            <ToggleButton
                size="small"
                value="showBorderEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setAnchorEl(e.currentTarget)
                    setInitialStroke(textStrokeString);
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                if (initialStroke != textStrokeString) {
                    dispatch({ type: DESIGNER_PUSH_HISTORY });
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={4}>
                <Grid container>
                    <Grid item xs={12}>
                        <Tooltip title={t('Enable Border')}>
                            <Switch
                                checked={borderEnabled}
                                onChange={() => setBorder(!borderEnabled, borderColor, borderWidth)}
                            >
                            </Switch></Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Width</LCTypography>
                        <EnhancedSlider
                            disabled={!borderEnabled}
                            size="small"
                            defaultValue={borderWidth}
                            valueLabelDisplay="Width"
                            step={1}
                            min={0}
                            max={20}
                            value={borderWidth}
                            onChange={(_, newValue) => setBorder(borderEnabled, borderColor, newValue)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Color</LCTypography>
                        <ToggleButton
                            size="small"
                            disabled={!borderEnabled}
                            value="showPicker"
                            selected={showPicker}
                            style={{ color: getBestColorIconColor(borderColor) }}
                            onChange={() => setShowPicker(!showPicker)}
                        >
                            <FormatColorFill />
                        </ToggleButton>
                        {showPicker
                            && <BestColorPicker
                                value={borderColor}
                                height={150}
                                onChange={(color) => setBorder(borderEnabled, color, borderWidth)}
                            />}
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    </>
}

// TODO: we can re-use it or make it generic for any CSS value that have a sequence values
// [ { key: 'rotate', value: ['10deg']}, { key: 'translate', value: ['10px', '10px']},  ]
const parseFilterCss = value => {
    const regex = /(([a-z].*?)\(.*?\))(?=\s([a-z].*?)\(.*?\)|\s*$)/g;
    const values = value.match(regex) || [];

    return values.reduce((prev, x) => {
        const idx = x.indexOf('(');
        const key = x.substring(0, idx);
        const value = x.substr(idx + 1, x.length - (idx + 1) - 1)

        return { ...prev, [key]: value };
    }, {})
}

const parseShadowCss = (shadowCss) => {
    const regex = /(-?\d+(?:\.\d+)?(?:px)?|\brgba\([^)]+\)|\brgb\([^)]+\)|\bhsla\([^)]+\)|\bhsl\([^)]+\)|#\w+)/g;

    const values = [];

    let match;
    while ((match = regex.exec(shadowCss)) !== null) {
        values.push(match[1]);
    }

    return values;
}

const stringifyFilterCss = filter => {
    return Object.keys(filter).map(x => `${x}(${filter[x]})`).join(' ')
}

export const ShadowEditor = ({ Icon, css, cssKey, onChangeCss, className }) => {
    const isDropShadow = cssKey === "filter"
    const isBoxShadow = cssKey === "box-shadow"
    const isTextShadow = cssKey === "text-shadow"

    const filter = isDropShadow && parseFilterCss(getStyleValueForCSSKey(css, cssKey));

    const valFromCss = isDropShadow
        ? filter["drop-shadow"]
        : getStyleValueForCSSKey(css, cssKey)

    const valExploded = parseShadowCss(valFromCss)

    const shadowEnabled = valExploded[0] && valExploded[0] !== "none";
    const shadowColor = isBoxShadow
        ? (valExploded[4] ? valExploded[4] : Designer.DefaultShadowColor)
        : (valExploded[3] ? valExploded[3] : Designer.DefaultShadowColor)

    const hOffset = valExploded[0] ? valExploded[0].replace("px", "") * 1 : 0;
    const vOffset = valExploded[1] ? valExploded[1].replace("px", "") * 1 : 0
    const blur = valExploded[2] ? valExploded[2].replace("px", "") * 1 : 0;
    const spread = valExploded[3] ? valExploded[3].replace("px", "") * 1 : 0;

    const setShadow = useCallback(debounce((enabled, hOffset, vOffset, blur, spread, shadowColor) => {
        let value = "none";

        if (isDropShadow) {
            value = stringifyFilterCss({ ...filter, "drop-shadow": `${hOffset}px ${vOffset}px ${blur}px ${shadowColor}` });
        } else if (isBoxShadow) {
            value = `${hOffset}px ${vOffset}px ${blur}px ${spread}px ${shadowColor}`
        } else if (isTextShadow) {
            value = `${hOffset}px ${vOffset}px ${blur}px ${shadowColor}`
        }

        if (!enabled) {
            value = "none";
        }

        onChangeCss(
            getNewStyleStringForString(css, [
                [cssKey, value],
            ]));
    }, 50), [isDropShadow, isBoxShadow, isTextShadow, css, onChangeCss, getNewStyleStringForString])

    const ShadowPresets = isTextShadow ? Designer.PresetsTextShadow : (
        isDropShadow ? Designer.PresetsDropShadow : Designer.PresetsBoxShadow
    )

    const [hOffsetInputValue, setHOffsetInputValue] = React.useState(hOffset);
    const [vOffsetInputValue, setVOffsetInputValue] = React.useState(vOffset);
    const [blurInputValue, setBlurInputValue] = React.useState(blur);
    const [spreadInputValue, setSpreadInputValue] = React.useState(spread);
    const [colorInputValue, setColorInputValue] = React.useState(shadowColor);

    const [initialValue, setInitialValue] = useState();
    const dispatch = useDispatch();

    const dropShadowStringFromPreset = (dropShadow) => {
        return `drop-shadow(${dropShadow.horizontalOffset}px ${dropShadow.verticalOffset}px ${dropShadow.blur}px ${dropShadow.color})`
    }

    const setDropShadowFromPreset = (dropShadow) => {
        setHOffsetInputValue(dropShadow.horizontalOffset);
        setVOffsetInputValue(dropShadow.verticalOffset);
        setBlurInputValue(dropShadow.blur);
        setColorInputValue(dropShadow.color);
        setShadow(!dropShadow.isNone, dropShadow.horizontalOffset, dropShadow.verticalOffset, dropShadow.blur, spread, dropShadow.color);
    }

    const dropShadowMatchesPreset = (dropShadow) => {
        return dropShadow.horizontalOffset === hOffsetInputValue
            && dropShadow.verticalOffset === vOffsetInputValue
            && dropShadow.blur === blurInputValue
    }

    useEffect(() => {
        setHOffsetInputValue(hOffset);
        setVOffsetInputValue(vOffset);
        setBlurInputValue(blur);
        setSpreadInputValue(spread);
        setColorInputValue(shadowColor);
    }, [hOffset, vOffset, blur, spread, shadowColor])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showPicker, setShowPicker] = React.useState(false);

    return <>
        <Tooltip title={isDropShadow ? "Drop Shadow" : (isTextShadow ? "Text Shadow" : "Box Shadow Editor")}>
            <ToggleButton
                size="small"
                value="showShadowEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setAnchorEl(e.currentTarget)
                    setInitialValue(valFromCss);
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                if (valFromCss !== initialValue) {
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: { width: '400px' },
            }}
        >
            <Box p={4}>
                <Grid container>

                    <Grid item xs={12}>

                        <Grid container spacing={4}
                            alignItems="center"
                            justifyContent="center">

                            {ShadowPresets.map((preset, index) => {

                                const css = {
                                    filter: dropShadowStringFromPreset(preset),
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "15%",
                                    margin: "auto",
                                    cursor: "pointer",
                                    backgroundColor: Designer.DefaultObjectColor,
                                    border: dropShadowMatchesPreset(preset) ? "1px solid #efefef" : "1px solid transparent",
                                }

                                return <Grid item xs={4} key={index}>
                                    <Box
                                        style={css}
                                        title={preset.name}
                                        onClick={() => {
                                            setDropShadowFromPreset(preset)
                                        }}
                                    >

                                    </Box>

                                </Grid>

                            })}

                        </Grid>

                    </Grid>

                    <Divider style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} />

                    <Grid item xs={3}>
                        <LCTypography gutterBottom>Color</LCTypography>
                    </Grid>
                    <Grid item xs={9}>

                        <ToggleButton
                            size="small"
                            disabled={!shadowEnabled}
                            value="showPicker"
                            selected={showPicker}
                            style={{ color: getBestColorIconColor(colorInputValue) }}
                            onChange={() => setShowPicker(!showPicker)}
                        >
                            <FormatColorFill />
                        </ToggleButton>
                        {showPicker
                            && <BestColorPicker
                                value={colorInputValue}
                                height={150}
                                width={200}
                                presets={Designer.ShadowColorPresets}
                                onChange={(color) => {
                                    setColorInputValue(color);
                                    setShadow(shadowEnabled, hOffset, vOffset, blur, spread, color);
                                }}
                            />}
                    </Grid>

                    <Grid item xs={3}>
                        <LCTypography gutterBottom>Horizontal Offset</LCTypography>
                    </Grid>
                    <Grid item xs={9}>

                        <EnhancedSlider
                            disabled={!shadowEnabled}
                            size="small"
                            defaultValue={!hOffsetInputValue ? 0 : hOffsetInputValue}
                            valueLabelDisplay="Horizontal Offset"
                            step={1}
                            min={-50}
                            max={50}
                            value={!hOffsetInputValue ? 0 : hOffsetInputValue}
                            onChange={(_, hOffsetNew) => {
                                setHOffsetInputValue(hOffsetNew);
                                setShadow(shadowEnabled, hOffsetNew, vOffset, blur, spread, shadowColor);
                            }}
                        />

                    </Grid>

                    <Grid item xs={3}>
                        <LCTypography gutterBottom>Vertical Offset</LCTypography>
                    </Grid>
                    <Grid item xs={9}>
                        <EnhancedSlider
                            disabled={!shadowEnabled}
                            size="small"
                            defaultValue={!vOffsetInputValue ? 0 : vOffsetInputValue}
                            valueLabelDisplay="Vertical Offset"
                            step={1}
                            min={-50}
                            max={50}
                            value={!vOffsetInputValue ? 0 : vOffsetInputValue}
                            onChange={(_, vOffsetNew) => {
                                setVOffsetInputValue(vOffsetNew)
                                setShadow(shadowEnabled, hOffset, vOffsetNew, blur, spread, shadowColor);
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <LCTypography gutterBottom>Blur</LCTypography>
                    </Grid>
                    <Grid item xs={9}>
                        <EnhancedSlider
                            disabled={!shadowEnabled}
                            size="small"
                            defaultValue={!blurInputValue ? 0 : blurInputValue}
                            valueLabelDisplay="Blur"
                            step={1}
                            min={0}
                            max={50}
                            value={!blurInputValue ? 0 : blurInputValue}
                            onChange={(_, blurNew) => {
                                setBlurInputValue(blurNew);
                                setShadow(shadowEnabled, hOffset, vOffset, blurNew, spread, shadowColor);
                            }}
                        />
                    </Grid>

                    {isBoxShadow
                        && <> <Grid item xs={3}>
                            <LCTypography gutterBottom>Spread</LCTypography>
                        </Grid>
                            <Grid item xs={9}>
                                <EnhancedSlider
                                    disabled={!shadowEnabled}
                                    size="small"
                                    defaultValue={!spreadInputValue ? 0 : spreadInputValue}
                                    valueLabelDisplay="Spread"
                                    step={1}
                                    min={-50}
                                    max={50}
                                    value={!spreadInputValue ? 0 : spreadInputValue}
                                    onChange={(_, spreadNew) => {
                                        setSpreadInputValue(spreadNew);
                                        setShadow(shadowEnabled, hOffset, vOffset, blur, spreadNew, shadowColor);
                                    }}
                                />
                            </Grid></>}

                </Grid>
            </Box>
        </Popover>
    </>
}

export const BlurEditor = ({ Icon, css, onChangeCss, className }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const [initialBlur, setInitialBlur] = useState(null);
    const dispatch = useDispatch();

    const filter = parseFilterCss(getStyleValueForCSSKey(css, "filter"));

    const blur = parseFloat(filter.blur) || 0;
    const setBlur = value => onChangeCss(getNewStyleStringForString(css, [
        ["filter", stringifyFilterCss({ ...filter, blur: `${value}px` })]
    ]));

    return <>
        <Tooltip title={t('Blur')}>
            <ToggleButton
                size="small"
                value="blurEditor"
                selected={Boolean(anchorEl)}
                onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                    setInitialBlur(blur);
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
                if (blur != initialBlur)
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={4} minWidth={300}>
                <Grid container>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Blur</LCTypography>
                        <EnhancedSlider
                            size="small"
                            defaultValue={!blur ? 0 : blur}
                            valueLabelDisplay="Width"
                            step={0.2}
                            min={0}
                            max={10}
                            value={!blur ? 0 : blur}
                            onChange={(_, newValue) => setBlur(newValue)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    </>
}

export const OpacityEditor = ({ Icon, css, onChangeCss, className }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const [initialOpacity, setInitialOpacity] = useState(null);
    const dispatch = useDispatch();
    const opacity = parseFloat(getStyleValueForCSSKey(css, "opacity", 1));
    const setOpacity = value => onChangeCss(getNewStyleStringForString(css, [
        ["opacity", value]
    ]));

    return <>
        <Tooltip title={t('Opacity / Transparency')}>
            <ToggleButton
                size="small"
                value="showEditor"
                selected={Boolean(anchorEl)}
                onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                    setInitialOpacity(opacity);
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
                if (opacity != initialOpacity)
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={4} minWidth={300}>
                <Grid container>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Opacity</LCTypography>
                        <EnhancedSlider
                            size="small"
                            defaultValue={!opacity ? 0 : opacity}
                            valueLabelDisplay="Width"
                            step={0.1}
                            min={0}
                            max={1}
                            value={!opacity ? 0 : opacity}
                            onChange={(_, newValue) => setOpacity(newValue)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    </>
}

export const RotationEditor = ({ Icon, css, onChangeCss, className }) => {
    const rotation = parseInt(getStyleValueForCSSKey(css, "rotate", "0deg").replace("deg", ""));
    const setRotation = value => onChangeCss(getNewStyleStringForString(css, [
        ["rotate", value + "deg"]
    ]))
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [initialRotation, setInitialRotation] = useState(null);
    const dispatch = useDispatch();

    return <>
        <Tooltip title={t('Rotation')}>
            <ToggleButton
                size="small"
                value="showEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setAnchorEl(e.currentTarget)
                    setInitialRotation(rotation);
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);

                if (rotation != initialRotation)
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={4} style={{ minWidth: "300px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Rotation</LCTypography>
                        <EnhancedSlider
                            size="small"
                            defaultValue={!rotation ? 0 : rotation}
                            valueLabelDisplay="Width"
                            step={1}
                            min={-180}
                            max={180}
                            value={!rotation ? 0 : rotation}
                            onChange={(_, newValue) => setRotation(newValue)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    </>
}

export const LineAndLetterSpacing = ({ Icon, css, onChangeCss, className }) => {
    const defaultLineHeight = 1.2
    const lineHeightString = getStyleValueForCSSKey(css, "line-height")
    const useDefaultLineHeight = lineHeightString === "" || lineHeightString === "normal"
    const lineHeight = useDefaultLineHeight ? defaultLineHeight : parseFloat(lineHeightString)
    const setLineHeight = value => onChangeCss(getNewStyleStringForString(css, [
        ["line-height", value]
    ]))

    const defaultLetterSpacing = 0
    const letterSpacingString = getStyleValueForCSSKey(css, "letter-spacing").replace("em")
    const useDefaultLetterSpacing = letterSpacingString === "" || letterSpacingString === "normal"
    const letterSpacing = useDefaultLetterSpacing ? defaultLetterSpacing : parseFloat(letterSpacingString)
    const setLetterSpacing = value => onChangeCss(getNewStyleStringForString(css, [
        ["letter-spacing", value + "em"]
    ]))

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [initialValue, setInitialValue] = useState(null);
    const value = { lineHeight, letterSpacing };

    return <>
        <Tooltip title={t('Line Height and Letter Spacing')}>
            <ToggleButton
                size="small"
                value="showEditor"
                selected={Boolean(anchorEl)}
                onChange={e => {
                    setAnchorEl(e.currentTarget)
                    setInitialValue(JSON.stringify(value));
                }}
                className={className}
            >
                {Icon}
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null);
                if (initialValue != JSON.stringify(value))
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={4} style={{ minWidth: "300px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Line Height</LCTypography>
                        <EnhancedSlider
                            size="small"
                            defaultValue={lineHeight}
                            valueLabelDisplay="Height"
                            step={0.25}
                            min={0.25}
                            max={5}
                            value={lineHeight}
                            onChange={(_, newValue) => setLineHeight(newValue)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LCTypography gutterBottom>Letter Spacing</LCTypography>
                        <EnhancedSlider
                            size="small"
                            defaultValue={letterSpacing}
                            valueLabelDisplay="Width"
                            step={0.1}
                            min={0}
                            max={5}
                            value={letterSpacing}
                            onChange={(_, newValue) => setLetterSpacing(newValue)}
                        />
                    </Grid>

                </Grid>
            </Box>
        </Popover>
    </>
}

export const ClearFormatting = ({ onChangeCss, className, layer, css }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state)

    return <Tooltip title={"Clear Formatting"}>
        <ToggleButton
            size="small"
            value="clearFormatting"
            className={className}
            onClick={() => {
                if (window.confirm("Are you sure you want to clear all formatting?")) {
                    const defaultCss = getDefaultCssForLayer(state, layer);
                    const currentPositioningCss = getPositioningFromCss(css);
                    const newCss = currentPositioningCss + defaultCss
                    onChangeCss(newCss)
                    dispatch({ type: DESIGNER_PUSH_HISTORY })
                }

            }}
        >
            <FormatClear />
        </ToggleButton>
    </Tooltip>
}

export const ImagePositionEditor = ({ css, onChangeCss, className }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const backgroundPosition = getStyleValueForCSSKey(css, "background-position", "center");
    const setBackgroundPosition = value => onChangeCss(getNewStyleStringForString(css, [
        ["background-position", value]
    ]));
    const backgroundSize = getStyleValueForCSSKey(css, "background-size", "auto");
    const setBackgroundSize = value => onChangeCss(getNewStyleStringForString(css, [
        ["background-size", value]
    ]));

    const positions = useMemo(() => ([
        { id: "top left", previewSrc: `https://i.ibb.co/jvyk4zt/h-OJhh-V9-WT8mm1-Uc-EAs-Jyw.png` },
        { id: "top center" },
        { id: "top right" },
        { id: "center left" },
        { id: "center" },
        { id: "center right" },
        { id: "bottom left" },
        { id: "bottom center" },
        { id: "bottom right" },
    ]), [])
    const sizes = useMemo(() => ([
        { id: "contain", label: "No cropping" },
        { id: "cover", label: "Zoom to fit" },
        { id: "100% 100%", label: "Stretch to fill" }
    ]), [])

    return <>

        <Tooltip title={t('Image Position')}>
            <ToggleButton
                size="small"
                value="showEditor"
                selected={Boolean(anchorEl)}
                onChange={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
                className={className}
            >
                <PhotoSizeSelectLarge />
            </ToggleButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={2} display="flex">
                <Box display="flex" flexDirection="column" >
                    <LCTypography gutterBottom>Choose Image Position:</LCTypography>
                    <Grid container spacing={1} style={{ width: 'calc(64px * 3 + 8px * 3)' }}>
                        {positions.map(x => (<Grid
                            item
                            sm={4}
                            key={x.id}
                        >
                            <Box
                                onClick={() => {
                                    setBackgroundPosition(x.id)
                                    dispatch({ type: DESIGNER_PUSH_HISTORY });
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    height: '64px',
                                    border: '1px solid grey',
                                    borderRadius: '8px',
                                    background: backgroundPosition == x.id ? theme.palette.primary.main : 'white',
                                    color: backgroundPosition == x.id ? theme.palette.primary.contrastText : theme.palette.text.primary

                                }}
                                dangerouslySetInnerHTML={{ __html: x.id.replace(' ', '<br/>') }}
                            >
                            </Box>
                        </Grid>))}
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="column" ml={2}>
                    <LCTypography gutterBottom>Choose Image Size:</LCTypography>
                    <Grid container spacing={1} style={{ width: 'calc(64px * 3 + 8px * 3)' }}>
                        {sizes.map(x => (<Grid
                            item
                            sm={4}
                            key={x.id}
                        >
                            <Box
                                onClick={() => {
                                    setBackgroundSize(x.id)
                                    dispatch({ type: DESIGNER_PUSH_HISTORY });
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    height: '64px',
                                    border: '1px solid grey',
                                    borderRadius: '8px',
                                    background: backgroundSize == x.id ? theme.palette.primary.main : 'white',
                                    color: backgroundSize == x.id ? theme.palette.primary.contrastText : theme.palette.text.primary
                                }}
                                dangerouslySetInnerHTML={{ __html: x.label.replace(' ', '<br/>') }}
                            >
                            </Box>
                        </Grid>))}
                    </Grid>
                </Box>
            </Box>
        </Popover >
    </>
}

export const CSSHelpers = ({ classesHelp }) => {
    const { t } = useTranslation();

    return (
        classesHelp &&
        <Box>
            <table>
                <thead>
                    <tr>
                        <th>{t('Class')}</th>
                        <th>{t('Description')}</th>
                    </tr>
                </thead>
                <tbody>
                    {classesHelp.map((classData, index) => {
                        return (
                            <tr key={index}>
                                <td><code>{classData.class}</code></td>
                                <td><code>{classData.description}</code></td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>

        </Box>
    )
}

export const CSSFontSizeEditor = ({ css, onChangeCss, html, onChangeHtml }) => {
    const { t } = useTranslation();

    const FONT_UNITS = "vmax";
    const FONT_SIZE_STEP = 0.2;
    const FONT_SIZE_MAX_FOR_STEP = 30;

    const className = "lc_format_fit_text"
    const fitToBox = hasClass(htmlStringToElement(html), className)

    const defaultFontSize = 1
    const fontSizeValue = getStyleValueForCSSKey(css, "font-size")
    const fontSizeString = fontSizeValue ? fontSizeValue.replace(FONT_UNITS) : defaultFontSize
    const useDefaultFontSize = fontSizeString === "" || !fontSizeString
    const [fontSize, setFontSize] = useState(useDefaultFontSize ? defaultFontSize : parseFloat(fontSizeString))

    const dispatch = useDispatch();
    const setCSSFontSize = value => {
        onChangeCss(getNewStyleStringForString(css, [
            ["font-size", value + FONT_UNITS]
        ]))
        dispatch({ type: DESIGNER_PUSH_HISTORY });
    }

    const toggleFitToBox = (checked) => {
        const el = htmlStringToElement(html);

        !checked ? el.classList.remove(className) : el.classList.add(className)

        onChangeHtml(el.outerHTML)
    }

    const maxChars = () => {
        const el = htmlStringToElement(html);
        return el.getAttribute('data-max-chars') ? el.getAttribute('data-max-chars') : 0
    }

    const setMaxChars = (value) => {
        const el = htmlStringToElement(html);
        el.setAttribute('data-max-chars', value)
        onChangeHtml(el.outerHTML)
    }

    const maxFontSize = () => {
        const el = htmlStringToElement(html);
        return el.getAttribute('data-max-font-size') ? el.getAttribute('data-max-font-size') : 0
    }

    const setMaxFontSize = (value) => {
        const el = htmlStringToElement(html);
        el.setAttribute('data-max-font-size', value)
        onChangeHtml(el.outerHTML)
    }

    const showEllipsis = () => {
        const el = htmlStringToElement(html);
        return el.getAttribute('data-max-chars-show-ellipsis') === 'true'
    }

    const setShowEllipsis = (value) => {
        const el = htmlStringToElement(html);
        if (value)
            el.setAttribute('data-max-chars-show-ellipsis', value)
        else
            el.removeAttribute('data-max-chars-show-ellipsis')

        onChangeHtml(el.outerHTML)
    }

    const allowWrapping = () => {
        const whiteSpaceStyle = getStyleValueForCSSKey(css, "white-space");

        return whiteSpaceStyle === "normal" || !whiteSpaceStyle;
    }

    const setAllowWrapping = (value) => {

        const whiteSpaceStyle = value ? "normal" : "nowrap";

        onChangeCss(getNewStyleStringForString(css, [
            ["white-space", whiteSpaceStyle]
        ]))

        dispatch({ type: DESIGNER_PUSH_HISTORY });
    }

    const sizeOptions = useMemo(() => ['auto', 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
        .map(x => isNaN(x) ? x : x), [])

    const maxSizeOptions = useMemo(() => ['none', 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
        .map(x => isNaN(x) ? x : x), [])

    return <>
        <Box display="flex">
            <Autocomplete
                freeSolo
                style={{ width: '160px' }}
                onBlur={(e) => {
                    const value = e.target.value;

                    if (isNaN(value))
                        return;

                    const valueAdjusted = parseFloat(value);

                    setFontSize(valueAdjusted)
                    setCSSFontSize(valueAdjusted.toFixed(2))
                }}
                onChange={(e, value) => {
                    if (value == 'auto') {
                        toggleFitToBox(true);
                        dispatch({ type: DESIGNER_PUSH_HISTORY });
                        return;
                    }

                    toggleFitToBox(false);

                    if (e.type === 'click') {
                        setFontSize(value)
                        setCSSFontSize(value.toFixed(2))
                    } else if (e.type === 'keydown') {
                        const valueAdjusted = parseFloat(value);

                        setFontSize(valueAdjusted)
                        setCSSFontSize(valueAdjusted.toFixed(2))
                    }
                }}
                multiple={false}
                disableClearable
                disableCloseOnSelect={false}
                value={fitToBox
                    ? "auto"
                    : fontSize}
                label={t("Size")}
                size="small"
                options={sizeOptions}
                textFieldProps={{
                    variant: "outlined",
                }}
                getOptionLabel={x => isNaN(x) ? capitalize(x) : `${x}`}
                getOptionSelected={x => fitToBox
                    ? x === 'auto'
                    : fontSize === x}
                renderOption={(x) => {
                    if (x === 'auto') {
                        return t("Auto");
                    }

                    return `${x}`;
                }}
            />
            {!fitToBox
                && <ToggleButtonGroup style={{ marginLeft: '8px' }}>
                    <ToggleButton
                        size="small"
                        disabled={fontSize <= 0.1}
                        onClick={() => {
                            setFontSize(Math.round(Math.max(0.1, fontSize - FONT_SIZE_STEP) * 100) / 100)
                            setCSSFontSize(Math.max(0.1, fontSize - FONT_SIZE_STEP).toFixed(2))
                        }}
                    >
                        <TextDecrease />
                    </ToggleButton>
                    <ToggleButton
                        size="small"
                        disabled={fontSize >= FONT_SIZE_MAX_FOR_STEP}
                        onClick={() => {
                            setFontSize(Math.round(Math.min(FONT_SIZE_MAX_FOR_STEP, fontSize + FONT_SIZE_STEP) * 100) / 100)
                            setCSSFontSize(Math.min(FONT_SIZE_MAX_FOR_STEP, fontSize + FONT_SIZE_STEP).toFixed(2))
                        }}
                    >
                        <TextIncrease />
                    </ToggleButton>
                </ToggleButtonGroup>}

            {fitToBox
                && <Autocomplete
                    freeSolo
                    style={{ width: '160px' }}
                    onBlur={(e) => {
                        const value = e.target.value;

                        if (isNaN(value))
                            return;

                        const valueAdjusted = parseFloat(value);

                        setMaxFontSize(valueAdjusted > 0 ? valueAdjusted.toFixed(2) : 0)
                    }}
                    onChange={(e, value) => {

                        if (e.type === 'click') {
                            setMaxFontSize(value > 0 ? value.toFixed(2) : 0)
                        } else if (e.type === 'keydown') {
                            const valueAdjusted = parseFloat(value);
                            setMaxFontSize(valueAdjusted > 0 ? valueAdjusted.toFixed(2) : 0)
                        }
                    }}
                    multiple={false}
                    disableClearable
                    disableCloseOnSelect={false}
                    value={maxFontSize() > 0 ? maxFontSize() : "none"}
                    label={t("Max Size")}
                    size="small"
                    options={maxSizeOptions}
                    textFieldProps={{
                        variant: "outlined",
                    }}
                    getOptionLabel={x => isNaN(x) ? capitalize(x) : `${x}`}
                    getOptionSelected={x => !maxFontSize() ? 'none' : maxFontSize() === x}
                    renderOption={(x) => {

                        if (x === 0) {
                            return t("No Max Size")
                        }

                        return `${x}`;
                    }}
                />

            }
        </Box>
        <Box display="flex" mt={2}>
            <Tooltip title={t('Limit the number of characters that can appear in the text box.')}>
                <TextField
                    label={t("Max Chars")}
                    size="small"
                    variant="outlined"
                    value={maxChars() > 0 ? maxChars() : ''}
                    inputProps={{ maxLength: 3 }}
                    style={{ maxWidth: 112 }}
                    onChange={(e) => {
                        const value = e.target.value;
                        setMaxChars(value)
                    }}
                />
            </Tooltip>
            {maxChars() > 0
                && <Tooltip title={t('Show ellipsis if the text is longer than the max characters.')}>
                    <FormControlLabel
                        style={{ marginLeft: 8 }}
                        control={<Checkbox checked={showEllipsis()} size="small"
                            onChange={() => setShowEllipsis(!showEllipsis())}
                        />}

                        label={<LCTypography variant="body2">Show Ellipsis</LCTypography>}
                    />
                </Tooltip>}

            {fitToBox
                && <Tooltip title={t('Allow text to wrap when auto sizing')}>
                    <FormControlLabel
                        style={{ marginLeft: 8 }}
                        control={<Checkbox checked={allowWrapping()} size="small"
                            onChange={() => setAllowWrapping(!allowWrapping())}
                        />}

                        label={<LCTypography variant="body2">Wrap Text</LCTypography>}
                    />
                </Tooltip>}
        </Box>

    </>
}

export const CSSFontSelector = ({ css, cssKey, onChangeCss, onSelectFont }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const dispatch = useDispatch();
    const fontName = getStyleValueForCSSKey(css, cssKey);
    const setFontName = value => {
        onChangeCss(
            getNewStyleStringForString(css, [
                [cssKey, `'${value}'`]
            ]));
        dispatch({ type: DESIGNER_PUSH_HISTORY })
    }

    const templateFonts = useSelector(state => state.designer.fonts)

    //Add a new field called group_name to each font that gives
    //each font a group_name of public or private depending on the public flag

    const fontList = useSelector(state => state.designer.fontList).map(x => {
        return {
            ...x,
            group_name: templateFonts.some(font => font.id === x.id) ? 'Template Fonts' : (x.public ? 'Fonts' : 'My Fonts')
        }
    }).sort((a, b) => {

        const groupOrder = {
            'Template Fonts': 0,
            'My Fonts': 1,
            'Fonts': 2,
        };

        if (groupOrder[a.group_name] !== groupOrder[b.group_name])
            return groupOrder[a.group_name] - groupOrder[b.group_name];

        return a.name.localeCompare(b.name);
    })

    const selectedFont = fontList.find(x => x.name == fontName || `'${x.name}'` == fontName) || '';
    const options = useMemo(() => [{ isUpload: true, name: "" }, { isDefault: true, name: "" }, ...fontList], [fontList.length])

    return <>
        <Autocomplete
            onChange={(_, value) => {

                if (onSelectFont && value)
                    onSelectFont(value)

                if (value && value.isUpload)
                    return;

                value
                    ? setFontName(value.name)
                    : setFontName('')
            }}
            multiple={false}
            disableClearable
            value={selectedFont}
            label={"Family"}
            size="small"
            options={options}
            groupBy={(option) => option.group_name}
            textFieldProps={{
                variant: "outlined",
            }}
            getOptionLabel={x => x.name}
            getOptionSelected={x => selectedFont === x}
            renderOption={(font) => {
                if (font.isUpload) {
                    return <Box component="span" onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setAnchorEl(e.currentTarget)
                    }}>
                        + Upload New
                    </Box>;
                }

                if (font.isDefault) {
                    return <em>Default</em>;
                }

                return font.options?.primary_image_public_url
                    ? <img src={font.options.primary_image_public_url} width="232" height="24" />
                    : font.name;
            }}
        />
        <GenericDialog
            title={t('Upload Font')}
            dialogProps={{
                open: Boolean(anchorEl),
                onClose: () => setAnchorEl(null),
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
            }}
            ContentComponent={<Box p={1} height={200}>
                <UploadFileAndGetFont
                    autoOpen={true}
                    onUpload={(font) => {
                        dispatch(addFont(font))
                        setFontName(font.name)
                        setAnchorEl(null);
                    }}
                />
            </Box>}
            ActionsComponent={<>
                <Button onClick={() => setAnchorEl(null)}
                    color="primary">
                    {t('Close')}
                </Button>
            </>}
        />
    </>
}

const UploadFileAndGetFont = ({ onUpload, autoOpen }) => {
    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
        borderColor: '#2196f3',
    };

    const acceptStyle = {
        borderColor: '#00e676',
        color: '#bdbdbd',
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const {
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: {
            'font/ttf': ['.ttf'],
            'font/otf': ['.otf'],
            'font/woff': ['.woff'],
            'font/woff2': ['.woff2'],
            'font/sfnt': ['.ttf'],
            'application/vnd.ms-fontobject': ['.eot'],
            'application/x-font-ttf': ['.ttf'],
            'application/x-font-woff': ['.woff'],
            'application/x-font-woff2': ['.woff2'],
            'application/x-font-otf': ['.otf'],
            'application/x-font-sfnt': ['.ttf']
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach((file) => {
                setLoading(true);
                lucitApi.uploads.uploadFontFile(file)
                    .then(font => {
                        onUpload(font)
                    })
                    .finally(() => setLoading(false))
            })
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useLayoutEffect(() => {
        if (autoOpen) {
            open();
        }
    }, [autoOpen])

    return (
        <div style={{ height: '100%', display: 'flex' }} onClick={open}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div>
                    {!loading && <Typography>{t('Click to Upload')}</Typography>}

                    {loading && <CircularProgress />}
                </div>
            </div>
        </div>
    )

}
