import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { CopyButton } from '../../components';
import { RoutePaths } from '../../helpers/constants';
import { lucitApi } from '../../services/lucitApi';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

const ShareInvoiceDialog = (props) => {
    const { invoice, open, handleClose } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [objectAccessToken, setObjectAccessToken] = useState(null);
    const [loading, setLoading] = useState(false);

    const fullSharingLink = objectAccessToken && `https://lucit.app` + RoutePaths.public.invoice(invoice.lcuid, objectAccessToken)

    const copyText = () => {
        return fullSharingLink
    }

    useEffect(() => {

        setLoading(true);
        if (invoice) {

            lucitApi.billing.getInvoiceObjectAccessToken(invoice.lcuid).then((response) => {
                setObjectAccessToken(response.object_access_token);
                setLoading(false);
            })
        }

    }, [invoice])

    return (
        <GenericDialog
            title={t('Share Invoice')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                <LCTypography variant="body2">
                    Use this special sharing link to share this invoice with people who are not signed in
                </LCTypography>

                {loading && <CircularProgressCentered />}

                {fullSharingLink &&
                    <Box mt={2} p={6} mb={3}>
                        <Typography variant="subtitle1">Link</Typography>
                        <Typography component="div"
                            variant='overline'
                            style={{ margin: "8px 0", whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '1.8' }}>
                            <span style={{ textTransform: "none" }}>
                                <Link color="secondary" target="_blank" rel="noreferrer" href={fullSharingLink}>
                                    {fullSharingLink}
                                </Link></span>
                        </Typography>
                    </Box>}
            </Box >}

            ActionsComponent={<>
                <CopyButton
                    title="Copy Link"
                    size='small'
                    color="secondary"
                    variant="contained"
                    text={copyText()}
                />
            </>}
        />
    )
}

export default ShareInvoiceDialog;
