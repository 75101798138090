import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NewApplicationAssignment from './NewApplicationAssignment';

const AssignApplicationButton = ({ lcuid, onNewAppAssigned }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation();

    return <>
        <Button
            variant="contained"
            onClick={() => setDialogOpen(true)}
            color="secondary"
        >
            {t('Add New')}
        </Button>

        <NewApplicationAssignment
            lcuid={lcuid}
            open={dialogOpen}
            handleClose={(app) => {
                if (app)
                    onNewAppAssigned(app)

                setDialogOpen(false)
            }}
        />
    </>
}

AssignApplicationButton.propTypes = {
    lcuid: PropTypes.string.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps
)(
    AssignApplicationButton
);
