import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withHomeLayout } from '../layouts/HomeLayout';
import { RoutePaths } from '../helpers/constants';
import { showError } from '../actions/snackbar';
import { getExport } from '../actions/exports';
import { exportsByIdSelector } from '../selectors/exports';
import ExportDetailsCampaign from './ExportDetailsCampaign';
import ExportDetailsDigitalBoardCampaign from './ExportDetailsDigitalBoardCampaign';
import ExportDetailsGroupCampaign from './ExportDetailsGroupCampaign';
import { CircularProgressCentered } from '../components';
import { InventoryExportCampaignClasses } from '../helpers/constants';

function ExportDetails(props) {

    const { exportId, exportItem, getExport } = props;
    const [loading, setLoading] = useState(false);

    const refreshExport = () => {
        setLoading(true);
        getExport(exportId)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        refreshExport();
    }, [getExport, exportId]);

    if (loading) {
        return <CircularProgressCentered />
    }

    if (exportItem.campaign_class === InventoryExportCampaignClasses.digitalBoard.class) {
        return <ExportDetailsDigitalBoardCampaign exportId={exportId} />
    }

    if (exportItem.campaign_class === InventoryExportCampaignClasses.group.class) {
        return <ExportDetailsGroupCampaign exportId={exportId} />
    }

    return <ExportDetailsCampaign exportId={exportId} />

}

const mapStateToProps = (state, ownProps) => {
    const { match } = ownProps;
    const exportId = match
        ? +match.params.id
        : null;

    return {
        exportId,
        exportItem: exportsByIdSelector(state)(exportId) || {},
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getExport: id => dispatch(getExport(id))
            .catch(error => {
                if (error.isOffline) {
                    ownProps.history.push({ pathname: RoutePaths.noInternet });
                    throw error;
                }

                dispatch(showError(`Export with id '${id}' was not found.`));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            ExportDetails
        )
    )
);
