import { Box, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LCTypography from '../../components/material/LCTypography';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { searched, isInvalidated } from '../../selectors/approvals';
import { getRejectionCodes, refresh } from '../../actions/approvals';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import CreativeList from '../../containers/approvals/CreativeList';
import { Refresh, Settings } from '@material-ui/icons';
import { selectedAgencyIds, selectedAgency } from '../../selectors/user';
import CreativeFilters from '../../containers/approvals/CreativeFilters';
import { PageTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import ApprovalSettingsDialog from '../../containers/approvals/ApprovalSettingsDialog';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Approvals(props) {
    const { loading, refresh, loadRejectionCodes, agencyIds, selectedAgency } = props;
    const classes = useStyles();

    const [showApprovalSettings, setShowApprovalSettings] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        loadRejectionCodes();
    }, [])

    useEffect(() => {
        refresh();

    }, [JSON.stringify(agencyIds)])

    return <Paper className={classes.root}>
        <PageTitle title={t('Approvals')} />

        <Box mb={2} display="flex">
            <LCTypography
                transProps={{ selectedName: selectedAgency.name }}
                variant="h5">{{ selectedName: selectedAgency.name }} Approvals</LCTypography>
            <Box ml={1}>
                <IconButton size="small" title={t('Refresh')}
                    disabled={loading}
                    onClick={() => refresh()}>
                    <Refresh />
                </IconButton>
            </Box>

            <Box ml={1}>
                <IconButton size="small" title={t('Settings')}
                    onClick={() => setShowApprovalSettings(true)}>
                    <Settings />
                </IconButton>
            </Box>
        </Box>

        <Box mt={2} />
        <CreativeFilters />

        <CreativeList />

        <ButtonScrollToTopFab />

        <ApprovalSettingsDialog
            open={showApprovalSettings}
            handleClose={() => setShowApprovalSettings(false)}
        />
    </Paper>
}

const mapStateToProps = state => {
    return {
        searched: searched(state),
        agencyIds: selectedAgencyIds(state),
        invalidated: isInvalidated(state),
        selectedAgency: selectedAgency(state),
        loading: state.approvals.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadRejectionCodes: () => {
            return dispatch(getRejectionCodes());
        },
        refresh: () => {
            return dispatch(refresh());
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            Approvals
        )
    )
);
