import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Grid, Tooltip } from '@material-ui/core';

const RolesChip = props => {
    const { roles, rolesDictionary } = props;

    return (<Grid spacing={1} container>
        {roles.map(role => <Grid item key={role}>
            <Tooltip title={rolesDictionary && rolesDictionary[role] && rolesDictionary[role].description}>
                <Chip
                    label={rolesDictionary && rolesDictionary[role] && rolesDictionary[role].title}
                    size="small" />
            </Tooltip>
        </Grid>)}
    </Grid>)
}

RolesChip.propTypes = {
    roles: PropTypes.array.isRequired,
    rolesDictionary: PropTypes.object.isRequired,
}

export default RolesChip;
