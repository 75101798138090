import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import React from 'react';

const LucitFormFieldBoolean = ({ field, onChange, value }) => {

    return <>
        <FormControl>

            <FormControlLabel control={
                <Switch
                    id={field.key}
                    checked={value ?? false}
                    onChange={(e) => onChange(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            } label={field.name} />

            <FormHelperText>{field.description}</FormHelperText>

        </FormControl>
    </>
}

export default LucitFormFieldBoolean;
