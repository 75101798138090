import { Box, Divider, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StoryClassMap } from '.';
import { RoutePaths } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        story: {
            maxWidth: 600,
            margin: 'auto'
        },

        commentedBox: {
            padding: theme.spacing(1.5, 2),
            marginTop: theme.spacing(0.5),
            boxShadow: `-2px -1px 1px 0px #f0f0f0,
                        2px 0px 1px 0px #f0f0f0`
        },

        cardBase: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        }
    }
})

export const CommentCreatedStory = (props) => {
    const { story } = props;
    const classes = useStyles();

    const stories = story.grouped
        ? story.stories.map(x => x.story)
        : [story];

    const commentators = stories
        .map(x => x.storyable)
        .filter((x, idx, arr) => arr.findIndex(t => (x && t.id === x.id)) === idx);

    const aboutStory = stories[0].story_data.about_story;

    if (!aboutStory)
        return null

    const Story = StoryClassMap[aboutStory.story_class]

    if (!Story) {
        return null;
    }

    return <Box className={classes.story}>
        <Box className={classes.commentedBox}>
            {commentators.map((x, idx) => <span key={idx}>
                <Link
                    component={RouterLink}
                    color="secondary"
                    to={RoutePaths.public.slug(x.slug)}>{x.name}</Link>
                {idx !== commentators.length - 1 && <>,&nbsp;</>}
            </span>)} commented...
        </Box>
        <Divider />
        <Story story={aboutStory}
            classes={{
                card: classes.cardBase
            }} />
    </Box>;
};

export default CommentCreatedStory;
