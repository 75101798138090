import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Chip } from '@material-ui/core';
import { ApprovalStatus } from '../../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        approvalChip: {
            marginRight: theme.spacing(1)
        },
        approved: {
            background: theme.palette.success.light
        },
        rejected: {
            background: theme.palette.error.light
        }
    }
})

const InventoryItemPhotoApprovalChips = (props) => {
    const { item, photo } = props;
    const classes = useStyles();
    const approvals = item.approvals.filter(x => x.inventory_photo_id == photo.id);

    if (approvals.length === 0) {
        return null;
    }

    return (
        <>
            {[ApprovalStatus.ok, ApprovalStatus.rejected, ApprovalStatus.pending]
                .map(status => ({
                    status,
                    approvals: approvals.filter(x => x.approval_status === status.id)
                }))
                .filter(x => x.approvals.length > 0)
                .map(group => {
                    return <Tooltip
                        key={group.status.id}
                        title={group.status === ApprovalStatus.pending
                            ? `Waiting approval from ${group.approvals.map(a => a.agency.name).join(', ')}`
                            : `${group.status.title} by ${group.approvals.map(a => a.agency.name).join(', ')}`}
                    >
                        <Chip
                            className={clx(classes.approvalChip, {
                                [classes.approved]: group.status === ApprovalStatus.ok,
                                [classes.rejected]: group.status === ApprovalStatus.rejected,
                            })}
                            size="small"
                            label={group.approvals.length > 1
                                ? `${group.approvals.length} ${group.status.title}`
                                : `${group.status.title}`}
                        />
                    </Tooltip>
                })}
        </>
    )
}

InventoryItemPhotoApprovalChips.propTypes = {
    item: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired
}

export default InventoryItemPhotoApprovalChips;
