/* eslint-disable */
import { Box, makeStyles } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography'
import PropTypes from 'prop-types';
import clx from 'classnames';
import { GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GoogleMapsContext } from '../../contexts';
import { logger } from '../../helpers/logger';
import { useTranslation } from 'react-i18next';
import { MapButton } from '../../components/google-maps';
import { CircularProgressCentered } from '../../components/material';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRAMMATIC_FILTERS_CHANGE, PROGRAMMATIC_INITIAL_VIEW_CHANGE, PROGRAMMATIC_VIEWPORT_CHANGE } from '../../helpers/actionTypes';
import DrawingFilter from './DrawingFilter';
import LocationMarker from './LocationMarker';
import LocationFilter from './LocationFilter';
import { ProgrammaticGeoSources, ProgrammaticGeoTypes, ProgrammaticMapSettings } from '../../helpers/constants';

const useStyles = makeStyles(() => {
    return {
        map: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            transition: 'width 1s ease'
        },
        zoomIn: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/plus.png)',
        },
        zoomOut: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/minus.png)',
        },
        mapButton: {
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
        firstMapButton: {
            borderRadius: '5px 5px 0px 0px !important',
        },
        lastMapButton: {
            borderRadius: '0px 0px 5px 5px !important',
        },
    }
})

export const ProgrammaticMap = ({ geo, locations, placeId, viewOnly,
    agencies, venueTypes,
    onViewportChange, onPlaceAdded, onGeoChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [didCentered, setDidCentered] = useState(false);

    const onMapIdle = useCallback(() => {
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        setZoom(map.getZoom())
        onViewportChange
            && onViewportChange({
                east: ne.lng(),   //longitude
                north: ne.lat(),   //Latitude
                south: sw.lat(),   //Latitude
                west: sw.lng(), //longitude
                zoom: map.getZoom()
            })
    }, [map]);
    const onMapLoaded = useCallback(map => {
        setMap(map);
        setZoom(map.getZoom())

        if (geo.length > 0) {
            map.fitBounds(getBoundsFromGeo(geo))
            setDidCentered(true)
        } else {
            //Initial center and zoom
            const center = ProgrammaticMapSettings.defaultCenter;

            map.setCenter(new window.google.maps.LatLng(center.lat, center.lng));
            map.setZoom(center.zoom);
        }
    }, [geo]);

    useEffect(() => {
        if (didCentered) {
            return;
        }

        if (map && geo.length > 0) {
            map.fitBounds(getBoundsFromGeo(geo))
            setDidCentered(true)
        }
    }, [didCentered, map, geo])

    const zomeInHandler = useCallback(() => map.setZoom(map.getZoom() + 1), [map]);
    const zomeOutHandler = useCallback(() => map.setZoom(map.getZoom() - 1), [map]);

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(gmContext.error)
            logger.logError(t('Map failed to load: {{error}}', { error: gmContext.error }), { Error: gmContext.error });
        }
    }, [gmContext.error])

    // Find place usign PlaceService by Id
    useEffect(() => {
        if (placeId) {
            const service = new window.google.maps.places.PlacesService(map);
            service.getDetails({ placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const geo = getBestGeoForPlace(place);

                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.union(place.geometry.viewport);
                    map.fitBounds(bounds, 36);
                    map.setCenter(place.geometry.location);

                    onPlaceAdded
                        && onPlaceAdded({
                            place,
                            geo,
                        })
                }
            })
        }
    }, [map, placeId]);

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <LCTypography>Map is currently unavailable</LCTypography>
        </Box>
    }

    const showDrawingFilter = !viewOnly;
    const mapOptions = useMemo(() => ({
        streetViewControl: false,
        fullscreenControl: false,
        minZoom: 2,
        zoom: ProgrammaticMapSettings.defaultZoom,
        mapId: "a69a5010584425cd",
        zoomControl: false,
        mapTypeControl: false,
        restriction: {
            latLngBounds: {
                east: 179.9999,
                north: 85,
                south: -85,
                west: -179.9999
            },
            strictBounds: true
        },
        // styles: require('../../assets/google-maps-styles.blackWhite.json')
    }), []);

    const maxClusterBoards = locations
        .filter(x => x.point_type == "h3_cluster")
        .map(x => x.cluster_data.total_boards)
        .reduce((max, current) => (current > max) ? current : max, 0)

    return <>
        <GoogleMap
            mapContainerClassName={clx(classes.map)}
            options={mapOptions}
            onLoad={onMapLoaded}
            onIdle={onMapIdle}
        >
            { /* Child components, such as markers, info windows, etc. */}
            <MapButton
                map={map}
                title={t('Zoom Out')}
                position={window.google.maps.ControlPosition.RIGHT_BOTTOM}
                classes={{
                    button: clx(classes.mapButton, classes.lastMapButton),
                    text: classes.zoomOut
                }}
                onClick={zomeOutHandler}
            />
            <MapButton
                map={map}
                title={t('Zoom In')}
                position={window.google.maps.ControlPosition.RIGHT_BOTTOM}
                classes={{
                    button: clx(classes.mapButton, classes.firstMapButton),
                    text: classes.zoomIn
                }}
                onClick={zomeInHandler}
            />

            {showDrawingFilter && <DrawingFilter
                geo={geo}
                onGeoChange={onGeoChange}
                map={map} />}

            {locations
                .map((location) =>
                    <LocationMarker
                        key={location.lcuid || location.id}
                        location={location}
                        zoom={zoom}
                        maxClusterBoards={maxClusterBoards}
                        agencies={agencies}
                        venueTypes={venueTypes}
                    />)}
        </GoogleMap>
    </>;
}

export const ProgrammaticMapConnected = () => {
    const dispatch = useDispatch();

    const geo = useSelector(state => state.programmatic.geo);
    const placeId = useSelector(state => state.programmatic.placeId);
    const locations = useSelector(state => state.programmatic.locations);
    const agencies = useSelector(state => state.programmatic.agency_options) ?? [];
    const venueTypes = useSelector(state => state.programmatic.venue_type_options) ?? [];

    return <ProgrammaticMap
        locations={locations}
        geo={geo}
        placeId={placeId}
        agencies={agencies}
        venueTypes={venueTypes}

        onViewportChange={({ zoom, ...rest }) => dispatch({
            type: PROGRAMMATIC_VIEWPORT_CHANGE,
            viewport: { ...rest },
            zoom
        })}
        onPlaceAdded={value => {
            dispatch({
                type: PROGRAMMATIC_FILTERS_CHANGE,
                ...value,
                placeId: null
            });
            dispatch({
                type: PROGRAMMATIC_INITIAL_VIEW_CHANGE,
                initial_view_active: false
            })
        }}
        onGeoChange={geo => dispatch({
            type: PROGRAMMATIC_FILTERS_CHANGE,
            geo
        })}
    />
}

/**
 *
 * @param {google.maps.places.PlaceResult} place
 * @returns json
 */
const getBestGeoForPlace = (place) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.union(place.geometry.viewport);

    const geo = {
        id: place.place_id,
        geo_source: ProgrammaticGeoSources.google_place.code,
        geo_source_data: {
            place
        },
        label: place.formatted_address,
        bounds,
    }

    if (place.types.includes("point_of_interest")) {
        return {
            ...geo,
            geo_type: ProgrammaticGeoTypes.radius.code,
            centerpoint: {
                lat: place.geometry.location.lat(),
                long: place.geometry.location.lng(),
            },
            units: "m",
            radius: getViewportRadius(place.geometry.viewport) * 0.6
        }
    }

    return {
        ...geo,
        geo_type: ProgrammaticGeoTypes.polygon.code,
        coordinates: [
            [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()],
            [bounds.getSouthWest().lat(), bounds.getNorthEast().lng()],
            [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()],
            [bounds.getNorthEast().lat(), bounds.getSouthWest().lng()],
        ]
    }
}

/**
 * AI Generted
 * Calculates the radius of the viewport based on the provided bounds.
 * @param {google.maps.LatLngBounds} bounds - The bounds of the viewport.
 * @param {number} padding - The padding factor around the center of the viewport (0 to 1).
 * @returns {number} The radius of the viewport.
 */
function getViewportRadius(bounds) {
    // Get the center of the viewport
    const center = bounds.getCenter();

    // Get the north-east corner of the viewport
    const ne = bounds.getNorthEast();

    // Calculate the distance between the center and the north-east corner
    return window.google.maps.geometry.spherical.computeDistanceBetween(center, ne);
}

const getBoundsFromGeo = geo => {
    const bounds = new google.maps.LatLngBounds();

    geo.forEach(x => {
        if (x.bounds instanceof google.maps.LatLngBounds) {
            bounds.union(x.bounds);
        }
        else if (x.bounds) {
            const sw = new google.maps.LatLng(x.bounds.south, x.bounds.west);
            const ne = new google.maps.LatLng(x.bounds.north, x.bounds.east);
            bounds.union(new google.maps.LatLngBounds(sw, ne));
        } else if (x.coordinates) {
            x.coordinates.forEach(coord =>
                bounds.extend(new window.google.maps.LatLng(coord[0], coord[1])));
        }
    });

    return bounds;
}

Map.propTypes = {
    locations: PropTypes.array.isRequired,
}

export default ProgrammaticMap;
