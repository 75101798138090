import { connect } from 'react-redux';
import usePageTracking from './../helpers/hooks/usePageTracking';

const GAUrlListener = () => {

    usePageTracking();

    return null;
};

const mapDispatchToProps = () => {
    return {}
}

export default connect(
    null,
    mapDispatchToProps
)(GAUrlListener);
