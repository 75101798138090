import React from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { apiHttp } from '../helpers/api'
import { SubmissionError } from 'redux-form';
import { ErrorCodes, QueryString, RoutePaths } from '../helpers/constants';
import { isPhoneNumber } from '../helpers/string';
import LucitApiPushNotifications from './lucitApi.pushNotifications';
import LucitApiAnalytics from './lucitApi.analytics';
import LucitApiBillboard from './lucitApi.billboard';
import LucitApiLocations from './lucitApi.locations';
import LucitApiMassEmails from './lucitApi.massEmail';
import LucitApiDictionaries from './lucitApi.dictionaries';
import LucitApiUploads from './lucitApi.uploads';
import LucitApiAccounts from './lucitApi.accounts';
import LucitApiAgencies from './lucitApi.agencies';
import LucitApiUsers from './lucitApi.users';
import LucitApiExports from './lucitApi.exports';
import LucitApiImages from './lucitApi.images';
import LucitApiInventory from './lucitApi.inventory';
import LucitApiFeeds from './lucitApi.feeds';
import LucitApiFeedProviders from './lucitApi.feedProviders';
import LucitApiCampaigns from './lucitApi.campaigns';
import LucitApiRegistration from './lucitApi.registration';
import LucitApiInvitations from './lucitApi.invitations';
import LucitApiRoles from './lucitApi.roles';
import LucitApiObjects from './lucitApi.objects';
import LucitApiStories from './lucitApi.stories';
import LucitApiFonts from './lucitApi.fonts';
import LucitApiDriveTemplates from './lucitApi.driveTemplates';
import LucitApiCreativeProofs from './lucitApi.creativeProofs';
import LucitApiApplications from './lucitApi.applications';
import LucitApiBilling from './lucitApi.billing';
import LucitApiFacebook from './lucitApi.facebook';
import LucitApiLightning from './lucitApi.lightning';
import LucitApiDriveTemplateElements from './lucitApi.driveTemplateElements';

/**
 * TODO: refactor
 * @export
 * @class LucitApi
 */
export default class LucitApi extends LucitApiPushNotifications {

    applications;
    analytics;
    notifications;
    billboards;
    billing;
    locations;
    massEmails;
    dictionaries;
    uploads;
    accounts;
    agencies;
    users;
    registrations;
    feeds;
    feedProviders;
    exports;
    images;
    inventory;
    campaigns;
    invitations;
    roles;
    objects;
    stories;
    fonts;
    driveTemplates;
    creativeProofs;
    facebook;
    lightning;
    driveTemplateElements;

    constructor() {
        super();

        this.applications = new LucitApiApplications();
        this.notifications = new LucitApiPushNotifications();
        this.analytics = new LucitApiAnalytics();
        this.billboards = new LucitApiBillboard();
        this.billing = new LucitApiBilling();
        this.locations = new LucitApiLocations();
        this.massEmails = new LucitApiMassEmails();
        this.dictionaries = new LucitApiDictionaries();
        this.uploads = new LucitApiUploads();
        this.accounts = new LucitApiAccounts();
        this.agencies = new LucitApiAgencies();
        this.users = new LucitApiUsers();
        this.feeds = new LucitApiFeeds();
        this.feedProviders = new LucitApiFeedProviders();
        this.exports = new LucitApiExports();
        this.images = new LucitApiImages();
        this.inventory = new LucitApiInventory();
        this.campaigns = new LucitApiCampaigns();
        this.registrations = new LucitApiRegistration();
        this.invitations = new LucitApiInvitations();
        this.roles = new LucitApiRoles();
        this.objects = new LucitApiObjects();
        this.stories = new LucitApiStories();
        this.fonts = new LucitApiFonts();
        this.driveTemplates = new LucitApiDriveTemplates();
        this.creativeProofs = new LucitApiCreativeProofs();
        this.facebook = new LucitApiFacebook();
        this.lightning = new LucitApiLightning();
        this.driveTemplateElements = new LucitApiDriveTemplateElements();
    }

    login(username, password, registrationId, join_lcuid, sms_code) {
        const body = {
            email_or_phone: username,
            password: password,
            registration_id: registrationId,
            sms_code: sms_code
        };

        return apiHttp.post('/login', body)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const response = error.response;

                const queryString = (isPhoneNumber(username)
                    ? `?${QueryString.phoneNumber}=${username}` : '')
                    + (join_lcuid ? `&${QueryString.join_lcuid}=${join_lcuid}` : '')

                if (response.status === 401) {
                    if (response.data.error_code === ErrorCodes.userEmailNotExists) {
                        throw new SubmissionError({
                            username: <span>There is no Lucit account with that {isPhoneNumber(username) ? 'phone number' : 'email'}.
                                <br />
                                Would you like to <Link
                                    color="secondary"
                                    component={RouterLink}
                                    to={{
                                        pathname: RoutePaths.registrationNew,
                                        search: queryString
                                    }}>Sign Up</Link>?</span>
                        })
                    } else if (response.data.error_code === ErrorCodes.userPasswordIsInvalid) {
                        throw new SubmissionError({
                            password: <span>Invalid Password. <Link
                                color="secondary"
                                component={RouterLink}
                                to={{
                                    pathname: RoutePaths.forgotPassword,
                                    search: queryString,
                                    state: isPhoneNumber(username)
                                        ? { phone: username }
                                        : null
                                }}>Forgot Password?</Link></span>
                        })
                    } else if (response.data.error_code === ErrorCodes.phoneInvalidFormat) {
                        throw new SubmissionError({
                            username: 'Invalid phone number format - Please use: ###-###-####'
                        })
                    } else if (response.data.error_code === ErrorCodes.userNotRegistered) {
                        throw new SubmissionError({
                            username: <span>Number not registered  -  <Link
                                color="secondary"
                                component={RouterLink}
                                to={{
                                    pathname: RoutePaths.register,
                                    search: queryString

                                }}
                            >Register?</Link></span>
                        })
                    }
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            });
    }

    loginMfaConfirm(lcuid, sms_code, token) {
        return apiHttp.post('/login-2fa-confirm', { lcuid, sms_code, token })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const response = error.response;
                if (response.status === 422) {
                    if (response.data.code === ErrorCodes.smsCodeInvalid) {
                        throw new SubmissionError({
                            code: response.data.data.sms_code_is_expired
                                ? "SMS Code is Expired"
                                : response.data.message
                        })
                    }
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            });
    }

    getUserInfo() {
        return apiHttp.get('/users/current');
    }

    updateUser(userId, name, timezone) {
        const data = {
            name,
            timezone
        };

        return apiHttp.put(`/users/${userId}`, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            });
    }

    updateUserTitle(userId, name, title) {
        const data = {
            name,
            title
        };

        return apiHttp.put(`/users/${userId}`, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            });
    }

    updateUserAboutMe(userId, name, aboutMe) {
        const data = {
            name: name,
            description: aboutMe
        };

        return apiHttp.put(`/users/${userId}`, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            });
    }

    setFcmToken(userId, token, platform) {
        return apiHttp.put(`/users/${userId}/setFcm`, {
            token,
            platform
        });
    }

    changePhone(userId, phone) {
        return apiHttp.put(`/users/${userId}/setMobile`, { mobile_phone_number: phone })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                if (data.code === ErrorCodes.invalidParameters) {
                    throw new SubmissionError({
                        phone: "Phone number is not valid"
                    });
                }

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    changePhoneValidate(userId, code) {
        return apiHttp.put(`/users/${userId}/setMobileValidate`, { sms_code: code })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                if (data.code === ErrorCodes.smsCodeInvalid) {
                    throw new SubmissionError({
                        code: "Confirmation code is invalid"
                    });
                }

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    changeEmail(userId, email) {
        return apiHttp.put(`/users/${userId}/setEmail`, { email })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    resendEmailConfirm(email) {
        return apiHttp.put(`/resend-email-verification`, { email })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    resetPassword(phone, code, password) {
        const data = {
            mobile_phone_number: phone,
            sms_code: code,
            password
        };

        return apiHttp.put(`/resetPassword`, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                if (data.code === ErrorCodes.smsCodeInvalid) {
                    throw new SubmissionError({
                        code: "Confirmation code is invalid"
                    });
                }

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    log({ message, data, level }) {
        return apiHttp.post('/log', {
            message,
            level,

            data: JSON.stringify(data)
        });
    }

    submitHelpRequest({ name, email, phone, message, ...rest }) {
        return apiHttp.post('/support', {
            from_name: name,
            from_email: email,
            from_phone: phone,
            from_message: message,
            support_data: JSON.stringify(rest)
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    submitOperatorNewAccountRequest({ accountWebSite, accountName, accountType, accountCityState, comments, agency_id }) {
        return apiHttp.post('/support/operator-new-account-request', {
            account_website: accountWebSite,
            account_name: accountName,
            account_type: accountType,
            account_city_state: accountCityState,
            comments: comments,
            agency_id: agency_id
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    submitUserNewAccountRequest({ accountWebSite, accountName, accountType, accountCityState, comments, agency_id, connectNew }) {
        return apiHttp.post('/support/user-new-account-request', {
            account_website: accountWebSite,
            account_name: accountName,
            account_type: accountType,
            account_city_state: accountCityState,
            comments: comments,
            agency_id: agency_id,
            connect_new: connectNew
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    submitQuickProofRequest({
        account_id, account_name, ad_agency_id, agency_id, new_account, selectedBoardSizes, selectedBoardIds, inventory_export_id
    }) {

        return apiHttp.post('/support/quick-proof-request', {
            account_id: account_id,
            account_name: account_name,
            ad_agency_id: ad_agency_id,
            agency_id: agency_id,
            new_account: new_account,
            inventory_export_id: inventory_export_id,
            selected_board_sizes: JSON.stringify(selectedBoardSizes),
            selected_board_ids: JSON.stringify(selectedBoardIds)
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {

                const { data } = error.response;

                throw new SubmissionError({
                    _error: (data && data.message) || 'Something went wrong'
                });
            });
    }

    globalSearch(isLoggedIn, { query, page, perPage }) {

        const url = isLoggedIn ? '/search-models' : '/search-models/noauth'

        return apiHttp.get(url, { params: { query, page, per_page: perPage } })
            .then(response => response.data.results);
    }

    getEnvironment() {
        return apiHttp.get('/environment')
            .then(response => response.data.environment);
    }

    getLatestVersion() {
        // eslint-disable-next-line no-undef
        return apiHttp.get('/environment/layoutui')
            .then(response => response.data.layoutui.latest_app_versions);
    }

    sendAdRequest(data) {
        return apiHttp.post('/advertise-request', {
            ...data,
            support_data: JSON.stringify(data.support_data)
        })
            .then(response => response.data.ticket_number);
    }

    submitBoardProof({ image, digital_board_lcuid, account_name, isLoggedIn }) {

        const url = isLoggedIn ? `/uploads/quick-board-proof` : `/public/uploads/quick-board-proof`;

        return fetch(image)
            .then(res => res.blob())
            .then(blob => {
                const form = new FormData();

                form.append('file', blob);
                form.append('account_name', account_name);
                form.append('digital_board_lcuid', digital_board_lcuid);

                return apiHttp.post(url, form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(response => response.data.creative_proof);
            })
    }

    spyStatus() {
        return apiHttp.get(`/spy/status`)
            .then(x => x.data);
    }
}

export const lucitApi = new LucitApi();
