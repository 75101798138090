import React, { useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DriveTemplateElementEditor from './DriveTemplateElementEditor';

const useStyles = makeStyles(() => {
    return {
        macroRow: {
            marginBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
        },
        macroRowTitle: {
            paddingLeft: "4px"
        },
        dataSourceRowBox: {
            padding: "4px",
            border: "1px solid transparent",
            borderRadius: "5px",
            cursor: "pointer",
            display: 'inline-table',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%',
            width: '100%',
            minHeight: '100px',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
                border: "1px solid #0000001f",
                boxShadow: "0px 0px 5px 0px #0000001f",
                '& .MuiBox-root': {
                    visibility: 'visible'
                }
            }
        }
    }
})

export const DriveTemplateElementItem = ({ element, clickToEdit = true }) => {

    const classes = useStyles();
    const [showEditor, setShowEditor] = useState(false);

    return (
        <Box
            className={classes.macroRow}
        >
            <Box
                onClick={() => clickToEdit ? setShowEditor(true) : null}
                className={classes.dataSourceRowBox}
                title={element.code}
            >
                <Tooltip title={element.name} placement="top">
                    <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: element.preview_html }}>
                    </div>
                </Tooltip>
            </Box>

            {showEditor && <DriveTemplateElementEditor
                open={true}
                driveTemplateElement={element}
                handleClose={() => setShowEditor(false)}
            />
            }

        </Box >
    )

}

export default DriveTemplateElementItem
