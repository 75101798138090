/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { connect, useDispatch } from 'react-redux';
import {
    Grid, makeStyles, Link, List, ListItem, ListItemText, ListItemIcon,
    Typography, ListItemSecondaryAction, Box, Collapse, IconButton
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { Warning } from '@material-ui/icons';
import Tooltip from '../../components/material/Tooltip';
import IconButtonLoaderNew from '../../components/material/IconButtonLoaderNew';
import { BillboardIcon, RoutePaths } from '../../helpers/constants';
import ExportsIcon from '../../components/icons/ExportIcon';
import { exportItems, unexportItems } from '../../actions/exports';
import { showSuccess, showError } from '../../actions/snackbar';
import { getItem, setExportRunSchedule, setExportDigitalBoardFilter } from '../../actions/inventory';
import { useEffect } from 'react';
import { groupBy } from '../../helpers/array';
import { sortString } from '../../helpers/sorters';
import { hasMaxTitleLengthWarning, shouldShowMaxTitleWarning } from '../../selectors/exports';
import { isExportedOrPending } from '../../selectors/inventoryStatus';
import { Messages } from '../../helpers/messages';
import { getItemRunSchedule, getItemBoardIds, getItemDigitalBoardFilter, hasItemFilterOrBoardFilter } from '../../selectors/inventory';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ItemScreenSelectDialog from '../../components/modals/ItemScreenSelectDialog';
import { Tv } from '@material-ui/icons';
import { ScheduleBuilderDialog } from '../itemSchedule';
import { FolderSpecial } from '@material-ui/icons';
import { ItemFilterDialog } from '../itemSchedule';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { currentUserHasPermission } from '../../selectors/user';

const useStyles = makeStyles(theme => {
    return {
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },

        exportListItem: {
            paddingRight: theme.spacing(10)
        },

        exportIcon: {
            minWidth: theme.spacing(5),
            '& img': {
                width: theme.spacing(3),
                height: theme.spacing(3),
            }
        },

        exportCheckmarkActive: {
            color: theme.palette.success.main,
        },

        itemHasSettings: {
            color: theme.palette.secondary.main
        },

        exportsSubHeader: {
            top: theme.spacing(-3),
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary
        },
        exportsSubHeaderIcon: {
            marginRight: theme.spacing(1)
        },

        exportHasSettings: {
            color: theme.palette.secondary.main
        },

        exportAction: {
            right: 0,
            display: 'flex',
            alignItems: 'center'
        },

        helpIcon: {
            marginLeft: theme.spacing(1)
        },

        warning: {
            color: theme.palette.warning.main
        }
    }
})

function InventoryItemExports(props) {
    const { exportsStatus, exportItem, unexportItem } = props;
    const classes = useStyles();
    const [exportsByType, setExportsByType] = useState({});

    useEffect(() => {
        setExportsByType(groupBy(exportsStatus, item => item.export.export_type));
    }, [exportsStatus])

    return (
        <>
            {Object.keys(exportsByType)
                .sort(sortString)
                .map(exportType => {
                    const ExportIcon = BillboardIcon

                    return <List
                        key={exportType}
                        subheader={
                            <Box display="flex" alignItems="center">
                                <ExportIcon className={classes.exportsSubHeaderIcon} />
                                <LCTypography className={classes.exportsSubHeader} variant="h6">
                                    Campaigns
                                </LCTypography>
                            </Box>}>
                        {exportsByType[exportType]
                            .map(exportStatus => <InventoryItemExport
                                key={exportStatus.export.inventory_export_id}
                                exportStatus={exportStatus}
                                {...{ exportItem, unexportItem }} />)}
                    </List>;
                })}
        </>
    );
}

function InventoryItemExport(props) {
    const { exportStatus, exportItem, unexportItem } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [isSettingsOpened, setSettingsOpened] = useState(false);
    const [isScheduleOpened, setScheduleOpened] = useState(false);
    const [isItemFilterOpened, setItemFilterOpened] = useState(false);

    const exportId = exportStatus.export.inventory_export_id

    const [itemHasSchedule, setItemHasSchedule] = useState(getItemRunSchedule(exportStatus.item, exportId).length > 0);
    const [itemHasFilter, setItemHasFilter] = useState(hasItemFilterOrBoardFilter(exportStatus.item, exportId));
    const [itemHasBoardIds, setItemHasBoardIds] = useState(getItemBoardIds(exportStatus.item, exportId).length > 0);

    const useCanEditCreativeTriggers = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editCreativeTriggers));

    const { t } = useTranslation();

    const hasExportIcon = x => x.export_options
        && x.export_options.primary_image_public_url;

    const tooltipTitle = exportStatus.requested ? t('Selected') +
        (exportStatus.exportedOrPending ? t(' And Active') : t(' but not active because of schedule or some other constraint')) : '';

    const exportedOrPending = isExportedOrPending(exportStatus);

    return <ListItem
        divider
        className={classes.exportListItem}
        key={exportId}
    >
        <ListItemIcon className={classes.exportIcon}>
            <>
                {hasExportIcon(exportStatus.export)
                    && <img
                        src={exportStatus.export.export_options.primary_image_public_url}
                        alt={exportStatus.export.inventory_export_name} />}
                {!hasExportIcon(exportStatus.export) && <ExportsIcon />}
            </>
        </ListItemIcon>
        <ListItemText>
            <Link component={RouterLink} to={`${RoutePaths.exports}/${exportStatus.export.id}`}>
                {exportStatus.export.inventory_export_name}
            </Link>
            <Collapse in={true}>
                <Box mt={1} />
                <Grid container spacing={1}>
                    <Grid item className={classes.gridItem} xs={12}>
                        <Typography variant="body2" color="textSecondary" >{exportStatus.details && t('Skipped...')}</Typography>
                    </Grid>
                    {shouldShowMaxTitleWarning(exportStatus.item) && hasMaxTitleLengthWarning(exportStatus.export, exportStatus.item.title)
                        && exportedOrPending
                        && <Grid item className={clx(classes.gridItem, classes.warning)}>
                            <Box component={Warning}
                                mr={1}
                                alignSelf="flex-start" />

                            <Box
                                alignSelf="flex-end"
                                component={Typography}
                                variant="body2">
                                {Messages.itemTitleCouldBeTooLong}
                            </Box>
                        </Grid>}
                </Grid>
            </Collapse>
        </ListItemText>
        <ListItemSecondaryAction className={classes.exportAction}>
            <Tooltip title={t('Adjust Date Range')}>
                <IconButton
                    disabled={!useCanEditCreativeTriggers}
                    className={clx({
                        [classes.itemHasSettings]: itemHasSchedule
                    })}
                    onClick={() => setScheduleOpened(!isScheduleOpened)}
                >
                    <DateRangeIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title={t('Set custom filters and triggers')}>
                <IconButton
                    disabled={!useCanEditCreativeTriggers}
                    className={clx({
                        [classes.itemHasSettings]: itemHasFilter
                    })}
                    onClick={() => setItemFilterOpened(!isItemFilterOpened)}
                >
                    <FolderSpecial />
                </IconButton>
            </Tooltip>

            <Tooltip title={t('Select Screens')}>
                <IconButton
                    disabled={!useCanEditCreativeTriggers}
                    className={clx({
                        [classes.itemHasSettings]: itemHasBoardIds
                    })}
                    onClick={() => setSettingsOpened(!isSettingsOpened)}>
                    <Tv />
                </IconButton>
            </Tooltip>

            <ScheduleBuilderDialog
                open={isScheduleOpened}
                handleClose={() => setScheduleOpened(false)}
                title={t(`Schedule Editor`)}

                schedule={getItemRunSchedule(exportStatus.item, exportId)}
                setSchedule={newSchedule => {

                    if (newSchedule.some(x => x.type === "date_range"
                        || x.type === "time_range"
                        || x.type === "days_of_the_week"
                    )) {
                        setItemHasSchedule(true); //purely for the icon color state
                    } else {
                        setItemHasSchedule(false); //purely for the icon color state
                    }

                    dispatch(setExportRunSchedule(exportStatus.item.id, exportId, newSchedule))
                        .then(() => dispatch(showSuccess(t('Item schedule saved'))))
                        .catch(() => dispatch(showError(t('Error saving schedule'))))
                }}
            />

            <ItemFilterDialog
                open={isItemFilterOpened}
                handleClose={() => setItemFilterOpened(false)}
                title={t(`Custom Filters / Triggers Editor`)}
                accountId={exportStatus.export.account_id}
                schedule={getItemRunSchedule(exportStatus.item, exportId)}
                digitalBoardFilter={getItemDigitalBoardFilter(exportStatus.item, exportId)}
                setFilter={(newSchedule, newDigitalBoardFilter) => {

                    if (newSchedule.some(x => x.type === "item_filter")

                        || newDigitalBoardFilter.filter_fields?.length > 0
                    ) {
                        setItemHasFilter(true); //purely for the icon color state
                    } else {
                        setItemHasFilter(false); //purely for the icon color state
                    }

                    dispatch(setExportRunSchedule(exportStatus.item.id, exportId, newSchedule)).finally(() => {
                        dispatch(setExportDigitalBoardFilter(exportStatus.item.id, exportId, newDigitalBoardFilter))
                            .then(() => dispatch(showSuccess(t('Item filter saved'))))
                            .catch(() => dispatch(showError(t('Error saving filter'))))
                    })
                }}
            />

            <ItemScreenSelectDialog
                open={isSettingsOpened}
                handleClose={() => setSettingsOpened(false)}
                itemId={exportStatus.item.id}
                exportId={exportId}
                itemBoardIds={getItemBoardIds(exportStatus.item, exportId)}
                lazy
                onChange={(hasBoardIds) => {
                    if (hasBoardIds) {
                        setItemHasBoardIds(true); //purely for the icon color state
                    } else {
                        setItemHasBoardIds(false); //purely for the icon color state
                    }
                }}
            />

            {exportStatus.requested
                && <Tooltip title={tooltipTitle}>
                    <IconButtonLoaderNew
                        submitting={submitting}
                        size={24}
                        color="secondary"
                        style={{ opacity: exportedOrPending ? 1 : 0.5 }}
                        onClick={() => {
                            setSubmitting(true);
                            unexportItem(
                                exportStatus.export.inventory_export_id,
                                exportStatus.itemId,
                                exportStatus.export.inventory_export_name)
                                .finally(() => setSubmitting(false))
                        }}>
                        <CheckCircleIcon />
                    </IconButtonLoaderNew>
                </Tooltip>}
            {!exportStatus.requested
                && <Tooltip title={t('Add Item')}>
                    <IconButtonLoaderNew
                        submitting={submitting}
                        size={24}
                        onClick={() => {
                            setSubmitting(true);
                            exportItem(
                                exportStatus.export.inventory_export_id,
                                exportStatus.itemId,
                                exportStatus.export.inventory_export_name)
                                .finally(() => setSubmitting(false))
                        }}>
                        <AddCircleOutlineIcon />
                    </IconButtonLoaderNew>
                </Tooltip>}
        </ListItemSecondaryAction>
    </ListItem>;
}

InventoryItemExports.propTypes = {
    exportsStatus: PropTypes.array.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        exportItem: (exportId, itemId, exportTitle) => dispatch(exportItems(exportId, [itemId]))
            .then(() => dispatch(showSuccess(`Item was successfully marked to be added to "${exportTitle}"`)))
            .then(() => dispatch(getItem(itemId)))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                dispatch(showError('Unexpected error during adding...'));
                throw error;
            }),

        unexportItem: (exportId, itemId, exportTitle) => dispatch(unexportItems(exportId, [itemId]))
            .then(() => dispatch(showSuccess(`Item was successfully removed from "${exportTitle}" campaign`)))
            .then(() => dispatch(getItem(itemId)))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                dispatch(showError('Unexpected error during removing item from campaign..'));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemExports
);
