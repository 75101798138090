import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { clearPriceOverride, getItem, setPriceOverride } from '../../../actions/inventory';
import { showSuccess } from '../../../actions/snackbar';
import { InventoryItemStatus, InventoryItemStatusDetails } from '../../../helpers/constants';
import { condition, hasMileage, mileage, mileageUnits } from '../../../selectors/inventory';
import InventoryItemPhotoGallery from './InventoryItemPhotoGallery';
import PriceOverridable from './PriceOverridable';
import TitleOverridable from './TitleOverridable';

import i18next from 'i18next';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },

        titleInputRoot: {
            maxWidth: '100%'
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        },

        strikethrough: {
            textDecoration: 'line-through'
        },

        undoIcon: {
            marginLeft: '10px'
        }
    }
})

function InventoryItem(props) {
    const { item } = props;
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <InventoryItemPhotoGallery
                    item={item}
                    showUploadDelete={true} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <TitleOverridable item={item} />
                </Box>

                {item.status === InventoryItemStatus.sold
                    && Boolean(InventoryItemStatusDetails[item.status])
                    && <Box className={classes.attribute}>
                        <Box component={Typography} className={classes.attributeTitle}>Status:</Box>
                        <Box component={Typography} className={classes.attributeValue}>{InventoryItemStatusDetails[item.status].title}</Box>
                    </Box>}
                <Box className={classes.attribute}>
                    <Box component={Typography} className={classes.attributeTitle}>Stock:</Box>
                    <Box component={Typography} className={classes.attributeValue}>{item.dealer_stock_number}</Box>
                </Box>
                <Box className={classes.attribute}>
                    <Box component={Typography} className={classes.attributeTitle}>VIN:</Box>
                    <Box component={Typography} className={classes.attributeValue}>{item.vin}</Box>
                </Box>
                <Box className={classes.attribute}>
                    <Box alignSelf="flex-start" component={Typography} className={classes.attributeTitle}>Price:</Box>
                    <Box className={classes.attributeValue}>
                        <PriceOverridable item={item} />
                    </Box>
                </Box>
                <Box className={classes.attribute}>
                    <Box component={Typography} className={classes.attributeTitle}>Condition:</Box>
                    <Box component={Typography} className={classes.attributeValue}>{condition(item)}</Box>
                </Box>

                {hasMileage(item)
                    &&
                    <Box className={classes.attribute}>
                        <Box component={Typography} className={classes.attributeTitle}>Odometer:</Box>
                        <Box component={Typography} className={classes.attributeValue}>{mileage(item)} {mileageUnits(item)}</Box>
                    </Box>}

            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        getItem: id => dispatch(getItem(id)),

        handlePriceChange: price => {
            return dispatch(setPriceOverride(props.item.id, price))
                .then(() => dispatch(showSuccess(i18next.t('Price was successfully set'))));
        },
        handlePriceClear: () => {
            return dispatch(clearPriceOverride(props.item.id))
                .then(() => dispatch(showSuccess(i18next.t('Price override was successfully cleared'))));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
