import React, { useState } from 'react';
import { Box, TextField, Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem'
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Designer } from '../../helpers/constants'
import { useDispatch } from 'react-redux';
import { DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';

export const getHtmlDomFromHtmlString = (html) => {

    const parser = new DOMParser();

    const preparedHtml = "<html><body>" + html + "</body></html>"

    const doc = parser.parseFromString(preparedHtml, 'text/html');

    return doc
}

export const parseHtmlForLayers = (html) => {

    const doc = getHtmlDomFromHtmlString(html)

    const layers = [];

    // Get all top-level <div> elements
    const divs = Array.from(doc.body.children)

    divs.forEach(div => {
        const id = div.getAttribute('id');
        if (!id) return;

        const layer = {
            id: id,
            name: id,
            visible: true,
            html: div.outerHTML
        };

        layers.push(layer);
    });

    return layers;
}

export const getInjectableHtml = (html, css, js, templateAssets, cacheBuster = "") => {

    if (!templateAssets || !templateAssets.html)
        return "<h5>{loading....}</h5>"

    let strHtml = templateAssets.html

    const wrappedJs = `try{ ${js} } catch(e) { handleTemplateError(new DesignerCustomJSRenderError(e)) }`

    strHtml = strHtml.replace("{LC_DT_HTML}", html)
    strHtml = strHtml.replace("{LC_DT_CSS}", (templateAssets.css ?? "") + css)
    strHtml = strHtml.replace("{LC_DT_JS}", (templateAssets.js ?? "") + wrappedJs)

    strHtml = strHtml.replace("<head>", `<head>
        <link rel="stylesheet" type="text/css" href="/assets/designer/dt_designer.css?v=${cacheBuster}" />
        <script src="/assets/designer/dt_designer.helpers.js?v=${cacheBuster}"></script>
        <script src="/assets/designer/dt_designer.js?v=${cacheBuster}"></script>
        <script src="/assets/designer/dt_interact.fork.js?v=${cacheBuster}"></script>
    `)
    strHtml = strHtml.replace("</head>", `
            <script src="https://cdn.jsdelivr.net/gh/L1quidH2O/ContextMenu.js@latest/contextmenu.js"></script>
        </head>
    `)
    return strHtml;
}

export const idExistsInHTMLString = (id, html) => {

    if (!html || !id)
        return false;

    return html.includes("id=\"" + id + "\"")

}

export const getHtmlForId = (id, html) => {

    if (!idExistsInHTMLString(id, html))
        return "";

    const doc = getHtmlDomFromHtmlString(html)

    const div = doc.getElementById(id)

    if (!div)
        return ""

    return div.outerHTML

}

export const replaceHtmlForId = (id, newHtml, html) => {

    if (!idExistsInHTMLString(id, html))
        return html;

    const doc = getHtmlDomFromHtmlString(html)

    const div = doc.getElementById(id)

    if (!div)
        return html

    div.outerHTML = newHtml

    return doc.body.innerHTML

}

export const replaceInnerHtmlForId = (id, newHtml, html) => {

    if (!idExistsInHTMLString(id, html))
        return html;

    const doc = getHtmlDomFromHtmlString(html)

    const div = doc.getElementById(id)

    if (!div)
        return html

    div.innerHTML = newHtml

    return doc.body.innerHTML

}

export const replaceInnerTextForHtml = (html, newInnerText) => {

    const firstDivEndsAt = html.indexOf(">") + 1

    if (!firstDivEndsAt)
        return html

    const closingDiv = html.lastIndexOf("</div>")

    if (!closingDiv)
        return html

    return html.substring(0, firstDivEndsAt) + newInnerText + html.substring(closingDiv).trim()
}

export const deleteIdFromHtml = (id, html) => {

    if (!idExistsInHTMLString(id, html))
        return html;

    const doc = getHtmlDomFromHtmlString(html)

    const div = doc.getElementById(id)

    if (!div)
        return html

    div.remove()

    return doc.body.innerHTML

}

export const htmlStringToElement = (html) => {

    const div = document.createElement("div");
    div.innerHTML = html;
    return div.firstChild;
}

export const addClassAndRemoveClasses = (element, classToAdd, classesToRemove) => {

    if (!element)
        return null;

    classesToRemove.forEach((classToRemove) => {
        element.classList.remove(classToRemove);
    })

    element.classList.add(classToAdd);

    return element
}

export const getCurrentClassFromListOfClasses = (element, classes) => {

    if (!element)
        return null;

    for (let i = 0; i < classes.length; i++) {
        if (element.classList.contains(classes[i]))
            return classes[i]
    }

    return null
}

export const getLayerTypeFromElement = (element) => {

    if (!element)
        return null;

    if (element.classList.contains("lc_dt_image"))
        return Designer.ObjectTypes.Image

    if (element.classList.contains("lc_dt_text"))
        return Designer.ObjectTypes.Text

    if (element.classList.contains("lc_dt_object_svg"))
        return Designer.ObjectTypes.Svg

    if (element.classList.contains("lc_dt_object"))
        return Designer.ObjectTypes.Object

    return null
}

export const isImageDiv = (element) => {
    if (!element)
        return false;

    return element.classList.contains("lc_dt_image")
}

export const isTextDiv = (element) => {
    if (!element)
        return false;

    return element.classList.contains("lc_dt_text")
}

export const isObjectDiv = (element) => {
    if (!element)
        return false;

    return element.classList.contains("lc_dt_object")
}

export const isObjectSvgDiv = (element) => {
    if (!element)
        return false;

    return element.classList.contains("lc_dt_object_svg")
}

export const hasClass = (element, className) => {
    if (!element)
        return false;

    return element.classList.contains(className)
}

export const SingleClassOnOff = ({
    titleOn, titleOff, html, className, onChange, IconOn = null, IconOff = null, disabled = false, buttonClassName
}) => {
    const dispatch = useDispatch();
    const isOn = hasClass(htmlStringToElement(html), className)

    const [value, setValue] = useState(isOn)

    return <Tooltip title={isOn ? titleOn : titleOff}>
        <ToggleButton
            size="small"
            disabled={disabled}
            selected={value}
            value={value}
            className={buttonClassName}
            onChange={() => {
                if (html) {
                    const el = htmlStringToElement(html);

                    value ? el.classList.remove(className) : el.classList.add(className)

                    onChange(el.outerHTML)
                    dispatch({ type: DESIGNER_PUSH_HISTORY })

                    setValue(!value)
                }
            }}
        >
            {isOn ? IconOn : IconOff || IconOn}
        </ToggleButton>
    </Tooltip>
}

export const ClassListSelector = ({ title, label, html, classOptions, onChangeHtml }) => {

    const dispatch = useDispatch();
    const cssClass = getCurrentClassFromListOfClasses(htmlStringToElement(html), classOptions.map((co) => co.class))
        ?? classOptions[0].class;
    const setCssClass = value => {
        if (value) {
            const el = htmlStringToElement(html);
            const elNew = addClassAndRemoveClasses(el, value, classOptions.map((co) => co.class))
            onChangeHtml(elNew.outerHTML)
            dispatch({ type: DESIGNER_PUSH_HISTORY })
        }
    }

    return <Box style={{ paddingTop: "3px" }}>
        {title} <TextField
            labelId="select-class"
            id="select-class"
            value={cssClass ?? ""}
            variant='outlined'
            fullWidth
            select
            size="small"
            label={label}
            onChange={(e) => setCssClass(e.target.value)}
        >
            {classOptions.map((option, index) => {
                return <MenuItem key={index} value={option.class}>{option.label}</MenuItem>
            })}
        </TextField>
    </Box>
}
