import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import LightningDeviceLayout from './LightningDeviceLayout';

export const LightningDeviceLayoutSelector = ({ device, value, setValue }) => {
    const deviceLayouts = useSelector((state) => state.lightning.deviceLayouts);
    const aspectRatio = device && device.width && device.height ? device.width / device.height : 16 / 9;

    return <Box>
        <LCTypography variant="body1">
            Change the screen layout for this device
        </LCTypography>
        <Box mb={2} />

        <Grid container spacing={1}>
            {deviceLayouts.map(x => {
                return <Grid item xs={6} key={x.lcuid}>
                    <LightningDeviceLayout
                        deviceLayout={x}
                        selected={value === x.lcuid}
                        aspectRatio={aspectRatio}
                        onDeviceLayoutSelected={() => setValue(x?.lcuid)} />
                </Grid>
            })}
        </Grid>
    </Box>
}
