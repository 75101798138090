import React, { useCallback, useEffect } from 'react';
import { reduxForm, getFormValues, initialize, reset } from 'redux-form';
import { DialogContent, DialogActions, Button, useTheme, useMediaQuery, Box } from '@material-ui/core';

import { Dialog, ButtonLoader } from '../../components/material';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import RolesField from './RolesField';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserRoles } from '../../actions/users';
import { showSuccess } from '../../actions/snackbar';

const EditRolesDialog = (props) => {
    const { lcuid, user, handleSubmit, submitting, roles, rolesDictionary, open, title, handleClose, dialogProps } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const values = useSelector(state => getFormValues(EDIT_ROLES_FORM)(state));

    useEffect(() => {
        if (open) {
            dispatch(initialize(EDIT_ROLES_FORM, { roles }));
        } else {
            dispatch(reset(EDIT_ROLES_FORM));
        }
    }, [open])

    const submit = useCallback(values => {
        return dispatch(updateUserRoles(lcuid, user, values.roles.map(x => x.id)))
            .then(() => dispatch(showSuccess(`${user.name} roles were updated`)))
            .then(handleClose);
    }, [lcuid, user])

    if (!values)
        return null;

    return (
        <Dialog
            open={open}
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            {...dialogProps}
        >
            <form onSubmit={handleSubmit(submit)}>
                <DialogContent>
                    <LCTypography variant="body2">Select an appropriate role for this user</LCTypography>
                    <Box mt={2} />

                    <RolesField
                        roles={rolesDictionary}
                        value={values.roles}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('Cancel')}
                    </Button>
                    <ButtonLoader
                        submitting={submitting}
                        type="submit"
                        color="secondary"
                        variant="contained">
                        {t('Save')}
                    </ButtonLoader>
                </DialogActions>
            </form>
        </Dialog >
    )
}

const EDIT_ROLES_FORM = "EditRolesDialog";
export default reduxForm({
    form: EDIT_ROLES_FORM,
    initialValues: {
        roles: []
    },
})(EditRolesDialog)
