import { SYSTEM_NOTIFICATION_SHOW, SYSTEM_NOTIFICATION_HIDE, SYSTEM_NOTIFICATION_REMOVE } from "../helpers/actionTypes";

const notification = {
    isOpen: false,
    key: '',
    message: '',
    duration: 6000,

    // click on whole notification
    onClick: null,

    action: {
        title: null,
        onClick: null,
        hideClose: false
    }
}

export const initialState = {
    notifications: []
}

export default function systemNotification(state = initialState, action) {
    const { type, key, ...payload } = action;
    switch (type) {
        case SYSTEM_NOTIFICATION_SHOW:
            return Object.assign({},
                state,
                {
                    notifications: [
                        ...state.notifications,
                        Object.assign({},
                            notification,
                            {
                                key,
                                isOpen: true,
                                ...payload
                            })
                    ]
                });
        case SYSTEM_NOTIFICATION_HIDE:
            return Object.assign({},
                state,
                {
                    notifications: state.notifications.map(n => {
                        if (n.key === key) {
                            n.isOpen = false;
                        }

                        return n;
                    })
                });
        case SYSTEM_NOTIFICATION_REMOVE:
            return Object.assign({},
                state,
                {
                    notifications: state.notifications.filter(n => n.key !== key)
                });
        default:
            return state
    }
}
