import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clx from 'classnames';
import { getUser } from '../../selectors/user';
import { Divider, Grid, Box } from '@material-ui/core';
import { IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import { connect } from 'react-redux';
import NoAccountsNoOperatorsUser from '../../containers/dashboard/NoAccountsNoOperatorsUser'
import { hasAgencies, selectedAccountIds } from '../../selectors/user';
import UserChooseableProfiles from '../../containers/user/UserChooseableProfiles';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../components/material';
import { hasIcon } from '../../selectors/account';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => ({
    homeGridContainer: {
        height: '100%',
        margin: 'auto',
        maxWidth: 900
    },
    content: {
        padding: 0,
        marginTop: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    userHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    userHeaderTitleAvatar: {
        marginRight: '6px'
    },
    avatar: {
        backgroundColor: 'transparent'
    },
    avatarNoIcon: {
        backgroundColor: theme.palette.secondary.main
    },
}))

const DashboardOperatorMy = ({ user, accountIds }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid
                container
                className={classes.homeGridContainer}
            >
                <Grid item xs={12} md={12}>
                    <IonCardContent>
                        <IonCardSubtitle>{t('Welcome')}</IonCardSubtitle>
                        <IonCardTitle className={classes.userHeaderTitle}>
                            <Avatar
                                src={hasIcon(user) && user.options.primary_image_public_url}
                                name={user.name}
                                className={clx(classes.userHeaderTitleAvatar, { [classes.avatarNoIcon]: !hasIcon(user) })} />
                            {t(user.name)}
                        </IonCardTitle>
                    </IonCardContent>

                    <Divider />

                    {!accountIds.length && !hasAgencies(user)
                        && <NoAccountsNoOperatorsUser />}
                </Grid>

                {
                    (accountIds.length || hasAgencies(user)) &&
                    <>
                        <Box ml={3} mt={3} mb={2}>
                            <LCTypography variant="body1">
                                {accountIds.length === 1 ? <>
                                    To get started, click on your account profile below
                                </> : <>
                                    Select one of your account profiles below to get started
                                </>}

                            </LCTypography>
                        </Box>

                        <Grid item xs={12} md={12}>
                            <UserChooseableProfiles />

                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

const mapStateToProps = state => {
    return {
        accountIds: selectedAccountIds(state),
        user: getUser(state)
    };
}

export default
    connect(
        mapStateToProps
    )(
        DashboardOperatorMy
    );
