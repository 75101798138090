import React from 'react';
import { IconButton } from '@material-ui/core';
import CircularProgressCentered from '../material/CircularProgressCentered';

const IconButtonLoader = ({ forwardedRef, disabled, submitting, children, buttonSize, progressProps, ...rest }) => (
    <IconButton
        ref={forwardedRef}
        {...rest}
        size={buttonSize}
        disabled={submitting || disabled}
    >
        {submitting && <CircularProgressCentered size={buttonSize}  {...progressProps} />}
        {!submitting && children}
    </IconButton>
);

export default React.forwardRef((props, ref) => <IconButtonLoader {...props} forwardedRef={ref} />);
