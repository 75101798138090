import React from 'react';
import { withRouter } from 'react-router';
import { fade, makeStyles } from '@material-ui/core';

import SearchAutocomplete from './SearchAutocomplete';

const useStyles = makeStyles((theme) => ({
    autocompleteRoot: {
        width: 'auto !important',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    search: {
        cursor: 'pointer',
        color: theme.palette.common.white,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    },
    inputInput: {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },

}));

function SearchInput() {
    const classes = useStyles();

    return <SearchAutocomplete
        classes={{
            autocompleteRoot: classes.autocompleteRoot,
            search: classes.search,
            inputInput: classes.inputInput,
            popperRoot: classes.popperRoot,
        }}
        PopperProps={{
            placement: "bottom-end"
        }} />;
}

export default withRouter(SearchInput);