import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Global } from '../../helpers/constants';
import { FileErrors, fileToBase64, validateFile } from '../../helpers/file';

const ImageUploadInput = (props) => {
    const { id, controller, forwardedRef,
        extensions, maxSize, children, onError, onSuccess, minWidth, minHeight, useCamera, ...rest } = props;

    const onChange = files => {
        if (!files.length) {
            return;
        }

        //This hack makes the validation at 1/3rd the required size
        const minFailWidth = (minWidth * Global.minFailWidthHeightReduction).toFixed(0);
        const minFailHeight = (minHeight * Global.minFailWidthHeightReduction).toFixed(0);

        ([...files]).forEach(file => {
            const validationPromise = validateFile(file, { extensions, maxSize, minFailWidth, minFailHeight });

            validationPromise.then(errors => {
                if (!errors.length) {
                    fileToBase64(file, { compressImage: true })
                        .then(fileBase64 => onSuccess({
                            file,
                            fileBase64
                        }))
                        .catch(error => {
                            console.error(error);

                            onError([{
                                type: FileErrors.invalidFile,
                                message: `File '${file.name}' is invalid image. Please upload image`
                            }])
                        });
                } else {
                    onError(errors);
                }
            })
        })
    }

    useImperativeHandle(controller, () => ({ onChange }));

    return (
        <>
            <input
                ref={forwardedRef}
                id={id}
                type="file"
                accept="image/*"
                capture={useCamera
                    ? true
                    : undefined}
                onChange={e => {
                    onChange(e.target.files);

                    e.target.value = null;
                }}
                hidden

                {...rest} />

            {children}
        </>
    )
};

ImageUploadInput.propTypes = {
    id: PropTypes.string.isRequired,

    maxSize: PropTypes.number,
    extensions: PropTypes.arrayOf(PropTypes.string),
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,

    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
}

export default React.forwardRef((props, ref) => <ImageUploadInput {...props} forwardedRef={ref} />);
