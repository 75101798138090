import { FormHelperText, Grid, Button, Box, Typography, Avatar } from '@material-ui/core';
import React from 'react';
import { Delete } from '@material-ui/icons';
import LucitFacebookPageAuth from '../../facebook/LucitFacebookPageAuth';
import LCTypography from '../../material/LCTypography';
import { useTranslation } from 'react-i18next';

const FacebookPageTokenFinder = ({ field, onChange, value }) => {

    const hasPageAccountSelected = () => value && value.selected_facebook_page_account && value.selected_facebook_page_account.id;
    const { t } = useTranslation();

    return <>
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <Box mb={4}>
                    <LCTypography variant="h6" gutterBottom>
                        {field.name}
                    </LCTypography>
                    <FormHelperText>{field.description}</FormHelperText>
                </Box>
            </Grid>
            <Grid xs={6} item>
                {hasPageAccountSelected() ?
                    <>
                        <Box display="flex" alignItems="center">
                            <Grid container spacing={2}>
                                <Grid xs={1} item></Grid>
                                <Grid xs={1} item>
                                    {value.selected_facebook_page_account.picture
                                        && value.selected_facebook_page_account.picture.data
                                        && value.selected_facebook_page_account.picture.data.url
                                        && (
                                            <Avatar style={{ marginRight: "5px" }}>
                                                <img
                                                    src={value.selected_facebook_page_account.picture.data.url}
                                                    alt={value.selected_facebook_page_account.name}
                                                />
                                            </Avatar>
                                        )}
                                </Grid>
                                <Grid xs={3} item>
                                    <div>
                                        <Typography variant="body1" gutterBottom>
                                            {value.selected_facebook_page_account.name}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" gutterBottom>
                                            {value.selected_facebook_page_account.id}
                                        </Typography>
                                    </div>

                                </Grid>
                                <Grid xs={7} item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (window.confirm(t('Are you sure you want to remove this Facebook page?')))
                                                onChange({});
                                        }}>
                                        <Delete />{t('Remove Facebook Page')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                    :
                    <>

                        <Box display="flex" alignItems="center">
                            <LucitFacebookPageAuth
                                handleFBData={onChange}
                            />
                        </Box>
                    </>
                }
            </Grid>
        </Grid>
    </>
}

export default FacebookPageTokenFinder;
