import React, { useCallback, useRef } from 'react';
import AccordionMaterial from '@material-ui/core/Accordion';

const AccordionScrollable = ({ disableScrollIntoView, expanded, children, ...rest }) => {
    const panelRef = useRef();

    const scrollIntoView = useCallback(() => {
        if (disableScrollIntoView) {
            return;
        }

        if (panelRef.current) {
            panelRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [disableScrollIntoView])

    return (
        <AccordionMaterial
            ref={panelRef}
            expanded={expanded}
            TransitionProps={{
                onEntered: scrollIntoView,
                onExited: scrollIntoView
            }}
            {...rest}
        >
            {children}
        </AccordionMaterial>
    );
};

export default AccordionScrollable;
