import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { IonButton } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import { Dialog, ButtonLoader } from '../../components/material';
import { revertToDraft } from '../../actions/exports';

const RevertToDraftButton = ({ exportItem, revertToDraft, ...rest }) => {
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    return <>
        <IonButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setOpen(true)}
            {...rest}
        >{t('Revert to Draft')}</IonButton>

        <Dialog
            open={open}
            title={t('Revert to Draft')}
        >
            <DialogContent>
                <Box>
                    <LCTypography variant="body1">
                        Put this campaign back into draft status.  This will remove this campaign from the Operators traffic queue
                    </LCTypography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                >
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitting(true);
                        revertToDraft(exportItem.id).then(() => {
                            setSubmitting(false);
                            setOpen(false);
                        })
                    }}
                    submitting={submitting}
                >Revert to Draft</ButtonLoader>
            </DialogActions>
        </Dialog>

    </>

}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        revertToDraft: (exportId) => dispatch(revertToDraft(exportId))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RevertToDraftButton)
    );
