import React from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import ChartIcon from '@material-ui/icons/ShowChart';
import CreativeIcon from '@material-ui/icons/Collections';
import UnknownIcon from '@material-ui/icons/FilterNone';
import Divider from '@material-ui/core/Divider';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { Apps, Dashboard, Payment, Map, People, ThumbsUpDown, Business, DeveloperBoard, FormatListBulleted } from '@material-ui/icons';
import { Button, ListSubheader, makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import { ExportIcon, LucitXrIcon, Tooltip } from '../../components';
import { useTranslation } from 'react-i18next';

import { getUser, isLoggedIn, profiles, selectedProfile } from '../../selectors/user';
import { RoutePaths, BillboardIcon, MenuLinkSection, ProfileType } from '../../helpers/constants';
import { getMenuLinks } from '../../selectors/layout';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount'
import QuickAddProof from '../proofs/QuickAddProof'
import { hasAgencies } from '../../selectors/user';
import { hasApp } from '../../selectors/objects';

const useStyles = makeStyles(() => ({
    addProofButton: {
        color: '#02c9c9'
    },
    addProofIcon: {
        color: '#02c9c9'
    }
}));

export const LinkIconMap = {
    "": <UnknownIcon />,
    [RoutePaths.feed]: <HomeIcon />,
    [RoutePaths.home]: <Dashboard />,
    [RoutePaths.map]: <Map />,

    [RoutePaths.campaigns]: <ExportIcon />,
    [RoutePaths.agencyCampaigns]: <ExportIcon />,
    [RoutePaths.analytics]: <ChartIcon />,
    [RoutePaths.settings]: <SettingsIcon />,
    [RoutePaths.agencySettings]: <SettingsIcon />,
    [RoutePaths.accountSettings]: <SettingsIcon />,
    [RoutePaths.accountChildren]: <FormatListBulleted />,
    [RoutePaths.accountBilling]: <Payment />,
    [RoutePaths.agencyBilling]: <Payment />,
    [RoutePaths.help]: <HelpIcon />,

    [RoutePaths.agencyAccounts]: <Business />,
    [RoutePaths.approvals]: <ThumbsUpDown />,
    [RoutePaths.agencyScreens]: <BillboardIcon />,
    [RoutePaths.agencyUsers]: <People />,
    [RoutePaths.agencyFeed]: <RecentActorsIcon />,
    [RoutePaths.accountUsers]: <People />,
    [RoutePaths.designer]: <CreativeIcon />,
    [RoutePaths.appsAndData]: <Apps />,

    [RoutePaths.dev.applications]: <Apps />,
    [RoutePaths.dev.designerElements]: <DeveloperBoard />,
};

const OperatorMenuTools = ({ selectedProfile, drawerDesktopOpen, closeDrawer }) => {
    const { t } = useTranslation();
    const [showAddNewAccount, setShowAddNewAccount] = React.useState(false);
    const [showQuickAddProof, setShowQuickAddProof] = React.useState(false);
    const classes = useStyles();
    return (
        <List
            component="nav"
            subheader={<ListSubheader component="div" id="nested-list-subheader"
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    visibility: drawerDesktopOpen ? 'visible' : 'hidden'
                }}>
                Tools and More
            </ListSubheader>}>

            <Button style={{ display: "none" }} fullWidth variant="contained"
                color="primary" onClick={() => setShowAddNewAccount(true)}>Add Account
            </Button>

            {selectedProfile.type !== ProfileType.user
                && <ListItem
                    button
                    onClick={() => setShowQuickAddProof(true)}
                    className={classes.addProofButton}
                >
                    <ListItemIcon>
                        <AddAPhotoRoundedIcon className={classes.addProofIcon} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ noWrap: true }}>{t('Add Proof')}</ListItemText>
                </ListItem>}

            {selectedProfile.type !== ProfileType.account
                && <ListItem
                    button
                    component={RouterLink}
                    to={RoutePaths.public.xr}
                    className={classes.addProofButton}
                >
                    <ListItemIcon>
                        <LucitXrIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <ListItemText primaryTypographyProps={{ noWrap: true }}>{t('Try Lucit XR')}</ListItemText>
                    </ListItemText>
                </ListItem>}

            <OperatorAddAccount showAgencyAndOperator={false} open={showAddNewAccount} handleClose={() => setShowAddNewAccount(false)} />

            {selectedProfile.type !== ProfileType.user
                && <QuickAddProof
                    open={showQuickAddProof}
                    handleClose={() => {
                        setShowQuickAddProof(false)
                        closeDrawer && closeDrawer()
                    }
                    } />}
        </List>
    );
}

function LeftSideMenu(props) {
    const { user, links, drawerDesktopOpen, closeDrawer, history, hasAgencies } = props;
    const { selectedProfile } = props;

    return (
        <div>
            <List disablePadding>

                {links
                    .filter(l => l.section === MenuLinkSection.general || l.section === user.profileType.id)
                    .map((item, i) => (
                        <MenuLink
                            key={i}
                            item={item}
                            closeDrawer={closeDrawer}
                            drawerDesktopOpen={drawerDesktopOpen}
                            history={history} />
                    ))}
            </List>

            <Divider />

            {hasAgencies && <OperatorMenuTools
                selectedProfile={selectedProfile}
                drawerDesktopOpen={drawerDesktopOpen}
                closeDrawer={closeDrawer}
            />}

        </div>
    );
}

const MenuLink = (props) => {
    const { history, item, closeDrawer } = props;

    return <ListItem
        button
        component={RouterLink}
        to={item.route}
        onClick={closeDrawer}
        selected={item.route === history.location.pathname}>
        <Tooltip title={item.title} hidden={props.drawerDesktopOpen}>
            <MenuLinkIcon item={item} />
        </Tooltip>
        <ListItemText primary={item.title} primaryTypographyProps={{ noWrap: true }} />
    </ListItem>;
}

const MenuLinkIcon = ({ item }) => {
    const profile = useSelector(selectedProfile);
    const application = useSelector(state => hasApp(state, profile.lcuid, item.appLcuid));

    if (application?.options?.primary_image_public_url) {
        return <ListItemIcon>
            <img
                style={{ opacity: 0.5 }}
                width={24}
                height={24}
                src={application?.options?.primary_image_public_url} />
        </ListItemIcon>
    }

    return <ListItemIcon>
        {LinkIconMap[item.route] || LinkIconMap[""]}
    </ListItemIcon>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
        user: getUser(state),
        profiles: profiles(state),

        links: getMenuLinks(state),
        hasAgencies: hasAgencies(getUser(state)),
        selectedProfile: selectedProfile(state),
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(LeftSideMenu)
);
