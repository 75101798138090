import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, ClickAwayListener, ListSubheader, List, ListItem, makeStyles, Paper, Popper, InputAdornment } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import MaskedInput from './MaskedInput';
import { useRef } from 'react';
import { Countries, DefaultCountry } from '../../helpers/countries';
import { Search } from '@material-ui/icons';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => {
    return {
        countryCodeBox: {
            display: "flex",
            alignItems: 'center',
            marginRight: theme.spacing(1),
            cursor: 'pointer'
        },

        countriesPopper: {
            zIndex: theme.zIndex.modal
        },
        countriesRoot: {
            marginTop: theme.spacing(1),
        },

        contriesList: {
            maxHeight: 300,
            overflow: 'auto'
        },

        countriesListSubheader: {
            background: theme.palette.common.white
        },

        countryOption: {
            '& > img': {
                marginRight: 16,
                flexShrink: 0,
            },
        },
    }
})
const PhoneInput = ({ input, label = "Mobile Phone", meta: { touched, error } = {}, ...rest }) => {
    const classes = useStyles();

    const value = input.value || { countryCode: DefaultCountry.code, phoneCode: DefaultCountry.phone, phoneNumber: '' };
    const onChange = input.onChange;

    const country = Countries.find(x => x.code === value.countryCode || '');

    const rootRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <TextField
                innerRef={rootRef}
                name="phone"
                label={label}
                type="tel"
                variant="outlined"
                margin="normal"
                autoComplete='tel'
                fullWidth
                error={touched && !!error}
                helperText={touched && error}

                {...rest}

                value={value.phoneNumber}
                onChange={e => onChange({ ...value, phoneNumber: e.target.value })}

                InputProps={{
                    startAdornment:
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <Box className={classes.countryCodeBox}
                                onClick={() => {
                                    if (rest.disabled) {
                                        return;
                                    }

                                    setAnchorEl(rootRef.current);
                                }}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    style={{ marginRight: 8 }}
                                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                <LCTypography transProps={{ phone: country.phone }} noWrap>
                                    +{{ phone: country.phone }}
                                </LCTypography>

                                <CountriesPopper
                                    value={value}
                                    onChange={(country) => onChange({
                                        ...value,
                                        countryCode: country.code,
                                        phoneCode: country.phone
                                    })}

                                    anchorEl={anchorEl}
                                    handleClose={() => setAnchorEl(null)} />
                            </Box>
                        </ClickAwayListener>,
                    placeholder: "701-555-1234",

                    inputComponent: MaskedInput,
                    inputProps: {
                        mask: [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
                        "aria-autocomplete": "both",
                        "aria-haspopup": "false",
                        "autoComplete": "off",
                        "spellCheck": "false"
                    }
                }}
            />
        </>
    );
};

const CountriesPopper = ({ anchorEl, value, onChange, handleClose }) => {
    const classes = useStyles();

    const [search, setSearch] = useState('');

    useEffect(() => {
        setSearch('');
    }, [anchorEl])

    const renderListItem = (option) => {
        return <ListItem
            key={option.code}
            button
            selected={option.code === value.countryCode}
            onClick={(e) => {
                e.stopPropagation();

                onChange(option);
                handleClose();
            }}
            className={classes.countryOption}>
            <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
            />
            {option.label} (+{option.phone})
        </ListItem>
    }

    const countriesFiltered = Countries
        .filter(x => !search
            || x.label.toLowerCase().indexOf(search.toLowerCase()) > -1
            || x.phone.toLowerCase().indexOf(search.toLowerCase()) > -1);

    return <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className={classes.countriesPopper}
        placement='bottom'>
        <Paper className={classes.countriesRoot}
            style={{
                width: anchorEl
                    ? anchorEl.clientWidth
                    : 0
            }}>

            <TextField
                style={{ padding: '16px 16px 8px 16px' }}
                size="small"
                variant="outlined"
                fullWidth
                autoFocus

                value={search}
                onChange={e => setSearch(e.target.value)}

                InputProps={{
                    startAdornment: <InputAdornment>
                        <Search />
                    </InputAdornment>,
                    placeholder: "Type to search"
                }}
            />

            <List disablePadding className={classes.contriesList}>
                {!search
                    && <>
                        <ListSubheader className={classes.countriesListSubheader}>{`Popular`}</ListSubheader>
                        {Countries.filter(x => x.suggested)
                            .map(option => renderListItem(option))}
                    </>}

                {countriesFiltered.length === 0
                    && <Box p={2}>
                        <LCTypography variant="body2">No Countries found...</LCTypography>
                    </Box>}

                {countriesFiltered.length > 0
                    && <>
                        <ListSubheader className={classes.countriesListSubheader}>{`Countries`}</ListSubheader>
                        {countriesFiltered.map(option => renderListItem(option))}
                    </>}
            </List>
        </Paper>
    </Popper>
}

export const getFullPhone = phone => {

    if (!phone)
        return "";

    if (!phone.phoneCode)
        return phone;

    if (!phone.phoneNumber)
        return phone;

    return `${phone.phoneCode.replace(/-/g, "")}${phone.phoneNumber.replace(/-/g, "")}`;
}

export default PhoneInput;
