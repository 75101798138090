import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedProfile, userAccounts } from '../../selectors/user';
import { ProfileType } from '../../helpers/constants';
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import AccountSelectField from './AccountSelectField';
import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AccountOptions = {
    current: 'current',
    another: 'another',
    new: 'new'
};

export const AccountSelectOptions = ({ onAccountSelected }) => {
    const { t } = useTranslation();
    const profile = useSelector(selectedProfile);
    const accounts = useSelector(userAccounts)
        .filter(x => profile.type == ProfileType.agency
            ? x.agency_id == profile.id
            : true);

    const [account, setAccount] = useState(null);
    const [accountName, setAccountName] = useState(t('New Account'));
    const [option, setOption] = useState(null);

    const selectAccount = x => {
        setAccount(x);

        profile.type == ProfileType.agency
            ? onAccountSelected({ ...x, agency_id: profile.id })
            : onAccountSelected(x);
    };

    useEffect(() => {
        const isUserOrAgency = (profile.type === ProfileType.user
            || profile.type === ProfileType.agency);

        if (profile.type === ProfileType.account) {
            setOption(AccountOptions.current);
        } else if (isUserOrAgency && accounts.length > 0) {
            setOption(AccountOptions.another);
        } else {
            setOption(AccountOptions.new)
        }
    }, [profile.type]);

    useEffect(() => {
        if (option == AccountOptions.current) {
            selectAccount(profile);
        }

        if (option == AccountOptions.another) {
            selectAccount(accounts[0]);
        }

        if (option == AccountOptions.new) {
            selectAccount({ name: accountName, new: true })
        }
    }, [option])

    return <>
        <IonList>
            <IonRadioGroup
                value={option}
                onIonChange={e => setOption(e.target.value)}
            >
                {profile.type == ProfileType.account
                    && <IonItem>
                        <IonLabel class="ion-text-wrap">
                            <h2>{t('Current Account')}</h2>
                            <p>{t('Add to : ')}<b>{profile.name}</b></p>
                        </IonLabel>
                        <IonRadio slot="end" value={AccountOptions.current}></IonRadio>
                    </IonItem>}

                {accounts.length > 0
                    && <IonItem>
                        <IonLabel class="ion-text-wrap">
                            {profile.type == ProfileType.account
                                ? <h2>{t('A Different Account')}</h2>
                                : <h2>{t('Existing Account')}</h2>}
                            <p>{t('Select from your accounts')}</p>
                        </IonLabel>
                        <IonRadio slot="end"
                            value={AccountOptions.another}></IonRadio>
                    </IonItem>}

                {option == AccountOptions.another
                    && <IonItem>
                        <AccountSelectField
                            agencyId={profile.type == ProfileType.agency
                                ? profile.id
                                : null}
                            value={account}
                            onChange={a => selectAccount(a)}
                        />
                    </IonItem>}

                <IonItem >
                    <IonLabel class="ion-text-wrap">
                        <h2>{t('Create new Account')}</h2>
                        <p>{t('Generate a new account')}</p>
                    </IonLabel>
                    <IonRadio slot="end" value={AccountOptions.new}></IonRadio>
                </IonItem>
                <Box mt={2} ml={2}>
                    {option == AccountOptions.new
                        && <TextField
                            variant="outlined"
                            fullWidth={true}
                            required={true}
                            label={t('Account name')}
                            value={accountName}
                            autoFocus
                            onChange={e => {
                                setAccountName(e.target.value);
                                selectAccount({ new: true, name: e.target.value });
                            }}
                        />}
                </Box>
            </IonRadioGroup>
        </IonList>
    </>
}
