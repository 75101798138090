import React, { useCallback, useEffect, useState } from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeclineIcon from '@material-ui/icons/Clear';
import { Box, IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import CircularProgressCentered from '../material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '400px'
        },
        disabled: {
            cursor: 'not-allowed'
        },
        button: {
            marginLeft: theme.spacing(1)
        },
        buttonError: {
            marginBottom: '20px'
        }
    }
})

const TextFieldEditable = (props) => {
    const { classes: classesCustom = {}, value: defaultValue, validators, handleDone, isEditDefault, handleIsEdit,
        ViewComponent, EditComponent = TextField,
        editButtonProps, doneButtonProps, cancelProps, circularProgressProps,
        editIconProps, doneIconProps, cancelIconProps,
        autoHighlightOnFocus,
        isEdit: isEditControlled,
        ...rest } = props;

    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [touched, setIsTouched] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [isEdit, setIsEdit] = useState(isEditDefault);

    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    const settingIsEdit = useCallback((value) => {
        setIsEdit(value);

        //If the parent component wants us to do something when in edit mode, do that
        if (handleIsEdit)
            handleIsEdit(value);
    }, [handleIsEdit]);

    const submit = useCallback(() => {
        setSubmitError(null);
        setSubmitting(true);

        //eslint-disable-next-line
        const promise = handleDone(value) || Promise.resolve();

        promise
            .then(() => {
                settingIsEdit(false);
            })
            .catch(error => {
                if (error.errors) {
                    setSubmitError(error.errors._error);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, [handleDone, settingIsEdit, value])

    const cancel = useCallback(() => {
        setValue(defaultValue);
        settingIsEdit(false);
        setSubmitError(null);
    }, [defaultValue, settingIsEdit])

    const syncError = isEdit
        && touched
        && (validators || [])
            .map(validator => validator(value))
            .find(e => !!e);
    const error = syncError || submitError;
    const inputProps = Object.assign({ className: clx({ [classes.disabled]: !isEdit }) }, props.inputProps);

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (isEditControlled !== undefined) {
            setIsEdit(isEditControlled)
        }
    }, [isEditControlled])

    return (
        <Box className={clx(classes.root, classesCustom.root)}>
            {!isEdit
                && ViewComponent}
            {(isEdit || !ViewComponent)
                && <EditComponent
                    variant={"outlined"}
                    disabled={!isEdit || submitting}
                    value={value}
                    autoFocus
                    onFocus={event => {
                        autoHighlightOnFocus && event.target.select();
                    }}
                    onChange={e => {
                        setValue(e.target.value);

                        !touched && setIsTouched(true)
                    }}
                    helperText={error || ''}
                    error={!!error}
                    inputProps={inputProps}
                    onKeyDown={e => {
                        if (!props.multiline && e.key === 'Enter') {
                            submit()
                        }

                        if (e.key === 'Escape') {
                            cancel()
                        }
                    }}
                    {...rest}
                />}

            {!isEdit
                && <IconButton
                    size={isXs ? 'small' : 'medium'}
                    className={clx(classes.button, { [classes.buttonError]: error })}
                    onClick={() => settingIsEdit(true)}
                    {...editButtonProps}>
                    <EditIcon {...editIconProps} />
                </IconButton>}
            {isEdit
                && <>
                    {submitting && <Box marginLeft={2}>
                        <CircularProgressCentered rootStyle={{ margin: 0 }} {...circularProgressProps} />
                    </Box>}
                    {!submitting &&
                        <>
                            <IconButton
                                size={isXs ? 'small' : 'medium'}
                                disabled={Boolean(syncError)}
                                className={clx(classes.button, { [classes.buttonError]: error })}
                                onClick={submit}
                                {...doneButtonProps}>
                                <DoneIcon {...doneIconProps} />
                            </IconButton>
                            <IconButton
                                size={isXs ? 'small' : 'medium'}
                                className={clx(classes.button, { [classes.buttonError]: error })}
                                onClick={cancel}
                                {...cancelProps}>
                                <DeclineIcon {...cancelIconProps} />
                            </IconButton>
                        </>}
                </>}

        </Box>
    );
}

TextFieldEditable.propTypes = {
    handleDone: PropTypes.func.isRequired
}

export default TextFieldEditable;
