import React from 'react';
import PropTypes from 'prop-types';
import { Chip, useTheme } from '@material-ui/core';
import { InvoicePaymentStatus, InvoiceStatus } from '../../helpers/constants';

function InvoiceStatusBadge(props) {
    const { status, paymentStatus } = props;
    const theme = useTheme();

    if (status === InvoiceStatus.closed) {
        return <Chip
            size="small"
            style={{ background: theme.palette.success.main, color: theme.palette.common.white }}
            label="Paid"
        />;
    }

    if (status === InvoiceStatus.draft) {
        return <Chip
            size="small"
            style={{ background: theme.palette.grey[400] }}
            label="Pending"
        />;
    }

    if (status === InvoiceStatus.void) {
        return <Chip
            size="small"
            style={{ background: theme.palette.text.primary, color: theme.palette.common.white }}
            label="Voided"
        />;
    }

    if (status === InvoiceStatus.overdue) {
        return <Chip
            size="small"
            style={{ background: theme.palette.warning.light, color: theme.palette.common.white }}
            label="Overdue"
        />;
    }

    if (paymentStatus === InvoicePaymentStatus.partial) {
        return <Chip
            size="small"
            style={{ background: theme.palette.secondary.main, color: theme.palette.common.white }}
            label="Partialy Paid"
        />;
    }

    if (status === InvoiceStatus.open) {
        return <Chip
            size="small"
            style={{ background: theme.palette.secondary.main, color: theme.palette.common.white }}
            label="Open"
        />;
    }

    return null;
}

InvoiceStatusBadge.propTypes = {
    status: PropTypes.string.isRequired
}
export default InvoiceStatusBadge;
