import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
const url = require('../../assets/images/Street_View_logo.png').default;

export default function StreetViewIcon(props) {
    const { t } = useTranslation();
    return <Tooltip title={t("Street View available")}>
        <img
            src={url}
            width={32}
            height={32}
            {...props} />
    </Tooltip>
}
