import React, { useEffect, useState } from 'react';
import { primary_image_public_url } from '../../selectors/designer'
import { setThumbFromSelectedFrame, reloadCurrentTemplate, updateTemplate } from '../../actions/designer'
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { Global } from '../../helpers/constants';
import { Refresh } from '@material-ui/icons';

const useStyles = makeStyles(() => {
    return {
        templateImage: {
            width: "110px",
            maxWidth: "100%",
            marginTop: "5px",
            cursor: "pointer",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
        },
        progress: {
            top: "10px",
            left: "50px",
            position: "absolute",
            color: "rgba(0,0,0,0.5)",
            cursor: "pointer",
        },
        refreshIcon: {
            top: "10px",
            left: "50px",
            position: "absolute",
            color: "rgba(0,0,0,0.5)",
            cursor: "pointer",
        }
    }
})

const DesignerTemplatePreviewImage = ({ primary_image_public_url, setThumbFromSelectedFrame, reloadCurrentTemplate }) => {

    const [templateImageUrl, setTemplateImageUrl] = useState(null)
    const [isCheckingItemUpdates, setCheckingItemUpdates] = useState(false);
    const [refreshingImage, setRefreshingImage] = useState(false);
    const [isHovered, setIsHovered] = useState(false)

    const classes = useStyles();

    const { t } = useTranslation();

    const getBestTemplateImageUrl = () => {
        return primary_image_public_url ? primary_image_public_url : '/assets/designer/empty-drive-template.png'
    }

    const generateNewImage = () => {
        setRefreshingImage(true)
        setCheckingItemUpdates(true)
        setThumbFromSelectedFrame()
    }

    const { reset } = useIntervalLimited(() => reloadCurrentTemplate(),
        {
            running: isCheckingItemUpdates,
            milliseconds: Global.itemExportsStatusUpdateInterval
        })

    const shouldKeepCheckingUpdates = () => {
        return refreshingImage
    }

    useEffect(() => {
        if (shouldKeepCheckingUpdates()) {
            reset();
            setCheckingItemUpdates(true);
        } else {
            setRefreshingImage(false);
            setCheckingItemUpdates(false);
        }
    }, [templateImageUrl, reset]);

    useEffect(() => {
        setTemplateImageUrl(primary_image_public_url)
        setRefreshingImage(false)
    }, [primary_image_public_url])

    return <div
        style={{ position: "relative" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={generateNewImage}
    >
        <Tooltip title={t("Refresh Preview Image")}
        >
            <img
                src={getBestTemplateImageUrl()}
                className={classes.templateImage}
            />
        </Tooltip>
        {isHovered && <Refresh className={classes.refreshIcon} />}
        {refreshingImage && <CircularProgress className={classes.progress} style={{ height: "20px", width: "20px" }} />}
    </div>

}

const mapStateToProps = state => {
    return {
        primary_image_public_url: primary_image_public_url(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setThumbFromSelectedFrame: () => {
            dispatch(updateTemplate()).then(() => {
                dispatch(setThumbFromSelectedFrame(false))
            })
        },
        reloadCurrentTemplate: () => dispatch(reloadCurrentTemplate()),
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerTemplatePreviewImage
    );
