import localStorage from "redux-persist/lib/storage";
import { createMigrate } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import {
    USER_SELECT_AGENCY, APPROVALS_TOGGLE_ACTION, APPROVALS_REJECTION_CODES_LOADED, APPROVALS_NEXT_PAGE_REQUEST,
    APPROVALS_SEARCH_REQUEST, APPROVALS_SEARCH_RESULT, APPROVALS_NEXT_PAGE_RESULT, APPROVALS_SAVE_SCROLL_POSITION,
    AGENCY_USER_TRUST_CHANGED, APPROVALS_FORCE_APPROVE_MODERATION_LCUID
} from "../helpers/actionTypes";
import { ApprovalStatus, Global } from "../helpers/constants";

export const initialState = {
    filters: {
        perPage: Global.approvalsPerPage,
        status: ApprovalStatus.pending.id
    },
    searched: false,

    data: [],

    rejectionCodes: [],

    loading: false,
    loadingNextPage: false,

    currentPage: 1,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,
    totalUniqueInventoryItems: 0,

    scrollY: null,

    invalidated: true
}

const migrations = {
    // set default value for Status filter
    0: (state) => ({
        ...state,
        filters: {
            ...state.filters,
            status: ApprovalStatus.pending.id
        }
    }),
    // reset perPage to 20
    1: (state) => ({
        ...state,
        filters: {
            ...state.filters,
            perPage: Global.approvalsPerPage,
        }
    })
}

export const persistConfig = {
    key: 'approvals',
    whitelist: ['filters'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations),
    version: 1
}

export default function approvals(state = initialState, { type, ...payload }) {
    switch (type) {
        case USER_SELECT_AGENCY:
            return Object.assign({},
                state,
                {
                    filters: initialState.filters,
                    data: [],
                    invalidated: true
                });

        case AGENCY_USER_TRUST_CHANGED:
            return Object.assign({}, state, {
                data: state.data.map(x => {

                    return {
                        ...x,
                        approvals: x.approvals.map(a => {

                            return {
                                ...a,
                                object_moderation_ownership: a.object_moderation_ownership ? a.object_moderation_ownership.map(b => {

                                    if (b.id === payload.userId) {
                                        return {
                                            ...b,
                                            is_trusted: payload.trusted
                                        }
                                    }

                                    return b
                                }) : null
                            }
                        })
                    }
                }
                )
            })

        case APPROVALS_FORCE_APPROVE_MODERATION_LCUID:
            return Object.assign({}, state, {
                data: state.data.map(x => {

                    return {
                        ...x,
                        approvals: x.approvals.map(a => {

                            return {
                                ...a,
                                object_moderation_data: a.object_moderation_data ? a.object_moderation_data.map(b => {

                                    if (b.moderation && b.moderation.lcuid === payload.lcuid) {
                                        return {
                                            ...b,
                                            moderation: {
                                                ...b.moderation,
                                                moderation_score: 1
                                            }
                                        }
                                    }

                                    return b
                                }) : null
                            }
                        })
                    }
                }
                )
            })

        case APPROVALS_SEARCH_REQUEST:
            return Object.assign({}, state,
                {
                    ...payload,
                    loading: true
                });

        case APPROVALS_REJECTION_CODES_LOADED:
            return Object.assign({}, state, {
                rejectionCodes: payload.codes
            });

        case APPROVALS_SEARCH_RESULT:
            return Object.assign({},
                state,
                payload,
                {
                    searched: true,
                    scrollY: null,
                    invalidated: false,
                    loading: false,
                });
        case APPROVALS_NEXT_PAGE_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingNextPage: true
                });
        case APPROVALS_NEXT_PAGE_RESULT: {
            const { data = [], ...rest } = payload;

            return Object.assign({},
                state,
                rest,
                {
                    from: state.from,
                    loading: false,
                    loadingNextPage: false,
                    data: state.data.concat(data)
                });
        }

        case APPROVALS_SAVE_SCROLL_POSITION:
            return Object.assign({}, state, {
                scrollY: payload.scrollY
            })

        case APPROVALS_TOGGLE_ACTION:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.inventoryItem.id === payload.inventoryItem.id) {
                        return {
                            ...x,
                            approvals: x.approvals.map(a => ({
                                ...a,
                                action: payload.action
                            }))
                        }
                    }

                    return x;
                })
            })

        default:
            return state
    }
}
