import { controller } from "../api";

/**
 * Interceptor to attach cancellation signal to request
 * @export
 * @param {*} instance
 */
export function useCancellationInterceptor(instance) {
    instance.interceptors.request.use(x => {
        if (x._cancelOnPageChange) {
            x.signal = controller.signal
        }

        return x;
    })
}
