import React, { useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import {
    loadLightningDeviceHeartbeatStats,
    loadLightningScreenStats
} from '../../actions/lightning';
import { useDispatch } from 'react-redux';
import { useColumns } from './ScreensColumns'
import { lucitApi } from '../../services/lucitApi';
import useInterval from '../../helpers/hooks/useInterval';
import { selectDigitalBoards } from '../../selectors/lightning';
import { groupBy } from '../../helpers/array';

const Screens = () => {
    const devicesLoaded = useSelector(state => state.lightning.dataStatus.devices);
    const digitalBoardsLoaded = useSelector(state => state.lightning.dataStatus.digitalBoards);
    const agencyExportsLoaded = useSelector(state => state.lightning.dataStatus.agencyExports);

    const digitalBoards = useSelector(selectDigitalBoards);
    const digitalBoardsByDeviceId = groupBy(digitalBoards, x => x.lightning_device_id);
    const devices = useSelector(state => state.lightning.devices);
    const rows = Object.keys(digitalBoardsByDeviceId)
        .map(deviceId => {
            if (digitalBoardsByDeviceId[deviceId].length > 1) {
                return [
                    ...devices
                        .filter(d => d.id == deviceId)
                        .map(x => ({
                            ...x,
                            screens: digitalBoardsByDeviceId[deviceId],
                            isGroupingRow: true
                        })),
                    ...digitalBoardsByDeviceId[deviceId].map(x => ({ ...x, isChildRow: true }))
                ];
            }

            return digitalBoardsByDeviceId[deviceId];
        })
        .flat();

    const dispatch = useDispatch();
    const columns = useColumns().map((column) => {
        return { ...column }
    });

    const loadStats = useCallback(() => {
        if (digitalBoards.length > 0) {
            lucitApi.analytics.getSummaryDigitalBoardLastPlay(digitalBoards.map(x => x.id))
                .then(data => dispatch(loadLightningScreenStats(data)))

            lucitApi.analytics.getSummaryLightningLastHeartbeat(digitalBoards.map(x => x.lightning_device_id))
                .then(data => dispatch(loadLightningDeviceHeartbeatStats(data)))
        }
    }, [digitalBoards]);

    useEffect(() => loadStats(), [digitalBoards.length]);
    useInterval(loadStats, 30_000); // 30 sec

    return <Box>
        <MaterialTable
            title={''}
            columns={columns}
            data={rows}
            parentChildData={(row, rows) => rows.find(a => a.id === row.lightning_device_id)}
            isLoading={!digitalBoardsLoaded || !agencyExportsLoaded || !devicesLoaded}
            components={{
                OverlayLoading: () => (
                    <div>
                        <CircularProgressCentered />
                    </div>
                )
            }}
            options={{
                defaultExpanded: true,
                emptyRowsWhenPaging: true,
                pageSize: 20,
                pageSizeOptions: [20, 100, 250],
                debounceInterval: 100,
                headerStyle: { padding: '16px 12px' },
                paging: digitalBoards && digitalBoards.length > 20,
                search: true,
                showTitle: true,
                sorting: true,
                selection: false,
                toolbar: true,
                draggable: false,
                tableLayout: 'auto',
                rowStyle: (rowData) => {

                    if (rowData.isGroupingRow) {
                        return { backgroundColor: '#f5f5f5' }
                    }
                    return {}
                }
            }}
        />
    </Box>
}

export default Screens;
