import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { IonCard, IonCardContent, IonRow, IonGrid, IonButton, IonIcon } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { informationCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    signBirdTipCard: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    signBirdText: {
        color: '#ff6900',
    },
    signBirdTipImage: {
        [theme.breakpoints.down('sm')]: {
            float: 'left',
            top: '-105px',
            position: 'absolute',
            maxHeight: '100%',
        },
        maxHeight: '50px',
    },
    signBirdPromoImage: {
        width: '100%',
        maxWidth: '400px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    }
}));

export const SignBirdPromoCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <IonCard className={classes.signBirdTipCard}>
                <img className={classes.signBirdPromoImage} src="../assets/images/signBird_cover.png" />
                <Box>
                    <IonCardContent>

                        <img className={classes.signBirdTipImage} src="../assets/images/cropped-SignBird-Logo.png" />
                        <Box>
                            <LCTypography>
                                Enhance your billboard photos with <strong
                                    className={classes.signBirdText}>SignBird</strong> for the perfect pitch to advertisers.
                                With <strong className={classes.signBirdText}>SignBird </strong> photo services,
                                you&apos;ll have access to high quality billboard photos for your inventory.
                                Provide one-of-a-kind photos of all your screens to sell ad space to your next advertiser!
                            </LCTypography>
                        </Box>

                        <IonGrid>
                            <IonRow>
                                <IonButton
                                    className={classes.ctaButton}
                                    color="secondary"
                                    href="https://signbird.io/"
                                    target="_blank"
                                >
                                    <IonIcon slot="start" icon={informationCircle}></IonIcon>
                                    {t('Upgrade Photos Here')}
                                </IonButton>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </Box>
            </IonCard>
        </>
    )
}
