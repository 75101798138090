import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DateRanges from './DateRanges';
import TimeRanges from './TimeRanges';
import DaysOfWeek from './DaysOfWeek';
import { useSelector } from 'react-redux';
import { selectedProfileIsAccount } from '../../selectors/user';
import LCTypography from '../../components/material/LCTypography';

const ScheduleType = {
    DateRange: { id: 'date_range', title: "Date Range", description: 'Set start and stop dates' },
    DaysOfWeek: { id: 'days_of_the_week', title: "Days Of Week", description: 'Select specific days of each week' },
    TimeRange: { id: 'time_range', title: "Time Range", description: 'Limit the times of the day' }
}
const ScheduleTypes = [ScheduleType.DateRange, ScheduleType.DaysOfWeek, ScheduleType.TimeRange];

function ScheduleBuilder({ schedules, setSchedules }) {
    const [activeType, setActiveType] = useState(null);
    const { t } = useTranslation();
    const isAccountProfile = useSelector(selectedProfileIsAccount);

    useEffect(() => {
        setActiveType(
            ScheduleTypes.find(
                t => schedules.some(s => s.type === t.id)
            ) ?? ScheduleType.DateRange
        )
    }, [])

    return (
        <>
            {isAccountProfile
                && <Box mb={2}>
                    <LCTypography variant="body2">
                        Use these settings to control when this item will start and stop based on dates and times.
                    </LCTypography>
                </Box>
            }

            <Box mb={2}>
                <hr />
            </Box>

            <ButtonGroup color="primary">
                {Object.values(ScheduleType)
                    .map(s => <Button
                        key={s.id}
                        onClick={() => setActiveType(s)}
                        variant={activeType === s
                            ? 'contained'
                            : 'outlined'}
                    >
                        {t(s.title)}
                    </Button>)}
            </ButtonGroup>

            <Box mt={3} mb={2}>
                <Typography color="textSecondary">{activeType?.description}</Typography>
            </Box>

            <Box mt={2}>
                {activeType === ScheduleType.DateRange
                    && <DateRanges
                        schedules={schedules}
                        setSchedules={setSchedules}
                    />}
                {activeType === ScheduleType.TimeRange
                    && <TimeRanges
                        schedules={schedules}
                        setSchedules={setSchedules}
                    />}
                {activeType === ScheduleType.DaysOfWeek
                    && <DaysOfWeek
                        schedules={schedules}
                        setSchedules={setSchedules}
                    />}
            </Box>
        </>
    );
}

export default ScheduleBuilder;
