import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import DateTimeRelative from '../../components/DateTimeRelative';
import { minutesAgo, toLocalDateTime } from '../../helpers/date';
import { BarChart } from '@material-ui/icons';

const LastPlayIndicator = ({ play_datetime, format }) => {
    const theme = useTheme();

    const Title = <>
        {play_datetime
            ? <>Last played <DateTimeRelative date={toLocalDateTime(play_datetime)} format={format} /></>
            : "No plays yet for this screen"}

    </>

    const getColor = () => {
        if (minutesAgo(play_datetime) <= 60)
            return theme.palette.success.main

        if (minutesAgo(play_datetime) <= 1440)
            return theme.palette.warning.main

        return theme.palette.error.main
    }

    return (<Tooltip title={Title}>
        <BarChart fontSize="small" style={{ color: getColor() }} />
    </Tooltip>
    )
}

export default LastPlayIndicator;
