import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { store } from '../../reducers';
import { getUser } from '../../selectors/user';
import { environment } from '../../helpers/environment';
import ReactPixel from 'react-facebook-pixel';


const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {

    if (!environment.GA_ID)
      return;

    const user = getUser(store.getState());

    ReactGA.initialize(environment.GA_ID, {
      gaOptions: {
        user_id: user ? user.id : null,
      },
      gtagOptions: {
        user_id: user ? user.id : null,
        lc_user_id: user ? user.id : null,
        lc_app_version: environment.VERSION
      }
    });

    ReactGA.send("pageview");

  }, [location, environment.REACT_GA_ID]);

  useEffect(() => {

    if (!environment.FB_META_PIXEL_ID)
      return;

    //https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const advancedMatching = {}; // optional, more info:

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    ReactPixel.init(environment.FB_META_PIXEL_ID, advancedMatching, options);

    ReactPixel.pageView(); // For tracking page view

  }, [location, environment.FB_META_PIXEL_ID]);

}

export default usePageTracking;
