import { Box, Button, FormHelperText, makeStyles, Link } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import TokenInfoDialog from './TokenInfoDialog';
import ButtonLoader from '../../components/material/ButtonLoader';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createBotUser } from '../../actions/applications';
import { IonList, IonRadio, IonRadioGroup, IonItem, IonLabel } from '@ionic/react';
import { UserTokenTypes } from '../../helpers/constants';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const NewTokenDialog = (props) => {
    const { application, open, handleClose, createBotUser, defaultName } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [name, setName] = useState(defaultName);
    const [token, setToken] = useState(null);
    const [userTokenType, setUserTokenType] = useState(UserTokenTypes.public);

    const [loading, setLoading] = useState(false);
    const [openSecret, setOpenSecret] = useState(false);

    useEffect(() => {
        setName(defaultName);
    }, [defaultName])

    return (<>
        <GenericDialog
            title={t('Create a New Token')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box mt={2}>

                <Box mb={4}>
                    <LCTypography>
                        Create a new token <Link
                            href="https://www.lucit.cc/post/creating-lucit-application-tokens"
                            target="_blank"
                            color="secondary">
                            Help me choose the right token type.
                        </Link>
                    </LCTypography>
                </Box>

                <TextField
                    variant="outlined"
                    fullWidth={true}
                    required={true}
                    label={t('Name your Token')}
                    defaultValue={defaultName}
                    onChange={e => setName(e.target.value)}
                />

                {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}

                <Box mt={4}>
                    <IonList>
                        <IonRadioGroup
                            value={userTokenType}
                            onIonChange={(e) => setUserTokenType(e.target.value)}
                        >
                            <IonItem>
                                <IonLabel class="ion-text-wrap">
                                    <LCTypography variant="h2">Public Token</LCTypography>
                                    <LCTypography>Public token for Lucit Widgets and Legacy Player Applications
                                    </LCTypography>
                                </IonLabel>
                                <IonRadio
                                    slot="end"
                                    value={UserTokenTypes.public}></IonRadio>
                            </IonItem>

                            <IonItem>
                                <IonLabel class="ion-text-wrap">
                                    <LCTypography variant="h2">Authentication Token</LCTypography>
                                    <LCTypography>
                                        Token and private secret for applications that need to authenticate with the Lucit API.
                                    </LCTypography>
                                </IonLabel>
                                <IonRadio
                                    slot="end"
                                    value={UserTokenTypes.auth}></IonRadio>
                            </IonItem>

                        </IonRadioGroup>

                    </IonList>
                </Box>
            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader
                    disabled={!name}
                    submitting={loading}
                    onClick={() => {
                        setLoading(true);
                        createBotUser(application, name, userTokenType)
                            .then(user => {
                                setToken(user);
                                handleClose();
                                setOpenSecret(true);
                            })
                            .catch(() => setError('Unexpected error happened, please try again later'))
                            .finally(() => setLoading(false))
                    }}
                    color="primary"
                    variant="contained">
                    {t('Create')}
                </ButtonLoader>
            </>}
        />

        <TokenInfoDialog
            open={openSecret}
            handleClose={() => setOpenSecret(false)}
            token={token && token.api_token}
            secret={token && token.secret} />
    </>)
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createBotUser: (application, name, userTokenType) => dispatch(createBotUser(application, name, userTokenType))
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewTokenDialog)
)
