import React from 'react';
import { Redirect, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

const HashToBrowserRoutes = () => {
    return <BrowserRouter>
        {/* Redirect everything to browser routes */}
        <Route path={"*"}
            render={({ location }) => {
                const hasHash = location.hash && location.hash.length > 1;
                const hasPathname = location.pathname && location.pathname.length > 1;

                return hasHash && !hasPathname
                    ? <Redirect to={{ pathname: location.hash.substring(1), search: location.search }} />
                    : null
            }} />
    </BrowserRouter>;
};

export default HashToBrowserRoutes;