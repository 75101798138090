import React, { useState, useEffect } from 'react';
import { lucitApi } from '../../services/lucitApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const VenueTypeDropDown = ({ initialVenueId, setVenueId }) => {
    const [venueTypes, setVenueTypes] = useState([]);
    const [selectedVenueType, setSelectedVenueType] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        lucitApi.billboards.getVenueTypes()
            .then(response => {
                setVenueTypes(response.map(venueType => ({
                    label: venueType.fully_qualified_name,
                    id: venueType.enumeration_id
                })))

                if (initialVenueId) {
                    const venueType = response.find(venueType => parseInt(venueType.enumeration_id) === parseInt(initialVenueId))
                    setSelectedVenueType({
                        label: venueType.fully_qualified_name,
                        id: venueType.enumeration_id
                    })
                }
            })
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
        setVenueId(selectedVenueType?.id)
    }, [selectedVenueType])

    return <Autocomplete
        style={{ maxWidth: 600, marginTop: 16 }}
        fullWidth
        loading={loading}
        value={selectedVenueType}
        onChange={(_, newValue) => setSelectedVenueType(newValue)}
        options={venueTypes}
        getOptionLabel={option => option.label}
        getOptionSelected={option => parseInt(selectedVenueType?.id) === parseInt(option.id)}
        renderInput={(params) => <TextField {...params}
            variant='outlined'
            label={t('Venue Type')}
            helperText={t('Choose the best venue type  for this screen')}
        />}
    />
}

export default VenueTypeDropDown;
