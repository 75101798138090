import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { StoryClassMap } from '../../components';

function StoriesList(props) {
    const { stories } = props;

    return <Box>
        {stories.map((story, idx) => {
            const Story = StoryClassMap[story.story_class];

            if (!Story) {
                return null;
            }

            return <Box key={idx} mb={4} style={{ marginLeft: 1, marginRight: 1 }}>
                <Story story={story} />
            </Box>
        })}
    </Box>;
}

StoriesList.propTypes = {
    stories: PropTypes.array.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    StoriesList
);
