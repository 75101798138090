import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import CastConnected from '@material-ui/icons/CastConnected';
import DateTimeRelative from '../../components/DateTimeRelative';
import { minutesAgo, toLocalDateTime } from '../../helpers/date';

const useStyles = makeStyles(theme => {
    return {
        green: {
            color: theme.palette.success.main
        },
        red: {
            color: theme.palette.error.main
        },
        orange: {
            color: theme.palette.warning.main
        }
    }
})

function BillboardPullIndicator({ pull_datetime }) {
    const classes = useStyles();

    const title = pull_datetime
        ? <>
            Last communication with sign (via Pull) <DateTimeRelative date={toLocalDateTime(pull_datetime)} />
        </>
        : "No Communication yet for this screen"

    const className = minutesAgo(pull_datetime) <= 60
        ? classes.green
        : minutesAgo(pull_datetime) <= 1440
            ? classes.orange
            : classes.red;

    return (<Tooltip title={title}>
        <CastConnected fontSize="small"
            className={className} />
    </Tooltip>
    )
}

export default BillboardPullIndicator;
