import { apiHttp } from "../helpers/api";

export default class LucitApiDriveTemplateElements {

    index(filters) {
        return apiHttp.get('/drive-template-elements', {
            params: filters
        })
            .then(response => response.data.drive_template_elements)
    }

    indexForEdit(filters) {
        return apiHttp.get('/drive-template-elements/for-edit', {
            params: filters
        })
            .then(response => response.data.drive_template_elements)
    }

    storeBasicShape(params) {
        return apiHttp.post('/drive-template-elements/basic-shape', params)
            .then(response => response.data.drive_template_element);
    }

    storeBasicShapeImage(params) {
        return apiHttp.post('/drive-template-elements/basic-shape-image', params)
            .then(response => response.data.drive_template_element);
    }

    storeBackground(params) {
        return apiHttp.post('/drive-template-elements/background', params)
            .then(response => response.data.drive_template_element);
    }
    storeBackgroundImage(params) {
        return apiHttp.post('/drive-template-elements/background-image', params)
            .then(response => response.data.drive_template_element);
    }

    storeText(params) {
        return apiHttp.post('/drive-template-elements/text', params)
            .then(response => response.data.drive_template_element);
    }

    update(driveTemplateElementId, params) {
        return apiHttp.put('/drive-template-elements/' + driveTemplateElementId, params)
            .then(response => response.data.drive_template_element);
    }

    move(driveTemplateElementId, targetElementLcuid,direction) {
        return apiHttp.put('/drive-template-elements/' + driveTemplateElementId + '/move', {
            target_element_lcuid: targetElementLcuid,
            direction: direction
        })
            .then(response => response.data.drive_template_element);
    }

}
