import { Box, FormControlLabel, Switch, Typography, Button } from '@material-ui/core';
import {
    BorderOuter,
    FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, FormatBold,
    FormatColorFill, FormatColorText, FormatItalic, FormatUnderlined, Loop, Opacity, StrikethroughS,
    FormatLineSpacing, StarBorder, BlurOn
} from '@material-ui/icons';
import { ToggleButtonGroup } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { getHtmlForSelectedId, getDesignerTextFormattingFunctions } from '../../selectors/designer';
import {
    BorderEditor, SVGStrokeEditor, ClearFormatting, ColorPicker, IconButtonExclusive,
    IconOnOff, OpacityEditor, RotationEditor, ShadowEditor, CSSFontSelector, VisibilityOnOff,
    LineAndLetterSpacing, FontStrokeEditor, CSSFontSizeEditor, BlurEditor, ImagePositionEditor
} from './DesignerCSS';
import {
    ClassListSelector, htmlStringToElement, isImageDiv, isTextDiv, isObjectDiv, isObjectSvgDiv,
    addClassAndRemoveClasses
} from './DesignerHTML';
import ToolbarControl from './ToolbarControl';
import { replaceHTMLForSelectedId } from '../../actions/designer';
import UploadAndReplaceImage from '../../components/inputs/UploadAndReplaceImage';
import LowercaseIcon from '../../components/icons/LowercaseIcon';
import SentenceCaseIcon from '../../components/icons/SentenceCaseIcon';
import UppercaseIcon from '../../components/icons/UppercaseIcon';
import TextShadowIcon from '../../components/icons/TextShadowIcon';
import DropShadowIcon from '../../components/icons/DropShadowIcon';
import TextOutlineIcon from '../../components/icons/TextOutlineIcon';

const DesignerObjectEditor = ({ html, editorSelectedId, customTextClassOptions }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // If no element selected - assume we editing background
    // const isBackgroundEdit = useSelector(state => !state.designer.editorSelectedId);

    const element = useMemo(() => htmlStringToElement(html), [html]);
    const isImage = useMemo(() => isImageDiv(element), [element]);
    const isImageFullSize = element && element.classList.contains('lc_dt_image_full_size_stretch');
    const isText = useMemo(() => isTextDiv(element), [element]);
    const isObject = useMemo(() => isObjectDiv(element), [element]);
    const isObjectSvg = useMemo(() => isObjectSvgDiv(element), [element]);

    const [showUploadAndReplaceImage, setShowUploadAndReplaceImage] = useState(false);

    const textClassOptionsCombined = customTextClassOptions
        ? [textClassOptions[0], ...customTextClassOptions, ...textClassOptions.slice(1)]
        : textClassOptions;

    return <>
        <Box p={2} style={{ height: "calc(100vh - 250px)", overflowY: "scroll" }}>
            <Box mb={2}>
                <Typography variant='subtitle2' style={{ marginBottom: 8 }}>{t('Appearance')}</Typography>
                <ToggleButtonGroup>
                    {isText
                        && <ToolbarControl
                            Component={ColorPicker}
                            className="MuiToggleButtonGroup-grouped"
                            title={t('Text Color')}
                            Icon={<FormatColorText />}
                            cssKey="color"
                        />}
                    {isObjectSvg
                        && <ToolbarControl
                            Component={ColorPicker}
                            className="MuiToggleButtonGroup-grouped"
                            title={t('Shape Fill Color')}
                            Icon={<FormatColorFill />}
                            cssKey="fill"
                        />}
                    {!isObjectSvg && <ToolbarControl
                        Component={ColorPicker}
                        className="MuiToggleButtonGroup-grouped"
                        title={t('Background Color')}
                        allowGradient={true}
                        Icon={<FormatColorFill />}
                        cssKey="background"
                    />
                    }
                    <ToolbarControl
                        Component={OpacityEditor}
                        className="MuiToggleButtonGroup-grouped"
                        Icon={<Opacity />}
                    />
                    <ToolbarControl
                        Component={RotationEditor}
                        className="MuiToggleButtonGroup-grouped"
                        Icon={<Loop />}
                    />
                    {!isObjectSvg
                        && <ToolbarControl
                            Component={BorderEditor}
                            className="MuiToggleButtonGroup-grouped"
                            Icon={<BorderOuter />}
                        />
                    }
                    {isObjectSvg
                        && <ToolbarControl
                            Component={SVGStrokeEditor}
                            className="MuiToggleButtonGroup-grouped"
                            Icon={<StarBorder />}
                        />}

                </ToggleButtonGroup >

                {(isImage || isObject)
                    && <Box>
                        <FormControlLabel
                            style={{ marginTop: 8 }}
                            control={<Switch
                                checked={isImageFullSize}
                                onChange={() => {
                                    const elNew = isImageFullSize
                                        ? addClassAndRemoveClasses(element, 'lc_dt_image_center_contain',
                                            ['lc_dt_image_full_size_stretch'])
                                        : addClassAndRemoveClasses(element, 'lc_dt_image_full_size_stretch',
                                            imageClassOptions.map((co) => co.class))
                                    dispatch(replaceHTMLForSelectedId(elNew.outerHTML))
                                }} />}
                            label={<Typography variant='body2'>{t('Stretch to fill the screen')}</Typography>}
                        />
                    </Box>}
            </Box>

            {isText
                && <>
                    <Box mt={2} mb={2}>
                        <Typography variant='subtitle2' style={{ marginBottom: 8 }}>{t('Font')}</Typography>

                        <Box mb={1}>
                            <ToolbarControl
                                Component={CSSFontSelector}
                                cssKey="font-family"
                            />
                        </Box>

                        <Box mb={2} mt={2}>
                            <ToolbarControl
                                Component={CSSFontSizeEditor}
                            />
                        </Box>

                        <ToggleButtonGroup size="small">
                            <ToolbarControl
                                Component={IconOnOff}
                                className="MuiToggleButtonGroup-grouped"
                                title={t('Bold')}
                                Icon={<FormatBold />}
                                cssKey="font-weight"
                                cssValueOn="bold"
                                cssValueOff="normal"
                            />
                            <ToolbarControl
                                Component={IconOnOff}
                                className="MuiToggleButtonGroup-grouped"
                                title={t('Italic')}
                                Icon={<FormatItalic />}
                                cssKey="font-style"
                                cssValueOn="italic"
                                cssValueOff="normal"
                            />
                            <ToolbarControl
                                Component={IconButtonExclusive}
                                cssKey="text-decoration-line"
                                iconClasses={textDecorationOptions}
                                group={true}
                            />

                        </ToggleButtonGroup>

                        <Box mt={1}>
                            <ToolbarControl
                                Component={IconButtonExclusive}
                                cssKey="text-transform"
                                iconClasses={textTransformOptions}
                            />
                        </Box>

                        <Box mt={1}>
                            <ToolbarControl
                                Component={LineAndLetterSpacing}
                                className="MuiToggleButtonGroup-grouped"
                                Icon={<FormatLineSpacing />}
                            />
                            <ToolbarControl
                                Component={FontStrokeEditor}
                                className="MuiToggleButtonGroup-grouped"
                                Icon={<TextOutlineIcon />}
                            />
                        </Box>

                    </Box>
                </>}

            <Box mt={2} mb={2}>
                <Typography variant='subtitle2' style={{ marginBottom: 8 }}>{t('Cropping & Sizing')}</Typography>
                {isImage
                    && <Box mt={1}>
                        <ToggleButtonGroup size="small">
                            <ToolbarControl
                                Component={ImagePositionEditor}
                                className="MuiToggleButtonGroup-grouped"
                            />
                        </ToggleButtonGroup>
                    </Box>}
                {isText
                    && <ToolbarControl
                        Component={IconButtonExclusive}
                        cssKey="text-align"
                        iconClasses={textAlignOptions}
                    />}

                {isObject
                    && <Box mt={1}>
                        <ToolbarControl
                            Component={ClassListSelector}
                            classOptions={imageClassOptions}
                        />
                    </Box>}
            </Box>

            <Box mt={2} mb={2}>
                <Typography variant='subtitle2' style={{ marginBottom: 8 }}>{t('Effects & Others')}</Typography>

                {isText
                    && <Box mb={1}>
                        <ToolbarControl
                            label={t('Format')}
                            Component={ClassListSelector}
                            classOptions={textClassOptionsCombined}
                        />
                    </Box>}

                <ToggleButtonGroup>
                    <ToolbarControl
                        Component={BlurEditor}
                        className="MuiToggleButtonGroup-grouped"
                        Icon={<BlurOn />}
                    />
                    {(isImage || isObject)
                        && <ToolbarControl
                            Component={ShadowEditor}
                            cssKey="filter"
                            className="MuiToggleButtonGroup-grouped"
                            Icon={<DropShadowIcon />}
                        />}
                    {isText
                        && <ToolbarControl
                            Component={ShadowEditor}
                            cssKey="box-shadow"
                            className="MuiToggleButtonGroup-grouped"
                            Icon={<DropShadowIcon />}
                        />}
                    {isText
                        && <ToolbarControl
                            Component={ShadowEditor}
                            cssKey="text-shadow"
                            className="MuiToggleButtonGroup-grouped"
                            Icon={<TextShadowIcon />}
                        />}

                    <ToolbarControl
                        Component={VisibilityOnOff}
                        className="MuiToggleButtonGroup-grouped"
                        title={t('Show / Hide')}
                    />

                    <ToolbarControl
                        Component={ClearFormatting}
                        className="MuiToggleButtonGroup-grouped"
                    />
                </ToggleButtonGroup>

            </Box >

            {isImage &&
                <Box mt={2} mb={2}>
                    <Typography variant='subtitle2' style={{ marginBottom: 8 }}>{t('Image')}</Typography>
                    <Box mb={1}>
                        <Button variant="outlined" color="primary" onClick={() => setShowUploadAndReplaceImage(true)}>
                            {t('Replace Image')}
                        </Button>

                        {showUploadAndReplaceImage &&
                            <UploadAndReplaceImage
                                elementId={editorSelectedId}
                                handleClose={() => setShowUploadAndReplaceImage(null)}
                            />
                        }
                    </Box>
                </Box>
            }

        </Box >
    </>

}

const mapStateToProps = state => {
    return {
        html: getHtmlForSelectedId(state),
        editorSelectedId: state.designer.editorSelectedId,
        customTextClassOptions: getDesignerTextFormattingFunctions(state),
    };
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DesignerObjectEditor
);

const textDecorationOptions = [
    {
        "class": "line-through",
        "label": 'Line Through',
        "icon": <StrikethroughS />
    },
    {
        "class": "underline",
        "label": 'Underline',
        "icon": <FormatUnderlined />
    }
];

const textTransformOptions = [
    {
        "class": "uppercase",
        "label": 'Uppercase',
        "icon": <UppercaseIcon />
    },
    {
        "class": "lowercase",
        "label": 'Lowercase',
        "icon": <LowercaseIcon />
    },
    {
        "class": "capitalize",
        "label": 'Sentence Case',
        "icon": <SentenceCaseIcon />
    }
];

const textAlignOptions = [
    {
        "class": "left",
        "label": 'Left',
        "icon": <FormatAlignLeft />
    },
    {
        "class": "center",
        "label": 'Center',
        "icon": <FormatAlignCenter />
    },
    {
        "class": "right",
        "label": 'Right',
        "icon": <FormatAlignRight />
    },
    {
        "class": "justify",
        "label": 'Justify',
        "icon": <FormatAlignJustify />
    }
]

const textClassOptions = [
    {
        "class": "lc_format_text_none",
        "label": 'No Special Format'
    },
    {
        "class": "lc_format_number_us",
        "label": 'Number 123,456, 1234, 12,234.56'
    },
    {
        "class": "lc_format_number_short_us_no_decimal_round_downK",
        "label": "Number 12K, 314K, 1.2M"
    },
    {
        "class": "lc_format_price_us",
        "label": 'Price $12,345'
    },
    {
        "class": "lc_format_price_short_us",
        "label": 'Price $12K, $314.2K, $1.2M'
    },
    {
        "class": "lc_format_price_short_us_no_decimal_round_downK",
        "label": 'Price $12K, $314K, $1.2M'
    },
    {
        "class": "lc_format_price_super_script_us",
        "label": "Price $12 99 (99 in superscript)"
    },
    {
        "class": "lc_format_price_uk",
        "label": 'Price ' + String.fromCharCode(163) + '12,345'
    },
    {
        "class": "lc_format_price_short_uk",
        "label": 'Price ' + String.fromCharCode(163) + '12K, ' + String.fromCharCode(163) + '314.2K, ' + String.fromCharCode(163) + '1.2M'
    },
    {
        "class": "lc_format_price_short_uk_no_decimal_round_downK",
        "label": 'Price ' + String.fromCharCode(163) + '12K, ' + String.fromCharCode(163) + '314K, ' + String.fromCharCode(163) + '1.2M'
    },
    {
        "class": "lc_format_price_super_script_uk",
        "label": "Price " + String.fromCharCode(163) + "12 99 (99 in superscript)"
    },
    {
        "class": "lc_format_phone_nanp",
        "label": "Phone (123) 456-7890"
    },
    {
        "class": "lc_format_phone_nanp_hyphen",
        "label": "Phone 123-456-7890"
    },
    {
        "class": "lc_format_degrees",
        "label": <span>Temperature 55&deg;</span>
    },
    { "class": "lc_format_date_fullDateLongWithTime", "label": "Date : July 8th, 2023 8:15 PM" },
    { "class": "lc_format_date_fullDateShortWithTime", "label": "Date : 07/08/23 8:15 PM" },
    { "class": "lc_format_date_fullDateLong", "label": "Date : July 8th, 2023" },
    { "class": "lc_format_date_fullDateShort", "label": "Date : 07/08/23" },
    { "class": "lc_format_date_fullDateNoYear", "label": "Date : 07/08" },
    { "class": "lc_format_date_fullDateNoYearShort", "label": "Date : 7/8" },
    { "class": "lc_format_date_monthNumeric", "label": "Month : 07" },
    { "class": "lc_format_date_monthNumericShort", "label": "Month : 7" },
    { "class": "lc_format_date_monthLong", "label": "Month : July" },
    { "class": "lc_format_date_dayNumeric", "label": "Day : 08" },
    { "class": "lc_format_date_dayNumericShort", "label": "Day : 8" },
    { "class": "lc_format_date_dayOrdinal", "label": "Day : 8th" },
    { "class": "lc_format_date_dayOfWeek", "label": "Day : Saturday" },
    { "class": "lc_format_date_timeShort", "label": "Time : 8:15" },
    { "class": "lc_format_date_timeShortPM", "label": "Time : 8:15 PM" },
    { "class": "lc_format_date_timeShortPMAbbr", "label": "Time : 08:15p" },
    { "class": "lc_format_date_time24h", "label": "Time : 20:15" },
    { "class": "lc_format_date_hour24", "label": "Time : 20h" }

]

const imageClassOptions = [
    {
        "class": "lc_dt_image_center_cover",
        "label": "Zoom to Fill Box"
    },
    {
        "class": "lc_dt_image_center_contain",
        "label": "No Cropping"
    },
    {
        "class": "lc_dt_image_full",
        "label": "Crop Top and Bottom"
    },
    {
        "class": "lc_dt_image_stretch",
        "label": "Stretch to fit box"
    },
]
