import { MapContext, Polygon } from '@react-google-maps/api';
import { cellToBoundary } from 'h3-js';
import clx from 'classnames';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Typography, makeStyles } from '@material-ui/core';
import { calculateNumberInRange, formatNumberToShortVariant } from '../../selectors/programmatic';
import { getScreenIcon } from '../../selectors/billboard';
import { Business } from '@material-ui/icons';
import { hasIcon } from '../../selectors/exports';
import { createPortal } from 'react-dom';

const useStyles = makeStyles(theme => {
    return {
        countTag: {
            color: theme.palette.primary.main,
            width: 24,
            height: 24,
            transform: 'translateY(50%)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        markerBox: {
            color: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "center",
            pointerEvents: 'none'
        },
        tooltipBoxVisible: {
            display: 'flex !important'
        },
        tooltipBox: {
            position: 'relative',
            display: 'none',
            flexDirection: 'column',
            padding: 12,
            marginBottom: 4,
            background: 'white',
            minWidth: 160,
            borderRadius: 4,
            '&:after': {
                borderLeft: '9px solid transparent',
                borderRight: '9px solid transparent',
                borderTop: '9px solid white',
                content: '""',
                height: 0,
                left: '50%',
                position: 'absolute',
                top: '100%',
                transform: 'translate(-50%)',
                width: 0,
                zIndex: 1
            }
        },
        billboardPoint: {
            cursor: 'pointer',
            width: 18,
            height: 18,
            // transform: 'translateY(50%)',
            borderRadius: '50%',
        },
        billboardPointZoomed1: {
            width: '24px !important',
            height: '24px !important',
        },
        billboardPointZoomed2: {
            width: '36px !important',
            height: '36px !important',
        },
        details: {
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: '10px 10px 5px #0003',
            height: 80,
            padding: '8px 16px',
            width: 'auto'
        },
        avatar: {
            width: 32,
            height: 32
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
        },
    }
})

const LocationMarker = ({ location, zoom, maxClusterBoards, agencies, venueTypes }) => {
    if (location.point_type == "h3_cluster") {
        return <ClusterPolygon location={location} maxClusterBoards={maxClusterBoards} />
    }

    return <BillboardPoint location={location}
        agencies={agencies}
        venueTypes={venueTypes}
        zoom={zoom} />
}

const ClusterPolygon = ({ location, maxClusterBoards }) => {
    const classes = useStyles();
    const path = useMemo(() =>
        cellToBoundary(location.cluster_data.h3_index)
            .map(x => new window.google.maps.LatLng(x[0], x[1]))
        , [location]);

    const [isHover, setIsHover] = useState(false);

    const coefficient = useMemo(() => location.cluster_data.total_boards / maxClusterBoards
        , [location.cluster_data.total_boards, maxClusterBoards])

    const options = useMemo(() => ({
        strokeColor: "#02c9c9",
        strokeOpacity: isHover ? 0.5 : 0.1,
        strokeWeight: 1,
        fillColor: "#02c9c9",
        geodesic: true,
        fillOpacity: calculateNumberInRange(coefficient, 0.3, 0.9)
    }), [coefficient, isHover]);

    const map = useContext(MapContext);

    useEffect(() => {
        const countTag = document.createElement('div');
        countTag.className = classes.countTag;
        countTag.textContent = formatNumberToShortVariant(location.cluster_data.total_boards);
        countTag.style.color = "#808080";
        //countTag.style.background = "#02c9c9";

        const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position: { lat: +location.latitude, lng: +location.longitude },
            content: countTag,
        });

        return () => {
            marker.setMap(null);
        }
    }, [location])

    const onClick = useCallback(() => {
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(p => bounds.extend(p))
        map.fitBounds(bounds, 75);
    }, [path])

    const onMouseOver = useCallback(() => setIsHover(true), []);
    const onMouseOut = useCallback(() => setIsHover(false), []);

    return <>
        <Polygon
            path={path}
            options={options}

            onClick={onClick}

            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        />

    </>
}

const BillboardPoint = ({ zoom, location, agencies, venueTypes }) => {
    const [contentContainer, setContentContainer] = useState(null);
    const [hovered, setHovered] = useState(false);
    const classes = useStyles();
    const map = useContext(MapContext);

    useEffect(() => {
        const content = document.createElement('div');
        content.className = classes.markerBox;
        setContentContainer(content);

        const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position: { lat: +location.latitude, lng: +location.longitude },
            content
        });

        marker.addEventListener('mouseenter', () => {
            setHovered(true);
            marker.zIndex = 1;
        })
        marker.addEventListener('mouseleave', () => {
            setHovered(false);
            marker.zIndex = null;
        })
        return () => marker.setMap(null)
    }, [location.latitude, location.longitude])

    return <>
        {contentContainer !== null
            && createPortal(<MarkerContent
                zoom={zoom}
                hovered={hovered}
                location={location}
                agencies={agencies}
                venueTypes={venueTypes} />, contentContainer)}
    </>
}

const MarkerContent = ({ agencies, location, venueTypes, zoom, hovered }) => {
    const classes = useStyles();
    const agency = agencies?.find(x => x.id == location.board_data.agency_id);
    const venueType = venueTypes?.find(x => x.enumeration_id == location.board_data.venue_taxonomy_id);

    return <div className={classes.markerBox}>
        <div className={clx(classes.tooltipBox,
            { [classes.tooltipBoxVisible]: hovered })}>
            <Typography gutterBottom><b>{location.board_data.name}</b></Typography>
            <Typography gutterBottom><b>Size:</b> {location.board_data.width}x{location.board_data.height}</Typography>
            <Typography gutterBottom><b>Venue Type:</b> {venueType && venueType.name}</Typography>

            {agency
                && <Box display="flex" alignItems="center">
                    {hasIcon(agency)
                        ? <Avatar className={classes.avatar} style={{ background: 'none' }}>
                            <img
                                src={agency.options.primary_image_public_url}
                                className={classes.image}
                                alt={agency.name}
                                title={agency.name}
                            />
                        </Avatar>
                        : <Avatar className={classes.avatar}>
                            <Business fontSize='small' />
                        </Avatar>}
                    <Typography style={{ marginLeft: 8 }}>{agency.name}</Typography>
                </Box>}
        </div >
        <img className={clx(classes.billboardPoint,
            {
                [classes.billboardPointZoomed1]: zoom > 11 && zoom < 14,
                [classes.billboardPointZoomed2]: zoom > 14
            })}
            style={{ background: `url(${getScreenIcon(location.board_data.digital_board_format)})` }}
            src={getScreenIcon(location.board_data.digital_board_format)}
        />
    </div >
}

export default LocationMarker
