import React, { useContext, useEffect } from 'react';
import { Collapse, Toolbar } from '@material-ui/core';

import { useScrollTop } from '../../helpers/hooks';
import SearchAutocomplete from './SearchAutocomplete';
import { ScrollContext } from '../../contexts';

function SearchToolbar(props) {
    const scrollContext = props.scrollContext || useContext(ScrollContext).current;

    const scrollTop = useScrollTop(scrollContext.ref);
    const collapse = scrollTop < 50;

    useEffect(() => {
        if (props.onCollapseChange) {
            props.onCollapseChange(collapse)
        }
    }, [collapse])

    return <Collapse in={collapse}>
        <Toolbar >
            <SearchAutocomplete />
        </Toolbar>
    </Collapse>;
}

export default SearchToolbar;
