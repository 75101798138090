import React, { useEffect, useState } from 'react';
//import FacebookLogin from 'react17-facebook-login'; //fork of react-facebook-login
import FacebookLogin from 'react17-facebook-login/dist/facebook-login-render-props'
import { lucitApi } from '../../services/lucitApi';
import { GenericDialog } from '../modals/GenericDialog';
import { FormControlLabel, Box, Radio, Button, FormControl, Grid, Avatar } from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../material/CircularProgressCentered';
import { Alert } from '@material-ui/lab';
import { Facebook as FacebookIcon } from '@material-ui/icons';
import { getFacebookAppId } from '../../helpers/environment';

const LucitFacebookPageAuth = ({ handleFBData }) => {

    const { t } = useTranslation();

    const [accessToken, setAccessToken] = useState(null);
    const [facebookAppScopedUserId, setFacebookAppScopedUserId] = useState(null); //response.id
    const [longTermAccessToken, setLongTermAccessToken] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [showAccountSelector, setShowAccountSelector] = useState(false);
    const [selectedFBAccount, setSelectedFBAccount] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (accessToken) {
            setLoading(true)
            lucitApi.facebook.getLongTermAccessToken(accessToken)
                .then((response) => {
                    setLongTermAccessToken(response.access_token)

                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }, [accessToken])

    useEffect(() => {
        if (longTermAccessToken) {
            setLoading(true)
            lucitApi.facebook.getAccounts(facebookAppScopedUserId, longTermAccessToken)
                .then((response) => {
                    setAccounts(response.data)
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }

    }, [longTermAccessToken]);

    useEffect(() => {
        //We we have the accounts, we can now ask the user to select one
        if (accounts && accounts.length > 0) {
            setShowAccountSelector(true)
        }
    }, [accounts])

    return (
        <div>
            {!accessToken ? <FacebookLogin
                appId={getFacebookAppId()}
                autoLoad={false}
                isDisabled={loading}
                fields="name,email,picture"
                textButton={loading ? t('Authorizing...') : t('Authorize Facebook Page Access')}
                callback={(response) => {
                    setAccessToken(response.accessToken)
                    setFacebookAppScopedUserId(response.userID)
                }}
                scope="pages_show_list,pages_read_engagement,public_profile,business_management"
                render={renderProps => (
                    <Button
                        onClick={renderProps.onClick}
                        size="small"
                        style={{
                            backgroundColor: '#1877f2', // Facebook blue
                            color: '#fff', // White text color
                            borderRadius: '5px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            border: '1px solid #1877f2', // Border color
                            cursor: 'pointer',
                        }}
                    >
                        <FacebookIcon style={{ marginRight: "10px" }} />{loading ? t('Authorizing...') : t('Connect Facebook Page')}
                    </Button>
                )}
            />

                :
                (!loading && <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setAccessToken(null)
                        setFacebookAppScopedUserId(null)
                        setLongTermAccessToken(null)
                        setAccounts([])
                        setShowAccountSelector(false)
                        setSelectedFBAccount(null)
                    }}
                >{t('Revoke Facebook Access')}</Button>)
            }

            {loading && <CircularProgressCentered />}

            {showAccountSelector && <GenericDialog
                open={showAccountSelector}
                title={t('Select Facebook Page')}
                dialogProps={{
                    open: showAccountSelector,
                    onClose: () => setShowAccountSelector(false),
                    fullWidth: true,
                    maxWidth: 'md'
                }}
                dialogContentProps={{}}

                ContentComponent={<>
                    <Box mb={6}>
                        <Box ml={3} mb={6} mt={3}>
                            <LCTypography variant="body2" gutterBottom>
                                Choose which of your pages you would like to connect to
                            </LCTypography>
                        </Box>
                        <Box ml={3}>
                            {!accounts.length
                                && <Alert severity="warning">
                                    {t('You have no Facebook pages that you are authorized to give access to')}
                                </Alert>
                            }
                            <Grid container spacing={4}>
                                {accounts.map((account) => (
                                    <Grid key={account.id} xs={6} item container alignItems="center">
                                        {account.picture && account.picture.data && account.picture.data.url && (
                                            <Avatar
                                                style={{ marginRight: "5px", cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedFBAccount(account);
                                                }}
                                            >
                                                <img src={account.picture.data.url} alt={account.name} />
                                            </Avatar>
                                        )}
                                        <Box ml={2}>
                                            <FormControl>
                                                <FormControlLabel
                                                    value={account.id}
                                                    checked={selectedFBAccount && selectedFBAccount.id === account.id}
                                                    control={<Radio />}
                                                    label={account.name}
                                                    labelPlacement="end"
                                                    onClick={() => {
                                                        setSelectedFBAccount(account);
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </>}

                ActionsComponent={<>
                    {
                        <>
                            <Button onClick={() => setShowAccountSelector(false)}
                                color="primary">
                                {t('Cancel')}
                            </Button>
                            <Button onClick={() => {
                                handleFBData({
                                    "facebook_app_scoped_user_id": facebookAppScopedUserId,
                                    "long_term_user_access_token": longTermAccessToken,
                                    "selected_facebook_page_account": selectedFBAccount
                                })

                                setShowAccountSelector(false)

                            }}
                                disabled={!selectedFBAccount}
                                variant="contained"
                                color="secondary">
                                {t('Connect')}
                            </Button>
                        </>
                    }
                </>}
            />}
        </div >

    );

}

export default LucitFacebookPageAuth;
