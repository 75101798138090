import i18next from '../../i18n';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleProfileMenu } from '../../actions/layout';
import { setTour } from '../../actions/user';
import ReactTour from '../../components/reactour/ReactTour';
import { delay } from '../../helpers/async';
import { AppTour, AppTourStatus } from '../../helpers/constants';
import { createTourSelector } from '../../helpers/tour';
import { store } from '../../reducers';
import { userTourSelector } from '../../selectors/user';
import LCTypography from '../../components/material/LCTypography';

const steps = [
    {
        selector: createTourSelector(AppTour.profileSwitcher.id, 0),
        content: <LCTypography>
            {i18next.t('The Profile Menu')}
        </LCTypography>,
        onShow: () => {
            const state = store.getState();

            if (state.layout.profileMenuOpen) {
                store.dispatch(toggleProfileMenu(false))
                return delay(300);
            }
        }
    },
    {
        selector: createTourSelector(AppTour.profileSwitcher.id, 1),
        content: <>
            <LCTypography>{i18next.t('All account profiles that you have access to are accessible from here.')}</LCTypography>
        </>,

        onShow: () => {
            const state = store.getState();

            if (!state.layout.profileMenuOpen) {
                store.dispatch(toggleProfileMenu(true))
                return delay(350);
            }
        }
    }
];

const ProfileSwitcherTour = props => {
    const { tour, tourReady, open, closeProfileMenu, onClose } = props;

    const isTourReady = tour.status === AppTourStatus.pending;

    const onRequestClose = useCallback((e, reason, step) => {
        onClose(reason, step)
            .finally(() => {
                closeProfileMenu();
            });
    }, [onClose, closeProfileMenu]);

    useEffect(() => {
        if (isTourReady) {
            tourReady(tour);
        }
    }, [isTourReady, tourReady, tour]);

    return (
        <ReactTour
            title={"Profile Menu"}
            steps={steps}
            isOpen={open}
            onRequestClose={onRequestClose}
            startAt={tour.last_step || 0}
        />
    )
};

const mapStateToProps = state => {
    return {
        tour: userTourSelector(state)(AppTour.profileSwitcher.id),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        tourReady: (tour) => dispatch(setTour({
            ...tour,
            status: AppTourStatus.ready
        })),
        closeProfileMenu: () => dispatch(toggleProfileMenu(false)),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ProfileSwitcherTour
    )
);
