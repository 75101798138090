import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { Field } from 'redux-form';

const CheckboxField = (props) => {
    return <Field
        component={CheckboxInput}
        {...props}
    />
}

const CheckboxInput = ({ input, label, meta: { touched, error } = {}, checkboxProps }) => (
    <FormControl error={touched && error} component="fieldset" >
        <FormControlLabel
            control={<Checkbox
                checked={input.value ? true : false}
                onChange={input.onChange}
                {...checkboxProps} />}
            label={label}
        />
        {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
);

export default CheckboxField;
export { CheckboxInput };
