import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import LCTypography from '../../components/material/LCTypography';

export const PlaceExchange = ({ exportItem, addPxDealMap, deletePxDealMap }) => {
    const [exportItemContracts, setExportItemContracts] = useState([])
    const [inputHasError, setInputHasError] = useState(false)

    useEffect(() => {
        setExportItemContracts(exportItem.px_deal_maps ?? []);
    }, [exportItem])

    const [pxDealMap, setPxDealMap] = useState("")

    const doAddPxDealMap = (newPxDealMap) => {

        if (!newPxDealMap) {
            setInputHasError(true)
            return
        }

        setInputHasError(false)

        setExportItemContracts([...exportItemContracts, {
            "px_deal_id": newPxDealMap
        }])

        addPxDealMap(newPxDealMap).then(() => setPxDealMap(""))

    }

    const doRemoveApxContract = (removePxDealMap) => {

        setExportItemContracts(exportItemContracts.filter(el => el.px_deal_id !== removePxDealMap))
        deletePxDealMap(removePxDealMap)
    }

    const hasContracts = () => exportItemContracts.length > 0
    const { t } = useTranslation();

    return (
        <Box p={5}>

            <Box mb={2}>
                <LCTypography variant="body2">

                </LCTypography>
            </Box>

            <hr />

            <Box pt={8} mb={4}>

                <Box pb={3}>
                    <LCTypography variant="h5">Place Exchange Deal Link</LCTypography>
                </Box>

                <Box p={2}>
                    <Box pb={2}>
                        <strong>{t('Place Exchange Deal Ids')}</strong>
                        {
                            !hasContracts() &&
                            <Alert severity="warning">
                                {t('This campaign is NOT linked to any Place Exchange Deal Ids.  ')}
                                {t('It will not be able to serve creatives until you attach deal id\'s')}
                            </Alert>
                        }
                    </Box>

                    <Grid container spacing={3}>

                        {exportItemContracts.map((pxDeal, idx) => {
                            return (
                                <Grid key={idx} container style={{ borderBottom: "1px solid #888888" }}>
                                    <Grid item md={4} xs={12}>
                                        <Box pt={2} pl={3}>
                                            {pxDeal.px_deal_id}
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Box pt={2} pl={3}>
                                            <strong>
                                                {t('Last Communication')}
                                            </strong> <br />
                                            {pxDeal.last_pull_at
                                                ? <DateTimeRelative date={toLocalDateTime(pxDeal.last_pull_at)} />
                                                : "--"
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Box pt={2} pl={3}>
                                            <strong>
                                                {t('Created')}
                                            </strong> <br />
                                            <DateTimeRelative date={toLocalDateTime(pxDeal.created_at)} />
                                        </Box>
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                        <IconButton
                                            onClick={() => {
                                                var r = window.confirm(t('Are you sure you want to delete this deal id?'))

                                                if (r)
                                                    doRemoveApxContract(pxDeal.px_deal_id)
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })}

                    </Grid>

                    <Box mt={5} mb={3}>
                        {t('Attach a new deal id to this campaign')}
                    </Box>

                    <Grid container>

                        <Grid item xs={10}>
                            <TextField
                                required
                                error={inputHasError}
                                helperText={t('Required')}
                                id="px_deal_id"
                                label={t('Place Exchange Deal Id')}
                                value={pxDealMap}
                                onChange={
                                    (e) => setPxDealMap(e.target.value.trim())
                                }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Box pt={2}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                        doAddPxDealMap(pxDealMap)
                                    }>
                                    {t('Add')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>

            </Box>
        </Box >
    )
}
