import React from 'react';
import clx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../../components';

const useStyles = makeStyles(() => ({
    avatar: {
        background: 'none'
    }
}));

const UserAvatar = ({ user, className, ...rest }) => {
    const classes = useStyles();

    return (<>
        <Avatar
            src={hasIcon(user) && user.options.primary_image_public_url}
            name={user.name}
            className={clx(classes.avatar, className)}
            {...rest} />
    </>);
}

export default UserAvatar;
