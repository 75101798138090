import {
    ENTITY_NEXT_PAGE_ERROR, ENTITY_NEXT_PAGE_REQUEST, ENTITY_NEXT_PAGE_RESULT,
    ENTITY_SEARCH_ERROR, ENTITY_SEARCH_REQUEST, ENTITY_SEARCH_RESULT
} from "../../helpers/actionTypes";

export const initialState = {
    searched: false,
    loading: false,
    loadingNextPage: false,
    invalidated: false,

    filters: {},
    scrollY: 0,
    data: [],

    currentPage: 1,
    perPage: 100,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,
}

export default function entities(state = initialState, { type, ...payload }) {
    switch (type) {

        case ENTITY_SEARCH_REQUEST:
            return Object.assign({},
                state,
                {
                    filters: payload.filters,
                    loading: true,
                    data: []
                });

        case ENTITY_SEARCH_RESULT:
            return Object.assign({},
                state,
                payload,
                {
                    loading: false,
                    searched: true,
                    scrollY: null,
                    invalidated: false
                });

        case ENTITY_SEARCH_ERROR:
            return Object.assign({},
                state,
                payload,
                {
                    loading: false,
                });

        case ENTITY_NEXT_PAGE_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingNextPage: true
                });

        case ENTITY_NEXT_PAGE_RESULT: {
            const { data, ...rest } = payload;

            return Object.assign({},
                state,
                rest,
                {
                    loadingNextPage: false,
                    data: state.data.concat(data)
                });
        }
        case ENTITY_NEXT_PAGE_ERROR: {
            return Object.assign({},
                state,
                {
                    loadingNextPage: false
                });
        }
        default:
            return state
    }
}
