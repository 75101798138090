import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const FeatureToggle = (props) => {
    const { features, featureName, reverse, children } = props;

    // Reverse actually means to hide content if feature is enabled
    if (reverse) {
        return features[featureName]
            ? null
            : children;
    }

    return !features[featureName]
        ? null
        : children;
}

FeatureToggle.propTypes = {
    featureName: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        features: state.featureToggle.features
    }
}

export default connect(
    mapStateToProps
)(
    FeatureToggle
)
