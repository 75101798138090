import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Circle, DrawingManager, Polygon, Rectangle } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MapButton } from '../../components/google-maps';
import { metersToMiles } from '../../helpers/distance';
import { showWarning } from '../../actions/snackbar';
import { ProgrammaticGeoTypes, ProgrammaticGeoSources } from '../../helpers/constants';

const useStyles = makeStyles(() => {
    return {
        circle: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/circle.png)',
        },
        polygon: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/polygon.png)',
        },
        rectangle: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/square.png)',
        },
        cancel: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/cancel.png)',
        },
        mapButton: {
            borderRadius: '5px !important',
            marginRight: '0px !important',
            marginLeft: '8px !important',
            marginTop: '12px !important',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
    }
})

const MAX_RADIUS = 1_000_000; // in meters

const DrawingFilter = ({ map, geo, onGeoChange }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [drawingMode, setDrawingMode] = useState(null);
    const [drawingManager, setDrawingManager] = useState(null);
    const [items, setItems] = useState({});

    const onLoad = useCallback(manager => {
        setDrawingManager(manager);
    }, []);

    const onOverlayComplete = useCallback(() => {
        setDrawingMode(null);
        drawingManager.setDrawingMode(null);
    }, [drawingManager]);

    const onPolygonChange = useCallback((polygon, geo) => {
        onGeoChange({
            geo_source: ProgrammaticGeoSources.map_drawing.code,
            geo_type: ProgrammaticGeoTypes.polygon.code,
            label: "Region on map",
            ...geo,
            coordinates: polygon.getPath()
                .getArray()
                .map(latLng => ([latLng.lat(), latLng.lng()])),
        })
    }, []);
    const onPolygonComplete = useCallback((polygon) => {
        const id = new Date().valueOf();
        const data = { id, geo_source: ProgrammaticGeoSources.map_drawing.code };

        setItems(x => ({ ...x, [id]: polygon }))
        onPolygonChange(polygon, { ...data, bounds: map.getBounds() });
        polygon.getPath().addListener('insert_at', () => onPolygonChange(polygon, data));
        polygon.getPath().addListener('remove_at', () => onPolygonChange(polygon, data));
        polygon.getPath().addListener('set_at', () => onPolygonChange(polygon, data));
    }, [map, geo, onPolygonChange])

    const onCircleChange = useCallback((circle, geo) => {
        if (circle.getRadius() > MAX_RADIUS) {
            circle.setRadius(MAX_RADIUS);

            const warning = t("The maximum circle radius is {{radius}} miles.", { radius: metersToMiles(MAX_RADIUS) });
            dispatch(showWarning(warning));
        }

        onGeoChange({
            geo_type: ProgrammaticGeoTypes.radius.code,
            geo_source: ProgrammaticGeoSources.map_drawing.code,
            label: "Region on map",
            units: "m",
            ...geo,
            centerpoint: {
                lat: circle.getCenter().lat(),
                long: circle.getCenter().lng(),
            },
            radius: circle.getRadius()
        })
    }, []);
    const onCircleComplete = useCallback((circle) => {
        const id = new Date().valueOf();
        const data = { id, geo_source: ProgrammaticGeoSources.map_drawing.code };

        setItems(x => ({ ...x, [id]: circle }))
        onCircleChange(circle, { ...data, bounds: map.getBounds() });
        circle.addListener('radius_changed', () => onCircleChange(circle, data));
        circle.addListener('center_changed', () => onCircleChange(circle, data));
    }, [map, geo, onCircleChange])

    const onRectangleChange = useCallback((rectangle, geo) => {
        onGeoChange({
            geo_source: ProgrammaticGeoSources.map_drawing.code,
            geo_type: ProgrammaticGeoTypes.rectangle.code,
            label: "Region on map",
            ...geo,
            coordinates: getRectanglePath(rectangle),
        })
    }, []);
    const onRectangleComplete = useCallback((rectangle) => {
        const id = new Date().valueOf();
        const data = { id, geo_source: ProgrammaticGeoSources.map_drawing.code };

        setItems(x => ({ ...x, [id]: rectangle }))
        onRectangleChange(rectangle, { ...data, bounds: map.getBounds() });
        rectangle.addListener('bounds_changed', () => onRectangleChange(rectangle, data));
    }, [map, onRectangleChange])

    const setModeFunction = useCallback((mode) => () => {
        if (mode == drawingMode) {
            mode = null;
        }

        setDrawingMode(mode);
        drawingManager.setDrawingMode(mode);
    }, [drawingManager, drawingMode])

    const options = useMemo(() => {
        const strokeColor = "#000000";
        const strokeOpacity = 0.8;
        const fillColor = "#02c9c9";
        const fillOpacity = 0.2;
        return {
            drawingControl: false,
            circleOptions: {
                strokeColor, strokeOpacity,
                fillColor, fillOpacity,
                editable: true, draggable: false, geodesic: true,
                zIndex: 1000
            },
            polygonOptions: {
                strokeColor, strokeOpacity,
                fillColor, fillOpacity,
                editable: true, draggable: false, geodesic: true,
                zIndex: 1000
            },
            rectangleOptions: {
                strokeColor, strokeOpacity,
                fillColor, fillOpacity,
                editable: true, draggable: false, geodesic: true,
                zIndex: 1000
            }
        }
    }, []);

    // Remove items that has been drawn from map when they are deleted from filters
    useEffect(() => {
        const ids = Object.keys(items)
            .filter(id => geo.find(x => x.id == id) == null);

        ids.forEach(id => items[id].setMap(null))
    }, [geo])

    return <>
        <DrawingManager
            onLoad={onLoad}
            onOverlayComplete={onOverlayComplete}
            onCircleComplete={onCircleComplete}
            onPolygonComplete={onPolygonComplete}
            onRectangleComplete={onRectangleComplete}
            options={options}
        />
        {geo.filter(g => items[g.id] == null)
            .map(x => (<Figure
                key={x.id}
                geo={x}
                circleOptions={options.circleOptions}
                polygonOptions={options.polygonOptions}
                rectangleOptions={options.rectangleOptions}
                onCircleChange={onCircleChange}
                onPolygonChange={onPolygonChange}
                onRectangleChange={onRectangleChange}
            />))}
        <MapButton
            map={map}
            title={t('Draw a circle')}
            position={window.google.maps.ControlPosition.LEFT_TOP}
            classes={{
                button: classes.mapButton,
                text: drawingMode == window.google.maps.drawing.OverlayType.CIRCLE
                    ? classes.cancel
                    : classes.circle
            }}
            onClick={setModeFunction(window.google.maps.drawing.OverlayType.CIRCLE)}
        />
        <MapButton
            map={map}
            title={t('Draw a polygon')}
            position={window.google.maps.ControlPosition.LEFT_TOP}
            classes={{
                button: classes.mapButton,
                text: drawingMode == window.google.maps.drawing.OverlayType.POLYGON
                    ? classes.cancel
                    : classes.polygon
            }}
            onClick={setModeFunction(window.google.maps.drawing.OverlayType.POLYGON)}
        />
        <MapButton
            map={map}
            title={t('Draw a rectangle')}
            position={window.google.maps.ControlPosition.LEFT_TOP}
            classes={{
                button: classes.mapButton,
                text: drawingMode == window.google.maps.drawing.OverlayType.RECTANGLE
                    ? classes.cancel
                    : classes.rectangle
            }}
            onClick={setModeFunction(window.google.maps.drawing.OverlayType.RECTANGLE)}
        />
    </>;
}

const Figure = (props) => {
    const { geo, circleOptions, polygonOptions, rectangleOptions } = props;
    const [circle, setCircle] = useState(null);
    const [rectangle, setRectangle] = useState(null);

    const onCircleChange = useCallback(() => circle && props.onCircleChange(circle, geo),
        [geo.id, circle, props.onCircleChange]);
    const onCircleLoad = useCallback(circle => {
        setCircle(circle);
        circle.setCenter(new window.google.maps.LatLng(geo.centerpoint.lat, geo.centerpoint.long))
        circle.setRadius(geo.radius);
    }, [geo])

    const onPolygonLoad = useCallback(polygon => {
        polygon.setPaths(geo.coordinates.map(x => ({ lat: x[0], lng: x[1] })));
        polygon.getPath().addListener('insert_at', () => props.onPolygonChange(polygon, geo));
        polygon.getPath().addListener('remove_at', () => props.onPolygonChange(polygon, geo));
        polygon.getPath().addListener('set_at', () => props.onPolygonChange(polygon, geo));
    }, [geo])

    const onRectangleChange = useCallback(() => rectangle && props.onRectangleChange(rectangle, geo),
        [geo.id, rectangle, props.onRectangleChange]);
    const onRectangleLoad = useCallback(rectangle => {
        setRectangle(rectangle);
        rectangle.setBounds(getBoundsFromRectangleCoordinates(geo.coordinates));
    }, [geo])

    if (geo.geo_type == ProgrammaticGeoTypes.radius.code) {
        return <Circle
            options={circleOptions}
            onLoad={onCircleLoad}
            onCenterChanged={onCircleChange}
            onRadiusChanged={onCircleChange}
        />;
    }

    if (geo.geo_type == ProgrammaticGeoTypes.polygon.code) {
        return <Polygon
            options={polygonOptions}
            onLoad={onPolygonLoad}
        />;
    }

    if (geo.geo_type == ProgrammaticGeoTypes.rectangle.code) {
        return <Rectangle
            options={rectangleOptions}
            onLoad={onRectangleLoad}
            onBoundsChanged={onRectangleChange}
        />;
    }

    return null;
}

// AI generated
const getRectanglePath = rectangle => {
    // Get the bounds of the rectangle
    var bounds = rectangle.getBounds();

    // Get the coordinates of all four corners
    const northeast = bounds.getNorthEast();
    const northwest = new window.google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getSouthWest().lng());
    const southwest = bounds.getSouthWest();
    const southeast = new window.google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getNorthEast().lng());

    // Store the coordinates in an array of arrays
    const rectangleCoordinates = [
        [northeast.lat(), northeast.lng()],
        [northwest.lat(), northwest.lng()],
        [southwest.lat(), southwest.lng()],
        [southeast.lat(), southeast.lng()]
    ];
    return rectangleCoordinates;
}

// AI Generated
const getBoundsFromRectangleCoordinates = rectangleCoordinates => {
    // Extract the coordinates of the four corners
    // eslint-disable-next-line
    const [northeast, _, southwest, __] = rectangleCoordinates;

    // Calculate the bounds
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(northeast[0], northeast[1]));
    bounds.extend(new window.google.maps.LatLng(southwest[0], southwest[1]));

    return bounds;
};

Map.propTypes = {
    locations: PropTypes.array.isRequired,
}

export default DrawingFilter;
