import { lucitApi } from "../services/lucitApi";
import { ENVIRONMENT_LOAD_RESULT } from "../helpers/actionTypes";

export function getEnvironment() {
    return dispatch => {
        return lucitApi
            .getEnvironment()
            .then(data => {
                dispatch(environmentResult(data));
            })
    }
}

export function setCanvasSizeLimits(canvasSizeLimits) {
    return {
        type: ENVIRONMENT_LOAD_RESULT,
        canvasSizeLimits,
    }
}

export function environmentResult(data) {
    return {
        type: ENVIRONMENT_LOAD_RESULT,
        luCoreVersion: data.lcconfig.lcutils.version.string,
        luCoreHost: data.hostname,
        luCoreServerIp: data.server_ip,
        phpVersion: data.php.version,
        lcUtilsVersion: data.lcutils,
    }
}
