import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';

function InventoryItemCardContentRealEstateAgent() {

    return <Box p={2}>
    </Box>;
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
)(
    InventoryItemCardContentRealEstateAgent
);
