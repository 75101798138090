import { EntitySet, STORIES_NEW } from "../helpers/actionTypes";
import { isLoggedIn } from "../selectors/user";
import { lucitApi } from "../services/lucitApi";
import { entityNextPage, entitySearch } from "./entities";

export function loadDigitalBoardFeed(digitalBoardId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getBillboardStories(isAuthenticated, digitalBoardId, { page, perPage });

        return entitySearch(EntitySet.publicStory, search)(dispatch);
    }
}
export function loadDigitalBoardFeedNextPage(digitalBoardId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getBillboardStories(isAuthenticated, digitalBoardId, { page, perPage });

        return entityNextPage(EntitySet.publicStory, search)(dispatch);
    }
}

export function loadAgencyFeed(agencyId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getAgencyStories(isAuthenticated, agencyId, { page, perPage });

        return entitySearch(EntitySet.publicStory, search)(dispatch);
    }
}
export function loadAgencyFeedNextPage(agencyId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getAgencyStories(isAuthenticated, agencyId, { page, perPage });

        return entityNextPage(EntitySet.publicStory, search)(dispatch);
    }
}

export function loadAccountFeed(accountId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getAccountStories(isAuthenticated, accountId, { page, perPage });

        return entitySearch(EntitySet.publicStory, search)(dispatch);
    }
}
export function loadAccountFeedNextPage(accountId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getAccountStories(isAuthenticated, accountId, { page, perPage });

        return entityNextPage(EntitySet.publicStory, search)(dispatch);
    }
}

export function loadUserFeed(userId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getUserStories(isAuthenticated, userId, { page, perPage });

        return entitySearch(EntitySet.publicStory, search)(dispatch);
    }
}
export function loadUserFeedNextPage(userId, page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getUserStories(isAuthenticated, userId, { page, perPage });

        return entityNextPage(EntitySet.publicStory, search)(dispatch);
    }
}

export function loadPublicStory(page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getPublicStories(isAuthenticated, { page, perPage });

        return entitySearch(EntitySet.publicStory, search)(dispatch);
    }
}

export function loadPublicStoryNextPage(page, perPage) {
    return (dispatch, getState) => {
        const isAuthenticated = isLoggedIn(getState());
        const search = () => lucitApi.stories
            .getPublicStories(isAuthenticated, { page, perPage });

        return entityNextPage(EntitySet.publicStory, search)(dispatch);
    }
}

export function newStory(story) {
    return {
        type: STORIES_NEW,
        story
    }
}
