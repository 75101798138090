import { Box, Link, makeStyles, Typography, Grid } from '@material-ui/core';
import { IonCard, IonCardContent, IonImg } from '@ionic/react';
import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import { Timer as TimerIcon } from '@material-ui/icons';
import { hasExports, hasCampaignsInRunningState } from '../../selectors/exports'
import { RoutePaths, BillboardIcon } from '../../helpers/constants';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import moment from "moment";
import { hasLucitXRImages } from '../../selectors/inventory'
import { useSize } from '../../helpers/hooks';
import { useTranslation } from 'react-i18next';
import { LucitXR } from '../../components/images';

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%',
        height: 'auto',
        '&::part(image)': {
            maxHeight: '180px'
        },
    },
    iconItem: {
        marginRight: 10,
    },
    liveAdText: {
        display: 'flex',
        alignContent: 'center',
        margin: 10,
    },
    cardContainer: {
        [theme.breakpoints.down('xs')]: {
            marginInline: 'auto',
            marginBottom: 0,
        },
    },
    gridItem: {
        width: '100%'
    },
    liveAdsGridContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        marginBottom: '500px'
    },
    liveAdOverFlowText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const DashboardsLiveAdsList = (props) => {
    const classes = useStyles()
    const { activeAds } = props;

    if (activeAds.length === 0) {
        return <Box>
            <Box mt={1} />
        </Box>
    }

    return (
        <Grid
            container
            className={classes.liveAdsGridContainer}
        >
            {activeAds
                .map((a) => (
                    <LiveGridItem key={a.id} item={a} />
                ))}
        </Grid>
    )
}

const liveAdsScrollerXREnabled = () => false;

const LiveGridItem = props => {
    const { item } = props;
    const classes = useStyles()
    const cardRef = useRef()
    const { width } = useSize(cardRef)

    return <Grid item md={4} className={classes.gridItem}>
        <IonCard ref={cardRef} className={classes.cardContainer}>
            <Link component={RouterLink}
                to={{ pathname: `${RoutePaths.inventory}/${item.id}` }}
                color="secondary">

                {liveAdsScrollerXREnabled() && hasLucitXRImages(item)
                    ? <LucitXR
                        creative={item.creatives_to_board_images[0]}
                        viewportWidth={width}
                        margin={0.5}
                    />
                    : item.best_image
                    && <IonImg
                        component="img"
                        className={classes.image}
                        mt={1}
                        src={item.best_image}
                        title={item.title}>
                    </IonImg>
                }
            </Link>

            <IonCardContent mt={5}>
                <LiveItemContent item={item} />
            </IonCardContent>
        </IonCard>
    </Grid>
}

const LiveItemContent = props => {
    const { item } = props;

    const classes = useStyles();

    const board = item.last_ping
        ? item.last_ping.digital_board : (
            item.last_play ? item.last_play.digital_board : null
        )

    const last_playdatetime = item.last_ping ? item.last_ping.play_datetime : (
        item.last_play ? item.last_play.last_play_datetime : null
    )

    const hasRunningCampaigns = useSelector(hasCampaignsInRunningState)

    const showDelayedWarning = () => {

        if (!board?.options?.stats_delayed)
            return false;

        if (Math.abs(toLocalDateTime(last_playdatetime).diff(moment(), "hours")) > 36)
            return false;

        return true;
    }
    const { t } = useTranslation();

    return <>

        <div className={classes.liveAdText}>
            <Link component={RouterLink}
                noWrap
                to={{ pathname: `${RoutePaths.inventory}/${item.id}` }}
                color="secondary">
                <Typography variant="body2" className={classes.liveAdOverFlowText}><b>{item.title}</b></Typography>
            </Link>
        </div>

        {board &&
            <div className={classes.liveAdText}>
                <BillboardIcon className={classes.iconItem} />
                <Link
                    component={RouterLink}
                    className={classes.avatarLink}
                    to={RoutePaths.screen(board.id)}
                    noWrap>
                    <Typography variant="body2" className={classes.liveAdOverFlowText}>{board.name}</Typography>
                </Link>
            </div>
        }

        {!last_playdatetime &&
            <div className={classes.liveAdText}>
                <TimerIcon className={classes.iconItem} />
                <Typography variant="body2" className={classes.liveAdOverFlowText}>
                    {hasRunningCampaigns ? t('Waiting for first play') : ('Waiting for Campaign to Start')}
                </Typography>
            </div>
        }

        {last_playdatetime &&
            <div className={classes.liveAdText}>
                <TimerIcon className={classes.iconItem} />
                <Typography variant="body2" className={classes.liveAdOverFlowText}>
                    {t('Last Play')}<span> </span>
                    <DateTimeRelative date={toLocalDateTime(last_playdatetime)} />
                    {
                        showDelayedWarning()
                        && <span> <i style={{ color: "#de8426" }}>{t('Reporting Delayed')}</i></span>
                    }
                </Typography>
            </div>
        }
    </>
}

const mapStateToProps = state => {
    return {
        activeAds: state.dashboard.activeAds,
        hasExports: hasExports(state),
    }
}

export default connect(
    mapStateToProps
)(
    DashboardsLiveAdsList
)
