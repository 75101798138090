import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, CardActions, Button, GridList, GridListTile, Box, makeStyles } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { Delete, Edit } from '@material-ui/icons'
import { ConfirmationDialog, ImagePreviewDialog } from '../../components'
import { connect } from 'react-redux';
import { deleteImage, setAsMainImage } from '../../actions/billboards';
import { showError, showSuccess } from '../../actions/snackbar';
import NewImageDialog from './NewImageDialog';
import { ImageStyle } from '../../helpers/constants';
import { IonImg } from '@ionic/react';
import { useWidth } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        tile: {
            padding: theme.spacing(1.5),
            display: 'flex',
            justifyContent: 'center'
        },
        media: {
            cursor: 'pointer',
            height: 160,
            backgroundSize: 'cover'
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',

            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
        },

        remove: {
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const colsMap = {
    "xs": 1,
    "sm": 1,
    "md": 2,
    "lg": 3,
    "xl": 4
}

const IsMainImageButton = ({ image, board, enableEdit, setAsMainImage }) => {

    const [isMainImage, setIsMainImage] = useState(image.is_main_image);

    useEffect(() => {

        if (image.is_main_image !== isMainImage)
            setIsMainImage(image.is_main_image)

    }, [image.is_main_image])

    return (
        isMainImage
            ? <LCTypography variant="body2" color="textSecondary">Main Image</LCTypography>
            : enableEdit && <Button onClick={() => setAsMainImage(board, image) && setIsMainImage(true)}
                size="small"
                color="primary">Set as Main</Button>
    )
}

function BillboardImages({ board, deleteImage, setAsMainImage, enableEdit = true }) {
    const classes = useStyles();
    const [removingImg, setRemovingImg] = useState(null);
    const [editingImg, setEditingImg] = useState(null);
    const [previewImg, setPreviewImg] = useState(null);

    const images = board.images.filter(i => i.style === ImageStyle.normal);
    const width = useWidth();
    const cols = colsMap[width];

    if (!images.length) {
        return enableEdit
            ? <Box>
                <LCTypography>Click &apos;Add&apos; to upload screen images</LCTypography>
            </Box>
            : <></>;
    }

    return <>
        <GridList
            style={{ width: '100%' }}
            cols={cols < images.length
                ? cols
                : images.length}
            cellHeight={'auto'}>
            {images.map(image => <GridListTile
                key={image.id}
                classes={{ tile: classes.tile }}>

                <Card className={classes.card} elevation={3} style={{ maxWidth: 400 }}>
                    <CardMedia
                        onClick={() => setPreviewImg(image)}
                        className={classes.media}
                        image={image.image && image.image.options.public_url}
                        title={image.name}
                    />
                    <CardContent style={{ padding: '8px 16px' }}>
                        <LCTypography transProps={{ name: image.name }} gutterBottom variant="body1">
                            {{ name: image.name }}
                        </LCTypography>
                    </CardContent>
                    <CardActions style={{ paddingTop: 0, justifyContent: 'flex-end' }}>
                        <IsMainImageButton image={image}
                            enableEdit={enableEdit}
                            setAsMainImage={setAsMainImage}
                            board={board} />

                        {enableEdit
                            && <>
                                <Button size="small"
                                    startIcon={<Edit />}
                                    onClick={() => setEditingImg(image)}
                                    color="primary">
                                    Edit
                                </Button>
                                <Button className={classes.remove}
                                    size="small"
                                    onClick={() => setRemovingImg(image)}
                                    startIcon={<Delete />}
                                    color="primary">
                                    Delete
                                </Button>
                            </>}
                    </CardActions>
                </Card>

            </GridListTile>)}
        </GridList>

        <ConfirmationDialog
            title={removingImg && removingImg.name}
            dialogProps={{
                open: Boolean(removingImg),
                onClose: () => setRemovingImg(null),
                keepMounted: false
            }}
            handleCancel={() => setRemovingImg(null)}
            handleConfirm={() => {
                return deleteImage(board, removingImg)
                    .then(() => setRemovingImg(null));
            }}>
            Are you sure you want to remove this item?
        </ConfirmationDialog>

        {editingImg
            && <NewImageDialog
                step="Select Screen"
                disabledSteps={['Upload Image']}
                screen={board}
                screenImage={editingImg}
                title={`Edit ${editingImg.name} for ${board.name}`}
                open={Boolean(editingImg)}
                handleClose={() => setEditingImg(null)} />}

        {previewImg
            && <ImagePreviewDialog
                title={`${previewImg.name} for ${board.name}`}
                open={Boolean(previewImg)}
                handleClose={() => setPreviewImg(null)}>
                <IonImg src={previewImg.image && previewImg.image.options.public_url} alt="zoomed" />
            </ImagePreviewDialog>}
    </>;
}

const mapStateToDispatch = (dispatch) => {
    return {
        setAsMainImage: (board, image) => dispatch(setAsMainImage(board, image)),
        deleteImage: (screen, image) => dispatch(deleteImage(screen, image))
            .then(() => dispatch(showSuccess("Digital Screen image was successfully deleted")))
            .catch(error => {
                dispatch(showError("Error during deleting Digital Screen image"))
                throw error;
            })
    }
}

export default connect(
    null,
    mapStateToDispatch
)(
    BillboardImages
)
