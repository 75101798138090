import { IonContent, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { makeStyles, Hidden } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { BottomSheetContext } from '../contexts';
import { isLoggedIn } from '../selectors/user';

import PostButtonMobile from '../containers/inventory/PostButton'
import { refreshOutline } from 'ionicons/icons';

const useStyles = makeStyles(() => ({
    modalWindow: {
        marginTop: '10%',
        zIndex: '1300 !important',
        '&::part(scroll)': {
            overflowY: 'hidden'
        },
        '&::part(handle)': {
            width: 150,
            height: 6,
            background: '#02c9c9',
        }
    },
    feedTitle: {
        paddingInline: 0,
    },
    bottomSheetToolBar: {
        width: 'auto',
    },
}));

function BottomSheetProvider(props) {
    const { isOpenDefault } = props;

    const classes = useStyles();
    const modal = useRef(null);
    const modalContent = useRef(null);
    const [isOpen, setOpen] = useState(Boolean(isOpenDefault));
    const [title, setTitle] = useState('');

    const context = useMemo(() => {
        return {
            isOpen,
            title,
            open: () => setOpen(true),
            close: () => setOpen(false),
            modal,
            modalContent,

            setTitle
        }
    }, [isOpen, modal.current, modalContent.current]);

    useEffect(() => {
    }, [])

    return <BottomSheetContext.Provider value={context}>
        {props.children}

        <Hidden mdUp>
            <IonModal
                ref={modal}
                isOpen={isOpen}
                initialBreakpoint={0.45}
                breakpoints={[0.1, 0.45, 1]}
                backdropDismiss={false}
                backdropBreakpoint={0.7}
                keepContentsMounted
                handleBehavior="cycle"
                className={classes.modalWindow}
            >
                <IonHeader>
                    <IonToolbar className={classes.bottomSheetToolBar}>
                        <IonButtons slot="start">
                            <IonButton>
                                <IonIcon icon={refreshOutline} />
                            </IonButton>

                        </IonButtons>
                        <IonButtons slot="end">
                            <PostButtonMobile />
                        </IonButtons>
                        {title
                            && <IonTitle className={classes.feedTitle}>{title}</IonTitle>}
                    </IonToolbar>
                </IonHeader>

                <IonContent
                    className="ion-padding"
                    ref={modalContent} />
            </IonModal>
        </Hidden>
    </BottomSheetContext.Provider >
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(BottomSheetProvider)
);
