import { Button, Grid, makeStyles, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { DesignerCodeEditors } from './CodeEditor';
import Alert from '@material-ui/lab/Alert';
import { setHTML, setCSS, setJS } from '../../actions/designer'
import prettier from 'prettier';
import parserHtml from 'prettier/parser-html';
import parserCss from 'prettier/parser-postcss';
import parserJs from 'prettier/parser-babel';
import { useEffect } from 'react';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0,
            overflowY: 'initial'
        }
    }
});

const GlobalCodeEditDialog = ({ open, handleClose }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const editModeGlobal = useSelector(state => state.designer.editModeGlobal);
    const stateHtml = useSelector(state => state.designer.html);
    const stateCss = useSelector(state => state.designer.css);
    const stateJs = useSelector(state => state.designer.js);

    const [html, setLocalHtml] = useState(stateHtml);
    const [css, setLocalCss] = useState(stateCss);
    const [js, setLocalJs] = useState(stateJs);

    const [error, setError] = useState(null);

    const saveCodeChanges = () => {

        dispatch(setHTML(html, editModeGlobal));
        dispatch(setCSS(css, editModeGlobal));
        dispatch(setJS(js, editModeGlobal));
        handleClose();
    }

    const formatHtml = (htmlString) => {
        try {
            return prettier.format(htmlString, {
                parser: 'html',
                plugins: [parserHtml],
                singleAttributePerLine: true,
                bracketSameLine: true
            });
        } catch (error) {
            console.error('Error formatting HTML:', error);
            return htmlString; // Return the original string if formatting fails
        }
    }

    const formatCss = (cssString) => {
        try {
            return prettier.format(cssString, {
                parser: 'css',
                plugins: [parserCss],
                singleAttributePerLine: true
            });
        } catch (error) {
            console.error('Error formatting CSS:', error);
            return cssString; // Return the original string if formatting fails
        }
    }

    const formatJs = (jsString) => {
        try {
            return prettier.format(jsString, {
                parser: 'babel',
                plugins: [parserJs],
                singleAttributePerLine: true
            });
        } catch (error) {
            console.error('Error formatting JS:', error);
            return jsString; // Return the original string if formatting fails
        }
    }

    useEffect(() => {
        const newHtml = formatHtml(stateHtml);
        setLocalHtml(newHtml);
    }, [open, stateHtml]);

    useEffect(() => {
        const newCss = formatCss(stateCss);
        setLocalCss(newCss);
    }, [open, stateCss]);

    useEffect(() => {
        const newJs = formatJs(stateJs);
        setLocalJs(newJs);
    }, [open, stateJs]);

    return (
        <>
            <GenericDialog
                title={t('Global Code Editor')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: true,
                    maxWidth: 'md',
                    allowDragAndMove: true
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LCTypography>
                            This is an advanced feature with little to no documentation. Please use with caution.
                        </LCTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={2}>

                            {!editModeGlobal && <Alert severity="warning">
                                {t('You are in individual edit mode.  Changes to HTML and CSS will not function as expected.'
                                    + ' HTML and Javascript Changes are global and will affect ALL sizes, '
                                    + ' GLobal CSS Changes are disabled, Use the right-click "Edit CSS" menu on each '
                                    + ' individual element to edit the CSS for each element')}
                            </Alert>}
                            <DesignerCodeEditors
                                html={html}
                                css={css}
                                js={js}
                                setError={((error) => setError(error))}
                                setHTML={(html) => setLocalHtml(html)}
                                setCSS={(css) => setLocalCss(css)}
                                setJS={(js) => setLocalJs(js)}
                            />

                        </Box>
                    </Grid>
                </Grid>}

                ActionsComponent={<>
                    <Button onClick={() => {
                        handleClose();
                    }}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>

                    <Button
                        onClick={() => {
                            saveCodeChanges()
                        }}
                        disabled={error}
                        color="primary">
                        {t('Save')}
                    </Button>
                </>}
            />
        </>
    )
}

export default GlobalCodeEditDialog;
