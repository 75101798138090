import React from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, DialogContent, DialogActions, Button, GridList, GridListTile, useTheme, useMediaQuery } from '@material-ui/core';
import { Dialog } from '../material';

const useStyles = makeStyles(theme => {
    return {
        content: {
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2),
        },

        imageList: {
            overflow: 'unset',
            margin: '0 !important'
        },
        imageTileEven: {
            objectPosition: 'left'
        },
        imageTileOdd: {
            objectPosition: 'right'
        },
        imgContain: {
            objectFit: 'contain',
            height: '100%',
            width: '100%'
        }
    }
})

const ImageGalleryDialog = (props) => {
    const { open, title, handleClose, dialogProps, photos } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullWidth
            fullScreen={isXs}
            lazy

            maxWidth={false}
            open={open}
            aria-labelledby="image-preview-dialog-title"
            aria-describedby="image-preview-dialog-description"
            onClose={handleClose}
            title={title}
            {...dialogProps}
        >
            <DialogContent className={classes.content} id="image-preview-dialog-description" >
                <GridList cellHeight={isXs ? 120 : 180} spacing={isXs ? 2 : 24} className={classes.imageList}>
                    {photos.map((photo, idx) => (
                        <GridListTile key={idx}
                            cols={isXs ? 2 : 1}
                            rows={isXs ? null : 1}
                        >
                            <img
                                src={photo}
                                className={clx(classes.imgContain, {
                                    [classes.imageTileOdd]: !isXs && idx % 2 === 0,
                                    [classes.imageTileEven]: !isXs && idx % 2 !== 0,
                                })}
                                alt={'Creative'} />
                        </GridListTile>
                    ))}
                </GridList>
            </DialogContent >
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog >
    )
}

ImageGalleryDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    photos: PropTypes.array.isRequired
}

export default ImageGalleryDialog
