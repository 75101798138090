import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";

import {
    LAYOUT_TOOGLE_SIGNIN_DIALOG, LAYOUT_CLOSE_SCHEDULE_BUILDER, LAYOUT_LOADED,
    LAYOUT_OPEN_SCHEDULE_BUILDER, LAYOUT_TOGGLE_ACCOUNT_SWITCHER, LAYOUT_TOGGLE_AGENCY_SWITCHER, LAYOUT_TOGGLE_LEFT_MENU,
    LAYOUT_TOOGLE_INVENTORY_TOGGLE_SEARCH, LAYOUT_TOGGLE_PROFILE_SWITCHER, LAYOUT_TOGGLE_PROFILE_MENU, LAYOUT_TOOGLE_LEFT_MENU_DESKTOP,
    LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG,
    USER_SWITCHING_PROFILE,
    USER_SELECT_ACCOUNT,
    USER_SELECT_AGENCY,
    USER_SELECT_USER
} from "../helpers/actionTypes";
import { MenuLinkSection, MenuLinksOrder, RoutePaths, AgencyClasses, ApplicationFeatures } from "../helpers/constants";
import { AgencyPolicy, AccountPolicy } from "../helpers/lucoreConstants";

export const initialState = {
    loaded: false,

    accountSwitcherOpen: false,
    agencySwitcherOpen: false,
    profileMenuOpen: false,
    profileSwitcherOpen: false,

    profileSwitchAnimateDelay: 1500,
    profileSwitch: null,

    leftMenuOpen: false,
    leftMenuDesktopOpen: true,

    signInOpen: false,

    pushNotificationsInfoOpen: false,

    inventorySearch: {
        open: false
    },

    scheduleBuilder: {
        open: false,
        item: {},
        exportId: null
    },

    links: [{
        title: 'Home',
        route: RoutePaths.home,
        order: MenuLinksOrder.home,
        section: MenuLinkSection.general,
        hidden: false
    }, {
        title: 'Map',
        route: RoutePaths.map,
        order: MenuLinksOrder.map,
        section: MenuLinkSection.agency,
        hidden: false
    },
    {
        title: 'Campaigns',
        route: RoutePaths.campaigns,
        order: MenuLinksOrder.exports + 1,
        section: MenuLinkSection.account,
        permission: AccountPolicy.viewCampaigns,
        hidden: false
    },
    {
        title: 'Templates',
        route: RoutePaths.designerTemplates,
        order: MenuLinksOrder.designer,
        section: MenuLinkSection.account,
        permission: AccountPolicy.editDriveTemplates,
        hidden: false,
    },
    {
        title: 'Apps & Data',
        route: RoutePaths.appsAndData,
        order: MenuLinksOrder.appsAndData,
        section: MenuLinkSection.account,
        permission: AccountPolicy.editApps,
        hidden: false
    },
    {
        title: 'Analytics',
        route: RoutePaths.analytics,
        order: MenuLinksOrder.analytics,
        section: MenuLinkSection.account,
        permission: AccountPolicy.viewAnalytics,
        hidden: false
    },
    {
        title: 'Users & Invites',
        route: RoutePaths.accountUsers,
        order: MenuLinksOrder.accountUsers,
        section: MenuLinkSection.account,
        permission: AccountPolicy.inviteUsers,
        hidden: false
    },
    {
        title: 'Billing',
        route: RoutePaths.accountBilling,
        order: MenuLinksOrder.accountBilling,
        section: MenuLinkSection.account,
        permission: AccountPolicy.canEditPayments,
        hidden: false
    },
    {
        title: 'Settings',
        route: RoutePaths.accountSettings,
        order: MenuLinksOrder.accountSettings,
        section: MenuLinkSection.account,
        permission: AccountPolicy.update,
        hidden: false
    },
    {
        title: 'Settings',
        route: RoutePaths.settings,
        order: MenuLinksOrder.settings,
        section: MenuLinkSection.user,
        hidden: false
    },
    {
        title: 'Help',
        route: RoutePaths.help,
        order: MenuLinksOrder.help,
        section: MenuLinkSection.general,
        hidden: false
    },
    {
        title: 'Accounts',
        route: RoutePaths.agencyAccounts,
        order: MenuLinksOrder.agencyAccounts,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.generic.class, AgencyClasses.operator.class, AgencyClasses.adagency.class],
        hidden: false
    },
    {
        title: 'Child Accounts',
        route: RoutePaths.accountChildren,
        order: MenuLinksOrder.accountChildren,
        section: MenuLinkSection.account,
        hidden: false
    },
    {
        title: 'Approvals',
        route: RoutePaths.approvals,
        order: MenuLinksOrder.approvals,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.operator.class],
        hidden: false
    },
    {
        title: 'Campaigns',
        route: RoutePaths.agencyCampaigns,
        order: MenuLinksOrder.agencyCampaigns,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.operator.class],
        hidden: false
    },
    {
        title: 'Team',
        route: RoutePaths.agencyUsers,
        order: MenuLinksOrder.agencyUsers,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.generic.class, AgencyClasses.operator.class, AgencyClasses.adagency.class],
        hidden: false
    },
    {
        title: 'Activity',
        route: RoutePaths.agencyFeed,
        order: MenuLinksOrder.agencyFeed,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.generic.class, AgencyClasses.operator.class, AgencyClasses.adagency.class],
        hidden: false
    },
    {
        title: 'Billing',
        route: RoutePaths.agencyBilling,
        order: MenuLinksOrder.agencyBilling,
        section: MenuLinkSection.agency,
        permission: AgencyPolicy.canEditPayments,
        hidden: false
    },
    {
        title: 'Settings',
        route: RoutePaths.agencySettings,
        order: MenuLinksOrder.agencySettings,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.generic.class, AgencyClasses.operator.class, AgencyClasses.adagency.class],
        hidden: false
    },
    {
        title: 'Analytics',
        route: RoutePaths.analytics,
        order: MenuLinksOrder.agencyAnalytics,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.operator.class],
        hidden: false
    },
    {
        title: 'Screens',
        route: RoutePaths.agencyScreens,
        order: MenuLinksOrder.agencyScreens,
        section: MenuLinkSection.agency,
        agencyClasses: [AgencyClasses.operator.class],
        hidden: false
    },
    {
        title: 'Templates',
        route: RoutePaths.designerTemplates,
        order: MenuLinksOrder.designer,
        section: MenuLinkSection.agency,
        hidden: false,
        agencyClasses: [AgencyClasses.adagency.class],
    },
    {
        title: 'Apps',
        route: RoutePaths.dev.applications,
        order: MenuLinksOrder.applications,
        section: MenuLinkSection.user,
        hidden: false,
    },
    {
        title: 'Elements',
        route: RoutePaths.dev.designerElements,
        order: MenuLinksOrder.designerElements,
        section: MenuLinkSection.user,
        hidden: false,
    },

    {
        title: 'Lightning',
        route: RoutePaths.apps.lightning,
        order: MenuLinksOrder.agencyScreens - 1,
        section: MenuLinkSection.agency,
        appLcuid: ApplicationFeatures.lightning.lcuid
    }]
}

export const layoutPersistConfig = {
    key: 'layout',
    whitelist: ['inventorySearch'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
}

export default function loader(state = initialState, action) {
    switch (action.type) {
        case USER_SWITCHING_PROFILE:
            return Object.assign({},
                state,
                {
                    profileSwitch: action.profile,
                    profileSwitchAnimateDelay: action.delay || initialState.profileSwitchAnimateDelay,
                    profileSwitched: action.onSwitched
                });
        case USER_SELECT_ACCOUNT:
        case USER_SELECT_AGENCY:
        case USER_SELECT_USER:
            return Object.assign({},
                state,
                {
                    profileSwitch: null,
                });

        case LAYOUT_LOADED:
            return Object.assign({},
                state,
                {
                    loaded: true
                });

        case LAYOUT_TOGGLE_ACCOUNT_SWITCHER:
            return Object.assign({},
                state,
                {
                    accountSwitcherOpen: action.open
                });

        case LAYOUT_TOGGLE_PROFILE_MENU:
            return Object.assign({},
                state,
                {
                    profileMenuOpen: action.open
                });
        case LAYOUT_TOGGLE_PROFILE_SWITCHER:
            return Object.assign({},
                state,
                {
                    profileSwitcherOpen: action.open
                });

        case LAYOUT_TOGGLE_AGENCY_SWITCHER:
            return Object.assign({},
                state,
                {
                    agencySwitcherOpen: action.open
                });

        case LAYOUT_TOGGLE_LEFT_MENU:
            return Object.assign({},
                state,
                {
                    leftMenuOpen: action.open
                });

        case LAYOUT_TOOGLE_SIGNIN_DIALOG:
            return Object.assign({},
                state,
                {
                    signInOpen: action.open
                });

        case LAYOUT_OPEN_SCHEDULE_BUILDER:
            return Object.assign({},
                state,
                {
                    scheduleBuilder: {
                        open: true,
                        item: action.item,
                        exportId: action.exportId
                    }
                });
        case LAYOUT_CLOSE_SCHEDULE_BUILDER:
            return Object.assign({},
                state,
                {
                    scheduleBuilder: {
                        open: false,
                        item: {},
                        exportId: null
                    }
                });
        case LAYOUT_TOOGLE_INVENTORY_TOGGLE_SEARCH:
            return Object.assign({},
                state,
                {
                    inventorySearch: {
                        ...state.inventorySearch,
                        open: action.open
                    }
                });
        case LAYOUT_TOOGLE_LEFT_MENU_DESKTOP:
            return Object.assign({},
                state,
                {
                    leftMenuDesktopOpen: action.leftMenuDesktopOpen
                });
        case LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG:
            return Object.assign({},
                state,
                {
                    pushNotificationsInfoOpen: action.pushNotificationsInfoOpen,
                });
        default:
            return state
    }
}
