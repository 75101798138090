import { groupBy } from "../helpers/array";
import { InventoryItemClasses } from "../helpers/constants";
import { inventoryItemClass } from "./inventory";

export const filters = state => state.approvals.filters;
export const hasNextPage = state => state.approvals.currentPage < state.approvals.lastPage;
export const nextPage = state => state.approvals.currentPage + 1;
export const searched = state => state.approvals.searched;
export const currentPage = state => state.approvals.currentPage;
export const approvals = state => state.approvals.data;
export const approvalsTotal = state => state.approvals.total;
export const approvalsScrollY = state => state.approvals.scrollY;
export const hasScrollY = state => !!state.approvals.scrollY;
export const isInvalidated = state => state.approvals.invalidated;

export const rejectionCodes = state => Object.keys(state.approvals.rejectionCodes)
    .map(code => ({
        id: code,
        name: state.approvals.rejectionCodes[code].name
    }));

export const rejectionCodeSelector = state => id => {
    const codes = rejectionCodes(state);
    const code = codes.find(x => x.id == id);

    return code
        ? code.name
        : null;
}

export const approvalGroups = (approvals) => {
    // This grouping gives us following structure
    // {
    //     "1020": [{ ... }, { ... }],
    //     "1021": [{ ... }],
    //     "1022": [{ ... }],
    // }
    const approvalsMap = groupBy(approvals, approval => approval.inventory_item_id)

    // This gives us this structure:
    // [
    //    {
    //       "agency": { ... },
    //       "account": { ... },
    //       "user": { ... },
    //       "inventoryItem": { ... },
    //       "approvals": [ ... ]
    //    }
    // ]
    return Object.keys(approvalsMap)
        .reduce((result, key) => {
            const approvals = approvalsMap[key];
            const inventoryItem = approvals[0].inventory_item;

            if (inventoryItemClass(inventoryItem) == InventoryItemClasses.creative) {
                return [
                    ...result,
                    ...approvals.map(approval => ({
                        agency: approval.agency,
                        account: approval.account,
                        user: approval.inventory_item.user,
                        created_by_user: approval.inventory_item.created_by_user,
                        inventoryItem: approval.inventory_item,
                        approvals: [approval]
                    }))
                ];
            }

            return [
                ...result,
                {
                    agency: approvals[0].agency,
                    account: approvals[0].account,
                    user: inventoryItem.user,
                    created_by_user: inventoryItem.created_by_user,
                    inventoryItem,
                    approvals
                }
            ]
        }, [])
};

export const lastComment = approval => {
    if (approval.comments.length > 0) {
        return approval.comments[approval.comments.length - 1].comment;
    }

    return null;
}
