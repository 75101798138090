import React from 'react';
import { withHomeLayout } from '../../layouts/HomeLayout';

import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import DashboardMy from '../../containers/dashboard/DashboardMy';

const Home = () => {
    return (
        <>
            <PageTitle title={"My Home"} />

            <DashboardMy />

            <ButtonScrollToTopFab />
        </>
    );
};

export default withHomeLayout(Home);
