import React from 'react';
import { Chip } from '@material-ui/core';
import { isStatsDelayed } from '../../selectors/exports';
import i18next from '../../i18n';

const StatsDelayedChip = ({ exportObject, chipProps }) => {

    if (!isStatsDelayed(exportObject))
        return null;

    return (
        <Chip component="span"
            size="small"
            label={i18next.t('Stats Delayed')}
            {...chipProps} />
    )
}

export default StatsDelayedChip
