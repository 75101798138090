import React, { Component } from "react";
import PropTypes from 'prop-types';

import { logger } from "../../helpers/logger";
import ErrorPage from "./ErrorPage";

export class ErrorBoundary extends Component {
    constructor () {
        super();

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logger.logError(error.message, {
            stack: error.stack,
            componentStack: errorInfo.componentStack
        })
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage {...this.props} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    isFatal: PropTypes.bool,
    fullSize: PropTypes.bool
}
