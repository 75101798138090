import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import MenuItem from '@material-ui/core/MenuItem'
import { duplicateTemplate } from '../../actions/designer';
import { selectedAccount } from '../../selectors/user';
import { FormControl, InputLabel, Select, FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
import { RoutePaths } from '../../helpers/constants';
import { useHistory } from 'react-router';
import { userHasApplication } from '../../selectors/user'
import ButtonLoader from '../../components/material/ButtonLoader';
import { lucitApi } from '../../services/lucitApi';
import { getApplications } from '../../actions/applications';
import { objectSelector } from '../../selectors/objects';
import { hasCapability } from '../../selectors/applications';
import { ApplicationCapabilities } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const DesignerPublishTemplateDialog = (props) => {
    const {
        driveTemplateLcuid, driveTemplate, open, handleClose, duplicateTemplate,
        selectedAccount, userHasApplication,
        getApplications, selectAssignedApps
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)

    const [accountLcuid, setAccountLcuid] = useState()

    const [copyToADataSourceProvider, setCopyToADataSourceProvider] = useState(false)
    const [copyToAnApp, setCopyToAnApp] = useState(false)

    const [feedProviders, setFeedProviders] = useState([])

    const [accountFeeds, setAccountFeeds] = useState([])
    const [selectedFeedLcuid, setSelectedFeedLcuid] = useState(null)

    const templateHasDataSourceScopedMacros = driveTemplate.options?.data_source_macro_scope_cache?.has_data_source_scoped_macros

    const apps = selectAssignedApps(selectedAccount.lcuid).filter(app => hasCapability(app, ApplicationCapabilities.designer));

    const getButtonName = () => {
        return t('Publish')
    }

    useEffect(() => {
        if (selectedAccount) {
            setLoading(true)
            lucitApi.feeds.getActiveFeedsForAccount(selectedAccount.id)
                .then(result => {
                    setAccountFeeds(result.data)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [selectedAccount?.id])

    const accountFeedsMatchingFeedProviderForDataSourceScopedMacros = () => {
        if (!templateHasDataSourceScopedMacros)
            return [];

        const feedProviderLcuids = driveTemplate.options.data_source_macro_scope_cache.linked_data_source_scoped_macro_feed_providers;

        return accountFeeds.filter(feed => feedProviderLcuids.includes(feed.inventoryFeedProvider.lcuid));
    }

    const hasMoreThanOneMatchingFeed = accountFeedsMatchingFeedProviderForDataSourceScopedMacros().length > 1

    const handleDuplicateTemplate = () => {

        setLoading(true)

        if (accountFeedsMatchingFeedProviderForDataSourceScopedMacros().length > 1) {

            const selectedFeed = accountFeeds.find(feed => feed.lcuid === selectedFeedLcuid)

            duplicateTemplate(driveTemplate.lcuid, accountLcuid, selectedFeed.lcuid).then((driveTemplate) => {

                history.push({ pathname: RoutePaths.designerTemplates + `/${driveTemplate.lcuid}` });

                setLoading(false)

                handleClose()
            })

        }
        else if (accountFeedsMatchingFeedProviderForDataSourceScopedMacros().length == 1) {

            const firstFeed = accountFeedsMatchingFeedProviderForDataSourceScopedMacros()[0];

            duplicateTemplate(driveTemplate.lcuid, accountLcuid, firstFeed.lcuid).then((driveTemplate) => {

                history.push({ pathname: RoutePaths.designerTemplates + `/${driveTemplate.lcuid}` });

                setLoading(false)

                handleClose()
            })
        }
        else {

            duplicateTemplate(driveTemplateLcuid, accountLcuid).then((driveTemplate) => {

                history.push({ pathname: RoutePaths.designerTemplates + `/${driveTemplate.lcuid}` });

                setLoading(false)

                handleClose()
            })
        }

    }

    useEffect(() => {

        if (selectedAccount?.lcuid) {
            getApplications(selectedAccount.lcuid)
        }

    }, [selectedAccount])

    useEffect(() => {

        if (selectedAccount) {
            lucitApi.feeds.getActiveFeedsForAccount(selectedAccount.id)
                .then(result => {

                    const feedProvidersUserHasAccessTo = result.data
                        .map(feedProvider => feedProvider.inventoryFeedProvider)
                        .filter((feedProvider) => userHasApplication(feedProvider.application_id))

                    setFeedProviders(feedProvidersUserHasAccessTo)

                });

        }

    }, [selectedAccount])

    return (<>
        <GenericDialog
            title={t('Publish Template')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <LCTypography variant="body2">
                            Publish this template
                            <br /><br />
                            Create groups of templates to share with others, or sample templates for data sources
                            by publishing them to one or more of our designer apps
                            or data sources
                        </LCTypography>
                    </Grid>

                    {hasMoreThanOneMatchingFeed && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="feed-select-label">{t('Select a feed to attach to this template')}</InputLabel>
                                <Select
                                    labelId="feed-select-label"
                                    id="feed-select"
                                    value={selectedFeedLcuid}
                                    onChange={(e) => setSelectedFeedLcuid(e.target.value)}
                                >
                                    {accountFeedsMatchingFeedProviderForDataSourceScopedMacros().map(feed => (
                                        <MenuItem key={feed.lcuid} value={feed.lcuid}>{feed.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={8}>
                        <Box p={2} mb={12}>
                            <Box mb={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t('Publish To')}</FormLabel>
                                    <RadioGroup
                                        aria-label="copy-option"
                                        value={
                                            copyToAnApp
                                                ? "app"
                                                : copyToADataSourceProvider
                                                    ? "dataSourceProvider"
                                                    : ""
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setCopyToAnApp(value === "app");
                                            setCopyToADataSourceProvider(value === "dataSourceProvider");
                                        }}
                                    >
                                        {apps && (
                                            <FormControlLabel
                                                value="app"
                                                control={<Radio />}
                                                label={t('Publish to a designer app')}
                                            />
                                        )}
                                        {feedProviders && (
                                            <FormControlLabel
                                                value="dataSourceProvider"
                                                control={<Radio />}
                                                label={t('Publish to a data source provider app')}
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            {copyToAnApp && (
                                <>
                                    {apps.length > 0 ? (

                                        <FormControl fullWidth className={classes.formRows}>
                                            <InputLabel id="app-select-label">{t('Copy to App : ')}</InputLabel>
                                            <Select
                                                value={accountLcuid}
                                                label={t('Copy to App : ')}
                                                onChange={(e) => setAccountLcuid(e.target.value)}
                                            >
                                                {apps.map((acc, index) => (
                                                    <MenuItem key={index} value={acc.lcuid} style={{
                                                        display: 'flex', alignItems: 'center'
                                                    }}>
                                                        {acc.options.primary_image_public_url && (
                                                            <img
                                                                style={{ width: "25px", maxHeight: "25px", marginRight: "5px" }}
                                                                src={acc.options.primary_image_public_url}
                                                            />
                                                        )}
                                                        {acc.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Box className={classes.error}>
                                            <LCTypography variant="body2" color="error">
                                                {t('You do not have any designer apps')}
                                            </LCTypography>
                                        </Box>
                                    )}
                                </>
                            )}

                            {copyToADataSourceProvider && (
                                <>{
                                    feedProviders.length > 0 ? (
                                        <FormControl fullWidth className={classes.formRows}>
                                            <InputLabel id="data-source-provider-select-label">
                                                {t('Copy to Data Source Provider : ')}
                                            </InputLabel>
                                            <Select
                                                value={accountLcuid}
                                                label={t('Copy to Data Source Provider')}
                                                onChange={(e) => setAccountLcuid(e.target.value)}
                                            >
                                                {feedProviders.map((acc, index) => (
                                                    <MenuItem key={index} value={acc.lcuid} style={{
                                                        display: 'flex', alignItems: 'center'
                                                    }}>
                                                        {acc.options.primary_image_public_url && (
                                                            <img
                                                                style={{ width: "25px", maxHeight: "25px", marginRight: "5px" }}
                                                                src={acc.options.primary_image_public_url}
                                                            />
                                                        )}
                                                        {acc.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Box className={classes.error}>
                                            <LCTypography variant="body2" color="error">
                                                {t('You do not have any data source provider apps')}
                                            </LCTypography>
                                        </Box>
                                    )
                                }
                                </>
                            )}
                        </Box>
                    </Grid>

                </Grid>

            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader onClick={handleDuplicateTemplate}
                    color="secondary"
                    variant="contained"
                    submitting={loading}
                    disabled={loading || (!accountLcuid) || (!copyToAnApp && !copyToADataSourceProvider)}
                >
                    {getButtonName()}
                </ButtonLoader>
            </>}
        />
    </>)
}

const mapStateToProps = (state) => {
    return {
        selectedAccount: selectedAccount(state),
        userHasApplication: (appId) => userHasApplication(state.user, appId),
        selectAssignedApps: lcuid => lcuid ? objectSelector(state)(lcuid).apps : []
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApplications: (lcuid) => dispatch(getApplications(lcuid)),
        duplicateTemplate: (driveTemplateLcuid, accountLcuid, feedLcuid) =>
            dispatch(duplicateTemplate(driveTemplateLcuid, accountLcuid, feedLcuid)),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DesignerPublishTemplateDialog)
)
