import {
    OBJECT_REACTIONS, OBJECT_NEW_COMMENT, OBJECT_NEW_REACTION, OBJECT_DELETE_REACTION,
    OBJECT_COMMENTS, CREATIVEPROOF_ITEM, OBJECT_ITEM, OBJECT_ATTACHMENTS, OBJECT_ATTACHMENT_ADD, OBJECT_ATTACHMENT_FILE
} from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import attachments from "./templates/attachments";
import reactions from "./templates/reactions";

export const initialState = {
    all: [],
    byId: {}
}

export default function creativeProofs(state = initialState, action) {
    switch (action.type) {
        case OBJECT_ITEM: {
            if (action.object_class !== LuCoreModelClasses.creativeProof) {
                return state;
            }

            action.creativeProof = action.object;
        }
        // eslint-disable-next-line
        case CREATIVEPROOF_ITEM: {
            return {
                all: [...state.all, action.creativeProof.lcuid],
                byId: {
                    ...state.byId,
                    [action.creativeProof.lcuid]: action.creativeProof
                }
            }
        }

        case OBJECT_COMMENTS:
        case OBJECT_NEW_COMMENT:
        case OBJECT_REACTIONS:
        case OBJECT_NEW_REACTION:
        case OBJECT_DELETE_REACTION: {
            if (action.objectClass === LuCoreModelClasses.creativeProof) {
                return apply(state, action, (state) => reactions(state, action))
            }

            return state;
        }

        case OBJECT_ATTACHMENTS:
        case OBJECT_ATTACHMENT_FILE:
        case OBJECT_ATTACHMENT_ADD: {
            if (action.objectClass === LuCoreModelClasses.creativeProof) {
                return apply(state, action, (state) => attachments(state, action))
            }

            return state;
        }

        default:
            return state
    }
}

const apply = (state, action, applyReducer) => ({
    ...state,
    byId: {
        ...state.byId,
        [action.lcuid]: {
            ...state.byId[action.lcuid],
            ...applyReducer(state.byId[action.lcuid])
        }
    }
})
