import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';

const MapButton = (props) => {
    const { map,
        text,
        title,
        position,
        classes,
        onClick = () => { },
    } = props;
    const theme = useTheme();
    const [mapButton, setMapButton] = useState(null);

    useEffect(() => {
        if (map && !mapButton) {
            const btn = CreateButton({
                theme,
                classes,
                onClick,
                text,
                title
            });

            map.controls[position].push(btn);

            setMapButton(btn);
        }
        // eslint-disable-next-line
    }, [map]);

    useEffect(() => {
        if (mapButton) {
            mapButton.addEventListener("click", onClick);

            return () => mapButton.removeEventListener("click", onClick);
        }
    }, [mapButton, onClick])

    useEffect(() => {
        if (mapButton) {
            const buttonDiv = mapButton.querySelector("#button");
            const textDiv = mapButton.querySelector("#text");

            buttonDiv.title = title;
            textDiv.innerHTML = text || '';
        }
    }, [mapButton, title, text])

    useEffect(() => {
        if (mapButton) {
            const textDiv = mapButton.querySelector("#text");

            textDiv.className = classes.text;
        }
    }, [mapButton, classes])

    return null;
}

const CreateButton = (props) => {
    const {
        theme,
        title,
        text,
        classes = {} } = props;

    const controlDiv = document.createElement("div");

    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.id = "button";
    controlUI.className = classes.button;
    controlUI.style.boxShadow = theme.shadows[2];
    controlUI.style.cursor = "pointer";
    controlUI.title = title;
    controlUI.style.width = "40px";
    controlUI.style.height = "40px";
    controlUI.style.display = "flex";
    controlUI.style.background = "white";
    controlUI.style.alignItems = "center";
    controlUI.style.justifyContent = "center";
    controlUI.style.padding = "0px";
    controlUI.style.marginTop = "0px";
    controlUI.style.marginBottom = "0px";
    controlUI.style.marginRight = "10px";
    controlUI.style.borderRadius = "0px";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");
    controlText.id = "text";
    controlText.className = classes.text;
    controlText.style.width = "24px";
    controlText.style.height = "24px";
    controlText.style.backgroundSize = '24px';
    controlText.style.backgroundRepeat = 'no-repeat';
    controlText.style.backgroundPosition = '0px 0px';
    controlText.innerHTML = text || '';

    controlUI.appendChild(controlText);

    return controlDiv;
}

MapButton.propTypes = {
}

export default MapButton
