import React, { useMemo, useState } from 'react';
import {
    Box, Chip, Divider, IconButton, List, ListItemIcon, ListItemText,
    Menu, MenuItem, Typography, makeStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { InventoryExportCampaignClasses } from "../../helpers/constants";
import { TreeItem, TreeView } from '@material-ui/lab';
import { ArrowDropDown, ArrowRight, Edit, MoreVert } from '@material-ui/icons';
import { CampaignGroupIcon, SearchInput } from '../../components';
import { flatDeep } from '../../helpers/array';
import GroupCampaignDeleteButton from './GroupCampaignDeleteButton';
import AddCampaignGroupButton from './AddCampaignGroupButton';
import CampaignGroupDialog from './CampaignGroupDialog';
import { selectAgencyExports } from '../../selectors/lightning';
import { IconFromSet } from '../../components/IconFromSet';
import { getStylesByName } from '../../components/StylePicker';

const useStyles = makeStyles(() => {
    return {
        treeItemIconContainer: {
            width: 24,
            '& svg': {
                fontSize: 24
            }
        },

        treeItemLabel: {
        }
    }
})

const CampaignGroups = () => {
    const agencyExportsLoaded = useSelector(state => state.lightning.dataStatus.agencyExports);
    const agencyExports = useSelector(selectAgencyExports);
    const campaignGroups = agencyExports.filter(x =>
        x.campaign_class === InventoryExportCampaignClasses.group.class &&
        x.parent_exports.every(e => e?.campaign_class !== InventoryExportCampaignClasses.group.class)
    );

    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const campaignGroupsFiltered = useMemo(() => campaignGroups
        .map(g => flatDeep(g, x => x.child_exports))
        .flat()
        .filter(x => x.item.name?.toLowerCase().indexOf(search.toLowerCase()) > -1),
        [search])

    return <Box pl={1} pt={1}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <LCTypography>{'Campaign groups are for organizing campaigns into groups for scheduling'}</LCTypography>
            <AddCampaignGroupButton />
        </Box>

        {!agencyExportsLoaded
            && <CircularProgressCentered size={40} />}

        {agencyExportsLoaded
            && <>
                <Box maxWidth={600}>
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        placeholder={t('Search...')}
                    />
                    <Box mb={2} />

                    {search
                        ? <List disablePadding>
                            {campaignGroupsFiltered.map(x => <TreeItemLabel key={x.item.lcuid} item={x.item} />)}
                        </List>
                        : <TreeView
                            disableSelection
                            defaultCollapseIcon={<ArrowDropDown />}
                            defaultExpandIcon={<ArrowRight />}
                            defaultEndIcon={<div style={{ width: 24 }} />}
                        >
                            {campaignGroups.map(g => <TreeItemRow key={g.lcuid} item={g} />)}
                        </TreeView>}

                </Box>
            </>}
    </Box>
}

const TreeItemRow = ({ item }) => {
    const classes = useStyles();

    return <TreeItem
        classes={{ iconContainer: classes.treeItemIconContainer, label: classes.treeItemLabel }}
        key={item.lcuid}
        nodeId={item.lcuid}
        label={<TreeItemLabel item={item} />}
    >
        {item.child_exports?.map(e => (
            <TreeItemRow key={e.lcuid} item={e} />
        ))}
    </TreeItem>
}

const TreeItemLabel = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const { t } = useTranslation();

    return <Box
        pl={1} pr={1}
        display={'flex'}
        alignItems={'center'}
        style={{
            height: 40,
        }}
    >
        <Chip
            icon={<IconFromSet
                iconKey={item.options?.ui_options?.layout?.icon}
                style={{
                    color: getStylesByName(item.options?.ui_options?.layout?.styleName)?.color
                }}
                FallbackIcon={CampaignGroupIcon} />}
            label={<>
                {item.name}
                {item.campaign_class !== InventoryExportCampaignClasses.group.class
                    && <Typography component="span" variant='body2' color="textSecondary" style={{ marginLeft: 8 }}>
                        {item.account?.name}
                    </Typography>}
            </>}
            style={getStylesByName(item.options?.ui_options?.layout?.styleName)}
        />
        {/* <ListItemIcon style={{ display: 'flex', alignItems: 'center', minWidth: 32 }}>
            {item.campaign_class === InventoryExportCampaignClasses.group.class
                ? <IconFromSet
                    iconKey={item?.options?.ui_options?.layout?.icon}
                    FallbackIcon={CampaignGroupIcon} />
                : <ExportIcon />}
        </ListItemIcon>
        <Typography>
            {item.name}
            {item.campaign_class !== InventoryExportCampaignClasses.group.class
                && <Typography component="span" variant='body2' color="textSecondary" style={{ marginLeft: 8 }}>
                    {item.account?.name}
                </Typography>}
        </Typography> */}
        {item.campaign_class === InventoryExportCampaignClasses.group.class
            && <Box ml="auto" onClick={e => e.stopPropagation()}>
                <IconButton
                    onClick={e => setAnchorEl(e.currentTarget)}
                    size='small'>
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{ disablePadding: true }}
                >
                    <MenuItem onClick={() => {
                        setIsEditOpen(true)
                        setAnchorEl(null)
                    }}>
                        <ListItemIcon ><Edit /></ListItemIcon>
                        <ListItemText primary={t("Edit")} />
                    </MenuItem>
                    <Divider />
                    <GroupCampaignDeleteButton
                        groupCampaign={item}
                        handleDeleted={() => setAnchorEl(null)}
                    />
                </Menu>

                {isEditOpen
                    && <CampaignGroupDialog
                        open={isEditOpen}
                        handleClose={() => setIsEditOpen(null)}
                        group={item} />}
            </Box>}
    </Box>
}

export default CampaignGroups;
