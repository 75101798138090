import {
    FormControlLabel, FormControl, RadioGroup, Radio, Typography, Tooltip
} from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    clearAllSizeVariants, setEditModeGlobal, syncAllSizeVariants
} from '../../actions/designer';
import {
    editModeGlobal
} from '../../selectors/designer';

const DesignerEditMode = ({ editModeGlobal, clearAllSizeVariants, setEditModeGlobal, syncAllSizeVariants }) => {

    const { t } = useTranslation();

    const [radioValue, setRadioValue] = React.useState(editModeGlobal ? "global" : "individual")

    const handleChange = (e) => {

        if (e.target.value === "global") {
            //Changing Editor mode global
            //Clear all size variants

            if (!window.confirm(
                t('Warning, switching back to global editor mode will clear styles that are '
                    + 'individually applied to each template. '
                    + ' You will LOSE ALL CHANGES that have been applied since you entered Individual Editor Mode.    Are you sure?')
            )) {
                return;
            }

            setRadioValue("global")
            clearAllSizeVariants()
            setEditModeGlobal(true)
        }
        else {

            if (!window.confirm(
                t('Warning, changing to Individual Editor mode will enter a mode where you can edit each size independently. '
                    + ' All changes will only apply to each size'
                    + ' and this is NOT REVERSABLE.   If you switch back to Global Editor mode, you will lose '
                    + ' individual editor mode changes you have made.'
                ))) {
                return;
            }

            //Editor mode individual
            //Sync CSS to each size
            setRadioValue("individual")
            syncAllSizeVariants()
            setEditModeGlobal(false)
        }

    }

    return <FormControl>

        <Tooltip
            title={t('Editor Mode determines how changes you make to the design will be applied to each size.  ')}
        >
            <Typography variant='subtitle2'>{t('Editor Mode')}</Typography>
        </Tooltip>

        <RadioGroup
            aria-labelledby="size-editing-mode"
            name="size-editing-mode"
            value={radioValue}
            onChange={handleChange}
        >
            <Tooltip
                title={t('All edits will apply to all sizes.  This is the default mode.  '
                    + ' If you switch to individual mode, you will lose all changes '
                    + ' that have been made since you entered individual mode.')}
            >
                <FormControlLabel value="global" control={<Radio size="small" />}
                    label={<Typography variant='body2'>{t('All Sizes')}</Typography>} />
            </Tooltip>
            <Tooltip
                title={t('Each size will be edited independently. And most edits will not affect the other sizes.')}
            >
                <FormControlLabel value="individual" control={<Radio size="small" />}
                    label={<Typography variant='body2'>{t('Each Size Independently')}</Typography>} />
            </Tooltip>
        </RadioGroup>
    </FormControl>

}

const mapStateToProps = state => {
    return {
        editModeGlobal: editModeGlobal(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setEditModeGlobal: (editModeGlobal) => dispatch(setEditModeGlobal(editModeGlobal)),
        clearAllSizeVariants: () => dispatch(clearAllSizeVariants()),
        syncAllSizeVariants: () => dispatch(syncAllSizeVariants()),
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerEditMode
    );
