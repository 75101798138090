import React from 'react';
import clx from 'classnames';
import { Avatar, makeStyles } from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { ApplicationIcon } from '../../components';

const useStyles = makeStyles(() => ({
    avatar: {
        background: 'none',
        marginRight: 16,
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    }
}));

const FeedProviderAvatar = ({ feedProvider, className, ...rest }) => {
    const classes = useStyles();

    return (<>
        {hasIcon(feedProvider)
            ? <Avatar className={clx(classes.avatar, className)} {...rest} >
                <img
                    src={feedProvider.options.primary_image_public_url}
                    className={classes.image}
                    alt={feedProvider.name}
                    title={feedProvider.name}
                />
            </Avatar>
            : <Avatar style={{ marginRight: 16 }} className={className} {...rest}>
                <ApplicationIcon fontSize="large" slot="start" />
            </Avatar>}
    </>);
}

export default FeedProviderAvatar;
