import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import DraftChip from './DraftChip';

function InventoryItemCardContentSocialPost({ item }) {
    return <Box p={2}>
        <DraftChip item={item} />
    </Box>;
}

InventoryItemCardContentSocialPost.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
)(
    InventoryItemCardContentSocialPost
);
