import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import debounce from 'lodash.debounce';

const DebounceInput = ({
    ownerComponent: Component,
    wait = 250,
    ...props
}) => {
    const [debounceFieldValue, setDebounceFieldValue] = useState(props.input.value);
    const [debouncing, setDebouncing] = useState(false);
    const lastInputValue = useRef(props.input.value);

    useEffect(() => {
        if (debouncing) {
            return;
        }
        if (props.input.value === lastInputValue.current) {
            return;
        }
        lastInputValue.current = props.input.value;
        setDebounceFieldValue(props.input.value);
    }, [debouncing, props.input.value]);

    const call = useMemo(() => debounce((
        onChange,
        evt,
    ) => {
        setDebouncing(false);
        onChange(evt);
    }, wait), [setDebouncing, wait]);

    const onChange = useCallback((evt) => {
        evt.persist();
        setDebouncing(true);
        call(props.input.onChange, evt);
        setDebounceFieldValue(evt.target.value);
    }, [setDebouncing, call, setDebounceFieldValue]);

    const onBlur = useCallback((evt) => {
        call.cancel();
        setDebouncing(false);
        props.input.onChange(evt);
        props.input.onBlur(evt);
    }, [call, setDebouncing, props.input.onChange, props.input.onBlur]);

    const onKeyDown = useCallback((evt) => {
        if (evt.keyCode === 13) {
            call.cancel();
            setDebouncing(false);
            props.input.onChange(evt);
        }
    }, [call, setDebouncing, props.input.onChange]);

    const input = {
        ...props.input,
        value: debounceFieldValue,
        onChange,
        onBlur,
        onKeyDown,
    };

    return (
        <Component
            {...props}
            input={input}
        />
    );
};
DebounceInput.displayName = 'DebounceInput';

export default DebounceInput;
