import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { selectProfile } from '../../actions/user';
import { ProfileType } from '../../helpers/constants';
import { sortNumber } from '../../helpers/sorters';
import { accountsTree, selectedProfile } from '../../selectors/user';
import { AccountListItems } from '../account';
import { AgencyListItems } from '../agency';
import { UserListItems } from '../user';
import { SeeMoreListItem } from '../../components';
import { toggleProfileSwitcher } from '../../actions/layout';
import { Divider } from '@material-ui/core';

const ProfileListItems = (props) => {
    const { selectedProfile, selectProfile, openProfileSwitcher,
        profiles,
        autoFocusSelected,
        showMoreOpensDialog,
        showMoreEnabled = true,
        showMoreThresold = 5 } = props;
    const history = useHistory();

    const [showAll, setShowAll] = useState(false);
    const hasMore = showMoreEnabled && profiles.length > showMoreThresold;

    const onChange = useCallback((profile) => {
        selectProfile(history, profile);

        if (props.onChange)
            props.onChange(profile);
    }, [selectProfile, props.onChange])

    let profilesTree = [
        ...profiles.filter(x => x.type !== ProfileType.account),
        ...accountsTree(profiles.filter(x => x.type === ProfileType.account))
    ].sort((a, b) => sortNumber(a.order, b.order));

    if (hasMore && !showAll) {
        profilesTree = profilesTree.slice(0, showMoreThresold);
    }

    return (
        <>
            <UserListItems
                users={profilesTree.filter(x => x.type === ProfileType.user)}
                autoFocusSelected={autoFocusSelected}
                handleChange={onChange}
            />

            <Divider />

            <AgencyListItems
                agencies={profilesTree.filter(x => x.type === ProfileType.agency)}
                autoFocusSelected={autoFocusSelected}
                selected={selectedProfile.type === ProfileType.agency
                    ? selectedProfile
                    : null}
                handleChange={onChange}
            />

            <Divider />

            <AccountListItems
                accounts={profilesTree.filter(x => x.type === ProfileType.account)}
                autoFocusSelected={autoFocusSelected}
                selected={selectedProfile.type === ProfileType.account
                    ? selectedProfile
                    : null}
                handleChange={onChange}
                level={0}
            />

            {hasMore
                && <SeeMoreListItem
                    more={showAll}
                    onChange={() => {
                        if (showMoreOpensDialog) {
                            openProfileSwitcher();
                        } else {
                            setShowAll(!showAll);
                        }
                    }}
                />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedProfile: selectedProfile(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectProfile: (history, profile) => {
            dispatch(selectProfile(profile, {
                animate: true,
                onSwitched: () => history.push({ pathname: profile.type.path })
            }));
        },
        openProfileSwitcher: () => dispatch(toggleProfileSwitcher(true))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ProfileListItems
    )
);
