import { lucitApi } from "../services/lucitApi";
import { selectedAccountId } from "../selectors/user";
import {
    EXPORTS_SEARCH_REQUEST, EXPORTS_SEARCH_RESULT, EXPORTS_NEXT_PAGE_REQUEST,
    EXPORTS_NEXT_PAGE_RESULT, EXPORTS_SAVE_SCROLL_POSITION, EXPORTS_RUN, EXPORTS_GET_RESULT,
    EXPORTS_GETBYID_RESULT, EXPORTS_STATUS_UPDATE, INVENTORYSTATUS_SET_ITEMS_WEIGHTS,
    EXPORTS_ADD_APX_CONTRACT, EXPORTS_DELETE_APX_CONTRACT, EXPORTS_SET_BOARD_IDS,
    EXPORTS_DELETE, EXPORTS_UPDATE, EXPORTS_CAMPAIGN_STATE_CHANGE,
    EXPORTS_ADD_PX_DEAL_MAP, EXPORTS_DELETE_PX_DEAL_MAP,
    EXPORTS_ADD_VISTAR_INSERTION_ORDER_MAP, EXPORTS_DELETE_VISTAR_INSERTION_ORDER_MAP,
} from "../helpers/actionTypes";
import { filters } from "../selectors/exports";
import {
    itemsExported, itemsUnexported, exportItemsStatusResult, exportItemsStatusRequest,
    exportItemsRefreshResult
} from "./inventoryStatus";
import { exportItemsSelector, inventoryStatus } from "../selectors/inventoryStatus";
import { CampaignState } from "../helpers/constants";

export function runExport(id) {
    return dispatch => {
        return lucitApi.exports.run(id)
            .then(() => {
                dispatch({
                    type: EXPORTS_RUN,
                    id
                })
            });
    }
}

export function assumeExportIsRunning(id) {
    return dispatch => {
        dispatch({
            type: EXPORTS_RUN,
            id
        });
    }
}

export function getExport(id) {
    return dispatch => {
        return lucitApi.getExport(id)
            .then(data => {
                dispatch(exportsByIdResult(data))

                return data;
            });
    }
}

export function getExportHistory(id) {
    return () => {
        return lucitApi.exports.getHistory(id);
    }
}

export function getExportItemsByIds(exportId, ids) {
    return dispatch => {
        const filters = {
            itemIds: ids
        }

        return lucitApi.getExportItems(exportId, filters)
            .then(data => {
                dispatch(exportItemsRefreshResult(data));
            });
    }
}

export function getExportItems(id, search, page, perPage) {
    return (dispatch, getState) => {
        const filters = {
            page,
            perPage,
            search,
            searchFields: ["title", "dealer_stock_number"],
        }

        dispatch(exportItemsStatusRequest(filters))

        return lucitApi.getExportItems(id, filters)
            .then(data => {
                dispatch(exportItemsStatusResult(data));

                const state = getState();
                const itemStatuses = inventoryStatus(state);

                return {
                    data: exportItemsSelector(state)(id).items,
                    page: itemStatuses.currentPage - 1,
                    totalCount: itemStatuses.total
                };
            });
    }
}

export function getExportUsers(id) {
    return () => {
        return lucitApi.getExportUsers(id)
            .then(users => users);
    }
}

export function getExportRuns(id, data) {
    return () => {
        return lucitApi.getExportRuns(id, { ...data, changed_only: 1 })
            .then(runs => runs.data);
    }
}

export function getExportBillboardsStatus(id) {
    return () => {
        return lucitApi.getExportBillboardsStatus(id)
            .then(response => response.data.billboards_status)
    }
}

export function getExportRun(id) {
    return () => {
        return lucitApi.getExportRun(id);
    }
}

export function getExports(data) {
    return (dispatch, getState) => {

        const state = getState();

        return lucitApi.exports.getActiveExports(
            {
                accountId: selectedAccountId(state),
                per_page: 1000,
                ...data
            }
        ).then(result => {
            const items = result.data;
            dispatch(exportsGetResult(items));
            dispatch(exportsSearchResult(result));
            return items;
        })
    }
}

export function setBoardIds(exportId, boardIds) {
    return (dispatch) => {
        return lucitApi.exports.setBoardIds(exportId, boardIds)
            .then((data) => {
                dispatch({
                    type: EXPORTS_SET_BOARD_IDS,
                    exportId,
                    data
                })
            })
    }
}

export function pushToTraffic(exportId, comments) {
    return (dispatch) => {
        return lucitApi.exports.pushToTraffic(exportId, comments)
            .then((data) => {
                dispatch({
                    type: EXPORTS_CAMPAIGN_STATE_CHANGE,
                    exportId,
                    data
                })
            })
    }
}

export function trafficReject(exportId, comments) {
    return (dispatch) => {
        return lucitApi.exports.trafficReject(exportId, comments)
            .then((data) => {
                dispatch({
                    type: EXPORTS_CAMPAIGN_STATE_CHANGE,
                    exportId,
                    previousState: CampaignState.pendingTraffic,
                    data
                })
            })
    }
}

export function revertToDraft(exportId) {
    return (dispatch) => {
        return lucitApi.exports.revertToDraft(exportId)
            .then((data) => {
                dispatch({
                    type: EXPORTS_CAMPAIGN_STATE_CHANGE,
                    exportId,
                    data
                })
            })
    }
}

export function trafficComplete(exportId, comments) {
    return (dispatch) => {
        return lucitApi.exports.trafficComplete(exportId, comments)
            .then((data) => {
                dispatch({
                    type: EXPORTS_CAMPAIGN_STATE_CHANGE,
                    exportId,
                    previousState: CampaignState.pendingTraffic,
                    data
                })
            })
    }
}

export function exportItems(exportId, itemIds) {
    return (dispatch) => {
        return lucitApi.inventory.export(exportId, itemIds)
            .then(() => dispatch(itemsExported(exportId, itemIds)));
    }
}

export function unexportItems(exportId, itemIds) {
    return (dispatch) => {
        return lucitApi.inventory.unexport(exportId, itemIds)
            .then(() => dispatch(itemsUnexported(exportId, itemIds)));
    }
}

export function setItemsWeights(exportId, itemsWeights) {
    return dispatch => {
        return lucitApi.exports.setItemsWeights(exportId, itemsWeights)
            .then(() => {
                dispatch({
                    type: INVENTORYSTATUS_SET_ITEMS_WEIGHTS,
                    exportId,
                    itemsWeights
                })
            })
    }
}

export function addApxContract(exportId, apxContractId) {
    return dispatch => {
        return lucitApi.exports.addApxContract(exportId, apxContractId)
            .then(() => {
                dispatch({
                    type: EXPORTS_ADD_APX_CONTRACT,
                    exportId,
                    apxContractId
                })
            })
    }
}

export function deleteApxContract(exportId, apxContractId) {
    return dispatch => {
        return lucitApi.exports.deleteApxContract(exportId, apxContractId)
            .then(() => {
                dispatch({
                    type: EXPORTS_DELETE_APX_CONTRACT,
                    exportId,
                    apxContractId
                })
            })
    }
}

export function addPxDealMap(exportId, pxDealId) {
    return dispatch => {
        return lucitApi.exports.addPxDealMap(exportId, pxDealId)
            .then(() => {
                dispatch({
                    type: EXPORTS_ADD_PX_DEAL_MAP,
                    exportId,
                    pxDealId
                })
            })
    }
}

export function deletePxDealMap(exportId, pxDealId) {
    return dispatch => {
        return lucitApi.exports.deletePxDealMap(exportId, pxDealId)
            .then(() => {
                dispatch({
                    type: EXPORTS_DELETE_PX_DEAL_MAP,
                    exportId,
                    pxDealId
                })
            })
    }
}

export function addVistarInsertionOrderMap(exportId, vistarInsertionOrderId) {
    return dispatch => {
        return lucitApi.exports.addVistarInsertionOrderMap(exportId, vistarInsertionOrderId)
            .then(() => {
                dispatch({
                    type: EXPORTS_ADD_VISTAR_INSERTION_ORDER_MAP,
                    exportId,
                    vistarInsertionOrderId
                })
            })
    }
}

export function deleteVistarInsertionOrderMap(exportId, vistarInsertionOrderId) {
    return dispatch => {
        return lucitApi.exports.deleteVistarInsertionOrderMap(exportId, vistarInsertionOrderId)
            .then(() => {
                dispatch({
                    type: EXPORTS_DELETE_VISTAR_INSERTION_ORDER_MAP,
                    exportId,
                    vistarInsertionOrderId
                })
            })
    }
}

export function search(data) {
    return (dispatch, getState) => {
        dispatch(exportsSearchRequest(data));

        const state = getState();

        const filters = {
            per_page: 1000,
            accountId: selectedAccountId(state),
            ...data
        };

        return lucitApi.exports.getActiveExports(filters)
            .then(data => dispatch(exportsSearchResult(data)))
            .catch(error => {
                if (error.isOffline) {
                    const exports = state.exports;
                    exports.isOffline = error.isOffline;

                    //filter at least by export_type when offline
                    if (filters.export_type) {
                        exports.data = exports.dataOriginal.filter(x => x.export_type === filters.export_type)
                    }

                    if (filters.search) {
                        exports.data = exports.dataOriginal.filter(x => x.name.toUpperCase().indexOf(filters.search.toUpperCase()) > -1)
                    }

                    dispatch(exportsSearchResultOffline(exports))
                }
            });
    }
}

export function loadPage(page) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(exportsNextPageRequest());

        const data = {
            accountId: selectedAccountId(state),
            page: page,

            ...filters(state)
        };

        return lucitApi.exports.getActiveExports(data)
            .then(data => dispatch(exportsNextPageResult(data)))
    }
}

export function exportsGetResult(data) {
    return {
        type: EXPORTS_GET_RESULT,
        data
    }
}

export function exportsByIdResult(data) {
    return {
        type: EXPORTS_GETBYID_RESULT,
        ...data
    }
}

export function exportStatusUpdate(id, status) {
    return {
        type: EXPORTS_STATUS_UPDATE,
        id,
        status
    }
}

export function exportsSearchRequest(filters) {
    return {
        type: EXPORTS_SEARCH_REQUEST,
        filters
    }
}

export function exportsSearchResult(data) {
    const { current_page, last_page, per_page, ...rest } = data;

    return {
        type: EXPORTS_SEARCH_RESULT,
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
        ...rest
    }
}
export function exportsSearchResultOffline(data) {
    return {
        type: EXPORTS_SEARCH_RESULT,
        ...data
    }
}

export function exportsNextPageRequest() {
    return {
        type: EXPORTS_NEXT_PAGE_REQUEST,
    }
}

export function exportsNextPageResult(data) {
    return {
        ...exportsSearchResult(data),

        type: EXPORTS_NEXT_PAGE_RESULT
    }
}

export function exportsSaveScroll(scrollY) {
    return {
        type: EXPORTS_SAVE_SCROLL_POSITION,
        scrollY
    }
}

export function deleteExport(exportItem) {
    return dispatch => {
        return lucitApi.objects.delete(exportItem.lcuid)
            .then(() => dispatch({
                type: EXPORTS_DELETE,
                exportItem
            }))
    }
}

export function setName(exportId, name) {
    return dispatch => {
        return lucitApi.exports.update(exportId, { name: name })
            .then(() => {
                dispatch({
                    type: EXPORTS_UPDATE,
                    exportId,
                    fields: { name: name }
                })
            })
    }
}
