import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import clx from 'classnames';
import {
    makeStyles, DialogContent, DialogActions, Button, Grid, Typography, useMediaQuery,
    useTheme, Box, Slider, IconButton, Hidden, Menu, MenuItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import { RotateLeft, RotateRight, Clear, RemoveRedEye as Preview, Edit, Delete } from '@material-ui/icons';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import { getCroppedImg, resizeImage } from '../../helpers/file';
import ButtonLoader from '../material/ButtonLoader';
import ImageUploadInput from '../inputs/ImageUploadInput';
import { useHookWithRefCallback } from '../../helpers/hooks/useHookWithRefCallback';
import { showError } from '../../actions/snackbar';
import { logger } from '../../helpers/logger';
import { connect } from 'react-redux';
import { Dialog } from '../material';
import { DialogCloseReason, Global } from '../../helpers/constants';
import { environment } from '../../selectors/environment';
import { isPlatform, IonSegment, IonSegmentButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../material/CircularProgressCentered';
import { cropperAreaCompute } from '../../helpers/image';
import { useDragNDrop, useSize } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        content: {
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2),
        },
        deleteButton: {
            color: theme.palette.error.main,
            marginRight: 'auto'
        },

        uploadArea: {
            width: '100%',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed grey',
            cursor: 'pointer'
        },

        grid: {
            margin: theme.spacing(2, 0)
        },

        gridImage: {
            height: 'calc(100% - 96px - 12px * 4)',

            [theme.breakpoints.up('lg')]: {
                height: 'calc(100% - 56px - 12px * 4)',
            },
        },
        gridImagePreview: {
            height: '100%'
        },

        cropContainer: {
            position: 'relative',
            width: '100%',
            height: '100%',

            display: 'flex',
            justifyContent: 'center',

            '& .reactEasyCrop_CropArea': {
                color: 'rgba(0, 0, 0, 0.80)'
            }
        },
        previewImage: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            imageOrientation: 'from-image'
        },

        sliderContainer: {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
        },
        sliderLabel: {
            [theme.breakpoints.down('xs')]: {
                minWidth: 65,
            },
        },
        slider: {
            padding: '22px 0px',
            marginLeft: 16,
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                alignItems: 'center',
                margin: '0 16px',
            },
        },
    }
})

const Modes = {
    crop: 1,
    stretch: 2
}

const ImageUploadDialog = (props) => {
    const { open, title, src, handleClose, autoUpload, dialogProps, skipInsteadOfCancel = false,
        showError, environment, uploadPromptMessage, askForCameraOnAndroid } = props;
    const { aspect = Global.imageAspectDefault,
        disableDelete,
        enableCrop = true,
        enableStretch = true,
        showPreviewButton = false,
        saveButtonText = "Save",
        UploadInputProps = {} } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    const [inputRef, setInputRef] = useHookWithRefCallback(null);
    const inputController = useRef();
    const contentRef = useRef();

    const [image, setImage] = useState(src);
    const [imageFile, setImageFile] = useState(null);

    const [isPreview, setIsPreview] = useState(false)
    const [loadingPreview, setLoadingPreview] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const { t } = useTranslation();

    const [mode, setMode] = useState(Modes.crop);

    useEffect(() => {
        setImage(src);
        setZoom(1);
        setRotation(0);
        setCrop({ x: 0, y: 0 })
        setIsPreview(false);
    }, [src, open]);

    useEffect(() => {
        if (autoUpload && inputRef) {
            inputRef.click();
        }

    }, [autoUpload, inputRef]);

    const getUploadFileName = () => {
        return imageFile ? imageFile.name : ""
    }

    const getCroppedImage = useCallback(async () => {
        if (mode === Modes.stretch) {
            return resizeImage(image, croppedAreaPixels.width, croppedAreaPixels.height)
        }

        try {
            const croppedImage = await getCroppedImg(
                image,
                imageFile,
                croppedAreaPixels,
                rotation,
                environment.canvasSizeLimits
            );

            return croppedImage;
        } catch (e) {
            showError('Failed to crop image...');
            logger.logError("Failed to crop image");
            throw e;
        }
    }, [image, imageFile, croppedAreaPixels, rotation, showError, environment.canvasSizeLimits, mode])

    const askCameraOrGallery = () => askForCameraOnAndroid && isPlatform("android")

    const [useCamera, setUseCamera] = useState(false)
    const [useCameraMenuItemClicked, setUseCameraMenuItemClicked] = useState(0)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getPhotoFromCamera = () => {
        setUseCamera(true);
        setAnchorEl(null);
        setUseCameraMenuItemClicked(useCameraMenuItemClicked + 1)
    }

    const getPhotoFromGallery = () => {
        setUseCamera(false);
        setAnchorEl(null);
        setUseCameraMenuItemClicked(useCameraMenuItemClicked + 1)
    }

    //This crazy code is here to ensure that the input is actually re-rendering BEFORE the
    //inputRef.click(); is called
    //So - IF we are on Android, AND we want to give user option to use the camera (askForCameraOnAndroid) then
    //We check to see if the useCameraMenuItemClicked is greater than zero when it changes
    //If so, this means one of the menu items (Gallery or Camera) was clicked, and we are confident that
    //useCamera is the correct value in ImageUploadInput

    useEffect(() => {
        if (inputRef && useCameraMenuItemClicked > 0) {
            inputRef.click();
        }
        // eslint-disable-next-line
    }, [useCameraMenuItemClicked]);

    const containerRef = useRef()
    const { width: wSize, height: hSize } = useSize(containerRef)
    const uploadBox = cropperAreaCompute(wSize, hSize, aspect, 0);

    const sizeDescription = (UploadInputProps.minWidth && UploadInputProps.minHeight)
        ? UploadInputProps.minWidth + 'x' + UploadInputProps.minHeight
        : null

    const cropAreaStyle = mode === Modes.stretch ? {
        boxShadow: "none",
        border: "none"
    } : {
        boxShadow: "rgb(0 0 0 / 76%) 0px 0px 0px 9999em"
    }

    //The 0.8 here is a hack to make the width/height of image to have some padding
    //A better solution probaby exists
    const mediaStyle = mode === Modes.stretch ? {
        width: uploadBox.width * 0.8 + "px",
        height: uploadBox.height * 0.8 + "px",
        border: "1px solid rgba(255, 255, 255, 0.5)"
    } : {}

    const { isDraggingOver } = useDragNDrop({
        element: contentRef.current,
        onDrop: files => inputController.current.onChange(files),
    })

    return (
        <Dialog
            fullWidth
            fullScreen={isXs}
            maxWidth={false}
            open={open}

            title={title}
            TitleProps={{
                id: "image-crop-dialog-title"
            }}

            aria-labelledby="image-crop-dialog-title"
            aria-describedby="image-crop-dialog-description"
            onClose={(_, reason) => handleClose(reason)}
            {...dialogProps}
        >
            <DialogContent dividers className={classes.content} id="image-crop-dialog-description" >
                <Grid ref={contentRef} container spacing={3} className={classes.grid}>
                    <Grid item xs={12}>
                        <Box display="flex" mt={-1.5} mb={-1.5} alignItems="center">
                            <Box>
                                <LCTypography
                                    transProps={{ aspectToFixed: aspect.toFixed(2) }}><b>Ratio:</b> {{ aspectToFixed: aspect.toFixed(2) }}:1
                                </LCTypography>
                            </Box>
                            <Box ml={2}>
                                <IonSegment value={mode} onIonChange={e => setMode(+e.detail.value)}>
                                    {enableCrop
                                        && <IonSegmentButton value={Modes.crop}>
                                            {t("Crop")}
                                        </IonSegmentButton>}
                                    {enableStretch
                                        && <IonSegmentButton value={Modes.stretch}>
                                            {t("Stretch")}
                                        </IonSegmentButton>}
                                </IonSegment>
                            </Box>
                            <Box display="flex" ml={"auto"}>
                                {UploadInputProps.minWidth > 0
                                    && <Box mr={2}>
                                        <LCTypography
                                            transProps={{ minWidth: UploadInputProps.minWidth }}>
                                            <b>Min Width: </b>
                                            {{ minWidth: UploadInputProps.minWidth }}px
                                        </LCTypography>
                                    </Box>}
                                {UploadInputProps.minHeight > 0
                                    && <Box>
                                        <LCTypography
                                            transProps={{ minHeight: UploadInputProps.minHeight }}>
                                            <b>Min Height: </b>
                                            {{ minHeight: UploadInputProps.minHeight }}px
                                        </LCTypography>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={clx(classes.gridImage, { [classes.gridImagePreview]: isPreview })}>
                        <Box ref={containerRef} className={classes.cropContainer} style={{ padding: mode === Modes.stretch ? "25px" : "" }}>
                            {loadingPreview
                                && <CircularProgressCentered size={40} />}
                            {isPreview
                                && croppedImage
                                && <img src={croppedImage} alt="Cropped" className={classes.previewImage} />}
                            {!isPreview
                                && image
                                && <Cropper
                                    image={image}
                                    crop={crop}
                                    rotation={mode === Modes.stretch ? null : rotation}
                                    transform={mode === Modes.stretch ? "none" : null}
                                    minZoom={0.1}
                                    showGrid={false}
                                    restrictPosition={zoom >= 1}
                                    style={{
                                        containerStyle: {
                                            backgroundColor: "black"
                                        },
                                        cropAreaStyle: cropAreaStyle,
                                        mediaStyle: mediaStyle
                                    }}
                                    zoom={mode === Modes.stretch ? null : zoom}
                                    zoomSpeed={0.1}
                                    aspect={aspect}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                                        setCroppedAreaPixels(croppedAreaPixels)
                                    }}
                                    onCropAreaChange={(croppedArea, croppedAreaPixels) => {
                                        setCroppedAreaPixels(croppedAreaPixels)
                                    }}
                                    onZoomChange={setZoom}
                                />}
                            {!image
                                && <Box
                                    className={classes.uploadArea}
                                    aria-controls="use-camera-menu"
                                    aria-haspopup="true"
                                    style={{
                                        board: isDraggingOver
                                            ? "3px dashed grey"
                                            : "2px solid black",
                                        background: isDraggingOver ? "#8080803d" : undefined,
                                        width: (uploadBox.width),
                                        height: (uploadBox.height),
                                        margin: "auto"
                                    }}
                                    onClick={(e) => { askCameraOrGallery() ? handleMenuClick(e) : inputRef.click() }}
                                >
                                    {isDraggingOver
                                        ? <Typography>{t('Drop files here')}</Typography>
                                        : <Typography>{uploadPromptMessage ?? t('Click here to upload')}</Typography>}

                                    {sizeDescription &&
                                        <LCTypography transProps={{ sizeDescription: sizeDescription }}>
                                            &nbsp;: size {{ sizeDescription: sizeDescription }}
                                        </LCTypography>
                                    }

                                </Box>}
                        </Box>

                        <Menu
                            id="use-camera-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => { getPhotoFromGallery() }}>
                                <ListItemIcon>
                                    <PhotoLibraryIcon />
                                </ListItemIcon>
                                <ListItemText>{t('Photo Gallery')}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => { getPhotoFromCamera() }}>
                                <ListItemIcon>
                                    <CameraAltIcon />
                                </ListItemIcon>
                                <ListItemText>{t('Camera')}</ListItemText>
                            </MenuItem>
                        </Menu>

                    </Grid>

                    {!isPreview && mode === Modes.crop
                        && <>
                            <Grid item xs={12} lg={6}>
                                <Box className={classes.sliderContainer}>
                                    <LCTypography
                                        variant="body1"
                                        classes={{ root: classes.sliderLabel }}
                                    >Zoom</LCTypography>
                                    <Hidden smDown implementation="js">
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            color="secondary"
                                            aria-labelledby="Zoom"
                                            classes={{ root: classes.slider }}
                                            disabled={!image}
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                        <IconButton
                                            disabled={!image}
                                            onClick={() => setZoom(1)}>
                                            <Clear />
                                        </IconButton>
                                    </Hidden>
                                    <Hidden mdUp implementation="js">
                                        <Box ml={'auto'}>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                disabled={!image}
                                                onClick={() => setZoom(1)}
                                                startIcon={<Clear />}>
                                                Reset
                                            </Button>
                                        </Box>
                                    </Hidden>
                                </Box>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Box className={classes.sliderContainer}>
                                    <LCTypography
                                        variant="body1"
                                        classes={{ root: classes.sliderLabel }}
                                    >Rotation</LCTypography>
                                    <Hidden smDown implementation="js">
                                        <Slider
                                            value={rotation}
                                            min={-360}
                                            max={360}
                                            step={1}
                                            color="secondary"
                                            aria-labelledby="Rotation"
                                            classes={{ root: classes.slider }}
                                            disabled={!image}
                                            onChange={(e, rotation) => setRotation(rotation)}
                                        />
                                        <IconButton
                                            disabled={!image}
                                            onClick={() => setRotation((rotation - 90) % 360)}>
                                            <RotateLeft />
                                        </IconButton>
                                        <IconButton
                                            disabled={!image}
                                            onClick={() => setRotation((rotation + 90) % 360)}>
                                            <RotateRight />
                                        </IconButton>
                                        <IconButton
                                            disabled={!image}
                                            onClick={() => setRotation(0)}>
                                            <Clear />
                                        </IconButton>
                                    </Hidden>
                                    <Hidden mdUp implementation="js">
                                        <Box ml={'auto'}>
                                            <IconButton
                                                disabled={!image}
                                                onClick={() => setRotation((rotation - 90) % 360)}>
                                                <RotateLeft />
                                            </IconButton>
                                            <IconButton
                                                disabled={!image}
                                                onClick={() => setRotation((rotation + 90) % 360)}>
                                                <RotateRight />
                                            </IconButton>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 8 }}
                                                disabled={!image}
                                                onClick={() => setRotation(0)}
                                                startIcon={<Clear />}>
                                                Reset
                                            </Button>
                                        </Box>
                                    </Hidden>
                                </Box>
                            </Grid>
                        </>}
                </Grid>

            </DialogContent>
            <DialogActions>

                {skipInsteadOfCancel && <Button
                    color="primary"
                    onClick={() => handleClose(DialogCloseReason.skip)}>
                    {t('Skip This Step')}
                </Button>
                }

                {!isPreview
                    && !disableDelete
                    && <>
                        <ButtonLoader
                            disabled={!src}
                            submitting={deleting}
                            onClick={() => {
                                setDeleting(true);

                                props.handleDelete()
                                    .finally(() => setDeleting(false));
                            }}
                            color="primary"
                            className={classes.deleteButton}
                            startIcon={<Delete />}
                        >{t('Delete')}</ButtonLoader>
                    </>}

                <ImageUploadInput
                    ref={setInputRef}
                    controller={inputController}
                    useCamera={useCamera}
                    id="avatarUpload"
                    // TODO: List of supported extensions
                    // extensions={["png"]}
                    // maxSize={10000}
                    onSuccess={({ file, fileBase64 }) => {
                        setImage(fileBase64);
                        setImageFile(file);
                    }}
                    onError={errors => {
                        showError(errors.map(e => <Typography key={e.type}>{e.message}</Typography>))
                    }}
                    {...props.UploadInputProps}>

                    {!isPreview
                        && <Button
                            component="label"
                            variant="outlined"
                            color="secondary"
                            onClick={(e) => { askCameraOrGallery() ? handleMenuClick(e) : inputRef.click() }}
                        >
                            {image && 'Change'}
                            {!image && 'Upload'}
                        </Button>}
                </ImageUploadInput>

                {showPreviewButton
                    && <Hidden xsDown>
                        {!isPreview
                            && <ButtonLoader
                                disabled={!image}
                                submitting={loadingPreview}
                                startIcon={<Preview />}
                                onClick={() => {
                                    setCroppedImage(null);
                                    setLoadingPreview(true);

                                    getCroppedImage()
                                        .then(croppedImage => {
                                            setCroppedImage(croppedImage);
                                            setIsPreview(true)
                                        })
                                        .finally(() => setLoadingPreview(false));
                                }}>
                                Preview</ButtonLoader>}
                        {isPreview
                            && <ButtonLoader
                                startIcon={<Edit />}
                                onClick={() => setIsPreview(false)}>
                                Edit</ButtonLoader>}
                    </Hidden>}
                {!skipInsteadOfCancel && <Hidden mdDown>
                    <Button onClick={() => handleClose(DialogCloseReason.cancelButton)}> {t('Cancel')}</Button>
                </Hidden>
                }
                <ButtonLoader
                    disabled={!image}
                    submitting={saving}
                    onClick={() => {
                        setSaving(true);

                        getCroppedImage()
                            .then(croppedImage => props.handleSave(croppedImage, {
                                fileName: getUploadFileName(),
                                file: imageFile,
                                fileBase64: image
                            }))
                            .finally(() => setSaving(false));
                    }}
                    variant="contained"
                    color="secondary">
                    {saveButtonText}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

ImageUploadDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,

    UploadInputProps: PropTypes.object,

    open: PropTypes.bool.isRequired,

    autoUpload: PropTypes.bool,
    enableCrop: PropTypes.bool,
    enableStretch: PropTypes.bool,

    showPreviewButton: PropTypes.bool,

    src: PropTypes.string,
    aspect: PropTypes.number,
}

const mapStateToProps = state => {
    return {
        environment: environment(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        showError: (error) => dispatch(showError(error))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageUploadDialog);
