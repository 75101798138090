import React from 'react';
import BillboardsIcon from '@material-ui/icons/DesktopWindows';
import { toDictionary } from './array';

export const IosAppId = "id1523546546";

export class RoutePaths {
    static login = '/login';
    static contact = '/contact';
    static register = '/register';
    static registrationNew = '/registration';
    static registrationUserInfo = (registrationId = ':registrationId') => `/registration/${registrationId}/user-info`;
    static forgotPassword = '/forgot-password';

    static home = '/';
    static map = '/map';
    static inventory = '/inventory';
    static inventoryPhoto = '/inventory-photo';

    static vehicles = '/vehicles';
    static properties = '/properties';
    static creatives = '/creatives';
    static photoStream = '/photo-stream';
    static appsAndData = '/apps-and-data';

    static articles = '/articles';
    static job_posts = '/job-posts';
    static reviews = '/reviews';
    static socialPosts = '/social-posts';
    static products = '/products';
    static recreationals = '/recreationals';

    static campaigns = '/campaigns';
    static campaign = (id = ':id') => `/campaign/${id}`;
    static exports = '/campaign';
    static childCampaign = '/childCampaign';
    static realEstateAgents = '/realEstateAgents';
    static feed = '/app/feed';
    static feeds = '/feeds';
    static settings = '/settings';
    static searchResults = '/search-results';
    static agencySettings = '/agency/settings';
    static accountSettings = '/account/settings';
    static accountChildren = '/account/children';
    static help = '/help';
    static analytics = '/analytics';
    static massEmail = '/mass-email';
    static approvals = '/agency/approvals';
    static invoice = (lcuid = ':lcuid') => `/invoices/${lcuid}`;
    static agencyAccounts = '/agency/accounts';
    static agencyUsers = '/agency/users';
    static agencyFeed = '/agency/feed';
    static agencyScreens = '/agency/screens';
    static agencyCampaigns = '/agency/campaigns';
    static agencyBilling = '/agency/billing';
    static screen = (id = ':id') => `/agency/screens/${id}`;

    static accountUsers = '/account/users';
    static accountProfile = '/account/:slug/profile';
    static accountBilling = '/account/billing';

    static noInternet = '/offline';

    static notFound = '/404';

    static designerTemplates = '/designer';
    static designer = '/designer/:lcuid';

    static dev = {
        applications: '/dev/apps',
        application: (lcuid = ":lcuid") => `/dev/apps/${lcuid}`,
        designerElements: '/dev/designer-elements',
    }

    static apps = {
        lightning: '/apps/lightning',
    }

    static public = {
        home: "/home",
        feed: "/home",
        apps: "/apps",
        xr: "/xr",
        programmatic: (lcuid = ":lcuid") => `/programmatic/${lcuid}`,
        invoice: (lcuid = ':lcuid', invoiceAccessToken = ':invoiceAccessToken') => `/public/invoices/${lcuid}/${invoiceAccessToken}`,
        slug: (slug = ":slug") => `/${slug}`,
        proof: (lcuid = ":lcuid") => `/proofs/${lcuid}`
    }
}

export class RoutePathLids {
    static inventoryItem = '/LI:id(\\d+)';
    static export = '/LE:id(\\d+)';
    static feed = '/LF:id(\\d+)';

    static massEmail = '/LME:id(\\d+)';
}

export class ErrorCodes {
    static validationFailed = "invalid_parameters";
    static userEmailNotExists = "user_does_not_exist";
    static userNotRegistered = "user_not_registered";
    static userAlreadyRegistered = "user_already_registered";
    static userAlreadyExists = "user_already_exists";
    static userEmailAlreadyExists = "error_user_email_exists";
    static userRegistrationExpired = "user_registration_expired";
    static userPasswordIsInvalid = "user_password_is_invalid";
    static userPhoneNotExists = "invalid_mobile_phone";
    static smsCodeInvalid = "invalid_sms_code";
    static smsCodeInvalidNew = "sms_code_invalid";
    static smsCodeExpired = "sms_code_expired";
    static phoneInvalidFormat = "phone_number_invalid_format";
    static invalidParameters = "invalid_parameters";
    static invalidPasswordToken = "passport_token_invalid_or_unauthorized";
    static invalidAppToken = "app_token_invalid_or_unauthorized";
    static boardIdExists = "board_id_exists";
}

export class QueryString {
    static username = 'username';
    static phoneNumber = 'pn';
    static countryCode = 'cc';
    static isNew = 'newuser';
    static search = 'q';

    static tab = 'tab';

    // This query string always mean: "Current account needs to switch to this ID"
    static contextAccountId = "context_account_id"
    // This query string always mean: "Current agency needs to switch to this ID"
    static contextAgencyId = "context_agency_id"

    static digitalScreenId = "screen_id"
    static campaignId = "campaign_id"

    static registrationLcuid = "lcuid"
    static inviteLcuid = "invite_lcuid" //lcuid of the invite object, usually passed in invite links
    static action = "action"
    static join_lcuid = "join_lcuid"
    static join_code = "join_code"
}

export class QueryStringActions {
    static post = "post";
}

export class SearchType {
    static link = {
        title: 'Links',
        type: "link"
    };
    static account = {
        title: 'Accounts',
        type: "App\\Account",
    };
    static agency = {
        title: 'Operators',
        type: "App\\Agency",
    };
    static others = {
        title: 'Others',
    };
}

export class UserTokenTypes {
    static public = "user_token_type_public";         //LC_CORE_USER_TOKEN_TYPE_PUBLIC
    static auth = "user_token_type_auth";           //LC_CORE_USER_TOKEN_TYPE_AUTH
}

export class ItemCondition {
    static new = "New";
    static used = "Used";
}

export const BillboardIcon = BillboardsIcon

export class InventoryPhotoStatus {
    static active = 1;
    static deleted = 0;
}

export class InventoryPhotoSource {
    static creatives = {
        code: "creatives",
        title: "Creatives",
        order: 90
    };
    static feed = {
        code: "feed",
        title: "Feeds",
        order: 100
    };
    static upload = {
        code: "upload",
        title: "Uploads",
        order: 110
    };
}

export class InventoryPhotoSet {
    static base = 'base';
    static overlay_primary = 'overlay_primary';
    static placeholder_primary = 'placeholder_primary';
    static branding_final = 'branding_final';
    static primary_override = 'primary_override';
    static creative_collage = 'creative_collage';
}

export class ExportRunStatus {
    static init = 0;
    static running = 1;
    static completed = 2;
    static error = 3;
}

export class ExportStatus {
    static deleted = 0;
    static setup = 1;
    static ready = 2;
    static running = 3;
    static error = 4;
    static runnable = 5;
    static retry = 6;
    static queued = 7;
    static empty = 8;
}

export class CampaignState {
    static pending = {
        id: 0,
        title: "Pending",
        order: 10,
        canEditStartDate: true
    };
    static pulling = {
        id: 1,
        title: "Pulling",
        order: 20
    };
    static pullingPaused = {
        id: 2,
        title: "Pulling Paused",
        order: 40
    };
    static running = {
        id: 3,
        title: "Running",
        order: 30
    };
    static paused = {
        id: 4,
        title: "Paused",
        order: 50
    };
    static complete = {
        id: 5,
        title: "Completed",
        order: 70
    };
    static draft = {
        id: 6,
        title: "Draft",
        order: 60,
        canEditStartDate: true
    };
    static pendingTraffic = {
        id: 7,
        title: "Pending Traffic",
        order: 5,
        canEditStartDate: true
    };
    static rejected = {
        id: 8,
        title: "Rejected",
        order: 55,
        canEditStartDate: true
    };
    static unknown = {
        id: -1,
        title: "Unknown",
        order: 1000,
        canEditStartDate: true
    };
}

export const CampaignStateMap = Object.values(CampaignState)
    .reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})

export class FeedStatus {
    static deleted = 0;
    static setup = 1;
    static ready = 2;
    static running = 3;
    static error = 4;
    static runnable = 5;
    static retry = 6;
    static queued = 7;
}

export class FeedRunStatus {
    static init = 0;
    static running = 1;
    static completed = 2;
    static error = 3;
}

export class FeedCron {
    static schedules = [
        {
            id: "every_minute",
            name: "Every Minute",
            value: "* * * * *"
        },
        {
            id: "every_5_minutes",
            name: "Every 5 Minutes",
            value: "*/5 * * * *"
        },
        {
            id: "every_10_minutes",
            name: "Every 10 Minutes",
            value: "*/10 * * * *"
        },
        {
            id: "every_15_minutes",
            name: "Every 15 Minutes",
            value: "*/15 * * * *"
        },
        {
            id: "every_30_minutes",
            name: "Every 30 Minutes",
            value: "*/30 * * * *"
        },
        {
            id: "every_hour",
            name: "Every Hour",
            value: "0 * * * *"
        },
        {
            id: "every_6_hours",
            name: "Every 6 Hours",
            value: "0 */6 * * *"
        },
        {
            id: "every_12_hours",
            name: "Every 12 Hours",
            value: "0 */12 * * *"
        },
        {
            id: "every_day",
            name: "Every Day",
            value: "0 0 * * *"
        }
    ]
}

export class ItemExportStatus {
    static notSelected = 'not_selected';
    static exportHasNoRuns = 'export_has_no_runs';
    static exportPending = 'export_pending';
    static exported = 'exported';
    static exportedByRequest = 'exported_by_request';
    static exportedFallback = 'exported_fallback';
    static exportedByFilter = 'exported_by_filter';
    static exportSkipped = 'export_skipped';
    static itemInactive = 'item_inactive';
    static invalid = 'invalid';
    static doesNotBelongToAccount = 'does_not_belong_to_account';

    /**
     * This is custom temporary status, indicating that item was unexported and should be refreshed to capture new status
     * @static
     * @memberof ItemExportStatus
     */
    static unexportPending = 'unexport_pending';
}

export class LightningDeviceStatus {
    static init = { id: 0, title: 'Init' }
    static setup = { id: 1, title: 'Setup' }
    static active = { id: 2, title: 'Active' }
    static offline = { id: 3, title: 'Offline' }
    static inactive = { id: 4, title: 'Deactivated' }
    static unknown = { id: 5, title: 'Unknown' }
}

export const LightningDeviceStatusDictionary = toDictionary(
    Object.keys(LightningDeviceStatus)
        .filter(key => typeof LightningDeviceStatus[key] === 'object')
        .map(key => LightningDeviceStatus[key]),
    x => x.id
);

export class LightningDeviceRemoteInstructionStatus {
    static pending = { id: 0, title: 'Pending' };
    static sent = { id: 1, title: 'Sent' };
    static ack = { id: 2, title: 'Acknowledged' };
    static success = { id: 3, title: 'Success' };
    static failure = { id: 4, title: 'Failure' };
}

export const LightningDeviceRemoteInstructionStatusDictionary = toDictionary(
    Object.keys(LightningDeviceRemoteInstructionStatus)
        .filter(key => typeof LightningDeviceRemoteInstructionStatus[key] === 'object')
        .map(key => LightningDeviceRemoteInstructionStatus[key]),
    x => x.id
);

export class DriveTemplateStatus {
    static draft = 0;
    static published = 1;
    static deleted = 2;
}

export const ItemExportStatusDescription = {
    [ItemExportStatus.notSelected]: {
        short: 'Not Selected',
        long: 'Item was not selected.',
    },
    [ItemExportStatus.exportHasNoRuns]: {
        short: 'No Builds',
        long: 'Not yet generated, campaign has not built creatives.'
    },
    [ItemExportStatus.exportPending]: {
        short: 'Pending',
        long: 'Waiting for next creative build to be added'
    },
    [ItemExportStatus.unexportPending]: {
        short: 'Pending',
        long: 'Waiting for next creative build to be removed'
    },
    [ItemExportStatus.exported]: {
        short: 'Added',
        long: 'Item was added.'
    },
    [ItemExportStatus.exportedByRequest]: {
        short: '',
        long: ''
    },
    [ItemExportStatus.exportedFallback]: {
        short: '',
        long: ''
    },
    [ItemExportStatus.exportedByFilter]: {
        short: '',
        long: ''
    },
    [ItemExportStatus.exportSkipped]: {
        short: '',
        long: ''
    },
    [ItemExportStatus.itemInactive]: {
        short: 'Item Inactive',
        long: 'Item still in draft and needs to be activated first'
    },
    [ItemExportStatus.invalid]: {
        short: 'Item Invalid',
        long: 'Invalid Item',
        hideInList: true
    },
    [ItemExportStatus.doesNotBelongToAccount]: {
        short: 'Account mismatch',
        long: 'Invalid Item (account mismatch)',
        hideInList: true
    },
}

/**
 * Levels of logging, sorted from most verbose to most critical
 * Properties order matters!!! The first level -- is most verbose.
 * @export
 * @class LogLevel
 */
export class LogLevel {
    static debug = 'DEBUG';
    static info = 'INFO';
    static notice = 'NOTICE';
    static warning = 'WARNING';
    static error = 'ERROR';
    static critical = 'CRITICAL';
    static alert = 'ALERT';
    static emergency = 'EMERGENCY';
}

// List of regexes for excluding errors from being logged to backend
export const blacklistedErrors = []

export class Global {
    static maxSafeInt32 = Math.pow(2, 32) / 2;

    /**
     * Page size of ExportItemList which we are using for small screen devices
     * @static
     * @memberof Global
     */
    static exportItemListSize = 25;

    /**
     * Page size of screens when choosing screens
     * @static
     * @memberof Global
     */
    static screenSelectorPageListSize = 250;

    /**
     * Page size of Approvals List
     * @static
     * @memberof Global
     */
    static approvalsPerPage = 20;

    /**
     * Count of last runs which we show in 'Export Detail' page
     * @static
     * @memberof Global
     */
    static countOfRuns = 12;

    /**
     * Minimum count of characters which user should type in order to make Global search
     * @static
     * @memberof Global
     */
    static globalSearchMinCharacters = 2;

    /**
     * Max count of results for each SearchType in Global Search
     * @static
     * @memberof Global
     */
    static globalSearchMaxResultsCount = 100;

    /**
     * Height in pixels before end, when should start load next page
     * Used in pages where we have virtual scroll
     * @static
     * @memberof Global
     */
    static nextPageLoadThresold = 300;

    /**
     * For mobile phones we want to open left side menu when swipping to right
     * This value is width of bounds where swipe will open actual menu
     * @static
     * @memberof Global
     */
    static leftSwipeOpenMenuBoundsWidth = 40;

    /**
     * We are using long-pooling for checking Export run status,
     * This number is max number of request which could be done.
     * @static
     * @memberof Global
     */
    static statusUpdateMaxCount = 100;

    /**
     * Interval for updating export items status
     * @static
     * @memberof Global
     */
    static exportItemStatusUpdateInterval = 5 * 1000;

    /**
     * Interval for updating items exports status
     * @static
     * @memberof Global
     */
    static itemExportsStatusUpdateInterval = 3 * 1000;

    /**
     * Seconds to wait after new item was uploaded to check for LucitXR Images
     */
    static lucitXRImageCheckInterval = 15;

    /**
     * We are using long-pooling for checking Export run status,
     * We are using also some random jitter, to prevent a lot of simultaneous requests
     * Interval is from 2 seconds to 6 seconds
     * @static
     * @memberof Global
     */
    static statusUpdateInterval = () => 1000 * (2 + 6 * Math.random());

    /**
     * Billboard status refresh interval
     * @static
     * @memberof Global
     */
    static billboardRefreshInterval = 15 * 1000;

    /**
     * Creative Proof status refresh interval
     * @static
     * @memberof Global
     */
    static creativeProofRefreshInternal = 5 * 1000;

    /**
     * It's default item weight when exporting item
     * @static
     * @memberof Global
     */
    static defaultItemWeight = 10;

    /**
     * It's default item weight when exporting item
     * @static
     * @memberof Global
     */
    static highestItemWeight = 100;

    //Fail to allow upload if image is smaller than this
    //pct of the width or height expected
    static minFailWidthHeightReduction = 0.3333;

    /**
     * Image aspect ratio for PhotoStream inventory image uploads
     * @static
     * @memberof Global
     */
    static imageAspectProfilePhoto = 3 / 4;

    //For the Operator and Account Logo Uploads
    //1=square
    static imageAspectAccountLogo = 1;

    /**
     * Image aspect ratio for PhotoStream inventory image uploads
     * @static
     * @memberof Global
     */
    static imageAspectDefault = 4 / 3;
    /**
     * Image aspect ratio for PhotoStream inventory image uploads
     * @static
     * @memberof Global
     */
    static imageAspectForPhotoStream = 1;

    static titleMaxLength = 120;

    static newCreativeNameMaxLength = 45;

    /**
     * Default allowed limits for canvas
     * @static
     * @memberof Global
     */
    static defaultCanvasSizeLimits = {
        width: 4032,
        height: 4032
    }

    /**
     * Compress the image to this amount right after selection
     * @static
     * @memberof Global
     */
    static defaultImageMaxDimensions = {
        width: 2500,
        height: 2500
    }

    /**
     * Max level of nested accounts
     * @static
     * @memberof Global
     */
    static accountMaxLevelHierarchy = 4;

    /**
     * Max number of accounts to auto-show all child accounts
     * If less than this, we will auto expand the account selector children
     * @static
     * @memberof Global
     */
    static accountMaxLevelHierarchy = 10;

    /**
     * Min number of accounts after what special filter will be visible
     * @static
     * @memberof Global
     */
    static accountCountForFilter = 10;

    /**
     * This is current version of EULA,
     * if you want force user's to accept new EULA just increment this version
     * @static
     * @memberof Global
     */
    static eulaModifiedAt = "2021-03-30T00:00:00.00";

    static maxProofViewsToShowStreetView = 250;

    /**
     * The maximum attachments count to comment
     * @static
     * @memberof Global
     */
    static commentMaxAttachments = 5;
}

export class Features {
    static leverage = 'Leverage';
    static searchBarInHeader = 'Search Bar In Header';
}

export const ApplicationFeatures = {
    lucit_reporter: {
        name: 'Lucit Reporter',
        lcuid: 'LCUID-LAP-a1b0aca8-212c-4530-bdab-b41acdc07a99'
    },
    lightning: {
        name: 'Lightning Player',
        lcuid: 'LCUID-LAP-ab60c25f-8db5-4a05-a7d8-ab23824714ee'
    },
    place_exchange_player: {
        name: 'Place Exchange Player',
        lcuid: 'LCUID-LAP-3f63b208-b093-4706-a547-3082b67f7807'
    },
    apparatix_player: {
        name: 'Apparatix Player',
        lcuid: 'LCUID-LAP-20c83817-82bb-4e19-949c-861e19be10c5'
    },
    formetco_f360_player: {
        name: 'Formetco F360 Player',
        lcuid: 'LCUID-LAP-d1a2b110-f7d6-4eab-bd19-6fefc681b400'
    },
    blip_player: {
        name: 'Blip Player',
        lcuid: 'LCUID-LAP-a5fe5d2c-a1f8-4ca2-90e7-d151ca3182cc'
    },
    watchfire_player: {
        name: 'Watchfire Player',
        lcuid: 'LCUID-LAP-0faa1a9a-f5dc-4fa6-a9fc-5c2add9ea48f'
    },
    daktronics_player: {
        name: 'Daktronics Player',
        lcuid: 'LCUID-LAP-27f3bccf-0357-4072-840e-49639907108f'
    },
    lamar_ispot_player: {
        name: 'Lamar iSpot Player',
        lcuid: 'LCUID-LAP-d81c49a1-5a71-4c1a-bfef-9b77545449cd'
    },
    adams_outdoor_player: {
        name: 'Adams Outdoor Player',
        lcuid: 'LCUID-LAP-60686528-930f-415f-8b1a-c263da9208ca'
    },
    ayuda_player: {
        name: 'Ayuda Player',
        lcuid: 'LCUID-LAP-117c9da1-a9b6-42a0-b9c2-0593ef9f7b45'
    },
    clear_channel_outdoor_player: {
        name: 'Clear Channel Outdoor Player',
        lcuid: 'LCUID-LAP-df50d08f-b90f-44dd-a168-ace4f99ecf6d'
    },
    dooh_click_player: {
        name: 'DOOH Click Player',
        lcuid: 'LCUID-LAP-c17153cb-e143-4330-a879-060a6bb0b619'
    },
    vistar_media_player: {
        name: 'Vistar Media Player',
        lcuid: 'LCUID-LAP-3ebe8562-a35d-486d-b887-42fb04192b25'
    }

}

export class Roles {
    static admin = 'admin'
    static user = 'user'
    static bot = 'bot'
}

export class NotificationTypes {
    static text = "text";
    static exportChanged = `App\\Notifications\\ExportChangedNotification`;
    static massEmail = `App\\Notifications\\MassEmailNotification`;
    static campaignStartedNotification = `App\\Notifications\\CampaignStartedNotification`;
    static campaignStateChangeNotification = `App\\Notifications\\CampaignStateChangeNotification`;
    static exportItemSoldAndRemovedFromBillboardNotification = `App\\Notifications\\ExportItemSoldAndRemovedFromBillboardNotification`;
    static multipleExportItemsSoldAndRemovedFromBillboardNotification =
        `App\\Notifications\\MultipleExportItemsSoldAndRemovedFromBillboardNotification`;
    static campaignAnalyticsNotification = `App\\Notifications\\CampaignAnalyticsNotification`;
    static addMoreItemsNotification = `App\\Notifications\\AddMoreItemsNotification`;
    static accountInformationNotification = `App\\Notifications\\AccountInformationNotification`;
    static agencyInformationNotification = `App\\Notifications\\AgencyInformationNotification`;
    static newCreativesNeedingApprovalNotification = `App\\Notifications\\NewCreativesNeedingApprovalNotification`;
    static creativeItemRejectedNotification = `App\\Notifications\\CreativeItemRejectedNotification`;
    static creativeItemApprovedNotification = `App\\Notifications\\CreativeItemApprovedNotification`;
    static genericReactionAndCommentNotification = `App\\Notifications\\GenericReactionAndCommentNotification`;
    static inventoryItemFirstPlayNotification = `App\\Notifications\\InventoryItemFirstPlayNotification`;
}

export const NotificationTypeDetails = {
    [NotificationTypes.exportChanged]: {
        title: 'Creatives Changed or Added',
        description: 'When billboard creatives change'
    },
    [NotificationTypes.massEmail]: {
        title: 'Mass Email',
        description: 'News, Tips and Announcements'
    },
    [NotificationTypes.campaignStartedNotification]: {
        title: 'Campaign Started',
        description: 'Notify me when campaigns start'
    },
    [NotificationTypes.campaignStateChangeNotification]: {
        title: 'Campaign State Changed',
        description: 'Notify me when campaigns change state'
    },
    [NotificationTypes.exportItemSoldAndRemovedFromBillboardNotification]: {
        title: 'Item Sold',
        description: 'Notify me when a sold item is removed'
    },
    [NotificationTypes.multipleExportItemsSoldAndRemovedFromBillboardNotification]: {
        title: 'Multiple Item Sold',
        description: 'Notify me when a larger number of items are removed at a single time'
    },
    [NotificationTypes.campaignAnalyticsNotification]: {
        title: 'Campaign Stats',
        description: 'Stats and Analytics about Campaigns'
    },
    [NotificationTypes.addMoreItemsNotification]: {
        title: 'Item Reminders',
        description: 'Reminders about inventory items and counts'
    },
    [NotificationTypes.accountInformationNotification]: {
        title: 'Account Information',
        description: 'Updates about accounts'
    },
    [NotificationTypes.agencyInformationNotification]: {
        title: 'Agency Information',
        description: 'Updates about Operators / Ad Agencies'
    },
    [NotificationTypes.newCreativesNeedingApprovalNotification]: {
        title: 'Creatives Needing Approval',
        description: 'Notifications regarding creatives that need approval'
    },
    [NotificationTypes.creativeItemRejectedNotification]: {
        title: 'Creative Rejected',
        description: 'Notification when creative was rejected by an operator'
    },
    [NotificationTypes.creativeItemApprovedNotification]: {
        title: 'Creative Approved',
        description: 'Notification when creative was approved by an operator'
    },
    [NotificationTypes.genericReactionAndCommentNotification]: {
        title: 'Comments & Reactions',
        description: 'Notification when other users post comments and reactions to your content'
    },
    [NotificationTypes.inventoryItemFirstPlayNotification]: {
        title: 'First Play',
        description: 'Notify me when my post get their first live plays'
    }
}

export class Channels {
    static email = "mail";
    static app = "app";
    static database = "database";
}

export const ChannelTitle = {
    [Channels.email]: "Email",
    [Channels.app]: "App",
    [Channels.database]: "Database",
}

export class Periods {
    static perSecond = "per-second";
    static perMinute = "per-minute";
    static hourly = "hourly";
    static daily = "daily";
    static weekly = "weekly";
    static monthly = "monthly";
}

export const PeriodDateFormat = {
    [Periods.perMinute]: "h:mm A",
    [Periods.hourly]: "h A",
    [Periods.daily]: "DD MMM",
    [Periods.monthly]: "MMMM",
}

export class Timeframe {
    static all = "all";
    static today = "today";
    static thisWeek = "this_week";
    static lastWeek = "last_week";
    static last7Days = "last_7_days";
    static last30Days = "last_30_days";
    static last90Days = "last_90_days";
}

export class AnalyticsObject {
    static inventoryPhoto = "InventoryPhoto"
}

export class ImageStyle {
    static normal = 'normal';
    static streetViewOverlay = 'street_view_overlay';
}

export const LocationClasses = {
    generic: {
        class: "App\\LuCore\\Locations\\GenericLocationClass",
        code: "generic",
        title: "Generic Location"
    },
    digitalBoardStructure: {
        class: "App\\LuCore\\Locations\\DigitalBoardStructureLocationClass",
        code: "digitalBoardStructure",
        title: "Digital Board Structure Location"
    },
    streetView: {
        class: "App\\LuCore\\Locations\\StreetViewLocationClass",
        code: "streetView",
        title: "Street View Location"
    },
    streetViewSnapshot: {
        class: "App\\LuCore\\Locations\\StreetViewSnapshotLocationClass",
        code: "streetViewSnapshot",
        title: "Street View Snapshot Location"
    }
}

export const AgencyClasses = {
    generic: {
        class: "App\\LuCore\\Agencies\\GenericAgencyClass",
        code: "generic",
        title: "Generic Agency"
    },
    operator: {
        class: "App\\LuCore\\Agencies\\OperatorAgencyClass",
        code: "operator",
        title: "Operator"
    },
    adagency: {
        class: "App\\LuCore\\Agencies\\AdAgencyClass",
        code: "adagency",
        title: "Ad Agency"
    }

}

export const LucitXrImageSize = {
    width: 640,
    height: 480
}

export class LocationStreetViewStatus {
    static inActive = 0;
    static active = 1;
}

export class StreetViewStatus {
    static pending = 0;
    static active = 1;
    static unavailableOutOfDate = 2;
    static unavailableNoStructure = 3;
    static unavailableStructureBlocked = 4;
    static unavailableNotVisibleFromStreet = 5;
    static unavailableIndoor = 6;
    static unavailableOther = 7
}

export const StreetViewStatusDetails = {
    [StreetViewStatus.pending]: {
        title: 'Pending',
        description: 'Street view has not been processed yet'
    },
    [StreetViewStatus.active]: {
        title: 'Active',
        description: 'Street view is active'
    },
    [StreetViewStatus.unavailableOutOfDate]: {
        title: 'Unavailable - Out of Date',
        description: 'Street view is unavailable because the images are out of date'
    },
    [StreetViewStatus.unavailableNoStructure]: {
        title: 'Unavailable - No Structure',
        description: 'Street view is unavailable because there is no structure visible on street view'
    },
    [StreetViewStatus.unavailableStructureBlocked]: {
        title: 'Unavailable - Structure Blocked',
        description: 'Street view is unavailable because the structure is blocked'
    },
    [StreetViewStatus.unavailableNotVisibleFromStreet]: {
        title: 'Unavailable - Not Visible From Street',
        description: 'Street view is unavailable because it is not visible from the street'
    },
    [StreetViewStatus.unavailableIndoor]: {
        title: 'Unavailable - Indoor',
        description: 'Street view is unavailable because it is indoors'
    },
    [StreetViewStatus.unavailableOther]: {
        title: 'Unavailable - Other',
        description: 'Street view is unavailable for other reasons'
    }
}

//WARNING:  Because of old behavior, these classes are prefexied with a double-slash
export const ExportTransformerClasses = {
    driveCampaign: {
        class: "\\App\\LuCore\\Exports\\Transformers\\DriveCampaignExportTransformer",
        code: "driveCampaign",
        title: "Creative Campaign"
    },
    parentCampaign: {
        class: "\\App\\LuCore\\Exports\\Transformers\\DriveParentExportTransformer",
        code: "parentCampaign",
        title: "Parent Campaign"
    },
}

export const InventoryItemClasses = {
    noInventory: {
        class: "App\\LuCore\\InventoryItems\\NoInventoryInventoryItemClass",
        code: 'noInventory',
        title: "No Inventory",
        route: RoutePaths.home,
        hidden: true
    },
    keyValueData: {
        class: "App\\LuCore\\InventoryItems\\KeyValueDataInventoryItemClass",
        code: 'keyValueData',
        title: "Key / Value Data",
        route: RoutePaths.home,
        hidden: true
    },
    generic: {
        class: "App\\LuCore\\InventoryItems\\GenericInventoryItemClass",
        code: 'generic',
        title: "Inventory",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.inventory,
        showProofButton: true
    },
    automotive: {
        class: "App\\LuCore\\InventoryItems\\AutomotiveInventoryItemClass",
        code: 'automotive',
        title: "Vehicles",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.vehicles,
        showProofButton: true
    },
    real_estate: {
        class: "App\\LuCore\\InventoryItems\\RealEstateInventoryItemClass",
        code: 'real_estate',
        title: "Properties",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.properties,
        showProofButton: true
    },
    creative: {
        class: "App\\LuCore\\InventoryItems\\CreativeInventoryItemClass",
        code: "creative",
        title: "Creatives",
        allowUserDelete: true,
        route: RoutePaths.creatives,
        allowPrimaryPhotoOverride: false,
        showProofButton: true
    },
    photoStream: {
        class: "App\\LuCore\\InventoryItems\\PhotoStreamInventoryItemClass",
        code: "photoStream",
        title: "Posts",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.photoStream,
        showProofButton: true
    },
    childCampaign: {
        class: "App\\LuCore\\InventoryItems\\InventoryExportInventoryItemClass",
        code: "childCampaign",
        title: "Child Campaigns",
        route: RoutePaths.childCampaign,
        hidden: true
    },
    defaultCreative: {
        class: "App\\LuCore\\InventoryItems\\DefaultCreativeInventoryItemClass",
        code: "defaultCreative",
        title: "Default Creative",
        route: RoutePaths.inventory,
        allowPrimaryPhotoOverride: false,
        hidden: true,
        showProofButton: true
    },
    realEstateAgent: {
        class: "App\\LuCore\\InventoryItems\\RealEstateAgentInventoryItemClass",
        code: "realEstateAgent",
        title: "Agents",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: false,
        route: RoutePaths.realEstateAgents,
        showProofButton: true
    },
    article: {
        class: "App\\LuCore\\InventoryItems\\ArticleInventoryItemClass",
        code: "article",
        title: "Articles",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.articles,
        showProofButton: true
    },
    job_post: {
        class: "App\\LuCore\\InventoryItems\\JobPostInventoryItemClass",
        code: "job_post",
        title: "Job Postings",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.job_posts,
        showProofButton: true
    },
    review: {
        class: "App\\LuCore\\InventoryItems\\ReviewInventoryItemClass",
        code: "review",
        title: "Reviews",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.reviews,
        showProofButton: true
    },
    social_post: {
        class: "App\\LuCore\\InventoryItems\\SocialPostInventoryItemClass",
        code: "social_post",
        title: "Social Posts",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.socialPosts,
        showProofButton: true
    },
    product: {
        class: "App\\LuCore\\InventoryItems\\ProductInventoryItemClass",
        code: "product",
        title: "Products",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.products,
        showProofButton: true
    },
    recreational: {
        class: "App\\LuCore\\InventoryItems\\RecreationalInventoryItemClass",
        code: "recreational",
        title: "Recreational",
        canAssignTempalates: true,
        allowUserDelete: true,
        allowPrimaryPhotoOverride: true,
        route: RoutePaths.recreationals,
        showProofButton: true
    }
}

export class CardBrand {
    static visa = "Visa";
    static masterCard = "MasterCard";
}

export const InventoryItemPlaceholder = {
    [InventoryItemClasses.generic.class]: require('../assets/images/generic_placeholder.jpg').default,
    [InventoryItemClasses.automotive.class]: require('../assets/images/vehicle_placeholder.png').default,
    [InventoryItemClasses.real_estate.class]: require('../assets/images/property_placeholder.jpg').default,
}

export class InventoryItemStatus {
    static draft = 2;
    static active = 1;
    static sold = 0;
    static deleted = -1;
}

export class InventoryItemCreativeState {
    static none = 0;
    static building = 1;
    static complete = 2;
    static error = 3;
}

export const InventoryItemStatusDetails = {
    [InventoryItemStatus.active]: {
        title: 'Active'
    },
    [InventoryItemStatus.sold]: {
        title: 'Sold'
    },
    [InventoryItemStatus.deleted]: {
        title: 'Deleted'
    }
}

export class MenuLinkSection {
    static account = "Account";
    static agency = "Agency";
    static user = "User";
    static general = "General";
}

export class MenuLinksOrder {
    static home = 0;
    static map = 120;
    static profile = 150;
    static inventory = 200;
    static exports = 300;
    static analytics = 500;
    static accountChildren = 540;
    static accountUsers = 550;
    static applications = 580;
    static designerElements = 585;
    static accountBilling = 590;
    static settings = 600;
    static accountSettings = 600;
    static help = 1000;

    static approvals = 100;
    static agencyAccounts = 102;
    static agencyCampaigns = 105;
    static agencyAnalytics = 120;
    static agencyScreens = 151;
    static agencyUsers = 200;
    static agencyFeed = 250;
    static designer = 400;
    static appsAndData = 450;
    static agencyBilling = 590;
    static agencySettings = 600;
}

export class AppTour {
    static profileSwitcher = {
        id: "profile_switcher",
        startPath: RoutePaths.home
    };
}

export class AppTourStatus {
    /**
     * When tour is waiting for some conditions
     *
     * @static
     * @memberof AppTourStatus
     */
    static pending = 'pending';

    /**
     * When tour is ready to be shown
     *
     * @static
     * @memberof AppTourStatus
     */
    static ready = 'ready';

    static started = 'started';
    static skipped = 'skipped';
    static completed = 'completed';
}

export class SortDir {
    static asc = 'asc';
    static desc = 'desc';
}

export class SortFields {
    static id = "id";
    static title = "title";
    static status = "status";
    static dealerStockNumber = "dealer_stock_number";
    static year = "year";
    static make = "make";
    static model = "model";
    static sub_model = "sub_model";
    static price = "price";
    static itemClass = "item_class";
}

export class DialogCloseReason {
    static confirm = "confirm";
    static skip = "skip";
    static cancelButton = 'cancelButton';
    static closeIcon = 'closeIcon';
    static backdropClick = 'backdropClick';
    static escapeKeyDown = 'escapeKeyDown';
}

export class ApprovalRejectCode {
    static inappropriateContent = 100;
}

export class ApprovalStatus {
    static undefined = {
        id: 0
    };
    static ok = {
        id: 1,
        title: "Approved"
    };
    static ai_approved = {
        id: 5,
        title: "Approved by AI"
    };
    static ai_rejected = {
        id: 6,
        title: "Rejected by AI"
    };
    static pending = {
        id: 2,
        title: "Pending"
    };
    static rejected = {
        id: 3,
        title: "Rejected"
    };
    static held = {
        id: 4
    };
}

export class ApprovalRunState {
    static allButRejected = {
        id: 2,
        title: "All But Rejected",
        description:
            "All ads will run UNTIL they are rejected. This includes ads that are pending or have not yet been through the approval process"
    };
    static approvedOnly = {
        id: 3,
        title: "Approved Only",
        description: "ONLY approved ads can run.  This includes Human Approved AND Ai Approved Ads"
    };
}

export const DefaultApprovalType = "all_not_rejected"

export const ApprovalTypes = {
    TeamApproved: "team_approved",
    AllApproved: "all_approved",
    AutoApproverReject: "auto_approver_reject",
    AllNotRejected: "all_not_rejected"
};

export const defaultApprovalMinModerationScore = 0.9
export const defaultApprovalMinModerationScoreToShowDetails = 0.95

export const compositionObjectClassTypes = {
    text: "text",
    image: "image"
}

export const compositionObjectClasses = {
    "App\\LuCore\\Images\\Composition\\TextCreativeCompositionObject": {
        title: "Text",
        code: "text",
        baseClassName: "TextCreativeCompositionObject",
        type: "text"
    },
    "App\\LuCore\\Images\\Composition\\TextMacroCreativeCompositionObject": {
        title: "Text Macro",
        code: "text_macro",
        baseClassName: "TextMacroCreativeCompositionObject",
        type: "text"
    },
    "App\\LuCore\\Images\\Composition\\DriveTemplateTextCreativeCompositionObject": {
        title: "Drive Template Text",
        code: "drive_template_text",
        baseClassName: "DriveTemplateTextCreativeCompositionObject",
        type: "text"
    },
    "App\\LuCore\\Images\\Composition\\ImageCreativeCompositionObject": {
        title: "Image",
        code: "image",
        baseClassName: "ImageCreativeCompositionObject",
        type: "image"
    },
    "App\\LuCore\\Images\\Composition\\ImageMacroCreativeCompositionObject": {
        title: "Image Macro",
        code: "image_macro",
        baseClassName: "ImageMacroCreativeCompositionObject",
        type: "image"
    }
}

export class AppLinks {
    static google = "https://play.google.com/store/apps/details?id=cc.lucit.layoutui";
    static apple = "https://apps.apple.com/us/app/id1523546546";
}

export class InvitationStatus {
    static pending = {
        id: 0,
        title: "Pending"
    };
    static accepted = {
        id: 1,
        title: "Accepted"
    };
    static duplicate = {
        id: 2,
        title: "Duplicate"
    };
    static declined = {
        id: 3,
        title: "Declined"
    };
    static expired = {
        id: 4,
        title: "Expired"
    };
    static deleted = {
        id: 5,
        title: "Deleted"
    };
    static invalid = {
        id: 6,
        title: "Invalid"
    };
}

export const DEVICE_ID_LCUID_PREFIX = "LDID"

export class LuCoreModelClasses {
    static digitalBoard = {
        class: "App\\DigitalBoard",
        lid: "LB"
    }
    static story = {
        class: "App\\FeedStory",
        lid: "LFS"
    }
    static creativeProof = {
        class: "App\\CreativeProof",
        lid: "LCP"
    }
    static comments = {
        class: "App\\Comment",
        lid: "LCT"
    }
    static agencies = {
        class: "App\\Agency",
        lid: "LY"
    }
    static applications = {
        class: "App\\Application",
        lid: "LAP"
    }
    static accounts = {
        class: "App\\Account",
        lid: "LA"
    }
    static inventoryItem = {
        class: "App\\InventoryItem",
        lid: "LI"
    }
    static users = {
        class: "App\\User",
        lid: "LU"
    }
    static lightningDevice = {
        class: "App\\LightningDevice",
        lid: "LD"
    }
}

export class ProfileType {
    static user = {
        id: 'User',
        path: RoutePaths.home
    };
    static account = {
        id: 'Account',
        path: RoutePaths.home
    };
    static agency = {
        id: 'Agency',
        path: RoutePaths.home
    };
}

export class ReactionClass {
    static like = 'App\\LuCore\\Reactions\\LikeReactionClass'
}

export class FileExtension {
    static pdf = "pdf";
    static doc = "doc";
    static docx = "docx";
    static xls = "xls";
    static xlsx = "xlsx";
    static csv = "csv";
    static jpg = "jpg";
    static jpeg = "jpeg";
    static png = "png";
    static gif = "gif";
}

export class ApplicationStatus {
    static draft = {
        id: 0,
        title: 'Draft'
    };
    static active = {
        id: 1,
        title: 'Active'
    };
    static inactive = {
        id: 2,
        title: 'Inactive'
    };
    static deleted = {
        id: 3,
        title: 'Deleted'
    };
    static pending = {
        id: 4,
        title: 'Under Review'
    };
    static rejected = {
        id: 5,
        title: 'Rejected'
    };
}

export class ApplicationClasses {
    static core = {
        id: "core",
        class: 'App\\LuCore\\Applications\\CoreApplicationClass',
        title: 'Core Application',
        requiresAuthorization: false
    };
    static generic = {
        id: "generic",
        class: 'App\\LuCore\\Applications\\GenericApplicationClass',
        title: 'Generic Application',
        requiresAuthorization: false
    };
    static private = {
        id: "private",
        class: 'App\\LuCore\\Applications\\PrivateApplicationClass',
        title: 'Private Application',
        requiresAuthorization: false
    };
    static thirdParty = {
        id: "thirdParty",
        class: 'App\\LuCore\\Applications\\ThirdPartyApplicationClass',
        title: 'Third Party Application',
        requiresAuthorization: true
    };
}

export class ApplicationCapabilities {
    static analyticsReporter = {
        id: "analyticsReporter",
        class: "App\\LuCore\\Applications\\Capabilities\\AnalyticsReporterCapabilityClass",
        name: "Analytics Reporter",
        showForAccount: true,
        showForOperator: true,
        showForAdAgency: true,
        components: {}
    };
    static coreApplication = {
        id: "coreApplication",
        class: "App\\LuCore\\Applications\\Capabilities\\CoreApplicationCapabilityClass",
        name: "Core Application",
        components: {}
    };
    static dataSource = {
        id: "dataSource",
        class: "App\\LuCore\\Applications\\Capabilities\\DataSourceApplicationCapabilityClass",
        name: "Data Source",
        showForAccount: true,
        components: {
            developerAppSettings: true
        },
        hasAssignedAppCapabilityPreviewComponent: true,
        hasAssignedAppCapabilityActionsComponent: true,
        hasDeveloperAppSettingsPage: true
    };
    static inventoryBuilder = {
        id: "inventoryBuilder",
        class: "App\\LuCore\\Applications\\Capabilities\\InventoryBuilderApplicationCapabilityClass",
        name: "Inventory Builder",
        showForAccount: true,
        components: {
            developerAppSettings: true
        },
        hasDeveloperAppSettingsPage: true
    };
    static widgets = {
        id: "widgets",
        class: "App\\LuCore\\Applications\\Capabilities\\WidgetsCapabilityClass",
        name: "Widgets",
        showForAccount: true,
        showForOperator: true,
        components: {
            developerAppSettings: true
        },
        hasDeveloperAppSettingsPage: true
    };
    static googleStreetView = {
        id: "googleStreetView",
        class: "App\\LuCore\\Applications\\Capabilities\\GoogleStreetViewCapabilityClass",
        name: "Google Street View",
        showForOperator: true,
        components: {}
    };
    static player = {
        id: "player",
        class: "App\\LuCore\\Applications\\Capabilities\\PlayerCapabilityClass",
        name: "Player",
        showForOperator: true,
        components: {}
    };
    static whiteLabel = {
        id: "whiteLabel",
        class: "App\\LuCore\\Applications\\Capabilities\\WhiteLabelCapabilityClass",
        name: "White Label",
        showForOperator: true,
        showForAdAgency: true,
        components: {}
    };
    static xrImageBrand = {
        id: "xrImageBrand",
        class: "App\\LuCore\\Applications\\Capabilities\\XRImageBrandCapabilityClass",
        name: "XR Image Brand",
        showForOperator: true,
        components: {}
    };
    static designer = {
        id: "designer",
        class: "App\\LuCore\\Applications\\Capabilities\\DesignerCapabilityClass",
        name: "Designer",
        components: {
            developerAppSettings: true
        },
        hasDeveloperAppSettingsPage: true,
        showForAccount: true,
    }

}

export class Designer {
    static Messages = {
        UpdateContainerHtml: "update_container_html",
        UpdateContainerCss: "update_container_css",
        NotifyClickedOff: "notify_clicked_off",
        ElementSelected: "element_selected",
        ElementDoubleClicked: "element_double_clicked",
        ElementDragResize: "element_move_resize",
        ElementDeleted: "element_deleted",
        RunFormattingFunctions: "run_formatting_functions",
        ElementOpenSettings: "element_open_settings",
        ElementOpenEdit: "element_open_edit",
        ElementHide: "element_hide",
        ElementUnHide: "element_unhide",
        ElementLock: "element_lock",
        ElementUnlock: "element_unlock",
        ElementMoveLayer: "element_move_layer",
        ElementDuplicateLayer: "element_duplicate_layer",
        FormatCopy: "format_copy",
        FormatPaste: "format_paste",
        PasteEvent: "paste_event",
        RequestToChangeImage: "request_to_change_image",

        UpdateGridSize: "update_grid_size",
        UpdateGridVisibility: "update_grid_visibility",
        UpdateGridColor: "update_grid_color",
        UpdateSnapping: "update_snapping",
        UpdateBorderAllElements: "update_border_all_elements",

        ZoomInOut: 'zoom_in_out'
    };

    static PasteObjectTypes = {
        Text: "text",
        LucitObject: "lucit_object",
        Image: "image"
    }

    static Directions = {
        Backward: "backward",
        Forward: "forward",
        Front: "front",
        Back: "back",
    }

    static ObjectClasses = {
        DataSource: "data_source",
        Asset: "asset",
        Object: "object",
    }

    static ObjectTypes = {
        Text: "text",
        Image: "img",
        Object: "object",
        Svg: "svg",
    }

    static ObjectElementClasses = {
        Background: {
            name: "Background",
            code: "Background",
            class: "App\\LuCore\\DriveTemplates\\BackgroundDriveTemplateElementClass"
        },
        BasicShape: {
            name: "Shape",
            code: "BasicShape",
            class: "App\\LuCore\\DriveTemplates\\BasicShapeDriveTemplateElementClass"
        },
        Text: {
            name: "Text",
            code: "Text",
            class: "App\\LuCore\\DriveTemplates\\TextDriveTemplateElementClass"
        },
    }

    static ObjectCodeForTextPaste = "text"

    static BackgroundLayerId = "rectangle_default_bg";

    static ColorPresets = [
        '#000000', // Black
        '#FFFFFF', // White
        '#1FAD94', // Teal
        '#F5A623', // Orange
        '#FF4081', // Pink
        '#6B5B95', // Purple
        '#4A90E2', // Blue
        '#E81C4F', // Red
        '#FFC107', // Yellow
        '#00B894', // Green
        '#D35400', // Brown
        '#8E44AD', // Violet
        '#FF5722', // Deep Orange
        '#009688', // Dark Teal
        '#E91E63', // Raspberry
        '#3F51B5', // Indigo
        '#FFEB3B', // Yellow
        '#4CAF50'  // Green
    ]

    static ShadowColorPresets = [
        '#000000', // Black
        '#333333', // Dark Gray
        '#666666', // Gray
        '#999999', // Light Gray
        '#CCCCCC', // Very Light Gray
        '#E6E6E6', // Pale Gray
        '#F2F2F2', // Off White
        '#FFFFFF', // White
    ]

    static DefaultFontName = 'Public Sans Thin';
    static DefaultBorderColor = '#000000';
    static DefaultShadowColor = 'rgba(0, 0, 0, 0.45)';
    static DefaultTextColor = '#2a2a2a';
    static DefaultObjectColor = '#333333';

    static IgnoreLastColorIfOneOf = [
        '#FFFFFF', // White
        'rgba(255, 255, 255, 1)', // Also white
    ]

    static NewTemplateHTML = `
        <div x-objectcode="rectangle" id="${this.BackgroundLayerId}" class="lc_ut_designer lc_dt_object lc_dt_default_background"></div>
    `

    static NewTemplateCSS = `
        #${this.BackgroundLayerId} {
            width: 100%;
            height: 100%;
            top:0%;
            left:0%;
        }
    `

    static NewObjectSizing = {
        width: {
            min_pct: 30,
            max_pct: 50
        },
        height: {
            min_pct: 30,
            max_pct: 50
        },
        top: {
            min_pct: 10,
            max_pct: 60
        },
        left: {
            min_pct: 10,
            max_pct: 60
        },
    }

    static PresetsDropShadow = [
        {
            id: "bottom_right",
            name: "Bottom Right",
            horizontalOffset: 5,
            verticalOffset: 5,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_middle",
            name: "Bottom Middle",
            horizontalOffset: 0,
            verticalOffset: 5,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_left",
            name: "Bottom Left",
            horizontalOffset: -5,
            verticalOffset: 5,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "middle_right",
            name: "Mid Right",
            horizontalOffset: 5,
            verticalOffset: 0,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "none",
            name: "None",
            horizontalOffset: 0,
            verticalOffset: 0,
            blur: 0,
            color: "",
            isNone: true
        },
        {
            id: "middle_left",
            name: "Mid Left",
            horizontalOffset: -5,
            verticalOffset: 0,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "top_right",
            name: "Top Right",
            horizontalOffset: 5,
            verticalOffset: -5,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "top_middle",
            name: "Top Middle",
            horizontalOffset: 0,
            verticalOffset: -5,
            blur: 4,
            color: this.DefaultShadowColor
        },
        {
            id: "top_left",
            name: "Top Left",
            horizontalOffset: -5,
            verticalOffset: -5,
            blur: 4,
            color: this.DefaultShadowColor
        }
    ]

    static PresetsBoxShadow = [
        {
            id: "bottom_right",
            name: "Bottom Right",
            horizontalOffset: 5,
            verticalOffset: 5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_middle",
            name: "Bottom Middle",
            horizontalOffset: 0,
            verticalOffset: 5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_left",
            name: "Bottom Left",
            horizontalOffset: -5,
            verticalOffset: 5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "middle_right",
            name: "Mid Right",
            horizontalOffset: 5,
            verticalOffset: 0,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "none",
            name: "None",
            horizontalOffset: 0,
            verticalOffset: 0,
            blur: 0,
            color: "",
            isNone: true
        },
        {
            id: "middle_left",
            name: "Mid Left",
            horizontalOffset: -5,
            verticalOffset: 0,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "top_right",
            name: "Top Right",
            horizontalOffset: 5,
            verticalOffset: -5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "top_middle",
            name: "Top Middle",
            horizontalOffset: 0,
            verticalOffset: -5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        },
        {
            id: "top_left",
            name: "Top Left",
            horizontalOffset: -5,
            verticalOffset: -5,
            blur: 10,
            spread: -1,
            color: this.DefaultShadowColor
        }
    ]

    static PresetsTextShadow = [
        {
            id: "bottom_right",
            name: "Bottom Right",
            horizontalOffset: 3,
            verticalOffset: 3,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_middle",
            name: "Bottom Middle",
            horizontalOffset: 0,
            verticalOffset: 3,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "bottom_left",
            name: "Bottom Left",
            horizontalOffset: -3,
            verticalOffset: 3,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "middle_right",
            name: "Mid Right",
            horizontalOffset: 3,
            verticalOffset: 0,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "none",
            name: "None",
            horizontalOffset: 0,
            verticalOffset: 0,
            blur: 0,
            color: "",
            isNone: true
        },
        {
            id: "middle_left",
            name: "Mid Left",
            horizontalOffset: -3,
            verticalOffset: 0,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "top_right",
            name: "Top Right",
            horizontalOffset: 3,
            verticalOffset: -3,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "top_middle",
            name: "Top Middle",
            horizontalOffset: 0,
            verticalOffset: -3,
            blur: 5,
            color: this.DefaultShadowColor
        },
        {
            id: "top_left",
            name: "Top Left",
            horizontalOffset: -3,
            verticalOffset: -3,
            blur: 5,
            color: this.DefaultShadowColor
        }
    ]

    static CustomFieldGroupName = "Custom Field"
    static CustomFieldGroupDescription = "Custom fields that have been added to this template"

    static CustomFieldTemplate = {
        id: null,
        object: "custom_field",
        object_name: "Custom Field",
        group_id: "custom_field",
        group_name: "Custom Field",
        "class": "App\\LuCore\\DriveTemplates\\CustomField",
        property: null,
        macro: null,
        macroCode: null,
        name: null,
        placeholder: null,
        type: "text",
        defaultClasses: "lc_ut_designer lc_dt_data lc_dt_text lc_format_fit_text lc_dt_data_function",
        "defaultCss": "",
        "show": true
    }

    static CustomFieldItemAttributeTemplate = {
        class: "App\\LuCore\\InventoryItems\\InventoryItemClass",
        defaultClasses: "lc_ut_designer lc_dt_data lc_dt_text lc_format_fit_text",
        defaultCss: "",
        description: "Custom field added to this template",
        group_id: "custom",
        group_name: "Custom",
        object: "item",
        object_name: "Ad Item",
        show: true,
        sort_order: 10,
        type: "text",
    }

    static AssetElements = {
        "img": {
            name: "Image",
            id_prefix: "img_",
            type: this.ObjectTypes.Image,
            defaultClasses: "lc_ut_designer lc_dt_asset lc_dt_image",
            defaultCss: ""
        }
    }

    static ObjectElements = [
        {
            name: "Text",
            code: "text",
            type: this.ObjectTypes.Text,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fontSize: "34px",
                textAlign: "center",
                verticalAlign: "center",
                display: "table-cell"
            }}> Az </div>,
            html: `<div x-objectcode="text" id="{id}" title="Custom Text"
                class="lc_ut_designer lc_dt_element lc_dt_text lc_dt_text_editable lc_format_fit_text">
                Your New Text
                </div>`,
            defaultCss: ""
        },
        {
            name: "Paragraph",
            code: "paragraph",
            type: this.ObjectTypes.Text,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fontSize: "10px",
                textAlign: "center",
                verticalAlign: "center",
                display: "table-cell"
            }}> Lorem Ipsum Text </div>,
            html: `<div x-objectcode="paragraph" id="{id}" title="Custom Paragraph Text"
                class="lc_ut_designer lc_dt_element lc_dt_text lc_dt_text_editable lc_format_fit_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </div>`,
            defaultCss: ""
        },
        {
            name: "Square",
            code: "square",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 100 100">
                    <rect x="5" y="5" width="90" height="90" />
                </svg>
            </div>,
            html: `<div x-objectcode="square" id="{id}" title="Square"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 100 100" pointer-events="none">
                            <rect x="5" y="5" width="90" height="90" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "Rectangle",
            code: "rectangle",
            type: this.ObjectTypes.Object,
            preview: <div style={{
                width: "50px",
                height: "25px",
                backgroundColor: this.DefaultObjectColor
            }}></div>,
            html: `<div x-objectcode="rectangle" title="Rectangle"
                id="{id}" class="lc_ut_designer lc_dt_element lc_dt_object"></div>`,
            defaultCss: ""
        },
        {
            name: "Circle",
            code: "circle",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
                    <circle cx="50" cy="50" r="40" />
                </svg>
            </div>,
            html: `<div x-objectcode="circle" id="{id}" title="Circle"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg height="100%" width="100%" viewBox="0 0 100 100"  preserveAspectRatio="xMidYMid meet" pointer-events="none">
                        <circle cx="50" cy="50" r="40"  />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Oval",
            code: "oval",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <circle cx="50" cy="50" r="40" />
                </svg>
            </div>,
            html: `<div x-objectcode="oval" id="{id}"  title="Oval"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none" pointer-events="none">
                        <circle cx="50" cy="50" r="40" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Semicircle",
            code: "semicircle",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1040,290c0,276.14-223.86,500-500,500S40,566.14,40,290h1000Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="semicircle" id="{id}" title="Semicircle"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1040,290c0,276.14-223.86,500-500,500S40,566.14,40,290h1000Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "D-Shape",
            code: "dshape",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m875.81,369.81c0-185.46-150.35-335.81-335.81-335.81h0c-185.46,0-335.81,150.35-335.81,
                    335.81h0c0,1.53.04,3.05.06,4.58h-.06v671.61h671.61V374.39h-.06c.02-1.53.06-3.05.06-4.58h0Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="dshape" id="{id}" title="D-Shape"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m875.81,369.81c0-185.46-150.35-335.81-335.81-335.81h0c-185.46,0-335.81,150.35-335.81,
                        335.81h0c0,1.53.04,3.05.06,4.58h-.06v671.61h671.61V374.39h-.06c.02-1.53.06-3.05.06-4.58h0Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Triangle",
            code: "triangle",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polygon points="540 106.99 40 973.01 1040 973.01 540 106.99" />
                </svg>
            </div>,
            html: `<div x-objectcode="triangle" id="{id}" title="Triangle"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                            <polygon points="540 106.99 40 973.01 1040 973.01 540 106.99" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "Right Triangle",
            code: "triangle_right",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <polygon points="1040 1040 40 1040 40 40 1040 1040" preserveAspectRatio="none" />
                </svg>
            </div>,
            html: `<div x-objectcode="triangle_right" id="{id}" title="Right Triangle"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                            <polygon points="1040 1040 40 1040 40 40 1040 1040" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "Rhombus",
            code: "rhombus",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polygon points="705.14 825.11 40 825.11 374.86 254.89 1040 254.89 705.14 825.11" />
                </svg>
            </div>,
            html: `<div x-objectcode="rhombus" id="{id}" title="Rhombus"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                            <polygon points="705.14 825.11 40 825.11 374.86 254.89 1040 254.89 705.14 825.11" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "Diamond",
            code: "diamond",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <rect x="186.45" y="186.45" width="707.11" height="707.11"
                        transform="translate(1303.68 540) rotate(135)" preserveAspectRatio="none" />
                </svg>
            </div>,
            html: `<div x-objectcode="diamond" id="{id}" title="Diamond"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                            <rect x="186.45" y="186.45" width="707.11" height="707.11" transform="translate(1303.68 540) rotate(135)" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "House",
            code: "house",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polygon points="1012.02 489.79 540 40 67.98 489.79 67.82 489.79 67.82 1040 1012.18 1040 1012.18 489.79 1012.02 489.79" />
                </svg>
            </div>,
            html: `<div x-objectcode="house" id="{id}" title="House"
                        class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                        <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                            <polygon points="1012.02 489.79 540 40 67.98 489.79 67.82 489.79 67.82 1040 1012.18 1040 1012.18 489.79 1012.02 489.79" />
                        </svg>
                    </div>`,
            defaultCss: ""
        },
        {
            name: "Hexagon",
            code: "hexagon",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <polygon points="784.64 108.67 1040 540 791.07 971.33 286.78 971.33 40 540 288.93 108.67 784.64 108.67" />
                </svg>
            </div>,
            html: `<div x-objectcode="hexagon" id="{id}" title="Hexagon"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <polygon points="784.64 108.67 1040 540 791.07 971.33 286.78 971.33 40 540 288.93 108.67 784.64 108.67" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Sparkle",
            code: "sparkle",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "37.5px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m540,40h0c0,276.14,223.86,500,500,500h0,0c-276.14,0-500,
                    223.86-500,500h0s0,0,0,0c0-276.14-223.86-500-500-500h0,0c276.14,0,500-223.86,500-500h0Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="sparkle" id="{id}" title="Sparkle"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m540,40h0c0,276.14,223.86,500,500,500h0,0c-276.14,0-500,
                        223.86-500,500h0s0,0,0,0c0-276.14-223.86-500-500-500h0,0c276.14,0,500-223.86,500-500h0Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "5-Point Star",
            code: "star_5_point",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m230.87,427.09c59.91,0,119.81-.07,179.72.09,7.12.02,12-1.19,14.56-9.09,29.99-92.57,60.3-185.03,
                    90.44-277.55,7.11-21.84,13.82-43.81,20.87-65.68.99-3.07,1.14-8.1,4.84-8.16,5.22-.09,3.55,5.61,4.58,8.76,
                    20.66,62.92,41.27,125.86,61.65,188.87,16.63,51.44,33.03,102.95,49.28,154.5,2.03,6.44,5.96,8.34,12.04,8.29,
                    14.89-.12,29.78-.06,44.68-.06,100.52,0,201.04.03,301.57-.03,5.4,0,10.79-.73,16.19-1.01,2.95-.15,6.78-1.43,
                    8.3,1.84,1.79,3.85-2.52,4.99-4.74,6.59-34.31,24.69-68.82,49.11-103.05,73.92-62.16,45.06-124.07,90.46-186.33,
                    135.38-8.48,6.12-11.42,11.18-7.72,22.38,36.58,110.69,72.38,221.63,108.25,332.55,1.46,4.5,7.43,10.13,2.52,13.63-4.96,
                    3.53-9.39-3.29-13.56-6.25-71.74-50.88-141.37-104.64-213.28-155.31-24.27-17.1-48.31-34.73-71.67-53.12-6.73-5.29-10.88-5.17-17.39-.39-72.02,
                    52.81-144.22,105.36-216.36,157.99-24.59,17.94-49.15,35.93-73.76,53.86-2.37,1.73-5.02,5.23-7.84,3.29-3.32-2.28-1.02-6.17,
                    0-9.3,30.62-93.44,59.89-187.32,91.9-280.3,6.6-19.16,11.79-38.81,18.51-57.92,
                    3.32-9.44.68-14.95-7.03-20.49-52.19-37.51-104.13-75.35-156.19-113.05-40.55-29.36-81.1-58.71-121.76-87.91-5.46-3.93-11.53-7.02-17.34-10.45-1.51-.89-3.12-1.88-2.68-3.8.43-1.85,
                    2.32-2.46,4-2.45,5.07.03,10.13.4,15.19.4,57.2.03,114.4.02,171.6.02,0-.01,0-.02,0-.03Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="star_5_point" id="{id}" title="5-Point Star"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m230.87,427.09c59.91,0,119.81-.07,179.72.09,7.12.02,12-1.19,14.56-9.09,
                        29.99-92.57,60.3-185.03,90.44-277.55,7.11-21.84,13.82-43.81,20.87-65.68.99-3.07,
                        1.14-8.1,4.84-8.16,5.22-.09,3.55,5.61,4.58,8.76,20.66,62.92,41.27,125.86,61.65,
                        188.87,16.63,51.44,33.03,102.95,49.28,154.5,2.03,6.44,5.96,8.34,12.04,8.29,14.89-.12,
                        29.78-.06,44.68-.06,100.52,0,201.04.03,301.57-.03,5.4,0,10.79-.73,16.19-1.01,2.95-.15,
                        6.78-1.43,8.3,1.84,1.79,3.85-2.52,4.99-4.74,6.59-34.31,24.69-68.82,49.11-103.05,73.92-62.16,
                        45.06-124.07,90.46-186.33,135.38-8.48,6.12-11.42,11.18-7.72,22.38,36.58,110.69,72.38,221.63,
                        108.25,332.55,1.46,4.5,7.43,10.13,2.52,13.63-4.96,
                        3.53-9.39-3.29-13.56-6.25-71.74-50.88-141.37-104.64-213.28-155.31-24.27-17.1-48.31-34.73-71.67-53.12-6.73-5.29-10.88-5.17-17.39-.39-72.02,
                        52.81-144.22,105.36-216.36,157.99-24.59,17.94-49.15,35.93-73.76,53.86-2.37,1.73-5.02,5.23-7.84,
                        3.29-3.32-2.28-1.02-6.17,0-9.3,30.62-93.44,59.89-187.32,91.9-280.3,6.6-19.16,11.79-38.81,18.51-57.92,
                        3.32-9.44.68-14.95-7.03-20.49-52.19-37.51-104.13-75.35-156.19-113.05-40.55-29.36-81.1-58.71-121.76-87.91-5.46-3.93-11.53-7.02-17.34-10.45-1.51-.89-3.12-1.88-2.68-3.8.43-1.85,
                        2.32-2.46,4-2.45,5.07.03,10.13.4,15.19.4,57.2.03,114.4.02,171.6.02,0-.01,0-.02,0-.03Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "6-Point Star",
            code: "star_6_point",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <polygon points="790.14 107.07 756.55 415.07 1040 540.16 756.47 665.07 789.86 973.09
                    539.92 790 289.86 972.93 323.45 664.93 40 539.84 323.53 414.93 290.14 106.91 540.08 290 790.14 107.07" />
                </svg>
            </div>,
            html: `<div x-objectcode="star_6_point" id="{id}" title="6-Point Star"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <polygon points="790.14 107.07 756.55 415.07 1040 540.16 756.47 665.07 789.86
                        973.09 539.92 790 289.86 972.93 323.45 664.93 40 539.84 323.53 414.93 290.14 106.91 540.08 290 790.14 107.07"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "rounded Cross",
            code: "rounded_cross",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m1022.15,361.92h-286.22c-9.86,0-17.85-7.99-17.85-17.85V57.85c0-9.86-7.99-17.85-17.85-17.85h-320.47c-9.86,0-17.85,7.99-17.85,
                    17.85v286.22c0,9.86-7.99,17.85-17.85,17.85H57.85c-9.86,0-17.85,7.99-17.85,17.85v320.47c0,9.86,7.99,17.85,17.85,17.85h286.22c9.86,0,
                    17.85,7.99,17.85,17.85v286.22c0,9.86,7.99,17.85,17.85,17.85h320.47c9.86,0,17.85-7.99,17.85-17.85v-286.22c0-9.86,
                    7.99-17.85,17.85-17.85h286.22c9.86,0,17.85-7.99,17.85-17.85v-320.47c0-9.86-7.99-17.85-17.85-17.85Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="rounded_cross" id="{id}" title="rounded Cross"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m1022.15,361.92h-286.22c-9.86,0-17.85-7.99-17.85-17.85V57.85c0-9.86-7.99-17.85-17.85-17.85h-320.47c-9.86,0-17.85,7.99-17.85,
                        17.85v286.22c0,9.86-7.99,17.85-17.85,17.85H57.85c-9.86,0-17.85,7.99-17.85,17.85v320.47c0,9.86,7.99,17.85,17.85,17.85h286.22c9.86,0,
                        17.85,7.99,17.85,17.85v286.22c0,9.86,7.99,17.85,17.85,17.85h320.47c9.86,0,17.85-7.99,17.85-17.85v-286.22c0-9.86,
                        7.99-17.85,17.85-17.85h286.22c9.86,0,17.85-7.99,17.85-17.85v-320.47c0-9.86-7.99-17.85-17.85-17.85Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Label 1",
            code: "label1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1040,540.54c-33.3,11.02-35.09,38.45-36.42,65.41-1.57,31.68,3.34,63.33,1.8,95.06-1,20.55-2.72,40.98-10,
                    60.49-17.94,48.04-56.13,68.02-103.92,74.61-34.69,4.78-69.06.1-103.56-3.04-61.75-5.63-123.59-12.71-185.55-3.21-20.73,
                    3.18-40.94,9.1-58.18,21.95-4.07,3.04-7.11,3.64-11.46.45-22.31-16.35-48.32-22.05-75.11-24.76-49.45-5-98.68-.49-147.96,
                    3.6-35.2,2.92-70.33,8.05-105.75,6.79-28.83-1.03-56.71-6.84-81.17-23.2-32.97-22.04-45.12-55.26-50.31-92.81-5.13-37.19,
                    2.05-74.1.45-111.16-.52-11.93-1.39-23.66-4.41-35.26-3.6-13.84-9.8-25.47-23.51-31.89-3.67-1.72-8.33-4.8-1.23-7.88,
                    21.98-9.53,27.08-29.22,28.43-49.61,1.52-22.94,1-46.05-.73-69.11-2.28-30.25-2.27-60.42,6.84-89.98,16.67-54.11,
                    55.35-78.72,109.7-84.76,38.45-4.28,76.21,2.82,114.29,5.3,58.48,3.82,116.99,13.64,175.67,1.32,18.91-3.97,36.98-9.78,
                    53.17-20.47,4.37-2.89,8.11-4.01,12.79-.73,26.52,18.6,57.48,21.89,88.38,25.48,47.06,5.46,93.67-1.21,140.27-5.62,
                    42.03-3.98,84.04-10,126.11-3.2,63.78,10.32,98.33,48.1,105.01,112.3,3.37,32.39,1.88,64.8.33,97.18-.71,14.88.34,29.61,
                    2.48,44.14,2.83,19.25,7.26,23.92,33.57,42.64Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="label1" id="{id}" title="Label 1"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1040,540.54c-33.3,11.02-35.09,38.45-36.42,65.41-1.57,31.68,3.34,63.33,1.8,95.06-1,20.55-2.72,40.98-10,
                        60.49-17.94,48.04-56.13,68.02-103.92,74.61-34.69,4.78-69.06.1-103.56-3.04-61.75-5.63-123.59-12.71-185.55-3.21-20.73,
                        3.18-40.94,9.1-58.18,21.95-4.07,3.04-7.11,3.64-11.46.45-22.31-16.35-48.32-22.05-75.11-24.76-49.45-5-98.68-.49-147.96,
                        3.6-35.2,2.92-70.33,8.05-105.75,6.79-28.83-1.03-56.71-6.84-81.17-23.2-32.97-22.04-45.12-55.26-50.31-92.81-5.13-37.19,
                        2.05-74.1.45-111.16-.52-11.93-1.39-23.66-4.41-35.26-3.6-13.84-9.8-25.47-23.51-31.89-3.67-1.72-8.33-4.8-1.23-7.88,
                        21.98-9.53,27.08-29.22,28.43-49.61,1.52-22.94,1-46.05-.73-69.11-2.28-30.25-2.27-60.42,6.84-89.98,16.67-54.11,
                        55.35-78.72,109.7-84.76,38.45-4.28,76.21,2.82,114.29,5.3,58.48,3.82,116.99,13.64,175.67,1.32,18.91-3.97,36.98-9.78,
                        53.17-20.47,4.37-2.89,8.11-4.01,12.79-.73,26.52,18.6,57.48,21.89,88.38,25.48,47.06,5.46,93.67-1.21,140.27-5.62,
                        42.03-3.98,84.04-10,126.11-3.2,63.78,10.32,98.33,48.1,105.01,112.3,3.37,32.39,1.88,64.8.33,97.18-.71,14.88.34,29.61,
                        2.48,44.14,2.83,19.25,7.26,23.92,33.57,42.64Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Label 2",
            code: "label2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m86.16,770.8c-4.17.4-7.1-1.91-9.69-4.65-10.73-11.33-21.33-22.8-32.11-34.08-5.02-5.26-5.7-10.21-2.05-16.95,
                    26.53-49.04,37.68-102.19,39.44-157.31,1.42-44.52,2.15-89.18-6.38-133.25-5.36-27.69-10.47-55.56-20.92-81.9-4.79-12.08-3.54-20.62,
                    7.09-29.01,8.97-7.07,16.44-16.13,24.08-24.74,5.37-6.06,11.21-7.88,18.88-5.42,40.19,12.91,81.57,19.98,123.43,24.26,49.61,5.07,
                    99.17,7.45,149.12,2.26,53.29-5.54,104.92-16.79,154.75-36.34,5.94-2.33,11.51-1.95,17.29.47,58.42,24.39,119.85,33.74,182.6,37.6,
                    78.78,4.84,155.82-4.69,231.75-25.21.72-.19,1.46-.33,2.14-.62,15.05-6.22,27.38-4.85,37.4,10.08,5.7,8.49,14.1,15.27,21.68,22.39,
                    5.54,5.2,7.46,10.85,4.74,17.99-16.79,44.1-25.75,90.11-29.1,136.82-5.54,77.26-2.59,153.83,30.91,225.98.63,1.35,1.23,2.71,1.87,
                    4.05,10.43,21.98,9.61,21.31-6.23,40.72-16.63,20.39-33.08,27.07-61.59,20.68-113.86-25.54-227.97-24.79-340.67,9.3-26.43,8-51.94,
                    18.27-76.05,31.73-6.01,3.35-10.1,2.89-16.12-.62-31.76-18.48-66.5-29.52-101.88-38.67-55.06-14.23-111.11-20.35-167.9-19.02-57.02,
                    1.33-113.46,7.71-168.86,21.82-2.52.64-5.08,1.12-7.63,1.67Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="label2" id="{id}" title="Label 2"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m86.16,770.8c-4.17.4-7.1-1.91-9.69-4.65-10.73-11.33-21.33-22.8-32.11-34.08-5.02-5.26-5.7-10.21-2.05-16.95,
                        26.53-49.04,37.68-102.19,39.44-157.31,1.42-44.52,2.15-89.18-6.38-133.25-5.36-27.69-10.47-55.56-20.92-81.9-4.79-12.08-3.54-20.62,
                        7.09-29.01,8.97-7.07,16.44-16.13,24.08-24.74,5.37-6.06,11.21-7.88,18.88-5.42,40.19,12.91,81.57,19.98,123.43,24.26,49.61,5.07,
                        99.17,7.45,149.12,2.26,53.29-5.54,104.92-16.79,154.75-36.34,5.94-2.33,11.51-1.95,17.29.47,58.42,24.39,119.85,33.74,182.6,37.6,
                        78.78,4.84,155.82-4.69,231.75-25.21.72-.19,1.46-.33,2.14-.62,15.05-6.22,27.38-4.85,37.4,10.08,5.7,8.49,14.1,15.27,21.68,22.39,
                        5.54,5.2,7.46,10.85,4.74,17.99-16.79,44.1-25.75,90.11-29.1,136.82-5.54,77.26-2.59,153.83,30.91,225.98.63,1.35,1.23,2.71,1.87,
                        4.05,10.43,21.98,9.61,21.31-6.23,40.72-16.63,20.39-33.08,27.07-61.59,20.68-113.86-25.54-227.97-24.79-340.67,9.3-26.43,8-51.94,
                        18.27-76.05,31.73-6.01,3.35-10.1,2.89-16.12-.62-31.76-18.48-66.5-29.52-101.88-38.67-55.06-14.23-111.11-20.35-167.9-19.02-57.02,
                        1.33-113.46,7.71-168.86,21.82-2.52.64-5.08,1.12-7.63,1.67Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Label 3",
            code: "label3",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m408.16,886.42c-123.23-20.62-240.62-56.18-342.84-131.95-17.39-12.89-25.54-26.65-25.31-49.26,
                    1.15-113.12.83-226.26.2-339.38-.09-17.17,5.76-28.84,19.21-38.65,76.1-55.5,160.78-91.58,251.99-113.36,68.69-16.4,138.53-23.84,
                    208.89-25.72,47.45-1.27,94.64,3.52,141.84,9.52,85.98,10.92,168.63,32.95,248,67.52,42.9,18.68,82.96,42.36,120.65,70.14,6.61,4.88,
                    8.54,11.33,8.7,18.67.31,14.36.29,28.73.3,43.09,0,102.8-.29,205.61.22,308.41.09,18.74-4.67,32.41-19.7,45.43-60.67,52.55-131.49,
                    84.98-207.1,107.92-42.38,12.86-85.26,24.53-129.62,28.97-47.54,5.41-223.25,6.63-275.43-1.35Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="label3" id="{id}" title="Label 3"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m408.16,886.42c-123.23-20.62-240.62-56.18-342.84-131.95-17.39-12.89-25.54-26.65-25.31-49.26,
                        1.15-113.12.83-226.26.2-339.38-.09-17.17,5.76-28.84,19.21-38.65,76.1-55.5,160.78-91.58,251.99-113.36,68.69-16.4,138.53-23.84,
                        208.89-25.72,47.45-1.27,94.64,3.52,141.84,9.52,85.98,10.92,168.63,32.95,248,67.52,42.9,18.68,82.96,42.36,120.65,70.14,6.61,4.88,
                        8.54,11.33,8.7,18.67.31,14.36.29,28.73.3,43.09,0,102.8-.29,205.61.22,308.41.09,18.74-4.67,32.41-19.7,45.43-60.67,52.55-131.49,
                        84.98-207.1,107.92-42.38,12.86-85.26,24.53-129.62,28.97-47.54,5.41-223.25,6.63-275.43-1.35Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Bracket Left",
            code: "bracket_left",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "35px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polyline className="cls-1" points="695.62 1024.26 384.38 1024.26 384.38 40.5" />
                    <polyline className="cls-1" points="695.62 55.74 384.38 55.74 384.38 1039.5" />
                </svg>
            </div>,
            html: `<div x-objectcode="bracket_left" id="{id}" title="Bracket Left"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <polyline class="cls-1" points="695.62 1024.26 384.38 1024.26 384.38 40.5" />
                        <polyline class="cls-1" points="695.62 55.74 384.38 55.74 384.38 1039.5" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Bracket Right",
            code: "bracket_right",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "35px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polyline className="cls-1" points="384.38 1024.26 695.62 1024.26 695.62 40.5" />
                    <polyline className="cls-1" points="384.38 55.74 695.62 55.74 695.62 1039.5" />
                </svg>
            </div>,
            html: `<div x-objectcode="bracket_right" id="{id}" title="Bracket Right"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <polyline class="cls-1" points="384.38 1024.26 695.62 1024.26 695.62 40.5" />
                        <polyline class="cls-1" points="384.38 55.74 695.62 55.74 695.62 1039.5" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Cheveron",
            code: "cheveron",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "25px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m110.71,34.41c2.8-.27,4.86-.65,6.92-.65,111.3.1,222.61.34,333.91.2,10.02-.01,16.87,3.54,22.23,11.52,1.75,
                    2.61,4.19,4.77,6.44,7.02,159.89,159.91,319.79,319.81,479.7,479.7,2.47,2.47,5.1,4.78,10.42,9.73-4.4,2.96-8.59,4.9-11.66,
                    7.96-95.19,95.12-190.23,190.39-285.38,285.56-67.12,67.14-134.57,133.95-201.24,201.54-7.8,7.9-15.4,9.05-25.03,
                    9.05-109.38-.02-218.75.15-328.13.19-2.36,0-4.72-.8-7.09-1.22-.72-1.03-1.44-2.06-2.16-3.09,168.53-166.76,337.05-333.51,
                    506.96-501.63C447.22,370.9,279.37,203.05,110.71,34.41Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="cheveron" id="{id}" title="Cheveron"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m110.71,34.41c2.8-.27,4.86-.65,6.92-.65,111.3.1,222.61.34,333.91.2,10.02-.01,16.87,3.54,22.23,11.52,1.75,
                        2.61,4.19,4.77,6.44,7.02,159.89,159.91,319.79,319.81,479.7,479.7,2.47,2.47,5.1,4.78,10.42,9.73-4.4,2.96-8.59,4.9-11.66,
                        7.96-95.19,95.12-190.23,190.39-285.38,285.56-67.12,67.14-134.57,133.95-201.24,201.54-7.8,7.9-15.4,9.05-25.03,
                        9.05-109.38-.02-218.75.15-328.13.19-2.36,0-4.72-.8-7.09-1.22-.72-1.03-1.44-2.06-2.16-3.09,168.53-166.76,337.05-333.51,
                        506.96-501.63C447.22,370.9,279.37,203.05,110.71,34.41Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Banner 1",
            code: "banner1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m41.45,362.85h892.67l105.88,169.74-105.88,184.63H41.45s-3.26-360.33,0-354.37Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="banner1" id="{id}" title="Banner 1"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m41.45,362.85h892.67l105.88,169.74-105.88,184.63H41.45s-3.26-360.33,0-354.37Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Banner 2",
            code: "banner2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1040,394.27l-132.35,144.07,132.35,149.04H40v-293.11s997.24-3.73,1000,0Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="banner2" id="{id}" title="Banner 2"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1040,394.27l-132.35,144.07,132.35,149.04H40v-293.11s997.24-3.73,1000,0Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Banner 3",
            code: "banner3",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1040,662.07c-209.89,149.01-647.42-101.44-1000,0v-246.43c349.56-149.72,606.05,121.85,1000,0v246.43Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="banner3" id="{id}" title="Banner 3"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1040,662.07c-209.89,149.01-647.42-101.44-1000,0v-246.43c349.56-149.72,606.05,121.85,1000,0v246.43Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Award 1",
            code: "award1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m275.69,171.64c.26-23.01-8.72-48.32,8.82-60.97,23.18-16.71,43.56,5.66,60.46,18.84,46.13,35.96,
                    67.23,16.72,78.33-31.41,4.54-19.68,5.85-45.81,27.94-50.42,22.22-4.64,35.95,18.58,45.16,35.54,27.48,50.6,
                    52.62,56.85,82.56,2.32,9.52-17.34,20.53-40.44,43.33-38.45,25.64,2.24,24.46,28.84,28.64,48,10.53,48.27,32.08,
                    67.86,78.24,33.53,55.3-41.12,77.07-23.23,69.88,41.43-6.02,54.16,20.63,59.63,64.63,47.23,18.07-5.09,40.63-18.91,
                    57.49-.32,17.74,19.56.91,39.31-9.42,55.23-32.33,49.77-17.9,72.83,38.94,76.59,63.67,4.22,71.8,30.29,24.45,
                    71.24-46.11,39.89-27.14,61.45,17.43,80.84,18.11,7.88,44.95,12.78,47.22,34.33,3.08,29.2-28.37,32.42-47.66,
                    41.06-44.74,20.04-62.68,40.74-17.41,81.15,41.76,37.27,48.13,69.57-23.36,71.34-60.14,1.49-60.99,31.16-39.13,
                    74.66,29.46,58.61,8.48,76.17-49.76,57.67-57.44-18.25-66.88,10.7-63.22,58.1,1.51,19.54,9.43,45.1-10.22,
                    56.84-21.79,13.02-37.26-10.93-51.64-23.01-46.59-39.13-75.02-33.18-86.67,29.23-3.63,19.46-3.71,45.78-27.36,
                    50.13-23.43,4.31-34.38-19.87-43.55-36.89-34.55-64.12-61.04-42.34-88.38,5.45-8.55,14.95-19.06,34.97-39.14,
                    31.78-19.96-3.17-23.71-24.64-26.96-42.1-10.56-56.89-34.04-76.44-86.46-35.66-46.27,36-71.95,21.95-62.62-36.18,
                    10.64-66.29-14.71-75.22-71.02-56.19-47.06,15.91-71.86,4.45-44.56-50,25.55-50.97,
                    17.6-81.11-47.08-84.95-66.55-3.95-49.46-38.47-16.41-65.91,50.43-41.87,
                    42.28-66.42-15.95-86.81-17.95-6.28-43.83-13.12-41.02-38.41,2.47-22.22,26.14-30.99,44.99-36.53,63.22-18.6,
                    53.67-44.08,12.57-80.11-49.87-43.71-33.1-66.59,26.88-71.3,47-3.69,73.97-17.97,
                    42.49-70.87-10.59-17.8-31.67-40.36-10.59-61.96,16.33-16.73,39.9-3.84,57.79,2.37,49.54,17.19,75.43,10,61.34-46.47Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="award1" id="{id}" title="Award 1"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m275.69,171.64c.26-23.01-8.72-48.32,8.82-60.97,23.18-16.71,43.56,5.66,60.46,18.84,46.13,35.96,
                        67.23,16.72,78.33-31.41,4.54-19.68,5.85-45.81,27.94-50.42,22.22-4.64,35.95,18.58,45.16,35.54,27.48,50.6,
                        52.62,56.85,82.56,2.32,9.52-17.34,20.53-40.44,43.33-38.45,25.64,2.24,24.46,28.84,28.64,48,10.53,48.27,32.08,
                        67.86,78.24,33.53,55.3-41.12,77.07-23.23,69.88,41.43-6.02,54.16,20.63,59.63,64.63,47.23,18.07-5.09,40.63-18.91,
                        57.49-.32,17.74,19.56.91,39.31-9.42,55.23-32.33,49.77-17.9,72.83,38.94,76.59,63.67,4.22,71.8,30.29,24.45,
                        71.24-46.11,39.89-27.14,61.45,17.43,80.84,18.11,7.88,44.95,12.78,47.22,34.33,3.08,29.2-28.37,32.42-47.66,
                        41.06-44.74,20.04-62.68,40.74-17.41,81.15,41.76,37.27,48.13,69.57-23.36,71.34-60.14,1.49-60.99,31.16-39.13,
                        74.66,29.46,58.61,8.48,76.17-49.76,57.67-57.44-18.25-66.88,10.7-63.22,58.1,1.51,19.54,9.43,45.1-10.22,
                        56.84-21.79,13.02-37.26-10.93-51.64-23.01-46.59-39.13-75.02-33.18-86.67,29.23-3.63,19.46-3.71,45.78-27.36,
                        50.13-23.43,4.31-34.38-19.87-43.55-36.89-34.55-64.12-61.04-42.34-88.38,5.45-8.55,14.95-19.06,34.97-39.14,
                        31.78-19.96-3.17-23.71-24.64-26.96-42.1-10.56-56.89-34.04-76.44-86.46-35.66-46.27,36-71.95,21.95-62.62-36.18,
                        10.64-66.29-14.71-75.22-71.02-56.19-47.06,15.91-71.86,4.45-44.56-50,25.55-50.97,
                        17.6-81.11-47.08-84.95-66.55-3.95-49.46-38.47-16.41-65.91,50.43-41.87,
                        42.28-66.42-15.95-86.81-17.95-6.28-43.83-13.12-41.02-38.41,2.47-22.22,26.14-30.99,44.99-36.53,63.22-18.6,
                        53.67-44.08,12.57-80.11-49.87-43.71-33.1-66.59,26.88-71.3,47-3.69,73.97-17.97,
                        42.49-70.87-10.59-17.8-31.67-40.36-10.59-61.96,16.33-16.73,39.9-3.84,57.79,2.37,49.54,17.19,75.43,10,61.34-46.47Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Award 2",
            code: "award2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m254.56,894.27c-6.81,2.81-13.17,5.39-19.5,8.07-16.7,7.06-32.78,6.09-46.88-5.58-14.56-12.05-15.19-28.14-12.07-45.6,
                    1.74-9.77,12.74-20.22,5.72-27.91-4.02-4.41-17.79.48-27.17.45-35.63-.14-53.26-27.45-38.65-59.93,3.37-7.49,7.89-14.45,
                    13.04-23.74-24.75-6.72-55.56-4.5-64.13-35.88-8.15-29.87,13.41-46.89,35.16-65.68-29.02-4.37-54.16-13.83-57.16-43.73-2.87-28.62,
                    22.72-40.08,45.09-54.61-24.54-15.61-56.17-26.7-46.11-62.57,7.21-25.69,30.81-33.83,60.03-38.54-25.31-16.02-41.99-35.89-33.76-63.76,
                    8.89-30.13,36.74-29.21,63.26-30.77-5.6-9.83-11.37-18.57-15.76-27.95-7.08-15.12-9.97-30.77,1.21-45.37,11.68-15.25,27.96-19.25,
                    46.25-17.02q21.18,2.59,15.16-16.82c-5.99-19.37-7.25-39.09,8.62-53.71,16.42-15.12,36.31-13.2,55.65-3.93,4.01,1.92,8.1,3.67,15.42,
                    6.97-2.82-25.87-8.67-51.32,15.39-69.03,27.06-19.91,46.22.63,68.42,14.38,1.85-11.61,2.71-21.57,5.09-31.16,9-36.32,37.81-47.03,
                    67.92-25.59.52.37,1.04.75,1.54,1.15,8.41,6.72,17.12,19.08,25.12,18.63,8.5-.48,6.07-17.04,10.8-25.9,16.88-31.63,48.89-35.02,
                    71.13-6.65,5.76,7.35,10.43,15.57,17.22,25.85,13.04-25.47,24.78-51.54,57.64-46,29.34,4.95,36.74,30.34,44.53,56.33,19.43-15.76,
                    33.14-42.68,63.12-30.15,27.63,11.55,29.24,37.33,29.91,64.4,23.53-12.8,43.01-36.03,71.96-15.63,25.33,17.86,17.86,42.37,14.95,
                    69.09,24.92-8.23,50.3-20.83,71.8,1.7,20.7,21.69,9.89,46.16.71,70.86,27.13-1,55.9-10.55,70.91,20.51,12.22,25.29-5.69,43.41-17.54,
                    63.1,5.41,4.97,12.11,2.54,18.03,2.5,22.21-.17,38.3,9.64,47.56,29.39,7.07,15.06-.79,36.52-17.17,51.32q-16.29,14.71,3.67,21.22c19.65,
                    6.42,34.49,18.37,37.27,39.62,2.7,20.67-11.25,33.75-27.14,42.64-16.05,8.97-15.45,13.13-.03,22.33,16.57,9.9,31.57,23.2,28.88,
                    45.91-2.88,24.38-22.08,33.43-41.64,40.06-12.8,4.34-17.28,6.71-4.75,17.33,16.01,13.57,26.87,30.74,18.58,52.31-8.48,22.06-28.36,
                    27.68-50.01,28.55-3.13.13-6.25.46-11.17.84,8.98,23.6,32.17,44.41,13.81,71.01-17.23,24.95-42.29,21.93-69.86,13.94,8.79,26.36,
                    21.35,52.57-.11,74.68-21.92,22.58-47.24,9.97-70.63-.35-4.96,7.4.03,13.21.66,19.23,2.16,20.5-1.95,38.95-20.52,50.19-18.23,
                    11.04-35.61,4.43-51.91-6.33-4.15-2.74-7.36-9.45-12.42-7.67-7.03,2.48-3.03,10.02-3.63,15.32-2.35,20.56-7.56,39.97-29.09,
                    47.99-21.23,7.92-38.63-.82-53-16.83-4.21-4.69-8.31-9.48-14.54-16.59-8.78,27.53-14.86,55.35-46.66,56.55-27.3,
                    1.02-42.26-17.82-51.71-45.87-13.87,26.78-28.71,50.59-59.27,46.09-30.13-4.43-34.78-33.62-44.83-58.3-7.96,8.46-14.21,16.47-21.78,
                    22.92-26.7,22.75-59.1,12.05-67.88-22.03-.32-1.23-.42-2.52-.68-3.78-5.14-24.58-5.19-24.67-26.21-13.01-28.44,15.77-70.01,
                    5.33-66-39.81.83-9.36.12-18.86.12-29.61Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="award2" id="{id}" title="Award 2"
                class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                    <path d="m254.56,894.27c-6.81,2.81-13.17,5.39-19.5,8.07-16.7,7.06-32.78,6.09-46.88-5.58-14.56-12.05-15.19-28.14-12.07-45.6,
                    1.74-9.77,12.74-20.22,5.72-27.91-4.02-4.41-17.79.48-27.17.45-35.63-.14-53.26-27.45-38.65-59.93,3.37-7.49,7.89-14.45,
                    13.04-23.74-24.75-6.72-55.56-4.5-64.13-35.88-8.15-29.87,13.41-46.89,35.16-65.68-29.02-4.37-54.16-13.83-57.16-43.73-2.87-28.62,
                    22.72-40.08,45.09-54.61-24.54-15.61-56.17-26.7-46.11-62.57,7.21-25.69,30.81-33.83,60.03-38.54-25.31-16.02-41.99-35.89-33.76-63.76,
                    8.89-30.13,36.74-29.21,63.26-30.77-5.6-9.83-11.37-18.57-15.76-27.95-7.08-15.12-9.97-30.77,1.21-45.37,11.68-15.25,27.96-19.25,
                    46.25-17.02q21.18,2.59,15.16-16.82c-5.99-19.37-7.25-39.09,8.62-53.71,16.42-15.12,36.31-13.2,55.65-3.93,4.01,1.92,8.1,3.67,15.42,
                    6.97-2.82-25.87-8.67-51.32,15.39-69.03,27.06-19.91,46.22.63,68.42,14.38,1.85-11.61,2.71-21.57,5.09-31.16,9-36.32,37.81-47.03,
                    67.92-25.59.52.37,1.04.75,1.54,1.15,8.41,6.72,17.12,19.08,25.12,18.63,8.5-.48,6.07-17.04,10.8-25.9,16.88-31.63,48.89-35.02,
                    71.13-6.65,5.76,7.35,10.43,15.57,17.22,25.85,13.04-25.47,24.78-51.54,57.64-46,29.34,4.95,36.74,30.34,44.53,56.33,19.43-15.76,
                    33.14-42.68,63.12-30.15,27.63,11.55,29.24,37.33,29.91,64.4,23.53-12.8,43.01-36.03,71.96-15.63,25.33,17.86,17.86,42.37,14.95,
                    69.09,24.92-8.23,50.3-20.83,71.8,1.7,20.7,21.69,9.89,46.16.71,70.86,27.13-1,55.9-10.55,70.91,20.51,12.22,25.29-5.69,43.41-17.54,
                    63.1,5.41,4.97,12.11,2.54,18.03,2.5,22.21-.17,38.3,9.64,47.56,29.39,7.07,15.06-.79,36.52-17.17,51.32q-16.29,14.71,3.67,21.22c19.65,
                    6.42,34.49,18.37,37.27,39.62,2.7,20.67-11.25,33.75-27.14,42.64-16.05,8.97-15.45,13.13-.03,22.33,16.57,9.9,31.57,23.2,28.88,
                    45.91-2.88,24.38-22.08,33.43-41.64,40.06-12.8,4.34-17.28,6.71-4.75,17.33,16.01,13.57,26.87,30.74,18.58,52.31-8.48,22.06-28.36,
                    27.68-50.01,28.55-3.13.13-6.25.46-11.17.84,8.98,23.6,32.17,44.41,13.81,71.01-17.23,24.95-42.29,21.93-69.86,13.94,8.79,26.36,
                    21.35,52.57-.11,74.68-21.92,22.58-47.24,9.97-70.63-.35-4.96,7.4.03,13.21.66,19.23,2.16,20.5-1.95,38.95-20.52,50.19-18.23,
                    11.04-35.61,4.43-51.91-6.33-4.15-2.74-7.36-9.45-12.42-7.67-7.03,2.48-3.03,10.02-3.63,15.32-2.35,20.56-7.56,39.97-29.09,
                    47.99-21.23,7.92-38.63-.82-53-16.83-4.21-4.69-8.31-9.48-14.54-16.59-8.78,27.53-14.86,55.35-46.66,56.55-27.3,
                    1.02-42.26-17.82-51.71-45.87-13.87,26.78-28.71,50.59-59.27,46.09-30.13-4.43-34.78-33.62-44.83-58.3-7.96,8.46-14.21,16.47-21.78,
                    22.92-26.7,22.75-59.1,12.05-67.88-22.03-.32-1.23-.42-2.52-.68-3.78-5.14-24.58-5.19-24.67-26.21-13.01-28.44,15.77-70.01,
                    5.33-66-39.81.83-9.36.12-18.86.12-29.61Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Award 3",
            code: "award3",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <polygon points="540 43.65 649.92 101.34 774.07 101.34 844.59 203.51 954.51 261.2 969.48 384.44 1040 486.61 995.98 602.68 1010.94 725.92 918.02 808.25 874 924.32 753.46 954.03 660.54 1036.35 540 1006.64 419.46 1036.35 326.54 954.03 206 924.32 161.98 808.25 69.06 725.92 84.02 602.68 40 486.61 110.52 384.44 125.49 261.2 235.41 203.51 305.93 101.34 430.08 101.34 540 43.65" />
                </svg>
            </div>,
            html: `<div x-objectcode="award3" id="{id}" title="Award 3"
                class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                    <polygon points="540 43.65 649.92 101.34 774.07 101.34 844.59 203.51 954.51 261.2 969.48 384.44 1040 486.61 995.98 602.68 1010.94 725.92 918.02 808.25 874 924.32 753.46 954.03 660.54 1036.35 540 1006.64 419.46 1036.35 326.54 954.03 206 924.32 161.98 808.25 69.06 725.92 84.02 602.68 40 486.61 110.52 384.44 125.49 261.2 235.41 203.51 305.93 101.34 430.08 101.34 540 43.65" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Heart1",
            code: "heart1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "43.75px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1039.96,379.06c.47-73.38-26.98-137.04-77.51-179.75-94.18-79.6-230.55-76.08-323.71,7.89-31.65,
                    28.53-98.77,100.86-98.77,100.86,0,0-42.01-45.32-58.51-63.4-61.95-67.9-134.92-110.59-230.98-101.24-69.74,
                    6.79-125.95,38.09-165.73,95.05-58.65,83.97-54.72,173.92-16.66,264.12,31.74,75.23,87.06,133.89,144.22,
                    190.01,99.52,97.7,327.68,280.05,327.68,280.05,0,0,143.33-113.2,206.83-167.86,75.1-64.64,148.96-130.75,
                    210.31-209.25,49.71-63.61,84.22-133.22,82.82-216.48Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="heart1" id="{id}" title="Heart1"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1039.96,379.06c.47-73.38-26.98-137.04-77.51-179.75-94.18-79.6-230.55-76.08-323.71,7.89-31.65,
                        28.53-98.77,100.86-98.77,100.86,0,0-42.01-45.32-58.51-63.4-61.95-67.9-134.92-110.59-230.98-101.24-69.74,
                        6.79-125.95,38.09-165.73,95.05-58.65,83.97-54.72,173.92-16.66,264.12,31.74,75.23,87.06,133.89,144.22,
                        190.01,99.52,97.7,327.68,280.05,327.68,280.05,0,0,143.33-113.2,206.83-167.86,75.1-64.64,148.96-130.75,
                        210.31-209.25,49.71-63.61,84.22-133.22,82.82-216.48Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Heart2",
            code: "heart2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "43.75px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m539.79,224.77c22.72-50.82,116.79-154.67,248.3-147.46,89.35,1.32,232.54,60.13,251.48,242.16,15.78,
                    307.23-398.77,472.42-499.78,684.95C344.08,688.77,37.91,696.14,40.01,319.47c25.25-237.79,330.72-358.67,499.78-94.69Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="heart2" id="{id}" title="Heart2"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m539.79,224.77c22.72-50.82,116.79-154.67,248.3-147.46,89.35,1.32,232.54,60.13,251.48,242.16,15.78,
                        307.23-398.77,472.42-499.78,684.95C344.08,688.77,37.91,696.14,40.01,319.47c25.25-237.79,330.72-358.67,499.78-94.69Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Raindrop",
            code: "raindrop",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m893.43,696.99c-5.86,142.13-74.82,243.75-202.43,304.82-139.29,66.66-271.05,44.5-384.1-54.4-105.13-91.96-147.68-211.74-103.14-349.6,
                    54.35-168.21,151.19-314.68,248.84-460.05,87.64-130.47,88.96-130.49,175.93.73,87.5,132.03,172.02,265.88,233.43,412.7,19.46,46.51,34.21,93.98,31.47,145.8Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="raindrop" id="{id}" title="Raindrop"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m893.43,696.99c-5.86,142.13-74.82,243.75-202.43,304.82-139.29,66.66-271.05,44.5-384.1-54.4-105.13-91.96-147.68-211.74-103.14-349.6,
                        54.35-168.21,151.19-314.68,248.84-460.05,87.64-130.47,88.96-130.49,175.93.73,87.5,132.03,172.02,265.88,233.43,412.7,19.46,46.51,34.21,93.98,31.47,145.8Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Flower 1",
            code: "flower1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m538.69,953.32c-18.42,30.75-39.87,56.31-72.21,70.35-57.19,24.83-112.44,22.75-162.55-16.03-51.17-39.6-73.24-92.12-59.88-156.88,
                    3.07-14.87,1.62-18.68-15.16-15.81-86.33,14.76-168.42-36.89-184.48-116.72-13.72-68.21,6.68-124.6,64.11-166.11,18.12-13.1,
                    17.6-12.77-.13-24.88-54.03-36.91-74.94-89.79-65.88-152.7,9.02-62.65,46.07-105.28,106.38-126.04,25.17-8.66,51.09-9.92,77.37-3.64,
                    19.59,4.68,20.68,3.11,17.13-17.55-14.72-85.6,35.59-163.08,118.83-183.02,63.74-15.27,129.19,10.81,168.12,68.64,8.78,13.04,11.4,
                    10.35,18.71-.5,41.94-62.24,112.44-86.27,184.78-63.81,59.7,18.54,105.58,84.11,105.98,152.03.04,7.57-1.46,15.25-3.07,22.7-5.27,
                    24.35-5.45,24.1,18.04,20.23,45-7.41,86.19,1.27,122.73,29.49,86.22,66.59,81.84,194.75-9.59,256.33-13.97,9.41-12.3,11.84-.08,20.98,
                    54.41,40.69,82.55,94.38,68.1,162.94-13.2,62.65-52.99,103.78-115.22,120.58-23.5,6.35-48.14,4.43-72.19-.12-12.77-2.42-18.89-.89-15.43,
                    15.33,16.14,75.47-17.98,144.88-85.4,176.29-65.85,30.68-144.3,11.08-190.94-47.8-5.85-7.38-11.27-15.11-18.08-24.28Z" /></svg>
            </div>,
            html: `<div x-objectcode="flower1" id="{id}" title="Flower 1" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m538.69,953.32c-18.42,30.75-39.87,56.31-72.21,70.35-57.19,24.83-112.44,22.75-162.55-16.03-51.17-39.6-73.24-92.12-59.88-156.88,
                        3.07-14.87,1.62-18.68-15.16-15.81-86.33,14.76-168.42-36.89-184.48-116.72-13.72-68.21,6.68-124.6,64.11-166.11,18.12-13.1,
                        17.6-12.77-.13-24.88-54.03-36.91-74.94-89.79-65.88-152.7,9.02-62.65,46.07-105.28,106.38-126.04,25.17-8.66,51.09-9.92,77.37-3.64,
                        19.59,4.68,20.68,3.11,17.13-17.55-14.72-85.6,35.59-163.08,118.83-183.02,63.74-15.27,129.19,10.81,168.12,68.64,8.78,13.04,11.4,
                        10.35,18.71-.5,41.94-62.24,112.44-86.27,184.78-63.81,59.7,18.54,105.58,84.11,105.98,152.03.04,7.57-1.46,15.25-3.07,22.7-5.27,
                        24.35-5.45,24.1,18.04,20.23,45-7.41,86.19,1.27,122.73,29.49,86.22,66.59,81.84,194.75-9.59,256.33-13.97,9.41-12.3,11.84-.08,20.98,
                        54.41,40.69,82.55,94.38,68.1,162.94-13.2,62.65-52.99,103.78-115.22,120.58-23.5,6.35-48.14,4.43-72.19-.12-12.77-2.42-18.89-.89-15.43,
                        15.33,16.14,75.47-17.98,144.88-85.4,176.29-65.85,30.68-144.3,11.08-190.94-47.8-5.85-7.38-11.27-15.11-18.08-24.28Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Flower 1 with Center",
            code: "flower1_with_center",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m967.84,550.38c-12.22-9.14-13.89-11.58.08-20.98,91.44-61.57,95.81-189.74,9.59-256.33-36.54-28.22-77.73-36.9-122.73-29.49-23.49,
                    3.87-23.31,4.11-18.04-20.23,1.61-7.45,3.11-15.13,3.07-22.7-.4-67.92-46.28-133.49-105.98-152.03-72.34-22.46-142.84,1.56-184.78,63.81-7.31,
                    10.85-9.93,13.54-18.71.5-38.93-57.83-104.38-83.9-168.12-68.64-83.24,19.94-133.55,97.42-118.83,183.02,3.55,20.66,2.46,22.23-17.13,
                    17.55-26.28-6.28-52.2-5.03-77.37,3.64-60.31,20.76-97.36,63.38-106.38,126.04-9.06,62.91,11.86,115.78,65.88,152.7,17.72,12.11,18.25,
                    11.78.13,24.88-57.43,41.51-77.83,97.9-64.11,166.11,16.06,79.83,98.15,131.49,184.48,116.72,16.78-2.87,18.23.95,15.16,15.81-13.36,64.76,
                    8.71,117.29,59.88,156.88,50.12,38.78,105.36,40.86,162.55,16.03,32.34-14.04,53.79-39.6,72.21-70.35,6.81,9.17,12.23,16.9,18.08,24.28,
                    46.65,58.88,125.09,78.48,190.94,47.8,67.42-31.41,101.54-100.83,85.4-176.29-3.47-16.22,2.65-17.75,15.43-15.33,24.05,4.55,48.69,6.46,
                    72.19.12,62.23-16.8,102.02-57.93,115.22-120.58,14.44-68.56-13.7-122.24-68.1-162.94Zm-427.84,183.54c-107.1,
                    0-193.92-86.82-193.92-193.92s86.82-193.92,193.92-193.92,193.92,86.82,193.92,193.92-86.82,193.92-193.92,193.92Z" /></svg>
            </div>,
            html: `<div x-objectcode="flower2" id="{id}" title="Flower 2" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none"pointer-events="none">
                        <path d="m967.84,550.38c-12.22-9.14-13.89-11.58.08-20.98,91.44-61.57,95.81-189.74,9.59-256.33-36.54-28.22-77.73-36.9-122.73-29.49-23.49,
                        3.87-23.31,4.11-18.04-20.23,1.61-7.45,3.11-15.13,3.07-22.7-.4-67.92-46.28-133.49-105.98-152.03-72.34-22.46-142.84,1.56-184.78,63.81-7.31,
                        10.85-9.93,13.54-18.71.5-38.93-57.83-104.38-83.9-168.12-68.64-83.24,19.94-133.55,97.42-118.83,183.02,3.55,20.66,2.46,22.23-17.13,
                        17.55-26.28-6.28-52.2-5.03-77.37,3.64-60.31,20.76-97.36,63.38-106.38,126.04-9.06,62.91,11.86,115.78,65.88,152.7,17.72,12.11,18.25,
                        11.78.13,24.88-57.43,41.51-77.83,97.9-64.11,166.11,16.06,79.83,98.15,131.49,184.48,116.72,16.78-2.87,18.23.95,15.16,15.81-13.36,64.76,
                        8.71,117.29,59.88,156.88,50.12,38.78,105.36,40.86,162.55,16.03,32.34-14.04,53.79-39.6,72.21-70.35,6.81,9.17,12.23,16.9,18.08,24.28,
                        46.65,58.88,125.09,78.48,190.94,47.8,67.42-31.41,101.54-100.83,85.4-176.29-3.47-16.22,2.65-17.75,15.43-15.33,24.05,4.55,48.69,6.46,
                        72.19.12,62.23-16.8,102.02-57.93,115.22-120.58,14.44-68.56-13.7-122.24-68.1-162.94Zm-427.84,183.54c-107.1,
                        0-193.92-86.82-193.92-193.92s86.82-193.92,193.92-193.92,193.92,86.82,193.92,193.92-86.82,193.92-193.92,193.92Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Flower 2",
            code: "flower2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m257.19,662.72c-55.81,5.1-105.93-8.56-149.05-44.93-32.41-27.34-53.48-61.91-63-103.14-18.29-79.23,13.22-159.98,79.29-202.31,
                    83.81-53.69,164.75-43.16,241.14,17.61-18.86-42.11-23.76-86.09-11.7-131.38,12.09-45.39,37.54-81.89,76.02-108.62,66.39-46.11,147.59-44.18,209.05-7.24,
                    42.27,25.41,70.89,62.07,85.32,109.15,14.38,46.92,10.44,93.03-9.28,138.31,37.73-33.41,81.36-51.33,131.51-51.55,50.34-.22,95.12,16.27,131.34,51.22,
                    58.44,56.4,75.31,124.5,52.16,202.41-36.95,94.05-107.38,135.09-207.1,130.98,43.22,25.28,74.1,60.87,89.86,108.56,15.76,47.69,14.22,95.36-7.85,
                    140.34-37.87,77.17-101.16,114.26-187.23,112.14-95.74-10.14-152.83-64.38-178.15-156.13-10.7,51.23-37.01,92.32-79.61,122.14-42.91,30.03-91.04,
                    40.21-142.38,31.61-78.89-13.22-141.86-74.24-156.78-154.17-13.28-71.16,13.22-156.61,96.44-205.01Z" /></svg>
            </div>,
            html: `<div x-objectcode="flower2" id="{id}" title="Flower 2" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m257.19,662.72c-55.81,5.1-105.93-8.56-149.05-44.93-32.41-27.34-53.48-61.91-63-103.14-18.29-79.23,13.22-159.98,79.29-202.31,
                        83.81-53.69,164.75-43.16,241.14,17.61-18.86-42.11-23.76-86.09-11.7-131.38,12.09-45.39,37.54-81.89,76.02-108.62,66.39-46.11,147.59-44.18,209.05-7.24,
                        42.27,25.41,70.89,62.07,85.32,109.15,14.38,46.92,10.44,93.03-9.28,138.31,37.73-33.41,81.36-51.33,131.51-51.55,50.34-.22,95.12,16.27,131.34,51.22,
                        58.44,56.4,75.31,124.5,52.16,202.41-36.95,94.05-107.38,135.09-207.1,130.98,43.22,25.28,74.1,60.87,89.86,108.56,15.76,47.69,14.22,95.36-7.85,
                        140.34-37.87,77.17-101.16,114.26-187.23,112.14-95.74-10.14-152.83-64.38-178.15-156.13-10.7,51.23-37.01,92.32-79.61,122.14-42.91,30.03-91.04,
                        40.21-142.38,31.61-78.89-13.22-141.86-74.24-156.78-154.17-13.28-71.16,13.22-156.61,96.44-205.01Z" /></svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Flower 2 with Center",
            code: "flower2_with_center",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m257.19,662.72c-55.81,5.1-105.93-8.56-149.05-44.93-32.41-27.34-53.48-61.91-63-103.14-18.29-79.23,13.22-159.98,79.29-202.31,83.81-53.69,
                    164.75-43.16,241.14,17.61-18.86-42.11-23.76-86.09-11.7-131.38,12.09-45.39,37.54-81.89,76.02-108.62,66.39-46.11,147.59-44.18,209.05-7.24,42.27,25.41,
                    70.89,62.07,85.32,109.15,14.38,46.92,10.44,93.03-9.28,138.31,37.73-33.41,81.36-51.33,131.51-51.55,50.34-.22,95.12,16.27,131.34,51.22,58.44,56.4,
                    75.31,124.5,52.16,202.41-36.95,94.05-107.38,135.09-207.1,130.98,43.22,25.28,74.1,60.87,89.86,108.56,15.76,47.69,14.22,95.36-7.85,140.34-37.87,
                    77.17-101.16,114.26-187.23,112.14-95.74-10.14-152.83-64.38-178.15-156.13-10.7,51.23-37.01,92.32-79.61,122.14-42.91,30.03-91.04,40.21-142.38,
                    31.61-78.89-13.22-141.86-74.24-156.78-154.17-13.28-71.16,13.22-156.61,96.44-205.01Zm170.52-91.67c-.74,61.04,49.86,112.33,111.81,112.37,62.57.04,
                    112.88-49.73,113.1-112.27.22-62.96-51.51-112.71-112.55-112.8-61.52-.09-113.55,51.2-112.36,112.7Z" /></svg>
            </div>,
            html: `<div x-objectcode="flower2_with_center" id="{id}" title="Flower 2 with Center" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none"pointer-events="none">
                    <path d="m257.19,662.72c-55.81,5.1-105.93-8.56-149.05-44.93-32.41-27.34-53.48-61.91-63-103.14-18.29-79.23,13.22-159.98,79.29-202.31,83.81-53.69,
                    164.75-43.16,241.14,17.61-18.86-42.11-23.76-86.09-11.7-131.38,12.09-45.39,37.54-81.89,76.02-108.62,66.39-46.11,147.59-44.18,209.05-7.24,42.27,25.41,
                    70.89,62.07,85.32,109.15,14.38,46.92,10.44,93.03-9.28,138.31,37.73-33.41,81.36-51.33,131.51-51.55,50.34-.22,95.12,16.27,131.34,51.22,58.44,56.4,
                    75.31,124.5,52.16,202.41-36.95,94.05-107.38,135.09-207.1,130.98,43.22,25.28,74.1,60.87,89.86,108.56,15.76,47.69,14.22,95.36-7.85,140.34-37.87,
                    77.17-101.16,114.26-187.23,112.14-95.74-10.14-152.83-64.38-178.15-156.13-10.7,51.23-37.01,92.32-79.61,122.14-42.91,30.03-91.04,40.21-142.38,
                    31.61-78.89-13.22-141.86-74.24-156.78-154.17-13.28-71.16,13.22-156.61,96.44-205.01Zm170.52-91.67c-.74,61.04,49.86,112.33,111.81,112.37,62.57.04,
                    112.88-49.73,113.1-112.27.22-62.96-51.51-112.71-112.55-112.8-61.52-.09-113.55,51.2-112.36,112.7Z" /></svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Paw",
            code: "paw",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m692.48,984.99c-33.54-1.33-65.85-7.31-97.64-18.01-27.47-9.24-56.34-11.02-84.9-5.55-20.88,4-41.09,11.46-61.97,15.48-35.54,6.85-71.07,10.87-107.61,4.03-29.89-5.59-57.29-15.63-82.91-31.54-30.33-18.85-51.8-45.38-62.84-78.81-6.68-20.23-9.13-41.47-6.33-63.87,6.97-55.87,37.71-96.9,76.99-133.49,17.3-16.11,35.38-31.41,52.21-47.99,24.18-23.82,42.54-52.15,60.15-80.99,27.93-45.74,68.42-74.55,120.58-85.9,57.8-12.58,110.73.42,158.25,34.81,25.28,18.29,42.75,43.37,56.6,71.18,19.93,40.03,48.99,71.91,85.35,98.06,41.81,30.06,73.63,68.27,87.73,119.1,13.22,47.61,5.92,91.57-24.14,131.15-21.37,28.13-50.36,45.23-83.02,57.39-28.06,10.45-57.15,13.4-86.5,14.95Z" />
                    <path className="cls-1" d="m363.83,463.7c-28.7-.19-53.53-11.13-75.17-29.13-32.45-27-47.46-61.42-56.09-99.95-10.67-47.62,4.87-89.25,29.44-129.5,16.58-27.16,38.55-50.7,63.91-71.49,20.59-16.89,43.63-30.46,70.83-36.76,31.27-7.25,61.78,7.2,76.93,34.89,12.75,23.29,18.56,48.09,22.86,73.6,5.96,35.39,6.82,70.9,5.94,106.49-.96,38.57-14.08,73.52-39.8,104.52-20.11,24.23-46.65,39.27-78.81,46.35-6,1.32-12.52.68-20.03.99Z" />
                    <path className="cls-1" d="m573.28,286.34c4.52-35.38,7.75-70.95,14.01-106.07,3.99-22.37,12.84-44.05,28.22-62.58,19.39-23.35,47.36-27.42,81.14-15.93,44.03,14.98,75,45.01,102.45,78.07,17.49,21.07,31.49,44.05,40.6,69.41,10.21,28.41,12.09,56.73,6.82,86.45-5.3,29.88-17.12,56.76-37.02,80.28-28.16,33.28-65.37,52.34-112.83,46.98-28.45-3.21-53.04-16.59-70.93-36.91-12.69-14.42-23.61-30.7-31.95-47.53-7.51-15.16-11.67-31.98-15-48.46-2.86-14.16-2.46-28.87-3.5-43.33-.67-.12-1.34-.25-2.02-.37Z" />
                    <path className="cls-1" d="m1044.76,514.95c-.6,52.36-21.8,96.29-64.78,130.82-25.84,20.76-55.9,34.44-91.68,33.36-30.83-.94-55.73-13.58-73.67-35.85-24.63-30.59-29.19-65.26-23.84-102.24,5.36-37.02,25.22-68.22,44.7-99.69,13.77-22.24,30.37-42.67,51.18-60.1,18.9-15.83,40.06-25.98,66.76-22.97,16.17,1.82,29.07,9.83,39.3,20.31,30.57,31.31,44.16,69.18,48.98,110.1,1.03,8.75,2.03,17.51,3.04,26.26Z" />
                    <path className="cls-1" d="m289.71,562.78c1.54,29.86-5.16,55.5-23.11,78.06-29.37,36.91-79.47,48.09-124.48,29.81-48.77-19.81-79.56-54.71-97.3-98.82-9.49-23.6-11.18-48.86-8.3-74.65,3.06-27.44,9.32-53.69,21.91-78.57,12.76-25.2,28.27-49.13,59.55-58.35,21.47-6.33,42.08-1.43,59.99,10.87,49.67,34.12,77.24,82.34,100.18,132.88,8.67,19.09,14.5,39.67,11.56,58.78Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="paw" id="{id}" title="Paw" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path class="cls-1" d="m692.48,984.99c-33.54-1.33-65.85-7.31-97.64-18.01-27.47-9.24-56.34-11.02-84.9-5.55-20.88,4-41.09,11.46-61.97,15.48-35.54,6.85-71.07,10.87-107.61,4.03-29.89-5.59-57.29-15.63-82.91-31.54-30.33-18.85-51.8-45.38-62.84-78.81-6.68-20.23-9.13-41.47-6.33-63.87,6.97-55.87,37.71-96.9,76.99-133.49,17.3-16.11,35.38-31.41,52.21-47.99,24.18-23.82,42.54-52.15,60.15-80.99,27.93-45.74,68.42-74.55,120.58-85.9,57.8-12.58,110.73.42,158.25,34.81,25.28,18.29,42.75,43.37,56.6,71.18,19.93,40.03,48.99,71.91,85.35,98.06,41.81,30.06,73.63,68.27,87.73,119.1,13.22,47.61,5.92,91.57-24.14,131.15-21.37,28.13-50.36,45.23-83.02,57.39-28.06,10.45-57.15,13.4-86.5,14.95Z" />
                        <path class="cls-1" d="m363.83,463.7c-28.7-.19-53.53-11.13-75.17-29.13-32.45-27-47.46-61.42-56.09-99.95-10.67-47.62,4.87-89.25,29.44-129.5,16.58-27.16,38.55-50.7,63.91-71.49,20.59-16.89,43.63-30.46,70.83-36.76,31.27-7.25,61.78,7.2,76.93,34.89,12.75,23.29,18.56,48.09,22.86,73.6,5.96,35.39,6.82,70.9,5.94,106.49-.96,38.57-14.08,73.52-39.8,104.52-20.11,24.23-46.65,39.27-78.81,46.35-6,1.32-12.52.68-20.03.99Z" />
                        <path class="cls-1" d="m573.28,286.34c4.52-35.38,7.75-70.95,14.01-106.07,3.99-22.37,12.84-44.05,28.22-62.58,19.39-23.35,47.36-27.42,81.14-15.93,44.03,14.98,75,45.01,102.45,78.07,17.49,21.07,31.49,44.05,40.6,69.41,10.21,28.41,12.09,56.73,6.82,86.45-5.3,29.88-17.12,56.76-37.02,80.28-28.16,33.28-65.37,52.34-112.83,46.98-28.45-3.21-53.04-16.59-70.93-36.91-12.69-14.42-23.61-30.7-31.95-47.53-7.51-15.16-11.67-31.98-15-48.46-2.86-14.16-2.46-28.87-3.5-43.33-.67-.12-1.34-.25-2.02-.37Z" />
                        <path class="cls-1" d="m1044.76,514.95c-.6,52.36-21.8,96.29-64.78,130.82-25.84,20.76-55.9,34.44-91.68,33.36-30.83-.94-55.73-13.58-73.67-35.85-24.63-30.59-29.19-65.26-23.84-102.24,5.36-37.02,25.22-68.22,44.7-99.69,13.77-22.24,30.37-42.67,51.18-60.1,18.9-15.83,40.06-25.98,66.76-22.97,16.17,1.82,29.07,9.83,39.3,20.31,30.57,31.31,44.16,69.18,48.98,110.1,1.03,8.75,2.03,17.51,3.04,26.26Z" />
                        <path class="cls-1" d="m289.71,562.78c1.54,29.86-5.16,55.5-23.11,78.06-29.37,36.91-79.47,48.09-124.48,29.81-48.77-19.81-79.56-54.71-97.3-98.82-9.49-23.6-11.18-48.86-8.3-74.65,3.06-27.44,9.32-53.69,21.91-78.57,12.76-25.2,28.27-49.13,59.55-58.35,21.47-6.33,42.08-1.43,59.99,10.87,49.67,34.12,77.24,82.34,100.18,132.88,8.67,19.09,14.5,39.67,11.56,58.78Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Speech Bubble 1",
            code: "speech_bubble_1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m233.75,1023.3l444.38-211.08c170.2-20.22,360.41-177.75,361.85-350.75,1.59-190.45-171.4-395.18-465.02-404.71C173.44,
                    52,31.5,307.13,40.39,453.42c2.91,217.54,240.97,354.03,401.27,371.49l-207.91,198.39Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="speech_bubble_1" id="{id}" title="Speech Bubble 1" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path className="cls-1" d="m233.75,1023.3l444.38-211.08c170.2-20.22,360.41-177.75,361.85-350.75,1.59-190.45-171.4-395.18-465.02-404.71C173.44,
                        52,31.5,307.13,40.39,453.42c2.91,217.54,240.97,354.03,401.27,371.49l-207.91,198.39Z" />
                    </svg>
                </div> `,
            defaultCss: ""
        },
        {
            name: "Speech Bubble 2",
            code: "speech_bubble_2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m993.15,336.83H220.25c-25.87,0-46.85,20.97-46.85,46.85v72.44l-133.4-23.42,133.4,120.36v143.28c0,
                    25.87,20.97,46.85,46.85,46.85h772.91c25.87,0,46.85-20.97,46.85-46.85v-312.65c0-25.87-20.97-46.85-46.85-46.85Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="speech_bubble_2" id="{id}" title="Speech Bubble 2" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path className="cls-1" d="m993.15,336.83H220.25c-25.87,0-46.85,20.97-46.85,46.85v72.44l-133.4-23.42,133.4,120.36v143.28c0,
                        25.87,20.97,46.85,46.85,46.85h772.91c25.87,0,46.85-20.97,46.85-46.85v-312.65c0-25.87-20.97-46.85-46.85-46.85Z" />
                    </svg>
                </div> `,
            defaultCss: ""
        },
        {
            name: "Speech Bubble 3",
            code: "speech_bubble_3",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m558.32,170.15c-266.02,0-481.68,147.72-481.68,329.94,0,82.11,43.8,157.2,116.25,214.94-8.01,
                    88.31-31.12,136.94-152.9,194.82,147.78-27.41,213.43-75,296.95-116.67,66.28,23.54,141.55,36.85,221.37,36.85,266.02,0,
                    481.68-147.72,481.68-329.94s-215.66-329.94-481.68-329.94Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="speech_bubble_3" id="{id}" title="Speech Bubble 3" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path className="cls-1" d="m558.32,170.15c-266.02,0-481.68,147.72-481.68,329.94,0,82.11,43.8,157.2,116.25,214.94-8.01,
                        88.31-31.12,136.94-152.9,194.82,147.78-27.41,213.43-75,296.95-116.67,66.28,23.54,141.55,36.85,221.37,36.85,266.02,0,
                        481.68-147.72,481.68-329.94s-215.66-329.94-481.68-329.94Z" />
                    </svg>
                </div> `,
            defaultCss: ""
        },
        {
            name: "Thought Bubble 1",
            code: "thought_bubble_1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m869.61,300.22c-7.66,0-15.2.51-22.59,1.5-17.59-63.6-75.87-110.3-145.07-110.3-34.9,0-67.02,11.88-92.54,
                    31.81-25.33-62.33-86.48-106.28-157.91-106.28-92.61,0-167.96,73.87-170.33,165.91-12-2.68-24.48-4.1-37.29-4.1-94.11,0-170.39,
                    76.29-170.39,170.39s70.3,164.24,159.17,170.02c-.15,2.97-.23,5.96-.23,8.97,0,94.11,76.29,170.39,170.39,170.39,49.37,0,93.83-21.01,
                    124.95-54.56,30.78,44.23,81.98,73.18,139.94,73.18,94.11,0,170.39-76.29,170.39-170.39,0-2.91-.07-5.81-.22-8.69,10.28,1.94,20.88,
                    2.96,31.72,2.96,94.11,0,170.39-76.29,170.39-170.39s-76.29-170.39-170.39-170.39Z" />
                    <circle className="cls-1" cx="206.65" cy="822.86" r="55.84" />
                    <circle className="cls-1" cx="120.36" cy="892.2" r="33.44" transform="translate(-749.99 661.97) rotate(-67.5)" />
                    <circle className="cls-1" cx="63.04" cy="940.02" r="23.04" transform="translate(-149.76 22.24) rotate(-9.22)" />
                </svg>
            </div>,
            html: `<div x-objectcode="thought_bubble_1" id="{id}" title="Thought Bubble 1" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path className="cls-1" d="m869.61,300.22c-7.66,0-15.2.51-22.59,1.5-17.59-63.6-75.87-110.3-145.07-110.3-34.9,0-67.02,11.88-92.54,
                        31.81-25.33-62.33-86.48-106.28-157.91-106.28-92.61,0-167.96,73.87-170.33,165.91-12-2.68-24.48-4.1-37.29-4.1-94.11,0-170.39,
                        76.29-170.39,170.39s70.3,164.24,159.17,170.02c-.15,2.97-.23,5.96-.23,8.97,0,94.11,76.29,170.39,170.39,170.39,49.37,0,93.83-21.01,
                        124.95-54.56,30.78,44.23,81.98,73.18,139.94,73.18,94.11,0,170.39-76.29,170.39-170.39,0-2.91-.07-5.81-.22-8.69,10.28,1.94,20.88,
                        2.96,31.72,2.96,94.11,0,170.39-76.29,170.39-170.39s-76.29-170.39-170.39-170.39Z" />
                        <circle className="cls-1" cx="206.65" cy="822.86" r="55.84" />
                        <circle className="cls-1" cx="120.36" cy="892.2" r="33.44" transform="translate(-749.99 661.97) rotate(-67.5)" />
                        <circle className="cls-1" cx="63.04" cy="940.02" r="23.04" transform="translate(-149.76 22.24) rotate(-9.22)" />
                    </svg>
                </div> `,
            defaultCss: ""
        },
        {
            name: "Thought Bubble 2",
            code: "thought_bubble_2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path className="cls-1" d="m856.3,181.11c-.37,0-.73.01-1.1.01-20.72-56.31-74.84-96.48-138.35-96.48-39.12,0-74.67,
                    15.25-101.06,40.12-40.15-51.58-102.83-84.76-173.26-84.76-101.68,0-187.19,69.15-212.1,162.99-104.03,17.21-183.37,
                    107.57-183.37,216.48,0,121.2,98.25,219.45,219.45,219.45,14.51,0,28.69-1.42,42.42-4.11,38.32,64.07,108.38,106.98,
                    188.46,106.98,69.39,0,131.25-32.22,171.47-82.5,23.68,10.76,49.99,16.76,77.7,16.76,93.91,0,171.72-68.85,185.75-158.82,
                    59.5-28.41,100.62-89.14,100.62-159.48,0-97.55-79.08-176.64-176.63-176.64Z" />
                    <circle className="cls-1" cx="760.29" cy="840.09" r="89.88" transform="translate(-120.56 1552.75) rotate(-86.83)" />
                    <circle className="cls-1" cx="912.68" cy="981.81" r="58.19" transform="translate(-426.92 932.93) rotate(-45)" />
                </svg>
            </div>,
            html: `<div x-objectcode="thought_bubble_1" id="{id}" title="Thought Bubble 1" class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path className="cls-1" d="m856.3,181.11c-.37,0-.73.01-1.1.01-20.72-56.31-74.84-96.48-138.35-96.48-39.12,0-74.67,
                        15.25-101.06,40.12-40.15-51.58-102.83-84.76-173.26-84.76-101.68,0-187.19,69.15-212.1,162.99-104.03,17.21-183.37,
                        107.57-183.37,216.48,0,121.2,98.25,219.45,219.45,219.45,14.51,0,28.69-1.42,42.42-4.11,38.32,64.07,108.38,106.98,
                        188.46,106.98,69.39,0,131.25-32.22,171.47-82.5,23.68,10.76,49.99,16.76,77.7,16.76,93.91,0,171.72-68.85,185.75-158.82,
                        59.5-28.41,100.62-89.14,100.62-159.48,0-97.55-79.08-176.64-176.63-176.64Z" />
                        <circle className="cls-1" cx="760.29" cy="840.09" r="89.88" transform="translate(-120.56 1552.75) rotate(-86.83)" />
                        <circle className="cls-1" cx="912.68" cy="981.81" r="58.19" transform="translate(-426.92 932.93) rotate(-45)" />
                    </svg>
                </div> `,
            defaultCss: ""
        },
        {
            name: "Blob 1",
            code: "blob_1",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m744.04,910.86c-50,
                    2.66-86.39-25.34-123.45-51.99-27.54-19.81-56.17-37.71-86.25-53.72-59.05-31.42-117.46-23.24-176.59-1.73-41.53,
                    15.11-82.76,31.26-124.96,44.23-51.89,15.94-101.82,10.3-150.07-16.9-40.37-22.76-47.22-64.71-40.42-110.53,
                    6.79-45.77,42.26-70.55,76.88-95.74,39.08-28.44,83.6-47.04,124.79-71.56,31.78-18.92,58-43.98,
                    77.73-75.4,24.04-38.28,19.99-79.46,11.46-120.7-4.65-22.49-11.08-44.61-16.9-66.85-6.56-25.03-4.24-49.45,
                    5.12-73.28,14.93-38.04,56.48-56.14,101.04-43.96,37.85,10.34,70.59,29.4,98.78,56.81,36.3,35.3,75.88,66.24,
                    118.72,93.77,44.23,28.42,85.71,26.43,130.09,2.06,25.23-13.85,48.08-31.21,74.03-44.11,66.65-33.13,
                    152.24-7.58,185.18,58.43,11.35,22.75,12.66,47.95,8.94,73.13-1.96,13.24-4.38,26.42-6.39,39.65-.58,
                    3.84-1.2,7.24-4.27,10.23-66.46,64.78-94.09,146.84-106.8,235.97-6.63,46.44-19.74,91.34-45.15,
                    131.38-30.52,48.09-73.72,76.85-131.48,80.81Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="blob_1" id="{id}" title="Blob 1"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m744.04,910.86c-50,
                        2.66-86.39-25.34-123.45-51.99-27.54-19.81-56.17-37.71-86.25-53.72-59.05-31.42-117.46-23.24-176.59-1.73-41.53,
                        15.11-82.76,31.26-124.96,44.23-51.89,15.94-101.82,10.3-150.07-16.9-40.37-22.76-47.22-64.71-40.42-110.53,
                        6.79-45.77,42.26-70.55,76.88-95.74,39.08-28.44,83.6-47.04,124.79-71.56,31.78-18.92,58-43.98,
                        77.73-75.4,24.04-38.28,19.99-79.46,11.46-120.7-4.65-22.49-11.08-44.61-16.9-66.85-6.56-25.03-4.24-49.45,
                        5.12-73.28,14.93-38.04,56.48-56.14,101.04-43.96,37.85,10.34,70.59,29.4,98.78,56.81,36.3,35.3,75.88,66.24,
                        118.72,93.77,44.23,28.42,85.71,26.43,130.09,2.06,25.23-13.85,48.08-31.21,74.03-44.11,66.65-33.13,
                        152.24-7.58,185.18,58.43,11.35,22.75,12.66,47.95,8.94,73.13-1.96,13.24-4.38,26.42-6.39,39.65-.58,
                        3.84-1.2,7.24-4.27,10.23-66.46,64.78-94.09,146.84-106.8,235.97-6.63,46.44-19.74,91.34-45.15,
                        131.38-30.52,48.09-73.72,76.85-131.48,80.81Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Blob 2",
            code: "blob_2",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m346.72,216.59c62.51,2.28,120.11,5.29,177.75,6.22,52.35.84,103.69-5.83,154.99-18.44,
                    69.92-17.19,141.7-21.82,210.8,3.66,85.42,31.5,137.23,93.85,148.52,184.26,6.76,54.11-15.46,
                    102.07-48.49,144.63-24.47,31.53-50.22,62.11-73.81,94.27-21.18,28.89-31.79,62.82-38.24,97.59-6.17,
                    33.2-20.49,62.35-41.94,87.52-33.17,38.92-76.08,64.86-126.89,68.81-59.16,4.61-118.97,
                    9.9-178.08-5.93-47.59-12.75-91.18-34.71-135.63-54.65-54.53-24.45-108.45-51.63-155.22-88.35-72.47-56.9-140.39-118.36-177.77-207.09-19.74-46.85-29.8-92.64-17.01-141.53,
                    17.24-65.89,56.8-114.51,120.1-142.06,59.27-25.79,122.62-27.4,180.91-28.92Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="blob_2" id="{id}" title="Blob 2"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m346.72,216.59c62.51,2.28,120.11,5.29,177.75,6.22,52.35.84,103.69-5.83,154.99-18.44,
                        69.92-17.19,141.7-21.82,210.8,3.66,85.42,31.5,137.23,93.85,148.52,184.26,6.76,54.11-15.46,
                        102.07-48.49,144.63-24.47,31.53-50.22,62.11-73.81,94.27-21.18,28.89-31.79,62.82-38.24,97.59-6.17,
                        33.2-20.49,62.35-41.94,87.52-33.17,38.92-76.08,64.86-126.89,68.81-59.16,4.61-118.97,
                        9.9-178.08-5.93-47.59-12.75-91.18-34.71-135.63-54.65-54.53-24.45-108.45-51.63-155.22-88.35-72.47-56.9-140.39-118.36-177.77-207.09-19.74-46.85-29.8-92.64-17.01-141.53,
                        17.24-65.89,56.8-114.51,120.1-142.06,59.27-25.79,122.62-27.4,180.91-28.92Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Blob 3",
            code: "blob_3",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m771.35,906.45c-58.58,1.95-116.06-8.68-173.78-15.74-65.56-8.02-130.34-3.46-195.36,5.31-61.06,
                    8.24-123.02,14.06-183.64,1.22-83.32-17.64-144.84-64.32-171.18-148.32-17.84-56.87-1.74-108.22,31.02-155.51,
                    15.44-22.29,32.09-43.67,51.18-63.14,23.93-24.4,33.5-53.08,23.75-86.86-1.79-6.19-2.46-12.77-4.75-18.75-30.28-79.46,
                    34.02-154.59,112.85-166.04,39.32-5.71,76.79-1.58,111.18,17.08,71.6,38.85,143.58,29.9,216.18,5.27,76.69-26.02,
                    148.64-63.54,223.93-92.83,86.07-33.48,161.47-11.42,198.22,77.58,27.28,66.07,20.78,133.81-.08,200.72-4.61,14.78-9.08,
                    29.5-11.13,44.99-4.16,31.42,4.97,59.84,17.29,88.04,27.1,61.97,33.51,124.54,1.16,186.74-34.5,66.33-93.03,99.6-163.66,
                    115.63-27.51,6.24-55.39,4.3-83.19,4.6Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="blob_3" id="{id}" title="Blob 3"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m771.35,906.45c-58.58,1.95-116.06-8.68-173.78-15.74-65.56-8.02-130.34-3.46-195.36,5.31-61.06,
                        8.24-123.02,14.06-183.64,1.22-83.32-17.64-144.84-64.32-171.18-148.32-17.84-56.87-1.74-108.22,31.02-155.51,
                        15.44-22.29,32.09-43.67,51.18-63.14,23.93-24.4,33.5-53.08,23.75-86.86-1.79-6.19-2.46-12.77-4.75-18.75-30.28-79.46,
                        34.02-154.59,112.85-166.04,39.32-5.71,76.79-1.58,111.18,17.08,71.6,38.85,143.58,29.9,216.18,5.27,76.69-26.02,
                        148.64-63.54,223.93-92.83,86.07-33.48,161.47-11.42,198.22,77.58,27.28,66.07,20.78,133.81-.08,200.72-4.61,14.78-9.08,
                        29.5-11.13,44.99-4.16,31.42,4.97,59.84,17.29,88.04,27.1,61.97,33.51,124.54,1.16,186.74-34.5,66.33-93.03,99.6-163.66,
                        115.63-27.51,6.24-55.39,4.3-83.19,4.6Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Blob 4",
            code: "blob_4",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m1039.63,530.88c.44,44.74-4.42,80.86-16.97,115.62-24.67,68.34-71.79,
                    111.05-143.99,126.3-43.75,9.24-88.11,14.15-132.34,18.25-64.7,5.99-123.89,27.19-182.39,
                    53.46-45.23,20.31-91.43,39.09-143.15,
                    35.35-42.63-3.08-79.54-17.85-111.43-46.7-32.14-29.08-54.3-65.66-79.04-100.29-19.13-26.79-41.81-49.21-67.58-69.91-32.29-25.94-70.67-46.75-93.06-82.46-54.19-86.42-35.92-187.85,
                    69.39-236.43,44.61-20.58,90.91-37.68,141-45.48,69.17-10.77,135.29-33.59,197.98-66.27,
                    32.89-17.14,68.36-29.44,106.32-32.06,71.66-4.94,133.3,20.59,187.62,64.13,49.88,39.98,
                    100.27,77.82,160.18,102.62,75.67,31.32,111.92,89.45,107.47,163.86Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="blob_4" id="{id}" title="Blob 4"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m1039.63,530.88c.44,44.74-4.42,80.86-16.97,115.62-24.67,68.34-71.79,
                        111.05-143.99,126.3-43.75,9.24-88.11,14.15-132.34,18.25-64.7,5.99-123.89,27.19-182.39,
                        53.46-45.23,20.31-91.43,39.09-143.15,
                        35.35-42.63-3.08-79.54-17.85-111.43-46.7-32.14-29.08-54.3-65.66-79.04-100.29-19.13-26.79-41.81-49.21-67.58-69.91-32.29-25.94-70.67-46.75-93.06-82.46-54.19-86.42-35.92-187.85,
                        69.39-236.43,44.61-20.58,90.91-37.68,141-45.48,69.17-10.77,135.29-33.59,197.98-66.27,
                        32.89-17.14,68.36-29.44,106.32-32.06,71.66-4.94,133.3,20.59,187.62,64.13,49.88,39.98,
                        100.27,77.82,160.18,102.62,75.67,31.32,111.92,89.45,107.47,163.86Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Blob 5",
            code: "blob_5",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m821.66,919.61c-42.96,
                    1.01-82.87-12.59-123.59-23.99-45.35-12.7-86.07-36.12-130.9-50.31-67.79-21.45-130.23-9.64-193.38,
                    15.73-43.41,17.44-86.69,36.15-133.52,43.39-60.14,
                    9.3-114.36-7.73-153.73-51.53-40.19-44.72-53.93-101.35-42.85-162.22,12.38-68.03,57.05-107.47,
                    117.63-132.85,21.08-8.83,42.83-16.08,63.78-25.17,34.06-14.77,59.24-39.35,75.91-72.4,15.5-30.73,
                    5.36-61.21-4.29-90.21-12.93-38.82-16.54-77.31-.27-114.77,21.23-48.87,58.66-79.53,111.97-91.6,33.93-7.69,
                    65.11-1.82,95.93,10.86,90.31,37.15,184.49,39.76,280.23,33.96,69.24-4.2,137.4-.04,195.89,45.18,64.23,49.65,
                    77.99,123.27,33.95,191-13.69,21.05-27.85,42.19-44.62,60.75-26.49,29.32-33.42,63.26-30.94,100.77,
                    2.17,32.91,10.32,64.44,20.44,95.72,9.72,30.03,19.73,60.47,16.04,92.63-9.2,80.07-52.32,
                    119.82-134.33,125-6.43.41-12.9.05-19.35.05Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="blob_5" id="{id}" title="Blob 5"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m821.66,919.61c-42.96,
                        1.01-82.87-12.59-123.59-23.99-45.35-12.7-86.07-36.12-130.9-50.31-67.79-21.45-130.23-9.64-193.38,
                        15.73-43.41,17.44-86.69,36.15-133.52,43.39-60.14,
                        9.3-114.36-7.73-153.73-51.53-40.19-44.72-53.93-101.35-42.85-162.22,12.38-68.03,57.05-107.47,
                        117.63-132.85,21.08-8.83,42.83-16.08,63.78-25.17,34.06-14.77,59.24-39.35,75.91-72.4,15.5-30.73,
                        5.36-61.21-4.29-90.21-12.93-38.82-16.54-77.31-.27-114.77,21.23-48.87,58.66-79.53,111.97-91.6,33.93-7.69,
                        65.11-1.82,95.93,10.86,90.31,37.15,184.49,39.76,280.23,33.96,69.24-4.2,137.4-.04,195.89,45.18,64.23,49.65,
                        77.99,123.27,33.95,191-13.69,21.05-27.85,42.19-44.62,60.75-26.49,29.32-33.42,63.26-30.94,100.77,
                        2.17,32.91,10.32,64.44,20.44,95.72,9.72,30.03,19.73,60.47,16.04,92.63-9.2,80.07-52.32,
                        119.82-134.33,125-6.43.41-12.9.05-19.35.05Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Location Pin",
            code: "location_pin",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m527.55,1040c-1.04-1.43-1.87-3.08-3.14-4.26-6.96-6.52-12.24-14.33-17.58-22.14-21.31-31.2-42.88-62.22-64.03-93.51-22.81-33.75-45.58-67.53-67.79-101.67-20.27-31.17-39.92-62.76-58.56-94.95-20.43-35.26-39.16-71.42-56.95-108.08-11.09-22.85-21.37-46.05-30.64-69.68-11.43-29.13-21.99-58.56-30.5-88.71-4.18-14.82-7.31-29.79-8.64-45.13-.41-4.7-1.09-9.38-1.89-14.03-1.21-7.06-1.31-14.13-.24-21.16,
                    1.24-8.18,1.74-16.44,2.83-24.62,4.16-31.11,11.32-61.51,22.73-90.82,3.42-8.79,6.99-17.56,11.1-26.05,15.53-32.14,35.72-61.04,
                    60.75-86.58,26.26-26.79,56.14-48.65,89.13-66.37,5.72-3.07,11.58-5.88,17.35-8.86,28.04-14.47,57.92-23.43,88.7-29.68,4.04-.82,
                    8.21-.93,12.28-1.63,2.29-.4,4.49-1.36,6.73-2.06,28.37,0,56.74,0,85.11,0,2.23.74,4.41,1.81,6.7,2.16,26.99,4.02,53.14,10.98,
                    78.4,21.38,39.78,16.37,75.5,38.91,106.26,69.11,10.96,10.77,21.81,21.65,31.75,33.37,16.46,19.4,31,40.18,43.9,62.15,11.12,18.95,
                    19.88,38.93,26.81,59.68,6.19,18.5,10.94,37.41,12.54,56.97,2.77,14.02,2.61,28.2,2.35,42.38-.08,4.13-.39,8.26-.81,12.37-.72,
                    7.05-1.4,14.11-2.46,21.11-1.15,7.58-2.44,15.17-4.17,22.64-4.27,18.42-9.5,36.53-16.26,54.25-9.7,25.39-20.29,50.38-31.23,75.26-12.13,
                    27.6-25.62,54.52-39.34,81.34-11.3,22.08-23.41,43.73-35.54,65.37-12.71,22.68-26.24,44.86-39.67,67.11-7.02,11.63-14.17,23.19-21.56,
                    34.58-20.56,31.72-40.76,63.68-62.13,94.87-21.7,31.67-43.48,63.29-65.24,94.92-2.01,2.92-4.09,5.79-6.2,8.63-4.24,5.7-8.8,11.09-14.61,
                    15.34-1.88,1.37-3.32,3.34-4.97,5.03h-21.28Zm190.99-662.98c-.02-18.3-1.82-31.15-6.93-47.43-14.61-46.48-42.84-82.37-84.3-107.83-20.93-12.85-43.76-20.51-68.01-24.07-1.75-.26-3.53-.29-5.3-.42-17.86-1.32-35.41.99-52.69,
                    5.14-33,7.92-61.11,24.71-85.34,48.23-2.12,2.05-4.15,4.22-6.04,6.48-6.07,7.25-11.84,14.72-17.17,22.55-11.44,16.79-19.2,35.16-24.55,
                    54.7-2.35,8.6-3.16,17.43-4.91,26.1-1.05,5.24-1.21,10.55-1.48,15.86-.73,14.34,1.96,28.24,5.24,42.05,1.91,8.05,4.42,15.93,7.72,23.55,
                    6.85,15.84,15.82,30.44,26.81,43.66,15.22,18.3,32.91,33.89,53.72,45.68,25.68,14.55,53.04,22.86,82.82,22.08,4.13-.11,8.27-.15,12.4-.35,
                    2.35-.11,4.73-.32,7.04-.74,14.55-2.65,28.76-6.42,42.69-11.49,24.55-8.93,56.66-32.43,71.06-48.74.78-.89,1.57-1.76,2.33-2.67,9.54-11.32,
                    18.12-23.29,25.22-36.33,12.97-23.81,20.19-49.05,19.65-76Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="location_pin" id="{id}" title="Location Pin"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                        <path d="m527.55,1040c-1.04-1.43-1.87-3.08-3.14-4.26-6.96-6.52-12.24-14.33-17.58-22.14-21.31-31.2-42.88-62.22-64.03-93.51-22.81-33.75-45.58-67.53-67.79-101.67-20.27-31.17-39.92-62.76-58.56-94.95-20.43-35.26-39.16-71.42-56.95-108.08-11.09-22.85-21.37-46.05-30.64-69.68-11.43-29.13-21.99-58.56-30.5-88.71-4.18-14.82-7.31-29.79-8.64-45.13-.41-4.7-1.09-9.38-1.89-14.03-1.21-7.06-1.31-14.13-.24-21.16,
                        1.24-8.18,1.74-16.44,2.83-24.62,4.16-31.11,11.32-61.51,22.73-90.82,3.42-8.79,6.99-17.56,11.1-26.05,15.53-32.14,35.72-61.04,
                        60.75-86.58,26.26-26.79,56.14-48.65,89.13-66.37,5.72-3.07,11.58-5.88,17.35-8.86,28.04-14.47,57.92-23.43,88.7-29.68,4.04-.82,
                        8.21-.93,12.28-1.63,2.29-.4,4.49-1.36,6.73-2.06,28.37,0,56.74,0,85.11,0,2.23.74,4.41,1.81,6.7,2.16,26.99,4.02,53.14,10.98,
                        78.4,21.38,39.78,16.37,75.5,38.91,106.26,69.11,10.96,10.77,21.81,21.65,31.75,33.37,16.46,19.4,31,40.18,43.9,62.15,11.12,18.95,
                        19.88,38.93,26.81,59.68,6.19,18.5,10.94,37.41,12.54,56.97,2.77,14.02,2.61,28.2,2.35,42.38-.08,4.13-.39,8.26-.81,12.37-.72,
                        7.05-1.4,14.11-2.46,21.11-1.15,7.58-2.44,15.17-4.17,22.64-4.27,18.42-9.5,36.53-16.26,54.25-9.7,25.39-20.29,50.38-31.23,75.26-12.13,
                        27.6-25.62,54.52-39.34,81.34-11.3,22.08-23.41,43.73-35.54,65.37-12.71,22.68-26.24,44.86-39.67,67.11-7.02,11.63-14.17,23.19-21.56,
                        34.58-20.56,31.72-40.76,63.68-62.13,94.87-21.7,31.67-43.48,63.29-65.24,94.92-2.01,2.92-4.09,5.79-6.2,8.63-4.24,5.7-8.8,11.09-14.61,
                        15.34-1.88,1.37-3.32,3.34-4.97,5.03h-21.28Zm190.99-662.98c-.02-18.3-1.82-31.15-6.93-47.43-14.61-46.48-42.84-82.37-84.3-107.83-20.93-12.85-43.76-20.51-68.01-24.07-1.75-.26-3.53-.29-5.3-.42-17.86-1.32-35.41.99-52.69,
                        5.14-33,7.92-61.11,24.71-85.34,48.23-2.12,2.05-4.15,4.22-6.04,6.48-6.07,7.25-11.84,14.72-17.17,22.55-11.44,16.79-19.2,35.16-24.55,
                        54.7-2.35,8.6-3.16,17.43-4.91,26.1-1.05,5.24-1.21,10.55-1.48,15.86-.73,14.34,1.96,28.24,5.24,42.05,1.91,8.05,4.42,15.93,7.72,23.55,
                        6.85,15.84,15.82,30.44,26.81,43.66,15.22,18.3,32.91,33.89,53.72,45.68,25.68,14.55,53.04,22.86,82.82,22.08,4.13-.11,8.27-.15,12.4-.35,
                        2.35-.11,4.73-.32,7.04-.74,14.55-2.65,28.76-6.42,42.69-11.49,24.55-8.93,56.66-32.43,71.06-48.74.78-.89,1.57-1.76,2.33-2.67,9.54-11.32,
                        18.12-23.29,25.22-36.33,12.97-23.81,20.19-49.05,19.65-76Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Phone",
            code: "phone",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m272.11,303.11c.77-3.4,1.51-6.8,2.31-10.19,4.3-18.2,12.88-34.07,26.44-46.98,8.48-8.07,
                    18.11-14.75,28.85-19.49,12.52-5.53,25.27-10.56,37.93-15.79.88-.36,1.84-.53,2.95-.84,3.9,8.75,7.78,17.37,11.59,
                    26.03,5.05,11.5,10.02,23.05,15.06,34.55,5.36,12.22,10.83,24.4,16.14,36.64,8.4,19.35,16.96,38.63,24.97,58.13,5.48,
                    13.33,10.18,26.99,15.1,40.55.27.74-.36,2.45-1.05,2.81-13.62,7.17-22.47,18.46-28.12,32.47-4.77,11.83-7.29,24.22-7.56,
                    36.92-.51,23.34,2.81,46.18,8.76,68.79,4.48,17.02,10.32,33.5,17.34,49.54,7.61,17.4,16.42,34.25,27.61,49.67,7.41,10.21,
                    14.98,20.36,23.27,29.84,10.36,11.83,22.39,22.01,35.89,30.14,9.77,5.89,20.23,10.28,31.64,12.41,16.76,3.13,32.73,1.17,
                    48.08-6.1,2.35-1.11,2.76.35,3.32,1.96,3.16,9.12,6.24,18.27,9.54,27.34,3.55,9.73,7.18,19.44,11.03,29.06,7.35,18.34,14.75,
                    36.65,22.37,54.88,6.46,15.45,12.93,30.91,20.02,46.08,5.69,12.15,12.34,23.84,18.45,35.8.29.58-.34,2.32-.93,2.55-15.53,
                    6.24-31.05,12.48-47.44,16.34-8.56,2.01-17.08,3.6-25.89,3.21-.14,
                    0-.29.35-.44.53-5.96-.49-11.91-.98-17.87-1.47-.48-.29-.93-.7-1.45-.84-6.44-1.76-13.06-3-19.31-5.29-16.48-6.03-31.75-14.21-45.5-25.36-19.24-15.6-37.04-32.7-53.21-51.34-14.76-17.02-28.56-34.91-42.17-52.89-9.93-13.13-19.45-26.65-28.1-40.64-13.3-21.53-26.14-43.38-38.34-65.54-10.13-18.4-19.47-37.27-28.42-56.28-8.1-17.23-15.63-34.76-22.59-52.48-7.02-17.85-13.3-36.01-19.38-54.21-4.83-14.46-9.44-29.04-13.13-43.83-5.06-20.28-9.31-40.77-13.58-61.23-1.78-8.52-2.98-17.18-3.98-25.83-1.04-9.06-1.71-18.18-2.19-27.29-.41-7.7-.3-15.42-.47-23.13-.03-1.64-.25-3.28-.38-4.92.94-11.42,1.88-22.84,2.81-34.26Z" />
                    <path d="m720.99,908.33c-7.89-.37-11.22-4.81-13.53-9.74-4.66-9.94-8.76-20.15-13.11-30.24-3.34-7.74-6.75-15.45-10.03-23.22-3.97-9.42-7.79-18.91-11.77-28.33-3.57-8.46-7.26-16.88-10.93-25.31-2.29-5.26-4.68-10.47-6.91-15.76-3.45-8.2-6.77-16.45-10.21-24.65-5.14-12.24-10.33-24.47-15.52-36.69-2.28-5.37-4.94-10.61-6.85-16.11-3.36-9.69.54-18.02,
                    9.78-22.49,16.1-7.8,32.11-15.8,48.23-23.56,5.68-2.74,11.44-5.18,18.01-5.29,7.07-.12,11.74,3.1,14.79,9.15,8.2,16.26,
                    16.32,32.57,24.56,48.81,10.07,19.85,20.23,39.64,30.34,59.47,7.55,14.8,15.04,29.63,22.62,44.42,5.34,10.42,10.93,20.72,
                    16.13,31.21,3.02,6.1,5.57,12.39,3.1,19.55-1.61,4.66-4.93,7.86-8.84,10.03-19.77,10.93-39.82,21.35-59.57,32.33-6.91,3.84-14.01,6.1-20.31,6.43Z" />
                    <path d="m487.92,399.34c-7.89,0-10.93-6.04-13.52-11.4-10.3-21.25-20.11-42.73-30.07-64.14-6.6-14.18-13.14-28.38-19.71-42.57-5.13-11.07-10.25-22.14-15.41-33.2-5.99-12.83-11.97-25.67-18.06-38.46-1.57-3.31-3.35-6.55-5.37-9.6-5.71-8.64-2.2-19.14,
                    7.63-23.3,16.35-6.92,32.61-14.08,48.9-21.15,7.41-3.22,14.97-6.31,23.21-5.24,7.7,1,14.92,4.06,18.51,11.36,4.95,10.06,8.85,20.62,
                    13.37,30.9,6.79,15.43,13.79,30.76,20.57,46.18,6.97,15.85,13.74,31.8,20.7,47.66,8.54,19.46,17.29,38.82,25.71,58.33,2.14,4.96,3.59,
                    10.27,4.9,15.53.78,3.14-1.21,5.44-3.6,7.54-9.18,8.04-20.79,11.02-31.66,15.5-12.21,5.02-24.5,9.85-36.86,14.5-2.87,1.08-6.16,1.08-9.25,1.57Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="phone" id="{id}" title="Phone"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                    <defs>
                        <style>
                        .cls-2 {
                            fill: none;
                            stroke-miterlimit: 10;
                            stroke-width: 37px;
                        }
                        </style>
                    </defs>
                    <g>
                        <path d="m272.11,303.11c.77-3.4,1.51-6.8,2.31-10.19,4.3-18.2,12.88-34.07,26.44-46.98,8.48-8.07,18.11-14.75,28.85-19.49,12.52-5.53,25.27-10.56,37.93-15.79.88-.36,1.84-.53,2.95-.84,3.9,8.75,7.78,17.37,11.59,26.03,5.05,11.5,10.02,23.05,15.06,34.55,5.36,12.22,10.83,24.4,16.14,36.64,8.4,19.35,16.96,38.63,24.97,58.13,5.48,13.33,10.18,26.99,15.1,40.55.27.74-.36,2.45-1.05,2.81-13.62,7.17-22.47,18.46-28.12,32.47-4.77,11.83-7.29,24.22-7.56,36.92-.51,23.34,2.81,46.18,8.76,68.79,4.48,17.02,10.32,33.5,17.34,49.54,7.61,17.4,16.42,34.25,27.61,49.67,7.41,10.21,14.98,20.36,23.27,29.84,10.36,11.83,22.39,22.01,35.89,30.14,9.77,5.89,20.23,10.28,31.64,12.41,16.76,3.13,32.73,1.17,48.08-6.1,2.35-1.11,2.76.35,3.32,1.96,3.16,9.12,6.24,18.27,9.54,27.34,3.55,9.73,7.18,19.44,11.03,29.06,7.35,18.34,14.75,36.65,22.37,54.88,6.46,15.45,12.93,30.91,20.02,46.08,5.69,12.15,12.34,23.84,18.45,35.8.29.58-.34,2.32-.93,2.55-15.53,6.24-31.05,12.48-47.44,16.34-8.56,2.01-17.08,3.6-25.89,3.21-.14,0-.29.35-.44.53-5.96-.49-11.91-.98-17.87-1.47-.48-.29-.93-.7-1.45-.84-6.44-1.76-13.06-3-19.31-5.29-16.48-6.03-31.75-14.21-45.5-25.36-19.24-15.6-37.04-32.7-53.21-51.34-14.76-17.02-28.56-34.91-42.17-52.89-9.93-13.13-19.45-26.65-28.1-40.64-13.3-21.53-26.14-43.38-38.34-65.54-10.13-18.4-19.47-37.27-28.42-56.28-8.1-17.23-15.63-34.76-22.59-52.48-7.02-17.85-13.3-36.01-19.38-54.21-4.83-14.46-9.44-29.04-13.13-43.83-5.06-20.28-9.31-40.77-13.58-61.23-1.78-8.52-2.98-17.18-3.98-25.83-1.04-9.06-1.71-18.18-2.19-27.29-.41-7.7-.3-15.42-.47-23.13-.03-1.64-.25-3.28-.38-4.92.94-11.42,1.88-22.84,2.81-34.26Z"/>
                        <path d="m720.99,908.33c-7.89-.37-11.22-4.81-13.53-9.74-4.66-9.94-8.76-20.15-13.11-30.24-3.34-7.74-6.75-15.45-10.03-23.22-3.97-9.42-7.79-18.91-11.77-28.33-3.57-8.46-7.26-16.88-10.93-25.31-2.29-5.26-4.68-10.47-6.91-15.76-3.45-8.2-6.77-16.45-10.21-24.65-5.14-12.24-10.33-24.47-15.52-36.69-2.28-5.37-4.94-10.61-6.85-16.11-3.36-9.69.54-18.02,9.78-22.49,16.1-7.8,32.11-15.8,48.23-23.56,5.68-2.74,11.44-5.18,18.01-5.29,7.07-.12,11.74,3.1,14.79,9.15,8.2,16.26,16.32,32.57,24.56,48.81,10.07,19.85,20.23,39.64,30.34,59.47,7.55,14.8,15.04,29.63,22.62,44.42,5.34,10.42,10.93,20.72,16.13,31.21,3.02,6.1,5.57,12.39,3.1,19.55-1.61,4.66-4.93,7.86-8.84,10.03-19.77,10.93-39.82,21.35-59.57,32.33-6.91,3.84-14.01,6.1-20.31,6.43Z"/>
                        <path d="m487.92,399.34c-7.89,0-10.93-6.04-13.52-11.4-10.3-21.25-20.11-42.73-30.07-64.14-6.6-14.18-13.14-28.38-19.71-42.57-5.13-11.07-10.25-22.14-15.41-33.2-5.99-12.83-11.97-25.67-18.06-38.46-1.57-3.31-3.35-6.55-5.37-9.6-5.71-8.64-2.2-19.14,7.63-23.3,16.35-6.92,32.61-14.08,48.9-21.15,7.41-3.22,14.97-6.31,23.21-5.24,7.7,1,14.92,4.06,18.51,11.36,4.95,10.06,8.85,20.62,13.37,30.9,6.79,15.43,13.79,30.76,20.57,46.18,6.97,15.85,13.74,31.8,20.7,47.66,8.54,19.46,17.29,38.82,25.71,58.33,2.14,4.96,3.59,10.27,4.9,15.53.78,3.14-1.21,5.44-3.6,7.54-9.18,8.04-20.79,11.02-31.66,15.5-12.21,5.02-24.5,9.85-36.86,14.5-2.87,1.08-6.16,1.08-9.25,1.57Z"/>
                    </g>
                    <circle class="cls-2" cx="540" cy="540" r="486"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Email",
            code: "email",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none">
                    <path d="m238.58,325.34h603.15c-3.28,2.83-6.27,5.33-9.16,7.94-14.82,13.42-29.56,26.93-44.43,40.29-16.39,14.73-32.95,
                    29.28-49.34,44.02-20.33,18.29-40.53,36.72-60.86,55.02-8.21,7.39-16.67,14.5-24.87,21.89-12.9,11.63-25.61,23.46-38.52,
                    35.07-15.24,13.7-30.66,27.21-45.92,40.89-8.57,7.69-17.03,15.5-25.39,23.41-2.18,2.06-3.58,2.11-5.84.07-32.86-29.7-65.78-59.34-98.74-88.93-12.23-10.98-24.7-21.68-36.9-32.69-32.89-29.69-65.67-59.5-98.52-89.22-19.06-17.25-38.17-34.44-57.29-51.64-2.23-2-4.61-3.84-7.38-6.13Z" />
                    <path d="m840.4,754.66H239.49c71.49-60.35,142.53-120.31,213.77-180.44,4.38,3.84,8.91,7.74,13.35,11.73,15.36,13.83,
                    30.67,27.73,46.05,41.54,5.22,4.69,10.49,9.35,15.94,13.77,7.15,5.79,17.11,5.06,23.3.03,5.55-4.5,10.89-9.25,16.22-14.02,
                    19.02-17.01,38.01-34.05,56.94-51.16,1.87-1.69,2.9-1.78,4.89-.02,9.39,8.26,18.95,16.33,28.51,24.4,19.47,16.44,38.99,32.82,
                    58.47,49.25,19.74,16.66,39.42,33.39,59.17,50.04,17.55,14.8,35.18,29.51,52.72,44.33,3.87,3.27,7.51,6.82,11.58,10.53Z" />
                    <path d="m202.19,727.61c-.03-126.11,0-252.22-.02-379.13,36.2,32.44,71.4,63.84,106.44,95.4,33.97,30.59,67.78,61.36,101.69,
                    92.01,2.95,2.67,6.07,5.18,9.28,7.53,2.09,1.52,2.66,2.41.3,4.38-16.01,13.4-31.9,26.96-47.84,40.44-27.63,23.37-55.25,46.77-82.93,
                    70.07-27.86,23.46-55.8,46.82-83.72,70.22-.87.73-1.84,1.36-3.19,2.35v-2.5c0-.26-.01-.52-.02-.78Z" />
                    <path d="m877.82,348.83v382.35c-73.53-62.03-146.6-123.67-219.42-185.09,73.02-65.65,145.85-131.13,219.42-197.26Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="email" id="{id}" title="Email"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" preserveAspectRatio="none" pointer-events="none">
                    <defs>
                        <style>
                        .cls-1 {
                            fill: none;
                            stroke-miterlimit: 10;
                            stroke-width: 37px;
                        }
                        </style>
                    </defs>
                    <g>
                        <path d="m238.58,325.34h603.15c-3.28,2.83-6.27,5.33-9.16,7.94-14.82,13.42-29.56,26.93-44.43,40.29-16.39,14.73-32.95,
                        29.28-49.34,44.02-20.33,18.29-40.53,36.72-60.86,55.02-8.21,7.39-16.67,14.5-24.87,21.89-12.9,11.63-25.61,23.46-38.52,
                        35.07-15.24,13.7-30.66,27.21-45.92,40.89-8.57,7.69-17.03,15.5-25.39,23.41-2.18,2.06-3.58,2.11-5.84.07-32.86-29.7-65.78-59.34-98.74-88.93-12.23-10.98-24.7-21.68-36.9-32.69-32.89-29.69-65.67-59.5-98.52-89.22-19.06-17.25-38.17-34.44-57.29-51.64-2.23-2-4.61-3.84-7.38-6.13Z"/>
                        <path d="m840.4,754.66H239.49c71.49-60.35,142.53-120.31,213.77-180.44,4.38,3.84,8.91,7.74,13.35,11.73,15.36,13.83,30.67,
                        27.73,46.05,41.54,5.22,4.69,10.49,9.35,15.94,13.77,7.15,5.79,17.11,5.06,23.3.03,5.55-4.5,10.89-9.25,16.22-14.02,19.02-17.01,
                        38.01-34.05,56.94-51.16,1.87-1.69,2.9-1.78,4.89-.02,9.39,8.26,18.95,16.33,28.51,24.4,19.47,16.44,38.99,32.82,58.47,49.25,19.74,
                        16.66,39.42,33.39,59.17,50.04,17.55,14.8,35.18,29.51,52.72,44.33,3.87,3.27,7.51,6.82,11.58,10.53Z"/>
                        <path d="m202.19,727.61c-.03-126.11,0-252.22-.02-379.13,36.2,32.44,71.4,63.84,106.44,95.4,33.97,30.59,67.78,61.36,101.69,
                        92.01,2.95,2.67,6.07,5.18,9.28,7.53,2.09,1.52,2.66,2.41.3,4.38-16.01,13.4-31.9,26.96-47.84,40.44-27.63,23.37-55.25,46.77-82.93,
                        70.07-27.86,23.46-55.8,46.82-83.72,70.22-.87.73-1.84,1.36-3.19,2.35v-2.5c0-.26-.01-.52-.02-.78Z"/>
                        <path d="m877.82,348.83v382.35c-73.53-62.03-146.6-123.67-219.42-185.09,73.02-65.65,145.85-131.13,219.42-197.26Z"/>
                    </g>
                    <path d="m305.72,663.29c0,.17,0,.33.01.5,0-.17,0-.33-.01-.5Z"/>
                    <circle class="cls-1" cx="540" cy="540" r="486"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "A",
            code: "a",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m183.75,1040L356.25,40h365l175,1000h-313.75l-8.75-98.75h-73.75l-8.75,
                    98.75H183.75Zm377.5-313.75l-12.5-211.25c-2.5-42.5-5-86.25-8.75-131.25-3.75,0-15,235-21.25,342.5h42.5Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="a" id="{id}" title="A"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m183.75,1040L356.25,40h365l175,1000h-313.75l-8.75-98.75h-73.75l-8.75,
                        98.75H183.75Zm377.5-313.75l-12.5-211.25c-2.5-42.5-5-86.25-8.75-131.25-3.75,0-15,235-21.25,342.5h42.5Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "B",
            code: "b",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m229.37,40h361.25c147.5,0,246.25,86.25,246.25,221.25v97.5c0,53.75-6.25,116.25-55,151.25,68.75,
                    32.5,68.75,105,68.75,165v151.25c0,136.25-121.25,213.75-268.75,213.75H229.37V40Zm300,788.75h7.5c11.25,0,
                    11.25-8.75,11.25-15v-190c0-3.75,2.5-11.25-11.25-11.25h-7.5v216.25Zm1.25-567.5v180h6.25c6.25,0,8.75-2.5,
                    8.75-7.5v-163.75c0-6.25-2.5-8.75-7.5-8.75h-7.5Z" /></svg>
            </div>,
            html: `<div x-objectcode="b" id="{id}" title="B"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m229.37,40h361.25c147.5,0,246.25,86.25,246.25,221.25v97.5c0,53.75-6.25,116.25-55,151.25,68.75,
                        32.5,68.75,105,68.75,165v151.25c0,136.25-121.25,213.75-268.75,213.75H229.37V40Zm300,788.75h7.5c11.25,0,
                        11.25-8.75,11.25-15v-190c0-3.75,2.5-11.25-11.25-11.25h-7.5v216.25Zm1.25-567.5v180h6.25c6.25,0,8.75-2.5,
                        8.75-7.5v-163.75c0-6.25-2.5-8.75-7.5-8.75h-7.5Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "C",
            code: "c",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m238.29,281.13c0-165.24,105.26-241.13,301.1-241.13s302.33,75.89,302.33,
                    241.13v186.05h-293.76v-209.3c0-4.9-2.45-7.34-8.57-7.34s-8.57,2.45-8.56,7.34v567.93c0,
                    4.9,2.45,7.34,8.56,7.34s8.57-2.45,8.57-7.35v-225.21h293.76v201.96c0,165.24-106.49,
                    237.45-302.33,237.45s-301.1-72.22-301.1-237.45V281.13Z" /></svg>
            </div>,
            html: `<div x-objectcode="c" id="{id}" title="C"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m238.29,281.13c0-165.24,105.26-241.13,301.1-241.13s302.33,75.89,302.33,
                        241.13v186.05h-293.76v-209.3c0-4.9-2.45-7.34-8.57-7.34s-8.57,2.45-8.56,7.34v567.93c0,
                        4.9,2.45,7.34,8.56,7.34s8.57-2.45,8.57-7.35v-225.21h293.76v201.96c0,165.24-106.49,
                        237.45-302.33,237.45s-301.1-72.22-301.1-237.45V281.13Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "D",
            code: "d",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m539.38,40c200,0,308.75,77.5,308.75,246.25v507.5c0,168.75-108.75,246.25-307.5,246.25H231.87V40h307.5Zm-7.5,785h7.5c6.25,
                    0,8.75-2.5,8.75-7.5V262.5c0-5-2.5-7.5-8.75-7.5h-7.5v570Z" /></svg>
            </div>,
            html: `<div x-objectcode="d" id="{id}" title="D"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m539.38,40c200,0,308.75,77.5,308.75,246.25v507.5c0,168.75-108.75,246.25-307.5,246.25H231.87V40h307.5Zm-7.5,785h7.5c6.25,
                        0,8.75-2.5,8.75-7.5V262.5c0-5-2.5-7.5-8.75-7.5h-7.5v570Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "E",
            code: "e",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m289.37,1040V40h501.25v233.75h-201.25v152.5h140v227.5h-140v152.5h201.25v233.75h-501.25Z" /></svg>
            </div>,
            html: `<div x-objectcode="e" id="{id}" title="E"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m289.37,1040V40h501.25v233.75h-201.25v152.5h140v227.5h-140v152.5h201.25v233.75h-501.25Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "F",
            code: "f",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m289.37,40h501.25v233.75h-201.25v158.75h140v215h-140v392.5h-300V40Z" /></svg>
            </div>,
            html: `<div x-objectcode="f" id="{id}" title="F"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m289.37,40h501.25v233.75h-201.25v158.75h140v215h-140v392.5h-300V40Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "G",
            code: "g",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m841.71,281.13v122.4h-293.76v-145.65c0-4.9-2.45-7.34-8.57-7.34s-8.57,2.45-8.56,7.34v567.93c0,
                    7.34,3.67,11.02,9.79,11.02,7.34,0,11.02-3.67,11.02-9.79v-165.24h-9.79v-210.53h298.65l1.22,580.17h-263.16v-26.93c-28.15,
                    25.7-72.22,35.5-129.74,35.5-127.3,0-210.53-72.22-210.53-237.45V281.13c0-165.24,105.26-241.13,301.1-241.13s302.33,
                    75.89,302.33,241.13Z" /></svg>
            </div>,
            html: `<div x-objectcode="g" id="{id}" title="G"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m841.71,281.13v122.4h-293.76v-145.65c0-4.9-2.45-7.34-8.57-7.34s-8.57,2.45-8.56,7.34v567.93c0,
                    7.34,3.67,11.02,9.79,11.02,7.34,0,11.02-3.67,11.02-9.79v-165.24h-9.79v-210.53h298.65l1.22,580.17h-263.16v-26.93c-28.15,
                    25.7-72.22,35.5-129.74,35.5-127.3,0-210.53-72.22-210.53-237.45V281.13c0-165.24,105.26-241.13,301.1-241.13s302.33,
                    75.89,302.33,241.13Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "H",
            code: "h",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m231.87,40h300v392.5h16.25V40h300v1000h-300v-391.25h-16.25v391.25H231.87V40Z" /></svg>
            </div>,
            html: `<div x-objectcode="h" id="{id}" title="H"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m231.87,40h300v392.5h16.25V40h300v1000h-300v-391.25h-16.25v391.25H231.87V40Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "I",
            code: "i",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m390,40h300v1000h-300V40Z" /></svg>
            </div>,
            html: `<div x-objectcode="i" id="{id}" title="I"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m390,40h300v1000h-300V40Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "J",
            code: "j",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m360.85,806.54h31.21c18.73,0,27.47-8.74,27.47-24.97V41.25l299.63-1.25v767.79c0,
                    156.06-106.12,232.21-282.15,232.21h-76.15v-233.46Z" /></svg>
            </div>,
            html: `<div x-objectcode="j" id="{id}" title="J"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m360.85,806.54h31.21c18.73,0,27.47-8.74,27.47-24.97V41.25l299.63-1.25v767.79c0,
                        156.06-106.12,232.21-282.15,232.21h-76.15v-233.46Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "K",
            code: "k",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m178.12,40h300v248.75l116.25-248.75h300l-256.25,502.5,263.75,
                    496.25h-298.75l-111.25-206.25-13.75,25v182.5H178.12V40Z" /></svg>
            </div>,
            html: `<div x-objectcode="k" id="{id}" title="K"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m178.12,40h300v248.75l116.25-248.75h300l-256.25,502.5,263.75,
                        496.25h-298.75l-111.25-206.25-13.75,25v182.5H178.12V40Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "L",
            code: "l",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m589.37,40v766.25h201.25v233.75h-501.25V40h300Z" /></svg>
            </div>,
            html: `<div x-objectcode="l" id="{id}" title="L"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m589.37,40v766.25h201.25v233.75h-501.25V40h300Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "M",
            code: "m",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m378.12,40l156.25,380L696.88,40h278.75l7.5,1000h-283.75v-421.25l-126.25,
                    296.25h-68.75l-123.75-296.25v421.25H96.87V40h281.25Z"/></svg>
            </div>,
            html: `<div x-objectcode="m" id="{id}" title="M"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m378.12,40l156.25,380L696.88,40h278.75l7.5,1000h-283.75v-421.25l-126.25,
                        296.25h-68.75l-123.75-296.25v421.25H96.87V40h281.25Z"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "N",
            code: "n",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m461.35,41.25l128.59,
                    392.01V41.25l275.91-1.25v998.75l-247.19,1.25-121.1-393.26v393.26H214.16V41.25h247.19Z"/></svg>
            </div>,
            html: `<div x-objectcode="n" id="{id}" title="N"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m461.35,41.25l128.59,
                        392.01V41.25l275.91-1.25v998.75l-247.19,1.25-121.1-393.26v393.26H214.16V41.25h247.19Z"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "O",
            code: "o",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m841.71,281.13v521.42c0,165.24-106.49,237.45-302.33,237.45s-301.1-72.22-301.1-237.45V281.13c0-165.24,105.26-241.13,
                    301.1-241.13s302.33,75.89,302.33,241.13Zm-293.76,544.68V257.88c0-4.9-2.45-7.34-8.57-7.34s-8.57,
                    2.45-8.56,7.34v567.93c0,4.9,2.45,7.34,8.57,7.34s8.57-2.45,8.57-7.34Z"/></svg>
            </div>,
            html: `<div x-objectcode="o" id="{id}" title="O"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m841.71,281.13v521.42c0,165.24-106.49,237.45-302.33,237.45s-301.1-72.22-301.1-237.45V281.13c0-165.24,105.26-241.13,
                    301.1-241.13s302.33,75.89,302.33,241.13Zm-293.76,544.68V257.88c0-4.9-2.45-7.34-8.57-7.34s-8.57,
                    2.45-8.56,7.34v567.93c0,4.9,2.45,7.34,8.57,7.34s8.57-2.45,8.57-7.34Z"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "P",
            code: "p",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m231.25,40h308.75c196.25,0,308.75,78.75,308.75,245v240c0,166.25-110,246.25-308.75,246.25h-8.75v268.75H231.25V40Zm300,
                516.25h8.75c5,0,7.5-2.5,7.5-6.25v-288.75c0-3.75-2.5-6.25-7.5-6.25h-8.75v301.25Z"/></svg>
            </div>,
            html: `<div x-objectcode="p" id="{id}" title="P"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m231.25,40h308.75c196.25,0,308.75,78.75,308.75,245v240c0,166.25-110,246.25-308.75,246.25h-8.75v268.75H231.25V40Zm300,
                    516.25h8.75c5,0,7.5-2.5,7.5-6.25v-288.75c0-3.75-2.5-6.25-7.5-6.25h-8.75v301.25Z"/>
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Q",
            code: "q",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m812.04,268.54v494.2c0,26.68-3.48,52.2-9.28,75.41l37.12,5.8v196.06l-373.55-54.52c-146.17-19.72-226.22-83.53-226.22-222.74v-494.2c0-156.61,
                    99.77-228.54,285.38-228.54s286.54,71.93,286.54,228.54Zm-294.67,516.24c0,4.64,2.32,6.96,8.12,6.96s8.12-2.32,
                    8.12-6.96V246.5c0-4.64-2.32-6.96-8.12-6.96s-8.12,2.32-8.12,6.95v538.28Z" /></svg>
            </div>,
            html: `<div x-objectcode="q" id="{id}" title="Q"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m812.04,268.54v494.2c0,26.68-3.48,52.2-9.28,75.41l37.12,5.8v196.06l-373.55-54.52c-146.17-19.72-226.22-83.53-226.22-222.74v-494.2c0-156.61,
                    99.77-228.54,285.38-228.54s286.54,71.93,286.54,228.54Zm-294.67,516.24c0,4.64,2.32,6.96,8.12,6.96s8.12-2.32,
                    8.12-6.96V246.5c0-4.64-2.32-6.96-8.12-6.96s-8.12,2.32-8.12,6.95v538.28Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "R",
            code: "r",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m230,1040V40h308.75c196.25,0,308.75,78.75,308.75,245v240c0,68.75-20,125-58.75,166.25l61.25,
                    348.75h-267.5l-47.5-268.75h-5v268.75H230ZM530,255v302.5h8.75c5,0,7.5-2.5,7.5-6.25v-290c0-3.75-2.5-6.25-7.5-6.25h-8.75Z" /></svg>
            </div>,
            html: `<div x-objectcode="r" id="{id}" title="R"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m230,1040V40h308.75c196.25,0,308.75,78.75,308.75,245v240c0,68.75-20,125-58.75,166.25l61.25,
                    348.75h-267.5l-47.5-268.75h-5v268.75H230ZM530,255v302.5h8.75c5,0,7.5-2.5,7.5-6.25v-290c0-3.75-2.5-6.25-7.5-6.25h-8.75Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "S",
            code: "s",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m841.71,266.44v138.31h-292.53v-129.74c0-6.12-3.67-9.79-9.79-11.02-4.9,1.22-7.34,4.9-7.34,11.02v121.18c0,18.36,20.81,
                    25.7,122.4,57.53,123.62,37.94,194.61,126.07,193.39,233.78l-1.22,129.74c-1.22,181.15-133.41,222.77-307.22,
                    222.77s-297.43-41.62-298.65-222.77l-1.22-137.09h293.76v128.52c0,6.12,2.45,9.79,6.12,11.02,4.9-2.45,7.34-6.12,
                    7.34-11.02v-128.52c0-25.7-22.03-34.27-122.4-64.87-148.1-46.51-192.17-108.94-192.17-220.32v-128.52c0-178.7,
                    130.97-226.44,304.77-226.44s304.77,44.06,304.77,226.44Z" /></svg>
            </div>,
            html: `<div x-objectcode="s" id="{id}" title="S"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m841.71,266.44v138.31h-292.53v-129.74c0-6.12-3.67-9.79-9.79-11.02-4.9,1.22-7.34,4.9-7.34,11.02v121.18c0,18.36,20.81,
                    25.7,122.4,57.53,123.62,37.94,194.61,126.07,193.39,233.78l-1.22,129.74c-1.22,181.15-133.41,222.77-307.22,
                    222.77s-297.43-41.62-298.65-222.77l-1.22-137.09h293.76v128.52c0,6.12,2.45,9.79,6.12,11.02,4.9-2.45,7.34-6.12,
                    7.34-11.02v-128.52c0-25.7-22.03-34.27-122.4-64.87-148.1-46.51-192.17-108.94-192.17-220.32v-128.52c0-178.7,
                    130.97-226.44,304.77-226.44s304.77,44.06,304.77,226.44Z" /></svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "T",
            code: "t",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m252.5,273.75V40h575v233.75h-137.5v766.25h-300V273.75h-137.5Z" /></svg>
            </div>,
            html: `<div x-objectcode="t" id="{id}" title="T"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m252.5,273.75V40h575v233.75h-137.5v766.25h-300V273.75h-137.5Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "U",
            code: "u",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m234.93,41.24h295.79v782.18c0,4.95,2.47,7.43,8.66,7.43s8.66-2.48,8.67-7.43V41.24l297.03-1.24v759.9c0,
                    164.6-107.67,240.1-305.69,240.1s-304.46-75.5-304.46-240.1V41.24Z" /></svg>
            </div>,
            html: `<div x-objectcode="u" id="{id}" title="U"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m234.93,41.24h295.79v782.18c0,4.95,2.47,7.43,8.66,7.43s8.66-2.48,8.67-7.43V41.24l297.03-1.24v759.9c0,
                    164.6-107.67,240.1-305.69,240.1s-304.46-75.5-304.46-240.1V41.24Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "V",
            code: "v",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m897.5,40l-173.75,1000h-366.25L182.5,40h307.5l50,611.25,51.25-611.25h306.25Z" /></svg>
            </div>,
            html: `<div x-objectcode="v" id="{id}" title="V"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m897.5,40l-173.75,1000h-366.25L182.5,40h307.5l50,611.25,51.25-611.25h306.25Z"/></svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "W",
            code: "w",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m364.37,40l33.75,476.25,50-476.25h185l50,475,33.75-475h318.75l-172.5,1000h-278.75l-43.75-320-41.25,320H218.13L44.37,40h320Z" /></svg>
            </div>,
            html: `<div x-objectcode="w" id="{id}" title="W"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m364.37,40l33.75,476.25,50-476.25h185l50,475,33.75-475h318.75l-172.5,1000h-278.75l-43.75-320-41.25,320H218.13L44.37,40h320Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "X",
            code: "x",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m209.37,1040l148.75-500L209.37,40h300l30,215,31.25-213.75h298.75l-146.25,498.75,147.5,500h-300l-31.25-216.25-30,216.25H209.37Z" /></svg>
            </div>,
            html: `<div x-objectcode="x" id="{id}" title="X"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m209.37,1040l148.75-500L209.37,40h300l30,215,31.25-213.75h298.75l-146.25,498.75,147.5,500h-300l-31.25-216.25-30,216.25H209.37Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Y",
            code: "y",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m175,40h300l66.25,308.75,63.75-308.75h300l-216.25,640v360h-300v-360L175,40Z" /></svg>
            </div>,
            html: `<div x-objectcode="y" id="{id}" title="Y"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m175,40h300l66.25,308.75,63.75-308.75h300l-216.25,640v360h-300v-360L175,40Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Z",
            code: "z",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m833.75,40v172.5l-273.75,593.75h275v233.75H248.75v-172.5l271.25-593.75H245V40h588.75Z" /></svg>
            </div>,
            html: `<div x-objectcode="z" id="{id}" title="Z"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m833.75,40v172.5l-273.75,593.75h275v233.75H248.75v-172.5l271.25-593.75H245V40h588.75Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Exclamation Mark",
            code: "exclamation-mark",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m677.12,40l-40.77,752.93h-192.71L402.88,40h274.24Zm-20.38,799.88v200.12h-233.48v-200.12h233.48Z" /></svg>
            </div>,
            html: `<div x-objectcode="exclamation-mark" id="{id}" title="Exclamation Mark"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m677.12,40l-40.77,752.93h-192.71L402.88,40h274.24Zm-20.38,799.88v200.12h-233.48v-200.12h233.48Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Amperstand",
            code: "amperstand",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m924.55,581.02v193.07l-79.66,50.94,99.82,190.93h-264.53l-34.36-63.59c-88.62,58.43-172.54,87.64-251.72,
                    87.64s-146.85-27.58-191.62-82.74c-44.79-55.16-67.17-121.64-67.17-199.43,0-62.24,13.19-111.03,39.61-146.39,26.4-35.36,
                    66-64.11,118.81-86.28-75.44-54.21-113.15-127.53-113.15-219.94,0-79.67,26.87-143.8,80.62-192.36,53.75-48.56,127.76-72.84,
                    222.06-72.84,84.87,0,151.7,23.81,200.49,71.43,48.8,47.63,73.2,108.2,73.2,181.75,0,77.33-29.7,145.93-89.11,205.8l100.81,
                    182.25,155.91-100.22Zm-376.61,242.11l-92.25-154.43c-35.38,32.07-53.06,66.72-53.06,103.97,0,26.87,5.53,47.03,16.58,60.47,
                    11.05,13.44,27.39,20.16,49.03,20.16,23.04,0,49.6-10.06,79.71-30.17Zm-63.98-442.25c32.06-42.12,48.09-82.35,48.09-120.68,
                    0-16.56-4.72-31.71-14.14-45.44-9.44-13.73-22.63-20.59-39.61-20.59-15.56,0-27.35,5.22-35.36,15.63-8.02,10.41-12.02,26.27-12.02,
                    47.56,0,44.49,17.68,85.66,53.04,123.52Z" /></svg>
            </div>,
            html: `<div x-objectcode="amperstand" id="{id}" title="Amperstand"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                <path d="m924.55,581.02v193.07l-79.66,50.94,99.82,190.93h-264.53l-34.36-63.59c-88.62,58.43-172.54,87.64-251.72,
                87.64s-146.85-27.58-191.62-82.74c-44.79-55.16-67.17-121.64-67.17-199.43,0-62.24,13.19-111.03,39.61-146.39,26.4-35.36,
                66-64.11,118.81-86.28-75.44-54.21-113.15-127.53-113.15-219.94,0-79.67,26.87-143.8,80.62-192.36,53.75-48.56,127.76-72.84,
                222.06-72.84,84.87,0,151.7,23.81,200.49,71.43,48.8,47.63,73.2,108.2,73.2,181.75,0,77.33-29.7,145.93-89.11,205.8l100.81,
                182.25,155.91-100.22Zm-376.61,242.11l-92.25-154.43c-35.38,32.07-53.06,66.72-53.06,103.97,0,26.87,5.53,47.03,16.58,60.47,
                11.05,13.44,27.39,20.16,49.03,20.16,23.04,0,49.6-10.06,79.71-30.17Zm-63.98-442.25c32.06-42.12,48.09-82.35,48.09-120.68,
                0-16.56-4.72-31.71-14.14-45.44-9.44-13.73-22.63-20.59-39.61-20.59-15.56,0-27.35,5.22-35.36,15.63-8.02,10.41-12.02,26.27-12.02,
                47.56,0,44.49,17.68,85.66,53.04,123.52Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "At Sign",
            code: "at_sign",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m755.7,239.65l-73.5,458.33c-1.55,10.04-2.32,16.78-2.32,20.25,0,15.05,7.13,22.57,21.41,22.57,23.15,0,48.32-11.09,
                    75.52-33.28,27.2-22.18,50.93-55.07,71.18-98.67,20.25-43.59,30.38-94.52,30.38-152.78,
                    0-98.38-29.61-177.95-88.83-238.72-59.23-60.76-136.86-91.15-232.93-91.15-111.5,0-203.51,38.48-276.04,115.45-72.54,76.97-108.8,
                    179.69-108.8,308.16s35.39,222.13,106.19,294.85c70.79,72.73,160.97,109.09,270.54,109.09,54.01,0,105.32-9.06,153.93-27.2,48.61-18.13,
                    94.71-45.14,138.31-81.02h121.53c-50.16,64.05-112.37,112.47-186.63,145.26-74.27,32.79-149.79,49.19-226.56,49.19-131.56,
                    0-241.61-44.46-330.15-133.39-88.54-88.92-132.81-206.11-132.81-351.56s41.28-266.2,123.84-365.74c82.56-99.54,197.34-149.31,
                    344.33-149.31,87.57,0,159.14,16.88,214.7,50.64,55.56,33.76,101.94,80.83,139.18,141.2,37.23,60.38,55.85,135.13,55.85,224.25,
                    0,118.44-32.99,211.71-98.96,279.8-65.97,68.1-132.14,102.14-198.5,102.14-49.77,0-80.06-22.37-90.86-67.13-26.24,25.46-48.9,
                    43.11-68,52.95s-41.96,14.76-68.58,14.76c-57.49,0-103.59-22.37-138.31-67.13-34.72-44.75-52.08-104.55-52.08-179.4,0-96.44,
                    24.21-182.19,72.63-257.23,48.41-75.03,107.35-112.56,176.79-112.56,27.78,0,52.85,6.94,75.23,20.83,22.37,13.89,41.86,34.72,
                    58.45,62.5l9.26-65.97h114.58Zm-386.57,385.42c0,36.27,7.71,66.94,23.15,92.01,15.43,25.08,36.07,37.61,61.92,37.61s47.16-9.84,
                    66.26-29.51c19.1-19.68,36.75-60.09,52.95-121.24,16.2-61.14,24.31-119.5,24.31-175.06,
                    0-34.72-7.33-62.79-21.99-84.2-14.67-21.41-34.53-32.12-59.61-32.12-47.07,0-83.33,33.09-108.8,99.25-25.46,66.17-38.2,137.25-38.2,213.25Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="at_sign" id="{id}" title="At Sign"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                <path d="m755.7,239.65l-73.5,458.33c-1.55,10.04-2.32,16.78-2.32,20.25,0,15.05,7.13,22.57,21.41,22.57,23.15,0,48.32-11.09,
                75.52-33.28,27.2-22.18,50.93-55.07,71.18-98.67,20.25-43.59,30.38-94.52,30.38-152.78,
                0-98.38-29.61-177.95-88.83-238.72-59.23-60.76-136.86-91.15-232.93-91.15-111.5,0-203.51,38.48-276.04,115.45-72.54,76.97-108.8,
                179.69-108.8,308.16s35.39,222.13,106.19,294.85c70.79,72.73,160.97,109.09,270.54,109.09,54.01,0,105.32-9.06,153.93-27.2,48.61-18.13,
                94.71-45.14,138.31-81.02h121.53c-50.16,64.05-112.37,112.47-186.63,145.26-74.27,32.79-149.79,49.19-226.56,49.19-131.56,
                0-241.61-44.46-330.15-133.39-88.54-88.92-132.81-206.11-132.81-351.56s41.28-266.2,123.84-365.74c82.56-99.54,197.34-149.31,
                344.33-149.31,87.57,0,159.14,16.88,214.7,50.64,55.56,33.76,101.94,80.83,139.18,141.2,37.23,60.38,55.85,135.13,55.85,224.25,
                0,118.44-32.99,211.71-98.96,279.8-65.97,68.1-132.14,102.14-198.5,102.14-49.77,0-80.06-22.37-90.86-67.13-26.24,25.46-48.9,
                43.11-68,52.95s-41.96,14.76-68.58,14.76c-57.49,0-103.59-22.37-138.31-67.13-34.72-44.75-52.08-104.55-52.08-179.4,0-96.44,
                24.21-182.19,72.63-257.23,48.41-75.03,107.35-112.56,176.79-112.56,27.78,0,52.85,6.94,75.23,20.83,22.37,13.89,41.86,34.72,
                58.45,62.5l9.26-65.97h114.58Zm-386.57,385.42c0,36.27,7.71,66.94,23.15,92.01,15.43,25.08,36.07,37.61,61.92,37.61s47.16-9.84,
                66.26-29.51c19.1-19.68,36.75-60.09,52.95-121.24,16.2-61.14,24.31-119.5,24.31-175.06,
                0-34.72-7.33-62.79-21.99-84.2-14.67-21.41-34.53-32.12-59.61-32.12-47.07,0-83.33,33.09-108.8,99.25-25.46,66.17-38.2,137.25-38.2,213.25Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Percent",
            code: "percent",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m493.9,222.63v165.38c0,43.23-4.07,75.65-12.2,97.26-8.13,21.62-25.58,40.45-52.35,56.52-26.77,16.06-62.17,24.09-106.19,
                    24.09-50.77,0-89.44-7.63-116-22.9-26.58-15.26-44.52-33.61-53.84-55.03-9.32-21.42-13.98-52.15-13.98-92.21v-173.11c0-47.59,4.85-82.78,
                    14.57-105.59,9.71-22.8,28.45-41.34,56.22-55.62,27.76-14.28,62.06-21.42,102.92-21.42,45.21,0,81.89,7.84,110.05,23.5,28.15,15.67,47,
                    34.6,56.51,56.81,9.52,22.22,14.28,56.32,14.28,102.32Zm-152.29-42.24c0-25.38-1.29-41.05-3.87-47-2.58-5.95-9.62-8.92-21.12-8.92-10.71,
                    0-17.65,3.18-20.82,9.52-3.18,6.35-4.76,21.82-4.76,46.4v242.71c0,27.77,1.39,44.42,4.16,49.97,2.77,5.56,10.11,8.33,22.01,8.33,10.31,0,
                    16.96-3.17,19.93-9.52,2.98-6.34,4.46-21.21,4.46-44.62v-246.88ZM774.09,40l-376.56,1000h-91.61L683.76,40h90.33Zm166.57,647.83v165.97c0,
                    42.83-4.07,75.16-12.19,96.97-8.13,21.82-25.58,40.75-52.35,56.81-26.77,16.06-62.17,24.09-106.19,24.09-50.37,
                    0-88.93-7.73-115.71-23.2-26.77-15.47-44.82-33.91-54.13-55.33-9.32-21.42-13.98-51.95-13.98-91.61v-173.71c0-47.19,4.85-82.19,14.57-105,
                    9.71-22.8,28.45-41.44,56.22-55.92,27.75-14.47,62.06-21.71,102.92-21.71,45.6,0,82.48,7.94,110.65,23.8,28.15,15.87,46.9,34.9,56.22,57.11,
                    9.31,22.22,13.98,56.12,13.98,101.73Zm-152.29-41.64c0-25.38-1.29-41.14-3.87-47.29-2.58-6.14-9.62-9.22-21.12-9.22-10.71,0-17.55,3.18-20.52,
                    9.52-2.98,6.35-4.46,22.01-4.46,47v242.71c0,27.37,1.39,43.83,4.17,49.38,2.77,5.56,9.91,8.33,21.42,8.33,10.71,0,17.45-3.07,20.23-9.22,
                    2.77-6.14,4.17-21.12,4.17-44.91v-246.28Z" /></svg>
            </div>,
            html: `<div x-objectcode="percent" id="{id}" title="Percent"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                <path d="m493.9,222.63v165.38c0,43.23-4.07,75.65-12.2,97.26-8.13,21.62-25.58,40.45-52.35,56.52-26.77,16.06-62.17,24.09-106.19,
                24.09-50.77,0-89.44-7.63-116-22.9-26.58-15.26-44.52-33.61-53.84-55.03-9.32-21.42-13.98-52.15-13.98-92.21v-173.11c0-47.59,4.85-82.78,
                14.57-105.59,9.71-22.8,28.45-41.34,56.22-55.62,27.76-14.28,62.06-21.42,102.92-21.42,45.21,0,81.89,7.84,110.05,23.5,28.15,15.67,47,
                34.6,56.51,56.81,9.52,22.22,14.28,56.32,14.28,102.32Zm-152.29-42.24c0-25.38-1.29-41.05-3.87-47-2.58-5.95-9.62-8.92-21.12-8.92-10.71,
                0-17.65,3.18-20.82,9.52-3.18,6.35-4.76,21.82-4.76,46.4v242.71c0,27.77,1.39,44.42,4.16,
                49.97,2.77,5.56,10.11,8.33,22.01,8.33,10.31,0,16.96-3.17,19.93-9.52,2.98-6.34,4.46-21.21,4.46-44.62v-246.88ZM774.09,40l-376.56,1000h-91.61L683.76,
                40h90.33Zm166.57,647.83v165.97c0,42.83-4.07,75.16-12.19,96.97-8.13,21.82-25.58,40.75-52.35,56.81-26.77,16.06-62.17,24.09-106.19,24.09-50.37,
                0-88.93-7.73-115.71-23.2-26.77-15.47-44.82-33.91-54.13-55.33-9.32-21.42-13.98-51.95-13.98-91.61v-173.71c0-47.19,4.85-82.19,14.57-105,9.71-22.8,
                28.45-41.44,56.22-55.92,27.75-14.47,62.06-21.71,102.92-21.71,45.6,0,82.48,7.94,110.65,23.8,28.15,15.87,46.9,34.9,56.22,57.11,9.31,22.22,
                13.98,56.12,13.98,101.73Zm-152.29-41.64c0-25.38-1.29-41.14-3.87-47.29-2.58-6.14-9.62-9.22-21.12-9.22-10.71,0-17.55,3.18-20.52,9.52-2.98,6.35-4.46,22.01-4.46,47v242.71c0,27.37,1.39,43.83,4.17,49.38,2.77,5.56,9.91,8.33,21.42,8.33,10.71,0,17.45-3.07,20.23-9.22,
                2.77-6.14,4.17-21.12,4.17-44.91v-246.28Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Pound",
            code: "pound",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m120.68,305.24h215.82l55.66-265.24h130.88l-55.28,265.24h244.63l55.11-265.24h130.89l-54.93,265.24h115.87v131.58h-143.1l-43.31,
                    209.14h186.42v130.89h-213.51l-54.49,263.16h-131.58l54.68-263.16h-244.98l-54.85,
                    263.16h-132.27l55.22-263.16h-116.85v-130.89h144.33l43.89-209.14H120.68v-131.58Zm319.64,131.58l-43.6,209.14h244.88l43.45-209.14h-244.73Z" /></svg>
            </div>,
            html: `<div x-objectcode="pound" id="{id}" title="Pound"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                    <path d="m120.68,305.24h215.82l55.66-265.24h130.88l-55.28,265.24h244.63l55.11-265.24h130.89l-54.93,265.24h115.87v131.58h-143.1l-43.31,
                    209.14h186.42v130.89h-213.51l-54.49,263.16h-131.58l54.68-263.16h-244.98l-54.85,
                    263.16h-132.27l55.22-263.16h-116.85v-130.89h144.33l43.89-209.14H120.68v-131.58Zm319.64,131.58l-43.6,209.14h244.88l43.45-209.14h-244.73Z" />
                </svg>
            </div>`,
            defaultCss: ""
        },
        {
            name: "Astrix",
            code: "astrix",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 810">
                    <path d="m608.22,277.68v170.29l156.54-53.94,47.6,124.81-156.54,65.58,98.37,139.62-105.77,78.27-106.83-140.68-104.72,
                    140.68-111.06-78.27,99.43-144.91-157.6-60.29,45.48-124.81,161.83,53.94v-170.29h133.27Z" /></svg>
            </div>,
            html: `<div x-objectcode="astrix" id="{id}" title="Astrix"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 810" pointer-events="none">
                        <path d="m608.22,277.68v170.29l156.54-53.94,47.6,124.81-156.54,65.58,98.37,139.62-105.77,78.27-106.83-140.68-104.72,
                    140.68-111.06-78.27,99.43-144.91-157.6-60.29,45.48-124.81,161.83,53.94v-170.29h133.27Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Trademark",
            code: "trademark",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m328.78,706.66v-275.52h-102.96v-57.79h270.64v57.79h-100.12v275.52h-67.56Zm192.5,0v-333.31h105.43l60.82,229.53,
                    60.74-229.53h105.91v333.31h-64.3v-265.75l-72.29,265.75h-60.94l-71.88-265.75v265.75h-63.49Z" /></svg>
            </div>,
            html: `<div x-objectcode="trademark" id="{id}" title="Trademark"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m328.78,706.66v-275.52h-102.96v-57.79h270.64v57.79h-100.12v275.52h-67.56Zm192.5,0v-333.31h105.43l60.82,229.53,
                    60.74-229.53h105.91v333.31h-64.3v-265.75l-72.29,265.75h-60.94l-71.88-265.75v265.75h-63.49Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Copyright",
            code: "copyright",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m531.8,225.82c95.16,0,172.68,31.45,232.56,94.34,59.88,62.9,89.83,136.17,89.83,219.85s-30.76,160.37-92.29,
                    221.9c-61.52,61.52-135.49,92.29-221.9,92.29s-161.06-30.69-222.31-92.08c-61.26-61.38-91.88-135.42-91.88-222.1,0-59.61,15.52-114.43,
                    46.55-164.47,31.03-50.04,70.89-87.5,119.56-112.38,48.67-24.88,95.29-37.32,139.86-37.32Zm14.77,61.93c-75.47,0-137.47,25.02-186.01,
                    75.06-48.54,50.04-72.8,109.1-72.8,177.19s24.67,128.52,74.03,178.01c49.35,49.49,108.76,74.24,178.21,74.24s128.85-24.74,
                    178.21-74.24c49.35-49.49,74.03-108.83,74.03-178.01s-25.5-132.28-76.5-180.27c-51-47.99-107.4-71.98-169.19-71.98Zm69.73,
                    298.6l66.04,22.56c-22.15,71.1-68.5,106.64-139.04,106.64-47.85,0-86.07-15.79-114.64-47.37-28.58-31.58-42.86-73.76-42.86-126.53s14.49-97.27,
                    43.48-128.59c28.98-31.31,66.45-46.96,112.38-46.96,70,0,116.9,33.22,140.68,99.67l-66.45,15.59c-14.22-38-38.55-57.01-73.01-57.01-27.35,
                    0-48.33,10.32-62.96,30.97-14.63,20.65-21.94,47.92-21.94,81.83,0,38.01,7.04,67.47,21.12,88.39,14.08,20.92,33.97,31.38,59.68,31.38,36.91,
                    0,62.75-23.51,77.52-70.55Z" /></svg>
            </div>,
            html: `<div x-objectcode="copyright" id="{id}" title="Copyright"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m531.8,225.82c95.16,0,172.68,31.45,232.56,94.34,59.88,62.9,89.83,136.17,89.83,219.85s-30.76,160.37-92.29,
                    221.9c-61.52,61.52-135.49,92.29-221.9,92.29s-161.06-30.69-222.31-92.08c-61.26-61.38-91.88-135.42-91.88-222.1,0-59.61,15.52-114.43,
                    46.55-164.47,31.03-50.04,70.89-87.5,119.56-112.38,48.67-24.88,95.29-37.32,139.86-37.32Zm14.77,61.93c-75.47,0-137.47,25.02-186.01,
                    75.06-48.54,50.04-72.8,109.1-72.8,177.19s24.67,128.52,74.03,178.01c49.35,49.49,108.76,74.24,178.21,74.24s128.85-24.74,
                    178.21-74.24c49.35-49.49,74.03-108.83,74.03-178.01s-25.5-132.28-76.5-180.27c-51-47.99-107.4-71.98-169.19-71.98Zm69.73,
                    298.6l66.04,22.56c-22.15,71.1-68.5,106.64-139.04,106.64-47.85,0-86.07-15.79-114.64-47.37-28.58-31.58-42.86-73.76-42.86-126.53s14.49-97.27,
                    43.48-128.59c28.98-31.31,66.45-46.96,112.38-46.96,70,0,116.9,33.22,140.68,99.67l-66.45,15.59c-14.22-38-38.55-57.01-73.01-57.01-27.35,
                    0-48.33,10.32-62.96,30.97-14.63,20.65-21.94,47.92-21.94,81.83,0,38.01,7.04,67.47,21.12,88.39,14.08,20.92,33.97,31.38,59.68,31.38,36.91,
                    0,62.75-23.51,77.52-70.55Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Registered",
            code: "registered",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m531.8,225.82c93.79,0,170.97,31.17,231.53,93.52,60.56,62.34,90.85,135.9,90.85,220.67s-30.76,160.37-92.29,221.9c-61.52,
                    61.52-135.49,92.29-221.9,92.29s-161.06-30.69-222.31-92.08c-61.26-61.38-91.88-135.42-91.88-222.1,0-60.97,16.2-116.96,48.6-167.96,
                    32.4-50.99,72.94-88.11,121.61-111.36,48.67-23.24,93.93-34.86,135.76-34.86Zm15.59,61.11c-77.11,0-139.59,25.43-187.44,76.29-47.85,
                    50.86-71.78,109.79-71.78,176.78s24.74,128.93,74.24,178.42c49.49,49.5,108.96,74.24,178.42,74.24s128.52-24.74,178.01-74.24c49.49-49.49,
                    74.24-108.96,74.24-178.42,0-47.85-12.45-91.94-37.32-132.28-24.89-40.33-56.88-70.55-95.98-90.65-39.11-20.1-76.57-30.15-112.38-30.15Zm-145.61,
                    420.82v-333.46h157.91c39.1,0,67.53,9.09,85.31,27.28,17.77,18.19,26.66,40.54,26.66,67.06s-7.45,46.35-22.35,61.93c-14.91,15.59-37.39,
                    26.12-67.47,31.58,13.67,5.2,24.54,12.24,32.61,21.12,8.06,8.89,20.85,28.1,38.35,57.63l38.97,
                    66.86h-83.26l-27.89-53.73c-16.68-31.99-31.38-53.79-44.09-65.42-12.72-11.62-29.33-17.43-49.83-17.43h-17.64v136.58h-67.27Zm67.27-189.49h54.96c27.62,
                    0,47.23-3.62,58.86-10.87,11.62-7.24,17.43-19.07,17.43-35.48,0-14.77-5.74-25.84-17.23-33.22-11.48-7.38-28.85-11.08-52.09-11.08h-61.93v90.65Z" /></svg>
            </div>,
            html: `<div x-objectcode="registered" id="{id}" title="Registered"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m531.8,225.82c93.79,0,170.97,31.17,231.53,93.52,60.56,62.34,90.85,135.9,90.85,220.67s-30.76,160.37-92.29,221.9c-61.52,
                    61.52-135.49,92.29-221.9,92.29s-161.06-30.69-222.31-92.08c-61.26-61.38-91.88-135.42-91.88-222.1,0-60.97,16.2-116.96,48.6-167.96,
                    32.4-50.99,72.94-88.11,121.61-111.36,48.67-23.24,93.93-34.86,135.76-34.86Zm15.59,61.11c-77.11,0-139.59,25.43-187.44,76.29-47.85,
                    50.86-71.78,109.79-71.78,176.78s24.74,128.93,74.24,178.42c49.49,49.5,108.96,74.24,178.42,74.24s128.52-24.74,178.01-74.24c49.49-49.49,
                    74.24-108.96,74.24-178.42,0-47.85-12.45-91.94-37.32-132.28-24.89-40.33-56.88-70.55-95.98-90.65-39.11-20.1-76.57-30.15-112.38-30.15Zm-145.61,
                    420.82v-333.46h157.91c39.1,0,67.53,9.09,85.31,27.28,17.77,18.19,26.66,40.54,26.66,67.06s-7.45,46.35-22.35,61.93c-14.91,15.59-37.39,
                    26.12-67.47,31.58,13.67,5.2,24.54,12.24,32.61,21.12,8.06,8.89,20.85,28.1,38.35,57.63l38.97,
                    66.86h-83.26l-27.89-53.73c-16.68-31.99-31.38-53.79-44.09-65.42-12.72-11.62-29.33-17.43-49.83-17.43h-17.64v136.58h-67.27Zm67.27-189.49h54.96c27.62,
                    0,47.23-3.62,58.86-10.87,11.62-7.24,17.43-19.07,17.43-35.48,0-14.77-5.74-25.84-17.23-33.22-11.48-7.38-28.85-11.08-52.09-11.08h-61.93v90.65Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "USA Dollar",
            code: "usa_dollar",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m779.44,358.73h-210.75v-34.95c0-37.21-2.44-61.56-7.3-73.03-4.87-11.48-15.31-17.21-31.3-17.21-12.87,0-22.52,4.95-28.95,14.87-6.44,
                    9.91-9.65,24.78-9.65,44.6,0,33.04,6.6,56.17,19.82,69.38,12.86,13.22,50.77,38.95,113.72,77.2,53.55,32.34,90.07,57.12,109.55,74.34,19.47,17.21,
                    35.9,41.56,49.3,73.03,13.38,31.48,20.08,70.68,20.08,117.63,0,75.12-18.09,134.06-54.25,176.84-36.17,42.77-90.42,69.21-162.76,
                    79.29v79.29h-97.03v-81.9c-56.34-5.56-105.55-27.21-147.63-64.94-42.08-37.73-63.12-103.55-63.12-197.44v-41.21h210.75v51.64c0,56.69,2.17,91.9,6.52,
                    105.63,4.34,13.74,14.87,20.61,31.56,20.61,14.26,0,24.86-4.78,31.82-14.35,6.95-9.56,10.43-23.73,10.43-42.51,
                    0-47.29-3.31-81.12-9.91-101.46-6.61-20.34-29.21-42.51-67.81-66.51-64.34-40.34-108.07-69.98-131.19-88.94-23.13-18.95-43.13-45.64-59.99-80.07-16.87-34.43-25.3-73.37-25.3-116.85,
                    0-62.94,17.82-112.33,53.47-148.15,35.64-35.81,89.11-57.9,160.41-66.25V40h97.03v67.29c65.03,8.35,113.72,30.17,146.06,65.47,32.34,35.3,48.51,84.07,48.51,
                    146.32,0,8.7-.7,21.91-2.09,39.65Z" /></svg>
            </div>,
            html: `<div x-objectcode="usa_dollar" id="{id}" title="USA Dollar"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m779.44,358.73h-210.75v-34.95c0-37.21-2.44-61.56-7.3-73.03-4.87-11.48-15.31-17.21-31.3-17.21-12.87,0-22.52,4.95-28.95,14.87-6.44,
                    9.91-9.65,24.78-9.65,44.6,0,33.04,6.6,56.17,19.82,69.38,12.86,13.22,50.77,38.95,113.72,77.2,53.55,32.34,90.07,57.12,109.55,74.34,19.47,17.21,
                    35.9,41.56,49.3,73.03,13.38,31.48,20.08,70.68,20.08,117.63,0,75.12-18.09,134.06-54.25,176.84-36.17,42.77-90.42,69.21-162.76,
                    79.29v79.29h-97.03v-81.9c-56.34-5.56-105.55-27.21-147.63-64.94-42.08-37.73-63.12-103.55-63.12-197.44v-41.21h210.75v51.64c0,56.69,2.17,91.9,6.52,
                    105.63,4.34,13.74,14.87,20.61,31.56,20.61,14.26,0,24.86-4.78,31.82-14.35,
                    6.95-9.56,10.43-23.73,10.43-42.51,0-47.29-3.31-81.12-9.91-101.46-6.61-20.34-29.21-42.51-67.81-66.51-64.34-40.34-108.07-69.98-131.19-88.94-23.13-18.95-43.13-45.64-59.99-80.07-16.87-34.43-25.3-73.37-25.3-116.85,
                    0-62.94,17.82-112.33,53.47-148.15,35.64-35.81,89.11-57.9,160.41-66.25V40h97.03v67.29c65.03,8.35,113.72,30.17,146.06,65.47,32.34,35.3,48.51,84.07,48.51,
                    146.32,0,8.7-.7,21.91-2.09,39.65Z" />
                    </svg>
                </div>`,
            defaultCss: ""

        },
        {
            name: "Arrow Left",
            code: "arrow_left",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m1040,328.26H454.42v-147.08c0-4.46-414.42,357.22-414.42,357.22l414.42,360.47v-147.12h585.58v-423.48Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="arrow_left" id="{id}" title="Arrow Left"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m1040,328.26H454.42v-147.08c0-4.46-414.42,357.22-414.42,357.22l414.42,360.47v-147.12h585.58v-423.48Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Arrow Right",
            code: "arrow_right",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m40,751.74h585.58v147.08c0,4.46,414.42-357.22,414.42-357.22l-414.42-360.47v147.12H40v423.48Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="arrow_right" id="{id}" title="Arrow Right"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m40,751.74h585.58v147.08c0,4.46,414.42-357.22,414.42-357.22l-414.42-360.47v147.12H40v423.48Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Arrow Up",
            code: "arrow_up",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "37.5px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m751.74,1040V454.42h147.08c4.46,0-357.22-414.42-357.22-414.42L181.14,454.42h147.12v585.58h423.48Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="arrow_up" id="{id}" title="Arrow Up"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m751.74,1040V454.42h147.08c4.46,0-357.22-414.42-357.22-414.42L181.14,454.42h147.12v585.58h423.48Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Arrow Down",
            code: "arrow_down",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "37.5px",
                height: "50px",
                fill: this.DefaultObjectColor,
            }}>
                <svg width="100%" height="100%" viewBox="0 0 1080 1080">
                    <path d="m328.26,40v585.58h-147.08c-4.46,0,357.22,414.42,357.22,414.42l360.47-414.42h-147.12V40h-423.48Z" />
                </svg>
            </div>,
            html: `<div x-objectcode="arrow_down" id="{id}" title="Arrow Down"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">
                    <svg width="100%" height="100%" viewBox="0 0 1080 1080" pointer-events="none">
                        <path d="m328.26,40v585.58h-147.08c-4.46,0,357.22,414.42,357.22,414.42l360.47-414.42h-147.12V40h-423.48Z" />
                    </svg>
                </div>`,
            defaultCss: ""
        },
        {
            name: "Horizontal Line",
            code: "line_horizontal",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                stroke: this.DefaultObjectColor,
                strokeWidth: "5px"
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <svg height="100%" width="100%" pointerEvents="none" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <line vectorEffect="non-scaling-stroke" x1="10" y1="50%" x2="90" y2="50%" />
                    </svg>
                </svg>
            </div>,
            html: `<div x-objectcode="line_horizontal" id="{id}" title="Horizontal Line"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg lc_dt_object_svg_line">
                    <svg height="100%" width="100%" pointer-events="none" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <line vector-effect="non-scaling-stroke" x1="10" y1="50%" x2="90" y2="50%" />
                    </svg>
                </div>`,
            defaultCss: "stroke-width:5;stroke:" + this.DefaultObjectColor + ";"
        },
        {
            name: "Vertical Line",
            code: "line_vertical",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                stroke: this.DefaultObjectColor,
                strokeWidth: "5px"
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <svg height="100%" width="100%" pointerEvents="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vectorEffect="non-scaling-stroke" x1="50%" y1="10" x2="50%" y2="90" />
                    </svg>
                </svg>
            </div>,
            html: `<div x-objectcode="line_vertical" id="{id}" title="Vertical Line"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg lc_dt_object_svg_line">
                    <svg height="100%" width="100%" pointer-events="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vector-effect="non-scaling-stroke" x1="50%" y1="10" x2="50%" y2="90" />
                    </svg>
                </div>`,
            defaultCss: "stroke-width:5;stroke:" + this.DefaultObjectColor + ";"
        },
        {
            name: "Slanted Line (Left)",
            code: "line_slanted_left",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                stroke: this.DefaultObjectColor,
                strokeWidth: "5px"
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <svg height="100%" width="100%" pointerEvents="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vectorEffect="non-scaling-stroke" x1="10" y1="10" x2="90" y2="90" />
                    </svg>
                </svg>
            </div>,
            html: `<div x-objectcode="line_slanted_left" id="{id}" title="Slanted Line (Left)"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg lc_dt_object_svg_line">
                    <svg height="100%" width="100%" pointer-events="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vector-effect="non-scaling-stroke" x1="10" y1="10" x2="90" y2="90" />
                    </svg>
                </div>`,
            defaultCss: "stroke-width:5;stroke:" + this.DefaultObjectColor + ";"
        },
        {
            name: "Slanted Line (Right)",
            code: "line_slanted_right",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "37.5px",
                stroke: this.DefaultObjectColor,
                strokeWidth: "5px"
            }}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <svg height="100%" width="100%" pointerEvents="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vectorEffect="non-scaling-stroke" x1="10" y1="90" x2="90" y2="10" />
                    </svg>
                </svg>
            </div>,
            html: `<div x-objectcode="line_slanted_right" id="{id}" title="Slanted Line (Right)"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg lc_dt_object_svg_line">
                    <svg height="100%" width="100%" pointer-events="none" preserveAspectRatio="none" viewBox="0 0 100 100">
                        <line vector-effect="non-scaling-stroke" x1="10" y1="90" x2="90" y2="10" />
                    </svg>
                </div>`,
            defaultCss: "stroke-width:5;stroke:" + this.DefaultObjectColor + ";"
        },
        {
            name: "Custom SVG",
            code: "custom_svg",
            type: this.ObjectTypes.Svg,
            preview: <div style={{
                width: "50px",
                height: "50px",
                fontSize: "22px",
                textAlign: "center",
                verticalAlign: "center",
                display: "table-cell"
            }}> &lt;svg .....&gt;&lt;/&gt; </div>,
            html: `<div x-objectcode="custom_svg" id="{id}" title="Custom SVG"
                    class="lc_ut_designer lc_dt_element lc_dt_object lc_dt_object_svg">

                    <!-- height and width MUSt be set to 100% -->
                    <!-- Remove preserveAspectRatio="none" to stop the svg from distorting to the container -->
                    <!--Be sure to set the viewBox to the correct values for your shape-->

                    <svg height="100%" width="100%" pointer-events="none" preserveAspectRatio="none" viewBox="0 0 100 100">

                        <!--Replace this rect with Your custom svg paths and stuff here-->
                        <rect width="100" height="100" />

                    </svg>
                </div>`,
            defaultCss: ""
        }
    ]
}

export const InvoiceStatus = {
    open: 0,
    overdue: 1,
    closed: 2,
    void: 3,
    deleted: 4,
    draft: 5,
}

export const InvoicePaymentStatus = {
    pending: 0,
    partial: 1,
    paid: 2,
}

export const PaymentStatus = {
    unknown: 0,
    pending: 1,
    succeeded: 2,
    failed: 3,
}

export const BillingPlanStatus = {
    new: 0,
    pendingStartDate: 1,
    active: 2,
    processing: 3,
    inactive: 4,
}

export const PaymentSourceByClass = {
    'App\\LuCore\\Invoices\\PaymentSourceClassManualCheck': 'Check',
    'App\\LuCore\\Invoices\\PaymentSourceClassStripe': 'Credit Card',
}

export const OpenOOHVenueTypeMaxSupportedLevel = 1;  //0, 1, and 2 are possible

export const InventoryExportCampaignClasses = {
    operatorContract: {
        class: "App\\LuCore\\Campaigns\\OperatorContractCampaignClass",
        code: 'operatorContract',
        title: "Operator Contract",
    },
    lucitProgrammatic: {
        class: "App\\LuCore\\Campaigns\\LucitProgrammaticCampaignClass",
        code: 'lucitProgrammatic',
        title: "Lucit Programmatic",
    },
    digitalBoard: {
        class: "App\\LuCore\\Campaigns\\DigitalBoardCampaignClass",
        code: 'digitalBoard',
        title: "Digital Board",
    },
    group: {
        class: "App\\LuCore\\Campaigns\\GroupCampaignClass",
        code: 'group',
        title: "Group",
    },
    screenGroup: {
        class: "App\\LuCore\\Campaigns\\ScreenGroupCampaignClass",
        code: 'screenGroup',
        title: "Screen Group",
    }
}

export const ProgrammaticMapSettings = {
    defaultZoom: 11,
    defaultCenter: {
        lat: 43.3159,
        lng: -93.05498,
        zoom: 4.410
    }
}

export const ProgrammaticGeoTypes = {
    radius: {
        code: "radius"
    },
    polygon: {
        code: "polygon"
    },
    rectangle: {
        code: "rectangle"
    }
}

export const ProgrammaticGeoSources = {
    google_place: {
        code: "google_place"
    },
    map_drawing: {
        code: "map_drawing"
    }
}

export const ProgrammaticBudgetTypes = {
    daily: {
        code: 'daily',
        name: 'Daily',
        altName: 'Per Day',
        asSuffix: 'per Day',
        budgetParams: {
            min: 5,
            max: 1000,
            step: 5,
            defaultDuration: 14
        }
    },
    weekly: {
        code: 'weekly',
        name: 'Weekly',
        altName: 'Per Week',
        asSuffix: 'per Week',
        budgetParams: {
            min: 50,
            max: 5000,
            step: 50,
            defaultDuration: 4
        }
    },
    lifetime: {
        code: 'lifetime',
        name: 'Lifetime',
        altName: 'Lifetime',
        asSuffix: 'Total',
        budgetParams: {
            min: 100,
            max: 10000,
            step: 100,
        }
    },
}

export const LightningTransitions = {
    fadeInOut: {
        title: 'Fade In/Out',
        css: {
            inactive: { opacity: 0 },
            enter: { opacity: 1, transition: '3000ms ease opacity' },
            active: null,
            leave: { opacity: 0, transition: '3000ms ease opacity' }
        },
        timing: {
            enterDuration: 3000,
            enterDelay: 0,
            leaveDuration: 3000,
            leaveDelay: 0
        }
    },
    slideRight: {
        title: 'Slide Right',
        css: {
            inactive: { transform: 'translateX(-100%)' },
            enter: { transform: 'translateX(0%)', transition: '2000ms ease transform' },
            active: null,
            leave: { transform: 'translateX(100%)', transition: '2000ms ease transform' }
        },
        timing: {
            enterDuration: 2000,
            enterDelay: 0,
            leaveDuration: 2000,
            leaveDelay: 0
        }
    },
    zoomInOut: {
        title: 'Zoom In/Out',
        css: {
            inactive: { transform: 'scale(0)' },
            enter: { transform: 'scale(1.25)', transition: '1000ms ease transform' },
            active: { transform: 'scale(1)', transition: '1000ms ease transform' },
            leave: { transform: 'scale(0)', transition: '1000ms ease transform' }
        },
        timing: {
            enterDuration: 1000,
            enterDelay: 0,
            leaveDuration: 1000,
            leaveDelay: 200
        }
    }
};