import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";
import { ROLES_REQUEST, ROLES_RESULT } from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import rolesTemplate from "./templates/roles";

export const initialState = {
    loading: false,
    agency: {},
    account: {},
    application: {},
}

export const persistConfig = {
    key: 'roles',
    whitelist: ['agency', 'account', 'application'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
}

export default function roles(state = initialState, action) {
    switch (action.type) {
        case ROLES_REQUEST:
        case ROLES_RESULT:
            return Object.assign({},
                state,
                {
                    agency: action.objectClass == LuCoreModelClasses.agencies
                        ? rolesTemplate(state.agency, action)
                        : state.agency,
                    account: action.objectClass == LuCoreModelClasses.accounts
                        ? rolesTemplate(state.account, action)
                        : state.account,
                    application: action.objectClass == LuCoreModelClasses.applications
                        ? rolesTemplate(state.application, action)
                        : state.application,
                });
        default:
            return state
    }
}
