import React from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { RoutePaths, SearchType } from '../../helpers/constants';
import { SearchTypeIcons } from '../../components';

const useStyles = makeStyles(() => ({

    image: {
        width: '100%',
        height: '100%',
    }
}));

function SearchResultListItem(props) {
    const { item, listItemProps = {} } = props;
    const classes = useStyles();

    const groupType = Object.values(SearchType).find(x => x.type == item.searchable_type)
        || SearchType.others;

    return <ListItem
        component={RouterLink}
        to={item.route
            || RoutePaths.public.slug(item.target_slug || item.target_lcuid)}
        disableGutters
        {...listItemProps}
    >
        <ListItemAvatar>
            {item.image_url
                ? <Avatar className={classes.avatar}>
                    <img
                        src={item.image_url}
                        className={classes.image}
                        alt={item.title}
                        title={item.title}
                    />
                </Avatar>
                : <Avatar className={classes.avatar}>
                    {SearchTypeIcons[groupType.type]}
                </Avatar>}
        </ListItemAvatar>
        <ListItemText>
            <Typography component="p" variant="body2" color="primary" noWrap>
                {item.title}
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" noWrap>
                {item.sub_title}
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" noWrap>
                {item.secondary_sub_title}
            </Typography>
        </ListItemText>
    </ListItem>;
}

export default withRouter(SearchResultListItem);
