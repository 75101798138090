import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export function useMouseWheel(action) {
    useEffect(() => {
        let ticking = false;

        function onWheel(e) {
            if (e.ctrlKey)
                e.preventDefault();

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    action(e);
                    ticking = false;
                });

                ticking = true;
            }
        }

        window.addEventListener('wheel', onWheel, { passive: false });
        return () => window.removeEventListener('wheel', onWheel);
    }, [action]);
}
