import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useEffect } from 'react';
import { attachmentsSelector } from '../../selectors/objects';
import FileThumbnail from '../../components/file/FileThumbnail';
import { Grid, makeStyles } from '@material-ui/core';
import { getAttachments } from '../../actions/objects';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';

const useStyles = makeStyles(() => {
    return {
        fileThumbnail: {
        },
        attachmentsCard: {
            borderRadius: '10px',
            margin: '10px 0',
        }
    }
})

const Attachments = (props) => {
    const { lcuid } = props;
    const { attachmentsSelector, getAttachments } = props;
    const classes = useStyles();

    const attachments = attachmentsSelector(lcuid);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getAttachments(lcuid)
            .finally(() => setLoading(false))
    }, [getAttachments])

    if (loading) {
        return <CircularProgressCentered />
    }

    if (!attachments) {
        return null;
    }

    return <>
        {attachments.length > 0 && <IonCard classes={classes.attachmentsCard}>
            <IonCardHeader>
                <IonCardTitle>Attachments</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <Grid container spacing={2}>
                    {attachments.map(a =>
                        <Grid key={a.lcuid} item xs={12} sm={6} md={4} zeroMinWidth>
                            <FileThumbnail
                                className={classes.fileThumbnail}
                                src={a.attachable.options.public_url}
                                fileName={a.attachable.original_filename}
                                fileSize={a.attachable.options.size_in_bytes}
                                user={a.attachable.created_by_user}
                            />
                        </Grid>
                    )}
                </Grid>
            </IonCardContent>
        </IonCard>}
    </>;
};

Attachments.propTypes = {
    lcuid: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,

        attachmentsSelector: lcuid => attachmentsSelector(state)(lcuid)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAttachments: (lcuid) => {
            return dispatch(getAttachments(lcuid));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Attachments);
