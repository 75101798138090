import React from 'react';
import { makeStyles, DialogActions, Button, useMediaQuery, useTheme, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../components/material';
import { CampaignBudgetAllocationTable } from './CampaignBudgetAllocationTable';

const useStyles = makeStyles(() => {
    return {
    }
})

export const CampaignBudgetAllocationDialog = ({ open, handleClose,
    budgetAllocation, budget, budgetType, onChange }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="md"
            scroll="paper"
            open={open}
            onClose={handleClose}
            title={t('Allocate Budget')}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <CampaignBudgetAllocationTable
                    budgetAllocation={budgetAllocation}
                    budget={budget}
                    budgetType={budgetType}
                    onChange={onChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained">
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
