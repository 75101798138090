import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, DialogContent, DialogActions, Button, useMediaQuery, useTheme, AppBar, Tabs, Tab
} from '@material-ui/core';
import { Dialog } from '../../components/material';
import ProofBoard from './ProofBoard';
import { useMemo } from 'react';
import { getFacingCardinal } from '../../selectors/billboard';
import { proofBoardContext } from '../../selectors/proofs';
import { useEffect } from 'react';

const useStyles = makeStyles(() => {
    return {
        boardTabsBar: {
            background: 'white',
        },
        previewXRContent: {
            padding: 0,
            '&:first-child': {
                padding: 0,
            },
        },
    }
})

const ProofLocationDialog = (props) => {
    const { open, handleClose } = props;
    const { proof, location } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const boards = useMemo(() => location
        ? location.board_ids
        : [], [location]);

    const [board, setBoard] = useState(null);

    useEffect(() => {
        setBoard(boards[0]);
    }, [boards.length])

    const boardContext = useMemo(() => board && proofBoardContext(proof, board), [board]);

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            className={classes.proofXRModal}
            classes={{
                paper: classes.paper,
                closeButton: classes.white
            }}
        >
            <DialogContent className={classes.previewXRContent} >
                {boards.length > 1
                    && <AppBar position="sticky" color="transparent" className={classes.boardTabsBar}>
                        <Tabs
                            value={board && board.id}
                            onChange={(_, value) => {
                                setBoard(boards.find(b => b.id === value));
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            {boards.map((x, idx) => {
                                const faceName = getFacingCardinal(x)
                                    ? getFacingCardinal(x) + ' Face'
                                    : 'Face ' + (idx + 1)

                                return <Tab key={x.id} label={faceName} value={x.id} />
                            })}
                        </Tabs>
                    </AppBar>}

                {boardContext
                    && <ProofBoard
                        agency={proof.agency}
                        board={boardContext.board}
                        creative={boardContext.creative}
                        creatives={boardContext.creatives}
                    />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.white}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ProofLocationDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,

    proof: PropTypes.object.isRequired,
    location: PropTypes.object,
}

export default ProofLocationDialog;
