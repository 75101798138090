import { MTableCell } from 'material-table';
import React from 'react';

const ItemCell = props => {
    const { rowData } = props;

    let columnDef = {
        ...props.columnDef,
        cellStyle: typeof props.columnDef.cellStyle == "function"
            ? props.columnDef.cellStyle()
            : props.columnDef.cellStyle
    };

    if (rowData.tableData.isExpanded) {
        columnDef.cellStyle = {
            ...columnDef.cellStyle,
            border: 0
        }
    }

    return <>
        <MTableCell {...props} columnDef={columnDef} />
    </>
};

export default ItemCell;
