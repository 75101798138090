import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function LowercaseIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 1080 1080">
        <path d="M465.2,596.35v243.2h-61.6v-53.6s-41.6,63.2-125.6,63.2c-66.4,0-137.6-46.4-137.6-126.4,0-72,
                63.2-123.2,146.4-123.2,68,0,111.2,30.4,111.2,30.4v-20c0-76.8-64-87.2-93.6-87.2-56,0-110.4,31.2-110.4,31.2l-28-56s57.6-37.6,
                138.4-37.6c108.8,0,160.8,67.2,160.8,136ZM398,733.95v-52s-36.8-26.4-96.8-26.4-93.6,28.8-93.6,67.2c0,48,43.2,67.2,84.8,
                67.2,65.6,0,105.6-56,105.6-56Z" />
        <path d="M947.6,654.75c0,108.8-79.2,194.4-179.2,194.4-88.8,0-124.8-63.2-124.8-63.2v53.6h-61.6V234.74h67.2v280.8s37.6-55.2,
                119.2-55.2c100,0,179.2,85.6,179.2,194.4ZM649.2,722.75s35.2,64,108.8,64,122.4-59.2,122.4-132-48.8-132-122.4-132-108.8,64-108.8,64v136Z" />
    </SvgIcon>
}
