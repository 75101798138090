import { Global } from "./constants";
import { orientationFixer } from "./image";
import Compressor from 'compressorjs'

/**
 * Converts File object to base64 string
 * @export
 * @param {*} file
 * @returns Promise<string> base64 encoded file
 */
export function fileToBase64(file, options = { compressImage: false }) {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result)

            if (options.compressImage) {
                getNormalizedFile(file, options)
                    .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
                    .catch((error) => reject(error))
            } else {
                reader.readAsDataURL(file);
            }
        } catch (error) {
            reject(error)
        }
    })
}

const getNormalizedFile = (file) => {

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            maxWidth: Global.defaultImageMaxDimensions.width,
            maxHeight: Global.defaultImageMaxDimensions.height,
            success(normalizedFile) {
                resolve(normalizedFile)
            },
            error(error) {
                reject(error)
            },
        })
    })
}

export function validateFile(file, options = {}) {
    const { extensions, maxSize, minWidth, minHeight } = options;
    const fileErrors = [];

    // Check for file extension
    if (extensions) {
        const pattern = new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$', 'i');

        if (!pattern.test(file.name)) {
            fileErrors.push({
                type: FileErrors.unsupportedExtension,
                message: `File '${file.name}' extension is not supported`
            });
        }
    }

    // Check for file size
    if (maxSize && file.size > maxSize) {
        fileErrors.push({
            type: FileErrors.sizeTooLarge,
            message: `File size ${formatBytes(file.size, 1)} exceed allowed limit ${formatBytes(maxSize, 1)}`
        });
    }

    // eslint-disable-next-line no-undef
    let promise = Promise.resolve();

    if (minWidth || minHeight) {
        promise = fileToBase64(file, { compressImage: true })
            .then(fileBase64 => createImage(fileBase64))
            .then(image => {
                const isInvalidWidth = minWidth > image.width;
                const isInvalidHeight = minHeight > image.height;

                if (isInvalidHeight || isInvalidWidth) {
                    fileErrors.push({
                        type: FileErrors.imageSizeLessThanMin,
                        message: `Your image is too small`
                            + `${isInvalidWidth ? `, width should be at least ${minWidth}px` : ''}`
                            + `${isInvalidHeight ? `, height should be at least ${minHeight}px` : ''}`
                    });
                }
            });
    }

    return promise.then(() => fileErrors);
}

export async function resizeImage(imageSrc, width, height, imageType = null) {

    const image = await createImage(imageSrc)

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(image, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    if (imageType)
        return canvas.toDataURL(imageType);
    else
        return canvas.toDataURL();
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, imageFile, pixelCrop, rotation = 0, canvasSizeLimits = Global.defaultCanvasSizeLimits) {
    // Apply fix for EXIF on old browsers
    if (orientationFixer.isBrowserImageOrientationNotSupported()) {
        const orientation = await orientationFixer.determineOrientation(imageFile);
        const rotation = orientationFixer.getRotationByOrientationValue(orientation);

        imageSrc = rotation
            ? await getRotatedImage(imageSrc, rotation)
            : imageSrc;
    }

    const image = await createImage(imageSrc, imageFile)
    const canvas = document.createElement('canvas')
    canvas.style.imageOrientation = 'from-image';

    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    if (safeArea > canvasSizeLimits.height) {
        safeArea = canvasSizeLimits.height;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    ctx.fillStyle = '#000000'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    return canvas.toDataURL('image/jpeg');

    // As a blob
    // return new Promise(resolve => {
    //     canvas.toBlob(file => {
    //         resolve(URL.createObjectURL(file))
    //     }, 'image/jpeg')
    // })
}

export async function getRotatedImage(imageSrc, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const orientationChanged =
        rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
    if (orientationChanged) {
        canvas.width = image.height
        canvas.height = image.width
    } else {
        canvas.width = image.width
        canvas.height = image.height
    }

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.drawImage(image, -image.width / 2, -image.height / 2)

    // eslint-disable-next-line no-undef
    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

export function formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function fileExtension(fileName) {
    if (!fileName) {
        return "";
    }

    const parts = fileName.split('.');

    return parts[parts.length - 1];
}

const createImage = (url) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.style.imageOrientation = 'from-image';
        image.src = url
    })
}

export class FileErrors {
    static sizeTooLarge = "FileSizeTooLarge";
    static unsupportedExtension = "FileExtensionNotSupported";

    static imageSizeLessThanMin = "imageSizeLessThanMin";
    static invalidFile = "invalidFile";
}
