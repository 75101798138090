import { apiHttp } from "../helpers/api";

export default class LucitApiCampaigns {
    getEstimateStats(data, options) {
        return apiHttp.post(`/campaigns/estimate-campaign-results`, data,
            {
                _cancelOnPageChange: true,
                ...options
            })
            .then(response => response.data);
    }

    getGroupedBoardCountsDigitalBoardFormats(digital_board_filters, options) {
        return apiHttp.post(`/campaigns/grouped-board-counts/digital-board-formats`, {
            digital_board_filters: digital_board_filters
        }, {
            _cancelOnPageChange: true,
            ...options
        }).then(response => response.data.digital_board_formats);
    }

    getGroupedBoardCountsVenueType(digital_board_filters, options) {
        return apiHttp.post(`/campaigns/grouped-board-counts/open-ooh-venue-types`, {
            digital_board_filters: digital_board_filters
        }, {
            _cancelOnPageChange: true,
            ...options
        }).then(response => response.data.open_ooh_venue_types);
    }

    getGroupedBoardCountsAgencies(digital_board_filters, options) {
        return apiHttp.post(`/campaigns/grouped-board-counts/agencies`, {
            digital_board_filters: digital_board_filters
        }, {
            _cancelOnPageChange: true,
            ...options
        }).then(response => response.data.agencies);
    }

    getAds(data = {}) {
        const { accountId } = data;

        return apiHttp.get(`/campaigns/active-ads`, {
            _cancelOnPageChange: true,
            params: {
                account_id: accountId
            }
        })
            .then(response => response.data.active_ads);
    }

    getBoardSizes(data = {}) {
        const { accountIds, inventoryExportId } = data;

        return apiHttp.get(`/campaigns/board-sizes`, {
            params: {
                account_id: accountIds
                    ? JSON.stringify(accountIds)
                    : null,
                inventory_export_id: inventoryExportId
            }
        })
            .then(response => response.data);
    }

    getApprovals(data = {}) {
        return apiHttp.get(`/campaigns/approvals`, {
            params: {
                page: data.page,
                // perPage * 4 is hack...
                // see https://lch.lcdevops.com/D3507
                per_page: data.perPage * 4,
                approval_status: data.status,
                agency_id: JSON.stringify(data.agencyId)
            }
        })
            .then(response => {
                return ({
                    ...response.data.approvals,
                    total_unique_inventory_item_id_count: response.data.total_unique_inventory_item_id_count
                });
            });
    }

    getApprovalRejectionCodes() {
        return apiHttp.get(`/campaigns/approvals/rejection-codes`)
            .then(response => response.data.rejection_codes);
    }

    setApprovalsActions(approvals) {
        return apiHttp.post(`/campaigns/approvals/set`, {
            approvals: JSON.stringify(approvals)
        })
            .then(response => response.data.approvals);
    }

    makeModerationGood(lcuid) {
        return apiHttp.put(`/campaigns/object-moderations/${lcuid}/make-good`)
            .then(response => response);
    }

    makeModerationBad(lcuid) {
        return apiHttp.put(`/campaigns/object-moderations/${lcuid}/make-bad`)
            .then(response => response);
    }

    autoGenerateCreativesCampaign({ account_lcuid, agency_lcuid, digital_board_formats }) {
        return apiHttp.post(`/campaigns/generate-creatives-campaign`, {
            account_lcuid: account_lcuid,
            agency_lcuid: agency_lcuid,
            digital_board_formats: JSON.stringify(digital_board_formats),
        })
            .then(response => response.data);
    }

    generateProgrammaticCampaign({
        account_lcuid, digital_board_filters, budget_allocation, budget_amount, budget_type, start_date, end_date,
        creative_image_ids
    }) {
        return apiHttp.post(`/campaigns/generate-programmatic-campaign`, {
            account_lcuid: account_lcuid,
            digital_board_filters: digital_board_filters,
            budget_allocation: budget_allocation,
            budget_amount: budget_amount,
            budget_type: budget_type,
            start_date: start_date,
            end_date: end_date,
            creative_image_ids
        })
            .then(response => response.data);
    }

    //agency_lcuid,name
    generateGroupCampaign(data = {}) {
        return apiHttp.post(`/campaigns/generate-group-campaign`, data)
            .then(response => response.data);
    }

    //agency_lcuid,name
    generateScreenGroupCampaign(agency_lcuid, data = {}) {
        return apiHttp.post(`/campaigns/generate-screen-group-campaign`, {
            agency_lcuid: agency_lcuid,
            ...data
        })
            .then(response => response.data);
    }

}
