import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UndoIcon from '@material-ui/icons/Undo';

import TextFieldEditable from '../../../components/inputs/TextFieldEditable';
import { required, maxLength } from '../../../helpers/validators';
import { useState } from 'react';
import { setTitleOverride, clearTitleOverride } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { Global } from '../../../helpers/constants';
import { itemOptions } from '../../../selectors/inventory';

export const useStyles = makeStyles(() => {
    return {
        titleInputRoot: {
            maxWidth: '100%'
        },
        strikethrough: {
            textDecoration: 'line-through'
        },

        undoIcon: {
            marginLeft: '10px'
        }
    }
})

const TitleOverridable = (props) => {
    const { item, handleTitleChange, handleTitleClear } = props;
    const classes = useStyles();

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isClearingTitle, setIsClearingTitle] = useState(false);

    return (
        <>
            <TextFieldEditable
                ViewComponent={<Typography variant="h5">{item.title}</Typography>}
                classes={{
                    root: classes.titleInputRoot
                }}
                label={"Caption"}
                variant={"outlined"}
                value={item.title}
                validators={[required, maxLength(Global.titleMaxLength)]}
                fullWidth={true}
                editButtonProps={{ size: 'medium' }}
                doneButtonProps={{ size: 'medium' }}
                cancelProps={{ size: 'medium' }}
                circularProgressProps={{ size: 40 }}
                handleIsEdit={setIsEditingTitle}
                handleDone={handleTitleChange}
            />
            {itemOptions(item).title_has_override && !isEditingTitle
                && <Box>
                    <Typography className={classes.strikethrough} color="textSecondary" variant="body1">
                        {itemOptions(item).title_upstream}

                        <IconButton className={classes.undoIcon}
                            size="small"
                            disabled={isClearingTitle}
                            onClick={() => {
                                setIsClearingTitle(true);
                                setIsEditingTitle(false)
                                handleTitleClear()
                                    .finally(() => {
                                        setIsClearingTitle(false)
                                    })
                            }}>
                            <UndoIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </Box>}
        </>
    )
}

TitleOverridable.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleTitleChange: title => dispatch(setTitleOverride(props.item.id, title))
            .then(() => dispatch(showSuccess(`Title was successfully saved`)))
            .catch(error => {
                dispatch(showError('Unexpected error happened...'));
                throw error;
            }),

        handleTitleClear: () => {
            return dispatch(clearTitleOverride(props.item.id))
                .then(() => dispatch(showSuccess('Title override was successfully cleared')));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    TitleOverridable
);
