import { Countries, DefaultCountry } from "./countries";

export function capitalize(string) {
    if (!string) {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getInitials(name) {
    return (name || '').split(' ')
        .map(x => x[0])
        .filter(x => Boolean(x) && x.toLowerCase().match(/[a-z]/i))
        .slice(0, 2)
        .join("")
}

export function toCommaString(x) {
    if (isNaN(x)) {
        return '';
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeTrailingSlash(x) {
    return x && x.replace(/\/$/, "")
}

export function isPhoneNumber(x) {
    return /(\d|-|\+|\(|\)| )+$/.test(x);
}

export function clearPhoneNumber(x) {
    return x.replace(/[-,+,(,) ]/g, "")
}

export function parsePhone(phone) {
    const value = {
        countryCode: DefaultCountry.code,
        phoneCode: DefaultCountry.phone,
        phoneNumber: ''
    }

    if (phone == null) {
        return value;
    }

    if (phone.length === 10) {
        value.phoneNumber = phone;
    } else {
        // try to parse country code
        const phoneCodeLength = phone.length - 10;
        const phoneCode = phone.substring(0, phoneCodeLength);

        let country = Countries.find(x => x.phone === phoneCode);

        if (country == null || country.code === 'CA') {
            country = { ...DefaultCountry };
        }

        value.countryCode = country.code;
        value.phoneCode = country.phone;
        value.phoneNumber = phone.substring(phoneCodeLength, phone.length);
    }

    return value;
}

export function parsePath(polygon) {
    return polygon.split(' ').map(s => {
        const [x, y] = s.split(',');
        return { x: parseInt(x, 10), y: parseInt(y, 10) };
    });
}

export function pathToString(path) {
    return path.map(({ x, y }) => x + ',' + y).join(' ');
}

export function formatNumber(number, options) {
    if (isNaN(number)) {
        return '';
    }

    return new Intl.NumberFormat('en-US', options).format(number);
}

export function searchTermInText(term, text) {
    if (!term) {
        return true;
    }

    const textNormalized = Array.isArray(text)
        ? text.join('').toLowerCase()
        : text.toLowerCase();

    // Split the query string into individual terms
    // Check if any of the fields match any of the query terms
    return term.toLowerCase()
        .split(' ')
        .every(term => textNormalized.includes(term));
}
