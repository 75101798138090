import React, { useState, createRef, useMemo, useContext } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, Box,
    useTheme, useMediaQuery, Grid,
    TextField, Step, Stepper, StepLabel,
    Typography,
    MenuItem,
    Divider
} from '@material-ui/core';
import { Dialog, CircularProgressCentered } from '../../components/material';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { useEffect } from 'react';
import { lucitApi } from '../../services/lucitApi';
import { useSelector } from 'react-redux';
import { selectedAgency } from '../../selectors/user';
import VenueTypeDropDown from '../../components/billboards/VenueTypeDropDown';
import { Map } from '../../components/google-maps';
import { Alert } from '@material-ui/lab';
import {
    AGENCY_EXPORTS, LIGHTNING_DEVICE_ADDED,
    LIGHTNING_GROUP_CAMPAIGN_ADDED,
    LIGHTNING_SET_SCREENGROUP_SCREENS
} from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';
import {
    loadAgencyDeviceLayouts, loadAgencyDigitalBoards, loadAgencyLightningDevices
} from '../../actions/lightning';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GoogleMapsContext } from '../../contexts';
import { getVenueIdFromLastAddedDigitalBoard } from '../../selectors/lightning';
import i18next from 'i18next';
import { LightningDeviceLayoutSelectorDialog } from './LightningDeviceLayoutSelectorDialog';
import { loadBoardFormats } from '../../actions/designer';
import { getBestFormatForRatio } from '../../selectors/billboard';
import { InventoryExportCampaignClasses } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
        map: {
            width: '100%',
            height: '360px',
        }
    }
})

const NewDeviceDialog = ({ open, handleClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const agency = useSelector(selectedAgency);
    const shadowAccount = useSelector(state => state.lightning.shadowAccount);

    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [deviceRequest, setDeviceRequest] = useState(null);
    const [device, setDevice] = useState(null);
    const [boardFormats, setBoardFormats] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!shadowAccount || !agency)
            return;

        dispatch(loadAgencyDeviceLayouts(agency.lcuid));
        dispatch(loadAgencyDigitalBoards(agency.id));
        dispatch(loadAgencyLightningDevices(agency.lcuid));
        dispatch(loadBoardFormats(agency.lcuid))
            .then(setBoardFormats);
    }, [shadowAccount, device?.created, agency])

    const steps = useMemo(() => [
        { title: 'Confirm Device Code', nextEnabled: Boolean(deviceRequest) },
        { title: 'Device Details', nextEnabled: Boolean(device) },
        { title: 'Group', nextEnabled: true },
        { title: 'Location', nextEnabled: device?.latitude && device?.latitude },
        { title: 'Done', hideBack: true },
    ], [device, deviceRequest])

    const step = steps[activeStep];

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}
            title={t('New Device')}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                <Stepper activeStep={activeStep} orientation={isXs ? "vertical" : "horizontal"}>
                    {steps.map(s => (
                        <Step key={s.title}>
                            <StepLabel>{t(s.title)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box p={2} pl={4}>
                    {activeStep === 0 && <StepConfirmCode
                        deviceRequest={deviceRequest}
                        onDeviceRequestReady={(deviceRequestResponse) => {
                            setDeviceRequest(deviceRequestResponse);
                            setActiveStep(1);
                        }} />}

                    {activeStep === 1 && <StepSetDeviceDetails
                        boardFormats={boardFormats}
                        device={device}
                        deviceRequest={deviceRequest}
                        onDeviceComplete={(x) => {
                            setDevice({ ...device, ...x });
                        }} />}

                    {activeStep === 2 && <StepSetDeviceGroup
                        device={device}
                        setDevice={setDevice}
                    />}

                    {activeStep === 3 && <StepSetDeviceLocation
                        device={device}
                        deviceRequest={deviceRequest}
                        onDone={x => setDevice({ ...device, ...x })} />}

                    {activeStep === 4 && <StepSetDeviceDone
                        device={device}
                        deviceRequest={deviceRequest}
                        onDeviceCreated={(device) => setDevice({ ...device, created: true })}
                    />}
                </Box>
            </DialogContent>
            <DialogActions>
                {activeStep == 0
                    && !device?.created
                    && <Button onClick={handleClose} color="primary">
                        {t('Cancel')}
                    </Button>}
                {activeStep > 0
                    && !step.hideBack
                    && <Button
                        onClick={() => setActiveStep(activeStep - 1)}
                        variant="outlined">
                        {t('Back')}
                    </Button>}
                {activeStep < steps.length - 1
                    && <Button
                        disabled={!step.nextEnabled}
                        onClick={() => setActiveStep(activeStep + 1)}
                        color="primary"
                        variant="contained">
                        {t('Next')}
                    </Button>}
                {activeStep === steps.length - 1
                    && <Button
                        disabled={!device?.created}
                        onClick={() => handleClose(device)}
                        color="primary"
                        variant="contained">
                        {t('Done')}
                    </Button>}
            </DialogActions>
        </Dialog >
    )
}

const DigitField = ({ digit, place, setDigitForPlace, fieldRef, moveToPlace }) => {
    return <Grid item xs={1} key={place}>
        <TextField
            inputRef={fieldRef}
            autoFocus={place === 0}
            value={digit ?? ""}
            onKeyDown={(e) => {
                if (e.key == "ArrowLeft") {
                    e.preventDefault();
                    moveToPlace(place - 1);
                } else if (e.key == "ArrowRight") {
                    e.preventDefault();
                    moveToPlace(place + 1);
                }
            }}
            onKeyUp={e => {
                if (e.key == "Backspace" && !e.target.value) {
                    moveToPlace(place - 1);
                }
            }}
            onChange={(e) => {
                setDigitForPlace(e.target.value, place)
            }}
            inputProps={{
                maxLength: 1, style: {
                    fontSize: "20px",
                    textAlign: 'center',
                    width: "10px",
                    border: "1px solid #888888",
                    padding: "8px"
                }
            }}
        />
    </Grid>
}

export const DeviceCodeField = ({ defaultValue, onConfirmed }) => {
    const { t } = useTranslation();
    const [digits, setDigits] = useState(defaultValue ?? [null, null, null, null, null, null, null, null, null]);
    const [checkingCode, setCheckingCode] = useState(false);
    const [codeIsValid, setCodeIsValid] = useState(defaultValue != null);

    const has9Digits = digits.filter(digit => digit).length === 9;

    const [inputRefsArray] = useState(() =>
        Array.from({ length: 9 }, () => createRef())
    );

    const moveToPlace = place => {
        inputRefsArray[place]?.current?.focus();
        inputRefsArray[place]?.current?.select();
    }

    const setDigitForPlace = (digit, place) => {
        digits[place] = digit;
        setDigits(digits.slice());

        if (digit) {
            moveToPlace(place + 1);
        } else {
            moveToPlace(place - 1);
        }

        if (digits.every(x => Boolean(x))) {
            setCheckingCode(true);
            lucitApi.lightning.confirmCode(digits.join(''))
                .then(device_request => {
                    setCodeIsValid(true);
                    onConfirmed(device_request);
                }).catch(() => {
                    setCodeIsValid(false);
                }).finally(() => {
                    setCheckingCode(false);
                })
        }
    }

    return <>
        <Box mb={2}>
            <LCTypography variant="body2">
                Enter the 9 digit code that is currently displayed on your devices screen.
            </LCTypography>
        </Box>

        <Box style={{ width: "100%", textAlign: "center" }}>
            <Grid container spacing={2}>
                <DigitField digit={digits[0]} place={0} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[0]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[1]} place={1} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[1]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[2]} place={2} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[2]}
                    moveToPlace={moveToPlace} />
                <Grid item xs={1} style={{ padding: 0 }}>
                    <Box style={{ width: "100%", fontSize: "30px", textAlign: "center", marginTop: "5px" }}>
                        -
                    </Box>
                </Grid>
                <DigitField digit={digits[3]} place={3} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[3]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[4]} place={4} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[4]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[5]} place={5} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[5]}
                    moveToPlace={moveToPlace} />
                <Grid item xs={1} style={{ padding: 0 }}>
                    <Box style={{ width: "100%", fontSize: "30px", textAlign: "center", marginTop: "5px" }}>
                        -
                    </Box>
                </Grid>
                <DigitField digit={digits[6]} place={6} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[6]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[7]} place={7} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[7]}
                    moveToPlace={moveToPlace} />
                <DigitField digit={digits[8]} place={8} setDigitForPlace={setDigitForPlace} fieldRef={inputRefsArray[8]}
                    moveToPlace={moveToPlace} />
            </Grid>
        </Box>

        <Box mt={3}>
            {has9Digits && checkingCode && <>
                <Alert severity="info">{t('Checking code...')}</Alert>
            </>}

            {has9Digits && !checkingCode && codeIsValid && <>
                <Alert severity="success">{t('Code is valid')}</Alert>
            </>}

            {has9Digits && !checkingCode && !codeIsValid && <>
                <Alert severity="error">{t('Code is invalid')}</Alert>
            </>}
        </Box>
    </>;
}

const StepConfirmCode = ({ deviceRequest, onDeviceRequestReady }) => {
    return (<Box style={{ width: "100%", maxWidth: "500px" }} >
        <DeviceCodeField
            defaultValue={deviceRequest?.code
                ? [...deviceRequest.code.toString()]
                : null
            }
            onConfirmed={onDeviceRequestReady}
        />
    </Box>)
}

const ResolutionType = {
    recommended: 'Recommended',
    actual: 'Actual Device Size',
    custom: 'Custom'
}
const StepSetDeviceDetails = ({ boardFormats, device, deviceRequest, onDeviceComplete }) => {
    const deviceLayouts = useSelector((state) => state.lightning.deviceLayouts);
    const lastVenueTypeId = useSelector(getVenueIdFromLastAddedDigitalBoard);

    const { t } = useTranslation();

    const [deviceLayoutsOpen, setDeviceLayoutsOpen] = useState(false);

    const [venueTypeId, setVenueTypeId] = useState(device?.venue_taxonomy_id ?? lastVenueTypeId);
    const [deviceName, setDeviceName] = useState(device?.name);
    const [deviceDescription, setDeviceDescription] = useState(device?.description);
    const [deviceLayoutLcuid, setDeviceLayoutLcuid] = useState(device?.lightning_device_layout_lcuid
        ?? deviceLayouts.find(l => l.is_default)?.lcuid
        ?? deviceLayouts[0]?.lcuid);

    const [resolutionType, setResolutionType] = useState(device?.resolutionType ?? ResolutionType.recommended);

    const bestFormat = getBestFormatForRatio(boardFormats, deviceRequest.width / deviceRequest.height);

    useEffect(() => {
        if (!venueTypeId || !deviceLayoutLcuid || !deviceName)
            return;

        onDeviceComplete({
            venue_taxonomy_id: venueTypeId,
            lightning_device_layout_lcuid: deviceLayoutLcuid,
            name: deviceName,
            description: deviceDescription,

            resolutionType,
            ...(resolutionType == ResolutionType.recommended && bestFormat
                ? bestFormat.size
                : { width: deviceRequest.width, height: deviceRequest.height })
        })
    }, [venueTypeId, deviceLayoutLcuid, deviceName, deviceDescription, resolutionType, bestFormat])

    return <Box width="100%" height="100%"  >
        <TextField
            label={t('Device Name')}
            variant="outlined"
            autoFocus
            fullWidth
            required
            style={{ maxWidth: 600 }}
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
        />
        <TextField
            style={{ marginTop: 16, maxWidth: 600 }}
            label={t('Notes')}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={deviceDescription}
            onChange={(e) => setDeviceDescription(e.target.value)}
        />

        <VenueTypeDropDown
            setVenueId={(venueTypeId) => setVenueTypeId(venueTypeId)}
            initialVenueId={venueTypeId} />

        {bestFormat
            && <TextField
                style={{ marginTop: 16, maxWidth: 600 }}
                label={t('Device Size')}
                variant="outlined"
                fullWidth
                select
                value={resolutionType}
                onChange={(e) => setResolutionType(e.target.value)}
            >
                <MenuItem value={ResolutionType.recommended}>
                    {t('Recommended')} - {bestFormat?.name} ({bestFormat?.size?.width}x{bestFormat?.size?.height})
                </MenuItem>

                <MenuItem value={ResolutionType.actual}>
                    {t('Actual Device Size')} ({deviceRequest.width}x{deviceRequest.height})
                </MenuItem>

                {/* <MenuItem value={ResolutionType.custom}>
                    {t('Custom')}
                </MenuItem> */}
            </TextField>}

        <Box mt={2} display="flex" alignItems="center">
            <Typography>
                <b>{t('Device Layout')}:&nbsp;</b>
                {deviceLayouts.find(l => l.lcuid == deviceLayoutLcuid)?.name ?? '(not-set)'}
            </Typography>
            <Button
                onClick={() => setDeviceLayoutsOpen(true)}
                size="small"
                variant='contained'
                style={{ marginLeft: 8 }}>{t('Change')}</Button>

            <LightningDeviceLayoutSelectorDialog
                open={deviceLayoutsOpen}
                handleClose={() => {
                    setDeviceLayoutsOpen(false);
                }}
                device={deviceRequest}
                value={deviceLayoutLcuid}
                setValue={x => {
                    setDeviceLayoutLcuid(x);
                    setDeviceLayoutsOpen(false);
                }}
            />
        </Box>
    </Box>
}

const StepSetDeviceGroup = ({ device, setDevice }) => {
    const { t } = useTranslation();
    const screenGroups = useSelector(state => state.lightning.agencyExports.filter(
        x => x.campaign_class === InventoryExportCampaignClasses.screenGroup.class
    ));

    const selectedDeviceLayout = useSelector(
        state => state.lightning.deviceLayouts.find(x => x.lcuid == device.lightning_device_layout_lcuid)
    );

    const hasMultipleScreens = selectedDeviceLayout.data.screens.length > 1;

    const [isNew, setIsNew] = useState(false);

    if (hasMultipleScreens) {
        return <Box p={12}>
            <Typography>
                {t("This device has multiple screens, please add groups to this screen after the device has been created")}
            </Typography>
        </Box>
    }

    return <>
        <Typography>{t("Optionally, you can add this device to existing group or create new group:")}</Typography>

        <Box mt={2}>
            <TextField
                style={{ maxWidth: 600 }}
                label={t('Screen Group (optional)')}
                variant="outlined"
                fullWidth
                select
                value={device?.group?.lcuid}
                onChange={(e) => {
                    if (e.target.value === 'new') {
                        setIsNew(true);
                    } else {
                        setIsNew(false);
                        setDevice({
                            ...device,
                            groupName: null,
                            group: screenGroups.find(g => g.lcuid == e.target.value)
                        })
                    }
                }}
            >
                <MenuItem value={'new'}>
                    <i>{t('Create New')}</i>
                </MenuItem>
                <Divider />
                {screenGroups.map(g => (
                    <MenuItem key={g.lcuid} value={g.lcuid}>
                        {g.name}
                    </MenuItem>
                ))}
            </TextField>

            {isNew
                && <TextField
                    label={t('Screen Group Name')}
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    style={{ marginTop: 16, maxWidth: 600 }}
                    value={device?.groupName}
                    onChange={(e) => {
                        setDevice({
                            ...device,
                            group: null,
                            groupName: e.target.value
                        })
                    }}
                />}
        </Box>
    </>
}

export const StepSetDeviceLocation = ({ device, onDone }) => {
    const classes = useStyles();
    const defaultCenter = {
        lat: 46.7957069,
        lng: -100.8271929,
        zoom: 13,
    }

    const [coords, setCoords] = useState({
        latitude: device?.latitude ?? defaultCenter.lat,
        longitude: device?.longitude ?? defaultCenter.lng
    });

    const [locationValue, setLocationValue] = useState(null)
    const [placeId, setPlaceId] = useState(null)
    const [place, setPlace] = useState(null)

    useEffect(() => {
        if (place) {
            setCoords({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            })
        }
    }, [place])

    const locations = useMemo(() => ([{
        latitude: coords?.latitude ?? defaultCenter.lat,
        longitude: coords?.longitude ?? defaultCenter.lng,
        draggable: true,
        targeted: true
    }]), [coords])

    useEffect(() => {
        locationValue && setPlaceId(locationValue.value.place_id)
    }, [locationValue])

    useEffect(() => {
        onDone({
            ...device,
            ...coords
        });
    }, [coords])

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(i18next.t('Places API failed to load: {{Error}}', { Error: gmContext.error }));
        }
    }, [gmContext.error])

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <LCTypography>Places API is currently unavailable</LCTypography>
        </Box>
    }

    return <>
        <Box mb={1}>
            <LCTypography>
                Drag the screen to the <strong>exact</strong> location of this device.
            </LCTypography>
        </Box>
        <Box position="relative">
            <Box position="absolute" top={16} width="100%" zIndex={1}>
                <GooglePlacesAutocomplete
                    apiKey={gmContext.key}
                    selectProps={{
                        autoFocus: true,
                        locationValue,
                        onChange: setLocationValue,
                        placeholder: i18next.t('Search for a location'),
                        styles: {
                            container: (baseStyles) => ({
                                ...baseStyles,
                                padding: '0 12.5%'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                        },
                    }}
                />
            </Box>

            <Map
                classes={{
                    map: classes.map
                }}
                center
                locations={locations}
                placeId={placeId}
                showCenterButton={false}
                showTargetedButton={false}
                extraZoomForPointsOfInterestPlaces={true}
                onNewPlace={(place) => {
                    setPlace(place)
                }}
                onDragEnd={e => {
                    setCoords({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
                }}
            />
        </Box>
    </>
}

const StepSetDeviceDone = ({ device, deviceRequest, onDeviceCreated }) => {
    const [creatingDevice, setCreatingDevice] = useState(false);

    const agency = useSelector(selectedAgency);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const deviceRequestFields = {
            ...device,
            group: null,
            agency_id: agency.id,
        };

        const promises = [
            lucitApi.lightning.generateDevice(deviceRequest.lcuid, deviceRequestFields)
                .then(async device => {
                    dispatch({
                        type: LIGHTNING_DEVICE_ADDED,
                        device: device
                    })

                    return device;
                })
        ];

        if (device.groupName) {
            promises.push(
                lucitApi.campaigns.generateScreenGroupCampaign(agency.lcuid, device.groupName)
                    .then(async (response) => {
                        dispatch({
                            type: LIGHTNING_GROUP_CAMPAIGN_ADDED,
                            groupCampaign: response.inventory_export
                        })

                        const deviceCreated = await promises[0];

                        await lucitApi.agencies.getExports(agency.lcuid)
                            .then(exports => dispatch({ type: AGENCY_EXPORTS, exports }));
                        await lucitApi.exports
                            .setParentExports(response.inventory_export.lcuid,
                                deviceCreated.digital_boards.map(s => s.options.connected_export_lcuid))
                            .then(() => {
                                dispatch({
                                    type: LIGHTNING_SET_SCREENGROUP_SCREENS,
                                    group: response.inventory_export,
                                    screens: deviceCreated.digital_boards,
                                })
                            })
                    })
            );
        }

        if (device.group) {
            promises.push(
                promises[0].then(async deviceCreated => {
                    await lucitApi.agencies.getExports(agency.lcuid)
                        .then(exports => dispatch({ type: AGENCY_EXPORTS, exports }));

                    await lucitApi.exports
                        .setParentExports(device.group.lcuid,
                            deviceCreated.digital_boards.map(s => s.options.connected_export_lcuid))
                        .then(() => {
                            dispatch({
                                type: LIGHTNING_SET_SCREENGROUP_SCREENS,
                                group: device.group,
                                screens: deviceCreated.digital_boards,
                            })
                        })
                })
            );
        }

        setCreatingDevice(true);
        return Promise.all(promises)
            .then(([device]) => {
                onDeviceCreated(device);
            })
            .catch(e => {
                setError(e.message);
                throw e;
            }).finally(() => {
                setCreatingDevice(false);
            });
    }, [])

    return <Box mt={3} mb={4} pl={isXs ? 0 : 6} width="100%" height="100%" >

        {creatingDevice && <CircularProgressCentered />}

        {error && <Alert severity="error">
            {i18next.t('An unknown error occurred trying to create this device, please close this dialog and try again')}
        </Alert>}

        {device?.created && <Box mt={3}>
            <LCTypography>Your new device <strong>{device.name}</strong> has been created!</LCTypography>
        </Box>}
    </Box>
}

export default NewDeviceDialog
