import { makeStyles, DialogContent, DialogActions, useMediaQuery, useTheme, Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonLoader, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { FORM_ADVERTISE } from './AdvertiseForm';
import { Field, isSubmitting, reduxForm, reset, submit } from 'redux-form';
import { lucitApi } from '../../services/lucitApi';
import ImageUploadField from '../../components/inputs/ImageUploadField';
import TextField from '../../components/inputs/TextField';
import i18next from '../../i18n';
import { showError } from '../../actions/snackbar';
import { history } from '../../helpers/history';
import { RoutePaths } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        content: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '600px',
            },
        }
    }
})

const CreateProofDialog = props => {
    const { open,
        handleSubmit,
        submitting,
        billboard,
        onClose,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return <Dialog
        fullScreen={isXs}
        maxWidth="md"
        scroll="paper"
        open={open}
        title={t("Create Proof")}
        onClose={onClose}
    >
        <DialogContent className={classes.content}>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <ImageUploadField
                    name="photo"
                    uploadPromptMessage={t("Click to upload an image to preview on this billboard")}
                    props={{
                        id: "photo",
                        defaultOpen: true,
                        hideButton: true,

                        handleCancel: onClose,

                        ImageUploadDialogProps: {
                            title: `Create Proof for ${billboard.name}`,
                            aspect: billboard.width / billboard.height,
                            UploadInputProps: {
                                minWidth: billboard.width,
                                minHeight: billboard.height
                            },
                            askForCameraOnAndroid: true,
                            autoUpload: false
                        }
                    }}
                />

                <Box mt={3} />

                <Field
                    name="account"
                    component={TextField}
                    props={{
                        label: "Name of Organization, Business or Account",
                        variant: "outlined",
                        disabled: submitting,
                        fullWidth: true,
                        autoFocus: true,
                        id: "account",
                    }}
                />

            </form>

            <Box p={2} fontSize={9}>
                By using this proofing tool, you are agreeing to the terms and conditions
                outlined in our <a href="https://www.lucit.cc/eula">eula</a>
            </Box>
        </DialogContent>
        <DialogActions>
            <ButtonLoader
                submitting={submitting}
                onClick={() => props.submitForm()}
                variant="contained"
                color="secondary">{t('Create')}</ButtonLoader>
        </DialogActions>

    </Dialog>
}

CreateProofDialog.propTypes = {
    billboard: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        submitting: isSubmitting(FORM_ADVERTISE)(state),
        initialValues: {
            isLoggedIn: state.user.isLoggedIn
        }
    }
}

const mapDispatchToProps = (dispatch, props) => {

    return {
        submitForm: () => dispatch(submit(FORM_CREATE_PROOF)),

        onSubmit: (values) => {

            return lucitApi.submitBoardProof({
                image: values.photo.image,
                account_name: values.account,
                digital_board_lcuid: props.billboard.lcuid,
                isLoggedIn: values.isLoggedIn
            }).then(proof => {
                dispatch(reset(FORM_CREATE_PROOF));
                props.onClose();
                history.push({ pathname: RoutePaths.public.slug(proof.lcuid) })
            })
                .catch(error => {
                    dispatch(showError(i18next.t(`Your while creating proof. ${error.response.data.message}`)));
                });
        }
    }
}

export const FORM_CREATE_PROOF = "createProof";

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        enableReinitialize: true,
        form: FORM_CREATE_PROOF, // a unique name for this form
        touchOnBlur: false,
        initialValues: {
            account: ''
        },
        validate: values => {
            if (!values.account) {
                return { account: 'Required' }
            }

            return {}
        }
    })(CreateProofDialog)
);
