import React from 'react';
import { connect } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import Inventory from './Inventory';
import { PageTitle } from '../../components';

import { InventoryItemClasses } from '../../helpers/constants';

const Articles = (props) => {
    const { inventorySearchOpen } = props;

    return <>
        <PageTitle title={"Articles"} />
        <Inventory
            title="Articles"
            noDataLabel={!inventorySearchOpen
                && <LCTypography variant="body1">
                    No Articles Yet
                </LCTypography>}
            inventoryClass={InventoryItemClasses.article}
            scrollToTopFab={false}
            {...props} />
    </>
}

const mapStateToProps = state => {
    return {
        inventorySearchOpen: state.layout.inventorySearch.open,
    }
}

export default connect(
    mapStateToProps
)(Articles);
