import { ReactionClass } from "../helpers/constants";
import { sortDate } from "../helpers/sorters";
import { initialState as usersInitialState } from "../reducers/templates/users";
import { initialState as appsInitialState } from "../reducers/templates/applications";
import { initialState as billingInitialState } from "../reducers/templates/billing";

export const idOrLcuidEquals = (object, idOrLcuid) => object.id == idOrLcuid || object.lcuid == idOrLcuid;

export const commentsSelector = state => (lcuid) => {
    const object = state.objects.byId[lcuid];

    // intentionally return null, so we need to load comments
    return object && object.comments
        ? object.comments.sort((a, b) => sortDate(b.created_at, a.created_at))
        : null;
}

export const likesSelector = state => lcuid => {
    const object = state.objects.byId[lcuid];

    return object
        ? object.reactions
        : [];
}

export const isLiked = state => lcuid => {
    const object = state.objects.byId[lcuid];

    return object
        && object.reactionCurrent
        && object.reactionCurrent.reaction_class == ReactionClass.like;
}

export const attachmentsSelector = state => (lcuid) => {
    const object = state.objects.byId[lcuid];

    // intentionally return null, so we need to load comments
    return object
        ? object.attachments
        : null;
}

export const usersSelector = state => (lcuid) => {
    const object = state.objects.byId[lcuid];

    // intentionally return null, so we need to load comments
    return object
        ? object.users
        : null;
}

export const invitesSelector = state => (lcuid) => {
    const object = state.objects.byId[lcuid];

    // intentionally return null, so we need to load comments
    return object
        ? object.invites
        : null;
}

export const appsSelector = state => (lcuid) => {
    const object = state.objects.byId[lcuid];

    // intentionally return null, so we need to load comments
    return object
        ? object.apps
        : null;
}

export const hasApp = (state, lcuid, appLcuid) => {

    const object = state.objects.byId[lcuid];

    return object
        && object.apps
        && object.apps.find(app => app.lcuid === appLcuid)
}

export const objectSelector = state => (lcuid) => state.objects.byId[lcuid] || {
    exists: false,
    lcuid,
    ...usersInitialState,
    ...appsInitialState,
    ...billingInitialState
};
