import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import LightningDeviceLayout from './LightningDeviceLayout';
import EditDeviceLayoutDialog from './EditDeviceLayoutDialog';
import AddDeviceLayoutButton from './AddDeviceLayoutButton';
import { useSelector } from 'react-redux';
import { CircularProgressCentered } from '../../components';

const DeviceLayouts = () => {

    const deviceLayoutsLoaded = useSelector(state => state.lightning.dataStatus.deviceLayouts);
    const deviceLayouts = useSelector(state => state.lightning.deviceLayouts);

    const [editDeviceLayout, setEditDeviceLayout] = useState(null);

    return <Box p={3}>
        <AddDeviceLayoutButton />

        <Box mt={3}>
            <Grid container spacing={3}>

                {deviceLayouts
                    .map(deviceLayout => {
                        return <Grid item key={deviceLayout.id}>
                            <LightningDeviceLayout
                                deviceLayout={deviceLayout}
                                onDeviceLayoutSelected={() => setEditDeviceLayout(deviceLayout)}
                                showSetAsDefault={true}
                            />
                        </Grid>
                    })}
            </Grid>
            {!deviceLayoutsLoaded && <CircularProgressCentered />}
        </Box>

        {editDeviceLayout && <EditDeviceLayoutDialog
            deviceLayout={editDeviceLayout}
            open={editDeviceLayout !== null}
            handleClose={() => setEditDeviceLayout(null)}
        />}
    </Box>
}

export default DeviceLayouts;
