import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import { duplicateTemplateForItem } from '../../actions/designer';
import { useHistory } from 'react-router';
import { addCircleOutline } from 'ionicons/icons';
import { LuCoreModelClasses } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { inventoryItemClass } from '../../selectors/inventory';
import { ConfirmationDialog } from '../../components/modals';
import { Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { currentUserHasPermission } from '../../selectors/user';


function EditItemTemplateButton(props) {
    const { buttonProps, duplicateTemplateForItem, item } = props;

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const history = useHistory()
    const { t } = useTranslation();

    const canEditTemplates = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editDriveTemplates))

    if (!inventoryItemClass(item).canAssignTempalates)
        return null

    if (!item.drive_template)
        return null

    const templateBelongsToItem = item.drive_template.parent_type === LuCoreModelClasses.inventoryItem.class
        && item.drive_template.parent_id === item.id;

    if (!canEditTemplates)
        return null;

    return <>

        <IonButton
            disabled={!canEditTemplates}
            color="primary"
            size="small"
            onClick={() => {
                templateBelongsToItem ? history.push(`/designer/${item.drive_template.lcuid}`) :
                    setShowConfirmationDialog(true)
            }}
            {...buttonProps}>
            {t('Edit')}
            <IonIcon slot="start" icon={addCircleOutline} />
        </IonButton>

        <ConfirmationDialog
            title={t('Template Edit')}
            dialogProps={{
                open: showConfirmationDialog,
                onClose: () => setShowConfirmationDialog(false),
                keepMounted: false
            }}
            ConfirmButtonText={t('Edit This ad only')}
            ConfirmButtonProps={{
                variant: "contained",
            }}
            Actions={
                <Button
                    onClick={() => history.push(`/designer/${item.drive_template.lcuid}`)}
                    color="secondary"
                    variant="contained"
                >
                    {t('Edit all ads using this template')}
                </Button>

            }
            handleCancel={() => setShowConfirmationDialog(false)}
            handleConfirm={() => {
                duplicateTemplateForItem().then((driveTemplate) => {
                    history.push(`/designer/${driveTemplate.lcuid}`);
                })
            }}>
            <Typography>{t('Would you like to edit just this item, or, all ads using this template?')}</Typography>
        </ConfirmationDialog>

    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        duplicateTemplateForItem: () => dispatch(duplicateTemplateForItem(ownProps.item.lcuid, ownProps.driveTemplateLcuid))
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    EditItemTemplateButton
);
