import React from 'react';
import { Route } from 'react-router-dom';

import Home from './pages/Home';
import { RouteProtected } from './components/routes/RouteProtected';
import { RoutePaths, RoutePathLids, ApplicationFeatures } from './helpers/constants';
import {
    Analytics, Billboard, Contact, ExportDetails, FeedDetails, Feeds, ForgotPassword,
    HomeContact, Slug, CreativeProofPage_old,
    InventoryItem, InventoryItemPhoto, Login, MassEmail, NoInternet,
    Register, UserSettings, AccountSettings, AgencySettings,
    Approvals, AgencyUsers,
    RegistrationNew, RegistrationUserInfo, AccountUsers,
    StoryFeedPublic,
    StoryFeedPrivate,
    AgencyScreens, AgencyCampaigns,
    Designer, DesignerTemplates, AgencyFeed,
    SearchResults, Map, Applications, Application, PublicXRPage,
    AccountBilling, AgencyBilling, Invoice, PublicInvoice, AgencyAccounts, AppsAndData, DesignerElements
} from './pages';
import Billboards from './pages/Campaigns';
import RouteAgency from './components/routes/RouteAgency';
import { Switch } from 'react-router';
import RouteAccount from './components/routes/RouteAccount';
import RouteApplication from './components/routes/RouteApplication';
import ProgrammaticPage from './pages/ProgrammaticPage';
import PublicAppsPage from './pages/PublicAppsPage';
import { Lightning } from './pages/apps';
import ChildAccounts from './pages/account/ChildAccounts';

const Routes = () => {
    return (<Switch>
        {/* Routes for Account profile type */}
        <RouteAccount path={RoutePaths.accountUsers} component={AccountUsers} />
        <RouteAccount path={RoutePaths.accountSettings} component={AccountSettings} />
        <RouteAccount path={RoutePaths.accountBilling} component={AccountBilling} />
        <RouteAccount path={RoutePaths.accountChildren} component={ChildAccounts} />

        {/* These old inventory routes are for backwards compatability */}
        <RouteAccount path={RoutePaths.vehicles} component={AppsAndData} />
        <RouteAccount path={RoutePaths.properties} component={AppsAndData} />
        <RouteAccount path={RoutePaths.creatives} component={AppsAndData} />
        <RouteAccount path={RoutePaths.photoStream} component={AppsAndData} />
        <RouteAccount path={RoutePaths.childCampaign} component={AppsAndData} />
        <RouteAccount path={RoutePaths.realEstateAgents} component={AppsAndData} />
        <RouteAccount path={RoutePaths.articles} component={AppsAndData} />
        <RouteAccount path={RoutePaths.job_posts} component={AppsAndData} />
        <RouteAccount path={RoutePaths.reviews} component={AppsAndData} />
        <RouteAccount path={RoutePaths.socialPosts} component={AppsAndData} />
        <RouteAccount path={RoutePaths.products} component={AppsAndData} />
        <RouteAccount path={RoutePaths.recreationals} component={AppsAndData} />
        <RouteAccount path={RoutePaths.inventory} component={AppsAndData} exact />

        <RouteAccount path={RoutePaths.campaigns} component={Billboards} exact />

        <RouteAccount path={RoutePaths.feeds} component={Feeds} exact />
        <RouteAccount path={RoutePaths.appsAndData} component={AppsAndData} exact />

        {/* Routes for Agency profile type */}
        <RouteAgency path={RoutePaths.agencyAccounts} component={AgencyAccounts} />
        <RouteAgency path={RoutePaths.agencySettings} component={AgencySettings} />
        <RouteAgency path={RoutePaths.agencyCampaigns} component={AgencyCampaigns} />
        <RouteAgency path={RoutePaths.agencyBilling} component={AgencyBilling} />
        <RouteAgency path={RoutePaths.approvals} component={Approvals} />
        <RouteAgency path={RoutePaths.agencyFeed} component={AgencyFeed} />
        <RouteAgency path={RoutePaths.agencyUsers} component={AgencyUsers} />
        <RouteAgency path={RoutePaths.agencyScreens} component={AgencyScreens} exact />
        <RouteAgency path={RoutePaths.appsAndData} component={AppsAndData} exact />

        {/* Routes that don't depend on selected profile type */}
        <RouteProtected path={RoutePaths.analytics} component={Analytics} />
        <RouteProtected path={RoutePaths.home} component={Home} exact />
        <RouteProtected path={RoutePaths.map} component={Map} />
        <RouteProtected path={RoutePaths.feed} component={StoryFeedPrivate} />
        <RouteProtected path={RoutePaths.inventoryPhoto + "/:id"} component={InventoryItemPhoto} />
        <RouteProtected path={RoutePaths.inventory + "/:id"} component={InventoryItem} />
        <RouteProtected path={RoutePathLids.inventoryItem} component={InventoryItem} />
        <RouteProtected path={RoutePaths.exports + "/:id"} component={ExportDetails} />
        <RouteProtected path={RoutePathLids.export} component={ExportDetails} />
        <RouteProtected path={RoutePaths.feeds + "/:id"} component={FeedDetails} />
        <RouteProtected path={RoutePathLids.feed} component={FeedDetails} />
        <RouteProtected path={RoutePaths.settings} component={UserSettings} />
        <RouteProtected path={RoutePaths.help} component={HomeContact} />
        <RouteProtected path={RoutePaths.massEmail + "/:id"} component={MassEmail} />
        <RouteProtected path={RoutePathLids.massEmail} component={MassEmail} />
        <RouteProtected path={RoutePaths.screen()} component={Billboard} />
        <RouteProtected path={RoutePaths.designer} component={Designer} />
        <RouteProtected path={RoutePaths.designerTemplates} component={DesignerTemplates} />
        <RouteProtected path={RoutePaths.dev.applications} component={Applications} exact />
        <RouteProtected path={RoutePaths.dev.application()} component={Application} />

        <RouteProtected path={RoutePaths.dev.designerElements} component={DesignerElements} exact />

        <RouteProtected path={RoutePaths.invoice()} component={Invoice} />

        {/* Routes that require Application to be added to profile */}
        <RouteApplication
            appFeature={ApplicationFeatures.lightning}
            path={RoutePaths.apps.lightning}
            component={Lightning} />

        {/* Public Routes */}
        <Route path={RoutePaths.searchResults} component={SearchResults} />
        <Route path={RoutePaths.login} component={Login} />
        <Route path={RoutePaths.contact} component={Contact} />
        <Route path={RoutePaths.register} component={Register} />
        <Route path={RoutePaths.registrationNew} component={RegistrationNew} exact />
        <Route path={RoutePaths.registrationUserInfo()} component={RegistrationUserInfo} />
        <Route path={RoutePaths.forgotPassword} component={ForgotPassword} />
        <Route path={RoutePaths.noInternet} component={NoInternet} />
        <Route key={Date.now()} path={RoutePaths.public.apps} component={PublicAppsPage} exact />
        <Route key={Date.now()} path={RoutePaths.public.programmatic('')} component={ProgrammaticPage} exact />
        <Route key={Date.now()} path={RoutePaths.public.programmatic()} component={ProgrammaticPage} exact />
        <Route key={Date.now()} path={RoutePaths.public.xr} component={PublicXRPage} exact />
        <Route key={Date.now()} path={RoutePaths.public.xr + "/:slug"} component={PublicXRPage} />
        <Route key={Date.now()} path={RoutePaths.public.feed} component={StoryFeedPublic} />
        <Route key={Date.now()} path={RoutePaths.public.home} component={StoryFeedPublic} />
        <Route key={Date.now()} path={RoutePaths.public.proof()} component={CreativeProofPage_old} />
        <Route key={Date.now()} path={RoutePaths.public.invoice()} component={PublicInvoice} />
        <Route key={Date.now()}
            path={RoutePaths.public.slug()}
            render={(props) => (<Slug key={props.match.params.slug} {...props} />)} />
    </Switch>)
};

export default Routes;
