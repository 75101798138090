import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import NewImageDialog from './NewImageDialog';

function AddImageButton({ board }) {
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);

    return <>
        <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setIsNewDialogOpen(true)}>
            Add
        </Button>

        {isNewDialogOpen
            && <NewImageDialog
                screen={board}
                title={`New Image for ${board && board.name}`}
                open={isNewDialogOpen}
                handleClose={() => setIsNewDialogOpen(false)} />}
    </>
}

export default AddImageButton;
