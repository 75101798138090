import { useRef, useEffect } from "react";

export function useFirstRun() {
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
    });

    return isFirstRun.current;
}
