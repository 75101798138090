/* eslint-disable */
const k = ['_', '_'].join('') + 'd' + 'a' + 't' + 'a'

/**
 * Interceptor to decrypt responses if it's encrypted
 * @export
 * @param {*} instance
 */
export function useDecryptInterceptor(instance) {
    instance.interceptors.response.use(onResponse, _ => Promise.reject(_));
}

export function onResponse(response) {
    // having __data in response mean that it encrypted
    if (response.data[k]) {
        return Promise.resolve({
            ...response,
            data: throw_json_error(response.data[k])
        })
    }

    // eslint-disable-next-line no-undef
    return Promise.resolve(response);
}

var _0x6b7a = ["\x73\x6C\x69\x63\x65", "", "\x72\x65\x70\x6C\x61\x63\x65", "\x6C\x65\x6E\x67\x74\x68", "\x73\x75\x62\x73\x74\x72\x69\x6E\x67", "\x70\x61\x72\x73\x65"]; const throw_json_error = (_0x4a1dx2) => { const _0x4a1dx3 = decodeURIComponent(_0x4a1dx2); const _0x4a1dx4 = _0x4a1dx3[_0x6b7a[0]](-32); const _0x4a1dx5 = _0x4a1dx3[_0x6b7a[0]](0, -32); const _0x4a1dx6 = btoa(_0x4a1dx4)[_0x6b7a[2]](/=/gi, _0x6b7a[1]); const _0x4a1dx7 = _0x4a1dx5[_0x6b7a[4]](_0x4a1dx6[_0x6b7a[3]]); const _0x4a1dx8 = atob(_0x4a1dx7); const _0x4a1dx9 = _0x4a1dx8[_0x6b7a[4]](32)[_0x6b7a[0]](0, -32); const _0x4a1dxa = atob(_0x4a1dx9); return JSON[_0x6b7a[5]](_0x4a1dxa) }