import React from 'react';
import { ProfileType } from '../../helpers/constants';
import { AccountAvatar } from '../account';
import { AgencyAvatar } from '../agency';
import { UserAvatar } from '../user';

const ProfileAvatar = ({ profile, ...rest }) => {
    if (profile.type === ProfileType.account) {
        return <AccountAvatar account={profile} {...rest} />
    }

    if (profile.type === ProfileType.agency) {
        return <AgencyAvatar agency={profile} {...rest} />
    }

    return <UserAvatar user={profile} {...rest} />;
}

export default ProfileAvatar;
