import React, { useEffect, useState } from 'react';
import { Grid, Divider, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockIcon from '@material-ui/icons/Lock';
import { Geolocation } from '@capacitor/geolocation';
import ChangePassword from './ChangePassword';
import { withRouter } from 'react-router';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { AddAlert, AddLocation } from '@material-ui/icons';
import { isPlatform } from '@ionic/core';
import LogOutButton from '../layout/LogOutButton';
import { PushNotifications } from '@capacitor/push-notifications';
import { Facebook as FacebookIcon } from '@material-ui/icons';
import FacebookLogin from 'react17-facebook-login/dist/facebook-login-render-props'
import { getFacebookAppId } from '../../helpers/environment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },
        headerIcon: {
            marginBottom: -3
        }
    }
});

function SecurityInfo() {
    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const [geoPermissionState, setGeoPermissionState] = useState('');
    const [notificationsPermState, setNotificationsPermState] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        Geolocation.checkPermissions()
            .then(x => setGeoPermissionState(x.location))
    }, [])

    useEffect(() => {
        if (isPlatform('hybrid')) {
            PushNotifications.checkPermissions()
                .then(x => setNotificationsPermState(x.receive))
        } else {
            setNotificationsPermState('N/A')
        }
    }, [])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="SecurityInfo-content"
                id="SecurityInfo-header"
            >
                <LCTypography variant="h5" > <LockIcon className={classes.headerIcon} /> Security & Authorization</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>

                <Grid container spacing={3} >
                    <Grid item xs={6}>

                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>{t('Security')}</IonCardTitle>
                                <IonCardSubtitle>{t('Change your password or log out')}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>

                                <Grid container spacing={3} >
                                    <Grid item xs={12}>
                                        <ChangePassword onChangingPassword={setIsChangingPassword} />
                                    </Grid>
                                    {!isChangingPassword
                                        && <Grid item xs={12}>
                                            <LogOutButton />
                                        </Grid>}

                                    <Grid item xs={12}>
                                        {(geoPermissionState === 'prompt' || geoPermissionState === 'prompt-with-rationale')
                                            && (isPlatform('android') || isPlatform('ios'))
                                            ? <Button
                                                startIcon={<AddLocation />}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    Geolocation.requestPermissions()
                                                        .then(data => setGeoPermissionState(data.location))
                                                }}>
                                                {t('Request Geolocation Permissions')}
                                            </Button>
                                            : <LCTypography transProps={{ geoPermissionState: geoPermissionState }}>
                                                <b>Geolocation Permissions: </b> {{ geoPermissionState: geoPermissionState }}
                                            </LCTypography>}

                                        {isPlatform('hybrid')
                                            && <>
                                                <LCTypography transProps={{ notificationsPermState }}>
                                                    <b>Push Notifications Permissions: </b> {{ notificationsPermState }}
                                                </LCTypography>

                                                {(notificationsPermState === 'prompt' || notificationsPermState === 'prompt-with-rationale')
                                                    && <Button
                                                        startIcon={<AddAlert />}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            PushNotifications.requestPermissions()
                                                                .then(x => setNotificationsPermState(x.receive))
                                                                .catch(error => {
                                                                    setNotificationsPermState(JSON.stringify(error))
                                                                });
                                                        }}>
                                                        {t('Request Push Notifications Permissions')}
                                                    </Button>}
                                            </>}
                                    </Grid>
                                </Grid>
                            </IonCardContent>
                        </IonCard>

                    </Grid>

                    <Grid item xs={6}>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>{t('Integrations & Third Party Apps')}</IonCardTitle>
                                <IonCardSubtitle>{t('Actions related to third party apps and integrations')}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>

                                <Grid container spacing={3} alignItems="center" style={{ display: 'flex', alignItems: 'center' }}>
                                    {isOpened && <FacebookConnectedStatus />}
                                </Grid>
                            </IonCardContent>
                        </IonCard>

                    </Grid>
                </Grid>

            </AccordionDetails>
        </AccordionScrollable>
    );
}

export const FacebookConnectedStatus = () => {

    const [isConnected, setIsConnected] = useState(false);
    const [facebookUserId, setFacebookUserId] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {

        if (!window.FB) {
            return
        }

        //Refs https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus/
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // the user is logged in and has authenticated your
                // app, and response.authResponse supplies
                // the user's ID, a valid access token, a signed
                // request, and the time the access token
                // and signed request each expire
                var uid = response.authResponse.userID;
                setFacebookUserId(uid)
                setIsConnected(true)
                setFacebookUserId(null)
            } else if (response.status === 'not_authorized') {
                // the user is logged in to Facebook,
                // but has not authenticated your app
                setIsConnected(false)
                setFacebookUserId(null)
            } else {
                // the user isn't logged in to Facebook.
                setIsConnected(false)
                setFacebookUserId(null)
            }
        });

    }, [window.FB])

    return <>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <FacebookIcon style={{ marginRight: "10px" }} />
            <strong>Facebook</strong>
        </Grid>
        <Grid item xs={4}>
            <LCTypography variant="body1">
                {isConnected ?
                    <>
                        <strong style={{ color: "green" }}>Connected</strong>
                        <br />
                        {facebookUserId}
                    </>
                    : <>Not Connected</>}
            </LCTypography>
        </Grid>
        <Grid item xs={5}>
            {isConnected ?
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            if (window.confirm(t('Are you sure you want to disconnect your Facebook account? '
                                + 'This may cause features related to Lucit and Facebook connectivity to stop working.'))) {
                                window.FB.logout();
                                setIsConnected(false);
                            }
                        }}>
                        {t('Disconnect')}
                    </Button>
                </> : <>
                    <FacebookLogin
                        appId={getFacebookAppId()}
                        autoLoad={false}
                        fields="name,email,picture"
                        textButton={"Connect"}
                        callback={() => {
                            if (window.FB.getUserID()) {
                                setIsConnected(true)
                                setFacebookUserId(window.FB.getUserID());
                            }
                        }}
                        scope="public_profile"
                        render={renderProps => (
                            <Button
                                onClick={renderProps.onClick}
                                size="small"
                                style={{
                                    backgroundColor: '#1877f2', // Facebook blue
                                    color: '#fff', // White text color
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    border: '1px solid #1877f2', // Border color
                                    cursor: 'pointer',
                                }}
                            >
                                <FacebookIcon style={{ marginRight: "10px" }} />{t('Connect')}
                            </Button>
                        )}
                    />

                </>}
        </Grid>
    </>
}

export default
    withRouter(SecurityInfo);
