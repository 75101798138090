import { LuCoreModelClasses } from "../helpers/constants";
import { parseClass } from "../helpers/lcuid";

export const rolesSelector = state => lcuid => {
    const objectClass = parseClass(lcuid);

    if (objectClass === LuCoreModelClasses.accounts)
        return state.roles.account.roles;

    if (objectClass === LuCoreModelClasses.agencies)
        return state.roles.agency.roles;

    if (objectClass === LuCoreModelClasses.applications)
        return state.roles.application.roles;

    throw `Can't get roles for ${lcuid}`
}

export const getCanInviteRoles = (userRoles, roles) => {
    return userRoles
        .reduce((prev, curr) =>
            [
                ...prev,
                ...(roles && roles[curr] && roles[curr].can_invite || [])
            ], [])
        .filter((x, idx, arr) => arr.indexOf(x) === idx)
        .map(x => ({
            id: x,
            ...roles[x]
        }));
}

export const canRemoveUsers = (userRoles, roles) => {
    return userRoles.some(x => {
        const role = roles[x];

        return role
            && role.can_remove_users
            && role.can_remove_users.all_roles;
    })
}
