import { Box, Button, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getExportHistory } from '../../actions/exports';
import { toLocalDateTime } from '../../helpers/date';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { CampaignStateMap } from '../../helpers/constants';
import DateTimeRelative from '../../components/DateTimeRelative';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

const CampaignHistoryDialog = (props) => {
    const { campaign, open, handleClose, getHistory } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        setLoading(true);
        getHistory(campaign)
            .then(setHistory)
            .finally(() => setLoading(false))
    }, [campaign]);

    return (<>
        <GenericDialog
            title={t('History')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>

                {loading
                    && <CircularProgressCentered size={40} />}

                {!loading
                    && <>
                        {history.length === 0
                            && <Typography>{t('No data available')}</Typography>}

                        {history.length > 0
                            && <List disablePadding>
                                {history.map(h => (<ListItem key={h.lcuid} disableGutters style={{ padding: 0, paddingBottom: 4 }}>
                                    <ListItemText
                                        primary={`Status changed to ${CampaignStateMap[h.state_new].title}`}
                                        secondary={<>
                                            {h.data && h.data.comments
                                                && <Typography component="p">
                                                    {t('Comment: {{comment}}', { comment: h.data.comments })}
                                                </Typography>}
                                            <DateTimeRelative date={toLocalDateTime(h.created_at)} />
                                        </>}
                                    />
                                </ListItem>))}
                            </List>}
                    </>}
            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Close')}
                </Button>
            </>}
        />
    </>)
}

CampaignHistoryDialog.propTypes = {
    campaign: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHistory: campaign => dispatch(getExportHistory(campaign.lcuid))
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CampaignHistoryDialog)
)
