import React, { useEffect, useMemo } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import { Sort } from '@material-ui/icons';
import { SortDir, SortFields } from '../../../helpers/constants';

function Sorting({ rootProps, ...rest }) {
    return <Box {...rootProps}>
        <Field
            component={SortByMenu}
            name="sort"

            {...rest}
        />
    </Box>
}

const SortByMenu = ({ input, buttonProps, renderSelected, values, disabled, submit, fields }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentSort = values.sort || {};

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fieldsMemoized = useMemo(() => fields || [
        { title: 'Title (A-z)', field: SortFields.title, direction: SortDir.asc },
        { title: 'Title (Z-a)', field: SortFields.title, direction: SortDir.desc },
    ], [fields]);

    const selectedField = fieldsMemoized.find(o => o.field === currentSort.field
        && o.direction === currentSort.direction);

    useEffect(() => {
        if (!selectedField) {
            input.onChange({
                field: fieldsMemoized[0].field,
                direction: fieldsMemoized[0].direction
            })
        }
    }, [selectedField, fieldsMemoized, input])

    return <>
        <Button
            aria-controls="sorting-menu"
            aria-haspopup="true"
            startIcon={<Sort />}
            disabled={disabled}
            {...buttonProps}
            onClick={handleClick}>
            {renderSelected
                ? renderSelected(selectedField)
                : selectedField && selectedField.title}
        </Button>
        <Menu
            id="sorting-menu"
            anchorEl={anchorEl}

            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {fieldsMemoized.map(f => (
                <MenuItem
                    key={f.field + f.direction}
                    selected={f === selectedField}
                    onClick={() => {
                        input.onChange({
                            field: f.field,
                            direction: f.direction
                        });

                        handleClose();
                        submit();
                    }}>
                    {f.title}
                </MenuItem>
            ))}
        </Menu>
    </>;
}

Sorting.propTypes = {
    values: PropTypes.object.isRequired,
    submit: PropTypes.func,
    disabled: PropTypes.bool,

    fields: PropTypes.array.isRequired
}

export default Sorting;