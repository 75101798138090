import React from 'react';
import { Button } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectedProfile } from '../../selectors/user';
import { selectProfile } from '../../actions/user';
import { ProfileType, QueryString } from '../../helpers/constants';

export const ProfileChangeButton = ({ targetProfile, pathname, children, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(selectedProfile);
    const isSameProfile = profile.lcuid == targetProfile.lcuid;

    const queryString = targetProfile.type == ProfileType.account
        ? QueryString.contextAccountId
        : targetProfile.type == ProfileType.agency
            ? QueryString.contextAgencyId
            : null;

    return <Button
        {...rest}
        component={RouterLink}
        to={{
            pathname,
            search: queryString && `?${queryString}=${targetProfile.id}`
        }}
        onClick={e => {
            if (!isSameProfile) {
                e.preventDefault();
                dispatch(selectProfile(targetProfile, {
                    animate: true,
                    onSwitched: () => history.push({ pathname })
                }));
            }
        }}
    >{children}</Button>
}
