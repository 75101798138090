import React from 'react';
import Error from '@material-ui/icons/Error';
import Help from '@material-ui/icons/Help';
import StarsIcon from '@material-ui/icons/Stars';

const scoreLevels = {
    "good": {
        "score": 0.9,
        "color": "#3a9752",
        "icon": <StarsIcon style={{ color: "#3a9752" }} />
    },
    "questionable": {
        "score": 0.70,
        "color": "#ffbc00",
        "icon": <Help style={{ color: "#ffbc00" }} />
    },
    "bad": {
        "score": 0.0,
        "color": "#ff4949",
        "icon": <Error style={{ color: "#ff4949" }} />
    }
}

const ModerationScoreIcon = ({ score }) => {

    if (score >= scoreLevels.good.score) {
        return scoreLevels.good.icon
    }

    if (score >= scoreLevels.questionable.score) {
        return scoreLevels.questionable.icon
    }

    return scoreLevels.bad.icon

}

export default ModerationScoreIcon
