import { IonButton, IonIcon } from '@ionic/react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addCircleOutline } from 'ionicons/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addAdditionalDriveTemplate, setDefaultDriveTemplate, updateAdditionalDriveTemplates } from '../../actions/feeds';
import LCTypography from '../../components/material/LCTypography';
import ChangeTemplateDialog from '../../containers/inventory/ChangeTemplateDialog';
import {
    defaultDriveTemplate, hasMultipleDriveTemplates, multipleDriveTemplates, providesDriveTemplates, feedAttributeFields
} from '../../selectors/feeds';
import ItemFilterEditor from '../inventory/itemFilter/ItemFilterEditor';
import { Delete as DeleteIcon, ArrowDropDown as ArrowDownward, ArrowDropUp as ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles(() => {
    return {
        templateImage: {
            width: '100%',
            borderRadius: '5px',
        }
    }
})

function FeedTemplates({
    hasMultipleDriveTemplates, defaultDriveTemplate, multipleDriveTemplates,
    setDefaultDriveTemplate, addAdditionalDriveTemplate, updateAdditionalDriveTemplates, feedAttributeFields
}) {

    const classes = useStyles();

    const [isChangeTemplateDialogOpen, setChangeTemplateDialogOpen] = useState(false);
    const [isAddMultipleTemplateDialogOpen, setAddMultipleTemplateDialogOpen] = useState(false);

    const updateFiltersForTemplate = (index, filters) => {
        const newDriveTemplates = [...multipleDriveTemplates];
        newDriveTemplates[index].filter_fields = filters.filter_fields;
        newDriveTemplates[index].filter_functions = filters.filter_functions;

        updateAdditionalDriveTemplates(newDriveTemplates);
    }

    const removeTemplateAtIndex = (index) => {
        const newDriveTemplates = [...multipleDriveTemplates];
        newDriveTemplates.splice(index, 1);

        updateAdditionalDriveTemplates(newDriveTemplates);
    }

    const changeTemplateAtIndex = (index, selectedTemplateLcuid) => {

        const newDriveTemplates = [...multipleDriveTemplates];
        newDriveTemplates[index].template_lcuid = selectedTemplateLcuid;

        updateAdditionalDriveTemplates(newDriveTemplates);
    }

    const moveTemplateUpOrDownInIndex = (index, direction) => {

        const newDriveTemplates = [...multipleDriveTemplates];

        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= newDriveTemplates.length)
            return;

        const temp = newDriveTemplates[newIndex];
        newDriveTemplates[newIndex] = newDriveTemplates[index];
        newDriveTemplates[index] = temp;

        updateAdditionalDriveTemplates(newDriveTemplates);

    }

    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box style={{ maxWidth: "500px" }}>
                    <LCTypography variant="body2">

                        Templates are the creative designs that you attach to the data coming in from this data source.
                        When Lucit receives data from this data source, it will automatically build creatives for each inventory
                        item using the template(s) that you select.
                    </LCTypography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <LCTypography variant="h6">
                    Default Template
                </LCTypography>
                <LCTypography variant="body2">
                    The default template will be automatically assigned to all inventory that comes in through this data source
                </LCTypography>
            </Grid>
            <Grid item xs={3}>
                {defaultDriveTemplate ? <Box mt={0} ml={3}>
                    <img
                        className={classes.templateImage}
                        src={defaultDriveTemplate.options.primary_image_public_url}
                    />

                    <Typography variant="body2">
                        <strong>{defaultDriveTemplate.name}</strong>
                    </Typography>

                </Box> :
                    <Box mt={2}>
                        <LCTypography variant="body2" color="textSecondary">
                            In order to get started, set a default template for this data source.   This template will be used
                            for all inventory items, unless you specify additional templates below
                        </LCTypography>
                    </Box>
                }
            </Grid>

            <Grid item xs={9}>
                <IonButton
                    color="primary"
                    onClick={() => setChangeTemplateDialogOpen(true)}
                >
                    {defaultDriveTemplate ? t('Change Default Template') : t('Choose Default Template')}
                    <IonIcon slot="start" icon={addCircleOutline} />
                </IonButton>

                {isChangeTemplateDialogOpen
                    && <ChangeTemplateDialog
                        open={isChangeTemplateDialogOpen}
                        selectedTemplateLcuid={defaultDriveTemplate ? defaultDriveTemplate.lcuid : null}
                        handleClose={(selectedTemplateLcuid) => {

                            if (selectedTemplateLcuid)
                                setDefaultDriveTemplate(selectedTemplateLcuid)

                            setChangeTemplateDialogOpen(false)
                        }
                        } />}
            </Grid>

            {
                defaultDriveTemplate && <>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <LCTypography variant="h6">
                            Additional Templates
                        </LCTypography>
                        <LCTypography variant="body2">
                            Add additional templates with rules to describe when they will be applied to an item from this data source.
                            <br />
                            <br />
                            Use this section to create different templates for different types of items.  For instance new versus used, or
                            specifica templates to specific types or manufacturers of products
                        </LCTypography>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid container spacing={2}>
                            {hasMultipleDriveTemplates && multipleDriveTemplates.map((template, index) => {

                                return <FeedTemplateRow
                                    key={index}
                                    template={template}
                                    onChangeFilter={(newValues) => updateFiltersForTemplate(index, newValues)}
                                    onDelete={() => removeTemplateAtIndex(index)}
                                    isLast={index === multipleDriveTemplates.length - 1}
                                    isFirst={index === 0}
                                    feedAttributeFields={feedAttributeFields}
                                    moveTemplateUpOrDownInIndex={(direction) => moveTemplateUpOrDownInIndex(index, direction)}
                                    onChangeTemplate={(newTemplateLcuid) => {

                                        if (newTemplateLcuid)
                                            changeTemplateAtIndex(index, newTemplateLcuid)
                                    }}
                                />
                            })}
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>

                        <Box ml={18} mt={2} mb={8}>
                            <IonButton
                                color="primary"
                                onClick={() => setAddMultipleTemplateDialogOpen(true)}
                            >
                                {t('Add Template')}
                                <IonIcon slot="start" icon={addCircleOutline} />
                            </IonButton>

                            {isAddMultipleTemplateDialogOpen
                                && <ChangeTemplateDialog
                                    open={isAddMultipleTemplateDialogOpen}
                                    handleClose={(selectedTemplateLcuid) => {

                                        if (selectedTemplateLcuid)
                                            addAdditionalDriveTemplate(selectedTemplateLcuid)

                                        setAddMultipleTemplateDialogOpen(false)
                                    }
                                    } />}
                        </Box>
                    </Grid>
                </>
            }
        </Grid >
    );
}

const FeedTemplateRow = ({
    template, onChangeFilter, onChangeTemplate, onDelete, moveTemplateUpOrDownInIndex, isLast, isFirst, feedAttributeFields
}) => {

    const classes = useStyles();

    const [isChangeTemplateDialogOpen, setChangeTemplateDialogOpen] = useState(false);
    const { t } = useTranslation();

    const additionalFieldsAndFunctions = feedAttributeFields ? feedAttributeFields.map((field) => {
        return {
            id: "inventoryAttribute_" + field,
            label: field,
            type: "function",
            dataType: "string",
            group: "Feed"
        }
    }) : []

    return <>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
            <Typography variant="body1">
                <strong>{template && template.drive_template && template.drive_template.name}</strong>
            </Typography>

            <Box>
                {!isFirst && <ArrowUpward
                    title={t('Move template up')}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => moveTemplateUpOrDownInIndex(-1)}
                />}
                {!isLast && <ArrowDownward
                    title={t('Move template down')}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => moveTemplateUpOrDownInIndex(1)}
                />}
                <DeleteIcon
                    title={t('Remove template')}
                    color="secondary"
                    style={{ marginLeft: "10px", cursor: 'pointer' }}
                    onClick={() => {
                        if (window.confirm(t('Are you sure you want to remove this template from this feed?')))
                            onDelete()
                    }}
                />
            </Box>
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={3}>
            {template && template.drive_template && template.drive_template.options.primary_image_public_url &&

                <img
                    style={{ cursor: 'pointer' }}
                    title={t('Change Template')}
                    onClick={() => setChangeTemplateDialogOpen(true)}
                    className={classes.templateImage}
                    src={template && template.drive_template && template.drive_template.options.primary_image_public_url}
                />
            }
        </Grid>
        <Grid item xs={8}>
            <ItemFilterEditor
                filterFields={template && template.filter_fields}
                filterFunctions={template && template.filter_functions}
                onChange={(newValues) => onChangeFilter(newValues)}
                additionalFieldsAndFunctions={additionalFieldsAndFunctions}
            />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
        </Grid>

        {isChangeTemplateDialogOpen
            && <ChangeTemplateDialog
                open={isChangeTemplateDialogOpen}
                selectedTemplateLcuid={template && template.template_lcuid}
                handleClose={(selectedTemplateLcuid) => {
                    if (selectedTemplateLcuid)
                        onChangeTemplate(selectedTemplateLcuid)

                    setChangeTemplateDialogOpen(false)
                }
                } />}

    </>

}

FeedTemplates.propTypes = {
    feed: PropTypes.object.isRequired,
}

const mapStateToProps = (_, props) => {

    const { feed } = props;

    return {
        providesDriveTemplates: providesDriveTemplates(feed),
        hasMultipleDriveTemplates: hasMultipleDriveTemplates(feed),
        defaultDriveTemplate: defaultDriveTemplate(feed),
        multipleDriveTemplates: multipleDriveTemplates(feed),
        feedAttributeFields: feedAttributeFields(feed),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setDefaultDriveTemplate: (templateLcuid) => dispatch(setDefaultDriveTemplate(ownProps.feed.id, templateLcuid)),
        addAdditionalDriveTemplate: (templateLcuid) => dispatch(addAdditionalDriveTemplate(ownProps.feed.id, templateLcuid)),
        updateAdditionalDriveTemplates: (driveTemplates) => dispatch(updateAdditionalDriveTemplates(ownProps.feed.id, driveTemplates)),
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        FeedTemplates
    )
);
