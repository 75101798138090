export const getUsedMacroses = (layers) => layers
    .filter(x => Boolean(x))
    .map(x => {
        if (x.type == 'html') {
            return x.macroCode.slice(1, -1) // remove first and last character
        }

        return '';
    });

export const getDataSourceObject = (element, item, account, user, digitalBoard, accountKeyValueStore) => {
    switch (element.object) {
        case "item":
            return item;
        case "account":
            return account;
        case "user":
            return user;
        case "digital_board":
            return digitalBoard;
        case "key_value_store": {

            //This is messy, but it works
            /**
             * We need to map the key_value_store objects to the correct object.
             *
             * Every key value has a parent and and object
             * The parent is the current users account always
             * The object, will be whatever object to which the keyvalue is attached
             *
             * This function takes the keyvalues for an object and correctly maps them
             * to the correct object
             *
             * For instance, if you have a key like `digital_board.store.CoolThing` it will
             * get it on to the actual digital board object so it can be later
             * evaluated by getDataSourceMacroValueFromObject
             *
             * Another way to think about this.   IF the key_value_store object
             * is a digital_board, this will return the digitalBoard object
             *
             */

            //Convert all of the matching keyValueStore objects into a single object of key/values
            //Which can then be reached by `digital_board.store.key` or `account.store.key`
            if (element.property && isDigitalBoardKeyValueStore(element.property) && digitalBoard) {
                return {
                    ...digitalBoard,
                    store: accountKeyValueStore.filter(
                        x => x.object_id == digitalBoard.id
                            && x.object_type == "App\\DigitalBoard"
                    ).reduce((keyValues, obj) => {
                        keyValues[obj.key] = obj.value;
                        return keyValues;
                    }, {})
                }
            }

            if (element.property && isAccountKeyValueStore(element.property) && account) {
                return {
                    ...account,
                    store: accountKeyValueStore.filter(
                        x => x.object_id == account.id
                            && x.object_type == "App\\Account"
                    ).reduce((keyValues, obj) => {
                        keyValues[obj.key] = obj.value;
                        return keyValues;
                    }, {})
                }
            }
            break;
        }
        default:
            return null;
    }
}

export const getDataSourceMacroValueFromObject = (element, object, returnPlaceholderIfNull) => {
    if (!object) {
        if (returnPlaceholderIfNull)
            return element.placeholder
        else
            return null
    }

    let newValue = null
    if (isOptionsProperty(element.property)) {  //object.options.property
        if (object.options
            && object.options[propertyFromOptions(element.property)] !== undefined
        ) {
            newValue = object.options[propertyFromOptions(element.property)]
        }
    }
    else if (isStoreProperty(element.property)) { //object.store.property
        if (object.store
            && object.store[propertyFromStore(element.property)] !== undefined
        ) {
            newValue = object.store[propertyFromStore(element.property)]
        }
    }
    else if (isLocationOptionsProperty(element.property)) { //object.location.options.property
        if (object.location
            && object.location.options
            && object.location.options[propertyFromLocationOptions(element.property)] !== undefined
        ) {
            newValue = object.location.options[propertyFromLocationOptions(element.property)]
        }
    }
    else if (isLocationProperty(element.property)) { //object.location.property
        if (object.location
            && object.location[propertyFromLocation(element.property)] !== undefined
        ) {
            newValue = object.location[propertyFromLocation(element.property)]
        }
    }
    else if (isInventoryAttributeProperty(element.property)) { //object.inventory_attributes.property
        if (object.inventory_attributes
            && object.inventory_attributes[propertyFromInventoryAttribute(element.property)] !== undefined
        ) {
            newValue = object.inventory_attributes[propertyFromInventoryAttribute(element.property)]
        }
    }
    else {
        newValue = object[element.property] ?? null;  //object.property
    }

    if (returnPlaceholderIfNull && (newValue === null || newValue === undefined)) {
        newValue = element.placeholder
    }

    return newValue
}

const isOptionsProperty = (property) => {
    return property.startsWith("options.")
}

const propertyFromOptions = (property) => {
    return property.substring(8) // 8 = "options.".length
}

/**
 *
 * Store properties are coming in from KeyValueStore, so they are
 * fully qualified with the object.
 *
 * For instance, if you have a key like `digital_board.store.CoolThing` it's
 * property is `digital_board.store.CoolThing`   (wherease other properties
 * like options, etc. are just `store.CoolThing`)
 *
 * This is why we are using a different method to determine if this is a store property
 * and to extract the property name from the string
 *
 */
const isStoreProperty = (property) => {
    return property.includes(".store.")
}

const propertyFromStore = (property) => {
    return property.split(".store.")[1]
}

const isLocationProperty = (property) => {
    return property.startsWith("location.")
}

const propertyFromLocation = (property) => {
    return property.substring(9) // 9 = "location.".length
}

const isLocationOptionsProperty = (property) => {
    return property.startsWith("location.options.")
}

const propertyFromLocationOptions = (property) => {
    return property.substring(17) // 17 = "location.options.".length
}

const isInventoryAttributeProperty = (property) => {
    return property.startsWith("inventory_attributes.")
}

const propertyFromInventoryAttribute = (property) => {
    return property.substring(21) // 21 = "inventory_attributes.".length
}

const isAccountKeyValueStore = (property) => {
    return property.startsWith("account.")
}

const isDigitalBoardKeyValueStore = (property) => {
    return property.startsWith("digital_board.")
}
