import { Geolocation } from '@capacitor/geolocation';
import {
    USER_INFO, USER_LOGIN, USER_LOGOUT, USER_SELECT_ACCOUNT, USER_NEW_UPDATE, USER_STORE_PERMISSIONS,
    USER_SW_INITIALIZED, USER_CONNECTION_STATUS_CHANGE, USER_PUSH_NOTIFICATIONS_TOKEN,
    USER_SET_TIMEZONE, USER_SET_IMAGE, USER_REMOVE_IMAGE, USER_UPDATE_TOUR, USER_UPDATE,
    USER_ACCEPT_EULA, USER_SELECT_AGENCY, USER_SELECT_USER, USER_DEVELOPER_MODE, USER_SWITCHING_PROFILE, USER_LOGIN_MFA
} from "../helpers/actionTypes";
import { lucitApi } from "../services/lucitApi";
import { updateAPI } from "../helpers/api";
import { showError } from "./snackbar";
import { accountSelector, agencySelector, getUser, selectedProfile, isLoggedIn } from "../selectors/user";
import { uploadImage } from "./uploads";
import { ProfileType } from '../helpers/constants';
import i18next from 'i18next';

export const TOKEN_CACHE_KEY = 'token';

export function userLogin(token) {
    return dispatch => {
        dispatch({
            type: USER_LOGIN,
            token
        });
        updateAPI({ accessToken: token });
        localStorage.setItem(TOKEN_CACHE_KEY, token);
    }
}

export function userInfo(data) {
    return {
        type: USER_INFO,
        ...data
    }
}

export function updateUser(data) {
    return {
        type: USER_UPDATE,
        ...data
    }
}

export function setTimezone(timezone) {
    return {
        type: USER_SET_TIMEZONE,
        timezone
    }
}

export function userLogout() {
    return {
        type: USER_LOGOUT
    }
}

export function savePushNotificationsToken(userId, token, platform) {
    return dispatch => lucitApi.setFcmToken(userId, token, platform)
        .then(() => dispatch(setPushNotificationToken(token)))
}

export function setPushNotificationToken(token) {
    return {
        type: USER_PUSH_NOTIFICATIONS_TOKEN,
        token
    };
}

export function selectProfile(profile, options = {}) {
    return (dispatch, getState) => {
        const currentProfile = selectedProfile(getState())
        const isSameProfile = currentProfile.id == profile.id;

        if (isSameProfile) {
            options.onSwitched && options.onSwitched();
            return;
        }

        if (options.animate) {
            return dispatch({
                type: USER_SWITCHING_PROFILE,
                profile,
                onSwitched: options.onSwitched
            });
        }

        if (profile.type === ProfileType.account) {
            return dispatch({ type: USER_SELECT_ACCOUNT, account: profile });
        }

        if (profile.type === ProfileType.agency) {
            return dispatch({ type: USER_SELECT_AGENCY, agency: profile });
        }

        return dispatch({ type: USER_SELECT_USER });
    }
}

export function selectAccount(account, options) {
    return selectProfile({ ...account, type: ProfileType.account }, options);
}

export function getPermissions() {

    return (dispatch, getState) => {

        const currentProfile = selectedProfile(getState())

        return lucitApi.objects.getPermissions(currentProfile.lcuid).then(permissions => {
            dispatch({
                type: USER_STORE_PERMISSIONS,
                object: currentProfile,
                permissions: permissions
            });
        });
    }

}

export function selectAgency(agency, options) {
    return selectProfile({ ...agency, type: ProfileType.agency }, options);
}

export function selectUser(options) {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch(selectProfile({ ...state.user, type: ProfileType.user }, options));
    }
}

export function swInitiazlied(handleCheckUpdate) {
    return {
        type: USER_SW_INITIALIZED,
        handleCheckUpdate
    }
}

export function newUpdateAvailable(handleUpdate) {
    return {
        type: USER_NEW_UPDATE,
        handleUpdate
    }
}

export function connectionStatusChange(isOnline) {
    return {
        type: USER_CONNECTION_STATUS_CHANGE,
        isOnline
    }
}

export function acceptEula() {
    return (dispatch) => {
        return lucitApi.users.acceptEula()
            .then(data => {
                dispatch({
                    type: USER_ACCEPT_EULA,
                    ...data
                })
            });
    }
}

export function setDeveloperMode(enabled) {
    return dispatch => dispatch({
        type: USER_DEVELOPER_MODE,
        isDeveloperMode: enabled
    })
}

export function login(username, password, registrationId = null, join_lcuid = null, sms_code = null) {

    return dispatch => {
        return lucitApi
            .login(username, password, registrationId, join_lcuid, sms_code)
            .then(data => {
                if (data.requires_mfa) {
                    dispatch({
                        type: USER_LOGIN_MFA,
                        id: data.lcuid,
                        mfa_last_4_digits: data.last_4_digits,
                        mfa_sms_code_expire_seconds: data.sms_code_expire_seconds,
                        mfa_token: data.token
                    });
                    return data;
                }

                dispatch(userLogin(data.success.token))
                return dispatch(getUserInfo());
            });
    }
}

export function loginMfaConfirm(code) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi
            .loginMfaConfirm(user.id, code, user.mfa_token)
            .then(data => dispatch(userLogin(data.success.token)))
            .then(() => dispatch(getUserInfo()));
    }
}

export function getUserInfo() {
    return dispatch => {
        return lucitApi.getUserInfo()
            .then(({ data }) => {
                const options = data.user.options || {};

                const user = Object.assign({}, data.user, {
                    isLoggedIn: true,
                    applicationId: data.application_id,
                    phone: data.user.mobile,
                    phoneConfirmed: options.mobile_verified,
                    emailConfirmed: options.email_verified,
                    createdAt: data.created_at,
                    updatedAt: data.updated_at,
                    applications: data.applications || [],
                    accounts: data.accounts || [],
                    agencies: data.agencies || [],
                    options: {
                        ...options,

                        tours: options.app_tours || {}
                    }
                });

                dispatch(userInfo(user));

                return user;
            })
            .catch(error => {
                if (!error.isOffline) {
                    if (error.response && (error.response.status === 404
                        || error.response.status > 500)) {
                        dispatch(showError(i18next.t('System is currently unavailable. Please try again later')));
                    }
                }

                throw error;
            })
    }
}

export function logout() {
    return dispatch => {
        localStorage.removeItem(TOKEN_CACHE_KEY);

        //TODO: probably in future we will revoke token

        // eslint-disable-next-line no-undef
        return Promise.resolve(dispatch(userLogout()));
    }
}

export function restore() {
    return dispatch => {
        const token = localStorage.getItem(TOKEN_CACHE_KEY);

        if (token) {
            updateAPI({ accessToken: token });

            return dispatch(getUserInfo());
        }

        //TODO: probably in future we will refresh token

        // eslint-disable-next-line no-undef
        return Promise.resolve();
    }
}

export function setContext(context) {
    return async (dispatch, getState) => {
        const { accountId, agencyId } = context;

        const isAuthenticated = isLoggedIn(getState());

        if (!isAuthenticated) {
            return;
        }

        if (accountId != null) {
            let account = accountSelector(getState())(accountId);

            if (!account) {
                await dispatch(getUserInfo());

                account = accountSelector(getState())(accountId);
            }

            if (account) {
                dispatch(selectAccount(account));
            }
        }

        if (agencyId != null) {
            let agency = agencySelector(getState())(agencyId);

            if (!agency) {
                await dispatch(getUserInfo());

                agency = agencySelector(getState())(agencyId);
            }

            if (agency) {
                dispatch(selectAgency(agency));
            }
        }
    }
}

export function changeUsername(name) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.updateUser(user.id, name)
            .then(data => {
                dispatch(updateUser({ name: data.user.name }));
            })
    }
}

export function changeTitle(title) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.updateUserTitle(user.id, user.name, title)
            .then(data => {
                dispatch(updateUser({ title: data.user.title }));
            })
    }
}

export function changeAboutMe(aboutMe) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.updateUserAboutMe(user.id, user.name, aboutMe)
            .then(data => {
                dispatch(updateUser({ description: data.user.description }));
            })
    }
}

export function changePhone(phone) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.changePhone(user.id, phone)
            .then(data => {
                dispatch(updateUser({ phone: data.user.options.pending_mobile_number_change }));
            })
    }
}

export function changePhoneConfirm(code) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.changePhoneValidate(user.id, code)
            .then(data => {
                dispatch(updateUser({ phone: data.user.mobile, phoneConfirmed: true }));
            })
    }
}

export function changeEmail(email) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.changeEmail(user.id, email)
            .then(() => {
                dispatch(updateUser({ email, emailConfirmed: false }));
            })
    }
}

export function changeTimezone(timezone) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.updateUser(user.id, user.name, timezone)
            .then(() => dispatch(setTimezone(timezone)));
    }
}

export function setPrimaryImage(fileBase64) {
    return (dispatch, getState) => {
        return dispatch(uploadImage(fileBase64))
            .then(image => {
                const user = getUser(getState());

                return lucitApi.users.setPrimaryImage(user.id, image.id)
                    .then(user => dispatch({
                        type: USER_SET_IMAGE,
                        options: user.options
                    }))
            })
    }
}

export function deletePrimaryImage() {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.users.deletePrimaryImage(user.id)
            .then(() => dispatch({
                type: USER_REMOVE_IMAGE
            }))
    }
}

export function setTour(tour) {
    return (dispatch, getState) => {
        const user = getUser(getState());

        dispatch({
            type: USER_UPDATE_TOUR,
            tourId: tour.id,
            status: tour.status
        })

        return lucitApi.users.setTour(user.id, tour)
            .then(data => dispatch({
                type: USER_UPDATE_TOUR,
                tourId: tour.id,
                ...data.tour_data
            }))
    }
}

export function resendEmail() {
    return (_, getState) => {
        const user = getUser(getState());

        return lucitApi.resendEmailConfirm(user.email);
    }
}

export function resetPassword(code, password) {
    return (dispatch, getState) => {
        const user = getUser(getState());
        return lucitApi.resetPassword(user.phone, code, password);
    }
}

export function getLocationGps() {
    return (dispatch) => {
        return Geolocation.getCurrentPosition()
            .then(data => {
                dispatch({
                    type: USER_UPDATE,
                    locationGps: {
                        lat: data.coords.latitude,
                        long: data.coords.longitude,
                        accuracy: data.coords.accuracy,
                        timestamp: data.timestamp
                    },
                })
            }).catch((error) => {
                console.error(i18next.t('Error getting location data from getCurrentPosition'), error);
            });
    }
}

export function guessLocation() {
    return (dispatch, getState) => {
        const user = getUser(getState());

        return lucitApi.users.guessLocation(user.id)
            .then(data => dispatch({
                type: USER_UPDATE,
                location: data.location_guess,
                locationSource: data.location_guess_source
            }));
    }
}
