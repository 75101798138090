import React from 'react';
import { connect } from 'react-redux';
import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import CreateApplicationButton from '../../containers/applications/CreateApplicationButton';
import { RoutePaths } from '../../helpers/constants';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { Link, Box } from '@material-ui/core';
import ApplicationList from '../../containers/applications/ApplicationList';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';

function Applications({ applications }) {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t("Applications")} />
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{t('My Applications')}</IonCardTitle>
                    <IonCardContent><LCTypography>
                        Build applications that interact with Lucit and your Digital OOH Campaigns :<> </>
                        <Link
                            color="secondary"
                            href="https://www.lucit.cc/developers"
                            target="_blank"
                        >
                            Learn More : View the Developer Documentation
                        </Link></LCTypography>
                    </IonCardContent>
                </IonCardHeader>

                <IonCardContent>

                    <Box mb={6} ml={1}>
                        <CreateApplicationButton />
                    </Box>

                    {applications.length === 0 && <Box mt={2} mb={2}><LCTypography>
                        You have no apps yet - Click <strong>New Application</strong> to get started
                    </LCTypography></Box>
                    }

                    <ApplicationList
                        apps={applications}
                        devMode={true}
                        selectApp={(app) => history.push({ pathname: RoutePaths.dev.application(app.lcuid) })}
                    />
                </IonCardContent>
            </IonCard>

            <ButtonScrollToTopFab />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        applications: state.applications.data
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withHomeLayout(Applications)
);
