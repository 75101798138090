import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { notifications, unreadCount } from '../../selectors/pushNotifications';
import { saveMarkAllAsRead, getNotifications } from '../../actions/pushNotifications';
import { handleNotificationTap } from '../../components/notifications';
import { useEffect } from 'react';
import { Popper } from '../../components/material';
import { Box, Divider, Link, List, ListItem, Typography } from '@material-ui/core';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        list: {
            width: theme.spacing(40),
            maxHeight: theme.spacing(50),
            overflow: 'auto'
        },
        listItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },

        unreadCircle: {
            cursor: 'pointer',
            width: theme.spacing(1.5),
            height: theme.spacing(1.5),
            background: theme.palette.secondary.main,
            borderRadius: '50%',
            marginLeft: 'auto'
        },
        gutterBottomText: {
            wordBreak: 'break-word'
        }
    }
})

const NotificationsPopper = (props) => {
    const { dispatch, items, anchorEl, open, onClose, markAllRead, getNotifications, unreadCount } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true)
            getNotifications()
                .finally(() => setLoading(false))
        }
    }, [getNotifications, open]);

    return (
        <Popper {...{ anchorEl, open, onClose }}>
            <Box display="flex" justifyContent="space-between" p={2}>
                <LCTypography variant="h6">Notifications</LCTypography>
                {unreadCount > 0
                    && <Link
                        component="button"
                        color="secondary"
                        disabled={saving}
                        variant="body2"
                        onClick={() => {
                            setSaving(true);
                            markAllRead()
                                .finally(() => setSaving(false))
                        }}
                    >Mark all as read</Link>}
            </Box>
            <Divider />
            {loading
                && <Box p={2} width={320}>
                    <CircularProgressCentered size={40} />
                </Box>}
            {!loading
                && items.length === 0
                && <Box p={2}>
                    <LCTypography variant="body2">You do not have any notifications yet</LCTypography>
                </Box>}
            {items.length > 0
                && <List className={classes.list} disablePadding>
                    {items.map((x, index) =>
                        <ListItem
                            key={x.id}
                            button
                            onClick={() => {
                                handleNotificationTap(x, dispatch);
                                onClose();
                            }}
                            selected={!x.read_at}
                            divider={index !== items.length - 1}
                            className={classes.listItem}>
                            <Box display="flex" alignItems="center" width="100%" mb={1}>
                                <Typography variant="body1">
                                    {x.data.title}
                                </Typography>

                                {!x.read_at
                                    && <Box className={classes.unreadCircle} />}
                            </Box>
                            <Typography className={classes.gutterBottomText} variant="body2" gutterBottom>
                                {x.data.body}
                            </Typography>
                            <Typography variant="caption">
                                {moment.utc(x.created_at).local().format("hh:mm A MMMM DD, YYYY")}
                            </Typography>
                        </ListItem>)}
                </List>}
        </Popper>
    );
};

const mapStateToProps = state => {
    return {
        items: notifications(state),
        unreadCount: unreadCount(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        markAllRead: () => dispatch(saveMarkAllAsRead()),
        getNotifications: () => dispatch(getNotifications())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    NotificationsPopper
);
