import React from 'react';
import { Box, Grid, ListItemAvatar, Avatar, ListItemText, ListItem, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => {
    return {
        avatar: {
            background: 'none'
        },
        chip: {
            borderRadius: 16
        }
    }
})

function FeedRunSkeleton() {
    const classes = useStyles();

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    <Skeleton variant="rect" width="100%" height="100%" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Skeleton variant="text" />
                <Box mt={1}>
                    <Grid container spacing={1}>
                        <Grid item className={classes.gridItem} >
                            <Skeleton variant="rect" className={classes.chip} width={70} height={24} />
                        </Grid>
                        <Grid item className={classes.gridItem} >
                            <Skeleton variant="rect" className={classes.chip} width={100} height={20} />
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem} >
                            <Skeleton variant="rect" className={classes.chip} width={140} height={20} />
                        </Grid>
                    </Grid>
                </Box>
            </ListItemText>
        </ListItem>
    );
}

function FeedRunsSkeleton(props) {
    const { count } = props;

    return <List>
        {Array.from({ length: count }).map((_, index) => <FeedRunSkeleton key={index} />)}
    </List>
}

export default FeedRunsSkeleton;
