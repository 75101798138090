import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clx from 'classnames';
import {
    Link, makeStyles, Box, Typography, ListItem, ListItemAvatar,
    ListItemText, ListItemSecondaryAction, IconButton
} from '@material-ui/core';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { RoutePaths } from '../../helpers/constants';
import { attachmentsSelector } from '../../selectors/objects';
import { TypographyShowMore, Avatar as LucitAvatar } from '../../components/material';
import { connect } from 'react-redux';
import { deleteComment, getAttachments } from '../../actions/objects';
import { useEffect } from 'react';
import { ImagePreviewDialog } from '../../components';
import { IonContent, IonImg, IonItem, IonList, IonPopover, useIonActionSheet } from '@ionic/react';
import { MoreHoriz } from '@material-ui/icons';
import { isLoggedIn } from '../../selectors/user';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        listItem: {
            paddingTop: 0,
            paddingBottom: 4
        },
        listItemWithActions: {
            paddingRight: 52
        },
        commentText: {
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            background: theme.palette.grey[200],
            borderRadius: 5,
            padding: theme.spacing(1, 1.5)
        },

        attachmentBox: {
            display: 'flex',
            flexWrap: 'wrap'

            // [theme.breakpoints.down('xs')]: {
            // }
        },
        attachmentImageBox: {
            cursor: 'pointer',
            background: 'white',
            position: 'relative',
            width: 120,
            height: 80,
            borderRadius: 8,
            marginBottom: 8

            // [theme.breakpoints.down('xs')]: {
            // }
        },
        attachmentImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    }
})

const Comment = props => {
    const { lcuid, className, comment } = props;
    const { attachmentsSelector, getAttachments, userId, isLoggedIn } = props;
    const classes = useStyles();

    const attachments = comment.attachments || attachmentsSelector(comment.lcuid);
    const [previewImage, setPreviewImage] = useState(null);

    const [isOpenMore, setIsOpenMore] = useState(false);
    const [popoverEvent, setPopoverEvent] = useState();
    const [deleting, setDeleting] = useState(false);

    const [present] = useIonActionSheet();
    const { t } = useTranslation();

    useEffect(() => {
        if (!attachments) {
            getAttachments(comment.lcuid)
        }
    }, [attachments])

    const canUpdate = isLoggedIn && userId === comment.commentator.id;

    return <>
        <ListItem alignItems="flex-start" disableGutters
            className={clx(className, classes.listItem)}
            classes={{ secondaryAction: classes.listItemWithActions }}>
            <ListItemAvatar>
                <RouterLink
                    to={RoutePaths.public.slug(comment.commentator.slug)}>
                    <LucitAvatar
                        alt={comment.commentator.name}
                        name={comment.commentator.name}
                        src={comment.commentator.options.primary_image_public_url}></LucitAvatar>
                </RouterLink>
            </ListItemAvatar>

            <Box width="100%">
                <ListItemText
                    className={classes.commentText}
                    primary={<Box mb={1}>
                        <Typography variant="body2">
                            <Link
                                component={RouterLink}
                                to={RoutePaths.public.slug(comment.commentator.slug)}
                            >
                                <b>{comment.commentator.name}</b>
                            </Link>
                        </Typography>
                    </Box>}
                    secondary={
                        <>
                            <TypographyShowMore
                                maxLength={120}
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"

                            >
                                {comment.comment}
                            </TypographyShowMore>

                            {attachments
                                && attachments.length > 0
                                && <Box className={classes.attachmentBox} mt={1}>
                                    {attachments.map((a, idx) => <Box key={a.lcuid}
                                        className={classes.attachmentImageBox}
                                        style={{ marginRight: idx !== attachments.length - 1 ? 8 : 0 }}
                                        onClick={() => setPreviewImage(a.attachable)}
                                    >
                                        <img
                                            className={classes.attachmentImage}
                                            src={a.attachable.options.public_url} />

                                    </Box>)}
                                </Box>}
                        </>
                    }
                />
                <Box ml={1} mt={-0.5}>
                    <Typography variant='caption' color='textSecondary'>
                        <DateTimeRelative
                            date={toLocalDateTime(comment.created_at)}
                            format="MMMM D [at] hh:mm A" />
                    </Typography>

                    {comment.created_at != comment.updated_at
                        && <Typography
                            style={{ marginLeft: 16 }}
                            variant='caption'
                            color='textSecondary'>({t('edited')})</Typography>}
                </Box>

            </Box>

            {canUpdate
                && <ListItemSecondaryAction style={{ right: 0, top: 'calc(50% - 12px)' }}>
                    <IconButton
                        onClick={e => { e.persist(); setPopoverEvent(e); setIsOpenMore(true); }}>
                        <MoreHoriz />
                    </IconButton>
                </ListItemSecondaryAction>}
        </ListItem>

        <ImagePreviewDialog
            open={Boolean(previewImage)}
            handleClose={() => setPreviewImage(null)}
        >
            {previewImage
                && <IonImg src={previewImage.options.public_url} alt="zoomed" />}
        </ImagePreviewDialog>

        <IonPopover
            event={popoverEvent}
            isOpen={isOpenMore}
            onDidDismiss={() => setIsOpenMore(false)}
            dismissOnSelect={true}>
            <IonContent>
                <IonList>
                    <IonItem
                        onClick={() => props.onEdit && props.onEdit()}
                        button={true}
                        detail={false}>
                        Edit
                    </IonItem>
                    <IonItem
                        onClick={() => {
                            present({
                                header: 'Are you sure?',
                                buttons: [
                                    {
                                        text: 'Delete',
                                        role: 'destructive',
                                    },
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                    },
                                ],

                                onDidDismiss: ({ detail }) => {
                                    if (detail.role === 'destructive') {
                                        setDeleting(true);
                                        props.deleteComment(lcuid, comment.lcuid)
                                            .finally(() => setDeleting(true));
                                    }
                                },
                            })

                        }}
                        disabled={deleting}
                        button={true}
                        detail={false}>
                        Delete
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPopover>
    </>
}

Comment.propTypes = {
    className: PropTypes.string,

    lcuid: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,

    onEdit: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        attachmentsSelector: lcuid => attachmentsSelector(state)(lcuid),
        isLoggedIn: isLoggedIn(state),
        userId: state.user.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAttachments: (lcuid) => dispatch(getAttachments(lcuid)),

        deleteComment: (lcuid, commentLcuid) => dispatch(deleteComment(lcuid, commentLcuid))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comment);
