import React, { useState } from 'react';
import { connect } from 'react-redux';
import clx from 'classnames';
import moment from 'moment';
import { Typography, Divider, AccordionSummary, AccordionDetails, Box, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LCTypography from '../../components/material/LCTypography';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { getUser, selectedAccount, userTimezone } from '../../selectors/user';
import { required, email } from '../../helpers/validators';
import { getTimezoneParts } from '../../helpers/moment';
import { changeUsername, changeEmail, changeTitle, changeAboutMe, resendEmail, changeTimezone, deletePrimaryImage } from '../../actions/user';
import { showSuccess, showError } from '../../actions/snackbar';
import UserPhoneInfo from './UserPhoneInfo';
import { useEffect } from 'react';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { lucitApi } from '../../services/lucitApi';
import AutocompleteEditable from '../../components/inputs/AutocompleteEditable';
import ImageUploadDialog from '../../components/modals/ImageUploadDialog';
import { setPrimaryImage } from '../../actions/user';
import { Global, RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import { AvatarEditable, ButtonLoader } from '../../components';
import { SettingsSection } from './SettingsSection';
import { hasIcon } from '../../selectors/exports';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => {
    return {
        content: {
            flexDirection: 'column'
        },

        formField: {
            width: '100%',
            maxWidth: 600
        },
        avatarContainer: {
            width: 100,
            height: 100,
        },
        resetFormTitle: {
            marginBottom: theme.spacing(2)
        },
        emailWarning: {
            marginTop: theme.spacing(1),
            color: theme.palette.warning.light,
        },
        emailResend: {
            cursor: 'pointer',
            color: theme.palette.secondary.main
        },
        emailResendDisabled: {
            color: theme.palette.grey[600]
        },
        headerIcon: {
            marginBottom: -3
        },

        flexCenter: {
            display: 'flex',
            justifyContent: 'center'
        },
    }
});

function UserInfo(props) {
    const { user, getTimezones } = props;
    const classes = useStyles();

    const [isOpened, setOpened] = useState(true);
    const [isDeletingImage, setIsDeletingImage] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [isUploadOpened, setIsUploadOpened] = useState(false);

    const [timezones, setTimezones] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getTimezones()
            .then(data => setTimezones(data));
    }, [getTimezones, setTimezones])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="userinfo-content"
                id="userinfo-header"
            >
                <LCTypography variant="h5" ><AccountBoxIcon className={classes.headerIcon} /> Personal</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <SettingsSection title={t("Profile Photo")}>
                    <Box className={classes.formField} display="flex" alignItems="center">
                        <AvatarEditable
                            classes={{ root: classes.avatarContainer }}
                            src={user.options.primary_image_public_url}
                            onClick={() => setIsUploadOpened(true)}
                        />
                        <Box ml="auto" mr={7}>
                            {hasIcon(user)
                                && <ButtonLoader
                                    submitting={isDeletingImage}
                                    onClick={() => {
                                        setIsDeletingImage(true);
                                        props.handleImageDelete()
                                            .then(() => setIsDeletingImage(false));
                                    }}
                                    variant='text'
                                    color="primary"
                                    style={{ marginRight: 12 }}>
                                    Remove Photo
                                </ButtonLoader>}
                            <Button
                                onClick={() => setIsUploadOpened(true)}
                                variant='contained'
                                color="primary">
                                {t('Change Photo')}
                            </Button>
                        </Box>
                    </Box>
                    {isUploadOpened
                        && <ImageUploadDialog
                            title={t('Edit Profile Image')}
                            src={user.options.primary_image_public_url}
                            open={isUploadOpened}
                            aspect={Global.imageAspectProfilePhoto}
                            askForCameraOnAndroid={true}
                            handleDelete={() => props.handleImageDelete()
                                .then(() => setIsUploadOpened(false))}
                            handleSave={image => props.handleImageUpload(image)
                                .then(() => setIsUploadOpened(false))}
                            handleClose={() => setIsUploadOpened(false)}
                        />}
                </SettingsSection>

                <SettingsSection title={t('Full Name')}>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        variant={"outlined"}
                        value={user.name}
                        validators={[required]}
                        fullWidth={true}
                        id={"fullname"}
                        handleDone={props.handleFullNameChange}
                    />
                </SettingsSection>

                <SettingsSection title={t("Email Address")}>
                    <Box className={clx(classes.formField)}>
                        <TextFieldEditable
                            classes={{
                                root: classes.formField
                            }}
                            variant={"outlined"}
                            value={user.email}
                            validators={[required, email]}
                            fullWidth={true}
                            id={"email"}
                            autoComplete={"email"}
                            handleDone={email => props.handleEmailChange(email, user.email)}
                        />
                        {!user.emailConfirmed
                            && <Box className={classes.emailWarning}>
                                <Box component="span">{t('Email is not confirmed. ')}</Box>
                                <Box component="span"
                                    className={clx(classes.emailResend, { [classes.emailResendDisabled]: isSendingEmail })}
                                    onClick={() => {
                                        if (!isSendingEmail) {
                                            setIsSendingEmail(true);
                                            props.handleEmailResend()
                                                .finally(() => setIsSendingEmail(false))
                                        }
                                    }}>{t('Resend link')}</Box>
                            </Box>}
                    </Box>
                </SettingsSection>

                <SettingsSection title={t("Mobile Phone")}>
                    <UserPhoneInfo
                        label={null}
                        classes={{
                            phoneGrid: classes.flexCenter,
                            confirmGrid: classes.flexCenter,
                            phoneField: classes.formField,
                            confirmField: classes.formField
                        }} />
                </SettingsSection>

                <SettingsSection title={t("About Me")}>
                    <LCTypography style={{ marginBottom: 8 }}>This text will appear in your public page</LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        multiline
                        rows={2}
                        variant={"outlined"}
                        value={user.description}
                        validators={[required]}
                        fullWidth={true}
                        id={"description"}
                        handleDone={props.handleAboutMeChange}
                    />

                    <Box mt={1}>
                        <Link
                            variant='body1'
                            component={RouterLink}
                            color="secondary"
                            to={RoutePaths.public.slug(user.slug)}>
                            {t('View your Public Page')}
                        </Link>
                    </Box>

                </SettingsSection>

                <SettingsSection title={t("Job Title")}>
                    <LCTypography style={{ marginBottom: 8 }}>Your Job Title will appear in your public page</LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        variant={"outlined"}
                        value={user.title}
                        validators={[required]}
                        fullWidth={true}
                        id={"title"}
                        handleDone={props.handleTitleChange}
                    />
                </SettingsSection>

                <SettingsSection title={t("Time Zone")}>
                    <AutocompleteEditable
                        classes={{
                            root: classes.formField
                        }}
                        id={"timezone"}
                        fullWidth={true}
                        multiple={false}
                        disableCloseOnSelect={false}

                        options={timezones}

                        validators={[required]}
                        handleDone={option => props.handleTimezoneChange(option.name)}
                        value={timezones.find(t => t.name === userTimezone(user)) || null}
                        getOptionLabel={option => {
                            const { continent, city, region } = getTimezoneParts(option);

                            return [city, region, continent]
                                .filter(x => !!x)
                                .join(', ');
                        }}
                        getOptionSelected={option => userTimezone(user) === option.name}
                        renderOption={(option) => {
                            const { continent, city, region } = getTimezoneParts(option);

                            return (
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body1">
                                        {[city, region]
                                            .filter(x => !!x)
                                            .join(', ')}
                                    </Typography>
                                    <LCTypography
                                        transProps={{ momentTZOptionNameFormat: moment().tz(option.name).format('hh:mm a') }}
                                        variant="body2">
                                        {{ momentTZOptionNameFormat: moment().tz(option.name).format('hh:mm a') }}
                                    </LCTypography>
                                    <Typography variant="caption">{continent}</Typography>
                                </Box>
                            )
                        }}
                    />
                </SettingsSection>

            </AccordionDetails>
        </AccordionScrollable>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAccount: selectedAccount(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTimezones: () => lucitApi.dictionaries.getTimezones(),

        handleFullNameChange: name => {
            return dispatch(changeUsername(name))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Your full name was successfully changed')))
                });
        },
        handleTitleChange: title => {
            return dispatch(changeTitle(title))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Your title was successfully changed')))
                });
        },
        handleAboutMeChange: aboutMe => {
            return dispatch(changeAboutMe(aboutMe))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Your about me was successfully changed')))
                });
        },

        handleTimezoneChange: timezone => {
            return dispatch(changeTimezone(timezone))
                .then(() => dispatch(showSuccess(i18next.t('Your timezone was successfuly changed'))))
        },
        handleEmailChange: (email, oldEmail) => {
            if (email === oldEmail) {
                // eslint-disable-next-line no-undef
                return Promise.resolve();
            }

            return dispatch(changeEmail(email))
                .then(() => dispatch(showSuccess(i18next.t('Email confirmation link was sent'))))
                .catch(results => {
                    dispatch(showError(results.errors._error));
                    throw results;
                });
        },
        handleEmailResend: () => {
            return dispatch(resendEmail())
                .then(() => dispatch(showSuccess(i18next.t('Email confirmation link was sent'))))
                .catch(results => {
                    dispatch(showError(results.errors._error));
                    throw results;
                });
        },

        handleImageUpload: (image) => {
            return dispatch(setPrimaryImage(image))
                .then(() => dispatch(showSuccess(i18next.t('Profile photo was updated successfuly'))))
                .catch(error => {
                    dispatch(showError(i18next.t('Failed to save profile photo')));

                    throw error;
                });
        },

        handleImageDelete: () => {
            return dispatch(deletePrimaryImage())
                .then(() => dispatch(showSuccess(i18next.t('Profile photo was removed'))))
                .catch(error => {
                    dispatch(showError(i18next.t('Failed to remove profile photo')));

                    throw error;
                });
        },

        showError: (error) => dispatch(showError(error))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInfo);
