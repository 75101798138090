import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { selectedAgency } from '../../selectors/user';
import { getCampaigns } from '../../actions/agencies';
import {
    Avatar, Box, Collapse, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
    makeStyles, TextField, Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { groupByKey } from '../../helpers/array';
import { CampaignState, CampaignStateMap, RoutePaths } from '../../helpers/constants';
import { hasIcon } from '../../selectors/exports';
import { ExportIcon } from '../../components';
import { sortNumber } from '../../helpers/sorters';
import { IonButton } from '@ionic/react';
import { CampaignHistoryButton, CampaignPlayerIntegrationsDialog } from '../campaigns';
import { useTranslation } from 'react-i18next';
import { debouncePromise } from '../../helpers/async';
import { RejectButton, MarkAsTrafficCompleteButton } from '../exports';
import { useHistory } from 'react-router';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles(() => {
    return {
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain'
        },
        imageIcon: {
            width: '100%',
            height: '100%'
        }
    }
})

function AgencyCampaigns(props) {
    const { agency, campaigns, getCampaigns } = props;

    const HIDE_DRAFT_CAMPAIGNS = false;
    const HIDE_ANONYMOUS_CAMPAIGNS = true;
    const HIDE_ACCOUNTS_ATTACHED_TO_NON_OPERATOR_ACCOUNT_AGENCY = true;
    const HIDE_DRAFT_CAMPAIGNS_NOT_ATTACHED_TO_CURRENT_AGENCY = true;
    const NON_OPERATOR_ACCOUNTS_AGENCY_ID = 745;

    const [selected, setSelected] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [query, setQuery] = useState('');
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const searchDebounce = useCallback(
        debouncePromise((query) => setQuery(query), 800),
        []);

    useEffect(() => {
        setLoading(true);
        getCampaigns(agency.lcuid).then(() => setLoading(false));
    }, [agency.lcuid])

    const campaignFiltered = useMemo(() => {
        return campaigns
            .map(c => ({
                ...c,
                campaign_state: c.campaign_state === null || CampaignStateMap[c.campaign_state] === undefined
                    ? CampaignState.unknown.id
                    : c.campaign_state
            }))
            .filter(c => {

                //Hack to hide anonymous campaigns

                if (!c.account)
                    return false;

                if (HIDE_ANONYMOUS_CAMPAIGNS && c.account && c.account.account_class === "App\\LuCore\\Accounts\\AnonymousAccountClass")
                    return false;

                if (HIDE_ACCOUNTS_ATTACHED_TO_NON_OPERATOR_ACCOUNT_AGENCY) {
                    if (c.account && c.account.agency_id === NON_OPERATOR_ACCOUNTS_AGENCY_ID)
                        return false;
                }

                if (HIDE_DRAFT_CAMPAIGNS_NOT_ATTACHED_TO_CURRENT_AGENCY)
                    if (c.campaign_state === CampaignState.draft.id && c.account.agency_id !== agency.id)
                        return false;

                return searchTermInText(query, [c.name, c.account?.name]);
            })
    }, [agency.lcuid, campaigns, query, HIDE_ANONYMOUS_CAMPAIGNS]);

    const campaignGroups = useMemo(() => groupByKey(campaignFiltered, 'campaign_state'), [campaignFiltered])

    return <>
        <Box mt={2} mb={1}>
            <TextField
                variant="outlined"
                fullWidth={true}
                label={t('Search')}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                    searchDebounce(e.target.value);
                }}
            />
        </Box>

        {loading && <CircularProgressCentered />}

        {Object.keys(campaignGroups).length === 0
            && query
            && <Box mt={1}>
                <Typography>{t('No results found for "{{query}}"', { query })}</Typography>
            </Box>}

        {Object.keys(campaignGroups)
            .filter(key => CampaignStateMap[key]) //HACK we are hiding campaigns with a satatus not in constants CampaignState
            .sort((a, b) => sortNumber(CampaignStateMap[a].order, CampaignStateMap[b].order))
            .map(key => {

                //HACK we are hiding draft campaigns
                if (HIDE_DRAFT_CAMPAIGNS && CampaignStateMap[key].id === CampaignState.draft.id)
                    return null

                return <CampaignGroup
                    key={key}
                    title={`${CampaignStateMap[key].title} - ${campaignGroups[key].length}`}
                    campaigns={campaignGroups[key]}
                    isFiltering={Boolean(query)}
                    select={setSelected}
                />
            }
            )}

        <CampaignPlayerIntegrationsDialog
            open={Boolean(selected)}
            handleClose={() => setSelected(null)}
            campaign={selected
                ? { ...selected, operators: [agency] }
                : null}
            agency={agency}
        />
    </>
}

const CampaignGroup = props => {
    const { campaigns, title, select, isFiltering } = props;

    const [open, setOpen] = useState(true);

    const campaignHasParent = (campaign) => campaign.parent_exports && campaign.parent_exports.length > 0;
    const campaignHasChildren = (campaign) => campaign.child_exports && campaign.child_exports.length > 0;

    return <List disablePadding>
        <ListItem button onClick={() => setOpen(!open)}>
            <ListItemText
                primary={title}
                primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {campaigns.map(c => {

                    if (!isFiltering && campaignHasParent(c))
                        return null

                    if (campaignHasChildren(c)) {
                        return <CampaignItemParentRow campaign={c} key={c.lcuid} select={select} />
                    }

                    return <CampaignItemRow campaign={c} key={c.lcuid} select={select} />

                }
                )}
            </List>
        </Collapse>
    </List>
}

const CampaignItemParentRow = ({ campaign, select }) => {

    return <>
        <CampaignItemRow campaign={campaign} select={select} isParent={true} />
        <List disablePadding>
            {campaign.child_exports.map(c => <CampaignItemRow campaign={c} key={c.lcuid} select={select} isChild={true} />)}
        </List>
    </>

}

const CampaignItemRow = ({ campaign, select, isChild }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const history = useHistory();

    const showLinkPlayer = !isChild

    const showDateTimeCreated = campaign.campaign_state === CampaignState.draft.id;

    return <ListItem key={campaign.lcuid} style={{ paddingLeft: isChild ? 32 : 16 }} disableGutters>
        <ListItemAvatar>
            {hasIcon(campaign.account)
                && <Avatar className={classes.avatar}>
                    <img
                        src={campaign.account.options.primary_image_public_url}
                        className={classes.image}
                        alt={campaign.name}
                        title={campaign.name}
                    />
                </Avatar>}
            {!hasIcon(campaign.account)
                && <ExportIcon style={{ width: 40, height: 40 }} />}
        </ListItemAvatar>
        <ListItemText
            primary={<>
                {campaign.account?.name}
            </>}
            secondary={<>
                {showDateTimeCreated && <><DateTimeRelative
                    format="MM/DD/YY"
                    date={toLocalDateTime(campaign.created_at)}
                /> : </>}
                {campaign.name}
            </>}
        />

        <ListItemSecondaryAction>

            <IonButton
                size='small'
                fill="outline"
                onClick={() => {
                    history.push({ pathname: RoutePaths.campaign(campaign.id) })
                }}
                color="dark"
            >
                {t('View')}
            </IonButton>

            <CampaignHistoryButton campaign={campaign} />
            {showLinkPlayer && <IonButton size='small' fill="outline" onClick={() => select(campaign)}>
                {t('Link Player')}
            </IonButton>
            }
            {campaign.campaign_state === CampaignState.pendingTraffic.id
                && <>
                    <MarkAsTrafficCompleteButton
                        className={null}
                        exportItem={campaign}
                        size='small' fill="outline" color="success"
                    />
                    <RejectButton exportItem={campaign}
                        className={null}
                        size='small' fill="outline" color="danger"
                    />
                </>}
        </ListItemSecondaryAction>
    </ListItem >

}

const mapStateToProps = (state) => {
    return {
        agency: selectedAgency(state),
        campaigns: state.agencies.campaigns
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCampaigns: (id) => dispatch(getCampaigns(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyCampaigns);
