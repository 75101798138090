import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import clx from 'classnames';
import { IonCard, IonButton, IonIcon, IonCardHeader, IonGrid, IonRow, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import { Divider, Box, Paper } from '@material-ui/core';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import LCTypography from '../../components/material/LCTypography';
import { CardContent, Grid, Link } from '@material-ui/core';
import DashboardsLiveAdsList from './DashboardsLiveAdsList';
import DashboardCampaigns from './DashboardCampaigns';
import { connect } from 'react-redux';
import PostButton from '../inventory/PostButton'
import { selectedAccount } from '../../selectors/user';
import { hasExports } from '../../selectors/exports';
import { exports } from '../../selectors/dashboard';
import { activeAdsAreSubCampaigns } from '../../selectors/dashboard'
import { calendar } from 'ionicons/icons';
import QuickAddProof from '../proofs/QuickAddProof'
import { useTranslation } from 'react-i18next';
import { AccountAvatar } from '../account';
import { useSelector } from 'react-redux';
import EditDefaultCreativeButton from '../../containers/account/EditDefaultCreativeButton';
import { lucitApi } from '../../services/lucitApi';
import ReactMarkdown from 'react-markdown'
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';

const useStyles = makeStyles(theme => ({
    summary: {
        cursor: 'default !important'
    },
    content: {
        padding: 0,
        marginTop: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    homeGridContainer: {
        height: '100%',
        margin: 'auto',
        maxWidth: 900
    },
    addPhotoButton: {
        marginTop: theme.spacing(2),
        paddingRight: 16,
    },
    itemMap: {
        height: '100%',
        maxWidth: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0
        },
    },
    appWelcomeMessageHeader: {
        backgroundColor: '#000000',
        color: 'white'
    },
    dashboardCampaignsRoot: {
        width: '100%'
    },

    liveAdsPost: {
        "& .MuiCardHeader-content": {
            flex: "none",
            marginRight: 20,
        },
    },
    infiniteScrollBottomSheet: {
        padding: 0
    },
    liveAdsHeader: {
        display: 'flex',
    },
    liveAdsTitle: {
        flex: 1
    },
    userHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    userHeaderTitleAvatar: {
        marginRight: '6px'
    },
}));

const DashboardAccountNew = (props) => {
    const { loadingAds, loadingExports, exportCount,
        selectedAccount, activeAdsCount, activeAdsAreSubCampaigns } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const hasActiveAds = () => activeAdsCount > 0

    const showAppWelcomeMessage = () => {
        return selectedAccount.app_welcome_message ? true : false
    }

    const hasExports = () => exportCount > 0

    const showCampaignSection = () => hasExports() && !loadingExports

    const showCampaignButNoAdsTip = () => !selectedAccount.is_parent_account &&
        !activeAdsAreSubCampaigns &&
        hasExports() &&
        !loadingExports &&
        !hasActiveAds()

    const showActiveAdsSection = () => hasExports() && !loadingExports && hasActiveAds()

    return <>

        <Grid
            container
            className={classes.homeGridContainer}
        >

            <Grid item xs={12} md={12}>
                <IonCardContent>
                    <IonCardSubtitle>{t('Welcome')}</IonCardSubtitle>
                    <IonCardTitle className={classes.userHeaderTitle}>
                        <AccountAvatar account={selectedAccount}
                            className={clx(classes.userHeaderTitleAvatar)}
                        />
                        {t(selectedAccount.name)}
                    </IonCardTitle>
                </IonCardContent>

                <Box mt={1} mb={3}>
                    <Divider />
                </Box>

                {showAppWelcomeMessage()
                    && <Box component={Paper} mb={3}>
                        <Box pl={2} pr={2} pb={2} pt={1}>
                            <ReactMarkdown>{selectedAccount.app_welcome_message}</ReactMarkdown>
                        </Box>
                    </Box>}

                <>
                    {showCampaignSection() && <PolicyFeatureToggle policy={AccountPolicy.viewCampaigns}>
                        <IonCardContent>
                            <IonCardTitle>{exportCount > 1 ? t('Campaigns') : t('Campaign')}</IonCardTitle>
                        </IonCardContent>

                        <CardContent className={classes.content}>
                            {loadingExports
                                && <CircularProgressCentered size={40} />}
                            {!loadingExports
                                && <DashboardCampaigns
                                    listProps={{
                                        classes: {
                                            root: classes.dashboardCampaignsRoot
                                        }
                                    }}
                                />}
                        </CardContent>

                        <Box mt={4} mb={4}>
                            <Divider />
                        </Box>

                        {showCampaignButNoAdsTip() &&
                            <CampaignButNotAdsTip />
                        }

                        {!selectedAccount.is_parent_account &&
                            <AddDefaultCreative hasCampaigns={hasExports()} />
                        }

                        {showActiveAdsSection() && <>

                            <div className={classes.liveAdsHeader}>
                                <IonCardContent className={classes.liveAdsTitle}>
                                    <IonCardSubtitle>
                                        {(loadingAds || !activeAdsCount) ? null : activeAdsCount + t(' Selected')}
                                    </IonCardSubtitle>
                                    <IonCardTitle>{activeAdsAreSubCampaigns ? t('Sub Campaigns') : t('Ads')}</IonCardTitle>
                                </IonCardContent>

                                <PostButton
                                    buttonProps={{
                                        className: classes.addPhotoButton
                                    }} />
                            </div>

                        </>
                        }

                    </PolicyFeatureToggle>}

                    {showActiveAdsSection() && <>

                        {loadingAds
                            && <CircularProgressCentered size={40} />}
                        {!loadingAds && hasExports() && (hasActiveAds()
                            && <DashboardsLiveAdsList />)}

                    </>}

                </>

                <AccountTipsCard hasCampaigns={loadingExports || hasExports()} />

            </Grid>
        </Grid>
    </>;
}

const AccountTipsCard = ({ hasCampaigns }) => {
    const classes = useStyles();
    const [newCampaignDialogOpen, setNewCampaignDialogOpen] = useState(false)
    const { t } = useTranslation();

    if (hasCampaigns)
        return null

    return (
        <PolicyFeatureToggle policy={AccountPolicy.createCampaigns}>
            <Box mb={2}>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Setup your Campaign Screens</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <LCTypography>
                            {hasCampaigns ? <>
                                Add additional campaigns with different screens and settings
                            </> : <>To get started adding creatives and creating templates, first,
                                create a <strong>New Campaign</strong> and select the screens that this account will be running on.</>}
                        </LCTypography>
                        <IonGrid>
                            <IonRow>
                                <IonButton
                                    className={classes.campaignButton}
                                    onClick={() => setNewCampaignDialogOpen(true)}
                                >
                                    <IonIcon slot="start" icon={calendar}></IonIcon>
                                    {t('New Campaign')}
                                </IonButton>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>

                <QuickAddProof
                    open={newCampaignDialogOpen}
                    handleClose={() => setNewCampaignDialogOpen(false)}
                    skipAccountSelection={true}
                    newCampaignOnly={true}
                    dialogTitle={t('New Campaign')}
                />

            </Box>
        </PolicyFeatureToggle>
    )
}

const CampaignButNotAdsTip = ({ hasCampaigns }) => {

    const classes = useStyles();

    if (hasCampaigns)
        return null

    return (
        <PolicyFeatureToggle policy={AccountPolicy.createContent}>
            <Box mb={4}>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Add your Creatives</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <LCTypography>
                            Your campaign is setup but no ads have been posted to it.
                            <br /><br />
                            Click into the campaign above to select from your existing ads, or use the <b>Post Ad</b> button to get started!
                        </LCTypography>

                        <PostButton
                            buttonProps={{
                                className: classes.addPhotoButton
                            }} />
                    </IonCardContent>
                </IonCard>
            </Box>
        </PolicyFeatureToggle>
    )
}

const AddDefaultCreative = ({ hasCampaigns }) => {

    const [hasDefaultCreative, setHasDefaultCreative] = useState(false)
    const [checkingForDefaultCreative, setCheckingForDefaultCreative] = useState(true)

    if (!hasCampaigns)
        return null

    const { t } = useTranslation();

    const selectedAccountObject = useSelector(selectedAccount)

    useEffect(() => {
        if (!selectedAccountObject.id)
            return

        setCheckingForDefaultCreative(true)
        lucitApi.accounts.hasDefaultCreative(selectedAccountObject.id)
            .then((accountHasDefaultCreative) => {
                setHasDefaultCreative(accountHasDefaultCreative)
            })
            .finally(() => {
                setCheckingForDefaultCreative(false)
            })
    }, [selectedAccountObject.id])

    if (checkingForDefaultCreative || hasDefaultCreative)
        return null

    return (
        <PolicyFeatureToggle policy={AccountPolicy.update} mode="hide">
            <Box mb={4}>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Add a backup default creative</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <Box mb={2}>
                            <LCTypography>
                                Prevent empty screens by setting a default creative
                                <br />
                                <br />
                                This creative will be used in the event that no other creatives
                                are available to display because of schedule issues,
                                data limitations or any other reason where your campaign is empty of eligible creatives
                                <br />
                                <br />
                                Setting a default creative is <strong>strongly</strong> recommended -
                                Learn more at <Link
                                    variant='body2'
                                    color="secondary"
                                    href={'https://www.lucit.cc/post/what-is-a-default-creative'}>
                                    {t('What is a Default Creative?')}
                                </Link>

                            </LCTypography>
                        </Box>

                        <EditDefaultCreativeButton account={selectedAccountObject} />

                    </IonCardContent>
                </IonCard>
            </Box>
        </PolicyFeatureToggle>
    )
}

const mapStateToProps = state => {
    return {
        loadingAds: state.dashboard.loadingAds,
        activeAdsCount: state.dashboard.activeAds ? state.dashboard.activeAds.length : 0,
        activeAdsAreSubCampaigns: activeAdsAreSubCampaigns(state.dashboard.activeAds),
        exportCount: exports(state).length,
        loadingLocations: state.dashboard.loadingLocations,
        loadingExports: state.dashboard.loadingExports,
        selectedAccount: selectedAccount(state),
        hasExports: hasExports(state)
    };
}

const mapDispatchToProps = () => {
    return {}
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DashboardAccountNew
    );
