import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import NewDeviceLayoutDialog from './NewDeviceLayoutDialog';
import { useTranslation } from 'react-i18next';

function AddDeviceLayoutButton() {
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const { t } = useTranslation();

    return <>
        <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setIsNewDialogOpen(true)}>
            {t('Add Device Layout')}
        </Button>

        {isNewDialogOpen
            && <NewDeviceLayoutDialog
                open={isNewDialogOpen}
                handleClose={() => setIsNewDialogOpen(false)} />}
    </>
}

export default AddDeviceLayoutButton;
