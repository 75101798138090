import React from 'react';
import { Box, Link } from '@material-ui/core';
import ItemCombinedFilter from './ItemCombinedFilter';
import { useSelector } from 'react-redux';
import { selectedProfileIsAccount } from '../../selectors/user';
import { RoutePaths } from '../../helpers/constants';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';

function ItemFilterBuilder({ schedules, setSchedules, digitalBoardFilter, setDigitalBoardFilter, accountId }) {

    const isAccountProfile = useSelector(selectedProfileIsAccount);

    return (
        <>
            {isAccountProfile
                &&
                <LCTypography variant="body2">
                    Use these settings to control this item based on specific
                    data from your data source apps.
                    <br />Create a set of custom conditions to trigger
                    when this item will start and stop.
                </LCTypography>
            }
            <Box mt={2}>

                <hr />

                <ItemCombinedFilter
                    schedules={schedules}
                    setSchedules={setSchedules}
                    digitalBoardFilter={digitalBoardFilter}
                    setDigitalBoardFilter={setDigitalBoardFilter}
                    accountId={accountId}
                />

                <hr />

                <LCTypography variant="body2">
                    Find more data options like Weather and Sports <Link
                        component={RouterLink}
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        to={RoutePaths.appsAndData}
                    >
                        by adding data apps to your account
                    </Link>
                </LCTypography>
            </Box>
        </>
    );
}

export default ItemFilterBuilder;
