import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExportDetailsContainer from '../containers/exports/ExportDetails';
import ExportDetailsSkeleton from '../containers/exports/ExportDetailsSkeleton';
import { RoutePaths } from '../helpers/constants';
import { showError, showSuccess } from '../actions/snackbar';
import { getExport, getExportUsers, getExportBillboardsStatus } from '../actions/exports';
import ExportItemList from '../containers/exports/itemsList/ExportItemList';
import { exportsByIdSelector, isItemsIdFilterAllowed, hasDigitalBoards, showScreens } from '../selectors/exports';
import { PageTitle, WeightsEditorDialog } from '../components';
import { hasRequestedItems } from '../selectors/inventoryStatus';
import Tooltip from '../components/material/Tooltip';
import { closeScheduleBuilder } from '../actions/layout';
import { getItemRunSchedule } from '../selectors/inventory';
import { getUser } from '../selectors/user';
import CampaignBoards from '../containers/exports/CampaignBoards'
import { useTranslation } from 'react-i18next';
import {
    IonCard, IonCardHeader, IonCardTitle, IonButton, IonIcon,
    IonCardContent, IonLabel, IonSegment, IonSegmentButton
} from '@ionic/react';
import { options } from 'ionicons/icons';
import DeveloperObjectTools from '../containers/settings/DeveloperObjectTools';
import { InventoryExportCampaignClasses } from '../helpers/constants';
import { CampaignBoardsProgrammatic } from '../containers/campaigns/CampaignBoardsProgrammatic';
import i18next from 'i18next';
import { ScheduleBuilderDialog } from '../containers/itemSchedule';
import { setExportRunSchedule } from '../actions/inventory';
import { ExportStatus, CampaignState } from '../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        },

        content: {
            marginTop: theme.spacing(2),
        },

        itemsExpansion: {
            padding: 0
        },

        itemsSummaryPanel: {
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        creativeCardHeader: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            },
        },
        creativeTitleBox: {
            flex: 1,
        },
        creativeCTABox: {
            display: 'flex',
        },
        campaignPageContainer: {
            margin: 'auto',
            paddingLeft: '20px',
            paddingRight: '20px',
            maxWidth: '1400px',
            marginBottom: "300px",
            [theme.breakpoints.down('sm')]: {
                padding: '10px !important',
                maxWidth: '100% !important',
            },
        },
    }
})

function ExportDetailsGroupCampaign(props) {
    const { exportId, exportItem, getExport, getExportBillboardsStatus, hasRequestedItems,
        scheduleBuilder, closeScheduleBuilder, user } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [billboards, setBillboards] = useState([]);
    const [loading, setLoading] = useState(true);

    const isOperatorContract = exportItem.campaign_class === InventoryExportCampaignClasses.operatorContract.class

    const screenTabs = {
        creative: "creative",
        screen: "screen",
        budget: "budget",
    }

    const [screenTab, setScreenTab] = useState(screenTabs.creative);

    const [isManageWeightsOpen, setIsManageWeightsOpen] = useState(false);
    const { t } = useTranslation();

    const refreshExport = () => {
        setLoading(true);
        getExport(exportId)
            .finally(() => setLoading(false));
    }

    const refreshBillboardsStatus = () => {
        getExportBillboardsStatus(exportId)
            .then(data => setBillboards(data))
    }

    useEffect(() => {
        refreshExport();
    }, [getExport, exportId]);

    useEffect(() => {
        if (isOperatorContract)
            refreshBillboardsStatus();

    }, [isOperatorContract, exportId, exportItem.export_type, getExportBillboardsStatus])

    return (
        <div className={classes.campaignPageContainer}>
            <PageTitle title={exportItem && exportItem.name} />

            <ScheduleBuilderDialog
                open={scheduleBuilder.open}
                handleClose={closeScheduleBuilder}
                title={t('Schedule for {{title}}', { title: scheduleBuilder.item.title })}

                schedule={getItemRunSchedule(scheduleBuilder.item, scheduleBuilder.exportId)}
                setSchedule={newSchedule =>
                    dispatch(setExportRunSchedule(scheduleBuilder.item.id, scheduleBuilder.exportId, newSchedule))
                        .then(() => dispatch(showSuccess(t('Item Run Schedule was saved'))))
                        .catch(() => dispatch(showError(t('Error during saving Item Run Schedule'))))
                }
            />


            {loading && <ExportDetailsSkeleton />}
            {!loading
                && exportItem
                && <ExportDetailsContainer item={exportItem} />}

            {exportItem.status == ExportStatus.empty && (
                exportItem.campaign_state == CampaignState.running.id || exportItem.campaign_state == CampaignState.pulling.id
            ) &&
                <Alert severity="warning">
                    {t('Your campaign is running your default creative '
                        + 'because all creative schedules (date ranges) have expired or no items are selected')}
                </Alert>
            }

            <IonSegment value={screenTab}>
                <IonSegmentButton
                    value="creative"
                    onClick={() => {
                        setScreenTab(screenTabs.creative);
                    }}
                >
                    <IonLabel>Campaigns</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                    value="screen"
                    onClick={() => {
                        setScreenTab(screenTabs.screen);

                        if (isOperatorContract)
                            refreshBillboardsStatus();
                    }}
                >
                    <IonLabel>Screens</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            {screenTab === screenTabs.screen && exportItem.campaign_class !== InventoryExportCampaignClasses.lucitProgrammatic.class &&
                <div>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                {t('Choose Screens ')}{
                                    !hasDigitalBoards(exportItem) && <Alert severity="error">{t('You have no screens selected')}</Alert>
                                }

                                {!showScreens(exportItem, user) &&
                                    <Alert severity="error">
                                        {t('This is a contract campaign and you must request screen changes from your operator')}
                                    </Alert>
                                }

                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <CampaignBoards exportItem={exportItem} billboards={billboards} allowEdit={showScreens(exportItem, user)} />
                        </IonCardContent>
                    </IonCard>
                </div>
            }

            {screenTab === screenTabs.screen && exportItem.campaign_class === InventoryExportCampaignClasses.lucitProgrammatic.class &&
                <div>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                {t('Screens ')}{

                                }
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <CampaignBoardsProgrammatic campaign={exportItem} />
                        </IonCardContent>
                    </IonCard>
                </div>
            }

            {screenTab === screenTabs.creative &&

                <div>
                    <IonCard>
                        <IonCardHeader className={classes.creativeCardHeader}>
                            <Box className={classes.creativeTitleBox}>
                                <IonCardTitle>
                                    Campaigns
                                </IonCardTitle>
                            </Box>
                            <Box className={classes.creativeCTABox}>
                                {isItemsIdFilterAllowed(exportItem)
                                    && <Tooltip
                                        title={!hasRequestedItems
                                            ? t('You don\'t have any items in campaign')
                                            : t('Adjust Frequency')}
                                    >
                                        <IonButton
                                            disabled={!hasRequestedItems}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setIsManageWeightsOpen(true);
                                            }}
                                            color="secondary"
                                            size="small">
                                            <IonIcon slot="start" icon={options} />
                                            {t('Frequency')}
                                        </IonButton>
                                    </Tooltip>}
                            </Box>

                        </IonCardHeader>

                        <IonCardContent className={classes.itemsExpansion} >
                            <ExportItemList exportId={exportId} />
                        </IonCardContent>
                    </IonCard>

                    <WeightsEditorDialog
                        title={t('Adjust Frequency')}
                        open={isManageWeightsOpen}
                        handleClose={() => setIsManageWeightsOpen(false)}
                        exportId={exportId}
                    />
                </div>
            }

            <DeveloperObjectTools object={exportItem} additionalFields={[
                {
                    label: 'hash_id',
                    value: exportItem.hash_id,
                }
            ]} />
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { exportId } = ownProps;

    return {
        exportId,
        exportItem: exportsByIdSelector(state)(exportId) || {},

        hasRequestedItems: hasRequestedItems(state)(exportId),

        scheduleBuilder: state.layout.scheduleBuilder,
        user: getUser(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeScheduleBuilder: () => dispatch(closeScheduleBuilder()),

        getExport: id => dispatch(getExport(id))
            .catch(error => {
                if (error.isOffline) {
                    ownProps.history.push({ pathname: RoutePaths.noInternet });
                    throw error;
                }

                dispatch(showError(i18next.t("Export with id {{eID}} was not found.", { eID: id })));
                throw error;
            }),
        getExportUsers: id => dispatch(getExportUsers(id)),
        getExportBillboardsStatus: id => dispatch(getExportBillboardsStatus(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ExportDetailsGroupCampaign
);
