import React, { useState } from 'react';
import { makeStyles, ListItemAvatar, Avatar, Typography, Box, Menu } from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { connect } from 'react-redux';
import { ArrowDropDown } from '@material-ui/icons';
import { AccountListItems } from '.';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { accountsTreeSearch } from '../../selectors/user';
import { AccountIcon } from '../../components';
import { sortNumber } from '../../helpers/sorters';

const useStyles = makeStyles((theme) => ({
    selectedAccountBox: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: 'fit-content',
        padding: theme.spacing(1, 1.5),
        borderRadius: theme.shape.borderRadius,

        '&:hover': {
            background: theme.palette.action.hover
        }
    },
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'center'
    },
}));

const AccountSelectField = ({ agencyId, value, onChange, accountsTreeSearch }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const onClose = () => {
        setAnchorEl(null);
    };

    const accountsTree = useMemo(() => accountsTreeSearch('', agencyId).sort((a, b) => sortNumber(a.order, b.order)),
        [accountsTreeSearch, agencyId]);

    return <>
        <SelectedAccount
            account={value}
            onClick={(e) => setAnchorEl(e.target)}
        />

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <AccountListItems
                accounts={accountsTree}
                selected={value}
                handleChange={a => {
                    onChange(a);
                    setAnchorEl(null);
                }}
                level={0}
            />
        </Menu>
    </>
}

const SelectedAccount = ({ account, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return <Box className={classes.selectedAccountBox} onClick={onClick}>
        <ListItemAvatar>
            {account && hasIcon(account)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={account.options.primary_image_public_url}
                        className={classes.image}
                        alt={account.name}
                        title={account.name}
                    />
                </Avatar>
                : <Avatar>
                    <AccountIcon />
                </Avatar>}
        </ListItemAvatar>
        <Typography
            color="secondary"
            variant="button"
            style={{ fontWeight: 'bold' }}
        >{account ? account.name : t('Choose Account')}</Typography>
        <Box mt={0.5} ml={1} >
            <ArrowDropDown color='secondary' />
        </Box>
    </Box>
}

const mapStateToProps = state => {
    return {
        accountsTreeSearch: (search, agencyId) => accountsTreeSearch(state, search, agencyId)
    };
}

export default connect(
    mapStateToProps
)(AccountSelectField);
