/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function FileExcel(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M23.9907 6.41296H14.7469V12.0005H23.9907V6.41296Z" fill="#21A365" />
        <path d="M23.9907 12H14.7469V17.5875H23.9907V12Z" fill="#107C41" />
        <path d="M14.7466 6.41296H5.52161V12.0005H14.7466V6.41296Z" fill="#107C41" />
        <path d="M14.7468 17.5875V12H5.54053V17.5875V18.3937V22.0687C5.54053 22.6687 6.02803 23.1562 6.62803 23.1562H22.8843C23.4843 23.1562 23.9718 22.6687 23.9718 22.0687V17.5875H14.7468Z" fill="#185B37" />
        <path d="M22.8843 0.84375H14.728V6.43125H23.9905V1.93125C23.9718 1.33125 23.4843 0.84375 22.8843 0.84375Z" fill="#33C481" />
        <path d="M14.7469 0.84375H6.64695C6.04695 0.84375 5.55945 1.33125 5.55945 1.93125V6.4125H14.7657V0.84375H14.7469Z" fill="#21A365" />
        <path d="M11.1468 18.0938H1.20928C0.553027 18.0938 0.00927734 17.55 0.00927734 16.8937V7.10625C0.00927734 6.45 0.553027 5.90625 1.20928 5.90625H11.1468C11.803 5.90625 12.3468 6.45 12.3468 7.10625V16.8937C12.3468 17.55 11.8218 18.0938 11.1468 18.0938Z" fill="#17864C" />
        <path d="M7.58393 15.45L6.85268 14.1188C6.55268 13.5938 6.36519 13.2375 6.15894 12.8438H6.14019C5.97144 13.2375 5.80269 13.5938 5.52144 14.1188L4.86519 15.45H3.44019L5.46519 12.0563L3.49644 8.71875H4.94019L5.67144 10.1063C5.89644 10.5188 6.06519 10.8563 6.23394 11.2313H6.27144C6.45894 10.8188 6.59019 10.5188 6.81519 10.1063L7.54643 8.71875H8.97144L6.96519 12L9.04643 15.45H7.58393Z" fill="white" />
        <path opacity="0.2" d="M12.4964 6.99329C12.4964 7.03079 12.4964 7.06829 12.4964 7.10579V16.8933C12.4964 17.5495 11.9527 18.0933 11.2964 18.0933H5.69019V19.162H12.2902C12.9464 19.162 13.4902 18.6183 13.4902 17.962V8.17454C13.5089 7.57454 13.0589 7.06829 12.4964 6.99329Z" fill="#414549" />
    </SvgIcon>
}
