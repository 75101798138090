import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { History } from '@material-ui/icons';
import CampaignHistoryDialog from './CampaignHistoryDialog';
import { IonButton } from '@ionic/react';

function CampaignHistoryButton({ campaign }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return <>
        <IonButton size='small'
            fill="outline"
            onClick={() => setIsDialogOpen(true)}
            color="dark">
            <History slot="icon-only" />
        </IonButton>

        {isDialogOpen
            && <CampaignHistoryDialog
                campaign={campaign}
                open={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)} />}
    </>
}

CampaignHistoryButton.propTypes = {
    campaign: PropTypes.object.isRequired
}

export default CampaignHistoryButton;
