import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import TextField from "@material-ui/core/TextField";
import { addCustomField } from '../../actions/designer';
import CustomFieldCodeEditor from './CustomFieldCodeEditor';
import { Designer } from "../../helpers/constants"
import { getUniqueId } from './DesignerElements'

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

const AddCustomFieldDialog = ({ open, handleClose }) => {

    const [error, setError] = useState(null);

    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [customFieldId, setCustomFieldId] = useState('');
    const [customFieldName, setCustomFieldName] = useState('');
    const [customFieldPlaceholder, setCustomFieldPlaceholder] = useState('');
    const [customFieldCode, setCustomFieldCode] = useState('');
    const [customFieldFields, setCustomFieldFields] = useState([]);

    useEffect(() => {
        if (open) {
            const customFieldId = Designer.CustomFieldTemplate.object + '_' + getUniqueId();
            setCustomFieldId(customFieldId);
        }
    }, [open]);

    const validateValues = () => {
        if (customFieldName === '') {
            alert(t('Please enter a custom field name'));
            return false;
        }

        if (customFieldCode === '') {
            alert(t('Please enter a custom field code'));
            return false;
        }

        return true;
    }

    const addNewCustomField = () => {

        if (!validateValues())
            return;

        const customField = {
            ...Designer.CustomFieldTemplate,
            ...{
                id: customFieldId,
                property: customFieldCode,
                macro: customFieldCode,
                macroCode: "{" + customFieldCode + "}",
                name: customFieldName,
                placeholder: customFieldPlaceholder,
                fields: customFieldFields
            }
        }

        dispatch(addCustomField(customField));
        handleClose();
    }

    return (
        <>
            <GenericDialog
                title={t('Add Custom Field')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: true,
                    maxWidth: 'md'
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<Grid container spacing={5}>
                    <Grid item xs={12}>
                        <LCTypography>
                            This is an advanced feature, please use with caution
                        </LCTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Custom Field Name')}
                                    value={customFieldName}
                                    onChange={e => setCustomFieldName(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={2}></Grid>

                            <Grid item xs={5}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Placeholder')}
                                    value={customFieldPlaceholder}
                                    onChange={e => setCustomFieldPlaceholder(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFieldCodeEditor
                                    customFieldCode={customFieldCode}
                                    setCustomFieldCode={setCustomFieldCode}
                                    customFieldFields={customFieldFields}
                                    setCustomFieldFields={setCustomFieldFields}
                                    setError={setError}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                ActionsComponent={<>
                    <Button onClick={() => {
                        handleClose();
                    }}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={error}
                        onClick={() => {
                            addNewCustomField()
                        }}
                        color="primary">
                        {t('Save')}
                    </Button>
                </>}
            />
        </>
    )
}

export default AddCustomFieldDialog;
