import React from 'react';
import { Business, Link, Search } from "@material-ui/icons";
import { SearchType } from "../../helpers/constants";
import { AccountIcon } from "../icons";

export const SearchTypeIcons = {
    [SearchType.link.type]: <Link />,
    [SearchType.account.type]: <AccountIcon />,
    [SearchType.agency.type]: <Business />,
    [SearchType.others.type]: <Search />,
}