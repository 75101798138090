import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ApplicationAvatar from './ApplicationAvatar';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { searchTermInText } from '../../helpers/string';
import ApplicationStatusBadge from './ApplicationStatusBadge';
import FlareIcon from '@material-ui/icons/Flare';

const NewAppItem = ({ app, selectApp, devMode }) => {

    const [appSelected, setAppSelected] = useState(false);

    const MAX_DESCRIPTION_LENGTH = 128;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return <Grid key={app.lcuid} item xs={12} md={4} lg={3}>
        <IonCard
            onClick={() => {
                setAppSelected(true);
                selectApp(app)
            }}
            button
            style={{ height: '100%' }}>
            <Box pl={2} pt={2} display="expand" justifyContent="space-between" sx={{ py: isXs ? "24px" : "80px", m: "auto" }}>
                <ApplicationAvatar app={app} style={{
                    width: isXs ? 50 : 150,
                    height: isXs ? 50 : 150,
                    margin: "auto"
                }} />
                {appSelected && <CircularProgressCentered />}
            </Box>

            <IonCardHeader>
                <IonCardTitle>{app.name}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {Boolean(app.premium) &&
                    <Box display="flex" alignItems="center" mb={1}>
                        <FlareIcon style={{ color: "#4caf50" }} />
                        <Box ml={1} style={{ fontWeight: "bold", color: "#4caf50" }}>Premium App</Box>
                    </Box>}
                {
                    app.description && app.description.length > MAX_DESCRIPTION_LENGTH
                        ? app.description.substring(0, MAX_DESCRIPTION_LENGTH) + '...'
                        : app.description
                }
                {devMode && <Box mt={2}><ApplicationStatusBadge application={app} hideBadgeIfActive={true} /></Box>}
            </IonCardContent>
        </IonCard>
    </Grid>

}

const ApplicationList = (props) => {
    const { apps, selectApp, devMode } = props;

    const { t } = useTranslation();

    const [query, setQuery] = useState('');

    const [showQueryBox, setShowQueryBox] = useState(false);

    const appMatchesQuery = (app) => {
        return searchTermInText(query, [
            app.name,
            app.description,
            app.application_class_description,
            ...(app.capabilities ?
                app.capabilities?.map(c => c.name + c.description)
                : [])
        ]);
    };

    useEffect(() => {
        setQuery('');
    }, [])

    useEffect(() => {

        const APP_COUNT_BEFORE_SHOWING_SEARCH = 12;

        if (apps && apps.length > APP_COUNT_BEFORE_SHOWING_SEARCH)
            setShowQueryBox(true);
    }, [apps])

    return (
        <Box mt={2}>
            <Grid container spacing={3}>

                {apps === null && <Grid item xs={12}>
                    <Box mt={8} mb={8}>
                        <CircularProgressCentered />
                    </Box>
                </Grid>}

                {showQueryBox &&
                    <Grid item xs={12}>
                        <Box mb={1} ml={1} mr={1}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                required={true}
                                label={t('Search')}
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                        </Box>
                    </Grid>
                }

                {apps && apps
                    .filter(x => appMatchesQuery(x))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(a => (<NewAppItem devMode={devMode} app={a} key={a.lcuid} selectApp={() => selectApp(a)} />))}
            </Grid>
        </Box>
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationList)
