import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { deleteItem } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';

function InventoryItemCardContentPhotoStream() {
    return <Box p={0}>
    </Box>;
}

InventoryItemCardContentPhotoStream.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteItem: () => dispatch(deleteItem(props.item))
            .then(() => dispatch(showSuccess(`"${props.item.title}" was successfully deleted`)))
            .catch(error => {
                dispatch(showError('Unexpected error happened...'));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemCardContentPhotoStream
);