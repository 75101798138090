import localStorage from "redux-persist/lib/storage";
import { createMigrate } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { FEATURE_TOGGLE_ENABLE, FEATURE_TOGGLE_DISABLE, USER_INFO } from "../helpers/actionTypes";
import { Features } from "../helpers/constants";

export const initialState = {
    features: {
        [Features.searchBarInHeader]: false
    }
}

const migrations = {
    // Enable New Dashboard by default

}

export const persistConfig = {
    key: 'features',
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations),
    version: 1
}

export default function featureToggle(state = initialState, action) {
    switch (action.type) {
        case FEATURE_TOGGLE_ENABLE:
            return Object.assign({},
                state,
                {
                    features: Object.assign({}, state.features, { [action.feature]: true }),
                });
        case FEATURE_TOGGLE_DISABLE:
            return Object.assign({},
                state,
                {
                    features: Object.assign({}, state.features, { [action.feature]: false }),
                });

        case USER_INFO:
            {
                const featureKeys = action.options
                    && action.options.features
                    && Object.keys(action.options.features);

                return Object.assign({},
                    state,
                    {
                        features: {
                            ...state.features,
                            ...(featureKeys || []).reduce((prev, curr) => ({
                                ...prev,
                                [Features[curr]]: action.options.features[curr].enabled
                            }), {})
                        }
                    });
            }

        default:
            return state
    }
}
