import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { LikeInCircleIcon } from '../../components/icons';
import { connect } from 'react-redux';
import { commentsSelector, likesSelector } from '../../selectors/objects';
import { getReactions } from '../../actions/objects';

const ReactionsBar = (props) => {
    const { lcuid, className, onCommentCountClick } = props;
    const { isLoggedIn, getReactions } = props;

    const likesCount = props.likesCount || props._likesCount(lcuid);
    const commentsCount = props.commentsCount || props._commentsCount(lcuid);

    useEffect(() => {
        if (isLoggedIn && props.likesCount == null) {
            getReactions(lcuid);
        }

    }, [isLoggedIn, lcuid, props.likesCount]);

    if (likesCount == 0 && commentsCount == 0) {
        return null;
    }

    return <Box
        className={className}
        height={32}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display='flex' alignItems={'center'}>
            {likesCount > 0
                && <>
                    <LikeInCircleIcon style={{ width: 18, marginRight: 8 }} />
                    <LCTypography variant="body2">
                        {{ likesCount }}
                    </LCTypography>
                </>}
        </Box>
        <Box>
            {commentsCount > 0
                && <>
                    <Link
                        component="button"
                        variant="body2"
                        color="inherit"
                        onClick={() => {
                            onCommentCountClick && onCommentCountClick();
                        }}
                    >
                        {commentsCount === 1 && `1 Comment`}
                        {commentsCount > 1 && `${commentsCount} Comments`}
                    </Link>
                </>}
        </Box>
    </Box>;
};

ReactionsBar.propTypes = {
    lcuid: PropTypes.string.isRequired,
    likesCount: PropTypes.number,
    commentsCount: PropTypes.number,

    onCommentCountClick: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        _commentsCount: lcuid => (commentsSelector(state)(lcuid) || []).length,
        _likesCount: lcuid => (likesSelector(state)(lcuid) || []).length
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReactions: (lcuid) => dispatch(getReactions(lcuid)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReactionsBar);
