import { lucitApi } from "../services/lucitApi";
import { selectedAccountIds } from "../selectors/user";
import {
    FEEDS_SAVE_SCROLL_POSITION, FEEDS_NEXT_PAGE_RESULT, FEEDS_NEXT_PAGE_REQUEST,
    FEEDS_SEARCH_RESULT, FEEDS_SEARCH_REQUEST, FEEDS_RUN, FEEDS_GETBYID_RESULT, FEEDS_STATUS_UPDATE,
    FEEDS_SET_DEFAULT_TEMPLATE, FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES, FEEDS_UPDATE_PARAM_VALUES,
    FEEDS_DELETE, INVENTORY_INVALIDATE, FEEDS_UPDATE, FEEDS_UPDATE_OVERRIDE_VALUES
} from "../helpers/actionTypes";
import { filters, feedByIdSelector, hasMultipleDriveTemplates, multipleDriveTemplates } from "../selectors/feeds";

export function runFeed(id) {
    return dispatch => {
        return lucitApi.feeds.run(id)
            .then(() => {
                dispatch({
                    type: FEEDS_RUN,
                    id
                })
            });
    }
}

export function getFeeds(data) {
    return (_, getState) => {
        const state = getState();

        const filters = {
            active: 1,
            accountId: selectedAccountIds(state),
            ...data
        }

        return lucitApi.getFeeds(filters);
    }
}

export function getFeed(id) {
    return (dispatch) => {
        return lucitApi.getFeed(id)
            .then(data => {
                dispatch({
                    ...data,
                    type: FEEDS_GETBYID_RESULT
                });
                return data;
            });
    }
}

export function setFeedStatus(id, status) {
    return dispatch => {
        dispatch({
            type: FEEDS_STATUS_UPDATE,
            id,
            status
        })
    }
}

export function updateFeedName(id, feedName) {
    return dispatch => {
        return lucitApi.feeds.updateFeed(id, {
            name: feedName
        })
            .then((inventory_feed) => {
                dispatch({
                    type: FEEDS_UPDATE,
                    id,
                    ...inventory_feed,
                })
            });
    }
}

export function updateFeedNameAndSchedule(id, feedName, scheduleCron) {
    return dispatch => {
        return lucitApi.feeds.updateFeed(id, {
            name: feedName,
            schedule: scheduleCron
        })
            .then((inventory_feed) => {
                dispatch({
                    type: FEEDS_UPDATE,
                    id,
                    ...inventory_feed,
                })
            });
    }
}

export function setDefaultDriveTemplate(id, templateLcuid) {
    return dispatch => {
        return lucitApi.feeds.setDefaultDriveTemplate(id, templateLcuid)
            .then((data) => {
                dispatch({
                    type: FEEDS_SET_DEFAULT_TEMPLATE,
                    id,
                    ...data.inventory_feed,
                })
            });
    }
}

export function addAdditionalDriveTemplate(id, templateLcuid) {

    return (dispatch, getState) => {

        const state = getState();

        const feed = feedByIdSelector(state)(id)

        const currentTemplates = hasMultipleDriveTemplates(feed) ? multipleDriveTemplates(feed) : [];

        currentTemplates.push({
            template_lcuid: templateLcuid,
            filter_fields: [],
            filter_functions: []
        })

        return lucitApi.feeds.setMultipleDriveTemplates(id, currentTemplates)
            .then((data) => {
                dispatch({
                    type: FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES,
                    id,
                    ...data.inventory_feed,
                })
            });
    }
}

export function updateAdditionalDriveTemplates(id, templates) {
    return dispatch => {
        return lucitApi.feeds.setMultipleDriveTemplates(id, templates)
            .then((data) => {
                dispatch({
                    type: FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES,
                    id,
                    ...data.inventory_feed,
                })
            });
    }
}

export function updateFeedParamValues(id, feedParamValues) {
    return dispatch => {
        return lucitApi.feeds.setFeedParamValues(id, feedParamValues)
            .then((inventory_feed) => {
                dispatch({
                    type: FEEDS_UPDATE_PARAM_VALUES,
                    id,
                    ...inventory_feed,
                })
            });
    }
}

export function updateFeedSettingsOverrides(id, settingsOverrides) {
    return dispatch => {
        return lucitApi.feeds.setFeedSettingsOverrides(id, settingsOverrides)
            .then((inventory_feed) => {
                dispatch({
                    type: FEEDS_UPDATE_OVERRIDE_VALUES,
                    id,
                    ...inventory_feed,
                })
            });
    }
}

export function deleteAllInventoryForFeed(feed) {
    return dispatch => {
        return lucitApi.feeds.deleteInventoryForFeed(feed.id)
            .then(() => {
                dispatch({
                    type: INVENTORY_INVALIDATE,
                })
            });
    }
}

export function deleteFeed(feed) {
    return dispatch => {
        return lucitApi.objects.delete(feed.lcuid)
            .then(() => dispatch({
                type: FEEDS_DELETE,
                feed
            }))
    }
}

export function getFeedRuns(id, data, changed_only = 1) {
    return () => {
        return lucitApi.getFeedRuns(id, { ...data, changed_only: changed_only })
            .then(runs => runs.data);
    }
}

export function getFeedRun(id) {
    return () => {
        return lucitApi.getFeedRun(id);
    }
}

export function search(data) {
    return (dispatch, getState) => {
        dispatch(feedsSearchRequest(data));

        const filters = {
            per_page: 20,
            accountId: selectedAccountIds(getState()),
            ...data
        };

        return lucitApi.getFeeds(filters)
            .then(data => dispatch(feedsSearchResult(data)));
    }
}

export function loadPage(page) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(feedsNextPageRequest());

        const data = {
            accountId: selectedAccountIds(state),
            page: page,

            ...filters(state)
        };

        return lucitApi.getFeeds(data)
            .then(data => dispatch(feedsNextPageResult(data)))
    }
}

export function feedsSearchRequest(filters) {
    return {
        type: FEEDS_SEARCH_REQUEST,
        filters
    }
}

export function feedsSearchResult(data) {
    const { current_page, last_page, per_page, ...rest } = data;

    return {
        type: FEEDS_SEARCH_RESULT,
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
        ...rest
    }
}

export function feedsNextPageRequest() {
    return {
        type: FEEDS_NEXT_PAGE_REQUEST,
    }
}

export function feedsNextPageResult(data) {
    return {
        ...feedsSearchResult(data),

        type: FEEDS_NEXT_PAGE_RESULT
    }
}

export function feedsSaveScroll(scrollY) {
    return {
        type: FEEDS_SAVE_SCROLL_POSITION,
        scrollY
    }
}
