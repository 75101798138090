import { Divider } from '@material-ui/core';
import React from 'react';

const LucitFormFieldDivider = () => {

    return <>
        <Divider variant="fullWidth" />
    </>
}

export default LucitFormFieldDivider;
