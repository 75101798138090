import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, IconButton, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { reduxForm, Field, change, untouch, formValueSelector, initialize } from 'redux-form';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';

import TextField from '../../components/inputs/TextField';
import { required, email } from '../../helpers/validators';
import { registerTypeChange, registerEditPhone, registerReset, sendPhoneCode } from '../../actions/register';
import { isEmailRegisterType, isPhoneRegisterType } from '../../selectors/register';
import { RegisterTypes } from '../../reducers/register';
import { store } from '../../reducers';
import { environment } from '../../helpers/environment';
import { RoutePaths, QueryString } from '../../helpers/constants';
import { useQueryString } from '../../helpers/hooks';
import PasswordField from '../../components/inputs/PasswordField';
import { Countries, DefaultCountry } from '../../helpers/countries';
import PhoneInput from '../../components/inputs/PhoneInput';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 0)
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    confirm: {
        marginTop: theme.spacing(2),
    },

    typeButton: {
        marginBottom: theme.spacing(2)
    }
}));

const RegisterForm = (props) => {
    const { valid, error, submitting, isEmail, isPhone, codeSentTime, askForJoinCode,
        handleSubmit,
        handleEditPhone,
        initializeForm,
        onResendCode,
        onSubmit } = props;
    const { submitText = 'Register',
        hidePassword } = props;
    const classes = useStyles();
    const [resendTime, setResendTime] = useState(30);

    const countryCode = useQueryString(QueryString.countryCode) || DefaultCountry.code;
    const country = Countries.find(x => x.code === countryCode);
    const phone = useQueryString(QueryString.phoneNumber);

    const [showJoinCodeField, setShowJoinCodeField] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (phone) {
            initializeForm({
                countryCode: country.code,
                phoneCode: country.phone,
                phoneNumber: phone
            });
        } else {
            store.dispatch(registerReset())
        }
    }, [phone])

    useEffect(() => {
        let timerId = null;

        if (codeSentTime) {
            timerId = setTimeout(() => {
                setResendTime(resendTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, [codeSentTime, resendTime])

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            {isEmail && <Field
                name="email"
                validate={isEmail && [required, email]}
                component={TextField}
                normalize={value => value.trim()}
                props={{
                    label: t("Email Address"),
                    variant: "outlined",
                    margin: "normal",
                    disabled: !!codeSentTime,
                    required: true,
                    fullWidth: true,
                    id: "email",
                    autoComplete: "email"
                }}
            />}

            {askForJoinCode && !codeSentTime && showJoinCodeField && <Field
                name="join_code"
                component={TextField}
                normalize={value => value.trim()}
                props={{
                    label: t("Promo Code (optional)"),
                    variant: "outlined",
                    margin: "normal",
                    required: false,
                    fullWidth: true,
                    id: "join_code",
                    inputProps: {
                        maxLength: 20
                    }
                }}
            />}

            {isPhone && <Box display="flex" position="relative">
                <Field
                    name="phone"
                    validate={[required]}
                    component={PhoneInput}
                    props={{
                        id: "phone",
                        required: true,
                        disabled: !!codeSentTime,

                    }}
                />
                {codeSentTime
                    && <IconButton
                        onClick={handleEditPhone}
                        style={{ margin: '20px 0 8px 8px', right: 8, position: 'absolute' }}>
                        <Edit />
                    </IconButton>}
            </Box>}

            {codeSentTime
                && <>
                    <Field
                        name="code"
                        validate={[required]}
                        component={TextField}
                        normalize={value => value.trim()}
                        props={{
                            label: t("Confirmation Code"),
                            type: "code",
                            variant: "outlined",
                            margin: "normal",
                            required: true,
                            fullWidth: true,
                            id: "code",
                            autoComplete: "one-time-code",
                            inputProps: {
                                maxLength: 5
                            }
                        }}
                    />
                    {!hidePassword
                        && <Field
                            name="password"
                            validate={[required]}
                            component={PasswordField}
                            props={{
                                label: t("Password"),
                                type: "password",
                                variant: "outlined",
                                margin: "normal",
                                required: true,
                                fullWidth: true,
                                id: "password",
                                autoComplete: "new-password",
                                placeholder: t("Create your password")
                            }}
                        />}
                </>}
            <LCTypography color="error">{error}</LCTypography>

            {submitting
                && <Box className={classes.progress}>
                    <CircularProgressCentered />
                </Box>}
            {codeSentTime
                && <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                    onClick={props.handleSubmit}
                    className={classes.confirm}>{submitText}</Button>}
            {codeSentTime
                && <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={resendTime > 0}
                    onClick={() => {
                        setResendTime(60);

                        if (onResendCode) {
                            onResendCode(props.phoneNumber);
                        } else {
                            props.handleResendCode(props.phoneNumber)
                        }
                    }}
                    className={classes.submit}>{resendTime > 0 && `(${resendTime})`} {t("Resend code")}</Button>}
            {!codeSentTime
                && <Button
                    fullWidth
                    type={'submit'}
                    variant="contained"
                    color="primary"
                    disabled={submitting || !valid}
                    onClick={props.handleSubmit}
                    className={classes.submit}>{t("Send confirmation code")}</Button>}
            {!isPhone
                && <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.typeButton}
                    onClick={() => props.handleRegisterTypeChange(RegisterTypes.PHONE)}>
                    {t("Register by mobile number")}
                </Button>}
            {environment.EMAIL_REGISTRATION_ENABLED
                && !isEmail
                && <Button fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.typeButton}
                    onClick={() => props.handleRegisterTypeChange(RegisterTypes.EMAIL)}>
                    {t("Register by email address")}
                </Button>}
            <Grid container>
                <Grid item xs={3}>
                    <Link variant="body2" component={RouterLink} to={RoutePaths.contact}>
                        {t("Contact us")}
                    </Link>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                    {askForJoinCode && <Link
                        style={{ cursor: 'pointer' }}
                        color="secondary"
                        onClick={() => setShowJoinCodeField(!showJoinCodeField)}
                    >
                        {t("Promo Code?")}
                    </Link>
                    }
                </Grid>
            </Grid>
        </form>
    )
}

RegisterForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const phone = formValueSelector(FORM_REGISTER)(state, 'phone') || {};

    return {
        isEmail: isEmailRegisterType(state),
        isPhone: isPhoneRegisterType(state),
        phoneNumber: phone.phoneNumber,

        codeSentTime: state.register.codeSentTime,
    }
}

const mapDipatchToProps = dispatch => {
    return {
        initializeForm: (phone) => dispatch(initialize(FORM_REGISTER, { phone })),

        handleRegisterTypeChange: (type) => dispatch(registerTypeChange(type)),
        handleEditPhone: () => {
            dispatch(registerEditPhone());
            dispatch(change(FORM_REGISTER, 'code', '', false));
            dispatch(untouch(FORM_REGISTER, 'code'));
        },
        handleResendCode: (phoneNumber) => {
            dispatch(sendPhoneCode(phoneNumber.replace(/-/g, "")))
        }
    }
}

export const FORM_REGISTER = "register";
export default reduxForm({
    form: FORM_REGISTER, // a unique name for this form
    initialValues: {
        phone: { countryCode: DefaultCountry.code, phoneCode: DefaultCountry.phone, phoneNumber: '' }
    }
})(
    connect(
        mapStateToProps,
        mapDipatchToProps
    )(RegisterForm));
