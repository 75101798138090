import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    button: {
        padding: "0px",
        minWidth: "38px",
    },
    slider: {
        flexGrow: 1,
    },
}));

const EnhancedSlider = ({ value, onChange, onChangeCommitted,
    min, max, step, disabled, hideInput, ...otherProps }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(String(value));

    useEffect(() => {
        setInputValue(String(value));
    }, [value]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);

        const parsedValue = parseFloat(event.target.value);
        if (!isNaN(parsedValue)) {
            onChange({}, parsedValue);
        }
    };

    const handleBlur = () => {
        const parsedValue = parseFloat(inputValue);
        if (!isNaN(parsedValue)) {
            onChange({}, parsedValue);
        }
        setInputValue(String(value));
    };

    const handleIncrement = (e) => {
        let currentValue = typeof value === 'string' ? parseFloat(value) : value;
        currentValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = currentValue + step <= max ? currentValue + step : max;
        onChange(e, newValue);
        onChangeCommitted && onChangeCommitted(e, newValue);
    };

    const handleDecrement = (e) => {
        let currentValue = typeof value === 'string' ? parseFloat(value) : value;
        currentValue = isNaN(currentValue) ? 0 : currentValue;
        const newValue = (currentValue - step) >= min ? (currentValue - step) : min;
        onChange(e, newValue);
        onChangeCommitted && onChangeCommitted(e, newValue);
    };

    return (
        <div className={classes.root}>
            <Button
                disabled={disabled}
                size="small"
                className={classes.button}
                variant="outlined"
                onClick={handleDecrement}
            >-</Button>
            <Slider
                value={value}
                onChange={(event, newValue) => onChange(event, newValue)}
                onChangeCommitted={onChangeCommitted}
                min={min}
                max={max}
                step={step}
                className={classes.slider}
                disabled={disabled}
                {...otherProps}
            />
            <Button
                disabled={disabled}
                size="small"
                className={classes.button}
                variant="outlined"
                onClick={handleIncrement}
            >+</Button>
            {!hideInput
                && <TextField
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                />}
        </div>
    );
};

export default EnhancedSlider;
