import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import MaterialTable from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Link, Box, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { getPlaysByCampaign } from '../../actions/analytics';
import { useTheme } from '@material-ui/styles';
import { sortNumber, sortString } from '../../helpers/sorters';
import { Global } from '../../helpers/constants';
import TextField from '../../components/inputs/TextField';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { DateRangeInput } from '../../components/inputs/DateRangeInput'

const useStyles = makeStyles(() => {
    return {
        avatar: {
            width: 'auto',
            height: '50px',
            background: 'none',
            justifyContent: 'left'
        },
        avatarLink: {
            height: '100%'
        },
        avatarImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'left'
        }
    }
})

function PlaysStatsByCampaign(props) {
    const { timeframe, setTimeframe, timeframes, getPlaysByCampaign } = props;
    const theme = useTheme();
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    // https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
    const [columns, setColumns] = useState([]);
    const { t } = useTranslation();

    const [dateRange, setDateRange] = useState({
        "startDate": new Date(new Date().setDate(new Date().getDate() - 30)),    //30 days ago
        "endDate": new Date()
    })

    const getTimeframeOpts = () => {

        return {
            "start_date": dateRange.startDate,
            "end_date": dateRange.endDate
        }
    }

    const showDateSelector = () => timeframe === "custom"

    useEffect(() => {
        setColumns([
            {
                title: t(''),
                filtering: false,
                sorting: false,
                width: 200,
                // eslint-disable-next-line react/display-name
                render: x =>
                    <Avatar
                        className={classes.avatar}
                        variant="square"
                    >
                        <Link
                            component={RouterLink}
                            className={classes.avatarLink}
                            to={`/${x.object_lid}`}>
                            {x.object_image && <img className={classes.avatarImage} src={x.object_image} alt={x.object_name} />}
                        </Link>
                    </Avatar>,
            },
            {
                title: t('Campaign'),
                filtering: false,
                customSort: (a, b) => sortString(a.object_name, b.object_name),
                // eslint-disable-next-line react/display-name
                render: x =>
                    <Link component={RouterLink}
                        to={`/${x.object_lid}`}>
                        <Typography variant="body2" >{x.object_name}</Typography>
                    </Link>,
            },
            {
                title: t('Statistics'),
                filtering: false,
                customSort: (a, b) => sortNumber(a.total_plays, b.total_plays),
                // eslint-disable-next-line react/display-name
                render: x => <>
                    {
                        x.total_impressions > 0 ?
                            <>
                                <LCTypography transProps={{ xTotalImpressions: x.total_impressions.toLocaleString() }} variant="body2">
                                    {{ xTotalImpressions: x.total_impressions.toLocaleString() }} Impressions
                                </LCTypography>
                                <Typography component="p" variant="caption" color="textSecondary">
                                    {t('with')}
                                    {moment.duration(x.total_play_duration, 'seconds').format("hh:mm:ss", { trim: false })}
                                    {t('Time On Screen')}
                                </Typography>
                                <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }}
                                    component="p"
                                    variant="caption"
                                    color="textSecondary">
                                    via {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                                </LCTypography>
                            </>
                            :
                            <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }} variant="body2">
                                {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                            </LCTypography>
                    }
                </>
            },
        ])

    }, [classes])

    useEffect(() => {

        const controller = new AbortController();

        if (timeframe) {
            setLoading(true);
            getPlaysByCampaign(timeframe, getTimeframeOpts(), { signal: controller.signal })
                .then(data => setData(data))
                .finally(() => setLoading(false))
        }

        return () => controller.abort();

    }, [timeframe, getPlaysByCampaign, dateRange])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t('Timeframes')}
                        input={{
                            onChange: (e) => setTimeframe(e.target.value),
                            value: timeframe
                        }}
                        select={true}
                        fullWidth={true}
                        variant={"outlined"}
                    >
                        {timeframes.map(t => (
                            <MenuItem key={t.timeframe} value={t.timeframe}>
                                {t.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    {showDateSelector() &&
                        <DateRangeInput

                            meta={{}}
                            input={{
                                value: dateRange,
                                onChange: (value) => setDateRange(value)
                            }}
                            fullWidth
                            mobilePickerProps={{
                                disablePast: true,
                                autoOk: true,
                                emptyLabel: 'No End Date',
                                // eslint-disable-next-line react/display-name
                            }}
                            pickerProps={{
                                showMonthAndYearPickers: false,
                                showDateDisplay: false,
                                months: 2,
                                direction: "horizontal",
                            }}
                            format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                                ? "MMM DD"
                                : "MMM DD, YYYY"}
                        />
                    }

                </Grid>
            </Grid>

            <Box mt={3} />

            <MaterialTable
                columns={columns}
                isLoading={loading}
                data={data}
                components={{
                    OverlayLoading: props => (
                        <div>
                            <CircularProgressCentered {...props} />
                        </div>
                    )
                }}

                options={{
                    emptyRowsWhenPaging: true,
                    pageSize: Global.exportItemListSize,
                    pageSizeOptions: [5, Global.exportItemListSize, 50, 100, 250, 500],
                    debounceInterval: 100,
                    headerStyle: { backgroundColor: theme.palette.grey[50] },
                    paging: false,
                    search: false,
                    showTitle: false,
                    sorting: true,
                    selection: false,
                    toolbar: false,
                    draggable: false,
                    tableLayout: 'auto'
                }}
            />
        </Box>
    );
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPlaysByCampaign: (timeframe, timeframe_opts) => dispatch(getPlaysByCampaign(timeframe, timeframe_opts))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PlaysStatsByCampaign)
    );
