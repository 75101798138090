import React, { useEffect } from 'react';
import { lucitApi } from "../../services/lucitApi";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { DESIGNER_FONTS } from '../../helpers/actionTypes';

export const getListOfUsedFonts = (css, fonts) => {

    let usedFonts = [];

    fonts.forEach(font => {
        if (css.indexOf(font.name) > -1) {
            usedFonts.push(font);
        }
    });

    return usedFonts;

}

export const DesignerFonts = () => {
    const fonts = useSelector(state => state.designer.fonts)
    const driveTemplateLcuid = useSelector(state => state.designer.lcuid)
    const dispatch = useDispatch();

    useEffect(() => {
        lucitApi.fonts.list({
            drive_template_lcuid: driveTemplateLcuid
        })
            .then((result) => {
                dispatch({ type: DESIGNER_FONTS, fonts: result.data })
            });
    }, [])

    return <>
        {fonts
            && <Helmet>
                {fonts.map(f => <style key={f.lcuid}>{
                    fontCSS(f)
                }
                </style>)}
            </Helmet>}
    </>
}

export const fontCSS = (font) => {
    return `@font-face {
        font-family: '`+ font.name + `';
        src: url('`+ font.options.public_url + `');
      }`
}

export const injectFont = (font) => {
    const fontFace = `
    @font-face {
      font-family: '${font.name}';
      src: url('${font.options.public_url}') format('truetype');
      font-weight: normal;
      font-style: normal;
    }
  `;

    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = fontFace;
    } else {
        style.appendChild(document.createTextNode(fontFace));
    }
    document.head.appendChild(style);
};
