import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import LCTypography from '../../../src/components/material/LCTypography';
import BillboardsIcon from '@material-ui/icons/DesktopWindows';
import { lucitApi } from '../../services/lucitApi';
import { Skeleton } from '@material-ui/lab';

export const Blip = ({ exportItem, apiToken }) => {

    const [loadingBoards, setLoadingBoards] = useState(true);
    const [digitalBoards, setDigitalBoards] = useState([]);

    useEffect(() => {
        exportItem && lucitApi.exports.getExportBillboards(exportItem.id).then((digital_boards) => {
            setDigitalBoards(digital_boards);
            setLoadingBoards(false);
        })
    }, [exportItem?.id])

    return (
        <Box p={5}>

            <Box pb={3}>
                <LCTypography variant="h5">Blip</LCTypography>
            </Box>

            <LCTypography variant="body2">
                The step by step process for loading Blip campaigns is documented in <a target="_blank" rel="noreferrer"
                    href="https://www.lucit.cc/post/how-to-schedule-lucit-in-the-blip-player">
                    How to Schedule Lucit in the Blip Player</a>
            </LCTypography>
            <LCTypography variant="body2">
                For each digital board in which this campaign is scheduled, you will need to load a different ad group and an ad.
            </LCTypography>

            {loadingBoards && <Skeleton animation="wave" height={300} width={'90%'} />}

            {digitalBoards && digitalBoards.map((board, idx) => {
                return (
                    <Box p={2} key={idx}>
                        <h5><BillboardsIcon /><> </>{board.board_identifier} : {board.name}</h5>
                        <code>
                            <b>SIGN ID</b> : {board.board_identifier}<br />
                            <b>EXPORT ID</b> : {exportItem.hash_id}<br />
                            <b>SIZE</b> : {board.width}x{board.height} <br />
                            <b>AD NAME</b> : Lucit : {exportItem.account && exportItem.account.name} :
                            {board.board_identifier}<br />
                            <b>AD GROUP</b>: Ad Group : Lucit : {exportItem.account && exportItem.account.name} :
                            {board.board_identifier}<br />
                            <b>XPATH</b> : lucit-layout-drive/item-set/item/@src<br />
                            <b>Dynamic URL</b>: <br />https://pull.lucit.app/api/v1/campaigns/blip/{exportItem.hash_id}
                            /pull/xml?api_token={apiToken}&amp;location_id={board.board_identifier}<br />
                        </code>
                    </Box>
                )
            })}

        </Box>
    )
}
