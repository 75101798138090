import React from 'react';
import clx from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        board: {
            background: '#ABABAB',
            border: '1px solid black',
            boxSizing: 'content-box',
        },
    }
})

function BillboardThumbnailV2({ board, boardScale = 18 }) {
    const classes = useStyles();
    return (<Box display="flex" alignItems="center">
        <Box display="flex" justifyContent="center" width={40}>
            <Box
                className={clx({
                    [classes.board]: true,
                })}
                style={{
                    width: (board.width / board.height).toFixed(1) * boardScale + 'px',
                    height: boardScale + 'px'
                }}
            />
        </Box>
        <Box ml={1}>
            <Typography variant='body2'>{board.digital_board_format?.name}</Typography>
            <Typography variant='caption' color="textSecondary">{board.board_size_description}</Typography>
        </Box>
    </Box >);
}

export default BillboardThumbnailV2;
