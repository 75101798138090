import { apiHttp } from "../helpers/api";

export default class LucitApiFonts {

    list(params) {

        return apiHttp.get(`/fonts/list`, {
            params: params
        })
            .then(response => response.data.fonts)
    }

    get(lcuid) {
        return apiHttp.put(`/fonts/${lcuid}`)
            .then(response => response.data.font);
    }

}
