import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import DOMPurify from 'dompurify';

function MassEmail(props) {
    const { massEmail } = props;

    return (
        <Box>
            <Typography variant="h4">{massEmail.title}</Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(massEmail.body) }} />

            <LCTypography transProps={{ momentMassEmail: moment.utc(massEmail.completed_at).local().format('hh:mm A MMMM DD, YYYY') }} variant="caption" >
                Received at {{ momentMassEmail: moment.utc(massEmail.completed_at).local().format('hh:mm A MMMM DD, YYYY') }}
            </LCTypography>
        </Box>
    );
}

MassEmail.propTypes = {
    massEmail: PropTypes.object.isRequired
}

export default MassEmail;
