import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { IonButton } from '@ionic/react';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setItemPhoto } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { ImageUploadDialog } from '../../../components';
import { inventoryItemClass } from '../../../selectors/inventory';
import { itemAspectRatioSelector } from '../../../selectors/inventoryStatus';

export const useStyles = makeStyles(() => {
    return {

    }
})

const PhotoOverrideButton = (props) => {
    const { item,
        ButtonProps = {},
        forceShow = false,
        buttonText = 'Change Main Photo',
        ImageUploadDialogProps,
        buttonRef,
        setItemPhoto,
        aspect,
        aspectSelector,
        askForCameraOnAndroid
    } = props;

    const [isUploadOpened, setIsUploadOpened] = useState(false)
    const { t } = useTranslation();

    const itemHasDriveTemplate = item.drive_template && item.drive_template.lcuid

    if (!forceShow && itemHasDriveTemplate)
        return null

    if (!forceShow && !inventoryItemClass(item).allowPrimaryPhotoOverride)
        return null

    return (
        <>
            <IonButton
                variant="contained"
                color="primary"
                size="small"
                ref={buttonRef}
                startIcon={<Add />}
                {...ButtonProps}
                onClick={() => setIsUploadOpened(true)}
            >
                {buttonText}
            </IonButton>
            {isUploadOpened
                && <ImageUploadDialog
                    title={t('Override Primary image for {{name}}', { name: item.title })}
                    disableDelete={true}
                    saveButtonText={"Add"}
                    aspect={aspect ? aspect : aspectSelector}
                    askForCameraOnAndroid={askForCameraOnAndroid}
                    open={isUploadOpened}
                    handleSave={file => {
                        return setItemPhoto(file)
                            .then(() => setIsUploadOpened(false));
                    }}
                    handleClose={() => setIsUploadOpened(false)}

                    {...ImageUploadDialogProps}
                />}
        </>
    )
}

PhotoOverrideButton.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state, props) => {
    return {
        aspectSelector: itemAspectRatioSelector(state)(props.item.id)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setItemPhoto: file => {
            return dispatch(setItemPhoto(props.item, file, props.replacesPhoto))
                .then(() => dispatch(showSuccess(i18next.t('Image was uploaded, generating new creatives...'))))
                .catch(() => dispatch(showError(i18next.t('Unexpected error happened...'))));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    PhotoOverrideButton
);
