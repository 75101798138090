import { Box, Card, CardActionArea, CardContent, Collapse, Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import clx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { RoutePaths } from '../../../helpers/constants';
import ImageSold from '../../../components/images/ImageSold';
import { isItemSold } from '../../../selectors/inventory';

const useStyles = makeStyles(theme => {
    return {
        noBorder: {
            border: 0
        },

        cards: {
            margin: theme.spacing(2, 2, 2, 7.75),

            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2)
            }
        },
        cardImage: {
            objectFit: 'contain',
            maxHeight: 200,
            width: '100%',
            display: 'block',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        },

        soldText: {
            ...theme.typography.body1,
            fontSize: theme.typography.pxToRem(18),
        },

        root: {
            maxWidth: 345,
        }
    }
})

const ItemDetailsCollapsable = props => {
    const { options, open, columns, data } = props;
    const classes = useStyles(props);

    const rowStyle = typeof options.rowStyle === 'function'
        ? options.rowStyle(data)
        : options.rowStyle

    return <>
        <TableRow style={rowStyle}>
            <TableCell className={clx({ [classes.noBorder]: !open })} padding="none" colSpan={columns.length}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box className={classes.cards}>
                        <Grid container spacing={2}>
                            {data.object_creatives.map(creative => (
                                <Grid key={creative.object_id} item>
                                    <CreativeCard creative={creative} />
                                </Grid>))}
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
};

const CreativeCard = (props) => {
    const { creative } = props;
    const classes = useStyles();

    return (

        <Card className={classes.root}>
            <CardActionArea component={RouterLink} to={RoutePaths.inventoryPhoto + `/${creative.object_id}`}>
                <ImageSold
                    isSold={isItemSold(creative)}
                    classes={{
                        image: classes.cardImage,
                        soldText: classes.soldText
                    }}
                    imageProps={{
                        alt: creative.object_name,
                        src: creative.object_image,
                        title: creative.object_name
                    }}
                />
                <CardContent>
                    {creative.total_impressions > 0 ?
                        <>
                            <Typography variant="body2">
                                {creative.total_impressions.toLocaleString()} Impressions
                            </Typography>
                            <Typography component="p" variant="caption" color="textSecondary">
                                with {moment.duration(creative.total_play_duration, 'seconds').format("hh:mm:ss", { trim: false })} Time On Screen
                            </Typography>
                            <Typography component="p" variant="caption" color="textSecondary">
                                via {creative.total_plays.toLocaleString()} Plays
                            </Typography>
                        </>
                        : <Typography variant="body2">
                            {creative.total_plays.toLocaleString()} Plays
                        </Typography>}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default ItemDetailsCollapsable;
