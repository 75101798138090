import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import DescriptionIcon from '@material-ui/icons/Description';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import TrafficIcon from '@material-ui/icons/Traffic';
import { useTranslation } from 'react-i18next';
import { ExportStatus, CampaignState } from "../../helpers/constants";
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
    return {
        pendingTrafficStatus: {
            // color: 'blue'
        },
        rejectedStatus: {
            display: 'flex',
            width: 'fit-content',
            color: theme.palette.error.dark,
            '& svg': {
                color: theme.palette.error.dark,
            },
        },
        runStatus: {
            background: '#34BFC2',
            color: 'white',
            fontWeight: 'bold',
            '& svg': {
                color: 'white',
            },
        }
    }
});

export const CampaignStatusChip = ({ campaign }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (campaign.status === ExportStatus.error) {
        return (
            <Chip
                className={classes.errorStatus}
                label={t('Error')}
                icon={<ErrorIcon />}
            />
        )
    }
    if (campaign.status === ExportStatus.empty) {
        return (
            <Chip
                className={classes.emptyStatus}
                label={t('Empty')}
                icon={<HourglassEmptyIcon />}
            />
        )
    }

    if (campaign.campaign_state === CampaignState.draft.id) {
        return (
            <>
                <Chip
                    className={classes.draftStatus}
                    label={t('Draft')}
                    icon={<DescriptionIcon />}
                />
            </>
        )
    }

    if (campaign.campaign_state === CampaignState.pendingTraffic.id) {
        return (
            <>
                <Chip
                    className={classes.pendingTrafficStatus}
                    label={t('Pending Approval')}
                    icon={<TrafficIcon />}
                />
            </>
        )
    }

    if (campaign.campaign_state === CampaignState.rejected.id) {
        return (
            <>
                <Chip
                    className={classes.rejectedStatus}
                    label={t('Rejected')}
                    icon={<BrokenImageIcon />}
                />
            </>
        )
    }

    if (campaign.campaign_state === CampaignState.pending.id) {
        return (<Chip
            className={classes.pendingStatus}
            label={t('Pending')}
            icon={<DescriptionIcon />}
        />)
    }

    if (campaign.campaign_state === CampaignState.running.id || campaign.campaign_state === CampaignState.pulling.id) {
        return (
            <Chip
                className={classes.runStatus}
                label={t('Active')}
                icon={<CheckCircleIcon />}
            />
        )
    }
    if (campaign.campaign_state === CampaignState.paused.id || campaign.campaign_state === CampaignState.pullingPaused.id) {
        return (
            <Chip
                className={classes.pauseStatus}
                label={t('Paused')}
                icon={<PauseCircleFilledIcon />}
            />
        )
    }
    if (campaign.campaign_state === CampaignState.complete.id) {
        return (
            <Chip
                className={classes.completeStatus}
                label={t('Complete')}
                icon={<DoneIcon />}
            />
        )
    }
    return (
        <Chip
            className={classes.unknownStatus}
            label={t('Unknown')}
            icon={<ErrorIcon />}
        />
    )
}
