import React, { useState } from 'react';
import clx from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { Link as MaterialLink } from '@material-ui/core';
import { isLoggedIn, selectedAccountIds, selectedProfile, selectedProfileIsAgency, selectedProfileIsOperator } from '../selectors/user';
import { withRouter } from 'react-router';
import LeftSideMenu from '../containers/layout/LeftSideMenu';
import { Features, RoutePaths } from '../helpers/constants';
import { useRef } from 'react';
import NotificationsButton from '../containers/notifications/NotificationsButton';
import FeatureToggle from '../components/features/FeatureToggle';
import { ErrorBoundary } from '../components/errors/ErrorBoundary';
import { loadLayoutSettings, toggleAccountSwitcher, toggleAgencySwitcher, toggleLeftMenu, toggleProfileSwitcher } from '../actions/layout';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { loadAgencyStoriesCount, loadApprovalsCount, loadPendingTrafficCount } from '../actions/agencies';
import ProfileMenu from '../containers/layout/ProfileMenu';
import { AccountSelectorDialog, AgencySelectorDialog, DoubleArrowsLeft, DoubleArrowsRight, ProfileSelectorDialog } from '../components';
import { getUserInfo } from '../actions/user';
import { SearchInput, SearchToolbar, SelectedProfile, SwitchingProfileDialog } from '../containers/layout';
import { ScrollContext } from '../contexts';
import { Swipeable } from '../components/swipeable/Swipeable';
import BottomSheetProvider from './BottomSheetProvider';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from "../selectors/featureToggle";
import { LAYOUT_TOOGLE_LEFT_MENU_DESKTOP } from '../helpers/actionTypes';
import { getApplicationLogoUrl } from '../selectors/layout';
import { getApplications } from '../actions/applications';
import { Link as RouterLink } from 'react-router-dom';

const drawerWidth = 260;
const drawerClosedWidth = 62;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },

    },
    drawerAnimation: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `${drawerClosedWidth}px !important`,
        [theme.breakpoints.up('sm')]: {
            width: `${drawerClosedWidth}px !important`,
        },
    },
    appBar: {
        height: '100%',
        zIndex: theme.zIndex.drawer + 1,
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        backgroundColor: theme.palette.header.background,
        color: theme.palette.header.text,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    title: {
        display: 'flex',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        '& img': {
            height: 32,
        },
        [theme.breakpoints.up('md')]: {
            position: 'static',
            transform: 'none',

            '& img': {
                height: 48,
            },
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingTop: `env(safe-area-inset-top)`,
    },
    safeAreaBox: {
        height: `env(safe-area-inset-top)`,
        backgroundColor: theme.palette.header.background,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        overflow: 'auto',

        // transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        height: `calc(100vh - 56px - env(safe-area-inset-top))`,

        "@media (min-width: 0px) and (orientation: landscape)": {
            height: `calc(100vh - 48px - env(safe-area-inset-top))`,
        },

        [theme.breakpoints.down('md')]: {
            padding: 0
        },

        [theme.breakpoints.up('md')]: {
            height: `calc(100vh - 64px - env(safe-area-inset-top))`,
            marginLeft: drawerWidth,
        }
    },
    contentClosedDrawer: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerClosedWidth,
        }
    },
    contentWithSearchToolbar: {
        padding: 0,
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 112px - env(safe-area-inset-top))`,
            "@media (min-width: 0px) and (orientation: landscape)": {
                height: `calc(100vh - 96px - env(safe-area-inset-top))`,
            }
        }
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    grow: {
        flexGrow: 1,
    },
    selectedProfile: {
        backgroundColor: theme.palette.header.background,
        color: theme.palette.header.text
    },
    selectedProfileItem: {
        color: theme.palette.header.text
    }
}));

function HomeLayout(props) {
    const { loadApprovalsCount, loadPendingTrafficCount, loadLayoutSettings, loadUserInfo,
        toggleAccountSwitcher, toggleAgencySwitcher, toggleProfileSwitcher,
        toggleLeftMenu, accountIds, layout, searchToolbarFeatureToggleOn,
        selectedProfileIsAgency, selectedProfileIsOperator, selectedProfile } = props;

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const drawerDesktopOpen = useSelector(state => state.layout.leftMenuDesktopOpen);
    const setDrawerDesktopOpen = (leftMenuDesktopOpen) => dispatch({ type: LAYOUT_TOOGLE_LEFT_MENU_DESKTOP, leftMenuDesktopOpen })

    const scrollingContext = useRef({ ref: null });

    const handleDrawerToggle = () => toggleLeftMenu(!layout.leftMenuOpen);
    const closeDrawer = () => toggleLeftMenu(false);

    const closeMenuSwipeHandler = useSwipeable({
        onSwipedLeft: () => {
            if (layout.leftMenuOpen) {
                handleDrawerToggle();
            }
        }
    })

    useEffect(() => {
        if (location.pathname === RoutePaths.settings
            || location.pathname === RoutePaths.home
            || !layout.loaded) {
            loadUserInfo()
                .then(loadLayoutSettings);
        }
        // eslint-disable-next-line
    }, [loadLayoutSettings, JSON.stringify(accountIds), layout.loaded, location.pathname])

    useEffect(() => {
        loadApprovalsCount();

        if (selectedProfileIsAgency) {
            dispatch(loadAgencyStoriesCount(selectedProfile.lcuid));
        }

        if (selectedProfileIsOperator) {
            loadPendingTrafficCount(selectedProfile.id);
        }
    }, [])

    useEffect(() => {
        if (selectedProfileIsAgency || selectedProfileIsOperator) {
            dispatch(getApplications(selectedProfile.lcuid));
        }
    }, [])

    const showSearchToolbar = searchToolbarFeatureToggleOn && [
        RoutePaths.inventory,
        RoutePaths.photoStream,
        RoutePaths.creatives,
        RoutePaths.articles,
        RoutePaths.job_posts,
        RoutePaths.reviews,
        RoutePaths.socialPosts,
        RoutePaths.childCampaign,
        RoutePaths.recreationals,
        RoutePaths.exports,
        RoutePaths.realEstateAgents,
        RoutePaths.products,
        RoutePaths.searchResults,
    ].includes(location.pathname) == false;

    const [searchToolbarExpanded, setSearchToolbarExpanded] = useState(true);
    const { t } = useTranslation();

    return (
        <ScrollContext.Provider value={scrollingContext}>
            <BottomSheetProvider isOpenDefault={props.sheetOpenDefault}>
                <div className={classes.root}>
                    <AppBar position="sticky" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label={t('Open drawer')}
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Box className={classes.title} >
                                <MaterialLink
                                    component={RouterLink}
                                    to="/">
                                    <img src={getApplicationLogoUrl()} alt="Main logo" />
                                </MaterialLink>
                            </Box>
                            <div className={classes.grow} />

                            <FeatureToggle featureName={Features.searchBarInHeader}>
                                <Hidden mdDown implementation="js">
                                    {/* <SearchButton /> */}
                                    {[RoutePaths.searchResults]
                                        .includes(location.pathname) == false
                                        && <SearchInput />}

                                </Hidden>
                            </FeatureToggle>

                            <NotificationsButton />

                            <ProfileMenu />
                        </Toolbar>
                        <FeatureToggle featureName={Features.searchBarInHeader}>
                            <Hidden mdUp implementation="js">
                                {showSearchToolbar && <SearchToolbar onCollapseChange={e => setSearchToolbarExpanded(e)} />}
                            </Hidden>
                        </FeatureToggle>
                    </AppBar>

                    <div className={classes.offset} />

                    <nav className={classes.drawer}>
                        <Hidden mdUp implementation="js">
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={layout.leftMenuOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                PaperProps={{
                                    ...closeMenuSwipeHandler
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                <div className={classes.safeAreaBox} ></div>

                                <SelectedProfile
                                    component="div"
                                    className={classes.selectedProfile}
                                    listItemClassName={classes.selectedProfileItem}
                                    Icon={<CloseIcon onClick={(e) => {
                                        e.stopPropagation();
                                        handleDrawerToggle();
                                    }} />} />

                                <LeftSideMenu closeDrawer={closeDrawer} />
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="js">
                            <Drawer
                                className={clx(classes.drawer, classes.drawerAnimation, {
                                    [classes.drawerClose]: !drawerDesktopOpen,
                                })}

                                variant="permanent"
                                classes={{
                                    paper: clx(classes.drawerPaper, classes.drawerAnimation, {
                                        [classes.drawerClose]: !drawerDesktopOpen,
                                    }),
                                }}
                            >
                                <div className={classes.toolbar} />
                                <LeftSideMenu drawerDesktopOpen={drawerDesktopOpen} />

                                <Divider style={{ marginTop: 'auto' }} />
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                        onClick={() => setDrawerDesktopOpen(!drawerDesktopOpen)}>
                                        {drawerDesktopOpen
                                            ? <DoubleArrowsLeft />
                                            : <DoubleArrowsRight />}
                                    </IconButton>
                                </Box>
                            </Drawer>
                        </Hidden>
                    </nav>
                    <Swipeable
                        innerRef={e => scrollingContext.current.ref = e}
                        className={clx(classes.content, {
                            [classes.contentWithSearchToolbar]: showSearchToolbar && searchToolbarExpanded,
                            [classes.contentClosedDrawer]: !drawerDesktopOpen
                        })}
                    >
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </Swipeable>
                </div>

                <AccountSelectorDialog
                    open={layout.accountSwitcherOpen}
                    handleClose={() => {
                        toggleAccountSwitcher(false);
                        closeDrawer();
                    }} />

                <AgencySelectorDialog
                    open={layout.agencySwitcherOpen}
                    handleClose={() => {
                        toggleAgencySwitcher(false);
                        closeDrawer();
                    }}
                />

                <ProfileSelectorDialog
                    open={layout.profileSwitcherOpen}
                    handleClose={() => {
                        toggleProfileSwitcher(false);
                        closeDrawer();
                    }}
                />

                <SwitchingProfileDialog />
            </BottomSheetProvider>
        </ScrollContext.Provider>
    );
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
        accountIds: selectedAccountIds(state),
        selectedProfileIsOperator: selectedProfileIsOperator(state),
        selectedProfileIsAgency: selectedProfileIsAgency(state),
        selectedProfile: selectedProfile(state),
        layout: state.layout,
        searchToolbarFeatureToggleOn: isFeatureEnabled(state)(Features.searchBarInHeader),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        loadUserInfo: () => dispatch(getUserInfo()),
        loadLayoutSettings: () => dispatch(loadLayoutSettings()),
        loadApprovalsCount: () => dispatch(loadApprovalsCount()),
        loadPendingTrafficCount: (agencyId) => dispatch(loadPendingTrafficCount(agencyId)),
        toggleLeftMenu: (open) => dispatch(toggleLeftMenu(open)),
        toggleAccountSwitcher: (open) => dispatch(toggleAccountSwitcher(open)),
        toggleAgencySwitcher: (open) => dispatch(toggleAgencySwitcher(open)),
        toggleProfileSwitcher: (open) => dispatch(toggleProfileSwitcher(open))
    }
}

const HomeLayoutConnected = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HomeLayout)
);

export default HomeLayoutConnected;
export { HomeLayoutConnected as HomeLayout };

export function withHomeLayout(WrappedComponent) {
    class WithHomeLayout extends React.Component {
        render() {
            const { sheetOpenDefault, ...props } = this.props;

            return <HomeLayoutConnected sheetOpenDefault={sheetOpenDefault}>
                <WrappedComponent {...props} />
            </HomeLayoutConnected>
        }
    }

    WithHomeLayout.displayName = `WithHomeLayout(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return WithHomeLayout;
}
