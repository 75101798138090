import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles, Box, MenuItem, Link, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LCTypography from '../../components/material/LCTypography';
import { BillboardIcon, Global, RoutePaths } from '../../helpers/constants';
import { capitalize } from '../../helpers/string';
import { getItem } from '../../actions/inventory';
import { getExport } from '../../actions/exports';
import { setLocationCoords } from '../../actions/locations';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import EditIcon from '@material-ui/icons/Edit';
import { maxLength, minOrEqual, required } from '../../helpers/validators';
import BillboardThumbnail from './BillboardThumbnail';
import BillboardPullIndicator from './BillboardPullIndicator';
import { updateScreen } from '../../actions/billboards';
import BillboardOnMap from './BillboardOnMap';
import { agencies, selectedAgency } from '../../selectors/user';
import { showError, showSuccess } from '../../actions/snackbar';
import { Language } from '@material-ui/icons';
import { billboardOptions, getAvgDailyImpressions } from '../../selectors/billboard';
import BillboardStreetView from './BillboardStreetView';
import { useTranslation } from 'react-i18next';
import SetBillboardSizeDialog from './SetBillboardSizeDialog';
import SetBillboardCoordinates from './SetBillboardCoordinates';
import { Menu, ListItemText, ListItemIcon, ButtonGroup, Button } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { trash } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import DeleteBillboardButton from '../../containers/billboards/DeleteBillboardButton';
import i18next from 'i18next';

const useStyles = makeStyles(theme => {
    return {
        imageGrid: {
        },
        image: {
            width: '100%'
        },
        boardSize: {
            fontSize: `${theme.typography.body1.fontSize} !important`
        },
        inputRoot: {
            width: '100%',
            maxWidth: '100%'
        },
        title: {
            ...theme.typography.body1,
            fontWeight: 'bold'
        },
        clickToAdd: {
            cursor: 'pointer',
            color: theme.palette.grey[500]
        },
        boardViews: {
            [theme.breakpoints.up('md')]: {
                marginTop: 16
            }
        },
        map: {
            width: 640,
            height: 240
        }
    }
})

const AdvancedSettings = ({ digitalBoard }) => {

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <ButtonGroup size='small'>
            <Tooltip title={t('Advanced Settings / Options')}>
                <Button
                    size="small"
                    onClick={handleClick}
                >
                    <MoreVert />
                </Button>
            </Tooltip>
        </ButtonGroup>

        <Menu
            id="advanced-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            <DeleteBillboardButton
                screen={digitalBoard}
                renderButton={props => <MenuItem
                    {...props}
                >
                    <ListItemIcon>
                        <IonIcon icon={trash} slot="start" />
                    </ListItemIcon>
                    <ListItemText primary={t('Delete Screen')} />
                </MenuItem>
                }
            />

        </Menu>

    </>

}

function BillboardProfileEditable(props) {
    const { selectedAgency, board, location, updateScreen, setLocationCoords } = props;
    const classes = useStyles();
    const [openSizeDialog, setOpenSizeDialog] = useState(false)
    const [openCoordsDialog, setOpenCoordsDialog] = useState(false)
    const [isEditField, setIsEditField] = useState({
        description: false,
        hardware: false,
        software: false,
        facing: false
    });
    const setEditField = (field, isEdit) => setIsEditField({ ...isEditField, [field]: isEdit })

    const agency = props.agencies.find(a => a.id === board.agency_id);
    const facing = board && board.options ? props.facingCardinals.find(x => x.degrees === board.options.facing_degrees) : null;
    const { t } = useTranslation();

    const textEditableProps = {
        classes: {
            root: classes.inputRoot
        },
        fullWidth: true,
        editButtonProps: { size: 'small' },
        doneButtonProps: { size: 'small' },
        cancelProps: { size: 'small' },
        editIconProps: { fontSize: 'small' },
        doneIconProps: { fontSize: 'small' },
        cancelIconProps: { fontSize: 'small' },
        circularProgressProps: { size: 24 },
        size: "small"
    };

    const ClickToAdd = ({ field }) => (<LCTypography
        onClick={() => setEditField(field, true)}
        className={classes.clickToAdd}
        variant="body2"><i>(click to add)</i></LCTypography>);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} zeroMinWidth>
                <Box style={{ float: "right" }} onClick={(e) => e.preventDefault()}>
                    <AdvancedSettings digitalBoard={board} />
                </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={5} zeroMinWidth>
                <Grid container wrap="nowrap" alignItems='center' style={{ marginBottom: 16 }}>
                    <Grid item xs="auto" style={{ display: 'flex' }}>
                        <Box component={BillboardIcon} mr={2} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <TextFieldEditable
                            classes={{
                                root: classes.inputRoot
                            }}
                            fullWidth={true}
                            circularProgressProps={{ size: 40 }}
                            size="small"
                            ViewComponent={<LCTypography
                                transProps={{ boardName: board.name }}
                                variant="h6"
                                noWrap>{{ boardName: board.name }}</LCTypography>}
                            label={t('Name')}
                            variant={"outlined"}
                            value={board.name || ""}
                            validators={[required, maxLength(Global.titleMaxLength)]}
                            handleDone={name => updateScreen({ ...board, name }, 'name')}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: 16 }}>
                    <Grid item xs={12}>
                        <Link
                            style={{ display: 'flex' }}
                            component={RouterLink}
                            to={RoutePaths.public.slug(board.slug || board.lcuid)}
                            color="secondary">
                            <Language style={{ marginRight: 8 }} fontSize='small' />
                            <LCTypography>Public Link</LCTypography>
                        </Link>
                    </Grid>
                </Grid>
                {[
                    {
                        hidden: selectedAgency && selectedAgency === agency,
                        title: <LCTypography className={classes.title}>Operator:</LCTypography>,
                        value: <Link
                            component={RouterLink}
                            to={RoutePaths.public.slug(agency && agency.slug)}
                            color="secondary">{agency && agency.name}</Link>
                    },
                    {
                        title: <LCTypography className={classes.title}>Description:</LCTypography>,
                        value: <TextFieldEditable
                            {...textEditableProps}
                            ViewComponent={board.description
                                ? <LCTypography transProps={{ boardDescription: board.description }}
                                    onClick={() => setEditField('description', true)}>
                                    {{ boardDescription: board.description }}
                                </LCTypography>
                                : <ClickToAdd field="description" />}
                            variant={"outlined"}
                            value={board.description || ""}
                            validators={[maxLength(Global.titleMaxLength)]}
                            isEdit={isEditField['description']}
                            handleIsEdit={isEdit => setEditField("description", isEdit)}
                            handleDone={description => updateScreen({ ...board, description }, 'description')}
                        />
                    },
                    {
                        title: <LCTypography className={classes.title}>Identifier:</LCTypography>,
                        value: <TextFieldEditable
                            {...textEditableProps}
                            ViewComponent={board.board_identifier
                                ? <LCTypography onClick={() => setEditField('identifier', true)}>
                                    {board.board_identifier}
                                </LCTypography>
                                : <ClickToAdd field="identifier" />}
                            variant={"outlined"}
                            value={board.board_identifier || ""}
                            validators={[maxLength(Global.titleMaxLength)]}
                            isEdit={isEditField['identifier']}
                            handleIsEdit={isEdit => setEditField("identifier", isEdit)}
                            handleDone={identifier => updateScreen({ ...board, board_identifier: identifier }, 'identifier')}
                        />
                    },
                    {
                        title: <LCTypography className={classes.title}>Status:</LCTypography>,
                        value: <>
                            <BillboardPullIndicator pull_datetime={board.play_datetime} />
                        </>
                    },
                    {
                        title: <LCTypography className={classes.title}>Size:</LCTypography>,
                        value: <Box onClick={() => setOpenSizeDialog(true)} width="fit-content" display="flex">
                            <BillboardThumbnail
                                classes={{ boardSize: classes.boardSize }}
                                showAsOneLine={true}
                                showPixelsSuffix={true}
                                board={board}
                                boardScale={20}
                            />
                            <IconButton size="small" style={{ marginLeft: 8 }}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    },
                    {
                        title: <LCTypography className={classes.title}>Slot Length:</LCTypography>,
                        value: <TextFieldEditable
                            {...textEditableProps}
                            ViewComponent={billboardOptions(board).slot_length
                                ? <Typography>
                                    {billboardOptions(board).slot_length ? board.options.slot_length + ' ' + t('seconds') : "Unkown"}
                                </Typography>
                                : <ClickToAdd field="slot_length" />}
                            variant={"outlined"}
                            value={billboardOptions(board).slot_length || ""}
                            validators={[minOrEqual(0)]}
                            type="number"
                            isEdit={isEditField['slot_length']}
                            handleIsEdit={isEdit => setEditField("slot_length", isEdit)}
                            handleDone={slot_length => updateScreen({
                                ...board, options: {
                                    ...board.options, slot_length
                                }
                            }, 'slot length')}
                        />
                    },
                    {
                        title: <Tooltip title={t('The total number of impressions this screen sees PER DAY.  Across ALL SLOTS')}>
                            <LCTypography className={classes.title}>Total Screen Daily Impressions:</LCTypography>
                        </Tooltip>,
                        value: <TextFieldEditable
                            {...textEditableProps}
                            ViewComponent={billboardOptions(board).avg_daily_impressions
                                ? <LCTypography transProps={{ getAvgImpressions: getAvgDailyImpressions(board) }}>
                                    {{ getAvgImpressions: getAvgDailyImpressions(board) }} impressions
                                </LCTypography>
                                : <ClickToAdd field="avg_daily_impressions" />}
                            variant={"outlined"}
                            value={billboardOptions(board).avg_daily_impressions || ""}
                            validators={[minOrEqual(0)]}
                            type="number"
                            isEdit={isEditField['avg_daily_impressions']}
                            handleIsEdit={isEdit => setEditField("avg_daily_impressions", isEdit)}
                            handleDone={avg_daily_impressions => updateScreen({
                                ...board, options: {
                                    ...board.options, avg_daily_impressions,
                                    impressions_calc_method: avg_daily_impressions ? 1 : 0
                                }
                            }, 'average daily impressions')}
                        />
                    },
                    {
                        title: <LCTypography className={classes.title}>Hardware:</LCTypography>,
                        value: <>
                            <TextFieldEditable
                                {...textEditableProps}
                                ViewComponent={board.hardware_provider
                                    ? <LCTypography transProps={{ capitalizeBoardHardware: capitalize(board.hardware_provider) }}
                                        onClick={() => setEditField('hardware', true)}>
                                        {{ capitalizeBoardHardware: capitalize(board.hardware_provider) }}
                                    </LCTypography>
                                    : <ClickToAdd field="hardware" />}
                                variant={"outlined"}
                                select
                                value={board.hardware_provider || ""}
                                isEdit={isEditField['hardware']}
                                handleIsEdit={isEdit => setEditField("hardware", isEdit)}
                                handleDone={hardware_provider => updateScreen({ ...board, hardware_provider }, 'hardeware provider')}
                            >
                                {props.hardwareProviders
                                    .map(provider => <MenuItem key={provider} value={provider}>
                                        {capitalize(provider)}
                                    </MenuItem>)}
                            </TextFieldEditable>
                        </>
                    },
                    {
                        title: <LCTypography className={classes.title}>Software:</LCTypography>,
                        value: <>
                            <TextFieldEditable
                                {...textEditableProps}
                                ViewComponent={board.software_provider
                                    ? <LCTypography transProps={{ capitalizeBoardSoftware: capitalize(board.software_provider) }}
                                        onClick={() => setEditField('software', true)}>
                                        {{ capitalizeBoardSoftware: capitalize(board.software_provider) }}
                                    </LCTypography>
                                    : <ClickToAdd field="software" />}
                                variant={"outlined"}
                                select
                                value={board.software_provider || ""}
                                isEdit={isEditField['software']}
                                handleIsEdit={isEdit => setEditField("software", isEdit)}
                                handleDone={software_provider => updateScreen({ ...board, software_provider }, 'software provider')}
                            >
                                {props.softwareProviders
                                    .map(provider => <MenuItem key={provider} value={provider}>
                                        {capitalize(provider)}
                                    </MenuItem>)}
                            </TextFieldEditable>
                        </>
                    },
                    {
                        title: <LCTypography className={classes.title}>Facing:</LCTypography>,
                        value: <>
                            <TextFieldEditable
                                {...textEditableProps}
                                ViewComponent={facing
                                    ? <LCTypography transProps={{ facingName: facing.name }} onClick={() => setEditField('facing', true)}>
                                        {{ facingName: facing.name }}
                                    </LCTypography>
                                    : <ClickToAdd field="facing" />}
                                variant={"outlined"}
                                select
                                value={isNaN(board.options.facing_degrees)
                                    ? ""
                                    : Number(board.options.facing_degrees)}
                                isEdit={isEditField['facing']}
                                handleIsEdit={isEdit => setEditField("facing", isEdit)}
                                handleDone={degrees => {
                                    const facing = props.facingCardinals.find(x => x.degrees === degrees);

                                    if (facing) {
                                        return updateScreen({
                                            ...board,
                                            options: {
                                                ...board.options,
                                                facing_cardinal: facing.cardinal,
                                                facing_degrees: facing.degrees
                                            }
                                        }, 'facing')
                                    }

                                    //eslint-disable-next-line
                                    return Promise.resolve();
                                }}
                            >
                                {props.facingCardinals
                                    .map(facing => <MenuItem key={facing.degrees} value={facing.degrees}>
                                        {facing.name}
                                    </MenuItem>)}
                            </TextFieldEditable>
                        </>
                    },
                    {
                        containerGripProps: { alignItems: "flex-start" },
                        title: <LCTypography className={classes.title}>Coordinates:</LCTypography>,
                        value: location
                            ? <Box onClick={() => setOpenCoordsDialog(true)}
                                display="flex"
                                alignItems="center">
                                <Typography>({(+location.latitude).toFixed(5)}, {(+location.longitude).toFixed(5)})</Typography>

                                <IconButton size="small" style={{ marginLeft: 8 }}>
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            : null
                    },
                    {
                        containerGripProps: { alignItems: "flex-start" },
                        title: <LCTypography className={classes.title}>Location:</LCTypography>,
                        value: location
                            ? <>
                                <LCTypography transProps={{ locationAddress: location.address }}>
                                    {{ locationAddress: location.address }}
                                </LCTypography>
                                <LCTypography transProps={{
                                    locationCity: location.city,
                                    locationRegion: location.region,
                                    locationPostal: location.postal_code
                                }}>
                                    {{ locationCity: location.city }}, {{ locationRegion: location.region }}
                                    {{ locationPostal: location.postal_code }}
                                </LCTypography>
                            </>
                            : null
                    }
                ]
                    .filter(x => !x.hidden)
                    .map(({ title, value, containerGripProps, titleGridProps, valueGridProps }, index) => (
                        <Grid key={index}
                            container
                            style={{ marginBottom: 12 }}
                            alignItems="flex-start"
                            {...containerGripProps}>
                            <Grid item xs={"auto"} sm={4} md={3} lg={2} style={{ minWidth: 150 }} {...titleGridProps}>
                                {title}
                            </Grid>
                            <Grid item xs={"auto"} sm {...valueGridProps}>
                                {value}
                            </Grid>
                        </Grid>
                    ))}
            </Grid>

            <Grid item xs={12} md={5} lg={7} className={classes.boardViews}>
                {location
                    && <>
                        <BillboardStreetView
                            editEnabled={true}
                            board={board}
                            location={location} />

                        <BillboardOnMap
                            location={location}
                            classes={{ map: classes.map }} />
                    </>}
            </Grid>

            <SetBillboardSizeDialog
                title={t('Screen Size')}
                open={openSizeDialog}
                defaultWidth={board.width}
                defaultHeight={board.height}
                handleClose={() => setOpenSizeDialog(false)}
                handleSave={size => updateScreen({ ...board, ...size }, 'size')}
            />

            {
                location
                && <SetBillboardCoordinates
                    title={t('Screen Coordinates')}
                    open={openCoordsDialog}
                    latitude={location.latitude}
                    longitude={location.longitude}
                    handleClose={() => setOpenCoordsDialog(false)}
                    handleSave={coords => setLocationCoords({ ...location, ...coords })}
                />
            }
        </Grid >
    );
}

BillboardProfileEditable.propTypes = {
    board: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        agencies: agencies(state.user),
        selectedAgency: selectedAgency(state),
        softwareProviders: state.billboard.softwareProviders,
        hardwareProviders: state.billboard.hardwareProviders,
        facingCardinals: state.billboard.facingCardinals,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getInventoryItem: id => dispatch(getItem(id)),
        getExport: id => dispatch(getExport(id)),

        updateScreen: (screen, field) => dispatch(updateScreen(screen))
            .then(() => dispatch(showSuccess(i18next.t('Screen {{sField}} was successfully updated!', { sField: field }))))
            .catch(error => {
                dispatch(showError(i18next.t('Unexpected error happened...')));
                throw error;
            }),
        setLocationCoords: (location) => dispatch(setLocationCoords(location))
            .then(() => dispatch(showSuccess(i18next.t('Screen location was successfully updated!'))))
            .catch(error => {
                dispatch(showError(i18next.t('Unexpected error happened...')));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BillboardProfileEditable
);
