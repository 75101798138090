import { SubmissionError } from "redux-form";
import { apiHttp } from "../helpers/api";

export default class LucitApiUploads {
    getFile(id) {
        return apiHttp.get(`/files/${id}`)
            .then(response => response.data.file)
    }

    uploadInventoryPhoto(fileBase64, inventoryItemId, photoSet = "base", fileName = "image.png", replacesPhotoId) {
        return fetch(fileBase64)
            .then(res => res.blob())
            .then(blob => {
                const form = new FormData();
                const file = new File([blob], fileName);

                form.append('file', file);
                form.append('inventory_item_id', inventoryItemId);
                form.append('photo_set', photoSet);
                if (replacesPhotoId)
                    form.append('replaces_photo_id', replacesPhotoId);

                return apiHttp.post(`/uploads/photo`, form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            })
            .then(response => response.data.photo)
            .catch(error => {
                if (error.isOffline) {
                    throw new SubmissionError({
                        _error: 'You are offline...'
                    })
                }

                const response = error.response;

                throw new SubmissionError({
                    _error: response.data.message || 'Something went wrong'
                })
            });
    }

    uploadImage(fileBase64, fileName = "image.png", templates = [], data = {}) {
        return fetch(fileBase64)
            .then(res => res.blob())
            .then(blob => {
                const form = new FormData();
                const file = new File([blob], fileName);

                form.append('file', file);
                form.append('image_templates', templates);

                Object.keys(data)
                    .forEach(key => form.append(key, data[key]))

                return apiHttp.post(`/uploads/image`, form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            })
            .then(response => response.data.image);
    }

    uploadImageFile(file, templates = []) {

        const form = new FormData();

        form.append('file', file);
        form.append('image_templates', JSON.stringify(templates));

        return apiHttp.post(`/uploads/image`, form, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => response.data.image);
    }

    getBackgroundRemovedImage(fileBase64, fileName = "image.png", data = {}) {

        return fetch(fileBase64)
            .then(res => res.blob())
            .then(blob => {
                const form = new FormData();
                const file = new File([blob], fileName);

                form.append('file', file);

                Object.keys(data)
                    .forEach(key => form.append(key, data[key]))

                return apiHttp.post(`/uploads/remove-background`, form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            })
            .then(response => response.data.image);
    }

    uploadFontFile(file) {

        const form = new FormData();

        form.append('file', file);

        return apiHttp.post(`/uploads/font`, form, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => response.data.font);
    }

    uploadFile(file) {
        const form = new FormData();

        form.append('file', file);

        return apiHttp.post(`/uploads/file`, form, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => response.data.file);
    }
}
