import React, { useEffect, useState } from 'react';
import { getDriveTemplateElementsForEdit, moveElementBefore, moveElementAfter } from '../../actions/driveTemplateElements';
import { useDispatch, useSelector } from 'react-redux';
import DriveTemplateElementItem from './DriveTemplateElementItem';
import {
    Grid, Button, TextField, Box, Select, MenuItem, FormControl, InputLabel, Switch, Tooltip, FormControlLabel, Typography
} from '@material-ui/core';
import DriveTemplateElementEditor from './DriveTemplateElementEditor';
import { searchTermInText } from '../../helpers/string';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from '@material-ui/core';
import { Designer } from '../../helpers/constants';
import { DESIGNER_FONTS } from '../../helpers/actionTypes';
import { lucitApi } from "../../services/lucitApi";
import { injectFont } from '../../containers/designer/DesignerFonts';
import { isAdminUser } from '../../selectors/user';
import { hasCapability } from '../../selectors/applications';
import { ApplicationCapabilities } from '../../helpers/constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const DriveTemplateElements = ({ application }) => {

    const [showAddNew, setShowAddNew] = useState(false);

    const dispatch = useDispatch();

    const driveTemplateElements = useSelector(state => state.driveTemplateElements.data);

    const myApplications = useSelector(state => state.applications.data.filter(app =>
        hasCapability(app, ApplicationCapabilities.designer)
    ));
    const adminUser = useSelector(isAdminUser);

    const hasApplications = myApplications.length > 0;

    const showAddNewButton = adminUser || hasApplications;

    const [query, setQuery] = useState('');

    const [selectedApplication, setSelectedApplication] = useState(application ? application : 'global');

    const [gridSize, setGridSize] = useState(1);

    const [sortOption, setSortOption] = useState('order_number');

    const [fontsUsed, setFontsUsed] = useState([]);

    const [enableReOrdering, setEnableReOrdering] = useState(false);

    const canEnableReOrdering = sortOption === "order_number";

    const { t } = useTranslation();

    const tabList = {
        all: {
            code: "all",
            name: "All",
            elementClass: null
        },
        basicShape: {
            code: "basicShape",
            name: "Shapes",
            elementClass: Designer.ObjectElementClasses.BasicShape.class
        },
        text: {
            code: "text",
            name: "Text",
            elementClass: Designer.ObjectElementClasses.Text.class
        },
        background: {
            code: "background",
            name: "Background",
            elementClass: Designer.ObjectElementClasses.Background.class
        }
    }

    const [selectedTab, setSelectedTab] = useState("all");

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const elementMatchesQuery = (element) => {
        return searchTermInText(query, [
            element.name,
            element.description,
            element.type
        ]);
    };

    const elementMatchesClass = (element) => {
        if (selectedTab === "all") {
            return true;
        }

        return element.element_class === tabList[selectedTab].elementClass;
    };

    const elementMatchesApp = (element) => {
        if (selectedApplication === 'global') {
            return !element.application_id;
        }

        return element.application_id === selectedApplication.id;
    };

    const sortElements = (elements) => {
        switch (sortOption) {
            case 'order_number':
                return elements.sort((a, b) => a.order_number - b.order_number);
            case 'name':
                return elements.sort((a, b) => a.name.localeCompare(b.name));
            case 'name_desc':
                return elements.sort((a, b) => b.name.localeCompare(a.name));
            case 'created_at':
                return elements.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            case 'created_at_desc':
                return elements.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            case 'updated_at':
                return elements.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
            case 'updated_at_desc':
                return elements.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            default:
                return elements;
        }
    };

    useEffect(() => {
        setQuery('');
    }, [])

    useEffect(() => {
        if (!enableReOrdering) {
            dispatch(getDriveTemplateElementsForEdit({ per_page: 10000 }));
        }
    }, [enableReOrdering]);

    useEffect(() => {

        const fonts = driveTemplateElements.filter(x => x.fonts && x.fonts.length).map(x => x.fonts[0]);

        //Get list of uniqueFonts based on the font.lcuid
        const uniqueFonts = fonts.filter((font, index, self) =>
            index === self.findIndex((t) => (
                t.lcuid === font.lcuid
            ))
        );

        setFontsUsed(uniqueFonts);

    }, [driveTemplateElements])

    useEffect(() => {

        fontsUsed.forEach((font) => {
            injectFont(font);
        });

    }, [fontsUsed])

    const handleSortChange = (event) => {

        setSortOption(event.target.value);
    };

    useEffect(() => {
        lucitApi.fonts.list()
            .then((result) => {
                dispatch({ type: DESIGNER_FONTS, fonts: result.data })
            });
    }, [])

    const sortedAndFilteredElements = driveTemplateElements ? sortElements(
        driveTemplateElements.filter(x => elementMatchesQuery(x) && elementMatchesClass(x) && elementMatchesApp(x))
    ) : [];

    return <>
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <Box mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddNew(true)}
                        disabled={!showAddNewButton || enableReOrdering}
                    >
                        Add New
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={2}>
                <Box mb={2} ml={1} mr={1}>
                    {application ?
                        <Typography variant="h6">{application.name}</Typography>
                        :
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="sort-label">Application</InputLabel>
                            <Select
                                labelId="app-label"
                                value={selectedApplication}
                                onChange={(e) => setSelectedApplication(e.target.value)}
                                label="App"
                                size="small"
                            >
                                {adminUser && <MenuItem value="global">Global</MenuItem>}
                                {myApplications.map((app, index) => {
                                    return <MenuItem key={index} value={app}>{app.name}</MenuItem>
                                })}

                            </Select>
                        </FormControl>}
                </Box>
            </Grid>

            <Grid item xs={2}>
                <Box mb={2} ml={1} mr={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="sort-label">Sort By</InputLabel>
                        <Select
                            labelId="sort-label"
                            value={sortOption}
                            onChange={handleSortChange}
                            label="Sort By"
                            size="small"
                            disabled={enableReOrdering}
                        >
                            <MenuItem value="order_number">Order Number</MenuItem>
                            <MenuItem value="name">Name A-Z</MenuItem>
                            <MenuItem value="name_desc">Name Z-A</MenuItem>
                            <MenuItem value="created_at">Newest</MenuItem>
                            <MenuItem value="created_at_desc">Oldest</MenuItem>
                            <MenuItem value="updated_at">Recently Updated</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid item xs={1}>
                {canEnableReOrdering && <Box mt={1}>
                    <Tooltip title="Drag and Drop to edit the sort order of the elements">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableReOrdering}
                                    onChange={() => setEnableReOrdering(!enableReOrdering)}
                                    color="primary"
                                    size="small"
                                />
                            }
                            label="Edit"
                        />
                    </Tooltip>
                </Box>}
            </Grid>

            <Grid item xs={1}>
                <Box mb={2} ml={1} mr={1}>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="sort-label">Grid Size</InputLabel>
                        <Select
                            labelId="grid-size-label"
                            value={gridSize}
                            onChange={(e) => setGridSize(e.target.value)}
                            label="Grid Size"
                            size="small"
                            disabled={enableReOrdering}
                        >
                            <MenuItem value={1}>12</MenuItem>
                            <MenuItem value={2}>6</MenuItem>
                            <MenuItem value={3}>4</MenuItem>
                            <MenuItem value={4}>3</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid item xs={5}>

            </Grid>

            <Grid item xs={8}>
                <Box mb={2}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {Object.keys(tabList).map((tabKey) => {
                            return (
                                <Tab
                                    key={tabKey}
                                    label={t(tabList[tabKey].name)}
                                    value={tabList[tabKey].code}
                                    disabled={enableReOrdering}
                                />
                            )
                        })}
                    </Tabs>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mb={1} ml={1} mr={1}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        required={true}
                        label={t('Search')}
                        value={query}
                        disabled={enableReOrdering}
                        onChange={e => setQuery(e.target.value)}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <DraggableDriveTemplateElements
                    driveTemplateElements={sortedAndFilteredElements}
                    gridSize={gridSize}
                    enableReOrdering={enableReOrdering}
                />
            </Grid>
        </Grid >

        {showAddNew && <DriveTemplateElementEditor
            open={showAddNew}
            selectedApplication={selectedApplication}
            handleClose={() => setShowAddNew(false)}
        />
        }
    </>

}
export const DraggableDriveTemplateElements = ({
    driveTemplateElements,
    gridSize,
    enableReOrdering = false,
}) => {
    const [sortedElements, setSortedElements] = useState([]);
    const [reorderingIsHappening, setReorderingIsHappening] = useState(false);

    const dispatch = useDispatch();

    const onDragEnd = (result) => {
        if (!result.destination) return;

        setReorderingIsHappening(true);

        const sourceRow = parseInt(result.source.droppableId.split('-')[1], 10);
        const destinationRow = parseInt(result.destination.droppableId.split('-')[1], 10);
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        const sourceElement = sortedElements[sourceRow][sourceIndex];
        const destinationElement =
            sortedElements[destinationRow][destinationIndex];

        const reorderedElements = Array.from(sortedElements);
        // Remove the element from the source position
        reorderedElements[sourceRow].splice(sourceIndex, 1);
        // Add the element to the new position
        reorderedElements[destinationRow].splice(destinationIndex, 0, sourceElement);

        //If we are moving to a new row.  Then, we will have too many items on that row
        //We need to remove the last item from the destination row
        //add it to the row after the destination row
        //And cascade that down until we have the correct number of items per row

        if (sourceRow !== destinationRow) {
            let currentRow = Math.min(destinationRow, sourceRow);
            let nextRow = currentRow + 1;

            while (reorderedElements[currentRow].length > Math.floor(12 / gridSize)) {

                const lastElement = reorderedElements[currentRow].pop();
                reorderedElements[nextRow].unshift(lastElement);

                currentRow++;
                nextRow++;

                if (nextRow >= reorderedElements.length) {
                    reorderedElements.push([]); //We need a new row man
                }
            }

        }

        setSortedElements(reorderedElements);

        // Dispatch actions accordingly if moved between rows

        //isMovedToBefore is true if the destination index is less than the source index
        //OR if the destination row is before the source row
        const isMovedToBefore = destinationRow < sourceRow || (destinationRow === sourceRow && destinationIndex < sourceIndex);

        if (isMovedToBefore) {
            dispatch(
                moveElementBefore(sourceElement.lcuid, destinationElement.lcuid)
            );
        } else {
            dispatch(
                moveElementAfter(sourceElement.lcuid, destinationElement.lcuid)
            );
        }

    };

    useEffect(() => {
        if (reorderingIsHappening) return;

        // Split the elements into rows based on the calculated number of items per row
        const splitElementsIntoRows = () => {
            const rows = [];
            const itemsPerRow = Math.floor(12 / gridSize);
            for (let i = 0; i < driveTemplateElements.length; i += itemsPerRow) {
                rows.push(driveTemplateElements.slice(i, i + itemsPerRow));
            }
            return rows;
        };

        setSortedElements(splitElementsIntoRows());
    }, [driveTemplateElements, gridSize, reorderingIsHappening]);

    useEffect(() => {
        setReorderingIsHappening(false);
    }, [enableReOrdering]);

    const elementContainerStyle = {
        width: "100%",
        height: "100%",
        minWidth: "100%",
        minHeight: "100%",
        boxSizing: "border-box",
        border: enableReOrdering ? "2px dotted #cccccc" : "none",
        borderRadius: enableReOrdering ? "10px" : "0px",
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {sortedElements.map((rowElements, rowIndex) => (
                <Droppable
                    key={`droppable-${rowIndex}`}
                    droppableId={`droppable-${rowIndex}`}
                    direction="horizontal"
                >
                    {(provided) => (
                        <Grid
                            container
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            spacing={2} // add spacing if necessary
                        >
                            {rowElements.map((element, index) => (
                                <Draggable
                                    key={element.id}
                                    draggableId={element.id.toString()}
                                    index={index}
                                    isDragDisabled={!enableReOrdering}
                                >
                                    {(provided) => (
                                        <Grid
                                            item
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            xs={gridSize} // Each item takes up gridSize columns
                                        >
                                            <Box style={elementContainerStyle}>
                                                <DriveTemplateElementItem
                                                    element={element}
                                                    clickToEdit={!enableReOrdering}
                                                />
                                            </Box>
                                        </Grid >
                                    )}
                                </Draggable >
                            ))}
                            {provided.placeholder}
                        </Grid >
                    )}
                </Droppable >
            ))
            }
        </DragDropContext >
    );
};

export default DriveTemplateElements;
