import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import BillboardsIcon from '@material-ui/icons/DesktopWindows';
import LCTypography from '../../../src/components/material/LCTypography';
import { lucitApi } from '../../services/lucitApi';
import { Skeleton } from '@material-ui/lab';

export const Watchfire = ({ exportItem, apiToken }) => {

    const [loadingBoards, setLoadingBoards] = useState(true);
    const [digitalBoards, setDigitalBoards] = useState([]);

    useEffect(() => {
        exportItem && lucitApi.exports.getExportBillboards(exportItem.id).then((digital_boards) => {
            setDigitalBoards(digital_boards);
            setLoadingBoards(false);
        })
    }, [exportItem?.id])

    return (
        <Box p={5}>
            <Box pb={3}>
                <LCTypography variant="h5">Watchfire</LCTypography>
            </Box>

            <LCTypography variant="body2">
                The step by step process for loading Watchfire campaigns is documented in
                <a target="_blank" rel="noreferrer" href="https://lucit.cc/post/how-to-schedule-lucit-in-watchfire-oa-ignite-player/">
                    How to Schedule Lucit in the Watchfire OA Ignite Player</a>
            </LCTypography>
            <LCTypography variant="body2">
                Watchfire ads are loaded by creating a dynamic ad that pulls the Lucit feed for a full sized image.
                Consult the Watchfire Ignite OA Documentation for more information on how to load dynamic content.
            </LCTypography>
            <LCTypography variant="body2">
                Important : IF you are running older watchfire hardware, that does not support secure connections,
                please request &quot;non ssl links&quot; from your Lucit rep.
            </LCTypography>

            {loadingBoards && <Skeleton animation="wave" height={300} width={'90%'} />}

            {digitalBoards && digitalBoards.map((board, idx) => {
                const dynamicUrlSecurePrefix = "https://pull.lucit.app/api/v1/campaigns/watchfire"
                const dynamicUrlUnSecurePrefix = "http://pull.lucit.app/api/v1/campaigns/watchfire"
                return (
                    <Box p={2} key={idx}>
                        <h5><BillboardsIcon /><> </>{board.board_identifier} : {board.name}</h5>
                        <code>
                            <b>SIGN ID</b> : {board.board_identifier}<br />
                            <b>EXPORT ID</b> : {exportItem.hash_id}<br />
                            <b>SIZE</b> : {board.width}x{board.height} <br />
                            <b>AD NAME</b> : Lucit : {exportItem.account && exportItem.account.name}  : {board.board_identifier}<br />
                            <b>AD GROUP</b>: Ad Group : Lucit : {exportItem.account && exportItem.account.name}
                            : {board.board_identifier}<br />
                            <b>XPATH</b> : lucit-layout-drive/item-set/item/@src<br />
                            <br />
                            <b>Dynamic URL - Secure</b>:
                            <ul>
                                <li><b>Dynamic : </b>{dynamicUrlSecurePrefix}/{exportItem.hash_id}/pull/xml?api_token=
                                    {apiToken}&amp;location_id={board.board_identifier}</li>
                                <li><b>Rotated : </b>{dynamicUrlSecurePrefix}/{exportItem.hash_id}/pull/xml?api_token=
                                    {apiToken}&amp;location_id={board.board_identifier}&amp;show_all=1</li>
                            </ul>

                            <b>Dynamic URL - Un-Secure</b>:
                            <ul>
                                <li><b>Dynamic : </b>{dynamicUrlUnSecurePrefix}/{exportItem.hash_id}/pull/xml?api_token=
                                    {apiToken}&amp;non_ssl_image_links=1&amp;location_id={board.board_identifier}</li>
                                <li><b>Rotated : </b>{dynamicUrlUnSecurePrefix}/{exportItem.hash_id}/pull/xml?api_token=
                                    {apiToken}&amp;non_ssl_image_links=1&amp;show_all=1&amp;location_id={board.board_identifier}</li>
                            </ul>

                        </code>
                    </Box>
                )
            })}

        </Box>
    )
}
