import {
    OBJECT_USERS_INVITATIONS_REQUEST, OBJECT_USERS_INVITATIONS_RESULT, OBJECT_USERS_INVITE,
    OBJECT_USERS_REMOVE, OBJECT_USERS_REQUEST, OBJECT_USERS_RESULT, OBJECT_USERS_UNINVITE, OBJECT_USERS_UPDATE_ROLES
} from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import { parseClass } from "../helpers/lcuid";
import { lucitApi } from "../services/lucitApi"

export function getUsers(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        dispatch({
            type: OBJECT_USERS_REQUEST,
            objectClass,
            lcuid
        });

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.users.getAgencyUsers(lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.users.getAccountUsers(lcuid)
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.users.getApplicationUsers(lcuid)

        return promise.then(data => {
            dispatch({
                type: OBJECT_USERS_RESULT,
                objectClass,
                data,
                lcuid
            })
        })
    }
}

export function deleteUser(userId, lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);
        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.users.deleteFromAgency(userId, lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.users.deleteFromAccount(userId, lcuid)
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.users.deleteFromApplication(userId, lcuid)

        return promise.then(() => dispatch({
            type: OBJECT_USERS_REMOVE,
            objectClass,
            lcuid,
            id: userId
        }))
    }
}

export function updateUserRoles(lcuid, user, roles) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);
        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.users.editAgencyRoles(user.id, lcuid, roles)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.users.editAccountRoles(user.id, lcuid, roles)
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.users.editApplicationRoles(user.id, lcuid, roles)

        return promise.then(() => {
            dispatch({
                type: OBJECT_USERS_UPDATE_ROLES,
                objectClass,
                lcuid,
                id: user.id,
                pivot: {
                    roles: roles.join(":")
                }
            })
        })
    }
}

export function getInvites(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        dispatch({
            type: OBJECT_USERS_INVITATIONS_REQUEST,
            objectClass,
            lcuid
        })

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.invitations.getAgencyInvites(lcuid)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.invitations.getAccountInvites(lcuid)
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.invitations.getApplicationInvites(lcuid)

        return promise.then(data => {
            dispatch({
                type: OBJECT_USERS_INVITATIONS_RESULT,
                objectClass,
                lcuid,
                data
            })
        })
    }
}

export function invite(lcuid, invites, roles) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.invitations.agencyInvite(lcuid, invites, roles)
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.invitations.accountInvite(lcuid, invites, roles)
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.invitations.applicationInvite(lcuid, invites, roles)

        return promise.then(data => {
            dispatch({
                type: OBJECT_USERS_INVITE,
                objectClass,
                lcuid,
                data: data.map(x => ({
                    ...x,
                    user: x.user
                        ? {
                            ...x.user,
                            pivot: {
                                roles: `:${roles.join(':')}:`,
                            }
                        }
                        : null
                }))
            })

            return data;
        })
    }
}

export function uninvite(lcuid, invitationLcuid) {
    return (dispatch) => {

        return lucitApi.invitations.delete(invitationLcuid)
            .then(() => {
                dispatch({
                    type: OBJECT_USERS_UNINVITE,
                    lcuid,
                    invitationLcuid,
                    objectClass: parseClass(lcuid)
                })
            })
    }
}
