import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { getScreenIcon } from '../../selectors/billboard'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
        label: {
            display: 'flex',
            flexDirection: 'column'
        },
        hasCampaignDot: {
            width: "10px",
            height: "10px",
            marginLeft: "4px",
            borderRadius: "50%",
            backgroundColor: "green",
            border: "2px solid white",
            display: "inline-block",
        }
    }
});

const BoardFormatToggleButton = (props) => {

    const classes = useStyles();
    const { boardFormat, boardFormatCode, isSelected, onClick, hasCampaignWithBoardFormatCode } = props;
    const { t } = useTranslation();

    const description = boardFormat.total_boards ?
        boardFormat.total_boards + " screens, with the largest size of " + boardFormat.largest_size +
        " covering formats in aspect ratios from " + boardFormat.ratios.min + " to " + boardFormat.ratios.max
        : "Base size of " + boardFormat.size.width + "x" + boardFormat.size.height +
        " Covering formats in aspect ratios from " + boardFormat.ratios.min + " to " + boardFormat.ratios.max

    return <Button
        style={{
            margin: "4px",
            fontSize: 12
        }}
        title={description}
        variant={isSelected ? "contained" : "outlined"}
        color="primary"
        onClick={onClick}
        classes={{
            label: classes.label
        }}
    >
        <img
            style={{ width: "48px" }}
            src={getScreenIcon(boardFormatCode, hasCampaignWithBoardFormatCode)}
        />
        {boardFormat.name}
        <Typography variant="caption">
            {boardFormat.size.width}x{boardFormat.size.height}
            {hasCampaignWithBoardFormatCode &&
                <span
                    className={classes.hasCampaignDot}
                    title={t('This format matches a screen in one of your campaigns')}>
                </span>}
        </Typography>
    </Button>

}

export default BoardFormatToggleButton
