import { createMigrate } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";

import {
    INVENTORY_SEARCH_REQUEST, INVENTORY_SEARCH_RESULT, INVENTORY_INVALIDATE,
    INVENTORY_ITEM_SELECT, INVENTORY_UNSELECT_ALL, INVENTORY_SELECT_ALL, INVENTORY_NEXT_PAGE_REQUEST,
    INVENTORY_NEXT_PAGE_RESULT, INVENTORY_SAVE_SCROLL_POSITION, EXPORTS_GET_RESULT, USER_SELECT_ACCOUNT,
    INVENTORY_SET_PRICE_OVERRIDE, INVENTORY_CLEAR_PRICE_OVERRIDE, INVENTORY_GETITEM_RESULT,
    INVENTORY_NEW_ITEM_PHOTO, INVENTORY_DELETE_ITEM, INVENTORY_ITEM_UPDATE, INVENTORY_ITEM_REMOVE_PHOTO,
    INVENTORY_NEW_ITEM, INVENTORY_SET_TITLE_OVERRIDE, INVENTORY_CLEAR_TITLE_OVERRIDE,
    INVENTORY_BOARD_SIZES_RESULT, INVENTORY_ACTIVATE_DRAFT
} from "../helpers/actionTypes"
import { InventoryItemCreativeState, InventoryItemStatus } from "../helpers/constants";
import { isItemsIdFilterAllowed } from "../selectors/exports";

export const initialState = {
    filters: null,
    inventoryClass: null,

    searched: false,
    data: [],
    exportsAvailable: [],

    loadingNextPage: false,

    currentPage: 1,
    perPage: 100,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,

    scrollY: null,

    // [{
    //    "aspect_ratio": 0.75,
    //    "matching_boards": [ 19287 ],
    //    "matching_board_count": 2,
    //    "min_width": 768
    //  }]
    boardSizes: [],

    invalidated: true,

    deletedIds: [],
}

const migrations = {
    // Make mustHavePhotos: true by default
    0: (state) => ({
        ...state,
        filters: {
            ...state.filters,
            mustHavePhotos: true
        }
    })
}

export const persistConfig = {
    key: 'inventory',
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations),
    version: 0
}

export default function inventory(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {

        case INVENTORY_BOARD_SIZES_RESULT:
            return Object.assign({}, state, {
                boardSizes: payload.data
            });

        case INVENTORY_INVALIDATE:
            return Object.assign({},
                state,
                {
                    ...initialState,
                    exportsAvailable: state.exportsAvailable,

                    inventoryClass: action.inventoryClass,
                });
        case USER_SELECT_ACCOUNT:
            return Object.assign({},
                state,
                initialState);
        case INVENTORY_SEARCH_REQUEST:
            return Object.assign({},
                state,
                {
                    filters: payload.filters
                });
        case INVENTORY_SEARCH_RESULT:
            return Object.assign({},
                state,
                payload,
                {
                    searched: true,
                    scrollY: null,
                    invalidated: false
                });
        case INVENTORY_NEXT_PAGE_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingNextPage: true
                });
        case INVENTORY_NEXT_PAGE_RESULT: {
            const { data, ...rest } = payload;

            return Object.assign({},
                state,
                rest,
                {
                    loadingNextPage: false,
                    data: state.data.concat(data)
                });
        }

        case INVENTORY_ITEM_UPDATE: {
            return Object.assign({}, state, {
                data: state.data.map(item => {
                    if (item.id === payload.id) {
                        return {
                            ...item,
                            ...payload.item,
                            creative_state: InventoryItemCreativeState.building
                        }
                    }

                    return item;
                })
            });
        }

        case INVENTORY_DELETE_ITEM: {
            return Object.assign({}, state, {
                data: state.data.filter(x => x.id !== payload.item.id),
                total: state.total - 1,
                deletedIds: [...state.deletedIds, payload.item.id]
            });
        }

        case INVENTORY_ITEM_REMOVE_PHOTO: {
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.itemId) {
                        return {
                            ...x,
                            creative_state: InventoryItemCreativeState.building,
                            photos: x.photos.filter(p => p.id !== payload.photoId)
                        };
                    }

                    return x;
                })
            });
        }

        case INVENTORY_NEW_ITEM: {
            return Object.assign({}, state, {
                data: [
                    payload.item,
                    ...state.data
                ]
            });
        }

        case INVENTORY_ACTIVATE_DRAFT: {
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.itemId) {
                        return {
                            ...x,
                            status: InventoryItemStatus.active
                        };
                    }

                    return x;
                })
            });
        }

        case INVENTORY_NEW_ITEM_PHOTO: {
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.itemId) {
                        return {
                            ...x,
                            creative_state: InventoryItemCreativeState.building,
                            photo: payload.photo,
                            photos: [...(x.photos || []), payload.photo]
                        };
                    }

                    return x;
                })
            });
        }

        case INVENTORY_ITEM_SELECT: {
            return Object.assign({}, state, {
                data: state.data.map(item => {
                    if (item.id === payload.itemId) {
                        return Object.assign({}, item, { selected: !item.selected })
                    }

                    return item;
                })
            });
        }
        case INVENTORY_UNSELECT_ALL:
            return Object.assign({}, state, {
                data: state.data.map(item => Object.assign({}, item, { selected: false }))
            });
        case INVENTORY_SELECT_ALL:
            return Object.assign({}, state, {
                data: state.data.map(item => Object.assign({}, item, { selected: true }))
            });

        case INVENTORY_SAVE_SCROLL_POSITION:
            return Object.assign({}, state, {
                scrollY: payload.scrollY
            })
        case EXPORTS_GET_RESULT:
            return Object.assign({}, state, {
                exportsAvailable: payload.data.filter(isItemsIdFilterAllowed)
            });

        case INVENTORY_GETITEM_RESULT: {
            return Object.assign({}, state, {
                data: state.data.some(d => d.id === payload.id)
                    ? state.data.map(item => {
                        if (item.id === payload.id) {
                            return Object.assign({}, item, payload)
                        }

                        return item;
                    })
                    : [
                        ...state.data,
                        payload
                    ]
            });
        }
        case INVENTORY_SET_TITLE_OVERRIDE:
        case INVENTORY_CLEAR_TITLE_OVERRIDE:
        case INVENTORY_SET_PRICE_OVERRIDE:
        case INVENTORY_CLEAR_PRICE_OVERRIDE:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.id) {
                        return {
                            ...x,
                            ...payload,
                            creative_state: InventoryItemCreativeState.building
                        };
                    }

                    return x;
                })
            });
        default:
            return state
    }
}
