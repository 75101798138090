import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles } from '@material-ui/core';
import LCTypography from '../../../components/material/LCTypography';
import { hasPrice } from '../../../selectors/inventory';
import DraftChip from './DraftChip';

const useStyles = makeStyles(theme => {
    return {
        text: {
            textAlign: 'left',
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1)
            }
        }
    }
})

function InventoryItemCardContentGeneric(props) {
    const { item } = props;
    const classes = useStyles();

    return <Box p={2}>
        {hasPrice(item)
            && <Typography className={classes.text} noWrap>${Number(item.price).toFixed(0)}</Typography>}

        {item.dealer_stock_number
            && <LCTypography transProps={{ itemDealerStockNumber: item.dealer_stock_number }} className={classes.text} noWrap>
                {{ itemDealerStockNumber: item.dealer_stock_number }}
            </LCTypography>}

        <DraftChip item={item} />
    </Box>;
}

InventoryItemCardContentGeneric.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
)(
    InventoryItemCardContentGeneric
);
