import React from 'react';
import { withHomeLayout } from '../layouts/HomeLayout';

import ButtonScrollToTopFab from '../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../components';
import { Container, makeStyles } from '@material-ui/core';
import BillboardsMap from '../containers/dashboard/BillboardsMap';
import { BottomSheetPortal } from '../layouts/BottomSheetPortal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    itemMap: {
        height: '100%',
        maxWidth: '100%',
        padding: '0px !important',
        marginTop: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: 0
        },
    }
}));

const Map = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t('Map')} />

            <Container className={classes.itemMap}>
                <BillboardsMap />
            </Container>

            <BottomSheetPortal>

            </BottomSheetPortal>

            <ButtonScrollToTopFab />
        </>
    );
};

export default withHomeLayout(Map);
