import {
    DASHBOARD_ACTIVE_ADS, DASHBOARD_ACTIVE_ADS_REQUEST, DASHBOARD_EXPORTS_REQUEST,
    DASHBOARD_LOCATIONS, DASHBOARD_LOCATIONS_REQUEST, EXPORTS_SEARCH_RESULT,
    FEEDS_SEARCH_RESULT, USER_SELECT_ACCOUNT
} from "../helpers/actionTypes";

export const initialState = {
    exports: [],
    feeds: [],

    activeAds: [],
    locations: [],
    lastPings: {},

    loadingAds: false,
    loadingLocations: false,
    loadingExports: false,
}

export default function dashboard(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case USER_SELECT_ACCOUNT:
            return Object.assign({},
                state,
                {
                    activeAds: []
                });
        case DASHBOARD_ACTIVE_ADS_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingAds: true
                });
        case DASHBOARD_ACTIVE_ADS:
            return Object.assign({},
                state,
                {
                    activeAds: payload.data,
                    loadingAds: false
                });

        case DASHBOARD_LOCATIONS_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingLocations: true
                });
        case DASHBOARD_LOCATIONS:
            return Object.assign({},
                state,
                {
                    locations: payload.data.locations,
                    lastPings: payload.data.last_pings,
                    loadingLocations: false
                })
        case DASHBOARD_EXPORTS_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingExports: true
                })

        case FEEDS_SEARCH_RESULT:
            return Object.assign({},
                state,
                {
                    feeds: payload.data
                })
        case EXPORTS_SEARCH_RESULT:
            return Object.assign({},
                state,
                {
                    exports: payload.data,
                    loadingExports: false
                });
        default:
            return state
    }
}
