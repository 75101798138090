import { FormHelperText, Grid, TextField, Box, Typography } from '@material-ui/core';
import React from 'react';
import usePlacesAutocomplete from "use-places-autocomplete";
import parse from 'autosuggest-highlight/parse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PinDrop } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useEffect } from 'react';

const GooglePlaceFinder = ({ field, onChange, value }) => {

    return <>
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <FormHelperText>{field.description}</FormHelperText>
            </Grid>
            <Grid xs={12} item>
                <PlacesAutocomplete
                    field={field}
                    defaultValue={value}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    </>
}

const PlacesAutocomplete = ({ field, defaultValue, onChange }) => {
    const {
        value,
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });

    const handleSelect = (selectedPlace) => {
        onChange(selectedPlace)
    };

    useEffect(() => {
        if (defaultValue && defaultValue.structured_formatting && defaultValue.structured_formatting.main_text) {
            setValue(defaultValue.structured_formatting.main_text);
        }
    }, [defaultValue, setValue]);

    return (
        <Grid
            container
            justify="flex-end"
            alignItems="center"
        >
            <Grid item xs={3}>
                {defaultValue ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <RadioButtonUncheckedIcon />}
            </Grid>
            <Grid item xs={9}>

                <Autocomplete
                    id={field.key}
                    name={field.key}
                    value={value ?? defaultValue}
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : (
                            option.structured_formatting.main_text
                        )
                    }
                    filterOptions={(x) => x}
                    options={data}
                    autoComplete
                    filterSelectedOptions
                    placeholder={field.placeholder}
                    noOptionsText="No matching places"
                    onChange={(_, newValue) => {
                        //When an option is chosen
                        handleSelect(newValue);
                    }}
                    onInputChange={(_, newInputValue) => {
                        //When typing is happening
                        setValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Find your Business Name" fullWidth />
                    )}
                    renderOption={(props) => {
                        const matches =
                            props && props.structured_formatting ? props.structured_formatting.main_text_matched_substrings : [];

                        const parts = parse(
                            props && props.structured_formatting ? props.structured_formatting.main_text : '',
                            matches.map((match) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <PinDrop sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                            >
                                                {part.text}
                                            </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                            {props && props.structured_formatting ? props.structured_formatting.secondary_text : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default GooglePlaceFinder;
