import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../../components';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import LCTypography from '../../components/material/LCTypography';
import { lucitApi } from "../../services/lucitApi";
import { LIGHTNING_GROUP_CAMPAIGN_DELETED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';

const GroupCampaignDeleteButton = (props) => {
    const { groupCampaign, handleDeleted } = props;
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(groupCampaign.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [groupCampaign, isConfirmOpen])

    const deleteGroupCampaign = () => {

        lucitApi.objects.delete(groupCampaign.lcuid)
            .then(() => dispatch({
                type: LIGHTNING_GROUP_CAMPAIGN_DELETED,
                groupCampaign
            }))
            .then(() => handleDeleted())
    }

    return (
        <>
            <ListItem button onClick={() => setIsConfirmOpen(true)}>
                <ListItemIcon ><Delete /></ListItemIcon>
                <ListItemText primary={t("Delete")} />
            </ListItem>

            <ConfirmationDialog
                title={t('Delete : ') + groupCampaign.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteGroupCampaign()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete <b>{groupCampaign.name}</b>?</LCTypography>
                        <LCTypography>This action CANNOT be un-done and once you delete this object, you will have to recreate all items,
                            data associated with it.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

export default GroupCampaignDeleteButton
