import { makeStyles, Typography, Box, Tabs, Tab, AppBar, Link, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clx from 'classnames';
import BillboardThumbnail from '../billboards/BillboardThumbnail';
import { RoutePaths } from '../../helpers/constants';
import { getFacingCardinal } from '../../selectors/billboard';
import { Avatar } from '../../components/material';
import { lucitApi } from '../../services/lucitApi';
import { hasIcon } from '../../selectors/account';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { IonCardContent, IonList, IonLabel, IonItem, IonIcon } from '@ionic/react';
import { locationOutline } from 'ionicons/icons';
import LCTypography from '../../components/material/LCTypography';
import BestBillboardMainImage from '../../containers/location/BestBillboardMainImage';

const useStyles = makeStyles(theme => {
    return {
        infoWindowImg: {
            width: '100%',
            height: '100%',
            objectFit: "contain",
            objectPosition: 'center',
            maxHeight: 300,
        },
        infoWindowGridLeft: {

        },
        billboardContainer: {
            minWidth: '100%'
        },
        infoWindowGridRight: {
            marginBottom: 'auto',
        },
        mapModalImageContainer: {
            padding: 0,
            height: 'auto',
            [theme.breakpoints.down('xs')]: {
                maxHeight: 200,
            },
        },
        billboardMap_infoModal: {
            width: '100%',
            padding: 0,
            paddingTop: 0,
            [theme.breakpoints.up('md')]: {
                width: '600px',
            },
        },
        sizeBoard: {
            margin: 0,
            marginLeft: "10px"
        },
        infoModalText: {
            display: 'flex !important',
            alignItems: 'center',
        },
        infoIconStyle: {
            marginRight: 8,
        },
        userMapTitleAvatar: {
            marginTop: 12,
            marginBottom: 12,
            marginRight: 15,
            width: 25,
            height: 25,
            marginInlineEnd: 32,
        },
        bilboardMap_cardHeader: {
            paddingBottom: 0,
        }
    }
})

const LocationDetailsPublic = (props) => {
    const { locationId, boards } = props;

    const classes = useStyles();

    const [location, setLocation] = useState(null);
    const [billboards, setBillboards] = useState([]);
    const [agency, setAgency] = useState(null);

    const [activeBillboard, setActiveBillboard] = useState(null);

    useEffect(() => {
        lucitApi.objects.get(`LL${locationId}`)
            .then(data => setLocation(data.object));

        // eslint-disable-next-line no-undef
        Promise.all(boards.map(x => lucitApi.objects.get(`LB${x.id}`)))
            .then(data => {
                const billboards = data.map(x => x.object);

                setActiveBillboard(billboards[0]);
                setBillboards(billboards);
            })
    }, [locationId, boards.length]);

    useEffect(() => {
        // Load agency if board selected, agency not loaded yet or board has another agency as previous
        if (activeBillboard && (!agency || agency.id !== activeBillboard.agency_id)) {
            setAgency(null)
            lucitApi.objects.get(`LY${activeBillboard.agency_id}`)
                .then(data => setAgency(data.object))
        }

        if (props.billboardChange) {
            props.billboardChange(activeBillboard)
        }
    }, [activeBillboard, agency])

    if (!location) {
        return <CircularProgressCentered size={40} />
    }

    return <div>
        {billboards.map(billboard =>
            <BoardTab
                key={billboard.id}
                {...{
                    isActive: billboard === activeBillboard,
                    location,
                    billboard,
                }}
            />
        )}

        <Box className={classes.billboardContainer}>

            {billboards.length > 1
                && <AppBar position="static" color="transparent">
                    <Tabs
                        value={activeBillboard.id}
                        onChange={(_, value) => {
                            setActiveBillboard(billboards.find(b => b.id === value));
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {billboards.map((billboard, idx) => {
                            const faceName = getFacingCardinal(billboard)
                                ? getFacingCardinal(billboard) + ' Face'
                                : 'Face ' + (idx + 1)

                            return <Tab key={billboard.id} label={faceName} value={billboard.id} />
                        })}
                    </Tabs>
                </AppBar>}

            <IonCardContent>
                <IonList>
                    {agency ?
                        <IonItem>
                            <Tooltip title="Operator">
                                <Avatar
                                    src={hasIcon(agency) && agency.options.primary_image_public_url}
                                    name={agency.name}
                                    className={clx(classes.userMapTitleAvatar, { [classes.avatarNoIcon]: !hasIcon(agency) })}
                                    slot="start" />
                            </Tooltip>
                            <IonLabel>
                                <Link component={RouterLink}
                                    to={RoutePaths.public.slug(agency.slug || agency.lcuid)}
                                    color="secondary">
                                    {agency.name}
                                </Link>
                                <span> : </span>
                                {Boolean(activeBillboard)
                                    && <Link component={RouterLink}
                                        to={RoutePaths.public.slug(activeBillboard.slug || activeBillboard.lcuid)}
                                        color="primary">
                                        <LCTypography variant="h7" color="secondary">
                                            {activeBillboard.name}
                                        </LCTypography>
                                    </Link>}
                            </IonLabel>
                        </IonItem>
                        :
                        <IonItem>
                            <Typography>
                                <Skeleton variant="text" width="50%" />
                            </Typography>
                        </IonItem>
                    }
                    {location ?
                        <IonItem>
                            <Tooltip title="Location">
                                <IonIcon icon={locationOutline} slot="start" />
                            </Tooltip>
                            <IonLabel className={classes.infoModalText}>
                                {location.address} {location.city} {location.region} {location.postal_code}
                                <span> : </span>{Boolean(activeBillboard) && <BillboardThumbnail
                                    board={activeBillboard}
                                    boardScale={20}
                                    fontSize={16}
                                    classes={{ board: classes.sizeBoard }}
                                    showAsOneLine={true} />}
                            </IonLabel>
                        </IonItem>
                        :
                        <IonItem>
                            <Typography>
                                <Skeleton variant="text" width="50%" />
                            </Typography>
                        </IonItem>
                    }

                </IonList>
            </IonCardContent>
        </Box>
    </div>
}

const BoardTab = props => {
    const { location, billboard, isActive } = props;
    const classes = useStyles();

    if (!isActive) {
        return null;
    }

    return <Box p={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.mapModalImageContainer}>
        {billboard
            && billboard.options
            && billboard.options.public_url
            && <img src={billboard.options.public_url}
                alt={location.name}
                className={classes.infoWindowImg} />}

        {billboard
            && <BestBillboardMainImage billboard={billboard} />}
    </Box>;
}

LocationDetailsPublic.propTypes = {
    locationId: PropTypes.number.isRequired,
    boards: PropTypes.array.isRequired,

    billboardChange: PropTypes.func,
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    LocationDetailsPublic
);
