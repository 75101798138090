import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, Box,
    useTheme, useMediaQuery, Grid, MenuItem, InputAdornment, RadioGroup, Radio, FormControlLabel, FormControl
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Dialog, ButtonLoader } from '../../components/material';
import { createScreen } from '../../actions/billboards';
import { showError, showSuccess } from '../../actions/snackbar';
import { Field, reduxForm, SubmissionError, submit } from 'redux-form';
import TextField from '../../components/inputs/TextField';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { capitalize } from '../../helpers/string';
import { required, min } from '../../helpers/validators';
import { history } from '../../helpers/history';
import { ErrorCodes, RoutePaths } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        }
    }
})

const NewBillboardDialog = (props) => {
    const { open, submitting, submitForm, title, handleClose, dialogProps } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            disableBackdropAndEscapeClose
            {...dialogProps}
        >
            <DialogContent className={classes.content}>
                <NewBillboardForm {...props} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <ButtonLoader
                    color="secondary"
                    submitting={submitting}
                    onClick={() => submitForm()}
                    variant="contained">
                    Create
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const NewBillboardForm = props => {
    const { error, handleSubmit, onSubmit, submitting } = props;
    const { t } = useTranslation();

    const [locationType, setLocationType] = useState("address");

    return (
        <Box pt={2} pb={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="name"
                        validate={[required]}
                        component={TextField}
                        props={{
                            autoFocus: true,
                            label: t("Name"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            id: "name",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="identifier"
                        validate={[required]}
                        component={TextField}
                        props={{
                            label: t("Identifier"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            id: "identifier",
                            helperText: "This is usually the ID of screen in your system"
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                    <Field
                        name="width"
                        validate={[required, min(1)]}
                        component={TextField}
                        props={{
                            label: t("Width"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            type: 'number',
                            min: 0,
                            id: "width",
                            InputProps: {
                                endAdornment: <InputAdornment position="end" >px</InputAdornment>
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                    <Field
                        name="height"
                        validate={[required, min(0)]}
                        component={TextField}
                        props={{
                            label: t("Height"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            id: "height",
                            min: 0,
                            type: 'number',
                            InputProps: {
                                endAdornment: <InputAdornment position="end" >px</InputAdornment>
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <Field
                        name="hardware"
                        validate={[required]}
                        component={TextField}
                        props={{
                            label: t("Hardware Provider"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            select: true,
                            id: "hardware",
                        }}
                    >
                        {props.hardwareProviders
                            .map(provider => <MenuItem key={provider} value={provider}>
                                {capitalize(provider)}
                            </MenuItem>)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <Field
                        name="software"
                        validate={[required]}
                        component={TextField}
                        props={{
                            label: t("Software Provider"),
                            variant: "outlined",
                            disabled: submitting,
                            required: true,
                            fullWidth: true,
                            select: true,
                            id: "software",
                        }}
                    >
                        {props.softwareProviders
                            .map(provider => <MenuItem key={provider} value={provider}>
                                {capitalize(provider)}
                            </MenuItem>)}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={locationType}
                            onChange={e => setLocationType(e.target.value)}
                            row
                        >
                            <FormControlLabel value="address" control={<Radio />} label="Address" />
                            <FormControlLabel value="coordinates" control={<Radio />} label="Coordinates" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {locationType === 'address'
                    && <>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="address"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("Address"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "address",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="city"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("City"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "city",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="region"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("State / Province"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "region",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="country"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("Country"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "country",
                                }}
                            />
                        </Grid>
                    </>}
                {locationType === 'coordinates'
                    && <>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="latitude"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("Latitude"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "latitude",
                                    type: 'number'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name="longitude"
                                validate={[required]}
                                component={TextField}
                                props={{
                                    label: t("Longitude"),
                                    variant: "outlined",
                                    disabled: submitting,
                                    required: true,
                                    fullWidth: true,
                                    id: "longitude",
                                    type: 'number'
                                }}
                            />
                        </Grid>
                    </>}
            </Grid>

            {error
                && <Box mt={2}>
                    <LCTypography transProps={{ error: error }} color="error">{{ error: error }}</LCTypography>
                </Box>}

        </Box>
    )
}

const mapStateToProps = (state) => {

    return {
        softwareProviders: state.billboard.softwareProviders,
        hardwareProviders: state.billboard.hardwareProviders,
    }
}

const mapStateToDispatch = (dispatch, ownProps) => {
    return {
        create: (screen) => dispatch(createScreen(screen))
            .then(() => dispatch(showSuccess("Digital Board was successfully created")))
            .catch(error => {
                dispatch(showError("Error during creating Digital Board"))
                throw error;
            }),
        submitForm: () => dispatch(submit(FORM_NEW_SCREEN)),
        onSubmit: values => {
            return dispatch(createScreen({
                ...values,
                board_identifier: values.identifier,
                hardware_provider: values.hardware,
                software_provider: values.software,
            })).then(screen => {
                dispatch(showSuccess(`${screen.name} was successfully created`))
                ownProps.handleClose();

                history.push({ pathname: RoutePaths.screen(screen.id) })
            }).catch(error => {
                const response = error.response;

                if (response.data.code === ErrorCodes.boardIdExists) {
                    throw new SubmissionError({
                        identifier: 'You already have screen with this ID'
                    })
                }
            })
        }
    }
};

export const FORM_NEW_SCREEN = "screenNew";

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    reduxForm({
        form: FORM_NEW_SCREEN,
        initialValues: {
            name: "",
            identifier: "",
            width: null,
            height: null,
            hardware: "_unknown",
            software: "_unknown"
        }
    })(
        NewBillboardDialog
    )
)
