import React from 'react';
import { useSwipeable } from 'react-swipeable';

export const Swipeable = ({ children, innerRef, className, ...props }) => {
    const handlers = useSwipeable(props);
    const refCallback = (ref) => {
        handlers.ref(ref);
        innerRef(ref);
    }
    return (<div {...handlers} ref={refCallback} className={className}>{children}</div>);
}