import { useCallback, useEffect, useState } from 'react';
import { Global } from '../constants';
import useInterval from './useInterval';

const defaultOptions = {
    running: true,
    milliseconds: null,
    maxTicksCount: Global.statusUpdateMaxCount
};

/**
 * An async-utility hook that accepts a callback function and a delay time (in milliseconds), then repeats the
 * execution of the given function by the defined milliseconds. But limited to X number of reuqests
 */
const useIntervalLimited = (fn, options = {}) => {
    const { running, milliseconds, maxTicksCount } = Object.assign({}, defaultOptions, options);

    const [checkCount, setCheckCount] = useState(0);
    const reset = useCallback(() => {
        setCheckCount(0)
    }, []);

    const limitExceeded = checkCount >= maxTicksCount;

    const interval = running && !limitExceeded
        ? milliseconds || Global.statusUpdateInterval()
        : null;

    const [isCleared, clear] = useInterval(() => {
        setCheckCount(checkCount + 1);

        if (fn) {
            fn();
        }
    }, interval);

    useEffect(() => {
        if (!running) {
            reset();
        }
    }, [running, reset])

    return {
        isCleared,
        clear,
        limit: maxTicksCount,
        limitExceeded,
        count: checkCount,
        reset
    };
};

export default useIntervalLimited;