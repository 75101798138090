import {
    USER_LOGIN, USER_INFO, USER_LOGOUT, USER_SELECT_ACCOUNT, USER_SELECT_USER, USER_NEW_UPDATE, USER_SET_TIMEZONE, USER_SW_INITIALIZED,
    USER_CONNECTION_STATUS_CHANGE, USER_PUSH_NOTIFICATIONS_TOKEN, PUSH_NOTIFICATIONS_SAVE_TYPE_PREFERENCE,
    PUSH_NOTIFICATIONS_SAVE_PREFERENCE, USER_ACCOUNT_SET_IMAGE, USER_SET_IMAGE, USER_REMOVE_IMAGE, USER_UPDATE_TOUR,
    USER_UPDATE, USER_ACCEPT_EULA,
    USER_SELECT_AGENCY, AGENCY_APPROVAL_SETTINGS_CHANGED, USER_AGENCY_SET_IMAGE,
    ACCOUNT_UPDATE, ACCOUNT_DELETE, AGENCY_DELETE, AGENCY_UPDATE,
    USER_ACCOUNT_REMOVE_IMAGE,
    USER_AGENCY_REMOVE_IMAGE,
    USER_DEVELOPER_MODE,
    ACCOUNT_JOIN,
    ACCOUNT_ADD,
    USER_LOGIN_MFA,
    USER_LOGIN_MFA_RESET, USER_STORE_PERMISSIONS,
    ACCOUNT_UPDATE_IS_PARENT,
    ACCOUNT_UPDATE_IS_PUBLIC
} from "../helpers/actionTypes"
import { createMigrate } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {
    isAccountSelected, hasAccounts, hasAccount, isAgencySelected, isAllAgencySelected, isAllAccountSelected,
    hasAgency, hasAgencies
} from "../selectors/user";
import { ProfileType } from "../helpers/constants";
import { initialState as billingInitialState } from "./templates/billing";
import { idOrLcuidEquals } from "../selectors/objects";

export const initialState = {
    isDeveloperMode: false,
    isLoggedIn: false,
    token: null,
    tokenPushNotifications: null,

    id: null,
    name: null,
    email: null,
    emailConfirmed: false,
    phone: null,
    phoneConfirmed: false,
    roles: [],
    createdAt: null,
    updatedAt: null,

    profileType: ProfileType.user,
    selectedAccountId: null,
    selectedAgencyId: null,

    hasUpdate: false,
    handleUpdate: null,
    handleCheckUpdate: null,

    isOnline: true,

    location: null,
    locationGps: null,

    applications: [],
    accounts: [],
    agencies: [],
    options: {
        tours: {},
        notification_channels: [],
        notification_classes: {},
        eula_accept: {},
    },
    permissions: {},

    billing: { ...billingInitialState },

    mfa: false,
    mfa_last_4_digits: null,
    mfa_sms_code_expire_seconds: null,
    mfa_token: null
}

const migrations = {
    // Refactor selectedAccount property to selectedAccountId
    0: (state) => {
        const nextState = {
            ...state,
            selectedAccountId: state.selectedAccount
                ? state.selectedAccount.id
                : null,
            selectedAgencyId: state.selectedAgency
                ? state.selectedAgency.id
                : null
        };

        delete nextState.selectedAccount;
        delete nextState.selectedAgency;
        return nextState;
    },
}

export const userPersistConfig = {
    key: 'user',
    blacklist: ['hasUpdate', 'handleUpdate', 'handleCheckUpdate', 'isOnline', 'cards', 'mfa'],
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations),
    version: 0
}

export default function user(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case USER_LOGIN:
            return Object.assign({}, state, payload);
        case USER_LOGIN_MFA:
            return Object.assign({}, state, {
                ...payload,
                mfa: true
            });
        case USER_LOGIN_MFA_RESET:
            return Object.assign({}, state, {
                mfa: false
            });
        case USER_LOGOUT:
            return Object.assign({}, state, initialState);
        case USER_SET_TIMEZONE:
            return Object.assign({}, state, {
                options: {
                    ...state.options,

                    timezone: payload.timezone
                }
            });
        case USER_UPDATE:
            return Object.assign({}, state, payload);
        case USER_INFO: {
            let nextState = Object.assign({}, state, payload, { mfa: false });
            const isAccountExists = hasAccount(nextState);
            const isAgencyExists = hasAgency(nextState);

            // if account was selected, but now it doesn't exists -> unselect it
            if (isAccountSelected(state) &&
                !isAllAccountSelected(state) &&
                !isAccountExists(state.selectedAccountId)) {
                nextState = Object.assign({}, nextState, { selectedAccountId: null });
            }

            // pre-select first account if profileType account and it is not selected yet
            if (state.profileType.id == ProfileType.account.id &&
                !isAccountSelected(nextState) &&
                hasAccounts(nextState)) {
                nextState.selectedAccountId = nextState.accounts[0].id;
            }

            // if agency was selected, but now it doesn't exists -> unselect it
            if (isAgencySelected(state) &&
                !isAllAgencySelected(state) &&
                !isAgencyExists(state.selectedAgencyId)) {
                nextState = Object.assign({}, nextState, { selectedAgencyId: null });
            }

            // pre-select first agency if profileType agency and it is not selected yet
            if (state.profileType.id == ProfileType.agency.id &&
                !isAgencySelected(nextState) &&
                hasAgencies(nextState)) {
                nextState.selectedAgencyId = nextState.agencies[0].id;
            }

            return nextState;
        }
        case USER_SELECT_USER: {
            return Object.assign({}, state, {
                profileType: ProfileType.user,
                selectedAccountId: null,
                selectedAgencyId: null
            });
        }
        case USER_SELECT_ACCOUNT:
            return Object.assign({}, state, {
                profileType: ProfileType.account,
                selectedAccountId: payload.account.id,
                selectedAgencyId: null
            });

        case USER_SELECT_AGENCY:
            return Object.assign({}, state, {
                profileType: ProfileType.agency,
                selectedAccountId: null,
                selectedAgencyId: payload.agency.id
            });

        case USER_NEW_UPDATE:
            return Object.assign({}, state, {
                hasUpdate: true,
                handleUpdate: payload.handleUpdate
            });
        case USER_SW_INITIALIZED:
            return Object.assign({}, state, {
                handleCheckUpdate: payload.handleCheckUpdate
            });
        case USER_CONNECTION_STATUS_CHANGE:
            return Object.assign({}, state, {
                isOnline: payload.isOnline
            });
        case USER_PUSH_NOTIFICATIONS_TOKEN:
            return Object.assign({}, state, {
                tokenPushNotifications: payload.token
            });
        case PUSH_NOTIFICATIONS_SAVE_TYPE_PREFERENCE:
            return Object.assign({}, state, {
                options: {
                    ...state.options,
                    notification_classes: {
                        ...state.options.notification_classes,

                        [payload.class]: payload.enabled
                            ? [...state.options.notification_classes[payload.class], payload.channel]
                            : state.options.notification_classes[payload.class].filter(c => c !== payload.channel)
                    }
                }
            });
        case PUSH_NOTIFICATIONS_SAVE_PREFERENCE:
            return Object.assign({}, state, {
                options: {
                    ...state.options,

                    notification_channels: payload.enabled
                        ? [...state.options.notification_channels, payload.channel]
                        : state.options.notification_channels.filter(c => c !== payload.channel)
                }
            });

        case ACCOUNT_UPDATE_IS_PARENT: {
            const update = account => ({
                ...account,
                is_parent_account: payload.is_parent_account
            });
            return updateAccount(state, payload.lcuid, update);
        }
        case ACCOUNT_UPDATE_IS_PUBLIC:
            {
                const update = account => ({
                    ...account,
                    options: {
                        ...account.options,
                        public_can_join: Boolean(payload.public_can_join),
                        public_join_options: payload.publicJoinOptions
                    }
                });
                return updateAccount(state, payload.lcuid, update);
            }

        case ACCOUNT_UPDATE:
            {
                const update = account => Object.assign({}, account, {
                    name: payload.account.name,
                    description: payload.account.description,
                    website: payload.account.website,
                    options: payload.account.options,
                    inventory_item_class: payload.account.inventory_item_class,
                    child_accounts: payload.account.child_accounts,
                    default_currency: payload.account.default_currency,
                    inventory_item_class_description: payload.account.inventory_item_class_description,
                    parent_account: payload.account.parent_account,
                    parent_account_id: payload.account.parent_account_id,
                });

                return updateAccount(state, payload.account.id, update);
            }

        case ACCOUNT_DELETE:
            {
                const newState = {
                    accounts: state.accounts.filter((x => x.id !== payload.account.id))
                };

                if (state.profileType.id === ProfileType.account.id &&
                    state.selectedAccountId == payload.account.id) {
                    newState.profileType = ProfileType.user;
                }

                return Object.assign({}, state, newState);
            }

        case AGENCY_DELETE:
            {
                const newState = {
                    agencies: state.agencies.filter((x => x.id !== payload.agency.id))
                };

                if (state.profileType.id === ProfileType.account.id &&
                    state.selectedAccountId == payload.agency.id) {
                    newState.profileType = ProfileType.user;
                }

                return Object.assign({}, state, newState);
            }
        case ACCOUNT_ADD:
        case ACCOUNT_JOIN: {
            return Object.assign({}, state, {
                accounts: [payload.account, ...state.accounts]
            });
        }

        case USER_ACCOUNT_SET_IMAGE:
            {
                const update = account => Object.assign({}, account, {
                    options: payload.options
                });

                return Object.assign({}, state, {
                    accounts: state.accounts.map(account => {
                        if (account.id === payload.accountId) {
                            return update(account);
                        }

                        return account;
                    })
                });
            }

        case AGENCY_UPDATE:
            {
                const update = agency => Object.assign({}, agency, {
                    name: payload.agency.name,
                    description: payload.agency.description,
                    website: payload.agency.website,
                });

                return updateAgency(state, payload.agency.id, update);
            }

        case USER_AGENCY_SET_IMAGE:
            {
                const update = agency => Object.assign({}, agency, {
                    options: payload.options
                });

                return Object.assign({}, state, {
                    agencies: state.agencies.map(agency => {
                        if (agency.id === payload.agencyId) {
                            return update(agency);
                        }

                        return agency;
                    })
                });
            }

        case USER_SET_IMAGE:
            return Object.assign({}, state, {
                options: Object.assign({}, state.options, payload.options)
            });
        case USER_REMOVE_IMAGE:
            return Object.assign({}, state, {
                options: Object.assign({}, state.options, {
                    primary_image_id: null,
                    primary_image_public_url: null
                })
            });

        case USER_ACCOUNT_REMOVE_IMAGE:
            return Object.assign({}, state, {
                accounts: state.accounts.map(a => {
                    if (a.id === payload.accountId) {
                        return {
                            ...a,
                            options: {
                                ...a.options,
                                primary_image_public_url: null
                            }
                        }
                    }

                    return a;
                })
            });

        case USER_AGENCY_REMOVE_IMAGE:
            return Object.assign({}, state, {
                agencies: state.agencies.map(a => {
                    if (a.id === payload.agencyId) {
                        return {
                            ...a,
                            options: {
                                ...a.options,
                                primary_image_public_url: null
                            }
                        }
                    }

                    return a;
                })
            });

        case USER_UPDATE_TOUR:
            return Object.assign({}, state, {
                options: {
                    ...state.options,

                    tours: {
                        ...state.options.tours,
                        [payload.tourId]: payload
                    }
                }
            });
        case USER_ACCEPT_EULA:
            return Object.assign({}, state, {
                options: {
                    ...state.options,

                    eula_accept: {
                        ...state.options.eula_accept,

                        [state.applicationId]: payload
                    }
                }
            });

        case AGENCY_APPROVAL_SETTINGS_CHANGED: {

            const update = agency => Object.assign({}, agency, {
                options: {
                    ...agency.options,
                    approvals: payload.settings
                }
            });

            return Object.assign({}, state, {
                agencies: state.agencies.map(agency => {
                    if (agency.id === payload.agencyId) {
                        return update(agency);
                    }

                    return agency;
                }),
            });
        }

        case USER_STORE_PERMISSIONS: {

            return Object.assign({}, state, {
                permissions: {
                    ...state.permissions,
                    [payload.object.lcuid]: payload.permissions
                }
            });
        }

        case USER_DEVELOPER_MODE:
            return Object.assign({}, state, {
                isDeveloperMode: payload.isDeveloperMode
            });

        default:
            return state
    }
}

const updateAccount = (state, accountId, update) => ({
    ...state,
    accounts: state.accounts.map(account => {
        if (idOrLcuidEquals(account, accountId)) {
            return update(account);
        }

        return account;
    })
});

const updateAgency = (state, agencyId, update) => ({
    ...state,
    agencies: state.agencies.map(agency => {
        if (idOrLcuidEquals(agency, agencyId)) {
            return update(agency);
        }

        return agency;
    })
});
