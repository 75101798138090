import { ApplicationClasses, ApplicationCapabilities } from '../helpers/constants';

export const dataSourceFeedProviders = (state) => state.applications.dataSource.feedProviders;

export const hasCapability = (application, capabilityConfig) => {

    return application.capabilities?.some((appCapability) => appCapability.destination.capability_class === capabilityConfig.class);

}

export const getCapabilityConfigForClassName = (className) => {

    const matchingCapability = Object.keys(ApplicationCapabilities).filter((capabilityCode) => {
        return ApplicationCapabilities[capabilityCode].class === className
    })

    if (matchingCapability.length === 0) {
        return null;
    }

    return ApplicationCapabilities[matchingCapability[0]]
}

export const getApplicationConfigForClassName = (className) => {

    const matchingApp = Object.keys(ApplicationClasses).filter((appCode) => ApplicationClasses[appCode].class === className);

    if (matchingApp.length === 0) {
        return null;
    }

    return ApplicationClasses[matchingApp[0]];
}

export const requiresAuthorization = (applicationClass) => {
    return getApplicationConfigForClassName(applicationClass).requiresAuthorization;
}
