import { SubmissionError } from 'redux-form';
import moment from 'moment';
import React from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorCodes, RoutePaths, QueryString } from '../helpers/constants';
import { apiHttp } from "../helpers/api";

export default class LucitApiRegistration {

    create(phone, join_lcuid, join_code) {
        return apiHttp.post('/users/registration', { mobile_phone_number: phone, join_lcuid: join_lcuid, join_code: join_code })
            .then(response => ({
                id: response.data.user_registration_lcuid,
                codeSentTime: moment.utc()
            }))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                const redirectQueryString = `?${QueryString.username}=${phone}`
                    + (join_lcuid ? '&' + QueryString.join_lcuid + '=' + join_lcuid : '')
                    + (join_code ? '&' + QueryString.join_code + '=' + join_code : '');

                if (data.code === ErrorCodes.userAlreadyRegistered) {
                    throw new SubmissionError({
                        _error: <span>Phone number is already registered. <Link
                            color="secondary"
                            component={RouterLink}
                            to={{
                                pathname: RoutePaths.login,
                                search: redirectQueryString
                            }}>Try Logging In</Link></span>
                    })
                }
                else if (data.code === ErrorCodes.userAlreadyExists) {
                    throw new SubmissionError({
                        _error: data.code
                    })
                }
                else if (data.code === ErrorCodes.phoneInvalidFormat) {
                    throw new SubmissionError({
                        phone: 'Invalid phone number format - Please use: ###-###-####'
                    })
                }

                throw new SubmissionError({
                    _error: <span>Something went wrong. <Link
                        color="secondary"
                        component={RouterLink}
                        to={RoutePaths.contact}>Contact us</Link></span>
                })
            })

    }

    confirmPhone(registrationId, code) {
        return apiHttp.put(`/users/registration/${registrationId}/confirm`, {
            sms_code: code
        }).catch(error => {
            if (error.isOffline) {
                throw error;
            }

            const { data } = error.response;

            if (data.code === ErrorCodes.smsCodeInvalidNew) {
                throw new SubmissionError({
                    code: "Confirmation code is invalid"
                });
            } else if (data.code === ErrorCodes.smsCodeExpired) {
                throw new SubmissionError({
                    code: "Confirmation code is expired"
                })
            } else if (data.data) {
                if (data.data.sms_code) {
                    throw new SubmissionError({
                        code: data.data.sms_code
                    })
                }
            }

            throw new SubmissionError({
                _error: <span>Something went wrong. <Link
                    color="secondary"
                    component={RouterLink}
                    to={RoutePaths.contact}>Contact us</Link></span>
            })
        })
    }

    createUser(data) {
        return apiHttp.post(`/users/registration/${data.registrationId}/create-user`, {
            name: data.name,
            email: data.email,
            password: data.password,
            timezone: data.timezone,
        })
            .then(response => ({
                id: response.data.user_id
            }))
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                if (data.code === ErrorCodes.userEmailAlreadyExists) {
                    throw new SubmissionError({
                        _error: <span>User with this email already registered. <Link
                            color="secondary"
                            component={RouterLink}
                            to={{ pathname: RoutePaths.login, search: `?${QueryString.username}=${data.email}` }}>Login</Link></span>
                    })
                }

                if (data.code === ErrorCodes.userRegistrationExpired) {
                    throw new SubmissionError({
                        _error: <span>Registration was expired, please try again. <Link
                            color="secondary"
                            component={RouterLink}
                            to={{ pathname: RoutePaths.registrationNew }}>Register</Link></span>
                    })
                }

                throw new SubmissionError({
                    _error: <span>Something went wrong. <Link
                        color="secondary"
                        component={RouterLink}
                        to={RoutePaths.contact}>Contact us</Link></span>
                })
            })
    }

    register(phone, code, password, timezone) {
        const body = {
            mobile_phone_number: phone,
            sms_code: code,
            password,
            timezone
        };
        return apiHttp.post('/register', body)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const { data } = error.response;

                if (data.code === ErrorCodes.smsCodeInvalid) {
                    throw new SubmissionError({
                        code: "Confirmation code is invalid"
                    });
                }
                else if (data.code === ErrorCodes.userAlreadyRegistered) {
                    throw new SubmissionError({
                        _error: <span>User with this phone already registered. <Link
                            color="secondary"
                            component={RouterLink}
                            to={{ pathname: RoutePaths.login, search: `?${QueryString.username}=${phone}` }}>Login</Link></span>
                    })
                }
                else if (data.code === ErrorCodes.userPhoneNotExists) {
                    throw new SubmissionError({
                        phone: "Phone number does not exist"
                    })
                } else if (data.data) {
                    if (data.data.sms_code) {
                        throw new SubmissionError({
                            code: data.data.sms_code
                        })
                    }
                }

                throw new SubmissionError({
                    _error: <span>Something went wrong. <Link
                        color="secondary"
                        component={RouterLink}
                        to={RoutePaths.contact}>Contact us</Link></span>
                })
            })
    }

    getSmsCode(phone) {
        const queryString = {
            mobile_phone_number: phone
        };

        return apiHttp.get('/get-sms-code', { params: queryString })
            .then(response => {
                response.data.codeSentTime = moment.utc();

                return response.data;
            })
            .catch(error => {
                if (error.isOffline) {
                    throw error;
                }

                const response = error.response;

                if (response.status === 422) {
                    if (response.data.code === ErrorCodes.userPhoneNotExists) {
                        throw new SubmissionError({
                            phone: "Phone number does not exist"
                        })
                    } else if (response.data.code === ErrorCodes.validationFailed
                        || response.data.code === ErrorCodes.phoneInvalidFormat) {
                        throw new SubmissionError({
                            phone: 'Invalid phone number format - Please use: ###-###-####'
                        })
                    }
                }

                throw new SubmissionError({
                    _error: 'Something went wrong'
                })
            })
    }

}
