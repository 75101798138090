import { Box, Button, Chip, List, ListItem, ListItemText, makeStyles, Typography, Link as MaterialLink, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import ApplicationAvatar from './ApplicationAvatar';
import { lucitApi } from '../../services/lucitApi';
import YouTubeVideo from '../../components/video/YouTubeVideo';
import ReactMarkdown from 'react-markdown'
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            // height: 'calc(100vh - 180px)',
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const AssignedAppInfoDialog = (props) => {
    const { app, open, handleClose } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [permissions, setPermissions] = useState({});
    const [loadingPermissions, setLoadingPermissions] = useState(false);

    useEffect(() => {
        setLoadingPermissions(true);
        lucitApi.applications.getPermissions()
            .then(data => {
                const map = data.reduce((prev, curr) => ({ ...prev, [curr.fully_qualified_policy]: curr }), {});
                setPermissions(map)
            })
            .finally(() => setLoadingPermissions(false));
    }, []);

    return (
        <GenericDialog
            title={t('Info')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                {app
                    && <>
                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                            <ApplicationAvatar app={app} style={{ width: 100, height: 100, marginRight: 0 }} />

                            <Typography variant="h6">{app.name}</Typography>
                        </Box>

                        <LCTypography variant="h6">App Details</LCTypography>

                        {app.description
                            && <Box mt={3}>
                                <ReactMarkdown>{app.description}</ReactMarkdown>
                            </Box>}

                        {app.help_url && <Box mt={1}>
                            <MaterialLink
                                href={app.help_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <strong>{t('Help & Support')}</strong> : {app.help_url}
                            </MaterialLink>
                        </Box>}

                        {app.video_url && <Box mt={6} mb={3}>

                            <YouTubeVideo
                                url={app.video_url}
                                youTubeProps={{
                                    opts: {
                                        width: '580',
                                        height: '400',
                                    }
                                }}
                            />

                        </Box>}

                        <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

                        {app.organization_name && <Box mt={1}>
                            <Typography variant="subtitle2">{app.organization_name}</Typography>
                        </Box>}

                        {app.website && <Box mt={1}>
                            <Typography variant="body2">
                                <MaterialLink
                                    href={app.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {app.website}
                                </MaterialLink>
                            </Typography>

                        </Box>}

                        <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

                        <Typography variant="h6">Capabilities</Typography>

                        <Box display="flex" flexWrap="wrap" mt={2} mb={2}>
                            {app.capabilities.map(c => c.destination)
                                .map(x =>
                                    <Chip key={x.id} label={x.name}
                                        style={{ margin: 2 }}
                                    />)}
                        </Box>

                        <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />

                        <LCTypography variant="h6">Permissions</LCTypography>

                        <Box mb={3}>
                            {!loadingPermissions
                                &&
                                <List style={{ listStyle: 'disc', listStylePosition: "inside" }}>
                                    {app.permissions.allowed.map(p => <ListItem key={p}
                                        style={{ paddingTop: 0, paddingBottom: 0, display: 'list-item' }}>
                                        <ListItemText
                                            style={{ display: 'inline-block' }}
                                            primary={permissions[p] && permissions[p].explanation}
                                        />
                                    </ListItem>)}
                                </List>
                            }
                        </Box>

                    </>}
            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('OK')}
                </Button>
            </>}
        />
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignedAppInfoDialog)
