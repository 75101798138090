import { } from "../helpers/actionTypes";

export const initialState = {
}

export default function analytics(state = initialState, action) {
    switch (action.type) {

        default:
            return state
    }
}
