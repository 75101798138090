import { Box, Container } from '@material-ui/core';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { newStory } from '../../actions/stories';
import { storySelector } from '../../selectors/stories';
import { StoryClassMap } from '../../components';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';

function Story(props) {
    const { object, newStory, storySelector } = props;

    useEffect(() => {
        newStory(object);
    }, [object.id])

    const Story = StoryClassMap[object.story_class];
    const story = storySelector(object.id);

    if (story == null) {
        return null;
    }

    return <Container>
        <Box display="flex" justifyContent="center">
            <Story story={story} commentsOpenDefault={true} />
        </Box>
    </Container>
}

const mapStateToProps = (state) => {
    return {
        storySelector: storySelector(state.stories.public.data)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        newStory: story => dispatch(newStory(story))
    }
}

export default withConditionalLayout(connect(
    mapStateToProps,
    mapDispatchToProps
)(Story),
    { publicOptions: { disablePaper: true } });