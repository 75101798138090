import React from 'react';
import MaterialAvatar from '@material-ui/core/Avatar';
import { makeStyles, useTheme } from '@material-ui/core';
import { getInitials } from '../../helpers/string';

const useStyles = makeStyles(() => ({
    avatar: {
        fontSize: '1.125rem'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    }
}));

const Avatar = ({ src, name, style, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();

    const colors = [
        { background: theme.palette.primary.main, textColor: theme.palette.primary.contrastText },
        { background: theme.palette.secondary.main, textColor: theme.palette.secondary.contrastText },

        { background: "#018A8A", textColor: theme.palette.common.white },
        { background: "#014A4A", textColor: theme.palette.common.white },
        { background: "#009688", textColor: theme.palette.common.white },
        { background: "#95ED94", textColor: theme.palette.common.black },
        { background: "#6d4c41", textColor: theme.palette.common.white },
        { background: "#616161", textColor: theme.palette.common.white },
        { background: "#BFFCFB", textColor: theme.palette.common.black },
        { background: "#5F6A72", textColor: theme.palette.common.white },
        { background: "#474554", textColor: theme.palette.common.white },
        { background: "#554738", textColor: theme.palette.common.white },
        { background: "#715A4D", textColor: theme.palette.common.white },
    ];

    const initials = getInitials(name);

    const colorIndex = initials
        .split('')
        .reduce((prev, curr) => prev + curr.charCodeAt(0), 0) % colors.length;

    const color = colors[colorIndex];

    return (
        <MaterialAvatar
            className={classes.avatar}
            style={Object.assign({},
                src
                    ? { backgroundColor: 'white' }
                    : {
                        backgroundColor: color.background,
                        color: color.textColor,
                    },
                style)}
            {...rest}>
            {src
                ? <img
                    src={src}
                    className={classes.image}
                    alt={name}
                    title={name}
                />
                : initials}
        </MaterialAvatar>
    );
};

export default Avatar;
