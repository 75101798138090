import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import { Field } from 'redux-form';

const SwitchField = (props) => {
    return <Field
        component={SwitchInput}
        {...props}
    />
}

const SwitchInput = ({ input, label, meta: { touched, error } = {}, switchProps, formControlProps = {} }) => (
    <FormControl error={touched && error} component="fieldset" {...formControlProps}>
        <FormControlLabel
            control={<Switch
                checked={input.value ? true : false}
                onChange={input.onChange}
                {...switchProps}
            />}
            label={label}
        />
        {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
);

export default SwitchField;
export { SwitchInput };