import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getItem } from '../../../actions/inventory';
import CreativeListItem from './CreativeListItem';
import { useTranslation } from 'react-i18next';
import { InventoryItemClasses } from '../../../helpers/constants';
import { InventoryItemCreativeState } from '../../../helpers/constants'
import { lucitApi } from '../../../services/lucitApi';

const CreativesList = ({ item, aspect }) => {

    const [boardFormats, setBoardFormats] = useState([]);

    useEffect(() => {
        lucitApi.billboards.getDigitalBoardFormats().then((boardFormats) => {
            setBoardFormats(boardFormats);
        });
    }, []);

    const getFormatForRatio = (ratio) => {

        if (!boardFormats)
            return null;

        const formatKeys = Object.keys(boardFormats);

        const format = formatKeys.find((formatCode) => {
            return boardFormats[formatCode].ratios.min <= ratio && boardFormats[formatCode].ratios.max >= ratio;
        });

        return boardFormats[format];
    }

    const uniqueSizesFromBoards = item.digital_boards ? item.digital_boards.map(board => {
        return board.board_size_description
    }).filter((board, idx, self) => {
        return self.indexOf(board) === idx
    }) : []

    const sortedPhotos = item.photos ? item.photos.sort((a, b) => {
        const ratioA = parseFloat(a.options ? a.options.dimension_width : 0) / parseFloat(a.options ? a.options.dimension_height : 1);
        const ratioB = parseFloat(b.options ? b.options.dimension_width : 0) / parseFloat(b.options ? b.options.dimension_height : 1);

        return ratioA - ratioB;
    }) : []

    const itemIsDefaultCreative = () => {
        return item.item_class === InventoryItemClasses.defaultCreative.class
    }

    const itemIsNotAttachedToAnyBoards = () => {
        return !item.digital_boards || !item.digital_boards.length
    }

    const limitImagesToBoardSizes = () => {

        if (itemIsDefaultCreative() || itemIsNotAttachedToAnyBoards())
            return false;

        return true;
    }

    const photoSetsFound = [];
    const { t } = useTranslation();

    const filteredPhotos = sortedPhotos.filter((photo) => {

        const photoSizeDescription = photo.options.dimension_width + 'x' + photo.options.dimension_height

        if (photo.photo_source === 'upload' || photo.status === 0)
            return false

        if (limitImagesToBoardSizes() && (!uniqueSizesFromBoards.includes(photoSizeDescription)))
            return false

        if (photoSetsFound.includes(photo.photo_set))
            return false

        photoSetsFound.push(photo.photo_set)

        return true
    })

    return (
        <>
            {!filteredPhotos.length && item.creative_state !== InventoryItemCreativeState.building && <Box mb={2} mt={4}>
                <Alert severity="warning">
                    {t('No campaigns are selected or No creatives exist for this item')}
                </Alert>
            </Box>}
            {filteredPhotos.map((photo, idx) => {

                const photoRatio = photo.options.dimension_width && photo.options.dimension_height ?
                    photo.options.dimension_width / photo.options.dimension_height
                    : 0;

                const boardFormat = getFormatForRatio(photoRatio);

                return (
                    <CreativeListItem photo={photo} item={item} aspect={aspect} key={idx} boardFormat={boardFormat} />
                )
            })
            }
        </>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    CreativesList
);
