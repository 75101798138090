import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Chip, Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { StatsDelayedChip, StatsUnavailableChip } from '../../components';
import { exportDescription } from '../../selectors/exports';
import { selectedProfileIsAgency } from '../../selectors/user';
import { getUser } from '../../selectors/user';
import { setName } from '../../actions/exports';
import { ExportStatus, Global } from '../../helpers/constants';
import LCTypography from '../../components/material/LCTypography';
import { useTranslation } from 'react-i18next';
import { IonCardTitle, IonCardContent } from '@ionic/react';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { maxLength, required } from '../../helpers/validators';
import AccountAvatar from '../../containers/account/AccountAvatar';
import CampaignMoreButton from '../campaigns/CampaignMoreButton';
import CampaignOverview from '../campaigns/CampaignOverview';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain',
            maxHeight: 50
        },
        imageIcon: {
            width: '100%',
            height: '100%',
            maxHeight: 50
        },
        inactiveChip: {
            // color: theme.palette.text.secondary,
        },
        delayedChip: {
        },
        attribute: {
            display: 'flex',
            margin: 0,
            padding: 10,
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
        },
        campaignStatsSection: {
            display: 'flex',
        },
        campaignTitleSection: {
            display: 'flex',
            alignItems: 'center',
            padding: 10,
            wordBreak: 'break-word',
        },
        campaignTitle: {
            flex: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12pt',
            },
        },
        campaignButtonsGroup: {
            display: 'flex',
        },
    }
})

function ExportDetails(props) {
    const { item, selectedProfileIsAgency, setName } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const updateCampaignName = (exportId, exportName) => {
        setName(exportId, exportName);
    }

    return (
        <div>

            {selectedProfileIsAgency && item && item.account
                && <Box mt={2}>
                    <IonCardContent>
                        <IonCardTitle style={{ display: "flex" }}>
                            <Link
                                component={RouterLink}
                                to={`/` + item.account.slug}
                                color="textPrimary"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <AccountAvatar account={item.account} style={{ display: "flex", marginRight: "10px" }} />
                                {t(item.account.name)}
                            </Link>
                        </IonCardTitle>
                    </IonCardContent>
                </Box>}

            <Box className={classes.campaignTitleSection}>
                <IonCardTitle
                    className={classes.campaignTitle}
                    color={item.active
                        ? "textPrimary"
                        : "textSecondary"}
                >
                    <TextFieldEditable
                        classes={{
                            root: classes.inputRoot
                        }}
                        fullWidth={true}
                        circularProgressProps={{ size: 40 }}
                        size="small"
                        ViewComponent={
                            item.name}
                        label={"Campaign Name"}
                        variant={"outlined"}
                        value={item.name || ""}
                        validators={[required, maxLength(Global.titleMaxLength)]}
                        handleDone={name => updateCampaignName(item.id, name)}
                    />

                    <Box display="flex" style={{ gap: 8 }}>
                        {!item.active
                            && <Chip
                                size="small"
                                className={classes.inactiveChip}
                                label={t('Inactive')} />}

                        <StatsDelayedChip
                            exportObject={item}
                            chipProps={{
                                className: classes.delayedChip
                            }}
                        />
                        <StatsUnavailableChip
                            exportObject={item}
                            chipProps={{
                                className: classes.delayedChip
                            }} />
                    </Box>
                </IonCardTitle>

                <Box className={classes.campaignButtonsGroup}>
                    <CampaignMoreButton campaign={item} />
                </Box>
            </Box>
            <Box className={classes.campaignStatsSection}>
                <CampaignOverview campaign={item} />
            </Box>

            {exportDescription(item)
                && <Box mb={2} className={classes.attribute}>
                    <Alert severity='warning'>
                        <AlertTitle>Filter Applied</AlertTitle>
                        {item.last_run_fallback_mode
                            &&
                            (item.status === ExportStatus.empty ?
                                <LCTypography>{t('Last build produced zero creatives.  Running fallback creative')}</LCTypography>
                                :
                                <LCTypography>{exportDescription(item)}</LCTypography>
                            )
                        }
                        {!item.last_run_fallback_mode
                            && exportDescription(item)}
                    </Alert>
                </Box>}
        </div>
    );
}

ExportDetails.propTypes = {
    item: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
        selectedProfileIsAgency: selectedProfileIsAgency(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setName: (id, name) => dispatch(setName(id, name)),
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ExportDetails
    )
);
