import { Badge, Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Save from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    updateTemplate
} from '../../actions/designer';
import {
    dirty, driveTemplateLcuid, status
} from '../../selectors/designer';
import DesignerSettingsDialog from './DesignerSettingsDialog';
import AdvancedMenu from './DesignerToolbarAdvancedMenu';
import { useTranslation } from 'react-i18next';
import { Redo, Undo } from '@material-ui/icons';
import { DESIGNER_REDO, DESIGNER_UNDO } from '../../helpers/actionTypes';
import { ToggleButton } from '@material-ui/lab';
import { setPreviewInventoryItem } from '../../actions/designer';
import TemplatePhotoStreamDialog from '../../containers/inventory/TemplatePhotoStreamDialog';
import { selectedProfileIsAccount } from '../../selectors/user';

const DesignerToolbar = ({ dirty, updateTemplate, driveTemplateLcuid, setPreviewInventoryItem, selectedProfileIsAccount }) => {

    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const historyLength = useSelector(state => state.designer.history.length);
    const historyPointer = useSelector(state => state.designer.historyPointer);
    const [showPostDialog, setShowPostDalog] = useState(false)

    const saving = useSelector(state => state.designer.saving)

    return (
        <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" width="100%">

                <Button
                    style={{ marginLeft: "auto", marginRight: 16 }}
                    onClick={() => {
                        if (dirty) {
                            alert("Please save your template before posting an ad.")
                            return;
                        }

                        if (!selectedProfileIsAccount) {
                            alert("Please switch to an account profile before posting an ad.")
                            return;
                        }

                        setShowPostDalog(true)
                    }}
                    size="small"
                    variant="contained"
                    color="secondary"
                >
                    Post Ad
                </Button>

                {showPostDialog && <TemplatePhotoStreamDialog
                    open={showPostDialog}
                    selectedTemplateLcuid={driveTemplateLcuid}
                    handleClose={(newItem) => {
                        if (newItem) {
                            setPreviewInventoryItem(newItem)
                        }
                        setShowPostDalog(false)
                    }}
                    fromDesigner={true}
                />}

            </Box>
            <Box ml="auto" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Box display="flex" alignItems="center" mr={2}>
                    <Box mr={1}>
                        <Tooltip title={t('Undo last change')}>
                            <Badge badgeContent={historyPointer}
                                max={99}
                                color="secondary">
                                <ToggleButton
                                    style={{ padding: 4 }}
                                    size="small"
                                    value=""
                                    disabled={historyPointer === 0}
                                    onChange={() => dispatch({ type: DESIGNER_UNDO })}
                                >
                                    <Undo />
                                </ToggleButton>
                            </Badge>
                        </Tooltip>
                    </Box>
                    <Tooltip title={t('Redo last change')}>
                        <span>
                            <ToggleButton
                                style={{ padding: 4 }}
                                size="small"
                                value=""
                                disabled={historyPointer >= historyLength - 1}
                                onChange={() => dispatch({ type: DESIGNER_REDO })}
                            >
                                <Redo />
                            </ToggleButton>
                        </span>
                    </Tooltip>
                </Box>
                <Tooltip
                    title={saving ? t('Saving Changes') : (dirty ? t('Save Changes') : t('All Changes Saved'))}
                >
                    <Box style={{ width: "40px" }}>
                        <ToggleButton
                            style={{ padding: 4 }}
                            size="small"
                            value=""
                            disabled={saving}
                            color={dirty ? "secondary" : "grey"}
                            variant="contained"
                            onClick={updateTemplate}>
                            {saving
                                ? <CircularProgress size={24} />
                                : <Save size="small" />}
                        </ToggleButton>
                    </Box>
                </Tooltip>

                <Tooltip title={t('Edit Settings')}>
                    <Button
                        style={{ marginLeft: 8, marginRight: 8 }}
                        size="small"
                        variant="contained"
                        color={"primary"}
                        onClick={() => setSettingsDialogOpen(!settingsDialogOpen)}
                        startIcon={<SettingsIcon />}>
                        {t('Settings')}
                    </Button>
                </Tooltip>

                <AdvancedMenu />
            </Box>

            <DesignerSettingsDialog
                open={settingsDialogOpen}
                handleClose={() => setSettingsDialogOpen(false)}
            />
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        driveTemplateLcuid: driveTemplateLcuid(state),
        selectedProfileIsAccount: selectedProfileIsAccount(state),
        dirty: dirty(state),
        status: status(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateTemplate: () => dispatch(updateTemplate()),
        setPreviewInventoryItem: (item) => dispatch(setPreviewInventoryItem(item))
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerToolbar
    );
