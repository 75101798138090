import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, IconButton, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import LCTypography from '../../components/material/LCTypography';
import { CopyButton } from '../../components/';

export const Apparatix = ({ exportItem, contractPrefix, addApxContract, deleteApxContract, apxSystemName, apiToken }) => {
    const [exportItemContracts, setExportItemContracts] = useState([])
    const [inputHasError, setInputHasError] = useState(false)

    useEffect(() => {
        setExportItemContracts(exportItem.apx_contracts ?? []);
    }, [exportItem, contractPrefix])

    const [apxContractId, setApxContractId] = useState("")

    const doAddApxContract = (newApxContractId) => {

        if (!newApxContractId) {
            setInputHasError(true)
            return
        }

        setInputHasError(false)

        setExportItemContracts([...exportItemContracts, {
            "apx_contract_id": contractPrefix + '-' + newApxContractId
        }])

        addApxContract(contractPrefix + '-' + newApxContractId).then(() => setApxContractId(""))

    }

    const doRemoveApxContract = (removeApxContractId) => {

        setExportItemContracts(exportItemContracts.filter(el => el.apx_contract_id !== removeApxContractId))
        deleteApxContract(removeApxContractId)
    }

    const hasContracts = () => exportItemContracts.length > 0
    const { t } = useTranslation();

    const apxScheduleUrl = `https://pull.lucit.app/api/v1/campaigns/apx/v2/${exportItem.hash_id}` +
        `/pull/html?api_token=${apiToken}`

    return (
        <Box p={5}>

            <Box mb={2}>
                <LCTypography variant="body2">
                    There are 2 types of scheduling processes that you can use depending on
                    your current install.  Please consult with your {apxSystemName} representative to determine
                    which process is best for you.
                </LCTypography>
            </Box>

            <hr />
            <Box pt={8} mb={4}>

                <Box pb={3}>
                    <Typography variant="h5">{apxSystemName} (Live Link)</Typography>
                </Box>

                <Typography variant="body2">
                    {t('To connect this Lucit campaign to your {{apxName}} installation, ', {
                        apxName: apxSystemName
                    })}
                    {t('create a new Live Link using the following URL:')}
                    <br />

                    {t(' For instructions on this process please visit : ')}
                    <> </>
                    {apxSystemName === "Apparatix" &&
                        <a target="_blank" rel="noreferrer"
                            href="https://www.lucit.cc/post/how-to-schedule-lucit-in-apparatix-with-live-link">
                            https://www.lucit.cc/post/how-to-schedule-lucit-in-apparatix-with-live-link</a>
                    }

                    {apxSystemName === "Formetco F360" &&
                        <a target="_blank" rel="noreferrer"
                            href="https://www.lucit.cc/post/how-to-schedule-lucit-in-formetco-f360">
                            https://www.lucit.cc/post/how-to-schedule-lucit-in-formetco-f360</a>
                    }

                    <Box mt={3} pt={1} mb={4}>

                        {t('Copy and paste the following URL into {{apxName}}. ', {
                            apxName: apxSystemName
                        })}
                    </Box>

                    <Box mt={3} pt={1} mb={6}>

                        <b>LIVE LINK URL</b>:

                        <Box ml={2} mb={3} mt={3}>

                            <CopyButton
                                size='small'
                                text={apxScheduleUrl}
                            />
                            <code style={{ marginLeft: "5px" }}>
                                {apxScheduleUrl}
                            </code>
                        </Box>
                    </Box>

                </Typography>

            </Box>

            <hr />

            <Box pt={12} mb={4}>

                <Box pb={3}>
                    <Typography variant="h5">{apxSystemName} (Legacy V1)</Typography>
                </Box>
                <Box mb={4}>
                    <Alert severity="warning">
                        {t('This method is deprecated and will no longer be supported in the future. ' +
                            'Contact {{apxName}} support to enable Live Link for your screens', {
                            apxName: apxSystemName
                        })}
                    </Alert>
                </Box>
                <Typography variant="body2">
                    {t('To connect this Lucit campaign to your {{apxName}} campaign, ', {
                        apxName: apxSystemName
                    })}
                    {t('simply add each of the "Contract" numbers below to link them together to this campaign.')}
                    <br />
                    {t(' For instructions on this process please visit : ')}
                    <> </>
                    {apxSystemName === "Apparatix" &&
                        <a target="_blank" rel="noreferrer"
                            href="https://www.lucit.cc/post/how-to-schedule-lucit-in-apparatix">
                            https://www.lucit.cc/post/how-to-schedule-lucit-in-apparatix</a>
                    }

                    {apxSystemName === "Formetco F360" &&
                        <a target="_blank" rel="noreferrer"
                            href="https://www.lucit.cc/post/how-to-schedule-lucit-in-formetco-f360">
                            https://www.lucit.cc/post/how-to-schedule-lucit-in-formetco-f360</a>
                    }

                </Typography>
                <Box p={2}>
                    <b>{t('{{apxName}} Contract Prefix : ', { apxName: apxSystemName })}</b>{contractPrefix ? contractPrefix :
                        <Alert severity="warning">
                            {t('There is no contract prefix enabled for this operator.  Please contact Lucit Support ')}
                            <code>support@lucit.cc</code>{t(' and mention error code ')}<code>MISSING_APX_PREFIX</code></Alert>
                    }
                </Box>

                {contractPrefix &&
                    <Box p={2}>
                        <Box pb={2}>
                            <strong>{t('{{apxName}} Contracts : ', { apxName: apxSystemName })}</strong>
                            {
                                !hasContracts() &&
                                <Alert severity="warning">
                                    {t('This campaign is NOT linked to any {{apxName}} Contracts.  ', {
                                        apxName: apxSystemName
                                    })}
                                    {t('It will not be able to serve creatives until you attach contract id\'s')}
                                </Alert>
                            }
                        </Box>

                        <Grid container spacing={3}>

                            {exportItemContracts.map((apxContract, idx) => {
                                return (
                                    <Grid key={idx} container style={{ borderBottom: "1px solid #888888" }}>
                                        <Grid item md={2} xs={12}>
                                            <Box pt={2} pl={3}>
                                                {apxContract.apx_contract_id}
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <Box pt={2} pl={3}>
                                                <strong>
                                                    Last Communication
                                                </strong> <br />
                                                {apxContract.last_pull_at
                                                    ? <DateTimeRelative date={toLocalDateTime(apxContract.last_pull_at)} />
                                                    : "--"
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <Box pt={2} pl={3}>
                                                <strong>
                                                    Created
                                                </strong> <br />
                                                <DateTimeRelative date={toLocalDateTime(apxContract.created_at)} />
                                            </Box>
                                        </Grid>
                                        <Grid item md={1} xs={12}>
                                            <IconButton
                                                onClick={() => doRemoveApxContract(apxContract.apx_contract_id)}>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })}

                        </Grid>

                        <Box mt={5} mb={3}>
                            {t('Attach a new contract to this campaign')}
                        </Box>

                        <Grid container>

                            <Grid item xs={10}>
                                <TextField
                                    required
                                    error={inputHasError}
                                    helperText={t('Required')}
                                    id="apx_contract_id"
                                    label={t('Contract Id')}
                                    value={apxContractId}
                                    onChange={
                                        (e) => setApxContractId(e.target.value.trim())
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Box pt={2}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        onClick={() =>
                                            doAddApxContract(apxContractId)
                                        }>
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                }
            </Box>
        </Box >
    )
}
