import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, TextField,
    Box,
    Typography,
    Divider,
    Chip
} from '@material-ui/core';
import { Dialog, ButtonLoader, CircularProgressCentered } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from "@material-ui/lab";
import LCTypography from '../../components/material/LCTypography';
import { AddCampaignsButton } from './AddCampaignsButton';
import { InventoryExportCampaignClasses } from '../../helpers/constants';
import { CampaignGroupIcon, ExportIcon, WeightsEditorBox } from '../../components';
import RowsAccordions from './RowsAccordions';
import { ScheduleBuilderDialog } from '../../containers/itemSchedule';
import { useExportSettings } from './useExportSettings';
import { selectAgencyExports, selectChildExports } from '../../selectors/lightning';
import { showSuccess } from '../../actions/snackbar';
import ItemScreenSelectDialog from '../../components/modals/ItemScreenSelectDialog';
import { IconPicker } from '../../components/IconPicker';
import { IconFromSet } from '../../components/IconFromSet';
import { StylePicker } from '../../components/StylePicker';

const useStyles = makeStyles(() => {
    return {
        content: {
            minHeight: 200
        }
    }
})

const CampaignGroupDialog = ({ group, open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isEdit = group != null;

    const agencyExports = useSelector(selectAgencyExports);
    const shadowAccount = useSelector(state => state.lightning.shadowAccount);
    const campaignsOptions = agencyExports.filter(
        x => x.campaign_class === InventoryExportCampaignClasses.lucitProgrammatic.class ||
            x.campaign_class === InventoryExportCampaignClasses.operatorContract.class ||
            x.campaign_class === InventoryExportCampaignClasses.group.class &&
            x.lcuid !== group?.lcuid &&
            // Campaign Group that doesn't belong to another CampaignGroup
            x.campaign_class === InventoryExportCampaignClasses.group.class &&
            x.parent_exports.every(y => y.campaign_class !== InventoryExportCampaignClasses.group.class)
    );

    const [error, setError] = useState(null);
    const [name, setName] = useState(group?.name ?? 'New Group');
    const [icon, setIcon] = useState(group?.options?.ui_options?.layout?.icon);
    const [styleName, setStyleName] = useState(group?.options?.ui_options?.layout?.styleName);
    const [campaigns, setCampaigns] = useState(useSelector(selectChildExports)(group));
    const [campaignEdit, setCampaignEdit] = useState(null);
    const [screenFilterEdit, setScreenFilterEdit] = useState(null);

    const getItemIdForScreenFilterEdit = (campaign) => {
        return campaign.options.connected_inventory_items_by_account[shadowAccount.lcuid] ?? null;
    }

    const { loading, saving,
        scheduleDictionary, setSchedule, itemBoardIdsDictionary,
        weights, setWeights, setBoardIds,
        handleSave } = useExportSettings(group, campaigns);

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            title={<>
                <IconPicker value={icon}
                    onChange={setIcon}
                    style={{ marginRight: 8 }}
                    FallbackIcon={CampaignGroupIcon}
                />
                {isEdit
                    ? group.name
                    : t('New Campaign Group')}
            </>}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                {!isEdit
                    && <Box mb={3}>
                        <LCTypography>
                            Create a new campaign group that you can use to organize your campaigns for scheduling
                        </LCTypography>
                    </Box>}

                <TextField
                    label={t('Group Name')}
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <Box mt={2}>
                    <Typography gutterBottom>{t('Preview')}:</Typography>
                    <StylePicker
                        value={styleName}
                        onChange={setStyleName}
                        renderPreview={styles => <Chip
                            style={styles}
                            icon={<IconFromSet
                                className="MuiChip-icon"
                                iconKey={icon}
                                style={{ color: styles?.color }}
                                FallbackIcon={CampaignGroupIcon} />}
                            label={name}
                        />}
                    />
                </Box>

                <Box mt={2} />
                {campaigns.length === 0
                    && <Typography>
                        {t('Please add at least one campaign')}
                    </Typography>}

                <Box mt={1} />
                <AddCampaignsButton
                    campaignsOptions={campaignsOptions}
                    campaigns={campaigns}
                    onChange={setCampaigns}
                />

                <Box mt={1} />
                {loading
                    && <CircularProgressCentered size={40} />}

                {campaigns.length > 0
                    && !loading
                    && <RowsAccordions
                        rows={campaigns.map(e => ({
                            ...e,
                            description: e.campaign_class !== InventoryExportCampaignClasses.group.class
                                && e.account.name,
                            Icon: e.campaign_class === InventoryExportCampaignClasses.group.class
                                ? <IconFromSet
                                    iconKey={e?.options?.ui_options?.layout?.icon}
                                    FallbackIcon={CampaignGroupIcon} />
                                : <ExportIcon />
                        }))}
                        onDelete={s => setCampaigns(campaigns.filter(x => x.lcuid !== s.lcuid))}
                        onScheduleClick={g => setCampaignEdit(g)}
                        hasSchedule={g => scheduleDictionary[g.lcuid]?.schedules?.length > 0}
                        onBoardClick={(g) => setScreenFilterEdit(g)}
                        hasBoardIds={g => itemBoardIdsDictionary[g.lcuid]?.board_ids?.length > 0}
                    />}

                {campaigns.length > 1
                    && !loading
                    && <>
                        <Divider />
                        <Box mt={2} />
                        <WeightsEditorBox weights={weights} setWeights={setWeights} />
                    </>}

                {campaignEdit
                    && <ScheduleBuilderDialog
                        title={campaignEdit.name}
                        open={true}
                        handleClose={() => setCampaignEdit(null)}
                        schedule={scheduleDictionary[campaignEdit.lcuid].schedules}
                        setSchedule={newSchedule => setSchedule(campaignEdit, newSchedule)}
                        buttonTitle={t("OK")}
                        buttonProps={{ color: 'primary' }}
                    />}

                {screenFilterEdit
                    &&
                    <ItemScreenSelectDialog
                        open={true}
                        handleClose={() => setScreenFilterEdit(null)}
                        itemId={getItemIdForScreenFilterEdit(itemBoardIdsDictionary[screenFilterEdit.lcuid].campaign)}
                        exportId={group.lcuid}
                        itemBoardIds={itemBoardIdsDictionary[screenFilterEdit.lcuid].board_ids}
                        onSave={(boardIds) => setBoardIds(screenFilterEdit, boardIds)}
                        lazy
                    />
                }
            </DialogContent>

            <DialogActions>
                {error && <Alert severity="error">{
                    error.response?.data?.message || error.message}
                </Alert>}

                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    disabled={!name || loading}
                    onClick={() => handleSave(group, { name, ui_options: { icon, styleName } })
                        .then(group => {
                            if (isEdit) {
                                dispatch(showSuccess(`${group.name} campaign group was successfully updated`))
                            } else {
                                dispatch(showSuccess(`${group.name} campaign group was created`))
                            }
                            handleClose();
                        })
                        .catch(error => {
                            setError(error);
                            return Promise.reject(error)
                        })}
                    color="primary"
                    variant="contained">
                    {isEdit
                        ? t('Save')
                        : t('Create')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

export default CampaignGroupDialog;
