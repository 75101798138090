import { Box, Chip, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';

const ChipList = ({ items, itemsToAdd, onDelete, onAdd }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const canAddItems = Boolean(itemsToAdd) && Boolean(itemsToAdd.length) && onAdd;

    return (
        <Box style={{ marginLeft: -4 }}>
            {items.map(i => <Chip
                key={i.id}
                style={{ margin: 4 }}
                color='primary'
                label={i.label}
                onDelete={onDelete
                    ? () => onDelete(i)
                    : null}
            />)}

            {canAddItems
                && <>
                    <Chip
                        style={{ margin: 4 }}
                        icon={<Add />}
                        label={"Add more"}
                        variant='outlined'
                        color="primary"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    />
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                        onClose={() => setAnchorEl(null)}
                        onBackdropClick={() => setAnchorEl(null)}
                    >
                        <List>
                            {itemsToAdd.map(i => <ListItem key={i.id}
                                button
                                onClick={() => {
                                    setAnchorEl(null);
                                    onAdd(i);
                                }}
                            >
                                <ListItemText
                                    primary={i.label}
                                />
                            </ListItem>)}
                        </List>
                    </Popover>
                </>}
        </Box>
    );
};

export default ChipList;
