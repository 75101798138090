import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreview, templateAssets } from '../../selectors/designer';
import { getInjectableHtml } from './DesignerHTML';
import { useMouseWheel, useNonInitialEffect } from '../../helpers/hooks';
import { Designer } from '../../helpers/constants';
import { useOutsideClick } from '../../helpers/hooks/useOutsideClick';
import { DESIGNER_SET_ZOOM } from '../../helpers/actionTypes';

/**
 *  Some notes on this component:
 *    When the component first renders, it sets the html to initialHtml.
 * ALL Subsequent changes to html or css, are injected to the iframe with the postMessage api
 * The reason for this is to prevent re-rendering of the entire iframe, and the blinking effect that goes along with it
 * See https://joyofcode.xyz/avoid-flashing-iframe for the inspiration behind this change
 *
 */
const DesignerPreviewFrame = ({ boardFormatCode, initialElementSelected,
    aspectRatio, frameIsSelected, html, css, disabled }) => {
    const preview = useSelector(getPreview);
    const assets = useSelector(templateAssets);
    const accountKeyValueStore = useSelector(state => state.designer.accountKeyValueStore)

    const appVersion = useSelector(state => state.environment.version)

    const [initialHtml, setInitialHtml] = useState(getInjectableHtml(
        preview.html,
        preview.css,
        preview.js,
        assets,
        appVersion
    ))

    const iframeRef = useMemo(() => React.createRef(), [boardFormatCode])
    const outsideClickRef = useOutsideClick(() => {
        if (frameIsSelected) {
            iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
                type: Designer.Messages.NotifyClickedOff,
                value: boardFormatCode
            }, "*")
        }
    })

    //Reload frame if keyvaluestore data changes
    useEffect(() => {
        setInitialHtml(getInjectableHtml(
            preview.html,
            preview.css,
            preview.js,
            assets,
            appVersion
        ))
    }, [accountKeyValueStore])

    // Reload frame when JS changes
    useNonInitialEffect(() => {
        setInitialHtml(getInjectableHtml(
            preview.html,
            preview.css,
            preview.js,
            assets,
            appVersion
        ))
    }, [preview.js])

    useEffect(() => {
        //As soon as the iframe is ready, run the formatting functions
        if (iframeRef.current) {
            iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
                type: Designer.Messages.RunFormattingFunctions
            }, "*")
        }
    }, [iframeRef])

    useEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.ElementSelected,
            value: initialElementSelected
        }, "*")

    }, [initialElementSelected, boardFormatCode])

    useEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateContainerHtml,
            value: html
        }, "*")

        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateContainerCss,
            value: css
        }, "*")

        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.RunFormattingFunctions
        }, "*")

    }, [html])

    useEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateContainerCss,
            value: css
        }, "*")

        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateContainerHtml,
            value: html
        }, "*")

        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.RunFormattingFunctions
        }, "*")
    }, [css])

    const gridSize = useSelector(state => state.designer.gridSize)
    useNonInitialEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateGridSize,
            value: gridSize || 1
        }, "*")
    }, [gridSize])

    const gridShow = useSelector(state => state.designer.gridShow)
    useNonInitialEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateGridVisibility,
            value: Boolean(gridShow)
        }, "*")
    }, [gridShow])

    const borderAllElementsShow = useSelector(state => state.designer.borderAllElementsShow)
    useNonInitialEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateBorderAllElements,
            value: Boolean(borderAllElementsShow)
        }, "*")
    }, [borderAllElementsShow])

    const gridColor = useSelector(state => state.designer.gridColor)
    useNonInitialEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateGridColor,
            value: gridColor
        }, "*")
    }, [gridColor])

    const enableSnapping = useSelector(state => state.designer.enableSnapping)
    useNonInitialEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.UpdateSnapping,
            value: enableSnapping
        }, "*")
    }, [enableSnapping])

    const zoom = useSelector(state => state.designer.zoom);
    useEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow && iframeRef.current.contentWindow.postMessage({
            type: Designer.Messages.ZoomInOut,
            value: zoom
        }, "*")
    }, [zoom])

    const dispatch = useDispatch();
    useMouseWheel(useCallback((e) => {
        if (e.ctrlKey) {
            e.preventDefault();
            let newZoom = zoom;

            newZoom += e.deltaY * -0.001;
            newZoom = Math.min(Math.max(1, newZoom), 8);

            dispatch({ type: DESIGNER_SET_ZOOM, zoom: newZoom });
        }
    }, [zoom]));

    const isTall = aspectRatio < 2;

    return < div ref={outsideClickRef}
        style={{
            margin: 0,
            border: "none",
            display: "flex",
            width: '100%',
            height: '100%'
        }}>
        <iframe
            id={boardFormatCode}
            ref={iframeRef}
            style={{
                margin: isTall ? "0px auto" : "auto 0px",
                border: "none",
                display: "block",
                pointerEvents: disabled ? "none" : "auto",

                boxShadow: "0 2px 8px rgba(14,19,24,.07)",
                background: 'white',
                height: isTall ? "85%" : 'auto',
                width: isTall ? 'auto' : '100%',
                aspectRatio: aspectRatio.toString(),
            }}
            srcDoc={initialHtml}
        />
    </div>;
}

export default React.forwardRef((props, ref) => <DesignerPreviewFrame forwardedRef={ref} {...props} />);
