import React from 'react';
import InventoryIcon from '@material-ui/icons/Layers';
import VehicleIcon from '@material-ui/icons/DirectionsCar';
import HouseIcon from '@material-ui/icons/House';
import CreativeIcon from '@material-ui/icons/Collections';
import PhotoStreamIcon from '@material-ui/icons/PhotoCamera';
import AgentIcon from '@material-ui/icons/Person';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Apps, DirectionsBoat, RssFeed } from '@material-ui/icons';
import { InventoryItemClasses } from '../../helpers/constants';

const InventoryItemClassIcon = (props) => {

    const { itemClass, ...rest } = props;

    switch (itemClass) {
        case InventoryItemClasses.article.class:
            return <RssFeed {...rest} />;
        case InventoryItemClasses.review.class:
            return <StarHalfIcon {...rest} />;
        case InventoryItemClasses.social_post.class:
            return <FavoriteIcon  {...rest} />;
        case InventoryItemClasses.creative.class:
            return <CreativeIcon  {...rest} />;
        case InventoryItemClasses.real_estate.class:
            return <HouseIcon  {...rest} />;
        case InventoryItemClasses.recreational.class:
            return <DirectionsBoat  {...rest} />;
        case InventoryItemClasses.automotive.class:
            return <VehicleIcon {...rest} />;
        case InventoryItemClasses.photoStream.class:
            return <PhotoStreamIcon {...rest} />;
        case InventoryItemClasses.realEstateAgent.class:
            return <AgentIcon {...rest} />;
        case InventoryItemClasses.product.class:
            return <Apps  {...rest} />;

        default:
            return <InventoryIcon  {...rest} />;
    }
}

export default InventoryItemClassIcon;
