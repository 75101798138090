export const toDictionary = (items, keySelector, valueSelector) =>
    Object.assign({}, ...items.map((x) => ({
        [keySelector(x)]: valueSelector ? valueSelector(x) : x
    })));

export const groupByKey = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

export const groupBy = (items, groupBySelector) => items.reduce(
    (result, item) => ({
        ...result,
        [groupBySelector(item)]: [
            ...(result[groupBySelector(item)] || []),
            item,
        ],
    }),
    {},
);

export const areSame = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

export const max = (array, keySelector = x => x) => array.reduce(
    (prev, curr) => keySelector(curr) > prev ? keySelector(curr) : prev,
    0
)

export function flatDeepArray(array, getChildren) {
    return array.flatMap(x => flatDeep(x, getChildren));
}

export function flatDeep(item,
    getChildren = x => x.children,
    parent = null,
    parents = []) {
    const items = [{ item, parent, parents }];

    for (const child of (getChildren(item) || [])) {
        items.push(
            ...flatDeep(child,
                getChildren,
                item,
                [...parents, item])
        );
    }

    return items;
}
