import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, DialogContent, DialogActions, Button, useMediaQuery, useTheme
} from '@material-ui/core';
import { Dialog } from '../../components/material';
import { proofBoardContexts } from '../../selectors/proofs';
import { useMemo } from 'react';
import ProofBoard from './ProofBoard';

const useStyles = makeStyles(() => {
    return {
        content: {
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 180px)',
            padding: 0,
            '& .react-transform-wrapper, .react-transform-component, .react-transform-element': {
                width: '100%',
                height: '100%'
            },
        },
        previewXRContent: {
            padding: 0,
            '&:first-child': {
                padding: 0,
            },
        }
    }
})

const ProofBoardDialog = (props) => {
    const { open, handleClose, proof } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const boardContexts = useMemo(() => proofBoardContexts(proof), [proof]);

    const [index, setIndex] = useState(0)

    useEffect(() => setIndex(props.indexDefault || 0), [props.indexDefault])

    if (boardContexts.length === 0) {
        return null;
    }

    const { board, creative, creatives } = boardContexts[index];

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="lg"

            open={open}
            onClose={handleClose}
            className={classes.proofXRModal}
            classes={{
                paper: classes.paper,
                closeButton: classes.white
            }}
        >
            <DialogContent className={classes.previewXRContent}>
                {board
                    && <ProofBoard board={board} agency={proof.agency} creative={creative} creatives={creatives} />}
            </DialogContent>
            <DialogActions>
                {boardContexts.length > 1
                    && <>
                        <Button className={classes.white}
                            disabled={index === 0}
                            onClick={() => setIndex(x => x - 1)}>
                            Previous
                        </Button>
                        <Button className={classes.white}
                            disabled={index === boardContexts.length - 1}
                            onClick={() => setIndex(x => x + 1)}>
                            Next
                        </Button>
                    </>}
                <Button onClick={handleClose} className={classes.white}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ProofBoardDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,

    proof: PropTypes.object.isRequired,

    indexDefault: PropTypes.number
}

export default ProofBoardDialog;
