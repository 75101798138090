import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { AccordionDetails, AccordionSummary, Box, Button, Divider, Link, Switch, ButtonGroup, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {
    addInventoryItemClass, changeAccountDetails, deletePrimaryImage, removeInventoryItemClass, setPrimaryImage
} from '../../actions/account';
import { showError, showSuccess } from '../../actions/snackbar';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import ButtonLoader from '../../components/material/ButtonLoader';
import LCTypography from '../../components/material/LCTypography';
import ImageUploadDialog from '../../components/modals/ImageUploadDialog';
import { Global, RoutePaths } from '../../helpers/constants';
import { required } from '../../helpers/validators';
import i18next from '../../i18n';
import { hasInventoryItemClass } from '../../selectors/account';
import { getUser, selectedAccount } from '../../selectors/user';
import { lucitApi } from '../../services/lucitApi';
import { AccountIcon, AvatarEditable, ChipList } from '../../components';
import { selectUser } from '../../actions/user';
import { SettingsSection, SettingsSectionRow } from '../settings';
import { Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { trash } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import AccountDeleteButton from '../../containers/account/AccountDeleteButton';
import EditDefaultCreativeButton from '../../containers/account/EditDefaultCreativeButton';
import ReactMarkdown from 'react-markdown'
import { ACCOUNT_UPDATE_IS_PARENT, ACCOUNT_UPDATE_IS_PUBLIC } from '../../helpers/actionTypes';
import { AccountPublicOptionsDialog } from './AccountPublicOptionsDialog';

const useStyles = makeStyles(() => {
    return {
        summaryContent: {
            justifyContent: 'center',
            flexDirection: 'column'
        },
        content: {
            flexDirection: 'column'
        },
        formField: {
            width: '100%',
            maxWidth: 600
        },
        headerIcon: {
            marginBottom: -3
        },
        avatarContainer: {
            width: '100px !important',
            height: '100px !important',
        },
    }
});

const AdvancedSettings = ({ account }) => {

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <ButtonGroup size='small'>
            <Tooltip title={t('Advanced Settings / Options')}>
                <Button
                    size="small"
                    onClick={handleClick}
                >
                    <MoreVert />
                </Button>
            </Tooltip>
        </ButtonGroup>

        <Menu
            id="advanced-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            <AccountDeleteButton
                account={account}
                renderButton={props => <MenuItem
                    {...props}
                >
                    <ListItemIcon>
                        <IonIcon icon={trash} slot="start" />
                    </ListItemIcon>
                    <ListItemText primary="Delete Account" />
                </MenuItem>
                }
            />

        </Menu>

    </>

}

function AccountInfo(props) {
    const { selectedAccount, changeAccountDetails } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isUploadOpened, setIsUploadOpened] = useState(false);
    const [isDeletingImage, setIsDeletingImage] = useState(false);

    const [publicJoinOptionsOpen, setPublicJoinOptionsOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <AccordionScrollable expanded={true}>
            <AccordionSummary
                expandIcon={null}
                aria-controls="userinfo-content"
                id="userinfo-header"
                classes={{ content: classes.summaryContent }}
            >
                <LCTypography variant="h5" ><AccountBoxIcon className={classes.headerIcon} />

                    Account Settings

                    <Box style={{ float: "right" }} onClick={(e) => e.preventDefault()}>
                        <AdvancedSettings account={selectedAccount} />
                    </Box>

                </LCTypography>

            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>

                <SettingsSection title={t("Personal Settings")}>
                    <Box mt={2}>
                        <LCTypography variant="body1">
                            To edit Notifications, Personal Information and Security,
                            <> </>
                            <Link
                                variant='body1'
                                color="secondary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(selectUser({
                                    animate: true,
                                    onSwitched: () => history.push({ pathname: RoutePaths.settings })
                                }))}
                            >
                                edit your personal profile settings
                            </Link>
                        </LCTypography>
                    </Box>
                </SettingsSection>
                <SettingsSection title="Profile Photo">
                    <Box className={classes.formField} display="flex" alignItems="center">
                        <AvatarEditable
                            classes={{ root: classes.avatarContainer }}
                            fallbackIcon={<AccountIcon />}
                            src={selectedAccount.options.primary_image_public_url}
                            onClick={() => setIsUploadOpened(true)}
                        />
                        <Box ml="auto" mr={7}>
                            <ButtonLoader
                                submitting={isDeletingImage}
                                onClick={() => {
                                    setIsDeletingImage(true);
                                    props.handleImageDelete()
                                        .then(() => setIsDeletingImage(false));
                                }}
                                variant='text'
                                color="primary"
                                style={{ marginRight: 12 }}>
                                {t('Remove Photo')}
                            </ButtonLoader>
                            <Button
                                onClick={() => setIsUploadOpened(true)}
                                variant='contained'
                                color="primary">
                                {t('Change Photo')}
                            </Button>
                        </Box>
                    </Box>
                    {isUploadOpened
                        && <ImageUploadDialog
                            title={t('Edit Profile Image')}
                            src={selectedAccount.options.primary_image_public_url}
                            open={isUploadOpened}
                            autoUpload
                            aspect={Global.imageAspectAccountLogo}
                            askForCameraOnAndroid={true}
                            handleDelete={() => props.handleImageDelete()
                                .then(() => setIsUploadOpened(false))}
                            handleSave={image => props.handleImageUpload(image)
                                .then(() => setIsUploadOpened(false))}
                            handleClose={() => setIsUploadOpened(false)}
                        />}
                </SettingsSection>

                <SettingsSection title={t('Account Name')}>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        style={{ marginBottom: 16 }}
                        variant={"outlined"}
                        value={selectedAccount.name}
                        validators={[required]}
                        fullWidth={true}
                        id={"accountname"}
                        handleDone={(value) => changeAccountDetails(
                            value,
                            selectedAccount.description,
                            selectedAccount.website,
                            selectedAccount.options.new_creatives_and_posts_always_in_draft_state,
                            selectedAccount.options.remove_backgrounds_from_all_primary_images
                        )}
                    />

                    <SettingsSectionRow
                        title={t('Parent Account')}
                        description={t('Parent accounts can have one or more child accounts attached to them.')}
                    >
                        <Switch
                            checked={Boolean(selectedAccount.is_parent_account)}
                            onChange={(e) => {
                                const isParentAccount = e.target.checked;
                                lucitApi.accounts.setIsParent(selectedAccount, isParentAccount)
                                    .then(() => {
                                        dispatch({
                                            type: ACCOUNT_UPDATE_IS_PARENT,
                                            lcuid: selectedAccount.lcuid,
                                            is_parent_account: isParentAccount
                                        })
                                        dispatch(showSuccess(t('Changes were saved')));
                                    })
                                    .catch(() => dispatch(showError(t('Error when saving changes, please try again later'))))
                            }}
                        />
                    </SettingsSectionRow>

                    <SettingsSectionRow
                        title={t('Community Account')}
                        description={<>
                            {t('Allow anyone to join and create a child account to which they can post creatives for your approval.')}
                            {selectedAccount.options.public_can_join
                                && <Box mt={1}>
                                    <Button
                                        onClick={() => setPublicJoinOptionsOpen(true)}
                                        variant="outlined">{t('Edit Options')}</Button>
                                </Box>}
                        </>}
                    >
                        <Switch
                            checked={Boolean(selectedAccount.options.public_can_join)}
                            onChange={(e) => e.target.checked
                                ? setPublicJoinOptionsOpen(true)
                                : lucitApi.accounts.setPublicCanJoin(selectedAccount, null)
                                    .then(() => {
                                        dispatch({
                                            type: ACCOUNT_UPDATE_IS_PUBLIC,
                                            lcuid: selectedAccount.lcuid,
                                            public_can_join: false
                                        })
                                        dispatch(showSuccess(t('Changes were saved')));
                                    })
                                    .catch(() => dispatch(showError(t('Error when saving changes, please try again later'))))
                            }
                        />
                        {publicJoinOptionsOpen
                            && <AccountPublicOptionsDialog
                                account={selectedAccount}
                                open={true}
                                handleClose={() => setPublicJoinOptionsOpen(false)}
                            />}
                    </SettingsSectionRow>
                </SettingsSection>

                <SettingsSection
                    title={t('Public Description')}>
                    <LCTypography style={{ marginBottom: 8 }}>This description will appear in your public page</LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        multiline
                        variant={"outlined"}
                        value={selectedAccount.description || ''}
                        validators={[required]}
                        fullWidth={true}
                        id={"description"}
                        ViewComponent={<Box
                            style={{
                                maxWidth: "90%",//hackish
                                position: "relative",
                                flexDirection: "column",
                                width: "100%",
                                display: "inline-flex",
                                borderRadius: "4px",
                                minHeight: 56,
                                padding: 8,
                                border: "1px solid rgba(0, 0, 0, 0.20)"
                            }}
                        >
                            <Box style={{ display: "block" }}>
                                <ReactMarkdown>{selectedAccount.description}</ReactMarkdown>
                            </Box>
                        </Box>
                        }
                        handleDone={(value) => changeAccountDetails(
                            selectedAccount.name,
                            value,
                            selectedAccount.website,
                            selectedAccount.options.new_creatives_and_posts_always_in_draft_state,
                            selectedAccount.options.remove_backgrounds_from_all_primary_images
                        )}
                    />
                    <Box mt={1}>
                        <Link
                            variant='body1'
                            component={RouterLink}
                            color="secondary"
                            to={RoutePaths.public.slug(selectedAccount.slug)}>
                            {t('View your Public Page')}
                        </Link>
                    </Box>
                </SettingsSection>

                <SettingsSection
                    title={t('Website')}>
                    <LCTypography style={{ marginBottom: 8 }}>This website link will be added to your public page</LCTypography>
                    <TextFieldEditable
                        classes={{
                            root: classes.formField
                        }}
                        variant={"outlined"}
                        value={selectedAccount.website || ''}
                        fullWidth={true}
                        id={"website"}
                        handleDone={(value) => changeAccountDetails(
                            selectedAccount.name,
                            selectedAccount.description,
                            value,
                            selectedAccount.options.new_creatives_and_posts_always_in_draft_state,
                            selectedAccount.options.remove_backgrounds_from_all_primary_images
                        )
                        }
                    />
                </SettingsSection>

                <SettingsSection title={t('Creatives')}>

                    <LCTypography style={{ marginBottom: 16 }}>Control account wide settings related to creatives</LCTypography>

                    <SettingsSectionRow
                        title={t('Default Creative')}
                        description={<>

                            {t('Set the image that will appear if all other creatives are deleted, disabled, '

                                + 'or unable to run due to limitations such as schedule, size, etc.')}

                            <>
                                <> </>Learn more at <Link
                                    variant='body2'
                                    color="secondary"
                                    href={'https://www.lucit.cc/post/what-is-a-default-creative'}>
                                    {t('What is a Default Creative?')}
                                </Link>
                            </>

                        </>}>
                        <EditDefaultCreativeButton account={selectedAccount} />
                    </SettingsSectionRow>

                    <SettingsSectionRow
                        title={t('New Ads will start in Draft state')}
                        description={t('When you post a new ad it will be in draft state.')}
                    >
                        <Switch
                            defaultChecked={selectedAccount.options.new_creatives_and_posts_always_in_draft_state}
                            onChange={(e) => changeAccountDetails(
                                selectedAccount.name,
                                selectedAccount.description,
                                selectedAccount.website,
                                e.target.checked,
                                selectedAccount.options.remove_backgrounds_from_all_primary_images
                            )}
                        />
                    </SettingsSectionRow>
                </SettingsSection>

                <SettingsSection title={('Template Studio')}>
                    <SettingsSectionRow
                        title={t('Background Remover')}
                        description={t('Uploaded photos and photos that come in from data sources, ' +
                            ' will be processed by AI to create a copy of the photo without a background')}
                    >
                        <Switch
                            defaultChecked={selectedAccount.options.remove_backgrounds_from_all_primary_images}
                            onChange={(e) => changeAccountDetails(
                                selectedAccount.name,
                                selectedAccount.description,
                                selectedAccount.website,
                                selectedAccount.options.new_creatives_and_posts_always_in_draft_state,
                                e.target.checked,
                            )}
                        />
                    </SettingsSectionRow>
                </SettingsSection>

                <SettingsSection title={t('Apps and Data')} last>

                    <SettingsSectionRow
                        title={t('Manage')}
                        description={<Link
                            component={RouterLink}
                            style={{ cursor: 'pointer' }}
                            to={RoutePaths.appsAndData}
                            color="secondary"
                            onClick={e => e.stopPropagation()}
                        >
                            {t('Add Apps and Data Sources to your Account')}
                        </Link>}
                        inline={false}>

                    </SettingsSectionRow>

                    <SettingsSectionRow
                        title={t('Inventory Types')}
                        description={t('A list of the types of ads you will be able to create using our template designer.')}
                        inline={false}>

                        <InventoryTypes />

                    </SettingsSectionRow>
                </SettingsSection>

            </AccordionDetails>
        </AccordionScrollable>
    );
}

const InventoryTypes = () => {
    const account = useSelector(selectedAccount);
    const [itemClasses, setItemClasses] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        lucitApi.inventory.getInventoryItemClasses()
            .then((itemClasses) => setItemClasses(itemClasses.filter((itemClass) => itemClass.has_inventory)))
    }, []);

    const items = itemClasses.map(i => ({ ...i, id: i.class, label: i.class_description }))

    return <Box mt={2}><ChipList
        items={items.filter(i => hasInventoryItemClass(account, i.class))}
        itemsToAdd={items.filter(i => !hasInventoryItemClass(account, i.class))}

        onAdd={(item) => dispatch(addInventoryItemClass(item.class))}
        onDelete={(item) => dispatch(removeInventoryItemClass(item.class))}
    /></Box>
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAccount: selectedAccount(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {

        changeAccountDetails: (name, description, website, newCreativesAndPostsAlwaysInDraftState, removeBackgroundsFromAllPrimaryImages) => {
            return dispatch(changeAccountDetails(
                name, description, website, newCreativesAndPostsAlwaysInDraftState,
                removeBackgroundsFromAllPrimaryImages))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Details Updated')))
                });
        },

        handleImageUpload: (image) => {
            return dispatch(setPrimaryImage(image))
                .then(() => dispatch(showSuccess(i18next.t('Profile photo was updated successfuly'))))
                .catch(error => {
                    dispatch(showError(i18next.t('Failed to save profile photo')));

                    throw error;
                });
        },

        handleImageDelete: () => {
            return dispatch(deletePrimaryImage())
                .then(() => dispatch(showSuccess(i18next.t('Profile photo was removed'))))
                .catch(error => {
                    dispatch(showError(i18next.t('Failed to remove profile photo')));

                    throw error;
                });
        },
        showError: (error) => dispatch(showError(error))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountInfo);
