import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

const useStyles = makeStyles(theme => ({
    itemPreview: {
        marginRight: theme.spacing(2)
    }
}));

function CampaignBoardsItemSkeleton() {
    const classes = useStyles();

    return (
        <ListItem divider alignItems="flex-start" className={classes.itemSecondaryAction}>
            <ListItemAvatar>
                <Skeleton variant="circle" width={40} height={40} className={classes.itemPreview} />
            </ListItemAvatar>
            <ListItemText>
                <Skeleton variant="text" />
                <Skeleton variant="text" width="50%" />
                <Skeleton variant="text" width="25%" />
                <Skeleton variant="text" width="75%" />
            </ListItemText>
        </ListItem>
    );
}

export default CampaignBoardsItemSkeleton;
