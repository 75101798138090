import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, Grid, makeStyles, MenuItem, Typography, useTheme } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { getPlaysByBillboard } from '../../actions/analytics';
import { Global, RoutePaths } from '../../helpers/constants';
import { sortNumber, sortString } from '../../helpers/sorters';
import TextField from '../../components/inputs/TextField';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { DateRangeInput } from '../../components/inputs/DateRangeInput'

const useStyles = makeStyles(() => {
    return {
        itemLink: {
            height: '100%',
            textDecoration: 'none'
        }
    }
})

function PlaysStatsByBillboard(props) {
    const { timeframe, setTimeframe, timeframes, hideFilters, getPlaysByBillboard } = props;

    const theme = useTheme();
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    // https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
    const [columns, setColumns] = useState([]);
    const { t } = useTranslation();

    const [dateRange, setDateRange] = useState({
        "startDate": new Date(new Date().setDate(new Date().getDate() - 30)),    //30 days ago
        "endDate": new Date()
    })

    const getTimeframeOpts = () => {

        return {
            "start_date": dateRange.startDate,
            "end_date": dateRange.endDate
        }
    }

    const showDateSelector = () => timeframe === "custom"

    useEffect(() => {
        setColumns([
            {
                title: "Screen",
                filtering: false,
                customSort: (a, b) => sortString(a.object_name, b.object_name),
                // eslint-disable-next-line react/display-name
                render: x => <Link
                    component={RouterLink}
                    className={classes.avatarLink}
                    to={RoutePaths.screen(x.board_id)}>
                    <Typography variant="body2">{x.object_name}</Typography>
                </Link>,
            },
            {
                title: "Statistics",
                filtering: false,
                customSort: (a, b) => sortNumber(a.total_plays, b.total_plays),
                // eslint-disable-next-line react/display-name
                render: x => <>
                    {
                        x.total_impressions > 0 ?
                            <>
                                <LCTypography transProps={{ xTotalImpressions: x.total_impressions.toLocaleString() }} variant="body2">
                                    {{ xTotalImpressions: x.total_impressions.toLocaleString() }} Impressions
                                </LCTypography>
                                <Typography component="p" variant="caption" color="textSecondary">
                                    {t('with')}
                                    {moment.duration(x.total_play_duration, 'seconds').format("hh:mm:ss", { trim: false })}
                                    {t('Time On Screen')}
                                </Typography>
                                <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }}
                                    component="p" variant="caption" color="textSecondary">
                                    via {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                                </LCTypography>
                            </>
                            :
                            <LCTypography transProps={{ xTotalPlays: x.total_plays.toLocaleString() }} variant="body2">
                                {{ xTotalPlays: x.total_plays.toLocaleString() }} Plays
                            </LCTypography>

                    }
                </>
            },
        ])

    }, [classes])

    useEffect(() => {

        const controller = new AbortController();

        if (timeframe) {
            setLoading(true);
            getPlaysByBillboard(timeframe, getTimeframeOpts(), { signal: controller.signal })
                .then(data => setData(data))
                .finally(() => setLoading(false))
        }

        return () => controller.abort();

    }, [timeframe, getPlaysByBillboard, dateRange])

    return (
        <Box>
            {!hideFilters
                && <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('Timeframes')}
                                input={{
                                    onChange: (e) => setTimeframe(e.target.value),
                                    value: timeframe
                                }}
                                select={true}
                                fullWidth={true}
                                variant={"outlined"}
                            >
                                {timeframes.map(t => (
                                    <MenuItem key={t.timeframe} value={t.timeframe}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {showDateSelector() &&
                                <DateRangeInput

                                    meta={{}}
                                    input={{
                                        value: dateRange,
                                        onChange: (value) => setDateRange(value)
                                    }}
                                    fullWidth
                                    mobilePickerProps={{
                                        disablePast: true,
                                        autoOk: true,
                                        emptyLabel: 'No End Date',
                                        // eslint-disable-next-line react/display-name
                                    }}
                                    pickerProps={{
                                        showMonthAndYearPickers: false,
                                        showDateDisplay: false,
                                        months: 2,
                                        direction: "horizontal",
                                    }}
                                    format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                                        ? "MMM DD"
                                        : "MMM DD, YYYY"}
                                />
                            }

                        </Grid>
                    </Grid>
                    <Box mt={3} />
                </>}

            <MaterialTable
                columns={columns}
                isLoading={loading}
                data={data}
                components={{
                    OverlayLoading: props => (
                        <div>
                            <CircularProgressCentered {...props} />
                        </div>
                    )
                }}
                options={{
                    emptyRowsWhenPaging: true,
                    pageSize: Global.exportItemListSize,
                    pageSizeOptions: [5, Global.exportItemListSize, 50],
                    debounceInterval: 100,
                    headerStyle: { backgroundColor: theme.palette.grey[50] },
                    paging: false,
                    search: false,
                    showTitle: false,
                    sorting: true,
                    selection: false,
                    toolbar: false,
                    draggable: false,
                    tableLayout: 'auto'
                }}
            />
        </Box>
    );
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        getPlaysByBillboard: (timeframe, timeframe_opts) => dispatch(getPlaysByBillboard(timeframe, props.filters, timeframe_opts))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PlaysStatsByBillboard)
    );
