import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Paper, Button } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import SignalWifiOff from '@material-ui/icons/SignalWifiOff';
import { withRouter } from 'react-router-dom';

import { withHomeLayout } from '../layouts/HomeLayout';
import { getUser, selectedProfile } from '../selectors/user';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',

        padding: theme.spacing(3, 2, 3, 2)
    },
    warning: {
        color: theme.palette.warning.main,
        fontSize: '3rem'
    }
}));

const NoInternet = ({ profile, user, history }) => {
    const classes = useStyles();

    useEffect(() => {
        if (user.isOnline) {
            history.push({ pathname: profile.type.path });
        }
    }, [user.isOnline, history])

    return (
        <Paper className={classes.root}>
            <SignalWifiOff className={classes.warning} />
            <LCTypography variant="body1">No Connection</LCTypography>
            <LCTypography variant="body1">Some functionallity may be unavailable.</LCTypography>

            <Box mt={2} />
            <Button color="primary"
                variant="contained"
                onClick={() => history.push({ pathname: profile.type.path })}>Back to Home</Button>
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        profile: selectedProfile(state)
    }
}

export default connect(
    mapStateToProps
)(
    withRouter(
        withHomeLayout(
            NoInternet
        )
    )
);