import React, { useEffect } from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Box, Link, Tabs, Tab, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        root: {
            maxWidth: 400,
        },
        imgContainer: {
            position: 'relative',
            width: '100%',
            minHeight: 255,
            maxWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        img: {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        },
        stepper: {
            backgroundColor: theme.palette.background.paper,
        },
        tabLabel: {
            textTransform: 'none',
            width: '100%'
        }
    }
})

function PhotosGallery(props) {
    const { photos: photosOriginal, tabs = [], tabFilter } = props;
    const { Header } = props;

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [activeTab, setActiveTab] = useState(null);
    const [photos, setPhotos] = useState(photosOriginal);
    const { t } = useTranslation();

    const maxSteps = photos.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    // Reset activeStep if it's greater than total count of photos 
    // This is possible when removing photos
    useEffect(() => {
        if (activeStep >= photos.length) {
            setActiveStep(0);
        }
    }, [activeStep, photos.length])

    // Preselect first tab, first time
    useEffect(() => {
        if (!activeTab && tabs.length > 0) {
            setActiveTab(tabs[0])
        }
    }, [tabs, activeTab])

    useEffect(() => {
        if (activeTab && tabFilter) {
            setPhotos(tabFilter(photosOriginal, activeTab));
        }
    }, [activeTab, tabFilter, photosOriginal])

    return (
        <div className={classes.root}>
            {Header}
            {tabs.length > 0
                && activeTab
                && <>
                    <AppBar position="static" color="transparent">
                        <Tabs
                            value={activeTab}
                            onChange={(_, value) => {
                                setActiveTab(value);
                                setActiveStep(0);
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            variant={tabs.length > 2
                                ? "scrollable"
                                : "fullWidth"}
                        >
                            {tabs.map(tab => (
                                <Tab
                                    key={tab.title}
                                    label={<Typography className={classes.tabLabel} noWrap>
                                        {tab.title}
                                    </Typography>}
                                    value={tab} />
                            ))}
                        </Tabs>
                    </AppBar>
                    <Box mt={1} />
                </>}
            <SwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {photos
                    .map((photo, index) => (
                        <Box key={photo.id || index} height='100%'>
                            {Math.abs(activeStep - index) <= 2
                                && <>
                                    {!photo.Component
                                        && <Box className={clx(classes.imgContainer)}>
                                            {photo.link
                                                ? <Link
                                                    component={RouterLink}
                                                    className={classes.avatarLink}
                                                    to={RoutePaths.inventoryPhoto + `/${photo.id}`}>
                                                    <img className={classes.img}
                                                        src={photo.src}
                                                        title={photo.title}
                                                        alt={photo.title} />
                                                </Link>
                                                : <img
                                                    className={classes.img}
                                                    src={photo.src}
                                                    alt={photo.title}
                                                    title={photo.title}
                                                    onClick={photo.handleClick}
                                                />
                                            }
                                        </Box>}
                                    {photo.Component}
                                </>}
                        </Box>
                    ))}
            </SwipeableViews>
            {maxSteps > 1
                && <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    className={classes.stepper}
                    nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {t('Next')} <KeyboardArrowRight />
                    </Button>}
                    backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        {t('Back')}</Button>}
                />}
        </div>
    );
}

PhotosGallery.propTypes = {
    photos: PropTypes.array,

    tabs: PropTypes.array,
    tabFilter: PropTypes.func,
}

export default PhotosGallery;
