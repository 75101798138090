import {
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    makeStyles
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unassignApplication } from '../../actions/applications';
import { ConfirmationDialog } from '../../components';
import ApplicationAvatar from './ApplicationAvatar';
import AssignedAppInfoDialog from './AssignedAppInfoDialog';
import AssignedAppCapabilitiesActions from './capabilities/AssignedAppCapabilitiesActions';
import AssignedAppCapabilitiesPreview from './capabilities/AssignedAppCapabilitiesPreview';
import { Delete, Info } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { lucitApi } from '../../services/lucitApi';

const useStyles = makeStyles((theme) => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        },
        listItem: {
            flexDirection: 'column',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '8px 16px',
            marginBottom: 12,
            borderRadius: 8,
        }
    }
})

const AssignedAppRemoveButton = ({ application, removeApp }) => {

    const [dataFeeds, setDataFeeds] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    //WARNING:  This is SHIT code.  I am hard coding a check to see if
    //this application has any data feeds attached to it
    //If so, then we don't allow it's removal

    useEffect(() => {

        if (!application || !application.pivot)
            return;

        setLoading(true)

        lucitApi.feeds.getFeedsForAccountAndApplication(application.pivot.source_id, application.lcuid).then((feeds) => {
            setDataFeeds(feeds.data);
            setLoading(false);
        })
    }, [application])

    const hasDataFeeds = dataFeeds && dataFeeds.length > 0;

    const doRemove = () => {

        if (!hasDataFeeds) {
            removeApp(application)
            return;
        }

        alert(t('You cannot remove this application until you have deleted the data sources attached to this application.') +
            t(' \n\nClick on the SETTINGS for the data source to delete them first'));
    }

    return <Button
        onClick={() => doRemove()}
        disabled={loading}
        startIcon={<Delete />}
        style={{ marginLeft: 8 }}
        variant='contained'
        color="primary">
        {t('Remove')}
    </Button>

}

const AssignedAppsList = ({ lcuid, applications, newApp }) => {
    const [app, setApp] = useState(null);
    const [appToRemove, removeApp] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    return <>
        <List style={{ maxWidth: 1400 }}>
            {applications
                .map(a =>
                    <ListItem key={a.lcuid} className={classes.listItem} >
                        <Box display="flex" width="100%" alignItems="center">
                            <ListItemAvatar>
                                <ApplicationAvatar app={a} />
                            </ListItemAvatar>

                            <ListItemText
                                primary={a.name}
                                primaryTypographyProps={{ noWrap: true }}
                            //secondary={a.description}
                            />
                            <Box display="flex" flex="none">
                                <Button
                                    onClick={() => setApp(a)}
                                    startIcon={<Info />}
                                    variant='text'
                                    color="secondary">
                                    {t('Info')}
                                </Button>
                                <AssignedAppCapabilitiesActions
                                    application={a}
                                    autoInitDefaultAction={newApp && newApp.lcuid === a.lcuid} />

                                <AssignedAppRemoveButton
                                    application={a}
                                    removeApp={removeApp}
                                />
                            </Box>
                        </Box>

                        <AssignedAppCapabilitiesPreview application={a} />
                    </ListItem >
                )}
        </List>

        {app &&
            <AssignedAppInfoDialog
                app={app}
                open={Boolean(app)}
                handleClose={() => setApp(null)}
            />
        }

        <ConfirmationDialog
            title={t('Remove Application')}
            dialogProps={{
                open: Boolean(appToRemove),
                onClose: () => removeApp(null),
                keepMounted: false
            }}
            ConfirmButtonText={t('Remove')}
            ConfirmButtonProps={{
                variant: "contained",
                className: classes.removeButton
            }}
            handleCancel={() => removeApp(null)}
            handleConfirm={() => {
                dispatch(unassignApplication(lcuid, appToRemove))
                    .finally(() => removeApp(null))
            }}>
            <Typography>{t('Are you sure you want to remove this application from your account?')}</Typography>
        </ConfirmationDialog>
    </>
}

export default AssignedAppsList;
