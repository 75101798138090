import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../selectors/user';
import HomeLayout from "./HomeLayout";
import PublicLayout from "./PublicLayout";

function ConditionalLayout(props) {
    const { isLoggedIn, homeOptions, publicOptions } = props;

    if (isLoggedIn) {
        return <HomeLayout {...homeOptions}>
            {props.children}
        </HomeLayout>
    }

    return <PublicLayout {...publicOptions} >
        {props.children}
    </PublicLayout>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
}

const ConditionalLayoutConnected = withRouter(
    connect(
        mapStateToProps
    )(ConditionalLayout)
);

export default ConditionalLayoutConnected;

export function withConditionalLayout(WrappedComponent, { homeOptions, publicOptions } = {}) {
    class WithConditionalLayout extends React.Component {
        render() {
            return <ConditionalLayoutConnected {...{ homeOptions, publicOptions }}>
                <WrappedComponent {...this.props} />
            </ConditionalLayoutConnected>
        }
    }

    WithConditionalLayout.displayName = `WithConditionalLayout(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return WithConditionalLayout;
}
