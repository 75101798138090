import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import LinkDeviceDialog from './LinkDeviceDialog';
import { useSelector } from 'react-redux';

function LinkDeviceButton() {
    const [isOpen, setIsOpen] = useState(false);
    const devices = useSelector((state) => state.lightning.devices);
    const { t } = useTranslation();

    if (devices.length === 0) {
        return null;
    }

    return <>
        <Button
            variant="contained"
            color="primary"
            startIcon={<Link />}
            onClick={() => setIsOpen(true)}>
            {t('Link Device')}
        </Button>

        {isOpen
            && <LinkDeviceDialog
                open={true}
                handleClose={() => setIsOpen(false)} />}
    </>
}

export default LinkDeviceButton;
