import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DESIGNER_SET_ZOOM } from '../../helpers/actionTypes';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const DesignerZoomControl = () => {
    const zoom = useSelector(state => state.designer.zoom);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const onClose = () => {
        setAnchorEl(null);
    };

    return (<Box style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'white',
    }}>
        <Button
            size='small'
            onClick={(e) => setAnchorEl(e.currentTarget)}
        >
            <Typography variant="body2">{Math.round(zoom * 100)}%</Typography>
            {anchorEl ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            {options.map(x => <MenuItem
                key={x}
                selected={zoom == x}
                onClick={() => {
                    dispatch({ type: DESIGNER_SET_ZOOM, zoom: x })
                    setAnchorEl(null);
                }}
            >{x * 100}%</MenuItem>)}
        </Menu>
    </Box >)
}
const options = [
    0.25,
    0.3,
    0.4,
    0.5,
    0.75,
    0.9,
    1,
    1.25,
    1.5,
    1.75,
    2
];

export default DesignerZoomControl
