import React, { useContext, useEffect, useState } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRAMMATIC_FILTERS_CHANGE } from '../../helpers/actionTypes';
import { GoogleMapsContext } from '../../contexts';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { MapContext } from '@react-google-maps/api';
import { Search } from '@material-ui/icons';

const LocationFilter = ({ isBig }) => {
    const [placeId, setPlaceId] = useState(null);

    const gmContext = useContext(GoogleMapsContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const mapViewport = useSelector(state => state.programmatic.viewport);
    const geo = useSelector(state => state.programmatic.geo);

    useEffect(() => {
        if (placeId) {
            dispatch({
                type: PROGRAMMATIC_FILTERS_CHANGE,
                placeId
            });
        }
    }, [placeId]);

    if (!gmContext.loaded) {
        return null;
    }

    return <>
        <GooglePlacesAutocomplete
            apiKey={gmContext.key}
            autocompletionRequest={{
                //Bias the results to the map viewport
                //NOTE:  bounds is deprecated, see notes in T7367
                //locationBias is the new supported param
                bounds: mapViewport ? [
                    { lat: mapViewport.south, lng: mapViewport.west },
                    { lat: mapViewport.north, lng: mapViewport.east }
                ] : null
            }}
            selectProps={{
                components: { MultiValueLabel, Control, MultiValueContainer, IndicatorsContainer },
                autoFocus: true,
                openMenuOnFocus: false,
                openMenuOnClick: false,
                isClearable: false,
                escapeClearsValue: false,
                isMulti: true,
                value: [],
                onChange: (_, e) => {
                    if (e.action == "select-option") {
                        setPlaceId(e.option.value.place_id);
                    }
                    if (e.action == "remove-value") {
                        dispatch({
                            type: PROGRAMMATIC_FILTERS_CHANGE,
                            geo: { id: e.removedValue.value.place_id, remove: true }
                        })
                    }
                },
                placeholder: geo.length > 0
                    ? t("Add another location")
                    : t('Search for a location'),
                noOptionsMessage: e => {
                    return t(e.inputValue
                        ? `No places found for ${e.inputValue}`
                        : "Type to search");
                },
                styles: {
                    container: (baseStyles) => ({
                        ...baseStyles,
                        marginBottom: 8
                    }),
                    control: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%',
                        borderRadius: 4,
                        minHeight: 0,
                        height: isBig
                            ? 56
                            : 36,
                        fontSize: isBig ? 16 : 14,
                        zIndex: 11,
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: 'rgba(0, 0, 0, 0.38)',
                        fontWeight: 500,
                        letterSpacing: '0.02857em'
                    }),
                },
            }}
        />
    </>
};

const MultiValueContainer = () => {
    return null;
};

const MultiValueLabel = (props) => {
    const geo = useSelector(state => state.programmatic.geo)
        .find(x => x.id == props.data.value.place_id);

    const map = useContext(MapContext);

    return (
        <components.MultiValueLabel {...props}
            innerProps={{
                ...props.innerProps,
                style: { cursor: 'pointer' },
                onClick: () => {
                    map.fitBounds(geo.bounds);
                }
            }} />
    );
};

const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <Search style={{ marginLeft: 12 }} /> {children}
    </components.Control>
);

const IndicatorsContainer = () => {
    return null;
};

export default LocationFilter;
