import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { IonImg } from '@ionic/react';

const AgencyTeamDriveTemplateCreatedStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    const storyData = story.story_data || {};

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} create a new template!`}
        story={story}
        Media={<><Link
            component={RouterLink}
            color="secondary"
            to={`/designer/` + storyData.lcuid}>
            {storyData.image
                ? <IonImg src={storyData.image} />
                : null
            }
        </Link>
        </>}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Created a new template <> </>
            <Link
                component={RouterLink}
                color="secondary"
                to={`/designer/` + storyData.lcuid}>{storyData.name}</Link> for
            <Link
                component={RouterLink}
                color="secondary"
                to={`/` + storyData.parent_slug}> {storyData.parent_name}</Link>
        </LCTypography>
    </BaseStory >;
};

export default AgencyTeamDriveTemplateCreatedStory;
