import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setDesignerPreviewAspectRatio, setSelectedFrame, setSizeVariant, setSelectedEditor } from '../../actions/designer';

import {
    allowEdit, draggingIsHappening, driveTemplateLcuid, editModeGlobal, editorSelectedId, frameIdSelected,
    getPreview, getSizeVariantCSSForBoardFormat, hasBoardFormat, templateAssets
} from '../../selectors/designer';
import DesignerPreviewFrame from './DesignerPreviewFrame';
import DesignerZoomControl from './DesignerZoomControl';

const DesignerPreviewTemplate = ({
    boardFormat, driveTemplateLcuid, templateAssets, editModeGlobal, aspectRatio,
    setSelectedFrame, getSizeVariantCSSForBoardFormat, draggingIsHappening,
    setSizeVariant, initialElementSelected, frameIdSelected, getPreview, allowEdit, bestAgencyId, setDesignerPreviewAspectRatio
}) => {
    const preview = getPreview();

    if (boardFormat == null) {
        return null;
    }

    const ratio = boardFormat.size.width / boardFormat.size.height;

    useEffect(() => {

        if (ratio) {
            setDesignerPreviewAspectRatio(ratio);
        }

    }, [boardFormat])

    return <DesignerPreview
        setSelectedFrame={setSelectedFrame}
        editModeGlobal={editModeGlobal}
        bestAgencyId={bestAgencyId}
        boardFormat={boardFormat}
        boardFormatCode={boardFormat.code}
        lcuid={driveTemplateLcuid}
        templateAssets={templateAssets}
        aspectRatio={aspectRatio}
        html={preview.html}
        css={preview.css}
        js={preview.js}
        customCSS={getSizeVariantCSSForBoardFormat(boardFormat.code)}
        onCustomCSSChange={(css) => setSizeVariant(boardFormat.code, css)}
        initialElementSelected={initialElementSelected}
        frameIsSelected={frameIdSelected === boardFormat.code}
        disabled={!allowEdit || draggingIsHappening}
    />
}

const mapStateToProps = state => {
    return {
        hasBoardFormat: (boardFormatCode) => hasBoardFormat(state, boardFormatCode),
        driveTemplateLcuid: driveTemplateLcuid(state),
        getSizeVariantCSSForBoardFormat: (boardFormat) => getSizeVariantCSSForBoardFormat(state, boardFormat),
        getPreview: () => getPreview(state),
        initialElementSelected: editorSelectedId(state),
        frameIdSelected: frameIdSelected(state),
        editModeGlobal: editModeGlobal(state),
        templateAssets: templateAssets(state),
        allowEdit: allowEdit(state),
        draggingIsHappening: draggingIsHappening(state),
        aspectRatio: state.designer.previewAspectRatio
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setSizeVariant: (boardFormat, css) => dispatch(setSizeVariant(boardFormat, css)),
        setSelectedFrame: (frameId) => dispatch(setSelectedFrame(frameId)),
        setDesignerPreviewAspectRatio: (aspectRatio) => dispatch(setDesignerPreviewAspectRatio(aspectRatio))
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerPreviewTemplate
    );

export const DesignerPreview = ({ aspectRatio, html, css, js, templateAssets,
    boardFormatCode, customCSS, postMode, initialElementSelected, frameIsSelected,
    setSelectedFrame, disabled, maxHeightTall = "600px"
}) => {

    const previewHtml = html;
    const previewCss = templateAssets.css + css + customCSS;

    const showZoom = !postMode && !disabled;

    return (
        <Box position="relative">
            <Box>
                <Grid container style={{
                    backgroundColor: "#ebecf0",
                    borderRadius: "15px",
                }}>

                    <DesignerPreviewIframeContainer
                        setSelectedFrame={setSelectedFrame}
                        boardFormatCode={boardFormatCode}
                        initialElementSelected={initialElementSelected}
                        frameIsSelected={frameIsSelected}
                        aspectRatio={aspectRatio}
                        maxHeightTall={maxHeightTall}
                        html={previewHtml}
                        css={previewCss}
                        js={js}
                        disabled={disabled}
                        postMode={postMode}
                    />
                </Grid>
            </Box>

            {showZoom && <DesignerZoomControl />}
        </Box>
    )
}

const DesignerPreviewIframeContainer = ({ boardFormatCode, html, css, js, postMode,
    initialElementSelected, frameIsSelected, aspectRatio, setSelectedFrame, disabled }) => {
    const dispatch = useDispatch();

    const zoom = useSelector(state => state.designer.zoom);
    const isTall = aspectRatio < 1.8;

    return (
        <div style={{
            width: "100%",
            display: isTall ? "flex" : "block",
            margin: "16px",
            height: postMode ? `auto` : `calc(100vh - 250px)`,
            overflow: 'auto'
        }}>
            <div
                style={{
                    display: "block",
                    boxSizing: "content-box",
                    width: `calc(100% * ${zoom})`,
                    height: `calc(100% * ${zoom})`,
                }}
                onClick={() => dispatch(setSelectedEditor(null))}
            >
                <DesignerPreviewFrame
                    setSelectedFrame={setSelectedFrame}
                    aspectRatio={aspectRatio}
                    boardFormatCode={boardFormatCode}
                    frameIsSelected={frameIsSelected}
                    initialElementSelected={initialElementSelected}
                    html={html}
                    css={css}
                    js={js}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
