import { SNACKBAR_SHOW, SNACKBAR_HIDE, SNACKBAR_UPDATE_ACTIVE, SNACKBAR_SET_ACTIVE } from "../helpers/actionTypes";

export function showSuccess(message, options) {
    return showSnackbar('success', message, options);
}

export function showInfo(message, options) {
    return showSnackbar('info', message, options);
}

export function showWarning(message, options) {
    return showSnackbar('warning', message, options);
}

export function showError(message, options) {
    return showSnackbar('error', message, options);
}

export function showSnackbar(severity, message, options = {}) {
    const { duration, ...other } = options;

    return {
        type: SNACKBAR_SHOW,
        severity: severity,
        message,
        duration: duration || 6000,
        ...other
    }
}

export function hideSnackbar(message) {
    return {
        type: SNACKBAR_HIDE,
        message
    }
}

export function setActiveMessage(message) {
    return {
        type: SNACKBAR_SET_ACTIVE,
        message
    }
}

export function updateActiveMessage(data) {
    return {
        type: SNACKBAR_UPDATE_ACTIVE,
        ...data
    }
}
