import React, { useState } from 'react';
import { Fab, makeStyles, Zoom, useTheme } from '@material-ui/core';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ScrollContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.modal
        }
    }
})

const ButtonScrollToTopFab = (props) => {
    const { scrollToOptions, thresold = 1 * window.innerHeight, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [scrollY, setScrollY] = useState(0);
    const scrollContext = props.scrollContext || useContext(ScrollContext).current;

    const scrollOptions = Object.assign({}, { top: 0, behavior: "smooth" }, scrollToOptions);
    const scrollToTopHandler = () => scrollContext.ref.scrollTo(scrollOptions);
    const { t } = useTranslation();

    useEffect(() => {
        const scrollRef = scrollContext.ref;
        const scrollHandler = () => setScrollY(scrollRef.scrollTop);

        scrollHandler();

        scrollRef.addEventListener("scroll", scrollHandler);

        return () => scrollRef.removeEventListener("scroll", scrollHandler);
    }, [scrollContext]);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <Zoom
            in={scrollY > thresold}
            timeout={transitionDuration}
            unmountOnExit
        >
            <Fab
                aria-label={t('Scroll to top')}
                className={classes.fab}
                color="primary"
                onClick={scrollToTopHandler}

                {...rest}>
                <UpIcon />
            </Fab>
        </Zoom>
    );
};

export default ButtonScrollToTopFab;