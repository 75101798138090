import { createBrowserHistory } from 'history';
import { controller, resetController } from './api';
import { environment } from './environment';

export const history = createBrowserHistory();
history.listen(() => {
    controller.abort();
    resetController()
});

export const getFullUrl = pathname => {
    let baseUrl = environment.APP_BASE_URL;

    if (baseUrl.endsWith("/")) {
        baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    }

    if (pathname.startsWith("/")) {
        pathname = pathname.substr(1);
    }

    return `${baseUrl}/${pathname}`;
}
