import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import TemplatePostDialog from './TemplatePostDialog';
import { hasExports, hasCreativeCampaigns } from '../../selectors/exports';
import { hasPhotoStreamInventoryClass, hasCreativeInventoryClass } from '../../selectors/user';
import { useQueryStringAction } from '../../helpers/hooks';
import { QueryStringActions } from '../../helpers/constants';

import { addCircleOutline } from 'ionicons/icons';

function PostButton(props) {
    const { buttonProps, showPost, showCreative, defaultSelectedExportId, hasCreativeCampaigns } = props;

    const [isNewDialogOpen, setIsNewDialogOpen] = useState(useQueryStringAction(QueryStringActions.post));

    if (hasCreativeCampaigns)
        return null

    if (!showPost && !showCreative)
        return null

    return <>
        <IonButton
            color="primary"
            onClick={() => setIsNewDialogOpen(true)}
            {...buttonProps}>
            Post Ad
            <IonIcon slot="start" icon={addCircleOutline} />
        </IonButton>

        {isNewDialogOpen
            && <>

                <TemplatePostDialog
                    open={isNewDialogOpen}
                    defaultSelectedExportId={defaultSelectedExportId}
                    handleClose={() => setIsNewDialogOpen(false)} />
            </>}
    </>
}

const mapStateToProps = state => {
    return {
        hasExports: hasExports(state),
        showPost: hasPhotoStreamInventoryClass(state) && hasExports(state),
        showCreative: hasCreativeInventoryClass(state) && hasExports(state),
        hasCreativeCampaigns: hasCreativeCampaigns(state)
    }
}

export default connect(
    mapStateToProps
)(
    PostButton
);
