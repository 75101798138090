import React from 'react';
import { Route } from "react-router";

import { useSelector } from 'react-redux';
import { HomeLayout } from '../../layouts/HomeLayout';
import { Box } from '@material-ui/core';
import { selectedProfile } from '../../selectors/user';
import { objectSelector } from '../../selectors/objects';
import { CircularProgressCentered } from '../material';
import RouteProtected from './RouteProtected';
import LCTypography from '../material/LCTypography';

export const RouteApplication = (props) => {
    return <RouteProtected
        {...props}
        component={Container}
        componentOriginal={props.component}
    />
}

const Container = ({ appFeature, componentOriginal: Component, ...rest }) => {
    const profile = useSelector(selectedProfile);
    const { loading, apps } = useSelector(state => objectSelector(state)(profile.lcuid))
    const hasApp = apps.find(a => a.lcuid === appFeature.lcuid)

    if (hasApp) {
        return <Route component={Component} {...rest} />
    }

    return <HomeLayout>
        <Box p={2}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {loading
                ? <CircularProgressCentered />
                : <LCTypography variant="h5" transProps={{ appName: appFeature.name }}>
                    The <b>{{ appName: appFeature.name }}</b> application is required to be added to your profile in order to see this page.
                </LCTypography >}
        </Box >
    </HomeLayout>;
}

export default RouteApplication;
