import React from 'react';
import FilterIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

import Tooltip from '../../../components/material/Tooltip';

const TitleFilter = ({ value, onChange, ...rest }) => (<TextField
    type="text"
    fullWidth
    variant="standard"
    placeholder="Filter..."
    inputProps={{
        "aria-autocomplete": "both",
        "aria-haspopup": "false",
        "autoComplete": "off",
        "spellCheck": "false"
    }}
    value={value || ''}
    onChange={(event) => onChange(event.target.value)}
    {...rest}

    InputProps={{
        startAdornment: <InputAdornment position="start">
            <Tooltip title={"Filter"}>
                <FilterIcon fontSize="small" />
            </Tooltip>
        </InputAdornment>,
        endAdornment: value && <InputAdornment position="end">
            <IconButton
                onClick={() => onChange('')}
                size="small">
                <ClearIcon fontSize="small" />
            </IconButton>
        </InputAdornment>
    }}
/>)

export default TitleFilter;
