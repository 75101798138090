import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, isSubmitting, getFormValues } from 'redux-form';
import { Paper, makeStyles, Typography, Box } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import InventoryList from '../../containers/inventory/InventoryList';
import InventoryFilters, { INVENTORY_FILTERS_FORM } from '../../containers/inventory/filters/InventoryFilters';

import { invalidate, searchItems } from '../../actions/inventory';
import { store } from '../../reducers';
import { inventoryTotal, searched, isAllSelected, hasAllowedExports, isInvalidated } from '../../selectors/inventory';
import { withRouter } from 'react-router';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { InventoryItemClasses } from '../../helpers/constants';
import { PageTitle } from '../../components';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Inventory(props) {
    const {
        title = 'Inventory',
        noDataLabel,
        inventoryClass = InventoryItemClasses.generic,
        scrollToTopFab = true,
        Actions } = props;
    const {
        invalidated,
        handleSearch,
        submitting,
        submitSearch,
        shouldInvalidate,
        invalidate } = props;
    const classes = useStyles();

    useEffect(() => {
        // We really don't want to have initial load, if user comes from 'InventoryItem' page, or any other page
        // We don't want to have inital load if user scrolled at least something and didn't changed filters 
        if (invalidated) {
            submitSearch();
        }

        // eslint-disable-next-line
    }, [invalidated])

    useEffect(() => {
        if (shouldInvalidate) {
            invalidate();
        }
    }, [shouldInvalidate, invalidate])

    return (
        <Paper className={classes.root}>
            <PageTitle title={title} />
            <Box mb={2}>
                <Typography variant="h5">{title}</Typography>
            </Box>

            <InventoryFilters inventoryClass={inventoryClass} onSubmit={handleSearch} />

            {submitting
                && <Box mt={2} mb={2}>
                    <CircularProgressCentered size={40} />
                </Box>}

            <Box mt={2} mb={2}>
                {Actions}
            </Box>

            <InventoryList noDataLabel={noDataLabel} />

            {/* Removed in scope of https://lch.lcdevops.com/T2730 */}
            {/* <InventoryExportAllDialog
                open={exportAllOpen}
                handleClose={() => setexportAllOpen(false)}
            /> */}

            {scrollToTopFab
                && <ButtonScrollToTopFab />}
        </Paper>
    );
}

Inventory.propTypes = {
    title: PropTypes.string,
    inventoryClass: PropTypes.object,
    scrollToTopFab: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    return {
        isAllSelected: isAllSelected(state),
        submitting: isSubmitting(INVENTORY_FILTERS_FORM)(state),
        searched: searched(state),
        total: inventoryTotal(state),
        hasAllowedExports: hasAllowedExports(state),

        shouldInvalidate: props.inventoryClass !== state.inventory.inventoryClass,
        invalidated: isInvalidated(state)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        invalidate: () => dispatch(invalidate(props.inventoryClass)),
        submitSearch: () => dispatch(submit(INVENTORY_FILTERS_FORM)),
        handleSearch: () => {
            const values = getFormValues(INVENTORY_FILTERS_FORM)(store.getState());
            return dispatch(searchItems({
                ...values,
                per_page: 20,
                item_class: props.inventoryClass
                    ? props.inventoryClass.class
                    : null
            }))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(Inventory)
    )
);