import { Box, Button, FormHelperText, makeStyles } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTheme } from '@material-ui/styles';
import { logger } from '../../helpers/logger';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            x: theme.palette.text.disabled,
            padding: theme.spacing(3)
        },

        cardWrapper: {
            boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            padding: '16px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(222, 222, 222, 0.7)'
        },

        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const PaymentMethodNewDialog = (props) => {
    const { card, addCard, open, handleClose } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();

    const handleSubmit = (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements || error) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        setLoading(true);

        // Use your card Element with other Stripe.js APIs
        stripe.createToken(cardElement)
            .then(({ error, token }) => {
                if (error) {
                    setError(error.message);
                    return;
                }

                return addCard(token.id);
            })
            .then(() => handleClose())
            .catch(() => {
                setError('Something went wrong, please try again later');

                logger.logError('Something went wrong with Stripe', error)
            })
            .finally(() => setLoading(false));
    };

    return (
        <GenericDialog
            title={t('New payment method')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>
                <Box className={classes.cardWrapper}>
                    <CardElement
                        onChange={e => setError(e.error && e.error.message)}
                        onReady={e => e.focus()}
                        options={{
                            value: card,
                            disabled: loading,

                            style: {
                                base: {
                                    fontFamily: theme.typography.body1.fontFamily,
                                    fontSize: '16px',
                                    fontWeight: theme.typography.body1.fontWeight,
                                    letterSpacing: theme.typography.body1.letterSpacing,

                                    iconColor: theme.palette.primary.main,
                                    color: theme.palette.text.primary,
                                    ':disabled': { color: theme.palette.text.disabled },
                                },
                                invalid: {
                                    iconColor: theme.palette.error.main,
                                    color: theme.palette.error.main,
                                }
                            }
                        }}
                    />
                </Box>
                {error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    onClick={handleSubmit}
                    submitting={loading}
                    color="primary"
                    variant="contained">
                    {t('Save')}
                </ButtonLoader>
            </>}
        />
    )
}

PaymentMethodNewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addCard: PropTypes.func.isRequired,

    card: PropTypes.object
}

export default PaymentMethodNewDialog;
