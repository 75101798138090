import React from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core'
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { getUser, selectedProfile } from '../../selectors/user';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { withPublicLayout } from '../../layouts/PublicLayout';
import { PageTitle } from '../../components';
import { history } from '../../helpers/history';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const backgroundUrl = require('../../assets/images/NotFoundBackground.png').default;

const useStyles = makeStyles(() => {
    return {
        root: {
            position: 'relative',
            backgroundImage: `url(${backgroundUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
        },
        box: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',

            textAlign: 'center'
        },
        button: {
            height: 50
        },
        fullHeight: {
            height: `calc(100vh)`
        }
    }
})

const NotFoundPage = ({ profile }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const NotFoundBox = <>
        <Box mb={2}>
            <LCTypography variant="h2">404 - Page Not Found</LCTypography>
        </Box>

        <Box mb={3}>
            <LCTypography variant="h5">Sorry, we were unable to find that page.</LCTypography>
            <LCTypography variant="body1">Please use the button below to get back to the homepage.</LCTypography>
        </Box>

        <IonButton
            color="primary"
            onClick={() => history.push({ pathname: profile.type.path })}>
            {t('Back to Homepage')}
        </IonButton>
    </>;

    return <>
        <PageTitle title={"Page not found"} />

        <Hidden smDown>
            <Box
                className={classes.root}
            >
                <Box className={classes.box}>
                    {NotFoundBox}
                </Box>
            </Box>
        </Hidden>

        <Hidden mdUp>
            <Box width="100%" p={2} className={classes.box}>
                {NotFoundBox}
            </Box>
        </Hidden>
    </>
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
        profile: selectedProfile(state)
    }
}

const NotFoundPageConnected = connect(mapStateToProps)(NotFoundPage);
const NotFoundPagePublic = withPublicLayout(NotFoundPageConnected, {
    disablePaper: true,
    disablePermanentDrawer: true,
    disableContentPadding: true
});
const NotFoundPageHome = withHomeLayout(NotFoundPageConnected);

export {
    NotFoundPageConnected as NotFound,
    NotFoundPagePublic,
    NotFoundPageHome as NotFoundPage
}
