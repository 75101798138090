import LCTypography from '../../components/material/LCTypography';
import React from 'react';
import { connect } from 'react-redux';

import Inventory from './Inventory';
import { InventoryItemClasses } from '../../helpers/constants';

function Properties(props) {
    const { inventorySearchOpen } = props;

    return <Inventory
        title="Recreationals"
        noDataLabel={!inventorySearchOpen
            && <LCTypography variant="body1">
                No Recreationals in your inventory
            </LCTypography>}
        inventoryClass={InventoryItemClasses.recreational}
        {...props} />
}

const mapStateToProps = state => {
    return {
        inventorySearchOpen: state.layout.inventorySearch.open,
    }
}

export default connect(
    mapStateToProps
)(Properties);
