import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, withRouter } from 'react-router-dom';

import { withHomeLayout } from '../layouts/HomeLayout';
import MassEmailContainer from '../containers/massEmails/MassEmail';
import { getMassEmailById } from '../actions/massEmails';
import CircularProgressCentered from '../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',

        padding: theme.spacing(3, 2, 3, 2)
    },

}));

const MassEmail = (props) => {
    const { getById } = props;
    const classes = useStyles();
    const match = useRouteMatch();

    const [loading, setLoading] = useState(false);
    const [massEmail, setMassEmail] = useState(null);

    useEffect(() => {
        setLoading(true);
        getById(match.params.id)
            .then(setMassEmail)
            .finally(() => setLoading(false))
    }, [match.params.id, getById])

    return (
        <Paper className={classes.root}>
            {loading
                && <CircularProgressCentered size={40} />}

            {massEmail
                && <MassEmailContainer massEmail={massEmail} />}
        </Paper>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getById: id => dispatch(getMassEmailById(id))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            MassEmail
        )
    )
);
