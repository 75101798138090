import React from 'react';
import { withHomeLayout } from '../../layouts/HomeLayout';

import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import DashboardOperatorUser from '../../containers/dashboard/DashboardOperatorUser';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageTitle title={t('Operator Home')} />

            <DashboardOperatorUser />

            <ButtonScrollToTopFab />
        </>
    );
};

export default withHomeLayout(Home);
