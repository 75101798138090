import {
    ACCOUNT_ADD,
    ACCOUNT_DELETE,
    AGENCY_ACCOUNTS,
    AGENCY_ACCOUNTS_REQUEST,
    AGENCY_CAMPAIGNS, AGENCY_UNREAD_STORIES, APPROVALS_COUNT_RESULT, APPROVALS_STATUS_CHANGED,
    CAMPAIGNS_PENDING_TRAFFIC_COUNT_RESULT,
    EXPORTS_CAMPAIGN_STATE_CHANGE
} from "../helpers/actionTypes";
import { CampaignState } from "../helpers/constants";

export const initialState = {
    approvalsCounts: {},
    campaigns: [],
    accounts: [],

    campaignsPendingTrafficCount: 0,
    unreadStoriesCount: 0
}

export default function agencies(state = initialState, action) {
    switch (action.type) {
        case AGENCY_UNREAD_STORIES: {
            return Object.assign({}, state, {
                unreadStoriesCount: action.count
            });
        }

        case APPROVALS_COUNT_RESULT: {
            const approvalsCounts = action.data.reduce((prev, curr) => ({
                ...prev,
                [curr.agency_id]: curr.item_count
            }), {});

            return Object.assign({}, state, {
                approvalsCounts
            });
        }

        case APPROVALS_STATUS_CHANGED: {
            return Object.assign({}, state, {
                approvalsCounts: {
                    ...state.approvalsCounts,
                    [action.agencyId]: Math.max(state.approvalsCounts[action.agencyId] - action.approvalGroups.length, 0)
                }
            });
        }

        case AGENCY_CAMPAIGNS: {
            return Object.assign({}, state, {
                campaigns: action.campaigns
            });
        }
        case AGENCY_ACCOUNTS_REQUEST: {
            return Object.assign({}, state, {
                accountsLoading: true,
                accounts: []
            });
        }
        case AGENCY_ACCOUNTS: {
            return Object.assign({}, state, {
                accountsLoading: false,
                accounts: action.accounts
            });
        }

        case CAMPAIGNS_PENDING_TRAFFIC_COUNT_RESULT: {
            return Object.assign({}, state, {
                campaignsPendingTrafficCount: action.campaigns_pending_traffic_count
            });
        }

        case EXPORTS_CAMPAIGN_STATE_CHANGE: {
            return Object.assign({}, state, {
                campaigns: state.campaigns.map(x => {
                    if (x.lcuid === action.data.lcuid) {
                        return { ...x, ...action.data };
                    }
                    return x;
                }),
                campaignsPendingTrafficCount: action.previousState === CampaignState.pendingTraffic
                    ? Math.max(state.campaignsPendingTrafficCount - 1, 0)
                    : state.campaignsPendingTrafficCount
            });
        }

        case ACCOUNT_DELETE:
            {
                return Object.assign({}, state, {
                    accounts: state.accounts.filter((x => x.id !== action.account.id))
                });
            }

        case ACCOUNT_ADD: {
            if (action.account.agency_id == null)
                return state;

            return Object.assign({}, state, {
                accounts: [action.account, ...state.accounts]
            });
        }

        default:
            return state
    }
}
