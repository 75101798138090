import { Box, Grid, IconButton, Popover, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AccountBalance, Brightness2, Brightness5, DirectionsCar,
    Fastfood, Favorite, LocalFlorist, LocalMall, LocationCity,
    Pets, Restaurant, School, SentimentSatisfiedAlt, ShoppingCart,
    SportsBaseball, SportsEsports, StarRate
} from '@material-ui/icons';

export const IconPicker = ({ value, onChange, FallbackIcon, ...rest }) => {
    const Icon = IconOptions[value] ?? FallbackIcon;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    return <>
        <Tooltip title={t('Click to change icon')}>
            <IconButton {...rest}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                <Icon />
            </IconButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box p={1} maxWidth={304}>
                <Grid container>
                    {['default', ...Object.keys(IconOptions)]
                        .map(key => {
                            const Component = IconOptions[key] ?? FallbackIcon;

                            return <Grid key={key} item>
                                <IconButton
                                    onClick={() => {
                                        setAnchorEl(null);
                                        onChange(key);
                                    }}>
                                    <Component />
                                </IconButton>
                            </Grid>
                        })}
                </Grid>
            </Box>
        </Popover>
    </>;
}

export const IconOptions = {
    // "house": Cottage,
    "car": DirectionsCar,
    // "tractor": Agriculture,
    "moon": Brightness2,
    "sun": Brightness5,
    "shopping bag": LocalMall,
    "shopping cart": ShoppingCart,
    "bank": AccountBalance,
    "video game": SportsEsports,
    // "beer mug": SportsBar,
    "sports ball": SportsBaseball,
    "smiley face": SentimentSatisfiedAlt,
    "flower": LocalFlorist,
    "heart": Favorite,
    "star": StarRate,
    // "science": Science,
    "school": School,
    "restaurant": Restaurant,
    "paw print": Pets,
    "office building": LocationCity,
    "food": Fastfood
}
