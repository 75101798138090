import React, { useEffect, useState } from 'react';
import { ClearAll } from '@material-ui/icons';

import { DialogContent, DialogActions, Button, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { exportItemsWeightsSelector } from '../../selectors/inventoryStatus';
import { connect } from 'react-redux';
import { showError, showSuccess } from '../../actions/snackbar';
import { setItemsWeights } from '../../actions/exports';
import ButtonLoader from '../material/ButtonLoader';
import { Global } from '../../helpers/constants';
import { Dialog } from '../material';
import WeightsEditor from './WeightsEditor';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const WeightsEditorDialog = (props) => {
    const { open, title, handleClose, dialogProps, itemsWeights, saveItemsWeight } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (items.length === 0 && itemsWeights.length > 0) {
            setItems(itemsWeights.sort((a, b) => b.weight - a.weight))
        }
    }, [itemsWeights])

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}

            title={title}
            TitleProps={{
                id: "image-crop-dialog-title"
            }}

            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Box pr={1} pl={1}>
                    <WeightsEditor items={items} onChange={setItems} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setItems(
                        items.map(x => ({
                            ...x,
                            weight: Global.defaultItemWeight
                        }))
                    )}
                    startIcon={<ClearAll />}
                    style={{ marginRight: 'auto' }}
                    color="secondary"
                    variant="contained">
                    {t('Reset')}
                </Button>

                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    onClick={() => {
                        setSaving(true);
                        saveItemsWeight(items)
                            .then(() => {
                                handleClose();
                            })
                            .finally(() => setSaving(false))
                    }}
                    color="secondary"
                    variant="contained">
                    {t('Save')}
                </ButtonLoader>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => {
    const itemsWeights = exportItemsWeightsSelector(state)(ownProps.exportId);

    return {
        itemsWeights
    }
}

const mapStateToDispatch = (dispatch, ownProps) => {
    return {
        saveItemsWeight: (itemsWeights) => {
            return dispatch(setItemsWeights(ownProps.exportId, itemsWeights))
                .then(() => {
                    dispatch(showSuccess(i18next.t("Items weights were saved")))
                })
                .catch(() => {
                    dispatch(showError(i18next.t("Error during saving items weights")))
                })
        }
    }
}

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    WeightsEditorDialog
)
