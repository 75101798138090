import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { IonImg } from '@ionic/react';

const AgencyTeamCreativeProofCreatedStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    const storyData = story.story_data || {};

    const bestImage = storyData.best_creative_image_photo_url
        ? storyData.best_creative_image_photo_url
        : (
            storyData.inventory_item_meta_data.image
                ? storyData.inventory_item_meta_data.image
                : storyData.image
        )

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} create a new proof!`}
        story={story}
        Media={<><Link
            component={RouterLink}
            color="secondary"
            to={`/` + storyData.lcuid}>
            <IonImg src={bestImage} />
        </Link>
        </>}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Created a <> </>
            <Link
                component={RouterLink}
                color="secondary"
                to={`/` + storyData.lcuid}>new proof</Link> for
            <Link
                component={RouterLink}
                color="secondary"
                to={`/` + storyData.account_slug}> {storyData.account_name}</Link>
        </LCTypography>
    </BaseStory >;
};

export default AgencyTeamCreativeProofCreatedStory;
