import { IonImg } from '@ionic/react';
import React, { useState } from 'react';

export const ZoomedCreative = ({ creativeToBoardImage: creative, viewportWidth, viewportHeight, margin, ...rest }) => {
    const canRender = creative.image
        && creative.image.options
        && creative.options.overlay_perspective_coordinates_scaled
        && creative.options.overlay_perspective_coordinates_scaled.length > 0;

    if (!canRender)
        return null;

    const getTopLeftCoords = () => {
        return creative.options.overlay_perspective_coordinates_scaled.reduce((prev, cur) => {
            return [
                prev[0] === null ? cur[0] : cur[0] < prev[0] ? cur[0] : prev[0],
                prev[1] === null ? cur[1] : cur[1] < prev[1] ? cur[1] : prev[1],
            ]
        }, [null, null])
    }

    const getBottomRightCoords = () => {
        return creative.options.overlay_perspective_coordinates_scaled.reduce((prev, cur) => {
            return [
                prev[0] === null ? cur[0] : cur[0] > prev[0] ? cur[0] : prev[0],
                prev[1] === null ? cur[1] : cur[1] > prev[1] ? cur[1] : prev[1],
            ]
        }, [null, null])
    }

    return <ZoomedImage
        src={creative.image.options.public_url}
        viewportWidth={viewportWidth}
        viewportHeight={viewportHeight}
        imageWidth={creative.options.image_width}
        imageHeight={creative.options.image_height}
        topLeftCoords={getTopLeftCoords()}
        bottomRightCoords={getBottomRightCoords()}
        margin={margin}

        {...rest}
    />
}

export const ZoomedImage = ({ src,
    viewportWidth,
    viewportHeight = null,
    imageWidth,
    imageHeight,
    topLeftCoords,
    bottomRightCoords,
    margin = 0.35,
    useNativeImageTag,
    ionImageStyle = {},
    className }) => {

    const lucitXrRatio = 200 / 500;

    const [loaded, setLoaded] = useState(false);

    //Width of the image
    const Iw = imageWidth
    const Ih = imageHeight

    //Width and height of the div container
    const VPw = viewportWidth;
    const VPh = viewportHeight ? viewportHeight : viewportWidth * lucitXrRatio;

    if (!topLeftCoords || !bottomRightCoords)
        return null;

    const Xtl = topLeftCoords[0];  // top left X
    const Xbr = bottomRightCoords[0];  // bottom right X

    const Ytl = topLeftCoords[1];  // top left Y
    const Ybr = bottomRightCoords[1];  // bottom right Y

    //The rough width of the digital board in the image (in pixels)
    const boardWidth = Xbr - Xtl;
    const boardHeight = Ybr - Ytl;

    //Width and height with margin
    const boardMarginWidth = boardWidth * (1 + 2 * margin);
    const boardMarginHeight = boardHeight * (1 + 2 * margin);

    //Target top left corner with the margin
    const TargetXtl = Xtl - margin * boardWidth;
    const TargetYtl = Ytl - margin * boardHeight;

    //Target top left corner in percentage
    const TargetXtlPct = TargetXtl / Iw;
    const TargetYtlPct = TargetYtl / Ih;

    //What is the scale factor of board width vs viewport width
    const scaleX = VPw / boardMarginWidth
    const scaleY = VPh / boardMarginHeight

    const scale = Math.min(scaleX, scaleY)

    //New width and height of the image
    const Iwnew = Iw * scale
    const Ihnew = Ih * scale

    //If the offset difference is less than the width or height, then adjust the offset to fit
    const TargetYtlPixel = (Ihnew - TargetYtlPct * Ihnew) < VPh ? (Ihnew - VPh) : TargetYtlPct * Ihnew;
    const TargetXtlPixel = (Iwnew - TargetXtlPct * Iwnew) < VPw ? (Iwnew - VPw) : TargetXtlPct * Iwnew;

    return (
        <div
            className={className}
            style={{
                width: VPw + "px",
                height: VPh + "px",
                overflow: "hidden",
            }}>
            {useNativeImageTag ?
                <img
                    src={src}
                    onLoad={() => setLoaded(true)}
                    style={{
                        width: Iwnew + "px",

                        ...(loaded
                            ? {
                                position: 'relative',
                                top: -TargetYtlPixel,
                                left: -TargetXtlPixel
                            }
                            : {})
                    }} />

                : <IonImg
                    src={src}
                    onIonImgDidLoad={() => setLoaded(true)}
                    style={{
                        width: Iwnew + "px",

                        ...ionImageStyle,

                        ...(loaded
                            ? {
                                position: 'relative',
                                top: -TargetYtlPixel,
                                left: -TargetXtlPixel
                            }
                            : {})
                    }} />
            }
        </div>
    )
}
