import React, { useEffect } from 'react';
import LCTypography from '../material/LCTypography';
import LucitFormFieldHeader from './LucitFormFieldHeader';
import LucitFormFieldSecret from './LucitFormFieldSecret';
import LucitFormFieldColor from './LucitFormFieldColor';
import LucitFormFieldBoolean from './LucitFormFieldBoolean';
import LucitFormFieldDate from './LucitFormFieldDate';
import LucitFormFieldDateTime from './LucitFormFieldDateTime';
import LucitFormFieldDivider from './LucitFormFieldDivider';
import LucitFormFieldSpace from './LucitFormFieldSpace';
import LucitFormFieldGeneric from './LucitFormFieldGeneric';
import LucitFormFieldMultipleSelect from './LucitFormFieldMultipleSelect';
import CustomComponents from './custom/index.js';
import { Alert } from "@material-ui/lab";

const LucitFormField = (props) => {

    const { field, onChange, value } = props;

    useEffect(() => {

        const fieldValueIsNullOrUndefined = value === null || value === undefined;
        const fieldDefaultValueIsSet = field.default_value !== null && field.default_value !== undefined;

        if (fieldValueIsNullOrUndefined && fieldDefaultValueIsSet) {
            onChange(field.default_value);
        }
    }, [value])

    const fieldHasCustomComponent = () => field && field.component && field.component !== "default";

    if (fieldHasCustomComponent()) {

        if (!CustomComponents[field.component]) {
            return <Alert severity="error">Unknown custom component: {field.component}</Alert>
        }

        const CustomComponent = CustomComponents[field.component];
        return <CustomComponent {...props} field={field} onChange={onChange} value={value} />
    }

    if (field.enum_multiple) {
        return <LucitFormFieldMultipleSelect {...props} field={field} onChange={onChange} value={value} />
    }

    switch (field.type) {

        case 'header': return <LucitFormFieldHeader {...props} field={field} onChange={onChange} value={value} />
        case 'divider': return <LucitFormFieldDivider {...props} field={field} onChange={onChange} value={value} />
        case 'space': return <LucitFormFieldSpace {...props} field={field} onChange={onChange} value={value} />
        case 'color': return <LucitFormFieldColor {...props} field={field} onChange={onChange} value={value} />
        case 'secret': return <LucitFormFieldSecret {...props} field={field} onChange={onChange} value={value} />
        case 'bool': return <LucitFormFieldBoolean {...props} field={field} onChange={onChange} value={value} />
        case 'date': return <LucitFormFieldDate {...props} vfield={field} onChange={onChange} value={value} />
        case 'datetime': return <LucitFormFieldDateTime {...props} field={field} onChange={onChange} value={value} />

        case 'int':
        case 'float':
        case 'text':
        case 'link':
            return <LucitFormFieldGeneric   {...props} field={field} onChange={onChange} value={value} />

        default: return <LCTypography color="error">Unknown field type: {field.type}</LCTypography>

    }
}

export default LucitFormField;
