import React from 'react';
import { connect } from 'react-redux';
import { Box, ButtonGroup } from '@material-ui/core';
import ProofButtonLink from './ProofButtonLink';
import EditTemplateItemValuesButton from '../../inventory/EditTemplateItemValuesButton';
import ActivateButton from './ActivateButton';
import DeleteButton from './DeleteButton';
import PhotoOverrideButton from './PhotoOverrideButton';
import { RoutePaths } from '../../../helpers/constants';

const InventoryItemActions = ({ item }) => {

    if (!item)
        return null

    return <Box>
        <ButtonGroup>
            <ButtonGroup>
                <ProofButtonLink item={item} />
                <ActivateButton item={item} />
                <PhotoOverrideButton item={item} />
                <EditTemplateItemValuesButton item={item} />
                <DeleteButton
                    item={item}
                    redirectPathname={RoutePaths.home} />
            </ButtonGroup>
        </ButtonGroup>
    </Box>
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemActions
);
