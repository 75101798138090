import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Editor from "react-simple-code-editor";
import Prism from 'prismjs';
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { editModeGlobal } from '../../selectors/designer'
import { Tabs, Tab } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const editorBoxStyle = {
    height: "350px",
    maxHeight: "350px",
    overflowY: "auto",
    marginTop: "0px",
}

const editorStyle = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
    width: "100%",
    minHeight: "350px"
}

const useStyles = makeStyles(() => {
    return {
        editorTextArea: {
            outline: 0
        },
        editorContainer: {
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginTop: "0px",
        }
    }
});

/**
 * ChatGPT is at it again....
 */
const checkCodeForBadThings = (code) => {
    // Check for common dangerous patterns
    const dangerousPatterns = [
        /eval\s*\(/,
        /new Function\s*\(/,
        /setTimeout\s*\(/,
        /setInterval\s*\(/,
        /XMLHttpRequest\s*\(/,
        /document\.cookie\s*=/,
        /localStorage\s*\(/,
        /sessionStorage\s*\(/,
        /window\s*\./,
        /document\s*\./,
        /write\s*\(/,
        /cookie\s*=/,
        /prompt\s*\(/,
        /confirm\s*\(/,
        /alert\s*\(/,
        /<\s*script/,
        /\bexecScript\b/,
        /<\s*iframe/,  // Disallow iframes
        /<\s*object/,  // Disallow objects
        /<\s*embed/,   // Disallow embed tags
        /<\s*applet/,  // Disallow applet tags
        /<\s*meta\s*http-equiv="refresh"/,  // Disallow meta refresh
        /<\s*link.*\s*rel="import"/,  // Disallow HTML imports
        /document\.write\s*\(/,  // Disallow document.write
        /@import/,  // Disallow CSS imports
        /\s+on\w+\s*=\s*["'][^"']*["']/,  // Disallow event attributes (e.g., onclick)
    ];

    // Check for each dangerous pattern
    for (const pattern of dangerousPatterns) {
        if (pattern.test(code)) {
            return true; // Code contains a potentially harmful pattern
        }
    }

    return false; // Code is likely safe
}

const checkXhtmlCompliance = (htmlString) => {

    if (checkCodeForBadThings(htmlString))
        return "Invalid or disallowed code detected"

    const preparedHtmlString = "<div>" + htmlString + "</div>"

    try {
        // Create a new DOMParser
        const parser = new DOMParser();

        // Try to parse the HTML string
        const xmlDoc = parser.parseFromString(preparedHtmlString, 'application/xml');

        // Check for parsing errors
        const parseErrors = xmlDoc.getElementsByTagName('parsererror');

        // If there are no parsing errors, the HTML is well-formed and XHTML compliant
        if (parseErrors.length === 0) {
            return null;
        } else {
            // If there are parsing errors, collect them into a string
            let errorString = 'Parsing errors:';
            for (let i = 0; i < parseErrors.length; i++) {
                errorString += `\n${parseErrors[i].textContent}`;
            }
            return errorString;
        }
    } catch (error) {
        // If an exception occurs during parsing, return an error message
        return 'An error occurred during parsing. The HTML is not well-formed or not XHTML compliant.';
    }
}

function checkWellFormedCSS(cssString) {

    if (checkCodeForBadThings(cssString))
        return "Invalid or disallowed code detected"

    // Check for balanced curly braces
    if ((cssString.match(/{/g) || []).length !== (cssString.match(/}/g) || []).length) {
        return "Unbalanced curly braces";
    }

    // Check for balanced square brackets
    if ((cssString.match(/\[/g) || []).length !== (cssString.match(/]/g) || []).length) {
        return "Unbalanced square brackets";
    }

    // Check for balanced parentheses
    if ((cssString.match(/\(/g) || []).length !== (cssString.match(/\)/g) || []).length) {
        return "Unbalanced parentheses";
    }

    // Split the CSS into individual rules
    const rules = cssString.split('}');

    // Check each rule for proper colon and semicolon usage
    for (const rule of rules) {
        const trimmedRule = rule.trim();

        const ruleParts = trimmedRule.split('{');
        if (ruleParts.length < 2) continue;

        const ruleWithoutName = ruleParts[1].trim();

        if (ruleWithoutName && ruleWithoutName.length > 0) {

            //Make sure the the rule ends with a semicolon
            if (ruleWithoutName[ruleWithoutName.length - 1] !== ';') {
                return <>
                    A semi colon is missing at the end of the rule : <strong>{ruleWithoutName}</strong>
                </>
            }

            const declarations = ruleWithoutName.split(';').map(decl => decl.trim()).filter(decl => decl.length > 0);

            for (const declaration of declarations) {

                if (!declaration)
                    continue;

                const parts = declaration.split(':');

                if (parts.length !== 2 || parts[0].trim() === '' || parts[1].trim() === '') {
                    return <>
                        A semi colon is missing, or Property and value
                        must be separated by a colon for : <strong>{declaration}</strong>
                    </>
                }
            }
        }
    }

    return null;
}

export const invalidJSCodeError = (jsCodToTest) => {

    if (!jsCodToTest) return null;

    if (checkCodeForBadThings(jsCodToTest))
        return "Invalid or disallowed code detected"

    try {
        new Function(jsCodToTest)
        return null; //things seem good
    } catch (e) {
        return "Syntax Error : " + e.message;
    }
}

//Highlights lucit macros with the lucit blue color
const highlightLucitMacros = (code) => {

    const lucitBlue = "#02c9c9";
    const lucitBlueButDarker = "#009999";

    const pattern = /(\{{1,2})([a-zA-Z_][\w.-]*)(\}{1,2})/g;

    const replacedString = code.replace(pattern, (match, openingBraces, _, closingBraces) => {

        const isValid = openingBraces.length === closingBraces.length;

        const color = (openingBraces && closingBraces && openingBraces.length === 1 && closingBraces.length === 1)
            ? lucitBlue
            : lucitBlueButDarker

        return isValid ? `<span style="color:${color}">${match}</span>` : match;

    });

    return replacedString;

}

export const ParseStatusBar = ({ message }) => {

    return <Box mt={2} mb={2}>
        {message
            ? <Alert severity="error">{message}</Alert>
            : <Alert style={{ backgroundColor: "inherit", maxWidth: "80px" }} severity="success" ></Alert>}
    </Box>
}

const DesignerCodeEditorsComponent = ({ html, setHTML, css, setCSS, js, setJS, editModeGlobal, setError }) => {

    const [selectedTab, setSelectedTab] = useState(0);
    const [htmlError, setHtmlError] = useState(null);
    const [cssError, setCssError] = useState(null);
    const [jsError, setJsError] = useState(null);

    useEffect(() => {
        setHtmlError(checkXhtmlCompliance(html));
    }, [html])

    useEffect(() => {
        setCssError(checkWellFormedCSS(css));
    }, [css])

    useEffect(() => {
        setJsError(invalidJSCodeError(js));
    }, [js])

    useEffect(() => {
        if (htmlError || cssError || jsError) {
            setError(true);
        } else {
            setError(false);
        }
    }, [htmlError, cssError, jsError])

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const classes = useStyles();

    return (
        <Box mb={3}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="HTML" />
                <Tab label="CSS" />
                <Tab label="JS" />
            </Tabs>
            {selectedTab === 0 && (<Box>
                <Box className={classes.editorContainer}>
                    <EditorHTML
                        html={html}
                        onChange={(html) => setHTML(html)}
                    />
                </Box>
                <ParseStatusBar
                    message={htmlError}
                />
            </Box>
            )
            }

            {
                selectedTab === 1 && (<Box>
                    <Box className={classes.editorContainer}>
                        <EditorCSS
                            css={css}
                            onChange={(css) => setCSS(css)}
                            disabled={!editModeGlobal}
                        />

                    </Box>
                    <ParseStatusBar
                        message={cssError}
                    />
                </Box>
                )
            }

            {
                selectedTab === 2 && (<Box>
                    <Box className={classes.editorContainer}>
                        <EditorJS
                            js={js}
                            onChange={(js) => setJS(js)}
                        />

                    </Box>
                    <ParseStatusBar
                        message={jsError}
                    />
                </Box>
                )
            }

        </Box>
    )
}

const mapStateToProps = state => {
    return {
        editModeGlobal: editModeGlobal(state)
    };
}

const mapDispatchToProps = () => {
    return {
    }
}

export const DesignerCodeEditors =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerCodeEditorsComponent
    );

/**
 *
 * Generic Code Editor Box
 */
export const CodeEditor = ({ code, onChange, title, language, editorBoxStyles = {}, styles = {}, disabled }) => {

    const editorStyles = { ...editorStyle, ...styles }

    const editorBoxStylesCombined = { ...editorBoxStyle, ...editorBoxStyles }

    editorStyles.backgroundColor = disabled ? "#f5f5f5" : editorStyles.backgroundColor

    const classes = useStyles();

    return (
        <Box>
            <Typography variant="h5">{title}</Typography>
            <Box style={editorBoxStylesCombined} mt={3}>
                <Editor
                    value={code ?? ""}
                    onValueChange={(newCode) => onChange(newCode)}
                    highlight={(newCode) => {
                        var highlighted = Prism.highlight(newCode, language)

                        highlighted = highlightLucitMacros(highlighted)

                        return highlighted
                    }}
                    style={editorStyles}
                    padding={10}
                    textareaClassName={classes.editorTextArea}
                    disabled={disabled}
                />
            </Box>
        </Box>
    )
}

export const EditorHTML = ({ html, onChange, title, styles, editorBoxStyles }) => {

    return (
        <CodeEditor
            code={html}
            onChange={onChange}
            language={Prism.languages.html}
            styles={styles}
            editorBoxStyles={editorBoxStyles}
            title={title} />
    )
}

export const EditorCSS = ({ css, onChange, title, disabled, styles, editorBoxStyles }) => {

    return (
        <CodeEditor
            disabled={disabled}
            code={css}
            onChange={onChange}
            language={Prism.languages.css}
            styles={styles}
            editorBoxStyles={editorBoxStyles}
            title={title}
        />
    )
}

export const EditorJS = ({ js, onChange, title, styles, editorBoxStyles }) => {

    return (
        <CodeEditor
            code={js}
            onChange={onChange}
            language={Prism.languages.js}
            title={title}
            styles={styles}
            editorBoxStyles={editorBoxStyles}
        />
    )
}
