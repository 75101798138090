import React from 'react';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { makeStyles, DialogContent, DialogActions, Button, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { Dialog } from '../material';

const useStyles = makeStyles(theme => {
    return {
        content: {
            display: 'flex',
            justifyContent: 'center',
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2),

            [theme.breakpoints.down('md')]: {
                padding: '0 !important',
            },

            '& .react-transform-wrapper, .react-transform-component, .react-transform-element': {
                width: '100%',
                height: '100%'
            },
        },

        paper: {
            [theme.breakpoints.down('md')]: {
                background: theme.palette.primary.main,
                color: theme.palette.common.white
            }
        },

        white: {
            [theme.breakpoints.down('md')]: {
                color: theme.palette.common.white
            }
        },

        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'scale-down',
            }
        }
    }
})

const ImagePreviewDialog = (props) => {
    const { open, title, handleClose, dialogProps, children } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth
            fullScreen={isXs}
            maxWidth={false}
            open={open}
            aria-labelledby="image-preview-dialog-title"
            aria-describedby="image-preview-dialog-description"
            onClose={handleClose}
            title={title}
            classes={{
                paper: classes.paper,
                closeButton: classes.white
            }}
            {...dialogProps}
        >
            <DialogContent className={classes.content} id="image-preview-dialog-description" >
                <TransformWrapper>
                    <TransformComponent>
                        <Box className={classes.imageContainer}>
                            {children}
                        </Box>
                    </TransformComponent>
                </TransformWrapper>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.white}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ImagePreviewDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

export { ImagePreviewDialog }
