import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function SentenceCaseIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 1080 1080">
        <path d="M281.2,279.54h62.4l218.4,560h-70.4l-58.4-151.2h-241.6l-58.4,151.2H61.2l220-560ZM216.4,
                624.35h192l-96-248.8-96,248.8Z" />
        <path d="M999.6,654.75c0,108.8-79.2,194.4-179.2,194.4-88.8,
                0-124.8-63.2-124.8-63.2v53.6h-61.6V234.74h67.2v280.8s37.6-55.2,119.2-55.2c100,0,
                179.2,85.6,179.2,194.4ZM701.2,722.75s35.2,64,108.8,64,122.4-59.2,122.4-132-48.8-132-122.4-132-108.8,
                64-108.8,64v136Z" />
    </SvgIcon>
}
