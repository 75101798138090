import React from 'react';
import { Box, makeStyles, Slider } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',

            [theme.breakpoints.down(520)]: {
                justifyContent: 'space-between'
            }
        },
        slider: {
            marginLeft: "25px",
            marginRight: "25px",
        },
        sliderEnds: {
            whiteSpace: "nowrap",
            width: "100px"
        }
    }
});

const TimeRangeInput = ({ onTimeRangeUpdate, defaultStartTime, defaultEndTime }) => {
    const classes = useStyles();
    const getHour = (time) => {
        const d = new Date(time);
        return d.getUTCHours();
    }

    const [rangeValue, setRangeValue] = useState([
        defaultStartTime ? getHour(defaultStartTime) : 6,
        defaultEndTime ? getHour(defaultEndTime) : 18
    ]);

    const [startTime, setStartTime] = useState(defaultStartTime);
    const [endTime, setEndTime] = useState(defaultEndTime);

    const getTimeStringFromHours = (hours) => {
        const d = new Date()
        d.setUTCHours(hours)
        d.setUTCMinutes(0)
        d.setUTCSeconds(0)
        d.setUTCMilliseconds(0)
        return d.toISOString()
    }

    const getTimeStringFromHoursForEnd = (hours) => {
        const d = new Date()
        d.setUTCHours(hours)
        d.setUTCMinutes(59)
        d.setUTCSeconds(59)
        d.setUTCMilliseconds(0)
        return d.toISOString()
    }

    const getTimeStringAmPmFromHours = (hours) => {

        if (hours === 0) {
            return <span>
                {hours + 12} AM
            </span>
        }
        else if (hours < 12) {
            return <span>
                {hours} AM
            </span>
        }
        else if (hours === 12) {
            return <span>
                {hours} PM
            </span>
        }
        else if (hours < 24) {
            return <span>
                {hours - 12} PM
            </span>
        }
        else {
            return <span>
                {hours - 12} AM
            </span>
        }
    }

    const getTimeStringAmPmFromHoursForEnd = (hours) => {

        if (hours === 0) {
            return <span>
                {hours + 12}:59 AM
            </span>
        }
        else if (hours < 12) {
            return <span>
                {hours}:59 AM
            </span>
        }
        else if (hours === 12) {
            return <span>
                {hours}:59 PM
            </span>
        }
        else if (hours < 24) {
            return <span>
                {hours - 12}:59 PM
            </span>
        }
        else {
            return <span>
                {hours - 12}:59 AM
            </span>
        }
    }

    useEffect(() => {
        setStartTime(getTimeStringAmPmFromHours(rangeValue[0]));
        setEndTime(getTimeStringAmPmFromHoursForEnd(rangeValue[1]));
        onTimeRangeUpdate({
            startTime: getTimeStringFromHours(rangeValue[0]),
            endTime: getTimeStringFromHoursForEnd(rangeValue[1]),
        });
    }, [rangeValue])

    return <Box className={classes.root}>
        <span className={classes.sliderEnds}>{startTime}</span>
        <Slider
            className={classes.slider}
            min={0}
            max={23}
            step={1}
            value={rangeValue}
            color="secondary"
            onChange={(e, newValue) => {
                setRangeValue(newValue)
            }}
        >
        </Slider>
        <span className={classes.sliderEnds}>{endTime}</span>
    </Box>
};

export { TimeRangeInput }
