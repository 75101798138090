import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import TextField from '../../components/inputs/TextField';
import { required, email } from '../../helpers/validators';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 0)
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));

const AdvertiseForm = (props) => {
    const { handleSubmit, error } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Field
                name="name"
                validate={[required]}
                component={TextField}
                props={{
                    label: t("Your name"),
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "name",
                    autoComplete: "name"
                }}
            />

            <Field
                name="email"
                validate={[required, email]}
                component={TextField}
                props={{
                    label: t("Email Address"),
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "email",
                    autoComplete: "email"
                }}
            />
            <Field
                name="company"
                component={TextField}
                props={{
                    label: t("Company Name (Optional)"),
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true,
                    id: "company",
                }}
            />
            <Field
                name="message"
                component={TextField}
                props={{
                    label: t("Message"),
                    multiline: true,
                    rows: "4",
                    variant: "outlined",
                    margin: "normal",
                    fullWidth: true,
                    id: "message"
                }}
            />
            <Typography color="error">
                {error}
            </Typography>
        </form>
    )
}

const mapStateToProps = () => {
}

export const FORM_ADVERTISE = "advertise";
export default
    connect(
        mapStateToProps
    )(
        reduxForm({
            enableReinitialize: true,
            form: FORM_ADVERTISE, // a unique name for this form
            initialValues: {
                name: '',
                email: '',
                company: '',
                message: ''
            }
        })(AdvertiseForm)
    );
