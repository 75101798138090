import { lucitApi } from "../services/lucitApi"
import { getAttachments } from "./objects";

export function getProof(inventoryItemId) {
    return () => {
        return lucitApi.inventory.getProof(inventoryItemId)
            .then(data => {
                return data.creative_proof;
            })
    }
}

export function addAttachment(lcuid, attachable_lcuid) {
    return dispatch => {
        return lucitApi.creativeProofs.addAttachment(lcuid, attachable_lcuid)
            .then(() => dispatch(getAttachments(lcuid)));
    }
}
