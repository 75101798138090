import React, { useState } from 'react';
import { Box, Accordion, AccordionSummary, Divider, AccordionDetails, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import LCTypography from '../components/material/LCTypography';
import { withHomeLayout } from '../layouts/HomeLayout';
import ContactForm from '../containers/contact/ContactForm';
import { ErrorBoundary } from '../components/errors/ErrorBoundary';
import { PageTitle } from '../components';

const useStyles = makeStyles(theme => {
    return {
        content: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2)
        },
    }
});

const Help = () => {
    const classes = useStyles();
    const [isHelpOpened, setHelpOpened] = useState(true);
    const [isHelpRequestOpened, setHelpRequestOpened] = useState(true);
    const [message, setMessage] = useState('');

    return (
        <ErrorBoundary isFatal>
            <PageTitle title={"Help"} />
            <Accordion expanded={isHelpOpened} onChange={() => setHelpOpened(!isHelpOpened)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="contact-form-content"
                    id="contact-form-header"
                >
                    <LCTypography variant="h5">Help</LCTypography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails className={classes.content}>
                    <LCTypography>
                        For help with this application, frequently asked questions, and demonstration videos, visit our online help
                    </LCTypography>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            href="https://lucit.cc/help/"
                            target="_blank">
                            Online Help
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={isHelpRequestOpened} onChange={() => setHelpRequestOpened(!isHelpRequestOpened)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="contact-form-content"
                    id="contact-form-header"
                >
                    <LCTypography variant="h5">Help Request</LCTypography>
                </AccordionSummary>
                <Divider />

                <AccordionDetails className={classes.content}>
                    <LCTypography>Fill out your help request and a Lucit Support rep will contact you</LCTypography>

                    <Box maxWidth={400} width="100%">
                        {message
                            && <>
                                <Alert severity={"success"}>
                                    <Box mb={1}>{message}</Box>
                                </Alert>
                            </>}
                        <ContactForm onSubmitted={data => setMessage(`Your message was successfully sent. Ticket with number #${data.ticket_number} was created.`)} />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </ErrorBoundary>
    );
};

export default withHomeLayout(Help);
