import { Box, makeStyles, useTheme } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography'
import PropTypes from 'prop-types';
import clx from 'classnames';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MapButton from './MapButton';
import { GoogleMapsContext } from '../../contexts';
import { logger } from '../../helpers/logger';
import ScreenMapMarker from './ScreenMapMarker';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        centerText: {
            ...theme.typography.body1
        },
        targetedButton: {
            marginLeft: theme.spacing(1)
        },
        mapButton: {
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
        firstMapButton: {
            marginTop: '10px !important',
            borderRadius: '5px 5px 0px 0px !important',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
        lastMapButton: {
            marginBottom: '0px !important',
            borderRadius: '0px 0px 5px 5px !important',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
        currePosIcon: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/navigationOn.png)',
        },
        allBoards_off: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/allBoards_off.png)',
        },
        allBoards_on: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/allBoards_on.png)',
        },
        zoomIn: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/plus.png)',
        },
        zoomOut: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/minus.png)',
        },
        satOn: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/satmap.png)',
        },
        satOff: {
            backgroundImage: 'url(/assets/google-maps/mapControl_icons/satmap.png)',
            filter: 'grayscale(100%)',
        }
    }
})

const defaultCenter = {
    lat: 46.7957069,
    lng: -100.8271929,
    zoom: 13,
}
const defaultZoom = 13;

const Map = (props) => {
    const {
        locations,
        locationSelected,
        centeringType = 'locations',
        centerFirstTimeOnly = false,
        firstTimeCenterKey = null,
        onLocationChange,
        placeId,
        onNewPlace,
        extraZoomForPointsOfInterestPlaces = false,
        enableClustering = true,
        highlightTargeted = false,
        showTargetedOnly = false,
        showTargetedButton = false,
        showCenterButton = true } = props;
    const propsClasses = props.classes || {};
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    // eslint-disable-next-line
    const [map, setMap] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [isTargetedOnly, setIsTargetedOnly] = useState(showTargetedOnly);

    const userLocation = useMemo(() => props.userLocation || {}, [props.userLocation]);

    const [hasBeenCentered, setHasBeenCentered] = useState(false);
    useEffect(() => {
        setHasBeenCentered(false);
    }, [firstTimeCenterKey])

    const shouldCenter = () => !centerFirstTimeOnly || (centerFirstTimeOnly && !hasBeenCentered);

    const selectLocation = useCallback(location => {
        if (onLocationChange) {
            onLocationChange(location);
        }
    }, [])

    const onMapLoaded = useCallback(map => {
        setMap(map);

        //Initial center and zoom
        const center = props.center || defaultCenter;

        map.setCenter(new window.google.maps.LatLng(center.lat, center.lng));
        map.setZoom(center.zoom);
    }, []);

    const markerClickHandler = useCallback((_, location) => {
        // Required so clicking a 2nd marker works as expected
        if (locationSelected) {
            selectLocation(null);
        }

        if (locationSelected !== location) {
            selectLocation(location);
        }

        // if you want to center the selected Marker
        // setCenter(place.pos)
    }, [locationSelected]);

    const centerMapClickHandler = useCallback(isTargetedOnly => {

        if (map && shouldCenter()) {

            const bounds = new window.google.maps.LatLngBounds();
            const targetedLocations = locations.filter(l => l.targeted);

            if (isTargetedOnly && targetedLocations.length) {
                targetedLocations.forEach(l =>
                    bounds.extend(new window.google.maps.LatLng(+l.latitude, +l.longitude)));
            } else {
                locations.forEach(l =>
                    bounds.extend(new window.google.maps.LatLng(+l.latitude, +l.longitude)));
            }

            if (centeringType === 'locations' && locations.length) {
                map.setOptions({ maxZoom: defaultZoom });
                map.fitBounds(bounds);
                setTimeout(() => {
                    map.fitBounds(bounds, 75);
                    map.setOptions({ maxZoom: 21 });
                    var zoom = map.getZoom();
                    map.setZoom(zoom > defaultZoom ? defaultZoom : zoom);
                }, 1000);
                setHasBeenCentered(true);
            } else if (centeringType === 'custom' && props.center) {
                map.setCenter(new window.google.maps.LatLng(
                    props.center.lat,
                    props.center.lng))

                map.setZoom(props.center.zoom);
                setHasBeenCentered(true);
            } else {
                map.setCenter(new window.google.maps.LatLng(
                    userLocation.lat || defaultCenter.lat,
                    userLocation.long || defaultCenter.lng))
                map.setZoom(defaultCenter.zoom);
                setHasBeenCentered(true);
            }
        }
    }, [map, locations, userLocation, centeringType]);

    const markerLoadHandler = useCallback((marker, location) => {
        setMarkerMap(prevState => {
            return { ...prevState, [location.id]: marker };
        });
    }, []);

    const markerUnmountHandler = useCallback((_, location) => {
        setMarkerMap(prevState => {
            delete prevState[location.id];

            return { ...prevState };
        });
    }, []);

    const markerDragEndHandle = useCallback((e, location) => props.onDragEnd && props.onDragEnd(e, location),
        [props.onDragEnd])

    const zomeInHandler = useCallback(() => {
        map.setZoom(map.getZoom() + 1);
    }, [map]);

    const zomeOutHandler = useCallback(() => {
        map.setZoom(map.getZoom() - 1);
    }, [map]);

    const [isSatelliteViewOn, setIsSatelliteViewOn] = useState(false);

    const mapViewHandler = useCallback(() => {
        setIsSatelliteViewOn(!isSatelliteViewOn);
    }, [map, isSatelliteViewOn]);

    useEffect(() => {
        map && map.setMapTypeId(isSatelliteViewOn ? 'hybrid' : 'roadmap');
    }, [isSatelliteViewOn]);

    const filterTargetedClickHandler = useCallback(() => {
        const isTargeted = !isTargetedOnly;
        selectLocation(null);
        setIsTargetedOnly(isTargeted);

        centerMapClickHandler(true);
    }, [isTargetedOnly, centerMapClickHandler]);

    // Initially center map when all markers are loaded
    useEffect(() => {
        if (locations.length > 0 && locations.length === Object.keys(markerMap).length) {
            centerMapClickHandler(isTargetedOnly);
        }
    }, [locations.length, markerMap, centerMapClickHandler])

    // Show all boards if no targeting boards
    useEffect(() => {
        if (locations.length && locations.every(l => !l.targeted)) {
            setIsTargetedOnly(false);
        }
    }, [locations])

    // Hide/Show markers when {isTargetedOnly} changes
    useEffect(() => {
        locations.forEach(l => {
            if (markerMap[l.id]) {
                markerMap[l.id].setVisible(!isTargetedOnly || (isTargetedOnly && l.targeted));
            }
        });
    }, [isTargetedOnly, locations, markerMap])

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(gmContext.error)
            logger.logError(t('Map failed to load: {{error}}', { error: gmContext.error }), { Error: gmContext.error });
        }
    }, [gmContext.error])

    useEffect(() => {
        if (placeId) {
            const service = new window.google.maps.places.PlacesService(map);
            service.getDetails({ placeId: placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.union(place.geometry.viewport)
                    map.fitBounds(bounds)

                    map.setCenter(place.geometry.location)

                    onNewPlace && onNewPlace(place);

                    if (extraZoomForPointsOfInterestPlaces && place.types.includes('point_of_interest')) {
                        setTimeout(() => {
                            map.setZoom(19);   //so much hackiness
                        }, 1500);
                    }

                }
            })
        }
    }, [placeId, map])

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <LCTypography>Map is currently unavailable</LCTypography>
        </Box>
    }

    const mapOptions = useMemo(() => ({
        //gestureHandling: 'auto',
        streetViewControl: false,
        fullscreenControl: false,
        minZoom: 2,
        zoom: 11,
        mapId: "a69a5010584425cd",
        zoomControl: false,
        zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        restriction: {
            latLngBounds: {
                east: 179.9999,
                north: 85,
                south: -85,
                west: -179.9999
            },
            strictBounds: true
        },
        // styles: require('../../assets/google-maps-styles.blackWhite.json')
    }), []);

    const markerClustererOptions = useMemo(() => ({
        imagePath: 'assets/google-maps/clustermarkers/groupB/m',
        imageExtension: 'png',
        maxZoom: 10
    }), []);

    const markerClustererCalculator = useCallback((markers, numStyles) => {
        var index = 0;
        var count = markers.length.toString();

        var dv = count;
        while (dv !== 0) {
            dv = parseInt(dv / 10, 10);
            index++;
        }

        index = Math.min(index, numStyles);
        return {
            text: "",
            index: index,
            title: count
        };
    }, [])

    return <>
        <GoogleMap
            mapContainerClassName={clx(classes.map, propsClasses.map)}
            options={mapOptions}

            onLoad={onMapLoaded}
        >
            {userLocation.accuracy
                && <>
                    <Marker
                        animation={window.google.maps.Animation.DROP}
                        zIndex={30}
                        icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            fillColor: theme.palette.secondary.main,
                            fillOpacity: 0.6,
                            strokeColor: theme.palette.secondary.main,
                            strokeOpacity: 0.9,
                            strokeWeight: 1,
                            scale: 7
                        }}

                        position={new window.google.maps.LatLng(+userLocation.lat, +userLocation.long)}
                    />
                </>}

            { /* Child components, such as markers, info windows, etc. */}
            {showCenterButton
                && <MapButton
                    map={map}
                    title={t('Click to recenter the map')}
                    position={window.google.maps.ControlPosition.RIGHT_TOP}
                    classes={{
                        button: classes.firstMapButton,
                        text: classes.currePosIcon
                    }}
                    onClick={() => centerMapClickHandler(isTargetedOnly)}
                />}

            {showTargetedButton
                && <MapButton
                    map={map}
                    title={isTargetedOnly
                        ? t('Click to show all boards')
                        : t('Click to show only targeted boards')}
                    position={window.google.maps.ControlPosition.RIGHT_TOP}
                    classes={{
                        button: showCenterButton ? classes.mapButton : classes.firstMapButton,
                        text: isTargetedOnly ? classes.allBoards_off : classes.allBoards_on
                    }}
                    onClick={filterTargetedClickHandler}
                />}

            <MapButton
                map={map}
                title={isSatelliteViewOn
                    ? t('Roadmap View')
                    : t('Satellite View')}
                position={window.google.maps.ControlPosition.RIGHT_TOP}
                classes={{
                    button: (showCenterButton || showTargetedButton) ? classes.mapButton : classes.firstMapButton,
                    text: isSatelliteViewOn ? classes.satOn : classes.satOff
                }}
                onClick={mapViewHandler}
            />

            <MapButton
                map={map}
                title={t('Zoom In')}
                position={window.google.maps.ControlPosition.RIGHT_TOP}
                classes={{
                    button: classes.mapButton,
                    text: classes.zoomIn
                }}
                onClick={zomeInHandler}
            />

            <MapButton
                map={map}
                title={t('Zoom Out')}
                position={window.google.maps.ControlPosition.RIGHT_TOP}
                classes={{
                    button: classes.lastMapButton,
                    text: classes.zoomOut
                }}
                onClick={zomeOutHandler}
            />

            <MarkerClusterer
                averageCenter
                ignoreHidden
                minimumClusterSize={3}
                gridSize={25}
                calculator={markerClustererCalculator}
                options={markerClustererOptions}
            >
                {(clusterer) =>
                    locations
                        .map((location) =>
                            <ScreenMapMarker
                                title={location.billboardName ? location.billboardName : location.name}
                                key={location.id}
                                clusterer={enableClustering ? clusterer : null}
                                location={location}
                                highlight={highlightTargeted
                                    ? location.targeted
                                    : false}

                                onLoad={markerLoadHandler}
                                onClick={markerClickHandler}
                                onUnmount={markerUnmountHandler}
                                onDragEnd={markerDragEndHandle}
                            />)}
            </MarkerClusterer>
        </GoogleMap>
    </>;
}

const mapStateToProps = (state) => {
    return {
        userLocation: state.user.locationGps || state.user.location
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

Map.propTypes = {
    locations: PropTypes.array.isRequired,
    locationSelected: PropTypes.object,
    onLocationChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    classes: PropTypes.object,
    // Determine how Center button will determine center
    // 'defaultCenter' -- mean centering to default center
    // 'userGeoposition' -- mean centering to user Geoposition
    // 'locations' -- mean centering to all locations
    // 'custom' -- center provided in props
    centeringType: PropTypes.oneOf(['userGeoposition', 'locations', 'defaultCenter', 'custom']),
    center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
        zoom: PropTypes.number
    }),

    showCenterButton: PropTypes.bool,
    showTargetedButton: PropTypes.bool,
    showTargetedOnly: PropTypes.bool,
    highlightTargeted: PropTypes.bool,
    centerFirstTimeOnly: PropTypes.bool,
    firstTimeCenterKey: PropTypes.string,
    enableClustering: PropTypes.bool
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Map
);
