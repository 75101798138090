import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getItem } from '../../../actions/inventory';
import LCTypography from '../../../components/material/LCTypography';
import CreativesList from './CreativesList';
import CircularProgressCentered from '../../../components/material/CircularProgressCentered';
import { InventoryItemCreativeState } from '../../../helpers/constants'
import { useHistory } from 'react-router';
import { lucitApi } from '../../../services/lucitApi';
import ButtonLoader from '../../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../../helpers/constants';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },
        fallbackDescription: {
            width: 300,
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    }
})

const ResetDefaultCreativeButton = ({ accountId }) => {

    const [gettingDefaultCreative, setGettingDefaultCreative] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const history = useHistory();

    const { t } = useTranslation();

    const getAndGoToDefaultCreative = () => {
        setGettingDefaultCreative(true);
        lucitApi.accounts.resetDefaultCreative(accountId)
            .then(item => {
                setGettingDefaultCreative(false);
                history.push({ pathname: RoutePaths.inventory + '/' + item.id })
            })
    }

    return <>
        <Button
            onClick={() => setConfirmOpen(true)}
            color="secondary"
            variant="contained"
        >{t('Reset this Creative to Default Logo')}</Button>

        <Dialog
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            title={t('Reset Default Creative')}
            open={confirmOpen}
        >
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-description"
                >
                    {t('Are you sure you want to reset the default creative to your account logo?')}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmOpen(false)}
                    disabled={gettingDefaultCreative}
                    color="primary"
                >
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={gettingDefaultCreative}
                    onClick={getAndGoToDefaultCreative}
                    color="secondary"
                    variant="contained"
                >{t('Yes - Reset')}</ButtonLoader>
            </DialogActions>
        </Dialog>

    </>
}

function InventoryItem(props) {
    const { item } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                {item.creative_state === InventoryItemCreativeState.building
                    && <Box component={Paper} elevation={2} p={2} mb={1} justifyContent="center">
                        <CircularProgressCentered size={10} rootStyle={{ margin: "3px", display: "inline" }} />
                        {t('Building Creatives, Please Wait...')}
                    </Box>}

                <CreativesList item={item} />

            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <h2>{t('Default Fallback Creative')}</h2>
                </Box>
                <Box mb={2}>

                </Box>
                <Box className={classes.fallbackDescription}>
                    <LCTypography>
                        This is the default image that will appear if all creatives are deleted, disabled,
                        or unable to run because of some limitation such as schedule, size, etc.
                    </LCTypography>
                </Box>
                <Box className={classes.fallbackDescription}>
                    <LCTypography>
                        If you are seeing this image in your account or on your campaign, this means that you need to
                        upload a new creative in order to replace this one.
                    </LCTypography>
                </Box>

                <Box>
                    <ResetDefaultCreativeButton accountId={item.account_id} />
                </Box>

            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
