import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, Chip, Typography, Card, CardContent, Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import VisaIcon from '../../components/icons/VisaIcon';
import { CardBrand } from '../../helpers/constants';
import MasterCardIcon from '../../components/icons/MasterCardIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCard, getCards, savePrimaryCard } from '../../actions/billing';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { logger } from '../../helpers/logger';
import { showError, showSuccess } from '../../actions/snackbar';
import { objectSelector } from '../../selectors/objects';
import { MoreVert } from '@material-ui/icons';
import AddCardButton from './AddCardButton';
import moment from 'moment';
import { ConfirmationDialog } from '../../components';

const useStyles = makeStyles(theme => {
    return {
        cardAvatar: {
            background: 'none'
        },
        error: {
            background: theme.palette.error.main,
            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
});

export const CardIconsComponentsMap = {
    '': <CreditCardIcon color="primary" fontSize="large" />,
    [CardBrand.visa]: <VisaIcon fontSize="large" />,
    [CardBrand.masterCard]: <MasterCardIcon fontSize="large" />
}

function PaymentMethods(props) {
    const { lcuid } = props;
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const billing = useSelector(state => objectSelector(state)(lcuid));
    const cards = useMemo(() => billing.cards, [billing])

    const canRemoveCards = useMemo(() => billing.cards.length > 1
        || (billing.cards.length === 1 && billing.options.can_remove_last_card), [billing]);

    const setPrimary = useCallback(card => dispatch(savePrimaryCard(card.id, lcuid))
        .then(() => dispatch(showSuccess(`Card ending with '${card.last4}' is now default`)))
        .catch(error => {
            dispatch(showError(`Error during saving default card`));
            logger.logError("Error during saving default card", { error, card });
        }), [lcuid]);

    const removeCard = useCallback(card => dispatch(deleteCard(card, lcuid))
        .then(() => dispatch(showSuccess(`Card ending with '${card.last4}' was removed`)))
        .catch(error => {
            dispatch(showError(`Error during removing the card`));
            logger.logError("Error during removing card", { error, card });
        }), [lcuid]);

    useEffect(() => {
        setLoading(true);
        dispatch(getCards(lcuid))
            .finally(() => setLoading(false));
    }, [])

    return (<>
        {loading && <CircularProgressCentered size={40} />}

        {!loading && cards.length === 0 &&
            <LCTypography>Please add a payment method</LCTypography>}

        {!loading && <Grid container spacing={1}>
            {cards.map(card => <Grid key={card.id} item xs={12} sm={4} md={3}>
                <CardComponent key={card.id} {...{ card, setPrimary, removeCard, canRemoveCards }} />
            </Grid>)}
            <Grid item xs={12} sm={4} md={3}>
                <AddCardButton
                    lcuid={lcuid}
                    style={{ width: '100%', height: '100%', minHeight: 122 }}
                    variant="text"
                />
            </Grid>
        </Grid>}
    </>);
}

function CardComponent(props) {
    const { card, setPrimary, removeCard, canRemoveCards } = props;
    const classes = useStyles();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [savingPrimary, setSavingPrimary] = useState(null);
    const [removing, setRemoving] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const isExpired = moment().set('year', card.exp_year)
        .set('month', card.exp_month - 1) // moment month starts from 0
        .isBefore(moment(), 'month');

    return <>
        <Card >
            <CardContent style={{ paddingBottom: 16 }}>
                <Box display="flex" alignItems="center">
                    <Typography>
                        {card.funding == 'credit'
                            ? "Credit card"
                            : "Debit card"}
                    </Typography>
                    {card.default
                        && <Chip
                            style={{ marginLeft: 8 }}
                            size="small"
                            color="secondary"
                            label={t('Default')} />}

                    && <IconButton size="small"
                        style={{ marginLeft: 'auto' }}
                        onClick={e => setAnchorEl(e.currentTarget)}
                    >
                        <MoreVert />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center">
                    <Avatar className={classes.cardAvatar} variant="square" >
                        {CardIconsComponentsMap[card.brand] || CardIconsComponentsMap['']}
                    </Avatar>
                    <Typography
                        style={{ marginLeft: 8, letterSpacing: '2px' }} >
                        **** **** **** {card.last4}
                    </Typography>
                </Box>
                {isExpired
                    ? <LCTypography color="error"
                        transProps={{
                            cardExpMonth: card.exp_month,
                            cardExpYear: card.exp_year
                        }}
                        variant="body2">
                        Expired at {{ cardExpMonth: card.exp_month }}/{{ cardExpYear: card.exp_year }}
                    </LCTypography>
                    : <LCTypography color="textSecondary"
                        transProps={{ cardExpMonth: card.exp_month, cardExpYear: card.exp_year }}
                        variant="body2">
                        Expires in {{ cardExpMonth: card.exp_month }}/{{ cardExpYear: card.exp_year }}
                    </LCTypography>}

            </CardContent>

        </Card>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            {!card.default
                && <MenuItem
                    disabled={savingPrimary}
                    onClick={() => {
                        setAnchorEl(null);
                        setSavingPrimary(true);
                        setPrimary(card)
                            .finally(() => setSavingPrimary(false))
                    }}>{t('Set as Default')}</MenuItem>}

            <MenuItem
                disabled={removing}
                title={canRemoveCards ? '' : 'You cannot remove the last card if you have pending invoices or active billing plans'}
                onClick={() => {
                    setIsConfirmOpen(true);
                    setAnchorEl(null);
                }}><Typography color='error'>Delete</Typography></MenuItem>
        </Menu>

        <ConfirmationDialog
            title={`Delete card **** ${card.last4}`}
            dialogProps={{
                open: isConfirmOpen,
                onClose: () => setIsConfirmOpen(false),
                keepMounted: false
            }}
            ConfirmButtonText={t('Delete')}
            ConfirmButtonProps={{
                disabled: removing || !canRemoveCards,
                variant: 'contained', className: classes.error
            }}
            handleCancel={() => setIsConfirmOpen(false)}
            handleConfirm={() => {
                setRemoving(true);
                return removeCard(card)
                    .finally(() => {
                        setRemoving(false);
                    })
            }}>
            {canRemoveCards ? `Are you sure you want to delete **** **** **** ` + card.last4 + `?` :
                `You cannot remove the last card in your account if you have pending invoices or active billing plans
                - Try adding a new card first, or please contact support using the Help center if you need assistance`
            }
        </ConfirmationDialog>
    </>
}

PaymentMethods.propTypes = {
    lcuid: PropTypes.string.isRequired
}

export default PaymentMethods;
