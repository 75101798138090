import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Share } from '@capacitor/share';
import { Typography } from '@material-ui/core';
import { getUser, hasAccount, isLoggedIn } from '../../selectors/user';
import { lucitApi } from '../../services/lucitApi';
import { PageTitle } from '../../components';
import LCTypography from '../../components/material/LCTypography';
import { hasIcon } from '../../selectors/account';
import { withRouter } from 'react-router';
import {
  Box, Divider, Grid
} from '@material-ui/core';
import { InventoryItemCreativeState, LuCoreModelClasses, RoutePaths } from '../../helpers/constants';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';
import { addAttachment } from '../../actions/creativeProofs';
import { showError, showSuccess } from '../../actions/snackbar';
import { getFullUrl } from '../../helpers/history';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import { makeStyles } from '@material-ui/core/styles';
import { AttachmentButton, Attachments } from '../../containers/attachments';
import { CommentWall, LikeButton, ReactionsBar } from '../../containers/reactions';
import { IonCard, IonButton, IonIcon, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import { shareOutline, createOutline } from 'ionicons/icons';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import ProofBillboardMap from '../../containers/proofs/ProofBillboardMap';
import ProofXRCreativesList from '../../containers/proofs/ProofXRCreativesList';
import { getUserInfo } from '../../actions/user';

import { toggleSignInDialog } from '../../actions/layout';

const useStyles = makeStyles(theme => ({
  proofHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  proofHeaderTitle: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  proofPageContainer: {
    margin: 'auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    maxWidth: '1400px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px !important',
      maxWidth: '100% !important',
    },
  },
  proofTitle: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
  proofSubTitle: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8pt',
    },
  },
  logoContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  proofCommentsContent: {
    marginBottom: '150px',
    width: '100%',
  },
}));

const CreativeProof = ({
  lcuid, data, user, isLoggedIn, share, addAttachment, refreshUserData, openSignInDialog, isStillCheckingForNewProofs
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const inventoryItem = useMemo(() => data
    ? {
      ...data.inventory_item,
      creatives_to_board_images: data.creatives_to_board_images
    }
    : null, [data]);

  useEffect(() => {
    if (data) {
      lucitApi.analytics.trackProofView(isLoggedIn, data.creative_proof.lcuid);
    }
  }, [Boolean(data)])

  useEffect(() => {
    //Reload the user account list
    if (isLoggedIn)
      refreshUserData();
  }, [lcuid])

  if (!inventoryItem) {
    return null;
  }

  const operator = data.agency;
  const account = inventoryItem.account;

  return <>
    <Box className={classes.proofPageContainer}>
      <PageTitle title={`Creative Proof : ` + (inventoryItem.title)} />

      <Grid container>

        <IonCardContent className={classes.proofHeaderContainer}>
          <div className={classes.proofHeaderTitle}>
            <Box className={classes.logoContainer}>
              {hasIcon(account)
                ? <div className={classes.clientLogo}>
                  <img style={{ maxHeight: "50px" }}
                    src={account.options.primary_image_public_url}
                  />
                </div>
                : hasIcon(operator)
                && <div className={classes.clientLogo}>
                  <img style={{ maxHeight: "50px" }}
                    src={operator.options.primary_image_public_url}
                  />
                </div>}
            </Box>
            <Box>
              <IonCardSubtitle className={classes.proofSubTitle}>
                {operator && operator.name}
              </IonCardSubtitle>
              <IonCardTitle className={classes.proofTitle}>
                {account && account.name} | {inventoryItem ? inventoryItem.title : ''}
              </IonCardTitle>
            </Box>
          </div>

          {inventoryItem
            &&
            <div className={classes.proofHeaderCTAs}>
              <IonButton
                color="secondary"
                size="small"
                onClick={() => share(
                  data.creative_proof.lcuid,
                  inventoryItem.title,
                  "Check out this creative proof"
                )}
                fill="outline">
                <IonIcon icon={shareOutline} slot="start" />
                {t('Share')}
              </IonButton>
              {<>

                <AttachmentButton
                  buttonCanBePressed={isLoggedIn && account && hasAccount(user)(account.lcuid)}
                  attach={(file) => addAttachment(lcuid, file.lcuid)}
                />
                {isLoggedIn ?
                  <IonButton
                    fill="outline"
                    size="small"
                    href={`${RoutePaths.inventory}/${inventoryItem.id}`}
                  >
                    <IonIcon icon={createOutline} slot="start" />
                    {t('Edit')}
                  </IonButton>
                  :
                  <IonButton
                    fill="outline"
                    size="small"
                    onClick={() => openSignInDialog()}
                  >
                    <IonIcon icon={createOutline} slot="start" />
                    {t('Sign In to Edit')}
                  </IonButton>
                }

              </>}
            </div>}
        </IonCardContent>

        <Grid item sm={12} md={12} className={classes.proofMainContent}>
          <ProofBillboardMap proof={data} />

          <LCTypography variant="h6" style={{ marginInline: '10px', marginTop: '10px' }}>Locations</LCTypography>

          {inventoryItem.creative_state === InventoryItemCreativeState.building
            ? <Box mb={8}>
              <CircularProgressCentered title={t("Building creatives...")} />
            </Box>
            : <>
              {isStillCheckingForNewProofs ?
                < CircularProgressCentered title={t("Building Lucit XR Previews...")} /> : null
              }
              <ProofXRCreativesList proof={data} isStillCheckingForNewProofs={isStillCheckingForNewProofs} />
            </>}

          {operator
            && operator.options.proof_legal_text
            &&
            <>
              <Divider />
              <LCTypography variant="h6" style={{ marginInline: '10px', marginTop: '10px' }}>Legal Disclaimer</LCTypography>
              <Divider />
              <IonCard>
                <IonCardContent>
                  <Box>
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {operator.options.proof_legal_text}
                    </Typography>
                  </Box>
                </IonCardContent>
              </IonCard>
            </>}

          <Attachments lcuid={lcuid} objectClass={LuCoreModelClasses.creativeProof} />
        </Grid>

        <Grid item sm={12} md={12} className={classes.proofCommentsContent}>
          <Box>
            {/* Here is how we can add comments/reactions for this object */}
            <LikeButton lcuid={lcuid} />
            <ReactionsBar lcuid={lcuid} />
            <CommentWall lcuid={lcuid} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
};

const mapStateToProps = state => {
  return {
    user: getUser(state),
    isLoggedIn: isLoggedIn(state),

    proofSelector: lcuid => state.creativeProofs.byId[lcuid],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAttachment: (lcuid, attachable_lcuid) => dispatch(addAttachment(lcuid, attachable_lcuid)),

    showSuccess: message => dispatch(showSuccess(message)),
    showError: message => dispatch(showError(message)),

    refreshUserData: () => dispatch(getUserInfo()),

    openSignInDialog: () => dispatch(toggleSignInDialog(true)),

    share: (pathname, title, text) => {
      return Share.share({
        title,
        text: text,
        url: getFullUrl(pathname),
        dialogTitle: i18next.t('Share Proof Page Link'),
      });
    },

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(
  withConditionalLayout(CreativeProof, { disablePaper: true })
));
