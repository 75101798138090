import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { CopyButton } from '../../components';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

const TokenInfoDialog = (props) => {
    const { token, secret, open, handleClose } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const copyText = () => {
        return secret ? t('API Token : \n\n{{token}} \n\nAPI Secret : \n\n{{secret}}\n\n', { token: token, secret: secret }) : token;
    }

    return (
        <GenericDialog
            title={t('Token')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                <LCTypography variant="body2">

                    {secret ? <>Please copy and store your secret secret securely.
                        Lucit <b>does not store the plain-text value of secrets</b>, if you lose this secret, it must be reset</>
                        : <>Your new public token</>}
                </LCTypography>

                {token &&
                    <Box mt={2}>
                        <LCTypography variant="subtitle1">API Token</LCTypography>
                        <LCTypography component="div"
                            variant='overline'
                            style={{ margin: "8px 0", whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '1.8' }}>
                            <span style={{ textTransform: "none" }}>{token}</span>
                        </LCTypography>
                    </Box>}
                {secret && <Box mt={2}>
                    <LCTypography variant="subtitle1">API Secret</LCTypography>
                    <LCTypography component="div"
                        variant='overline'
                        style={{ margin: "8px 0", whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineHeight: '1.8' }}>
                        <span style={{ textTransform: "none" }}>{secret}</span>
                    </LCTypography>
                </Box>}
            </Box >}

            ActionsComponent={<>
                <CopyButton
                    title={t('Copy Token') + (secret ? t(' & Secret') : '')}
                    size='small'
                    color="secondary"
                    variant="contained"
                    text={copyText()}
                />
            </>}
        />
    )
}

export default TokenInfoDialog;
