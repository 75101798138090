import { useTheme, useMediaQuery, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { acceptEula, logout } from '../../actions/user';
import { RoutePaths } from '../../helpers/constants';
import { isEulaAccepted, isLoggedIn } from '../../selectors/user';
import { ConfirmationDialog } from '../modals';
import EulaText from './EulaText';
import { useTranslation } from 'react-i18next';

const Eula = (props) => {
    const { isEulaAccepted, isLoggedIn, acceptEula, declineEula } = props;

    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [eulaAccepted, setEulaAccepted] = useState(false);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    useEffect(() => {
        if (isLoggedIn && !isEulaAccepted) {
            setOpen(true);
        }
    }, [isLoggedIn, isEulaAccepted])

    return (
        <>
            <ConfirmationDialog
                title={t('Mobile App End User License Agreement')}
                handleConfirm={() => acceptEula().then(() => setOpen(false))}
                handleCancel={() => setConfirmOpen(true)}
                dialogProps={{
                    open,
                    onClose: () => setOpen(false),
                    maxWidth: "lg",
                    hideCloseButton: true,
                    disableEscapeKeyDown: true,
                    disableBackdropClick: true,
                    fullScreen: isXs
                }}
                CancelButtonText={t('Decline')}
                ConfirmButtonText={t('Accept')}
                ConfirmButtonProps={{
                    variant: 'contained',
                    disabled: !eulaAccepted
                }}
                DialogContentTextProps={{
                    component: 'div'
                }}
                Actions={<Box ml={2} mr="auto">
                    <FormControlLabel
                        control={<Checkbox
                            value={eulaAccepted}
                            onChange={() => setEulaAccepted(!eulaAccepted)}
                        />}
                        label={t('I accept')}
                    />
                </Box>}
            >
                <EulaText />
            </ConfirmationDialog>

            <ConfirmationDialog
                title={t('Are you sure?')}
                handleConfirm={() => {
                    declineEula();
                    setOpen(false);
                    setConfirmOpen(false);
                }}
                handleCancel={() => setConfirmOpen(false)}
                dialogProps={{
                    open: confirmOpen,
                    onClose: () => setConfirmOpen(false),
                }}
                CancelButtonProps={{
                    variant: 'contained'
                }}
            >
                {t('After declining ')}<b>{t('Mobile App End User License Agreement')}</b>{t(' application will be closed')}
            </ConfirmationDialog>
        </>
    )
}

Eula.propTypes = {
}

const mapStateToProps = state => {
    return {
        isEulaAccepted: isEulaAccepted(state),
        isLoggedIn: isLoggedIn(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        acceptEula: () => dispatch(acceptEula()),

        declineEula: () => {
            dispatch(logout())

            ownProps.history.push({ pathname: RoutePaths.login });
        }
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        Eula
    )
);
