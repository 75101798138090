import React from 'react';
import { useSelector } from 'react-redux';
import AddRowsButton from './AddRowsButton';
import { useTranslation } from 'react-i18next';

const AddScreensButton = ({ screens, onChange }) => {
    const { t } = useTranslation();
    const screensOptions = useSelector(state => state.lightning.digitalBoards)

    return <AddRowsButton
        options={screensOptions}
        value={screens}
        onChange={onChange}
        buttonTitle={t('Add Screens')}
        noOptionsText={t('No screens found for your search')}
    />
}

export { AddScreensButton };
