import { createSelector } from "reselect";
import { RegisterTypes } from "../reducers/register";

const getRegisterType = state => state.register.registerType;

export const isEmailRegisterType = createSelector(
    [getRegisterType],
    (type) => type === RegisterTypes.EMAIL);

export const isPhoneRegisterType = createSelector(
    [getRegisterType],
    (type) => type === RegisterTypes.PHONE);