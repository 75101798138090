import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { syncLayerSettingsToHtml } from '../../actions/designer';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { Designer } from '../../helpers/constants';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ImageUploadDialog from '../../components/modals/ImageUploadDialog';
import { lucitApi } from '../../services/lucitApi'
import { deriveDropAspectRatioForIds, getDataSourceFields } from '../../selectors/designer'
import { DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';
import { getBestDefaultPlaceholderForLayerId } from '../../selectors/designerLayers';
import LucitFormFields from '../../components/forms/LucitFormFields';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const DesignerLayerSettingsDialog = (props) => {
    const { open, handleClose, layerSettings, syncLayerSettingsToHtml, deriveDropAspectRatioForIds } = props;

    const [placeholderText, setPlaceholderText] = useState(layerSettings.placeholder);
    const [fieldName, setFieldName] = useState(layerSettings.fieldName);
    const [objectCode, setObjectCode] = useState(layerSettings.objectCode);
    const [elementSettingValues, setElementSettingValues] = useState({});

    const [isUploadOpened, setIsUploadOpened] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const state = useSelector(state => state);
    const macroFields = useSelector(getDataSourceFields);

    if (!layerSettings)
        return null;

    const isDataSourceElement = layerSettings.objectClass === Designer.ObjectClasses.DataSource;

    const isImage = layerSettings.objectType === Designer.ObjectTypes.Image;
    const isText = layerSettings.objectType === Designer.ObjectTypes.Text;

    const isBackgroundLayer = layerSettings.id === Designer.BackgroundLayerId

    let elementSettingsObject = {}

    try {
        elementSettingsObject = JSON.parse(layerSettings.elementSettings);
    }
    catch (e) {
        elementSettingsObject = {}
    }

    const hasSettings = elementSettingsObject?.fields?.length > 0;

    const elementSettingsFields = elementSettingsObject?.fields ?? [];

    useEffect(() => {

        let newElementSettingValues = {};
        try {
            newElementSettingValues = JSON.parse(layerSettings.elementSettingValues);
            setElementSettingValues(newElementSettingValues);
        }
        catch (e) {
            setElementSettingValues({});
        }

    }, [layerSettings])

    const getAspectRatioForLayer = () => {
        return deriveDropAspectRatioForIds(layerSettings.objectCode)
    }

    const handleImageDelete = () => {
        return new Promise((resolve) => {
            setIsUploadOpened(false);
            setPlaceholderText(getBestDefaultPlaceholderForLayerId(state, layerSettings.objectCode));
            resolve();
        });
    }

    const handleImageUpload = (imageBlob) => {

        return lucitApi.uploads.uploadImage(imageBlob).then((image) => {
            setPlaceholderText(image.options.public_url);
            setIsUploadOpened(false);
        })

    }

    const saveChanges = () => {

        const newLayerSettings = {
            ...layerSettings,
            placeholder: placeholderText,
            fieldName: fieldName,
            objectCode: objectCode,
            elementSettingValues: JSON.stringify(elementSettingValues)
        }

        syncLayerSettingsToHtml(layerSettings.id, newLayerSettings);

        handleClose();
    }

    return (<>
        <GenericDialog
            title={t('Object Settings')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>

                <Grid container spacing={4}>

                    {isBackgroundLayer && <>
                        <Grid item xs={8}>
                            <LCTypography variant="body2">
                                This is the <strong>background layer</strong> for your template.
                                It is recommended to keep this layer in place, however, you can delete it
                                if you wish
                            </LCTypography>
                        </Grid>
                    </>
                    }

                    {isDataSourceElement && <>
                        <Grid item xs={8}>
                            <LCTypography variant="body2">
                                This is a <strong>data source connected element</strong>.
                                The value is derived from the inventory item that this template is attached to
                            </LCTypography>
                        </Grid>
                        <Grid item xs={4}>

                            <Box p={2}>
                                Data Source : <br /><Select
                                    labelId="select-data-source"
                                    id="select-data-source"
                                    value={objectCode ?? ""}
                                    size="small"
                                    label={t('Data Source')}
                                    onChange={(e) => setObjectCode(e.target.value)}
                                >
                                    {macroFields.map((dataSourceElement, index) => {

                                        if (dataSourceElement.type !== layerSettings.objectType)
                                            return null;

                                        return <MenuItem key={index} value={dataSourceElement.id}>{dataSourceElement.name}</MenuItem>
                                    })}
                                </Select>
                            </Box>
                        </Grid>
                    </>}
                    <Grid item xs={8}>
                        <LCTypography variant="body2">
                            The <strong>Field Name</strong> is the label that will appear when a user posts an ad using this template.
                            This should be thought of as a short description of the type of information this field is meant to display
                        </LCTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box p={2}>

                            <TextField
                                label={t('Field Name')}
                                id="field-name"
                                autoFocus
                                fullWidth
                                autoComplete='off'
                                value={fieldName}
                                onChange={(e) => setFieldName(e.target.value)}
                            />
                        </Box>
                    </Grid>

                    {isDataSourceElement && <>

                        <Grid item xs={8}>
                            {isText && <LCTypography variant="body2">
                                The <strong>Placeholder</strong> will appear in the input field and on
                                the template in place of the actual text BEFORE the
                                user enters in their input.   This placeholder should be thought of as
                                an example of the type of information the user should enter.

                                For instance, if this field is a price, the placeholder should be a number
                                like <i>1234</i> if it is a title it could be like <i>My Awesome Title</i>
                                <br />
                                This placeholder will also be used in the preview image for this template
                            </LCTypography>}

                            {isImage && <LCTypography variant="body2">
                                The <strong>Placeholder Image</strong> will appear on the template before the user
                                selects an image to upload onto the template.<br />
                                This image will also be used in the
                                preview image for this template
                            </LCTypography>}

                        </Grid>

                        <Grid item xs={4}>
                            <Box p={2}>

                                {isText && <TextField
                                    label={t('Placeholder helper text')}
                                    id="placeholder-text"
                                    fullWidth
                                    autoComplete='off'
                                    value={placeholderText}
                                    onChange={(e) => setPlaceholderText(e.target.value)}
                                />}

                                {isImage && <>

                                    {placeholderText && <img src={placeholderText} style={{ width: '100%' }} />}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setIsUploadOpened(true)}
                                    >
                                        {t('Edit Placeholder Image')}
                                    </Button>

                                    {isUploadOpened
                                        && <ImageUploadDialog
                                            title={t('Edit Placeholder Image')}
                                            src={placeholderText}
                                            open={isUploadOpened}
                                            aspect={getAspectRatioForLayer()}
                                            askForCameraOnAndroid={true}
                                            handleDelete={() => handleImageDelete()}
                                            handleSave={image => handleImageUpload(image)}
                                            handleClose={() => setIsUploadOpened(false)}
                                        />}

                                </>}

                            </Box>
                        </Grid>

                    </>}

                    {hasSettings && <Grid item xs={12}>

                        <Box mb={3}>
                            <LCTypography variant="h6">
                                Custom Element Settings
                            </LCTypography>
                        </Box>

                        <LucitFormFields
                            fields={elementSettingsFields}
                            feedParamValues={elementSettingValues}
                            onChange={(key, value) => {
                                setElementSettingValues(currentSettingValues => {
                                    const newSettingValues = { ...currentSettingValues }
                                    newSettingValues[key] = value
                                    return newSettingValues
                                })
                            }}
                        />

                    </Grid>
                    }

                </Grid>

            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <Button onClick={saveChanges}
                    color="secondary"
                    variant="contained"
                >
                    {t('Save Changes')}
                </Button>
            </>}
        />
    </>)
}

const mapStateToProps = (state) => {
    return {
        deriveDropAspectRatioForIds: (objectCode, bgRemovedObjectCode) =>
            deriveDropAspectRatioForIds(state, objectCode, bgRemovedObjectCode)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncLayerSettingsToHtml: (id, layerSettings) => {
            dispatch(syncLayerSettingsToHtml(id, layerSettings));
            dispatch({ type: DESIGNER_PUSH_HISTORY });
        }
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DesignerLayerSettingsDialog)
)
