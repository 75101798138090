import { Box, Grid, Link, Divider } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { updateFeedParamValues, updateFeedSettingsOverrides, runFeed, updateFeedNameAndSchedule } from '../../actions/feeds';
import LucitFormFields from '../../components/forms/LucitFormFields';
import ButtonLoader from '../../components/material/ButtonLoader';
import { lucitApi } from '../../services/lucitApi';
import { Alert } from '@material-ui/lab';
import FeedScheduleEdit from './FeedScheduleEdit';
import LCTypography from '../../components/material/LCTypography';
import { providesFeedSettings, feedIsKeyValueData } from '../../selectors/feeds';
import {
    Radio, RadioGroup, FormControlLabel, TextField, Switch, List, ListItem, ListItemText, ListItemSecondaryAction
} from '@material-ui/core';
import { debounce } from 'lodash';
import ItemFilterEditor from '../inventory/itemFilter/ItemFilterEditor';

const FeedFilter = ({ feed, feedSettingsOverrides, setFeedSettingsOverrides }) => {

    const [additionalFieldsAndFunctions, setAdditionalFieldsAndFunctions] = useState([]);
    const [readerFields, setReaderFields] = useState([]);
    const [readerFieldsLoaded, setReaderFieldsLoaded] = useState(false);

    const toNiceName = (id) => {
        // Replace underscores and hyphens with spaces
        let niceName = id.replace(/[_-]/g, ' ');

        // Capitalize the first letter of each word
        niceName = niceName.replace(/\b\w/g, (char) => char.toUpperCase());

        return niceName;
    }

    const onChangeFilter = (newValues) => {
        setFeedSettingsOverrides({
            ...feedSettingsOverrides,
            converter: {
                ...feedSettingsOverrides.converter,
                filters: {
                    filter_reader_fields: newValues.filter_fields,
                    filter_reader_functions: newValues.filter_functions
                }
            }
        });
    };

    useEffect(() => {

        if (!feed?.id) return;
        if (!feed.last_run_id) return;

        lucitApi.feeds.getFeedRunReaderFields(feed.last_run_id).then((fields) => {
            setReaderFields(fields);
        });
    }, [feed?.id]);

    useEffect(() => {
        if (!readerFields.length) return;

        const fields = readerFields.map((field) => {
            return {
                id: field.name,
                label: toNiceName(field.name),
                type: 'field',
                dataType: 'string',
                group: feed.name
            }
        });

        setAdditionalFieldsAndFunctions(fields);
        setReaderFieldsLoaded(true);
    }, [readerFields]);

    const filterFields = Array.isArray(feedSettingsOverrides.converter?.filters?.filter_reader_fields) ?
        feedSettingsOverrides.converter?.filters?.filter_reader_fields : [];

    if (!readerFieldsLoaded)
        return <></>;

    return (
        <Box mb={2} mt={4}>
            <LCTypography variant="h5">Custom Filter</LCTypography>
            <Box mt={2} pl={2}>
                <Box style={{ maxWidth: '900px', width: '90%' }}>
                    <LCTypography>Create a custom Filter for the rows coming in from this data source</LCTypography>
                    <Box mt={2}>

                        <ItemFilterEditor
                            filterFields={filterFields}
                            filterFunctions={[]}
                            onChange={(newValues) => onChangeFilter(newValues)}
                            additionalFieldsAndFunctions={additionalFieldsAndFunctions}
                            includeDefaultFieldsAndFunctions={false}
                        />

                    </Box>
                </Box>
            </Box>
        </Box>
    );

}

const FeedPhotoItemsSettings = ({ feedProviderSettings, feedSettingsOverrides, setFeedSettingsOverrides }) => {

    const { t } = useTranslation();

    const getItemPhotoSettingFromSettings = () => {

        //First check the overrides
        if (feedSettingsOverrides?.processor?.skip_items_with_no_photos) return 'skip';

        if ((feedSettingsOverrides?.converter?.set_items_with_missing_photo_to_url)) return 'set-to-url';

        if (feedSettingsOverrides?.converter?.set_items_with_missing_photo_to_account_logo) return 'set-to-account-logo';

        if (feedSettingsOverrides?.converter?.set_items_with_missing_photo_to_feed_provider_logo) return 'set-to-feed-privider-logo';

        //Check and see if anything was previously set
        if (feedProviderSettings?.processor?.skip_items_with_no_photos) return 'skip';

        if (feedProviderSettings?.converter?.set_items_with_missing_photo_to_url) return 'set-to-url';

        if (feedProviderSettings?.converter?.set_items_with_missing_photo_to_account_logo) return 'set-to-account-logo';

        if (feedProviderSettings?.converter?.set_items_with_missing_photo_to_feed_provider_logo) return 'set-to-feed-privider-logo';

        //Nothing specific, so, no-action
        return 'no-action';

    }

    const getMissingPhotoToUrl = () => {

        if (feedSettingsOverrides?.converter?.set_items_with_missing_photo_to_url) {
            return feedSettingsOverrides?.converter?.set_items_with_missing_photo_to_url;
        }

        if (feedProviderSettings?.converter?.set_items_with_missing_photo_to_url) {
            return feedProviderSettings?.converter?.set_items_with_missing_photo_to_url;
        }

        return '';
    }

    const [itemPhotoSetting, setItemPhotoSetting] = useState(getItemPhotoSettingFromSettings());

    const handlePhotoItemSetting = (event) => {

        setItemPhotoSetting(event.target.value);

        setFeedSettingsOverrides({
            ...feedSettingsOverrides,
            processor: {
                ...feedSettingsOverrides.processor,
                skip_items_with_no_photos: event.target.value === 'skip' ? true : false
            },
            converter: {
                ...feedSettingsOverrides.converter,
                set_items_with_missing_photo_to_account_logo: event.target.value === 'set-to-account-logo' ? true : false,
                set_items_with_missing_photo_to_feed_provider_logo: event.target.value === 'set-to-feed-privider-logo' ? true : false
            }
        });
    };

    return (
        <Box mb={2} mt={4}>
            <LCTypography variant="h5">Images</LCTypography>
            <Box mt={2} pl={2}>
                <Box style={{ maxWidth: '600px', width: '90%' }}>
                    <LCTypography>If an item has no photos or images : </LCTypography>

                    {itemPhotoSetting === 'set-to-url' ? (
                        <Box mt={2} style={{ maxWidth: "400px" }}>
                            <LCTypography variant="body2">Item will be assigned the following image :</LCTypography>
                            <img src={getMissingPhotoToUrl()} />
                        </Box>

                    ) : (

                        <Box mt={2}>
                            <RadioGroup value={itemPhotoSetting} onChange={handlePhotoItemSetting}>
                                <FormControlLabel value="no-action" control={<Radio />} label={t('Allow the item')} />
                                <FormControlLabel value="skip" control={<Radio />} label={t('Skip the item')} />
                                <FormControlLabel
                                    value="set-to-account-logo"
                                    control={<Radio />}
                                    label={t('Set the image to your account profile image')}
                                />
                                <FormControlLabel
                                    value="set-to-feed-privider-logo"
                                    control={<Radio />}
                                    label={t("Set the image to this app's logo")}
                                />
                            </RadioGroup>
                        </Box>

                    )}

                </Box>
            </Box>
        </Box>
    );
}

const FeedSkipItemsSettings = ({ feedSettingsOverrides, setFeedSettingsOverrides }) => {

    const { t } = useTranslation();

    const [skipItemsWithNoPrice, setSkipItemsWithNoPrice] = useState(feedSettingsOverrides?.processor?.skip_items_with_no_price || false);

    const handleSkipItemsWithNoPriceChange = (event) => {

        setSkipItemsWithNoPrice(event.target.value === 'true' ? true : false);

        setFeedSettingsOverrides({
            ...feedSettingsOverrides,
            processor: {
                ...feedSettingsOverrides.processor,
                skip_items_with_no_price: event.target.value === 'true' ? true : false
            }
        });
    };

    return (
        <Box mb={2} mt={4}>
            <LCTypography variant="h5">Prices</LCTypography>
            <Box mt={2} pl={2}>
                <Box style={{ maxWidth: '600px', width: '90%' }}>
                    <LCTypography>When an item has no price, the price is empty, or the price is zero : </LCTypography>
                    <Box mt={2}>
                        <RadioGroup value={skipItemsWithNoPrice ? 'true' : 'false'} onChange={handleSkipItemsWithNoPriceChange}>
                            <FormControlLabel value="false" control={<Radio />} label={t("Allow the item")} />
                            <FormControlLabel value="true" control={<Radio />} label={t("Skip the item")} />
                        </RadioGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const FeedAutoAddToCampaigns = ({ feedSettingsOverrides, setFeedSettingsOverrides }) => {

    const { t } = useTranslation();

    const [autoAddToCampaigns, setAutoAddToCampaigns] = useState(
        feedSettingsOverrides?.processor?.auto_add_items_to_campaigns?.length ? 'auto' : 'manual'
    );

    const exports = useSelector((state) => state.exports.data);

    const handleAutoAddToCampaignsChange = (event) => {
        setAutoAddToCampaigns(event.target.value);

        if (event.target.value === 'manual') {
            setFeedSettingsOverrides({
                ...feedSettingsOverrides,
                processor: {
                    ...feedSettingsOverrides.processor,
                    auto_add_items_to_campaigns: []
                }
            })
        }
        else {
            setFeedSettingsOverrides({
                ...feedSettingsOverrides,
                processor: {
                    ...feedSettingsOverrides.processor,
                    auto_add_items_to_campaigns: exports.map((exp) => exp.lcuid)
                }
            })
        }
    };

    const handleExportChange = (lcuid) => {
        const selectedExports = feedSettingsOverrides?.processor?.auto_add_items_to_campaigns || [];

        if (selectedExports.includes(lcuid)) {
            setFeedSettingsOverrides({
                ...feedSettingsOverrides,
                processor: {
                    ...feedSettingsOverrides.processor,
                    auto_add_items_to_campaigns: selectedExports.filter((exp) => exp !== lcuid)
                }
            })
            if (selectedExports.filter((exp) => exp !== lcuid).length == 0) {
                setAutoAddToCampaigns('manual');
            }
        } else {
            setFeedSettingsOverrides({
                ...feedSettingsOverrides,
                processor: {
                    ...feedSettingsOverrides.processor,
                    auto_add_items_to_campaigns: [...selectedExports, lcuid]
                }
            })
            setAutoAddToCampaigns('auto')
        }
    };

    const exportIsSelected = (lcuid) => {
        return feedSettingsOverrides.processor?.auto_add_items_to_campaigns?.includes(lcuid);
    };

    return (
        <Box mb={2} mt={4}>
            <LCTypography variant="h5">Campaigns</LCTypography>
            <Box mt={2} pl={2}>
                <Box style={{ maxWidth: '600px', width: '90%' }}>
                    <LCTypography>Control how items from this data source are automatically posted to your campaigns</LCTypography>
                    <Box mt={2}>
                        <RadioGroup value={autoAddToCampaigns} onChange={handleAutoAddToCampaignsChange}>
                            <FormControlLabel
                                value="manual"
                                control={<Radio />}
                                label={<><b>{t('Manual ')}</b>{t('I will add them manually on my campaigns page')}</>} />
                            <FormControlLabel
                                value="auto"
                                control={<Radio />}
                                label={<><b>{t('Automatic ')}</b>{t('Items will be automatically posted to the following campaigns : ')}</>}
                            />
                        </RadioGroup>
                        <List>
                            {exports.map((exp) => (
                                <ListItem key={exp.lcuid}>
                                    <ListItemText
                                        style={{ cursor: 'pointer', color: exportIsSelected(exp.lcuid) ? 'inherit' : 'grey' }}
                                        primary={exp.name}
                                        onClick={() => handleExportChange(exp.lcuid)}
                                    />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            checked={exportIsSelected(exp.lcuid)}
                                            onChange={() => handleExportChange(exp.lcuid)}
                                            color="secondary"
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

}

const FeedLimitItems = ({ feedSettingsOverrides, setFeedSettingsOverrides }) => {
    const [limitOption, setLimitOption] = useState(feedSettingsOverrides?.processor?.item_limit ? 'limited' : 'no-limit');
    const [limitValue, setLimitValue] = useState(feedSettingsOverrides?.processor?.item_limit || 0);
    const { t } = useTranslation();

    //Note that fail_on_zero_rows defaults to true in FeedProcessor.php
    //So, if it is missing here, it will be true
    const [failOnZeroRows, setFailOnZeroRows] = useState(feedSettingsOverrides?.processor?.fail_on_zero_rows ?? true);

    const handleLimitChange = (event) => {
        setLimitOption(event.target.value);
        if (event.target.value === 'no-limit') {
            setLimitValue(0);
        }
        setFeedSettingsOverrides({
            ...feedSettingsOverrides,
            processor: {
                ...feedSettingsOverrides.processor,
                item_limit: limitValue
            }
        });
    };

    const handleLimitValueChange = (event) => {
        setLimitValue(event.target.value);
        debouncedSetFeedSettingsOverrides(event.target.value);
    };

    const debouncedSetFeedSettingsOverrides = useCallback(
        debounce((value) => {
            setFeedSettingsOverrides({
                ...feedSettingsOverrides,
                processor: {
                    ...feedSettingsOverrides.processor,
                    item_limit: value
                }
            });
        }, 300),
        [feedSettingsOverrides, setFeedSettingsOverrides]
    );

    const handleFailOnZeroRowsChange = (event) => {

        setFailOnZeroRows(event.target.value === 'true' ? true : false);
        setFeedSettingsOverrides({
            ...feedSettingsOverrides,
            processor: {
                ...feedSettingsOverrides.processor,
                fail_on_zero_rows: event.target.value === 'true' ? true : false
            }
        });

    }

    return (
        <Box mb={2} mt={4}>
            <LCTypography variant="h5">Limits</LCTypography>
            <Box mt={2} pl={2}>
                <Box style={{ maxWidth: '600px', width: '90%' }}>
                    <LCTypography>Control the maximum number of items this data source can import</LCTypography>
                    <Box mt={2}>
                        <RadioGroup value={limitOption} onChange={handleLimitChange}>
                            <FormControlLabel value="no-limit" control={<Radio />} label={t("No Limit")} />
                            <FormControlLabel value="limited" control={<Radio />} label={t("Limited")} />
                        </RadioGroup>
                        {limitOption === 'limited' && (
                            <TextField
                                label={t("Enter limit")}
                                value={limitValue}
                                onChange={handleLimitValueChange}
                                type="number"
                                inputProps={{ min: 1 }}
                                fullWidth
                                margin="normal"
                            />
                        )}
                    </Box>

                    <Box mt={2}>
                        <LCTypography>If this data source produces zero results (it is empty) : </LCTypography>
                        <Box mt={2}>
                            <RadioGroup value={failOnZeroRows ? 'true' : 'false'} onChange={handleFailOnZeroRowsChange}>
                                <FormControlLabel value="true" control={<Radio />} label={t("Stop processing and do nothing")} />
                                <FormControlLabel value="false" control={<Radio />}
                                    label={t("Continue processing and delete all old items")} />
                            </RadioGroup>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const FeedTestError = ({ error, expectedExceptions }) => {

    const basicError = error.response && error.response.data && error.response.data.message

    const [showDetailedError, setShowDetailedError] = useState(false)

    const { t } = useTranslation()

    const getBestMessage = () => {

        if (!expectedExceptions) {
            return basicError
        }

        //Here, we check each expectedException to see if it matches the error
        //Any fields specified, must match
        const matchingException = expectedExceptions.find((expectedException) => {

            let matches = true;

            if (expectedException.code) {
                matches = matches && (expectedException.code === error.response.data.code);
            }

            if (expectedException.http_code) {
                matches = matches && (expectedException.http_code === error.response.data.http_code);
            }

            if (expectedException["data.error_code"]) {
                matches = matches && (expectedException["data.error_code"] === error.response.data?.data?.code);
            }

            if (expectedException["data.lucore_error_code"]) {
                matches = matches && (expectedException["data.lucore_error_code"] === error.response.data?.data?.lucore_error_code);
            }

            if (expectedException["data.exception_class"]) {
                matches = matches && (expectedException["data.exception_class"] === error.response.data?.data?.exception_class);
            }

            if (expectedException["data.error_code"]) {
                matches = matches && expectedException["data.error_code"] === error.response.data?.data?.error_code;
            }

            return matches

        })

        return matchingException ? matchingException.message : basicError
    }

    return <Box style={{ maxWidth: "600px", width: "100%" }}>
        <Alert
            severity="error"
        >
            {getBestMessage()}

            <Link
                style={{ cursor: "pointer", marginLeft: "10px" }}
                color="secondary"
                onClick={() => {
                    setShowDetailedError(!showDetailedError)
                }}
            >
                {t('View detailed error information')}
            </Link>

        </Alert>

        <Box>
            {showDetailedError && <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(error.response.data, null, 2)}</pre>}
        </Box>
    </Box>
}

function FeedSettings({ feed, updateFeedParamValues, updateFeedSettingsOverrides, runFeed, updateFeedNameAndSchedule, switchToTab }) {

    const [feedParamValues, setFeedParamValues] = useState(
        (!feed
            || feed.feed_param_values === undefined
            || feed.feed_param_values === null
            || Array.isArray(feed.feed_param_values)
        ) ? {} : feed.feed_param_values
    );

    const [feedSettingsOverrides, setFeedSettingsOverrides] = useState(
        (!feed
            || feed.feed_settings_overrides === undefined
            || feed.feed_settings_overrides === null
            || Array.isArray(feed.feed_settings_overrides)
        ) ? {} : feed.feed_settings_overrides
    );

    const expectedParamFields = feed.inventoryFeedProvider?.feed_params?.fields ?? []
    const expectedExceptions = feed.inventoryFeedProvider?.feed_params?.exceptions ?? []

    const [dirty, setDirty] = useState(false)
    const [savingParams, setSavingParams] = useState(false)
    const [savingOverrides, setSavingOverrides] = useState(false)
    const [runningTest, setRunningTest] = useState(false)
    const [error, setError] = useState(null)
    const [testResults, setTestResults] = useState(null)

    const { t } = useTranslation();

    const fieldTypesThatDoNotRequireInput = ["header", "divider", "space"]

    const fieldTypeRequiresInput = (fieldType) => !fieldTypesThatDoNotRequireInput.includes(fieldType)

    const feedRequiresInput = expectedParamFields
        && expectedParamFields.length > 0
        && expectedParamFields.reduce((acc, field) => {
            return acc || fieldTypeRequiresInput(field.type)
        }, false)

    useEffect(() => {
        if (feed && feed.feed_param_values !== undefined
            && feed.feed_param_values !== null && !Array.isArray(feed.feed_param_values)) {
            setFeedParamValues(feed.feed_param_values)
        }
    }, [feed?.feed_param_values])

    useEffect(() => {
        if (feed && feed.feed_settings_overrides !== undefined
            && feed.feed_settings_overrides !== null && !Array.isArray(feed.feed_settings_overrides)) {
            setFeedSettingsOverrides(feed.feed_settings_overrides)
        }
    }, [feed?.feed_settings_overrides])

    const testFeed = () => {

        setTestResults(null)
        setError(null)
        setRunningTest(true)

        lucitApi.feeds.testRunConverter(feed.id, feedParamValues)
            .then((response) => {
                setTestResults(response)
                setRunningTest(false)

                if (response.success) {
                    runFeedNow()
                }
            })
            .catch((error) => {
                setError(error)
                setRunningTest(false)
            })
    }

    const saveParams = (feedId, feedParamValues) => {

        setSavingParams(true)

        updateFeedParamValues(feedId, feedParamValues).then(() => {
            setDirty(false)
            setSavingParams(false)
            testFeed()
        }).catch((error) => {
            setError(error)
            setDirty(false)
            setSavingParams(false)
            testFeed()
        })
    }

    const saveOverrides = (feedId, feedSettingsOverrides) => {

        setSavingOverrides(true)

        updateFeedSettingsOverrides(feedId, feedSettingsOverrides).then(() => {
            setDirty(false)
            setSavingOverrides(false)
        }).catch((error) => {
            setError(error)
            setDirty(false)
            setSavingOverrides(false)
        })
    }

    const runFeedNow = () => {

        runFeed(feed.id).then(() => {
            setTestResults(null)
            switchToTab(1) //data
        })
    }

    const shouldShowFeedSettings = () => {

        if (!providesFeedSettings(feed))
            return false

        if (!expectedParamFields || !expectedParamFields.length)
            return false

        return true
    }

    if (!feed)
        return <>
        </>

    return (

        <Box mt={2} mb={4}>

            {shouldShowFeedSettings() && <>

                <LCTypography variant="h5">
                    {t('Feed Settings')}
                </LCTypography>

                <Box pl={2} mt={4}>
                    <Grid container>

                        <Grid item xs={12}>

                            <Box style={{ width: "90%" }} mb={4}>

                                <LucitFormFields
                                    fields={expectedParamFields}
                                    feedParamValues={feedParamValues}
                                    onChange={(key, value) => {
                                        setFeedParamValues(currentFeedParamValues => {
                                            const newFeedParamValues = { ...currentFeedParamValues }
                                            newFeedParamValues[key] = value
                                            return newFeedParamValues
                                        })
                                        setDirty(true)
                                    }}
                                />
                            </Box>

                        </Grid>

                        {feedRequiresInput && <Grid item xs={12}>

                            <ButtonLoader
                                submitting={savingParams || runningTest}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    saveParams(feed.id, feedParamValues)
                                }}
                            >{dirty ? t('Save Changes') : (runningTest ? t('Testing Data Source...') : t('Save Changes'))}</ButtonLoader>

                            <Box mt={2}>
                                {error && <FeedTestError error={error} expectedExceptions={expectedExceptions} />}

                                {runningTest && <Alert severity="info">{t('Testing data source...')}</Alert>}

                                {testResults && testResults.success && <Alert severity="info">
                                    <b>{t('Success!')}</b>
                                </Alert>}

                            </Box>

                        </Grid>
                        }
                    </Grid>

                </Box>

            </>}

            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

            <Box mb={2} mt={4}>

                <LCTypography variant="h5">Schedule</LCTypography>

                <Box mt={2} pl={2}>
                    <Box style={{ maxWidth: "400px", width: "90%" }}>
                        <LCTypography>
                            Control how often this data source will automatically refresh when you have actively running campaigns
                        </LCTypography>
                        <Box mt={2}>
                            <LCTypography style={{ marginTop: "10px", fontWeight: "bold" }}>
                                Refresh <FeedScheduleEdit
                                    feed={feed}
                                    onScheduleChange={(schedule) => {
                                        updateFeedNameAndSchedule(feed.id, feed.name, schedule)
                                    }}
                                />
                            </LCTypography>
                        </Box>
                        {(feed.provides_custom_run_schedule || feed.run_schedule?.type) && <Box mt={2}>
                            <LCTypography variant="body2">
                                Note:  This specific data source provides a custom calculated refresh schedule,
                                that depends on factors other than
                                the refresh setting you have specified.  The data source may run more or less often depending on its
                                internal configuration.  See the documentation for this data source for more information.
                            </LCTypography>
                        </Box>
                        }
                    </Box>
                </Box>
            </Box>

            {!feedIsKeyValueData(feed) && <>

                <FeedAutoAddToCampaigns
                    feedProviderSettings={feed.inventoryFeedProvider?.feed_settings}
                    savingOverrides={savingOverrides}
                    feedSettingsOverrides={feedSettingsOverrides}
                    setFeedSettingsOverrides={(settingsOverrides) => {
                        setFeedSettingsOverrides(settingsOverrides)
                        saveOverrides(feed.id, settingsOverrides)
                    }}
                />

                <FeedLimitItems
                    feedProviderSettings={feed.inventoryFeedProvider?.feed_settings}
                    savingOverrides={savingOverrides}
                    feedSettingsOverrides={feedSettingsOverrides}
                    setFeedSettingsOverrides={(settingsOverrides) => {
                        setFeedSettingsOverrides(settingsOverrides)
                        saveOverrides(feed.id, settingsOverrides)
                    }}
                />

                <FeedSkipItemsSettings
                    feedProviderSettings={feed.inventoryFeedProvider?.feed_settings}
                    savingOverrides={savingOverrides}
                    feedSettingsOverrides={feedSettingsOverrides}
                    setFeedSettingsOverrides={(settingsOverrides) => {
                        setFeedSettingsOverrides(settingsOverrides)
                        saveOverrides(feed.id, settingsOverrides)
                    }}
                />

                <FeedPhotoItemsSettings
                    feedProviderSettings={feed.inventoryFeedProvider?.feed_settings}
                    savingOverrides={savingOverrides}
                    feedSettingsOverrides={feedSettingsOverrides}
                    setFeedSettingsOverrides={(settingsOverrides) => {
                        setFeedSettingsOverrides(settingsOverrides)
                        saveOverrides(feed.id, settingsOverrides)
                    }}
                />

                <FeedFilter
                    feed={feed}
                    feedProviderSettings={feed.inventoryFeedProvider?.feed_settings}
                    savingOverrides={savingOverrides}
                    feedSettingsOverrides={feedSettingsOverrides}
                    setFeedSettingsOverrides={(settingsOverrides) => {
                        setFeedSettingsOverrides(settingsOverrides)
                        saveOverrides(feed.id, settingsOverrides)
                    }}
                />

            </>
            }
        </Box>
    )
}

const mapStateToProps = () => {

    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFeedParamValues: (feedId, feedParamValues) => {
            return dispatch(updateFeedParamValues(feedId, feedParamValues))
        },
        updateFeedSettingsOverrides: (feedId, feedSettingsOverrides) => {
            return dispatch(updateFeedSettingsOverrides(feedId, feedSettingsOverrides))
        },
        runFeed: id => dispatch(runFeed(id)),
        updateFeedNameAndSchedule: (id, name, schedule) => dispatch(updateFeedNameAndSchedule(id, name, schedule))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        FeedSettings
    )
);
