import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { PageTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import LightningPage from '../../apps/lightning/LightningPage';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

function Lightning() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.root}>
            <PageTitle title={t('Lightning')} />

            <LightningPage />
        </Paper>
    );
}

export default withHomeLayout(Lightning);
