import React from 'react';
import PropTypes from 'prop-types';
import { CampaignState, RoutePaths } from '../../helpers/constants';
import { IonButton, IonIcon } from '@ionic/react';
import { link, refresh, ellipsisVerticalCircle } from 'ionicons/icons';
import { makeStyles, Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExportDeleteButton from '../exports/ExportDeleteButton';
import { useDispatch } from 'react-redux';
import { canRunExport } from '../../selectors/exports';
import { runExport } from '../../actions/exports';
import CampaignPlayerIntegrationsDialog from './CampaignPlayerIntegrationsDialog';
import SendToTrafficButton from '../exports/SendToTrafficButton';
import RevertToDraftButton from '../exports/RevertToDraftButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentUserHasPermission } from '../../selectors/user';
import { AccountPolicy } from '../../helpers/lucoreConstants';

const useStyles = makeStyles(() => {
    return {
        button: {
            width: '100%',
            padding: '0 16px'
        }
    }
});

function CampaignMoreButton({ campaign }) {
    const [playerIntegrationsOpen, setPlayerIntegrationsOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const dispatch = useDispatch();
    const classes = useStyles();
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);
    const { t } = useTranslation();

    const canEditCampaign = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editCampaigns));

    return <>
        <IonButton
            color="secondary"
            fill="clear"
            onClick={handleClick}
        >
            <IonIcon slot="icon-only" icon={ellipsisVerticalCircle} />
        </IonButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            {canEditCampaign && campaign.campaign_state === CampaignState.draft.id
                && <Box>
                    <SendToTrafficButton exportItem={campaign} className={classes.button} />
                </Box>}
            {canEditCampaign && campaign.campaign_state === CampaignState.rejected.id
                && <Box>
                    <RevertToDraftButton exportItem={campaign} className={classes.button} />
                </Box>}
            <MenuItem
                disabled={!canEditCampaign || refreshing || !canRunExport(campaign)}
                onClick={() => {
                    setRefreshing(true);
                    dispatch(runExport(campaign.id))
                        .finally(() => setRefreshing(false))
                    handleCloseMenu()
                }}
            >
                <ListItemIcon>
                    <IonIcon icon={refresh} slot="start" />
                </ListItemIcon>
                <ListItemText primary={t('Refresh Data')} />
            </MenuItem>
            <MenuItem
                disabled={!canEditCampaign}
                onClick={() => {
                    dispatch(runExport(campaign.id))
                    setPlayerIntegrationsOpen(true)
                    handleCloseMenu()
                }}
            >
                <ListItemIcon>
                    <IonIcon icon={link} slot="start" />
                </ListItemIcon>
                <ListItemText primary={t('Link Player')} />
            </MenuItem>
            <ExportDeleteButton
                disabled={!canEditCampaign}
                onClick={() => handleCloseMenu()}
                campaign={campaign}
                redirectPathname={RoutePaths.home} />
        </Menu>

        {playerIntegrationsOpen && <CampaignPlayerIntegrationsDialog
            open={playerIntegrationsOpen}
            handleClose={() => setPlayerIntegrationsOpen(false)}
            campaign={campaign}
        />}
    </>;
}

CampaignMoreButton.propTypes = {
    campaign: PropTypes.object.isRequired
}

export default CampaignMoreButton;
