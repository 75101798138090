import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { IconButton, Badge, Box, ClickAwayListener, makeStyles } from '@material-ui/core';
import { useState } from 'react';

import NotificationsPopper from './NotificationsPopper';
import { unreadCount } from '../../selectors/pushNotifications';
import { connect } from 'react-redux';
import { getUnreadCount } from '../../actions/pushNotifications';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => {
    return {
        root: {
            marginRight: theme.spacing(3),

            [theme.breakpoints.down('xs')]: {
                marginRight: theme.spacing(1),
            }
        },
    }
})

const NotificationsButton = (props) => {
    const { unreadCount, getUnreadCount } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        getUnreadCount();
    }, [getUnreadCount])

    return (
        <ClickAwayListener
            onClickAway={() => setAnchorEl(null)}>
            <Box className={classes.root}>
                <IconButton
                    color={unreadCount
                        ? "secondary"
                        : "inherit"}
                    onClick={e => {
                        setAnchorEl(anchorEl
                            ? null
                            : e.currentTarget)
                    }}
                >
                    <Badge
                        badgeContent={unreadCount}
                        max={99}
                        invisible={unreadCount === 0} >
                        <NotificationsIcon />
                    </Badge>
                </IconButton>

                <NotificationsPopper
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                />
            </Box>
        </ClickAwayListener>
    );
};

const mapStateToProps = state => {
    return {
        unreadCount: unreadCount(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUnreadCount: () => dispatch(getUnreadCount())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    NotificationsButton
);
