import { apiHttp } from "../helpers/api";

export default class LucitApiExports {

    async getExport(id) {
        const item = await apiHttp.get(`/inventory-exports/${id}`)
            .then(response => response.data.inventory_export);

        if (!item) {
            throw new Error(`Export item with id '${id}' was not found.`);
        }

        return item;
    }

    getExportUsers(exportId) {
        return apiHttp.get(`/inventory-exports/${exportId}/users`)
            .then(response => response.data.users)
    }

    getExportBillboardsStatus(exportId) {
        return apiHttp.get(`/inventory-exports/${exportId}/billboards/status`)
    }

    getExportBillboards(exportId) {
        return apiHttp.get(`/inventory-exports/${exportId}/billboards`)
            .then(response => response.data.digital_boards)
    }

    getExportRuns(exporId, data) {
        return apiHttp.get(`/inventory-exports/${exporId}/runs`, { params: data })
            .then(response => response.data.inventory_export_runs)
    }

    getExportRun(runId) {
        return apiHttp.get(`/inventory-exports/run/${runId}`)
            .then(response => response.data.inventory_export_run);
    }

    getExportItems(exportId, filters) {
        const { perPage, search, searchFields, itemIds, ...rest } = filters;

        return apiHttp.get(`/inventory-exports/${exportId}/item-status`,
            {
                params: {
                    query: search,
                    per_page: perPage,
                    query_fields: searchFields && searchFields.length
                        ? JSON.stringify(searchFields)
                        : null,
                    inventory_item_id: itemIds && itemIds.length
                        ? JSON.stringify(itemIds)
                        : null,

                    ...rest
                }
            })
            .then(response => response.data);
    }

    getExports(data) {

        return apiHttp.get('/inventory-exports', {
            params: this.prepareExportsFilters(data)
        })
            .then(response => response.data.inventory_exports)
    }

    getActiveExports(data) {

        data.active = 1;

        return this.getExports(data)

    }

    setItemsWeights(id, itemsWeights) {
        return apiHttp.put(`/inventory-exports/${id}/setWeights`, {
            item_weights: JSON.stringify(itemsWeights.reduce((acc, x) => ({ ...acc, [x.id.toString()]: x.weight }), {}))
        })
            .then(response => response.data)
    }

    setBoardIds(id, boardIds) {
        return apiHttp.put(`/inventory-exports/${id}/set-board-ids`, {
            board_ids: JSON.stringify(boardIds)
        })
            .then(response => response.data)
    }

    setChildExports(lcuid, exportIds) {
        return apiHttp.put(`/inventory-exports/${lcuid}/set-child-exports`, {
            inventory_export_ids: exportIds
        })
            .then(response => response.data.inventory_export)
    }

    setParentExports(lcuid, exportIds) {
        return apiHttp.put(`/inventory-exports/${lcuid}/set-parent-exports`, {
            inventory_export_ids: exportIds
        })
            .then(response => response.data.inventory_export)
    }

    pushToTraffic(id, comments) {
        return apiHttp.put(`/inventory-exports/${id}/push-to-traffic`, {
            comments: comments
        })
            .then(response => response.data.inventory_export)
    }

    trafficReject(id, comments) {
        return apiHttp.put(`/inventory-exports/${id}/traffic-reject`, {
            comments: comments
        })
            .then(response => response.data.inventory_export)
    }

    revertToDraft(id) {
        return apiHttp.put(`/inventory-exports/${id}/revert-to-draft`)
            .then(response => response.data.inventory_export)
    }

    trafficComplete(id, comments) {
        return apiHttp.put(`/inventory-exports/${id}/traffic-complete`, {
            comments: comments
        })
            .then(response => response.data.inventory_export)
    }

    prepareExportsFilters(data) {
        const { search, accountId, status, ...rest } = data;

        return {
            query: search,
            account_id: accountId,
            status: status != null
                ? status
                : undefined,

            ...rest
        };
    }

    run(exportId) {
        return apiHttp.put(`/inventory-exports/${exportId}/make-runnable`, {})
            .then(response => response.data);
    }

    addApxContract(id, apxContractId) {
        return apiHttp.put(`/inventory-exports/${id}/apx-contract/${apxContractId}`)
            .then(response => response.data)
    }

    deleteApxContract(id, apxContractId) {
        return apiHttp.delete(`/inventory-exports/${id}/apx-contract/${apxContractId}`)
            .then(response => response.data)
    }

    addPxDealMap(id, pxDealId) {
        return apiHttp.put(`/inventory-exports/${id}/px-deal-map/${pxDealId}`)
            .then(response => response.data)
    }

    deletePxDealMap(id, pxDealId) {
        return apiHttp.delete(`/inventory-exports/${id}/px-deal-map/${pxDealId}`)
            .then(response => response.data)
    }

    addVistarInsertionOrderMap(id, vistarInsertionOrderId) {
        return apiHttp.put(`/inventory-exports/${id}/vistar-insertion-order-map/${vistarInsertionOrderId}`)
            .then(response => response.data)
    }

    deleteVistarInsertionOrderMap(id, vistarInsertionOrderId) {
        return apiHttp.delete(`/inventory-exports/${id}/vistar-insertion-order-map/${vistarInsertionOrderId}`)
            .then(response => response.data)
    }

    update(id, params) {
        return apiHttp.put(`/inventory-exports/${id}`, params)
            .then(response => response.data)
    }

    getHistory(id) {
        return apiHttp.get(`/inventory-exports/${id}/campaign-state-history`)
            .then(response => response.data.campaign_state_history);
    }

    setProgrammaticSettings(id, settings) {
        return apiHttp.put(`/inventory-exports/${id}/set-programmatic-settings`, settings)
            .then(response => response.data)
    }

    //Returns lifetime_cost (floatr) and cost_rollup (array)
    getLifetimeCosts(id) {
        return apiHttp.get(`/inventory-exports/${id}/get-lifetime-costs`)
            .then(response => response.data)
    }

    attachBoardToGroupCampaign(id, digitalBoardLcuid) {
        return apiHttp.put(`/inventory-exports/${id}/attach-board-to-group-campaign`, {
            digital_board_lcuid: digitalBoardLcuid
        })
            .then(response => response.data)
    }

    detachBoardFromGroupCampaign(id, digitalBoardLcuid) {
        return apiHttp.put(`/inventory-exports/${id}/detach-board-from-group-campaign`, {
            digital_board_lcuid: digitalBoardLcuid
        })
            .then(response => response.data)
    }
}
