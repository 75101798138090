import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from '../../components';
import AccountInfo from '../../containers/account/AccountInfo';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { getUser, selectedAccount } from '../../selectors/user';
import DeveloperObjectTools from '../../containers/settings/DeveloperObjectTools';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';


function Settings({ selectedAccount }) {

    if (!selectedAccount) {
        return null;
    }

    return (

        <PolicyFeatureToggle policy={AccountPolicy.update}>
            <Box>
                <PageTitle title={"Account Settings"} />

                <AccountInfo />

                <Box mt={2} />

                <Box mt={8} ml={6} mb={8}>

                </Box>

                <DeveloperObjectTools object={selectedAccount} />

            </Box>
        </PolicyFeatureToggle>
    );
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        selectedAccount: selectedAccount(state)
    }
}

const SettingsWithoutLayout = connect(
    mapStateToProps
)(Settings);

export { SettingsWithoutLayout };
export default withHomeLayout(SettingsWithoutLayout);
