import { apiHttp } from "../helpers/api";

export default class LucitApiUsers {
    getCards(userId) {
        return apiHttp.get(`/users/${userId}/cards`)
            .then(response => response.data);
    }

    saveCard(userId, token) {
        return apiHttp.post(`/users/${userId}/cards`, { token })
            .then(response => response.data.stripe.card)
    }

    savePrimary(userId, id) {
        return apiHttp.put(`/users/${userId}/cards/${id}/setPrimary`)
            .then(response => response.data)
    }

    deleteCard(userId, id) {
        return apiHttp.delete(`/users/${userId}/cards/${id}`)
            .then(response => response.data)
    }

    getAgencyUsers(agencyId) {
        return apiHttp.get(`/agencies/${agencyId}/users`)
            .then(response => response.data.users)
    }

    getAccountUsers(accountId) {
        return apiHttp.get(`/accounts/${accountId}/users`)
            .then(response => response.data.users)
    }

    getApplicationUsers(applicationId) {
        return apiHttp.get(`/applications/${applicationId}/users`)
            .then(response => response.data.users);
    }

    guessLocation(userId) {
        return apiHttp.get(`/users/${userId}/guess-location`)
            .then(response => response.data)
    }

    setPrimaryImage(userId, image_id) {
        return apiHttp.post(`/users/${userId}/primaryImage`, { image_id })
            .then(response => response.data.object);
    }

    deletePrimaryImage(userId) {
        return apiHttp.delete(`/users/${userId}/primaryImage`)
            .then(response => response.data);
    }

    setTour(userId, tour) {
        return apiHttp.put(`/users/${userId}/tours/${tour.id}`, tour)
            .then(response => response.data);
    }

    acceptEula() {
        return apiHttp.put("/users/current/eula-accept")
            .then(response => response.data.eula_data);
    }

    deleteFromAccount(userId, accountId) {
        return apiHttp.delete(`/users/${userId}/accounts/${accountId}`)
            .then(response => response.data);
    }

    deleteFromAgency(userId, agencyId) {
        return apiHttp.delete(`/users/${userId}/agencies/${agencyId}`)
            .then(response => response.data);
    }

    deleteFromApplication(userId, appId) {
        return apiHttp.delete(`/users/${userId}/applications/${appId}`)
            .then(response => response.data);
    }

    getDriveTemplates(userId, filters) {
        return apiHttp.get(`/users/${userId}/drive-templates`, { params: filters })
            .then(response => response.data.drive_templates);
    }

    editAccountRoles(userId, accountId, roles) {
        return apiHttp.put(`/accounts/${accountId}/users/${userId}/roles`, { roles: roles.join(':') })
            .then(response => response.data);
    }

    editAgencyRoles(userId, agencyId, roles) {
        return apiHttp.put(`/agencies/${agencyId}/users/${userId}/roles`, { roles: roles.join(':') })
            .then(response => response.data);
    }

    editApplicationRoles(userId, appId, roles) {
        return apiHttp.put(`/applications/${appId}/users/${userId}/roles`, { roles: roles.join(':') })
            .then(response => response.data);
    }
}
