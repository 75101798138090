import {
    Box, CardActionArea, CardContent, CardMedia, Grid, makeStyles, useTheme,
    RadioGroup, Tooltip, FormControlLabel, Radio, Typography
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import React, { useState } from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';
import { Image } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEditor } from '../../actions/designer';
import { DESIGNER_ADD_ASSET } from '../../helpers/actionTypes';
import { lucitApi } from '../../services/lucitApi';
import { BackgroundImage } from '../../components';
import { getIdFromAsset } from './DesignerElements';
import { selectedFrameIdName, selectedFrameAspectRatio } from '../../selectors/designer';

const useStyles = makeStyles(theme => {
    return {
        box: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: theme.shape.borderRadius
        }
    }
});

export const DesignerChooseImageTypeDialog = (props) => {
    const { open, handleClose, images } = props;
    const classes = useStyles();
    const [type, setType] = useState('image');
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const image = images && images[index];

    const [showOnlyForThisBoardFormat, setShowOnlyForThisBoardFormat] = useState("all_sizes"); //radios do not like true/false I guessa

    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const editModeGlobal = useSelector(state => state.designer.editModeGlobal);
    const currentFrameName = useSelector(selectedFrameIdName);
    const currentFrameAspectRatio = useSelector(selectedFrameAspectRatio);

    if (image == null) {
        return null;
    }

    const imageStyle = (type === 'background') ? {
        height: 160,
        width: currentFrameAspectRatio * 160
    } : {
        maxHeight: 160,
        height: "100%",
        maxWidth: "100%"
    }

    return (<>
        <GenericDialog
            title={t('Image Options')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            ContentComponent={<Box pt={2} pb={2}>
                <Grid container spacing={4}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src={image.fileBase64}
                            style={imageStyle} />
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            onClick={() => setType('image')}
                            className={classes.box}
                            style={{ background: type === 'image' ? theme.palette.action.selected : null }}>
                            <CardActionArea>
                                <CardMedia
                                    style={{ height: 60, paddingTop: 8 }}
                                >
                                    <Image style={{ width: '100%', height: '100%' }} />
                                </CardMedia>
                                <CardContent style={{ padding: 8 }}>
                                    <LCTypography style={{ textAlign: 'center' }}>
                                        Normal
                                    </LCTypography>
                                </CardContent>
                            </CardActionArea>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            onClick={() => setType('background')}
                            className={classes.box}
                            style={{ background: type === 'background' ? theme.palette.action.selected : null }}>
                            <CardActionArea>
                                <CardMedia
                                    style={{ height: 60, paddingTop: 8 }}
                                >
                                    <BackgroundImage style={{ width: '100%', height: '100%' }} />
                                </CardMedia>
                                <CardContent style={{ padding: 8 }}>
                                    <LCTypography style={{ textAlign: 'center' }}>
                                        Stretch to fill the screen
                                    </LCTypography>
                                </CardContent>
                            </CardActionArea>
                        </Box>
                    </Grid>
                </Grid>

                {!editModeGlobal && <Grid item xs={12}>

                    <Box mt={4}>

                        <RadioGroup
                            value={showOnlyForThisBoardFormat}
                            onChange={e => setShowOnlyForThisBoardFormat(e.target.value)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5}>

                                    <Tooltip
                                        title={t('Add this image to all sizes of your design')}
                                    >
                                        <FormControlLabel value={"all_sizes"} control={<Radio size="small" />}
                                            label={<Typography variant='body2'>{t('Add to canvas for all sizes')}</Typography>} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={5}>

                                    <Tooltip
                                        title={t('Make this image visible on the ' + currentFrameName + ' size only')}
                                    >
                                        <FormControlLabel value={"this_format_size"} control={<Radio size="small" />}
                                            label={<Typography variant='body2'>
                                                {t('Add to size ')}<strong>{currentFrameName}</strong>{t('  only')}
                                            </Typography>}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </RadioGroup>
                    </Box>
                </Grid>}
            </Box>}
            ActionsComponent={<>
                <ButtonLoader
                    onClick={() => {
                        image.type = type;

                        if (index === images.length - 1) {
                            setLoading(true);
                            const promises = images.map(image => {

                                //If coming from a drop event, we will have original_file_name
                                //passed in from setPasteImages
                                const original_file_name = image.file ? image.file.name : image.original_file_name;

                                const promise = image.isBlob
                                    ? lucitApi.uploads.uploadImage(image.fileBase64)
                                    : lucitApi.uploads.uploadImageFile(image.file)

                                return promise
                                    .then(uploaded => {
                                        const asset = {
                                            id: uploaded.id,
                                            original_file_name: original_file_name,
                                            image: uploaded,
                                            isBackground: image.type === 'background',
                                            className: image.type === 'background'
                                                ? 'lc_dt_image_full_size_stretch'
                                                : ''
                                        }
                                        dispatch({
                                            type: DESIGNER_ADD_ASSET,
                                            showOnlyForThisBoardFormat: showOnlyForThisBoardFormat === "all_sizes" ? false : true, //dumb
                                            asset,
                                        })
                                        dispatch(setSelectedEditor(getIdFromAsset(asset)))
                                    }).catch(() => {
                                        alert(t('There was an error uploading your image.  ' +
                                            'It may be too large or some other issue has occurred.  Please try again.'))
                                    });
                            });

                            Promise.all(promises)
                                .then(() => {
                                    handleClose();
                                    setType('image')
                                    setIndex(0);
                                })
                                .finally(() => setLoading(false));
                        } else {
                            setIndex(index + 1);
                        }
                    }}
                    color="secondary"
                    variant="contained"
                    submitting={loading}
                >
                    {t('OK')}
                </ButtonLoader>
            </>}
        />
    </>)
}
