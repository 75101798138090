import { lucitApi } from "../services/lucitApi";

export function uploadImage(fileBase64) {
    return () => {
        return lucitApi.uploads.uploadImage(fileBase64, "profile_image.png");
    }
}

export function uploadFile(fileBase64, fileName) {
    return () => {
        return lucitApi.uploads.uploadFile(fileBase64, fileName);
    }
}
