import PropTypes from 'prop-types';
import React from 'react';
import StreetViewOverlay from './StreetViewOverlay';
import { ZoomedCreative } from './ZoomedCreative';
import { ImageStyle } from '../../helpers/constants';

const LucitXR = (props) => {
    const { creative, canViewStreetView = true, useNativeImageTag, zoomTheCreative = true } = props;

    if (creative.digital_board_to_image.style == ImageStyle.streetViewOverlay &&
        canViewStreetView) {
        return <StreetViewOverlay
            className={props.className}
            creative={creative}
            streetView={props.streetView}
            alwaysUseBackupImage={true}
            useNativeImageTag={useNativeImageTag}
        />
    }

    if (creative.digital_board_to_image.style == ImageStyle.normal) {
        return zoomTheCreative ? <ZoomedCreative
            className={props.className}
            ionImageStyle={props.ionImageStyle}
            creativeToBoardImage={creative}
            viewportHeight={props.viewportHeight}
            viewportWidth={props.viewportWidth}
            margin={props.margin}
            useNativeImageTag={useNativeImageTag}
        />
            :
            <img src={creative.image.options.public_url} style={{ width: "100%" }} />
    }

    // Or maybe placeholder?
    return null;
}

LucitXR.propTypes = {
    creative: PropTypes.object.isRequired,
    canViewStreetView: PropTypes.bool,
    streetView: PropTypes.object,

    viewportHeight: PropTypes.number,
    viewportWidth: PropTypes.number,
    margin: PropTypes.number,
}

export default LucitXR;
