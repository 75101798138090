import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import PaymentMethodNewDialog from './PaymentMethodNewDialog';
import { useDispatch } from 'react-redux';
import { saveCard } from '../../actions/billing';
import { logger } from '../../helpers/logger';
import { showError, showSuccess } from '../../actions/snackbar';

function AddCardButton(props) {
    const { lcuid, ...rest } = props;
    const [isAddingCard, setAddingCard] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const addCard = useCallback(token => dispatch(saveCard(token, lcuid))
        .then(() => dispatch(showSuccess(`Card added`)))
        .catch(error => {
            dispatch(showError(`Error during saving the card`));
            logger.logError("Error during saving card", { error, token });
        }), [lcuid]);

    return (
        <>
            <Button
                onClick={() => setAddingCard(true)}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                {...rest}>
                {t('Add Card')}
            </Button>

            {isAddingCard
                && <PaymentMethodNewDialog
                    addCard={addCard}
                    open={true}
                    handleClose={() => setAddingCard(false)} />}
        </>
    );
}

AddCardButton.propTypes = {
    lcuid: PropTypes.string.isRequired
}
export default AddCardButton;
