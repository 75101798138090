import React from 'react';
import { Button } from '@material-ui/core';
import InventoryItemClassIcon from './InventoryItemClassIcon';

const ItemClassToggleButton = (props) => {
    const { itemClass, isSelected, onClick } = props;

    return <Button
        style={{ margin: "5px" }}
        variant={isSelected ? "contained" : "outlined"}
        color="primary"
        onClick={onClick}
        startIcon={<InventoryItemClassIcon itemClass={itemClass.class} />}
    >
        {itemClass.class_description ?? itemClass.title}
    </Button>

}

export default ItemClassToggleButton
