/**
 * @typedef {Object} Point
 * @property {number} id
 * @property {boolean} selected
 * @property {number} x
 * @property {number} y
 */

import { throttle } from "../../helpers/async";

/**
 * @typedef {Object} LassoOptions
 * @property {HTMLImageElement} image
 * @property {HTMLCanvasElement} canvas
 * @property {number} radius
 * @property {number} maxPoints
 * @property {(polygon: string) => void} onChange
 * @property {(polygon: string) => void} onUpdate
 * @property {(point: Point) => void} onPointSelected
 * @property {(point: Point) => void} onPointMove
 * @property {(absolute: Point, relative: Point) => void} onMouseMove
 * @property {boolean} enabled
 * @property {boolean} enablePointRemove
 */

/**
 * Create Canvas Config
 * @param {LassoOptions} options
 */
export function createLasso(options) {
    if (!(options.image instanceof HTMLImageElement)) {
        throw new Error('options.canvas is not a HTMLCanvasElement instance');
    }
    if (!(options.canvas instanceof HTMLCanvasElement)) {
        throw new Error('options.canvas is not a HTMLCanvasElement instance');
    }

    options = Object.assign({
        radius: 5,
        maxPoints: null,
        onChange: Function.prototype,
        onUpdate: Function.prototype,
        onPointSelected: Function.prototype,
        onPointMove: Function.prototype,
        onMouseMove: Function.prototype,
        enabled: true,
        enablePointRemove: true,

        strokeColor: 'black',
        strokeColorSelected: 'red',
        fillColor: 'rgba(2, 202, 202, 0.4)',
    }, options);

    // Replace elements
    const canvas = options.canvas;
    const ctx = canvas.getContext('2d');
    window.ctx = ctx;
    /**
     * @type {Point[]}
     */
    const path = [];
    let pathClosed = false;

    const setCtxStyle = (selected) => {
        if (selected) {
            ctx.strokeStyle = options.strokeColorSelected;
        } else {
            ctx.strokeStyle = options.strokeColor;
        }
    }

    /**
     * @param {() => void} fn
     */
    const addCtxPath = (fn) => {
        ctx.save();
        ctx.beginPath();
        fn();
        ctx.closePath();
        ctx.restore();
    }
    /**
     * @param {number} x
     * @param {number} y
     */
    const drawPoint = (x, y, selected) => {
        setCtxStyle(selected);

        addCtxPath(() => {
            ctx.arc(x, y, options.radius, 0, 2 * Math.PI);
            ctx.stroke();
        });

        addCtxPath(() => {
            ctx.moveTo(x - options.radius / 2, y - options.radius / 2);
            ctx.lineTo(x + options.radius / 2, y + options.radius / 2);
            ctx.stroke();
        });

        addCtxPath(() => {
            ctx.moveTo(x + options.radius / 2, y - options.radius / 2);
            ctx.lineTo(x - options.radius / 2, y + options.radius / 2);
            ctx.stroke();
        });
    };
    /**
     * @param {Point} p1
     * @param {Point} p2
     */
    const drawLine = (p1, p2) => {
        setCtxStyle(p1.selected || p2.selected);

        addCtxPath(() => {
            ctx.moveTo(p1.x, p1.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.stroke();
        });
    }
    const nextFrame = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(options.image, 0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = options.strokeColor;

        if (pathClosed) {
            if (path.length > 1) {
                drawLine(path[0], path[path.length - 1]);
            }
            addCtxPath(() => {
                ctx.moveTo(path[0].x, path[0].y);
                for (let i = 1; i < path.length; i++) {
                    const { x, y } = path[i];
                    ctx.lineTo(x, y);
                }
                ctx.fillStyle = options.fillColor;
                ctx.fill();
            });
        } else if (path.length && !controllers.selectedPoint) {
            const { x, y } = getDistance(path[0], controllers.pos) <= options.radius ? path[0] : controllers.pos;
            drawPoint(x, y);
            drawLine(path[path.length - 1], { x, y });
        }

        for (let i = 0; i < path.length; i++) {
            const { x, y, selected } = path[i];
            drawPoint(x, y, selected);
            if (i > 0) {
                drawLine(path[i - 1], path[i]);
            }
        }

    };

    /**
     * @param {MouseEvent | TouchEvent} e
     * @param {boolean} [shiftSensitive]
     */
    const getMousePosition = (e, shiftSensitive = true) => {
        let clientX, clientY;
        if (e instanceof MouseEvent) {
            clientX = e.clientX;
            clientY = e.clientY;
        } else {
            const tEvent = e.touches[0];
            clientX = tEvent.clientX;
            clientY = tEvent.clientY;
        }
        const rect = canvas.getBoundingClientRect();
        const ret = {
            x: clientX - rect.left,
            y: clientY - rect.top
        };
        if (e.shiftKey) {
            if (!controllers.relativePoint && path.length) {
                controllers.relativePoint = path
                    .filter(p => p !== controllers.selectedPoint)
                    .reduce((a, b) => getDistance(ret, a) < getDistance(ret, b) ? a : b);
            }
        } else {
            controllers.relativePoint = null;
        }
        if (shiftSensitive && controllers.relativePoint) {
            straightenLine(ret, controllers.relativePoint);
        }
        return ret;
    }

    const controllers = {
        mousedown: false,
        startPos: { x: 0, y: 0 },
        pos: { x: 0, y: 0 },
        selectedPoint: null,
        relativePoint: null,

        selectPoint: (point) => {
            if (controllers.selectedPoint) {
                controllers.selectedPoint.selected = false;
            }

            if (point) {
                point.selected = true;
            }

            controllers.selectedPoint = point;
        }
    };
    canvas.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
    ['mousedown', 'touchstart'].forEach(event => canvas.addEventListener(event, /** @param {MouseEvent | TouchEvent} e */(e) => {
        if (!options.enabled) {
            return;
        }
        nextFrame();
        controllers.mousedown = true;
        controllers.startPos = getMousePosition(e, false);
        controllers.pos = getMousePosition(e);

        const point = path.find((p1) => getDistance(p1, controllers.pos) <= options.radius) || null;

        controllers.selectPoint(point)

        if (controllers.selectedPoint) {
            options.onPointSelected({ ...controllers.selectedPoint });
        } else {
            options.onPointSelected(null);
        }
    }, { passive: true }));
    ['mousemove', 'touchmove'].forEach(event => canvas.addEventListener(event, throttle(/** @param {MouseEvent | TouchEvent} e */(e) => {
        if (!options.enabled) {
            return;
        }

        controllers.pos = getMousePosition(e);
        if (controllers.mousedown) {
            if (controllers.selectedPoint) {
                controllers.selectedPoint.x = controllers.pos.x;
                controllers.selectedPoint.y = controllers.pos.y;
                onPathUpdate();
                options.onPointMove({ ...controllers.selectedPoint });
            }
        }

        options.onMouseMove({ ...controllers.pos });

        nextFrame();
    }, 5), { passive: true }));
    ['mouseup', 'touchend', 'touchcancel'].forEach(event => canvas.addEventListener(event, /** @param {MouseEvent | TouchEvent} e */(e) => {
        if (!options.enabled) {
            return;
        }

        if (e instanceof MouseEvent && e.button === 2 && options.enablePointRemove) {
            if (controllers.selectedPoint) {
                path.splice(path.indexOf(controllers.selectedPoint), 1);
            } else {
                const pointToRemove = path.find((p1) => getDistance(p1, controllers.pos) <= options.radius);
                if (pointToRemove) {
                    path.splice(path.indexOf(pointToRemove), 1);
                }
            }
        } else {
            if (!controllers.selectedPoint) {
                if (!options.maxPoints || options.maxPoints > path.length)
                    path.push({
                        id: Math.max(path.map(p => p.id)) + 1,
                        x: controllers.pos.x,
                        y: controllers.pos.y
                    });

                if (options.maxPoints === path.length)
                    pathClosed = true;

            } else if (controllers.selectedPoint === path[0]) {
                pathClosed = true;
            }
        }
        if (path.length < 3) {
            pathClosed = false;
        }
        controllers.mousedown = false;
        controllers.relativePoint = null;

        onPathChange();
        onPathUpdate();
        nextFrame();
    }, { passive: true }));

    /**
     * @param {Point} point
     * @param {Point} [relative]
     */
    function straightenLine(point, relative) {
        const dx = Math.abs(relative.x - point.x);
        const dy = Math.abs(relative.y - point.y);
        if (dx > dy) {
            point.y = relative.y;
        } else {
            point.x = relative.x;
        }
    }
    /**
     * @param {Point} p1
     * @param {Point} p2
     */
    function getDistance(p1, p2) {
        return Math.hypot(p1.x - p2.x, p1.y - p2.y);
    }
    function onPathChange() {
        options.onChange(path.slice());
    }
    function onPathUpdate() {
        options.onUpdate(path.slice());
    }
    return {
        init() {
            nextFrame();
        },
        reset() {
            path.length = 0;
            pathClosed = false;
            nextFrame();
            onPathChange();
            onPathUpdate();
        },
        /**
         * @param {string} polygon
         */
        setPath(newPath) {
            path.length = 0;
            path.push(...newPath);
            path.forEach((p, idx) => p.id = idx);

            pathClosed = true;
            nextFrame();
        },
        updatePoint(point) {
            path.forEach(p => {
                if (p.id === point.id) {
                    p.x = point.x;
                    p.y = point.y;
                }
            });

            onPathChange();
            onPathUpdate();
            nextFrame();
        },
        enable() {
            options.enabled = true;
            nextFrame();
        },
        disable() {
            if (!pathClosed) {
                path.length = 0;
                pathClosed = true;
                onPathChange();
                onPathUpdate();
                nextFrame();
            }
            options.enabled = false;
        },

        redraw() {
            nextFrame();
        }
    }
}
