import {
    ROLES_REQUEST,
    ROLES_RESULT,
} from "../../helpers/actionTypes";

export const initialState = {
    loadingRoles: false,

    roles: [],
}

export default function roles(state = initialState, action) {
    switch (action.type) {
        case ROLES_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingRoles: true
                });

        case ROLES_RESULT:
            return Object.assign({},
                state,
                {
                    loadingRoles: false,
                    roles: action.data
                });

        default:
            return state
    }
}
