import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Typography, AccordionDetails, AccordionSummary, Box,
    Divider, makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpandMore } from '@material-ui/icons';
import { AccordionScrollable } from '../../components/material';
import { getUser } from '../../selectors/user';
import UsersList from './UsersList';
import NewInviteButton from './NewInviteButton';

const useStyles = makeStyles((theme) => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },
    }
})

function UsersListAccordion(props) {
    const { lcuid, currentUserRoles, title, description, buttonText, showAccountList } = props;
    const classes = useStyles();
    const [isOpened, setOpened] = useState(true);

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Box>
                    <NewInviteButton
                        lcuid={lcuid}
                        currentUserRoles={currentUserRoles}
                        text={buttonText}
                    />

                    <Typography variant="body2">
                        {description}
                    </Typography>

                    <UsersList
                        lcuid={lcuid}
                        currentUserRoles={currentUserRoles}
                        enableRemove={true}
                        showAccountList={showAccountList}
                    />
                </Box>
            </AccordionDetails>
        </AccordionScrollable>

    );
}

UsersListAccordion.propTypes = {
    lcuid: PropTypes.string.isRequired,

    currentUserRoles: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {
        currentUser: getUser(state)
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    UsersListAccordion
);
