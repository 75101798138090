import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import LCTypography from '../../components/material/LCTypography';
import { Paper, makeStyles, Box, AccordionSummary, Divider, AccordionDetails } from '@material-ui/core';

import { withHomeLayout } from '../../layouts/HomeLayout';
import { PageTitle } from '../../components';
import {
    getFacingCardinals, getImages, getPlayStatus,
    getScreen, getScreensHardware, getScreensSoftware, getStreetView
} from '../../actions/billboards';
import {
    AddImageButton, BillboardImages,
    BillboardProfileEditable
} from '../../containers/billboards';
import { billboardSelector } from '../../selectors/billboard';
import { getLocationById } from '../../actions/locations';
import { AccordionScrollable } from '../../components/material';
import { getUser, hasAgency } from '../../selectors/user'
import { RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { locationByIdSelector } from '../../selectors/locations';
import DeveloperObjectTools from '../../containers/settings/DeveloperObjectTools';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        },
        summaryContent: {
            alignItems: "center",
            margin: "0 !important"
        }
    }
})

function Billboard(props) {
    const { billboardSelector, match, locationByIdSelector,
        getById, getPlayStatus, getImages, getLocationById, getStreetView,
        getScreensSoftware, getScreensHardware, getFacingCardinals, user } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const boardId = match.params.id;
    const board = useMemo(() => billboardSelector(boardId), [billboardSelector, boardId])

    const boardLocationId = board && board.location_id;
    const boardStreeViewId = board && board.street_view_location_id;
    const location = locationByIdSelector(boardLocationId);

    useEffect(() => {
        getById(boardId);
        getImages(boardId);
        getPlayStatus(boardId);
        getPlayStatus(boardId);
    }, [getById, boardId])

    useEffect(() => {
        if (boardLocationId) {
            getLocationById(boardLocationId);
        }

        if (boardStreeViewId) {
            getStreetView(board.id, boardStreeViewId);
        }
    }, [getLocationById, boardLocationId, boardStreeViewId])

    useEffect(() => {
        getScreensSoftware();
        getScreensHardware();
        getFacingCardinals();
    }, [])

    const canEditBoard = board
        ? hasAgency(user)(board.agency_id)
        : null;

    if (!board) {
        return null;
    }

    return <>
        <PageTitle title={board.name} />

        {canEditBoard
            ? <>
                <Paper className={classes.root}>
                    <BillboardProfileEditable board={board} location={location} />
                </Paper>

                <Box mt={2} />
                <AccordionScrollable expanded>
                    <AccordionSummary classes={{ content: classes.summaryContent }}>
                        <LCTypography variant="h5">{t('Images')}</LCTypography>
                        <Box ml={2}>
                            <AddImageButton board={board} />
                        </Box>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails style={{ padding: 16 }}>
                        {board
                            && <BillboardImages board={board} />}
                    </AccordionDetails>
                </AccordionScrollable>

                <DeveloperObjectTools object={board} />
            </>
            : <Redirect to={RoutePaths.public.slug(board.slug || board.lcuid)} />
        }
    </>;
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        billboardSelector: id => billboardSelector(state)(id),
        locationByIdSelector: id => locationByIdSelector(state)(id)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getById: id => dispatch(getScreen(id)),
        getImages: id => dispatch(getImages(id)),
        getPlayStatus: id => dispatch(getPlayStatus(id)),
        getLocationById: id => dispatch(getLocationById(id)),
        getStreetView: (boardId, streetViewId) => dispatch(getStreetView(boardId, streetViewId)),
        getScreensSoftware: () => dispatch(getScreensSoftware()),
        getScreensHardware: () => dispatch(getScreensHardware()),
        getFacingCardinals: () => dispatch(getFacingCardinals())
    }
}

const BillboardWithoutLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Billboard);

export { BillboardWithoutLayout }
export default withRouter(withHomeLayout(BillboardWithoutLayout));
