import React, { useState } from 'react';
import clx from 'classnames';
import { Box, Button, Chip, makeStyles } from '@material-ui/core';
import VenueTypesFilter from './VenueTypesFilter';
import AgenciesFilter from './AgenciesFilter';
import BoardFormatsFilter from './BoardFormatsFilter';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRAMMATIC_FILTERS_CHANGE, PROGRAMMATIC_RESET } from '../../helpers/actionTypes';
import BudgetFilter from './BudgetFilter';
import ImpressionsStats from './ImpressionsStats';
import LocationFilter from './LocationFilter';
import CreativesSection from './CreativesSection';
import { Clear } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { showError } from '../../actions/snackbar';
import { CreateCampaignDialog } from './CreateCampaignDialog';
import { ButtonLoader } from '../../components';
import { getDigitalBoardFilters } from '../../selectors/programmatic';
import { lucitApi } from '../../services/lucitApi';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => {
    return {
        filtersBox: {
            display: 'flex',
            padding: '8px 8px',
            background: 'white',
            flexDirection: 'column',
            maxWidth: 248,
            width: '100%',
            height: '100%',
            zIndex: 3,
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: `width 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                         padding 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
        },
        filtersBoxInitial: {
            width: '0 !important',
            padding: '0 !important'
        }
    }
})

const ProgrammaticFilters = () => {
    const [createCampaignOpen, setCreateCampaignOpen] = useState(false);
    const [creatingCampaign, setCreatingCampaign] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const editMode = useSelector(state => state.programmatic.editMode);
    const stats = useSelector(state => state.programmatic.stats);
    const statsLoading = useSelector(state => state.programmatic.statsLoading);
    const initial_view_active = useSelector(state => state.programmatic.initial_view_active);

    const digitalBoardFilters = useSelector(getDigitalBoardFilters);
    const campaignLcuid = useSelector(state => state.programmatic.campaignLcuid);
    const programmatic = useSelector(state => state.programmatic);

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            <Box className={clx(classes.filtersBox,
                { [classes.filtersBoxInitial]: initial_view_active })}>
                <LCTypography variant="h6" gutterBottom>Filters</LCTypography>
                <LocationFilter />
                <RegionsChips />

                <VenueTypesFilter />
                <BoardFormatsFilter />
                <AgenciesFilter />

                {!editMode
                    && <>
                        <Box mt={1} />
                        <BudgetFilter />
                    </>}
                <Box mt={1} />
                <CreativesSection />
                <Box mt={2} />

                <Box mt="auto"
                    p={1}
                    ml={-1}
                    mr={-1}
                    display="flex"
                    flexDirection="column"
                    position="sticky"
                    bottom={0}
                    style={{
                        background: 'white',
                        borderTop: '1px solid rgba(17, 25, 39, 0.2)'
                        // boxShadow: '0px -2px 6px 0px rgba(17, 25, 39, 0.2)'
                    }}>
                    <ImpressionsStats />

                    <Box mt={1} mb={1} />
                    {!editMode
                        && <Button
                            size="small"
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                            onClick={() => dispatch({ type: PROGRAMMATIC_RESET })}>
                            {t('Reset Filters')}
                        </Button>}
                    <ButtonLoader
                        size="small"
                        disabled={statsLoading}
                        submitting={creatingCampaign}
                        variant="contained"
                        onClick={() => {
                            if (editMode && stats.campaign_is_possible) {
                                setCreatingCampaign(true)
                                lucitApi.exports.setProgrammaticSettings(campaignLcuid, {
                                    digital_board_filters: digitalBoardFilters,
                                    budget_allocation: programmatic.budget_allocation,
                                    budget_amount: programmatic.budget.amount,
                                    budget_type: programmatic.budget.budget_type,
                                    start_date: programmatic.dates.startDate,
                                    end_date: programmatic.dates.endDate
                                }).then(response => {
                                    setCreatingCampaign(false);
                                    const newExport = response.inventory_export;
                                    history.push(`/campaign/${newExport.id}`);
                                })
                            } else if (!editMode && stats.campaign_is_possible) {
                                setCreateCampaignOpen(true);
                            }
                            else {
                                dispatch(showError(stats.campaign_rejection_reasons.join('.\n')))
                            }
                        }}
                        color="secondary">
                        {editMode ? t('Save Changes') : t('Continue')}
                    </ButtonLoader>
                    {createCampaignOpen
                        && <CreateCampaignDialog
                            open={createCampaignOpen}
                            handleClose={() => setCreateCampaignOpen(false)}
                        />}
                </Box>
            </Box >
        </>
    );
};

const RegionsChips = () => {
    const dispatch = useDispatch();
    const geo = useSelector(state => state.programmatic.geo);

    if (geo.length == 0) {
        return null;
    }

    return <Box mb={1} ml={-0.5} mr={-0.5}>
        {geo.map(x => (<Chip
            key={x.id}
            label={x.label || x.id}
            color='primary'
            onClick={() => {
                // map.fitBounds(geo.bounds);
            }}
            onDelete={() => dispatch({
                type: PROGRAMMATIC_FILTERS_CHANGE,
                geo: { id: x.id, remove: true }
            })}
            style={{ margin: 4, maxWidth: 'calc(100% - 8px)' }}
            deleteIcon={<Clear />}
            variant="outlined" />))}
    </Box>;
}

export default ProgrammaticFilters;
