import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import { Box, Button, Grow, Popover, makeStyles } from '@material-ui/core';
import { ArrowForward, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
    return {
        filterButton: {
            background: 'white',
            textTransform: 'none',
            color: theme.palette.text.hint,

            '&:hover': {
                background: 'white',
                borderColor: theme.palette.primary.main
            }
        },
        endIcon: {
            marginLeft: 'auto'
        }
    }
})

const FilterButton = ({ title, className, open, onOpen, onClose, onApply, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        anchorEl
            ? onOpen && onOpen()
            : onClose && onClose();
    }, [anchorEl])

    useEffect(() => {
        open
            ? null
            : setAnchorEl(null)
    }, [open])

    return (
        <Box mb={1}>
            <Button
                fullWidth
                color="default"
                variant="outlined"
                size="medium"
                endIcon={<ExpandMore color="primary" />}
                classes={{ endIcon: classes.endIcon }}
                className={clx(classes.filterButton, className)}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                {title}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                TransitionComponent={Grow}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{ marginTop: 4 }}
            >
                <Box display="flex" flexDirection="column" p={2} pt={0} pb={1} minWidth={360}>
                    {children}
                    <Button
                        style={{ marginTop: 8 }}
                        endIcon={<ArrowForward />}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            onApply && onApply();
                            setAnchorEl(null);
                        }}
                    >
                        Apply Filter
                    </Button>
                </Box>
            </Popover>
        </Box>
    );
};

export default FilterButton;
