import React from 'react';

const LucitFormFieldSpace = () => {

    return <>
        <span>&nbsp;</span>
    </>
}

export default LucitFormFieldSpace
