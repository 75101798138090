import {
    Avatar, Box, Card, CardActionArea, CardContent, CardHeader, CardMedia,
    Divider,
    GridList, GridListTile, TextField, Typography, makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clx from 'classnames';
import LCTypography from '../../components/material/LCTypography';
import { debouncePromise } from '../../helpers/async';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useDispatch, useSelector } from 'react-redux';
import { loadTemplates } from '../../actions/designer';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles(theme => {
    return {
        cardWrapper: {
            cursor: 'pointer',
            height: '100%',
            width: '100%'
        },
        cardSelected: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        creativeTile: {
            padding: theme.spacing(1.5),
            display: 'flex',
            justifyContent: 'center'
        }
    }
});

const colsMap = {
    "xs": 1,
    "sm": 1,
    "md": 3,
    "lg": 4,
    "xl": 5
}

export const DesignerTemplateList = ({ filters, width, showUserName,
    onClick, onEmptyClick, isSelected, compact }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const cols = colsMap[width];
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const searchDebounce = useCallback(debouncePromise((query) => setQuery(query.toLowerCase()), 800), []);
    const loading = useSelector(state => state.designer.templatesLoading)
    const templates = useSelector(state => state.designer.templates)

    const templatesFiltered = useMemo(() => {
        return templates.filter(template => searchTermInText(query, [
            template.name,
            template.description,
            template.created_by_user?.name,
            template.parent?.name,
            ...template.inventory_item_class_data.map(y => y.class_description)
        ]));
    }, [templates, query]);

    const hasAppTemplates = templatesFiltered.some(x => x.parent_type === 'App\\Application');
    const hasFeedProviderTemplates = templatesFiltered.some(x => x.parent_type === 'App\\InventoryFeedProvider');
    const hasCustomTemplates = templatesFiltered.some(x => x.parent_type !== 'App\\InventoryFeedProvider');

    useEffect(() => {
        dispatch(loadTemplates(filters))
    }, [])

    return <>
        {templates.length >= 10
            && <Box mb={2} width="100%">
                <TextField
                    variant="outlined"
                    fullWidth={true}
                    label={t('Search')}
                    value={inputValue}
                    onChange={e => {
                        setInputValue(e.target.value);
                        searchDebounce(e.target.value);
                    }}
                />
            </Box>}

        {loading && <CircularProgressCentered />}
        {!loading
            && templates.length == 0
            && <Box
                style={{
                    cursor: "pointer",
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                textAlign="center"
                onClick={() => onEmptyClick && onEmptyClick()}
            >
                <img src="/assets/designer/empty-drive-template.png" style={{ maxWidth: 320 }} />
                <Typography variant="h6" gutterBottom>{t('No templates yet')}</Typography>
                <Typography variant="body1" gutterBottom>{t('Create a new template to get started')}</Typography>
            </Box>}
        {!loading
            && templates.length != 0
            && templatesFiltered.length == 0
            && <Typography variant="body1" gutterBottom>{t('No matching templates')}</Typography>}

        {templatesFiltered.length > 0
            && <>
                <GridList
                    cols={cols}
                    cellHeight={'auto'}
                    style={{ margin: '-2px -12px' }}>
                    {templatesFiltered.filter(x => (
                        x.parent_type !== 'App\\InventoryFeedProvider' && x.parent_type !== 'App\\Application')
                    ).map(x => <GridListTile
                        key={x.lcuid}
                        classes={{ tile: classes.creativeTile }}>
                        <TemplateCard
                            template={x}
                            width={width}
                            compact={compact}
                            selected={isSelected && isSelected(x)}
                            {...{ showUserName, onClick }}
                        />
                    </GridListTile>)}
                </GridList>

                {!hasCustomTemplates && <Box mt={4} mb={4} pt={4} pl={1} pb={4}>
                    <Typography variant="body2" gutterBottom>{t('You have not yet created any templates for your account')}</Typography>

                </Box>}

                {hasFeedProviderTemplates && <Box mt={4}>
                    <Box mb={4}>
                        <Divider />
                    </Box>

                    <Typography variant="h6" gutterBottom>{t('Sample Templates')}</Typography>
                    <GridList
                        cols={cols}
                        cellHeight={'auto'}
                        style={{ margin: '-2px -12px' }}>
                        {templatesFiltered.filter(x => x.parent_type === 'App\\InventoryFeedProvider').map(x => <GridListTile
                            key={x.lcuid}
                            classes={{ tile: classes.creativeTile }}>
                            <TemplateCard
                                template={x}
                                width={width}
                                compact={compact}
                                showUserName={false}
                                selected={isSelected && isSelected(x)}
                                {...{ onClick }}
                            />
                        </GridListTile>)}
                    </GridList>
                </Box>}

                {hasAppTemplates && <Box mt={4}>
                    <Box mb={4}>
                        <Divider />
                    </Box>

                    <Typography variant="h6" gutterBottom>{t('App Templates')}</Typography>
                    <GridList
                        cols={cols}
                        cellHeight={'auto'}
                        style={{ margin: '-2px -12px' }}>
                        {templatesFiltered.filter(x => x.parent_type === 'App\\Application').map(x => <GridListTile
                            key={x.lcuid}
                            classes={{ tile: classes.creativeTile }}>
                            <TemplateCard
                                template={x}
                                width={width}
                                compact={compact}
                                showUserName={false}
                                selected={isSelected && isSelected(x)}
                                {...{ onClick }}
                            />
                        </GridListTile>)}
                    </GridList>
                </Box>}

            </>
        }
    </>;
}

const TemplateCard = ({ template, showUserName, selected, onClick, compact }) => {
    const classes = useStyles()

    return <>
        <Card className={clx(classes.cardWrapper, { [classes.cardSelected]: selected })}>
            <CardActionArea
                onClick={() => {
                    if (onClick) {
                        onClick(template);
                    }
                }}
            >
                {template.parent &&
                    <CardHeader
                        titleTypographyProps={{
                            style: { fontSize: compact ? "0.75rem" : "0.875rem" }
                        }}
                        avatar={
                            <Avatar
                                alt={template.parent.name}
                                className={classes.avatar}
                                style={{
                                    width: compact ? 15 : 40,
                                    height: compact ? 15 : 40,
                                }}
                                src={template.parent.options.primary_image_public_url}
                            />
                        }
                        title={template.parent ? template.parent.name : ''}
                        subheader={compact
                            ? null
                            : (showUserName && template.created_by_user ? template.created_by_user.name : 'Creative Team')
                        }
                        subheaderTypographyProps={{
                            className: selected && classes.cardSelected
                        }}
                    />
                }
                <CardMedia
                    component="img"
                    height={compact ? 70 : 140}
                    style={{
                        objectFit: "contain",
                    }}
                    image={
                        template.options.primary_image_public_url
                            ? template.options.primary_image_public_url
                            : "/assets/designer/empty-drive-template.png"
                    }
                    title={template.name}
                />
                <CardContent>
                    <LCTypography gutterBottom variant="subtitle2" component="p" noWrap>
                        {template.name}
                    </LCTypography>
                </CardContent>
            </CardActionArea>
        </Card>
    </>
}
