import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Box,
    List, ListItem, Divider, Grid, Link
} from '@material-ui/core';
import { LucitXR } from '../../components/images';
import LCTypography from '../../components/material/LCTypography';
import BillboardOnMap from '../billboards/BillboardOnMap';
import { getCityScapeIcon } from '../../selectors/billboard';
import { IonImg } from '@ionic/react';
import { IonCard } from '@ionic/react';

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import { Pagination, Navigation } from "swiper";

const useStyles = makeStyles(theme => {
    return {
        lucitXRImage: {
            overflow: 'visible !important',
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '300px',
                width: '100%',
            },
            '& img': {
                width: '100%',
            },
        },
        proofXRModalContent: {
            padding: 10,
            flex: 1,
        },
        previewGrid: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                flexWrap: 'nowrap',
            },
        },
        previewXRDetailsModalContainer: {
            display: 'flex',
            zIndex: 1,
            padding: '10px',
            paddingLeft: 0,
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: 10,
            },
        },
        previewXRMapContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            height: '100px'
        },
        map: {
            height: '240px !important',
            borderRadius: '10px',
            marginInline: '10px',
            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
        },
    }
})

const ProofBoard = (props) => {
    const { agency, board, creative, creatives } = props;
    const classes = useStyles();

    const [activeCreativeStep, setActiveCreativeStep] = useState(0);

    const hasImages = () => creative ? true : false

    //If we have a single image, then don't show the swiper
    const showTheSwiper = () => creatives.length > 1

    return <><Grid container className={classes.previewGrid}>
        <Grid item xs={12} md={8} className={classes.proofXRModalContent}>
            <Box display="flex"
                alignItems="center"
                justifyContent="center"
                className={classes.proofXRModalImgContainer}
            >
                {hasImages() ?
                    showTheSwiper() ?
                        <>
                            <Swiper
                                pagination={{
                                    type: "fraction",
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                                index={activeCreativeStep}
                                onChangeIndex={(step) => setActiveCreativeStep(step)}
                                enableMouseEvents
                            >
                                {creatives.map((creativeImage) => (
                                    <SwiperSlide key={creativeImage.id}>
                                        <LucitXR
                                            className={classes.lucitXRImage}
                                            canViewStreetView={true}
                                            creative={creativeImage}
                                            streetView={board.street_view_location}
                                            useNativeImageTag
                                            zoomTheCreative={false}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </>
                        :
                        <IonCard>
                            <LucitXR
                                className={classes.lucitXRImage}
                                canViewStreetView={true}
                                creative={creative}
                                streetView={board.street_view_location}
                                useNativeImageTag
                            />
                        </IonCard>
                    : <IonImg className={classes.previewCityScape}
                        src={getCityScapeIcon(board.digital_board_format.code)} />
                }
            </Box>
        </Grid>
        <Grid item xs={12} md={4}>
            <Box className={classes.previewXRDetailsModalContainer}>
                <LCTypography variant="h6">{agency.name}</LCTypography>
                <List>
                    <ListItem>
                        <strong>Location:&nbsp;</strong>
                        <Link href={board.slug}>{board.name}, {board.location.city}, {board.location.region}</Link>
                    </ListItem>
                    <ListItem>
                        <strong>Facing:&nbsp;</strong>
                        {board.options.facing_cardinal}
                    </ListItem>
                    <ListItem>
                        <strong>Type:&nbsp;</strong>
                        Digital
                    </ListItem>
                    <ListItem>
                        <strong>Daily Impressions:&nbsp;</strong>
                        {new Intl.NumberFormat('en-US').format(+board.options.avg_daily_impressions)}
                    </ListItem>
                </List>

                <Divider />

                <Box className={classes.previewXRMapContainer}>
                    <BillboardOnMap
                        classes={{ map: classes.map }}
                        location={board.location} />
                </Box>
            </Box>
        </Grid>
    </Grid >
    </>
        ;
}

ProofBoard.propTypes = {
    board: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    creative: PropTypes.object.isRequired,
    creatives: PropTypes.object.isRequired,
}

export default ProofBoard;
