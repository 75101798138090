import { createSelector } from "reselect";
import { InventoryItemClasses } from "../helpers/constants";

const dashboard = state => state.dashboard;

export const feeds = state => state.dashboard.feeds;
export const exports = state => state.dashboard.exports;

export const lastPingSelector = createSelector(
    [dashboard],
    dashboard => id => dashboard.lastPings[id]
)

export const activeAdsAreSubCampaigns = (activeAds) => {
    return activeAds && activeAds.some(ad => ad.item_class === InventoryItemClasses.childCampaign.class);
}
