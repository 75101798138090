import React from 'react';
import { Helmet } from "react-helmet";

const OgMetatags = ({ url, type, title, description, image }) => {

    return (
        <Helmet>
            <meta property="og:url" content={url || window.location.href} />
            <meta property="og:type" content={type || 'website'} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description || ''} />
            {image
                && <meta property="og:image" content={image} />}
        </Helmet>
    );
};

export default OgMetatags;
