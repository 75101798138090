import {
    Box, Button, makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { DateRangeInput } from '../../components/inputs/DateRangeInput';
import moment from 'moment';
import { lucitApi } from '../../services/lucitApi';
import { useDispatch } from 'react-redux';
import { EXPORTS_GETBYID_RESULT } from '../../helpers/actionTypes';
import { ButtonLoader } from '../../components';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

export const CampaignDatesEditDialog = ({ open, handleClose, campaign }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(campaign.campaign_start_at),
        endDate: new Date(campaign.campaign_end_at)
    })

    const onSave = () => {
        setSaving(true);
        return lucitApi.exports.setProgrammaticSettings(campaign.lcuid, {
            digital_board_filters: campaign.digital_board_filters,
            budget_allocation: campaign.budget_allocation,
            budget_amount: campaign.budget_amount,
            budget_type: campaign.budget_type,
            start_date: dateRange.startDate.toISOString(),
            end_date: dateRange.endDate.toISOString()
        })
            .then(data => dispatch({
                type: EXPORTS_GETBYID_RESULT,
                ...data.inventory_export
            }))
            .finally(() => setSaving(false));
    };

    return (<>
        <GenericDialog
            title={t('Campaign Dates')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'xs'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<Box>
                <Box mt={1}>
                    <DateRangeInput
                        size="small"
                        meta={{}}
                        input={{
                            value: dateRange,
                            onChange: (value) => setDateRange(value, true)
                        }}
                        fullWidth
                        mobilePickerProps={{
                            disablePast: true,
                            autoOk: true,
                            emptyLabel: t('No End Date'),
                            // eslint-disable-next-line react/display-name
                        }}
                        pickerProps={{
                            showMonthAndYearPickers: false,
                            showDateDisplay: false,
                            months: 2,
                            minDate: new Date(),
                            direction: "horizontal",
                        }}
                        format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                            ? "MMM DD"
                            : "MMM DD, YYYY"}
                    />
                </Box>
            </Box>}

            ActionsComponent={<>
                <Button onClick={handleClose}>
                    {t('Close')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    onClick={async () => {
                        await onSave()
                        handleClose();
                    }}
                    variant="contained"
                    color="primary">
                    {t('Save')}
                </ButtonLoader>
            </>}
        />
    </>)
}
