import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clx from 'classnames';
import { connect } from 'react-redux';
import { Share } from '@capacitor/share';
import { Link, Button, CardActions, CardContent, CardHeader, Divider, makeStyles, Box } from '@material-ui/core';
import { IonCard, IonImg } from '@ionic/react'
import { Share as ShareIcon } from '@material-ui/icons';
import DateTimeRelative from '../DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { RoutePaths } from '../../helpers/constants';
import { useState } from 'react';
import { sortDate } from '../../helpers/sorters';
import { getFullUrl } from '../../helpers/history';
import { LikeButton, CommentWall, CommentButton, ReactionsBar } from '../../containers/reactions';

const useStyles = makeStyles(theme => {
    return {
        card: {
            maxWidth: 600,
            margin: 'auto',
        },
        btn: {
            width: 'calc(100% / 3)',
            height: 36
        },
        commentBtn: {
            marginRight: 'auto'
        },

        comments: {
            marginTop: theme.spacing(2)
        },

        reactionsBar: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        }
    }
})

const BaseStory = (props) => {
    const { share, sharableText } = props;
    const { commentsOpenDefault = false, classes: classesProps = {} } = props;

    const stories = props.story.grouped
        ? props.story.stories.map(x => x.story)
        : [props.story];

    const story = stories[0];

    const likesCount = stories.reduce((accum, x) => accum + x.reaction_count, 0);
    const comments = stories
        .reduce((accum, x) => [...accum, ...x.comments], [])
        .sort((a, b) => sortDate(b.created_at, a.created_at));

    const classes = useStyles();

    const [likesCountLocally, setLikesCountLocally] = useState(likesCount);

    const [commentsOpen, setCommentsOpen] = useState(commentsOpenDefault);

    const {
        Avatar,
        title,
        Media,
        imageSrc,
        StorySubMedia,
        children
    } = props;

    return <IonCard className={clx(classes.card, classesProps.card)}>
        <CardHeader
            avatar={Avatar}
            title={story.storyable.slug
                ? <Link
                    variant='body1'
                    component={RouterLink}
                    to={RoutePaths.public.slug(story.storyable.slug)}
                    color="secondary">
                    {title}
                </Link>
                : title}
            subheader={<Link component={RouterLink} color="textSecondary" to={RoutePaths.public.slug(story.lcuid)}>
                <BaseStoryDateTime createdAt={story.created_at}/>
            </Link>}
        />
        <CardContent>
            {children}
        </CardContent>
        <Box mb={1}>
            {Media}
            {imageSrc &&
                <IonImg src={imageSrc} />}
        </Box>

        {StorySubMedia && <Box pt={3} pb={2} style={{ textAlign: "center" }}>{StorySubMedia}</Box>}

        <ReactionsBar
            className={classes.reactionsBar}
            lcuid={story.lcuid}
            likesCount={likesCountLocally}
            commentsCount={comments.length}
            onCommentCountClick={() => setCommentsOpen(!commentsOpen)}
        />
        <Divider />
        <CardActions>
            <LikeButton
                className={classes.btn}
                lcuid={story.lcuid}

                likeValue={Boolean(story._current_user_liked)}
                onClick={liked => setLikesCountLocally(liked
                    ? likesCountLocally + 1
                    : likesCountLocally - 1)}
            />

            <CommentButton
                style={{ width: 'calc(100% / 3)' }}
                className={clx(classes.btn, classes.commentBtn)}
                onClick={() => setCommentsOpen(!commentsOpen)}
            />

            <Button
                style={{ width: 'calc(100% / 3)' }}
                className={clx(classes.btn)}
                onClick={() => share(story, title, sharableText)}
                startIcon={<ShareIcon />}
                color="primary">Share</Button>
        </CardActions>

        {commentsOpen
            && <>
                <Divider />
                <Box mr={2} ml={2} mt={1} mb={1}>
                    <CommentWall className={classes.comments}
                        comments={comments}
                        lcuid={story.lcuid}
                        autoFocus={commentsOpenDefault} />
                </Box>
            </>}
    </IonCard>;
};

const BaseStoryDateTime = ({createdAt}) => {

    const createdAtIsFromPreviousYear = new Date(createdAt).getFullYear() !== new Date().getFullYear();
    const dateTimeFormat = createdAtIsFromPreviousYear ? 'MMMM DD, YYYY [at] h:mm A' : 'MMMM DD [at] h:mm A';

    return <DateTimeRelative date={toLocalDateTime(createdAt)} format={dateTimeFormat} />;
}

const mapDispatchToProps = () => {
    return {
        share: (story, title, text) => {
            return Share.share({
                title: title,
                text: text,
                url: getFullUrl(RoutePaths.public.slug(story.lcuid)),
                dialogTitle: 'Share story',
            });
        },
    }
}

export default connect(
    null,
    mapDispatchToProps
)(BaseStory);
