import { PUSH_NOTIFICATIONS_RECEIVED, PUSH_NOTIFICATIONS_MARK_ALL_READ, PUSH_NOTIFICATIONS_UNREAD_COUNT, PUSH_NOTIFICATIONS_GET_RESULT, PUSH_NOTIFICATIONS_MARK_READ } from "../helpers/actionTypes"

export const initialState = {
    unreadCount: 0,

    // {
    // created_at: "2020-09-08T22:03:24.000000Z"
    // data: {title: "Export Changed", body: "The export SC Marketing New York Billboards has changed",…}
    // id: "71867d00-b2f5-41fb-a182-1da9f5f6582c"
    // notifiable_id: 2
    // notifiable_type: "App\User"
    // read_at: "2020-09-08T22:03:29.000000Z"
    // type: "App\Notifications\ExportChangedNotification"
    // updated_at: "2020-09-08T22:03:29.000000Z"
    // }
    items: []
}

export default function pushNotifications(state = initialState, action) {
    switch (action.type) {

        case PUSH_NOTIFICATIONS_RECEIVED:
            return Object.assign({}, state, {
                items: [
                    ...state.items,
                    action.notification
                ],
                unreadCount: state.unreadCount + 1
            })
        case PUSH_NOTIFICATIONS_MARK_READ:
            {
                const notification = state.items.find(x => x.id === action.notificationId);

                return Object.assign({}, state, {
                    items: state.items.map(x => {
                        if (x === notification) {
                            return Object.assign({}, x, { read_at: action.readAt })
                        }

                        return x;
                    }),
                    unreadCount: notification.read_at
                        ? state.unreadCount
                        : Math.max(state.unreadCount - 1, 0)
                });
            }

        case PUSH_NOTIFICATIONS_MARK_ALL_READ:
            return Object.assign({}, state, {
                items: state.items.map(x => Object.assign({}, x, { read_at: action.readAt })),
                unreadCount: 0
            });
        case PUSH_NOTIFICATIONS_UNREAD_COUNT:
            return Object.assign({}, state, {
                unreadCount: action.count
            });
        case PUSH_NOTIFICATIONS_GET_RESULT:
            return Object.assign({}, state, {
                items: action.data
            });

        default:
            return state
    }
}
