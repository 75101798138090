import React, { useState } from 'react';
import { Box, Button, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import AddScreenGroupButton from './AddScreenGroupButton';
import LCTypography from '../../components/material/LCTypography';
import { BillboardIcon, InventoryExportCampaignClasses } from "../../helpers/constants";
import { selectScreenGroupScreens } from '../../selectors/lightning';
import { Edit, MoreVert, Tv } from '@material-ui/icons';
import GroupCampaignDeleteButton from './GroupCampaignDeleteButton';
import EditScreenGroupScreensDialog from './EditScreenGroupScreensDialog';
import { CampaignGroupIcon, ExportIcon } from '../../components';
import EditScreenGroupCampaignsDialog from './EditScreenGroupCampaignsDialog';
import { IconFromSet } from '../../components/IconFromSet';
import ScreenGroupDialog from './ScreenGroupDialog';
import { getStylesByName } from '../../components/StylePicker';

const ScreenGroups = () => {
    const agencyExportsLoaded = useSelector(state => state.lightning.dataStatus.agencyExports);
    const screenGroups = useSelector(state => state.lightning.agencyExports.filter(
        x => x.campaign_class === InventoryExportCampaignClasses.screenGroup.class
    ));

    const { t } = useTranslation();

    const getScreenGroupScreens = useSelector(selectScreenGroupScreens);

    const columnStyle = { padding: '4px 12px' }
    const columns = [
        {
            title: t('Name'),
            filtering: true,
            sorting: true,
            width: '40%',
            field: 'name',
            cellStyle: columnStyle,
            render: x => {
                return <Chip
                    icon={<IconFromSet
                        iconKey={x.options?.ui_options?.layout?.icon}
                        style={{
                            color: getStylesByName(x.options?.ui_options?.layout?.styleName)?.color
                        }}
                        FallbackIcon={Tv} />}
                    key={x.lcuid}
                    label={x.name}
                    style={getStylesByName(x.options?.ui_options?.layout?.styleName)}
                />
            },
        },
        {
            title: t('Screens in Group'),
            filtering: true,
            sorting: true,
            width: '10%',
            cellStyle: columnStyle,
            render: x => {
                const screens = getScreenGroupScreens(x);

                if (screens.length === 0) {
                    return null;
                }

                return <>{screens.length}</>
            },
        },
        {
            title: t('Campaigns'),
            filtering: true,
            sorting: true,
            field: 'name',
            width: '40%',
            cellStyle: columnStyle,
            render: x => <CampaignsColumns value={x} />
        },
        {
            title: t('Actions'),
            width: '10%',
            filtering: false,
            sorting: false,
            cellStyle: columnStyle,
            render: x => <ActionsColumn value={x} />
        }
    ];

    return <Box pl={1} pt={1}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <LCTypography>{'Screen groups are for organizing your screens'}</LCTypography>
            <AddScreenGroupButton groupType={InventoryExportCampaignClasses.screenGroup.code} />
        </Box>

        <MaterialTable
            title={''}
            columns={columns}
            data={screenGroups}
            isLoading={!agencyExportsLoaded}
            components={{
                OverlayLoading: () => (
                    <div>
                        <CircularProgressCentered />
                    </div>
                )
            }}
            options={{
                emptyRowsWhenPaging: true,
                pageSize: 100,
                pageSizeOptions: [100, 250],
                debounceInterval: 100,
                headerStyle: {},
                paging: screenGroups?.length > 100,
                search: true,
                showTitle: true,
                sorting: true,
                selection: false,
                toolbar: true,
                draggable: false,
                tableLayout: "fixed" //TODO: this is not working at all... see https://github.com/mbrn/material-table/issues/3035
            }}
        />
    </Box>
}

const CampaignsColumns = ({ value }) => {
    const [showMore, setShowMore] = useState(false);

    if (value?.child_exports == null || value?.child_exports.length == 0) {
        return null;
    }

    return <Box display="flex">
        <Box display="flex" style={{ gap: 4, flexWrap: 'wrap' }}>
            {value.child_exports
                .slice(0, showMore ? value.child_exports.lenght : 1)
                .map((g, index) => <>
                    <Chip
                        icon={g.campaign_class === InventoryExportCampaignClasses.group.class
                            ? <IconFromSet
                                className="MuiChip-icon"
                                style={{
                                    color: getStylesByName(g.options?.ui_options?.layout?.styleName).color
                                }}
                                iconKey={g.options?.ui_options?.layout?.icon}
                                FallbackIcon={CampaignGroupIcon} />
                            : <ExportIcon />}
                        key={index}
                        style={getStylesByName(g.options?.ui_options?.layout?.styleName)}
                        label={g.name}
                    />
                </>)}
        </Box>

        {value.child_exports.length > 1
            && !showMore
            && <Button
                onClick={() => setShowMore(true)}
                style={{ width: 70, marginLeft: 8, textTransform: 'none' }}
                color="secondary">
                <Typography variant="body2">+{value?.child_exports.length - 1} more</Typography>
            </Button>}
    </Box>
}

const ActionsColumn = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isEditScreensOpen, setIsEditScreensOpen] = useState(false);
    const [isEditCampaignsOpen, setIsEditCampaignsOpen] = useState(false);
    const { t } = useTranslation();

    return <>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{ disablePadding: true }}
        >
            <MenuItem onClick={() => {
                setIsEditOpen(true);
                setAnchorEl(null);
            }}>
                <ListItemIcon ><Edit /></ListItemIcon>
                <ListItemText primary={t("Edit")} />
            </MenuItem>
            <MenuItem onClick={() => {
                setIsEditScreensOpen(true)
                setAnchorEl(null)
            }}>
                <ListItemIcon ><BillboardIcon /></ListItemIcon>
                <ListItemText primary={t("Screens")} />
            </MenuItem>
            <MenuItem onClick={() => {
                setIsEditCampaignsOpen(true)
                setAnchorEl(null)
            }}>
                <ListItemIcon ><ExportIcon /></ListItemIcon>
                <ListItemText primary={t("Campaigns")} />
            </MenuItem>
            <Divider />
            <GroupCampaignDeleteButton
                groupCampaign={value}
                handleDeleted={() => setAnchorEl(null)}
            />
        </Menu>

        {isEditScreensOpen
            && <EditScreenGroupScreensDialog
                group={value}
                open={isEditScreensOpen}
                handleClose={() => setIsEditScreensOpen(false)}
            />}
        {isEditCampaignsOpen
            && <EditScreenGroupCampaignsDialog
                group={value}
                open={isEditCampaignsOpen}
                handleClose={() => setIsEditCampaignsOpen(false)}
            />}

        {isEditOpen
            && <ScreenGroupDialog
                open={isEditOpen}
                handleClose={() => setIsEditOpen(false)}
                group={value} />}
    </>
}

export default ScreenGroups;
