import React from 'react';
import PropTypes from 'prop-types';
import { getProof } from '../../../actions/creativeProofs';
import { openOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ButtonLoader } from '../../../components/ionic';
import { useHistory } from 'react-router';
import { RoutePaths } from '../../../helpers/constants';
import { inventoryItemClass } from '../../../selectors/inventory';

const ProofButtonLink = ({ item, getProof }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    if (!inventoryItemClass(item).showProofButton)
        return null

    return <ButtonLoader
        color="primary"
        variant="contained"
        size="small"
        startIcon={openOutline}
        submitting={loading}
        onClick={() => {
            setLoading(true);
            getProof(item.id)
                .then(x => {
                    history.push({ pathname: RoutePaths.public.slug(x.lcuid) })
                })
                .catch(() => setLoading(false))
        }}
    >
        {t('View Proof')}
    </ButtonLoader>

}

ProofButtonLink.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProof: (id) => dispatch(getProof(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ProofButtonLink
);
