import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";

import {
    FEEDS_SEARCH_REQUEST, FEEDS_SEARCH_RESULT, FEEDS_NEXT_PAGE_REQUEST, FEEDS_NEXT_PAGE_RESULT, FEEDS_SAVE_SCROLL_POSITION,
    USER_SELECT_ACCOUNT, FEEDS_RUN, FEEDS_GETBYID_RESULT, FEEDS_STATUS_UPDATE, FEEDS_DELETE,
    FEEDS_SET_DEFAULT_TEMPLATE, FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES, FEEDS_UPDATE_PARAM_VALUES, FEEDS_UPDATE, FEEDS_UPDATE_OVERRIDE_VALUES
} from "../helpers/actionTypes";
import { FeedStatus } from "../helpers/constants";

export const initialState = {
    filters: null,
    searched: false,
    data: [],

    loadingNextPage: false,

    currentPage: 1,
    perPage: 100,
    lastPage: 1,
    from: 0,
    to: 0,
    total: 0,

    scrollY: null,

    invalidated: true
}

export const persistConfig = {
    key: 'feeds',
    storage: localStorage,
    blacklist: ["loadingNextPage"],
    stateReconciler: autoMergeLevel2,
}

export default function FEEDS(state = initialState, { type, ...payload }) {
    switch (type) {
        case USER_SELECT_ACCOUNT:
            return Object.assign({},
                state,
                {
                    filters: null,
                    data: [],
                    invalidated: true
                });
        case FEEDS_SEARCH_REQUEST:
            return Object.assign({}, state, payload);

        case FEEDS_SEARCH_RESULT:
            return Object.assign({},
                state,
                payload,
                {
                    searched: true,
                    scrollY: null,
                    invalidated: false
                });
        case FEEDS_NEXT_PAGE_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingNextPage: true
                });
        case FEEDS_NEXT_PAGE_RESULT: {
            const { data = [], ...rest } = payload;

            return Object.assign({},
                state,
                rest,
                {
                    loadingNextPage: false,
                    data: state.data.concat(data)
                });
        }

        case FEEDS_SET_MULTIPLE_DRIVE_TEMPLATES:
        case FEEDS_SET_DEFAULT_TEMPLATE:
        case FEEDS_UPDATE_PARAM_VALUES:
        case FEEDS_UPDATE_OVERRIDE_VALUES:
        case FEEDS_UPDATE:
        case FEEDS_GETBYID_RESULT: {
            const feed = state.data.find(x => x.id === payload.id);

            return Object.assign({}, state, {
                data: feed
                    ? state.data.map(x => x.id === payload.id
                        ? Object.assign({}, x, payload)
                        : x)
                    : [
                        ...state.data,
                        payload
                    ]
            })
        }

        case FEEDS_STATUS_UPDATE:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.id) {
                        x.status = payload.status;
                    }

                    return x;
                })
            })

        case FEEDS_DELETE: {

            return Object.assign({}, state, {
                data: state.data.filter(x => x.id !== payload.feed.id),
            });

        }

        case FEEDS_SAVE_SCROLL_POSITION:
            return Object.assign({}, state, {
                scrollY: payload.scrollY
            })
        case FEEDS_RUN:
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.id === payload.id) {
                        return {
                            ...x,
                            status: FeedStatus.running
                        }
                    }

                    return x;
                })
            })
        default:
            return state
    }
}
