import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
    return {
        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        },

        chip: {
            borderRadius: 16
        }
    }
})

function FeedDetailsSkeleton() {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3} lg={2}>
                    <Skeleton variant="rect" height={150} width="100%" />
                </Grid>
                <Grid item xs={12} sm={9} lg={10}>
                    <Box mb={2}>
                        <Skeleton variant="rect" height={32} width="100%" style={{ maxWidth: 400 }} />
                    </Box>
                    <Box mb={2} className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" height={24} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" height={24} width={140} />
                        </Box>
                    </Box>
                    <Box mb={2} className={classes.attribute}>
                        <Box className={classes.attributeTitle}>
                            <Skeleton variant="rect" height={24} />
                        </Box>
                        <Box className={classes.attributeValue}>
                            <Skeleton variant="rect" height={32} width={60} className={classes.chip} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default FeedDetailsSkeleton;
