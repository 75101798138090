import React, { useState, useEffect } from 'react';
import { lucitApi } from '../../services/lucitApi';
import MaterialTable from 'material-table';
import { Box } from '@material-ui/core';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { useTranslation } from 'react-i18next';
import { isFeedRunningOrWillBe } from '../../selectors/feeds';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { Global } from '../../helpers/constants';

const FeedDataKeyValueStore = ({ feed, active }) => {

    const [keyValueStore, setKeyValueStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const shouldKeepCheckingUpdates = () => {
        return isFeedRunningOrWillBe(feed)
    }

    const { reset } = useIntervalLimited(() => reloadFeed(),
        {
            running: shouldKeepCheckingUpdates(),
            milliseconds: Global.itemExportsStatusUpdateInterval
        })

    const reloadFeed = () => {
        if (feed) {
            lucitApi.feeds.getFeedKeyValueStores(feed.id, active).then((response) => {
                setKeyValueStores(response);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        reloadFeed();
    }, [feed?.status, feed?.updated_at, active, reset]);

    return <Box>

        <MaterialTable
            title={''}
            columns={
                [
                    {
                        title: t('Key Name'),
                        filtering: true,
                        sorting: true,
                        field: "key_name",
                        render: x => x.key_name,
                    },
                    {
                        title: t('Key'),
                        filtering: true,
                        sorting: true,
                        width: 100,
                        field: 'key',
                        render: x => x.key,
                    },
                    {
                        title: t('Value'),
                        filtering: true,
                        sorting: true,
                        field: 'value',
                        render: x => x.value,
                    },
                    {
                        title: t('Attached To'),
                        filtering: false,
                        sorting: true,
                        field: 'object_type',
                        render: x => x.object && x.object.name && x.object.name,

                    },
                    {
                        title: t('Created'),
                        filtering: false,
                        sorting: true,
                        field: 'created_at',
                        render: x => <DateTimeRelative date={toLocalDateTime(x.created_at)} />,
                        customSort: (a, b) => {
                            return new Date(a.created_at) - new Date(b.created_at)
                        }
                    },
                ]
            }
            isLoading={loading}
            data={keyValueStore}
            components={{
                OverlayLoading: () => (
                    <div>
                        <CircularProgressCentered />
                    </div>
                )
            }}
            options={{
                emptyRowsWhenPaging: true,
                pageSize: 100,
                pageSizeOptions: [100, 250],
                debounceInterval: 100,
                headerStyle: {},
                paging: keyValueStore && keyValueStore.length > 100,
                search: true,
                showTitle: true,
                sorting: true,
                selection: false,
                toolbar: true,
                draggable: false,
                tableLayout: 'auto'
            }}
        />

    </Box>

}

export default FeedDataKeyValueStore;
