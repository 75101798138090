import React from 'react';
import PropTypes from 'prop-types';

const BillboardSkeleton = props => {
    // ration = width / height
    const {
        ratio = 0.96,
        photoSrc,

        legType = BillboardLegType.none,

        SvgProps = {}
    } = props;

    const screenStrokeWidth = 6;

    const defaultUnits = 100;

    // The amount of height which is given for board leg and board foot
    const legHeightPercentage = 0.5;

    // SVG viewbox will have same ratio as original board
    // but we will zoom out board in order to show also board leg and board foot
    const totalViewBoxHeight = defaultUnits + defaultUnits * legHeightPercentage + 2 * screenStrokeWidth;
    const totalViewBoxWidth = defaultUnits * ratio + 2 * screenStrokeWidth;

    const screenHeight = defaultUnits;
    const screenWidth = defaultUnits * ratio;

    const screenX = screenStrokeWidth;
    const screenY = screenStrokeWidth;

    return (
        <svg
            width="400"
            height="200"

            {...SvgProps}

            viewBox={`0 0 ${totalViewBoxWidth} ${totalViewBoxHeight}`}>
            <rect
                id="screen"

                fill="transparent"
                strokeWidth={screenStrokeWidth}
                style={{ stroke: 'black' }}

                x={screenX}
                y={screenY}

                width={screenWidth}
                height={screenHeight}></rect>

            <image href={photoSrc}
                x={screenX}
                y={screenY}

                height={screenHeight}
                width={screenWidth}
            />

            {legType === BillboardLegType.simpleCentered
                && <SimpleCenteredLeg
                    {...{
                        defaultUnits,
                        screenWidth,
                        screenHeight,
                        screenX,
                        screenY,
                        legHeightPercentage
                    }}
                />}
        </svg>
    )
};

const SimpleCenteredLeg = props => {
    const { defaultUnits,
        screenWidth,
        screenHeight,
        screenX,
        screenY,
        legHeightPercentage } = props;

    const legHeight = defaultUnits * legHeightPercentage;
    const legWidth = 5;

    const footWidth = defaultUnits * 0.2;
    const footHeight = 5;

    return <>
        <rect
            id="leg"

            fill="transparent"
            style={{ fill: 'black' }}

            x={screenX + screenWidth / 2 - legWidth / 2}
            y={screenY + screenHeight}

            width={legWidth}
            height={legHeight}></rect>

        <rect
            id="foot"

            fill="transparent"
            style={{ fill: 'black' }}

            x={screenX + screenWidth / 2 - footWidth / 2}
            y={screenY + screenHeight + legHeight - footHeight}

            width={footWidth}
            height={footHeight}></rect>
    </>
}

export const BillboardLegType = {
    simpleCentered: 'simpleCentered',
    none: 'none'
}

BillboardSkeleton.propTypes = {
    ratio: PropTypes.number.isRequired,
    photoSrc: PropTypes.string.isRequired,

    legType: PropTypes.string
}

export default BillboardSkeleton;
