import React from 'react';
import { Helmet } from "react-helmet";
import { getAppName } from '../../helpers/environment'

const PageTitle = ({ prefix = 'Lucit |', title }) => {

  const titlePrefix = prefix ? (
    getAppName() ? getAppName() : prefix) : '';

  return (
    <Helmet>
      <title>{`${titlePrefix} ${title}`}</title>
    </Helmet>
  );
};

export default PageTitle;
