import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Comment as CommentIcon } from '@material-ui/icons';

const CommentButton = (props) => {
    return <Button
        startIcon={<CommentIcon />}
        color="primary"
        {...props}>Comment</Button>;
};

CommentButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
}

export default CommentButton;
