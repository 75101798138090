import React from 'react';
import { useSelector } from 'react-redux';
import { currentUserHasPermission } from '../../selectors/user';

const PolicyFeatureToggle = ({ policy, reverse, children, mode = "hide", disableRootNodeStyle = {} }) => {

    const hasPermission = useSelector(state => currentUserHasPermission(state)(policy))

    const styleForDisabledRootNode = {
        pointerEvents: "none",
        opacity: "0.3",
        ...disableRootNodeStyle
    }

    // Reverse actually means to hide content if feature is enabled
    if (reverse && mode === "hide") {
        return hasPermission
            ? null
            : children;
    }

    if (reverse && mode === "disable") {
        return hasPermission
            ? <div style={styleForDisabledRootNode}>{children}</div>
            : children;
    }

    if (mode === "hide") {
        return !hasPermission
            ? null
            : children;
    }

    if (mode === "disable") {
        return !hasPermission
            ? <div style={styleForDisabledRootNode}>{children}</div>
            : children;
    }
}

export default PolicyFeatureToggle
