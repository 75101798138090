import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Search as SearchIcon } from '@material-ui/icons';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import SearchBar from './SearchBar';

const useStyles = makeStyles(theme => {
    return {
        root: {
            color: theme.palette.common.white,
            marginRight: theme.spacing(1)
        },
    }
})

const SearchButton = () => {
    const [isSearchOpen, setSearchOpen] = useState(false);
    const classes = useStyles();

    return (
        <Box>
            <IconButton
                className={classes.root}
                color="default"
                onClick={() => setSearchOpen(true)}
            >
                <SearchIcon />
            </IconButton>

            <SearchBar open={isSearchOpen} onClose={() => setSearchOpen(false)} />
        </Box>
    );

}

export default withRouter(SearchButton);
