import SampleCustomJsonDropDown from './SampleCustomJsonDropDown';
import GooglePlaceFinder from './GooglePlaceFinder';
import FacebookPageTokenFinder from './FacebookPageTokenFinder';

const CustomComponents = {
    "SampleCustomJsonDropDown": SampleCustomJsonDropDown,
    "GooglePlaceFinder": GooglePlaceFinder,
    "FacebookPageTokenFinder": FacebookPageTokenFinder,

}

export default CustomComponents
