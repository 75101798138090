import React, { useState, useRef } from 'react';
import { List, Box, Divider, Link, makeStyles, Popover, useTheme, useMediaQuery, ListItemIcon, ListItem, ListItemText, Avatar, ListSubheader, TextField, Tooltip, InputAdornment } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Search } from '@material-ui/icons';
import { AccountAddIcon } from '../../components/icons';
import { profiles, selectedProfile } from '../../selectors/user';
import { hasIcon } from '../../selectors/account';
import { AppTour, Global, RoutePaths } from '../../helpers/constants';
import { toggleProfileSwitcher } from '../../actions/layout';
import ProfileListItems from './ProfileListItems';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount';
import { useTranslation } from 'react-i18next';
import { attachTours } from '../../helpers/tour';
import { hasAgencies, getUser } from '../../selectors/user';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            width: 24,
            height: 24
        },
        list: {
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(45),
            },
            width: '100%',
            maxHeight: theme.spacing(50),
            overflow: 'auto'
        },
        listSubheader: {
            paddingTop: 12,
            background: 'white'
        },
        listItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    }
})

const ProfileMenuPopper = (props) => {
    const { anchorEl, open, onClose, selectedProfile, hasAgencies } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const [showAddNewAccount, setShowAddNewAccount] = useState(false);
    const [search, setSearch] = useState("");

    //Auto select should be turned off once you start filtering the list for the first time
    const [shouldAutoSelect, setShouldAutoSelect] = useState(true);
    const { t } = useTranslation();

    const profiles = props.profiles.filter(profile => searchTermInText(search, [profile.name]));

    const listRef = useRef(null);

    const scrollListToTop = () => {
        listRef.current.scroll({ top: 0, behavior: 'smooth' })
    }

    const getBestWidth = () => {
        return isMd ? "calc(100% - 32px)" : "auto"
    }

    return (
        <Popover {... {
            anchorEl,
            open,
            onClose,
            onBackdropClick: onClose,
            BackdropProps: { invisible: !isMd },
            PaperProps: {
                style: { width: getBestWidth() },

                ...attachTours([
                    { id: AppTour.profileSwitcher.id, step: 1 }
                ])
            },
            TransitionProps: {
                onExited: () => setSearch('')
            },
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }
        }}>
            <Box display="flex" alignItems="center" p={2}>
                <Box mr={2}>
                    <Avatar
                        src={hasIcon(selectedProfile) && selectedProfile.options.primary_image_public_url}
                        name={selectedProfile.name} />
                </Box>
                <Box maxWidth={240}>
                    <Link component={RouterLink}
                        to={RoutePaths.public.slug(selectedProfile.slug)}>
                        <LCTypography transProps={{ selectedProfileName: selectedProfile.name }} variant="h6">
                            {{ selectedProfileName: selectedProfile.name }}
                        </LCTypography>
                    </Link>
                </Box>
            </Box>
            <Divider />
            <List className={classes.list}
                ref={listRef}
                subheader={Global.accountCountForFilter < props.profiles.length
                    && <ListSubheader className={classes.listSubheader}>
                        <SearchInput search={search} setSearch={setSearch} onFirstSearchChange={
                            () => {
                                scrollListToTop();
                                setShouldAutoSelect(false);
                            }
                        } />
                    </ListSubheader>}
                disablePadding>

                {profiles.length
                    ? <ProfileListItems
                        profiles={profiles}
                        autoFocusSelected={shouldAutoSelect}
                        showMoreEnabled={false}
                    />
                    : <ListItem>
                        <LCTypography transProps={{ search: search }}>No results for <i>{{ search: search }}</i></LCTypography>
                    </ListItem>}

                {hasAgencies && <ListItem button onClick={() => setShowAddNewAccount(true)}>
                    <ListItemIcon style={{ paddingLeft: 10 }}>
                        <AccountAddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('Add New Account')}
                        primaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
                }
            </List>

            <OperatorAddAccount showAgencyAndOperator={false} open={showAddNewAccount} handleClose={() => setShowAddNewAccount(false)} />
        </Popover>
    );
};

export const SearchInput = (props) => {
    const { search, setSearch, onFirstSearchChange } = props;
    const { t } = useTranslation();
    const [firstFilter, setFirstFilter] = useState(true);
    const theme = useTheme();

    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const autoFocusTheFilterField = () => {
        return !isMd    //Don't auto focus the input on mobile
    }

    return (<TextField
        autoFocus
        inputRef={input => input && autoFocusTheFilterField() && input.focus()}
        type="text"
        fullWidth
        size="small"
        variant="outlined"
        placeholder={t("Filter...")}
        inputProps={{
            "aria-autocomplete": "both",
            "aria-haspopup": "false",
            "autoComplete": "off",
            "spellCheck": "false"
        }}
        value={search || ''}
        onChange={(event) => {
            setSearch(event.target.value)
            if (firstFilter) {
                onFirstSearchChange();
                setFirstFilter(false);
            }
        }}

        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Tooltip title={t('Filter')}>
                    <Search fontSize="small" />
                </Tooltip>
            </InputAdornment>
        }}
    />)
}

const mapStateToProps = state => {
    return {
        profiles: profiles(state),
        selectedProfile: selectedProfile(state),
        hasAgencies: hasAgencies(getUser(state)),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openProfileSelector: () => dispatch(toggleProfileSwitcher(true)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ProfileMenuPopper
);
