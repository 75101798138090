import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { t } from 'i18next';
import { lucitApi } from '../../services/lucitApi';
import { useDispatch } from 'react-redux';
import { ButtonLoader } from '../../components';
import { LIGHTNING_DEVICE_LAYOUT_MAKE_DEFAULT } from '../../helpers/actionTypes';

//1.77777 is 16/9
const LightningDeviceLayout = ({ deviceLayout,
    aspectRatio = 1.77777,
    selected,
    onDeviceLayoutSelected,
    showSetAsDefault,
    width = 250 }) => {
    const height = width / aspectRatio;
    const [isHovering, setIsHovering] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const dispatch = useDispatch();
    const setDefault = () => {
        setSubmitting(true);
        lucitApi.lightning.makeDefaultLayoutDefault(deviceLayout?.lcuid)
            .then(() => dispatch({ type: LIGHTNING_DEVICE_LAYOUT_MAKE_DEFAULT, deviceLayout }))
            .finally(() => setSubmitting(false))
    };

    return <Box>
        <Box
            style={{
                width: `${width}px`,
                height: `${height}px`,
                backgroundColor: '#a0a0a0',
                cursor: 'pointer',
                border: selected || isHovering ? '2px solid #000' : '2px solid transparent',
                position: 'relative',
            }}
            onClick={onDeviceLayoutSelected ? onDeviceLayoutSelected : () => null}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {deviceLayout?.data?.screens?.map((screen, index) => {
                let customScreenSyles = {};

                try {
                    customScreenSyles = screen.styles ? JSON.parse(screen.styles) : {};
                }
                catch (_) {
                    customScreenSyles = {};
                }

                return <Box key={index} style={{
                    position: 'absolute',
                    left: `${screen.position?.left}`,
                    top: `${screen.position?.top}`,
                    width: `${screen.size?.width}`,
                    height: `${screen.size?.height}`,
                    border: '2px dashed #ffffff',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...customScreenSyles
                }}>
                    {screen.name}
                </Box>
            })}

        </Box>
        <Box style={{
            textAlign: 'center',
            marginTop: '10px'
        }}>
            <Typography gutterBottom>{deviceLayout.name}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
            {deviceLayout.is_default
                ? <Typography><i>{t('Default')}</i></Typography>
                : showSetAsDefault &&
                <ButtonLoader
                    submitting={submitting}
                    onClick={setDefault}
                    variant='outlined'
                    size="small">{t('Set as Default')}</ButtonLoader>}
        </Box>
    </Box>
}

export default LightningDeviceLayout;
