import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Checkbox, Divider, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PROGRAMMATIC_FILTERS_CHANGE } from '../../helpers/actionTypes';
import FilterButton from './FilterButton';
import { CircularProgressCentered, SearchInput } from '../../components';
import { useTranslation } from 'react-i18next';
import { debouncePromise } from '../../helpers/async';
import { hasIcon } from '../../selectors/exports';
import { Business } from '@material-ui/icons';
import LCTypography from '../../components/material/LCTypography';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles((theme) => {
    return {
        filterButtonSelected: {
            color: theme.palette.primary.main,
        },
        list: {
            maxHeight: 300,
            overflow: 'auto',
            margin: '0 -16px',
            padding: '0 8px;'
        },
        subheader: {
            lineHeight: '48px',
            background: 'white',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        avatar: {
            width: 32,
            height: 32
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
        },
    }
})

const AgenciesFilter = () => {
    const [opened, setOpened] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [query, setQuery] = useState('');
    const searchDebounce = useCallback(debouncePromise(setQuery, 800), []);

    const [optionsSorted, setOptionsSorted] = useState([]);
    const [agencies, setAgencies] = useState({});
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const programmatic = useSelector(state => state.programmatic);

    const options = useSelector(state => state.programmatic.agency_options) ?? [];
    const loading = useMemo(() => !options, [options]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const DROP_DOWN_LIMIT = 50;

    useEffect(() => {
        if (query && options) {
            const filtered = options.filter(option => searchTermInText(query, [option.name]));

            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options ?? []);
        }
    }, [query, options]);

    useEffect(() => {
        if (opened == false) {
            setQuery('');
            setSearchInput('');
        }
    }, [opened])

    useEffect(() => {
        const agenciesDictionary = programmatic.agencies
            .reduce((prev, curr) => ({
                ...prev,
                [curr.lcuid]: curr
            }), {})
        setAgencies(agenciesDictionary);
    }, [programmatic.agencies, opened])

    const onChange = useCallback((option) => {
        if (agencies[option.lcuid]) {
            delete agencies[option.lcuid]
        } else {
            agencies[option.lcuid] = option;
        }

        setAgencies({ ...agencies })
    }, [agencies]);

    const agenciesCount = useMemo(() => Object.keys(agencies).length, [agencies])

    const onChangeAll = useCallback(() => {
        if (filteredOptions.length == agenciesCount) {
            setAgencies({})
        } else {
            setAgencies(filteredOptions.reduce((prev, curr) =>
                ({ ...prev, [curr.lcuid]: curr }),
                {}
            ));
        }
    }, [agenciesCount, filteredOptions])

    /**
     * This useEffect creates a sorted version of the options
     * where the selected ones are listed first
     * But, we only do this when the list is not opened up
     * So we don't cause weird UI behavior
     */
    useEffect(() => {

        if (!opened) {
            //Only do the sorting when the list is not opened up
            const sortedOptions = filteredOptions.sort((a, b) => {
                const aHasId = Boolean(agencies[a.lcuid])
                const bHasId = Boolean(agencies[b.lcuid])

                if (aHasId && !bHasId) {
                    return -1;
                } else if (!aHasId && bHasId) {
                    return 1;
                } else {
                    return b.count - a.count;
                }
            })

            setOptionsSorted(sortedOptions)
        }

    }, [filteredOptions, agencies, opened]);

    return <FilterButton
        className={programmatic.agencies.length > 0
            ? classes.filterButtonSelected
            : null}
        title={programmatic.agencies.length > 0
            ? `${programmatic.agencies.length} ` + t('Media Companies')
            : t('Media Companies')}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        onApply={() => {
            dispatch({
                type: PROGRAMMATIC_FILTERS_CHANGE,
                agencies: Object.values(agencies)
            })
        }}
    >
        <Typography className={classes.subheader}>
            {t('Media Companies')}
        </Typography>
        <Box mb={1}>
            <SearchInput
                search={searchInput}
                setSearch={e => {
                    setSearchInput(e);
                    searchDebounce(e);
                }} />
        </Box>
        <List disablePadding className={classes.list}>
            {query
                && <ListItem
                    style={{ padding: 0, cursor: 'pointer' }}
                    onClick={() => onChangeAll()}
                    disableGutters>
                    <Checkbox
                        size='small'
                        classes={{ indeterminate: classes.indeterminate }}
                        indeterminate={agenciesCount > 0 && filteredOptions.length != agenciesCount}
                        checked={filteredOptions.length == agenciesCount}
                    />
                    <ListItemText>{t('Select All')}</ListItemText>
                </ListItem>}
            <Divider />
            {loading
                && <CircularProgressCentered />}
            {!loading
                && Boolean(query)
                && filteredOptions.length === 0
                && <Box mt={2} mb={1} textAlign="center">
                    <LCTypography>No results found for <i>{query}</i></LCTypography>
                </Box>}
            {!loading
                && (query ? filteredOptions : optionsSorted).slice(0, DROP_DOWN_LIMIT).map(o => (<ListItem
                    key={o.lcuid}
                    style={{
                        padding: 0,
                        cursor: 'pointer',
                        opacity: o.count ? 1 : 0.5
                    }}
                    onClick={() => onChange(o)}
                    disableGutters>
                    <Checkbox
                        size='small'
                        checked={Boolean(agencies[o.lcuid])}
                    />
                    <ListItemText>{o.name + ` (` + o.count + `)`}</ListItemText>
                    {hasIcon(o)
                        ? <Avatar className={classes.avatar} style={{ background: 'none' }}>
                            <img
                                src={o.options.primary_image_public_url}
                                className={classes.image}
                                alt={o.name}
                                title={o.name}
                            />
                        </Avatar>
                        : <Avatar className={classes.avatar}>
                            <Business fontSize='small' />
                        </Avatar>}
                </ListItem>))}
            {filteredOptions.length > DROP_DOWN_LIMIT
                && !loading
                && <LCTypography variant='body2'>
                    <i>Showing first {DROP_DOWN_LIMIT.toString()} from {filteredOptions.length.toString()} total results
                    </i></LCTypography>}
        </List>
    </FilterButton>
};

export default AgenciesFilter;
