import {
    INVENTORYSTATUS_RESET_FILTERS, INVENTORYSTATUS_UNEXPORT_ITEMS,
    INVENTORYSTATUS_ITEM_STATUS_RESULT, INVENTORYSTATUS_EXPORT_ITEMS,
    INVENTORYSTATUS_EXPORT_ITEMS_STATUS_RESULT, INVENTORYSTATUS_EXPORT_ITEMS_STATUS_REQUEST,
    INVENTORYSTATUS_EXPORT_ITEMS_REFRESH_RESULT
} from "../helpers/actionTypes"

export function inventoryStatusReset() {
    return {
        type: INVENTORYSTATUS_RESET_FILTERS
    }
}

export function itemStatusResult(data) {
    return {
        type: INVENTORYSTATUS_ITEM_STATUS_RESULT,
        data
    }
}

export function exportItemsStatusRequest(data) {
    const { page, search, searchFields, ...rest } = data;
    return {
        type: INVENTORYSTATUS_EXPORT_ITEMS_STATUS_REQUEST,
        currentPage: page,

        filters: {
            search,
            searchFields
        },
        ...rest
    }
}

export function exportItemsStatusResult(data) {
    const { item_status } = data;
    const { current_page, last_page, per_page, ...rest } = item_status

    return {
        type: INVENTORYSTATUS_EXPORT_ITEMS_STATUS_RESULT,
        ...data,

        item_status: {
            currentPage: current_page,
            lastPage: last_page,
            perPage: per_page,

            ...rest,
        }
    }
}

export function exportItemsRefreshResult(data) {
    const { item_status } = data;
    const { data: itemStatusData } = item_status;

    return {
        type: INVENTORYSTATUS_EXPORT_ITEMS_REFRESH_RESULT,
        ...data,

        item_status: {
            data: itemStatusData,
        }
    }
}

export function itemsExported(exportId, itemIds) {
    return {
        type: INVENTORYSTATUS_EXPORT_ITEMS,
        exportId,
        itemIds
    }
}

export function itemsUnexported(exportId, itemIds) {
    return {
        type: INVENTORYSTATUS_UNEXPORT_ITEMS,
        exportId,
        itemIds
    }
}
