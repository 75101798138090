import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from '../helpers/environment';

const stripeGlobalOptions = {
    fonts: [
        { src: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' }
    ]
};

export const StripeProvider = (props) => {
    const { children } = props;

    const initStripe = useMemo(() => loadStripe(environment.STRIPE_API_KEY), []);

    return (
        <Elements stripe={initStripe} options={stripeGlobalOptions}>
            {children}
        </Elements>
    )
};

export default StripeProvider;
