import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const CircularProgressCentered = (props) => {
    const { className, title, rootStyle, ...rest } = props;

    return <Box
        className={className}
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '24px',

            ...rootStyle
        }}
    >
        <CircularProgress color="secondary" {...rest} />
        {title && <Typography style={{ marginTop: 12 }}>{title}</Typography>}
    </Box>
};

export default CircularProgressCentered;
