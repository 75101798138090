import React, { useState, useEffect } from 'react';
import { lucitApi } from '../../services/lucitApi';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { Box, Link, Tooltip } from '@material-ui/core';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import DateTimeRelative from '../../components/DateTimeRelative';
import { toLocalDateTime } from '../../helpers/date';
import { Link as RouterLink } from 'react-router-dom';
import useIntervalLimited from '../../helpers/hooks/useIntervalLimited';
import { isFeedRunningOrWillBe } from '../../selectors/feeds';
import { Global } from '../../helpers/constants';

const FeedDataInventoryItems = ({ feed, active }) => {

    const [inventoryItems, setInventoryItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [photosPendingRetrieval, setPhotosPendingRetrieval] = useState(0);

    const hasPhotosPendingRetrieval = () => photosPendingRetrieval > 0;

    const shouldKeepCheckingUpdates = () => {
        return isFeedRunningOrWillBe(feed) || hasPhotosPendingRetrieval()
    }

    const { reset } = useIntervalLimited(() => reloadFeed(),
        {
            running: shouldKeepCheckingUpdates(),
            milliseconds: Global.itemExportsStatusUpdateInterval
        })

    const { t } = useTranslation();

    const reloadFeed = () => {
        if (feed) {
            lucitApi.feeds.getFeedInventoryItems(feed.id, active).then((response) => {
                setInventoryItems(response.inventory_items);
                setPhotosPendingRetrieval(response.photos_pending_retrieval_and_processing);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        reloadFeed();
    }, [feed?.status, feed?.updated_at, active, reset]);

    const columns = [
        {
            title: t('Image'),
            filtering: false,
            sorting: false,
            width: "10%",
            render: x => {
                return x.options.primary_image_public_url ?
                    <Link
                        component={RouterLink}
                        to={`/inventory/${x.id}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <Tooltip title={t("The primary image of this item")}>
                            <img style={{ width: 50 }} src={x.options.primary_image_public_url} />
                        </Tooltip>
                    </Link>
                    :
                    <span title={t("This item has no image, or the image is still downloading")}>--</span>
            },
        },
        {
            title: "",
            filtering: false,
            sorting: false,
            width: "10%",
            render: x => {
                return x.options.primary_image_background_removed_public_url ?
                    <Link
                        component={RouterLink}
                        to={`/inventory/${x.id}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <Tooltip title={t("The primary image with background automatically removed")}>
                            <img style={{ width: 50 }} src={x.options.primary_image_background_removed_public_url} />
                        </Tooltip>
                    </Link>
                    :
                    <></>
            },
        },
        {
            title: t('Creative'),
            filtering: false,
            sorting: true,
            //width: 20,
            customSort: (a, b) => {
                return a.creatives.length - b.creatives.length
            },
            render: x => {
                return x.creatives && x.creatives.length ?
                    <Link
                        component={RouterLink}
                        to={`/inventory/${x.id}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <img style={{ width: 150 }} src={x.options.best_creative_image_photo_url} />
                    </Link>
                    :
                    <span title={t("No creatives generated yet for this item")}>--</span>
            },
        },
        {
            title: t('Title'),
            filtering: true,
            sorting: true,
            field: 'title',
            render: x => (
                <Tooltip title={x.title} aria-label="description">
                    <Link
                        component={RouterLink}
                        color="secondary"
                        to={`/inventory/${x.id}`}
                    >
                        {x.title?.length > 35 ? x.title.slice(0, 33) + '...' : x.title}
                    </Link>
                </Tooltip>
            ),
        },
        {
            title: t('Description'),
            filtering: true,
            sorting: false,
            //width: 30,
            field: 'description',
            render: x => (
                <Tooltip title={x.description} aria-label="description">
                    <span>
                        {x.description?.length > 70 ? x.description.slice(0, 68) + '...' : x.description}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: t('Id'),
            filtering: false,
            sorting: true,
            field: 'unique_id',
            render: x => (
                <Tooltip title={x.unique_id} aria-label="unique_id">
                    <span>
                        {x.unique_id?.length > 12 ? x.unique_id.slice(0, 10) + '...' : x.unique_id}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: t('Price'),
            filtering: false,
            sorting: true,
            field: 'price',
            render: x => x.price,
        },
        {
            title: t('Created'),
            filtering: false,
            sorting: true,
            field: 'created_at',
            render: x => <DateTimeRelative date={toLocalDateTime(x.created_at)} />,
            customSort: (a, b) => {
                return new Date(a.created_at) - new Date(b.created_at)
            }
        }
    ]

    if (!active) {
        columns.push({
            title: t('Deleted'),
            filtering: false,
            sorting: true,
            field: 'deleted_at',
            render: x => <DateTimeRelative date={toLocalDateTime(x.deleted_at ?? x.updated_at)} />,
            customSort: (a, b) => {
                return new Date(a.deleted_at) - new Date(b.deleted_at)
            }
        })
    }

    return <Box>

        <MaterialTable
            title={''}
            columns={columns}
            isLoading={loading}
            data={inventoryItems}
            components={{
                OverlayLoading: () => (
                    <div>
                        <CircularProgressCentered />
                    </div>
                )
            }}
            options={{
                emptyRowsWhenPaging: true,
                pageSize: 100,
                pageSizeOptions: [100, 250],
                debounceInterval: 100,
                headerStyle: {},
                paging: inventoryItems && inventoryItems.length > 100,
                search: true,
                showTitle: true,
                sorting: true,
                selection: false,
                toolbar: true,
                draggable: false,
                tableLayout: 'auto'
            }}
        />

    </Box>

}

export default FeedDataInventoryItems;
