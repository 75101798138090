import React, { useCallback, useMemo, useRef, useState } from 'react';
import clx from 'classnames';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ButtonLoader from '../../components/material/ButtonLoader';
import { CircularProgressCentered, Dialog } from '../../components/material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { useDragNDrop } from '../../helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { showError } from '../../actions/snackbar';
import ImageUploadInput from '../../components/inputs/ImageUploadInput';
import { getScreenIcon } from '../../selectors/billboard';
import { lucitApi } from '../../services/lucitApi';
import { PROGRAMMATIC_CREATIVE_DELETED, PROGRAMMATIC_CREATIVE_UPLOADED } from '../../helpers/actionTypes';
import { useTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        paper: { height: '100%' },
        uploadArea: {
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px dashed gray'
        },
        uploadAreaActive: {
            background: theme.palette.action.hover,
        },
        accordionSummary: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        heading: {
            display: 'flex',
            alignItems: 'center'
        }
    }
})

export const CreativesUploadDialog = (props) => {
    const { title, showApply = true, open, handleClose, handleApply } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const contentRef = useRef();
    const inputRef = useRef();
    const { t } = useTranslation();

    const [loadCount, setLoadCount] = useState(false);
    const loading = loadCount > 0;

    const creatives = useSelector(state => state.programmatic.creative_images)
    const onFileAdded = useCallback(file => {
        setLoadCount(x => x + 1);
        return lucitApi.uploads.uploadImageFile(file)
            .then(image => dispatch({
                type: PROGRAMMATIC_CREATIVE_UPLOADED,
                image
            }))
            .finally(() => setLoadCount(x => x - 1))
    }, [])

    const { isDraggingOver } = useDragNDrop({
        element: contentRef.current,
        onDrop: files => [...files].map(file => onFileAdded(file)),
    })

    const boardOptions = useSelector(state => state.programmatic.board_format_options);
    const sizes = useMemo(() => creatives.reduce((prev, x) => {
        const width = x.options.dimension_width;
        const height = x.options.dimension_height;
        const boardFormat = chooseBoardFormat(boardOptions,
            width,
            height);

        return {
            ...prev,
            [boardFormat.code]: {
                boardFormat,
                files: [...prev[boardFormat.code]?.files || [], {
                    lcuid: x.lcuid,
                    url: x.options.public_url,
                    width,
                    height
                }]
            }
        };
    }, {}), [creatives, boardOptions]);

    return (
        <Dialog
            itemRef={contentRef}
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            lazy={true}
            classes={{ paper: classes.paper }}
            aria-labelledby="export-dialog-title">
            <DialogTitle id="export-dialog-title">{title
                ? t(title)
                : t('Help choosing screen sizes')}</DialogTitle>
            <DialogContent>
                <ImageUploadInput
                    multiple
                    ref={inputRef}
                    id="creativesUpload"
                    onSuccess={({ file }) => onFileAdded(file)}
                    onError={errors => {
                        dispatch(
                            showError(errors.map(e => <Typography key={e.type}>{e.message}</Typography>))
                        )
                    }}>
                    {creatives.length == 0
                        && !loading
                        && <Box className={clx(classes.uploadArea, { [classes.uploadAreaActive]: isDraggingOver })}
                            onClick={() => { inputRef.current.click() }}>
                            {isDraggingOver
                                ? <LCTypography>Drop files here</LCTypography>
                                : <LCTypography>
                                    Click or Drag your Ads here and we will select the
                                    right screen sizes for you.
                                </LCTypography>}
                        </Box>}
                    {creatives.length > 0
                        && <Button
                            style={{ marginBottom: 8 }}
                            color="primary"
                            variant='contained'
                            onClick={() => { inputRef.current.click() }}>
                            {t('Upload more')}
                        </Button>}
                    {loading && <CircularProgressCentered />}
                </ImageUploadInput>
                <Box>
                    {Object.keys(sizes)
                        .map(size => {
                            const format = sizes[size].boardFormat;
                            const files = sizes[size].files;
                            return <Accordion key={size}>
                                <AccordionSummary className={classes.accordionSummary}
                                    expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <img
                                            style={{ width: "40px", marginRight: 12 }}
                                            src={getScreenIcon(size)}
                                        />
                                        <b>{format.name} ({format.size.width}x{format.size.height})</b>: {files.length} creatives
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {files.map((file, idx) => (<Grid item xs={4} key={idx}
                                            justify='center'
                                            style={{ display: 'flex' }}>
                                            <Box>
                                                <img src={file.fileBase64 || file.url} width={240} height={200}
                                                    style={{ objectFit: 'contain' }} />
                                                {file.fileName
                                                    && <Typography variant="body2" align='center'>{file.fileName}</Typography>}
                                                <Typography variant="body2" align='center'>
                                                    {file.width}x{file.height}
                                                    {file.lcuid
                                                        && <IconButton
                                                            onClick={() => lucitApi.objects.delete(file.lcuid)
                                                                .then(() => dispatch({
                                                                    type: PROGRAMMATIC_CREATIVE_DELETED,
                                                                    lcuid: file.lcuid
                                                                }))}
                                                            size="small"
                                                            style={{ marginLeft: 8 }}>
                                                            <Delete />
                                                        </IconButton>}
                                                </Typography>

                                            </Box>
                                        </Grid>))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>;
                        })}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >Close</Button>
                {showApply
                    && <ButtonLoader
                        onClick={() => {
                            handleApply(Object.keys(sizes).map(x => sizes[x].boardFormat));
                            handleClose();
                        }}
                        disabled={Object.keys(sizes).length == 0}
                        variant="contained"
                        color="secondary"
                    >{t('Select')}</ButtonLoader>}
            </DialogActions>
        </Dialog>
    );
}

function chooseBoardFormat(formats, width, height) {
    const aspectRatio = width / height;

    for (const format of formats) {
        const minRatio = format.ratios.min;
        const maxRatio = format.ratios.max;

        if (aspectRatio >= minRatio && aspectRatio <= maxRatio) {
            return format;
        }
    }

    return null;
}
