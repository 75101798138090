import { history } from "../history";
import { RoutePaths, ErrorCodes } from "../constants";
import { store } from "../../reducers";
import { showError } from "../../actions/snackbar";
import { logout } from "../../actions/user";

/**
 * Interceptor to catch 401 Unauthorized errors and redirect to login screen
 * @export
 * @param {*} instance
 */
export function useAuthenticationErrorInterceptor(instance) {
    instance.interceptors.response.use(_ => _, onError);
}

const errorMessages = {
    // invalidPasswordToken is not really because session was expired, but user doesn't need to know real error...
    // This error just indicates that token is bad and we need to login again
    [ErrorCodes.invalidPasswordToken]: 'Your session was expired. Please login again.',
    [ErrorCodes.invalidAppToken]: 'Application token is not valid. Please contact us.'
}

export function onError(error) {
    if (error.isOffline) {
        // eslint-disable-next-line no-undef
        return Promise.reject(error);
    }

    if (error.response.status === 403) {

        store.dispatch(showError('Not authorized to perform this action', {
            duration: 5_000,
        }))

        // eslint-disable-next-line no-undef
        return Promise.reject(error);
    }

    if (error.response.status === 401) {
        const errorCode = error.response.data && error.response.data.code;

        if (errorCode === ErrorCodes.invalidPasswordToken || errorCode === ErrorCodes.invalidAppToken) {
            const message = errorMessages[error.response.data.code];
            history.push({ ...history.location, pathname: RoutePaths.login })

            store.dispatch(logout());
            store.dispatch(showError(message))
        }
    }

    // eslint-disable-next-line no-undef
    return Promise.reject(error);
}
