import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { reduxForm, Field, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '../../components/inputs/TextField';
import { required } from '../../helpers/validators';
import LCTypography from '../../components/material/LCTypography';
import RolesField from './RolesField';

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
    },
}));

const NewInviteForm = (props) => {
    const { values, handleSubmit, error, roles, onSubmit } = props;
    const classes = useStyles();

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <LCTypography variant="body1">Enter either an Email Address or a Mobile Phone Number</LCTypography>
            <Field
                name="invites"
                validate={[required]}
                component={TextField}
                normalize={value => value.trim()}
                props={{
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "invites"
                }}
            />
            <LCTypography variant="caption">You can provide comma-separated list of emails/phones </LCTypography>
            <Box mt={2} />

            <LCTypography variant="body1">Select an appropriate role for this user</LCTypography>
            <Box mt={2} />
            <RolesField
                roles={roles}
                value={values.roles}
            />
            <Box mt={1} />
            <Typography color="error">{error}</Typography>
            <Box mt={1} />
        </form>
    )
}

NewInviteForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export const NEW_INVITE_FORM = "NewInviteForm";

const mapStateToProps = (state) => {
    return {
        values: getFormValues(NEW_INVITE_FORM)(state)
    }
}

export default reduxForm({
    form: NEW_INVITE_FORM, // a unique name for this form
    initialValues: {
        invites: "",
        roles: []
    }
})(
    connect(
        mapStateToProps
    )(
        NewInviteForm
    )
);
