/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    DialogContent, DialogActions, useTheme, useMediaQuery,
    Button,
} from '@material-ui/core';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';
import ScheduleBuilder from './ScheduleBuilder';
import { Dialog } from '../../components';

const ScheduleBuilderDialog = ({ open, handleClose, title, buttonTitle, dialogProps, buttonProps,
    schedule = [], setSchedule }) => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [saving, setSaving] = useState(false);
    const [_schedules, _setSchedules] = useState(schedule);
    const { t } = useTranslation();

    useEffect(() => {
        _setSchedules(schedule);
    }, [JSON.stringify(schedule)])

    return (
        <Dialog
            open={open}
            lazy
            disableEnforceFocus={true}
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent style={{ minHeight: 200 }}>
                {open && <ScheduleBuilder
                    schedules={_schedules}
                    setSchedules={_setSchedules}
                />}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={saving}
                    onClick={() => {
                        if (JSON.stringify(_schedules) === JSON.stringify(schedule)) {
                            handleClose();
                            return;
                        }

                        setSaving(true);
                        (setSchedule(_schedules) ?? Promise.resolve())
                            .then(handleClose)
                            .finally(() => setSaving(false))
                    }}
                    color="secondary"
                    variant="contained"
                    {...buttonProps}>
                    {buttonTitle
                        ? buttonTitle
                        : t('Save')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

export default ScheduleBuilderDialog
