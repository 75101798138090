import { Box, Button, IconButton, makeStyles, Avatar, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setAccount, setItemClass, setPreviewInventoryItem } from '../../actions/designer';
import TextField from '../../components/inputs/TextField';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { Autocomplete } from '@material-ui/lab';

import {
    driveTemplateLcuid, previewAccount, previewInventoryItem, templateParentIsInventoryItem,
    inventoryItemClasses
} from '../../selectors/designer';
import { getInventoryItemClassListFromClassesArray } from '../../selectors/inventory';
import {
    selectedProfile, selectedProfileIsAccount, selectedProfileIsOperator,
    selectedProfileIsAdAgency, userAccounts, selectedAccount
} from '../../selectors/user';
import { lucitApi } from "../../services/lucitApi";
import FeedIcon from '@material-ui/icons/DoubleArrow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import ItemClassToggleButton from '../../containers/inventory/ItemClassToggleButton'
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles(() => {
    return {
        inputCenter: {
            display: 'flex',
            alignItems: "center"
        },
        feedProviderRow: {
            // marginBottom: 16,
            // cursor: "pointer",
            // '&:hover': {
            //     backgroundColor: '#F7F7F7 !important',
            // }
        },
        avatar: {
            background: 'none',
            margin: "0 auto",
            marginBottom: "5px"
        },
        image: {
            width: '100%',
            objectFit: 'contain'
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
        imageIcon: {
            width: '100%',
            height: '100%',
        },
        itemContainerBox: {
            padding: "16px 20px",
            border: "1px solid #909090",
            backgroundColor: "#efefef",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px #0000001f"
        },
        dataPreviewItemContainer: {
            margin: "0 auto",
            marginBottom: "5px",
            textAlign: "center"
        },
        previewFeedInfoContainer: {
            textAlign: "center"
        },
        itemClassSelector: {
            padding: "10px 5px 25px 5px",
        },
        dataPreviewItemTitle: {
            fontWeight: "bold",
            display: "flex",
            padding: "5px 10px 5px 10px",
            border: "1px solid #aeaeae",
            borderRadius: "5px",
            height: "60px",
            overflowY: "hidden",
            margin: "5px",
            backgroundColor: "#ffffff",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
        }
    }
})

const DesignerInventorySelector = (props) => {
    const {
        userAccounts, previewAccount, setAccount, templateParentIsInventoryItem,
        setPreviewInventoryItem, inventoryItemClasses, selectedProfile, selectedProfileIsAccount, previewInventoryItem
    } = props;

    const bestFirstPreviewAccountId = selectedProfileIsAccount ? selectedProfile.id : previewAccount?.id
    const bestFirstPreviewItemId = previewInventoryItem?.id

    const [accountId, setAccountId] = useState(bestFirstPreviewAccountId);
    const [inventory, setInventory] = useState([])
    const [inventoryItemId, setInventoryItemId] = useState(bestFirstPreviewItemId)

    const [showPreviewDialog, setShowPreviewDialog] = useState(false)
    const [loadingInventory, setLoadingInventory] = useState(false)

    const { t } = useTranslation();
    const [loadingItem, setLoadingItem] = useState(false)

    const classes = useStyles();

    useEffect(() => {
        if (selectedProfileIsAccount)
            setAccountId(selectedProfile.id)
    }, [selectedProfile]);

    useEffect(() => {
        if (!accountId)
            return;

        const inventoryAccountIds = (previewAccount && previewAccount.children.length > 0)
            ? JSON.stringify(previewAccount.children.map(a => a.id))
            : accountId;

        setLoadingInventory(true);
        lucitApi.inventory.getInventoryItems({
            item_class: inventoryItemClasses && inventoryItemClasses.length ? inventoryItemClasses : null,
            account_id: inventoryAccountIds,
            per_page: 1000
        }).then((result) => setInventory(result.data))
            .finally(() => setLoadingInventory(false));
    }, [inventoryItemClasses, accountId])

    useEffect(() => {

        if (templateParentIsInventoryItem)
            return;

        if (!inventoryItemId) {
            setPreviewInventoryItem(null)
            return;
        }

        setLoadingItem(true)

        lucitApi.inventory
            .getInventoryItem(inventoryItemId)
            .then((item) => {
                setPreviewInventoryItem(item)
                setLoadingItem(false)
            })

    }, [inventoryItemId, accountId, templateParentIsInventoryItem])

    useEffect(() => {
        const selAccount = userAccounts.filter(a => a.id === accountId)
        setAccount(selAccount ? selAccount[0] : null)
    }, [accountId])

    const hasFeedProviderIcon = previewInventoryItem
        && previewInventoryItem.feed
        && previewInventoryItem.feed.inventory_feed_provider
        && previewInventoryItem.feed.inventory_feed_provider.options.primary_image_public_url

    const feedProviderIcon = hasFeedProviderIcon ? previewInventoryItem.feed.inventory_feed_provider.options.primary_image_public_url : null

    const hasPreviewItem = () => previewInventoryItem && previewInventoryItem.title
    const previewItemHasFeed = () => previewInventoryItem && previewInventoryItem.feed && previewInventoryItem.feed.id

    return (
        <>
            {<Grid item xs={12} className={classes.feedProviderRow}>
                <Box width="100%" display="flex" alignItems="center">

                    <Box className={classes.dataPreviewItemTitle}
                        style={{ height: 'auto', margin: 0, marginTop: 8, flexDirection: 'column', textAlign: "center" }}>
                        {loadingItem
                            && <CircularProgressCentered size={24} />}
                        {hasPreviewItem() && previewItemHasFeed() &&
                            <Box display="flex" alignItems="center" mb={1}>
                                <Avatar className={classes.avatar}>
                                    {hasFeedProviderIcon
                                        && <img
                                            src={feedProviderIcon}
                                            className={classes.image}
                                            alt={previewInventoryItem.feed.name}
                                            title={previewInventoryItem.feed.name}
                                        />}
                                    {!hasFeedProviderIcon
                                        && <FeedIcon className={classes.imageIcon} />}
                                </Avatar>

                                <span style={{ color: "#808080" }}>
                                    {previewInventoryItem.feed.name}
                                </span>
                            </Box>}
                        {hasPreviewItem() && <>
                            <span><Clear
                                color="secondary"
                                size="small"
                                title="Clear Preview Item"
                                style={{ float: "right", marginLeft: 8, marginTop: "-4px", cursor: "pointer" }}
                                onClick={(e) => {
                                    setPreviewInventoryItem({})
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return;
                                }}
                            />
                                {previewInventoryItem.title}
                            </span>
                        </>
                        }

                        {!hasPreviewItem() && t("Select an existing item to preview data on this template")}

                        <Button color="secondary"
                            variant='contained'
                            size="small"
                            style={{ marginTop: 16, marginBottom: 8 }}
                            onClick={() => {
                                if (templateParentIsInventoryItem) {
                                    alert("You cannot change this item because this template is applied only to this item")
                                }
                                else {
                                    setShowPreviewDialog(true)
                                }
                            }}
                        >{hasPreviewItem() ? `Change Preview Item` : `Preview Item`}</Button>

                    </Box>

                </Box>

            </Grid>
            }

            {showPreviewDialog && <PreviewItemDialog
                {...props}
                {...{
                    inventory, setInventoryItemId, inventoryItemId,
                    setAccountId, accountId, loadingInventory, inventoryItemClasses
                }}
                loadingItem={loadingItem}
                open={showPreviewDialog}
                handleClose={() => setShowPreviewDialog(false)}
            />}
        </>
    )
}

const PreviewItemDialog = (props) => {
    const { open, handleClose } = props;
    const { userAccounts, inventory, selectedAccount, selectedProfile, selectedProfileIsOperator, selectedProfileIsAdAgency,
        templateParentIsInventoryItem, previewInventoryItem, loadingInventory, inventoryItemClasses,
        selectedProfileIsAccount, setInventoryItemId, inventoryItemId, setAccountId, accountId } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    const selectedProfileIsParentAccount = () => {
        return selectedProfileIsAccount && selectedAccount.children && (selectedAccount.children.length > 0)
    }

    const [filteredClass, setFilteredClass] = useState(null)

    const showAccountFilter = () => {
        if (!selectedProfileIsAccount)
            return true
        if (selectedProfileIsParentAccount())
            return true
        return false
    }

    const accountsDropDown = () => {

        if (!userAccounts)
            return []

        if (selectedProfileIsParentAccount())
            return userAccounts.filter(a => a.id === selectedAccount.id || a.parent_account_id === selectedAccount.id)

        if (selectedProfileIsOperator || selectedProfileIsAdAgency) {
            return userAccounts.filter(a => a.agency_id === selectedProfile.id)
        }

        return userAccounts
    }

    const classesArray = getInventoryItemClassListFromClassesArray(inventoryItemClasses)

    const filteredInventory = filteredClass ? inventory.filter(i => i.item_class === filteredClass) : inventory

    const incrementInventoryItem = () => {
        const currentIndex = inventory.findIndex(i => i.id === inventoryItemId)
        const nextIndex = currentIndex + 1
        if (nextIndex >= inventory.length)
            return
        setInventoryItemId(inventory[nextIndex].id)
    }

    const decrementInventoryItem = () => {
        const currentIndex = inventory.findIndex(i => i.id === inventoryItemId)
        const nextIndex = currentIndex - 1
        if (nextIndex < 0)
            return
        setInventoryItemId(inventory[nextIndex].id)
    }

    const canIncrementInventoryItem = () => {
        const currentIndex = inventory.findIndex(i => i.id === inventoryItemId)
        const nextIndex = currentIndex + 1
        return nextIndex < inventory.length
    }

    const canDecrementInventoryItem = () => {
        const currentIndex = inventory.findIndex(i => i.id === inventoryItemId)
        const nextIndex = currentIndex - 1
        return nextIndex >= 0
    }

    return (<>
        <GenericDialog
            title={t('Preview Item')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm',
                allowDragAndMove: true,
            }}
            dialogContentProps={{
                // className: classes.dialogContent
            }}

            ContentComponent={<>
                <Box>
                    {loadingInventory && <CircularProgressCentered size={15} />}
                    {showAccountFilter() && <TextField
                        fullWidth
                        labelId="account"
                        id="account"
                        variant="outlined"
                        disabled={loadingInventory}
                        label={t('Account') + (loadingInventory ? "  Loading..." : "")}
                        input={{
                            onChange: (e) => setAccountId(e.target.value),
                            value: accountId ?? ""
                        }}
                        select={true}
                        SelectProps={{
                            classes: { root: classes.inputCenter }
                        }}>
                        {!loadingInventory && accountsDropDown().map((acc, index) => {
                            return (
                                <MenuItem key={index} value={acc.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    {acc.options.primary_image_public_url &&
                                        <img style={{ width: "25px", maxHeight: "25px", marginRight: "5px" }}
                                            src={acc.options.primary_image_public_url} />}
                                    {acc.name}
                                </MenuItem>
                            )
                        })}
                    </TextField>}
                </Box>
                <Box mt={2}>
                    {templateParentIsInventoryItem
                        ? previewInventoryItem
                        : <Grid container>

                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <Box className={classes.itemClassSelector}>

                                    <Button
                                        style={{ margin: "5px" }}
                                        variant={filteredClass === null ? "contained" : "outlined"}
                                        color="primary"
                                        onClick={() => {
                                            setFilteredClass(null)
                                        }}
                                    >
                                        All
                                    </Button>

                                    {classesArray.map((itemClass) => {
                                        return (

                                            <ItemClassToggleButton
                                                key={itemClass.class}
                                                itemClass={itemClass}
                                                isSelected={itemClass.class === filteredClass}
                                                onClick={() => {
                                                    setFilteredClass(itemClass.class)
                                                }}
                                            />
                                        )
                                    })
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={1}></Grid>

                            <Grid item xs={1}>
                                <IconButton
                                    disabled={!canDecrementInventoryItem()}
                                    onClick={() => decrementInventoryItem()}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={10}>
                                <Autocomplete
                                    loading={loadingInventory}
                                    fullWidth
                                    id="item-item"
                                    options={filteredInventory}
                                    value={filteredInventory.find(item => item.id === inventoryItemId) ?? null}
                                    onChange={(_, newValue) => setInventoryItemId(newValue?.id ?? null)}
                                    getOptionLabel={(item) => item?.title ?? ""}
                                    renderOption={(item) => (
                                        <React.Fragment>
                                            {item.options.primary_image_public_url && (
                                                <img
                                                    style={{ width: '25px', maxHeight: '25px', marginRight: '5px' }}
                                                    src={item.options.primary_image_public_url}
                                                    alt=""
                                                />
                                            )}
                                            {item.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            labelId="item-item"
                                            variant="outlined"
                                            label={t('{Choose Item}')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={!canIncrementInventoryItem()}
                                    onClick={() => incrementInventoryItem()}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Ok')}
                </Button>
            </>}
        />
    </>)
}

const mapStateToProps = state => {
    return {
        previewAccount: previewAccount(state),
        previewInventoryItem: previewInventoryItem(state),
        inventoryItemClasses: inventoryItemClasses(state),
        userAccounts: userAccounts(state),
        selectedProfile: selectedProfile(state),
        selectedProfileIsAccount: selectedProfileIsAccount(state),
        selectedProfileIsOperator: selectedProfileIsOperator(state),
        selectedProfileIsAdAgency: selectedProfileIsAdAgency(state),
        templateParentIsInventoryItem: templateParentIsInventoryItem(state),
        selectedAccount: selectedAccount(state),
        driveTemplateLcuid: driveTemplateLcuid(state),
        dirty: state.designer.dirty
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setItemClass: (itemClass) => dispatch(setItemClass(itemClass)),
        setPreviewInventoryItem: (inventoryItem) => dispatch(setPreviewInventoryItem(inventoryItem)),
        setAccount: (account) => dispatch(setAccount(account))
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerInventorySelector
    );
