import React from 'react';
import clx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectedProfile } from '../../selectors/user';
import { toggleProfileSwitcher } from '../../actions/layout';
import { hasIcon } from '../../selectors/account';
import { Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    selected: {
        cursor: "pointer",
        // color: theme.palette.secondary.main

        '& .MuiTypography-root': {
            fontWeight: 'bold',
        }
    },
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },
    listItemClassName: {
        color: theme.palette.header.text
    }
}));

const ProfileListItem = (props) => {
    const { selectedProfile, openProfileSwitcher, Icon, ...rest } = props;
    const classes = useStyles();

    return (
        <ListItem
            className={clx(classes.selected, props.className)}
            onClick={openProfileSwitcher}
            {...rest}>
            <ListItemAvatar>
                {hasIcon(selectedProfile)
                    ? <Avatar className={classes.avatar} >
                        <img
                            src={selectedProfile.options.primary_image_public_url}
                            className={classes.image}
                            alt={selectedProfile.name}
                            title={selectedProfile.name}
                        />
                    </Avatar>
                    : <Avatar />}
            </ListItemAvatar>

            <Tooltip title={selectedProfile.name}>
                <ListItemText
                    className={clx(classes.listItemClassName, props.listItemClassName)}
                    primary={selectedProfile.name}
                    primaryTypographyProps={{ noWrap: true }}
                />
            </Tooltip>

            {Icon}
        </ListItem>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedProfile: selectedProfile(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openProfileSwitcher: () => dispatch(toggleProfileSwitcher(true))
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ProfileListItem
    )
);
