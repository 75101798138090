import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    Box,
    Divider,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { CircularProgressCentered, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddScreenGroupsButton from './AddScreenGroupsButton';
import { getBoardConnectedExportForBoard } from '../../selectors/lightning';
import { WeightsEditorBox } from '../../components/weights';
import { showSuccess } from '../../actions/snackbar';
import { ScheduleBuilderDialog } from '../../containers/itemSchedule';
import RowsAccordions from './RowsAccordions';
import { useExportSettings } from './useExportSettings';
import { IconFromSet } from '../../components/IconFromSet';
import { Tv } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
            minHeight: 200
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const EditSceenGroupsDialog = ({ screen, open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const screenExport = useSelector(state => getBoardConnectedExportForBoard(state)(screen));
    const [screenGroups, setScreenGroups] = useState(screenExport.child_exports ?? []);
    const [screenGroupEdit, setScreenGroupEdit] = useState(null);

    const { loading, saving,
        weights, setWeights,
        scheduleDictionary, setSchedule,
        handleSave
    } = useExportSettings(screenExport, screenGroups)

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                title={screen.name}
                disableBackdropAndEscapeClose
            >
                <DialogContent className={classes.content}>
                    {loading
                        && <CircularProgressCentered size={40} />}

                    {!loading
                        && <>
                            <AddScreenGroupsButton
                                screen={screen}
                                screenGroups={screenGroups}
                                onChange={setScreenGroups}
                            />

                            <Box mt={2} mb={2}>
                                {screenGroups.length === 0
                                    && <Typography>
                                        {t('Please add screen to at least one group')}
                                    </Typography>}

                                {screenGroups.length > 0
                                    && <RowsAccordions
                                        rows={screenGroups.map(x => ({
                                            ...x,
                                            Icon: <IconFromSet
                                                iconKey={x?.options?.ui_options?.layout?.icon}
                                                FallbackIcon={Tv} />
                                        }))}
                                        onDelete={g => setScreenGroups(screenGroups.filter(x => x.lcuid !== g.lcuid))}
                                        onScheduleClick={g => setScreenGroupEdit(g)}
                                        hasSchedule={g => scheduleDictionary[g.lcuid]?.schedules?.length > 0}
                                        hideScreenSelector={true}
                                    />}
                            </Box>

                            {screenGroups.length > 1
                                && <>
                                    <Divider />
                                    <Box mt={2} />
                                    <WeightsEditorBox weights={weights} setWeights={setWeights} />
                                </>}
                        </>}
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={saving}
                        startIcon={saving && <CircularProgress size={20} />}
                        onClick={() =>
                            handleSave(screenExport)
                                .then(() => {
                                    dispatch(showSuccess(`${screen.name} groups were successfully updated`));
                                    handleClose();
                                })}
                        color="primary"
                        variant="contained">
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog >

            {screenGroupEdit
                && <ScheduleBuilderDialog
                    title={screenGroupEdit.name}
                    open={true}
                    handleClose={() => setScreenGroupEdit(null)}
                    schedule={scheduleDictionary[screenGroupEdit.lcuid].schedules}
                    setSchedule={newSchedule => setSchedule(screenGroupEdit, newSchedule)}
                    buttonTitle={t("OK")}
                    buttonProps={{ color: 'primary' }}
                />}
        </>
    )

}

export default EditSceenGroupsDialog;
