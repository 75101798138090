import { Box, Divider, Grid, IconButton, Tooltip, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Clear } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteAsset, replaceCSSForId, addObject } from '../../actions/designer';
import LCTypography from '../../components/material/LCTypography';
import { Designer } from '../../helpers/constants';
import {
    assets, editModeGlobal, getLastColorUsed, getLastFontUsed, html, previewAccount, previewInventoryItem, templateIsFullSizeCreative
} from '../../selectors/designer';
import { idExistsInHTMLString } from './DesignerHTML';
import { ImageUploaderDropZone } from './DesignerImages';
import DesignerInventorySelector from './DesignerInventorySelector';
import { DesignerMacros } from './DesignerMacros';
import { macroHasBeenUsed } from '../../selectors/designerMacro';
import { useSelector } from 'react-redux';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles((theme) => {
    return {
        macroRow: {
            marginBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
        },
        feedProviderRow: {
            marginBottom: 16,
            cursor: "pointer",
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
            }
        },
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain'
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
        imageIcon: {
            width: '100%',
            height: '100%',
        },
        dataSourceRowBox: {
            position: 'relative',
            padding: "4px",
            border: "1px solid transparent",
            borderRadius: "5px",
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minHeight: '50px',
            width: '100%',
            '&:hover': {
                backgroundColor: '#F7F7F7 !important',
                border: "1px solid #0000001f",
                boxShadow: "0px 0px 5px 0px #0000001f",
                '& .MuiBox-root': {
                    visibility: 'visible'
                }
            }
        },
        dataConnectedElementIcon: {
            width: "10px",
            height: "10px",
            display: "inline-block",
            borderRadius: "50%",
            backgroundColor: "#02c9c9",
            border: "1px solid #ffffff",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        },
        accordionRoot: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&.Mui-expanded': {
                margin: 'auto',
            },
        },
        accordionSummaryRoot: {
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 40,
            '&.Mui-expanded': {
                minHeight: 40,
            },
        },
        accordionSummaryContent: {
            width: 'calc(100% - 30px)',
            margin: 'auto',
            '&.Mui-expanded': {
                margin: 'auto',
            },
        },
        accordionDetailsRoot: {
            padding: '8px',
        },
        remove: {
            background: 'white',
            visibility: 'hidden',
            position: 'absolute',
            top: 0,
            right: 0,
            '& .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        }
    }
})

export const DataConnectedElementIcon = () => {
    const classes = useStyles();

    return <span className={classes.dataConnectedElementIcon} />
}

export const getMacroIdFromAsset = (asset) => {
    if (asset.image)
        return asset.image.lcuid + ".options.public_url"

    return null
}

export const buildMacroCodeFromAsset = (asset) => {
    if (asset.image)
        return "{" + getMacroIdFromAsset(asset) + "}"

    return null
}

export const DesignerElementsComponent = ({
    assets, designerHtml, editModeGlobal, addObject, deleteAsset,
    macroHasBeenUsed, lastFontUsed, lastColorUsed, templateIsFullSizeCreative
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const [queryBackgrounds, setQueryBackgrounds] = useState("");
    const [queryShapes, setQueryShapes] = useState("");
    const [queryText, setQueryText] = useState("");
    const [queryMacros, setQueryMacros] = useState("");

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const prepareElementJsForTemplate = (js, id) => {

        //if not a string, return it as is
        if (typeof js !== 'string') {
            return js
        }

        //replace {id} with the actual element id
        return js.replace(/{id}/g, id)
    }

    const onAddObject = (id, html, css, js, elementSettings) => {
        if (!idExistsInHTMLString(id, designerHtml)) {

            addObject({
                id: id,
                html: html,
                css: css,
                //If we are NOT in global edit (i.e.  individual edit) only add to this one
                showOnlyForThisBoardFormat: !editModeGlobal,
                js: js ? prepareElementJsForTemplate(js, id) : null,
                elementSettings: elementSettings
            })
        }
    }

    const onAddBackgroundObject = (id, html, css, js, elementSettings) => {
        if (!idExistsInHTMLString(id, designerHtml)) {

            addObject({
                id: id,
                html: html,
                css: css,
                isBackground: true,
                //If we are NOT in global edit (i.e.  individual edit) only add to this one
                showOnlyForThisBoardFormat: !editModeGlobal,
                js: js ? prepareElementJsForTemplate(js, id) : null,
                elementSettings: elementSettings
            })
        }
    }

    const showDataElements = () => {
        return !templateIsFullSizeCreative
    }

    const DesignerElementObjects = useSelector(state => state.designer.elements)

    const elementMatchesQuery = (query, element) => {
        return searchTermInText(query, [
            element.name,
            element.description,
            element.type
        ]);
    };

    return (
        <Box style={{ height: "calc(100vh - 250px)", overflowY: "scroll", }}>

            {showDataElements() && <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={expanded === 'data'}
                onChange={handleChange('data')}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: "MuiIconButton-sizeSmall"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    id="designer-macros-data"
                >
                    <LCTypography variant="subtitle2" noWrap>Dynamic Data Elements</LCTypography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <Grid container spacing={1}>
                        <DesignerInventorySelector />

                        <Divider style={{ width: '100%', margin: '8px 0' }} />

                        <Grid item xs={12}>
                            <Box mb={1} mt={1} ml={1} mr={1}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Search Elements')}
                                    value={queryMacros}
                                    size="small"
                                    type="search"
                                    onChange={e => setQueryMacros(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <DesignerMacros query={queryMacros} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            }

            <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={expanded === 'images'}
                onChange={handleChange('images')}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: "MuiIconButton-sizeSmall"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    id="designer-macros-images"
                >
                    <LCTypography variant="subtitle2">Uploaded Images</LCTypography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <ImageUploaderDropZone
                                showMessage={true}
                            />
                        </Grid>

                        {assets.map((asset, index) => {
                            if (!asset || !asset.image)
                                return

                            return <AssetRow
                                key={`asset_` + index}
                                asset={asset}
                                assetDelete={() => deleteAsset(asset.image.id)}
                                onAddObject={onAddObject}
                                hasBeenUsed={macroHasBeenUsed(getMacroIdFromAsset(asset))}
                            />

                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={expanded === 'backgrounds'}
                onChange={handleChange('backgrounds')}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: "MuiIconButton-sizeSmall"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    id="designer-macros-backgrounds"
                >
                    <LCTypography variant="subtitle2">Backgrounds</LCTypography>
                </AccordionSummary>
                <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}>
                    <Grid container spacing={1}>

                        <Grid item xs={12}>
                            <Box mb={1} mt={1} ml={1} mr={1}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Search Backgrounds')}
                                    value={queryBackgrounds}
                                    size="small"
                                    type="search"
                                    onChange={e => setQueryBackgrounds(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        {DesignerElementObjects
                            .filter(e => e.element_class == Designer.ObjectElementClasses.Background.class)
                            .filter(e => elementMatchesQuery(queryBackgrounds, e))
                            .map((object, index) => {
                                return <ObjectRow
                                    key={`object_` + index}
                                    object={object}
                                    lastFontUsed={lastFontUsed}
                                    lastColorUsed={lastColorUsed}
                                    onAddObject={onAddBackgroundObject}
                                    xs={12}
                                />

                            })}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={expanded === 'elements'}
                onChange={handleChange('elements')}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: "MuiIconButton-sizeSmall"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    id="designer-macros-elements"
                >
                    <LCTypography variant="subtitle2">Shapes</LCTypography>
                </AccordionSummary>
                <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}>
                    <Grid container spacing={1}>

                        <Grid item xs={12}>
                            <Box mb={1} mt={1} ml={1} mr={1}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Search Shapes')}
                                    value={queryShapes}
                                    size="small"
                                    type="search"
                                    onChange={e => setQueryShapes(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        {DesignerElementObjects
                            .filter(e => e.element_class == Designer.ObjectElementClasses.BasicShape.class)
                            .filter(e => elementMatchesQuery(queryShapes, e))
                            .map((object, index) => {
                                return <ObjectRow
                                    key={`object_` + index}
                                    object={object}
                                    lastFontUsed={lastFontUsed}
                                    lastColorUsed={lastColorUsed}
                                    onAddObject={onAddObject}
                                />

                            })}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                classes={{ root: classes.accordionRoot }}
                expanded={expanded === 'text'}
                onChange={handleChange('text')}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: "MuiIconButton-sizeSmall"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    id="designer-macros-text"
                >
                    <LCTypography variant="subtitle2">{t('Text')}</LCTypography>
                </AccordionSummary>
                <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}>
                    <Grid container spacing={1}>

                        <Grid item xs={12}>
                            <Box mb={1} mt={1} ml={1} mr={1}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    required={true}
                                    label={t('Search Text')}
                                    value={queryText}
                                    size="small"
                                    type="search"
                                    onChange={e => setQueryText(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        {DesignerElementObjects
                            .filter(e => e.element_class == Designer.ObjectElementClasses.Text.class)
                            .filter(e => elementMatchesQuery(queryText, e))
                            .map((object, index) => {
                                return <ObjectRow
                                    key={`object_` + index}
                                    object={object}
                                    lastFontUsed={lastFontUsed}
                                    lastColorUsed={lastColorUsed}
                                    onAddObject={onAddObject}
                                />

                            })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}

const mapStateToProps = state => {
    return {
        assets: assets(state),
        inventoryItemObject: previewInventoryItem(state),
        templateIsFullSizeCreative: templateIsFullSizeCreative(state),
        account: previewAccount(state),
        designerHtml: html(state),
        macroHasBeenUsed: (macro) => macroHasBeenUsed(state, macro),
        lastFontUsed: getLastFontUsed(state),
        lastColorUsed: getLastColorUsed(state),
        editModeGlobal: editModeGlobal(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addObject: (params) => dispatch(
            addObject(params)
        ),
        replaceCSSForId: (id, css) => dispatch(replaceCSSForId(id, css)),
        deleteAsset: (assetId) => dispatch(deleteAsset(assetId))
    }
}

export const DesignerElements =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerElementsComponent
    );

export const getIdFromAsset = (asset) => {
    return Designer.AssetElements.img.id_prefix + asset.image.id
}

export const getFieldNameFromAsset = (asset) => {

    if (asset.original_file_name) {

        //Just in case
        const fileNameWithoutQuotes = asset.original_file_name.replace(/^"(.*)"$/, '$1');

        //Remove the extension
        const fileNameWithoutExtension = fileNameWithoutQuotes.split('.').slice(0, -1).join('.')

        return fileNameWithoutExtension
    }

    return Designer.AssetElements.img.id_prefix + asset.image.id
}

export const getUniqueId = () => (Math.random() + 0.000001).toString(36).slice(-8)

export const getNewAssetIdFromCopy = (id) => {
    return id + '_' + getUniqueId();
}

export const getIdForNewObject = (object) => {
    return "obj_" + object.code + '_' + getUniqueId()
}
export const getAssetElementForObjectType = (objectType) => {
    return Designer.AssetElements[objectType] ?? null
}
export const getObjectElementForObjectCode = (objectCode) => {

    const DesignerElementObjects = useSelector(state => state.designer.elements)

    return DesignerElementObjects.find((element) => {
        return element.code === objectCode
    })
}
export const getCSSSizingStringForNewElements = () => {
    const randomValue = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
    const width = randomValue(Designer.NewObjectSizing.width.min_pct, Designer.NewObjectSizing.width.max_pct);
    const height = randomValue(Designer.NewObjectSizing.height.min_pct, Designer.NewObjectSizing.height.max_pct);
    const top = randomValue(Designer.NewObjectSizing.top.min_pct, Designer.NewObjectSizing.top.max_pct);
    const left = randomValue(Designer.NewObjectSizing.left.min_pct, Designer.NewObjectSizing.left.max_pct);

    return `width: ${width}%; height: ${height}%; top: ${top}%; left: ${left}%;`;
}

export const getAssetMacroHtml = (asset) => {
    if (asset.image) {
        return `<div x-objectcode="asset_img" ` +
            `id="${getIdFromAsset(asset)}" ` +
            `x-fieldname="${getFieldNameFromAsset(asset)}" ` +
            `class="${Designer.AssetElements.img.defaultClasses} ${asset.className || 'lc_dt_image_center_contain'}" ` +
            `style="background-image: url('${buildMacroCodeFromAsset(asset)}')" ` +
            `data-value="${buildMacroCodeFromAsset(asset)}"></div>`;
    }

    return null
}

export const getAssetMacroCSS = (asset) => {
    if (asset.image) {
        return Designer.AssetElements.img.defaultCss + getCSSSizingStringForNewElements()
    }

    return null
}

export const AssetRow = ({ asset, onAddObject, assetDelete }) => {
    const classes = useStyles();

    const macroHtml = getAssetMacroHtml(asset)
    const macroCSS = getAssetMacroCSS(asset)

    const { t } = useTranslation();

    return (
        <Grid item xs={6} className={classes.macroRow} onClick={() => onAddObject(getIdFromAsset(asset), macroHtml, macroCSS)}>
            <Box className={classes.dataSourceRowBox} title={`Image element source : ` + asset.image.options.public_url}>
                <img style={{ maxWidth: "50px", maxHeight: '50px', width: "100%", height: "100%" }} src={asset.image.options.public_url} />

                <Box className={classes.remove}>
                    <IconButton title={t('Delete this assset')}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (window.confirm(t('Are you sure you want to delete this image?'))) {
                                assetDelete()
                            }
                        }}
                        size="small"><Clear /></IconButton>
                </Box>

                {/* <Grid container>
                    <Grid item xs={9}  >
                        <img style={{ width: "100px" }} src={asset.image.options.public_url} />
                    </Grid>
                    <Grid item xs={3}>
                        <IconButton title={t('Delete this assset')} onClick={() => {
                            if (window.confirm(t('Are you sure you want to delete this image?'))) {
                                assetDelete()
                            }
                        }}><Delete /></IconButton>
                    </Grid>
                </Grid> */}
            </Box>
        </Grid>
    )
}

export const ObjectRow = ({ object, onAddObject, lastFontUsed, lastColorUsed, xs = 4 }) => {
    const classes = useStyles();
    const objectId = getIdForNewObject(object)
    const addHtml = object.html.replace("{id}", objectId)

    const fontName = lastFontUsed ? lastFontUsed.name : Designer.DefaultFontName
    const lastColorShouldBeIgnored = Designer.IgnoreLastColorIfOneOf.includes(lastColorUsed)
    const color = (lastColorUsed && !lastColorShouldBeIgnored) ? lastColorUsed : Designer.DefaultTextColor

    const fontsCss = object.type === Designer.ObjectTypes.Text ? "font-family:'" + fontName + "';" : ""
    const colorProperty = (
        (object.type === Designer.ObjectTypes.Text)
        || (object.type === Designer.ObjectTypes.Svg)
    ) ? "color" : "background-color"

    const defaultCss = object.defaultCss ?? ""

    const cssHasColor = object.defaultCss?.includes("color") || object.defaultCss?.includes("background")

    const shouldAddColor = !cssHasColor && (object.type === Designer.ObjectTypes.Text || object.type === Designer.ObjectTypes.Object)
    const colorCss = shouldAddColor ? (colorProperty + ":" + color + ";") : ""

    const css = defaultCss + getCSSSizingStringForNewElements() + fontsCss + colorCss

    const bestName = object.name ?? object.code

    const dataSourceRowBoxCustomStyle = {}

    if (object.element_class === Designer.ObjectElementClasses.Background.class) {
        dataSourceRowBoxCustomStyle.minHeight = "100px";
        dataSourceRowBoxCustomStyle.height = "100px";
    }

    const js = object.js
    const elementSettings = object.element_settings

    return (
        <Grid item xs={object.code === 'custom_svg' ? 12 : xs}
            className={classes.macroRow}
            onClick={() => onAddObject(objectId, addHtml, css, js, elementSettings)}>
            <Box
                className={classes.dataSourceRowBox}
                style={dataSourceRowBoxCustomStyle}
            >
                <Tooltip title={bestName}>
                    <div style={{ width: "100%", height: "100%" }}>
                        {object.preview}
                    </div>
                </Tooltip>
            </Box>
        </Grid >
    )
}
