import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import LCTypography from '../../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { getItem } from '../../../actions/inventory';
import InventoryItemPhotoGallery from './InventoryItemPhotoGallery';

export const useStyles = makeStyles(theme => {
    return {
        root: {
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },

        attributeTitle: {
            width: 80,
            marginRight: theme.spacing(2),
            fontWeight: 'bold'
        },
        attributeValue: {
            textAlign: 'left'
        }
    }
})

function InventoryItem(props) {
    const { item } = props;

    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <InventoryItemPhotoGallery item={item} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <Typography variant="h5">{item.title}</Typography>

                    <Box className={classes.attribute}>
                        <Box component={Typography} className={classes.attributeValue}>
                            <br />
                            <LCTypography variant="body2">
                                Real Estate Agent
                            </LCTypography>
                        </Box>
                    </Box>

                </Box>
            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
