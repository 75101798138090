import { IonCard, IonItem, IonLabel, IonList, IonToggle } from '@ionic/react';
import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setCapability } from '../../actions/applications';
import { lucitApi } from '../../services/lucitApi';
import ApplicationCapabilitySettings from './capabilities/ApplicationCapabilitySettings';

const ApplicationCapabilities = ({ application, setCapability }) => {
    const [capabilities, setCapabilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showCapabilitySettingsForClass, setShowCapabilitySettingsForClass] = useState(null);

    useEffect(() => {
        setLoading(true);
        lucitApi.applications.getCapabilities()
            .then(data => setCapabilities(data))
            .finally(() => setLoading(false));
    }, []);

    if (showCapabilitySettingsForClass) {
        return <ApplicationCapabilitySettings
            application={application}
            capabilityClass={showCapabilitySettingsForClass}
            onClose={() => setShowCapabilitySettingsForClass(null)}
        />
    }

    if (!application) {
        return null;
    }

    return <>
        <IonCard style={{ color: 'unset' }}>
            <Box p={2}>
                <Typography variant="h6">Capabilities</Typography>
                <IonList>
                    {loading
                        && <CircularProgress size={40} />}
                    {!loading
                        && capabilities.filter(x => x.show_in_ui).map(c => {
                            const appCapability = application.capabilities &&
                                application.capabilities.find(cap => cap.destination.id === c.id);

                            return (
                                <IonItem key={c.lcuid}>
                                    <IonLabel class="ion-text-wrap">
                                        <h2>{c.name}</h2>
                                        <p>{c.description}</p>
                                    </IonLabel>

                                    <IconButton
                                        slot="end"
                                        disabled={!appCapability}
                                        onClick={() => {
                                            setShowCapabilitySettingsForClass(appCapability.destination.capability_class)
                                        }}
                                    ><SettingsIcon /></IconButton>

                                    <CapabilityToggle
                                        disabled={updating}
                                        defaultChecked={Boolean(appCapability)}
                                        onClick={e => {
                                            setUpdating(true);
                                            setCapability(application, { ...appCapability, ...c }, e.target.checked)
                                                .finally(() => setUpdating(false))
                                        }}
                                        slot="end"></CapabilityToggle>
                                </IonItem>
                            )
                        })}
                </IonList>
            </Box>
        </IonCard>
    </>
}

const CapabilityToggle = ({ disabled, defaultChecked, onClick }) => {

    const [checked, setChecked] = useState(defaultChecked);

    return <IonToggle
        disabled={disabled}
        checked={checked}
        onClick={(e) => {
            onClick(e);
            setChecked(!checked);
        }}
        slot="end"></IonToggle>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCapability: (application, capability, enable) => dispatch(setCapability(application, capability, enable))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ApplicationCapabilities
);
