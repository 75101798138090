import React from 'react';
import moment from 'moment';
import { Typography, Box, makeStyles } from '@material-ui/core';

import { RoutePaths } from '../../helpers/constants';
import ExportsIcon from '../../components/icons/ExportIcon';
import { showInfo, updateActiveMessage } from '../../actions/snackbar';
import { history } from '../../helpers/history';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer'
        },

        icon: {
            marginRight: theme.spacing(1),
            height: theme.spacing(4),
            objectFit: 'contain'
        }
    }
});

const ExportChangedNotification = ({ notification }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {notification.data
                && <>
                    <Typography variant="subtitle1">{notification.data.title}</Typography>
                    <Typography variant="body2" gutterBottom>{notification.data.body}</Typography>
                    <Typography variant="caption" align="right">{moment.utc(notification.data.created_at).local().format("hh:mm A MMMM DD, YYYY")}</Typography>
                </>}
        </Box>
    );
};

const handleNotification = (notification, dispatch, handleClick) => {
    dispatch(
        showInfo(
            <ExportChangedNotification notification={notification} />,
            {
                snackbarProps: {
                    onClick: () => {
                        handleClick();
                        dispatch(updateActiveMessage({ isOpen: false }));
                    }
                },
                alertProps: {
                    icon: notification.data.icon
                        ? <img src={notification.data.icon}
                            alt="icon" />
                        : <ExportsIcon />
                }
            }
        )
    )
};

const handleClick = (notification) =>
    history.push({ pathname: RoutePaths.exports + `/${notification.data.inventory_export_id}` });

export { ExportChangedNotification, handleNotification, handleClick }