import { Avatar, Grid, Typography, makeStyles, Box, Button, Divider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getInitials } from '../../helpers/string';
import { hasIcon } from '../../selectors/account';
import { Share } from '@capacitor/share';
import { Share as ShareIcon } from '@material-ui/icons';
import { getFullUrl } from '../../helpers/history';
import clx from 'classnames';
import { RoutePaths } from '../../helpers/constants';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },
        btn: {
            //height: 36
        }
    }
})

const ManageProfileButton = ({ user, manageUser, isLoggedIn }) => {

    if (!isLoggedIn)
        return null;

    if (user.lcuid !== manageUser.lcuid)
        return null;

    return <Button
        style={{ marginRight: '10px' }}
        color="secondary"
        variant="contained"
        component={RouterLink}
        to={{
            pathname: RoutePaths.settings
        }}
    >
        Edit Settings
    </Button>
}

function UserProfile(props) {
    const { user, currentUser, isLoggedIn, share } = props;

    const classes = useStyles();

    return <Grid container spacing={2}>
        <Grid item xs={12} md="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {hasIcon(user)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={user.options.primary_image_public_url}
                        className={classes.image}
                        alt={user.name}
                        title={user.name}
                    />
                </Avatar>
                : <Avatar className={classes.avatar}>
                    {getInitials(user.name)}
                </Avatar>}

        </Grid>
        <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5">{user.name}</Typography>
            <Typography variant="body1">{user.title}</Typography>

            {user.description
                && <Box mt={2}>
                    <Typography variant="body2">{user.description}</Typography>
                </Box>}

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

            <div style={{ display: 'flex' }}>
                <ManageProfileButton
                    user={currentUser}
                    manageUser={user}
                    isLoggedIn={isLoggedIn}
                />
                <Button
                    className={clx(classes.btn)}
                    onClick={() => share(user, user.name, user.description)}
                    startIcon={<ShareIcon />}
                    variant="outlined"
                    color="primary">
                    Share
                </Button>
            </div>

        </Grid>
    </Grid>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        currentUser: state.user,
    }
}

const mapDispatchToProps = () => {
    return {
        share: (account, title, text) => {
            return Share.share({
                title: title,
                text: text,
                url: getFullUrl(RoutePaths.public.slug(account.slug)),
                dialogTitle: 'Share',
            });
        },

    }
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(
    UserProfile
);
