import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Box, List } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { useState } from 'react';
import { toggleSignInDialog } from '../../actions/layout';
import { CommentInput, Comment } from '.';
import { getComments } from '../../actions/objects';
import { commentsSelector } from '../../selectors/objects';

const CommentWall = (props) => {
    const { lcuid, className, commentsPerPage = 3, autoFocus } = props;
    const { isLoggedIn, getComments, openSignInDialog } = props;

    const comments = props.comments || props.commentsSelector(lcuid);
    const [commentsPage, setCommentsPage] = useState(0);

    const [commentToEdit, setCommentToEdit] = useState(null);

    useEffect(() => {
        if (comments == null) {
            getComments(lcuid);
        }
    }, [lcuid, comments])

    if (comments == null) {
        return null;
    }

    const commentsLastPage = Math.ceil(comments.length / commentsPerPage);

    return <>
        <List disablePadding className={className}>
            {!isLoggedIn
                && <Box mb={1.5} textAlign="center">
                    <Link
                        component="button"
                        variant="body1"
                        color="secondary"
                        underline="none"
                        onClick={() => openSignInDialog()}
                    >
                        <b>Sign In to write comment</b>
                    </Link>
                </Box>}

            {isLoggedIn
                && <CommentInput
                    lcuid={lcuid}
                    autoFocus={autoFocus} />}

            {comments
                .slice(0, (commentsPage + 1) * commentsPerPage)
                .map(comment => {
                    if (commentToEdit && comment.id === commentToEdit.id) {
                        return <CommentInput
                            key={comment.id}
                            lcuid={lcuid}
                            autoFocus={true}
                            editComment={comment}
                            onEditCancel={() => setCommentToEdit(null)}
                            onEditOk={() => setCommentToEdit(null)} />
                    }

                    return <Comment key={comment.id}
                        lcuid={lcuid}
                        comment={comment}
                        onEdit={() => setCommentToEdit(comment)} />
                })}

            {commentsPage < commentsLastPage - 1
                && <Box display="flex" justifyContent="space-between">
                    <Link
                        component="button"
                        variant="body2"
                        color="inherit"
                        onClick={() => setCommentsPage(commentsPage + 1)}
                    >
                        View more comments
                    </Link>

                    <LCTypography transProps={{
                        commentsPagePer: (commentsPage + 1) * commentsPerPage,
                        commentsLength: comments.length
                    }} variant="body2">
                        {{ commentsPagePer: (commentsPage + 1) * commentsPerPage }} of {{ commentsLength: comments.length }}
                    </LCTypography>
                </Box>}
        </List>
    </>;
};

CommentWall.propTypes = {
    lcuid: PropTypes.string,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,

    comments: PropTypes.array,
    commentsPerPage: PropTypes.number
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        commentsSelector: commentsSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openSignInDialog: () => dispatch(toggleSignInDialog(true)),
        getComments: (lcuid) => dispatch(getComments(lcuid))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentWall);
