import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import { showError, showSuccess } from '../../actions/snackbar';
import { ConfirmationDialog } from '../../components';
import { history } from '../../helpers/history';
import { deleteAgency } from '../../actions/agencies';
import { lucitApi } from "../../services/lucitApi"
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { AGENCY_DELETE } from '../../helpers/actionTypes';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const AgencyDeleteButton = (props) => {
    const { agency, deleteAgency } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(agency.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [agency, isConfirmOpen])

    return (
        <>
            <Button
                color="inherit"
                variant="contained"
                className={classes.removeButton}
                startIcon={<Delete />}
                onClick={() => setIsConfirmOpen(true)}>
                {t('Permanently Delete') + ' ' + agency.name}
            </Button>

            <ConfirmationDialog
                title={t('Delete : ') + agency.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteAgency()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete <b>{agency.name}</b>?</LCTypography>
                        <LCTypography>This action CANNOT be un-done and once you delete this object, you will have to recreate all items,
                            data associated with it.</LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

AgencyDeleteButton.propTypes = {
    account: PropTypes.object.isRequired,
    redirectPathname: PropTypes.string
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteAgencyFromStore: () => dispatch({
            type: AGENCY_DELETE,
            agency: props.agency
        }),
        deleteAgency: () => {
            return dispatch(deleteAgency(props.agency))
                .then(() => {
                    if (props.redirectPathname) {
                        history.push({ pathname: props.redirectPathname });
                    }

                    dispatch(showSuccess(`"${props.agency.name}" was successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AgencyDeleteButton
);
