import {
    OBJECT_USERS_INVITATIONS_REQUEST, OBJECT_USERS_INVITATIONS_RESULT, OBJECT_USERS_INVITE,
    OBJECT_USERS_REMOVE, OBJECT_USERS_REQUEST, OBJECT_USERS_RESULT, OBJECT_USERS_UNINVITE, OBJECT_USERS_UPDATE_ROLES
} from "../../helpers/actionTypes";
import { InvitationStatus } from "../../helpers/constants";

export const initialState = {
    loadingUsers: false,
    loadingInvites: false,

    users: [],
    invites: [],
}

export default function users(state = initialState, action) {
    const initializedState = { ...initialState, ...state };

    switch (action.type) {
        case OBJECT_USERS_REQUEST:
            return Object.assign({},
                state,
                {
                    loadingUsers: true
                });

        case OBJECT_USERS_RESULT:
            return Object.assign({},
                state,
                {
                    loadingUsers: false,
                    users: action.data
                });
        case OBJECT_USERS_REMOVE:
            return Object.assign({},
                state,
                {
                    users: initializedState.users.filter(a => a.id !== action.id)
                });
        case OBJECT_USERS_UPDATE_ROLES:
            return Object.assign({},
                state,
                {
                    users: initializedState.users.map(user => {
                        if (user.id == action.id) {
                            return { ...user, pivot: action.pivot }
                        }

                        return user;
                    })
                });

        case OBJECT_USERS_INVITATIONS_REQUEST:
            return Object.assign({}, state, { loadingInvites: true });
        case OBJECT_USERS_INVITATIONS_RESULT:
            return Object.assign({}, state, { loadingInvites: false, invites: action.data });
        case OBJECT_USERS_INVITE:
            return Object.assign({},
                state,
                {
                    invites: [
                        ...action.data.filter(x => x.status !== InvitationStatus.accepted.id),
                        ...initializedState.invites
                    ],
                    users: [
                        ...initializedState.users,
                        ...action.data.filter(x => x.user && x.status == InvitationStatus.accepted.id)
                            .map(x => x.user),
                    ]
                });
        case OBJECT_USERS_UNINVITE:
            return Object.assign({},
                state,
                {
                    invites: initializedState.invites.filter(x => x.lcuid !== action.invitationLcuid),
                });

        default:
            return state
    }
}
