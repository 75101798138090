import React from 'react';
import clx from 'classnames';
import { Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Edit as EditIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => {
    return {
        root: {
            position: 'relative',
            width: 140,
            height: 140,

            '&:hover': {
                '& .MuiBox-root': {
                    opacity: 0.2
                },
                '& .MuiSvgIcon-root': {
                    opacity: 1
                }
            }
        },
        avatar: {
            width: '100%',
            height: '100%',
            '& .MuiSvgIcon-root': {
                width: '2.5em',
                height: '2.5em'
            }
        },

        editBox: {
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: theme.palette.common.black,
            opacity: 0,
            zIndex: 1,
        },

        editIcon: {
            cursor: 'pointer',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.secondary.main,
            zIndex: 2,
            opacity: 0,
        }
    }
});

function AvatarEditable({ name, src, onClick, fallbackIcon, classes = {} }) {
    const _classes = useStyles();

    return (<Box className={clx(_classes.root, classes.root)}
        onClick={onClick}>
        <Avatar alt={name}
            className={clx(_classes.avatar, classes.avatar)}
            src={src}>
            {!src && fallbackIcon}
        </Avatar>

        <Box className={_classes.editBox} />
        <EditIcon className={_classes.editIcon} />
    </Box >);
}

export default AvatarEditable;
