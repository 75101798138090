import { FEATURE_TOGGLE_ENABLE, FEATURE_TOGGLE_DISABLE } from "../helpers/actionTypes";

export function toggle(featureName, enable) {
    return {
        type: enable
            ? FEATURE_TOGGLE_ENABLE
            : FEATURE_TOGGLE_DISABLE,

        feature: featureName
    }
}