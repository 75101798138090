import { apiHttp } from "../helpers/api";

export default class LucitApiCreativeProofs {
    getPublic(id) {
        return apiHttp.get(`/public/creative-proofs/${id}`)
            .then(response => response.data);
    }

    getAttachments(lcuid) {
        return apiHttp.get(`/creative-proofs/${lcuid}/attachments`)
            .then(response => response.data.attachments);
    }

    addAttachment(lcuid, attachable_lcuid) {
        return apiHttp.post(`/creative-proofs/${lcuid}/attachments`, { attachable_lcuid })
            .then(response => response.data.attachment);
    }
}
