import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeclineIcon from '@material-ui/icons/Clear';
import { Box, IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Autocomplete } from './AutocompleteField';
import CircularProgressCentered from '../material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '400px'
        },
        disabled: {
            cursor: 'not-allowed'
        },
        button: {
            marginLeft: theme.spacing(1)
        },
        buttonError: {
            marginBottom: '20px'
        }
    }
})

const AutocompleteEditable = (props) => {
    const { classes: classesCustom = {}, value: defaultValue, validators, handleDone, isAlwaysEdit, handleIsEdit,
        ViewComponent,
        editButtonProps, doneButtonProps, cancelProps, circularProgressProps,
        editIconProps, doneIconProps, cancelIconProps
        , ...rest } = props;

    const [submitting, setSubtmitting] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [touched, setIsTouched] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [isEdit, setIsEdit] = useState(isAlwaysEdit);

    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    const settingIsEdit = (value) => {

        setIsEdit(value);

        //If the parent component wants us to do something when in edit mode, do that
        if (handleIsEdit)
            handleIsEdit(value);
    }

    const syncError = isEdit
        && touched
        && (validators || [])
            .map(validator => validator(value))
            .find(e => !!e);
    const error = syncError || submitError;

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    return (
        <Box className={clx(classes.root, classesCustom.root)}>
            {!isEdit
                && ViewComponent}
            {(isEdit || !ViewComponent)
                && <Autocomplete
                    onChange={(_, value) => {
                        setValue(value);

                        !touched && setIsTouched(true)
                    }}
                    multiple={false}
                    disabled={!isEdit || submitting}
                    value={value}
                    classes={{
                        input: clx({ [classes.disabled]: !isEdit })
                    }}
                    textFieldProps={{
                        variant: "outlined",
                    }}
                    meta={{ touched, error }}
                    options={[]}

                    {...rest}
                />}

            {!isEdit
                && <IconButton
                    size={isXs ? 'small' : 'medium'}
                    className={clx(classes.button, { [classes.buttonError]: error })}
                    onClick={() => settingIsEdit(true)}
                    {...editButtonProps}>
                    <EditIcon {...editIconProps} />
                </IconButton>}
            {isEdit
                && <>
                    {submitting && <Box marginLeft={2}>
                        <CircularProgressCentered {...circularProgressProps} />
                    </Box>}
                    {!submitting &&
                        <>
                            <IconButton
                                size={isXs ? 'small' : 'medium'}
                                disabled={Boolean(syncError)}
                                className={clx(classes.button, { [classes.buttonError]: error })}
                                onClick={() => {
                                    setSubmitError(null);
                                    setSubtmitting(true);
                                    handleDone(value)
                                        .then(() => {
                                            settingIsEdit(false);
                                        })
                                        .catch(error => {
                                            setSubmitError(error.errors._error);
                                        })
                                        .finally(() => {
                                            setSubtmitting(false);
                                        });
                                }}
                                {...doneButtonProps}>
                                <DoneIcon {...doneIconProps} />
                            </IconButton>
                            <IconButton
                                size={isXs ? 'small' : 'medium'}
                                className={clx(classes.button, { [classes.buttonError]: error })}
                                onClick={() => {
                                    setValue(defaultValue);
                                    settingIsEdit(false);
                                    setSubmitError(null);
                                }}
                                {...cancelProps}>
                                <DeclineIcon {...cancelIconProps} />
                            </IconButton>
                        </>}
                </>}

        </Box>
    );
}

AutocompleteEditable.propTypes = {
    handleDone: PropTypes.func.isRequired
}

export default AutocompleteEditable;
