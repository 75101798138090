import React from 'react';
import PropTypes from 'prop-types';
import { FileErrors, fileToBase64, validateFile } from '../../helpers/file';

const FileUploadInput = (props) => {
    const { id, forwardedRef, extensions, maxSize, children, onError, onSuccess, ...rest } = props;

    const onChange = files => {
        if (!files.length) {
            return;
        }

        const file = files[0];
        const validationPromise = validateFile(file, { extensions, maxSize });

        validationPromise.then(errors => {
            if (!errors.length) {
                fileToBase64(file)
                    .then(fileBase64 => onSuccess({
                        file,
                        fileBase64
                    }))
                    .catch(error => {
                        console.error(error);

                        onError([{
                            type: FileErrors.invalidFile,
                            message: `File '${file.name}' is invalid. Please upload another one`
                        }])
                    });
            } else {
                onError(errors);
            }
        })
    }

    return (
        <>
            <input
                ref={forwardedRef}
                id={id}
                type="file"
                onChange={e => {
                    onChange(e.target.files);

                    e.target.value = null;
                }}
                hidden

                {...rest} />

            {children}
        </>
    )
};

FileUploadInput.propTypes = {
    id: PropTypes.string.isRequired,

    maxSize: PropTypes.number,
    extensions: PropTypes.arrayOf(PropTypes.string),

    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
}

export default React.forwardRef((props, ref) => <FileUploadInput {...props} forwardedRef={ref} />);
