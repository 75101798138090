import { Box, Link } from '@material-ui/core';
import React from 'react';
import { PageTitle } from '../../components';
import { withPublicLayout } from '../../layouts/PublicLayout';
import { useHistory, useRouteMatch } from 'react-router';
import InvoiceDetails from '../../containers/billing/InvoiceDetails';
import { ArrowBack } from '@material-ui/icons';
import LCTypography from '../../components/material/LCTypography';

const PublicInvoice = () => {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <Box>
            <PageTitle title={"Invoice"} />
            <Box p={2}>
                {history.length
                    && <Box mb={1}>
                        <Link
                            component="button"
                            onClick={() => history.goBack()}>
                            <Box display="flex" alignItems="center">
                                <ArrowBack />
                                <LCTypography style={{ marginLeft: 8 }}>Back</LCTypography>
                            </Box>
                        </Link>
                    </Box>}
                <InvoiceDetails lcuid={match.params.lcuid} invoiceAccessToken={match.params.invoiceAccessToken} publicView />
            </Box>
        </Box>
    );
}

export default withPublicLayout(PublicInvoice, {
    disablePaper: true,
    disablePermanentDrawer: true,
    showHomeButton: true,
});
