import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TextShadowIcon(props) {
    return <SvgIcon {...props} viewBox="0 0 1080 1080">
        <defs>
            <style>{`
                .cls-1-text-shadow {
                    fill: #bbbbbb;
      }

                .cls-1-text-shadow, .cls-2-text-shadow {
                    stroke - width: 0px;
      }
            `}</style>
        </defs>
        <path className="cls-1-text-shadow" d="M998.71,455.08l58.29,86.26-155.04,461.62h-157.37s116.02-150.5,
        116.02-150.5l-148.66-154.92L185.05,1002.96l-119.05-75.19,932.71-472.69ZM829,661.77l54,82,92.4-173.29-146.4,91.29Z" />
        <path className="cls-2-text-shadow" d="M390.32,94.42h158.32l352.97,908.37h-157.02l-83.05-215.41h-384.11l-83.05,
        215.41H37.35L390.32,94.42ZM333.22,644.63h272.51l-136.26-354.26-136.26,354.26Z" />
    </SvgIcon>
}
