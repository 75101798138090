import { apiHttp } from "../helpers/api";

export default class LucitApiBilling {
    getInvoice(lcuid) {
        return apiHttp.get(`/invoices/${lcuid}`)
            .then(response => response.data.invoice)
    }

    getInvoiceObjectAccessToken(lcuid) {
        return apiHttp.get(`/invoices/${lcuid}/object-access-token`)
            .then(response => response.data)
    }

    getPublicViewInvoice(lcuid, invoiceAccessToken) {
        return apiHttp.get(`/public/invoices/${lcuid}`, {
            params: {
                object_access_token: invoiceAccessToken
            }
        })
            .then(response => response.data.invoice)
    }

    payInvoice(lcuid) {
        return apiHttp.put(`/invoices/${lcuid}/pay`)
            .then(response => response.data)
    }
}
