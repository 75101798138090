import { IonCard } from '@ionic/react';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getUserRoles } from '../../selectors/user';
import InvitedList from '../users/InvitedList';
import NewInviteButton from '../users/NewInviteButton';
import UsersList from '../users/UsersList';

const ApplicationUsers = ({ application }) => {
    return <>
        <IonCard style={{ color: 'unset' }}>
            <Box p={2}>
                <NewInviteButton
                    lcuid={application.lcuid}
                    currentUserRoles={getUserRoles(application)} />

                <Typography variant="h6">Team:</Typography>
                <Box mt={1} />
                <UsersList
                    lcuid={application.lcuid}
                    currentUserRoles={getUserRoles(application)}
                />

                <Box mt={3} />
                <Typography variant="h6">Pending Invitations:</Typography>
                <Box mt={1} />
                <InvitedList lcuid={application.lcuid} />
            </Box>
        </IonCard>
    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ApplicationUsers
);
