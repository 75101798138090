import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import {
    BarChart, AreaChart, Area, Bar, Tooltip, XAxis, ResponsiveContainer
} from 'recharts';
import { Box, Grid, IconButton, MenuItem, Paper, Typography, Tooltip as MaterialTooltip } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { BarChart as BarChartIcon, ShowChart as AreaChartIcon } from '@material-ui/icons';
import { getPlaysByPeriod } from '../../actions/analytics';
import { useTheme } from '@material-ui/styles';
import { PeriodDateFormat, Periods } from '../../helpers/constants';
import TextField from '../../components/inputs/TextField';
import { shouldConvertToLocalTime } from '../../selectors/analytics';
import { DateRangeInput } from '../../components/inputs/DateRangeInput';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import ScreenFilter from './ScreenFilter';
import { selectedProfile } from '../../selectors/user';
import CampaignFilter from './CampaignFilter';

const datapoint = {
    totalPlays: 'total_plays',
    totalImpressions: 'total_impressions',
    totalPlayDuration: 'total_play_duration'
};

const datapoints = [
    { "name": "Plays", "data_point": datapoint.totalPlays },
    { "name": "Impressions", "data_point": datapoint.totalImpressions },
    { "name": "Duration", "data_point": datapoint.totalPlayDuration }
];

const chartTypes = {
    bar: 'bar',
    area: 'area'
}

function PlaysStatsByPeriod(props) {
    const { timeframe, setTimeframe, period, setPeriod, periods, timeframes, getPlaysByPeriod } = props;
    const options = Object.assign({
        hideFilters: false,
        hideChartType: false,
        hideTooltip: false,
        height: 400
    }, props.options);

    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [chartType, setChartType] = useState(chartTypes.bar);
    const [data, setData] = useState([]);
    const [dataPoint, setDataPoint] = useState(datapoint.totalPlays);
    const [screen, setScreen] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const { t } = useTranslation();

    const [dateRange, setDateRange] = useState({
        "startDate": new Date(new Date().setDate(new Date().getDate() - 30)),    //30 days ago
        "endDate": new Date()
    })

    const getTimeframeOpts = () => {

        return {
            "start_date": dateRange.startDate,
            "end_date": dateRange.endDate
        }
    }

    const showDateSelector = () => timeframe === "custom"

    useEffect(() => {

        const controller = new AbortController();

        if (period && timeframe) {
            setLoading(true);

            getPlaysByPeriod(period, timeframe, getTimeframeOpts(), screen && screen.id, campaign && campaign.id,
                { signal: controller.signal }
            )
                .then(data => data.map(x => {

                    const date = moment.utc(x.period);
                    const format = PeriodDateFormat[period] || PeriodDateFormat[Periods.daily];

                    const convertedDate = shouldConvertToLocalTime(period) ? date.local() : date

                    return {
                        ...x,
                        date: convertedDate.format(format)
                    };
                }))
                .then(x => setData(x))
                .finally(() => setLoading(false))
        }

        return () => controller.abort();
        // eslint-disable-next-line
    }, [period, timeframe, getPlaysByPeriod, screen, campaign, dataPoint, dateRange])

    return (
        <Box width={'100%'}>
            {!options.hideFilters
                && <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('Periods')}
                            input={{
                                onChange: (e) => setPeriod(e.target.value),
                                value: period
                            }}
                            select={true}
                            fullWidth={true}
                            variant={"outlined"}
                        >
                            {periods.map(p => (
                                <MenuItem key={p.period} value={p.period}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('Timeframes')}
                            input={{
                                onChange: (e) => setTimeframe(e.target.value),
                                value: timeframe
                            }}
                            select={true}
                            fullWidth={true}
                            variant={"outlined"}
                        >
                            {timeframes.map(t => (
                                <MenuItem key={t.timeframe} value={t.timeframe}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        {showDateSelector() &&
                            <DateRangeInput

                                meta={{}}
                                input={{
                                    value: dateRange,
                                    onChange: (value) => setDateRange(value)
                                }}
                                fullWidth
                                mobilePickerProps={{
                                    disablePast: true,
                                    autoOk: true,
                                    emptyLabel: 'No End Date',
                                    // eslint-disable-next-line react/display-name
                                }}
                                pickerProps={{
                                    showMonthAndYearPickers: false,
                                    showDateDisplay: false,
                                    months: 2,
                                    direction: "horizontal",
                                }}
                                format={moment(dateRange.startDate).isSame(dateRange.endDate, 'year')
                                    ? "MMM DD"
                                    : "MMM DD, YYYY"}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('Data Point')}
                            input={{
                                onChange: (e) => setDataPoint(e.target.value),
                                value: dataPoint
                            }}
                            select={true}
                            fullWidth={true}
                            variant={"outlined"}
                        >
                            {datapoints.map(t => (
                                <MenuItem key={t.data_point} value={t.data_point}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ScreenFilter
                            value={screen}
                            onChange={e => setScreen(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CampaignFilter
                            value={campaign}
                            onChange={e => setCampaign(e)}
                        />
                    </Grid>
                </Grid>}

            {data && data.length > 0
                && chartType === chartTypes.bar
                && (<Box width={"100%"} height={options.height} mt={1}>
                    <ResponsiveContainer >
                        <BarChart
                            data={data}
                            margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                            barCategoryGap={1}
                            barGap={0}
                        >
                            <XAxis dataKey="date" />
                            {!options.hideTooltip
                                && <Tooltip content={<CustomTooltip />} />}
                            <Bar dataKey={dataPoint} fill={theme.palette.secondary.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>)}
            {data && data.length > 0
                && chartType === chartTypes.area
                && (<Box width={"100%"} height={options.height} mt={1}>
                    <ResponsiveContainer >
                        <AreaChart
                            data={data}
                            margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                        >
                            <XAxis dataKey="date" />
                            {!options.hideTooltip
                                && <Tooltip content={<CustomTooltip period={period} />} />}
                            <Area type="monotone"
                                dataKey={dataPoint}
                                stroke={theme.palette.secondary.dark}
                                fill={theme.palette.secondary.main} />
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>)}

            {loading && <CircularProgressCentered />}

            {!loading
                && (!data || !data.length)
                && <Box mt={4} pl={4}>
                    <LCTypography>No analytics available for this timeframe</LCTypography>
                </Box>}

            {!options.hideChartType
                && <Box display='flex' justifyContent='flex-end'>
                    <MaterialTooltip title={t('Display bar chart')}>
                        <IconButton
                            color={chartType === chartTypes.bar
                                ? "secondary"
                                : "default"}
                            onClick={() => setChartType(chartTypes.bar)}>
                            <BarChartIcon />
                        </IconButton>
                    </MaterialTooltip>
                    <MaterialTooltip title={t('Display trend chart')}>
                        <IconButton
                            color={chartType === chartTypes.area
                                ? "secondary"
                                : "default"}
                            onClick={() => setChartType(chartTypes.area)}>
                            <AreaChartIcon />
                        </IconButton>
                    </MaterialTooltip>
                </Box>}

            <ChartTotals data={data} />

        </Box>
    );
}

const ChartTotals = ({ data }) => {

    const { t } = useTranslation();

    const totalPlays = data.reduce((acc, cur) => acc + cur.total_plays, 0);
    const totalImpressions = data.reduce((acc, cur) => acc + cur.total_impressions, 0);
    const totalPlayDuration = data.reduce((acc, cur) => acc + cur.total_play_duration, 0);

    return (data && data.length > 0)
        && <Box>
            {t('Total {{plays}} plays, {{impressions}} impressions and {{hours}} hours on screen.', {
                plays: totalPlays.toLocaleString(),
                impressions: totalImpressions.toLocaleString(),
                hours: moment.duration(totalPlayDuration, 'seconds').as('hours').toFixed(1)
            })}
        </Box>;
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload.length) {
        const dataKey = payload[0].dataKey;
        const datapointName = (datapoints.find(x => x.data_point === dataKey) || {}).name;
        const item = payload[0].payload;
        const { t } = useTranslation();

        return (
            <Paper>
                <Box p={2}>
                    {item.total_impressions > 0
                        && <>
                            <LCTypography transProps={{ itemTotalImpressions: item.total_impressions.toLocaleString() }} variant="body2">
                                {{ itemTotalImpressions: item.total_impressions.toLocaleString() }} Impressions
                            </LCTypography>
                            <Typography component="p" variant="caption" color="textSecondary">
                                {t('with')}
                                {moment.duration(item.total_play_duration, 'seconds').format("hh:mm:ss", { trim: false })}
                                {t('Time On Screen')}
                            </Typography>
                            <LCTypography transProps={{ itemTotalPlays: item.total_plays.toLocaleString() }}
                                component="p" variant="caption" color="textSecondary">
                                via {{ itemTotalPlays: item.total_plays.toLocaleString() }} Plays
                            </LCTypography>
                        </>}

                    {item.total_impressions <= 0
                        && <>
                            {dataKey === datapoint.totalPlayDuration
                                ? <Typography variant="body2">
                                    {moment.duration(payload[0].value, 'seconds').format("hh:mm:ss", { trim: false }).toLocaleString()}
                                    {t('Time On Screen')}
                                </Typography>
                                :
                                <Typography variant="body2">
                                    {payload[0].value.toLocaleString()} {datapointName}
                                </Typography>}
                        </>}

                    <Typography variant="caption" color="textSecondary">{item.date}</Typography>
                </Box>
            </Paper>
        );
    }

    return null;
};

const mapStateToProps = (state) => {
    return {
        profile: selectedProfile(state)

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPlaysByPeriod: (period, timeframe, timeframe_opts, screenId, campaignId) =>
            dispatch(getPlaysByPeriod(period, timeframe, timeframe_opts, screenId, campaignId))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PlaysStatsByPeriod)
    );
