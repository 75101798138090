import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import ChangeTemplateDialog from './ChangeTemplateDialog';
import { getItem, setDriveTemplate } from '../../actions/inventory';
import { addCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { inventoryItemClass } from '../../selectors/inventory';

function ChangeTemplateButton({ buttonProps, item, setDriveTemplate }) {

    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const { t } = useTranslation();

    if (!inventoryItemClass(item).canAssignTempalates)
        return null

    return <>

        <IonButton
            color="primary"
            size="small"
            onClick={() => setIsNewDialogOpen(true)}
            {...buttonProps}>
            {t('Change')}
            <IonIcon slot="start" icon={addCircleOutline} />
        </IonButton>

        {isNewDialogOpen
            && <ChangeTemplateDialog
                open={isNewDialogOpen}
                selectedTemplateLcuid={item.drive_template ? item.drive_template.lcuid : null}
                handleClose={(selectedTemplateLcuid) => {
                    setDriveTemplate(item.id, selectedTemplateLcuid)
                    setIsNewDialogOpen(false)
                }
                } />}

    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDriveTemplate: (itemId, templateLcuid) => dispatch(setDriveTemplate(itemId, templateLcuid))
            .then(() => dispatch(getItem(itemId)))
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    ChangeTemplateButton
);
