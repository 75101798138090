import { ENVIRONMENT_LOAD_RESULT } from "../helpers/actionTypes";
import { Global } from "../helpers/constants";
import { environment as RuntimeEnvironment, getCurrentPlatform, getBrowserInfo } from "../helpers/environment";
const { browserName, userAgent } = getBrowserInfo();

export const initialState = {
    nodeEnv: process.env.NODE_ENV,
    version: RuntimeEnvironment.VERSION,
    apiUrl: RuntimeEnvironment.API_BASE_URL,
    luCoreVersion: '',
    luCoreHost: '',
    luCoreServerIp: '',
    phpVersion: '',
    lcUtilsVersion: '',
    platform: getCurrentPlatform(),
    canvasSizeLimits: Global.defaultCanvasSizeLimits,
    browserName,
    userAgent
}

export default function environment(state = initialState, action) {
    const { type, ...data } = action;

    switch (type) {
        case ENVIRONMENT_LOAD_RESULT:
            return Object.assign({},
                state,
                data);
        default:
            return state
    }
}
