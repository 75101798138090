import React from 'react';
import { Typography, Box, ListItemAvatar, Avatar, ListItemText, ListItem, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ExportsIcon from '../../components/icons/ExportIcon';
import { CampaignGroupIcon, StatsDelayedChip, StatsUnavailableChip } from '../../components';
import { InventoryExportCampaignClasses, RoutePaths } from '../../helpers/constants';
import CampaignStatusText from '../campaigns/CampaignStatusText';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            background: 'none'
        },
        image: {
            width: '100%',
            objectFit: 'contain'
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center'
        },
        imageIcon: {
            width: '100%',
            height: '100%'
        },
        active: {
            background: theme.palette.success.main,
            color: theme.palette.common.white
        },
        exportDelayedChip: {
            marginLeft: theme.spacing(1)
        },
        exportAvatarIcon: {
            width: '100%',
            height: '100%',
            color: theme.palette.primary.main
        },
    }
})

function ExportListItem(props) {
    const { item } = props;
    const classes = useStyles();

    return (
        <ListItem key={item.id}
            button
            divider
            component={RouterLink}
            to={`${RoutePaths.exports}/${item.id}`}
        >
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {item.options?.primary_image_public_url
                        ? <img
                            src={item.options.primary_image_public_url}
                            className={classes.image}
                            alt={item.name}
                            title={item.name}
                        />
                        : item.campaign_class == InventoryExportCampaignClasses.group.class
                            ? <CampaignGroupIcon className={classes.exportAvatarIcon} />
                            : <ExportsIcon className={classes.exportAvatarIcon} />}
                </Avatar>
            </ListItemAvatar>

            <ListItemText>
                <Typography variant="body1">{item.name}</Typography>
                <Box mt={1}>
                    <Grid container spacing={1}>
                        {item.last_run_at
                            && <Grid item className={classes.gridItem} >
                                <Typography variant="body2" color="textSecondary">
                                    <CampaignStatusText campaign={item} />
                                    <StatsDelayedChip
                                        exportObject={item}
                                        chipProps={{
                                            className: classes.exportDelayedChip
                                        }}
                                    />
                                    <StatsUnavailableChip
                                        exportObject={item}
                                        chipProps={{
                                            className: classes.exportDelayedChip
                                        }} />
                                </Typography>
                            </Grid>}
                    </Grid>
                </Box>

            </ListItemText>
        </ListItem>
    );
}

export default withRouter(
    ExportListItem
);
