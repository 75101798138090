import React from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { hasIcon } from '../../selectors/exports';
import { Business } from '@material-ui/icons';
import { approvalAgencyGroups } from '../../selectors/inventory';
import { ApprovalStatus } from '../../helpers/constants';
import { lastComment, rejectionCodeSelector } from '../../selectors/approvals';

const useStyles = makeStyles(theme => {
    return {
        approved: {
            background: theme.palette.success.light
        },

        approvalChip: {
            marginRight: theme.spacing(1)
        },
        rejected: {
            marginRight: theme.spacing(1),
            background: theme.palette.error.light
        }
    }
})

function InventoryItemOperators(props) {
    const { item, rejectionCodeSelector } = props;
    const classes = useStyles();

    const approvalGroups = approvalAgencyGroups(item.approvals);

    if (approvalGroups.length === 0) {
        return null;
    }

    return <>
        <Box display="flex">
            <Business />
            <Box mr={1} />
            <LCTypography variant="h6">Approvals</LCTypography>
        </Box>
        <List>
            {approvalGroups.map((group, idxg) => (
                <ListItem key={idxg} divider>
                    <ListItemAvatar>
                        {hasIcon(group.agency)
                            ? <Avatar src={group.agency.options.primary_image_public_url} />
                            : <Avatar>
                                <Business />
                            </Avatar>}
                    </ListItemAvatar>
                    <ListItemText >
                        {group.agency.name}
                        <br />

                        {[ApprovalStatus.pending, ApprovalStatus.ok, ApprovalStatus.rejected]
                            .map(status => ({
                                status,
                                approvals: group.approvals.filter(x => x.approval_status === status.id)
                            }))
                            .filter(x => x.approvals.length > 0)
                            .map((x, idx) => {
                                return <span key={idx}>
                                    <Chip
                                        className={clx(classes.approvalChip, {
                                            [classes.approved]: x.status === ApprovalStatus.ok,
                                            [classes.rejected]: x.status === ApprovalStatus.rejected,
                                        })}
                                        size="small"
                                        label={<>
                                            {x.status !== ApprovalStatus.rejected
                                                && <>
                                                    {x.approvals.length > 1
                                                        ? `${x.approvals.length} ${x.status.title}`
                                                        : `${x.status.title}`}
                                                </>}
                                            {x.status === ApprovalStatus.rejected
                                                && <>

                                                    {x.approvals.length > 1
                                                        ? `${x.approvals.length} Rejected (${rejectionCodeSelector(x.approvals[0].rejection_code)})`
                                                        : `Rejected (${rejectionCodeSelector(x.approvals[0].rejection_code)})`}
                                                </>}
                                        </>}
                                    />
                                    {x.status === ApprovalStatus.rejected
                                        && <LCTypography
                                            transProps={{ commentApproval: lastComment(x.approvals[0]) }}
                                            variant="caption"><b>Note</b>: {{ commentApproval: lastComment(x.approvals[0]) }}</LCTypography>}
                                </span>
                            })}
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    </>;
}

InventoryItemOperators.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        rejectionCodeSelector: rejectionCodeSelector(state)
    };
}

export default connect(
    mapStateToProps
)(
    InventoryItemOperators
);
