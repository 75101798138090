import { connect } from 'react-redux';
import { showSystemNotification } from '../actions/systemNotification';
import { useEffect } from 'react';
import { connectionStatusChange } from '../actions/user';

const InternetMonitor = (props) => {
    const { handleOnline, handleOffline } = props;

    useEffect(() => {
        if ('onLine' in window.navigator) {
            !window.navigator.onLine
                && handleOffline();
        }

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        }
    }, [handleOnline, handleOffline])

    return null;
};

const notificationKey = "internet";
const mapDispatchToProps = dispatch => {
    return {
        handleOnline: () => {
            dispatch(connectionStatusChange(true))
            dispatch(showSystemNotification(notificationKey, 'Connection re-established', { duration: 10 * 1000 }))

        },
        handleOffline: () => {
            dispatch(connectionStatusChange(false))
            dispatch(showSystemNotification(notificationKey, 'No Connection', { duration: null }))
        }
    }
}

export default connect(
    null,
    mapDispatchToProps
)(InternetMonitor);
