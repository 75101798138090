import React from 'react';
import {
    Field,
} from 'redux-form';
import DebounceInput from './DebounceInput';

const DebounceField = ({
    component,
    ...props
}) => (
    <Field
        {...props}
        component={DebounceInput}
        ownerComponent={component}
    />
);
DebounceField.displayName = 'DebounceField';

export default DebounceField;
