import { useEffect, useState } from "react";
import { lucitApi } from "../../services/lucitApi";
import { toDictionary } from "../../helpers/array";
import { Global } from "../../helpers/constants";
import { setExportRunSchedule, setExportBoardIds } from "../../actions/inventory";
import { useDispatch, useSelector } from "react-redux";
import { LIGHTNING_GROUP_CAMPAIGN_ADDED, LIGHTNING_GROUP_CAMPAIGN_UPDATED, LIGHTNING_SET_CHILD_CAMPAIGNS } from "../../helpers/actionTypes";
import { selectedAgency } from "../../selectors/user";

export const useExportSettings = (item, child_exports) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false)
    const [weightsCurrent, setWeightsCurrent] = useState({});
    const [weights, setWeights] = useState([]);
    const [schedulesCurrent, setSchedulesCurrent] = useState({});
    const [scheduleDictionary, setScheduleDictionary] = useState({});
    const [itemBoardIdsCurrent, setItemBoardIdsCurrent] = useState({});
    const [itemBoardIdsDictionary, setItemBoardIdsDictionary] = useState({});

    const agency = useSelector(selectedAgency);
    const shadowAccount = useSelector(state => state.lightning.shadowAccount);

    const getItemIdForScreenFilterEdit = (campaign) => {
        return campaign.options.connected_inventory_items_by_account[shadowAccount.lcuid] ?? null;
    }


    // Load current settings
    useEffect(() => {
        if (item?.lcuid) {
            setLoading(true);
            lucitApi.exports.getExportItems(item?.lcuid, { per_page: 1_000 })
                .then(data => {
                    setWeightsCurrent(
                        toDictionary(data.item_status.data,
                            x => x.lcuid,
                            x => x.item.weight)
                    );
                    setSchedulesCurrent(
                        toDictionary(data.item_status.data,
                            x => x.lcuid,
                            x => x.item.schedule
                        )
                    );
                    setItemBoardIdsCurrent(
                        toDictionary(data.item_status.data,
                            x => x.lcuid,
                            x => x.item.board_ids
                        )
                    );
                })
                .finally(() => setLoading(false))
        }
    }, [item?.lcuid])

    // Initialize schedules when child_exports added/removed
    useEffect(() => {
        setScheduleDictionary(
            toDictionary(
                child_exports,
                x => x.lcuid,
                x => ({
                    campaign: x,
                    schedules: getItemIdForScreenFilterEdit(x) ? (
                        schedulesCurrent[getItemIdForScreenFilterEdit(x)] ?? []
                    ) : []
                })
            )
        );
    }, [child_exports, schedulesCurrent])

    // Initialize schedules when child_exports added/removed
    useEffect(() => {
        setItemBoardIdsDictionary(
            toDictionary(
                child_exports,
                x => x.lcuid,
                x => ({
                    campaign: x,
                    board_ids: getItemIdForScreenFilterEdit(x) ? (
                        itemBoardIdsCurrent[getItemIdForScreenFilterEdit(x)] ?? []
                    ) : []
                })
            )
        );
    }, [child_exports, itemBoardIdsCurrent])

    // Initialize weights when screen groups added/removed
    useEffect(() => {

        const totalWeight = child_exports.length > 0 ? child_exports.reduce((prev, cur) => {
            const id = getItemIdForScreenFilterEdit(cur);
            return id ? prev + (weightsCurrent[id] ?? 0) : prev;
        }, 0) : 0;

        const totalItems = child_exports.length

        //New items will get avg weight instead of default weight - Which is many times, way too low
        const avgWeight = (totalItems > 0 && totalWeight > 0) ? totalWeight / totalItems : Global.defaultItemWeight;

        setWeights(child_exports.map(g => {
            const id = getItemIdForScreenFilterEdit(g);

            //This hacky shit says : IF the item has the default weight of 10 -
            //Lets find a better default weight
            //Assuming "better" means "average" which is probably not always true, but is infinitely better
            //then giving it weight of 10 when the other item has a weight of 50000

            const bestWeight = (
                !id || weightsCurrent[id] == Global.defaultItemWeight || !weightsCurrent[id]
            ) ? avgWeight : weightsCurrent[id];

            return {
                id,
                title: g.name,
                weight: bestWeight
            }
        }).filter(x => x.id))
    }, [child_exports, weightsCurrent])

    const handleSave = async (item, saveOptions) => {
        const promises = [];

        setSaving(true);

        // Autocreate
        if (item == null) {
            item = await lucitApi.campaigns.generateGroupCampaign({ ...saveOptions, agency_lcuid: agency.lcuid })
                .then((response) => {
                    dispatch({
                        type: LIGHTNING_GROUP_CAMPAIGN_ADDED,
                        groupCampaign: response.inventory_export
                    });
                    return response.inventory_export
                });

        }
        // Autosave name
        else if (saveOptions) {
            promises.push(
                lucitApi.exports.update(item.id, saveOptions)
                    .then((response) => dispatch({
                        type: LIGHTNING_GROUP_CAMPAIGN_UPDATED,
                        groupCampaign: response.inventory_export
                    }))
            );
        }

        promises.push(
            lucitApi.exports
                .setChildExports(item.lcuid, child_exports.map(c => c.lcuid))
                .then(() => dispatch({
                    type: LIGHTNING_SET_CHILD_CAMPAIGNS,
                    item,
                    child_exports,
                }))
        );

        // Save Frequency weights
        promises.push(
            lucitApi.exports.setItemsWeights(item.lcuid, weights),
        );

        //Save updated schedules
        promises.push(
            ...Object.values(scheduleDictionary)
                .filter(x => x.dirty)
                .map(x => dispatch(
                    setExportRunSchedule(
                        getItemIdForScreenFilterEdit(x.campaign),
                        item.id,
                        x.schedules
                    )
                ))
        )

        //Save board id filters
        promises.push(
            ...Object.values(itemBoardIdsDictionary)
                .map(x => dispatch(
                    setExportBoardIds(
                        getItemIdForScreenFilterEdit(x.campaign),
                        item.id,
                        x.board_ids
                    )
                ))
        )

        setSaving(true);
        return Promise.all(promises)
            .then(() => item)
            .finally(() => setSaving(false));
    };

    const setSchedule = (item, newSchedule) => setScheduleDictionary({
        ...scheduleDictionary,
        [item.lcuid]: {
            ...scheduleDictionary[item.lcuid],
            schedules: newSchedule,
            dirty: true
        }
    });

    const setBoardIds = (item, newBoardIds) => setItemBoardIdsDictionary({
        ...itemBoardIdsDictionary,
        [item.lcuid]: {
            ...itemBoardIdsDictionary[item.lcuid],
            board_ids: newBoardIds
        }
    });

    return {
        loading,
        scheduleDictionary,
        itemBoardIdsDictionary,
        weights,
        setWeights,
        setSchedule,
        setBoardIds,
        handleSave,
        saving
    }
}
