import { Avatar, Grid, Typography, makeStyles, Box, Button, Divider } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { getInitials } from '../../helpers/string';
import { hasIcon } from '../../selectors/account';
import { Share } from '@capacitor/share';
import { Map, Share as ShareIcon } from '@material-ui/icons';
import { Language as LanguageIcon } from '@material-ui/icons';
import { getFullUrl } from '../../helpers/history';
import clx from 'classnames';
import { RoutePaths, QueryString, ProfileType } from '../../helpers/constants';
import { hasAgency } from '../../selectors/user';
import { getQueryString } from '../../helpers/url';
import { useTranslation } from 'react-i18next';
import { isOperator } from '../../selectors/agencies';
import { ProfileChangeButton } from '../layout/ProfileChangeButton';

const useStyles = makeStyles(theme => {
    return {
        avatar: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top'
        },
        btn: {
            //height: 36
        }
    }
})

const ManageAgencyButton = ({ user, agency, isLoggedIn }) => {

    if (!isLoggedIn)
        return null;

    if (!hasAgency(user)(agency.lcuid))
        return null;

    return <ProfileChangeButton
        targetProfile={{ ...agency, type: ProfileType.agency }}
        pathname={RoutePaths.home}
        style={{ marginRight: '10px' }}
        color="secondary"
        variant="contained"
    >
        {isOperator(agency) ? `Manage Operator` : `Manage Agency`}
    </ProfileChangeButton>
}

const JoinAgencyButton = ({ agency, isLoggedIn }) => {

    let history = useHistory();

    const inviteLcuid = getQueryString(history, QueryString.inviteLcuid);
    const { t } = useTranslation();

    if (isLoggedIn)
        return null;

    if (!inviteLcuid)
        return null;

    return (
        <>
            <Typography variant="body1">
                <Button
                    style={{ marginRight: '10px' }}
                    color="secondary"
                    variant="contained"
                    component={RouterLink}
                    to={{
                        pathname: RoutePaths.registrationNew,
                        search: `?lcuid=${agency.lcuid}`
                    }}
                >
                    {t('JOIN')}
                </Button>
            </Typography>
        </>
    )
}

function AgencyProfile(props) {
    const { agency, share, isLoggedIn, user } = props;

    const classes = useStyles();
    const history = useHistory();

    return <Grid container spacing={2}>
        <Grid item xs={12} md="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {hasIcon(agency)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={agency.options.primary_image_public_url}
                        className={classes.image}
                        alt={agency.name}
                        title={agency.name}
                    />
                </Avatar>
                : <Avatar className={classes.avatar}>
                    {getInitials(agency.name)}
                </Avatar>}
        </Grid>
        <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">{agency.name}</Typography>

            {agency.description
                && <Box mt={2}>
                    <Typography variant="body1">{agency.description}</Typography>
                </Box>}

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                <ManageAgencyButton
                    user={user}
                    agency={agency}
                    isLoggedIn={isLoggedIn}
                />

                <JoinAgencyButton
                    agency={agency}
                    isLoggedIn={isLoggedIn} />

                {isOperator(agency) && <Button
                    className={clx(classes.btn)}
                    onClick={() => history.push({ pathname: `${RoutePaths.public.xr}/${agency.slug}` })}
                    startIcon={<Map />}
                    variant="outlined"
                    color="primary">
                    Map
                </Button>}

                <Button
                    className={clx(classes.btn)}
                    style={{ marginLeft: '10px' }}
                    onClick={() => share(agency, agency.name, agency.description)}
                    startIcon={<ShareIcon />}
                    variant="outlined"
                    color="primary">
                    Share
                </Button>

                {agency.website
                    &&
                    <Button
                        style={{ marginLeft: '10px' }}
                        className={clx(classes.btn)}
                        href={agency.website}
                        startIcon={<LanguageIcon />}
                        variant="outlined"
                        color="primary">
                        Website
                    </Button>}

            </div>

        </Grid>

    </Grid>
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        user: state.user,
    }
}

const mapDispatchToProps = () => {
    return {
        share: (account, title, text) => {
            return Share.share({
                title: title,
                text: text,
                url: getFullUrl(RoutePaths.public.slug(account.slug)),
                dialogTitle: 'Share',
            });
        },

    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(
    AgencyProfile
);
