import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, initialize, getFormValues } from 'redux-form';
import { Grid } from '@material-ui/core';

import TextField from '../../components/inputs/TextField';
import { selectedAccountIds, selectedAccount } from '../../selectors/user';
import { debounce } from '../../helpers/async';
import { filters } from '../../selectors/feeds';
import { useFirstRun } from '../../helpers/hooks/useFirstRun';
import { useQueryString } from '../../helpers/hooks/useQueryString';
import { QueryString } from '../../helpers/constants';

function FeedsFilters(props) {
    const { initialValues, selectedAccount, handleSubmit, onSubmit, initializeFilters, accountIds } = props;

    const submit = handleSubmit(onSubmit);
    const [submitDebounced, setSubmitDebounced] = useState(null);

    const isFirstRun = useFirstRun();
    const search = useQueryString(QueryString.search);

    useEffect(() => {
        let filters = initialValues;

        if (search) {
            filters = Object.assign({}, filters, { search });
        }

        setSubmitDebounced(() => debounce(submit, 1000));
        initializeFilters(filters);
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        // As you know useEffect is running not only if dependencies changed, but also on initial render
        // we really don't want to initially submit form here
        if (!isFirstRun) {
            submit();
        }

        // eslint-disable-next-line
    }, [search, selectedAccount])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>

                {accountIds.length > 1 &&
                    <Grid item xs={12}>
                        <Field
                            name="search"
                            component={TextField}
                            onChange={submitDebounced}
                            props={{
                                label: "Search",
                                variant: "outlined",
                                fullWidth: true,
                                clearable: true
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </form>
    );
}

const mapStateToProps = state => {
    const initialValues = Object.assign({},
        {
            status: null
        },
        filters(state));

    const values = Object.assign({}, initialValues, getFormValues(FEEDS_FILTERS_FORM)(state))

    return {
        accountIds: selectedAccountIds(state),
        initialValues: initialValues,
        values: values,
        selectedAccount: selectedAccount(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initializeFilters: values => dispatch(initialize(FEEDS_FILTERS_FORM, values)),
    }
}

export const FEEDS_FILTERS_FORM = "feedsFilters";
export default reduxForm({
    enableReinitialize: true,
    form: FEEDS_FILTERS_FORM
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FeedsFilters)
);
