/* eslint-disable no-unused-vars */
import React from 'react';
import { submit, isSubmitting } from 'redux-form';
import { DialogContent, DialogActions, Button, useTheme, useMediaQuery } from '@material-ui/core';

import { connect } from 'react-redux';
import { Dialog, ButtonLoader } from '../../components/material';
import NewInviteForm, { NEW_INVITE_FORM } from './NewInviteForm';
import { useTranslation } from 'react-i18next';

const NewInviteDialog = (props) => {
    const { submitting, submitForm, onNewInvite, roles, open, title, handleClose, dialogProps } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            {...dialogProps}
        >
            <DialogContent>
                <NewInviteForm
                    roles={roles}
                    onSubmit={onNewInvite} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={() => submitForm()}
                    color="secondary"
                    variant="contained">
                    {t('Invite')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const mapStateToProps = (state) => {

    return {
        submitting: isSubmitting(NEW_INVITE_FORM)(state)
    }
}

const mapStateToDispatch = (dispatch) => {
    return {
        submitForm: () => dispatch(submit(NEW_INVITE_FORM))
    }
}

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    NewInviteDialog
)
