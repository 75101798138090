import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ContactForm from '../containers/contact/ContactForm';
import { PageTitle } from '../components';
import { withPublicLayout } from '../layouts/PublicLayout';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    }
}));

const Contact = () => {
    const classes = useStyles();
    const [message, setMessage] = useState('');

    return (
        <Container component="main" maxWidth="xs">
            <PageTitle title={"Contact"} />
            <div className={classes.paper}>
                <LCTypography component="h1" variant="h5">
                    Contact us
                </LCTypography>

                {message
                    && <>
                        <Box m={5} />
                        <Alert severity={"success"}>
                            <Box mb={1}>{message}</Box>
                        </Alert>
                    </>}
                {!message
                    && <ContactForm onSubmitted={data => setMessage(`Your message was successfully sent. Ticket with number #${data.ticket_number} was created.`)} />}
            </div>
        </Container>
    )
}

export default withPublicLayout(Contact, { disablePaper: true, disablePermanentDrawer: true, showHomeButton: true });
