import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { App } from '@capacitor/app';
import { setContext } from '../actions/user';
import { connect } from 'react-redux';
import { QueryString } from '../helpers/constants';
import { getQueryString } from '../helpers/url';

const AppUrlListener = () => {
    let history = useHistory();

    useEffect(() => {
        const unsubscribe = App.addListener('appUrlOpen', data => {
            const url = new URL(data.url);

            if (url && url.pathname) {
                history.push(url.pathname)
            }
            // use hash if pathname is empty (so hash routes will work too)
            else if (url && url.hash) {
                history.push(url.hash.substr(1));
            }

            const contextAccountId = getQueryString(QueryString.contextAccountId);

            if (contextAccountId) {
                setContext({
                    accountId: contextAccountId
                });
            }
        });

        return () => {
            unsubscribe.remove();
        }
    }, [history]);

    return null;
};

const mapDispatchToProps = dispatch => {
    return {
        setContext: data => {
            dispatch(setContext(data));
        }
    }
}

export default connect(
    null,
    mapDispatchToProps
)(AppUrlListener);
