import Axios from "axios";
import { environment } from "./environment";
import { removeTrailingSlash } from "./string";
import { useOfflineErrorInterceptor } from "./interceptors/offlineErrorInterceptor";
import { useAuthenticationErrorInterceptor } from "./interceptors/authenticationErrorInterceptor";
import { useTransientErrorInterceptor } from "./interceptors/transientErrorInterceptor";
import { useDecryptInterceptor } from "./interceptors/decryptInterceptor";
import { useCancellationInterceptor } from "./interceptors/cancellationInterceptor";

export let controller = new AbortController();
export const apiHttp = Axios.create({
    baseURL: removeTrailingSlash(environment.API_BASE_URL),
    headers: {
        AppTokenV2: environment.LAYOUT_APP_TOKEN,
        AppVersion: environment.VERSION,
        LCDeviceId: environment.LC_DEVICE_ID
    }
});

useCancellationInterceptor(apiHttp, controller);
useOfflineErrorInterceptor(apiHttp);
useTransientErrorInterceptor(apiHttp);
useAuthenticationErrorInterceptor(apiHttp);
useDecryptInterceptor(apiHttp);

export function resetController() {
    controller = new AbortController();
}

export function setupAPI(options) {

    updateAPI(options);
    return apiHttp;
}

export function updateAPI(options) {
    if (options.accessToken) {
        apiHttp.defaults.headers.common['Authorization'] = `Bearer ${options.accessToken}`;
    }

    return apiHttp;
}
