import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateTimeRelative from '../../../components/DateTimeRelative';
import CircularProgressCentered from '../../../components/material/CircularProgressCentered';
import { Global, InventoryItemCreativeState, InventoryItemStatus } from '../../../helpers/constants';
import { exportSettings } from '../../../selectors/exports';
import { itemOptions } from '../../../selectors/inventory';
import DraftChip from '../itemCardContent/DraftChip';
import CreativesList from './CreativesList';
import TitleEditable from './TitleEditable';

export const useStyles = makeStyles(theme => {
    return {
        grid: {
            display: 'flex',
            flexDirection: 'column'
        },

        attribute: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },
    }
})

function InventoryItemDetailsPhotoStream(props) {
    const { item, aspect } = props;
    const { t } = useTranslation();

    const bestAspect = () => item
        && item.drive_template
        && item.drive_template.templates
        && item.drive_template.templates.base
        && item.drive_template.templates.base.photo_crop_aspect_ratio
        ? item.drive_template.templates.base.photo_crop_aspect_ratio
        : aspect;

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>

                {item.creative_state === InventoryItemCreativeState.building
                    && <Box component={Paper} elevation={2} p={2} mb={1} justifyContent="center">
                        <CircularProgressCentered size={10} rootStyle={{ margin: "3px", display: "inline" }} />
                        {t('Building Creatives, Please Wait...')}
                    </Box>}

                <CreativesList item={item} aspect={bestAspect()} />

            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <TitleEditable
                        label={t('Caption')}
                        item={item}
                    />

                </Box>

                {!itemOptions(item).title_in_draft
                    && item.status === InventoryItemStatus.draft && <>
                        <Box sx={{ width: '100%', display: "block" }} mb={3}>
                            <Alert severity="warning">
                                You must <strong>activate</strong> this
                                <DraftChip item={item} size="medium" />
                                creative before it can be added to your campaign
                            </Alert>
                        </Box>
                    </>}

                <Box mt={2}>
                    <Typography variant="caption">
                        Created <DateTimeRelative date={moment.utc(item.created_at).local()} />
                    </Typography>
                </Box>
            </Grid>
        </>
    );
}

InventoryItemDetailsPhotoStream.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = state => {

    const exportSetting = state.exports.data
        .map(exportSettings)
        .map(x => x.transformer)
        .find(x => x.photo_crop_aspect_ratio) || {};

    return {

        aspect: exportSetting.photo_crop_aspect_ratio
            ? +exportSetting.photo_crop_aspect_ratio
            : Global.imageAspectForPhotoStream,
    }
}

export default
    connect(
        mapStateToProps
    )(
        InventoryItemDetailsPhotoStream
    );
