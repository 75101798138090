import { saveMarkAsRead } from '../../actions/pushNotifications';
import { NotificationTypes } from '../../helpers/constants';
import * as TextNotification from './TextNotification';
import * as ExportChangedNotification from './ExportChangedNotification';
import * as CampaignStartedNotification from './CampaignStartedNotification';
import * as CampaignStateChangeNotification from './CampaignStateChangeNotification';
import * as MassEmailNotification from './MassEmailNotification';
import * as ExportItemSoldAndRemovedFromBillboardNotification from './ExportItemSoldAndRemovedFromBillboardNotification';
import * as MultipleExportItemsSoldAndRemovedFromBillboardNotification from './MultipleExportItemsSoldAndRemovedFromBillboardNotification';
import * as CampaignAnalyticsNotification from './CampaignAnalyticsNotification';
import * as AddMoreItemsNotification from './AddMoreItemsNotification';
import * as AccountInformationNotification from './AccountInformationNotification';
import * as NewCreativesNeedingApprovalNotification from './NewCreativesNeedingApprovalNotification';
import * as CreativeItemRejectedNotification from './CreativeItemRejectedNotification';
import * as CreativeItemApprovedNotification from './CreativeItemApprovedNotification';
import * as GenericReactionAndCommentNotification from './GenericReactionAndCommentNotification';
import * as InventoryItemFirstPlayNotification from './InventoryItemFirstPlayNotification';
import { setContext } from '../../actions/user';

export const NotificationHandlers = {
    [NotificationTypes.text]: TextNotification,
    [NotificationTypes.exportChanged]: ExportChangedNotification,
    [NotificationTypes.campaignStartedNotification]: CampaignStartedNotification,
    [NotificationTypes.campaignStateChangeNotification]: CampaignStateChangeNotification,
    [NotificationTypes.massEmail]: MassEmailNotification,
    [NotificationTypes.exportItemSoldAndRemovedFromBillboardNotification]: ExportItemSoldAndRemovedFromBillboardNotification,
    [NotificationTypes.multipleExportItemsSoldAndRemovedFromBillboardNotification]:
        MultipleExportItemsSoldAndRemovedFromBillboardNotification,
    [NotificationTypes.campaignAnalyticsNotification]: CampaignAnalyticsNotification,
    [NotificationTypes.addMoreItemsNotification]: AddMoreItemsNotification,
    //Note that account and agency use the same class
    [NotificationTypes.accountInformationNotification]: AccountInformationNotification,
    [NotificationTypes.agencyInformationNotification]: AccountInformationNotification,

    [NotificationTypes.newCreativesNeedingApprovalNotification]: NewCreativesNeedingApprovalNotification,
    [NotificationTypes.creativeItemRejectedNotification]: CreativeItemRejectedNotification,
    [NotificationTypes.creativeItemApprovedNotification]: CreativeItemApprovedNotification,
    [NotificationTypes.genericReactionAndCommentNotification]: GenericReactionAndCommentNotification,
    [NotificationTypes.inventoryItemFirstPlayNotification]: InventoryItemFirstPlayNotification
}

export const handleNotification = (notification, dispatch) => {
    const type = notification.type;
    const handler = NotificationHandlers[type]
        || NotificationHandlers[NotificationTypes.text];

    const handleClick = () => handleNotificationTap(notification, dispatch);

    return handler.handleNotification(notification, dispatch, handleClick);
}

export const handleNotificationTap = (notification, dispatch) => {
    const type = notification.type;
    const handler = NotificationHandlers[type]
        || NotificationHandlers[NotificationTypes.text];

    if (!notification.read_at) {
        dispatch(saveMarkAsRead(notification.id))
    }

    if (notification.data.context_account_id != null) {
        dispatch(setContext({
            accountId: notification.data.context_account_id
        }));
    }

    if (notification.data.context_agency_id != null) {
        dispatch(setContext({
            agencyId: notification.data.context_agency_id
        }));
    }

    return handler.handleClick(notification, dispatch);
}
