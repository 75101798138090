import { getUser } from "../selectors/user";
import { lucitApi } from "../services/lucitApi";

export function submitHelpRequest(data) {
    return (_, getState) => {
        const user = getUser(getState());
        const request = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message,

            userId: user.id,
        }

        return lucitApi.submitHelpRequest(request);
    }
}