import React from 'react';
import clx from 'classnames';
import { Avatar, makeStyles } from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { Business } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    }
}));

const AgencyAvatar = ({ agency, className, ...rest }) => {
    const classes = useStyles();

    return (<>
        {hasIcon(agency)
            ? <Avatar className={clx(classes.avatar, className)} {...rest} >
                <img
                    src={agency.options.primary_image_public_url}
                    className={classes.image}
                    alt={agency.name}
                    title={agency.name}
                />
            </Avatar>
            : <Avatar className={className} {...rest}>
                <Business />
            </Avatar>}
    </>);
}

export default AgencyAvatar;
