import React, { useState, useEffect } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { Map } from '../../components/google-maps';
import { convertBoardArrayToLocationsArray } from '../../selectors/billboard';
import { lucitApi } from "../../services/lucitApi";

const useStyles = makeStyles(theme => {
    return {
        map: {
            width: '100%',
            height: '300px',
            borderRadius: '10px',
            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
            [theme.breakpoints.up('md')]: {
                height: '400px',
            }
        }
    }
})

const OperatorBoardMapFromSelectedScreens = ({ chosenAgency, selectedBoardIds, setSelectedBoardIds, boards, disabled }) => {

    const classes = useStyles();

    const [digitalBoards, setDigitalBoards] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        boards ? setDigitalBoards(boards) : lucitApi.agencies.getDigitalBoards(chosenAgency.id).then(boards => {
            setDigitalBoards(boards);
        });
    }, [chosenAgency, boards])

    useEffect(() => {

        const boardLocations = convertBoardArrayToLocationsArray(digitalBoards).map(l => {
            var location = Object.assign({}, l);
            location.targeted = selectedBoardIds.some(boardId => location.board_ids.map(b => b.id).includes(boardId));
            return location;
        });

        setLocations(boardLocations)

    }, [digitalBoards, selectedBoardIds])

    return <Box mt={2}>

        <Map
            classes={{
                map: classes.map
            }}
            locations={locations}
            showCenterButton={false}
            firstTimeCenterKey={((chosenAgency ? chosenAgency.id : '_') + digitalBoards.length).toString()}
            centerFirstTimeOnly={true}
            showTargetedButton={false}
            showTargetedOnly={false}
            highlightTargeted={true}
            enableClustering={false}
            onLocationChange={(l) => {

                if (disabled)
                    return;

                const boardIds = l.board_ids.map(b => b.id);

                if (l.targeted) {
                    setSelectedBoardIds(selectedBoardIds => selectedBoardIds.filter(id => !boardIds.includes(id)))
                }
                else {
                    setSelectedBoardIds(selectedBoardIds => [...selectedBoardIds, ...boardIds])
                }

            }}
        />

    </Box>
}

export default OperatorBoardMapFromSelectedScreens
