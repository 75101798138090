import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, DialogActions, DialogContent, Link, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sortDate } from "../../helpers/sorters";
import useInterval from "../../helpers/hooks/useInterval";
import MaterialTable from "material-table";
import { lucitApi } from '../../services/lucitApi';
import { CircularProgressCentered, Dialog, Tooltip } from '../../components';
import { toLocalDateTime } from '../../helpers/date';
import moment from 'moment';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ReactJson from 'react-json-view';
import { Skeleton } from '@material-ui/lab';
import LCTypography from '../../components/material/LCTypography';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        }
    }
})

export const DeviceLogsDialog = ({ device, open, handleClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [logs, setLogs] = useState([]);
    const [firstLoadComplete, setFirstLoadComplete] = useState(false);
    const [logToView, setLogToView] = useState(null);

    const loadLogs = () => lucitApi.lightning.getLogs(device.lcuid, "lightning")
        .then((deviceLogs) => setLogs(deviceLogs?.logs?.sort((a, b) => sortDate(b.datetime, a.datetime)) ?? []));

    const refreshInterval = 10_000;

    useEffect(() => {
        loadLogs()
            .finally(() => {
                setFirstLoadComplete(true)
            })
    }, [device])
    useInterval(loadLogs, refreshInterval)

    const columns = [
        {
            title: t('Datetime'),
            filtering: true,
            sorting: true,
            field: 'datetime',
            render: x => {
                return <Tooltip title={`${moment.utc(x.datetime).format("MM/DD/YYYY hh:mm A")} UTC`}>
                    <Typography>{toLocalDateTime(x.datetime).format("MM/DD/YYYY hh:mm A")}</Typography>
                </Tooltip>
            }
        },
        {
            title: t('Message'),
            filtering: true,
            sorting: true,
            field: 'message',
            render: x => {
                return <Box display="flex" alignItems="center">
                    <Chip label={x.level_name}
                        variant='outlined'
                        style={{ marginRight: 8 }}
                        size="small" />

                    {x.context?.data
                        && !Array.isArray(x.context?.data)
                        ? <Link
                            onClick={() => setLogToView(x)}
                            style={{ cursor: 'pointer' }}
                            color='secondary'>{x.message}</Link>
                        : <Typography>{x.message}</Typography>}
                </Box>
            }
        }
    ];

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            title={t('Logs : ') + device.name}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>
                {!firstLoadComplete
                    && <>
                        <Skeleton variant='rect' width="100%" height={25} />
                        <hr />
                        <Skeleton variant='rect' width="100%" height={25} />
                        <hr />
                        <Skeleton variant='rect' width="100%" height={25} />
                        <hr />
                        <Skeleton variant='rect' width="100%" height={25} />
                        <hr />
                        <Skeleton variant='rect' width="100%" height={25} />
                        <hr />
                        <Skeleton variant='rect' width="100%" height={25} />

                        <hr />

                    </>}
                {firstLoadComplete
                    && <>
                        <LCTypography color='textSecondary'>Refreshing data every {refreshInterval / 1000} seconds</LCTypography>
                        <MaterialTable
                            title={''}
                            columns={columns}
                            data={logs}
                            components={{
                                OverlayLoading: () => (
                                    <div>
                                        <CircularProgressCentered />
                                    </div>
                                )
                            }}
                            options={{
                                emptyRowsWhenPaging: true,
                                pageSize: 100,
                                pageSizeOptions: [100, 250],
                                debounceInterval: 100,
                                headerStyle: {},
                                paging: logs && logs.length > 100,
                                search: true,
                                showTitle: true,
                                sorting: true,
                                selection: false,
                                toolbar: true,
                                draggable: false,
                                tableLayout: 'auto'
                            }}
                        />
                    </>}

                {logToView
                    && <GenericDialog
                        title={logToView.message}
                        dialogProps={{
                            open: Boolean(logToView),
                            onClose: () => setLogToView(null),
                            fullWidth: true,
                            maxWidth: 'sm'
                        }}
                        ContentComponent={<Box>
                            <ReactJson src={logToView.context?.data}
                                collapsed={1}
                                displayDataTypes={false} />
                        </Box>}
                        ActionsComponent={<>
                            <Button
                                onClick={() => setLogToView(null)}
                                variant="outlined"
                                color="primary">
                                {t('Close')}
                            </Button>
                        </>}
                    />}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}
