import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box, IconButton, AccordionDetails, AccordionSummary, Divider, makeStyles
} from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { connect } from 'react-redux';
import { ExpandMore, Refresh } from '@material-ui/icons';
import { AccordionScrollable } from '../../components/material';
import InvitedList from './InvitedList';
import { getInvites } from '../../actions/users';

const useStyles = makeStyles(theme => {
    return {
        refreshButton: {
            marginLeft: theme.spacing(1)
        },
        pendingContentContainer: {
            width: '100%'
        }
    }
})

function InvitedListAccordion(props) {
    const { lcuid, loading, getInvites } = props;
    const classes = useStyles();
    const [isOpened, setOpened] = useState(true);

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <LCTypography variant="h5" >Pending Invites</LCTypography>

                <IconButton
                    className={classes.refreshButton}
                    size="small"
                    disabled={loading}
                    onClick={(e) => {
                        e.stopPropagation();
                        getInvites(lcuid);
                    }}>
                    <Refresh />
                </IconButton>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Box className={classes.pendingContentContainer}>
                    <InvitedList
                        lcuid={lcuid}
                    />
                </Box>
            </AccordionDetails>
        </AccordionScrollable >
    );
}

InvitedListAccordion.propTypes = {
    lcuid: PropTypes.string.isRequired,
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getInvites: lcuid => dispatch(getInvites(lcuid))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InvitedListAccordion
);
