import React, { useEffect } from 'react';
import { boardFormats, hasBoardFormat } from '../../selectors/designer';
import { connect } from 'react-redux';
import { Tab, Tabs, AppBar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const BoardFormatsTabs = ({ value, setValue, templateBoardFormats, boardFormats, hasBoardFormat }) => {
    useEffect(() => {
        const matchingFormats = Object.keys(boardFormats).filter(format => hasBoardFormat(format))

        //So - Let's grab the "middle" board from the list of board formats
        //The primary reason for this, is because the tall ones are dumb and I like to see
        //the wider ones first
        const middleIndex = Math.floor(matchingFormats.length / 2)

        const firstCode = matchingFormats ? matchingFormats[middleIndex] : null

        if (firstCode) {
            setValue({
                ...boardFormats[firstCode],
                code: firstCode
            })
        }

    }, [boardFormats, templateBoardFormats.length])

    if (!value)
        return <Skeleton animation="wave" height={70} width={'100%'} />;

    return <AppBar position="static" color="default">
        <Tabs
            value={value ? value.code : null}
            onChange={(_, code) => setValue({ ...boardFormats[code], code })}
            variant="scrollable"
            scrollButtons="auto"
        >
            {Object.keys(boardFormats)
                .filter(x => hasBoardFormat(x))
                .map((code) => {

                    const boardFormat = boardFormats[code]

                    return <Tab
                        key={code}
                        value={code}
                        style={{ textTransform: 'none' }}
                        label={<>
                            <b>{boardFormat.name}</b> {boardFormat.size.width}x{boardFormat.size.height}
                        </>} />
                })}
        </Tabs>
    </AppBar>
}

const mapStateToProps = state => {
    return {
        boardFormats: boardFormats(state),
        templateBoardFormats: state.designer.templateBoardFormats,
        hasBoardFormat: code => hasBoardFormat(state, code),
    };
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    BoardFormatsTabs
);
