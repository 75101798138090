import { useEffect } from 'react';
import { throttle } from '../async'
/**
 * useKeyDown
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export function useKeyDown(key, action, limit = 50) {
    useEffect(() => {
        const onKeyDown = throttle((e) => {
            if (Array.isArray(key) && key.includes(e.key)) {
                action();
            } else if (e.key === key) {
                action();
            }
        }, limit);

        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [action]);
}
