import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { withHomeLayout } from '../layouts/HomeLayout';
import { connect } from 'react-redux';
import PlaysStats from '../containers/analytics/PlaysStats';
import { getDatePeriods, getDateTimeframes } from '../actions/analytics';
import ButtonScrollToTopFab from '../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../components';
import CircularProgressCentered from '../components/material/CircularProgressCentered';

function Analytics(props) {
    const { getDatePeriods, getDateTimeframes } = props;

    const [loading, setLoading] = useState(false);
    const [periods, setPeriods] = useState([]);
    const [timeframes, setTimeframes] = useState([]);

    useEffect(() => {
        setLoading(true);

        // eslint-disable-next-line no-undef
        Promise.all([
            getDatePeriods()
                .then(setPeriods),
            getDateTimeframes()
                .then(setTimeframes)
        ])
            .finally(() => setLoading(false))

    }, [getDatePeriods, getDateTimeframes])

    return (
        <>
            <PageTitle title={"Analytics"} />

            <Box>
                {loading && <CircularProgressCentered />}
                {!loading && <>
                    <PlaysStats periods={periods} timeframes={timeframes} />
                </>}
            </Box>

            <ButtonScrollToTopFab />

        </>
    );
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDatePeriods: () => dispatch(getDatePeriods()),
        getDateTimeframes: () => dispatch(getDateTimeframes())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withHomeLayout(Analytics)
);
