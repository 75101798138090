import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import { Dialog as DialogMaterial, DialogTitle, IconButton, makeStyles, Slide, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DialogCloseReason } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import Draggable from "react-draggable";

const DIALOG_CLOSE_REASON_BACKDROP_CLICK = 'backdropClick';
const DIALOG_CLOSE_REASON_ESCAPE_KEY_DOWN = 'escapeKeyDown';

const useStyles = makeStyles((theme) => {
    return {
        paperSafeArea: {
            paddingTop: `env(safe-area-inset-top)`,
            paddingBottom: `env(safe-area-inset-bottom)`,
            paddingLeft: `env(safe-area-inset-left)`,
            paddingRight: `env(safe-area-inset-right)`
        },

        titleRoot: {
            position: 'relative'
        },
        titleMoveable: {
            cursor: 'move'
        },
        title: {
            paddingRight: theme.spacing(4)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
        },
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const Dialog = (props) => {
    const { classes = {},
        open,
        onClose,
        fullScreen,
        title,
        TitleProps,

        hideCloseButton,

        disableBackdropAndEscapeClose = true,

        allowDragAndMove = false,

        // Lazy means that Dialog content will be rendered only when Dialog will be opened
        lazy,
        children,
        ...rest } = props;

    const dialogClasses = useStyles();
    const [_open, _setOpen] = useState(open);

    const [closeArgs, setCloseArgs] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        _setOpen(open);
    }, [open])

    return (
        <DialogMaterial
            PaperComponent={allowDragAndMove ? PaperComponent : undefined}
            TransitionComponent={Transition}
            TransitionProps={{
                onExited: () => onClose && onClose(closeArgs.event, closeArgs.reason)
            }}

            {...rest}

            open={_open}
            onClose={(event, reason) => {

                if (disableBackdropAndEscapeClose) {
                    if (reason === DIALOG_CLOSE_REASON_BACKDROP_CLICK
                        || reason === DIALOG_CLOSE_REASON_ESCAPE_KEY_DOWN)
                        return;
                }

                _setOpen(false);
                setCloseArgs({ event, reason })
            }}
            classes={{
                paper: clx(classes.paper, {
                    [dialogClasses.paperSafeArea]: fullScreen
                }),
            }}
            fullScreen={fullScreen}
        >
            {title
                && <DialogTitle
                    className={
                        clx(dialogClasses.titleRoot, { [dialogClasses.titleMoveable]: allowDragAndMove })
                    }
                    disableTypography
                    {...TitleProps}>
                    <Typography
                        className={clx({ [dialogClasses.title]: !hideCloseButton })}
                        variant="h6">
                        {title}
                    </Typography>

                    {!hideCloseButton
                        && <IconButton aria-label={t('close')}
                            className={clx(dialogClasses.closeButton, classes.closeButton)}
                            onClick={event => {
                                _setOpen(false);
                                setCloseArgs({ event, reason: DialogCloseReason.closeIcon })
                            }}>
                            <Close />
                        </IconButton>}
                </DialogTitle>
            }
            {lazy
                ? open && children
                : children}
        </DialogMaterial >
    )
};

export default Dialog;
