import { clearPhoneNumber } from "./string";

export const requiredBuilder = errorMessage => value => value
    ? undefined
    : errorMessage;
export const required = requiredBuilder('This field is required');

export const requiredAtLeastOne = value => !value || value.length
    ? undefined
    : 'This field is required';

export const min = (min, message) => value =>
    value <= min
        ? message || `Must be greater than ${min}`
        : undefined;

export const minOrEqual = (min, message) => value =>
    value && value < min
        ? message || `Must be greater or equals ${min}`
        : undefined;

export const maxOrEqual = (max, message) => value =>
    value && value > max
        ? message || `Must be less or equals ${max}`
        : undefined;

export const minLength = (min, message) => value =>
    value && value.length < min
        ? message || `Must be ${min} characters or more`
        : undefined
export const maxLength = (max, message) => value =>
    value && value.length > max
        ? message || `Must be ${max} characters or less`
        : undefined
export const number = value => value && isNaN(Number(value))
    ? 'Must be a number'
    : undefined
export const minValue = min => value =>
    value && value < min
        ? `Must be at least ${min}`
        : undefined
export const email = value =>
    // eslint-disable-next-line no-useless-escape
    value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        ? 'Invalid email address'
        : undefined

export const regex = (regex, errorMessage) => value => {
    return value && !regex.test(value)
        ? errorMessage || 'Field has incorrect format'
        : undefined;
}
export const phoneFormat = value => regex(/^[0-9 ()-]+$/, 'Phone number has invalid format')(value)
    && minLength(10, 'Phone number has invalid format')(value)
    && maxLength(15, 'Phone number has invalid format')(clearPhoneNumber(value))
