import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  AccountProfile, AgencyProfile, UserProfile, BillboardProfile,
  CreativeProfile, CreativeProofPage, ApplicationProfile
} from './index';
import Story from './story/Story';
import { NotFoundPagePublic } from './common';
import { connect } from 'react-redux';
import ConditionalLayout from '../layouts/ConditionalLayout';
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import { getObject } from '../actions/objects';

const Slug = ({ getObject }) => {
  const { params } = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [objectClass, setObjectClass] = useState(null);
  const [object, setObject] = useState(null);

  useEffect(() => {
    setLoaded(false);

    getObject(params.slug)
      .then(data => {
        setObjectClass(data.object_class);
        setObject(data.object);
      })
      .catch(() => {
        setObjectClass(null)
      })
      .finally(() => setLoaded(true))
  }, [params.slug])

  if (!loaded) {
    return <ConditionalLayout>;
      <CircularProgressCentered size={40} />
    </ConditionalLayout>
  }

  const SlugPage = {
    'App\\Account': AccountProfile,
    'App\\Agency': AgencyProfile,
    'App\\User': UserProfile,
    'App\\DigitalBoard': BillboardProfile,
    'App\\InventoryPhoto': CreativeProfile,
    'App\\FeedStory': Story,
    'App\\CreativeProof': CreativeProofPage,
    'App\\Application': ApplicationProfile,
  }
  const Component = SlugPage[objectClass];

  if (Component == null) {
    return <NotFoundPagePublic />
  }

  return <Component object={object} />;
};

const mapDispatchToProps = dispatch => {
  return {
    getObject: (lcuid) => dispatch(getObject(lcuid))
  }
}

export default connect(null, mapDispatchToProps)(Slug);
