import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EnhancedSlider from '../../components/material/EnhancedSlider';
import { ProgrammaticBudgetTypes } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
    }
})

export const CampaignBudgetAllocationTable = ({ budgetAllocation, budget, budgetType, onChange }) => {
    const classes = useStyles();
    const venueTypes = budgetAllocation.open_ooh_venue_types;
    const screenCount = venueTypes.reduce((acc, x) => acc + x.count, 0)
    const { t } = useTranslation();

    const setallocationForVenue = (venueTypeId, newValue) => {
        const currentValue = venueTypes.find(x => x.venue_type_id == venueTypeId)?.allocation;
        const originalRemainingPercentage = 1 - currentValue;
        const remainingPercentage = 1 - newValue;

        onChange({
            open_ooh_venue_types: venueTypes.map(x => (x.venue_type_id == venueTypeId
                ? { ...x, allocation_type: 'share', allocation: newValue }
                : { ...x, allocation_type: 'share', allocation: remainingPercentage * (x.allocation / originalRemainingPercentage) }))
        });
    }

    // Set initial allocation (if not set)
    useEffect(() => {
        onChange({
            open_ooh_venue_types: venueTypes.map(x => x.allocation
                ? x
                : ({ ...x, allocation: (x.count ?? 0) / screenCount }))
        })
    }, [venueTypes.length, screenCount])

    return <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('Venue Type')}</TableCell>
                    <TableCell>{t('Screens')}</TableCell>
                    <TableCell style={{ width: "45%" }} >{t('Allocation')}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">{t('Amount')} {ProgrammaticBudgetTypes[budgetType].asSuffix}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {venueTypes.sort((a, b) => b.count - a.count)
                    .filter(x => x.level == 1) // intentionally get just 1st level
                    .map((row) => {
                        return <AllocationRow
                            key={row.venue_type_id}
                            row={row}
                            budget={budget}
                            onAllocationChange={(newValue) => setallocationForVenue(row.venue_type_id, newValue)}
                        />
                    })}
            </TableBody>
        </Table>
    </TableContainer>
}

const AllocationRow = ({ row, budget, onAllocationChange }) => {
    const [sliderValue, setSliderValue] = React.useState(row.allocation * 100);
    const [allocationBudget, setAllocationBudget] = React.useState(row.allocation * budget);

    useEffect(() => {
        setAllocationBudget((sliderValue / 100) * budget)
    }, [sliderValue, budget])

    useEffect(() => {
        setSliderValue(row.allocation * 100)
    }, [row.allocation])

    return <TableRow key={row.venue_type_id}>
        <TableCell component="th" scope="row">
            {row.fully_qualified_name?.replace(':', '/')}
        </TableCell>
        <TableCell>{row.count}</TableCell>
        <TableCell style={{ width: "45%" }} >
            <EnhancedSlider
                size="small"
                defaultValue={sliderValue}
                step={1}
                min={0}
                max={99}
                hideInput={true}
                value={sliderValue}
                onChange={(_, newValue) => setSliderValue(newValue)}
                onChangeCommitted={(_, newValue) => onAllocationChange(newValue / 100)}
            />

        </TableCell>
        <TableCell align="right">{sliderValue.toFixed(2)}%</TableCell>
        <TableCell align="right">${allocationBudget.toFixed(2)}</TableCell>
    </TableRow>
}
