import moment from 'moment';

export function getTimezoneParts(timezone) {
    const parts = timezone.name.split('/');

    if (parts.length < 2) {
        return {
            continent: timezone.name
        };
    }

    const [continent, city, region] = parts;

    return {
        continent,
        city: city && city.replace(/_/gi, ' '),
        region: region && region.replace(/_/gi, ' ')
    }
}

export function isLessThan(date, value, unit) {
    const diff = moment().diff(moment(date));

    return moment.duration(diff) < moment.duration(value, unit)
}

export function isMoreThan(date, value, unit) {
    const diff = moment().diff(moment(date));

    return moment.duration(diff) > moment.duration(value, unit)
}
