import React, { useState } from 'react';
import { Paper, makeStyles, Box, Grid, MenuItem, Tooltip, ButtonGroup, Menu } from '@material-ui/core';
import LCTypography from '../components/material/LCTypography';
import { withHomeLayout } from '../layouts/HomeLayout';
import { connect } from 'react-redux';
import { submit, isSubmitting, getFormValues } from 'redux-form';
import { searched, isInvalidated } from '../selectors/exports';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import ExportsList from '../containers/exports/ExportsList';
import ExportsFilters, { EXPORTS_FILTERS_FORM } from '../containers/exports/ExportsFilters';
import { search } from '../actions/exports';
import { store } from '../reducers';
import { CampaignGroupIcon, ExportIcon, PageTitle } from '../components';
import { hasExports } from '../selectors/exports'
import CircularProgressCentered from '../components/material/CircularProgressCentered';
import QuickAddProof from '../containers/proofs/QuickAddProof'

import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../helpers/lucoreConstants';
import { currentUserHasPermission } from '../selectors/user';
import { InventoryExportCampaignClasses } from '../helpers/constants';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        }
    }
})

const AdvancedMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [newCampaignDialogOpen, setNewCampaignDialogOpen] = useState(false)
    const [newCampaignClass, setNewCampaignClass] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { t } = useTranslation();
    const canCreateCampaigns = useSelector(state => currentUserHasPermission(state)(AccountPolicy.createCampaigns));

    return <>
        <ButtonGroup size='small'>
            <Tooltip title={t('Advanced Settings / Options')}>
                <Button
                    size="small"
                    onClick={handleClick}
                >
                    <MoreVert />
                </Button>
            </Tooltip>
        </ButtonGroup>

        <Menu
            id="advanced-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            <MenuItem>
                <Button
                    disabled={!canCreateCampaigns}
                    color="primary"
                    variant={"contained"}
                    size="small"
                    startIcon={<ExportIcon />}
                    onClick={() => {
                        setNewCampaignClass(InventoryExportCampaignClasses.operatorContract.class);
                        setNewCampaignDialogOpen(true);
                    }}
                >
                    {t('Add Campaign')}
                </Button>
            </MenuItem>
            <MenuItem>
                <Button
                    disabled={!canCreateCampaigns}
                    color="primary"
                    variant={"contained"}
                    size="small"
                    startIcon={<CampaignGroupIcon />}
                    onClick={() => {
                        setNewCampaignClass(InventoryExportCampaignClasses.group.class);
                        setNewCampaignDialogOpen(true);
                    }}
                >
                    {t('Add Group Campaign')}
                </Button>
            </MenuItem>
        </Menu>

        <QuickAddProof
            open={newCampaignDialogOpen}
            handleClose={() => setNewCampaignDialogOpen(false)}
            dialogTitle="Create a New Campaign"
            skipAccountSelection={true}
            newCampaignOnly={true}
            newCampaignClass={newCampaignClass}
        />
    </>
}

function Campaigns(props) {
    const { submitting, submitSearch, handleSearch, hasExports } = props;
    const classes = useStyles();

    useEffect(() => {
        submitSearch();
        // eslint-disable-next-line
    }, [])

    return <>
        <PageTitle title={"Campaigns"} />
        <Paper className={classes.root}>

            <Grid container>
                <Grid item xs={12}>
                    <Box mb={2} display="flex" alignItems="center">
                        <Box mt={3}>
                            <LCTypography variant="h5">Campaigns</LCTypography>
                            <LCTypography variant="body2">
                                Click on the campaign you wish to manage, edit and add / remove ads from
                            </LCTypography>
                        </Box>
                        <Box display="flex" ml='auto' style={{ gap: 8 }}>
                            <AdvancedMenu />
                        </Box>
                    </Box>

                    <ExportsFilters onSubmit={handleSearch} />

                    {submitting ?
                        <Box mt={2} mb={2}>
                            <CircularProgressCentered size={40} />
                        </Box>

                        :
                        <>
                            <Box mt={2} />
                            {hasExports && <ExportsList />}
                        </>
                    }

                </Grid>
            </Grid>

        </Paper>
    </>;
}

const mapStateToProps = state => {
    return {
        submitting: isSubmitting(EXPORTS_FILTERS_FORM)(state),
        searched: searched(state),
        invalidated: isInvalidated(state),
        hasExports: hasExports(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitSearch: () => dispatch(submit(EXPORTS_FILTERS_FORM)),
        handleSearch: () => {
            const values = Object.assign({},
                getFormValues(EXPORTS_FILTERS_FORM)(store.getState()),
                {
                    active: 1
                });

            return dispatch(search(values));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(
            Campaigns
        )
    )
);
