import React, { useState, useEffect, useMemo } from 'react';
import {
    makeStyles, ListItemAvatar, Avatar, Typography, Box, Menu, ListItem, ListItemText, TextField, InputAdornment,
    CircularProgress,
    Button
} from '@material-ui/core';
import { hasIcon } from '../../selectors/account';
import { connect } from 'react-redux';
import { ArrowDropDown, Business } from '@material-ui/icons';
import { AgencyListItem } from '.';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import { isOperator } from '../../selectors/agencies';
import FilterIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../../components/material';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles((theme) => ({
    selectedAgencyBox: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: 'fit-content',
        padding: theme.spacing(1, 1.5),
        borderRadius: theme.shape.borderRadius,

        '&:hover': {
            background: theme.palette.action.hover
        }
    },
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'center'
    },
}));

const AllOperatorsSelectField = ({ value, onChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [renderCount, setRenderCount] = useState(20);
    const { t } = useTranslation();

    const agenciesFiltered = useMemo(() => (agencies ?? [])
        .filter(x => searchTermInText(search, [x.name])), [agencies, search])

    const onClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setLoading(true);
        lucitApi.agencies.getOperatorsWithScreens()
            .then(({ agencies }) => {
                setAgencies(agencies);
            })
            .finally(() => setLoading(false));
    }, []);

    return <>
        <SelectedAgency
            agency={value}
            onClick={(e) => setAnchorEl(e.target)}
        />

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <ListItem onKeyDown={(e) => e.stopPropagation()}>
                <ListItemText>
                    <TextField
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder="Filter..."
                        value={search || ''}

                        onChange={(event) => setSearch(event.target.value)}
                        inputProps={{
                            "aria-autocomplete": "both",
                            "aria-haspopup": "false",
                            "autoComplete": "off",
                            "spellCheck": "false"
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <Tooltip title={t('Filter')}>
                                    <FilterIcon fontSize="small" />
                                </Tooltip>
                            </InputAdornment>
                        }}
                    />
                </ListItemText>
            </ListItem>
            {loading && <CircularProgress />}
            {agenciesFiltered
                .slice(0, renderCount)
                .map(agency => (
                    <AgencyListItem
                        key={agency.id}
                        value={agency.id}
                        agency={agency}
                        handleChange={agency => {
                            onChange(agency);
                            setAnchorEl(null);
                        }}
                    />
                ))
            }
            {agenciesFiltered.length > renderCount
                && <ListItem mt={1} >
                    <Button onClick={() => setRenderCount(renderCount + 20)} variant='text' color='secondary'>
                        {t('Show more')}
                    </Button>
                </ListItem>}
        </Menu >
    </>
}

const SelectedAgency = ({ agency, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return <Box className={classes.selectedAgencyBox} onClick={onClick}>
        <ListItemAvatar>
            {agency && isOperator(agency) && hasIcon(agency)
                ? <Avatar className={classes.avatar} >
                    <img
                        src={agency.options.primary_image_public_url}
                        className={classes.image}
                        alt={agency.name}
                        title={agency.name}
                    />
                </Avatar>
                : <Avatar>
                    <Business />
                </Avatar>}
        </ListItemAvatar>
        <Typography
            color="secondary"
            variant="button"
            style={{ fontWeight: 'bold' }}
        >{agency && isOperator(agency) ? agency.name : t('Choose Operator')}</Typography>
        <Box mt={0.5} ml={1} >
            <ArrowDropDown color='secondary' />
        </Box>
    </Box>
}

const mapStateToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps
)(AllOperatorsSelectField);
