import React from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { LightningDeviceLayoutSelector } from './LightningDeviceLayoutSelector';
import { Button } from '@material-ui/core';

export const LightningDeviceLayoutSelectorDialog = ({ device,
    value,
    setValue,
    open,
    handleClose }) => {
    const { t } = useTranslation();

    return <GenericDialog
        title={t('Device Layouts')}
        dialogProps={{
            open,
            handleClose,
            fullWidth: true,
            maxWidth: 'sm'
        }}
        ContentComponent={<>
            <LightningDeviceLayoutSelector device={device}
                value={value}
                setValue={setValue} />
        </>}
        ActionsComponent={<>
            <Button onClick={handleClose} color="primary" variant='outlined'>
                {t('Close')}
            </Button>
        </>}
    />
}
