/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, Box, useTheme, useMediaQuery, Stepper,
    Step, StepLabel, StepContent, Grid
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Dialog, ButtonLoader } from '../../components/material';
import { LassoCanvasImage } from '../../components/lasso-canvas';
import { createStreetView, createStreetViewSnapshot, updateStreetView } from '../../actions/billboards';
import { pathToString } from '../../helpers/string';
import { transformCoordinates } from '../../helpers/image';
import { showError, showSuccess } from '../../actions/snackbar';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { StreetView } from '../../components';
import { gmStreetViewUrl } from '../../helpers/url';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { ImageStyle, LucitXrImageSize } from '../../helpers/constants';
import { Camera } from '@material-ui/icons';
import SkipStreetViewDialog from './SkipStreetViewDialog';
import { StreetViewMap } from '../../components/street-view';
import { resizeImage } from '../../helpers/file';
import { lucitApi } from "../../services/lucitApi";

const useStyles = makeStyles(theme => {
    return {
        dialogPaper: {
            margin: '16px 32px',
            maxHeight: 'calc(100% - 32px)',
            height: '100%'
        },

        content: {
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },

        stepper: {
            padding: 0,
            marginTop: 8,
            marginLeft: -8
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
        },
        previewImage: {
            maxHeight: '100%',
            maxWidth: '100%',
            imageOrientation: 'from-image'
        },
        previewBox: {
            '& .react-transform-component, .react-transform-wrapper': {
                height: '100%'
            }
        },
        streetViewBoardContainer: {
            maxHeight: 480,
        },
    }
})

const NewStreetViewDialog = (props) => {
    const { open, location, screen, create, createSnapshot, update, title, handleClose, dialogProps } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()
    const screenImage = screen && screen.images.find(i => i.style === ImageStyle.streetViewOverlay);

    const [loading, setLoading] = useState(false);
    const [savingSnapshot, setSavingSnapshot] = useState(false);
    const [skipOpened, setSkipOpened] = useState(false);
    const [fields, setFields] = useState({});
    const setValue = useCallback((field, value) => setFields(fields => ({ ...fields, [field]: value })), []);

    const [activeStep, setActiveStep] = React.useState(0);

    const stepProps = {
        location,
        fields, setValue,
        next: () => setActiveStep(activeStep + 1)
    };

    const steps = [
        { title: t('Street View'), content: <StreetViewStep {...stepProps} /> },
        { title: t('Select Screen'), content: <SelectBorderStep {...stepProps} /> },
        { title: t('Preview'), content: <PreviewStep {...stepProps} /> }
    ].filter(step => !props.disabledSteps || !props.disabledSteps.includes(step.title));

    useEffect(() => {
        setFields({
            position: {
                panoId: location.options && location.options.google_street_view_pano_id,
                lat: +location.latitude,
                lng: +location.longitude
            },
            pov: {
                heading: location.heading ? +location.heading : 100,
                pitch: location.pitch ? +location.pitch : 0,
                zoom: location.zoom ? +location.zoom : 1
            },
            coordinates: screenImage
                ? screenImage.options.overlay_perspective_coordinates.map(cords => ({ x: cords[0], y: cords[1] }))
                : []
        })

        if (props.step) {
            const step = steps.indexOf(steps.find(step => step.title === props.step));

            setActiveStep(step);
        }
    }, [props.step])

    return <>
        <Dialog
            open={open}
            maxWidth="lg"
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
            classes={{
                paper: classes.dialogPaper
            }}
            {...dialogProps}
        >
            <DialogContent className={classes.content}>
                <Stepper activeStep={activeStep} className={classes.stepper} orientation={isXs ? "vertical" : "horizontal"}>
                    {steps
                        .map((step) => (
                            <Step key={step.title}>
                                <StepLabel>{step.title}</StepLabel>

                                {isXs
                                    && <StepContent>
                                        {step.content}
                                    </StepContent>}
                            </Step>
                        ))}
                </Stepper>

                {!isXs
                    && <Box mt={2} mb={2} height="calc(100% - 80px)" overflow="auto"
                        display="flex" justifyContent="center" alignItems="center">
                        {steps[activeStep].content}
                    </Box>}
            </DialogContent>
            <DialogActions>
                {activeStep === 0
                    && <Box mr={1}>
                        <ButtonLoader
                            submitting={savingSnapshot}
                            startIcon={<Camera />}
                            onClick={() => {
                                setSavingSnapshot(true);
                                createSnapshot(location, screen, fields.position, fields.pov)
                                    .finally(() => setSavingSnapshot(false))
                            }}
                            variant="contained"
                            color="secondary">
                            {t('Take Snapshot')}
                        </ButtonLoader>
                    </Box>}
                {activeStep == 0
                    && <Button
                        variant="contained"
                        onClick={() => setSkipOpened(true)}
                        className={classes.button}
                    >
                        {t('Skip')}
                    </Button>}

                {activeStep > 0
                    && <Button
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                        className={classes.button}
                    >
                        {t('Back')}
                    </Button>}
                {activeStep < steps.length - 1
                    && <Button
                        disabled={activeStep === 1 && !fields.coordinates}
                        variant="contained"
                        color="primary"
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        className={classes.button}
                    >
                        {t('Next')}
                    </Button>}
                {activeStep === steps.length - 1
                    && <ButtonLoader
                        color="secondary"
                        submitting={loading}
                        onClick={() => {
                            setLoading(true);

                            //This is a mess of code
                            //It does this
                            //First, get an image from the street view img url
                            //Then, upload that and get an image object
                            //then if we are updating, update the image object, sending the image object id
                            //if we are creating, do the same

                            resizeImage(
                                fields.image, LucitXrImageSize.width, LucitXrImageSize.height, 'image/jpeg'
                            ).then(backupStreetViewImage => {
                                lucitApi.uploads.uploadImage(backupStreetViewImage, "image.jpg").then(imageObject => {
                                    if (screenImage) {
                                        return update(
                                            location, screen, fields.position, fields.pov, screenImage,
                                            fields.coordinates, imageObject.id
                                        )
                                            .then(handleClose)
                                            .finally(() => setLoading(false))
                                    }

                                    return create(location, screen, fields.position, fields.pov, fields.coordinates, imageObject.id)
                                        .then(handleClose)
                                        .finally(() => setLoading(false))
                                })
                            })
                        }}
                        variant="contained">
                        {screenImage
                            ? t('Update')
                            : t('Create')}
                    </ButtonLoader>}
            </DialogActions>
        </Dialog >

        <SkipStreetViewDialog
            screen={screen}
            open={skipOpened}
            handleClose={() => setSkipOpened(false)}
            onSkip={() => handleClose()}
        />
    </>
}

const StreetViewStep = (props) => {
    const { fields, setValue } = props;
    const classes = useStyles();

    return <Box width="100%" height="100%" className={classes.streetViewBoardContainer}>
        <StreetViewMap
            lat={fields.position.lat}
            lng={fields.position.lng}
            panoId={fields.position.panoId}
            pov={fields.pov}
            zoom={fields.pov.zoom}
            location={props.location}
            onChangePosition={(pos) => setValue('position', pos)}
            onChangePov={(pov) => setValue('pov', pov)}
        />
    </Box>
}

const SelectBorderStep = (props) => {
    const { fields, setValue } = props;
    const classes = useStyles();

    const image = useMemo(() => gmStreetViewUrl(fields.position, fields.pov), [fields.position, fields.pov]);

    const onPathChange = useCallback(path => setValue('coordinates', path), [setValue])

    useEffect(() => setValue('image', image), [image])

    return <>
        <Box height="100%" width="100%" overflow="hidden">
            <Grid container>
                <Grid item md={12} className={classes.center}>
                    <LassoCanvasImage
                        src={image}
                        path={fields.coordinates}
                        onPathChange={onPathChange}
                        classes={{ previewImage: classes.previewImage }}
                    />
                </Grid>
            </Grid>
        </Box>
    </>
}

const PreviewStep = (props) => {
    const { fields } = props;
    const imageRef = useRef();
    const classes = useStyles();
    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageNaturalSize, setImageNaturalSize] = useState({ width: 0, height: 0 });
    const image = fields.image;

    const path = transformCoordinates(fields.coordinates, imageNaturalSize, imageSize);

    const onLoad = useCallback(() => {
        setImageSize({
            width: imageRef.current.width,
            height: imageRef.current.height
        })
        setImageNaturalSize({
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight
        })

        setLoaded(true);
    }, [])

    return <Box mb={1}
        className={classes.previewBox}
        height="calc(100% - 8px)"
        display="flex"
        justifyContent="center">
        <TransformWrapper >
            <TransformComponent>
                <Box display="flex" position="relative" height="100%">
                    <img src={image}
                        ref={imageRef}
                        onLoad={onLoad}
                        className={classes.previewImage}
                    />

                    {loaded
                        && <svg className={classes.previewImage}
                            style={{
                                position: 'absolute',
                                width: imageSize.width,
                                height: imageSize.height
                            }}
                        >
                            <polygon
                                fill='#aaFFaa'
                                stroke={theme.palette.primary.main}
                                points={pathToString(path)} />
                        </svg>}
                </Box>
            </TransformComponent>
        </TransformWrapper>
    </Box>
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapStateToDispatch = (dispatch) => {
    return {
        create: (location, screen, position, pov, coordinates, backupImageId) =>
            dispatch(createStreetView(location, screen.id, position, pov, coordinates, backupImageId))
                .then(() => dispatch(showSuccess(i18n.t("Digital Screen Street View was successfully saved"))))
                .catch(error => {
                    dispatch(showError(i18n.t("Error during saving Digital Screen Street View")))
                    throw error;
                }),

        createSnapshot: (location, screen, position, pov) =>
            dispatch(createStreetViewSnapshot(location, screen.id, position, pov))
                .then(() => dispatch(showSuccess(i18n.t("Street View Snapshot was successfully saved"))))
                .catch(error => {
                    dispatch(showError(i18n.t("Error during saving Street View Snapshot")))
                    throw error;
                }),

        update: (location, screen, position, pov, screenImage, coordinates, backupImageId) =>
            dispatch(updateStreetView(location, screen.id, position, pov, screenImage, coordinates, backupImageId))
                .then(() => dispatch(showSuccess(i18n.t("Digital Screen Street View was successfully saved"))))
                .catch(error => {
                    dispatch(showError(i18n.t("Error during saving Digital Screen Street View")))
                    throw error;
                }),
    }
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    NewStreetViewDialog
)
