import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import clx from 'classnames';
import { Box, Button, Link } from '@material-ui/core';
import ButtonLoader from '../components/material/ButtonLoader';
import ResetPasswordForm from '../containers/ResetPasswordForm';
import { withRouter } from 'react-router';
import { required } from '../helpers/validators';
import { getIsCodeSending, getIsCodeSent } from '../selectors/forgotPassword';
import { requestCode, editPhone, resetPassword, reset } from '../actions/forgotPassword';
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import PhoneInput, { getFullPhone } from '../components/inputs/PhoneInput';
import { store } from '../reducers';
import { Alert } from '@material-ui/lab';
import { login } from '../actions/user';
import { useLocation } from 'react-router';
import { DefaultCountry } from '../helpers/countries';
import { PageTitle } from '../components';
import { withPublicLayout } from '../layouts/PublicLayout';
import { selectedProfile } from '../selectors/user';
import LCTypography from '../components/material/LCTypography';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    },
    form: {
        width: '100%'
    },
    error: {
        marginBottom: theme.spacing(1)
    },
    resendCode: {
        marginRight: 'auto'
    },
    resendCodeDisabled: {
        color: theme.palette.grey[600],
        cursor: 'not-allowed'
    }
}));

const ForgotPassword = (props) => {
    const { profile, phone, isCodeSent, isCodeSending, handleSubmit, error } = props;
    const classes = useStyles();
    const [resendWaitTime, setResendWaitTime] = useState(30);
    const [message, setMessage] = useState(null);
    const location = useLocation();

    const canResend = resendWaitTime === 0;

    useEffect(() => {
        store.dispatch(reset());

        if (location.state) {
            store.dispatch(initialize(FORM_FORGOT_PASSWORD, {
                phone: {
                    ...phone,
                    phoneNumber: location.state.phone
                }
            }))
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        let timerId = null;

        if (isCodeSent && !canResend) {
            timerId = setTimeout(() => {
                setResendWaitTime(resendWaitTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, [isCodeSent, resendWaitTime, canResend])

    return (
        <Container component="main" maxWidth="xs">
            <PageTitle title={"ForgotPassword"} />
            <div className={classes.paper}>
                <LCTypography component="h1" variant="h5">
                    Forgot Password
                </LCTypography>

                <Box m={2} />

                {message
                    && <>
                        <Alert severity={"info"}>
                            <Box mb={1}>{message}</Box>
                            <Link
                                component="button"
                                color="secondary"
                                className={clx(classes.resendCode, { [classes.resendCodeDisabled]: !canResend })}
                                disabled={!canResend || isCodeSending}
                                onClick={handleSubmit(values => props.handlePasswordChangeRequest(values)
                                    .then(() => setResendWaitTime(60)))}>
                                {!canResend && `(${resendWaitTime})`} Resend Code.
                            </Link>
                        </Alert>
                        <Box m={1} />
                    </>}

                <form className={classes.form} noValidate onSubmit={handleSubmit(props.handlePasswordChangeRequest)}>
                    <Field
                        name="phone"
                        validate={[required]}
                        component={PhoneInput}
                        props={{
                            id: "phone",
                            required: true,
                            disabled: isCodeSent
                        }}
                    />
                </form>

                <Box m={1} />
                {isCodeSent
                    && <ResetPasswordForm
                        gridProps={{ spacing: 2 }}
                        onSubmit={values => props.handlePasswordChange(phone, values, profile)} />}

                {!isCodeSent
                    &&
                    <>
                        {error && <LCTypography transProps={{ error: error }} className={classes.error} color="error">{{ error: error }}</LCTypography>}
                        <ButtonLoader
                            submitting={isCodeSending}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(values => props.handlePasswordChangeRequest(values).then(() => setMessage('SMS with confirmation code was sent to your phone.')))}>
                            Send Confirmation Code
                        </ButtonLoader>
                    </>}
                {isCodeSent
                    &&
                    <>
                        <Box m={1} />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={props.handleEditPhone}>
                            Back
                        </Button>
                    </>}
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    const formSelector = formValueSelector(FORM_FORGOT_PASSWORD)

    return {
        profile: selectedProfile(state),
        phone: formSelector(state, "phone"),
        isCodeSending: getIsCodeSending(state),
        isCodeSent: getIsCodeSent(state),
    }
}

const mapDisptachToProps = (dispatch, ownProps) => {

    const joinLucidFromQueryString = (ownProps && ownProps.location && ownProps.location.search)
        ? ownProps.location.search.split('join_lcuid=').pop().split('&')[0] ?? null : null;

    const hasJoinLocation = joinLucidFromQueryString && joinLucidFromQueryString.length > 0;

    return {
        handleEditPhone: () => {
            dispatch(editPhone())
        },
        handlePasswordChangeRequest: values => {
            const phoneNumber = getFullPhone(values.phone)

            return dispatch(requestCode(phoneNumber));
        },
        handlePasswordChange: (phone, values, profile) => {
            const phoneNumber = getFullPhone(phone)

            return dispatch(resetPassword(phoneNumber, values.code, values.password))
                .then(() => dispatch(login(phoneNumber, values.password, null, null, values.code)))
                .then(() => {
                    if (hasJoinLocation) {
                        return ownProps.history.push({
                            pathname: '/' + joinLucidFromQueryString
                        })
                    }
                    else {
                        ownProps.history.push({ ...ownProps.history.location, pathname: profile.type.path })
                    }
                })
        }
    }
}

export const FORM_FORGOT_PASSWORD = 'forgotPassword';

export default reduxForm({
    form: FORM_FORGOT_PASSWORD,
    initialValues: {
        phone: { countryCode: DefaultCountry.code, phoneCode: DefaultCountry.phone, phoneNumber: '' }
    }
})(connect(
    mapStateToProps,
    mapDisptachToProps
)(
    withRouter(
        withPublicLayout(ForgotPassword, { disablePaper: true, disablePermanentDrawer: true, showHomeButton: true })
    )
)
);
