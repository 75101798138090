import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { ProfileType } from '../../helpers/constants';
import { profiles } from '../../selectors/user';
import { selectProfile } from '../../actions/user';
import { useHistory } from 'react-router';
import { IonCard, IonCardContent } from '@ionic/react';
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount';
import { Button, Box } from '@material-ui/core';
import ProfileAvatar from '../layout/ProfileAvatar';
import { hasAgencies, getUser } from '../../selectors/user';
import LCTypography from '../../components/material/LCTypography';
import { toggleProfileSwitcher } from '../../actions/layout';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

    userHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    userHeaderTitleAvatar: {
        marginRight: '6px'
    },
    accountNameLink: {
        display: 'flex',
        alignItems: 'Center',
        flex: 1,
        textDecoration: 'none',
    },
    avatar: {
        backgroundColor: 'transparent',
    },
    accountMobileList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accountMobileItemInline: {
        display: 'inline',
    },
    accountMobileItem: {
        alignItems: 'center'
    },
    accountMobileListCard: {
        marginBottom: '500px'
    },
    clientItemGrid: {
        marginTop: 3,
        marginBottom: 3,
    },
    clientItemGridItem: {
        margin: 'auto',
        padding: 4,
        textAlign: 'center',
    }
}));

const UserChooseableProfiles = (props) => {
    const { profiles, selectProfile, hasAgencies } = props;

    const [showAddNewAccount, setShowAddNewAccount] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    const hasOnlyChildProfiles = profiles.filter(profile =>
        !profile.parent_account_id && profile.type.id !== ProfileType.user.id
    ).length === 0;

    const useProfiles = profiles.filter(profile =>
        (hasOnlyChildProfiles || !profile.parent_account_id) && profile.type.id !== ProfileType.user.id
    );

    return <IonCard className={classes.accountMobileListCard}>
        <IonCardContent>

            <Grid container>
                {useProfiles.map((profile, index) => {
                    return <ChooseableProfileItem key={index} profile={profile} selectProfile={selectProfile} />
                })}

                <Grid item xs={12} sm={12}>

                    {hasAgencies && <Box mt={6} mb={2} ml={1}>
                        <Box mb={2}>
                            <LCTypography variant="body2">
                                Or, add a new account to start creating new content, data sources and campaigns
                            </LCTypography>
                        </Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowAddNewAccount(true)}
                        >{t('Create New Account / Profile')}</Button>
                    </Box>
                    }
                </Grid>
            </Grid>

            <OperatorAddAccount
                showAgencyAndOperator={true}
                open={showAddNewAccount}
                handleClose={() => setShowAddNewAccount(false)}
            />
        </IonCardContent>
    </IonCard>
}

const ChooseableProfileItem = ({ profile, selectProfile }) => {
    const history = useHistory();
    const classes = useStyles();

    return <Grid item xs={12} sm={6}>
        <ListItem
            button
            alignItems="flex-start"
            className={classes.accountMobileItem}
            onClick={() => selectProfile(history, profile)}
        >
            <ListItemAvatar>
                <ProfileAvatar
                    className={classes.userHeaderTitleAvatar}
                    profile={profile} />
            </ListItemAvatar>
            <ListItemText
                primary={<>{profile.name}</>}
                secondary={
                    <></>
                }
            />
        </ListItem>
    </Grid>
}

const mapStateToProps = state => {
    return {
        profiles: profiles(state),
        currentUser: state.user,
        hasAgencies: hasAgencies(getUser(state)),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectProfile: (history, profile) => {
            dispatch(selectProfile(profile, {
                animate: true,
                onSwitched: () => history.push({ pathname: profile.type.path })
            }));

            history.push({ pathname: profile.type.path });
        },
        openProfileSwitcher: () => dispatch(toggleProfileSwitcher(true))
    }
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(
    UserChooseableProfiles
);
