import { ROLES_REQUEST, ROLES_RESULT } from "../helpers/actionTypes";
import { LuCoreModelClasses } from "../helpers/constants";
import { parseClass } from "../helpers/lcuid";
import { lucitApi } from "../services/lucitApi"

export function getRoles(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        dispatch({
            type: ROLES_REQUEST,
            objectClass,
            lcuid
        })

        let promise = Promise.resolve();

        if (objectClass === LuCoreModelClasses.agencies)
            promise = lucitApi.roles.getAgencyRoles()
        if (objectClass === LuCoreModelClasses.accounts)
            promise = lucitApi.roles.getAccountRoles()
        if (objectClass === LuCoreModelClasses.applications)
            promise = lucitApi.roles.getApplicationRoles()

        return promise.then(data => {
            dispatch({
                type: ROLES_RESULT,
                data,
                objectClass,
                lcuid
            })
        })
    }
}
