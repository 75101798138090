import React, { useCallback } from 'react';
import { DaysOfTheWeekInput } from '../../components/inputs/DaysOfTheWeekInput';
import { max } from '../../helpers/array';

function DaysOfWeek({ schedules, setSchedules }) {
    const daysOfWeek = schedules.find(s => s.type === "days_of_the_week");
    const onChange = useCallback((id, value) => {
        setSchedules(schedules.map(s => {
            return s.id === id
                ? { ...s, ...value }
                : s;
        }))
    }, [schedules])

    return <>
        <DaysOfTheWeekInput
            daysOfTheWeek={daysOfWeek?.days ?? [0, 1, 2, 3, 4, 5, 6]}
            setDaysOfTheWeek={(value) => {
                if (value.length === 7) {
                    return setSchedules(schedules.filter(x => x.id !== daysOfWeek?.id));
                }
                else if (daysOfWeek == null) {
                    setSchedules([
                        ...schedules,
                        {
                            id: max(schedules, x => x.id) + 1,
                            type: "days_of_the_week",
                            days: value
                        }
                    ])
                } else {
                    onChange(daysOfWeek.id, { days: value })
                }
            }}
        />
    </>;
}

export default DaysOfWeek;
