import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GenericDialog } from '../../components/modals/GenericDialog';
import DesignerSettings from './DesignerSettings';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            paddingTop: 0
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const DesignerSettingsDialog = (props) => {
    const { open, handleClose } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    return (<>
        <GenericDialog
            title={t('Template Settings')}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}

            ContentComponent={<>
                <DesignerSettings />
            </>}

            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Ok')}
                </Button>
            </>}
        />
    </>)
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {

    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DesignerSettingsDialog)
)
