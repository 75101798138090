import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import TextField from '../../components/inputs/TextField';
import { required, email } from '../../helpers/validators';
import { connect } from 'react-redux';
import { RoutePaths } from '../../helpers/constants';
import PasswordField from '../../components/inputs/PasswordField';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2, 0, 0)
    },
    confirm: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

const RegisterUserInfoForm = (props) => {
    const { error, submitting, handleSubmit, onSubmit } = props;
    const classes = useStyles();

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="fullName"
                validate={[required]}
                component={TextField}
                props={{
                    label: "FullName",
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "fullName",
                    autoComplete: "fullName"
                }}
            />

            <Field
                name="email"
                validate={[required, email]}
                component={TextField}
                normalize={value => value.trim()}
                props={{
                    label: "Email Address",
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "email",
                    autoComplete: "email"
                }}
            />

            <Field
                name="password"
                validate={[required]}
                component={PasswordField}
                props={{
                    label: "Password",
                    type: "password",
                    variant: "outlined",
                    margin: "normal",
                    required: true,
                    fullWidth: true,
                    id: "password",
                    autoComplete: "new-password",
                    placeholder: "Create your password"
                }}
            />

            <Typography color="error">{error}</Typography>

            {submitting
                && <Box className={classes.progress}>
                    <CircularProgressCentered />
                </Box>}
            <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={props.handleSubmit}
                className={classes.confirm}>Register</Button>

            <Grid container>
                <Grid item xs={12}>
                    <Link variant="body2" component={RouterLink} to={RoutePaths.contact}>
                        {"Contact us"}
                    </Link>
                </Grid>
            </Grid>
        </form>
    )
}

RegisterUserInfoForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

// const mapStateToProps = state => {
//     return {
//     }
// }

// const mapDipatchToProps = dispatch => {
//     return {
//     }
// }

export const FORM_REGISTER_USERINFO = "registerUserInfo";
export default reduxForm({
    form: FORM_REGISTER_USERINFO, // a unique name for this form
})(
    connect(
        // mapStateToProps,
        // mapDipatchToProps
    )(RegisterUserInfoForm));
