import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import { Box } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import { Alert, ToggleButton } from '@material-ui/lab';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    deleteLayer, moveElementLayerToAnotherIdInHtml, replaceCSSForId, replaceHtmlForId, setSelectedEditor
} from '../../actions/designer';
import { editModeGlobal, getCSSForId, getPreview, idIsSelected, allowEdit } from '../../selectors/designer';
import { VisibilityOnOff, removePositioningFromCss, getStyleValueForCSSKey, isColorLighterThanFafafa } from './DesignerCSS';
import { parseHtmlForLayers, SingleClassOnOff, getHtmlForId } from './DesignerHTML';
import { makeStyles } from '@material-ui/styles';
import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Settings } from '@material-ui/icons';
import DesignerLayerSettingsDialog from './DesignerLayerSettingsDialog';
import { Designer } from '../../helpers/constants';
import { DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';
import { CssEditorDialog } from './CssEditorDialog';
import { deriveSettingsFromLayer } from '../../selectors/designerLayers';

const useStyles = makeStyles(() => {
    return {
        layer: {
            border: "1px solid #ffffff",
        },
        layerPreviewBox: {
            width: "100px",
            minWidth: "100px",
            height: "75px",
            border: "1px solid black",
            overflow: "hidden",
            background: "#ffffff",
            display: "inline-block"
        },
        layerName: {
            fontSize: "0.8em",
            fontWeight: "medium",
            color: "#222222",
            display: "inline-block",
            marginLeft: "10px",
            verticalAlign: "top",
            whiteSpace: "normal",
        },
        layerFieldName: {
            display: "block",
            backgroundColor: "#efefef",
            padding: "2px 5px 2px 5px",
            borderRadius: "5px",
            border: "1px solid #d5d5d5",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#d5d5d5",
            }
        },
        layerIsSelected: {
            borderColor: "#8b3dff",
        },
        layerIsHidden: {
            opacity: 0.2,
        },
        layerVisibilityButton: {
            background: "none !important",
            border: "none !important",
        }
    }
})

const DesignerLayerToolComponent = ({
    previewHtml, moveElementLayerToAnotherIdInHtml, deleteLayer, getCSSForId, getHtmlForId, onChangeHtml,
    onChangeCss, editModeGlobal, idIsSelected, setSelectedEditor, allowEdit
}) => {

    const [showLayerSettings, setShowLayerSettings] = useState(null);
    const [showAdvancedEdit, setShowAdvancedEdit] = useState(null);

    const classes = useStyles();
    const { t } = useTranslation();
    const state = useSelector(state => state);

    const modifyHtmlForLayerTool = (html, layerCss) => {
        let newHtml = "";

        const layerColor = getStyleValueForCSSKey(layerCss, "color")

        let newStyle = layerCss + "width:100%;height:100%;background-position:center;background-size:contain;"
            + "background-repeat:no-repeat;display:flex;justify-content:center;align-items:center;"
            + "font-size:12px;text-align:center;white-space: pre-line;overflow-wrap: break-word;"
            + "visibility:visible;"

        if (isColorLighterThanFafafa(layerColor)) {
            newStyle += "background-color: #111111;"
        }

        //hack for text layers to make the text nicer and more centered vertically
        if (html.includes("lc_dt_text")) {
            newStyle += "padding-bottom:15px;"
        }

        if (html.includes("style=\""))
            newHtml = html.replace("style=\"", "style=\"" + newStyle);
        else
            newHtml = html.replace(">", " style=\"" + newStyle + "\">");

        return newHtml;
    }

    const layers = parseHtmlForLayers(previewHtml).reverse()
    const dispatch = useDispatch();

    const handleReorder = (event) => {
        const fromOrdinal = event.detail.from;
        const toOrdinal = event.detail.to;
        const fromId = layers[fromOrdinal].id;
        const toId = layers[toOrdinal].id;

        const direction = fromOrdinal > toOrdinal ? "forward" : "backward";

        moveElementLayerToAnotherIdInHtml(fromId, toId, direction)
        dispatch({ type: DESIGNER_PUSH_HISTORY })

        event.detail.complete(false);
    }

    return (
        <Box style={{ height: "calc(100vh - 250px)", overflowY: "scroll" }}>
            {!editModeGlobal && <Alert severity="info">{t('Changing / Moving Layers affects ALL sizes regardless of edit mode.')}</Alert>}
            <IonList>
                <IonReorderGroup disabled={!allowEdit} onIonItemReorder={handleReorder}>
                    {layers.map((layer) => {

                        if (layer.id === Designer.BackgroundLayerId)
                            return null

                        const layerSettings = deriveSettingsFromLayer(state, layer)
                        const cssForLayer = removePositioningFromCss(getCSSForId(layer.id))
                        const layerIsHidden = getStyleValueForCSSKey(cssForLayer, "visibility") === "hidden"

                        return (
                            <IonItem
                                key={layer.id}
                                className={clx(
                                    classes.layer,
                                    {
                                        [classes.layerIsSelected]: idIsSelected(layer.id)
                                    })
                                }
                            >
                                <IonLabel
                                    onClick={() => setSelectedEditor(layer.id)}
                                >

                                    <Box display="flex">
                                        <div className={clx(classes.layerPreviewBox,
                                            {
                                                [classes.layerIsHidden]: layerIsHidden
                                            })}
                                            dangerouslySetInnerHTML={{ __html: modifyHtmlForLayerTool(layer.html, cssForLayer) }}
                                        />

                                        <div className={classes.layerName}>
                                            <div
                                                title={t('Edit Layer Settings')}
                                                onClick={() => setShowLayerSettings(layer)}
                                                className={classes.layerFieldName}
                                                id={'editLayerName' + layer.id}
                                            >
                                                {layerSettings.fieldName}
                                            </div>
                                        </div>
                                    </Box>

                                    <Box mt={1}>
                                        <VisibilityOnOff
                                            title={t('Hide / Un-Hide Layer')}
                                            disabled={!allowEdit}
                                            objectId={layer.id}
                                            css={getCSSForId(layer.id)}
                                            className={classes.layerVisibilityButton}
                                            onChangeCss={(css) => onChangeCss(layer.id, css)}
                                        />

                                        <SingleClassOnOff
                                            titleOn={t('Lock Layer')}
                                            titleOff={t('Unlock Layer')}
                                            disabled={!allowEdit}
                                            objectId={layer.id}
                                            html={getHtmlForId(layer.id)}
                                            className="lc_ut_designer"
                                            useIcon
                                            IconOn={<LockOpen />}
                                            IconOff={<Lock />}
                                            buttonClassName={classes.layerVisibilityButton}
                                            onChange={(html) => onChangeHtml(layer.id, html)}
                                        />

                                        <ToggleButton
                                            size="small"
                                            className={classes.layerVisibilityButton}
                                            disabled={!allowEdit}
                                            aria-label="settings"
                                            title={t('Layer Settings')}
                                            onClick={() => setShowLayerSettings(layer)}
                                        >
                                            <Settings />
                                        </ToggleButton>

                                        <ToggleButton
                                            size="small"
                                            className={classes.layerVisibilityButton}
                                            disabled={!allowEdit}
                                            aria-label="settings"
                                            title={t('Edit Advanced Layer HTML / CSS')}
                                            onClick={() => {
                                                setSelectedEditor(layer.id)
                                                setShowAdvancedEdit(layer)
                                            }}
                                        >
                                            <CodeIcon />
                                        </ToggleButton>

                                        <ToggleButton
                                            size="small"
                                            className={classes.layerVisibilityButton}
                                            disabled={!allowEdit}
                                            aria-label="delete"
                                            title={t('Delete Layer')}
                                            onClick={() => {
                                                if (window.confirm(
                                                    "Are you sure you want to delete this element?  " +
                                                    " NOTE:  This will delete this from ALL sizes.  " +
                                                    " If you want to delete from just one size, use the 'Hide' button instead.")) {
                                                    deleteLayer(layer.id)
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </ToggleButton>

                                    </Box>
                                </IonLabel>
                                <IonReorder slot="end" style={{ marginLeft: 0 }} title={t('Move Layer')}></IonReorder>
                            </IonItem>
                        )
                    })}

                </IonReorderGroup>

            </IonList>

            {
                showLayerSettings && <DesignerLayerSettingsDialog
                    open={Boolean(showLayerSettings)}
                    layerSettings={deriveSettingsFromLayer(state, showLayerSettings)}
                    handleClose={() => setShowLayerSettings(null)}
                />
            }

            {
                showAdvancedEdit && <CssEditorDialog
                    open={showAdvancedEdit}
                    handleClose={() => setShowAdvancedEdit(false)}
                />
            }

        </Box >
    )
}

const mapStateToProps = state => {
    return {
        previewHtml: getPreview(state).html,
        getCSSForId: (id) => getCSSForId(state, id),
        getHtmlForId: (id) => getHtmlForId(id, state.designer.html),
        editModeGlobal: editModeGlobal(state),
        idIsSelected: (id) => idIsSelected(state, id),
        allowEdit: allowEdit(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        moveElementLayerToAnotherIdInHtml: (
            elementId, newParentId, direction
        ) => dispatch(moveElementLayerToAnotherIdInHtml(elementId, newParentId, direction)),
        deleteLayer: (id) => dispatch(deleteLayer(id)),
        onChangeCss: (id, css) => dispatch(replaceCSSForId(id, css)),
        onChangeHtml: (id, html) => dispatch(replaceHtmlForId(id, html)),
        setSelectedEditor: (id) => dispatch(setSelectedEditor(id, 'layer'))
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerLayerToolComponent
    );
