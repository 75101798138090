import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import useInterval from '../helpers/hooks/useInterval';
import { useTranslation } from 'react-i18next';

const formatDefault = "MM/DD/YYYY hh:mm A";
const formatNoYear = "MM/DD hh:mm A";

const DateTimeRelative = ({ date, format }) => {
    const [duration, setDuration] = useState(moment.duration(date.diff(moment())));
    const [interval, setInterval] = useState(1000);
    const { t } = useTranslation();

    const defaultFormat = date.diff(moment(), "days") > 350 ? formatDefault : formatNoYear

    useEffect(() => {
        if (Math.abs(duration.asMinutes()) > 0 && Math.abs(duration.asDays()) < 1) {
            // Duration could be refreshed once at minute (to prevent a lot of rerenders)
            setInterval(60 * 1000);
        } else {
            // null means to disable
            setInterval(null);
        }
    }, [duration])

    useInterval(() => {
        const duration = moment.duration(date.diff(moment()));

        setDuration(duration);
    }, interval);

    //If the date is in the future, show as "just now"
    if (duration.asSeconds() >= 0) {
        return t('just now');
    }

    return <>
        {interval
            && duration.humanize(true, { h: 23 })}
        {!interval
            && date.format(format || defaultFormat)}
    </>;
}

DateTimeRelative.propTypes = {
    date: PropTypes.object.isRequired,
    format: PropTypes.string,
}

export default DateTimeRelative;
