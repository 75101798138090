import React from 'react';
import BaseStory from './BaseStory';
import { hasIcon } from '../../selectors/account';
import { Avatar } from '../material';
import LCTypography from '../../components/material/LCTypography';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { RoutePaths } from '../../helpers/constants';

const AgencyTeamUserSignedUpStory = (props) => {
    const { story, ...rest } = props;
    const user = story.storyable;

    if (!user)
        return null

    const storyData = story.story_data || {};

    const accounts = storyData.accounts || [];
    const hasAccounts = accounts.length > 0;
    const agencies = storyData.agencies || [];
    const hasAgencies = agencies.length > 0;

    const combined = [...accounts, ...agencies];

    return <BaseStory
        title={user.name}
        sharableText={`${user.name} joined Lucit!`}
        story={story}
        Avatar={
            <Avatar
                alt={user.name}
                name={user.name}
                src={hasIcon(user) && user.options.primary_image_public_url}
            />}
        {...rest}
    >
        <LCTypography>Joined Lucit
            {(hasAccounts || hasAgencies) &&
                <span> and is now a member of <>{combined.map((account, index) => {
                    return <span key={index}><Link
                        component={RouterLink}
                        color="secondary"
                        to={RoutePaths.public.slug(account.slug)}>{account.name}</Link>

                        {index < combined.length - 1 ? ', ' : ''}

                    </span>
                })}</></span>
            }
        </LCTypography>
    </BaseStory >;
};

export default AgencyTeamUserSignedUpStory;
