import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, useTheme, useMediaQuery, FormControl, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Dialog, ButtonLoader } from '../../components/material';
import { setStreetViewStatus } from '../../actions/billboards';
import { showError, showSuccess } from '../../actions/snackbar';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { StreetViewStatus, StreetViewStatusDetails } from '../../helpers/constants';

const useStyles = makeStyles(theme => {
    return {
        dialogPaper: {
            margin: '16px 32px',
            maxHeight: 'calc(100% - 32px)',
            height: '100%'
        },

        content: {
            height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
    }
})

const SkipStreetViewDialog = (props) => {
    const { open, screen, handleClose, onSkip, skip } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(StreetViewStatus.unavailableOutOfDate);
    const handleValueChange = (event) => setValue(+event.target.value);

    return (
        <Dialog
            open={open}
            fullWidth
            fullScreen={isXs}
            title={'Choose reason'}
            onClose={handleClose}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.content}>
                <FormControl sx={{ m: 3 }} variant="standard">
                    <RadioGroup
                        aria-labelledby="skip"
                        value={value}
                        onChange={handleValueChange}
                    >
                        {Object.keys(StreetViewStatus)
                            .filter(x => x.startsWith('unavailable'))
                            .map(x => (
                                <FormControlLabel key={x}
                                    value={StreetViewStatus[x]}
                                    control={<Radio />}
                                    label={StreetViewStatusDetails[StreetViewStatus[x]].title} />
                            ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t('Close')}
                </Button>
                <ButtonLoader
                    color="secondary"
                    submitting={loading}
                    onClick={() => {
                        setLoading(true);

                        return skip(screen, value)
                            .then(() => {
                                onSkip();
                                handleClose();
                            })
                            .finally(() => setLoading(false))
                    }}
                    variant="contained">
                    {t('Skip')}
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const mapStateToDispatch = (dispatch) => {
    return {
        skip: (screen, status) =>
            dispatch(setStreetViewStatus(screen.id, status))
                .then(() => dispatch(showSuccess(i18n.t("Digital Screen Street View was skipped"))))
                .catch(error => {
                    dispatch(showError(i18n.t("Error during skipping Digital Screen Street View")))
                    throw error;
                }),
    }
};

export default connect(
    null,
    mapStateToDispatch
)(
    SkipStreetViewDialog
)
