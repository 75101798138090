import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clx from 'classnames';
import { withRouter } from 'react-router';
import { Grid, Divider, AccordionSummary, AccordionDetails, Box, List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { SwitchInput } from '../../components/inputs/SwitchField';
import { getChannels, getNotificationTypes, updateChannel, updateChannelForType } from '../../actions/pushNotifications';
import { Channels, ChannelTitle, NotificationTypeDetails, NotificationTypes } from '../../helpers/constants';
import { channelGloballyEnabled, getUser } from '../../selectors/user';
import { showError, showSuccess } from '../../actions/snackbar';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },
        headerIcon: {
            marginBottom: -3
        },

        lineItem: {
            paddingRight: 'calc(126px + 32px)'
        },
        lineItemSecondaryAction: {
            right: 0
        },
        lineItemSwitcher: {
            marginLeft: theme.spacing(3),
        },
        lineItemSwitcherLast: {
            marginRight: theme.spacing(1),
        },

        breakWords: {
            wordBreak: 'break-word'
        }
    }
});

function NotificationsSettings(props) {
    const { user, getChannels, getNotificationTypes, updateChannel } = props;
    const classes = useStyles();

    const [channels, setChannels] = useState([]);
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [isOpened, setOpened] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getChannels()
            .then(x => setChannels(x.filter(x => x !== Channels.database)));

        getNotificationTypes()
            .then(x => setNotificationTypes(x));
    }, [getChannels, getNotificationTypes])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="Notifications-content"
                id="Notifications-header"
            >
                <LCTypography
                    variant="h5" >
                    <NotificationsIcon className={{ classesHeaderIcon: classes.headerIcon }} />{t('Notifications')}
                </LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Grid container spacing={1} >
                    {/* <Grid item xs={12}>
                        <SwitchInput
                            label={t('Notifications')}
                            input={{
                                value: user.options.notifications_enabled,
                                onChange: () => { }
                            }} />
                    </Grid> */}

                    <Grid item xs={12}>
                        <List
                            disablePadding
                            subheader={<ListSubheader disableGutters disableSticky>
                                <Box display="flex">
                                    <Box>Notification</Box>
                                    {channels
                                        .map((c, idx) => <Box
                                            key={c}
                                            ml={idx > 0 ? 2 : "auto"}
                                            width={63}>
                                            {ChannelTitle[c]}
                                        </Box>)}
                                </Box>
                            </ListSubheader>}
                            className={classes.root}>
                            <ListItem
                                divider
                                className={classes.lineItem}
                                disableGutters>
                                <ListItemText
                                    primary={"All"}
                                    primaryTypographyProps={{ className: classes.breakWords }}
                                    secondary={"Apply to all settings"}
                                    secondaryTypographyProps={{ className: classes.breakWords }}
                                />
                                <ListItemSecondaryAction className={classes.lineItemSecondaryAction}>
                                    {channels
                                        .map((channel, idx) => (
                                            <SwitchInput
                                                key={channel}
                                                switchProps={{
                                                    edge: 'end',
                                                    disabled: isSaving === channel,
                                                }}
                                                formControlProps={{
                                                    className: clx(classes.lineItemSwitcher, { [classes.lineItemSwitcherLast]: idx === channels.length - 1 })
                                                }}
                                                input={{
                                                    value: channelGloballyEnabled(user, channel),
                                                    onChange: () => {
                                                        setIsSaving(channel);
                                                        updateChannel(channel, !channelGloballyEnabled(user, channel))
                                                            .finally(() => setIsSaving(false))
                                                    }
                                                }} />
                                        ))}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {notificationTypes
                                .filter(type => Object.values(NotificationTypes).includes(type.class))
                                .map(type => <ListItem
                                    key={type.class}
                                    className={classes.lineItem}
                                    disableGutters>
                                    <ListItemText
                                        primary={NotificationTypeDetails[type.class].title}
                                        primaryTypographyProps={{ className: classes.breakWords }}
                                        secondary={NotificationTypeDetails[type.class].description}
                                        secondaryTypographyProps={{ className: classes.breakWords }}
                                    />
                                    <ListItemSecondaryAction className={classes.lineItemSecondaryAction}>
                                        {channels
                                            .filter(c => type.supported_channels.includes(c))
                                            .map((channel, idx) => (
                                                <PreferenceSwitcher
                                                    key={channel}
                                                    {...{ channels, channel, type, idx, ...props }} />
                                            ))}
                                    </ListItemSecondaryAction>
                                </ListItem>)}
                        </List>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </AccordionScrollable>
    );
}

function PreferenceSwitcher(props) {
    const { user, channel, type, idx, channels, updateChannelForType } = props;
    const classes = useStyles();
    const [saving, setSaving] = useState(false);

    const enabled = user.options.notification_classes[type.class]
        && user.options.notification_classes[type.class].includes(channel);

    return <Tooltip
        title={!channelGloballyEnabled(user, channel)
            ? `Enable ${ChannelTitle[channel]} globally first`
            : ``}>
        <span>
            <SwitchInput
                switchProps={{
                    edge: 'end',
                    disabled: saving || !channelGloballyEnabled(user, channel)
                }}
                formControlProps={{
                    className: clx(classes.lineItemSwitcher, { [classes.lineItemSwitcherLast]: idx === channels.length - 1 })
                }}
                input={{
                    value: enabled,
                    onChange: () => {
                        setSaving(true);
                        updateChannelForType(type.class, channel, !enabled)
                            .finally(() => setSaving(false))
                    }
                }} />
        </span>
    </Tooltip>
}

const mapStateToProps = state => {
    return {
        user: getUser(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChannels: () => dispatch(getChannels()),
        getNotificationTypes: () => dispatch(getNotificationTypes()),

        updateChannel: (channel, enabled) => dispatch(updateChannel(channel, enabled))
            .then(() => dispatch(showSuccess("Changes saved")))
            .catch(() => dispatch(showError("Error during saving"))),
        updateChannelForType: (type, channel, enabled) => dispatch(updateChannelForType(type, channel, enabled))
            .then(() => dispatch(showSuccess("Changes saved")))
            .catch(() => dispatch(showError("Error during saving")))
    };
}

export default
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(NotificationsSettings)
    );
