import React from 'react';
import { connect } from 'react-redux';
import { Box, makeStyles, AppBar, Toolbar, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExportIcon from '@material-ui/icons/ImportExport';
import CloseIcon from '@material-ui/icons/Close';
import LCTypography from '../../components/material/LCTypography';
import { unselectAll } from '../../actions/inventory';
import { selectedCount } from '../../selectors/inventory';
import { useState } from 'react';
import InventoryExportDialog from './InventoryExportDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        appBar: {
            zIndex: theme.zIndex.drawer + 2,

            paddingTop: `env(safe-area-inset-top)`,
            paddingLeft: `env(safe-area-inset-left)`,
            paddingRight: `env(safe-area-inset-right)`
        },
        backButton: {
            marginRight: theme.spacing(2)
        },
        grow: {
            flexGrow: 1,
        },
    }
})

function InventoryItemSkeleton(props) {
    const { selectedCount, handleClose, open } = props;
    const classes = useStyles();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [openExportDialog, setOpenExportDialog] = useState(false);
    const { t } = useTranslation();

    if (!open) {
        return null;
    }

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label={t('Back')}
                        edge="start"
                        onClick={handleClose}
                        className={classes.backButton}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Box>
                        <LCTypography
                            transProps={{ selectedCount: selectedCount }}
                            variant="h6">{{ selectedCount: selectedCount }} item(s) selected</LCTypography>
                    </Box>
                    <div className={classes.grow} />
                    <IconButton
                        color="inherit"
                        edge="end"
                        aria-label={t('Menu')}
                        onClick={event => setMenuAnchorEl(event.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Menu
                id="export-options"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
            >
                <MenuItem onClick={() => {
                    setOpenExportDialog(true);
                    setMenuAnchorEl(null);
                }}>
                    <ListItemIcon>
                        <ExportIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Promote')} />
                </MenuItem>
                <MenuItem onClick={() => setMenuAnchorEl(null)}>
                    <ListItemIcon>
                        <CloseIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Close')} />
                </MenuItem>
            </Menu>

            <InventoryExportDialog open={openExportDialog} handleClose={() => setOpenExportDialog(false)} />
        </>
    );
}

const mapStateToProps = state => {
    return {
        selectedCount: selectedCount(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleClose: () => dispatch(unselectAll())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemSkeleton
);
