import { apiHttp } from "../helpers/api";

export default class LucitApiAnalytics {
    getOverview({ accountIds }) {
        return apiHttp.get(`/analytics/fetch/plays/overview`, {
            params: {
                account_id: accountIds
                    ? JSON.stringify(accountIds)
                    : null
            }
        })
            .then(response => response.data.analytics)
    }

    getOverviewForOperator({ agency_id }) {
        return apiHttp.get(`/analytics/fetch/plays/overview`, {
            params: {
                agency_id: agency_id
            }
        })
            .then(response => response.data.analytics)
    }

    getOverviewForExport({ inventory_export_id, timeframe, timeframe_opts }) {
        return apiHttp.get(`/analytics/fetch/plays/overview`, {
            params: {
                inventory_export_id: inventory_export_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts),
            }
        })
            .then(response => response.data.analytics)
    }

    getDatePeriods() {
        return apiHttp.get(`/analytics/filters/date-periods-all`)
            .then(response => response.data.date_periods);
    }
    getDateTimeframes() {
        return apiHttp.get(`/analytics/filters/date-timeframes`)
            .then(response => response.data.date_timeframes);
    }

    getPlaysByPeriod(filters = {}, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-period', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                ...filters,
                agency_id: filters.agency_id,
                account_id: filters.account_id
                    ? JSON.stringify(filters.account_id)
                    : undefined,
                timeframe_opts: filters.timeframe_opts
                    ? JSON.stringify(filters.timeframe_opts)
                    : undefined,
                use_analytics_reporter: 1
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByAd(account_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-ad', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                account_id: JSON.stringify(account_id),
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByAdForOperator(agency_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-ad', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                agency_id: agency_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByItem(account_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-inventory-item', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                account_id: JSON.stringify(account_id),
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByItemForOperator(agency_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-inventory-item', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                agency_id: agency_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByCampaign(account_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-campaign', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                account_id: JSON.stringify(account_id),
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysForCampaign(export_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-campaign', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                inventory_export_id: export_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByCampaignForOperator(agency_id, timeframe, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-campaign', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                agency_id: agency_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts)
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByBillboard(account_id, timeframe, data, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-digital-board', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                account_id: JSON.stringify(account_id),
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts),
                ...data
            }
        })
            .then(response => response.data.analytics);
    }

    getPlaysByBillboardForOperator(agency_id, timeframe, data, timeframe_opts, options = {}) {
        return apiHttp.get('/analytics/fetch/plays/by-digital-board', {
            _cancelOnPageChange: true,
            ...options,
            params: {
                agency_id: agency_id,
                timeframe,
                timeframe_opts: JSON.stringify(timeframe_opts),
                ...data
            }
        })
            .then(response => response.data.analytics);
    }

    getSummaryLightningLastHeartbeat(lightningDeviceIds) {
        return apiHttp.post('/analytics/fetch/summary-lightning-last-heartbeat', {
            lightning_device_ids: lightningDeviceIds
        })
            .then(response => response.data.analytics);
    }

    getSummaryDigitalBoardLastPlay(digitalBoardIds) {
        return apiHttp.post('/analytics/fetch/summary-digital-board-last-play', {
            digital_board_ids: digitalBoardIds,
            'with_inventory_item': true,
            'with_inventory_photo': true
        })
            .then(response => response.data.analytics);
    }

    trackProofView(isLoggedIn, proofId) {

        const url = isLoggedIn ? `/analytics/track/${proofId}/proof-view` : `/public/analytics/track/${proofId}/proof-view`;

        return apiHttp.post(url)
            .then(response => response.data)
    }

}
