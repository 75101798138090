import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG } from '../../helpers/actionTypes';
import { PushNotifications } from '@capacitor/push-notifications';
import { register as registerPushNotifications } from '../../notificationsWorker';
import { GenericDialog } from './GenericDialog';
import { Button } from '@material-ui/core';

export const PushNotificationsInfoDialog = () => {
    const pushNotificationsInfoOpen = useSelector(state => state.layout.pushNotificationsInfoOpen);
    const dispatch = useDispatch();

    const register = () => PushNotifications.requestPermissions()
        .then(x => {
            if (x.receive == 'granted') {
                return registerPushNotifications();
            }
        });

    return (
        <GenericDialog
            title="Push Notifications Permissions"
            dialogProps={{
                open: pushNotificationsInfoOpen,
                onClose: () => {
                    dispatch({
                        type: LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG,
                        pushNotificationsInfoOpen: false
                    });
                    register();
                },
                fullWidth: true,
                maxWidth: 'xs'
            }}
            ContentComponent={<>
                Lucit uses push notifications to inform you for important events about your ads.

                You will be prompted to grant permissions for sending push notifications after closing this message.
            </>}

            ActionsComponent={<>
                <Button onClick={() => {
                    dispatch({
                        type: LAYOUT_TOOGLE_PUSH_NOTIFICATIONS_INFO_DIALOG,
                        pushNotificationsInfoOpen: false
                    });
                    register();
                }}
                    variant="contained"
                    color="primary">
                    OK
                </Button>
            </>}
        />
    )
}
