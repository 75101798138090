import React from 'react';
import { withHomeLayout } from '../layouts/HomeLayout';
import ButtonScrollToTopFab from '../components/material/ButtonScrollToTopFab';
import DashboardsNew from '../containers/dashboard/DashboardsNew';
import { ProfileType } from '../helpers/constants';
import { PageTitle } from '../components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectedProfile } from '../selectors/user';
import DashboardOperatorUser from '../containers/dashboard/DashboardOperatorUser';
import DashboardMy from '../containers/dashboard/DashboardMy';
import { useTranslation } from 'react-i18next';

const Home = ({ profile }) => {

  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t("Home")} />

      {profile.type === ProfileType.account ? <DashboardsNew /> : null}

      {profile.type === ProfileType.agency ? <DashboardOperatorUser /> : null}

      {profile.type === ProfileType.user ? <DashboardMy /> : null}

      <ButtonScrollToTopFab />
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    profile: selectedProfile(state)
  }
}

const mapDispatchToProps = () => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withHomeLayout(
      Home
    )
  )
);
