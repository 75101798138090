/*  eslint-disable no-undef */

import {
    BILLING_INVOICES_RESULT, BILLING_PLANS_RESULT, PAYMENT_METHODS_ADD_CARD, PAYMENT_METHODS_CARDS_REQUEST,
    PAYMENT_METHODS_CARDS_RESULT, PAYMENT_METHODS_REMOVE_CARD, PAYMENT_METHODS_SET_PRIMARY_CARD
} from "../helpers/actionTypes"
import { LuCoreModelClasses } from "../helpers/constants";
import { parseClass } from "../helpers/lcuid";
import { lucitApi } from "../services/lucitApi";
import i18next from "i18next";

export function getBillingPlans(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);
        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.getBillingPlans(lcuid)
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.getBillingPlans(lcuid)
                break;
        }

        return promise
            .then(data => dispatch({
                type: BILLING_PLANS_RESULT,
                lcuid,
                objectClass,
                ...data
            }))
    }
}

export function getInvoices(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);
        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.getInvoices(lcuid)
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.getInvoices(lcuid)
                break;
        }

        return promise
            .then(data => dispatch({
                type: BILLING_INVOICES_RESULT,
                lcuid,
                objectClass,
                ...data
            }))
    }
}

export function getCards(lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        dispatch({
            type: PAYMENT_METHODS_CARDS_REQUEST,
            lcuid,
            objectClass
        })

        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.users:
                promise = lucitApi.users.getCards(lcuid)
                break;
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.getCards(lcuid)
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.getCards(lcuid)
                break;
            default:
                throw new Error(i18next.t('Unknown objectClass value {{name}}', { name: objectClass }));
        }

        return promise
            .then(data => dispatch({
                type: PAYMENT_METHODS_CARDS_RESULT,
                lcuid,
                objectClass,
                ...data
            }))
    }
}

export function saveCard(token, lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.users:
                promise = lucitApi.users.saveCard(lcuid, token);
                break;
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.saveCard(lcuid, token);
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.saveCard(lcuid, token);
                break;
            default:
                throw new Error(i18next.t('Unknown objectClass value {{name}}', { name: objectClass.class }));
        }

        return promise
            .then(card => {
                dispatch({
                    type: PAYMENT_METHODS_ADD_CARD,
                    lcuid,
                    objectClass,
                    card
                });

                return card;
            })
    }
}

export function deleteCard(card, lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.users:
                promise = lucitApi.users.deleteCard(lcuid, card.id);
                break;
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.deleteCard(lcuid, card.id);
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.deleteCard(lcuid, card.id);
                break;
            default:
                throw new Error(i18next.t("Unknown LuCoreModelClass value {{name}}", { name: objectClass.class }));
        }

        return promise
            .then(() => dispatch({
                type: PAYMENT_METHODS_REMOVE_CARD,
                lcuid,
                objectClass,
                id: card.id,
                default: card.default
            }))
    }
}

export function savePrimaryCard(id, lcuid) {
    return (dispatch) => {
        const objectClass = parseClass(lcuid);

        let promise = Promise.resolve();

        switch (objectClass) {
            case LuCoreModelClasses.users:
                promise = lucitApi.users.savePrimary(lcuid, id);
                break;
            case LuCoreModelClasses.accounts:
                promise = lucitApi.accounts.savePrimary(lcuid, id);
                break;
            case LuCoreModelClasses.agencies:
                promise = lucitApi.agencies.savePrimary(lcuid, id);
                break;
            default:
                throw new Error(i18next.t('Unknown LuCoreModelClass value {{name}}', { name: objectClass.class }));
        }

        return promise
            .then(() => dispatch({
                type: PAYMENT_METHODS_SET_PRIMARY_CARD,
                lcuid,
                objectClass,
                id
            }))
    }
}
