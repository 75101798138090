import React, { useState } from 'react';
import clx from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { StreetView } from '../../components';
import { connect } from 'react-redux';
import { LucitXrImageSize, StreetViewStatus, StreetViewStatusDetails } from '../../helpers/constants';
import { IonImg } from '@ionic/react';
import { getCityScapeIcon } from '../../selectors/billboard';
import AddStreetViewButton from './AddStreetViewButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        root: {
            position: 'relative',
            height: LucitXrImageSize.height,
            width: LucitXrImageSize.width,
        },
        rootNoInteraction: {
            '&:hover div': {
                display: 'flex'
            },
            [theme.breakpoints.down('sm')]: {
                '& div': {
                    display: 'flex'
                },
            }
        },
        rootPlaceholder: {
            height: 'unset',
            '& div': {
                display: 'flex'
            }
        },
        rootCanEdit: {
            cursor: 'pointer',
        },
        overlay: {
            zIndex: 1,
            display: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        overlayBackground: {
            width: '100%',
            height: '100%',
            background: 'white',
            opacity: 0.7
        },
        overlayContent: {
            width: '60%',
            borderRadius: 8,
            padding: 16,
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        }
    }
})

function BillboardStreetView({ board, location, interact, editEnabled, classes: customClasses = {} }) {
    const classes = useStyles();
    const streetView = board.street_view_location;
    const { t } = useTranslation();

    const hasStreetView = streetView && board.street_view_status === StreetViewStatus.active;
    const canEdit = editEnabled;

    const [dialogOpen, setDialogOpen] = useState(false);

    return <Box className={clx(classes.root, {
        [classes.rootPlaceholder]: !hasStreetView,
        [classes.rootCanEdit]: canEdit,
        [classes.rootNoInteraction]: !interact,
        [customClasses.root]: Boolean(customClasses.root),
        [customClasses.rootPlaceholder]: Boolean(customClasses.rootPlaceholder) && !hasStreetView
    })}>
        {hasStreetView
            ? <StreetView
                lat={+streetView.latitude}
                lng={+streetView.longitude}
                pov={{ heading: +streetView.heading, pitch: +streetView.pitch, }}
                zoom={+streetView.zoom}
            />
            : <IonImg src={getCityScapeIcon(board.digital_board_format.code)} />}

        <Box className={clx(classes.overlay, classes.overlayBackground)}
            onClick={() => setDialogOpen(true)} />

        {canEdit
            ? <Box className={clx(classes.overlay, classes.overlayContent)}>
                {board.street_view_status !== StreetViewStatus.active
                    && Boolean(StreetViewStatusDetails[board.street_view_status])
                    && <Typography style={{ marginBottom: 8 }}>{StreetViewStatusDetails[board.street_view_status].description}</Typography>}

                <AddStreetViewButton board={board} location={location} open={dialogOpen} onClose={() => setDialogOpen(false)} />
            </Box>
            : <Box className={clx(classes.overlay, classes.overlayContent)}>
                <Typography variant="h6">{t('Billboard Street View')}</Typography>
                {board.street_view_status !== StreetViewStatus.active
                    && Boolean(StreetViewStatusDetails[board.street_view_status])
                    && <Typography>{StreetViewStatusDetails[board.street_view_status].description}</Typography>}
            </Box>}
    </Box>;
}

const mapStateToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps
)(
    BillboardStreetView
)
