import { toCommaString } from "../helpers/string";
import {
    ItemCondition, InventoryItemClasses, InventoryItemStatus,
    InventoryPhotoStatus, InventoryPhotoSource, InventoryItemPlaceholder, ImageStyle, Global
} from "../helpers/constants";
import moment from "moment";
import { sortDate, sortNumber } from "../helpers/sorters";
import { groupBy } from "../helpers/array";

export const filters = state => state.inventory.filters;
export const itemByIdSelector = state => id => {
    const item = state.inventory.data.find(x => x.id === id);

    return item
        ? { photos: [], options: {}, ...item }
        : null
};
export const isDeleted = state => id => {
    return Boolean(state.inventory.deletedIds.find(x => x === id))
};

export const hasNextPage = state => state.inventory.currentPage < state.inventory.lastPage;
export const nextPage = state => state.inventory.currentPage + 1;
export const nextPageLoading = state => state.inventory.loadingNextPage;
export const itemsSelected = state => state.inventory.data.filter(x => x.selected);
export const selectedCount = state => itemsSelected(state).length;
export const isAllSelected = state => state.inventory.data.every(x => x.selected);
export const isSomethingSelected = state => state.inventory.data.some(x => x.selected);
export const searched = state => state.inventory.searched;
export const inventoryItems = state => state.inventory.data;
export const inventoryTotal = state => state.inventory.total;
export const inventoryScrollY = state => state.inventory.scrollY;
export const hasScrollY = state => !!state.inventory.scrollY;
export const isInvalidated = state => state.inventory.invalidated;
export const hasAllowedExports = state => state.inventory.exportsAvailable.length > 0

export const hasPhoto = item => item.photo && (item.photo.public_url || !!item.photo.length)
export const getBestCreativePhoto = item => {

    if (!item)
        return ""

    if (item.options?.best_creative_image_photo_url)
        return item.options.best_creative_image_photo_url

    if (item.best_creative && item.best_creative.public_url)
        return item.best_creative.public_url

    if (item.photo && item.photo.public_url)
        return item.photo.public_url

    return ""

}
export const canRemovePhoto = itemPhoto => isPhotoUpload(itemPhoto)
// Do we really want to allow deleting Creatives? this could lead to situation, when InventoryItem has no creatives to be shown
// || isPhotoCreative(itemPhoto);

export const isPhotoCreative = itemPhoto => getItemPhotoSource(itemPhoto) === InventoryPhotoSource.creatives.code
export const isPhotoUpload = itemPhoto => getItemPhotoSource(itemPhoto) === InventoryPhotoSource.upload.code

export const photoRatio = itemPhoto => {
    const ratio = itemPhoto.options.dimension_width / itemPhoto.options.dimension_height;

    return isNaN(ratio)
        ? 0
        : ratio;
};

// all photo sources that are not in InventoryPhotoSource constant are considered as   InventoryPhotoSource.creatives
export const getItemPhotoSource =
    itemPhoto => Object.values(InventoryPhotoSource)
        .map(x => x.code)
        .includes(itemPhoto.photo_source)
        ? itemPhoto.photo_source
        : InventoryPhotoSource.creatives.code;

export const getItemPhotoSources = item => {
    return (item.photos || [])
        .map(getItemPhotoSource)
        .map(x => InventoryPhotoSource[x])
        .sort((a, b) => sortNumber(a.order, b.order))
        .filter((x, i, arr) => arr.indexOf(x) === i);
}

export const hasCreatives = item => {
    return getItemPhotoSources(item)
        .some(source => source === InventoryPhotoSource.creatives.code);
}

export const getItemPhotos = (item, photoSource) => {
    return item.photos && item.photos.length
        ? item.photos
            .filter(p => p.public_url && p.status === InventoryPhotoStatus.active)
            .filter(x => !photoSource || (photoSource === getItemPhotoSource(x)))
        : [{
            public_url: InventoryItemPlaceholder[item.item_class] || InventoryItemPlaceholder[InventoryItemClasses.generic.class],
            title: 'placeholder photo'
        }];
}

export const canViewStreetViewForItem = (inventoryItem, proofViewCount) => {
    return inventoryItem && proofViewCount < Global.maxProofViewsToShowStreetView
}

export const hasLucitXRImages = item => Boolean(
    item &&
    item.creatives_to_board_images &&
    item.creatives_to_board_images.filter(cbi => cbi.digital_board_to_image.style === ImageStyle.normal).length
)

export const hasLucitXRStreetViewImages = item => Boolean(
    item &&
    item.creatives_to_board_images &&
    item.creatives_to_board_images.filter(isLucitXRStreetView).length
)

export const isLucitXRStreetView = creative => creative.digital_board_to_image.style === ImageStyle.streetViewOverlay
export const isLucitXRPhoto = creative => creative.digital_board_to_image.style === ImageStyle.normal

export const boardImagesHaveAnyPhotos = creatives_to_board_images =>
    creatives_to_board_images
    && creatives_to_board_images.filter(isLucitXRPhoto).length

export const getFirstPhotoForBoard = (board, inventoryItem) => {
    return getItemPhotos(inventoryItem)
        .find(p => p.options
            && p.options.dimension_height == board.height
            && p.options.dimension_width == board.width)
}
export const getFirstBoardImageForPhoto = (item, photo) => {

    if (!hasLucitXRImages(item))
        return null

    const firstImage = item.creatives_to_board_images.filter((x) => x.inventory_photo_id === photo.id);

    return (firstImage && firstImage.length) ? firstImage[0] : null
}

export const getItemBoardIds = (item, exportId) => {
    const options = itemOptions(item);

    if (options.exports && options.exports[exportId] && options.exports[exportId].board_ids) {
        return options.exports[exportId].board_ids;
    }

    return [];
}

export const getItemRunSchedule = (item, exportId) => {
    const options = itemOptions(item);

    if (options.exports && options.exports[exportId] && options.exports[exportId].run_schedule) {

        return options.exports[exportId].run_schedule
            .map((x, idx) => ({
                id: idx,
                type: x.type,
                //When x.type==="date_range"
                startDate: x.start_datetime,
                endDate: x.end_datetime,

                //When x.type==="time_range"
                startTime: x.start_time,
                endTime: x.end_time,

                //When x.type==="days_of_the_week"
                days: x.days,

                //When x.type==="item_filter"
                filter_fields: x.type === "item_filter" ? x.filter_fields : null,
                filter_functions: x.type === "item_filter" ? x.filter_functions : null
            }))
            .sort((a, b) => sortDate(a.startDate ?? a.startTime, b.startDate ?? b.startTime));
    }

    return [];
}

export const getItemRunScheduleItemFilter = (item, exportId) => {

    const options = itemOptions(item);

    if (options.exports && options.exports[exportId] && options.exports[exportId].run_schedule) {

        return options.exports[exportId].run_schedule
            .filter(x => x.type === "item_filter")
    }

    return [];

}

export const getItemDigitalBoardFilter = (item, exportId) => {

    const options = itemOptions(item);

    if (options.exports && options.exports[exportId] && options.exports[exportId].digital_board_filter) {

        return options.exports[exportId].digital_board_filter
    }

    return [];

}

export const hasItemFilterOrBoardFilter = (item, exportId) => {
    return getItemRunScheduleItemFilter(item, exportId).length > 0 ||
        getItemDigitalBoardFilter(item, exportId).filter_fields?.length > 0 ||
        getItemDigitalBoardFilter(item, exportId).filter_functions?.length > 0
}

export const condition = item => item.new_used === 1
    ? ItemCondition.new
    : ItemCondition.used;
export const selected = item => item.selected === true;
export const hasPrice = item => +item.price > 0;
export const hasMileage = item => item.inventory_attributes && !!item.inventory_attributes.mileage;
export const mileage = item => toCommaString(item.inventory_attributes.mileage);
export const mileageUnits = item => item.inventory_attributes.mileage_units && item.inventory_attributes.mileage_units.toLowerCase();

//NOTE:  This function should only be used on ANALYTICS object's - in all other cases, this will not work
export const isItemSold = item => item.object_status === InventoryItemStatus.sold;
export const showSoldBannerOnItem = item => item.object_inventory_item_say_sold;

export const itemOptions = (item = {}) => Object.assign({
    exports: {}
}, item.options);

export const exportIconUrl = exportItem => exportItem.export.options && exportItem.export.options.primary_image_public_url;
export const isExportActive = exportItem => exportItem.run_stats && exportItem.run_stats.stats && exportItem.run_stats.stats.exported;
export const lastRun = exportItem => exportItem.run_stats && moment(exportItem.run_stats.last_run_completed)

export const inventoryItemClass = item => {
    if (!item) {
        return InventoryItemClasses.generic;
    }

    return Object.values(InventoryItemClasses).find(value => value.class === item.item_class)
        || InventoryItemClasses.generic;
}

export const inventoryItemIsPhotoStream = item => {
    return inventoryItemClass(item) === InventoryItemClasses.photoStream
}

export const getInventoryItemClassListTitles = itemClasses => {
    const titles = itemClasses.map((itemClass) => {
        const className = itemClass.split('\\').pop();
        const item = Object.values(InventoryItemClasses).find(
            (inventoryItem) => inventoryItem.class === itemClass
        );
        return item ? item.title : `${className} (Title not found)`;
    });

    return titles.join(',');
}

export const getInventoryItemClassListFromClassesArray = itemClasses => {

    const inventoryItemClasses = Object.values(InventoryItemClasses).filter(
        (inventoryItem) => itemClasses.includes(inventoryItem.class)
    );

    return inventoryItemClasses
}

export const approvalAgencyGroups = approvals => {
    // This grouping gives us following structure (key - agencyId)
    // {
    //     "12": [{ ... }, { ... }],
    //     "13": [{ ... }],
    //     "14": [{ ... }],
    // }
    const approvalsMap = groupBy(approvals || [], approval => approval.agency_id);

    // This gives us this structure:
    // [
    //    {
    //       "agency": { ... },
    //       "approvals": [ ... ]
    //    }
    // ]
    return Object.keys(approvalsMap)
        .reduce((result, key) => {
            const approvals = approvalsMap[key];
            const agency = approvals[0].agency;

            return [
                ...result,
                {
                    agency,
                    approvals
                }
            ]
        }, []);
}
