import { createSelector } from "reselect";
import { toDictionary } from "../helpers/array";
import { InventoryExportCampaignClasses } from "../helpers/constants";
import { sortDate } from "../helpers/sorters";

export const selectDigitalBoards = createSelector([state => state.lightning.digitalBoards, state => state.lightning.devices],
    (digitalBoards, devices) => {
        const devicesDict = toDictionary(devices, x => x.id);

        return digitalBoards
            .filter(d => d.lightning_device_id != null)
            .map(d => ({
                ...d,
                lightning_device: devicesDict[d.lightning_device_id]
            }))
    }
)

export const selectAgencyExports = createSelector([state => state.lightning.agencyExports],
    exports => {
        const dict = toDictionary(exports, x => x.lcuid);

        return exports.map(e => {
            if (e.child_exports) {
                e.child_exports = e.child_exports.map(x => dict[x.lcuid])
                    .filter(x => x != null);
            }
            if (e.parent_exports) {
                e.parent_exports = e.parent_exports.map(x => dict[x.lcuid])
                    .filter(x => x != null);
            }
            return e;
        })
    }
)

export const getBoardConnectedExportForBoard = createSelector([selectAgencyExports],
    exports => digitalBoard => {
        return exports.find(x => x.lcuid === digitalBoard.options?.connected_export_lcuid);
    }
)

export const selectChildExports = createSelector([state => state.lightning],
    lightning => item => {
        const dict = toDictionary(lightning.agencyExports, x => x.lcuid);

        return item?.child_exports?.map(e => dict[e.lcuid]).filter(x => x != null) ?? []
    }
)

export const selectScreenGroupScreens = createSelector([state => state.lightning],
    (lightning) => group => {
        const dict = toDictionary(lightning.digitalBoards, x => x.lcuid, x => x);

        return group.parent_exports
            .map(e => dict[e.options.connected_digital_board_lcuid])
            .filter(x => x != null)
    })

export const selectScreenGroupCampaigns = createSelector([selectAgencyExports],
    (exports) => group => {
        const campaigns = exports.filter(e =>
            e.campaign_class === InventoryExportCampaignClasses.operatorContract.class ||
            e.campaign_class === InventoryExportCampaignClasses.lucitProgrammatic.class ||
            e.campaign_class === InventoryExportCampaignClasses.group.class
        );

        const dict = toDictionary(campaigns, x => x.lcuid, x => x);

        return group.child_exports.map(e => dict[e.lcuid])
            .filter(x => Boolean(x));
    })

export const getVenueIdFromLastAddedDigitalBoard = (state) => {
    const device = state.lightning.devices
        .sort((a, b) => sortDate(b.created_at, a.created_at))
        .find(() => true);

    return parseInt(device?.venue_taxonomy_id);
}
