import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { loadTemplate, loadTemplateAssets, loadAccountKeyValueStore, loadAllBoardFormats, loadElements } from '../../actions/designer';
import { designerReady, getKeysUsedInMacros, getUsedMacros } from '../../selectors/designer';
import { selectedProfileIsAccount, selectedProfile } from '../../selectors/user';
import { PageTitle } from '../../components';
import DesignerHome from '../../containers/designer/DesignerHome';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { LAYOUT_TOOGLE_LEFT_MENU_DESKTOP } from '../../helpers/actionTypes';
import { getAccountScreens } from '../../actions/account';
import { Skeleton } from '@material-ui/lab';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';

const Designer = ({
    match, loadTemplate, loadTemplateAssets, loadAccountKeyValueStore, designerReady, loadAllBoardFormats,
    selectedProfileIsAccount, selectedProfile, usedKeyValueMacroKeys, usedMacros, getAccountScreens, loadElements
}) => {
    const [driveTemplateId, setDriveTemplateId] = useState(null)
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const leftMenuDesktopOpen = useSelector(state => state.layout.leftMenuDesktopOpen);
    const [stringifiedUsedKeyValueMacroKeys, setStringifiedUsedKeyValueMacroKeys] = useState('')

    useEffect(() => {
        setDriveTemplateId(match.params.lcuid)
    }, [match.params.lcuid])

    useEffect(() => {
        if (driveTemplateId) {
            loadTemplate(driveTemplateId)
        }
    }, [driveTemplateId])

    useEffect(() => {

        const loadElementsOptions = {
            per_page: 10000
        }

        if (selectedProfileIsAccount && selectedProfile) {
            loadElementsOptions.account_id = selectedProfile.id
        }
        loadElements(loadElementsOptions)
        loadTemplateAssets()
        loadAllBoardFormats()
        getAccountScreens(selectedProfile)
    }, [])

    useEffect(() => {
        if (usedKeyValueMacroKeys.length > 0) {
            setStringifiedUsedKeyValueMacroKeys(JSON.stringify(usedKeyValueMacroKeys))
        }
    }, [usedKeyValueMacroKeys])

    useEffect(() => {

        if (selectedProfileIsAccount && selectedProfile && usedMacros.length > 0 && stringifiedUsedKeyValueMacroKeys) {
            loadAccountKeyValueStore(selectedProfile.id, stringifiedUsedKeyValueMacroKeys)
        }

    }, [selectedProfileIsAccount, selectedProfile, usedMacros.length, stringifiedUsedKeyValueMacroKeys])

    useEffect(() => {
        dispatch({ type: LAYOUT_TOOGLE_LEFT_MENU_DESKTOP, leftMenuDesktopOpen: false });
        return () => dispatch({ type: LAYOUT_TOOGLE_LEFT_MENU_DESKTOP, leftMenuDesktopOpen });
    }, [])

    return (
        <Box>
            <PageTitle title={t('Template Designer')} />
            <PolicyFeatureToggle policy={AccountPolicy.editDriveTemplates}>
                {designerReady
                    ? <DesignerHome />
                    :
                    <Box p={3}>
                        <Skeleton animation="wave" height={70} width={'100%'} />
                        <Skeleton animation="wave" height={70} width={'100%'} />
                        <Skeleton animation="wave" height={800} width={'100%'} />
                    </Box>

                }
            </PolicyFeatureToggle>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        designerReady: designerReady(state),
        selectedProfileIsAccount: selectedProfileIsAccount(state),
        selectedProfile: selectedProfile(state),
        usedKeyValueMacroKeys: getKeysUsedInMacros(state),
        usedMacros: getUsedMacros(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadTemplate: (lcuid) => dispatch(loadTemplate(lcuid)),
        loadTemplateAssets: () => dispatch(loadTemplateAssets()),
        loadAllBoardFormats: () => dispatch(loadAllBoardFormats()),
        loadAccountKeyValueStore: (accountId, keys) => dispatch(loadAccountKeyValueStore(accountId, keys)),
        getAccountScreens: (profile) => dispatch(getAccountScreens(profile)),
        loadElements: (params) => dispatch(loadElements(params))
    };
}

const DesignerWithoutLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Designer);

export { DesignerWithoutLayout }
export default withHomeLayout(DesignerWithoutLayout);
