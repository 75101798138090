import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lucitApi } from '../../services/lucitApi';
import { getDigitalBoardFilters } from '../../selectors/programmatic';
import { CampaignImpressionStats } from '../../containers/campaigns/CampaignImpressionStats';
import { PROGRAMMATIC_STATS_LOADED, PROGRAMMATIC_STATS_LOADING } from '../../helpers/actionTypes';

const ImpressionsStats = () => {
    const dispatch = useDispatch();

    const loading = useSelector(state => state.programmatic.statsLoading)
    const stats = useSelector(state => state.programmatic.stats)
    const digitalBoardFilters = useSelector(getDigitalBoardFilters);
    const budgetType = useSelector(state => state.programmatic.budget.budget_type);
    const budget = useSelector(state => state.programmatic.budget.amount);
    const dates = useSelector(state => state.programmatic.dates);

    useEffect(() => {
        const controller = new AbortController();
        const filters = {
            digital_board_filters: digitalBoardFilters,
            budget_type: budgetType,
            budget_amount_dollars: budget,
            start_date: dates.startDate,
            end_date: dates.endDate,
        };

        dispatch({ type: PROGRAMMATIC_STATS_LOADING, loading: true })
        lucitApi.campaigns
            .getEstimateStats(filters, { singal: controller.signal })
            .then(stats => dispatch({ type: PROGRAMMATIC_STATS_LOADED, stats }))
            .finally(() => dispatch({ type: PROGRAMMATIC_STATS_LOADING, loading: false }));
        return () => controller.abort();
    }, [digitalBoardFilters, budget, budgetType, dates]);

    return <CampaignImpressionStats
        loading={loading}
        stats={stats}
        budgetType={budgetType}
        dates={dates}
    />
};

export default ImpressionsStats;
