import moment from "moment";
import { FeedStatus } from "../helpers/constants";
import { toLocalDateTime } from "../helpers/date";
import { getInventoryItemClassListFromClassesArray } from "./inventory";

export const filters = state => state.feeds.filters;
export const hasNextPage = state => state.feeds.currentPage < state.feeds.lastPage;
export const nextPage = state => state.feeds.currentPage + 1;
export const nextPageLoading = state => state.feeds.loadingNextPage;
export const searched = state => state.feeds.searched;
export const feeds = state => state.feeds.data;
export const feedsTotal = state => state.feeds.total;
export const feedsScrollY = state => state.feeds.scrollY;
export const hasScrollY = state => !!state.feeds.scrollY;
export const isInvalidated = state => state.feeds.invalidated;
export const feedByIdSelector = state => id => state.feeds.data.find(x => x.id === id);

export const hasIcon = feed => feed.inventoryFeedProvider
    && feed.inventoryFeedProvider.options
    && feed.inventoryFeedProvider.options.primary_image_public_url;

export const lastRunAt = feed => toLocalDateTime(feed.last_run_at);

export const nextRunLocal = feed => toLocalDateTime(feed.next_run_date)
export const nextRunMilliseconds = feed => feed.next_run_date
    ? moment.duration(nextRunLocal(feed).diff(moment())).asMilliseconds()
    : null;

export const canRunFeed = feed => feed.status === FeedStatus.setup
    || feed.status === FeedStatus.ready
    || feed.status === FeedStatus.error

export const isFeedRunningOrWillBe = feed => feed.status === FeedStatus.running
    || feed.status === FeedStatus.runnable
    || feed.status === FeedStatus.retry
    || feed.status === FeedStatus.queued;

export const providesDriveTemplates = feed => feed.drive_templates
    && feed.drive_templates.default_template

export const providesFeedSettings = feed => feed.inventoryFeedProvider
    && feed.inventoryFeedProvider.feed_params
    && feed.inventoryFeedProvider.feed_params.fields
    && feed.inventoryFeedProvider.feed_params.fields.length > 0

export const canHaveTemplatesAssigned = feed => {
    if (!feed.inventoryFeedProvider)
        return false;

    if (!feed.inventoryFeedProvider.inventory_item_class)
        return false;

    //Hack
    if (feed && feed.feed_settings && feed.feed_settings.includes("InventoryExportFeedRetriever")) {
        return false;
    }

    //Hack again
    if (feed && feed.feed_settings && feed.feed_settings.includes("RETSBrokerAgentsFeedConverter")) {
        return false;
    }

    const itemClasses = getInventoryItemClassListFromClassesArray(feed.inventoryFeedProvider.inventory_item_class);

    return itemClasses.some(itemClass => itemClass.canAssignTempalates);
}

export const feedIsInventoryItemFeed = feed => {
    return !feedIsKeyValueData(feed)
}

//KeyValueFeedProcessor
export const feedIsKeyValueData = feed => {

    //Hack
    if (feed && feed.feed_settings && feed.feed_settings.includes("KeyValueFeedProcessor")) {
        return true;
    }

    return false;
}

export const feedWritesStatsToKeyValues = feed => {

    //Hack because some item feeds can write key values from their stats
    if (feed && feed.feed_settings && feed.feed_settings.includes("write_feed_stats_keys_as_key_values")) {
        return true;
    }

    return false;
}

export const hasMultipleDriveTemplates = feed => feed.drive_templates
    && feed.drive_templates.templates
    && feed.drive_templates.templates.length > 0

export const defaultDriveTemplate = feed => feed.drive_templates
    && feed.drive_templates.default_template

export const multipleDriveTemplates = feed => feed.drive_templates
    && feed.drive_templates.templates

export const hasRunDuration = run => run.feed_run_start_at
    && run.completed;
export const runDuration = run => hasRunDuration(run)
    ? moment.duration(moment(run.completed).diff(moment(run.feed_run_start_at)))
    : null;

export const runCompletedAt = run => toLocalDateTime(run.completed);
export const runStartedAt = run => toLocalDateTime(run.feed_run_start_at);

export const hasLimitedCronSchedules = feed => {

    const feedSettings = getSettingsFromFeed(feed);

    feedSettings
        && feedSettings.retriever
        && feedSettings.retriever.allowed_cron_schedules
        && feedSettings.retriever.allowed_cron_schedules.length > 0;
}

//The allowed_cron_schedules is an array of cron scheduile ids
export const cronScheduleIdIsAllowed = (feed, cronScheduleId) => {

    if (!hasLimitedCronSchedules(feed))
        return true;

    const feedSettings = getSettingsFromFeed(feed);

    return feedSettings.retriever.allowed_cron_schedules.includes(cronScheduleId);

}

export const getSettingsFromFeed = feed => {

    try {
        const feedSettings = JSON.parse(feed?.feed_settings || "{}");
        return feedSettings;
    }
    catch (e) {
        return {};
    }
}

export const feedAttributeFields = feed => {

    const feedSettings = getSettingsFromFeed(feed);

    return feedSettings
        && feedSettings.converter
        && feedSettings.converter.attribute_fields
}
