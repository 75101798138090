import {
    DESIGNER_ACCOUNT_CHANGE, DESIGNER_ADD_ASSET, DESIGNER_ADD_BOARD_FORMAT, DESIGNER_ADD_FONT, DESIGNER_ADD_ITEM_CLASS,
    DESIGNER_CLEAR_ALL_SIZE_VARIANTS, DESIGNER_CREATE_TEMPLATE, DESIGNER_CSS_CHANGE, DESIGNER_CSS_REPLACE_ID,
    DESIGNER_DEL_ASSET, DESIGNER_EDIT_MODE_GLOBAL_CHANGE, DESIGNER_HTML_APPEND, DESIGNER_HTML_CHANGE,
    DESIGNER_HTML_DELETE_LAYER, DESIGNER_HTML_MOVE_ELEMENT_LAYER, DESIGNER_HTML_REPLACE_FOR_ID, DESIGNER_ITEM_CHANGE,
    DESIGNER_ITEM_CLASS_CHANGE, DESIGNER_JS_CHANGE, DESIGNER_LOAD_BOARD_FORMATS, DESIGNER_LOAD_TEMPLATE, DESIGNER_LOAD_TEMPLATE_ASSETS,
    DESIGNER_PUBLISH_TEMPLATE, DESIGNER_REMOVE_BOARD_FORMAT, DESIGNER_REMOVE_ITEM_CLASS, DESIGNER_RESET_STATE,
    DESIGNER_SELECT_VARIANT, DESIGNER_SET_CSS_VARIANTS, DESIGNER_SET_SELECTED_EDITOR_ID, DESIGNER_SET_SELECTED_FRAME_ID,
    DESIGNER_SET_SIZE_VARIANT, DESIGNER_SET_TEMPLATE_CROP_RATIO, DESIGNER_SET_TEMPLATE_DESCRIPTION, DESIGNER_SET_TEMPLATE_NAME,
    DESIGNER_SHOW_HIDE_CODE_EDITOR, DESIGNER_SHOW_HIDE_TEMPLATES,
    DESIGNER_UNPUBLISH_TEMPLATE, DESIGNER_UPDATE_TEMPLATE, DESIGNER_HTML_REPLACE_INNER_HTML_FOR_ID,
    DESIGNER_TEMPLATE_MAKE_PUBLIC, DESIGNER_TEMPLATE_MAKE_PRIVATE, DESIGNER_REMOVE_TEMPLATE, DESIGNER_DUPLICATE_TEMPLATE,
    DESIGNER_DRAGGING_IS_HAPPENING, DESIGNER_DRAGGING_IS_DONE, DESIGNER_HTML_SYNC_LAYERS, DESIGNER_FONTS,
    DESIGNER_HTML_LAYER_ADD_CLASS, DESIGNER_HTML_LAYER_REMOVE_CLASS, DESIGNER_HTML_LAYER_SET_CSS_FOR_KEY,
    DESIGNER_PUSH_HISTORY, DESIGNER_UNDO, DESIGNER_REDO, DESIGNER_HTML_DUPLICATE_LAYER, DESIGNER_HTML_PASTE_TEXT,
    USER_SELECT_USER, USER_SELECT_AGENCY, USER_SELECT_ACCOUNT, DESIGNER_UPDATE_TEMPLATE_REQUEST, DESIGNER_FORMAT_PASTE,
    DESIGNER_FORMAT_COPY, DESIGNER_PREVIEW_SET_ASPECT_RATIO, DESIGNER_ITEM_USER_CHANGE, DESIGNER_SYNC_ALL_SIZE_VARIANTS,
    DESIGNER_SET_GRIDSIZE, DESIGNER_SET_GRIDSHOW, DESIGNER_SET_GRIDCOLOR, DESIGNER_SET_SNAPPING, DESIGNER_SET_ZOOM,
    DESIGNER_SET_BORDER_ALL_ELEMENTS_SHOW, DESIGNER_BOARD_CHANGE,
    DESGINER_SET_ITEM_CLASS, DESIGNER_REPLACE_ASSET, DESIGNER_LOAD_ACCOUNT_KEY_VALUE_STORE,
    DESIGNER_SET_FIELD_MAP, DESIGNER_ADD_CUSTOM_FIELD, DESIGNER_UPDATE_CUSTOM_FIELD, DESIGNER_REMOVE_CUSTOM_FIELD,
    DESIGNER_LOAD_TEMPLATES, DESIGNER_LOAD_TEMPLATES_START, DESIGNER_CLEAR_TEMPLATE, DESIGNER_TEMPLATE_LOADING,
    DESIGNER_KEY_VALUE_DATA_LOADING, DESISGNER_LOAD_TEMPLATE_ELEMENTS, DESIGNER_ADD_OBJECT
} from "../helpers/actionTypes";

import {
    deleteCSSId, replaceCSSStyleForId, getColorsFromCSS, getNewStyleStringForString, getCSSStyleForId,
    ofssetTopLeftPositionInCss, getStyleValueForCSSKey
} from '../containers/designer/DesignerCSS';
import {
    replaceHtmlForId, replaceInnerHtmlForId, getHtmlForId, htmlStringToElement, replaceInnerTextForHtml
} from '../containers/designer/DesignerHTML';
import {
    getAssetMacroHtml, getAssetMacroCSS, getIdFromAsset, getIdForNewObject,
    getNewAssetIdFromCopy, getObjectElementForObjectCode, getCSSSizingStringForNewElements
} from '../containers/designer/DesignerElements';
import { getListOfUsedFonts } from '../containers/designer/DesignerFonts';
import { LuCoreModelClasses, Designer, InventoryItemClasses } from '../helpers/constants';
import { getDataSourceFields } from "../selectors/designer";
import { getListOfUsedMacros } from "../selectors/designerMacro";
import { getLayerDataFromHtml } from "../selectors/designerLayers";
import { parseLayersFromHtml, serializeLayersToHtml } from "../helpers/designer/html";
import { getUsedMacroses } from "../helpers/designer/macroses";

export const initialState = {
    templates: [],
    templatesLoading: false,
    templateIsLoading: false,
    lcuid: null,
    templateName: "Untitled Design",
    templateDescription: "",
    html: Designer.NewTemplateHTML,
    css: Designer.NewTemplateCSS,
    js: "",
    sizeVariantsCSS: {},
    variants: [],
    templateBoardFormats: [],
    selectedVariant: null,
    previewAccount: null,
    previewInventoryItem: null,
    previewInventoryItemUser: null,
    previewBoard: null,
    previewAspectRatio: 1,
    itemClass: null,
    editorSelectedId: Designer.BackgroundLayerId,
    editorSelectedIdSource: null,
    frameIdSelected: null,
    editModeGlobal: true,
    //if this is true, editing the object will affect all sizes.
    //If false, only will affect the sizeVariant that is frameIdSelected
    assets: [],
    fonts: [],
    fontList: [],
    colors: [],
    photo_crop_aspect_ratio: 1,
    dirty: false,
    boardFormats: {},
    primaryImagePublicUrl: null,
    options: {},
    inventory_item_class: [InventoryItemClasses.photoStream.class],
    status: null,
    templatesVisible: false,
    codeEditorVisible: false,
    draggingIsHappening: false,
    templateAssets: {},
    usedMacros: [],
    layers: [],
    parent_id: null,
    parent_type: null,
    parent: null,
    public: false,
    gridSize: 1,
    gridShow: false,
    gridColor: "rgba(196, 196, 196, 0.3)",
    enableSnapping: true,
    borderAllElementsShow: false,
    zoom: 1,
    history: [],
    historyPointer: -1,
    clipboardFormat: null,
    fieldMap: null,
    customFieldMap: [],
    accountKeyValueStore: [],
    accountKeyValueStoreLoading: false,
    fields: [],
    elements: [],
}

const getAutosaveProps = state => ({
    css: state.css,
    html: state.html,
    sizeVariantsCSS: state.sizeVariantsCSS
})

export default function designer(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case DESIGNER_LOAD_TEMPLATES_START:
            return Object.assign({}, state, {
                templatesLoading: true
            })
        case DESIGNER_LOAD_TEMPLATES:
            return Object.assign({}, state, {
                templates: payload.templates,
                templatesLoading: false
            })
        case DESISGNER_LOAD_TEMPLATE_ELEMENTS:
            return Object.assign({}, state, {
                elements: payload.elements
            })
        case DESIGNER_PUSH_HISTORY:
            {
                const history = state.historyPointer == state.history.length - 1
                    ? [...state.history, getAutosaveProps(state)]
                    : [...state.history.slice(0, state.historyPointer + 1), getAutosaveProps(state)];
                const historyPointer = history.length - 1;

                return Object.assign({}, state, {
                    history,
                    historyPointer
                })
            }
        case DESIGNER_UNDO:
            {
                const historyPointer = Math.max(state.historyPointer - 1, 0);
                const lastState = state.history[historyPointer];

                return Object.assign({}, state, {
                    ...lastState,
                    historyPointer,
                    dirty: true
                });
            }
        case DESIGNER_REDO:
            {
                const historyPointer = Math.min(state.historyPointer + 1, state.history.length - 1);
                const lastState = state.history[historyPointer];

                return Object.assign({}, state, {
                    ...lastState,
                    historyPointer,
                    dirty: true
                });
            }

        case DESIGNER_SET_GRIDSIZE: {
            return Object.assign({}, state, {
                gridSize: payload.gridSize
            });
        }
        case DESIGNER_SET_GRIDSHOW: {
            return Object.assign({}, state, {
                gridShow: payload.gridShow
            });
        }
        case DESIGNER_SET_GRIDCOLOR: {
            return Object.assign({}, state, {
                gridColor: payload.gridColor
            });
        }

        case DESIGNER_SET_BORDER_ALL_ELEMENTS_SHOW: {
            return Object.assign({}, state, {
                borderAllElementsShow: payload.borderAllElementsShow
            });
        }

        case USER_SELECT_USER:
        case USER_SELECT_AGENCY:
        case USER_SELECT_ACCOUNT:
            return Object.assign({},
                state,
                { ...initialState });

        case DESIGNER_RESET_STATE:
            return Object.assign({},
                state,
                { ...initialState, templates: state.templates, elements: state.elements });

        case DESIGNER_CLEAR_TEMPLATE: {

            return Object.assign({},
                state,
                {
                    ...initialState,
                    templates: state.templates,
                    lcuid: state.lcuid,
                    elements: state.elements,
                    dirty: false
                });
        }

        case DESIGNER_TEMPLATE_LOADING: {
            return Object.assign({},
                state,
                {
                    templateIsLoading: true
                });
        }

        case DESIGNER_LOAD_TEMPLATE:
        case DESIGNER_DUPLICATE_TEMPLATE: {

            const driveTemplate = payload.drive_template ?? {};
            const templateBase =
                (driveTemplate && driveTemplate.templates && driveTemplate.templates.base) ? driveTemplate.templates.base : {};

            const parentIsInventoryItem = driveTemplate.parent_type === LuCoreModelClasses.inventoryItem.class;

            const newState = Object.assign({},
                state,
                {
                    lcuid: driveTemplate.lcuid,
                    templateIsLoading: false,
                    templateName: driveTemplate.name,
                    templateDescription: driveTemplate.description,
                    html: templateBase.html,
                    css: templateBase.css,
                    js: templateBase.js,
                    sizeVariantsCSS: templateBase.css_size_variants ?? {},
                    variants: driveTemplate.variants ?? [],
                    templateBoardFormats: templateBase.template_board_formats ?? [],
                    photo_crop_aspect_ratio: templateBase.photo_crop_aspect_ratio ?? 1,
                    selectedVariant: null,
                    editorSelectedId: Designer.BackgroundLayerId,
                    editorSelectedIdSource: null,
                    editModeGlobal: templateBase["edit_mode_global"] === undefined ? true : templateBase["edit_mode_global"],
                    assets: driveTemplate.assets ?? [],
                    fonts: templateBase.fonts ?? [],
                    colors: templateBase.colors ?? [],
                    inventory_item_class: driveTemplate.inventory_item_class ?? initialState.inventory_item_class,
                    primaryImagePublicUrl: driveTemplate && driveTemplate.options && driveTemplate.options.primary_image_public_url,
                    options: driveTemplate.options ?? {},
                    status: driveTemplate.status,
                    public: driveTemplate.public,
                    usedMacros: templateBase.used_macros,
                    customFieldMap: templateBase.custom_field_map ?? [],
                    //This is here to get legacy templates to have layers
                    layers: templateBase.layers ?? getLayerDataFromHtml({ designer: state }, templateBase.html),
                    parent_id: driveTemplate.parent_id,
                    parent_type: driveTemplate.parent_type,
                    parent: driveTemplate.parent,
                    previewInventoryItem: parentIsInventoryItem ? driveTemplate.parent : state.previewInventoryItem,
                    previewInventoryItemUser: parentIsInventoryItem ? driveTemplate.parent.user : state.previewInventoryItemUser,
                    dirty: false
                });

            return { ...newState, history: [getAutosaveProps(newState)], historyPointer: 0 }
        }

        case DESIGNER_SHOW_HIDE_TEMPLATES: {
            return Object.assign({},
                state,
                {
                    templatesVisible: payload.templatesVisible
                });
        }

        case DESIGNER_SHOW_HIDE_CODE_EDITOR: {
            return Object.assign({},
                state,
                {
                    codeEditorVisible: payload.codeEditorVisible
                });
        }

        case DESIGNER_CREATE_TEMPLATE: {
            const driveTemplate = payload.drive_template ?? {};

            return Object.assign({},
                state,
                {
                    lcuid: driveTemplate.lcuid,
                    status: driveTemplate.status,
                    dirty: false,
                    templateIsLoading: false
                });
        }
        case DESIGNER_TEMPLATE_MAKE_PUBLIC:
        case DESIGNER_TEMPLATE_MAKE_PRIVATE: {
            const driveTemplate = payload.drive_template ?? {};

            return Object.assign({},
                state,
                {
                    public: driveTemplate.public,
                    dirty: false
                });
        }

        case DESIGNER_UPDATE_TEMPLATE_REQUEST:
            return Object.assign({},
                state,
                {
                    saving: true
                });

        case DESIGNER_UPDATE_TEMPLATE:
        case DESIGNER_PUBLISH_TEMPLATE:
        case DESIGNER_REMOVE_TEMPLATE:
        case DESIGNER_UNPUBLISH_TEMPLATE: {
            const driveTemplate = payload.drive_template ?? {};

            return Object.assign({},
                state,
                {
                    status: driveTemplate.status,
                    dirty: false,
                    saving: false
                });
        }

        case DESIGNER_LOAD_TEMPLATE_ASSETS: {
            return Object.assign({},
                state,
                {
                    templateAssets: payload.templateAssets
                });
        }

        case DESIGNER_LOAD_BOARD_FORMATS:

            return Object.assign({},
                state,
                {
                    boardFormats: payload.boardFormats
                });

        case DESIGNER_KEY_VALUE_DATA_LOADING: {
            return Object.assign({},
                state,
                {
                    accountKeyValueStoreLoading: true
                });
        }

        case DESIGNER_LOAD_ACCOUNT_KEY_VALUE_STORE:

            return Object.assign({},
                state,
                {
                    accountKeyValueStore: payload.keyValueStore,
                    accountKeyValueStoreLoading: false
                });

        case DESIGNER_SET_TEMPLATE_NAME:

            return Object.assign({},
                state,
                {
                    templateName: payload.templateName,
                    dirty: true
                });

        case DESIGNER_SET_TEMPLATE_DESCRIPTION:

            return Object.assign({},
                state,
                {
                    templateDescription: payload.templateDescription,
                    dirty: true
                });

        case DESIGNER_SET_TEMPLATE_CROP_RATIO:

            return Object.assign({},
                state,
                {
                    photo_crop_aspect_ratio: payload.photo_crop_aspect_ratio,
                    dirty: true
                });

        case DESIGNER_HTML_CHANGE:

            return Object.assign({},
                state,
                {
                    html: payload.html,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        payload.html + state.css + state.js,
                        state.assets),
                    dirty: true
                });

        case DESIGNER_HTML_REPLACE_INNER_HTML_FOR_ID: {
            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            const newHtml = replaceInnerHtmlForId(id, payload.html, state.html)

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        newHtml + state.css + state.js,
                        state.assets),
                    dirty: true
                });
        }

        case DESIGNER_HTML_REPLACE_FOR_ID: {

            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            const newHtml = replaceHtmlForId(id, payload.html, state.html)

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        newHtml + state.css + state.js,
                        state.assets),
                    layers: state.layers.map(layer => payload.layerSettings && layer.id === id ? payload.layerSettings : layer),
                    dirty: true
                });
        }

        case DESIGNER_HTML_DUPLICATE_LAYER: {
            const layers = parseLayersFromHtml(state.html);
            const existingLayer = layers.find(x => x.id == payload.id);
            const newLayer = { ...existingLayer, id: getNewAssetIdFromCopy(payload.id) };
            layers.push(newLayer);

            const existingCss = ofssetTopLeftPositionInCss(
                getCSSStyleForId(payload.id, state.css), 2
            )

            return Object.assign({},
                state,
                {
                    html: serializeLayersToHtml(layers),
                    css: replaceCSSStyleForId(newLayer.id, existingCss, state.css),
                    usedMacros: getUsedMacroses(layers),
                    layers,
                    dirty: true
                });
        }

        case DESIGNER_HTML_PASTE_TEXT: {

            const lastFontUsed = state.fonts.length ? state.fonts[state.fonts.length - 1] : null
            const lastColorUsed = state.colors.length ? state.colors[state.colors.length - 1] : null

            const newTextElement = getObjectElementForObjectCode(Designer.ObjectCodeForTextPaste)
            const newId = getIdForNewObject(newTextElement)

            const newHtmlElement = replaceInnerTextForHtml(newTextElement.html, payload.content).replace("{id}", newId)
            const newHtml = state.html + newHtmlElement

            const fontName = lastFontUsed ? lastFontUsed.name : Designer.DefaultFontName
            const lastColorShouldBeIgnored = Designer.IgnoreLastColorIfOneOf.includes(lastColorUsed)
            const color = (lastColorUsed && !lastColorShouldBeIgnored) ? lastColorUsed : Designer.DefaultTextColor

            const fontsCss = "font-family:'" + fontName + "';"
            const colorCss = "color:" + color + ";"
            const css = newTextElement.defaultCss + getCSSSizingStringForNewElements() + fontsCss + colorCss

            const newCss = replaceCSSStyleForId(newId, css, state.css)

            const newLayers = getLayerDataFromHtml({ designer: state }, newHtml)

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    css: newCss,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        newHtml + newCss + state.js, state.assets, getDataSourceFields({ designer: state })),
                    layers: newLayers,
                    dirty: true
                });
        }

        case DESIGNER_HTML_DELETE_LAYER: {

            const newHtml = replaceHtmlForId(payload.id, "", state.html)
            const newCss = deleteCSSId(payload.id, state.css)

            const sizeVariants = state.sizeVariantsCSS ?? {}

            //THis part remvoes the css from all size variants
            const sizeVariantIds = Object.keys(sizeVariants) ?? []

            sizeVariantIds.forEach((sizeVariant) => {
                const newCss = deleteCSSId(payload.id, sizeVariants[sizeVariant].css)
                sizeVariants[sizeVariant].css = newCss
            })

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    css: newCss,
                    sizeVariantsCSS: sizeVariants,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        newHtml + newCss + state.js,
                        state.assets),
                    layers: state.layers.filter((layer) => layer.id !== payload.id),
                    dirty: true
                });
        }

        case DESIGNER_HTML_LAYER_ADD_CLASS: {
            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            const html = getHtmlForId(id, state.html)

            const el = htmlStringToElement(html);

            el.classList.add(payload.className)

            const newHtml = replaceHtmlForId(id, el.outerHTML, state.html)

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    dirty: true
                });
        }

        case DESIGNER_HTML_LAYER_REMOVE_CLASS: {

            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            const html = getHtmlForId(id, state.html)

            const el = htmlStringToElement(html);

            el.classList.remove(payload.className)

            const newHtml = replaceHtmlForId(id, el.outerHTML, state.html)

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    dirty: true
                });

        }

        case DESIGNER_HTML_LAYER_SET_CSS_FOR_KEY: {
            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            if (state.editModeGlobal) {
                const css = getCSSStyleForId(id, state.css)

                const newCssForId = getNewStyleStringForString(css, [
                    [payload.key, payload.value]
                ])

                const newCss = replaceCSSStyleForId(id, newCssForId, state.css)

                return Object.assign({},
                    state,
                    {
                        css: newCss,
                        usedMacros: getListOfUsedMacros(
                            { designer: state },
                            state.html + newCss + state.js,
                            state.assets),
                        fonts: getListOfUsedFonts(newCss, state.fontList),
                        colors: getColorsFromCSS(newCss),
                        dirty: true
                    });
            }
            else {

                const sizeVariant = state.sizeVariantsCSS[state.frameIdSelected] ?? []

                const sizeVariantCss = sizeVariant.css ?? ""

                const css = getCSSStyleForId(id, sizeVariantCss)

                const newCssForId = getNewStyleStringForString(css, [
                    [payload.key, payload.value]
                ])

                const newCss = replaceCSSStyleForId(
                    id,
                    newCssForId,
                    sizeVariantCss,
                )

                const newSv = {}

                newSv[state.frameIdSelected] = {
                    css: newCss
                }

                const newSizeVariants = {
                    ...state.sizeVariantsCSS,
                    ...newSv
                }

                const allCss = state.css + Object.values(newSizeVariants).map(sv => sv.css).join("\n")

                return Object.assign({},
                    state,
                    {
                        sizeVariantsCSS: newSizeVariants,
                        fonts: getListOfUsedFonts(allCss, state.fontList),
                        colors: getColorsFromCSS(allCss),
                        dirty: true
                    });
            }

        }

        case DESIGNER_HTML_SYNC_LAYERS: {

            return Object.assign({},
                state,
                {
                    layers: payload.layers,
                    dirty: true
                });
        }

        case DESIGNER_HTML_APPEND:

            return Object.assign({},
                state,
                {
                    html: payload.html,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        payload.html + state.css + state.js,
                        state.assets),
                    dirty: true
                });

        case DESIGNER_HTML_MOVE_ELEMENT_LAYER: {

            let html = state.html;
            const parser = new DOMParser();
            const htmlDom = parser.parseFromString(html, "text/html");

            const elemToMove = htmlDom.getElementById(payload.id);
            const parent = elemToMove.parentNode;

            const bgLayer = htmlDom.getElementById(Designer.BackgroundLayerId);

            if (!elemToMove)
                return state; //Can't move id that does not exist

            if (payload.toId) {
                //We are moving to a specific id
                const elemToMoveTo = htmlDom.getElementById(payload.toId);

                if (!elemToMoveTo)
                    return state; //Can't move to id that does not exist

                if (payload.direction === Designer.Directions.Backward)
                    parent.insertBefore(elemToMove, elemToMoveTo);

                if (payload.direction === Designer.Directions.Forward)
                    parent.insertBefore(elemToMove, elemToMoveTo.nextElementSibling);
            }
            else {
                //We are just going up or down
                if (payload.direction === Designer.Directions.Back) {
                    const firstChild = parent.firstElementChild;
                    if (firstChild !== elemToMove) {
                        if (bgLayer)
                            parent.insertBefore(elemToMove, bgLayer.nextElementSibling);
                        else
                            parent.insertBefore(elemToMove, firstChild);
                    }
                } else if (payload.direction === Designer.Directions.Front) {
                    const lastChild = parent.lastElementChild;
                    if (lastChild !== elemToMove) {
                        parent.insertBefore(elemToMove, null);
                    }
                } else if (payload.direction === Designer.Directions.Backward) {
                    const prevSibling = elemToMove.previousElementSibling;
                    if (prevSibling && prevSibling.id !== Designer.BackgroundLayerId) {
                        parent.insertBefore(elemToMove, prevSibling);
                    }
                } else if (payload.direction === Designer.Directions.Forward) {
                    const nextSibling = elemToMove.nextElementSibling;
                    if (nextSibling) {
                        parent.insertBefore(elemToMove, nextSibling.nextElementSibling);
                    }
                }
            }

            const newHtmlString = htmlDom.body.innerHTML;

            return Object.assign({},
                state,
                {
                    html: newHtmlString,
                    dirty: true
                });
        }

        case DESIGNER_CSS_CHANGE: {

            const allCss = payload.css + Object.values(state.sizeVariantsCSS).map(sv => sv.css).join("\n")

            return Object.assign({},
                state,
                {
                    css: payload.css,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        state.html + payload.css + state.js,
                        state.assets),
                    fonts: getListOfUsedFonts(allCss, state.fontList),
                    colors: getColorsFromCSS(allCss),
                    dirty: true
                });
        }

        case DESIGNER_FORMAT_COPY: {
            const id = payload.id ?? state.editorSelectedId

            if (state.editModeGlobal) {
                return Object.assign({},
                    state,
                    {
                        clipboardFormat: getCSSStyleForId(id, state.css)
                    });
            }
            else {
                const sizeVariant = state.sizeVariantsCSS[state.frameIdSelected] ?? []

                const css = sizeVariant.css ?? ""

                return Object.assign({},
                    state,
                    {
                        clipboardFormat: getCSSStyleForId(id, css)
                    });
            }

        }

        case DESIGNER_FORMAT_PASTE: {
            const id = payload.id ?? state.editorSelectedId

            const allSizes = payload.allSizes ?? false

            let css = getCSSStyleForId(id, state.css);
            let newCss = getNewStyleStringForString(state.clipboardFormat, [
                ['top', getStyleValueForCSSKey(css, 'top')],
                ['left', getStyleValueForCSSKey(css, 'left')],
                ['width', getStyleValueForCSSKey(css, 'width')],
                ['height', getStyleValueForCSSKey(css, 'height')]
            ])

            if (!id || !state.clipboardFormat)
                return state;

            if (state.editModeGlobal) {
                return Object.assign({},
                    state,
                    {
                        css: replaceCSSStyleForId(id, newCss, state.css),
                        usedMacros: getListOfUsedMacros(
                            { designer: state },
                            state.html + newCss + state.js,
                            state.assets),
                        fonts: getListOfUsedFonts(newCss, state.fontList),
                        colors: getColorsFromCSS(newCss),
                        dirty: true
                    });
            }
            else {

                if (allSizes) {
                    const newSizeVariants = {}

                    state.templateBoardFormats.forEach(format => {

                        let individualSizeVariant = state.sizeVariantsCSS[format] ?? []
                        let individualCss = individualSizeVariant.css ?? ""

                        const itemCss = getCSSStyleForId(id, individualCss);

                        newCss = getNewStyleStringForString(state.clipboardFormat, [
                            ['top', getStyleValueForCSSKey(itemCss, 'top')],
                            ['left', getStyleValueForCSSKey(itemCss, 'left')],
                            ['width', getStyleValueForCSSKey(itemCss, 'width')],
                            ['height', getStyleValueForCSSKey(itemCss, 'height')]
                        ]);

                        newSizeVariants[format] = {
                            css: replaceCSSStyleForId(
                                id,
                                newCss,
                                individualCss,
                            )
                        }
                    });

                    const allCss = state.css + Object.values(newSizeVariants).map(sv => sv.css).join("\n")

                    return Object.assign({},
                        state,
                        {
                            sizeVariantsCSS: newSizeVariants,
                            fonts: getListOfUsedFonts(allCss, state.fontList),
                            colors: getColorsFromCSS(allCss),
                            dirty: true
                        });

                }
                else {
                    const sizeVariant = state.sizeVariantsCSS[state.frameIdSelected] ?? []

                    const sizeVariantCss = sizeVariant.css ?? ""

                    const itemCss = getCSSStyleForId(id, sizeVariantCss);

                    newCss = getNewStyleStringForString(state.clipboardFormat, [
                        ['top', getStyleValueForCSSKey(itemCss, 'top')],
                        ['left', getStyleValueForCSSKey(itemCss, 'left')],
                        ['width', getStyleValueForCSSKey(itemCss, 'width')],
                        ['height', getStyleValueForCSSKey(itemCss, 'height')]
                    ]);

                    const newSv = {
                        [state.frameIdSelected]: {
                            css: replaceCSSStyleForId(
                                id,
                                newCss,
                                sizeVariantCss,
                            )
                        }
                    }

                    const newSizeVariants = {
                        ...state.sizeVariantsCSS,
                        ...newSv
                    }

                    const allCss = state.css + Object.values(newSizeVariants).map(sv => sv.css).join("\n")

                    return Object.assign({},
                        state,
                        {
                            sizeVariantsCSS: newSizeVariants,
                            fonts: getListOfUsedFonts(allCss, state.fontList),
                            colors: getColorsFromCSS(allCss),
                            dirty: true
                        });
                }

            }
        }

        case DESIGNER_CSS_REPLACE_ID: {

            const id = payload.id ?? state.editorSelectedId

            if (!id)
                return state;

            if (state.editModeGlobal) {
                const newCss = replaceCSSStyleForId(id, payload.css, state.css)

                return Object.assign({},
                    state,
                    {
                        css: newCss,
                        usedMacros: getListOfUsedMacros(
                            { designer: state },
                            state.html + newCss + state.js,
                            state.assets),
                        fonts: getListOfUsedFonts(newCss, state.fontList),
                        colors: getColorsFromCSS(newCss),
                        dirty: true
                    });
            }
            else {

                const sizeVariant = state.sizeVariantsCSS[state.frameIdSelected] ?? []

                const sizeVariantCss = sizeVariant.css ?? ""

                const newCss = replaceCSSStyleForId(
                    id,
                    payload.css,
                    sizeVariantCss,
                )

                const newSv = {}

                newSv[state.frameIdSelected] = {
                    css: newCss
                }

                const newSizeVariants = {
                    ...state.sizeVariantsCSS,
                    ...newSv
                }

                const allCss = state.css + Object.values(newSizeVariants).map(sv => sv.css).join("\n")

                return Object.assign({},
                    state,
                    {
                        sizeVariantsCSS: newSizeVariants,
                        fonts: getListOfUsedFonts(allCss, state.fontList),
                        colors: getColorsFromCSS(allCss),
                        dirty: true
                    });
            }

        }

        case DESIGNER_JS_CHANGE:

            return Object.assign({},
                state,
                {
                    js: payload.js,
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        state.html + state.css + payload.js,
                        state.assets),
                    dirty: true
                });

        case DESIGNER_SET_SIZE_VARIANT: {

            const newSv = {}

            newSv[payload.boardFormat] = {
                css: payload.css
            }

            return Object.assign({},
                state,
                {
                    sizeVariantsCSS: {
                        ...state.sizeVariantsCSS,
                        ...newSv
                    },
                    dirty: true
                });
        }

        case DESIGNER_CLEAR_ALL_SIZE_VARIANTS: {

            return Object.assign({},
                state,
                {
                    sizeVariantsCSS: {},
                    dirty: true
                });
        }

        case DESIGNER_SYNC_ALL_SIZE_VARIANTS: {

            const newSizeVariants = {}

            state.templateBoardFormats.forEach(format => {
                newSizeVariants[format] = {
                    css: state.css
                }
            });

            return Object.assign({},
                state,
                {
                    sizeVariantsCSS: newSizeVariants,
                    dirty: true
                });
        }

        case DESIGNER_SET_CSS_VARIANTS:

            return Object.assign({},
                state,
                {
                    variants: payload.variants,
                    dirty: true
                });

        case DESIGNER_SELECT_VARIANT:
            return Object.assign({},
                state,
                {
                    selectedVariant: payload.variantId,
                });

        case DESIGNER_ADD_OBJECT: {

            const id = payload.id
            const html = payload.html
            const css = payload.css
            const showOnlyForThisBoardFormat = payload.showOnlyForThisBoardFormat ?? false
            const isBackground = payload.isBackground ?? false

            const layer = parseLayersFromHtml(html)[0];
            const layers = parseLayersFromHtml(state.html);

            //Find highest index of layers where layer.classes contains lc_dt_image_full_size_stretch
            //This is so, when adding a new background layer, it will be above any existing ones
            //this is really helpful when working in individual edit mode.
            const highestBackgroundLayerIndex = layers.reduce((acc, layer, index) => {
                if (layer.classes.includes("lc_dt_image_full_size_stretch")) {
                    return index
                }
                return acc
            }, 0)

            if (isBackground) {
                layers.splice((highestBackgroundLayerIndex + 1), 0, layer)
            } else {
                layers.push(layer);
            }

            const newGlobalCss = replaceCSSStyleForId(
                id,
                css,
                state.css)

            if (state.editModeGlobal) {

                return Object.assign({},

                    state,
                    {
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        layers,
                        js: payload.js ? state.js + '\n' + payload.js : state.js,
                    });
            }
            else {
                const sizeVariants = state.sizeVariantsCSS ?? {}

                const sizeVariantIds = Object.keys(sizeVariants) ?? []

                sizeVariantIds.forEach((sizeVariantId) => {
                    const sizeVariantCss = sizeVariants[sizeVariantId].css ?? ""

                    const isCurrentFrame = state.frameIdSelected === sizeVariantId

                    const newElementCss = css

                    //If the user requested to add it only for this current board format
                    //then we need to hide it for all others
                    const shouldBeVisible = !showOnlyForThisBoardFormat || isCurrentFrame

                    const newFrameSpecificCss = getNewStyleStringForString(newElementCss, [
                        ["visibility", shouldBeVisible ? "visible" : "hidden"]
                    ])

                    const newCss = replaceCSSStyleForId(
                        id,
                        newFrameSpecificCss,
                        sizeVariantCss,
                    )

                    sizeVariants[sizeVariantId].css = newCss
                })

                return Object.assign({},
                    state,
                    {
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        sizeVariantsCSS: sizeVariants,
                        dirty: true,
                        js: payload.js ? state.js + '\n' + payload.js : state.js,
                    });
            }

        }

        case DESIGNER_ADD_ASSET: {

            const layer = parseLayersFromHtml(getAssetMacroHtml(payload.asset))[0];
            const layers = parseLayersFromHtml(state.html);

            const showOnlyForThisBoardFormat = payload.showOnlyForThisBoardFormat ?? false

            //Find highest index of layers where layer.classes contains lc_dt_image_full_size_stretch
            //This is so, when adding a new background layer, it will be above any existing ones
            //this is really helpful when working in individual edit mode.
            const highestBackgroundLayerIndex = layers.reduce((acc, layer, index) => {
                if (layer.classes.includes("lc_dt_image_full_size_stretch")) {
                    return index
                }
                return acc
            }, 0)

            if (payload.asset.isBackground) {
                layers.splice((highestBackgroundLayerIndex + 1), 0, layer)
            } else {
                layers.push(layer);
            }

            const newGlobalCss = replaceCSSStyleForId(
                getIdFromAsset(payload.asset),
                getAssetMacroCSS(payload.asset),
                state.css)

            if (state.editModeGlobal) {

                return Object.assign({},
                    state,
                    {
                        assets: [...state.assets, payload.asset],
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        layers
                    });
            }
            else {
                const sizeVariants = state.sizeVariantsCSS ?? {}

                const sizeVariantIds = Object.keys(sizeVariants) ?? []

                sizeVariantIds.forEach((sizeVariantId) => {
                    const sizeVariantCss = sizeVariants[sizeVariantId].css ?? ""

                    const isCurrentFrame = state.frameIdSelected === sizeVariantId

                    const newElementCss = getAssetMacroCSS(payload.asset)

                    //If the user requested to add it only for this current board format
                    //then we need to hide it for all others
                    const shouldBeVisible = !showOnlyForThisBoardFormat || isCurrentFrame

                    const newFrameSpecificCss = getNewStyleStringForString(newElementCss, [
                        ["visibility", shouldBeVisible ? "visible" : "hidden"]
                    ])

                    const newCss = replaceCSSStyleForId(
                        getIdFromAsset(payload.asset),
                        newFrameSpecificCss,
                        sizeVariantCss,
                    )

                    sizeVariants[sizeVariantId].css = newCss
                })

                return Object.assign({},
                    state,
                    {
                        assets: [...state.assets, payload.asset],
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        sizeVariantsCSS: sizeVariants,
                        dirty: true
                    });

            }

        }

        case DESIGNER_REPLACE_ASSET: {

            /**
             * Note to future self or reader of this god-awful garbage code that I wrote:
             *
             * When replacing an image with a new one, we need to do a few things.
             * 1.  Determine if the user wants to replace it on THIS BOARD SIZE only
             * 2.  IF the user wants to replace it on THIS BOARD SIZE only, then we need to
             *    add a new layer just above the existing one, and hide it for all other
             *   sizes.
             * 3.  IF the user wants to replace it on ALL BOARD SIZES, then we need to just replace
             *  the image on all sizes (global and all size variants)
             *
             * So, confusingly, replace, when user wants to replace only in this board format size, is
             * not a replace, but a hide and add new
             */

            const replaceId = payload.replaceId
            const replaceOnlyForThisBoardFormatSize = payload.replaceOnlyForThisBoardFormatSize ?? false

            //We have a new layer here
            const layer = parseLayersFromHtml(getAssetMacroHtml(payload.asset))[0];
            const layers = parseLayersFromHtml(state.html);

            const layerIndex = layers.findIndex(l => l.id === replaceId)

            const originalLayerClasses = layers[layerIndex] ? layers[layerIndex].classes : []

            if (originalLayerClasses.length) {
                layer.classes = originalLayerClasses
            }

            if (!state.editModeGlobal && replaceOnlyForThisBoardFormatSize) {
                //We are only doing this layer, basically, add it as a new layer just above
                //the existing one
                layers.splice((layerIndex + 1), 0, layer)
            }
            else {
                //We are replaceing this layer everwhere
                //So, just update the index with the new layer
                layers[layerIndex] = layer
            }

            //This is the original global css for the layer we are replacing
            const originalGlobalCss = getCSSStyleForId(replaceId, state.css)

            var newGlobalLayerCss = originalGlobalCss

            //We need to make it hidden on the global css, if we are only replacing it for this layer
            if (!state.editModeGlobal && replaceOnlyForThisBoardFormatSize) {
                newGlobalLayerCss = getNewStyleStringForString(newGlobalLayerCss, [
                    ["visibility", "hidden"]
                ])
            }

            const newGlobalCss = replaceCSSStyleForId(
                getIdFromAsset(payload.asset),
                newGlobalLayerCss,
                state.css)

            if (state.editModeGlobal) {
                return Object.assign({},
                    state,
                    {
                        assets: [...state.assets, payload.asset],
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        layers
                    });
            }
            else {

                const sizeVariants = state.sizeVariantsCSS ?? {}

                const sizeVariantIds = Object.keys(sizeVariants) ?? []

                sizeVariantIds.forEach((sizeVariantId) => {

                    const isCurrentFrame = state.frameIdSelected === sizeVariantId

                    const sizeVariantCss = sizeVariants[sizeVariantId].css ?? ""

                    const originalCss = getCSSStyleForId(replaceId, sizeVariantCss)

                    const showLayer = (
                        (replaceOnlyForThisBoardFormatSize && isCurrentFrame)
                        || (!replaceOnlyForThisBoardFormatSize)
                    )

                    const newLayerCss = getNewStyleStringForString(originalCss, [
                        ["visibility", showLayer ? "visible" : "hidden"]
                    ])

                    if (replaceOnlyForThisBoardFormatSize) {
                        if (isCurrentFrame) {

                            //Make the css for replaceId visibility:hidden
                            //Add in the new css for the new asset

                            const originalLayerCss = getNewStyleStringForString(originalCss, [
                                ["visibility", "hidden"]
                            ])

                            var newCurrentFrameCss = replaceCSSStyleForId(
                                replaceId,
                                originalLayerCss,
                                sizeVariantCss,
                            )

                            newCurrentFrameCss = replaceCSSStyleForId(
                                getIdFromAsset(payload.asset),
                                newLayerCss,
                                newCurrentFrameCss,
                            )

                            sizeVariants[sizeVariantId].css = newCurrentFrameCss

                        }
                        else {
                            const newFrameCss = replaceCSSStyleForId(
                                getIdFromAsset(payload.asset),
                                newLayerCss,
                                sizeVariantCss,
                            )

                            sizeVariants[sizeVariantId].css = newFrameCss

                        }
                    }
                    else {

                        const newCss = replaceCSSStyleForId(
                            getIdFromAsset(payload.asset),
                            originalCss,
                            sizeVariantCss,
                        )

                        //just replace it
                        sizeVariants[sizeVariantId].css = newCss
                    }

                })

                return Object.assign({},
                    state,
                    {
                        assets: [...state.assets, payload.asset],
                        html: serializeLayersToHtml(layers),
                        css: newGlobalCss,
                        usedMacros: getUsedMacroses(layers),
                        sizeVariantsCSS: sizeVariants,
                        dirty: true
                    });

            }

        }

        case DESIGNER_DEL_ASSET: {

            const layerId = Designer.AssetElements.img.id_prefix + payload.assetId

            const newHtml = replaceHtmlForId(layerId, "", state.html)
            const newCss = deleteCSSId(layerId, state.css)

            const sizeVariants = state.sizeVariantsCSS ?? {}

            //THis part remvoes the css from all size variants
            const sizeVariantIds = Object.keys(sizeVariants) ?? []

            sizeVariantIds.forEach((sizeVariant) => {
                const newCss = deleteCSSId(layerId, sizeVariants[sizeVariant].css)
                sizeVariants[sizeVariant].css = newCss
            })

            const newAssets = state.assets.filter(a => a.image.id !== payload.assetId);

            return Object.assign({},
                state,
                {
                    html: newHtml,
                    assets: newAssets,
                    css: newCss,
                    sizeVariantsCSS: sizeVariants,
                    layers: state.layers.filter((layer) => layer.id !== payload.id),
                    usedMacros: getListOfUsedMacros(
                        { designer: state },
                        newHtml + newCss + state.js,
                        newAssets),
                    dirty: true
                });

        }

        case DESIGNER_FONTS: {
            return Object.assign({},
                state,
                {
                    fontList: payload.fonts
                });
        }
        case DESIGNER_ADD_FONT: {

            const fontExists = state.fontList.some(el => el.id === payload.font.id)

            const newFonts = fontExists ? state.fontList : [...state.fontList, payload.font]

            return Object.assign({},
                state,
                {
                    fontList: newFonts
                });

        }
        case DESGINER_SET_ITEM_CLASS: {
            return Object.assign({},
                state,
                {
                    inventory_item_class: Array.isArray(payload.inventory_item_class)
                        ? payload.inventory_item_class
                        : [payload.inventory_item_class]
                });
        }
        case DESIGNER_ADD_ITEM_CLASS: {
            return Object.assign({},
                state,
                {
                    inventory_item_class: [...state.inventory_item_class, payload.inventory_item_class]
                });
        }

        case DESIGNER_REMOVE_ITEM_CLASS:
            return Object.assign({},
                state,
                {
                    inventory_item_class: state.inventory_item_class.filter(a => a !== payload.inventory_item_class),
                });

        case DESIGNER_ADD_BOARD_FORMAT: {

            if (state.editModeGlobal) {
                return Object.assign({},
                    state,
                    {
                        templateBoardFormats: [...state.templateBoardFormats, payload.boardFormat]
                    });
            }
            else {
                const newSv = {}

                const lastSizeVariant = Object.keys(state.sizeVariantsCSS).pop()

                const newCss = lastSizeVariant ? state.sizeVariantsCSS[lastSizeVariant].css : state.css

                newSv[payload.boardFormat] = {
                    css: newCss
                }

                const newSizeVariants = {
                    ...state.sizeVariantsCSS,
                    ...newSv
                }

                return Object.assign({},
                    state,
                    {
                        templateBoardFormats: [...state.templateBoardFormats, payload.boardFormat],
                        sizeVariantsCSS: newSizeVariants
                    });
            }

        }

        case DESIGNER_REMOVE_BOARD_FORMAT:
            return Object.assign({},
                state,
                {
                    templateBoardFormats: state.templateBoardFormats.filter(a => a !== payload.boardFormat),
                });

        case DESIGNER_SET_SELECTED_EDITOR_ID:

            if (payload.id == state.editorSelectedId && payload.source == state.editorSelectedIdSource)
                return state;

            return Object.assign({},
                state,
                {
                    editorSelectedId: payload.id || Designer.BackgroundLayerId,
                    editorSelectedIdSource: payload.source
                });

        case DESIGNER_SET_SELECTED_FRAME_ID:

            if (payload.id == state.frameIdSelected)
                return state;

            return Object.assign({},
                state,
                {
                    frameIdSelected: payload.id,
                });

        case DESIGNER_ACCOUNT_CHANGE:

            return Object.assign({},
                state,
                {
                    previewAccount: payload.account
                });

        case DESIGNER_BOARD_CHANGE:

            return Object.assign({},
                state,
                {
                    previewBoard: payload.digital_board
                });

        case DESIGNER_ITEM_CLASS_CHANGE:

            return Object.assign({},
                state,
                {
                    itemClass: payload.itemClass
                });

        case DESIGNER_ITEM_CHANGE:

            return Object.assign({},
                state,
                {
                    previewInventoryItem: {
                        ...payload.inventoryItem
                    }
                });

        case DESIGNER_ITEM_USER_CHANGE:

            return Object.assign({},
                state,
                {
                    previewInventoryItemUser: payload.user,
                    previewInventoryItem: {
                        ...state.previewInventoryItem,
                        user: payload.user ?? state.previewInventoryItem.user
                    }
                });

        case DESIGNER_PREVIEW_SET_ASPECT_RATIO:

            return Object.assign({},
                state,
                {
                    previewAspectRatio: payload.aspectRatio
                });

        case DESIGNER_EDIT_MODE_GLOBAL_CHANGE:

            return Object.assign({},
                state,
                {
                    editModeGlobal: payload.editModeGlobal
                });

        case DESIGNER_DRAGGING_IS_HAPPENING:
            return Object.assign({},
                state,
                {
                    draggingIsHappening: true
                });

        case DESIGNER_DRAGGING_IS_DONE:
            return Object.assign({},
                state,
                {
                    draggingIsHappening: false
                });
        case DESIGNER_SET_SNAPPING:
            return Object.assign({},
                state,
                {
                    enableSnapping: payload.enableSnapping
                });

        case DESIGNER_SET_ZOOM:
            return Object.assign({},
                state,
                {
                    zoom: action.zoom
                });
        case DESIGNER_SET_FIELD_MAP:
            return Object.assign({},
                state,
                {
                    fieldMap: action.fieldMap,
                    fields: [...Object.values(action.fieldMap)
                        .reduce((prev, curr) => {
                            if (curr.field_map) {
                                return [...prev, ...Object.values(curr.field_map)
                                    .reduce((prev, curr) => ([...prev, ...curr.fields]), [])]
                            }

                            return prev;
                        }, []),
                    ...state.customFieldMap],
                });

        case DESIGNER_ADD_CUSTOM_FIELD:
            return Object.assign({},
                state,
                {
                    customFieldMap: [...state.customFieldMap, payload.customField],
                    fields: [...state.fields, payload.customField]
                });

        case DESIGNER_UPDATE_CUSTOM_FIELD: {

            //Combine the incoming params of the custom field with the existing custom field

            const newCustomField = {
                ...state.customFieldMap.find(a => a.id === payload.customField.id),
                ...payload.customField
            }

            return Object.assign({},
                state,
                {
                    customFieldMap: state.customFieldMap.map(a => a.id === payload.customField.id ? newCustomField : a),
                    fields: state.fields.map(a => a.id === payload.customField.id ? newCustomField : a)
                });
        }
        case DESIGNER_REMOVE_CUSTOM_FIELD:
            return Object.assign({},
                state,
                {
                    customFieldMap: state.customFieldMap.filter(a => a.id !== payload.customFieldId),
                    fields: state.fields.filter(a => a.id !== payload.customFieldId)
                });

        default:
            return state
    }
}
