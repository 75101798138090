import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingPlans } from '../../actions/billing';
import { objectSelector } from '../../selectors/objects';
import BillingPlanStatusBadge from './BillingPlanStatusBadge';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import moment from 'moment';
import LCTypography from '../../components/material/LCTypography';
import { formatNumber } from '../../helpers/string';

function BillingPlans(props) {
    const { lcuid } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const billing = useSelector(state => objectSelector(state)(lcuid));
    const plans = useMemo(() => billing.plans, [billing])

    useEffect(() => {
        setLoading(true);
        dispatch(getBillingPlans(lcuid))
            .finally(() => setLoading(false));
    }, [])

    return <>
        {loading && <CircularProgressCentered size={40} />}
        {!loading && plans.length === 0 && <Box p={4}>
            <Typography>{t('There are no current billing plans configured for this account')}</Typography>
        </Box>}

        {!loading && <Grid container spacing={1}>
            {plans.map(plan => <Grid key={plan.lcuid} item xs={12} sm={4} md={3}>
                <Card>
                    <CardContent style={{ paddingBottom: 16 }}>
                        <Box mb={1} display="flex" alignItems="center">
                            <Typography noWrap>
                                {plan.name}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <LCTypography color="textSecondary" variant="body2">
                                {moment(plan.first_billing_start_date).isSame(moment(), 'year')
                                    ? moment(plan.first_billing_start_date).format('MMM DD')
                                    : moment(plan.first_billing_start_date).format('MMM DD, yyyy')}
                                &nbsp;&mdash;&nbsp;
                                {plan.billing_end_date
                                    ? moment(plan.billing_end_date).isSame(moment(), 'year')
                                        ? moment(plan.billing_end_date).format('MMM DD')
                                        : moment(plan.billing_end_date).format('MMM DD, yyyy')
                                    : 'Recurring'}
                            </LCTypography>
                            <Typography style={{ fontWeight: 500 }}>
                                {plan.billing_plan_data
                                    && plan.billing_plan_data.form_param_values
                                    && formatNumber(plan.billing_plan_data.form_param_values.monthly_amount,
                                        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
                            </Typography>
                        </Box>
                        <Box mt={1} display="flex">
                            <BillingPlanStatusBadge status={plan.status} />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>)}
        </Grid>}
    </>
}

BillingPlans.propTypes = {
    lcuid: PropTypes.string.isRequired
}
export default BillingPlans;
