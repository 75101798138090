import { useEffect, useState } from 'react';
/**
 * useKeyPress
 * @param {string} container - the HTML object to we should watch scroll
 */
export function useScrollTop(container) {
    const [scrollTop, setScrollTop] = useState(container && container.scrollTop)

    useEffect(() => {
        function onScroll() {
            setScrollTop(container.scrollTop);
        }

        if (container) {
            container.addEventListener('scroll', onScroll);
        }

        return () => {
            if (container)
                container.removeEventListener('scroll', onScroll)
        };
    }, [container]);

    return scrollTop;
}