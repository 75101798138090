import React from 'react';
import { InventoryExportCampaignClasses } from '../../helpers/constants';
import AddRowsButton from './AddRowsButton';
import { useTranslation } from 'react-i18next';
import { CampaignGroupIcon, ExportIcon } from '../../components/icons';
import { IconFromSet } from '../../components/IconFromSet';

const AddCampaignsButton = ({ campaignsOptions = [], campaigns, onChange }) => {
    const { t } = useTranslation();
    return <AddRowsButton
        options={campaignsOptions.map(e => ({
            ...e,
            description: e.campaign_class !== InventoryExportCampaignClasses.group.class
                && e.account.name,
            Icon: e.campaign_class === InventoryExportCampaignClasses.group.class
                ? <IconFromSet
                    iconKey={e?.options?.ui_options?.layout?.icon}
                    FallbackIcon={CampaignGroupIcon} />
                : <ExportIcon />
        }))}
        value={campaigns}
        onChange={onChange}
        buttonTitle={t('Add Campaigns')}
        noOptionsText={t('No campaigns found for your search')}
    />
}

export { AddCampaignsButton };
