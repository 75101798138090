import { Box, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    deleteLayer, derivePhotoCropAspectRatio, duplicateLayer, getFieldMap, layerAddClass, layerRemoveClass, layerSetCssForKey,
    loadAllBoardFormats, loadBoardFormats, moveElementLayerInHtml, pasteText, replaceCSSForSelectedId,
    replaceInnerHtmlForSelectedId, setSelectedEditor, setSelectedFrame, setTemplateName, updateTemplate, showHideCodeEditor
} from '../../actions/designer';
import { Designer } from '../../helpers/constants';
import {
    allowEdit, codeEditorVisible, css, designer, editModeGlobal, editorSelectedId,
    editorSelectedIdSource, frameIdSelected, getNewStyleStringForId,
    html, templateName, variants, sizeVariantsCSS
} from '../../selectors/designer';
import { selectedAccount, selectedAgency } from '../../selectors/user';
import BoardFormatsTabs from './BoardFormatsTabs';
import GlobalCodeEditDialog from './GlobalCodeEditDialog';
import DesignerAutosave from './DesignerAutosave';
import DesignerBoardFormatTool from './DesignerBoardFormatTool';
import { DesignerFonts } from './DesignerFonts';
import DesignerLayerSettingsDialog from './DesignerLayerSettingsDialog';
import DesignerLayerTool from './DesignerLayerTool';
import { DesignerElements } from './DesignerElements';
import DesignerObjectEditor from './DesignerObjectEditor';
import DesignerPreviewTemplate from './DesignerPreview';
import DesignerToolbar from './DesignerToolbar';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { makeStyles } from '@material-ui/styles';
import { DESIGNER_FORMAT_COPY, DESIGNER_FORMAT_PASTE, DESIGNER_PUSH_HISTORY, DESIGNER_SET_ZOOM } from '../../helpers/actionTypes';
import { PageTitle } from '../../components';
import { CssEditorDialog } from './CssEditorDialog';
import { PasteFormatDialog } from './PasteFormatDialog';
import DesignerTemplatePreviewImage from './DesignerTemplatePreviewImage';
import DesignerBackgroundEditor from './DesignerBackgroundEditor';
import { isPlatform } from '@ionic/react';
import { DesignerChooseImageTypeDialog } from './DesignerChooseImageTypeDialog';
import { deriveSettingsFromLayer, getHtmlLayerForId } from '../../selectors/designerLayers';
import UploadAndReplaceImage from '../../components/inputs/UploadAndReplaceImage';
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(() => {
    return {
        root: {
            '*::-webkit-scrollbar': {
                width: isPlatform('desktop') ? '6px' : '4px',
                height: isPlatform('desktop') ? '6px' : '4px'
            },
        },
        titleEditField: {
            maxWidth: '100%'
        },
        templateImage: {
            width: "110px",
            marginTop: "5px",
            cursor: "pointer",
        }
    }
})

const DesignerHome = ({
    templateName, selectedAgency, selectedAccount, replaceCSSForSelectedId,
    editModeGlobal, loadBoardFormats, loadAllBoardFormats, derivePhotoCropAspectRatio,
    setSelectedEditor, setSelectedFrame, getNewStyleStringForId, codeEditorVisible, deleteLayer,
    editorSelectedId, editorSelectedIdSource, getHtmlLayerForId, moveElementLayerInHtml,
    frameIdSelected, html, css, variants, replaceInnerHtmlForSelectedId, duplicateLayer, sizeVariantsCSS,
    setTemplateName, layerAddClass, layerRemoveClass, layerSetCssForKey, pasteText, showHideCodeEditor,
    templateIsLoading
}) => {

    const [showLayerSettings, setShowLayerSettings] = useState(false);
    const [showAdvancedEdit, setShowAdvancedEdit] = useState(false);
    const [showPasteFormatDialog, setShowPasteFormatDialog] = useState(false);
    const [showReplaceImageDialog, setShowReplaceImageDialog] = useState(null);
    const [pasteImages, setPasteImages] = useState(null);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const lcuid = useSelector(state => state.designer.lcuid);

    const [fieldMapLoading, setFieldMapLoading] = useState(true);
    const [boardFormatsLoading, setBoardFormatsLoading] = useState(true);
    const keyValueDataLoading = useSelector(state => state.designer.accountKeyValueStoreLoading);

    const [showLoadingBar, setShowLoadingBar] = useState(false);

    const bestAgencyId = selectedAgency ? selectedAgency.id : selectedAccount ? selectedAccount.agency_id : null;

    const { t } = useTranslation();

    useEffect(() => {
        setFieldMapLoading(true);
        dispatch(getFieldMap(lcuid)).then(() => {
            setFieldMapLoading(false);
        });
    }, [lcuid, state.designer.inventory_item_class])

    //List of board formats that belong to this operator
    useEffect(() => {
        setBoardFormatsLoading(true);
        if (bestAgencyId)
            loadBoardFormats(bestAgencyId).then(() => {
                setBoardFormatsLoading(false);
            });
        else
            loadAllBoardFormats().then(() => {
                setBoardFormatsLoading(false);
            });
    }, [selectedAgency, selectedAccount, templateName])

    useEffect(() => {

        if (templateIsLoading || keyValueDataLoading || fieldMapLoading || boardFormatsLoading || !frameIdSelected) {
            setShowLoadingBar(true);
        } else {
            setShowLoadingBar(false);
        }

    }, [templateIsLoading, fieldMapLoading, boardFormatsLoading, frameIdSelected, keyValueDataLoading])

    //Listen to messages from the iframes containing the previews
    //It is important that any state vars required by the handler are listed
    //in the effect dependencies array.
    useEffect(() => {
        const windowMessageListener = (event) => {
            if (!event.data || !event.data.action || !event.data.data)
                return;

            handleDTIframeEvent(event.data)
        }

        window.addEventListener("message", windowMessageListener);
        return () => {
            window.removeEventListener('message', windowMessageListener);
        };
        //REMEMBER - Put any state vars here that the event listener will need access to
    }, [
        editorSelectedId, frameIdSelected, editModeGlobal, html, css,
        variants, getNewStyleStringForId, replaceCSSForSelectedId, sizeVariantsCSS
    ])

    const handleDTIframeEvent = (eventData) => {

        switch (eventData.action) {
            case Designer.Messages.ElementSelected:
                dtElementSelected(eventData.data.id, eventData.frame_id)
                break;
            case Designer.Messages.ElementDragResize:
                dtMoveIdToTopLeftInCss(
                    eventData.data.id,
                    eventData.data.top_pct,
                    eventData.data.left_pct,
                    eventData.data.width_pct,
                    eventData.data.height_pct,
                    eventData.data.rotate,
                );
                if (eventData.data.can_be_text_edited)
                    replaceInnerHtmlForSelectedId(eventData.data.content)

                derivePhotoCropAspectRatio()
                dispatch({ type: DESIGNER_PUSH_HISTORY })
                break;
            case Designer.Messages.ElementDeleted: {

                //This section will just hide an element
                //if we are in individual edit mode
                if (editModeGlobal)
                    deleteLayer(eventData.data.id)
                else
                    layerSetCssForKey(eventData.data.id, "visibility", "hidden")

                setSelectedEditor(null)

                break;
            }
            case Designer.Messages.ElementHide:
                layerSetCssForKey(eventData.data.id, "visibility", "hidden")
                break;
            case Designer.Messages.ElementUnHide:
                layerSetCssForKey(eventData.data.id, "visibility", "visible")
                break;
            case Designer.Messages.ElementLock:
                layerRemoveClass(eventData.data.id, "lc_ut_designer")
                break;
            case Designer.Messages.ElementUnlock:
                layerAddClass(eventData.data.id, "lc_ut_designer")
                break;
            case Designer.Messages.ElementOpenSettings:
                setShowLayerSettings(eventData.data.id)
                break;
            case Designer.Messages.ElementOpenEdit:
                setShowAdvancedEdit(true)
                break;
            case Designer.Messages.ElementMoveLayer:
                moveElementLayerInHtml(eventData.data.id, eventData.data.direction)
                break;
            case Designer.Messages.ElementDuplicateLayer:
                duplicateLayer(eventData.data.id)
                break;
            case Designer.Messages.FormatCopy:
                dispatch({ type: DESIGNER_FORMAT_COPY, id: eventData.data.id })
                break;
            case Designer.Messages.ZoomInOut:
                dispatch({ type: DESIGNER_SET_ZOOM, zoom: eventData.data.zoom })
                break;
            case Designer.Messages.FormatPaste:
                if (editModeGlobal) {
                    dispatch({ type: DESIGNER_FORMAT_PASTE, id: eventData.data.id })
                    dispatch({ type: DESIGNER_PUSH_HISTORY });
                }
                else {
                    setShowPasteFormatDialog(true)
                }

                break;
            case Designer.Messages.PasteEvent: {

                switch (eventData.data.paste_object_type) {

                    case Designer.PasteObjectTypes.LucitObject: {
                        eventData.data.ids.forEach(id => {
                            duplicateLayer(id)
                        })
                        break;
                    }

                    case Designer.PasteObjectTypes.Text: {
                        pasteText(eventData.data.content)
                        break;
                    }

                    case Designer.PasteObjectTypes.Image: {
                        setPasteImages([{
                            fileBase64: eventData.data.blob,
                            original_file_name: eventData.data.original_file_name,
                            isBlob: true
                        }])
                        break;
                    }
                }
                break;
            }

            case Designer.Messages.RequestToChangeImage: {
                setShowReplaceImageDialog(null) //Because of file input cancel button issue
                setShowReplaceImageDialog(eventData.data.id)
                break;
            }
            default:
                console.error("UNKNOWN event from preview iframe", eventData)
        }
    }

    const dtElementSelected = (id, frameId) => {
        setSelectedEditor(id)
        setSelectedFrame(frameId)
    }

    const dtMoveIdToTopLeftInCss = (id, top, left, width, height, rotate) => {
        const newStyle = getNewStyleStringForId(id, [
            ["top", top + "%"],
            ["left", left + "%"],
            ["width", width + "%"],
            ["height", height + "%"],
            ["rotate", rotate]
        ])
        replaceCSSForSelectedId(newStyle)
    }

    const [selectedBoardFormat, setSelectedBoardFormat] = useState(null);
    const [editorTab, selectEditorTab] = useState(2);

    useEffect(() => {
        if (editorSelectedId == Designer.BackgroundLayerId) {
            // Auto select Canvas tab when BackgroundLayerId is selected to edit
            selectEditorTab(1)
        } else if (editorSelectedIdSource !== 'layer') {
            // Select 'Edit' tab, except a case when element was selected from 'layer' tab.
            selectEditorTab(0)
        }
    }, [editorSelectedId, editorSelectedIdSource])

    useEffect(() => {
        if (selectedBoardFormat)
            setSelectedFrame(selectedBoardFormat.code)

    }, [selectedBoardFormat])

    const classes = useStyles();

    const textEditableProps = {
        editButtonProps: { size: 'small' },
        doneButtonProps: { size: 'small' },
        cancelProps: { size: 'small' },
        editIconProps: { fontSize: 'small' },
        doneIconProps: { fontSize: 'small' },
        cancelIconProps: { fontSize: 'small' },
        circularProgressProps: { size: 24 },
        size: "small"
    };

    return (
        <Box ml={2} mr={2} mt={2} className={classes.root}>
            <PageTitle prefix={``} title={templateName ? templateName : "Template Designer"} />

            <DesignerAutosave />
            <DesignerFonts />

            <Grid container>
                <Grid item xs={1}>
                    <DesignerTemplatePreviewImage />
                </Grid>
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                    <Box mr={1} ml={2}>
                        <TextFieldEditable
                            ViewComponent={<Typography variant="h5">
                                {templateName}
                            </Typography>}
                            classes={{ root: classes.titleEditField }}
                            variant={"outlined"}
                            value={templateName || ""}
                            handleDone={name => {
                                setTemplateName(name);
                            }}
                            fullWidth={true}
                            autoHighlightOnFocus
                            {...textEditableProps}
                        />
                    </Box>
                </Grid>
                <Grid item xs={7} style={{ alignSelf: 'center' }}>
                    <DesignerToolbar />
                </Grid>
                <Grid item xs={12}>
                    {showLoadingBar && <Box style={{ width: "100%" }}><LinearProgress /></Box>}
                </Grid>
                <Grid item xs={12}>
                    <Box mt={1}>
                        <BoardFormatsTabs
                            value={selectedBoardFormat}
                            setValue={setSelectedBoardFormat}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box mt={2} mr={2} >
                        <Box component={Paper}>
                            <DesignerElements />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box mt={2}>
                        <DesignerPreviewTemplate
                            boardFormat={selectedBoardFormat}
                            constHeight={300}
                            bestAgencyId={bestAgencyId}
                        />
                    </Box>
                    {codeEditorVisible &&
                        <GlobalCodeEditDialog
                            open={codeEditorVisible}
                            handleClose={() => showHideCodeEditor(false)}
                        />
                    }
                </Grid>
                <Grid item xs={3}>
                    <Box mt={2} ml={2}>
                        <Box component={Paper}>
                            <Tabs value={editorTab} onChange={(_, e) => selectEditorTab(e)} style={{ minHeight: 32 }}>
                                <Tab value={0}
                                    disabled={editorSelectedId == Designer.BackgroundLayerId}
                                    label={t('Edit')} style={{ textTransform: 'none', minWidth: 'auto', minHeight: 32 }} />
                                <Tab value={1} label={t('Canvas')} style={{ textTransform: 'none', minWidth: 'auto', minHeight: 32 }} />
                                <Tab value={2} label={t('Layers')} style={{ textTransform: 'none', minWidth: 'auto', minHeight: 32 }} />
                                <Tab value={3} label={t('Screens')} style={{ textTransform: 'none', minWidth: 'auto', minHeight: 32 }} />
                            </Tabs>

                            {editorTab === 0
                                && <DesignerObjectEditor />}
                            {editorTab === 1
                                && <DesignerBackgroundEditor />}
                            {editorTab === 2
                                && <DesignerLayerTool />}
                            {editorTab === 3
                                && <DesignerBoardFormatTool />}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {showLayerSettings && <DesignerLayerSettingsDialog
                open={Boolean(showLayerSettings)}
                layerSettings={deriveSettingsFromLayer(state, getHtmlLayerForId(showLayerSettings))}
                handleClose={() => setShowLayerSettings(null)}
            />}
            {showAdvancedEdit && <CssEditorDialog
                open={showAdvancedEdit}
                handleClose={() => setShowAdvancedEdit(false)}
            />}

            {showPasteFormatDialog && <PasteFormatDialog
                open={showPasteFormatDialog}
                handleClose={() => {
                    setShowPasteFormatDialog(false)
                }}
            />}

            {showReplaceImageDialog && <UploadAndReplaceImage
                elementId={showReplaceImageDialog}
                handleClose={() => setShowReplaceImageDialog(null)}
            />}

            {pasteImages
                && <DesignerChooseImageTypeDialog
                    open={Boolean(pasteImages)}
                    handleClose={() => setPasteImages(null)}
                    images={pasteImages}
                />}
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        selectedAgency: selectedAgency(state),
        selectedAccount: selectedAccount(state),
        designer: designer(state),
        editModeGlobal: editModeGlobal(state),
        templateName: templateName(state),
        getNewStyleStringForId: (id, styles) => getNewStyleStringForId(state, id, styles),
        editorSelectedId: editorSelectedId(state),
        editorSelectedIdSource: editorSelectedIdSource(state),
        frameIdSelected: frameIdSelected(state),
        html: html(state),
        css: css(state),
        sizeVariantsCSS: sizeVariantsCSS(state),
        variants: variants(state),
        codeEditorVisible: codeEditorVisible(state),
        allowEdit: allowEdit(state),
        getHtmlLayerForId: (id) => getHtmlLayerForId(state, id),
        templateIsLoading: state.designer.templateIsLoading,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        moveElementLayerInHtml: (id, direction) => dispatch(moveElementLayerInHtml(id, direction)),
        loadBoardFormats: (agencyId) => dispatch(loadBoardFormats(agencyId)),
        loadAllBoardFormats: () => dispatch(loadAllBoardFormats()),
        setSelectedEditor: (editorSelectedId) => dispatch(setSelectedEditor(editorSelectedId, 'frame')),
        setSelectedFrame: (frameIdSelected) => dispatch(setSelectedFrame(frameIdSelected)),
        replaceCSSForSelectedId: (newStyle) => dispatch(replaceCSSForSelectedId(newStyle)),
        replaceInnerHtmlForSelectedId: (newHtml) => dispatch(replaceInnerHtmlForSelectedId(newHtml)),
        deleteLayer: (id) => dispatch(deleteLayer(id)),
        derivePhotoCropAspectRatio: () => dispatch(derivePhotoCropAspectRatio()),

        setTemplateName: (name) => dispatch(setTemplateName(name)),
        updateTemplate: () => dispatch(updateTemplate()),
        layerAddClass: (id, className) => dispatch(layerAddClass(id, className)),
        layerRemoveClass: (id, className) => dispatch(layerRemoveClass(id, className)),
        layerSetCssForKey: (id, key, value) => dispatch(layerSetCssForKey(id, key, value)),
        duplicateLayer: (id) => dispatch(duplicateLayer(id)),
        pasteText: (text) => dispatch(pasteText(text)),
        showHideCodeEditor: (visible) => dispatch(showHideCodeEditor(visible)),
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        DesignerHome
    );
