import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHookWithRefCallback } from '../../helpers/hooks';
import FileUploadInput from '../../components/inputs/FileUploadInput';
import { useDispatch, useSelector } from 'react-redux';
import { DESIGNER_REPLACE_ASSET } from '../../helpers/actionTypes';
import { lucitApi } from '../../services/lucitApi';
import { Box, Grid, RadioGroup, Tooltip, FormControlLabel, Radio, Typography, Button } from '@material-ui/core';
import { GenericDialog } from '../../components/modals/GenericDialog';
import ButtonLoader from '../../components/material/ButtonLoader';
import { useTranslation } from 'react-i18next';
import { selectedFrameIdName } from '../../selectors/designer';

const UploadAndReplaceImage = (props) => {
    const { elementId, handleClose } = props;

    const [inputRef, setInputRef] = useHookWithRefCallback(null);

    const [imageFile, setImageFile] = useState(null);
    const [imageFileBase64, setImageFileBase64] = useState(null);

    //radios do not like true/false I guess
    const [replaceOnlyForThisBoardFormatSize, setReplaceOnlyForThisBoardFormatSize] = useState("all_sizes");
    const editModeGlobal = useSelector(state => state.designer.editModeGlobal);
    const currentFrameName = useSelector(selectedFrameIdName);

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        inputRef && inputRef.click();
    }, [inputRef]);

    return <>
        <GenericDialog
            title={t('Replace Image')}
            dialogProps={{
                open: elementId ? true : false,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            ContentComponent={<Box pt={2} pb={2}>

                <FileUploadInput
                    ref={setInputRef}
                    id="uploadAndRFeplaceImage"
                    extensions={["jpg", "jpeg", "png"]}
                    onSuccess={({ file, fileBase64 }) => {

                        setImageFileBase64(fileBase64);
                        setImageFile(file);
                    }}
                    onError={errors => {
                        console.log(errors);
                        handleClose();
                    }}>
                </FileUploadInput>

                <Grid container spacing={4}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        {!imageFile && <Button
                            onClick={() => inputRef && inputRef.click()}
                            variant="outlined"
                            color="primary"
                        >{t('Choose Image')}</Button>}
                        <img src={imageFileBase64} style={{ height: 160 }} />
                    </Grid>
                </Grid>

                {!editModeGlobal && <Grid item xs={12}>

                    <Box mt={4}>

                        <RadioGroup
                            value={replaceOnlyForThisBoardFormatSize}
                            onChange={e => setReplaceOnlyForThisBoardFormatSize(e.target.value)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={4}>

                                    <Tooltip
                                        title={t('Replace this image across all sizes')}
                                    >
                                        <FormControlLabel value={"all_sizes"} control={<Radio size="small" />}
                                            label={<Typography variant='body2'>{t('All screen sizes')}</Typography>} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={8}>

                                    <Tooltip
                                        title={t('Replace this image on the ' + currentFrameName + ' size only')}
                                    >
                                        <FormControlLabel value={"this_format_size"} control={<Radio size="small" />}
                                            label={<Typography variant='body2'>
                                                {t('Replace this image on the ')}<strong>{currentFrameName}</strong>{t(' size only')}
                                            </Typography>}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Box>
                </Grid>}
            </Box>}
            ActionsComponent={<>
                <Button onClick={handleClose}
                    color="primary">
                    {t('Cancel')}
                </Button>

                <ButtonLoader
                    onClick={() => {

                        setLoading(true);

                        lucitApi.uploads.uploadImageFile(imageFile).then(response => {

                            const asset = {
                                id: response.id,
                                image: response,
                                original_file_name: imageFile.name
                            }

                            dispatch({
                                type: DESIGNER_REPLACE_ASSET,
                                replaceId: elementId,
                                replaceOnlyForThisBoardFormatSize: replaceOnlyForThisBoardFormatSize === "this_format_size",
                                asset,
                            })

                            setLoading(false);
                        }).catch(() => {
                            alert(t('There was an error uploading your image.  ' +
                                'It may be too large or some other issue has occurred.  Please try again.'))
                        });

                        handleClose();

                    }}
                    color="secondary"
                    variant="contained"
                    disabled={!imageFile}
                    submitting={loading}
                >
                    {t('OK')}
                </ButtonLoader>
            </>}
        />
    </>
};

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadAndReplaceImage);
