import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Paper, makeStyles, Box, Link } from '@material-ui/core';

import { withHomeLayout } from '../../layouts/HomeLayout';
import { history } from '../../helpers/history';
import { getPhoto } from '../../actions/inventory';
import InventoryPhoto from '../../containers/inventoryPhoto/InventoryPhoto';
import { selectedAccountInventoryClasses } from '../../selectors/user';
import { AnalyticsObject, Timeframe } from '../../helpers/constants';
import PlaysStatsByBillboard from '../../containers/analytics/PlaysStatsByBillboard';
import { PageTitle } from '../../components';
import DeveloperObjectTools from '../../containers/settings/DeveloperObjectTools';

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: theme.spacing(3, 2, 3, 2)
        },
        back: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem'
        },
        backIcon: {
            marginRight: theme.spacing(1)
        }
    }
})

function InventoryItemPhoto(props) {
    const { getPhoto, match, accountInventoryClass } = props;

    const classes = useStyles();
    const [photo, setPhoto] = useState(null);

    const photoId = match.params.id;

    useEffect(() => {
        getPhoto(photoId)
            .then(setPhoto);
    }, [getPhoto, photoId])

    const analyticFilters = useMemo(() => ({
        object_id: photo && photo.id,
        object_class: AnalyticsObject.inventoryPhoto
    }), [photo]);

    return <>
        <PageTitle title={`Creative ${photoId}`} />
        <Paper className={classes.root}>
            <Box mb={3}>
                <Link
                    component="button"
                    onClick={() => history.length
                        ? history.goBack()
                        : history.push({ pathname: accountInventoryClass.route })}>
                    <Box className={classes.back}>
                        <ArrowBackIcon className={classes.backIcon} />
                        Back
                    </Box>
                </Link>
            </Box>

            {photo
                && <>
                    <InventoryPhoto photo={photo} />

                    <Box mt={3}>
                        <PlaysStatsByBillboard
                            timeframe={Timeframe.all}
                            filters={analyticFilters}
                            hideFilters />
                    </Box>

                    <DeveloperObjectTools object={photo} />

                </>}
        </Paper>
    </>;
}

const mapStateToProps = state => {
    return {
        accountInventoryClass: selectedAccountInventoryClasses(state)[0]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPhoto: id => dispatch(getPhoto(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withHomeLayout(InventoryItemPhoto)
    )
);
