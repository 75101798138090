import { Box, Button, Grid, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LCTypography from '../../components/material/LCTypography';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { hasCreativeInventoryClass, currentUserHasPermission } from '../../selectors/user';
import NewCreativeDialog from '../inventory/NewCreativeDialog';
import TemplatePhotoStreamDialog from '../inventory/TemplatePhotoStreamDialog';
import { DesignerTemplateList } from '../designer/DesignerTemplateList';
import { CustomCard } from '../../components';
import { hasExports } from '../../selectors/exports';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InventoryItemClasses, RoutePaths } from '../../helpers/constants';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { useWidth } from '../../helpers/hooks';

const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            padding: theme.spacing(3)
        },

        cardWrapper: {
            boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            padding: '16px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(222, 222, 222, 0.7)'
        },

        existingCreativeBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100px',
            padding: '0 16px',
            textAlign: 'center',
            fontSize: '1.3em',
            marginTop: "3px",
            border: "1px solid #808080",
            borderRadius: "5px",
            backgroundColor: "#fafafa"
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const TemplatePostDialog = (props) => {
    const { open, handleClose, defaultSelectedExportId } = props;
    const width = useWidth();
    const [selectedExportId, setSelectedExportId] = useState(defaultSelectedExportId);
    const [postType, setPostType] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isNewCreativeDialogOpen, setIsNewCreativeDialogOpen] = useState(false);
    const [isPhotoStreamDialogOpen, setIsPhotoStreamDialogOpen] = useState(false);
    const hasCreativeClass = useSelector(state => hasCreativeInventoryClass(state) && hasExports(state))

    const canPostFullSizeCreatives = useSelector(state => currentUserHasPermission(state)(AccountPolicy.createFullSizeCreatives))

    const [stepNumber, setStepNumber] = useState(0);
    const steps = useMemo(() => (hasCreativeClass && canPostFullSizeCreatives
        ? [{
            id: 0,
            title: "What do you want to post?",
            disabled: postType == null,
            onNext: () => postType == 'Creative'
                ? setIsNewCreativeDialogOpen(true)
                : setStepNumber(x => x + 1)
        },
        {
            id: 1,
            title: "Select Template",
            disabled: selectedTemplate == null,
            onNext: () => setIsPhotoStreamDialogOpen(true)
        }]
        : [{
            id: 1,
            title: "Select Template to post Ad",
            disabled: selectedTemplate == null,
            onNext: () => setIsPhotoStreamDialogOpen(true)
        }]), [hasCreativeClass, postType, selectedTemplate]);
    const step = steps[stepNumber];

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedExportId(defaultSelectedExportId);
    }, [defaultSelectedExportId])

    return (<>
        <GenericDialog
            title={step.title}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'lg'
            }}
            dialogContentProps={{ className: classes.dialogContent }}
            ContentComponent={<>
                {step.id == 0
                    && <PostTypeSelector
                        postType={postType}
                        setPostType={setPostType}
                        selectedExportId={selectedExportId}
                        setSelectedExportId={setSelectedExportId}
                    />}
                {step.id == 1
                    && <TemplateSelector
                        width={width}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                    />}
            </>}
            ActionsComponent={<>
                {stepNumber == 0
                    ? <Button onClick={handleClose}
                        color="primary">
                        {t('Cancel')}
                    </Button>
                    : <Button onClick={() => setStepNumber(x => x - 1)}
                        color="primary">
                        {t('Back')}
                    </Button>}
                <Button disabled={step.disabled}
                    color="secondary"
                    variant="contained"
                    onClick={step.onNext}>
                    {t('Next')}
                </Button>
            </>}
        />
        <TemplatePhotoStreamDialog
            open={isPhotoStreamDialogOpen}
            selectedTemplateLcuid={selectedTemplate}
            selectedExportId={selectedExportId}
            handleClose={() => setIsPhotoStreamDialogOpen(null)}
        />
        <NewCreativeDialog
            open={isNewCreativeDialogOpen}
            selectedExportId={selectedExportId}
            autoActivateCreative={true}
            handleClose={() => setIsNewCreativeDialogOpen(false)} />
    </>
    )
}

const PostTypeSelector = ({
    postType, setPostType,
    selectedExportId, setSelectedExportId
}) => {
    const { t } = useTranslation();
    const exports = useSelector(state => state.exports.data)
    const hasMultipleExports = exports.length > 1;

    const types = useMemo(() => ([
        {
            id: 'Creative',
            title: t('Complete Creative'),
            description: t('Upload a fully designed ad that is ready to run on your screens'),
            imageUrl: '/assets/images/studio/static_template_icon.png',
        },
        {
            id: 'Dynamic Template',
            title: t('Dynamic Template'),
            imageUrl: '/assets/images/studio/generic_template_icon.png',
            description: t('Post an ad by filling out the fields from one of your existing dynamic templates')
        }
    ]), []);

    return <Box>
        <Grid container spacing={1}>
            {types.map(x => <Grid key={x.title} item xs={12} md={6}>
                <CustomCard
                    title={x.title}
                    description={x.description}
                    imageUrl={x.imageUrl}
                    isSelected={x.id == postType}
                    select={() => setPostType(x.id)}
                />
            </Grid>)}
        </Grid>

        {
            hasMultipleExports
            && <Box mt={2}>
                <Box mb={1}>
                    <LCTypography variant="subtitle1">Select Campaign:</LCTypography>
                </Box>
                <Select
                    variant="outlined"
                    style={{ maxWidth: 600 }}
                    fullWidth
                    value={selectedExportId ?? ""}
                    displayEmpty
                    onChange={e => setSelectedExportId(e.target.value)}
                >
                    <MenuItem value={""}>{t('All Campaigns')}</MenuItem>
                    {exports.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                </Select>
            </Box>
        }
    </Box >
}

const TemplateSelector = ({ selectedTemplate, setSelectedTemplate, width = "lg" }) => {

    const history = useHistory();

    return <>
        <DesignerTemplateList
            filters={{
                inventory_item_class: [InventoryItemClasses.photoStream.class, InventoryItemClasses.creative.class].join(','),
            }}
            compact={true}
            width={width}
            onClick={x => setSelectedTemplate(x.lcuid)}
            onEmptyClick={() => {
                history.push(RoutePaths.designerTemplates);
            }}
            isSelected={x => x.lcuid == selectedTemplate}
        />
    </>
}

export default connect()(
    TemplatePostDialog
);
