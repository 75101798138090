import { lucitApi } from "../services/lucitApi";
import {
    APPROVALS_REJECTION_CODES_LOADED, APPROVALS_SAVE_SCROLL_POSITION,
    APPROVALS_NEXT_PAGE_RESULT, APPROVALS_NEXT_PAGE_REQUEST, APPROVALS_SEARCH_RESULT,
    APPROVALS_SEARCH_REQUEST, APPROVALS_TOGGLE_ACTION, APPROVALS_FORCE_APPROVE_MODERATION_LCUID,
    APPROVALS_STATUS_CHANGED
} from "../helpers/actionTypes";
import { approvalGroups, filters } from "../selectors/approvals";
import { ApprovalStatus } from "../helpers/constants";
import { selectedAgencyIds } from "../selectors/user";

export function getRejectionCodes() {
    return dispatch => {
        return lucitApi.campaigns.getApprovalRejectionCodes()
            .then(codes => dispatch({
                type: APPROVALS_REJECTION_CODES_LOADED,
                codes
            }));

    }
}

export function reject(inventoryItem) {
    return dispatch => {
        return dispatch({
            type: APPROVALS_TOGGLE_ACTION,
            inventoryItem,
            action: ApprovalStatus.rejected.id
        })
    }
}

export function approve(inventoryItem) {
    return dispatch => {
        return dispatch({
            type: APPROVALS_TOGGLE_ACTION,
            inventoryItem,
            action: ApprovalStatus.ok.id
        })
    }
}

export function forceApproveModeration(lcuid) {

    return (dispatch) => {

        lucitApi.campaigns.makeModerationGood(lcuid)

        return dispatch({
            type: APPROVALS_FORCE_APPROVE_MODERATION_LCUID,
            lcuid: lcuid
        })
    }
}

export function saveApprovalsStatus(approvalGroups, rejectionCode, notes) {
    return (dispatch, getState) => {
        const approvals = approvalGroups
            .reduce((result, group) => {
                return [
                    ...result,
                    ...group.approvals
                ]
            }, []);

        const actions = approvals.map(approval => ({
            creative_approval_id: approval.id,
            action: approval.action,
            rejection_code: rejectionCode,
            comments: notes
        }));

        const selectedAgencyId = selectedAgencyIds(getState())[0];

        return lucitApi.campaigns.setApprovalsActions(actions)
            .then(() => dispatch({
                type: APPROVALS_STATUS_CHANGED,
                actions,
                approvalGroups,
                agencyId: selectedAgencyId
            }));
    }
}

export function refresh() {
    return (dispatch, getState) => {
        const data = {
            ...filters(getState()),
            agencyId: selectedAgencyIds(getState())
        };

        return dispatch(search(data));
    }
}

export function search(data = {}) {
    return (dispatch, getState) => {
        data.perPage = data.perPage || 20;
        data.agencyId = selectedAgencyIds(getState());

        dispatch(approvalsSearchRequest(data));

        return lucitApi.campaigns.getApprovals(data)
            .then(result => dispatch(approvalsSearchResult({
                ...result,
                per_page: data.perPage,
                status: data.status
            })));
    }
}

export function loadPage(page) {
    return (dispatch, getState) => {
        const state = getState();
        const filtersData = filters(state);

        dispatch(approvalsNextPageRequest());

        const data = {
            page: page,

            ...filtersData
        };

        return lucitApi.campaigns.getApprovals(data)
            .then(result => dispatch(approvalsNextPageResult({
                ...result,
                per_page: filtersData.perPage,
                status: filtersData.status
            })))
    }
}

export function approvalsSearchRequest(filters) {
    return {
        type: APPROVALS_SEARCH_REQUEST,
        filters
    }
}

export function approvalsSearchResult(result) {
    const { current_page, data, last_page, per_page, status,
        total_unique_inventory_item_id_count } = result;

    let approvals = approvalGroups(data, current_page, per_page)

    approvals = approvals.sort((a, b) => {

        const aUpdatedAt = new Date(Math.max(...a.approvals.map(approval => new Date(approval.updated_at))));
        const bUpdatedAt = new Date(Math.max(...b.approvals.map(approval => new Date(approval.updated_at))));

        return bUpdatedAt - aUpdatedAt;
    })

    if (status === ApprovalStatus.pending.id) {
        approvals = approvals.slice(0, current_page * per_page);
    }

    return {
        type: APPROVALS_SEARCH_RESULT,
        currentPage: current_page,
        lastPage: last_page,

        data: approvals,
        totalUniqueInventoryItems: total_unique_inventory_item_id_count
    }
}

export function approvalsNextPageRequest() {
    return {
        type: APPROVALS_NEXT_PAGE_REQUEST,
    }
}

export function approvalsNextPageResult(data) {
    return {
        ...approvalsSearchResult(data),

        type: APPROVALS_NEXT_PAGE_RESULT
    }
}

export function approvalsSaveScroll(scrollY) {
    return {
        type: APPROVALS_SAVE_SCROLL_POSITION,
        scrollY
    }
}
