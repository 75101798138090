import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import { Box, Button, Grid, makeStyles, Typography, Link, Tooltip, Divider } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationIcon, AvatarEditable, ButtonLoader, ConfirmationDialog, CopyButton, ImageUploadDialog } from '../../components';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import { maxLength, required } from '../../helpers/validators';
import { ApplicationStatus, Global, RoutePaths } from '../../helpers/constants';
import ApplicationStatusBadge from './ApplicationStatusBadge';
import { useTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import LCTypography from '../../components/material/LCTypography';
import {
    deleteApplication, deletePrimaryImage,
    setPrimaryImage, submitApplicationReview, updateApplication
} from '../../actions/applications';
import i18next from 'i18next';
import { showError, showSuccess } from '../../actions/snackbar';
import { withRouter } from 'react-router';
import ReactMarkdown from 'react-markdown'
import { Switch, FormControlLabel } from '@material-ui/core';
import FlareIcon from '@material-ui/icons/Flare';


const useStyles = makeStyles((theme) => {
    return {
        avatar: {
            background: 'transparent',
            // border: "1px solid black"
        },
        removeButton: {
            color: theme.palette.error.main,

            '&:hover': {
                color: theme.palette.error.dark,
            }
        },
        clickToAdd: {
            cursor: 'pointer',
            color: theme.palette.grey[500]
        },
        textFieldMaxWidth: {
            maxWidth: 600
        }
    }
});

const ApplicationGeneral = (props) => {
    const { application } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [isUploadOpened, setIsUploadOpened] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isEditDescription, setEditDescription] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    return <>
        <IonCard>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <AvatarEditable
                            classes={{
                                avatar: classes.avatar
                            }}
                            name={application.name}
                            src={application.options && application.options.primary_image_public_url}
                            fallbackIcon={<ApplicationIcon style={{ width: 64, height: 64 }} />}
                            onClick={() => setIsUploadOpened(true)}
                        />

                        {isUploadOpened
                            && <ImageUploadDialog
                                title={t('Edit Application Icon')}
                                src={application.options.primary_image_public_url}
                                open={isUploadOpened}
                                aspect={Global.imageAspectAccountLogo}

                                handleDelete={() => props.handleImageDelete(application)
                                    .then(() => setIsUploadOpened(false))}
                                handleSave={image => props.handleImageUpload(application, image)
                                    .then(() => setIsUploadOpened(false))}
                                handleClose={() => setIsUploadOpened(false)}
                            />}
                    </Box>

                    <Box mt={4} style={{ textAlign: "center" }} >
                        <Link
                            component={RouterLink}
                            color="secondary"
                            to={RoutePaths.public.slug(application.slug)}>
                            {t('Public Profile Page')}
                        </Link>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={8} md={9}>

                    <IonCardHeader style={{ paddingBottom: 8 }}>
                        <IonCardTitle>
                            <TextFieldEditable
                                classes={{ root: classes.textFieldMaxWidth }}
                                fullWidth={true}
                                circularProgressProps={{ size: 40 }}
                                size="small"
                                inputProps={{ maxLength: 254 }}
                                ViewComponent={<Typography variant='h5'>{application.name}</Typography>}
                                label={"Name"}
                                variant={"outlined"}
                                value={application.name}
                                validators={[required, maxLength(Global.titleMaxLength)]}
                                handleDone={name => props.handleUpdate({ ...application, name })}
                            />
                        </IonCardTitle>
                    </IonCardHeader>

                    <Box style={{ paddingLeft: 16 }} mb={2}>
                        <TextFieldEditable
                            classes={{ root: classes.textFieldMaxWidth }}
                            fullWidth={true}
                            multiline
                            rows={24}
                            circularProgressProps={{ size: 40 }}
                            size="small"
                            inputProps={{ maxLength: 4096 }}
                            isEdit={isEditDescription}
                            handleIsEdit={isEdit => setEditDescription(isEdit)}
                            ViewComponent={application.description
                                ? <Box>
                                    <ReactMarkdown>{application.description}</ReactMarkdown>
                                </Box>
                                : <LCTypography
                                    onClick={() => setEditDescription(true)}
                                    className={classes.clickToAdd}
                                    variant="body2"><i>(click to add description)</i></LCTypography>}
                            label={"Description"}
                            variant={"outlined"}
                            value={application.description}
                            handleDone={description => props.handleUpdate({ ...application, description })}
                        />

                        <Box mt={4} style={{ maxWidth: 600 }}>
                            <Divider />
                        </Box>

                        <Box mt={4} style={{ maxWidth: 600 }}>

                            <TextFieldEditable
                                fullWidth={true}
                                circularProgressProps={{ size: 40 }}
                                size="small"
                                inputProps={{ maxLength: 254 }}
                                ViewComponent={<><LCTypography variant='body2'><strong>Organization : </strong>{
                                    application.organization_name ?? <i>Add the organzation name for this app</i>
                                }</LCTypography></>}
                                label={t("Organization / Company Name")}
                                helperText={t("This is the name of the organization or company that owns this application")}
                                variant={"outlined"}
                                value={application.organization_name}
                                validators={[required, maxLength(Global.titleMaxLength)]}
                                handleDone={organization_name => props.handleUpdate({ ...application, organization_name })}
                            />

                            <TextFieldEditable
                                fullWidth={true}
                                circularProgressProps={{ size: 40 }}
                                size="small"
                                inputProps={{ maxLength: 254 }}
                                ViewComponent={<><LCTypography variant='body2'><strong>{t('Website : ')}</strong>{
                                    application.website ?? <i>{t('Add a Website Link')}</i>
                                }</LCTypography></>}
                                label={t("Website")}
                                helperText={t("Website for the company that owns this application")}
                                variant={"outlined"}
                                value={application.website}
                                validators={[required, maxLength(Global.titleMaxLength)]}
                                handleDone={website => props.handleUpdate({ ...application, website })}
                            />

                            <TextFieldEditable
                                fullWidth={true}
                                circularProgressProps={{ size: 40 }}
                                size="small"
                                inputProps={{ maxLength: 254 }}
                                ViewComponent={<><LCTypography variant='body2'><strong>Help : </strong>{
                                    application.help_url ?? <i>Add a help link</i>
                                }</LCTypography></>}
                                label={t("Help Page Link")}
                                helperText={t("An optional link to a help page for this application")}
                                variant={"outlined"}
                                value={application.help_url}
                                validators={[required, maxLength(Global.titleMaxLength)]}
                                handleDone={help_url => props.handleUpdate({ ...application, help_url })}
                            />

                            <TextFieldEditable
                                fullWidth={true}
                                circularProgressProps={{ size: 40 }}
                                size="small"
                                inputProps={{ maxLength: 254 }}
                                ViewComponent={<><LCTypography variant='body2'><strong>Video : </strong>{
                                    application.video_url ?? <i>Add a video Link</i>
                                }</LCTypography></>}
                                label={t("Video Link")}
                                helperText={t("An optional link to a video about this application")}
                                variant={"outlined"}
                                value={application.video_url}
                                validators={[required, maxLength(Global.titleMaxLength)]}
                                handleDone={video_url => props.handleUpdate({ ...application, video_url })}
                            />

                        </Box>

                        <Box mt={4} style={{ maxWidth: 600 }}>
                            <Divider />
                        </Box>

                        <Box mt={4}>

                            <Grid container spacing={2}>
                                <Grid item xs={1}>

                                    <FlareIcon
                                        style={{
                                            color: application.premium ? '#4caf50' : '#cdcdcd',
                                            marginTop: 4
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={11}>

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={application.premium || false}
                                                onChange={(event) =>
                                                    props.handleUpdate({ ...application, premium: event.target.checked })
                                                }
                                                name="premium"
                                                color="primary"
                                                style={{
                                                    color: application.premium ? '#4caf50' : '#cdcdcd'
                                                }}
                                            />
                                        }
                                        label={t("Premium App")}
                                        labelPlacement="end"
                                    />

                                </Grid>

                                <Grid item xs={1}>

                                </Grid>
                                <Grid item xs={11}>

                                    <Typography variant='body1' style={{ marginBottom: "12px" }}>
                                        Premium apps have fee structures and billing plans assigned to them
                                    </Typography>

                                    {application.premium &&
                                        <TextFieldEditable
                                            fullWidth={true}
                                            multiline
                                            circularProgressProps={{ size: 40 }}
                                            size="small"
                                            inputProps={{ maxLength: 4096 }}
                                            ViewComponent={application.premium_fees_description
                                                ? <Box>
                                                    <ReactMarkdown>{application.premium_fees_description}</ReactMarkdown>
                                                </Box>
                                                : <LCTypography
                                                    onClick={() => setEditDescription(true)}
                                                    className={classes.clickToAdd}
                                                    variant="body2"><i>(click to edit public premium fees description)</i></LCTypography>
                                            }
                                            label={t("Premium Fees Description")}
                                            helperText={t("A description of the fees for this premium application")}
                                            variant={"outlined"}
                                            value={application.premium_fees_description}
                                            handleDone={premium_fees_description => props.handleUpdate({
                                                ...application, premium_fees_description
                                            })}
                                        />
                                    }

                                </Grid>
                            </Grid>

                        </Box>

                        <Box mt={4} style={{ maxWidth: 600 }}>
                            <Divider />
                        </Box>

                        <Box mt={4}>
                            <LCTypography variant="subtitle2">Class : {application.application_class_name}</LCTypography>
                            <Typography variant='body1' style={{ margin: "8px 0" }}>{application.application_class_description}</Typography>
                        </Box>

                        <Box mt={2}>
                            <LCTypography variant="subtitle2">Submission Status:</LCTypography>
                            <Box mt={1} mb={1}>
                                <ApplicationStatusBadge application={application} />
                            </Box>
                            {application.status === ApplicationStatus.draft.id
                                && <ButtonLoader
                                    submitting={submitting}
                                    size='small'
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setSubmitting(true);
                                        props.submitForReview(application)
                                            .finally(() => setSubmitting(false))
                                    }}>
                                    {t('Submit for review')}
                                </ButtonLoader>}
                        </Box>

                        <Box mt={4} style={{ maxWidth: 600 }}>
                            <Divider />
                        </Box>

                        <Box mt={4}>
                            <Tooltip title={t('Application ID is unique lcuid for your application used in most API calls')}>
                                <LCTypography variant="subtitle2">Documentation</LCTypography>
                            </Tooltip>
                            <LCTypography variant='body2' style={{ margin: "8px 0" }}>
                                View the Lucit documentation for applications at
                            </LCTypography>

                            <Link
                                target="_blank"
                                href="https://www.lucit.cc/developers"
                                color="secondary"
                                variant="body2">
                                https://www.lucit.cc/developers
                            </Link>
                        </Box>

                        <Box mt={4}>
                            <Tooltip title={t('Application ID is unique lcuid for your application used in most API calls')}>
                                <LCTypography variant="subtitle2">Application ID:</LCTypography>
                            </Tooltip>
                            <Typography variant='body1' style={{ margin: "8px 0" }}>{application.lcuid}</Typography>
                            <CopyButton
                                size='small'
                                text={application.lcuid}
                            />
                        </Box>

                        <Box mt={4}>
                            <Tooltip title={t('Application Slug is unique, friendly url slug for your application')}>
                                <LCTypography variant="subtitle2">Application URL Slug:</LCTypography>
                            </Tooltip>
                            <Typography variant='body1' style={{ margin: "8px 0" }}>{application.slug}</Typography>
                            <CopyButton
                                size='small'
                                text={application.slug}
                            />
                        </Box>

                        <Box mt={12} />
                        <Button
                            variant="outlined"
                            className={classes.removeButton}
                            startIcon={<Delete />}
                            onClick={() => setIsConfirmOpen(true)}>
                            {t('Delete Application')}
                        </Button>
                        <ConfirmationDialog
                            title={t('Delete Application')}
                            dialogProps={{
                                open: isConfirmOpen,
                                onClose: () => setIsConfirmOpen(false),
                                keepMounted: false
                            }}
                            ConfirmButtonText={t('Delete')}
                            ConfirmButtonProps={{
                                variant: "outlined",
                                className: classes.removeButton
                            }}
                            handleCancel={() => setIsConfirmOpen(false)}
                            handleConfirm={() => props.handleDelete(application)}>
                            <LCTypography>Are you sure you want to delete this application?</LCTypography>
                        </ConfirmationDialog>
                    </Box>
                </Grid>
            </Grid>
        </IonCard>
    </>
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleImageDelete: (application) => dispatch(deletePrimaryImage(application))
            .then(() => dispatch(showSuccess(i18next.t('Application logo was removed'))))
            .catch(error => {
                dispatch(showError(i18next.t('Failed to remove Application logo')));

                throw error;
            }),
        handleImageUpload: (application, image) => dispatch(setPrimaryImage(application, image))
            .then(() => dispatch(showSuccess(i18next.t('Application logo was updated successfuly'))))
            .catch(error => {
                dispatch(showError(i18next.t('Failed to save Application logo')));

                throw error;
            })
        ,
        handleUpdate: (application) => dispatch(updateApplication({ ...application, permissions: undefined }))
            .then(() => dispatch(showSuccess(i18next.t('Application was successfuly updated'))))
            .catch(error => {
                dispatch(showError(i18next.t('Failed to update')));

                throw error;
            }),
        handleDelete: (application) => dispatch(deleteApplication(application))
            .then(() => {
                dispatch(showSuccess(i18next.t('Application was successfuly deleted')));

                ownProps.history.push({ pathname: RoutePaths.dev.applications })
            })
            .catch(error => {
                dispatch(showError(i18next.t('Failed to delete Application')));

                throw error;
            }),

        submitForReview: application => dispatch(submitApplicationReview(application))
            .then(() => dispatch(showSuccess(i18next.t('Application was submitted for review'))))
            .catch(error => {
                dispatch(showError(i18next.t('Failed to submit Application for review')));

                throw error;
            }),
    }
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        ApplicationGeneral
    )
);
