import { Box, Button, IconButton, makeStyles, Paper } from '@material-ui/core';
import LCTypography from '../material/LCTypography';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import Tour from 'reactour'

const useStyles = makeStyles(theme => {
    return {
        mask: {
            fill: '#ffffff40'
        },
        area: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: 150,
            minWidth: 300,
            maxWidth: 500,

            padding: theme.spacing(2, 3, 1.5, 3),
        },

        close: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(2)
        }
    }
})

const ReactTour = props => {
    const { isOpen, steps, startAt } = props;
    const classes = useStyles();

    useEffect(() => {
        if (isOpen && steps.length) {
            const step = steps[startAt || 0];

            if (step.onShow) {
                step.onShow();
            }
        }
    }, [isOpen, startAt, steps]);

    return <Tour
        closeWithMask={false}
        disableFocusLock={true}
        disableInteraction={true}
        highlightedMaskClassName={classes.mask}
        CustomHelper={(helperProps) => <Area {...props} {...helperProps} />}

        rounded={4}

        {...props}
    >
        {props.children}
    </Tour>
};

const Area = props => {
    const { title, steps, content, close, gotoStep, current, totalSteps } = props;
    const classes = useStyles();

    const step = steps[current];
    const StepComponent = step.Component;

    const nextStep = useCallback(async () => {
        const currentNext = current + 1;
        const stepNext = steps[currentNext];

        if (currentNext < totalSteps) {
            if (step.onHide) {
                await step.onHide();
            }

            if (stepNext.onShow) {
                await stepNext.onShow();
            }

            gotoStep(currentNext);
        }
    }, [gotoStep, current, totalSteps, step, steps])

    const prevStep = useCallback(async () => {
        const currentNext = current - 1;
        const stepNext = steps[currentNext];

        if (currentNext >= 0) {
            if (step.onHide) {
                await step.onHide();
            }

            if (stepNext.onShow) {
                await stepNext.onShow();
            }

            gotoStep(currentNext);
        }
    }, [gotoStep, current, step, steps])

    const handleClose = useCallback(async (e, reason) => {
        close(e, reason, current);

        if (step.onHide) {
            step.onHide();
        }
    }, [step, close, current])

    const hasPrevStep = current > 0;
    const hasNextStep = current < totalSteps - 1;

    return <Paper className={classes.area}>
        <Box className={classes.close}
            onClick={e => handleClose(e, TourCloseReason.skipped)}>
            <IconButton>
                <Close />
            </IconButton>
        </Box>

        {title
            && <Box mb={2}>
                <LCTypography transProps={{ title: title }} variant="h5">{{ title: title }}</LCTypography>
            </Box>}

        <Box pr={4} mb={2}>
            {content
                ? content
                : <StepComponent />}
        </Box>

        <Box
            display="flex"
            justifyContent="space-between"
            mt="auto">
            {hasPrevStep
                ? <Button
                    variant="outlined"
                    onClick={prevStep}
                    startIcon={<ArrowBack />}> Previous</Button>
                : <Button
                    variant="outlined"
                    onClick={e => handleClose(e, TourCloseReason.skipped)}>Skip</Button>}
            {hasNextStep
                ? <Button
                    color="primary"
                    variant="contained"
                    onClick={nextStep}
                    endIcon={<ArrowForward />}>Next</Button>
                : <Button
                    color="primary"
                    variant="contained"
                    onClick={e => handleClose(e, TourCloseReason.completed)}>Finish</Button>}
        </Box>
    </Paper>
}

export const TourCloseReason = {
    skipped: 'skipped',
    completed: 'completed',
};

export default ReactTour;
