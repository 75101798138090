import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isSubmitting, submit } from 'redux-form';

import { unselectAll, exportSelectedItems } from '../../actions/inventory';
import { selectedCount } from '../../selectors/inventory';
import { connect } from 'react-redux';
import InventoryExportForm from './InventoryExportForm';
import ButtonLoader from '../../components/material/ButtonLoader';
import { showSuccess } from '../../actions/snackbar';
import { Dialog } from '../../components/material';

function InventoryExportDialog(props) {
    const { selectedCount, open, handleClose, onSubmit, submitting, handleSubmit } = props;

    return (
        <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="export-dialog-title">
            <DialogTitle id="export-dialog-title">Items</DialogTitle>
            <DialogContent>
                <DialogContentText>{selectedCount} item(s) were selected</DialogContentText>
                <Box mb={2}>
                    <InventoryExportForm
                        form={FORM_INVENTORY_EXPORT}
                        onSubmit={onSubmit} />
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonLoader
                    submitting={submitting}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >Promote</ButtonLoader>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    startIcon={<CloseIcon />}
                >Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        submitting: isSubmitting(FORM_INVENTORY_EXPORT)(state),
        selectedCount: selectedCount(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: values => {
            const exportIds = values.exports.map(e => e.id);

            // eslint-disable-next-line no-undef
            return dispatch(exportSelectedItems(exportIds))
                .then(() => dispatch(unselectAll()))
                .then(() => ownProps.handleClose())
                .then(() => dispatch(showSuccess("Items were successfully exported!")))
        },
        handleSubmit: () => dispatch(submit(FORM_INVENTORY_EXPORT)),
        handleUnselectAll: () => dispatch(unselectAll())
    }
}

export const FORM_INVENTORY_EXPORT = "inventoryExport";
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryExportDialog
);
