import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import TextFieldEditable from '../../components/inputs/TextFieldEditable';
import PhoneInput, { getFullPhone } from '../../components/inputs/PhoneInput';
import { getUser } from '../../selectors/user';
import { phoneFormat, required } from '../../helpers/validators';
import { changePhone, changePhoneConfirm } from '../../actions/user';
import { showSuccess } from '../../actions/snackbar';
import { useEffect } from 'react';
import { parsePhone } from '../../helpers/string';

function UserPhoneInfo(props) {
    const { label, user, handleIsEdit, classes: classesCustom = {} } = props;
    const [isChangingPhone, setIsChangingPhone] = useState(false);
    const [isEditingPhone, setIsEditingPhone] = useState(false);

    useEffect(() => {
        if (handleIsEdit) {
            handleIsEdit(isChangingPhone || isEditingPhone);
        }
    }, [isChangingPhone, isEditingPhone, handleIsEdit])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} className={classesCustom.phoneGrid}>
                <TextFieldEditable
                    classes={{
                        root: classesCustom.phoneField
                    }}
                    EditComponent={PhoneInputEdit}

                    label={label}
                    value={parsePhone(user.phone)}
                    validators={[required, phoneFormat]}
                    id={"phone"}
                    type={"tel"}
                    autoComplete={"tel"}
                    handleIsEdit={setIsEditingPhone}
                    handleDone={phone => props.handlePhoneChange(phone)
                        .then(() => setIsChangingPhone(true))}
                />
            </Grid>
            {isChangingPhone
                && <Grid item xs={12} className={classesCustom.confirmGrid}>
                    <TextFieldEditable
                        classes={{
                            root: classesCustom.confirmField
                        }}
                        label={"Confirmation Code"}
                        value={""}
                        required
                        validators={[required]}
                        fullWidth={true}
                        id={"code"}
                        autoComplete={"one-time-code"}
                        autoFocus
                        inputProps={{
                            maxLength: 5
                        }}
                        isEditDefault={true}
                        handleDone={code => {
                            return props.handlePhoneChangeConfirm(code)
                                .then(() => setIsChangingPhone(false));
                        }}
                        cancelProps={{ style: { display: 'none' } }}
                    />
                </Grid>
            }
        </Grid>
    );
}

const PhoneInputEdit = (props) => {
    const { value, onChange, ...rest } = props;

    return <PhoneInput
        input={{
            value,
            onChange: value => onChange({ target: { value } })
        }}
        margin="none"
        {...rest} />
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handlePhoneChange: phone => {
            const phoneNumber = getFullPhone(phone);

            return dispatch(changePhone(phoneNumber))
                .then(() => dispatch(showSuccess('Sms with confirmation code was sent to your new phone')))
                .catch(results => {
                    results.errors._error = results.errors.phone || results.errors._error;

                    throw results;
                });
        },
        handlePhoneChangeConfirm: code => {
            return dispatch(changePhoneConfirm(code))
                .then(() => dispatch(showSuccess('Your phone was successfully confirmed')))
                .catch(results => {
                    results.errors._error = results.errors.code || results.errors._error;

                    throw results;
                });
        },

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPhoneInfo);
