import { makeStyles, DialogContent, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonLoader, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import AdvertiseForm, { FORM_ADVERTISE } from './AdvertiseForm';
import { isSubmitting, reset, submit } from 'redux-form';
import { lucitApi } from '../../services/lucitApi';
import { showSuccess } from '../../actions/snackbar';
import i18next from '../../i18n';

const useStyles = makeStyles(theme => {
    return {
        content: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '600px',
            },
        }
    }
})

const AdvertiseDialog = props => {
    const { open,
        submitting,
        onClose } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return <Dialog
        fullScreen={isXs}
        maxWidth="md"
        scroll="paper"
        open={open}
        title={t("Advertise Request")}
        onClose={onClose}
    >
        <DialogContent className={classes.content}>
            <AdvertiseForm onSubmit={props.sendRequest} />
        </DialogContent>
        <DialogActions>
            <ButtonLoader
                submitting={submitting}
                onClick={() => props.submitForm()}
                variant="contained"
                color="secondary">{t('Submit')}</ButtonLoader>
        </DialogActions>
    </Dialog>
}

AdvertiseDialog.propTypes = {
    billboardId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        submitting: isSubmitting(FORM_ADVERTISE)(state),
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        submitForm: () => dispatch(submit(FORM_ADVERTISE)),
        sendRequest: values => {
            return lucitApi.sendAdRequest({
                from_name: values.name,
                from_email: values.email,
                from_company: values.company,
                from_message: values.message,
                digital_board_id: props.billboardId,
                support_data: {
                    location_id: props.location.id,
                    location_name: props.location.name,
                    board_ids: props.location.board_ids.map(x => x.id)
                },

            }).then(() => {
                dispatch(reset(FORM_ADVERTISE));
                dispatch(showSuccess(i18next.t('Your request was successfully sent.')));

                if (props.onRequestSent) {
                    props.onRequestSent(true);
                }

                props.onClose();
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    AdvertiseDialog
);
