import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getItem } from '../../../actions/inventory';
import DateTimeRelative from '../../../components/DateTimeRelative';
import CircularProgressCentered from '../../../components/material/CircularProgressCentered';
import { InventoryItemCreativeState, InventoryItemStatus } from '../../../helpers/constants';
import { itemOptions } from '../../../selectors/inventory';
import DraftChip from '../itemCardContent/DraftChip';
import CreativesList from './CreativesList';
import TitleEditable from './TitleEditable';

function InventoryItem(props) {
    const { item } = props;

    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                {item.creative_state === InventoryItemCreativeState.building
                    && <Box component={Paper} elevation={2} p={2} mb={1} justifyContent="center">
                        <CircularProgressCentered size={10} rootStyle={{ margin: "3px", display: "inline" }} />
                        {t('Building Creatives, Please Wait...')}
                    </Box>}

                <CreativesList item={item} />

            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Box mb={2}>
                    <TitleEditable
                        label={itemOptions(item).title_in_draft ?
                            <>Name This Creative <span style={{ color: "red" }}>(Required to run)</span></> : <>Creative Name</>}
                        item={item}
                        isEditDefault={itemOptions(item).title_in_draft}
                        autoFocus={itemOptions(item).title_in_draft}
                    />
                </Box>

                {item.status === InventoryItemStatus.draft && <>
                    <Box sx={{ width: '100%', display: "block" }} mb={3}>
                        <Alert severity="warning">
                            You must <strong>activate</strong> this
                            <DraftChip item={item} size="medium" />
                            creative before it can be added to your campaign
                        </Alert>
                    </Box>
                </>}

                <Box mt={2}>
                    <Typography variant="caption">
                        Created <DateTimeRelative date={moment.utc(item.created_at).local()} />
                    </Typography>
                </Box>
            </Grid>
        </>
    );
}

InventoryItem.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getItem: id => dispatch(getItem(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItem
);
