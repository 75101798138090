import React from 'react';
import AccountListItems from './AccountListItems';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AgencyAccountButtons } from '../agency/AgencyAccounts';

export function AccountChildren({ loading, accounts, onDelete }) {
    const { t } = useTranslation();

    return <Box mt={2}>
        {loading
            && <CircularProgress size={40} />}

        <AccountListItems
            accounts={accounts}
            handleChange={(_, toggleOpen) => toggleOpen()}
            level={0}

            ListItemProps={{ selected: false }}
            arrowPosition="left"
            Buttons={AgencyAccountButtons}
            ButtonProps={{ onDelete }}
        />

        {accounts.length === 0
            && !loading
            && <Box
                style={{
                    cursor: "pointer",
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                textAlign="center"
            >
                <img src="/assets/designer/empty-drive-template.png" style={{ maxWidth: 320 }} />
                <Typography variant="h6" gutterBottom>{t('Click \'Add Account\' to create a child account.')}</Typography>
                <Typography variant="body1" gutterBottom>{t('This account doesn\'t have child accounts attached to it.')}</Typography>
            </Box>}
    </Box>
}
