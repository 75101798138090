import React from 'react';
import PropTypes from 'prop-types';

import InventoryItemCardContentGeneric from './InventoryItemCardContentGeneric'
import InventoryItemCardContentAutomovie from './InventoryItemCardContentAutomovie'
import InventoryItemCardContentArticle from './InventoryItemCardContentArticle'
import InventoryItemCardContentRealEstate from './InventoryItemCardContentRealEstate'
import InventoryItemCardContentPhotoStream from './InventoryItemCardContentPhotoStream';
import InventoryItemCardContentChildCampaign from './InventoryItemCardContentChildCampaign';
import InventoryItemCardContentRealEstateAgent from './InventoryItemCardContentRealEstateAgent';
import InventoryItemCardContentCreative from './InventoryItemCardContentCreative';
import InventoryItemCardContentReview from './InventoryItemCardContentReview';
import InventoryItemCardContentSocialPost from './InventoryItemCardContentSocialPost';

import { InventoryItemClasses } from '../../../helpers/constants';

function InventoryItemCardContent(props) {
    const { itemClass, ...rest } = props;

    if (itemClass === InventoryItemClasses.automotive) {
        return <InventoryItemCardContentAutomovie {...rest} />
    }

    if (itemClass === InventoryItemClasses.real_estate) {
        return <InventoryItemCardContentRealEstate {...rest} />
    }

    if (itemClass === InventoryItemClasses.photoStream) {
        return <InventoryItemCardContentPhotoStream {...rest} />
    }

    if (itemClass === InventoryItemClasses.childCampaign) {
        return <InventoryItemCardContentChildCampaign {...rest} />
    }

    if (itemClass === InventoryItemClasses.realEstateAgent) {
        return <InventoryItemCardContentRealEstateAgent {...rest} />
    }

    if (itemClass === InventoryItemClasses.article) {
        return <InventoryItemCardContentArticle {...rest} />
    }

    if (itemClass === InventoryItemClasses.review) {
        return <InventoryItemCardContentReview {...rest} />
    }

    if (itemClass === InventoryItemClasses.social_post) {
        return <InventoryItemCardContentSocialPost {...rest} />
    }

    if (itemClass === InventoryItemClasses.creative) {
        return <InventoryItemCardContentCreative {...rest} />
    }

    return <InventoryItemCardContentGeneric {...rest} />;
}

InventoryItemCardContent.propTypes = {
    itemClass: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
}

export default InventoryItemCardContent;
