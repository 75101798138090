import React from 'react';
import { connect } from 'react-redux';
import { withHomeLayout } from '../../layouts/HomeLayout';
import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { PageTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import AppsSettings from '../../containers/settings/AppsSettings';
import { selectedAccount } from '../../selectors/user';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';

const AppsAndData = ({ selectedAccount }) => {

    const { t } = useTranslation();

    return (
        <>
            <PageTitle title={t("Apps & Data Sources")} />

            <PolicyFeatureToggle policy={AccountPolicy.editApps} >

                <AppsSettings lcuid={selectedAccount.lcuid} />

            </PolicyFeatureToggle>

            <ButtonScrollToTopFab />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedAccount: selectedAccount(state)
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withHomeLayout(AppsAndData)
);
