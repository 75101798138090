import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { CopyIcon } from '../icons';

const useStyles = makeStyles(theme => {
    return {
        root: {

        },
        success: {
            background: theme.palette.success.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.success.dark,
            }
        },
    }
});

const CopyButton = ({ text, forwardedRef, onClick, title = "Copy", ...rest }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 3000)
        }
    }, [copied])

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text)
                .then(() => setCopied(true));
        } else {
            setCopied(true);
            return document.execCommand('copy', true, text);
        }
    }

    return <Button
        ref={forwardedRef}
        className={copied
            ? classes.success
            : ''}
        color={"secondary"}
        startIcon={<CopyIcon />}
        onClick={() => {
            copyTextToClipboard(text);

            onClick && onClick();
        }}
        variant='contained' {...rest}>
        {copied
            ? t('Copied!')
            : t(title)}
    </Button>
}

export default React.forwardRef((props, ref) => <CopyButton {...props} forwardedRef={ref} />);
