import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextField from '../../components/inputs/TextField';
import { selectedProfile } from '../../selectors/user';
import { useState } from 'react';
import { Autocomplete } from '../../components/inputs/AutocompleteField';
import { getExports } from '../../actions/exports';
import { CampaignState, ProfileType, QueryString } from '../../helpers/constants';
import { useQueryString } from '../../helpers/hooks';
import { getCampaigns } from '../../actions/agencies';

function CampaignFilter(props) {
    const { campaigns, profile, getExports, getAgencyCampaigns, value, onChange } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState();

    const defaultCampaignId = useQueryString(QueryString.campaignId, null)

    useEffect(() => {
        if (profile.type === ProfileType.account) {
            setLoading(true);
            getExports()
                .finally(() => setLoading(false))
        }

        if (profile.type === ProfileType.agency) {
            setLoading(true);
            getAgencyCampaigns(profile.lcuid)
                .finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        if (campaigns.length > 0 && defaultCampaignId) {
            onChange(campaigns.find(x => x.id == defaultCampaignId) || null)
        }
    }, [campaigns, defaultCampaignId])

    return (
        <Autocomplete
            multiple={false}
            openOnFocus={true}
            disableCloseOnSelect={false}
            input={{
                value,
                onChange
            }}
            loading={loading}
            options={campaigns}
            getOptionSelected={option => value.id === option.id}
            getOptionLabel={(option) => profile.type === ProfileType.account
                ? option.name || option.title
                : `${option.name} (${option.account.name})`}
            renderInput={(params) => <TextField {...params}
                variant="outlined"
                label={t('Campaign')} />}
        />
    );
}

const mapStateToProps = (state) => {
    const profile = selectedProfile(state);

    return {
        profile,
        campaigns: profile.type === ProfileType.account
            ? state.inventory.exportsAvailable
            : state.agencies.campaigns
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getExports: () => dispatch(getExports()),
        getAgencyCampaigns: (id) => dispatch(getCampaigns(id, {
            campaign_state: [CampaignState.running.id,
            CampaignState.paused.id,
            CampaignState.pullingPaused.id,
            CampaignState.complete.id]
        })),
    };
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CampaignFilter);
