import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { getUser } from '../../selectors/user';
import { useTranslation } from 'react-i18next';
import { selectedAccount } from '../../selectors/user';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../helpers/constants';
import { DesignerTemplateList } from '../designer/DesignerTemplateList';
import { useSelector } from 'react-redux';
import { AccountPolicy } from '../../helpers/lucoreConstants';
import { currentUserHasPermission } from '../../selectors/user';
import { useWidth } from '../../helpers/hooks';


const useStyles = makeStyles(theme => {
    return {
        dialogContent: {
            padding: theme.spacing(3)
        },

        cardWrapper: {
            boxShadow: 'rgba(74, 74, 74, 0.15) 1px 1px 5px 0px',
            padding: '16px 8px',
            borderRadius: '4px',
            border: '1px solid rgba(222, 222, 222, 0.7)'
        },
        selectedTemplate: {
            border: '1px solid #3f51b5'
        },
        error: {
            marginTop: theme.spacing(1)
        }
    }
});

const ChangeTemplateDialog = (props) => {
    const { open, handleClose, selectedTemplateLcuid } = props;
    const [selectedTemplate, setSelectedTemplate] = useState(selectedTemplateLcuid);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const width = useWidth();

    const canEditTemplates = useSelector(state => currentUserHasPermission(state)(AccountPolicy.editDriveTemplates))

    return (
        <GenericDialog
            title={t('Change Template')}
            dialogProps={{
                open,
                onClose: () => handleClose(selectedTemplate),
                fullWidth: true,
                maxWidth: 'md'
            }}
            dialogContentProps={{
                className: classes.dialogContent
            }}
            ContentComponent={<>
                <DesignerTemplateList
                    width={width}
                    compact={true}
                    isSelected={template => template.lcuid == selectedTemplate}
                    onClick={template => setSelectedTemplate(template.lcuid)}
                    onEmptyClick={() => history.push({ pathname: RoutePaths.designerTemplates })}
                />
            </>}

            ActionsComponent={<>
                <Button
                    disabled={!canEditTemplates}
                    onClick={() => history.push({ pathname: RoutePaths.designerTemplates })}
                    color="primary">
                    {t('Create a new Template')}
                </Button>
                <Button onClick={() => handleClose(selectedTemplate)}
                    color="primary">
                    {t('Cancel')}
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={!selectedTemplate}
                    onClick={() => {
                        if (handleClose)
                            handleClose(selectedTemplate);
                    }}
                >
                    {t('Change')}
                </Button>
            </>}
        />
    )
}

const mapStateToProps = state => {

    return {
        user: getUser(state),
        selectedAccount: selectedAccount(state)
    }

}

const mapDispatchToProps = () => {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeTemplateDialog)
