import {
    APPLICATION_ADD_CAPABILITY,
    APPLICATION_BOTS,
    APPLICATION_BYID,
    APPLICATION_DELETE, APPLICATION_DELETE_BOT, APPLICATION_NEW, APPLICATION_NEW_BOT, APPLICATION_REMOVE_CAPABILITY,
    APPLICATION_REMOVE_IMAGE, APPLICATION_REVIEW, APPLICATION_SET_IMAGE, APPLICATION_UPDATE, USER_INFO,
    OBJECT_APPLICATION_DATA_SOURCES_RESULT, OBJECT_APPLICATION_DATA_SOURCES_CLEAR,
    OBJECT_APPLICATION_DATA_SOURCES_ADD, OBJECT_APPLICATION_DATA_SOURCES_UPDATE, OBJECT_APPLICATION_DATA_SOURCES_REMOVE,
    OBJECT_APPLICATION_DATA_SOURCES_SET_IMAGE, OBJECT_APPLICATION_DATA_SOURCES_REMOVE_IMAGE
} from "../helpers/actionTypes";
import { ApplicationStatus } from "../helpers/constants";

export const initialState = {
    data: [],

    //State for editing the dataSource capability for an application
    dataSource: {
        application_lcuid: null,   //the current one being edited,
        feedProviders: [], //The list of feed providers
    }
}

export default function applications(state = initialState, action) {
    switch (action.type) {

        case USER_INFO: {
            return Object.assign({}, state, {
                data: Object.values([...state.data, ...action.applications]
                    .reduce((prev, curr) => ({ ...prev, [curr.lcuid]: { ...prev[curr.lcuid], ...curr } }), {}))
            });
        }
        case APPLICATION_BYID: {
            return Object.assign({}, state, {
                data: state.data.find(x => x.lcuid === action.application.lcuid)
                    ? state.data.map(x => {
                        if (x.lcuid === action.application.lcuid) {
                            return { ...x, ...action.application }
                        }

                        return x;
                    })
                    : [...state.data, action.application]
            });
        }

        case APPLICATION_NEW: {
            return Object.assign({}, state, {
                data: [...state.data, action.application]
            });
        }

        case APPLICATION_UPDATE: {
            return Object.assign({}, state, {
                data: state.data.map(x => {
                    if (x.lcuid === action.application.lcuid) {
                        return { ...x, ...action.application }
                    }

                    return x;
                })
            });
        }

        case APPLICATION_SET_IMAGE:
            {
                const update = application => Object.assign({}, application, {
                    options: action.options
                });

                return Object.assign({}, state, {
                    data: state.data.map(a => {
                        if (a.lcuid === action.application.lcuid) {
                            return update(a);
                        }

                        return a;
                    })
                });
            }

        case APPLICATION_REMOVE_IMAGE:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            options: {
                                ...a.options,
                                primary_image_public_url: null
                            }
                        }
                    }

                    return a;
                })
            });

        case APPLICATION_REVIEW: {
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            status: ApplicationStatus.pending.id
                        }
                    }

                    return a;
                })
            });
        }

        case APPLICATION_DELETE: {
            return Object.assign({}, state, {
                data: state.data.filter(a => a.lcuid !== action.application.lcuid)
            });
        }

        case APPLICATION_ADD_CAPABILITY:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            capabilities: [...(a.capabilities || []), action.capability]
                        }
                    }

                    return a;
                })
            });

        case APPLICATION_REMOVE_CAPABILITY:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            capabilities: a.capabilities.filter(c => c.id !== action.capability.id)
                        }
                    }

                    return a;
                })
            });

        case APPLICATION_BOTS:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            bots: action.data
                        }
                    }

                    return a;
                })
            });

        case APPLICATION_NEW_BOT:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            bots: [...a.bots, action.user]
                        }
                    }

                    return a;
                })
            });

        case APPLICATION_DELETE_BOT:
            return Object.assign({}, state, {
                data: state.data.map(a => {
                    if (a.lcuid === action.application.lcuid) {
                        return {
                            ...a,
                            bots: a.bots.filter(x => x.lcuid !== action.user.lcuid)
                        }
                    }

                    return a;
                })
            });

        case OBJECT_APPLICATION_DATA_SOURCES_RESULT:
            return Object.assign({}, state, {
                dataSource: {
                    application_lcuid: action.application_lcuid,
                    feedProviders: action.inventory_feed_providers
                }
            });

        case OBJECT_APPLICATION_DATA_SOURCES_CLEAR:
            return Object.assign({}, state, {
                dataSource: {
                    application_lcuid: null,
                    feedProviders: []
                }
            });

        case OBJECT_APPLICATION_DATA_SOURCES_ADD:
            return Object.assign({}, state, {
                dataSource: {
                    ...state.dataSource,
                    feedProviders: [...state.dataSource.feedProviders, action.inventory_feed_provider]
                }
            });

        case OBJECT_APPLICATION_DATA_SOURCES_UPDATE:
            return Object.assign({}, state, {
                dataSource: {
                    ...state.dataSource,
                    feedProviders: state.dataSource.feedProviders.map(x => {
                        if (x.id === action.inventory_feed_provider.id) {
                            return action.inventory_feed_provider;
                        }

                        return x;
                    })
                }
            });

        case OBJECT_APPLICATION_DATA_SOURCES_REMOVE:
            return Object.assign({}, state, {
                dataSource: {
                    ...state.dataSource,
                    feedProviders: state.dataSource.feedProviders.filter(x => x.lcuid !== action.feed_provider_lcuid)
                }
            });

        case OBJECT_APPLICATION_DATA_SOURCES_SET_IMAGE:
            {
                const update = feedProvider => Object.assign({}, feedProvider, {
                    options: action.options
                });

                return Object.assign({}, state, {
                    dataSource: {
                        ...state.dataSource,
                        feedProviders: state.dataSource.feedProviders.map(x => {
                            if (x.id === action.feedProvider.id) {
                                return update(x);
                            }
                            return x;
                        })
                    }
                });
            }

        case OBJECT_APPLICATION_DATA_SOURCES_REMOVE_IMAGE:

            return Object.assign({}, state, {
                dataSource: {
                    ...state.dataSource,
                    feedProviders: state.dataSource.feedProviders.map(x => {
                        if (x.id === action.feedProvider.id) {
                            return {
                                ...x,
                                options: {
                                    ...x.options,
                                    primary_image_public_url: null
                                }
                            }
                        }
                        return x;
                    })
                }
            });

        default:
            return state
    }
}
