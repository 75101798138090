import React, { useEffect, useMemo } from 'react';
import clx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Box, Tab, Tabs, Typography, Paper, Link } from '@material-ui/core';
import { InventoryItemCreativeState, InventoryPhotoSource } from '../../../helpers/constants'
import { Link as RouterLink } from 'react-router-dom';
import { ImagePreviewDialog } from '../../../components/modals/ImagePreviewDialog';
import { useState } from 'react';
import { getItemPhotos, getItemPhotoSources, isPhotoCreative, isPhotoUpload, photoRatio } from '../../../selectors/inventory';
import { ImageUploadDialog } from '../../../components';
import { connect } from 'react-redux';
import { removeItemPhoto, setItemPhoto } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { itemAspectRatioSelector } from '../../../selectors/inventoryStatus';
import { RoutePaths } from '../../../helpers/constants';
import { feedByIdSelector } from '../../../selectors/feeds';
import DeletePhotoButton from './DeletePhotoButton';
import InventoryItemPhotoApprovalChips from './InventoryItemPhotoApprovalChips';
import BillboardSkeleton from '../../../components/images/BillboardSkeleton';
import CircularProgressCentered from '../../../components/material/CircularProgressCentered';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => {
    return {
        root: {
            maxWidth: 400,
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: 50,
            paddingLeft: theme.spacing(4),
            backgroundColor: theme.palette.background.default,
        },
        imgContainer: {
            position: 'relative',
            width: '100%',
            minHeight: 255,
            maxWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        imgWithPreview: {
            cursor: 'pointer',

            '&:hover img': {
                filter: 'opacity(0.3)'
            },
            '&:hover svg': {
                display: 'block'
            }
        },
        img: {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        },
        stepper: {
            backgroundColor: theme.palette.background.paper,
        },

        tabLabel: {
            textTransform: 'none',
            width: '100%'
        }
    }
})

function InventoryItemPhotoGallery(props) {
    const { feed, item, setPhoto, deletePhoto, aspect,
        showAgencyApproveStatus = true,
        showUploadDelete = false } = props;

    const classes = useStyles();
    const [photoSource, setPhotoSource] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [previewPhoto, setPreviewPhoto] = useState(null);

    const photoSources = useMemo(() => getItemPhotoSources(item).map(x => x.code), [item]);
    const photos = useMemo(() => getItemPhotos(item, photoSource), [item, photoSource]);
    const { t } = useTranslation();

    const maxSteps = photos.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    // Reset activeStep if it's greater than total count of photos
    // This is possible when removing photos
    useEffect(() => {
        if (activeStep >= photos.length) {
            setActiveStep(0);
        }
    }, [activeStep, photos.length])

    useEffect(() => {
        if (!photoSource || !photoSources.includes(photoSource)) {
            setPhotoSource(photoSources[0])
        }
    }, [photoSource, photoSources])

    useEffect(() => {
        if (photoSources.length) {
            setPhotoSource(photoSources[0]);
            setActiveStep(0);
        }
    }, [item.creative_state])

    return (
        <div className={classes.root}>
            {item.creative_state === InventoryItemCreativeState.building
                && <Box component={Paper} elevation={2} p={2} mb={1} justifyContent="center">
                    <CircularProgressCentered size={10} rootStyle={{ margin: "3px", display: "inline" }} />
                    Building Creatives, Please Wait...
                </Box>}

            {photoSources.length > 1
                && photoSource
                && <>
                    <AppBar position="static" color="transparent">
                        <Tabs
                            value={photoSource}
                            onChange={(_, value) => {
                                setPhotoSource(value);
                                setActiveStep(0);
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            variant={photoSources.length > 2
                                ? "scrollable"
                                : "fullWidth"}
                        >
                            {photoSources.map(source => (
                                <Tab
                                    key={source}
                                    label={<Typography className={classes.tabLabel} noWrap>
                                        {source === InventoryPhotoSource.feed.code
                                            ? feed && feed.inventoryFeedProvider
                                                ? feed.inventoryFeedProvider.name
                                                : InventoryPhotoSource[source].title
                                            : null}
                                        {source !== InventoryPhotoSource.feed.code
                                            && InventoryPhotoSource[source].title}
                                    </Typography>}
                                    value={source} />
                            ))}
                        </Tabs>
                    </AppBar>
                    <Box mt={1} />
                </>}
            <SwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {photos
                    .map((photo, index) => {

                        return (
                            <Box key={photo.id || index} height='100%'>
                                {Math.abs(activeStep - index) <= 2
                                    && <>
                                        {photo.id
                                            && <Box className={clx(classes.imgContainer)}>

                                                {
                                                    isPhotoUpload(photo)
                                                    && <Link
                                                        component={RouterLink}
                                                        className={classes.avatarLink}
                                                        to={RoutePaths.inventoryPhoto + `/${photo.id}`}>
                                                        <img className={classes.img} src={photo.public_url} alt={item.title} />
                                                    </Link>
                                                }

                                                {
                                                    isPhotoCreative(photo)

                                                    &&
                                                    <Link
                                                        component={RouterLink}
                                                        className={classes.avatarLink}
                                                        to={RoutePaths.inventoryPhoto + `/${photo.id}`}>

                                                        <BillboardSkeleton
                                                            ratio={photoRatio(photo)}
                                                            photoSrc={photo.public_url}

                                                            SvgProps={{
                                                                width: '100%',
                                                                height: 400
                                                            }}
                                                        />
                                                    </Link>
                                                }

                                                {!isPhotoCreative(photo) && !isPhotoUpload(photo) &&
                                                    <img className={classes.img} src={photo.public_url} alt={item.title} />
                                                }

                                                {isPhotoUpload(photo)
                                                    && showUploadDelete
                                                    && <Box position="absolute" bottom={8} right={8}>
                                                        <DeletePhotoButton
                                                            item={item}
                                                            photo={photo}
                                                        />
                                                    </Box>}

                                                {showAgencyApproveStatus
                                                    && <Box position="absolute" bottom={8} left={8}>
                                                        <InventoryItemPhotoApprovalChips
                                                            item={item}
                                                            photo={photo} />
                                                    </Box>}
                                            </Box>}
                                        {!photo.id
                                            && <Box className={classes.imgContainer}>
                                                <img className={classes.img} src={photo.public_url} alt={item.title} />
                                            </Box>}
                                    </>}
                            </Box>

                        )
                    })}
            </SwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                className={classes.stepper}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back</Button>
                }
            />

            {photoSource === InventoryPhotoSource.upload.code
                && <ImageUploadDialog
                    src={previewPhoto && previewPhoto.public_url}
                    title={t('Edit Photo for {{title}}', { title: item.title })}
                    aspect={aspect}

                    open={Boolean(previewPhoto)}
                    handleSave={image => setPhoto(image, previewPhoto)
                        .then(() => setPreviewPhoto(null))}
                    handleDelete={() => deletePhoto(previewPhoto)
                        .then(() => setPreviewPhoto(null))}
                    handleClose={() => setPreviewPhoto(null)}
                />}

            {photoSource !== InventoryPhotoSource.upload.code
                && <ImagePreviewDialog
                    title={item.dealer_stock_number
                        ? `(${item.dealer_stock_number}) ${item.title}`
                        : item.title}
                    open={Boolean(previewPhoto)}
                    handleClose={() => setPreviewPhoto(null)}
                >
                    {previewPhoto && <img src={previewPhoto.public_url} alt={t('item preview')} />}
                </ImagePreviewDialog>}
        </div>
    );
}

InventoryItemPhotoGallery.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state, props) => {
    return {
        feed: feedByIdSelector(state)(props.item.feed_id),
        aspect: itemAspectRatioSelector(state)(props.item.id)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setPhoto: (newPhoto, oldPhoto) => dispatch(setItemPhoto(props.item, newPhoto))
            .then(() => dispatch(removeItemPhoto(props.item, oldPhoto)))
            .then(() => dispatch(showSuccess(i18next.t('New photo was successfully saved'))))
            .catch(error => {
                dispatch(showError(i18next.t('Unexpected error happened...')));
                throw error;
            }),
        deletePhoto: photo => dispatch(removeItemPhoto(props.item, photo))
            .then(() => dispatch(showSuccess(i18next.t('Photo was successfully deleted'))))
            .catch(error => {
                dispatch(showError(i18next.t('Unexpected error happened...')));
                throw error;
            })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    InventoryItemPhotoGallery
);
