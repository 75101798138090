import React, { useEffect, useState } from 'react';
import Prism from 'prismjs';
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { useDispatch, useSelector } from 'react-redux';
import { replaceCSSForSelectedId, replaceHTMLForSelectedId } from '../../actions/designer';
import { getCSSForSelectedId, getHtmlForSelectedId } from '../../selectors/designer';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { CodeEditor } from './CodeEditor';
import LCTypography from '../../components/material/LCTypography';
import { DESIGNER_PUSH_HISTORY } from '../../helpers/actionTypes';

const useStyles = makeStyles(() => {
    return {
        dialogContent: {
            paddingTop: 0
        }
    }
});

export const CssEditorDialog = ({ open, handleClose }) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const { t } = useTranslation();

    const cssString = useSelector(state => getCSSForSelectedId(state))
    const htmlString = useSelector(state => getHtmlForSelectedId(state))
    const onChange = (css, html) => {
        dispatch(replaceCSSForSelectedId(css))
        dispatch(replaceHTMLForSelectedId(html))

    };

    const [css, setCss] = useState('');
    const [html, setHtml] = useState('');

    useEffect(() => setCss(cssString), [cssString])
    useEffect(() => setHtml(htmlString), [htmlString])

    return (
        <>
            <GenericDialog
                title={t('Code Editor')}
                dialogProps={{
                    open,
                    onClose: handleClose,
                    fullWidth: true,
                    maxWidth: 'md'
                }}
                dialogContentProps={{
                    className: classes.dialogContent
                }}

                ContentComponent={<Grid container spacing={5}>
                    <Grid item xs={12}>
                        <LCTypography>
                            Advanced code editing can be unstable, updating CSS and HTML may cause issues with the editing tools.
                            Use sparingly and for advanced techniques.
                            CSS Styles support all CSS standards as of the latest version of Chrome and Chrome based web browsers
                        </LCTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <strong>CSS</strong>
                        <CodeEditor code={css} onChange={setCss} language={Prism.languages.css} styles={{ height: '100%' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <strong>HTML</strong>
                        <CodeEditor code={html} onChange={setHtml} language={Prism.languages.html} styles={{ height: '100%' }} />
                    </Grid>
                </Grid>}

                ActionsComponent={<>
                    <Button onClick={() => {
                        onChange(css, html);
                        handleClose();

                        // push history only if there were some changes
                        if (css !== cssString || html !== htmlString) {
                            dispatch({ type: DESIGNER_PUSH_HISTORY });
                        }
                    }}
                        color="primary">
                        {t('Apply')}
                    </Button>
                </>}
            />
        </>
    )
}
