import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ConfirmationDialog } from '../../components';
import { ExitToApp } from '@material-ui/icons';
import { logout } from '../../actions/user';
import { RoutePaths } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const LogOutButton = ({ handleLogout, isListItem, onLoggedOut }) => {
    const [isConfirmOpened, setIsConfirmOpened] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            {isListItem
                ? <ListItem
                    button
                    onClick={() => setIsConfirmOpened(true)}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={t('Log Out')} />
                </ListItem>
                : <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsConfirmOpened(true)}
                    startIcon={<ExitToApp />}>
                    Log out
                </Button>}

            <ConfirmationDialog
                dialogProps={{
                    open: isConfirmOpened
                }}

                handleCancel={() => setIsConfirmOpened(false)}
                handleConfirm={() => {
                    handleLogout();
                    onLoggedOut && onLoggedOut();
                }}
            >
                {t('Are you sure you want to be logged out?')}
            </ConfirmationDialog>
        </>
    );

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogout: () => {
            ownProps.history.push({ pathname: RoutePaths.login })

            return dispatch(logout());
        }
    }
}

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(LogOutButton)
);
