import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { removeItemPhoto } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { ConfirmationDialog } from '../../../components';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,
            minWidth: 'auto',
            padding: '5px 8px',

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const DeleteButton = (props) => {
    const { item, deleteItem } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    return (
        <>
            <Button
                variant="contained"
                className={classes.removeButton}
                onClick={() => setIsConfirmOpen(true)}>
                <Delete />
            </Button>
            <ConfirmationDialog
                title={`Upload of ${item.title}`}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    deleteItem()
                        .finally(() => setIsConfirmOpen(false))
                }}>
                Are you sure you want to remove this image?
            </ConfirmationDialog>
        </>
    )
}

DeleteButton.propTypes = {
    item: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteItem: () => {
            // Should not update store until user will leave this page
            return dispatch(removeItemPhoto(props.item, props.photo))
                .then(() => {
                    dispatch(showSuccess(`Upload was successfully deleted`));
                })
                .catch(error => {
                    dispatch(showError('Unexpected error happened...'));
                    throw error;
                });
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DeleteButton
);
