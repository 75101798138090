import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: '#02c9c9',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
}));

const IconButtonLoaderNew = ({ forwardedRef, disabled, submitting, children, buttonSize, progressProps, ...rest }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <IconButton
                ref={forwardedRef}
                {...rest}
                size={buttonSize}
                disabled={submitting || disabled}
            >
                {submitting && children}
                {!submitting && children}
            </IconButton>
            {submitting && <CircularProgress size={24} className={classes.buttonProgress} {...progressProps} />}
        </div>
    );
}

export default React.forwardRef((props, ref) => <IconButtonLoaderNew {...props} forwardedRef={ref} />);
