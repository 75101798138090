import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { RoutePaths } from '../../helpers/constants';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import ApplicationStatusBadge from './ApplicationStatusBadge';
import ApplicationAvatar from './ApplicationAvatar';

const ApplicationsList = ({ applications }) => {
    const history = useHistory();

    return <IonList>
        {applications.map(a => (<IonItem
            href={RoutePaths.dev.application(a.lcuid)}
            onClick={e => {
                e.preventDefault();
                history.push({ pathname: RoutePaths.dev.application(a.lcuid) })
            }}
            key={a.lcuid}>
            <ApplicationAvatar app={a} />
            <IonLabel>
                <h2>{a.name}</h2>
                <Box component="p" display="flex">
                    <ApplicationStatusBadge application={a} />
                    <Box component="span"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        ml={1}>{a.description}</Box>
                </Box>
            </IonLabel>
        </IonItem>))}
    </IonList>
}

const mapStateToProps = () => {
    return {
    }
}

ApplicationsList.propTypes = {
    applications: PropTypes.array.isRequired
}

export default connect(
    mapStateToProps
)(
    ApplicationsList
);
