import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import TextFieldEditable from '../../../components/inputs/TextFieldEditable';
import { required, maxLength } from '../../../helpers/validators';
import { setItemTitle } from '../../../actions/inventory';
import { showError, showSuccess } from '../../../actions/snackbar';
import { Global } from '../../../helpers/constants';

const TitleEditable = (props) => {
    const { item,
        label = "Caption",
        handleTitleChange,
        handleIsEdit,
        isEditDefault = false,
        autoFocus = false } = props;

    return (<TextFieldEditable
        ViewComponent={<Typography variant="h5" style={{ wordBreak: "break-word" }}>{item.title}</Typography>}
        label={label}
        variant={"outlined"}
        value={item.title}
        validators={[required, maxLength(Global.titleMaxLength)]}
        fullWidth={true}

        isEditDefault={isEditDefault}
        autoFocus={autoFocus}

        editButtonProps={{ size: 'medium' }}
        doneButtonProps={{ size: 'medium' }}
        cancelProps={{ size: 'medium' }}
        circularProgressProps={{ size: 40 }}
        handleDone={handleTitleChange}
        handleIsEdit={handleIsEdit}
    />)
}

TitleEditable.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleTitleChange: title => dispatch(setItemTitle(props.item, title))
            .then(() => dispatch(showSuccess(`New title was successfully saved`)))
            .catch(error => {
                dispatch(showError('Unexpected error happened...'));
                throw error;
            }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    TitleEditable
);
