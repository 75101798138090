import { Box } from '@material-ui/core';
import React from 'react';
import { PageTitle, StripeProvider } from '../../components';
import { withHomeLayout } from '../../layouts/HomeLayout';
import { useSelector } from 'react-redux';
import { selectedAccount } from '../../selectors/user';
import BillingSettings from '../../containers/settings/BillingSettings';
import PolicyFeatureToggle from '../../components/features/PolicyFeatureToggle';
import { AccountPolicy } from '../../helpers/lucoreConstants';


function Billing() {
    const account = useSelector(selectedAccount);

    return (
        <PolicyFeatureToggle policy={AccountPolicy.canEditPayments} >
            <StripeProvider>
                <Box>
                    <PageTitle title={"Billing"} />
                    <BillingSettings
                        lcuid={account.lcuid}
                    />
                </Box>
            </StripeProvider>
        </PolicyFeatureToggle>
    );
}

const BillingWithoutLayout = Billing;

export { BillingWithoutLayout };
export default withHomeLayout(BillingWithoutLayout);
