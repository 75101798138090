import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { showInfo, updateActiveMessage } from '../../actions/snackbar';

const TextNotification = ({ notification }) => (
    <Box>
        {notification.data
            && <>
                <Typography variant="subtitle1">{notification.data.title}</Typography>
                <Typography variant="body2">{notification.data.body}</Typography>
            </>
        }
    </Box>
);

const handleNotification = (notification, dispatch) => {
    dispatch(
        showInfo(
            <TextNotification notification={notification} />,
            {
                snackbarProps: {
                    onClick: () => {
                        handleClick();
                        dispatch(updateActiveMessage({ isOpen: false }));
                    }
                }
            })
    )
};
const handleClick = () => { }

export { TextNotification, handleNotification, handleClick }
