import {
    Button, ButtonGroup, Menu, MenuItem, Tooltip
} from '@material-ui/core';

import { MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { removeTemplate, resetState } from '../../actions/designer';
import { DriveTemplateStatus, RoutePaths } from '../../helpers/constants';
import {
    dirty, driveTemplateLcuid, frameIdSelected,
    isPublic, primary_image_public_url, status, templatesVisible
} from '../../selectors/designer';
import DesignerCopyTemplateDialog from './DesignerCopyTemplateDialog';
import DesignerPublishTemplateDialog from './DesignerPublishTemplateDialog';

const AdvancedMenu = ({
    removeTemplate, status, driveTemplate, driveTemplateLcuid, resetState }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [showCopyTemplateDialog, setShowCopyTemplateDialog] = useState(false);
    const [showPublishTemplateDialog, setShowPublishTemplateDialog] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <ButtonGroup size='small'>
                <Tooltip title={t('Advanced Settings / Options')}>
                    <Button
                        size="small"
                        onClick={handleClick}
                    >
                        <MoreVert />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            <Menu
                id="advanced-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem>
                    <Button color="primary" variant="contained" onClick={() => {
                        setShowCopyTemplateDialog(true);
                    }
                    }>{t('Duplicate')}</Button>
                </MenuItem>

                <MenuItem>
                    <Button color="primary" variant="contained" onClick={() => {
                        setShowPublishTemplateDialog(true);
                    }
                    }>{t('Publish')}</Button>
                </MenuItem>

                {status !== DriveTemplateStatus.deleted &&
                    <MenuItem>
                        <Button color="primary" variant="contained" onClick={() => {

                            if (window.confirm(
                                t('If you delete this template, existing users will still have access to it '
                                    + ' but will not be able to use it on new posts.'
                                    + 'Are you sure you want to remove this template?')
                            )) {
                                removeTemplate();
                                resetState()
                                handleClose()
                                history.push({ pathname: RoutePaths.designerTemplates });
                            }

                        }

                        }>{t('Delete')}</Button></MenuItem>
                }
            </Menu>

            {showCopyTemplateDialog && <DesignerCopyTemplateDialog
                driveTemplateLcuid={driveTemplateLcuid}
                driveTemplate={driveTemplate}
                open={showCopyTemplateDialog}
                handleClose={() => setShowCopyTemplateDialog(false)}
            />}

            {showPublishTemplateDialog && <DesignerPublishTemplateDialog
                driveTemplateLcuid={driveTemplateLcuid}
                driveTemplate={driveTemplate}
                open={showPublishTemplateDialog}
                handleClose={() => setShowPublishTemplateDialog(false)}
            />}
        </>
    )
}

const mapStateToProps = state => {
    return {
        templatesVisible: templatesVisible(state),
        driveTemplateLcuid: driveTemplateLcuid(state),
        driveTemplate: state.designer,
        dirty: dirty(state),
        status: status(state),
        frameIdSelected: frameIdSelected(state),
        isPublic: isPublic(state),
        primary_image_public_url: primary_image_public_url(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        removeTemplate: () => dispatch(removeTemplate()),
        resetState: () => dispatch(resetState())
    }
}

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        AdvancedMenu
    );
