import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTheme, Typography, Grid, Card, CardContent, CardActionArea, CardMedia, makeStyles } from '@material-ui/core';
import {
    approvalSetting, minModerationScore
} from '../../selectors/agencies'
import { ApprovalRunState, ApprovalTypes } from '../../helpers/constants'
import LCTypography from '../../components/material/LCTypography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    recommended: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 1.4,
        textAlign: 'center',
        marginTop: 8,
        display: 'inline',
        position: 'absolute',
        right: 10,
        top: 4
    }
}))

const ApprovalTypeIcon = ({ approvalSetting }) => {

    return <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <img src={`/assets/approvals/${approvalSetting}.png`} style={{ width: 48, height: 48 }} />
    </Box>
}

const ApprovalCard = ({ approvalSelected, onSelect, approvalSetting, title, description, recommended }) => {

    const classes = useStyles();

    const theme = useTheme();

    return <Card variant='outlined'
        style={{
            background: approvalSelected === approvalSetting ? theme.palette.primary.main : null,
            color: approvalSelected === approvalSetting ? theme.palette.primary.contrastText : null,
            height: '100%'
        }}
        onClick={onSelect}>
        <CardActionArea style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        }}>
            {recommended && <>
                <div className={classes.recommended}>* Recommended</div>
            </>}
            <CardMedia style={{ height: 48, paddingTop: 8 }}                    >
                <ApprovalTypeIcon approvalSetting={approvalSetting} />
            </CardMedia>
            <CardContent style={{ padding: 8 }}>

                <Typography variant='subtitle1' style={{ textAlign: 'center', fontWeight: 500, lineHeight: 1.4 }}>
                    {title}
                </Typography>
                <Typography component="p" variant="caption" style={{ textAlign: 'center', marginTop: 8 }}>
                    {description}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>

}

const ApprovalSettings = ({ agency, handleApprovalSettingsChange }) => {

    const { t } = useTranslation();

    const [approvalSelected, setApprovalSelected] = useState(approvalSetting(agency));

    useEffect(() => {
        setApprovalSelected(approvalSetting(agency))
    }, [agency])

    if (!agency || !agency.id) {
        return null
    }

    return (
        <Box>
            <Box mt={4}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} md={6}>
                        <ApprovalCard
                            lcNotes="Approved Only Run State, AI Auto Approver Disabled, Auto Reject disabled for both"
                            approvalSelected={approvalSelected}
                            onSelect={() => {
                                setApprovalSelected(ApprovalTypes.TeamApproved)
                                handleApprovalSettingsChange({
                                    run_state: ApprovalRunState.approvedOnly.id,
                                    allow_ai_auto_approve: false,
                                    default_ai_min_moderation_score: minModerationScore(agency),
                                    ai_auto_reject_untrusted: false,
                                    ai_auto_reject_score: false
                                })
                            }}
                            approvalSetting={ApprovalTypes.TeamApproved}
                            title={t('Team Approved')}
                            description={
                                <>{t('Only ads that have been manually approved by a member of your team will be able to run.\
                                All other ads will queue until they are either manually approved or rejected.')
                                }
                                    <br /><br />
                                    {t('Use this setting if you have notifications enabled, and you are always\
                                available to approve ads.')}</>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <ApprovalCard
                            lcNotes="Approved Only Run State, AI Auto Approver Enabled, auto reject disabled for both"
                            approvalSelected={approvalSelected}
                            recommended={true}
                            onSelect={() => {
                                setApprovalSelected(ApprovalTypes.AllApproved)
                                handleApprovalSettingsChange({
                                    run_state: ApprovalRunState.approvedOnly.id,
                                    allow_ai_auto_approve: true,
                                    default_ai_min_moderation_score: minModerationScore(agency),
                                    ai_auto_reject_untrusted: false,
                                    ai_auto_reject_score: false
                                })
                            }}
                            approvalSetting={ApprovalTypes.AllApproved}
                            title={t('Team Approved+Auto Approver')}
                            description={
                                <>{t('In addition to team member approvals, any ads that have been approved by the Lucit \
                            Auto Approver will also be able to run.  All other ads will queue until they are either approved or rejected.')}
                                    <br /><br />
                                    {t('Use this setting to reduce the amount of manual labor involved in approving ads\
                                     by allowing the auto approver \
                            to identify and approve ads from trusted users, that meet a high enough quality threshold')
                                    }</>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <ApprovalCard
                            lcNotes="Approved Only Run State, AI Auto Approver Enabled, auto reject enabled for both"
                            approvalSelected={approvalSelected}
                            onSelect={() => {
                                setApprovalSelected(ApprovalTypes.AutoApproverReject)
                                handleApprovalSettingsChange({
                                    run_state: ApprovalRunState.approvedOnly.id,
                                    allow_ai_auto_approve: true,
                                    default_ai_min_moderation_score: minModerationScore(agency),
                                    ai_auto_reject_untrusted: true,
                                    ai_auto_reject_score: true
                                })
                            }}
                            approvalSetting={ApprovalTypes.AutoApproverReject}
                            title={t('Let the Auto Approver Decide')}
                            description={<>{t('Let the auto approver make the decision to approve or reject ads automatically.')}
                                <br /><br />
                                {t('Use this setting if you do not wish to review ads manually and you trust the auto approver \
                            to make the right decisions. If the auto approver rejects an ad, it will notifify the user \
                            that their ad has been rejected, \
                            but it will not provide a reason, nor will it notify you that an ad has been rejected.')
                                }</>}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <ApprovalCard
                            lcNotes="All but rejected enabled, AI Auto Approver Enabled, auto reject disabled for both"
                            approvalSelected={approvalSelected}
                            onSelect={() => {
                                setApprovalSelected(ApprovalTypes.AllNotRejected)
                                handleApprovalSettingsChange({
                                    run_state: ApprovalRunState.allButRejected.id,
                                    allow_ai_auto_approve: true,
                                    default_ai_min_moderation_score: minModerationScore(agency),
                                    ai_auto_reject_untrusted: false,
                                    ai_auto_reject_score: false
                                })

                            }}
                            approvalSetting={ApprovalTypes.AllNotRejected}
                            title={t('Run All Ads')}
                            description={<>{
                                t('All ads that are posted by users, will automatically start running unless a member\
                            of your team reviews and manually rejects them.')}
                                < br /> <br />
                                {t('Use this setting if you trust the Lucit users who are posting ads to your screens,\
                            and you want their ads to start running automatically with no delays.   You will still have the ability to\
                            reject ads manually after they are posted if you wish.')
                                }</>}
                        />
                    </Grid>

                </Grid>
            </Box>

            <Box mt={2}>
                <LCTypography variant="body1">
                    Learn more about Approval Settings at&nbsp;
                    <a href="https://lucit.cc/post/lucit-content-security-overview/">lucit.cc</a>
                </LCTypography>
            </Box>
        </Box >
    )
}

export default ApprovalSettings
