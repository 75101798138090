import React from 'react';
import { GenericDialog } from '../../components/modals/GenericDialog';
import { useTranslation } from 'react-i18next';
import { ButtonLoader } from '../../components';
import { getUserRoles } from '../../selectors/user';
import UsersList from '../users/UsersList';
import NewInviteButton from '../users/NewInviteButton';

const AccountUsersDialog = ({ account, agency, open, handleClose }) => {
    const { t } = useTranslation();

    let roles = [];

    if (agency == null) {
        roles = getUserRoles(account);
    } else if (agency.id == account.agency_id) {
        roles = getUserRoles(agency).some(role => role == 'agency_admin')
            ? ['account_admin']
            : [];
    }

    return <>
        <GenericDialog
            title={t('{{account}} Team Members', { account: account.name })}
            dialogProps={{
                open,
                onClose: handleClose,
                fullWidth: true,
                maxWidth: 'sm'
            }}
            dialogContentProps={{
            }}
            ContentComponent={<>
                <NewInviteButton
                    lcuid={account.lcuid}
                    currentUserRoles={roles}
                    text={"Invite New"}
                />
                <UsersList
                    lcuid={account.lcuid}
                    currentUserRoles={roles}
                    enableRemove={true}
                />
            </>}
            ActionsComponent={<>
                <ButtonLoader
                    onClick={handleClose}
                    variant='contained'
                    color="secondary">
                    {t('OK')}
                </ButtonLoader>
            </>}
        />
    </>
}

export default AccountUsersDialog
