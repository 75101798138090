import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    useTheme, useMediaQuery
} from '@material-ui/core';
import { Dialog, CircularProgressCentered } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { lucitApi } from '../../services/lucitApi';
import { selectedAgency } from '../../selectors/user';
import { useSelector } from 'react-redux';
import { LIGHTNING_DEVICE_LAYOUT_UPDATED } from "../../helpers/actionTypes";
import { useDispatch } from 'react-redux';
import DeviceLayoutEditor from './DeviceLayoutEditor';
import DeviceLayoutDeleteButton from './DeviceLayoutDeleteButton';

const useStyles = makeStyles(theme => {
    return {
        content: {
            // height: 'calc(100vh - 180px)',
            padding: theme.spacing(0, 2.5),
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const EditDeviceLayoutDialog = ({ deviceLayout, open, handleClose }) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const agency = useSelector(selectedAgency);

    const [deviceLayoutLocal, setdeviceLayoutLocal] = useState(deviceLayout)

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)

    const updateDeviceLayout = () => {

        setLoading(true)

        const deviceLayoutPayload = {
            ...deviceLayoutLocal,
            agency_id: agency.id
        }

        lucitApi.lightning.updateDeviceLayout(deviceLayout.lcuid, deviceLayoutPayload)
            .then((response) => {
                dispatch({
                    type: LIGHTNING_DEVICE_LAYOUT_UPDATED,
                    deviceLayout: response.lightning_device_layout
                })
                handleClose()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isXs}
            title={t('Edit Device Layout')}
            onClose={handleClose}
            disableBackdropAndEscapeClose
        >
            <DialogContent className={classes.content}>

                Edit this device layout

                <DeviceLayoutEditor
                    deviceLayout={deviceLayoutLocal}
                    onDeviceLayoutChange={setdeviceLayoutLocal}
                />

            </DialogContent>

            <DialogActions>
                <DeviceLayoutDeleteButton
                    deviceLayout={deviceLayoutLocal}
                    handleDeleted={handleClose}
                />
                {loading && <CircularProgressCentered size={24} />}
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {
                    <Button
                        disabled={loading}
                        onClick={() => updateDeviceLayout()}
                        color="primary"
                        variant="contained">
                        Done
                    </Button>
                }
            </DialogActions>
        </Dialog >
    )

}

export default EditDeviceLayoutDialog;
