import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { withRouter } from 'react-router';
import { operatorIsAttachedToExport } from '../../selectors/exports'
import {
    addApxContract, deleteApxContract, addPxDealMap, deletePxDealMap, getExportUsers,
    addVistarInsertionOrderMap, deleteVistarInsertionOrderMap
} from '../../actions/exports';
import { showError, showSuccess } from '../../actions/snackbar';
import {
    Apparatix, Ayuda, Blip, Daktronics, ScalaAdams, ScalaLamar, Watchfire, ClearChannel, DoohClick, PlaceExchange, Vistar
} from '../../apps';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n'
import ApparatixFeatureToggle from '../../apps/apparatix/ApparatixFeatureToggle';
import F360FeatureToggle from '../../apps/apparatix/F360FeatureToggle';
import AyudaFeatureToggle from '../../apps/ayuda/AyudaFeatureToggle';
import BlipFeatureToggle from '../../apps/blip/BlipFeatureToggle';
import DaktronicsFeatureToggle from '../../apps/daktronics/DaktronicsFeatureToggle';
import AdamsOutdoorFeatureToggle from '../../apps/adams/AdamsOutdoorFeatureToggle';
import LamarISpotFeatureToggle from '../../apps/lamar/LamarISpotFeatureToggle';
import WatchfireFeatureToggle from '../../apps/watchfire/WatchfireFeatureToggle';
import ClearChannelFeatureToggle from '../../apps/clearchannel/ClearChannelFeatureToggle';
import DoohClickFeatureToggle from '../../apps/doohclick/DoohClickFeatureToggle';
import PlaceExchangeFeatureToggle from '../../apps/placeexchange/PlaceExchangeFeatureToggle';
import VistarFeatureToggle from '../../apps/vistar/VistarFeatureToggle';
import { getApplications } from '../../actions/applications';
import { useDispatch } from "react-redux";

const CampaignPlayerIntegrationsDialog = ({
    open,
    handleClose,
    campaign,
    agency,
    agencies,
    getCampaignUsers,
    addApxContract,
    deleteApxContract,
    addPxDealMap,
    deletePxDealMap,
    addVistarInsertionOrderMap,
    deleteVistarInsertionOrderMap,
}) => {

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedAgency, setSelectedAgency] = useState(agency);
    const selectedUserApiToken = selectedUser ? selectedUser.api_token : "";
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const apxContractPrefix = () => {

        if (selectedAgency && selectedAgency.options && selectedAgency.options.apx_prefix)
            return selectedAgency && selectedAgency.options && selectedAgency.options.apx_prefix

        //This is hacky shit - See D7296 and T6582
        if (selectedAgency && selectedAgency.options && selectedAgency.options.parent_agency_apx_prefix)
            return selectedAgency && selectedAgency.options && selectedAgency.options.parent_agency_apx_prefix

        return null
    }

    // Load Campaign Users and preselect it
    useEffect(() => {
        if (campaign) {
            getCampaignUsers(campaign.id)
                .then(users => {
                    users = users.filter(user => user.api_token);
                    setSelectedUser(users.find(() => true));
                });
        } else {
            setSelectedUser(null);
        }
    }, [campaign, getCampaignUsers]);

    // Preselect Agency
    useEffect(() => {
        if (agency == null) {
            setSelectedAgency(agencies.find(agency => operatorIsAttachedToExport(campaign, agency)));
        }
    }, [agency, campaign]);

    useEffect(() => {
        if (agency)
            dispatch(getApplications(agency.lcuid))
        else if (campaign?.account)
            dispatch(getApplications(campaign.account.lcuid));
    }, [])

    if (!campaign)
        return null;

    return (
        <Dialog
            open={open}
            maxWidth="xl"
            onClose={handleClose}
            scroll="paper"
        >
            <DialogContent dividers>
                <Box>

                    <>

                        <Box mt={3} ml={3}>
                            The Campaign Id for this Campaign is : <b>{campaign.hash_id}</b>
                        </Box>

                        <ApparatixFeatureToggle>
                            <Apparatix exportItem={campaign} apiToken={selectedUserApiToken}
                                contractPrefix={apxContractPrefix()} addApxContract={addApxContract}
                                deleteApxContract={deleteApxContract} apxSystemName="Apparatix" />
                        </ApparatixFeatureToggle>

                        <F360FeatureToggle>
                            <Apparatix exportItem={campaign} apiToken={selectedUserApiToken}
                                contractPrefix={apxContractPrefix()} addApxContract={addApxContract}
                                deleteApxContract={deleteApxContract} apxSystemName="Formetco F360" />
                        </F360FeatureToggle>

                        <BlipFeatureToggle>
                            <Blip exportItem={campaign} apiToken={selectedUserApiToken} />
                        </BlipFeatureToggle>

                        <WatchfireFeatureToggle>
                            <Watchfire exportItem={campaign} apiToken={selectedUserApiToken} />
                        </WatchfireFeatureToggle>

                        <DaktronicsFeatureToggle>
                            <Daktronics exportItem={campaign} apiToken={selectedUserApiToken} />
                        </DaktronicsFeatureToggle>

                        <LamarISpotFeatureToggle>
                            <ScalaLamar exportItem={campaign} apiToken={selectedUserApiToken} />
                        </LamarISpotFeatureToggle>

                        <AdamsOutdoorFeatureToggle>
                            <ScalaAdams exportItem={campaign} apiToken={selectedUserApiToken} />
                        </AdamsOutdoorFeatureToggle>

                        <AyudaFeatureToggle>
                            <Ayuda exportItem={campaign} apiToken={selectedUserApiToken} />
                        </AyudaFeatureToggle>

                        <ClearChannelFeatureToggle>
                            <ClearChannel exportItem={campaign} apiToken={selectedUserApiToken} />
                        </ClearChannelFeatureToggle>

                        <DoohClickFeatureToggle>
                            <DoohClick exportItem={campaign} apiToken={selectedUserApiToken} />
                        </DoohClickFeatureToggle>

                        <PlaceExchangeFeatureToggle>
                            <PlaceExchange exportItem={campaign} addPxDealMap={addPxDealMap} deletePxDealMap={deletePxDealMap} />
                        </PlaceExchangeFeatureToggle>

                        <VistarFeatureToggle>
                            <Vistar
                                exportItem={campaign}
                                addVistarInsertionOrderMap={addVistarInsertionOrderMap}
                                deleteVistarInsertionOrderMap={deleteVistarInsertionOrderMap} />
                        </VistarFeatureToggle>
                    </>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        agencies: state.user.agencies
    }
}

const mapStateToDispatch = (dispatch, ownProps) => {
    return {
        getCampaignUsers: id => dispatch(getExportUsers(id)),
        addApxContract: (apxContractId) => {
            return dispatch(addApxContract(ownProps.campaign.id, apxContractId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Contract Added')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error adding Contract')))
                })
        },
        deleteApxContract: (apxContractId) => {
            return dispatch(deleteApxContract(ownProps.campaign.id, apxContractId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Contract Deleted')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error deleting Contract')))
                })
        },
        addPxDealMap: (pxDealId) => {
            return dispatch(addPxDealMap(ownProps.campaign.id, pxDealId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Deal Id Added')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error adding Deal Id')))
                })
        },
        deletePxDealMap: (pxDealId) => {
            return dispatch(deletePxDealMap(ownProps.campaign.id, pxDealId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Deal Id Deleted')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error deleting Deal Id')))
                })
        },
        addVistarInsertionOrderMap: (vistarInsertionOrderId) => {
            return dispatch(addVistarInsertionOrderMap(ownProps.campaign.id, vistarInsertionOrderId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Insertion Order Id Added')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error adding Insertion Order Id')))
                })
        },
        deleteVistarInsertionOrderMap: (vistarInsertionOrderId) => {
            return dispatch(deleteVistarInsertionOrderMap(ownProps.campaign.id, vistarInsertionOrderId))
                .then(() => {
                    dispatch(showSuccess(i18next.t('Insertion Order Id Deleted')))
                })
                .catch(() => {
                    dispatch(showError(i18next.t('Error deleting Insertion Order Id')))
                })
        },
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapStateToDispatch
    )(
        CampaignPlayerIntegrationsDialog
    )
);
