import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import { ConfirmationDialog } from '../../components';
import { lucitApi } from "../../services/lucitApi"
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { useTranslation } from 'react-i18next';
import LCTypography from '../../components/material/LCTypography';
import { DRIVE_TEMPLATE_ELEMENT_DELETE } from '../../helpers/actionTypes';
import { useDispatch } from 'react-redux';

export const useStyles = makeStyles(theme => {
    return {
        removeButton: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,

            '&:hover': {
                background: theme.palette.error.dark,
            }
        }
    }
})

const DeleteElementButton = (props) => {
    const { driveTemplateElement, handleClose } = props;
    const classes = useStyles();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [canBeDeleted, setCanBeDeleted] = useState(false);
    const [reasons, setReasons] = useState([]);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (!isConfirmOpen)
            return;

        setLoading(true);

        lucitApi.objects.canBeDeleted(driveTemplateElement.lcuid)
            .then((response) => {
                setCanBeDeleted(response.can_be_deleted);
                setReasons(response.reasons);
                setLoading(false);
            });
    }, [driveTemplateElement, isConfirmOpen])

    const deleteElement = () => {
        lucitApi.objects.delete(driveTemplateElement.lcuid).then(() => {
            dispatch({
                type: DRIVE_TEMPLATE_ELEMENT_DELETE,
                payload: driveTemplateElement.id
            });
        }).then(() => {
            setIsConfirmOpen(false);
            handleClose();
        });
    }

    return (
        <>
            <Button
                color="inherit"
                variant="contained"
                className={classes.removeButton}
                startIcon={<Delete />}
                onClick={() => setIsConfirmOpen(true)}>
                {t('Delete')}
            </Button>

            <ConfirmationDialog
                title={t('Delete : ') + driveTemplateElement.name}
                dialogProps={{
                    open: isConfirmOpen,
                    onClose: () => setIsConfirmOpen(false),
                    keepMounted: false
                }}
                ConfirmButtonText={canBeDeleted ? t('Delete') : t('OK')}
                handleCancel={() => setIsConfirmOpen(false)}
                handleConfirm={() => {
                    canBeDeleted
                        ? deleteElement()
                        : setIsConfirmOpen(false);
                }}>

                {loading
                    && <CircularProgressCentered />}

                {!loading
                    && canBeDeleted
                    && <>
                        <LCTypography>Are you sure you want to delete <b>{driveTemplateElement.name}</b>?</LCTypography>
                        <LCTypography>
                            This action CANNOT be un-done and once you delete this object, you will have to recreate it.
                        </LCTypography>
                    </>
                }

                {!loading
                    && !canBeDeleted
                    && <>
                        <LCTypography>Can not be deleted because:</LCTypography>

                        <ul>
                            {Object.keys(reasons).map((reason_code, index) => {
                                return <li key={index}>{reasons[reason_code]}</li>
                            })}
                        </ul>
                    </>
                }
            </ConfirmationDialog>
        </>
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    DeleteElementButton
);
