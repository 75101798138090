import React, { useState } from 'react';
import { Grid, Divider, AccordionSummary, AccordionDetails, Box } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import { Bug } from 'react-ionicons';
import { IonItem, IonLabel } from '@ionic/react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
        content: {
            padding: "8px 0"
        },
        headerIcon: {
            marginBottom: -3,
            width: '1em',
            height: '1em'
        }
    }
});

function DeveloperObjectTools({ object, isDeveloperMode, additionalFields }) {
    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);

    const { t } = useTranslation();

    if (!isDeveloperMode || !object)
        return null;

    return (<Box mt={8} mb={8}>
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="DeveloperSettings-content"
                id="DeveloperSettings-header"
            >
                <LCTypography variant="h5" >
                    <Bug style={{
                        width: '1em',
                        height: '1em',
                        marginBottom: -3,
                        marginRight: 5
                    }} />
                    Developer Object Info</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        {object.slug && <IonItem lines='none'>
                            <IonLabel>
                                <h2>{t('slug')}</h2>
                                <code>{object.slug}</code>
                            </IonLabel>
                        </IonItem>
                        }
                        <IonItem lines='none'>
                            <IonLabel>
                                <h2>{t('lcuid')}</h2>
                                <code>{object.lcuid}</code>
                            </IonLabel>
                        </IonItem>

                        {additionalFields && additionalFields.map((field, index) => {
                            return (
                                <IonItem lines='none' key={index}>
                                    <IonLabel>
                                        <h2>{field.label}</h2>
                                        <code>{field.value}</code>
                                    </IonLabel>
                                </IonItem>
                            )
                        })}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </AccordionScrollable>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        isDeveloperMode: state.user.isDeveloperMode
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    DeveloperObjectTools
);
