import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    Box,
    Typography,
    CircularProgress,
    Divider
} from '@material-ui/core';
import { CircularProgressCentered, Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgencyExports, selectScreenGroupCampaigns } from '../../selectors/lightning';
import { showSuccess } from '../../actions/snackbar';
import RowsAccordions from './RowsAccordions';
import { AddCampaignsButton } from './AddCampaignsButton';
import { ScheduleBuilderDialog } from '../../containers/itemSchedule';
import { CampaignGroupIcon, ExportIcon, WeightsEditorBox } from '../../components';
import { InventoryExportCampaignClasses } from '../../helpers/constants';
import { useExportSettings } from './useExportSettings';
import { IconFromSet } from '../../components/IconFromSet';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
            minHeight: 200
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const EditScreenGroupCampaignsDialog = ({ group, open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getScreenGroupCampaigns = useSelector(selectScreenGroupCampaigns)

    const agencyExports = useSelector(selectAgencyExports);
    const campaignsOptions = agencyExports.filter(
        x => x.campaign_class === InventoryExportCampaignClasses.lucitProgrammatic.class ||
            x.campaign_class === InventoryExportCampaignClasses.operatorContract.class ||

            // Campaign Group that doesn't belong to another CampaignGroup
            x.campaign_class === InventoryExportCampaignClasses.group.class &&
            x.parent_exports.every(y => y.campaign_class !== InventoryExportCampaignClasses.group.class)
    );

    const [campaigns, setCampaigns] = useState(getScreenGroupCampaigns(group));
    const [campaignEdit, setCampaignEdit] = useState(null);

    const { loading, saving,
        weights, setWeights,
        scheduleDictionary,
        setSchedule,
        handleSave
    } = useExportSettings(group, campaigns);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                title={group.name}
                disableBackdropAndEscapeClose
            >
                <DialogContent className={classes.content}>
                    {loading
                        && <CircularProgressCentered size={40} />}

                    {!loading
                        && <>
                            <AddCampaignsButton
                                campaignsOptions={campaignsOptions}
                                campaigns={campaigns}
                                onChange={setCampaigns}
                            />

                            <Box mt={2} mb={2}>
                                {campaigns.length === 0
                                    && <Typography>
                                        {t('Please add at least one campaign')}
                                    </Typography>}

                                {campaigns.length > 0
                                    && <RowsAccordions
                                        rows={campaigns.map(e => ({
                                            ...e,
                                            description: e.campaign_class !== InventoryExportCampaignClasses.group.class
                                                && e.account.name,
                                            Icon: e.campaign_class === InventoryExportCampaignClasses.group.class
                                                ? <IconFromSet
                                                    iconKey={e?.options?.ui_options?.layout?.icon}
                                                    FallbackIcon={CampaignGroupIcon} />
                                                : <ExportIcon />
                                        }))}
                                        onDelete={s => setCampaigns(campaigns.filter(x => x.lcuid !== s.lcuid))}
                                        onScheduleClick={g => setCampaignEdit(g)}
                                        hasSchedule={g => scheduleDictionary[g.lcuid]?.schedules?.length > 0}
                                        hideScreenSelector={true}
                                    />}
                            </Box>

                            {
                                campaigns.length > 1
                                && <>
                                    <Divider />
                                    <Box mt={2} />
                                    <WeightsEditorBox weights={weights} setWeights={setWeights} />
                                </>
                            }
                        </>}
                </DialogContent >

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={saving}
                        startIcon={saving && <CircularProgress size={20} />}
                        onClick={() => handleSave(group)
                            .then(() => {
                                dispatch(showSuccess(`${group.name} campaigns were successfully updated`));
                                handleClose();
                            })
                        }
                        color="primary"
                        variant="contained">
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog >

            {campaignEdit
                && <ScheduleBuilderDialog
                    title={campaignEdit.name}
                    open={true}
                    handleClose={() => setCampaignEdit(null)}
                    schedule={scheduleDictionary[campaignEdit.lcuid].schedules}
                    setSchedule={newSchedule => setSchedule(campaignEdit, newSchedule)}
                    buttonTitle={t("OK")}
                    buttonProps={{ color: 'primary' }}
                />}

        </>
    )
}

export default EditScreenGroupCampaignsDialog;
