import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ListItemAvatar, ListItemText, Avatar, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import RegisterForm from '../containers/registration/RegisterForm';
import { RoutePaths, ErrorCodes, QueryString } from '../helpers/constants';
import { connect } from 'react-redux';
import { lucitApi } from '../services/lucitApi';
import { createRegistration, sendPhoneCode } from '../actions/register';
import { getFullPhone } from '../components/inputs/PhoneInput';
import { PageTitle } from '../components';
import { getQueryString } from '../helpers/url';
import { hasIcon } from '../selectors/account';
import { withPublicLayout } from '../layouts/PublicLayout';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(6),

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(8),
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },
    avatarContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    avatar: {
        maxWidth: 200
    }
}));

const RegistrationNew = (props) => {
    const { registrationId } = props;
    const classes = useStyles();
    const [object, setObject] = useState(null);
    const [isJoinableAccount, setIsJoinableAccount] = useState(false);

    const [askForJoinCode, setAskForJoinCode] = useState(true);

    let history = useHistory();

    const lcuid = getQueryString(history, QueryString.registrationLcuid);

    const getJoinableAccountLcuid = () => isJoinableAccount ? lcuid : null
    const { t } = useTranslation();

    useEffect(() => {
        if (!lcuid)
            return;

        lucitApi.objects.get(lcuid)
            .then(data => {

                setObject(data.object);

                if (data.object_class === "App\\Account" && data.object.options.public_can_join) {
                    setIsJoinableAccount(true)
                    setAskForJoinCode(false)
                }
            })
            .catch(() => {
                setIsJoinableAccount(false)
                setAskForJoinCode(true)
            })
    }, [lcuid])

    return (
        <Container component="main" maxWidth="xs">
            <PageTitle title={"Registration"} />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {t('Sign Up')}{isJoinableAccount && <> For </>}
                </Typography>
                {isJoinableAccount && <Box mt={3} p={5} style={{ display: "flex", alignItems: "center" }}>

                    <ListItemAvatar>
                        {hasIcon(object)
                            ? <Avatar className={classes.avatar} >
                                <img
                                    src={object.options.primary_image_public_url}
                                    className={classes.image}
                                    alt={object.name}
                                    title={object.name}
                                />
                            </Avatar>
                            : <Avatar />}
                    </ListItemAvatar>
                    <ListItemText>
                        <strong style={{ fontSize: "1.4em" }}>{object.name}</strong>
                    </ListItemText>
                </Box>}

                <RegisterForm
                    askForJoinCode={askForJoinCode}
                    hidePassword={true}
                    onSubmit={values => props.handleRegister(registrationId, values, getJoinableAccountLcuid())}
                    onResendCode={phone => props.handleResendCode(registrationId, phone)}
                    submitText="Continue"
                    join_lcuid={getJoinableAccountLcuid()}
                />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => {

    return {
        registrationId: state.register.registrationId
    }
}

const mapDisptachToProps = (dispatch, ownProps) => {
    return {
        handleResendCode: (_, phone) => {
            return dispatch(createRegistration(phone))
        },

        handleRegister: (registrationId, values, join_lcuid) => {
            const phone = getFullPhone(values.phone);

            if (!registrationId) {
                return dispatch(createRegistration(phone, join_lcuid, values.join_code))
                    .catch(error => {
                        if (error instanceof SubmissionError
                            && error.errors._error === ErrorCodes.userAlreadyExists) {

                            return dispatch(sendPhoneCode(phone))
                                .then(() => {
                                    ownProps.history.push({
                                        ...ownProps.history.location,
                                        pathname: RoutePaths.register,
                                        search: `?${QueryString.phoneNumber}=${values.phone.phoneNumber}`
                                            + `&${QueryString.countryCode}=${values.phone.countryCode}`
                                    })
                                })
                        }

                        throw error;
                    })
            }

            return lucitApi.registrations.confirmPhone(registrationId, values.code)
                .then(() => {
                    ownProps.history.push({
                        ...ownProps.history.location,
                        pathname: RoutePaths.registrationUserInfo(registrationId)
                    });
                })
        }
    }
}

export default connect(
    mapStateToProps,
    mapDisptachToProps
)(
    withRouter(
        withPublicLayout(RegistrationNew, { disablePaper: true, disablePermanentDrawer: true, showHomeButton: true })
    )
);
