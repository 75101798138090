import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone'
import { lucitApi } from "../../services/lucitApi";
import { assets } from '../../selectors/designer';
import { deleteAsset, setDraggingIsHappening, setDraggingIsDone } from '../../actions/designer'
import { useTranslation } from 'react-i18next';
import { DesignerChooseImageTypeDialog } from './DesignerChooseImageTypeDialog';
import { fileToBase64 } from '../../helpers/file';

const mapStateToProps = state => {
  return {
    assets: assets(state),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    deleteAsset: (assetId) => dispatch(deleteAsset(assetId)),
    setDraggingIsHappening: () => dispatch(setDraggingIsHappening()),
    setDraggingIsDone: () => dispatch(setDraggingIsDone()),
  }
}

const ImageUploaderDropZoneComponent = ({ children, showMessage, setDraggingIsHappening, setDraggingIsDone }) => {
  const [images, setImages] = useState([]);

  return (<>
    <UploadFileAndGetImage
      showMessage={showMessage}
      setDraggingIsHappening={setDraggingIsHappening}
      setDraggingIsDone={setDraggingIsDone}
      onUpload={(files) => {
        const promises = files.map(async file => {
          const fileBase64 = await fileToBase64(file);

          return {
            file,
            fileBase64
          }
        });

        Promise.all(promises)
          .then(setImages);
      }}
    >
      {children}
    </UploadFileAndGetImage>
    <DesignerChooseImageTypeDialog
      open={Boolean(images)}
      handleClose={() => setImages(null)}
      images={images}
    />
  </>)
}

const ImageAssetsComponent = ({ assets, deleteAsset, showMessage }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ImageUploaderDropZone
        showMessage={showMessage}
      >
        <Box>
          {assets.map((img, index) => {
            return (img
              && img.image
              && <Box p={3} key={index} >
                <img style={{ width: "100px", margin: "5px" }} src={img.image.options.public_url} />
                <Button
                  onClick={() => {
                    if (window.confirm(t('Are you sure you want to delete this image?')))
                      deleteAsset(img.image.id)
                  }}>{t('Delete')}</Button>
              </Box>)
          })}
        </Box>
      </ImageUploaderDropZone>
    </Box>
  )
}

export const ImageUploaderDropZone =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    ImageUploaderDropZoneComponent
  );

export const ImageAssets =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    ImageAssetsComponent
  );

export const UploadFileAndGetImage = ({ onUpload, children, showMessage, setDraggingIsHappening, setDraggingIsDone }) => {
  const baseStyle = {
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
    color: '#bdbdbd',
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': []
    },
    onDragEnter: () => {
      setDraggingIsHappening()
    },
    onDragLeave: () => {
      setDraggingIsDone()
    },
    onError: (err) => {
      console.error(err)
      setDraggingIsDone()
    },
    onDrop: acceptedFiles => {
      onUpload(acceptedFiles);
      setDraggingIsDone();
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  const { t } = useTranslation();

  return (<>
    <div className="container"
      style={{ cursor: 'pointer', marginBottom: 8 }}
      onClick={open}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div >
          {showMessage && <p
            style={{ textAlign: 'center' }}>
            {t('Click or Drag and drop here to add images')}
          </p>}
          {children}
        </div>
      </div>
    </div>
  </>)

}

export const generatePrimaryImageFromHtml = (lcuid, html, width, height) => {

  lucitApi.driveTemplates.generatePrimaryImageFromHtml(lcuid, {
    html: html,
    width: width,
    height: height,
  })
}
