import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { InfiniteScroll } from '../../components';
import { screens } from '../../selectors/billboard';
import { getPlayStatuses, getScreens } from '../../actions/billboards';
import { List, IconButton, InputAdornment, Tooltip, TextField, Box, Typography, Button } from '@material-ui/core';
import { Edit, FilterList } from '@material-ui/icons';
import { selectedAgency } from '../../selectors/user';
import { AddBillboardButton, BillboardListItem, DeleteBillboardButton } from '../billboards';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../helpers/constants';
import { ScrollContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { debouncePromise } from '../../helpers/async';
import { sortString } from '../../helpers/sorters';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';
import { Map } from '@material-ui/icons';
import { useHistory } from 'react-router';
import LCTypography from '../../components/material/LCTypography';

function AgencyScreens(props) {
    const { agency, screens, loadScreens, loadStatuses } = props;

    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const scrollContext = useContext(ScrollContext).current;

    const load = () => {
        setLoading(true);
        loadScreens(agency.id, 1, props.perPage).then(() => setLoading(false));
    }

    const loadNext = () => loadScreens(agency.id, props.page + 1, props.perPage, true);

    useEffect(() => {
        load();
        loadStatuses(agency.id);
    }, [])

    return <>
        <AddBillboardButton />

        <Button
            onClick={() => history.push({ pathname: RoutePaths.map })}
            startIcon={<Map />}
            style={{ marginLeft: 10 }}
            variant="outlined"
            color="primary">
            Map
        </Button>

        <Box mt={1} />

        {loading && <CircularProgressCentered />}

        {!loading && screens.length === 0
            && <LCTypography>
                Click &quot;Create&quot; to add new Billboard screen
            </LCTypography>}

        {screens.length > 0
            && <InfiniteScroll dataLength={screens.length}
                page={props.page}
                perPage={props.perPage}
                lastPage={props.lastPage}
                loading={props.loading}
                load={load}
                loadNext={loadNext}
                scrollableTarget={scrollContext.ref}

                endMessage={null}
            >
                <ScreenList
                    screens={screens} />
            </InfiniteScroll>}
    </>
}

const ScreenList = (props) => {
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [screens, setScreens] = useState(props.screens || []);
    const { t } = useTranslation();

    const searchDebounce = useCallback(debouncePromise((query) => setSearch(query), 500),
        [setSearch]);

    useEffect(() => {
        const screensFiltered = props.screens
            .filter(x => x.name.toUpperCase().includes(search.toUpperCase())
                || x.board_identifier.toUpperCase().includes(search.toUpperCase())
                || x.location.city.toUpperCase().includes(search.toUpperCase())
                || x.location.region.toUpperCase().includes(search.toUpperCase())
                || x.location.country.toUpperCase().includes(search.toUpperCase())
                || x.board_size_description.toUpperCase().includes(search.toUpperCase()))
            .sort((a, b) => sortString(a.name, b.name));

        setScreens(screensFiltered);
    }, [props.screens, search])

    return <Box style={{ maxWidth: 900 }} mt={2}>
        <TextField
            type="text"
            fullWidth
            variant="standard"
            placeholder={t("Filter...")}
            value={searchInput}
            onChange={(event) => {
                setSearchInput(event.target.value);
                searchDebounce(event.target.value);
            }}
            inputProps={{
                "aria-autocomplete": "both",
                "aria-haspopup": "false",
                "autoComplete": "off",
                "spellCheck": "false"
            }}
            InputProps={{
                startAdornment: <InputAdornment position="start">
                    <Tooltip title={t("Filter")}>
                        <FilterList fontSize="small" />
                    </Tooltip>
                </InputAdornment>
            }}
        />

        <Box mt={4} mb={2} pl={0} >
            <Typography variant="body2">
                {screens.length} {t("screens")}
            </Typography>
        </Box>

        <List>
            {screens.map(x => (
                <BillboardListItem key={x.id}
                    board={x}
                    last_pull_datetime={x.play_datetime}

                    Actions={<Box>
                        <Link to={RoutePaths.screen(x.id)}>
                            <IconButton>
                                <Edit />
                            </IconButton>
                        </Link>

                        <DeleteBillboardButton screen={x} />
                    </Box>}
                />
            ))}
        </List>
    </Box>
}

const mapStateToProps = (state) => {
    return {
        agency: selectedAgency(state),
        screens: screens(state),
        page: state.billboard.currentPage,
        perPage: state.billboard.perPage,
        loading: state.billboard.loading,
        lastPage: state.billboard.lastPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadScreens: (agencyId, page, perPage, isNextPage) => dispatch(getScreens({ agencyId, page, perPage }, isNextPage)),
        loadStatuses: (agencyId) => dispatch(getPlayStatuses(agencyId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyScreens);
