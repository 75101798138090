import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, useMediaQuery, useTheme, DialogContent, InputAdornment, TextField, ListSubheader, Box } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import LCTypography from '../material/LCTypography';
import { Dialog, Tooltip } from '../material';
import { withRouter } from 'react-router';
import { Global } from '../../helpers/constants';
import { getUserInfo } from '../../actions/user';
import { profiles } from '../../selectors/user';
import { connect } from 'react-redux';
import { ProfileListItems } from '../../containers/layout';
import { useTranslation } from 'react-i18next';
import { searchTermInText } from '../../helpers/string';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        height: '100%'
    },

    dialogContentRoot: {
        padding: 0
    },

    listSubheader: {
        background: 'white'
    },
}));

const ProfileSelectorDialog = (props) => {
    const { open, handleClose, onChange, getUserInfo } = props;

    const [search, setSearch] = useState("");
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const profiles = props.profiles.filter(profile => searchTermInText(search, [profile.name]));

    const handleChange = () => {
        handleClose();

        if (onChange)
            onChange();
    };

    useEffect(() => {
        if (open) {
            getUserInfo()
        }
    }, [open]);

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="xs"
            scroll="paper"
            open={open}
            aria-labelledby="profile-selector-dialog-title"
            onClose={handleClose}
            title={t('Select Profile')}
            TitleProps={{
                id: "profile-selector-dialog-title"
            }}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <List
                    disablePadding
                    subheader={Global.accountCountForFilter < props.profiles.length
                        && <ListSubheader className={classes.listSubheader}>
                            <SearchInput search={search} setSearch={setSearch} />
                        </ListSubheader>}
                >
                    <ProfileListItems
                        profiles={profiles}
                        onChange={handleChange}
                        showMoreEnabled={false}
                    />
                </List>

                {profiles.length === 0
                    && search
                    && <Box p={2}>
                        <LCTypography transProps={{ search: search }} >Nothing was found for <i>{{ search: search }}</i></LCTypography>
                    </Box>}
            </DialogContent>
        </Dialog>
    )
}

export const SearchInput = (props) => {
    const { search, setSearch } = props;

    return (<TextField
        type="text"
        fullWidth
        variant="standard"
        placeholder="Filter..."
        value={search || ''}
        onChange={(event) => setSearch(event.target.value)}
        inputProps={{
            "aria-autocomplete": "both",
            "aria-haspopup": "false",
            "autoComplete": "off",
            "spellCheck": "false"
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Tooltip title={"Filter"}>
                    <FilterIcon fontSize="small" />
                </Tooltip>
            </InputAdornment>
        }}
    />)
}

ProfileSelectorDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        profiles: profiles(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(getUserInfo()),
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProfileSelectorDialog)
);
