import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExportStatus, CampaignState } from "../../helpers/constants";

export const CampaignStatusText = ({ campaign }) => {
    const { t } = useTranslation();

    const adOrAds = campaign.last_run_written > 1 ? ' ads ' : ' ad ';

    return (<>

        {
            campaign.campaign_state == CampaignState.running.id &&
            <>
                {'Playing ' + campaign.last_run_written + adOrAds}
            </>
        }

        {
            campaign.campaign_state == CampaignState.draft.id &&
            <>
                {t('Campaign is in draft')}
            </>
        }

        {
            campaign.campaign_state == CampaignState.pending.id || campaign.campaign_state == CampaignState.pulling.id &&
            <>
                {t('Campaign is pending first plays : ' + campaign.last_run_written + adOrAds)}
            </>
        }

        {
            campaign.campaign_state == CampaignState.pendingTraffic.id &&
            <>
                {t('Campaign is waiting on media owner to schedule : ' + campaign.last_run_written + adOrAds)}
            </>
        }

        {
            campaign.campaign_state == CampaignState.rejected.id &&
            <>
                {t('Campaign was Rejected by media owner')}
            </>
        }

        {
            campaign.campaign_state == CampaignState.paused.id || campaign.campaign_state == CampaignState.pullingPaused.id &&
            <>
                {t('Campaign is Paused (no longer playing)')}
            </>
        }

        {
            campaign.campaign_state == CampaignState.complete.id &&
            <>
                {t('Campaign is complete')}
            </>
        }

        {
            campaign.status == ExportStatus.empty &&
            (campaign.campaign_state == CampaignState.running.id || campaign.campaign_state == CampaignState.pulling.id) &&
            <><br />
                {t('No ads - Your campaign will run your default creative '
                    + 'because all creative schedules have expired or none are selected')}
            </>
        }
    </>
    )
}

export default CampaignStatusText;
