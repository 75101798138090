import { FORGOTPASSWORD_SET, FORGOTPASSWORD_RESET } from "../helpers/actionTypes";

export const initialState = {
    isCodeSent: false,
    isCodeSending: false,
    phone: '',
    userId: null
}

export default function forgotPassword(state = initialState, { type, ...payload }) {
    switch (type) {
        case FORGOTPASSWORD_SET:
            return Object.assign({}, state, payload);
        case FORGOTPASSWORD_RESET:
            return Object.assign({}, state, initialState);
        default:
            return state
    }
}
