import { LOCATION_GETBYID, LOCATION_NEW_STREET_VIEW, LOCATION_UPDATE } from "../helpers/actionTypes";

export const initialState = {
    allIds: [],

    byId: {

    }
}

export default function locations(state = initialState, action) {
    const { type, ...payload } = action;

    switch (type) {
        case LOCATION_NEW_STREET_VIEW:
        case LOCATION_GETBYID:
            return {
                byId: {
                    ...state.byId,

                    [payload.data.id]: payload.data
                },
                allIds: state.allIds.includes(payload.id)
                    ? state.allIds
                    : [
                        ...state.allIds,
                        payload.data.id
                    ]
            }
        case LOCATION_UPDATE:
            return {
                ...state,

                byId: {
                    ...state.byId,

                    [payload.location.id]: {
                        ...state.byId[payload.location.id],
                        ...payload.location
                    }
                },
            }
        default:
            return state
    }
}
