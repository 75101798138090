/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles, useMediaQuery, useTheme, InputAdornment, Box
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ButtonLoader, Dialog } from '../../components/material';
import TextField from '../../components/inputs/TextField';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
        },
    }
})

const SetBillboardSizeDialog = (props) => {
    const { open,
        title,
        handleSave,
        handleClose } = props;

    const { t } = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [submitting, setSubmitting] = useState(false);
    const [size, setSize] = useState({ width: '', height: '' });

    useEffect(() => {
        const newSize = {
            width: props.defaultWidth || size.width,
            height: props.defaultHeight || size.height
        }

        setSize(newSize)
    }, [props.defaultWidth, props.defaultHeight])

    return (
        <Dialog
            open={open}
            fullWidth
            fullScreen={isXs}
            title={title}
            onClose={handleClose}
        >
            <DialogContent className={classes.content}>
                <Box display="flex">
                    <TextField
                        value={size.width}
                        onChange={e => setSize({ ...size, width: e.target.value })}
                        {...{
                            label: t("Width"),
                            variant: "outlined",
                            required: true,
                            fullWidth: true,
                            type: 'number',
                            min: 0,
                            id: "width",
                            InputProps: {
                                endAdornment: <InputAdornment position="end" >px</InputAdornment>
                            },
                            inputProps: {
                                min: 1
                            }
                        }}
                    />
                    <Box ml={2} />
                    <TextField
                        value={size.height}
                        onChange={e => setSize({ ...size, height: e.target.value })}
                        {...{
                            label: t("Height"),
                            variant: "outlined",
                            required: true,
                            fullWidth: true,
                            id: "height",
                            type: 'number',
                            InputProps: {
                                endAdornment: <InputAdornment position="end" >px</InputAdornment>
                            },
                            inputProps: {
                                min: 1
                            }
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <ButtonLoader
                    submitting={submitting}
                    onClick={() => {
                        setSubmitting(true);
                        (handleSave(size) || Promise.resolve())
                            .then(handleClose)
                            .finally(() => setSubmitting(false))
                    }}
                    className={classes.button}
                >
                    Save
                </ButtonLoader>
            </DialogActions>
        </Dialog >
    )
}

const mapStateToProps = (state) => {

    return {
    }
}

const mapStateToDispatch = (dispatch) => {
    return {

    }
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(
    SetBillboardSizeDialog
)
