import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, useMediaQuery, useTheme, DialogContent, InputAdornment, TextField, ListSubheader, Box } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import LCTypography from '../material/LCTypography';
import { Dialog, Tooltip } from '../material';
import { withRouter } from 'react-router';
import { Global, ProfileType } from '../../helpers/constants';
import { getUserInfo, selectAccount } from '../../actions/user';
import { accountsTreeSearch, getUser } from '../../selectors/user';
import { connect } from 'react-redux';
import { AccountListItems } from '../../containers/account';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    avatar: {
        background: 'none'
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top'
    },

    expandAction: {
        cursor: 'pointer',
        display: 'flex',
        padding: theme.spacing(2),
        right: 0
    },

    dialogPaper: {
        height: '100%'
    },

    dialogContentRoot: {
        padding: 0
    },

    listSubheader: {
        background: 'white'
    },
}));

const AccountSelectorDialog = ({ user, accountsTreeSearch,
    open, handleClose, handleAccountSelect, onChange, getUserInfo }) => {
    const [search, setSearch] = useState("");
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const accountsTree = accountsTreeSearch(search);
    const { t } = useTranslation();

    const handleChange = (account) => {
        handleAccountSelect(account);
        handleClose();
        if (onChange)
            onChange();
    };

    useEffect(() => {
        if (open) {
            getUserInfo()
        }
    }, [open]);

    return (
        <Dialog
            fullScreen={isXs}
            fullWidth
            maxWidth="xs"
            scroll="paper"
            open={open}
            aria-labelledby="account-selector-dialog-title"
            onClose={handleClose}
            title={t('Select Account')}
            TitleProps={{
                id: "account-selector-dialog-title"
            }}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogContent className={classes.dialogContentRoot}>
                <List
                    disablePadding

                    subheader={Global.accountCountForFilter < user.accounts.length
                        && <ListSubheader className={classes.listSubheader}>
                            <AccountSearchInput search={search} setSearch={setSearch} />
                        </ListSubheader>}
                >
                    <AccountListItems
                        accounts={accountsTree}
                        handleChange={handleChange}
                        level={0}
                    />
                </List>

                {accountsTree.length === 0
                    && search
                    && <Box p={2}>
                        <LCTypography transProps={{ inSearch: search }}>Nothing was found for <i>{{ inSearch: search }}</i></LCTypography>
                    </Box>}
            </DialogContent>
        </Dialog>
    )
}

const AccountSearchInput = (props) => {
    const { search, setSearch } = props;
    const { t } = useTranslation();

    return (<TextField
        type="text"
        fullWidth
        variant="standard"
        placeholder="Filter..."
        value={search || ''}

        onChange={(event) => setSearch(event.target.value)}
        inputProps={{
            "aria-autocomplete": "both",
            "aria-haspopup": "false",
            "autoComplete": "off",
            "spellCheck": "false"
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Tooltip title={t('Filter')}>
                    <FilterIcon fontSize="small" />
                </Tooltip>
            </InputAdornment>
        }}
    />)
}

AccountSelectorDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        user: getUser(state),
        accountsTreeSearch: search => accountsTreeSearch(state, search)
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAccountSelect: account => {
            dispatch(selectAccount(account, {
                animate: true,
                onSwitched: () =>
                    ownProps.history.push({ pathname: ProfileType.account.path })
            }));
        },
        getUserInfo: () => dispatch(getUserInfo()),
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AccountSelectorDialog)
);
