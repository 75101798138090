import React from 'react';
import { Box, makeStyles, FormControl, FormGroup, FormControlLabel, Checkbox, Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',

            [theme.breakpoints.down(520)]: {
                justifyContent: 'space-between'
            }
        },
        slider: {
            marginLeft: "25px",
            marginRight: "25px",
        },
        sliderEnds: {
            whiteSpace: "nowrap"
        }
    }
});

const DaysOfTheWeekInput = ({ daysOfTheWeek, setDaysOfTheWeek }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event) => {
        const dayOfTheWeek = parseInt(event.target.name);
        const isChecked = event.target.checked;

        if (isChecked) {
            setDaysOfTheWeek([...daysOfTheWeek, dayOfTheWeek]);
        } else {
            setDaysOfTheWeek(daysOfTheWeek.filter((day) => day !== dayOfTheWeek))
        }
    }

    return <Box className={classes.root}>
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                <Grid container>

                    {!daysOfTheWeek.length
                        && <Grid item xs={12}><Alert severity="warning">{t('Please select at least one day of the week')}</Alert></Grid>
                    }

                    <Grid item xs={2}>

                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(1)} onChange={handleChange} name="1" />
                            }
                            label={t('Mon')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(2)} onChange={handleChange} name="2" />
                            }
                            label={t('Tue')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(3)} onChange={handleChange} name="3" />
                            }
                            label={t('Wed')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(4)} onChange={handleChange} name="4" />
                            }
                            label={t('Thr')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(5)} onChange={handleChange} name="5" />
                            }
                            label={t('Fri')}
                        />
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(6)} onChange={handleChange} name="6" />
                            }
                            label={t('Sat')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={daysOfTheWeek.includes(0)} onChange={handleChange} name="0" />
                            }
                            label={t('Sun')}
                        />
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', width: 100 }}>
                        {daysOfTheWeek.length !== 7
                            && <Button
                                size='small'
                                variant='outlined'
                                onClick={() => setDaysOfTheWeek([0, 1, 2, 3, 4, 5, 6])}>
                                {t('Reset')}
                            </Button>}
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    </Box>
};

export { DaysOfTheWeekInput }
