import React, { useState } from 'react';
import {
    DialogContent, DialogActions, Button, makeStyles,
    Box,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { Dialog } from '../../components/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectScreenGroupScreens } from '../../selectors/lightning';
import { lucitApi } from '../../services/lucitApi';
import { showSuccess } from '../../actions/snackbar';
import RowsAccordions from './RowsAccordions';
import { AddScreensButton } from './AddScreensButton';
import { LIGHTNING_SET_SCREENGROUP_SCREENS } from '../../helpers/actionTypes';

const useStyles = makeStyles(theme => {
    return {
        content: {
            padding: theme.spacing(1, 2.5),
            minHeight: 200
        },
        map: {
            width: '100%',
            height: '500px',
        }
    }
})

const EditScreenGroupScreensDialog = ({ group, open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getScreenGroupScreens = useSelector(selectScreenGroupScreens)

    const [saving, setSaving] = useState(false);
    const [screens, setScreens] = useState(getScreenGroupScreens(group));

    const handleSave = () => {
        setSaving(true)
        return lucitApi.exports
            .setParentExports(group.lcuid, screens.map(s => s.options.connected_export_lcuid))
            .then(() => {
                dispatch({
                    type: LIGHTNING_SET_SCREENGROUP_SCREENS,
                    group,
                    screens,
                })
                dispatch(showSuccess(`${group.name} screens were successfully updated`))
                handleClose();
            })
            .finally(() => setSaving(false));
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                title={group.name}
                disableBackdropAndEscapeClose
            >
                <DialogContent className={classes.content}>
                    <AddScreensButton
                        screens={screens}
                        onChange={setScreens}
                    />

                    <Box mt={2} mb={2}>
                        {screens.length === 0
                            && <Typography>
                                {t('Please add at least one screen')}
                            </Typography>}

                        {screens.length > 0
                            && <RowsAccordions
                                rows={screens}
                                onDelete={s => setScreens(screens.filter(x => x.lcuid !== s.lcuid))}
                                hideSchedule
                                hideScreenSelector
                            />}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={saving}
                        startIcon={saving && <CircularProgress size={20} />}
                        onClick={handleSave}
                        color="primary"
                        variant="contained">
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default EditScreenGroupScreensDialog;
