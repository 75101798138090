import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MaterialTooltip, Box } from '@material-ui/core';

export const Tooltip = (props) => {
    const { hidden, children, containerProps, enterTouchDelay = 0, ...rest } = props;

    if (hidden) {
        return children;
    }

    return <MaterialTooltip
        // On mobile, the tooltip is displayed when the user longpresses the element and hides after a delay of 1500ms.
        // You can disable this feature with the disableTouchListener property.
        enterTouchDelay={enterTouchDelay}
        {...rest}
    >
        {/* https://stackoverflow.com/questions/48930334/mui-next-tooltip-does-not-show-on-hover 
          * The Tooltip component works by responding to events on its children components (onMouseEnter, onMouseLeave, and a couple of others). 
          * It registers to those events by applying props to the top-level children.
          * When you wrap the Checkbox in a div, the div receives the onMouseEnter and onMouseLeave props and so the hover works properly.
          */}
        <Box {...containerProps}>
            {children}
        </Box>
    </MaterialTooltip>
};

Tooltip.propTypes = {
    children: PropTypes.object.isRequired
}

export default Tooltip;
