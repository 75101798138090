import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { setupIonicReact } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
import '@ionic/react/css/core.css';
import './theme/defaultIonTheme.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "jquery";

import { defaultTheme } from './theme/defaultTheme';
import Loader from './components/Loader';
import { store, persistor } from './reducers';
import { restore } from './actions/user';
import Snackbar from './components/material/Snackbar';
import SystemNotification from './components/SystemNotification';
import InternetMonitor from './components/InternetMonitor';
import * as notificationsWorker from './notificationsWorker';
import AppUrlListener from './components/AppUrlListener';
import GAUrlListener from './components/GAUrlListener';
import HashToBrowserRoutes from './components/HashToBrowserRoutes';
import { ErrorBoundary } from './components/errors/ErrorBoundary';
import Tours from './containers/tours/Tours';
import { environment } from './helpers/environment';
import ContextInitializer from './components/ContextInitializer';
import Eula from './components/eula/Eula';
import EulaGate from './components/eula/EulaGate';
import { history } from './helpers/history';
import { GoogleMapsContext } from './contexts';
import { useGoogleMapScripts } from './helpers/hooks';
import Routes from './Routes';
import { PushNotificationsInfoDialog } from './components/modals/PushNotificationsInfoDialog';

setupIonicReact({
  mode: isPlatform('ios')
    ? 'ios'
    : 'md'
});
momentDurationFormatSetup(moment);

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    store.dispatch(restore())
      .then(() => {
        notificationsWorker.promptWhenIsLoggedIn();
      })
      .finally(() => setLoaded(true));
  }, []);

  const gmContext = useGoogleMapScripts(environment.GOOGLE_API_KEY)

  return (
    <ErrorBoundary isFatal fullSize>
      <Provider {...{ store }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={defaultTheme}>
            <PersistGate persistor={persistor}>
              <HashToBrowserRoutes />

              <Router history={history}>
                <CssBaseline />
                <AppUrlListener />
                <GAUrlListener />
                <ContextInitializer />

                {loaded
                  && (
                    <ErrorBoundary isFatal fullSize>
                      <GoogleMapsContext.Provider value={gmContext}>
                        <Routes />
                      </GoogleMapsContext.Provider>
                    </ErrorBoundary>
                  )}

                <Eula />

                <EulaGate>
                  <Tours />
                </EulaGate>

                <Loader />
                <Snackbar />
                <SystemNotification />
                <InternetMonitor />
                <PushNotificationsInfoDialog />
              </Router>
            </PersistGate>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </ErrorBoundary>
  )
};

export default App;
