import React from "react";
import clx from "classnames";
import {
    Card, CardActionArea, CardContent, CardMedia, Typography,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => {
    return {
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px 12px'
        },
        cardSelected: {
            background: theme.palette.primary.main,
            color: `${theme.palette.primary.contrastText}`
        },
        cardDescription: {
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginTop: 8
        },
        cardDescriptionSelected: {
            color: 'inherit'
        },
        media: {
            height: '80px',
            width: '100%',
            backgroundSize: 'contain'
        }
    }
});

export const CustomCard = ({ title, description, imageUrl, Icon,
    isSelected, select, className }) => {
    const classes = useStyles();

    return <Card variant='outlined'
        className={isSelected ? classes.cardSelected : ''}
        style={{ height: '100%', minHeight: 120 }}
        onClick={() => select()}>
        <CardActionArea className={clx(classes.card, className)}>
            {imageUrl
                && <CardMedia
                    image={imageUrl}
                    title={title}
                    className={classes.media}
                />}
            {Icon
                && <CardMedia style={{ height: 48 }}                    >
                    {Icon}
                </CardMedia>}

            <CardContent style={{ width: '100%', padding: 8 }}>
                <Typography variant='subtitle1' style={{ textAlign: 'center', fontWeight: 500, lineHeight: 1.4 }}>
                    {title}
                </Typography>
                {description
                    && <Typography component="p" variant="body2"
                        className={clx(classes.cardDescription,
                            {
                                [classes.cardDescriptionSelected]: isSelected
                            })}>
                        {description}
                    </Typography>}
            </CardContent>
        </CardActionArea>
    </Card >
}
