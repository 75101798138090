import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography, Link } from '@material-ui/core';
import { RoutePaths } from '../../../helpers/constants';
import BillboardSkeleton from '../../../components/images/BillboardSkeleton';
import { photoRatio } from '../../../selectors/inventory';
import PhotoOverrideButton from './PhotoOverrideButton';
import { Link as RouterLink } from 'react-router-dom';
import { InventoryItemClasses } from '../../../helpers/constants';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from '../../../components/material/CircularProgressCentered';
import { InventoryItemCreativeState } from '../../../helpers/constants'

const BestPhotoOverrideButton = ({ item, photo, aspect, overrideButtonRef }) => {

    const { t } = useTranslation();

    if (item.item_class === InventoryItemClasses.photoStream.class) {

        //We put a hidden button here, so, if you click on the image, you can upload a new photo
        return <Typography variant="body2" style={{ display: "none" }}>
            <PhotoOverrideButton
                buttonRef={overrideButtonRef}
                item={item}
                buttonText={t('Change Photo')}
                aspect={aspect}
                askForCameraOnAndroid={true}
            />
        </Typography>
    }

    if (item.item_class === InventoryItemClasses.creative.class || item.item_class === InventoryItemClasses.defaultCreative.class) {
        return <Typography variant="body2">
            <PhotoOverrideButton
                buttonRef={overrideButtonRef}
                forceShow={true}
                item={item}
                replacesPhoto={photo}
                ButtonProps={{
                    variant: 'text',
                    color: 'secondary',
                    startIcon: null
                }}
                buttonText={t('Change this Image')}
                ImageUploadDialogProps={{
                    title: t('Upload new creative for {{itemTitle}}', { itemTitle: item.title }),
                    aspect: photoRatio(photo),
                    UploadInputProps: {
                        minWidth: photo.options.dimension_width,
                        minHeight: photo.options.dimension_height
                    }
                }}
            />
        </Typography>
    }

    return <></>;

}

const CreativeListItem = ({ item, photo, aspect, boardFormat }) => {

    const overrideButtonRef = useRef(null)

    return (
        <Grid>
            <Box position="relative" style={{ border: "1px solid grey", paddingTop: "10px" }}>
                <Box style={{ padding: "4px" }} mb={3}>
                    <Grid container>
                        <Grid item xs={7}>
                            <Box style={{ padding: "6px" }}>
                                <Typography variant="body2">

                                    <strong>{boardFormat && boardFormat.name}</strong> : <Link
                                        component={RouterLink}
                                        to={RoutePaths.inventoryPhoto + `/${photo.id}`}>
                                        {photo.options.dimension_width}x{photo.options.dimension_height}
                                    </Link>{item.creative_state === InventoryItemCreativeState.building &&
                                        <CircularProgressCentered size={10} rootStyle={{ margin: "3px", display: "inline" }} />
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <BestPhotoOverrideButton
                                item={item}
                                photo={photo}
                                aspect={aspect}
                                overrideButtonRef={overrideButtonRef}
                            />
                        </Grid>
                    </Grid>

                </Box>

                <Box
                    onClick={() => overrideButtonRef && overrideButtonRef.current && overrideButtonRef.current.click()}
                >
                    <BillboardSkeleton
                        ratio={photoRatio(photo)}
                        photoSrc={photo.public_url}

                        SvgProps={{
                            width: '100%'
                        }}
                    />
                </Box>
            </Box>
        </Grid >
    )

}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    CreativeListItem
);
