import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { OgMetatags, PageTitle } from '../../components';
import AgencyProfile from '../../containers/agency/AgencyProfile';

import ButtonScrollToTopFab from '../../components/material/ButtonScrollToTopFab';
import { hasIcon } from '../../selectors/account';
import AgencyFeed from '../../containers/agency/AgencyFeed';
import { withConditionalLayout } from '../../layouts/ConditionalLayout';

function Profile(props) {
    const { object } = props;

    return <>
        <Box component={Paper} p={3} style={{ maxWidth: '600px', margin: 'auto' }}>
            <PageTitle title={object.name} />
            <OgMetatags
                title={object.name}
                image={hasIcon(object)
                    ? object.options.primary_image_public_url
                    : null} />

            <AgencyProfile agency={object} />
        </Box>

        <Box mt={2}>
            <AgencyFeed agency={object} />
        </Box>

        <ButtonScrollToTopFab />
    </>
}

export default withConditionalLayout(
    Profile, { publicOptions: { disablePaper: true } }
)
