import React, { Portal } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { BottomSheetContext } from "../contexts";

export const BottomSheetPortal = (props) => {
    const { title } = props;
    const { modalContent, setTitle } = useContext(BottomSheetContext);

    useEffect(() => {
        setTitle(title);
    }, [])

    if (!modalContent.current) {
        return null;
    }

    return <Portal container={modalContent.current}>
        {props.children}
    </Portal>
}
