import semver from 'semver'
import { isPlatform } from '@ionic/react';
import { AppVersion } from '@ionic-native/app-version';
import { Market } from '@ionic-native/market';

import { lucitApi } from "./services/lucitApi";
import { store } from "./reducers";
import { swInitiazlied, newUpdateAvailable } from "./actions/user";
import { showSystemNotification } from "./actions/systemNotification";
import { IosAppId } from "./helpers/constants";

let intervalId = null;

export function register(config) {
    const platform = config.platforms.find(p => isPlatform(p));

    if (platform) {
        intervalId = setInterval(() => handleCheckUpdates(platform), config.intervalInMs);

        store.dispatch(swInitiazlied(() => handleCheckUpdates(platform)));

        console.log("Starting UpdateWorker...", platform, config, intervalId);
        return;
    }
}

export function unregister() {
    if (intervalId) {
        clearInterval(intervalId);
    }
}

function handleCheckUpdates(platform) {
    return lucitApi.getLatestVersion()
        .then(async (versions) => {
            const versionCurrent = await AppVersion.getVersionNumber();
            const versionNew = versions[platform];

            if (!versionNew) {
                console.warn("Unable to get latest version for platform", platform, versions);

                // assume that client is on latest version, if something is wrong
                return true;
            }

            if (semver.gt(versionNew, versionCurrent)) {
                store.dispatch(showSystemNotification('update',
                    'New version is available, please click to reload',
                    {
                        duration: null,
                        onClick: () => handleUpdate(platform),
                        action: {
                            hideClose: true,
                        }
                    })
                );
                store.dispatch(newUpdateAvailable(() => handleUpdate(platform)));
                return;
            }

            return true;
        })
}

function handleUpdate(platform) {
    const getAppIdMap = {
        "android": () => AppVersion.getPackageName(),

        // eslint-disable-next-line no-undef
        "ios": () => Promise.resolve(IosAppId)
    }

    const getAppId = getAppIdMap[platform];

    if (!getAppId) {
        console.warn(`Can't find getAppId method for platform '${platform}'`);
        // eslint-disable-next-line no-undef
        return Promise.reject();
    }

    return getAppId()
        .then(appId => Market.open(appId));
}
