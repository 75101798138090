import React from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgressCentered from '../material/CircularProgressCentered';

const TextField = ({ input, label, meta: { touched, error } = {},
    children, loading, InputProps, inputProps, clearable, forwardedRef, ...custom }) => {
    const InputPropsCombined = Object.assign({},
        InputProps,
        clearable && input.value && {
            endAdornment: <InputAdornment>
                <IconButton
                    size="small"
                    onClick={() => input.onChange('')}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            </InputAdornment>
        },
        loading && {
            endAdornment: <InputAdornment>
                <CircularProgressCentered size={25} />
            </InputAdornment>
        });

    const inputPropsCombined = Object.assign({},
        inputProps,

        //Disable dropdown icon for select field while loading
        loading && custom.select && {
            IconComponent: () => null
        });

    return (
        <MaterialTextField
            ref={forwardedRef}
            label={label}
            error={touched && !!error}
            InputProps={InputPropsCombined}
            inputProps={inputPropsCombined}
            {...input}
            {...custom}
            helperText={touched && error || custom.helperText}
        >
            {children}
        </MaterialTextField>
    );
}

export default React.forwardRef((props, ref) => <TextField {...props} forwardedRef={ref} />);
