/* eslint-disable */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function FileText(props) {
    return <SvgIcon {...props} viewBox="0 0 24 24">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.5 6.21707V21.7597C22.5 22.79 21.6661 23.625 20.6379 23.625H6.36208C5.33351 23.625 4.5 22.79 4.5 21.7597V1.86527C4.5 0.834996 5.33351 0 6.36208 0H16.2929L22.5 6.21707Z" fill="#DFE2E6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.5 5.96378V6.75H17.3178C16.2448 6.75 15.75 5.87976 15.75 4.80659V0H16.5348L22.5 5.96378Z" fill="#747B82" />
        <path d="M19.5 19.0625C19.5 19.475 19.1625 19.8125 18.75 19.8125H2.25C1.8375 19.8125 1.5 19.475 1.5 19.0625V11.5625C1.5 11.15 1.8375 10.8125 2.25 10.8125H18.75C19.1625 10.8125 19.5 11.15 19.5 11.5625V19.0625Z" fill="#414549" />
        <path d="M6.22432 14.1628H5.17507C4.65307 14.1628 4.65307 13.3956 5.17507 13.3956H8.01157C8.54557 13.3956 8.54557 14.1628 8.01157 14.1628H7.01557V17.1906C7.01557 17.7118 6.22357 17.7246 6.22357 17.1906V14.1628H6.22432Z" fill="white" />
        <path d="M10.5196 14.852L11.5636 13.5448C11.8756 13.1375 12.4809 13.6588 12.1396 14.0488C11.7796 14.4748 11.4023 14.9368 11.0416 15.4093L12.2656 16.9325C12.5949 17.3825 11.9356 17.8145 11.6116 17.3705L10.5083 15.9553L9.42309 17.4005C9.11709 17.8378 8.43909 17.3465 8.78184 16.9385L9.98634 15.4093C9.60909 14.936 9.24309 14.4748 8.87709 14.0488C8.49909 13.5988 9.20109 13.1375 9.47634 13.5575L10.5196 14.852Z" fill="white" />
        <path d="M13.9824 14.1628H12.9339C12.4119 14.1628 12.4119 13.3956 12.9339 13.3956H15.7696C16.3036 13.3956 16.3036 14.1628 15.7696 14.1628H14.7744V17.1906C14.7744 17.7118 13.9824 17.7246 13.9824 17.1906V14.1628V14.1628Z" fill="white" />
        <path d="M18.75 19.8125H4.5V20.5625H18.75C19.1625 20.5625 19.5 20.225 19.5 19.8125V19.0625C19.5 19.475 19.1625 19.8125 18.75 19.8125Z" fill="#CAD1D8" />
    </SvgIcon>
}
