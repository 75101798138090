import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UndoIcon from '@material-ui/icons/Undo';

import TextFieldEditable from '../../../components/inputs/TextFieldEditable';
import { required, minOrEqual, maxOrEqual } from '../../../helpers/validators';
import { useState } from 'react';
import { setPriceOverride, clearPriceOverride } from '../../../actions/inventory';
import { showSuccess } from '../../../actions/snackbar';
import { Global } from '../../../helpers/constants';

export const useStyles = makeStyles(() => {
    return {
        strikethrough: {
            textDecoration: 'line-through'
        },

        undoIcon: {
            marginLeft: '10px'
        }
    }
})

const TitleOverridable = (props) => {
    const { item, handlePriceChange, handlePriceClear } = props;
    const classes = useStyles();

    const [isEditingPrice, setIsEditingPrice] = useState(false);
    const [isClearingPrice, setIsClearingPrice] = useState(false);

    return (
        <>
            <Box display="flex" alignItems="flex-end">
                <TextFieldEditable
                    ViewComponent={<Box alignSelf="flex-end" component={Typography}>${(+item.price).toFixed(0)}</Box>}
                    label={"New Price"}
                    variant={"outlined"}
                    value={item.price && (+item.price).toFixed(0)}
                    validators={[required, minOrEqual(0), maxOrEqual(Global.maxSafeInt32)]}
                    fullWidth={true}
                    type={"number"}
                    size="small"
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    editButtonProps={{ size: 'small' }}
                    doneButtonProps={{ size: 'small' }}
                    cancelProps={{ size: 'small' }}
                    editIconProps={{ fontSize: 'small' }}
                    doneIconProps={{ fontSize: 'small' }}
                    cancelIconProps={{ fontSize: 'small' }}
                    circularProgressProps={{ size: 26 }}
                    handleIsEdit={setIsEditingPrice}
                    handleDone={handlePriceChange}
                />
            </Box>
            {item.has_price_override && !isEditingPrice
                && <Box>
                    <Typography className={classes.strikethrough} color="textSecondary" variant="body2">
                        ${(+item.price_upstream).toFixed(0)}

                        <IconButton className={classes.undoIcon}
                            size="small"
                            disabled={isClearingPrice}
                            onClick={() => {
                                setIsClearingPrice(true);
                                setIsEditingPrice(false)
                                handlePriceClear()
                                    .finally(() => {
                                        setIsClearingPrice(false)
                                    })
                            }}>
                            <UndoIcon fontSize="small" />
                        </IconButton>

                    </Typography>

                </Box>}
        </>
    )
}

TitleOverridable.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handlePriceChange: price => {
            return dispatch(setPriceOverride(props.item.id, price))
                .then(() => dispatch(showSuccess('Price was successfully set')));
        },
        handlePriceClear: () => {
            return dispatch(clearPriceOverride(props.item.id))
                .then(() => dispatch(showSuccess('Price override was successfully cleared')));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    TitleOverridable
);
