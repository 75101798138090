import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { GoogleMapsContext } from '../../contexts';
import { logger } from '../../helpers/logger';
import CircularProgressCentered from '../material/CircularProgressCentered';
import { useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker } from '@react-google-maps/api';

const useStyles = makeStyles(() => {
    return {
        map: {
            width: '100%',
            height: '100%'
        },
        streetViewBoard: {
            height: '480px',
            width: '640px'
        }
    }
})

const StreetViewMap = (props) => {
    const { panoId, lat, lng, pov = { heading: 100, pitch: 0, }, zoom = 1,
        onChangePosition, onChangePov } = props;

    const propsClasses = props.classes || {};
    const classes = useStyles();
    const { t } = useTranslation();

    const panoRef = useRef();
    const [pano, setPano] = useState();

    const position = useMemo(
        () => new window.google.maps.LatLng(+lat, +lng),
        [lat, lng]
    );

    const onMapLoaded = useCallback(map => {
        map.setCenter(new window.google.maps.LatLng(lat, lng));
        map.setZoom(16);

        const panorama = new window.google.maps.StreetViewPanorama(
            panoRef.current,
            {
                pano: panoId,
                position: { lat, lng },
                pov,
            }
        );

        map.setStreetView(panorama);
        setPano(panorama);
    }, [panoId, lat, lng, pov, zoom]);

    useEffect(() => {
        if (!pano) {
            return;
        }

        const posChanged = pano.addListener("position_changed",
            () => onChangePosition({
                panoId: pano.getPano(),
                lat: pano.getPosition().lat(),
                lng: pano.getPosition().lng()
            }));
        const povChanged = pano.addListener("pov_changed",
            () => onChangePov(pano.getPov()));

        return () => {
            posChanged.remove();
            povChanged.remove();
        }
    }, [pano, onChangePosition, onChangePov])

    const gmContext = useContext(GoogleMapsContext);

    useEffect(() => {
        if (gmContext.error) {
            console.error(gmContext.error)
            logger.logError(`StreetViewMap failed to load: ${gmContext.error}`, { Error: gmContext.error });
        }
    }, [gmContext.error])

    if (!gmContext.loaded) {
        return <CircularProgressCentered size={40} />
    }

    if (gmContext.error) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>{t('Map is currently unavailable')}</Typography>
        </Box>
    }

    const mapOptions = useMemo(() => ({
        mapId: "48545040baabafe",
        mapTypeControl: false,
    }), []);

    return <Grid container style={{ height: '100%' }}>
        <div style={{ flex: '1' }}>
            <GoogleMap
                mapContainerClassName={clx(classes.map, propsClasses.map)}
                options={mapOptions}

                onLoad={onMapLoaded}
            >
                <Marker
                    animation={window.google.maps.Animation.DROP}
                    position={position}
                />
            </GoogleMap>
        </div>
        <div className={classes.streetViewBoard}>
            <Box width="100%" height="100%" ref={panoRef} />
        </div>
    </Grid>;
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

StreetViewMap.propTypes = {
    classes: PropTypes.object,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    StreetViewMap
);
