import { useState, useLayoutEffect } from "react";
import useResizeObserver from '@react-hook/resize-observer'

export const useSize = myRef => {
    const [size, setSize] = useState({ width: 0, height: 0 })

    useLayoutEffect(() => {
        if (myRef.current) {
            setSize(myRef.current.getBoundingClientRect())
        }
    }, [myRef.current])

    useResizeObserver(myRef, x => setSize(x.contentRect))
    return size;
};
