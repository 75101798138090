import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import { lucitApi } from "../../services/lucitApi";
import { Box, Checkbox, Typography } from "@material-ui/core";

const BillboardScreenSizeSelector = (props) => {
    const { agency, values, onChange, onLoadedFormats } = props;

    const [loadingFormats, setLoadingFormats] = useState(false)
    const [boardFormats, setBoardFormats] = useState({})
    const { t } = useTranslation();

    const getOptions = (formats) => Object.keys(formats)
        .map(x => ({
            code: x,
            data: formats[x]
        }))

    useEffect(() => {
        if (agency) {
            setBoardFormats({});
            setLoadingFormats(true);
            lucitApi.agencies.getDigitalBoardFormats(agency.id)
                .then((boardFormats) => {
                    setBoardFormats(boardFormats);

                    if (onLoadedFormats)
                        onLoadedFormats(getOptions(boardFormats));
                })
                .finally(() => setLoadingFormats(false));
        }
    }, [agency])

    return <Autocomplete
        value={values}
        onChange={(_, values) => onChange(values)}
        name="boardFormats"
        multiple
        limitTags={2}
        options={getOptions(boardFormats)}
        loading={loadingFormats}
        disabled={loadingFormats}
        disableCloseOnSelect

        getOptionSelected={(option) => values.some(e => e.code === option.code)}
        getOptionLabel={(option) => option.data.name}
        renderOption={ScreenSizeOption}
        renderInput={renderProps => <TextField
            {...renderProps}
            variant="outlined"
            fullWidth={true}
            label={t('Screen Sizes')}
        />}
    />
}

const ScreenSizeOption = (option, { selected }) => {
    const maxSizes = 3;

    const sizesUnderMax = option.data.unique_sizes.filter((size, index) => size && index < maxSizes)
    const sizesCountOverMax = option.data.unique_sizes.length - maxSizes
    const hasSizesOverMax = sizesCountOverMax > 0;

    const uniqueSizeListString = sizesUnderMax.join("px, ") + "px";
    const description = uniqueSizeListString + (Boolean(hasSizesOverMax) && ` + ${sizesCountOverMax} more`)

    return (
        <>
            <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
            />
            <Box>
                <strong>{option.data.name}</strong>
                <Typography variant="body2">{description}</Typography>
            </Box>
        </>
    )
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BillboardScreenSizeSelector)
)
