import React from 'react';
import { connect } from 'react-redux';
import clx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Typography, CardMedia, CardActionArea, CardHeader, Box, Link } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { InventoryItemClasses, InventoryItemPlaceholder, RoutePaths } from '../../helpers/constants';
import { selected, isSomethingSelected, hasPhoto } from '../../selectors/inventory';
import { toggleSelect } from '../../actions/inventory';
import { useState } from 'react';
import Tooltip from '../../components/material/Tooltip';
import { toLocalDateTime } from '../../helpers/date';
import { hasAllowedExports, inventoryItemClass } from '../../selectors/inventory';
import InventoryItemCardContent from './itemCardContent/InventoryItemCardContent';

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '325px',

            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
        },
        selected: {
            backgroundColor: theme.palette.action.selected
        },
        headerRoot: {
            // display: 'block'
        },
        headerContent: {
            width: 'calc(100% - 48px)'
        },
        subheader: {
            fontSize: '0.75rem',
            fontWeight: 400
        },
        actionArea: {
            userSelect: 'none'
        },

        image: {
            width: '100%',
            objectFit: 'contain'
        },

        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: 'relative',
            width: '100%'
        },

        checkbox: {
            position: 'absolute',
            right: theme.spacing(0.5),
            bottom: theme.spacing(0.5)
        }
    }
})

function InventoryItemCard(props) {
    const { hasAllowedExports, isSomethingSelected, item, history, handleSelect } = props;
    const classes = useStyles();
    const [cardPressTimer, setCardPressTimer] = useState(null);

    const handleCardPress = hasAllowedExports
        ? () => setCardPressTimer(setTimeout(() => handleSelect(item.id), 800))
        : () => { };

    const handleCardRelease = () => {
        clearTimeout(cardPressTimer);
        setCardPressTimer(null);
    };

    return (
        <Card classes={{ root: clx(classes.root, { [classes.selected]: selected(item) }) }} elevation={3}>
            <CardHeader
                classes={{
                    root: classes.headerRoot,
                    content: classes.headerContent
                }}
                title={<Tooltip title={item.title} placement="top">
                    <Link
                        component={RouterLink}
                        to={RoutePaths.inventory + `/${item.id}`}
                    >
                        <LCTypography transProp={{ itemTitle: item.title }} noWrap>{{ itemTitle: item.title }}</LCTypography>
                    </Link>
                </Tooltip>}
                subheader={<Typography className={classes.subheader}
                    noWrap>{toLocalDateTime(item.created_at).format("MM/DD/YYYY hh:mm A")}</Typography>}
            />
            <CardActionArea
                className={classes.actionArea}
                onTouchStart={handleCardPress}
                onTouchEnd={handleCardRelease}
                onClick={() => {
                    isSomethingSelected
                        ? handleSelect(item.id)
                        : history.push({ pathname: RoutePaths.inventory + `/${item.id}`, })
                }}>
                <CardMedia
                    component="img"
                    height={160}
                    className={classes.image}
                    title={item.title}
                    image={!hasPhoto(item)
                        ? InventoryItemPlaceholder[item.item_class]
                        || InventoryItemPlaceholder[InventoryItemClasses.generic.class]
                        : item.photo.public_url}
                />
            </CardActionArea>

            <Box className={clx(classes.content)}>
                <InventoryItemCardContent itemClass={inventoryItemClass(item)} item={item} />
            </Box>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        isSomethingSelected: isSomethingSelected(state),
        hasAllowedExports: hasAllowedExports(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleSelect: itemId => dispatch(toggleSelect(itemId))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        InventoryItemCard
    )
);
