import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Accordion, AccordionSummary, AccordionDetails, Link, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core'
import { business } from 'ionicons/icons';
import LCTypography from '../../components/material/LCTypography';
import { IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import OperatorAddAccount from '../../components/modals/OperatorAddAccount'

const useStyles = makeStyles(theme => ({
    summary: {
        cursor: 'default !important',

        '& .Mui-expanded': {
            margin: theme.spacing(1.5, 0)

        }
    },
    content: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        },
    },
    contentContainer: {
        maxWidth: 800
    }
}));

const NoAccountsNoOperatorsUser = () => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [showAddNewAccount, setShowAddNewAccount] = useState(false)
    const [defaultAccountTypeOption, setDefaultAccountTypeOption] = useState('newAccount')

    return (
        <div className={classes.contentContainer}>

            <Accordion expanded>
                <AccordionSummary
                    className={classes.summary}
                >
                    <LCTypography variant="h5">Welcome to Lucit!</LCTypography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails className={classes.content}>
                    <Box display="flex" flexDirection="column">
                        <LCTypography>
                            Lucit helps you control your digital billboard campaigns from anywhere
                        </LCTypography>
                        <ul>
                            <li>Post images to digital billboards</li>
                            <li>Connect your inventory system to auto-generate creatives</li>
                            <li>View real-time statistics of your billboard plays and impressions</li>
                        </ul>
                        <LCTypography>
                            You can learn more about lucit at <Link variant="body2" color="secondary" href="https://lucit.cc" target="_blank">lucit.cc</Link> or by visiting our help
                            and <Link variant="body2" color="secondary" href="https://lucit.cc/help/" target="_blank">frequently asked questions page</Link>
                        </LCTypography>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Grid container spacing={2}>

                <Grid item xs={4}>

                    <Accordion expanded>
                        <AccordionSummary
                            className={classes.summary}
                        >
                            <LCTypography variant="h5">Advertisers</LCTypography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails className={classes.content}>
                            <Box pt={1} pb={5} width="100%" textAlign='center'>
                                <Box>

                                    <Box mb={3}>
                                        <IonButton
                                            className={classes.operatorButtons}
                                            onClick={() => {
                                                setDefaultAccountTypeOption("newAccount")
                                                setShowAddNewAccount(true)
                                            }}
                                        >
                                            <IonIcon slot="start" icon={business}></IonIcon>
                                            {t('Create your Account')}
                                        </IonButton>
                                    </Box>

                                    <LCTypography>
                                        Use Lucit to control your existing digital out-of-home
                                        billboard advertising campaigns or create new ones
                                    </LCTypography>

                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <Grid item xs={4}>

                    <Accordion expanded>
                        <AccordionSummary
                            className={classes.summary}
                        >
                            <LCTypography variant="h5">Ad Agencies</LCTypography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails className={classes.content}>
                            <Box pt={1} pb={5} width="100%" textAlign='center'>
                                <Box>

                                    <Box mb={3}>
                                        <IonButton
                                            className={classes.operatorButtons}
                                            onClick={() => {
                                                setDefaultAccountTypeOption("newAdAgency")
                                                setShowAddNewAccount(true)
                                            }}
                                        >
                                            <IonIcon slot="start" icon={business}></IonIcon>
                                            {t('Create Ad Agency')}
                                        </IonButton>
                                    </Box>

                                    <LCTypography>
                                        <strong>Do you manage out-of-home advertising for multiple clients?</strong> <br /><br />
                                        Control all of your digital out of home billboard advertising with Lucit
                                    </LCTypography>

                                    <Box mt={3}>
                                        <LCTypography>
                                            You can learn more about how ad agencies can use lucit at&nbsp;
                                            <Link variant="body2" color="secondary" href="https://lucit.cc/operators/" target="_blank">
                                                lucit.cc/operators
                                            </Link>
                                            &nbsp;and&nbsp;
                                            <Link variant="body2" color="secondary" href="https://lucit.cc/help/" target="_blank">
                                                frequently asked questions page
                                            </Link>
                                        </LCTypography>
                                    </Box>

                                </Box>

                            </Box>
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={4}>

                    <Accordion expanded>
                        <AccordionSummary
                            className={classes.summary}
                        >
                            <LCTypography variant="h5">Media Owners</LCTypography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails className={classes.content}>
                            <Box pt={1} pb={5} width="100%" textAlign='center'>
                                <Box>

                                    <Box mb={3}>
                                        <IonButton
                                            className={classes.operatorButtons}
                                            onClick={() => {
                                                setDefaultAccountTypeOption("newOperator")
                                                setShowAddNewAccount(true)
                                            }}
                                        >
                                            <IonIcon slot="start" icon={business}></IonIcon>
                                            {t('Create Operator')}
                                        </IonButton>
                                    </Box>

                                    <LCTypography>
                                        <strong>Do you work for a digital out-of-home company?</strong> <br /><br />
                                        On-board your screens into the Lucit Application and give your clients access
                                        to control their campaigns, manage creatives and view real-time statistics
                                    </LCTypography>

                                    <Box mt={3}>
                                        <LCTypography>
                                            You can learn more about how out-of-home operators can use lucit at&nbsp;
                                            <Link variant="body2" color="secondary" href="https://lucit.cc/operators/" target="_blank">
                                                lucit.cc/operators
                                            </Link>
                                            &nbsp;and&nbsp;
                                            <Link variant="body2" color="secondary" href="https://lucit.cc/help/" target="_blank">
                                                frequently asked questions page
                                            </Link>
                                        </LCTypography>
                                    </Box>

                                </Box>

                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <OperatorAddAccount
                showAgencyAndOperator={true}
                defaultAccountTypeOption={defaultAccountTypeOption}
                open={showAddNewAccount}
                handleClose={() => setShowAddNewAccount(false)}
            />

        </div>
    )
}

const mapStateToProps = () => {
    return {
    };
}

export default
    connect(
        mapStateToProps
    )(
        NoAccountsNoOperatorsUser
    );
