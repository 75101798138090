import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider, Box, List, AccordionSummary, AccordionDetails } from '@material-ui/core';
import LCTypography from '../../components/material/LCTypography';
import AccordionScrollable from '../../components/material/AccordionScrollable';
import FeedListItem from './FeedListItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FeedIcon from '@material-ui/icons/DoubleArrow';
import { makeStyles } from '@material-ui/styles';
import { lucitApi } from "../../services/lucitApi";
import { selectedAccount } from '../../selectors/user';
import CircularProgressCentered from '../../components/material/CircularProgressCentered';

const useStyles = makeStyles(theme => {
    return {
        content: {
            marginTop: theme.spacing(2)
        },
        headerIcon: {
            marginBottom: -3
        }
    }
});

const FeedsList = ({ selectedAccount }) => {

    const classes = useStyles();

    const [isOpened, setOpened] = useState(true);
    const [feeds, setFeeds] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        lucitApi.feeds.getActiveFeedsForAccount(selectedAccount.id)
            .then(result => {
                setFeeds(result.data)
                setLoading(false)
            });

    }, [selectedAccount.id])

    return (
        <AccordionScrollable expanded={isOpened} onChange={() => setOpened(!isOpened)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="userinfo-content"
                id="userinfo-header"
            >
                <LCTypography
                    variant="h5" ><FeedIcon className={classes.headerIcon} /> Data Feeds</LCTypography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.content}>

                {loading && <CircularProgressCentered />}

                <List
                    style={{ width: '100%' }}
                    disablePadding>
                    {feeds.map((item) => <FeedListItem key={item.id} item={item} />)}
                </List>

                {!feeds && !feeds.length
                    && <Box mt={2}>
                        <LCTypography variant="subtitle1">No Data Source</LCTypography>
                    </Box>}

            </AccordionDetails>

        </AccordionScrollable>
    );
}

const mapStateToProps = state => {
    return {
        selectedAccount: selectedAccount(state)
    };
}

export default connect(
    mapStateToProps
)(
    FeedsList
);
