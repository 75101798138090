import { Grid, Box, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMousePosition, useKeyDown, useMouseWheel } from '../../helpers/hooks';
import { pathToString } from '../../helpers/string';
import { getDefaultPolygonPath, transformCoordinates } from '../../helpers/image';
import { createLasso } from './lasso-canvas-image';
import LCTypography from '../../components/material/LCTypography';
const keyboardArrowsUrl = require('../../assets/images/KeyboardArrows.png').default;
const mouseWheelUrl = require('../../assets/images/MouseWheel.png').default;

const useStyles = makeStyles(theme => {
    return {
        previewBox: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            paddingRight: 236
        },

        hintsBox: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: 228,
            background: 'white'
        },

        controlsHints: {
            borderRadius: theme.shape.borderRadius,
            border: '1px solid black',
            padding: theme.spacing(1, 2)
        },

        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
})

const LassoCanvasImage = (props) => {
    const { src,
        classes: classesCustom = {},
        previewScaleDefault = 8,
        previewScaleMin = 2,
        previewScaleMax = 20,
        previewBoxSize = 150,
        strokeColor = 'black',
        fillColor = 'rgba(2, 202, 202, 0.4)',
        lassoOptions = {} } = props;

    const classes = useStyles();
    const canvasRef = useRef();
    const imageRef = useRef();

    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageNaturalSize, setImageNaturalSize] = useState({ width: 0, height: 0 });
    const [previewScale, setPreviewScale] = useState(previewScaleDefault);
    const [loaded, setLoaded] = useState(false);
    const [lasso, setLasso] = useState(null);

    const [path, setPath] = useState(props.path || []);
    const [selectedPoint, setSelectedPoint] = useState(null);

    const [mousePointOnCanvas, setMousePointOnCanvas] = useState({ x: 0, y: 0 })
    const mousePoint = useMousePosition();
    const [mouseOver, setMouseOver] = useState(false);

    const showPreview = selectedPoint && mouseOver;

    const onLoad = useCallback(() => {
        setImageSize({
            width: imageRef.current.width,
            height: imageRef.current.height
        })
        setImageNaturalSize({
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight
        })

        setLoaded(true);
    }, [])

    const onPathChange = useCallback((path, propagate) => {
        setPath(path);

        if (props.onPathChange && propagate) {
            props.onPathChange(transformCoordinates(path, imageSize, imageNaturalSize));
        }
    }, [imageSize, imageNaturalSize, props.onPathChange]);

    useEffect(() => {
        if (!loaded)
            return;

        const lasso = createLasso({
            radius: 10,
            maxPoints: 4,
            enablePointRemove: false,
            strokeColor,
            fillColor,

            ...lassoOptions,

            image: imageRef.current,
            canvas: canvasRef.current,

            onChange: path => onPathChange(path, true),
            onUpdate: path => onPathChange(path),
            onPointSelected: point => {
                setSelectedPoint(point);
            },
            onPointMove: point => {
                setSelectedPoint(point);
            },
            onMouseMove: point => setMousePointOnCanvas({ ...point })
        });

        lasso.init();

        setLasso(lasso);
    }, [loaded, onPathChange])

    useEffect(() => {
        if (!loaded)
            return;

        let path = transformCoordinates(props.path || [], imageNaturalSize, imageSize);

        if (path.length === 0) {
            path = getDefaultPolygonPath(imageSize);
        }

        onPathChange(path, true);

        setPath(path);

        if (lasso && imageSize) {
            lasso.setPath(path)
        }
    }, [lasso, loaded])

    useKeyDown(['a', 'A', 'ArrowLeft'], () => {
        if (selectedPoint) {
            const newPoint = {
                ...selectedPoint,
                x: selectedPoint.x - 1 / previewScale
            };

            setSelectedPoint(newPoint);
            lasso.updatePoint(newPoint);
        }
    });

    useKeyDown(['w', 'W', 'ArrowUp'], () => {
        if (selectedPoint) {
            const newPoint = {
                ...selectedPoint,
                y: selectedPoint.y - 1 / previewScale
            };

            setSelectedPoint(newPoint);
            lasso.updatePoint(newPoint);
        }
    });

    useKeyDown(['d', 'D', 'ArrowRight'], () => {
        if (selectedPoint) {
            const newPoint = {
                ...selectedPoint,
                x: selectedPoint.x + 1 / previewScale
            };

            setSelectedPoint(newPoint);
            lasso.updatePoint(newPoint);
        }
    });

    useKeyDown(['s', 'S', 'ArrowDown'], () => {
        if (selectedPoint) {
            const newPoint = {
                ...selectedPoint,
                y: selectedPoint.y + 1 / previewScale
            };

            setSelectedPoint(newPoint);
            lasso.updatePoint(newPoint);
        }
    });

    useMouseWheel(useCallback((e) => {
        let newScale = previewScale + e.deltaY * -0.004;

        newScale = Math.max(newScale, previewScaleMin);
        newScale = Math.min(newScale, previewScaleMax);

        if (showPreview) {
            setPreviewScale(newScale);
        }
    }, [previewScale, showPreview]));

    return <Box className={classes.previewBox}>
        <img ref={imageRef}
            className={classesCustom.previewImage}
            style={{ display: loaded ? 'none' : 'block' }}
            src={src}
            onLoad={onLoad} />

        <canvas width={imageSize.width}
            height={imageSize.height}
            ref={canvasRef}
            onMouseLeave={() => setMouseOver(false)}
            onMouseEnter={() => setMouseOver(true)}
            style={{ userSelect: 'none' }} />

        <Box position="fixed"
            left={mousePoint.x}
            top={mousePoint.y - previewBoxSize}
            width={previewBoxSize}
            height={previewBoxSize}
            overflow="hidden"
            zIndex={1003}
            display={showPreview ? 'block' : 'none'}
            style={{ border: '1px solid black', borderRadius: '50%' }}>
            <img src={src}
                style={{
                    position: `absolute`,
                    height: previewScale * imageSize.height,
                    width: previewScale * imageSize.width,
                    top: -previewScale * mousePointOnCanvas.y + previewBoxSize / 2,
                    left: -previewScale * mousePointOnCanvas.x + previewBoxSize / 2,
                }}
            />

            <svg
                style={{
                    position: 'absolute',
                    height: previewScale * imageSize.height,
                    width: previewScale * imageSize.width,
                    top: -previewScale * mousePointOnCanvas.y + previewBoxSize / 2,
                    left: -previewScale * mousePointOnCanvas.x + previewBoxSize / 2,
                }}
            >
                <polygon
                    fill={fillColor}
                    stroke={strokeColor}
                    points={pathToString(path.map(p => ({ x: p.x * previewScale, y: p.y * previewScale })))} />

                {/* {path.map((p, idx) => <SvgPoint key={idx} point={{ x: p.x * previewScale, y: p.y * previewScale }} />)} */}
            </svg>
        </Box>

        <Box className={classes.hintsBox}>
            <Box className={classes.controlsHints}>
                <Grid container spacing={2} alignItems='center' justify='center'>

                    <Grid item xs={12}>
                        <LCTypography variant="body2">
                            Use the corners of the highlighted area to drag the selection to match up to the 4
                            corners of the billboard screen in your image.   Each corner should be as exact as possible
                            to ensure that the image is properly aligned.
                        </LCTypography>
                    </Grid>

                    <Grid item xs={4} className={classes.center}>
                        <svg width={34} height={34}>
                            <SvgPoint point={{ x: 17, y: 17 }}
                                radius={15}
                                strokeWidth={2} />
                        </svg>
                    </Grid>

                    <Grid item xs={8}>
                        <LCTypography variant="caption">Points (selected point is in red color)</LCTypography>
                    </Grid>
                    <Grid item xs={4} className={classes.center}>
                        <img width={60} src={keyboardArrowsUrl} />
                    </Grid>
                    <Grid item xs={8}>
                        <LCTypography variant="caption"> Use keyboard arrows to move points</LCTypography>
                    </Grid>
                    <Grid item xs={4} className={classes.center}>
                        <img width={50} src={mouseWheelUrl} />
                    </Grid>
                    <Grid item xs={8}>
                        <LCTypography variant="caption"> Use mouse wheel to zoom preview</LCTypography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Box>
}

const SvgPoint = ({ point, radius = 10, strokeWidth = 1, strokeColor = "black" }) => <>
    <circle cx={point.x} cy={point.y} r={radius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="none" />
    <line x1={point.x - radius / 2} y1={point.y - radius / 2} x2={point.x + radius / 2} y2={point.y + radius / 2}
        stroke={strokeColor}
        strokeWidth={strokeWidth} />
    <line x1={point.x - radius / 2} y1={point.y + radius / 2} x2={point.x + radius / 2} y2={point.y - radius / 2}
        stroke={strokeColor}
        strokeWidth={strokeWidth} />
</>

export default LassoCanvasImage;
