import { LOADER_SHOW, LOADER_HIDE, LOADER_TOOGLE } from "../helpers/actionTypes";

const initialState = {
    isOpen: false,
    requestCount: 0
}

export default function loader(state = initialState, action) {
    switch (action.type) {
        case LOADER_SHOW:
            return Object.assign({},
                state,
                {
                    isOpen: true,
                    requestCount: state.requestCount + 1
                });
        case LOADER_HIDE:
            return Object.assign({},
                state,
                {
                    isOpen: state.requestCount > 1,
                    requestCount: state.requestCount - 1
                });
        case LOADER_TOOGLE:
            return Object.assign({},
                state,
                {
                    isOpen: !state.isOpen,
                    requestCount: !state.isOpen
                        ? 1
                        : 0
                });
        default:
            return state
    }
}
